export enum EAuthActions {
    AUTH_PENDING = 'AUTH_PENDING',
    AUTH_FETCHED = 'AUTH_FETCHED',
    AUTH_ERROR = 'AUTH_ERROR',
    AUTH_USER_LOGOUT = 'AUTH_USER_LOGOUT',
    AUTH_USER_LOGIN_SUCCESS = 'AUTH_USER_LOGIN_SUCCESS',
    AUTH_USER_EDIT = 'AUTH_USER_EDIT',
    AUTH_USER_LOADED_PAGE = 'AUTH_USER_LOADED_PAGE',
    AUTH_USER_GET_CHILD_CONTACTS = 'AUTH_USER_GET_CHILD_CONTACTS',
    AUTH_USER_GET_CHILD_CONTACTS_SUCCESS = 'AUTH_USER_GET_CHILD_CONTACTS_SUCCESS',
    AUTH_USER_GET_CHILD_CONTACT = 'AUTH_USER_GET_CHILD_CONTACT',
    AUTH_USER_GET_CHILD_CONTACT_SUCCESS = 'AUTH_USER_GET_CHILD_CONTACT_SUCCESS',
    AUTH_USER_UPDATE_CHILD_CONTACT = 'AUTH_USER_UPDATE_CHILD_CONTACT',
    AUTH_USER_CHILD_CONTACT_OUTLETS_NEED_REMOVE = 'AUTH_USER_CHILD_CONTACT_OUTLETS_NEED_REMOVE',
    AUTH_USER_SET_CHILD_CONTACT_OUTLETS_TO_REMOVE = 'AUTH_USER_SET_CHILD_CONTACT_OUTLETS_TO_REMOVE',
    AUTH_USER_UPDATE_TUTORIALHIDE_FLAG = 'AUTH_USER_UPDATE_TUTORIALHIDE_FLAG',
}
