/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotChildContactSearchResult } from '../models/hot-child-contact-search-result';
import { HotChildContactRegistrationRequest } from '../models/hot-child-contact-registration-request';
import { HotChildContactOutletResponse } from '../models/hot-child-contact-outlet-response';
import { HotChildContactOutlet } from '../models/hot-child-contact-outlet';
import { HotSecurityResponse } from '../models/hot-security-response';
import { HotChildContactActivationRequest } from '../models/hot-child-contact-activation-request';
@Injectable({
  providedIn: 'root',
})
class HotApiChildContactsService extends __BaseService {
  static readonly getChildContactsPath = '/storefrontapi/hot/child-contacts';
  static readonly savePath = '/storefrontapi/hot/child-contacts';
  static readonly getChildContactOutletsPath = '/storefrontapi/hot/child-contacts/{contactId}/outlets';
  static readonly updateChildContactOutletsPath = '/storefrontapi/hot/child-contacts/{contactId}/outlets';
  static readonly deletePath = '/storefrontapi/hot/child-contacts/{contactId}';
  static readonly activatePath = '/storefrontapi/hot/child-contacts/activate';
  static readonly activateChildAccountPath = '/storefrontapi/hot/child-contacts/{requestId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `HotApiChildContactsService.GetChildContactsParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `keyword`:
   *
   * @return Success
   */
  getChildContactsResponse(params: HotApiChildContactsService.GetChildContactsParams): __Observable<__StrictHttpResponse<HotChildContactSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.keyword != null) __params = __params.set('keyword', params.keyword.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/child-contacts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotChildContactSearchResult>;
      })
    );
  }
  /**
   * @param params The `HotApiChildContactsService.GetChildContactsParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `keyword`:
   *
   * @return Success
   */
  getChildContacts(params: HotApiChildContactsService.GetChildContactsParams): __Observable<HotChildContactSearchResult> {
    return this.getChildContactsResponse(params).pipe(
      __map(_r => _r.body as HotChildContactSearchResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  saveResponse(body?: HotChildContactRegistrationRequest): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/child-contacts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  save(body?: HotChildContactRegistrationRequest): __Observable<string> {
    return this.saveResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param contactId undefined
   * @return Success
   */
  getChildContactOutletsResponse(contactId: string): __Observable<__StrictHttpResponse<HotChildContactOutletResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/child-contacts/${encodeURIComponent(String(contactId))}/outlets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotChildContactOutletResponse>;
      })
    );
  }
  /**
   * @param contactId undefined
   * @return Success
   */
  getChildContactOutlets(contactId: string): __Observable<HotChildContactOutletResponse> {
    return this.getChildContactOutletsResponse(contactId).pipe(
      __map(_r => _r.body as HotChildContactOutletResponse)
    );
  }

  /**
   * @param params The `HotApiChildContactsService.UpdateChildContactOutletsParams` containing the following parameters:
   *
   * - `contactId`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateChildContactOutletsResponse(params: HotApiChildContactsService.UpdateChildContactOutletsParams): __Observable<__StrictHttpResponse<HotChildContactOutletResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/child-contacts/${encodeURIComponent(String(params.contactId))}/outlets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotChildContactOutletResponse>;
      })
    );
  }
  /**
   * @param params The `HotApiChildContactsService.UpdateChildContactOutletsParams` containing the following parameters:
   *
   * - `contactId`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateChildContactOutlets(params: HotApiChildContactsService.UpdateChildContactOutletsParams): __Observable<HotChildContactOutletResponse> {
    return this.updateChildContactOutletsResponse(params).pipe(
      __map(_r => _r.body as HotChildContactOutletResponse)
    );
  }

  /**
   * @param contactId undefined
   */
  deleteResponse(contactId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/storefrontapi/hot/child-contacts/${encodeURIComponent(String(contactId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param contactId undefined
   */
  delete(contactId: string): __Observable<null> {
    return this.deleteResponse(contactId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  activateResponse(body?: HotChildContactActivationRequest): __Observable<__StrictHttpResponse<HotSecurityResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/child-contacts/activate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotSecurityResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  activate(body?: HotChildContactActivationRequest): __Observable<HotSecurityResponse> {
    return this.activateResponse(body).pipe(
      __map(_r => _r.body as HotSecurityResponse)
    );
  }

  /**
   * @param requestId undefined
   */
  activateChildAccountResponse(requestId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/child-contacts/${encodeURIComponent(String(requestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param requestId undefined
   */
  activateChildAccount(requestId: string): __Observable<null> {
    return this.activateChildAccountResponse(requestId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module HotApiChildContactsService {

  /**
   * Parameters for GetChildContacts
   */
  export interface GetChildContactsParams {
    take?: number;
    skip?: number;
    keyword?: string;
  }

  /**
   * Parameters for UpdateChildContactOutlets
   */
  export interface UpdateChildContactOutletsParams {
    contactId: string;
    body?: Array<HotChildContactOutlet>;
  }
}

export { HotApiChildContactsService }
