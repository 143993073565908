import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, take } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';

import { CurrencyFormat, HotLoyaltyStatement, HotRedemptionWindowStatus } from '@hot-theme-nx/generated-api';
import { SettingsStoreModel } from '@hot-libs/shared-models';

import {
    DateFormatParserService,
    FeaturesService,
    LoaderService,
    ProductService,
} from 'apps/hot-b2b/src/app/shared/services';
import { LoyaltyService } from 'apps/hot-b2b/src/app/shared/services/loyalty.service';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsCurrencyFormat, settingsData, settingsStore } from '@hot-b2b/store/settings/selector';
import { CartProductUpdate } from '@hot-b2b/store/cart/actions';
import { ProductsItemUpdate } from '@hot-b2b/store/products/actions';
import { loyalty, redemptionWindowStatus } from '@hot-b2b/store/loyalty/selector';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { combineLatest } from 'rxjs';
import { formatDate } from '@angular/common';

@Component({
    selector: 'hot-rewards-product-modal',
    templateUrl: './rewards-product-modal.component.html',
})
export class RewardsProductModalComponent implements OnInit {
    @Input() public modifier: string;
    @Input() public product;
    @Output() public closeModal: EventEmitter<any> = new EventEmitter();
    @Output() public addToCart: EventEmitter<any> = new EventEmitter();
    @Output() public removeFromCart: EventEmitter<any> = new EventEmitter();
    @Output() public sendChangeQuantity: EventEmitter<any> = new EventEmitter();
    @Output() public redeem: EventEmitter<any> = new EventEmitter();
    @Output() public buttonTopUp: EventEmitter<any> = new EventEmitter();

    public selectedImageShow: boolean;
    public selectedImageHide = false;
    public selectedImage: string;
    public dimensions: string;
    public dateFormat: string;
    public locale: string;
    public isFormTopup: boolean = false;

    public readonly loyaltyBalanceEnable$: Observable<boolean>;
    public readonly loyaltyStatement$: Observable<HotLoyaltyStatement>;
    public readonly settingsStore$: Observable<SettingsStoreModel>;
    public readonly currencyFormat$: Observable<CurrencyFormat>;
    public readonly settings$: Observable<HotSettingsExtended>;
    public redemptionWindowStatus$: Observable<HotRedemptionWindowStatus>;

    public selfProduct;
    public loyaltyCurrencyFormat: CurrencyFormat;

    public featureBackInStockSubscription: boolean = this.featuresService.BackInStockSubscription;

    constructor(
        private readonly store: Store<AppState>,
        private readonly loyaltyService: LoyaltyService,
        private readonly loaderService: LoaderService,
        private readonly productService: ProductService,
        private readonly featuresService: FeaturesService,
        private readonly dateFormatParserService: DateFormatParserService
    ) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.loyaltyBalanceEnable$ = this.loyaltyService.loyaltyBalanceEnable();
        this.loyaltyStatement$ = this.store.pipe(select(loyalty));
        this.redemptionWindowStatus$ = this.store.pipe(select(redemptionWindowStatus));
        this.settings$ = this.store.pipe(select(settingsData));
    }

    public ngOnInit(): void {
        this.selectedImage = this.product.images[0];
        this.selectedImageShow = true;
        this.product.images = this.product.images.slice(0, 3);
        this.settingsStore$.subscribe((settings: SettingsStoreModel) => {
            this.loyaltyCurrencyFormat = cloneDeep(settings.loyaltyCurrencyFormat);
        });
        this.dimensions = this.productService.getProductMeasures(this.product);
        this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
            this.dateFormat = this.dateFormatParserService.parseDateFormatForDatePipe(settings.dateFormat);
            if (settings.currentLanguage != null) {
                this.locale = settings.currentLanguage.code;
            }
        });
        this.isFormTopup = this.product.externalProductType === 'Topup' || this.product.externalProductType === 'Voucher';
    }

    public showImage(image: any): void {
        this.selectedImageShow = false;
        this.selectedImageHide = true;

        setTimeout(() => {
            this.selectedImage = image;
            this.selectedImageHide = false;
            this.selectedImageShow = true;
        }, 100);
    }

    public onImageLoadingError(_errorType: boolean, index: number): void {
        this.product.images.splice(index, 1);
        this.selectedImage = this.product.images[0];
    }

    public addToCartHandler(event: any): void {
        this.selfProduct = event;
        this.addToCart.emit(event);
    }

    public sendChangeQuantityHandler(event: any): void {
        this.selfProduct = event;
        this.sendChangeQuantity.emit(event);
    }

    public removeFromCartHandler(event: any): void {
        this.selfProduct = null;
        this.removeFromCart.emit(event);
    }

    public redeemHandler(product: any): void {
        this.loaderService.show();
        this.redeem.emit(product);
    }

    public emailMe(event: Event, productId: string): void {
        event.stopPropagation();

        this.productService.putProductSubscription(productId).subscribe(() => {
            this.product.isSubscribed = true;
            this.store.dispatch(new ProductsItemUpdate(this.product));
            this.store.dispatch(new CartProductUpdate(this.product));
        });
    }

    public showRedeemButton(): Observable<boolean> {
        return this.redemptionWindowStatus$.pipe(
            map((redemptionRedeemStatus: HotRedemptionWindowStatus) => {
                return (
                    !(
                        !redemptionRedeemStatus.isActive &&
                        !!formatDate(redemptionRedeemStatus.nextStartDate, this.dateFormat, this.locale)
                    ) && this.product.isInStock
                );
            })
        );
    }

    public showEmailMeButton(): Observable<boolean> {
        return this.redemptionWindowStatus$.pipe(
            map((redemptionEmailStatus: HotRedemptionWindowStatus) => {
                return (
                    redemptionEmailStatus.isActive && this.featureBackInStockSubscription && !this.product.isInStock
                );
            })
        );
    }

    public isRedeemButtonDisabled(): Observable<boolean> {
        return combineLatest([this.redemptionWindowStatus$, this.loyaltyStatement$]).pipe(
            map(([redemptionIsRedeemStatus, _loyaltyStatement]: [HotRedemptionWindowStatus, HotLoyaltyStatement]) => {
                return !redemptionIsRedeemStatus.isActive;
            })
        );
    }

    public topUp(event: any): void {
        this.buttonTopUp.emit(event);
    }
}
