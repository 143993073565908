import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { take, withLatestFrom } from 'rxjs/operators';

import { ModalIds, VerificationModalType } from '@hot-libs/shared-types';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'apps/hot-b2b/src/app/account/services';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';

@Component({
    selector: 'hot-verification-code-modal',
    templateUrl: './verification-code-modal.component.html',
})
export class VerificationCodeModalComponent {
    @Input() public stringToVerify: string;
    @Input() public type: VerificationModalType;

    private invalidTokenErrorMassage$: Observable<string>;

    public isVerificationCodeFormSubmitted: boolean;
    public verificationCodeForm: UntypedFormGroup = new UntypedFormGroup({
        token: new UntypedFormControl('', Validators.required),
    });

    constructor(
        private readonly accountService: UserService,
        private readonly modalService: ModalService,
        private readonly translateService: TranslateService
    ) {
        this.invalidTokenErrorMassage$ = this.translateService.get('shared.modals.verification.token-error');
    }

    public onSubmitVerificationCode(): void {
        this.isVerificationCodeFormSubmitted = true;
        if (this.verificationCodeForm.invalid) {
            return;
        }

        const token = this.verificationCodeForm.controls.token.value;
        let changeObservable: Observable<any> = this.accountService.changePhone(this.stringToVerify, token);

        if (this.type === VerificationModalType.Email) {
            changeObservable = this.accountService.changeEmail(this.stringToVerify, token);
        }

        changeObservable.pipe(take(1), withLatestFrom(this.invalidTokenErrorMassage$)).subscribe(([data, massage]) => {
            if (data.succeeded) {
                this.modalService.toggleModal(ModalIds.phoneEdit, false, { condition: 'success', type: this.type });
            } else {
                this.verificationCodeForm.controls.token.setErrors({ custom: massage });
            }
        });
    }
}
