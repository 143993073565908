import { Component } from '@angular/core';
import { selectModalData } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsCurrencyFormat } from '@hot-b2b/store/settings/selector';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { HotOrderExtended } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';
import { CurrencyFormat, HotDelivery } from '@hot-theme-nx/generated-api';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FeaturesService, OrderDetailsService } from '../../../services';

@Component({
    selector: 'hot-track-and-trace-modal',
    templateUrl: './track-and-trace-modal.component.html',
})
export class TrackAndTraceModalComponent {
    public order: HotOrderExtended;
    public currencyFormat$: Observable<CurrencyFormat>;
    private readonly subscriptionsDestroy$ = new Subject<boolean>();

    public startDate: string;
    public endDate: string;
    public invoiceNumberArr: string[] = [];
    public orangeColor: string = 'rgb(248, 185, 102)';

    public featureOrderPaymentsProcessing: boolean = this.featuresService.OrderPaymentsProcessing;

    constructor(
        public readonly orderDetailsService: OrderDetailsService,
        public readonly featuresService: FeaturesService,
        private readonly store: Store<AppState>,
        public readonly screenDimensionService: ScreenDimensionService,
        public readonly translateService: TranslateService
    ) {
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
    }

    public isUpLg = () => this.screenDimensionService.upLg();

    public buildCreationDate(date): Observable<string> {
        return this.orderDetailsService.setOrderCreationDateFormat(date);
    }

    public setDateFormat(date): Observable<string> {
        let startDate = moment.utc(date).format('DD/MM/YYYY');
        let endDate = moment.utc(date).add(6, 'days').format('DD/MM/YYYY');
        this.startDate = startDate;
        this.endDate = endDate;
        return this.orderDetailsService.setDaysFormat(date);
    }

    public ngOnInit(): void {
        this.store
            .pipe(
                select(selectModalData(ModalIds.trackAndTraceModal)),
                filter((order: any) => !!order),
                takeUntil(this.subscriptionsDestroy$)
            )
            .subscribe((order: HotOrderExtended) => {
                this.order = order;
            });
    }

    public openContainerUrl(event: Event, delivery: HotDelivery): void {
        event?.stopPropagation();
        window.open(delivery.containerUrl, '_blank');
    }

    public transformInvoiceNumber(invoiceNumber: string): string[] {
        this.invoiceNumberArr = [];
        this.invoiceNumberArr = invoiceNumber ? invoiceNumber.split(', ') : null;
        return this.invoiceNumberArr;
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }
}
