import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'quarterRoman',
})
export class QuarterRomanPipe implements PipeTransform {
    transform(quarter: number): string {
        if (quarter === 1) {
            return 'I';
        }
        if (quarter === 2) {
            return 'II';
        }
        if (quarter === 3) {
            return 'III';
        }
        if (quarter === 4) {
            return 'IV';
        }
    }
}
