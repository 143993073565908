import { Component, OnInit } from "@angular/core";
import { Observable} from 'rxjs';
import { HotCartLineItemExtended, HotCartExtended } from 'apps/hot-b2b/src/app/shared/models';
import { select, Store } from '@ngrx/store';
import { AppState } from '@hot-b2b/store/reducers';
import { cartItems, cartData } from '@hot-b2b/store/cart/selector';
import { take} from 'rxjs/operators';
import { LoaderService } from 'apps/hot-b2b/src/app/shared/services/loader.service';

@Component({
    selector:'cash-payment-loader',
    templateUrl: './cash-payment-loader.component.html',
})

export class CashPaymentLoaderComponent implements OnInit{
    public cartItems$: Observable<HotCartLineItemExtended[]>;
    public cart$: Observable<HotCartExtended>;
    public cartItems: HotCartLineItemExtended[];
    public cartLineItems = [];
    public outletName;
    

    constructor(private readonly store: Store<AppState>, 
        private readonly loaderService: LoaderService) {
        this.cartItems$ = this.store.pipe(select(cartItems));
        this.cart$ = this.store.pipe(select(cartData));
    }
    
    ngOnInit(): void {
       this.loaderService.hide();
       this.cartItems$.pipe(take(1)).subscribe((cartItemData:HotCartLineItemExtended[])=>{
            cartItemData.forEach((element) => {
                let cartLineItem : any = {};
                cartLineItem.itemQuantity = element.quantity;
                cartLineItem.itemName = element.product.name;
                cartLineItem.itemPackageType = element.product.packageType;
                cartLineItem.itemUnitName = element.product.unitName;
                this.cartLineItems.push(cartLineItem);
            })
       })
       this.cart$.pipe(take(1)).subscribe((data)=>{
            this.outletName = data?.outletName;
       })
    }
}