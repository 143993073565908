import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsCurrencyFormat } from '@hot-b2b/store/settings/selector';
import { HotOrderLineItemExtended } from '@hot-libs/shared-models';
import { CurrencyFormat } from '@hot-theme-nx/generated-api';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
    selector: 'hot-distributor-order-item-price',
    templateUrl: './distributor-order-item-price.component.html',
})
export class DistributorOrderItemPriceComponent implements OnInit, OnChanges {
    @Input() public item: HotOrderLineItemExtended;
    @Input() public modifier: string;
    @Input() public isVansSalesMan = false;

    public currencyFormat$: Observable<CurrencyFormat>;

    public total: number;

    constructor(private readonly store: Store<AppState>, public readonly router: Router) {
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
    }

    public ngOnInit(): void {
        this.total = this.calculateTotal();
    }

    public ngOnChanges(): void {
        this.total = this.calculateTotal();
    }

    private calculateTotal(): number {
        if (this.isVansSalesMan && this.item?.invoiceExtendedPrice) {
            return this.item.invoiceExtendedPrice;
        }
        if (this.item.isReturnableEmpty) {
            let quantity = this.item.deliveredQuantity > 0 ? this.item.deliveredQuantity : this.item.quantity;
            return this.item.emptiesReturn * quantity;
        }
        return this.item.placedPrice * this.item.quantity;
    }
}
