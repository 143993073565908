import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { take, withLatestFrom, takeUntil } from 'rxjs/operators';

import {
    ApiConfiguration,
    HotApiCartsService,
    HotApiOrdersService,
    HotLineItem,
    HotOrder,
} from '@hot-theme-nx/generated-api';

import { FeaturesService } from '../../shared/services/features.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import {
    CartAddPendingChanges,
    CartClearChangedItems,
    CartClearProcessChanges,
    CartGetRecommendedProducts,
    CartGetSuccess,
    CartMovePendingToProcessChanges,
    CartUpdate,
    CartOfflineRemoveItem,
} from '@hot-b2b/store/cart/actions';
import { cartChangesBuffer, cartData, cartItems, cartItemsMin } from '@hot-b2b/store/cart/selector';
import { CatalogSynchronized, EmptiesCatalogSynchronized } from '@hot-b2b/store/catalog/actions';
import { InsightsSyncronized } from '@hot-b2b/store/insights/actions';
import { PromotionsSynchronized } from '@hot-b2b/store/promotions/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsStore } from '@hot-b2b/store/settings/selector';
import { PopularProductsSynchronized } from '@hot-b2b/store/widgets/actions';
import { LocalStorageService } from '@hot-libs/browser-specific';
import { isApplicationOnline } from '@hot-libs/helpers';
import { ProductsBufferModel, SettingsStoreModel } from '@hot-libs/shared-models';
import { StorageKeys } from '@hot-libs/shared-types';
import { HotProductExtended, ProductFunctionalityModel } from 'apps/hot-b2b/src/app/catalog/models';
import { OrderTemplateModel } from 'apps/hot-b2b/src/app/distributor/models';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { StoreService } from 'apps/hot-b2b/src/app/shared/services/store.service';
import uniqBy from 'lodash/uniqBy';
import { offlineCatalogProducts, catalogProducts } from '@hot-b2b/store/catalog/selector';
import _groupBy from 'lodash/groupBy';
import _find from 'lodash/find';
import _cloneDeep from 'lodash/cloneDeep';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    public readonly cartIsValid$ = new BehaviorSubject<boolean>(true);
    private timeoutId: any;

    private readonly settingsStore$: Observable<SettingsStoreModel>;
    private readonly cart$: Observable<HotCartExtended>;
    private readonly catalogOfflineProducts$: Observable<HotProductExtended[]>;
    private readonly subscriptionDestroy$ = new Subject<boolean>();
    public orderSaved = new Subject();
    public tokenDetail: any;
    public orderNo: any;
    public catalogProducts$: Observable<HotProductExtended[]>;
    public replaceLineItemsParams: HotApiCartsService.ReplaceLineItemsParams;
    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    private requestValidate = new Subject<any>();
    requestValidate$ = this.requestValidate.asObservable();
    constructor(
        private readonly httpClient: HttpClient,
        private readonly hotApiCartsService: HotApiCartsService,
        private readonly ordersApi: HotApiOrdersService,
        private readonly store: Store<AppState>,
        private readonly localStorageService: LocalStorageService,
        private readonly storeService: StoreService,
        private readonly apiConfiguration: ApiConfiguration,
        public readonly featureService: FeaturesService,
        private readonly configurationService: ConfigurationService
    ) {
        this.settingsStore$ = this.store.pipe(select(settingsStore));
        this.cart$ = this.store.pipe(select(cartData));
        this.catalogOfflineProducts$ = this.store.pipe(select(offlineCatalogProducts));
        this.catalogProducts$ = this.store.pipe(select(catalogProducts));
    }

    public validateRequest(): void {
        this.requestValidate.next(undefined);
    }

    public getCart(): Observable<HotCartExtended> {
        const isOnline: boolean = isApplicationOnline();
        if (isOnline) {
            return this.hotApiCartsService.getCart();
        } else {
            const cartStorageValue: string = localStorage.getItem(StorageKeys.offlineCart);
            if (cartStorageValue) {
                const cart: HotCartExtended = JSON.parse(cartStorageValue);
                return of(cart);
            }
            return of(null);
        }
    }

    public keepOfflineCartLineItems(): void {
        this.catalogOfflineProducts$
            .pipe(withLatestFrom(this.cart$), take(1))
            .subscribe(([offlineProducts, cart]: [HotProductExtended[], HotCartExtended]) => {
                cart.items.forEach((cartItem: HotCartLineItemExtended) => {
                    const offlineProduct: HotProductExtended = offlineProducts.find(
                        (product: HotProductExtended) => product.id === cartItem.productId
                    );
                    if (!offlineProduct) {
                        this.store.dispatch(new CartOfflineRemoveItem({ id: cartItem.productId }));
                    }
                });
            });
    }

    public addPreviousOrder(request: string): Observable<HotCartExtended> {
        return this.httpClient.post<HotCartExtended>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/orders/${request}/cart`,
            {}
        );
    }

    public addPreviousOrderAndCheckOut(request: string): Observable<HotCartExtended> {
        return this.httpClient.put<HotCartExtended>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/orders/${request}/cart`,
            {}
        );
    }

    public savePreviousOrder(request: string): Observable<OrderTemplateModel> {
        return this.httpClient.post<OrderTemplateModel>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/orders/${request}/template`,
            {}
        );
    }

    public addOrderTemplate(request: string): Observable<HotCartExtended> {
        return this.httpClient.post<HotCartExtended>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/order-templates/${request}/cart`,
            {}
        );
    }

    public addOrderTemplateAndCheckOut(request: string): Observable<HotCartExtended> {
        return this.httpClient.put<HotCartExtended>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/order-templates/${request}/cart`,
            {}
        );
    }

    public latestOrders(pageSize: number,startDate?: string, endDate?: string): Observable<HotOrder[]> {
        return this.ordersApi.getLatestOrders({"pageSize":pageSize, "startDate": startDate, "endDate" : endDate});
    }

    public manageProductsBuffer(productsBuffer: ProductsBufferModel, changesItem: ProductFunctionalityModel): void {
        if (changesItem) {
            const existingPendingItem: ProductFunctionalityModel = productsBuffer.pending.find(
                (item: ProductFunctionalityModel) => item.productId === changesItem.productId
            );
            if (existingPendingItem) {
                existingPendingItem.quantity = changesItem.quantity;
            } else {
                productsBuffer.pending.push(changesItem);
                localStorage.setItem('latestCart', changesItem.quantity.toString());
            }
        }
        this.store.dispatch(new CartAddPendingChanges(productsBuffer));

        if (typeof this.timeoutId === 'number') {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(this.processProducts.bind(this), 500, productsBuffer);
    }

    public processProducts(productsBuffer: ProductsBufferModel, isConsignment?: boolean): void {
        if (productsBuffer.processing.length) {
            return;
        }

        const selectedSupplier = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter));

        productsBuffer.processing = productsBuffer.processing.concat(productsBuffer.pending);
        productsBuffer.changedItems = productsBuffer.changedItems.concat(productsBuffer.processing);
        productsBuffer.pending = [];
        this.store.dispatch(new CartMovePendingToProcessChanges(productsBuffer));

        this.store.pipe(select(cartItems), take(1)).subscribe((items: HotCartLineItemExtended[]) => {
            this.productHop(items);
            const cartPayloadItems: HotLineItem[] = productsBuffer.changedItems.map(
                (item: ProductFunctionalityModel) => ({
                    productId: item.productId,
                    quantity: item.quantity,
                    packageTypeHop: item.packageTypeHop,
                    isSelected: item?.isSelected,
                    storeId: item?.storeId || selectedSupplier?.storeId || localStorage.getItem('storeId'),
                    isReturnableEmpty: item?.isReturnableEmpty,
                })
            );
            const currentItems = items
                .filter((item: HotCartLineItemExtended) => !item.isGift)
                .map((item: HotCartLineItemExtended) => ({
                    productId: item.productId,
                    storeId: item?.storeId || localStorage.getItem('storeId'),
                    isSelected: item?.isSelected,
                    quantity:
                        !this.featureService.UsePackagesWithCartsAndOrders && item.packageSize !== 0
                            ? Math.round(item.quantity * item.packageSize)
                            : item.quantity,
                    packageTypeHop: item.packageTypeHop,
                    isReturnableEmpty: item?.isReturnableEmpty,
                }));
            let withUniq: HotLineItem[] = uniqBy([...cartPayloadItems, ...currentItems], 'productId').filter(
                (product: HotLineItem) => product.quantity !== 0
            );

            if (this.featureService.SplitOrdersBySuppliersWhenCheckout) {
                withUniq = [];
                withUniq = this.splitOrderFeature(withUniq, cartPayloadItems, currentItems);
            }
            this.replaceLineItemsParams = { isConsignment: isConsignment, body: withUniq };
            this.hotApiCartsService.replaceLineItems(this.replaceLineItemsParams).subscribe((cart: HotCartExtended) => {
                productsBuffer.processing = [];
                this.store.dispatch(new CartClearProcessChanges(productsBuffer));
                this.store.pipe(select(cartChangesBuffer), take(1)).subscribe((buffer: ProductsBufferModel) => {
                    this.storeDispatch(buffer, cart);
                });
            });
        });
    }
    public splitOrderFeature(withUniq, cartPayloadItems, currentItems) {
        const mergeCartItems = [..._cloneDeep(cartPayloadItems), ..._cloneDeep(currentItems)];
        const cartGroupingByStoreId = _groupBy(mergeCartItems, 'storeId');

        for (const [, products] of Object.entries(cartGroupingByStoreId)) {
            const _withUniq = uniqBy(products, 'productId').filter((product: HotLineItem) => product.quantity !== 0);
            const withStatus = _withUniq.map((product: HotCartLineItemExtended) => {
                const _cartItem = _find(
                    currentItems,
                    (item: HotCartLineItemExtended) =>
                        item.productId === product.productId && item.storeId === product.storeId
                ) as HotCartLineItemExtended | undefined;

                if (_cartItem) {
                    return {
                        ...product,
                        isSelected: _cartItem?.isSelected,
                    };
                }

                product = this.selectedProduct(product);

                return product;
            });
            withUniq = [...withUniq, ...withStatus];
        }
        return withUniq;
    }
    public storeDispatch(buffer, cart) {
        if (!buffer.pending.length && !buffer.processing.length) {
            this.store.dispatch(new CartGetSuccess(cart));
            this.store.dispatch(new CatalogSynchronized(cart));
            this.store.dispatch(new EmptiesCatalogSynchronized(cart));
            this.store.dispatch(new PromotionsSynchronized(cart));
            this.store.dispatch(new InsightsSyncronized(cart));
            this.store.dispatch(new PopularProductsSynchronized(cart));
            this.store.dispatch(new CartGetRecommendedProducts());
            buffer.changedItems = [];
            this.store.dispatch(new CartClearChangedItems(buffer));
        }

        if (buffer.pending.length) {
            this.processProducts(buffer);
        }
    }
    public selectedProduct(product) {
        if (product.isSelected === undefined) {
            product.isSelected = true;
        }
        return product;
    }
    public productHop(items) {
        if (this.isHopB2b()) {
            this.catalogProducts$.pipe(takeUntil(this.subscriptionDestroy$)).subscribe((data) => {
                data.forEach((product) => {
                    items.forEach((lineItem) => {
                        if (product.id === lineItem.productId) {
                            lineItem.packageTypeHop = product.unitCurrent?.packageType;
                        }
                    });
                });
            });
        }
    }

    public clearStorageWithOffline(): void {
        this.localStorageService.removeItem(StorageKeys.offlineCart);
    }

    public sendOfflineOnlineProducts(): Observable<HotCartExtended> {
        this.replaceLineItemsParams = { body: this.storeService.getSelector(cartItemsMin) };
        return this.hotApiCartsService.replaceLineItems(this.replaceLineItemsParams);
    }

    public removeSelectedItemsCart(ids: HotLineItem[]): void {
        this.replaceLineItemsParams = { body: ids };
        this.hotApiCartsService.replaceLineItems(this.replaceLineItemsParams).subscribe((cart: HotCartExtended) => {
            this.store.dispatch(new CartGetSuccess(cart));
            this.store.dispatch(new CatalogSynchronized(cart));
            this.store.dispatch(new EmptiesCatalogSynchronized(cart));
            this.store.dispatch(new PromotionsSynchronized(cart));
            this.store.dispatch(new InsightsSyncronized(cart));
            this.store.dispatch(new PopularProductsSynchronized(cart));
        });
    }

    public calculateTaxesOffline(): void {
        this.settingsStore$
            .pipe(withLatestFrom(this.cart$), take(1))
            .subscribe(([storeSettings, cart]: [SettingsStoreModel, HotCartExtended]) => {
                cart.taxPercentRate = storeSettings.taxRate;
                if (storeSettings.taxRate > 0) {
                    cart.items.forEach((item: HotCartLineItemExtended) => {
                        cart.taxTotal += Math.round(item.extendedPrice * (storeSettings.taxRate / 100) * 100) / 100;
                    });
                    cart.total += cart.taxTotal;
                    this.store.dispatch(new CartUpdate(cart));
                }
            });
    }
}
