import { Component } from '@angular/core';
import { AuthenticationService } from '../../../account/services';

@Component({
    selector: 'hot-theme-nx-azure-login-failed',
    templateUrl: './azure-login-failed.component.html'
})
export class AzureLoginFailedComponent {
    constructor(private _authenticationService : AuthenticationService) {}

    azureFailedLogout() {
        this._authenticationService.logout();
    }

}
