import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AccordionItem } from '@hot-libs/shared-models';

@Component({
    selector: 'hot-accordion',
    templateUrl: './accordion.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
    @Input() public items: AccordionItem[];
    @Input() public multiExpand = true;
    @Input() public showMoreIsActive = true;

    public allItemsVisible: boolean;
    private readonly defaultVisibleItemsNumber = 5;

    public get isShowAllButtonVisible(): boolean {
        return this.items && this.items.length > this.defaultVisibleItemsNumber && !this.allItemsVisible;
    }

    public get visibleItems(): AccordionItem[] {
        if (this.allItemsVisible || !this.showMoreIsActive) {
            return this.items;
        } else {
            return this.items.slice(0, this.defaultVisibleItemsNumber);
        }
    }

    public constructor(private readonly router: Router) {}

    public toggleAll(state: boolean): void {
        this.allItemsVisible = state;
    }

    public toggleItem(targetItem: AccordionItem): void {
        if (this.multiExpand) {
            targetItem.isOpened = !targetItem.isOpened;
        } else {
            const state = targetItem.isOpened === undefined ? false : targetItem.isOpened;
            this.items.forEach((item: AccordionItem) => (item.isOpened = false));
            targetItem.isOpened = !state;
        }
    }

    public navigate(url: string): void {
        this.router.navigateByUrl(url);
    }
}
