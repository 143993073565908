import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CurrencyFormat, HotOutlet } from '@hot-theme-nx/generated-api';

import { PermissionsService } from '../../services/permissions.service';

import { AppState } from '@hot-b2b/store/reducers';
import { settingsStore } from '@hot-b2b/store/settings/selector';
import { HotOrderExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { CustomerPermissions, ModalIds } from '@hot-libs/shared-types';
import { FeaturesService, ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { authContactOutlet } from '@hot-b2b/store/auth/selector';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'hot-order-totals',
    templateUrl: './order-totals.component.html',
})
export class OrderTotalsComponent implements OnInit {
    @Input() public modifier: string;
    @Input() public order: HotOrderExtended;
    @Input() public settingsStore: SettingsStoreModel;
    @Input() public currencyFormat: CurrencyFormat;
    @Input() public isAppOnline: boolean;
    @Input() public showPalletTotal: boolean;
    public readonly contactOutlet$: Observable<HotOutlet>;
    public totalPallets = 0;

    public emptiesDepositReturnValue: number;
    public isEmptiesReturnVisible = false;
    public featureReturnEmpties = this.featuresService.ReturnEmpties;
    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public isVnB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';
    public isLAB2b = () => this.configurationService.getCurrentConfiguration() === 'RE';
    public isGRB2b = () => this.configurationService.getCurrentConfiguration() === 'GR';
    public featureOrderSimulationLA: boolean = this.featuresService.OrderSimulationPricesAndTaxes;

    public settingsStore$: Observable<SettingsStoreModel>;

    public haveReadPricingPermission: Observable<boolean> = this.permissionsService.checkPermission(
        CustomerPermissions.PricingRead
    );

    public childElementCount = (element: HTMLElement) => element.childElementCount;

    constructor(
        private readonly modalService: ModalService,
        private readonly permissionsService: PermissionsService,
        private readonly store: Store<AppState>,
        private readonly featuresService: FeaturesService,
        private readonly configurationService: ConfigurationService
    ) {
        this.contactOutlet$ = this.store.pipe(select(authContactOutlet));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
    }

    public ngOnInit(): void {
        this.order.promotionalDiscountAmount = this.order.couponDiscountAmount
            ? this.order.discountTotal - this.order.couponDiscountAmount
            : this.order.discountTotal;

        if (this.featureReturnEmpties) {
            if (!this.order.emptiesDepositTotal) {
                this.order.emptiesDepositTotal = 0;
            }

            if (!this.order.emptiesReturnTotal) {
                this.order.emptiesReturnTotal = 0;
            }

            this.emptiesDepositReturnValue = Math.abs(this.order.emptiesDepositTotal - this.order.emptiesReturnTotal);
            this.isEmptiesReturnVisible = this.order.emptiesDepositTotal <= this.order.emptiesReturnTotal;
        }
        this.calculatePallets();
    }

    public calculatePallets(): void {
        if (this.order) {
            this.totalPallets = 0;
            this.order.items.forEach((cartItem) => {
                let packagePerPallet = cartItem.product.packagesPerPallet;
                if (packagePerPallet) {
                    let quantity = cartItem.quantity;
                    let palletPerProduct = quantity / packagePerPallet;
                    this.totalPallets = this.totalPallets + palletPerProduct;
                }
            });
        }
    }

    public showTermsAndUseModal(): void {
        this.modalService.toggleModal(ModalIds.termsAndConditions, true);
    }
}
