import { Component, EventEmitter, Output } from '@angular/core';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { Observable } from 'rxjs';
import { AppState } from '@hot-b2b/store/reducers';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';

@Component({
    selector: 'hot-rewards-confirm',
    templateUrl: './rewards-confirm.component.html',
})
export class RewardsConfirmComponent {
    @Output() confirm: EventEmitter<any> = new EventEmitter();
    public hotSettings$: Observable<HotSettingsExtended>;
    public displayContactSupportPhone: boolean;
    public phoneNumber: string;
    public phone: string;

    public featureGotItIntegration: boolean = this.featuresService.GotItIntegration;

    constructor(
        private readonly store: Store<AppState>, 
        private readonly translateService: TranslateService,
        private readonly featuresService: FeaturesService,
    ) {
        this.hotSettings$ = this.store.pipe(select(settingsData));
    }
    public ngOnInit(): void {
        this.hotSettings$.subscribe((settings: HotSettingsExtended) => {
            this.displayContactSupportPhone = settings.support.displayContactSupportPhone;
            this.phoneNumber = settings.support.contactSupportPhone;
            if (!this.displayContactSupportPhone || this.featureGotItIntegration) {
                this.phone = this.translateService.instant('shared.modals.rewards.confirm.body');
            }
            else if(this.phoneNumber == undefined) {    
                    this.phone = this.translateService.instant('shared.modals.rewards.confirm.body');
            }
            else {
                    this.phone = this.translateService.instant('shared.modals.rewards.confirm.body') +
                    this.translateService.instant('shared.modals.rewards.confirm.body1', {
                        phoneNumber: this.phoneNumber,
                    });
            }
        });
    }
}
