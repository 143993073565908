export enum EOrdersActions {
    ORDERS_RESPONSE_PENDING = 'ORDERS_RESPONSE_PENDING',
    ORDERS_RESPONSE_FETCHED = 'ORDERS_RESPONSE_FETCHED',
    ORDERS_PREVIOUS_PENDING = 'ORDERS_PREVIOUS_PENDING',
    ORDERS_PREVIOUS_FETCHED = 'ORDERS_PREVIOUS_FETCHED',
    ORDERS_PREVIOUS_ERROR = 'ORDERS_PREVIOUS_ERROR',
    ORDERS_PREVIOUS_CLEAR = 'ORDERS_PREVIOUS_CLEAR',
    ORDERS_CHANGE_STATUS = 'ORDERS_CHANGE_STATUS',
    ORDERS_DEMO_DATA_SET = 'ORDERS_DEMO_DATA_SET',
    ORDERS_DEMO_DATA_CLEAR = 'ORDERS_DEMO_DATA_CLEAR',
    ORDERS_UPDATE_SEARCH_CRITERIA = 'ORDERS_UPDATE_SEARCH_CRITERIA',
    ORDERS_SET_DEFAULT_SEARCH_CRITERIA = 'ORDERS_SET_DEFAULT_SEARCH_CRITERIA',
    ORDERS_UPDATE_SEARCH_CRITERIA_SUCCESS = 'ORDERS_UPDATE_SEARCH_CRITERIA_SUCCESS',
    ORDERS_LAST_PENDING = 'ORDERS_LAST_PENDING',
    ORDERS_LAST_FETCHED = 'ORDERS_LAST_FETCHED',
    UPDATE_ORDER = 'UPDATE_ORDER',
    ORDER_SYNCHRONIZE = 'ORDER_SYNCHRONIZE',
    ORDERS_CUSTOMER_REJECT_REASONS = 'ORDERS_CUSTOMER_REJECT_REASONS',
    ORDERS_CUSTOMER_REJECT_REASONS_SUCCESS = 'ORDERS_CUSTOMER_REJECT_REASONS_SUCCESS',
    REJECT_ORDER_BY_CUSTOMER = 'REJECT_ORDER_BY_CUSTOMER',
    REJECT_ORDER_BY_CUSTOMER_SUCCESS = 'REJECT_ORDER_BY_CUSTOMER_SUCCESS',
    CONFIRM_ORDER_BY_CUSTOMER = 'CONFIRM_ORDER_BY_CUSTOMER',
    CONFIRM_ORDER_BY_CUSTOMER_SUCCESS = 'CONFIRM_ORDER_BY_CUSTOMER_SUCCESS',
    ORDER_SIMULATION_SUCCCES = 'ORDER_SIMULATION_SUCCCES',
    ORDER_SIMULATION_FAILURE = 'ORDER_SIMULATION_FAILURE',
    ORDER_SIMULATION_PENDING = 'ORDER_SIMULATION_PENDING',
    ORDER_SIMULATION_TAXCALCULATION = 'ORDER_SIMULATION_TAXCALCULATION',
    ORDER_SIMULATION_SUCCESSFUL_RESPONSE = 'ORDER_SIMULATION_SUCCESSFUL_RESPONSE',
    ORDER_SIMULATION_RESET = 'ORDER_SIMULATION_RESET',
    ORDER_SIMULATION_FETCHED = 'ORDER_SIMULATION_FETCHED',
}
