import { ProductService } from '@hot-theme-nx/common-api';

import { OrderTemplateItemModel } from '../../order-template/models';
import { OrderTemplateModel } from '../../order-template/models/order-template.model';

import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';

const updateOrderTemplate = (templates: OrderTemplateModel[], updatedTemplate: OrderTemplateModel) => {
    const isNewTemplate = !find(templates, { id: updatedTemplate.id });

    if (isNewTemplate) {
        templates.push(updatedTemplate);
    } else {
        templates.forEach((template: OrderTemplateModel) => {
            if (template.id === updatedTemplate.id) {
                template.name = updatedTemplate.name;
            }

            return template;
        });
        const updatedTemplateIndex: number = templates.findIndex(
            (template: OrderTemplateModel) => template.id === updatedTemplate.id
        );
        templates.splice(updatedTemplateIndex, 1);
        templates.unshift(updatedTemplate);
    }

    return templates;
};

const addNewOrderTemplate = (templates: OrderTemplateModel[], newTemplate: OrderTemplateModel) => {
    templates.unshift(newTemplate);
    return templates;
};

const calculateTemplateDiscountTotal = (template: OrderTemplateModel): OrderTemplateModel => {
    template.discountTotal = 0;

    template.items.forEach((item: OrderTemplateItemModel) => {
        template.discountTotal += item.discountTotal;
    });

    return template;
};

const normalizeItemsQuantity = (
    selectedTemplate: OrderTemplateModel,
    usePackagesWithCartsAndOrders: boolean
): OrderTemplateModel => {
    const mutatedTemplate = cloneDeep(selectedTemplate);

    if (!mutatedTemplate?.items) {
        return null;
    }

    mutatedTemplate.items.forEach((item) => {
        if (item.product?.firstQuantityStep) {
            item.firstQuantityStep = item.product.firstQuantityStep;
            item.secondQuantityStep = item.product.secondQuantityStep;
        }
    });

    mutatedTemplate.items = calculatePackagePart(mutatedTemplate.items, usePackagesWithCartsAndOrders);

    return mutatedTemplate;
};

const calculatePackagePart = (templateItems: OrderTemplateItemModel[], usePackagesWithCartsAndOrders: boolean) => {
    return templateItems.map((item: OrderTemplateItemModel) => {
        if (item.packageSize !== 0 && !usePackagesWithCartsAndOrders) {
            item.quantity = ProductService.calculateFractionalPart(item.quantity, item.packageSize);
        }
        return item;
    });
};

export { updateOrderTemplate, addNewOrderTemplate, calculateTemplateDiscountTotal, normalizeItemsQuantity };
