import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';

import {
    HotApiOutletRegistrationService,
    HotOutletSimplifiedRegistrationResult,
    StrictHttpResponse,
    HotApiCustomerSelfRegistrationService,
    HotApiOutletsService,
    HotOutletRegistrationResult
} from '@hot-theme-nx/generated-api';

import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { HotUserExtended, OutletRegistrationField } from '@hot-libs/shared-models';



import { AccountType, OutletRegistrationFieldType } from '@hot-libs/shared-types';
import { config } from 'apps/hot-b2b/src/environments/config';

const EMAIL_REGEXP = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
@Injectable({
    providedIn: 'root',
})
export class OutletRegistrationService {
    public outletRegistrationFields$ = new BehaviorSubject<OutletRegistrationField[]>(null);
    public removedChildContactId = new Subject<string>();

    private currentUser: HotUserExtended;

    constructor(
        private readonly store: Store<AppState>,
        private readonly hotApiOutletRegistrationService: HotApiOutletRegistrationService,
        private readonly hotApiCustomerRegistrationService: HotApiCustomerSelfRegistrationService,
        private readonly hotApiOutletsService: HotApiOutletsService
    ) {
        const settings$ = this.store.pipe(select(settingsData));
        const authUser$ = this.store.pipe(select(authUser));

        combineLatest([settings$, authUser$]).subscribe(([settings, user]: [HotSettingsExtended, HotUserExtended]) => {
            this.currentUser = user;

            const availableOutletRegistrationFields = this.getAvailableFieldsForUser(user, settings)?.map(
                (field: OutletRegistrationField) => {
                    if (!field.pattern) {
                        field.pattern = this.getValidatorPatternByFieldType(field.type, settings);
                    }

                    return field;
                }
            );
            this.outletRegistrationFields$.next(availableOutletRegistrationFields);
        });
    }

    public sendOutletRegistrationRequest(request: any): Observable<StrictHttpResponse<HotOutletRegistrationResult>> {
        if (!this.currentUser) {
            return this.hotApiOutletRegistrationService.createAnonymousUserRequestResponse(request);
        }

        if (this.currentUser.userType === AccountType.SalesRepresentative) {
            return this.hotApiOutletRegistrationService.createSalesRepresentativeRequestResponse(request);
        }

        return this.hotApiOutletRegistrationService.createGroupAccountRequestResponse(request);
    }

    public sendSimplifiedRegistrationRequest(request: any): Observable<HotOutletSimplifiedRegistrationResult> {
        return this.hotApiOutletRegistrationService.createSimplifiedRegistrationRequest(request);
    }

    private getAvailableFieldsForUser(user: HotUserExtended, settings: HotSettingsExtended): OutletRegistrationField[] {
        let availableFieldNames: string[];
        let availableFields: OutletRegistrationField[];
        const isCustomerSelfRegistrationEnabled = settings.isCustomerSelfRegistrationEnabled;

        if (!user) {
            availableFieldNames = settings.outletRegistrationFields.anonymousUserFields;
            availableFields = config.availableOutletRegistrationFields.anonymousUserFields;

            if (isCustomerSelfRegistrationEnabled) {
                availableFieldNames = settings.outletRegistrationFields.customerSelfRegistration;
                availableFields = config.availableOutletRegistrationFields.customerSelfRegistration;
            }
        } else if (user.userType === AccountType.SalesRepresentative) {
            availableFieldNames = settings.outletRegistrationFields.salesRepresentativeFields;
            availableFields = config.availableOutletRegistrationFields.salesRepresentativeFields;
        } else {
            availableFieldNames = settings.outletRegistrationFields.groupAccountFields;
            availableFields = config.availableOutletRegistrationFields.groupAccountFields;
        }

        return availableFields.filter((field: OutletRegistrationField) => availableFieldNames.includes(field.name));
    }

    private getValidatorPatternByFieldType(
        fieldType: OutletRegistrationFieldType,
        settings: HotSettingsExtended
    ): string {
        switch (fieldType) {
            case OutletRegistrationFieldType.Email:
                return EMAIL_REGEXP;
            case OutletRegistrationFieldType.Phone:
                return this.generatePhoneRegex(settings);
            default:
                return null;
        }
    }

    public generatePhoneRegex(settings: HotSettingsExtended): string {
        return settings.phoneNumberFormats.reduce((prevItem, item, index) => {
            const rules = item.split('|');
            const digitsCount = parseInt(rules[1]);

            if (rules[0].startsWith('+')) {
                rules[0] = '\\' + rules[0];
            }

            const pattern = `(^${rules[0]}\\d{${digitsCount}}$)`;

            return index === 0 ? pattern : `${prevItem}|${pattern}`;
        }, '');
    }
    public register(data: any): Observable<any> {
        return this.hotApiCustomerRegistrationService.register(data);
    }

    public getOutletByOuterId(outerId: string): Observable<any> {
        return this.hotApiOutletsService.getOutletByOuterId(outerId);
    }

    public verifyToken(data: any): Observable<any> {
        return this.hotApiCustomerRegistrationService.verifyTokenAsync(data);
    }

    public resendOtp(data: any): Observable<any> {
        return this.hotApiCustomerRegistrationService.resendOtpRequest(data);
    }
}
