import { Component, EventEmitter, Output } from '@angular/core';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { Location } from '@angular/common';

@Component({
    selector: 'hot-cannot-update-prices-modal',
    templateUrl: './cannot-update-prices-modal.component.html',
})
export class CannotUpdatePricesModal {
    @Output() public confirmBtnPress = new EventEmitter();
    @Output() public cancelBtnPress = new EventEmitter();

    constructor(private readonly modalService: ModalService, private location: Location) {}

    public closeModal() {
        console.log('closepopup2');
        this.cancelBtnPress.emit();
        this.modalService.toggleModal(ModalIds.CannotUpdatePricesModal, false);
    }

    public yesConfirm() {
        console.log('yes,placeorder');
        this.modalService.toggleModal(ModalIds.CannotUpdatePricesModal, false);
        this.confirmBtnPress.emit();
    }
}
