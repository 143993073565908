import { catalogInitialState } from '@hot-b2b/store/catalog/state';

import {
    editItemSubscription,
    mutableCatalogs,
    mutableCurrentCatalog,
    addAggregationsAndBrandsGroups,
    clearProductsInAllCatalogs,
    concatProducts,
    addOfflineProducts,
    updateCatalogItems,
    updateCatalogCategories,
    updateProducts,
} from '@hot-b2b/store/catalog/helpers';
import { ECatalogActions } from '@hot-b2b/store/catalog/types';
import { CatalogActions } from '@hot-b2b/store/catalog/actions';
import { CatalogState } from '@hot-b2b/store/catalog/model';

export function catalogReducer(state = catalogInitialState, action: CatalogActions): CatalogState {
    switch (action.type) {
        case ECatalogActions.CATALOG_SYNCHRONIZED_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    catalogs: mutableCurrentCatalog(state.data.catalogs, action.payload.cartItems, action.payload.ffc),
                },
            };
        }
        case ECatalogActions.CATALOG_SYNCHRONIZED_SUCCESS_WITH_STORE_ID: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    catalogs: mutableCurrentCatalog(
                        state.data.catalogs,
                        action.payload.cartItems,
                        action.payload.ffc,
                        true
                    ),
                },
            };
        }
        case ECatalogActions.CATALOG_ITEM_CHANGE_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    catalogs: editItemSubscription(state.data.catalogs, action.payload.itemId, action.payload.ffcId),
                },
            };
        }
        case ECatalogActions.CATALOG_ITEM_UPDATE: {
            return {
                ...state,
                fetched: false,
                pending: true,
                data: {
                    ...state.data,
                    catalogs: updateCatalogItems(state.data.catalogs, action.payload.ffcId, action.payload.product),
                },
            };
        }
        case ECatalogActions.EMPTIES_CATALOG_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    emptiesCatalogs: mutableCatalogs(action.payload),
                },
            };
        }
        case ECatalogActions.EMPTIES_CATALOG_SYNCHRONIZED_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    emptiesCatalogs: mutableCurrentCatalog(
                        state.data.emptiesCatalogs,
                        action.payload.cartItems,
                        action.payload.ffc
                    ),
                },
            };
        }
        case ECatalogActions.OFFLINE_CATALOG_PENDING:
        case ECatalogActions.OFFLINE_CATALOG_PENDING_FROM_CENTER: {
            return {
                ...state,
                pending: true,
                fetched: false,
                offlineCatalogFetched: false,
            };
        }
        case ECatalogActions.OFFLINE_CATALOG_FETCHED:
        case ECatalogActions.OFFLINE_CATALOG_FETCHED_FROM_CENTER: {
            return {
                ...state,
                pending: false,
                fetched: true,
                offlineCatalogFetched: true,
                data: {
                    ...state.data,
                    catalogs: addAggregationsAndBrandsGroups(action.payload),
                    offlineCatalogs: mutableCatalogs(action.payload),
                },
            };
        }
        case ECatalogActions.CATALOG_PRODUCTS_CLEAR: {
            return {
                ...state,
                pending: true,
                fetched: true,
                data: {
                    ...state.data,
                    catalogs: clearProductsInAllCatalogs(state.data.catalogs),
                },
            };
        }
        case ECatalogActions.CATALOG_PRODUCTS_SEARCH:
        case ECatalogActions.CATALOG_PRODUCTS_RELOAD: {
            return {
                ...state,
                pending: true,
                fetched: false,
            };
        }
        case ECatalogActions.CATALOG_PRODUCTS_SEARCH_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    catalogs: concatProducts(state.data.catalogs, action.payload),
                },
            };
        }
        case ECatalogActions.CATALOG_PRODUCTS_RELOAD_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    catalogs: updateProducts(state.data.catalogs, action.payload),
                },
            };
        }
        case ECatalogActions.OFFLINE_CATALOG_PRODUCTS_SEARCH_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    catalogs: addOfflineProducts(state.data.catalogs, state.data.offlineCatalogs, action.payload),
                },
            };
        }
        case ECatalogActions.CATALOG_APPLY_MULTI_FILTER: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    multiFilter: action.payload,
                },
            };
        }
        case ECatalogActions.CATALOG_CLEAR_MULTI_FILTER: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    multiFilter: catalogInitialState.data.multiFilter,
                },
            };
        }
        case ECatalogActions.CATALOG_CATEGORIES_SEARCH_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    catalogs: updateCatalogCategories(state.data.catalogs, action.payload),
                },
            };
        }

        case ECatalogActions.CATALOG_APPLY_ADVANCE_FILTER: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    advanceFilter: action.payload,
                },
            };
        }
        case ECatalogActions.CATALOG_CLEAR_ADVANCE_FILTER: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    advanceFilter: catalogInitialState.data.advanceFilter,
                },
            };
        }
        default:
            return state;
    }
}
