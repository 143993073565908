import { Component } from '@angular/core';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from '../../../services';

@Component({
    selector: 'hot-transition-confirmation-modal',
    templateUrl: './transition-confirmation-modal.component.html',
})
export class TransitionConfirmationModalComponent {
    constructor(private readonly modalService: ModalService) {}

    public agreeTransitionWithSave(transitionWithSave: boolean) {
        this.modalService.toggleModal(ModalIds.transitionConfirmation, false, {
            transitionWithSave: transitionWithSave,
        });
    }
}
