import { DOCUMENT } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    forwardRef,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { HotLanguage } from '@hot-theme-nx/generated-api';
import { HotUserExtended, ICalendarConfig, SettingsStoreModel } from '@hot-libs/shared-models';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsLanguageCurrent, settingsStore } from '@hot-b2b/store/settings/selector';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HotNxCalendarComponent } from '../hot-nx-calendar/hot-nx-calendar.component';
import { DateService } from '../../services/date.service';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { authUser } from '@hot-b2b/store/auth/selector';
import { ConfigurationService } from '../../services/configuration.service';
export const CALENDAR_WEEK_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HotNxCalendarComponent),
    multi: true,
};

@Component({
    selector: 'hot-week-calendar',
    templateUrl: './week-calendar.component.html',
    animations: [
        trigger('overlayAnimation', [
            state(
                'visible',
                style({
                    transform: 'translateY(0)',
                    opacity: 1,
                })
            ),
            state(
                'visibleTouchUI',
                style({
                    transform: 'translate(-50%,-50%)',
                    opacity: 1,
                })
            ),
            transition('void => visible', [
                style({ transform: 'translateY(5%)', opacity: 0 }),
                animate('{{showTransitionParams}}'),
            ]),
            transition('visible => void', [
                animate(
                    '{{hideTransitionParams}}',
                    style({
                        opacity: 0,
                        transform: 'translateY(5%)',
                    })
                ),
            ]),
            transition('void => visibleTouchUI', [
                style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)' }),
                animate('{{showTransitionParams}}'),
            ]),
            transition('visibleTouchUI => void', [
                animate(
                    '{{hideTransitionParams}}',
                    style({
                        opacity: 0,
                        transform: 'translate3d(-50%, -40%, 0) scale(0.9)',
                    })
                ),
            ]),
        ]),
    ],
    providers: [CALENDAR_WEEK_VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeekCalendarComponent extends HotNxCalendarComponent implements OnInit {
    @Input() public selectedDateOfWeek: moment.Moment;
    @Input() public isRequired: boolean;
    @Input() public config: ICalendarConfig;
    @Input() public maxDeliveryDaysInterval: number;
    @Output() public setDate = new EventEmitter<moment.Moment>();

    public settingsStore$: Observable<SettingsStoreModel>;
    public defaultConfig: ICalendarConfig;
    private unavailableDaysNumber: number;
    public displayDate = moment();
    public weekLocale: string;
    public user$: Observable<HotUserExtended>;
    overridePreferredWeek: any;
    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public isSA = () => this.configurationService.getCurrentConfiguration() === 'SA';
    constructor(
        private readonly store: Store<AppState>,
        @Inject(DOCUMENT) private readonly _document: Document,
        public readonly dateService: DateService,
        private readonly translateService: TranslateService,
        public el: ElementRef,
        public renderer: Renderer2,
        public cd: ChangeDetectorRef,
        public zone: NgZone,
        public readonly screenDimensionService: ScreenDimensionService,
        public readonly configurationService: ConfigurationService
    ) {
        super(el, renderer, cd, zone, screenDimensionService,configurationService);
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.currentLanguage$ = this.store.pipe(select(settingsLanguageCurrent));
        this.user$ = this.store.pipe(select(authUser));
    }

    public currentLanguage$: Observable<HotLanguage>;

    public ngOnInit(): void {
        this.user$.pipe(take(1)).subscribe((user: HotUserExtended) => {
            this.overridePreferredWeek = user.contact.outlet.overridePreferredDeliveryWeekStartsAfterWeeks;
        });
        this.settingsStore$.pipe().subscribe((storeSettings: SettingsStoreModel) => {
            if (!this.isHopB2b()) {
                this.unavailableDaysNumber = storeSettings.preferredDeliveryDateAsWeekOnCheckoutPage
                    ? storeSettings.preferredDeliveryWeekStartsAfterWeeksOnCheckoutPage * 7
                    : 0;
            } else if (storeSettings.preferredDeliveryDateAsWeekOnCheckoutPage) {
                if (this.overridePreferredWeek && this.overridePreferredWeek !== 0) {
                    this.unavailableDaysNumber = this.overridePreferredWeek * 7;
                } else {
                    this.unavailableDaysNumber = storeSettings.preferredDeliveryWeekStartsAfterWeeksOnCheckoutPage * 7;
                }
            }
            this.isWeekCalendarEnabled = storeSettings.preferredDeliveryDateAsWeekOnCheckoutPage;
            this.initConfig();
            this.initLocale();
        });
        let today = moment();
        this.defaultDate = today.add(this.unavailableDaysNumber, 'day').toDate();
        this.value = this.defaultDate.setDate(
            this.defaultDate.getDate() + 7 + this.locale.firstDayOfWeek - this.defaultDate.getDay()
        );
        this.mondayOfSelectedWeek = moment(this.defaultDate);
        this.selectDate({
            day: this.mondayOfSelectedWeek.date(),
            month: this.mondayOfSelectedWeek.month(),
            year: this.mondayOfSelectedWeek.year(),
            today: false,
            selectable: true,
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedDateOfWeek) {
            this.selectedDateOfWeek = this.dateService.getNextMondayDate(this.selectedDateOfWeek);
        }
    }

    private initConfig(): void {
        this.weekLocale = this.translateService.instant('shared.week-calendar.week');
        const minDate = this.getMinDate();
        this.minDate = minDate.toDate();
        if (this.locale) {
            this.locale.firstDayOfWeek = 1;
            this.createWeekDays();
        }
    }

    private getMinDate(): moment.Moment {
        let minDate = moment();
        minDate.second(0);
        minDate.millisecond(0);
        minDate.minute(0);
        minDate.hour(0);
        if (!!this.unavailableDaysNumber) {
            minDate = minDate.add(this.unavailableDaysNumber, 'day');
        }

        return this.dateService.getNextMondayDate(minDate);
    }

    private initLocale(): void {
        this.currentLanguage$.subscribe((language: HotLanguage) => {
            if (language) {
                this.config.locale = language.code;
                if (!!this.selectedDateOfWeek) {
                    this.selectedDateOfWeek.locale(language.code);
                }

                if (!!this.config.min) {
                    (this.config.min as moment.Moment).locale(language.code);
                }

                if (!!this.config.max) {
                    (this.config.max as moment.Moment).locale(language.code);
                }
            }
        });
    }
}
