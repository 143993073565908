import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ModalService } from '../../shared/services/modal.service';

import { AppState } from '@hot-b2b/store/reducers';
import { settingsStore } from '@hot-b2b/store/settings/selector';
import { HotBrandExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { OrderTemplateItemModel, OrderTemplateModel } from 'apps/hot-b2b/src/app/order-template/models';
import { ModalIds } from '@hot-libs/shared-types';
import { CacheClearService } from '../../shared/services/cache-clear.service';

@Injectable({
    providedIn: 'root',
})
export class OrderTemplateService {
    public settingsStore$: Observable<SettingsStoreModel>;
    public saveSuccess = new BehaviorSubject(false);

    constructor(
        private readonly http: HttpClient,
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly clearCache: CacheClearService
    ) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
    }

    public getOrderTemplates(): Observable<OrderTemplateModel[]> {
        const templatesResult = this.http.get<OrderTemplateModel[]>('/storefrontapi/hot/order-templates');

        return combineLatest([templatesResult, this.settingsStore$]).pipe(
            map(([templates, settings]: [OrderTemplateModel[], SettingsStoreModel]) => {
                templates.forEach((t: OrderTemplateModel) => {
                    t.status = 'Saved';
                    t.brands = this.getOrderTemplateBrands(t);
                    t.displayedTotalAmount = settings.showSubtotalInOrderList ? t.subTotal : t.total;
                });
                return templates;
            })
        );
    }

    private getOrderTemplateBrands(order: OrderTemplateModel): HotBrandExtended[] {
        const brands: HotBrandExtended[] = [];
        order.items.forEach((i: OrderTemplateItemModel) => {
            const existingBrand = brands.find((b: HotBrandExtended) => b.name === i.brandGroup);
            if (!existingBrand) {
                brands.push({ name: i.brandGroup, imageUrl: i.brandGroupImageUrl, url: '', active: false });
            }
        });
        return brands;
    }

    public deleteOrderTemplate(templateId: string): Observable<OrderTemplateModel> {
        return this.http.delete<OrderTemplateModel>(`/storefrontapi/hot/order-templates/${templateId}`);
    }

    public editOrderTemplate(templateId: string, items: any[]): Observable<OrderTemplateModel> {
        return this.http.put<OrderTemplateModel>(`/storefrontapi/hot/order-templates/${templateId}/items`, items).pipe(
            map((template: OrderTemplateModel) => {
                template.status = 'Saved';
                return template;
            })
        );
    }

    public openOrderTemplateModal() {
        this.modalService.toggleModal(ModalIds.orderTemplates, true);
    }

    public saveOrderTemplateSuccess(isSaved: boolean): void {
        this.saveSuccess.next(isSaved);
    }

    public recalculate(orderTemplate: OrderTemplateModel): OrderTemplateModel {
        orderTemplate.subTotal = 0;
        orderTemplate.emptiesDepositTotal = 0;
        orderTemplate.taxTotal = 0;
        const items: OrderTemplateItemModel[] = orderTemplate.items.filter(
            (item: OrderTemplateItemModel) => !item.isGift && item.isInStock
        );

        items.forEach((item: OrderTemplateItemModel) => {
            orderTemplate.subTotal += item.quantity * item.listPrice;
            orderTemplate.emptiesDepositTotal += item.quantity * (item.emptiesDeposit || 0);

            const price: number = item.salePrice === item.listPrice ? item.listPrice : item.salePrice;
            orderTemplate.taxTotal += item.quantity * price * item.taxPercentRate;
        });

        orderTemplate.total = orderTemplate.subTotal + orderTemplate.emptiesDepositTotal + orderTemplate.taxTotal;
        return orderTemplate;
    }

    public deleteOrderTemplateCache(): Promise<boolean> {
        const deleteURLs = [
            { 'dataGroupName': 'performance2', 'url': '/storefrontapi/hot/order-templates' }
        ];

        return this.clearCache.deleteCacheByAPIUrl(deleteURLs)
        .then(() => {return true;})
        .catch(() => {return false;});

    }
}
