import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HotLoyaltyStatement } from '@hot-theme-nx/generated-api';

import { LoyaltyService } from 'apps/hot-b2b/src/app/shared/services/loyalty.service';
import { loyalty } from '@hot-b2b/store/loyalty/selector';
import { AppState } from '@hot-b2b/store/reducers';

@Component({
    selector: 'hot-tier-status',
    templateUrl: './tier-status.component.html',
})
export class TierStatusComponent implements OnInit {
    public loyaltyStatement$: Observable<HotLoyaltyStatement>;
    public loyaltyTiersEnabled$: Observable<boolean>;

    constructor(private readonly store: Store<AppState>, public readonly loyaltyService: LoyaltyService) {
        this.loyaltyStatement$ = this.store.pipe(select(loyalty));
    }

    public ngOnInit(): void {
        this.loyaltyTiersEnabled$ = this.loyaltyService.loyaltyTiersEnable();
    }
}
