export enum OrderStatus {
    Saved = 'Saved',
    Received = 'Received',
    OnTheWay = 'On the way',
    Cancelled = 'Cancelled',
    Delivered = 'Delivered',
    Confirmed = 'Confirmed',
    SentToDistributor = 'Sent to distributor',
    ConfirmedDelivery = 'Confirmed delivery',
    NewOrder = 'New Order',
}
