import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, withLatestFrom } from 'rxjs/operators';

import {
    HotApiPriceManagementService,
    HotDistributorProductPrice,
    HotDistributorProductUserGroupPrice,
    CurrencyFormat,
} from '@hot-theme-nx/generated-api';

import { CurrencyFormatPipe } from '../../../pipes';
import { FeaturesService, ModalService, ProductService } from '../../../services';

import { selectModalData } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsCurrencyFormat } from '@hot-b2b/store/settings/selector';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { AccountType, ModalIds } from '@hot-libs/shared-types';
import { authUserType } from '@hot-b2b/store/auth/selector';

enum EPriceType {
    all = 'all-customers',
    subDistr = 'sub-distributor',
    outlet = 'outlet',
}

const numberRegEx = /-?\d*\.?\d{1,2}/ ;

@Component({
    selector: 'hot-theme-product-price-modal',
    templateUrl: './product-price-modal.component.html',
})
export class ProductPriceModalComponent implements OnInit, OnDestroy {
    @Input() public product;
    @Output() public changePrices = new EventEmitter<boolean>();

    public isUpLg = () => this.screenDimensionService.upLg();

    public dimensions: string;
    public readonly featureShowSku: boolean = this.featuresService.ShowDistributorLineItemSku;

    public initAllPrice: number;
    public initSubDistrPrice: number;
    public initOutletPrice: number;

    public tempAllPrice: number;
    public tempSubDistrPrice: number;
    public tempOutletPrice: number;

    public editedPriceType: EPriceType;
    public selectedMessageType: EPriceType;
    public showMessage: boolean;

    public editModeAllPriceEnable: boolean;
    public editModeASubDistrPriceEnable: boolean;
    public editModeOutletPriceEnable: boolean;

    public readonly authUserType$: Observable<string>;
    public accountType = AccountType;

    public pricesForm: UntypedFormGroup = new UntypedFormGroup({
        all: new UntypedFormControl('', [Validators.required, Validators.pattern(numberRegEx)]),
        subDistr: new UntypedFormControl('', Validators.required),
        outlet: new UntypedFormControl('', Validators.required),
    });

    public currencyFormat$: Observable<CurrencyFormat>;
    public currencyFormat: CurrencyFormat;

    private subscriptionDestroy$ = new Subject();

    constructor(
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly productService: ProductService,
        private readonly featuresService: FeaturesService,
        private readonly hotApiPriceManagementService: HotApiPriceManagementService,
        private readonly store: Store<AppState>,
        private currencyFormatPipe: CurrencyFormatPipe,
        private readonly modalService: ModalService
    ) {
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.authUserType$ = this.store.pipe(select(authUserType));
    }

    ngOnInit(): void {
        this.dimensions = this.productService.getProductMeasures(this.product.product);

        this.currencyFormat$
            .pipe(take(1), withLatestFrom(this.authUserType$))
            .subscribe(([currencyFormat, userType]) => {
                this.currencyFormat = currencyFormat;
                this.initTempPrices(this.product, currencyFormat);

                if (userType === this.accountType.SubDistributor) {
                    this.pricesForm.controls['all'].setValue(this.tempAllPrice);
                }
            });

        this.store
            .pipe(select(selectModalData(ModalIds.confirmationModal)), takeUntil(this.subscriptionDestroy$))
            .subscribe((data: any) => {
                if (data?.closeWithSave) {
                    this.saveChanges();
                }
            });

        this.pricesForm.controls['all'].valueChanges
            .pipe(takeUntil(this.subscriptionDestroy$), withLatestFrom(this.authUserType$))
            .subscribe(([formValue, userType]) => {
                if (userType === this.accountType.SubDistributor) {
                    this.tempAllPrice = formValue;
                    this.checkIsPricesChanged();
                }
            });
    }

    public saveChanges() {
        const powerOfTenForDecimalDigits = Math.pow(10, this.currencyFormat.decimalDigits);

        const productPrice: HotDistributorProductPrice = {
            productId: this.product.productId,
            userGroupPrices: [],
        };

        productPrice.priceForAllCustomers = +this.tempAllPrice ? this.tempAllPrice * powerOfTenForDecimalDigits : null;

        // TO DO: eliminate hard-coded user groups - use user groups from HotStoreSettings.distributorPricingManagementUserGroups instead

        if (+this.tempSubDistrPrice) {
            productPrice.userGroupPrices.push({
                userGroup: 'SubDistributors',
                price: this.tempSubDistrPrice * powerOfTenForDecimalDigits,
            });
        }

        if (+this.tempOutletPrice) {
            productPrice.userGroupPrices.push({
                userGroup: 'Outlets',
                price: this.tempOutletPrice * powerOfTenForDecimalDigits,
            });
        }

        this.hotApiPriceManagementService
            .updateProductPrices([productPrice])
            .pipe(take(1))
            .subscribe(() => {
                this.initAllPrice = this.tempAllPrice;
                this.initSubDistrPrice = this.tempSubDistrPrice;
                this.initOutletPrice = this.tempOutletPrice;

                this.product.priceForAllCustomers = productPrice.priceForAllCustomers;
                this.product.userGroupPrices = productPrice.userGroupPrices;

                this.checkIsPricesChanged();

                this.modalService.toggleModal(ModalIds.productPrice, false);
                this.modalService.toggleModal(ModalIds.confirmationModal, false);
                this.modalService.toggleModal(ModalIds.updatePriceModal, true);
            });
    }

    public isEditModeBusy(priceType: EPriceType): boolean {
        return (
            (this.editModeAllPriceEnable || this.editModeASubDistrPriceEnable || this.editModeOutletPriceEnable) &&
            this.editedPriceType !== priceType
        );
    }

    public setEditMode(priceType: EPriceType) {
        this.editedPriceType = priceType;

        switch (priceType) {
            case EPriceType.all:
                this.editModeAllPriceEnable = true;
                this.setAllPrice();
                break;

            case EPriceType.subDistr:
                this.editModeASubDistrPriceEnable = true;
                this.setSubDistrPrice();
                break;

            case EPriceType.outlet:
                this.editModeOutletPriceEnable = true;
                this.setOutletPrice();
                break;

            default:
                break;
        }

        this.focusFormControlElement(priceType);
    }

    public editPrice(priceType: EPriceType) {
        this.editedPriceType = priceType;

        switch (priceType) {
            case EPriceType.all:
                this.editModeAllPriceEnable = true;
                this.controlValueToEditedView(this.tempAllPrice, 'all');

                break;

            case EPriceType.subDistr:
                this.editModeASubDistrPriceEnable = true;
                this.controlValueToEditedView(this.tempSubDistrPrice, 'subDistr');

                break;

            case EPriceType.outlet:
                this.editModeOutletPriceEnable = true;
                this.controlValueToEditedView(this.tempOutletPrice, 'outlet');

                break;

            default:
                break;
        }

        this.focusFormControlElement(priceType);
    }

    public clearPrice(priceType: EPriceType) {
        switch (priceType) {
            case EPriceType.all:
                this.tempAllPrice = null;
                this.pricesForm.controls.all.setValue(null);

                break;

            case EPriceType.subDistr:
                this.tempSubDistrPrice = null;
                this.pricesForm.controls.subDistr.setValue(null);

                break;

            case EPriceType.outlet:
                this.tempOutletPrice = null;
                this.pricesForm.controls.outlet.setValue(null);

                break;

            default:
                break;
        }

        this.hideInfoMessage();
        this.checkIsPricesChanged();
    }

    public isPricesChanged(): boolean {
        return (
            +this.initAllPrice !== +this.tempAllPrice ||
            +this.initSubDistrPrice !== +this.tempSubDistrPrice ||
            +this.initOutletPrice !== +this.tempOutletPrice
        );
    }

    private initTempPrices(product: any, currencyFormat: CurrencyFormat) {
        const powerOfTenForDecimalDigits = Math.pow(10, currencyFormat.decimalDigits);

        const priceForAllCustomers = product.priceForAllCustomers;
        const priceForSubDistributors = product.userGroupPrices?.find(
            (userGroupPrice: HotDistributorProductUserGroupPrice) => userGroupPrice.userGroup === 'SubDistributors'
        )?.price;
        const priceForOutlets = product.userGroupPrices?.find(
            (userGroupPrice: HotDistributorProductUserGroupPrice) => userGroupPrice.userGroup === 'Outlets'
        )?.price;

        this.initAllPrice = !!priceForAllCustomers ? priceForAllCustomers / powerOfTenForDecimalDigits : null;
        this.initSubDistrPrice = !!priceForSubDistributors
            ? priceForSubDistributors / powerOfTenForDecimalDigits
            : null;
        this.initOutletPrice = !!priceForOutlets ? priceForOutlets / powerOfTenForDecimalDigits : null;

        this.tempAllPrice = this.initAllPrice;
        this.tempSubDistrPrice = this.initSubDistrPrice;
        this.tempOutletPrice = this.initOutletPrice;

        this.initFormControlsValue();
    }

    private initFormControlsValue() {
        this.controlValueToSimpleView(this.tempAllPrice, 'all');
        this.controlValueToSimpleView(this.tempSubDistrPrice, 'subDistr');
        this.controlValueToSimpleView(this.tempOutletPrice, 'outlet');
    }

    private setAllPrice() {
        if (this.pricesForm.controls.all.value) {
            this.tempAllPrice = this.pricesForm.controls.all.value;

            // Clear prices for specific user groups - price for all customers overrides these prices
            this.clearPricesForUserGroups();

            this.completeSetPrice();
            this.controlValueToSimpleView(this.tempAllPrice, 'all');
        } else {
            this.editModeAllPriceEnable = true;
        }
    }

    private setSubDistrPrice() {
        if (this.pricesForm.controls.subDistr.value) {
            this.tempSubDistrPrice = this.pricesForm.controls.subDistr.value;

            this.clearPriceForAllCustomers();

            this.completeSetPrice();
            this.controlValueToSimpleView(this.tempSubDistrPrice, 'subDistr');
        } else {
            this.editModeASubDistrPriceEnable = true;
        }
    }

    private setOutletPrice() {
        if (this.pricesForm.controls.outlet.value) {
            this.tempOutletPrice = this.pricesForm.controls.outlet.value;

            this.clearPriceForAllCustomers();

            this.completeSetPrice();
            this.controlValueToSimpleView(this.tempOutletPrice, 'outlet');
        } else {
            this.editModeOutletPriceEnable = true;
        }
    }

    private completeSetPrice() {
        this.checkIsPricesChanged();
        this.showInfoMessage();

        this.editedPriceType = null;
        this.editModeAllPriceEnable = false;
        this.editModeASubDistrPriceEnable = false;
        this.editModeOutletPriceEnable = false;
    }

    private clearPricesForUserGroups() {
        this.tempSubDistrPrice = null;
        this.pricesForm.controls.subDistr.setValue(null);

        this.tempOutletPrice = null;
        this.pricesForm.controls.outlet.setValue(null);
    }

    private clearPriceForAllCustomers() {
        this.tempAllPrice = null;
        this.pricesForm.controls.all.setValue(null);
    }

    private showInfoMessage() {
        this.selectedMessageType = this.editedPriceType;
        this.showMessage = true;
    }

    private hideInfoMessage() {
        this.showMessage = false;
        this.selectedMessageType = null;
    }

    private checkIsPricesChanged() {
        this.isPricesChanged() ? this.changePrices.emit(true) : this.changePrices.emit(false);
    }

    private controlValueToSimpleView(tempValue: number, controlName: string) {
        const powerOfTenForDecimalDigits = Math.pow(10, this.currencyFormat.decimalDigits);

        if (tempValue) {
            this.pricesForm.controls[controlName].setValue(
                this.currencyFormatPipe.transform(tempValue * powerOfTenForDecimalDigits, this.currencyFormat)
            );
        }
    }

    private controlValueToEditedView(tempValue: number, controlName: string) {
        this.pricesForm.controls[controlName].setValue(tempValue);
    }

    private focusFormControlElement(priceType: EPriceType) {
        const element = document.getElementById(priceType).querySelector('input');
        setTimeout(() => element.focus(), 0);
    }

    ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }
}
