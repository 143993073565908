import { NativeMobileAppDetailsAction } from './actions';
import { MobileAppState } from './model';
import { mobileAppDetailsInitialState } from './state';
import { MobielAppDetailssActions } from './type';

export function nativeMobileAppDetailsReducer(
    state: MobileAppState = mobileAppDetailsInitialState,
    action: NativeMobileAppDetailsAction
): MobileAppState {
    switch (action.type) {
        case MobielAppDetailssActions.UPDATE_APP_DETAILS: {
            return {
                ...state,
                fetched: true,
                data: action.payload,
            };
        }
        default:
            return state;
    }
}
