import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { HotUserExtended, Breadcrumb } from '@hot-libs/shared-models';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { ConfigurationService } from 'apps/hot-b2b/src/app/shared/services/configuration.service';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '@hot-b2b/store/reducers';
import { authUser } from '@hot-b2b/store/auth/selector';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbsService {
    public isUpLg = () => this.screenDimensionService.upLg();
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    public authUser$: Observable<HotUserExtended>;
    public isPopPilotUser: boolean = false;

    constructor(
        private readonly location: Location,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly configurationService: ConfigurationService,
        private readonly store: Store<AppState>,
    ) {
        this.authUser$ = this.store.pipe(select(authUser));
    }

    private isSelectDistributor: boolean;
    private isSelfRegistration: boolean;
    private isCustomerSelfRegistration: boolean;
    private isCreateAccount: boolean;
    private isCatalog: boolean;
    private isMassOrderUpload: boolean;
    private isKegsCatalog: boolean;
    private isKegsHistory: boolean;
    private isOpenInvoicesHistory: boolean;
    private isPromotions: boolean;
    private isPromotionHistory: boolean;
    private isInsights: boolean;
    private isInsightDetails: boolean;
    private lastUrlItem: string;
    private isCart: boolean;
    private isEmptiesReturned: boolean;
    private isThankYouPage: boolean;
    private isParentAccountManagement: boolean;
    private isProfileSettings: boolean;
    private isPaymentSuccess: boolean;
    private isCheckout: boolean;
    private isCheckoutPaymentMethod: boolean;
    private isCheckoutRetryPayment: boolean;
    private isFeedback: boolean;
    private isPerformance: boolean;
    private isOrderTemplates: boolean;
    private isPerformanceSdip: boolean;
    private isTlp: boolean;
    private isTlpOnGoing: boolean;
    private isTlpHistory: boolean;
    private isVouchers: boolean;
    private isMobilePushNotification: boolean;
    private isRewards: boolean;
    private isRewardsShop: boolean;
    private isRewardMechanics: boolean;
    private isRewardsHistory: boolean;
    private isRewardsVoucher: boolean;
    private isDistributorCatalog: boolean;
    private isDistributorPerformance: boolean;
    private isDistributorConfirmedOrders: boolean;
    private isDistributorNewOrders: boolean;
    private isDistributorPendingOrders: boolean;
    private isDistributorDispatchedOrders: boolean;
    private isDistributorCompletedOrders: boolean;
    private isDistributorManagePricingList: boolean;
    private isGroupAccountOrders: boolean;
    private isOrderSummary: boolean;
    private isCookiePolicy: boolean;
    private isOrdersPayOnDelivery: boolean;
    private isVsm: boolean;
    private isAccountView : boolean;
    private isOrderSuggestions: boolean;
    private isPromo: boolean;
    private isPromotionTrackHistory:boolean;
    private isPromotionProgramDetail :boolean;
    private isPromotionMechanics:boolean;

    public buildBreadcrumbs(customLabel?: string): Breadcrumb[] {
        const breadcrumbs: Breadcrumb[] = [{ label: 'shared.breadcrumbs.home', url: '/' }];
        const urlItems: string[] = this.location.path().split('/');
        this.isSelectDistributor = urlItems.indexOf('warehouses') >= 0;
        this.isSelfRegistration = urlItems.indexOf('self-registration') >= 0;
        this.isCustomerSelfRegistration = urlItems.indexOf('customer-self-registration') >= 0;
        this.isCreateAccount = urlItems.indexOf('create-account') >= 0;
        this.isCatalog = urlItems.indexOf('brands') >= 0;
        this.isMassOrderUpload = urlItems.indexOf('order-upload-template') >= 0;
        this.isKegsCatalog = urlItems.indexOf('kegs-catalog') >= 0;
        this.isKegsHistory = urlItems.indexOf('kegs-history') >= 0;
        this.isOpenInvoicesHistory = urlItems.indexOf('open-invoices-history') >= 0;
        this.isPromotions = urlItems.indexOf('promotions') >= 0;
        this.isPromotionHistory = this.findPromotionsHistory(urlItems);
        this.isOrderTemplates = urlItems.indexOf('order-templates') >= 0;
        this.isInsights = urlItems.indexOf('trends') >= 0;
        this.isInsightDetails = urlItems.includes('trends/');
        this.lastUrlItem = urlItems[urlItems.length - 1];
        this.isCart = urlItems.indexOf('cart') >= 0;
        this.isEmptiesReturned = urlItems.indexOf('empties') >= 0;
        this.isCheckout = urlItems.indexOf('checkout') >= 0;
        this.isCheckoutPaymentMethod = urlItems.indexOf('payment-method') >= 0;
        this.isCheckoutRetryPayment = urlItems.indexOf('retry-payment') >= 0;
        this.isProfileSettings = !!urlItems.filter((item: string) => item.indexOf('profile') >= 0).length;
        this.isThankYouPage = this.findThankYouPage(urlItems);
        this.isFeedback = urlItems.indexOf('feedback') >= 0;
        this.isPerformance = urlItems.indexOf('performance') >= 0;
        this.isPerformanceSdip = urlItems.indexOf('sdip') >= 0;
        this.isRewards = urlItems.indexOf('rewards') >= 0;
        this.isRewardsShop = urlItems.indexOf('shop') >= 0;
        this.isRewardMechanics = urlItems.indexOf('mechanics') >= 0;
        this.isRewardsHistory = urlItems.indexOf('history') >= 0;
        this.isRewardsVoucher = urlItems.indexOf('voucher') >= 0;
        this.isDistributorCatalog = this.findDistributorCatalog(urlItems);
        this.isDistributorNewOrders = this.findDistributorNewOrders(urlItems);
        this.isDistributorPendingOrders = this.findDistributorPendingOrders(urlItems);
        this.isDistributorConfirmedOrders = this.findDistributorConfirmedOrders(urlItems);
        this.isDistributorDispatchedOrders = this.findDistributorDispatchedOrders(urlItems);
        this.isDistributorCompletedOrders = this.findDistributorCompletedOrders(urlItems);
        this.isOrdersPayOnDelivery = urlItems.indexOf('pay-on-delivery-orders') >= 0;
        this.isPaymentSuccess = urlItems.indexOf('payment-successful') >= 0;
        this.isGroupAccountOrders = urlItems.indexOf('group-account-orders') >= 0;
        this.isOrderSummary = urlItems.indexOf('order-summary') >= 0;
        this.isCookiePolicy = urlItems.indexOf('cookie-policy') >= 0;
        this.isVsm = urlItems.indexOf('vsm') >= 0;
        this.isVouchers = urlItems.indexOf('vouchers') >= 0;
        this.isMobilePushNotification = urlItems.indexOf('notifications') >= 0;
        this.isTlp = urlItems.indexOf('tlp') >= 0;
        this.isTlpOnGoing = this.findTlpOngoing(urlItems);
        this.isTlpHistory = this.findTlpHistory(urlItems);
        this.isAccountView = urlItems.indexOf('account-view') >= 0;
        this.isOrderSuggestions = urlItems.indexOf('order-suggestions') >= 0;
        this.isPromo = urlItems.indexOf('promotion-tracking') >= 0;
        this.isPromotionTrackHistory = this.findPromotionTrackHistory(urlItems);
        this.isPromotionProgramDetail = urlItems.indexOf('program-details') >= 0;
        this.isPromotionMechanics = urlItems.indexOf('promotion-mechanics') >= 0;
        const isOrderDetails = () => {
            const ordersIndex: number = urlItems.indexOf('orders');
            return this.findOrderDetails(urlItems, ordersIndex);
        };
        this.isParentAccountManagement = urlItems.indexOf('parent-account-management') >= 0;
        this.isDistributorManagePricingList =
            urlItems.indexOf('distributor') >= 0 && urlItems.indexOf('manage-pricing') >= 0;
        this.isDistributorPerformance =
            urlItems.indexOf('distributor') >= 0 && urlItems.indexOf('performance-report') >= 0;
        const isOrderTracking = () => {
            const trackIndex: number = urlItems.indexOf('track-order');
            return this.findOrderTracking(trackIndex);
        };
        const orderInd: number = urlItems.indexOf('orders');
        const orderDetailsInd = urlItems[orderInd + 1];
        if (this.buildHelper1(breadcrumbs)) {
            return breadcrumbs;
        }
        if (this.buildHelper2(breadcrumbs, customLabel)) {
            return breadcrumbs;
        }
        if (this.buildHelper3(breadcrumbs, customLabel)) {
            return breadcrumbs;
        }
        if (this.buildHelper4(breadcrumbs)) {
            return breadcrumbs;
        }
        if (isOrderDetails && !isOrderTracking()) {
            breadcrumbs.pop();
            breadcrumbs.pop();
            breadcrumbs.push({ label: 'shared.breadcrumbs.orders', url: '/orders' });
        } else if (isOrderTracking) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.orders', url: '/orders' });
            breadcrumbs.push({ label: 'shared.breadcrumbs.order-details', url: `/orders/${orderDetailsInd}` });
            breadcrumbs.push({
                label: 'shared.breadcrumbs.track-order',
                url: `/orders/${orderDetailsInd}/track-order`,
            });
        } else {
            breadcrumbs.push({ label: `shared.breadcrumbs.${this.lastUrlItem}`, url: `/${this.lastUrlItem}` });
        }

        return breadcrumbs;
    }
    public findPromotionsHistory(urlItems: string[]) {
        if (urlItems.indexOf('promotions') >= 0 && urlItems.indexOf('history') >= 0) {
            return true;
        }
        return false;
    }
    public findPromotionTrackHistory(urlItems: string[]) {
        if (urlItems.indexOf('promotion-tracking') >= 0 && urlItems.indexOf('history') >= 0) {
            return true;
        }
        return false;
    }
    public findThankYouPage(urlItems: string[]) {
        if (urlItems.indexOf('thank-you') >= 0 || urlItems.indexOf('thank-you-page') >= 0) {
            return true;
        }
        return false;
    }
    public findDistributorCatalog(urlItems: string[]) {
        if (urlItems.indexOf('distributor') >= 0 && urlItems.indexOf('catalog') >= 0) {
            return true;
        }
        return false;
    }
    public findDistributorNewOrders(urlItems: string[]) {
        if (urlItems.indexOf('distributor') >= 0 && urlItems.indexOf('new-orders') >= 0) {
            return true;
        }
        return false;
    }
    public findDistributorPendingOrders(urlItems: string[]) {
        if (urlItems.indexOf('distributor') >= 0 && urlItems.indexOf('pending-orders') >= 0) {
            return true;
        }
        return false;
    }
    public findDistributorConfirmedOrders(urlItems: string[]) {
        if (urlItems.indexOf('distributor') >= 0 && urlItems.indexOf('confirmed-orders') >= 0) {
            return true;
        }
        return false;
    }
    public findDistributorDispatchedOrders(urlItems: string[]) {
        if (urlItems.indexOf('distributor') >= 0 && urlItems.indexOf('dispatched-orders') >= 0) {
            return true;
        }
        return false;
    }
    public findDistributorCompletedOrders(urlItems: string[]) {
        if (urlItems.indexOf('distributor') >= 0 && urlItems.indexOf('completed-orders') >= 0) {
            return true;
        }
        return false;
    }
    public findTlpOngoing(urlItems: string[]) {
        if (urlItems.indexOf('tlp') >= 0 && urlItems.indexOf('on-going') >= 0) {
            return true;
        }
        return false;
    }
    public findTlpHistory(urlItems: string[]) {
        if (urlItems.indexOf('tlp') >= 0 && urlItems.indexOf('history') >= 0) {
            return true;
        }
        return false;
    }
    public findOrderDetails(urlItems: string[], ordersIndex: number) {
        if (ordersIndex >= 0 && urlItems[ordersIndex + 1] !== '' && !this.isOrdersPayOnDelivery) {
            return true;
        }

        return false;
    }
    public findOrderTracking(trackIndex: number) {
        if (trackIndex >= 0) {
            return true;
        }
        return false;
    }
    public buildPerformance(breadcrumbs: Breadcrumb[]) {
        if (this.isPerformanceSdip) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.sdip', url: '/performance/sdip' });
        }
        if (this.isTlp) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.tlp', url: '/performance/tlp' });

            if (this.isTlpOnGoing) {
                breadcrumbs.push({ label: 'shared.breadcrumbs.tlp-on-going', url: '/performance/tlp/on-going' });
            } else if (this.isTlpHistory) {
                breadcrumbs.push({ label: 'shared.breadcrumbs.tlp-history', url: '/performance/tlp/history' });
            }
        }
        if(this.isPromo){
            breadcrumbs.push({ label: 'shared.breadcrumbs.performance-tracking', url: 'performance/promotion-tracking' });

            if(this.isPromotionProgramDetail){
                breadcrumbs.push({ label: 'shared.breadcrumbs.promotions-program-detail', url: 'performance/promotion-tracking/program-details' });
            }

            if(this.isPromotionMechanics){
                breadcrumbs.push({ label: 'shared.breadcrumbs.promotion-mechanics', url: 'performance/promotion-tracking/program-details/promotion-mechanics' });
            }
        }
    }
    public buildRewardsShop(breadcrumbs: Breadcrumb[]) {
        if (this.isRewardsShop) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.rewards-shop', url: '/rewards/shop' });
        }
        if (this.isRewardMechanics) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.rewards-mechanics', url: '/rewards/mechanics' });
        }
        if (this.isRewardsHistory) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.rewards-history', url: '/rewards/history' });
        }
        if (this.isRewardsVoucher) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.rewards-voucher', url: '/rewards/voucher' });
        }
    }
    public buildInsights(breadcrumbs: Breadcrumb[], customLabel: string) {
        if (this.lastUrlItem !== 'trends') {
            breadcrumbs.push({
                label: this.isUpLg() ? customLabel : 'shared.breadcrumbs.trends',
                url: this.isUpLg() ? `/trends/${this.lastUrlItem}` : '/trends',
            });
        }
    }
    public buildParentAccountManagement(breadcrumbs: Breadcrumb[]) {
        if (this.isUpLg()) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.profile', url: '/profile' });
            breadcrumbs.push({
                label: 'shared.breadcrumbs.manage-child-accounts',
                url: '/parent-account-management',
            });
        } else {
            breadcrumbs.push({
                label: 'shared.breadcrumbs.manage-child-accounts',
                url: '/parent-account-management',
            });
        }
    }
    public buildThankYouPage(breadcrumbs: Breadcrumb[]) {
        if (this.isPaymentSuccess) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.orders', url: '/orders' });
            breadcrumbs.push({
                label: 'shared.breadcrumbs.thank-you-payment-success',
                url: `/thank-you/${this.lastUrlItem}`,
            });
        } else {
            breadcrumbs.push({ label: 'shared.breadcrumbs.catalogue', url: '/brands/all' });
            breadcrumbs.push({ label: 'shared.breadcrumbs.thank-you', url: `/thank-you/${this.lastUrlItem}` });
        }
    }
    public buildDistributorConfirmedOrders(breadcrumbs:Breadcrumb[],customLabel:string){
        breadcrumbs.push({ label: 'shared.breadcrumbs.confirmed-orders', url: '' });
            if (customLabel === 'shared.breadcrumbs.confirm-delivery') {
                breadcrumbs.pop();
                breadcrumbs.push({ label: customLabel, url: '/orders' });
            }
    }

public buildHelper1(breadcrumbs: Breadcrumb[]): boolean {
        if (this.isSelectDistributor) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.warehouses', url: '/warehouses' });
        } else if (this.isSelfRegistration || this.isCustomerSelfRegistration || this.isCreateAccount) {
            breadcrumbs.push({ label: 'shared.back-button', url: '/' });
        } else if (this.isCatalog) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.catalogue', url: '/brands/all' });
        } else if (this.isMassOrderUpload) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.catalogue', url: '/brands/all' });
            breadcrumbs.push({ label: 'shared.breadcrumbs.mass-order-upload', url: '/order-upload-template' });
        } else if (this.isKegsCatalog) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.kegs-catalog', url: '/kegs-catalog' });
        } else if (this.isKegsHistory) {
            breadcrumbs.push({ label: 'shared.kegs-sidebar.kegHistory', url: '/kegs-history' });
        } else if (this.isOpenInvoicesHistory) {
            breadcrumbs.push({ label: 'shared.desktop-menu.open-invoices', url: '/open-invoices-history' });
        } else if (this.isPromotions) {
            if (this.isPromotionHistory) {
                breadcrumbs.push({ label: 'shared.breadcrumbs.promotion-history', url: '/promotions/history' });
            } else {
                breadcrumbs.push({ label: 'shared.breadcrumbs.promotions', url: '/promotions' });
            }
        } else {
            return false;
        }
        return true;
    }
    public buildHelper2(breadcrumbs: Breadcrumb[], customLabel: string): boolean {
        if (this.isInsights || this.isInsightDetails) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.trends', url: '/trends' });
            this.buildInsights(breadcrumbs, customLabel);
        } else if (this.isParentAccountManagement) {
            this.buildParentAccountManagement(breadcrumbs);
        } else if (this.isProfileSettings) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.profile', url: '/profile' });
        } else if (this.isCart) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.catalogue', url: '/brands/all' });
            breadcrumbs.push({ label: 'shared.breadcrumbs.cart', url: '/cart' });
        } else if (this.isEmptiesReturned) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.catalogue', url: '/brands/all' });
            breadcrumbs.push({ label: 'shared.breadcrumbs.cart', url: '/cart' });
            breadcrumbs.push({ label: 'shared.breadcrumbs.empties', url: '/empties' });
        } else if (this.isThankYouPage) {
            this.buildThankYouPage(breadcrumbs);
        }
        else if (this.isCheckoutRetryPayment) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.newcheckout', url: `/retry-payment/${this.lastUrlItem}` });
        }
        else if (this.isCheckout) {
           this.buildNewCheckout(breadcrumbs);  
        } else {
            return false;
        }
        return true;
    }

    public buildNewCheckout(breadcrumbs: Breadcrumb[]){
        this.authUser$.subscribe((userData: HotUserExtended) => { 
            this.isPopPilotUser = userData?.isPopPilotUser;       
        });
        if (this.isPopPilotUser) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.newcheckout', url: '/checkout' });
        } 
        else {
            breadcrumbs.push({ label: 'shared.breadcrumbs.catalogue', url: '/brands/all' });
            breadcrumbs.push({ label: 'shared.breadcrumbs.cart', url: '/cart' });
            breadcrumbs.push({ label: 'shared.breadcrumbs.checkout', url: '/checkout' });
            if (this.isCheckoutPaymentMethod) {
            breadcrumbs.push({ label: 'cart.payment-method.title', url: '/checkout' });
            }
        }
    }
    public buildHelper3(breadcrumbs: Breadcrumb[], customLabel: string): boolean {
        if (this.isOrderTemplates) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.order-templates', url: '/order-templates' });
        } else if (this.isFeedback) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.feedback', url: '/feedback' });
        } else if (this.isPerformance) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.performance', url: '/performance' });
            this.buildPerformance(breadcrumbs);
        } else if (this.isVouchers) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.catalogue', url: '/brands/all' });
            breadcrumbs.push({ label: 'shared.breadcrumbs.cart', url: '/cart' });
            breadcrumbs.push({ label: 'shared.breadcrumbs.vouchers', url: '/vouchers' });
        } else if (this.isMobilePushNotification) {
            breadcrumbs.push({
                label: this.isUpLg() ? customLabel : 'shared.breadcrumbs.notifications',
                url: this.isUpLg() ? `/notifications/${this.lastUrlItem}` : '/notifications',
            });
        } else if (this.isRewards) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.rewards', url: '/rewards' });
            this.buildRewardsShop(breadcrumbs);
        } else if (this.isDistributorCatalog) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.distributor-catalog', url: '' });
        } else if (this.isDistributorPerformance) {
            breadcrumbs.push({
                label: 'shared.breadcrumbs.performance-report',
                url: '/distributor/performance-report',
            });
        } else if (this.isDistributorConfirmedOrders) {
            this.buildDistributorConfirmedOrders(breadcrumbs,customLabel);
        } else {
            return false;
        }
        return true;
    }
    public buildHelper4(breadcrumbs: Breadcrumb[]): boolean {
        if (this.isDistributorNewOrders) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.new-orders', url: '' });
        } else if (this.isDistributorPendingOrders) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.pending-orders', url: '' });
        } else if (this.isDistributorDispatchedOrders) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.dispatched-orders', url: '' });
        } else if (this.isDistributorCompletedOrders) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.completed-orders', url: '' });
        } else if (this.isDistributorManagePricingList) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.distributor-catalog', url: '/distributor/catalog' });
            breadcrumbs.push({ label: 'shared.breadcrumbs.manage-pricing-list', url: '/distributor/manage-pricing' });
        } else if (this.isGroupAccountOrders) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.group-order', url: '/group-account-orders' });
        } else if (this.isOrderSummary) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.order-summary', url: '/order-summary' });

        } else if (this.isAccountView) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.account-view', url: '/account-view' });
        } else if(this.isOrderSuggestions){
            breadcrumbs.push({label: 'shared.breadcrumbs.order-suggestions', url: '/order-suggestions'})
        }
        else if (this.isCookiePolicy) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.cookie-policy', url: '/cookie-policy' });
        } else if (this.isOrdersPayOnDelivery) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.pay-on-delivery', url: '/pay-on-delivery-orders' });
        } else if (this.isVsm) {
            breadcrumbs.push({ label: 'shared.breadcrumbs.confirmed-orders', url: '/vsm' });
        } else {
            return false;
        }
        return true;
    }
}
