import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

import { HotSettings } from '@hot-theme-nx/generated-api';

@Injectable({
    providedIn: 'root',
})
export class FormValidationsService {

    // What is it? It is the same as angular 'Required' validator
    static fieldIsEmpty(control: AbstractControl) {
        if (!control.value && !control.pristine) {
            control.setErrors({ isEmpty: true });
            return {
                isEmpty: true,
            };
        } else {
            return null;
        }
    }

    /**
     * TIN(INN) validator
     * @param lengthsEqual - lengths digit 
     * @param errorMessageKey - translate key
     */
    static tin(lengthsEqual: number[], errorMessageKey: string) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value || !lengthsEqual || !lengthsEqual.length) {
                return null;
            }

            const isValid = lengthsEqual.includes(control.value.length);
            const displayErrorMsgKey = errorMessageKey
                ? { tinMessageKey: errorMessageKey }
                : ({ tin: true } as ValidationErrors);

            return isValid ? null : displayErrorMsgKey;
        };
    }

    /**
     * RegExpPattern validator
     * @param pattern - RegExp string. Example string value: '/^([^0-9]*)$/'
     * @param errorMessageKey - translate key
     */
    static customRegExpPattern(pattern: RegExp, errorMessageKey: string) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && !control.value.match(pattern)) {
                return errorMessageKey
                    ? { customRegExpPatternMessageKey: errorMessageKey }
                    : ({ customRegExpPattern: true } as ValidationErrors);
            } else {
                return null;
            }
        };
    }

    static simpleFormValidation(group: UntypedFormGroup): ValidationErrors {
        const invalidControls = [];
        Object.keys(group.controls).forEach((key) => {
            if (!group.controls[key].value && group.controls[key].pristine) {
                invalidControls.push(key);
            }
        });
        return invalidControls.length ? { emptyFields: true } : null;
    }

    static passwordValidationByRegexps(passwordRequirements: any, password: string) {
        if (passwordRequirements) {
            if (passwordRequirements.requiredLength.value) {
                passwordRequirements.requiredLength.hasError = !password.match(
                    `.{${passwordRequirements.requiredLength.value},}`
                );
            }
            if (passwordRequirements.requireUppercase.value) {
                passwordRequirements.requireUppercase.hasError = !new RegExp(/[A-Z]/).exec(password);
            }
            if (passwordRequirements.requireLowercase.value) {
                passwordRequirements.requireLowercase.hasError = !new RegExp(/[a-z]/).exec(password);
            }
            if (passwordRequirements.requireDigit.value) {
                passwordRequirements.requireDigit.hasError = !new RegExp(/\d/).exec(password);
            }
            if (passwordRequirements.requireNonAlphanumeric.value) {
                passwordRequirements.requireNonAlphanumeric.hasError = !new RegExp(/[^a-zA-Z0-9_\s]/).exec(password);
            }

            return passwordRequirements;
        }
    }

    public static matchingValidator(controlName: string, matchingControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (control.pristine || matchingControl.pristine) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ notMatch: true });
                control.setErrors({ notMatch: true });
            } else {
                matchingControl.setErrors(null);
                control.setErrors(null);
            }
        };
    }

    public static matchingEmailValidator(controlName: string, matchingControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.notMatch) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ notMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    static getUniqueCharsCount(s: string) {
        return s.split('').filter((v, i, a) => a.indexOf(v) === i).length;
    }

    static initPasswordValidationRegexps(settings: HotSettings, regexps: {}) {
        const passwordOptions = settings?.passwordOptions;

        if (passwordOptions) {
            if (passwordOptions.requiredLength) {
                regexps['tooShort'] = `.{${passwordOptions.requiredLength},}`;
            }

            if (passwordOptions.requireUppercase) {
                regexps['noUpperSymbols'] = `(?=.*[A-Z])`;
            }

            if (passwordOptions.requireLowercase) {
                regexps['noLowerSymbols'] = `(?=.*[a-z])`;
            }

            if (passwordOptions.requireDigit) {
                regexps['noDigits'] = `(?=.*[0-9])`;
            }

            if (passwordOptions.requireNonAlphanumeric) {
                regexps['noSpecialCharacters'] = `(?=.*[!\\]@#$%^&|<>/:;.,"'(){}[*\`?_=\\\\+~-])`;
            }
        }
    }
}
