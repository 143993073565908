import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'apps/hot-b2b/src/app/app.module';
import { environment } from 'apps/hot-b2b/src/environments/environment';

import 'zone.js';
import 'hammerjs';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
        if ('serviceWorker' in navigator && environment.production && environment.pwa) {
            navigator.serviceWorker.register('/sw-main.js').then(
                (registration: ServiceWorkerRegistration) => {
                    console.log('Service Worker registration successful with scope: ', registration.scope);
                },
                (err: any) => {
                    console.log('Service Worker registration failed: ', err);
                }
            );

            navigator.serviceWorker.ready.then(() => {
                console.log('Service Worker ready');
                askForPermission();
            });
        }
    })
    .catch((err: any) => console.error(err));

function askForPermission() {
    if ('Notification' in window) {
        Notification.requestPermission(function (result) {
            console.log('Permissions', result);
        });
    }
}
