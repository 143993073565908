import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CurrencyFormat, HotPaymentMethod } from '@hot-theme-nx/generated-api';

import { RouteGuardModel, SettingsStoreModel } from '@hot-libs/shared-models';
import { FeatureNames } from '@hot-libs/shared-types';
import { AppState } from '@hot-b2b/store/reducers';
import {
    ContactSupport,
    FeaturesItem,
    RoutesFeature,
    SettingsFFC,
    SettingsState,
    SettingsStoreSlots,
} from '@hot-b2b/store/settings/model';

export const stateSettings = (state: AppState) => state.settings;

const getWithFfc = (id: string, data: SettingsStoreSlots[], key: string) => {
    const getDataByKey = (withData: SettingsStoreSlots) => {
        return key ? withData.settings[key] : withData.settings;
    };

    if (data.length) {
        if (!!id) {
            const storeSettings = data.find((item: SettingsStoreSlots) => item.id === id);

            if (!storeSettings) {
                return getDataByKey(data[0]);
            }

            return getDataByKey(storeSettings);
        }

        return getDataByKey(data[0]);
    }

    return null;
};

export const settingsPending = createSelector(stateSettings, (state: SettingsState) => state.pending);

export const settingsFetched = createSelector(stateSettings, (state: SettingsState) => state.fetched);

export const settingsData = createSelector(stateSettings, (state: SettingsState) => state.data);

export const applicationInsightsInstrumentationKey = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.applicationInsightsInstrumentationKey
);

export const settingsLanguages = createSelector(stateSettings, (state: SettingsState) => state.data.availableLanguages);

export const settingsPasswordOptions = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.passwordOptions
);

export const settingsLanguageCurrent = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.currentLanguage
);

export const settingsFeatures = createSelector(stateSettings, (state: SettingsState) => state?.data?.features);

export const settingsStorefrontVersion = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.storefrontVersion
);

export const settingsBetaTesting = createSelector(stateSettings, (state: SettingsState) => state?.data?.betaTesting);

export const settingsIOSDownloadLink = createSelector(
    stateSettings,
    (state: SettingsState) => state?.data?.iosDownloadLink
);

export const settingsAndroidDownloadLink = createSelector(
    stateSettings,
    (state: SettingsState) => state?.data?.androidDownloadLink
);

export const settingsEnablePhoneNumberSelfRegistrationAsReferralCode = createSelector(
    stateSettings,
    (state: SettingsState) => state?.data?.enablePhoneNumberSelfRegistrationAsReferralCode
);

export const pricingIndicatorIndexes = createSelector(
    stateSettings,
    (state: SettingsState) => state?.data?.pricingIndicatorIndexes
);

const routesFeatures: RoutesFeature[] = [
    {
        name: FeatureNames.Insights,
        url: '/trends',
        redirect: '/',
        showNavLink: false,
    },
    {
        name: FeatureNames.BrandsPage,
        url: '/brands',
        redirect: '/brands/all',
        showNavLink: true,
    },
    {
        name: FeatureNames.OrderTemplates,
        url: '/order-templates',
        redirect: '/',
        showNavLink: false,
    },
    {
        name: FeatureNames.LoyaltyProgram,
        url: '/rewards',
        redirect: '/',
        showNavLink: false,
    },
    {
        name: FeatureNames.LoyaltyProgram,
        url: '/rewards/shop',
        redirect: '/',
        showNavLink: false,
    },
    {
        name: FeatureNames.LoyaltyProgram,
        url: '/rewards/mechanics',
        redirect: '/',
        showNavLink: false,
    },
    {
        name: FeatureNames.LoyaltyProgram,
        url: '/rewards/history',
        redirect: '/',
        showNavLink: false,
    },
    {
        name: FeatureNames.PerformancePage,
        url: '/performance',
        redirect: '/',
        showNavLink: false,
    },
    {
        name: FeatureNames.ConfirmedOrdersPageForDistributor,
        url: '/distributor/confirmed-orders',
        redirect: '/',
        showNavLink: false,
    },
    {
        name: FeatureNames.OrdersPageForGroupAccount,
        url: '/group-account-orders',
        redirect: '/',
        showNavLink: false,
    },
    {
        name: FeatureNames.OrdersStatisticsForGroupAccount,
        url: '/order-summary',
        redirect: '/',
        showNavLink: false,
    },
    {
        name: FeatureNames.DashboardHomePage,
        url: '/ordering',
        redirect: '/',
        showNavLink: false,
    },
    {
        name: FeatureNames.EnableViewAllCoupons,
        url: '/vouchers',
        redirect: '/',
        showNavLink: false,
        requiredNames: [FeatureNames.Coupons],
    },
];

export const settingsFeaturesRoutes: MemoizedSelector<AppState, RouteGuardModel[]> = createSelector(
    stateSettings,
    (state: SettingsState) => {
        const routes: RouteGuardModel[] = [];
        state.data.features.forEach((feature: FeaturesItem) => {
            routesFeatures.forEach((item: RoutesFeature) => {
                if (feature.name === item.name && !feature.isActive) {
                    routes.push({ url: item.url, redirect: item.redirect });
                }
                if (item.requiredNames?.length) {
                    item.requiredNames.forEach((extraName: string) => {
                        if (feature.name === extraName && !feature.isActive) {
                            routes.push({ url: item.url, redirect: item.redirect });
                        }
                    });
                }
            });
        });
        return routes;
    }
);

export const settingsFeaturesLinks = createSelector(stateSettings, (state: SettingsState) => {
    const routes: string[] = [];
    state.data.features.forEach((feature: FeaturesItem) => {
        routesFeatures.forEach((item: RoutesFeature) => {
            if (feature.name === item.name && !item.showNavLink && !feature.isActive) {
                routes.push(item.url);
            }
        });
    });
    return routes;
});

export const settingsLanguagesUser = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.isLanguagesLoaded
);

export const settingsStoreSessionTimeout = createSelector(stateSettings, (state: SettingsState) =>
    getWithFfc(state.data.ffc.id, state.data.settingsStore, 'inactiveSessionTimeout')
);

export const settingsEnabledEmailOrderNotifications = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.enableEmailOrderNotifications
);

export const settingsEnabledSmsOrderNotifications = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.enableSmsOrderNotifications
);

export const minimumOrderQuantitySelector = createSelector(
    stateSettings,
    (state: SettingsState) =>
        state.data.settingsStore.map((settings: SettingsStoreSlots) => settings.settings.minimumOrderQuantity)[0]
);

export const settingsStoreOrigin: MemoizedSelector<AppState, SettingsStoreSlots[]> = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.settingsStore
);

export const settingsStore: MemoizedSelector<AppState, SettingsStoreModel> = createSelector(
    stateSettings,
    (state: SettingsState) => getWithFfc(state.data.ffc.id, state.data.settingsStore, '')
);

export const settingsCurrencyFormat: MemoizedSelector<AppState, CurrencyFormat> = createSelector(
    stateSettings,
    (state: SettingsState) => getWithFfc(state.data.ffc.id, state.data.settingsStore, 'currencyFormat')
);

export const settingsLoyaltyCurrencyFormat: MemoizedSelector<AppState, CurrencyFormat> = createSelector(
    stateSettings,
    (state: SettingsState) => getWithFfc(state.data.ffc.id, state.data.settingsStore, 'loyaltyCurrencyFormat')
);

export const settingsAppOnLine: MemoizedSelector<AppState, boolean> = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.online
);

export const settingsPaymentMethods: MemoizedSelector<AppState, HotPaymentMethod[]> = createSelector(
    stateSettings,
    (state: SettingsState) => getWithFfc(state.data.ffc.id, state.data.settingsStore, 'paymentMethods')
);

export const settingsStoreShowProductExtendedPrice: MemoizedSelector<AppState, boolean> = createSelector(
    stateSettings,
    (state: SettingsState) => getWithFfc(state.data.ffc.id, state.data.settingsStore, 'showProductExtendedPrice')
);

export const settingsFFC: MemoizedSelector<AppState, SettingsFFC> = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.ffc
);

export const settingsFFCId: MemoizedSelector<AppState, string> = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.ffc.id
);

export const settingsSupport: MemoizedSelector<AppState, ContactSupport> = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.support
);
export const settingsSkipDistributorPage: MemoizedSelector<AppState, boolean> = createSelector(
    stateSettings,
    (state: SettingsState) => state.data.skipDistributorSelection
);

