import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ModalService } from './../../../services';

import { ModalIds } from '@hot-libs/shared-types';

@Component({
    selector: 'hot-clear-cart-confirmation-modal',
    templateUrl: './clear-cart-confirmation-modal.component.html',
})
export class ClearCartConfirmationModalComponent {
    @Input() public innerHtmlTitle: string;
    @Input() public innerHtmlContent: string;
    @Input() public isOrderTemplate: boolean;
    @Input() promotionType?:string
    @Output() public clearCart = new EventEmitter();
    @Output() public resetQuantityCount = new EventEmitter();

    constructor(private readonly modalService: ModalService) {
    }

    public close(): void {
        this.modalService.toggleModal(ModalIds.clearCartConfirmation, false);
        this.modalService.toggleModal(ModalIds.clearCartConfirmationSingle, false);
        this.modalService.toggleModal(ModalIds.clearCartConfirmationConsignment, false);
    }
}
