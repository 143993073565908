import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, withLatestFrom } from 'rxjs/operators';

import { isTimeout } from '@hot-libs/helpers';
import { StorageKeys } from '@hot-libs/shared-types';

import { AuthenticationService } from 'apps/hot-b2b/src/app/account/services';
import { authLoggedIn } from '@hot-b2b/store/auth/selector';
import { NotificationsGet } from '@hot-b2b/store/notifications/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine, settingsStoreSessionTimeout } from '@hot-b2b/store/settings/selector';

import { StoreService } from './store.service';

const CHECK_INTERVAL = 15000; // in ms
const UPDATE_NOTIFICATION_INTERVAL = 500000;

@Injectable({
    providedIn: 'root',
})
export class TimerService {
    public getLastAction(): number {
        return parseInt(localStorage.getItem(StorageKeys.lastAction), 10);
    }

    public setLastAction(lastAction: number): void {
        localStorage.setItem(StorageKeys.lastAction, lastAction.toString());
    }

    constructor(
        private readonly store: Store<AppState>,
        private readonly storeService: StoreService,
        private readonly authenticationService: AuthenticationService
    ) {
        this.check();
        this.initInterval();
        localStorage.setItem(StorageKeys.lastAction, Date.now().toString());
    }

    public reset(): void {
        this.setLastAction(Date.now());
    }

    private initInterval(): void {
        setInterval(() => {
            this.check();
        }, CHECK_INTERVAL);

        setInterval(() => {
            this.updateNotification();
        }, UPDATE_NOTIFICATION_INTERVAL);
    }

    private check(): void {
        this.store
            .pipe(
                take(1),
                select(settingsStoreSessionTimeout),
                withLatestFrom(this.store.pipe(select(settingsAppOnLine)))
            )
            .subscribe((data: [number, boolean]) => {
                if (data[1] && data[0] && data[0] !== 0 && isTimeout(this.getLastAction(), data[0])) {
                    if (this.storeService.getSelector(authLoggedIn)) {
                        const rememberMe = localStorage.getItem(StorageKeys.rememberMe);
                        if (rememberMe !== 'true') {
                            localStorage.removeItem(StorageKeys.lastAction);
                            this.authenticationService.logout();
                        }
                    }
                }
            });
    }

    private updateNotification(): void {
        this.store.dispatch(new NotificationsGet());
    }
}
