import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { HotLegalAgreement, HotLineItem } from '@hot-theme-nx/generated-api';

import { AuthenticationService } from '../../../account/services/authentication.service';
import { HotProductExtended } from '../../../catalog/models';
import { OrderTemplateService } from '../../../order-template/services/order-template.service';
import { HotCartExtended, HotCartLineItemExtended } from '../../models';
import { LegalAgreementService } from '../../services/legal-agreement.service';
import { OrderSimulationReset } from '@hot-b2b/store/orders/actions';
import { authUser } from '@hot-b2b/store/auth/selector';
import { cartData, cartFetched } from '@hot-b2b/store/cart/selector';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalData, selectModalState } from '@hot-b2b/store/modals/selector';
import { suggestedPromotions } from '@hot-b2b/store/promotions/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotOrderExtended, HotUserExtended, SuggestedPromotion } from '@hot-libs/shared-models';
import { ModalIds, VerificationModalType, StorageKeys, PageName } from '@hot-libs/shared-types';
import { ModalService } from '../../services/modal.service';
import { ProductService } from '../../services/product.service';
import { FeaturesService } from '../../services/features.service';
import { DOCUMENT } from '@angular/common';
import { CartClearWithApiRequest, CartOfflineClear } from '@hot-b2b/store/cart/actions';
import { isApplicationOnline } from '@hot-libs/helpers';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { ItemLocationService } from 'apps/hot-b2b/src/app/shared/services/Item-location.service';

@Component({
    selector: 'hot-theme-nx-modal-dialogs-list',
    templateUrl: './modal-dialogs-list.component.html',
})
export class ModalDialogsListComponent implements AfterViewInit, OnDestroy, OnInit {
    public modalPassExpiredState$: Observable<ModalsState>;
    public modalCacheWarningState$: Observable<ModalsState>;
    public modalNetworkUnavailableState$: Observable<ModalsState>;
    public modalOrderTemplatesState$: Observable<ModalsState>;
    public modalOfflineNotificationState$: Observable<ModalsState>;
    public modalPrivacyState$: Observable<ModalsState>;
    public modalTermsState$: Observable<ModalsState>;
    public modalTermsUpdateState$: Observable<ModalsState>;
    public modalPrivacyPolicyUpdateState$: Observable<ModalsState>;
    public modalDeclinedState$: Observable<ModalsState>;
    public modalPrivacyPolicyDeclinedState$: Observable<ModalsState>;
    public clearCartConfirmationSingle$: Observable<ModalsState>;
    public modalReorderConfirmationState$: Observable<ModalsState>;
    public modalNotifyMeState$: Observable<ModalsState>;
    public modalDistributorCatalogChangesSavedState$: Observable<ModalsState>;
    public modalDistributorOrderComfirmedState$: Observable<ModalsState>;
    public modalEmptiesState$: Observable<ModalsState>;
    public modalPhoneEditState$: Observable<ModalsState>;
    public modalSuggestedPromotionsState$: Observable<ModalsState>;
    public modalRewardsTermsAndConditions$: Observable<ModalsState>;
    public modalTutorialsState$: Observable<ModalsState>;
    public modalTransitionConfirmationState$: Observable<ModalsState>;
    public voucherModal$: Observable<ModalsState>;
    public modalAgeConfirmation$: Observable<ModalsState>;
    public manageChildAccountState$: Observable<ModalsState>;
    public modalDistributorOrdersDispatchedState$: Observable<ModalsState>;
    public modalDistributorOrdersConfirmedState$: Observable<ModalsState>;
    public modalConfirmationState$: Observable<ModalsState>;
    public modalConfirmationClearCart$: Observable<ModalsState>;
    public modalUpdatePriceState$: Observable<ModalsState>;
    public modalInformation$: Observable<ModalsState>;
    public removeSelectedItemsCartModalState$: Observable<ModalsState>;
    public suggestedPromotions$: Observable<SuggestedPromotion[]>;


    public isTermsAndConditionsAccepted: boolean = true;
    public isTermsAndConditionsDeclined: boolean = false;

    public isPrivacyPolicyAccepted: boolean = true;
    public isPrivacyPolicyDeclined: boolean = false;

    public cart$: Observable<HotCartExtended>;
    private readonly cartFetched$: Observable<boolean>;
    private readonly user$: Observable<HotUserExtended>;
    private readonly subscriptionsDestroy$ = new Subject<boolean>();

    public clearCartConfirmationTitle: string;
    public clearCartConfirmationContent: string;
    public clearCartConfirmationProduct: HotProductExtended;
    public clearCartConfirmationPageName: string;
    public isOrderTemplate: boolean;
    public order: HotOrderExtended;

    public removeSelectedItemCartProducts: HotProductExtended[];
    public removeSelectedItemCartItems: HotLineItem[];
    public removeSelectedItemCartPageName: string;
    public removeSelectedItemCartIsClear = false;
    public isGRB2b = () => this.configurationService.getCurrentConfiguration() === 'GR';

    // For verification modal
    public verificationModalType: VerificationModalType;
    public stringToVerify: string;
    private cartIsUpdated: boolean;
    public featureShowAgeConfirmation: boolean = this.featuresService.ShowAgeConfirmation;

    constructor
        (
        public readonly configurationService: ConfigurationService,
        public readonly store: Store<AppState>,
        public readonly modalService: ModalService,
        public readonly legalAgreementService: LegalAgreementService,
        public readonly authenticationService: AuthenticationService,
        public readonly productService: ProductService,
        public readonly orderTemplateService: OrderTemplateService,
        public readonly changeDetector: ChangeDetectorRef,
        public readonly router: Router,
        public readonly featuresService: FeaturesService,
        public readonly renderer: Renderer2,
        public readonly itemlocationService: ItemLocationService,
        @Inject(DOCUMENT) public readonly document: Document,
    ) {
        this.modalPassExpiredState$ = this.store.pipe(select(selectModalState(ModalIds.passwordExpired)));
        this.modalOfflineNotificationState$ = this.store.pipe(select(selectModalState(ModalIds.offlineNotification)));
        this.modalCacheWarningState$ = this.store.pipe(select(selectModalState(ModalIds.cacheWarning)));
        this.modalNetworkUnavailableState$ = this.store.pipe(select(selectModalState(ModalIds.networkUnavailable)));
        this.modalOrderTemplatesState$ = this.store.pipe(select(selectModalState(ModalIds.orderTemplates)));
        this.modalPrivacyState$ = this.store.pipe(select(selectModalState(ModalIds.privacyPolicy)));
        this.modalTermsState$ = this.store.pipe(select(selectModalState(ModalIds.termsAndConditions)));
        this.modalTermsUpdateState$ = this.store.pipe(select(selectModalState(ModalIds.updateTermsAndConditions)));
        this.modalPrivacyPolicyUpdateState$ = this.store.pipe(select(selectModalState(ModalIds.updatePrivacyPolicy)));
        this.modalDeclinedState$ = this.store.pipe(select(selectModalState(ModalIds.termsAndConditionsDeclined)));
        this.modalPrivacyPolicyDeclinedState$ = this.store.pipe(
            select(selectModalState(ModalIds.privacyPolicyDeclined))
        );
        this.clearCartConfirmationSingle$ = this.store.pipe(
            select(selectModalState(ModalIds.clearCartConfirmationSingle))
        );
        this.modalReorderConfirmationState$ = this.store.pipe(select(selectModalState(ModalIds.reorderConfirmation)));
        this.modalNotifyMeState$ = this.store.pipe(select(selectModalState(ModalIds.notifyMe)));
        this.modalDistributorCatalogChangesSavedState$ = this.store.pipe(
            select(selectModalState(ModalIds.distributorCatalogChangesSaved))
        );
        this.modalDistributorOrderComfirmedState$ = this.store.pipe(
            select(selectModalState(ModalIds.distributorOrderConfirmed))
        );
        this.modalPhoneEditState$ = this.store.pipe(select(selectModalState(ModalIds.phoneEdit)));
        this.modalEmptiesState$ = this.store.pipe(select(selectModalState(ModalIds.empties)));
        this.modalSuggestedPromotionsState$ = this.store.pipe(select(selectModalState(ModalIds.suggestedPromotions)));
        this.modalRewardsTermsAndConditions$ = this.store.pipe(
            select(selectModalState(ModalIds.rewardsTermsAndConditions))
        );
        this.modalTransitionConfirmationState$ = this.store.pipe(
            select(selectModalState(ModalIds.transitionConfirmation))
        );
        this.modalConfirmationState$ = this.store.pipe(select(selectModalState(ModalIds.confirmationModal)));
        this.modalConfirmationClearCart$ = this.store.pipe(select(selectModalState(ModalIds.clearCartFromFileModal)));

        this.modalTutorialsState$ = this.store.pipe(select(selectModalState(ModalIds.tutorials)));
        this.voucherModal$ = this.store.pipe(select(selectModalState(ModalIds.voucherModal)));
        this.modalUpdatePriceState$ = this.store.pipe(select(selectModalState(ModalIds.updatePriceModal)));

        this.user$ = this.store.pipe(select(authUser));
        this.cart$ = this.store.pipe(select(cartData));
        this.modalDistributorOrdersDispatchedState$ = this.store.pipe(
            select(selectModalState(ModalIds.distributorOrdersDispatchedModal))
        );
        this.modalDistributorOrdersConfirmedState$ = this.store.pipe(
            select(selectModalState(ModalIds.distributorOrdersConfirmedModal))
        );
        this.suggestedPromotions$ = this.store.pipe(select(suggestedPromotions));

        this.cartFetched$ = this.store.pipe(select(cartFetched)).pipe(filter((data) => data));
        this.modalAgeConfirmation$ = this.store.pipe(select(selectModalState(ModalIds.ageConfirmationModal)));
        this.removeSelectedItemsCartModalState$ = this.store.pipe(
            select(selectModalState(ModalIds.removeSelectedItemCartConfirmationModal))
        );
        this.manageChildAccountState$ = this.store.pipe(select(selectModalState(ModalIds.manageChildAccount)));
        this.modalInformation$ = this.store.pipe(select(selectModalState(ModalIds.informationModal)));
    }

    public ngOnInit(): void {
        this.store.pipe(select(selectModalData(ModalIds.clearCartConfirmationSingle))).subscribe((data: any) => {
            if (data) {
                this.clearCartConfirmationTitle = data.innerHtmlTitle;
                this.clearCartConfirmationContent = data.innerHtmlContent;
                this.clearCartConfirmationProduct = data.product;
                this.clearCartConfirmationPageName = data.pageName;
                this.isOrderTemplate = data.isOrderTemplate || false;
            }
        });
        this.store.pipe(select(selectModalData(ModalIds.reorderConfirmation))).subscribe((data: any) => {
            if (data) {
                this.order = data.order;
            }
        });
        this.store.pipe(select(selectModalData(ModalIds.phoneEdit))).subscribe((data: any) => {
            if (data) {
                this.verificationModalType = data.verificationModalType;
                this.stringToVerify = data.stringToVerify;
            }
        });
        this.store.pipe(select(selectModalData(ModalIds.termsAndConditions))).subscribe((data: any) => {
            if (data?.fromManageChildAccount) {
                this.renderer.addClass(
                    this.document.querySelector('#manage-child-account-modal .modal'),
                    'modal--no-priority'
                );
            }
        });
        this.store
            .pipe(select(selectModalData(ModalIds.removeSelectedItemCartConfirmationModal)))
            .subscribe((data: any) => {
                if (data) {
                    this.removeSelectedItemCartProducts = data.selectedProducts;
                    this.removeSelectedItemCartItems = data.cartItems;
                    this.removeSelectedItemCartPageName = data.pageName;
                    this.removeSelectedItemCartIsClear = data.isClear;
                }
            });
    }

    public ngAfterViewInit(): void {
        if (this.featureShowAgeConfirmation) {
            this.user$.pipe(take(1)).subscribe((user: HotUserExtended) => {
                if (!user.contact.ageConfirmationAcceptedDate) {
                    this.modalService.toggleModal(ModalIds.ageConfirmationModal, true);
                }
            });
        } else {
            this.legalAgreementService.currentTermsAndConditions$
                .pipe(
                    withLatestFrom(this.user$),
                    filter((terms) => !!terms[0]),
                    takeUntil(this.subscriptionsDestroy$)
                )
                .subscribe(([termsAndConditions, user]: [HotLegalAgreement, HotUserExtended]) => {
                    const isTermsAndConditionsAccepted =
                        user.contact.termsAndConditionsAcceptedDate > termsAndConditions.modifiedDate;

                    if (!isTermsAndConditionsAccepted) {
                        this.modalService.toggleModal('update-terms-and-conditions', true);
                        this.isTermsAndConditionsAccepted = false;
                    }

                    this.changeDetector.detectChanges();
                });

            this.legalAgreementService.currentPrivacyPolicy$
                .pipe(
                    withLatestFrom(this.user$),
                    filter((policies) => !!policies[0]),
                    takeUntil(this.subscriptionsDestroy$)
                )
                .subscribe(([privacyPolicy, user]: [HotLegalAgreement, HotUserExtended]) => {
                    const isPrivacyPolicyAccepted = user.contact.privacyPolicyAcceptedDate > privacyPolicy.modifiedDate;

                    if (!isPrivacyPolicyAccepted) {
                        this.modalService.toggleModal('update-privacy-policy', true);
                        this.isPrivacyPolicyAccepted = false;
                    }

                    this.changeDetector.detectChanges();
                });
        }
    }

    public onCloseTcUpdateModal() {
        if (this.isTermsAndConditionsAccepted) {
            this.renderer.removeClass(
                this.document.querySelector('#manage-child-account-modal .modal'),
                'modal--no-priority'
            );
            return;
        }

        if (!this.isTermsAndConditionsDeclined) {
            this.isTermsAndConditionsDeclined = true;
            this.modalService.toggleModal(ModalIds.termsAndConditionsDeclined, true);
        } else {
            this.modalService.toggleModal(ModalIds.termsAndConditions, false);
            this.authenticationService.logout();
        }
    }

    public onClosePpUpdateModal() {
        if (this.isPrivacyPolicyAccepted) {
            return;
        }

        if (!this.isPrivacyPolicyDeclined) {
            this.isPrivacyPolicyDeclined = true;
            this.modalService.toggleModal(ModalIds.privacyPolicyDeclined, true);
        } else {
            this.modalService.toggleModal(ModalIds.privacyPolicy, false);
            this.authenticationService.logout();
        }
    }

    public removeProduct() {
        if (this.isGRB2b()) {
            this.store.dispatch(new OrderSimulationReset());
        }
        if (this.clearCartConfirmationPageName === PageName.FASTORDERMODAL) {
            this.productService.removedProduct.next(this.clearCartConfirmationProduct);
        } else if (!this.isOrderTemplate) {
            this.productService.removeFromCartHandler(
                this.clearCartConfirmationProduct,
                this.clearCartConfirmationPageName
            );
            if (this.clearCartConfirmationProduct?.isReturnableEmpty) {
                this.productService.addProductToAutoAddEmptiesRemovedFromCartTracker(
                    this.clearCartConfirmationProduct?.sku
                );
            }
        } else {
            this.productService.removedProduct.next(this.clearCartConfirmationProduct);
        }
        this.modalService.toggleModal(ModalIds.clearCartConfirmationSingle, false);
        this.orderTemplateService.saveOrderTemplateSuccess(false);
    }

    public declineEvent() {
        this.isTermsAndConditionsDeclined = true;
    }

    public declinePrivacyPolicyEvent() {
        this.isPrivacyPolicyDeclined = true;
    }

    public closeEmptiesAndOpenCheckout(): void {
        this.modalService.toggleModal(ModalIds.empties, false);
        this.router.navigate(['checkout']);
    }

    public closeRewardsTermsAndConitions(): void {
        this.modalService.toggleModal(ModalIds.rewardsTermsAndConditions, false);
        this.router.navigateByUrl('/');
    }

    public closePasswordExpiredModal(): void {
        this.modalService.toggleModal(ModalIds.passwordExpired, false);
        this.authenticationService.logout();
    }

    public closeInformation(): void {
        this.modalService.toggleModal(ModalIds.informationModal, false);
    }

    public applySuggestedPromotionsProducts(data: any): void {
        this.cart$.pipe(take(1)).subscribe((cart: HotCartExtended) => {
            data.selectedProducts.forEach((selectedProduct: any) => {
                const cartProduct: HotCartLineItemExtended = cart.items.find((x) => x.productId === selectedProduct.id);
                const newQuantity = selectedProduct.quantity + cartProduct?.quantity || 0;
                this.productService.sendProductHandler({ productId: selectedProduct.id }, newQuantity);
            });
        });
        this.closeSuggestedPromotions(data.redirectUrl);
    }

    public closeSuggestedPromotions(redirectUrl?: string): void {
        if (!redirectUrl) {
            redirectUrl = '/checkout';
        }
        if (this.featuresService.ReturnEmpties && this.router.url=='/empties') {
                redirectUrl = '/checkout';
        }
        else if  (this.featuresService.ReturnEmpties){
                redirectUrl ='/cart'
        }
        

        combineLatest([this.cartFetched$, this.modalSuggestedPromotionsState$]).subscribe(([_cartIsUpdated, modal]) => {
            if (modal.isOpened) {
                this.modalService.toggleModal(ModalIds.suggestedPromotions, false);
                if (this.router.url !== redirectUrl) {
                    this.modalService.toggleModal(ModalIds.cart, false);
                    this.router.navigateByUrl(modal.data?.redirectUrl || redirectUrl);
                }
            }
        });
    }
    public onRemoveSelectedItemsCart() {
        if (this.removeSelectedItemCartIsClear) {
            this.itemlocationService.deleteProductUsingOutletId();
            const isOnline: boolean = isApplicationOnline();
            if (isOnline) {
                this.store.dispatch(new CartClearWithApiRequest());
            } else {
                localStorage.removeItem(StorageKeys.offlineCart);
                this.store.dispatch(new CartOfflineClear());
            }
        } else {
            this.productService.removeSelectedItemsCart(this.removeSelectedItemCartItems);
        }

        this.modalService.toggleModal(ModalIds.removeSelectedItemCartConfirmationModal, false);
    }

    public closeAgeConfirmationModal(): void {
        this.modalService.toggleModal(ModalIds.ageConfirmationModal, false);
        this.authenticationService.logout();
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }
}
