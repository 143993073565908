import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HotLanguage, HotPromotion } from '@hot-theme-nx/generated-api';
import { HotApiMarketingService } from 'libs/generated-api/src/lib/services/hot-api-marketing.service';
import { HotOffInvoicePromotionDetail } from 'libs/generated-api/src/lib/models/hot-off-invoice-promotion-detail';
import { authUser } from '@hot-b2b/store/auth/selector';
import { Store, select } from '@ngrx/store';
import { AppState } from '@hot-b2b/store/reducers';
import { HotUserExtended } from '@hot-libs/shared-models';
import { Observable } from 'rxjs';
import { settingsData, settingsLanguageCurrent } from '@hot-b2b/store/settings/selector';
import { filter, take } from 'rxjs/operators';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { DateFormatParserService } from '../../services/date-format-parser.service';

@Component({
    selector: 'hot-promotion-performance-list',
    templateUrl: './promotion-performance-list.component.html',
})
export class PromotionPerformanceListComponent implements OnInit {
    @Input() public promotions: any;
    @Input() public pageTag: string;
    @Input() public pageStatus: string;
    public authUser$: Observable<HotUserExtended>;
    public programDetailsUrl: string = "performance/promotion-tracking/program-details";
    public promotionMechanicsUrl: string = "performance/promotion-tracking/program-details/promotion-mechanics";
    public outletOuterId: string;
    public settingsLanguageCurrent$: Observable<HotLanguage>;
    public locale: string;
    public settings$: Observable<HotSettingsExtended>;
    public dateFormat: string;
    public dateFormatMonth: string;

    constructor(
        private readonly router: Router,
        private readonly hotApiMarketingService: HotApiMarketingService,
        private readonly store: Store<AppState>,
        private readonly dateFormatParserService: DateFormatParserService
    ) 
    {
        this.authUser$ = this.store.pipe(select(authUser));
        this.settingsLanguageCurrent$ = this.store.pipe(
            select(settingsLanguageCurrent),
            filter((language) => language != null)
        );
        this.settings$ = this.store.pipe(select(settingsData));

    }

    ngOnInit(): void {
        this.authUser$.subscribe((userData: HotUserExtended) => {
            this.outletOuterId = userData.outlet.outerId;
        });

        this.settingsLanguageCurrent$.pipe(take(1)).subscribe((language: HotLanguage) => {
            if (language != null) {
                this.locale = language.code;
            }
        });

        if(this.locale === 'vi-VN'){
            this.dateFormat = this.dateFormatParserService.parseDateFormatForDatePipe('dd MMMM yyyy');
            this.dateFormatMonth = this.dateFormatParserService.parseDateFormatForDatePipe('dd MMMM');
        }else{
            this.dateFormat = this.dateFormatParserService.parseDateFormatForDatePipe('dd MMM yyyy');
            this.dateFormatMonth = this.dateFormatParserService.parseDateFormatForDatePipe('dd MMM');
        }

    }

    public clickToNavigate(promotion: any): void{      
        if(this.pageTag === 'PromotionalProgramsHistory'){
            this.navigateToNextPage(promotion, this.pageStatus, this.programDetailsUrl);
        }
        else if(this.pageTag === 'PromotionalProgramsActive'){
            this.navigateToNextPage(promotion, this.pageStatus, this.programDetailsUrl);
        }
        else if(this.pageTag === 'ProgramDetailsHistory'){
            this.getPromotionDetails(promotion);
        }
        else if(this.pageTag === 'ProgramDetailsActive'){
            this.getPromotionDetails(promotion);
        }
    }

    private getPromotionDetails(promotion: HotPromotion){
        const params: HotApiMarketingService.GetOffInvoicePromotionDetailsParams = {
            promotionId: promotion.id,
            outletOuterId: this.outletOuterId
        };

        this.hotApiMarketingService.getOffInvoicePromotionDetails(params).subscribe((responseData: HotOffInvoicePromotionDetail) => {
            this.navigateToNextPage(promotion, this.pageStatus, this.promotionMechanicsUrl, responseData);
        });
    }

    private navigateToNextPage(promotion: HotPromotion, statusTag: string, url: string, promotionDetails?: HotOffInvoicePromotionDetail) {
        const promoDetail = { promotion, promotionDetails, status: statusTag };
        this.router.navigateByUrl(url, { state: promoDetail });
    }
}
