import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HotFulfillmentCenter } from '@hot-theme-nx/generated-api';
import { ModalService, WarehouseService } from '../../services';
@Component({
    selector: 'hot-warehouse-select-button',
    templateUrl: './warehouse-select-button.component.html',
})
export class WarehouseSelectButtonComponent implements OnInit, OnDestroy {
    public isFulfillmentSelected = false;
    public selectedFulfillmentCenter: HotFulfillmentCenter;
    public defualtFulfillmentSelected: HotFulfillmentCenter;
    private readonly subscriptionDestroy$ = new Subject<boolean>();
    constructor(private readonly warehouseService: WarehouseService, private readonly modalService: ModalService) {}
    public ngOnInit(): void {
        this.warehouseService.currentSelected
            .pipe(takeUntil(this.subscriptionDestroy$))
            .subscribe((ffc: HotFulfillmentCenter) => {
                this.selectedFulfillmentCenter = ffc;
                if (!this.isFulfillmentSelected) {
                    this.defualtFulfillmentSelected = ffc;
                }
                this.isFulfillmentSelected = !!ffc;
            });
        this.modalService.toggleModal('distributor-change-modal', false);
    }
    public toggleModal(modalId: string, state: boolean) {
        if (state) {
            if (this.selectedFulfillmentCenter?.storeId != this.defualtFulfillmentSelected?.storeId) {
                this.modalService.toggleModal(modalId, state);
            } else {
                this.changeDistributor();
            }
        }
    }
    public changeDistributor() {
        this.modalService.toggleModal('distributor-change-modal', false);
        this.warehouseService.selectFulfillmentCenter().pipe(takeUntil(this.subscriptionDestroy$)).subscribe();
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }
}
