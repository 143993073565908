import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'hot-rewards-points-modal',
    templateUrl: './rewards-points-modal.component.html',
})
export class RewardsPointsModalComponent {
    @Input() public showModal = false;

    constructor(private readonly router: Router) {}

    public redeemNow() {
        this.closeModal();
        this.router.navigateByUrl('/rewards/shop');
    }

    public closeModal(): void {
        this.showModal = false;
    }
}
