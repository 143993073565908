import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class DateService {
    public getPrevMondayDate(date: moment.Moment): moment.Moment {
        let monday = date;
        const weekDay = monday.day();

        if (weekDay === 0) {
            monday = date.subtract(6, 'day');
        } else if (weekDay > 1) {
            monday = date.subtract(weekDay - 1, 'day');
        }

        return monday;
    }

    public getNextMondayDate(date: moment.Moment): moment.Moment {
        let monday = date;
        const weekDay = monday.day();

        if (weekDay > 1) {
            monday = monday.add(8 - weekDay, 'day');
        } else if (weekDay === 0) {
            monday = monday.add(1, 'day');
        }

        return monday;
    }

    public toAnalyticsDateFormat(date: Date): string {
        try{
            if(date){
                const symbol = '-';
                const day = String(date.getDate()).padStart(2,'0');
                const month = String(date.getMonth()+1).padStart(2,'0');
                const year = String(date.getFullYear()).padStart(2,'0');
                
                return `${month}${symbol}${day}${symbol}${year}`;
            }
            return;
        }catch (err){
            console.error(err);
        }
    }
}
