import { Component, Input } from '@angular/core';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
@Component({
    selector: 'hot-order-informer',
    templateUrl: './order-informer.component.html',
})
export class OrderInformerComponent {
    @Input() orderNumber: string;
    @Input() ratingValue: number;
    public featureEnableRating: boolean = this.featuresService.EnableRating;

    constructor(private readonly featuresService: FeaturesService) {}

    public getRatingIcon() {
        if (this.ratingValue > 4) {
            return 'icon-rewards-green.svg';
        } else if (this.ratingValue <= 2) {
            return 'icon-rewards-red.svg';
        } else {
            return 'icon-rewards-amber.svg';
        }
    }

    public getRatingTextColor() {
        if (this.ratingValue > 4) {
            return '#017C28';
        } else if (this.ratingValue <= 2) {
            return '#EC1C24';
        } else {
            return '#292929';
        }
    }
}
