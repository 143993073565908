import { Injectable } from '@angular/core';

import { CartErrorsCodeConstant } from '../constants/cart-errors-code.constant';

@Injectable({
    providedIn: 'root',
})
export class CartErrorHandlerService {
    public transformError(cartError: any) {
        const arrayOfCartError = {};
        const transformedErrorCode = this.normalizeErrorCode(cartError.errorCode);
        arrayOfCartError[transformedErrorCode] = {
            value: cartError.parameters[Object.keys(cartError.parameters)[0]],
            value_1: cartError.parameters[Object.keys(cartError.parameters)[1]],
            value_2: cartError.parameters[Object.keys(cartError.parameters)[2]],
            value_3: cartError.parameters[Object.keys(cartError.parameters)[3]],
            value_4: cartError.parameters[Object.keys(cartError.parameters)[4]],
            value_5: cartError.parameters[Object.keys(cartError.parameters)[5]],
        };
        return arrayOfCartError;
    }

    public transformWarningsArray(cartWarnings: any) {
        const arrayOfCartWarnings = {};

        cartWarnings.map((warning) => {
            const transformedWarningCode = this.normalizeErrorCode(warning.warningCode);
            arrayOfCartWarnings[transformedWarningCode] = {
                value: warning.parameters[Object.keys(warning.parameters)[0]],
                value_1: warning.parameters[Object.keys(warning.parameters)[1]],
                value_2: warning.parameters[Object.keys(warning.parameters)[2]],
            };
        });

        return arrayOfCartWarnings;
    }

    public transformErrorsArray(cartErrors: any) {
        const arrayOfCartErrors = {};

        cartErrors.map((error) => {
            const transformedErrorCode = this.normalizeErrorCode(error.errorCode);
            arrayOfCartErrors[transformedErrorCode] = {
                header: `cart.modal-dialog.${transformedErrorCode}-header`,
                value: error.parameters[Object.keys(error.parameters)[0]],

                buttonText: 'cart.modal-dialog.return-to-cart-btn',
                content: `cart.modal-dialog.${transformedErrorCode}`,
            };
            if (Object.keys(error.parameters).length > 1) {
                arrayOfCartErrors[transformedErrorCode].value =
                    error.parameters[Object.keys(error.parameters)[0]] +
                    '_' +
                    error.parameters[Object.keys(error.parameters)[1]] +
                    '_' +
                    error.parameters[Object.keys(error.parameters)[2]] +
                    '_' +
                    error.parameters[Object.keys(error.parameters)[3]];
            }
        });

        return arrayOfCartErrors;
    }

    public prepareToolbarData(arrayOfCartErrors) {
        let error = {};
        if (
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY] &&
            !arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL] &&
            !arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_ORDER_QUANTITY]
        ) {
            error = {
                message: 'cart.moq-error-toolbar',
                itemsCount: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY].value,
            };
        } else if (
            !arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY] &&
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL] &&
            !arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_ORDER_QUANTITY]
        ) {
            error = {
                message: 'cart.mot-error-toolbar',
                minimumOrderTotal: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL].value,
            };
        } else if (
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY] &&
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL] &&
            !arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_ORDER_QUANTITY]
        ) {
            error = {
                message: 'cart.moq-mot-error-toolbar',
                itemsCount: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY].value,
                minimumOrderTotal: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL].value,
            };
        } else if (
            !arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY] &&
            !arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL] &&
            arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_ORDER_QUANTITY]
        ) {
            error = {
                message: 'cart.moq-mot-error-toolbar',
                maxItemsCount: arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_ORDER_QUANTITY].value,
            };
        } else if (
            !arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY] &&
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL] &&
            arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_ORDER_QUANTITY]
        ) {
            error = {
                message: 'cart.moq-mot-error-toolbar',
                maxItemsCount: arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_ORDER_QUANTITY].value,
                minimumOrderTotal: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL].value,
            };
        } else if (
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY] &&
            !arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL] &&
            arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_ORDER_QUANTITY]
        ) {
            error = {
                message: 'cart.moq-mot-error-toolbar',
                maxItemsCount: arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_ORDER_QUANTITY].value,
                itemsCount: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY].value,
            };
        } else if (arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY_CART_ERROR]) {
            error['HotMinimumOrderQuantityCartError'] = CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY_CART_ERROR;
        } else {
            error = this.cartErrors(arrayOfCartErrors, error);
        }

        error = this.cartErrorArray(arrayOfCartErrors, error);

        return error;
    }
    public cartErrorArray(arrayOfCartErrors, error) {
        if (arrayOfCartErrors[CartErrorsCodeConstant.MULTIPLE_MINIMUM_NON_BACK_ORDER_QUANTITY_SETTING]) {
            error['HotMultipleMinimumNonBackOrderQuantitySettingError'] =
                CartErrorsCodeConstant.MULTIPLE_MINIMUM_NON_BACK_ORDER_QUANTITY_SETTING;
        }

        if (arrayOfCartErrors[CartErrorsCodeConstant.REQUIRE_LINE_ITEM_PRICE_GREATER_THAN_ZERO]) {
            error['requireLineItemPriceGreaterThanZero'] = !!arrayOfCartErrors[
                CartErrorsCodeConstant.REQUIRE_LINE_ITEM_PRICE_GREATER_THAN_ZERO
            ];
        }

        if (arrayOfCartErrors[CartErrorsCodeConstant.SPLIT_ORDER_ATLEAST_ONE_LINE_ITEM_PRICE_GREATER_THAN_ZERO]) {
            error['splitOrderAtleastOneLineItemPriceGreaterThanZero'] = !!arrayOfCartErrors[
                CartErrorsCodeConstant.SPLIT_ORDER_ATLEAST_ONE_LINE_ITEM_PRICE_GREATER_THAN_ZERO
            ];
        }

        if (arrayOfCartErrors[CartErrorsCodeConstant.PRODUCT_LIMIT_ORDER_QUANTITY_ERROR]) {
            const productLimitOrderQuantityError =
                arrayOfCartErrors[CartErrorsCodeConstant.PRODUCT_LIMIT_ORDER_QUANTITY_ERROR].value;
            const productLimitOrderQuantitySku = [];
            productLimitOrderQuantityError.forEach((item) => {
                productLimitOrderQuantitySku.push(item.sku);
            });
            error = {
                HotProductLimitOrderQuantityError: CartErrorsCodeConstant.PRODUCT_LIMIT_ORDER_QUANTITY_ERROR,
                productLimitOrderQuantityErrorSku: productLimitOrderQuantitySku.join(', '),
            };
        }
        if (arrayOfCartErrors[CartErrorsCodeConstant.PRODUCT_LIMIT_ORDER_QUANTITY_WARNING]) {
            error = {
                HotProductLimitOrderQuantityWarning: CartErrorsCodeConstant.PRODUCT_LIMIT_ORDER_QUANTITY_WARNING,
            };
        }

        return error;
    }
    public cartErrors(arrayOfCartErrors, error) {
        if (
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY] &&
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL] &&
            arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_ORDER_QUANTITY]
        ) {
            error = {
                message: 'cart.moq-mot-error-toolbar',
                maxItemsCount: arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_ORDER_QUANTITY].value,
                itemsCount: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY].value,
                minimumOrderTotal: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL].value,
            };
        } else if (arrayOfCartErrors[CartErrorsCodeConstant.ONLY_RETURNABLE_EMPTIES]) {
            error = {
                onlyEmpties: arrayOfCartErrors[CartErrorsCodeConstant.ONLY_RETURNABLE_EMPTIES].content,
            };
        } else if (arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_EMPTIES_RETURN_TOTAL]) {
            error = {
                maxEmptiesReturnTotal: arrayOfCartErrors[CartErrorsCodeConstant.MAXIMUM_EMPTIES_RETURN_TOTAL].value,
            };
        } else if (arrayOfCartErrors[CartErrorsCodeConstant.IN_STOCK_QUANTITY]) {
            let value = arrayOfCartErrors[CartErrorsCodeConstant.IN_STOCK_QUANTITY].value.split('_');
            error = {
                inStockQuantity: value[0],
                productName: value[1],
            };
        } else if (arrayOfCartErrors[CartErrorsCodeConstant.MIN_ORDER_QUANTITY]) {
            let value = arrayOfCartErrors[CartErrorsCodeConstant.MIN_ORDER_QUANTITY].value.split('_');
            error = {
                minQuantity: value[0],
                productName: value[1],
            };
        } else if (arrayOfCartErrors[CartErrorsCodeConstant.MAX_ORDER_QUANTITY]) {
            let value = arrayOfCartErrors[CartErrorsCodeConstant.MAX_ORDER_QUANTITY].value.split('_');
            error = {
                maxQuantity: value[0],
                productName: value[1],
            };
        } else if (arrayOfCartErrors[CartErrorsCodeConstant.MAX_PURCHASE_PER_TIME_PERIOD]) {
            let value = arrayOfCartErrors[CartErrorsCodeConstant.MAX_PURCHASE_PER_TIME_PERIOD].value.split('_');
            error = {
                skuName: value[0],
                unitOfMeasure: value[1],
                maxQuantityPerTime: value[2],
                remainingQuantity: value[3],
            };
        } else if (arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY_PRODUCT_ERROR]) {
            error = {
                HotMinimumOrderQuantityProductError: CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY_PRODUCT_ERROR,
                subCode: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY_PRODUCT_ERROR].value,
                originalSku: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY_PRODUCT_ERROR].value_1,
                minimumOrderQuantity:
                    arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY_PRODUCT_ERROR].value_2,
                missingBaseMiniumOrderQuantity:
                    arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY_PRODUCT_ERROR].value_3,
                baseProductId: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY_PRODUCT_ERROR].value_4,
                unitOfMeasure: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY_PRODUCT_ERROR].value_5,
            };
        }
        return error;
    }
    public preparePopupData(arrayOfCartErrors) {
        let popupData = {};

        // moq exist
        if (
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY] &&
            !arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL]
        ) {
            popupData = {
                header: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY].header,
                headerClasses: 'text-red',
                buttons: [
                    {
                        label: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY].buttonText,
                    },
                ],
                data: {
                    content: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY].content,
                    values: { itemsCount: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY].value },
                },
                closable: true,
                closeOnEscape: true,
            };
            // mot exist
        } else if (
            !arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY] &&
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL]
        ) {
            popupData = {
                header: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL].header,
                headerClasses: 'text-red',
                buttons: [
                    {
                        label: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL].buttonText,
                    },
                ],
                data: {
                    content: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL].content,
                    values: { minimumOrderTotal: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL].value },
                },
                closable: true,
                closeOnEscape: true,
            };
            // mot and moq exist
        } else if (
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY] &&
            arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL]
        ) {
            popupData = {
                header: `cart.modal-dialog.moq-and-mot-error-header`,
                headerClasses: 'text-red',
                buttons: [
                    {
                        label: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL].buttonText,
                    },
                ],
                data: {
                    content: `cart.modal-dialog.moq-and-mot-error-content`,
                    values: {
                        minimumOrderTotal: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_TOTAL].value,
                        itemsCount: arrayOfCartErrors[CartErrorsCodeConstant.MINIMUM_ORDER_QUANTITY].value,
                    },
                },
                closable: true,
                closeOnEscape: true,
            };
        }

        return popupData;
    }

    public normalizeErrorCode(errorCode: string) {
        const camelCaseSymbols = /([a-z])([A-Z])/g;
        return errorCode.replace(camelCaseSymbols, '$1-$2').toLocaleLowerCase();
    }
    public processMoqError(cartValidationError: any) {
        let groupError: any = {
            moqProductErrorBackOrder: {},
            moqProductErrorIndividualSku: {},
            moqCartErrorTotalOrder: {},
            moqCartErrorKegOnly: {},
            moqCartErrorKegAndPack: {},
        };
        for (let error of cartValidationError) {
            this.errorCode(error, groupError);
            if (
                error.errorCode === 'HotMinimumOrderQuantityProductError' &&
                error.parameters.subCode === 'IndividualSku'
            ) {
                groupError.moqProductErrorIndividualSku.individualSku = error.parameters.subCode;
            }
            if (error.errorCode === 'HotMinimumOrderQuantityCartError') {
                if (error.parameters.subCode === 'TotalOrder') {
                    groupError.moqCartErrorTotalOrder.moqTotalOrder =
                        error.parameters.minimumOrderQuantityItems[0].minimumOrderQuantity +
                        ' ' +
                        error.parameters.minimumOrderQuantityItems[0].packageType;
                }
                if (error.parameters.subCode === 'KegOnly') {
                    groupError.moqCartErrorKegOnly.moqKegOnly =
                        error.parameters.minimumOrderQuantityItems[0].minimumOrderQuantity +
                        ' ' +
                        error.parameters.minimumOrderQuantityItems[0].packageType;
                }
                if (error.parameters.subCode === 'KegAndPack') {
                    groupError.moqCartErrorKegAndPack = this.keyAndPack(error, groupError);
                }
            }
        }
        return groupError;
    }
    public errorCode(error, groupError) {
        if (error.errorCode === 'HotMinimumOrderQuantityProductError' && error.parameters.subCode === 'BackOrder') {
            groupError.moqProductErrorBackOrder.backOrder = error.parameters.subCode;
            if (error.parameters.basePackage === 'Keg') {
                groupError.moqProductErrorBackOrder.basePackageKeg = error.parameters.basePackage;
                groupError.moqProductErrorBackOrder.moqKeg =
                    error.parameters.minimumOrderQuantity + ' ' + error.parameters.unitOfMeasure;
            }
            if (error.parameters.basePackage === 'Pack') {
                groupError.moqProductErrorBackOrder.basePackagePack = error.parameters.basePackage;
                groupError.moqProductErrorBackOrder.moqPack =
                    error.parameters.minimumOrderQuantity + ' ' + error.parameters.unitOfMeasure;
            }
        }
        return { error, groupError };
    }
    public keyAndPack(error, groupError) {
        for (const item of error.parameters.minimumOrderQuantityItems) {
            if (item.basePackage === 'Keg') {
                groupError.moqCartErrorKegAndPack.moqKeg = item.minimumOrderQuantity + ' ' + item.packageType;
            }
            if (item.basePackage === 'Pack') {
                groupError.moqCartErrorKegAndPack.moqPack = item.minimumOrderQuantity + ' ' + item.packageType;
            }
        }
        return groupError.moqCartErrorKegAndPack;
    }
    public processProductLimitOrderQuantity(cartValidationError?: any, cartValidationWarning?: any) {
        let groupError: any = [];
        let groupWarning: any = [];

        if (cartValidationError.length) {
            for (const error of cartValidationError) {
                if (
                    error.errorCode === 'HotProductLimitOrderQuantityError'
                ) {
                    groupError.push(error.parameters.productLimitOrderQuantityError); 
                }
            }
        }
        if (cartValidationWarning.length) {
            for (const warning of cartValidationWarning) {
                if (
                    warning.warningCode === 'HotProductLimitOrderQuantityWarning'
                ) {
                    groupWarning.push(warning.parameters.productLimitOrderQuantityWarning);
                }
            }

        }

        return groupError.concat(groupWarning);
    }
}
