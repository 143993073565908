import { HotOutlet, User } from '@hot-theme-nx/generated-api';

const betaTestersGroupName = 'BETATESTERS';

const setCurrentOutlet = (user: User, outlet: HotOutlet) => {
    user.contact.outlet = outlet;
    return user;
};

const isUserBetaTester = (user: any, groupName?: string) => {
    const currentBetaTestersGroupName = groupName || betaTestersGroupName;
    return (
        user.userGroups?.some((group: string) => group === currentBetaTestersGroupName) ||
        user.outlet?.userGroups?.some((group: string) => group === currentBetaTestersGroupName) ||
        user.contact?.userGroups?.some((group: string) => group === currentBetaTestersGroupName)
    );
};


export { setCurrentOutlet, isUserBetaTester };
