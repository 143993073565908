import { DOCUMENT } from '@angular/common';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ElementRef,
    ViewChild,
    SimpleChanges,
} from '@angular/core';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { settingsStore, settingsData } from 'apps/hot-b2b/src/app/store/settings/selector';
import { Observable, Subject, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { AppState } from '@hot-b2b/store/reducers';
import { catalogIsAlcoholic, catalogIsHighProfitMargin } from '@hot-b2b/store/catalog/selector';
import { AdvanceFilterService } from '../../services/advance-filter.service';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { FeaturesService } from '../../services/features.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';

@Component({
    selector: 'hot-quick-filters',
    templateUrl: './quick-filters.component.html',
})
export class QuickFiltersComponent implements OnInit {
    selectedPrice: any;
    selectedBrandOwner: any;
    @ViewChild('carousel', { static: false }) carousel: ElementRef;

    private scrollPoint: number;
    public carouselConfig: any;
    public selectedFilters = [];
    public selectedBrandOwnerFilters = [];
    private isRTL: boolean;
    public sortOrder: any = [
        { label: 'Price: Low to High', value: 'Price: Low to High', code: 'asc' },
        { label: 'Price: High to Low', value: 'Price: High to Low', code: 'desc' },
    ];
    public staticFilters = [];

    public selectedOrder = 'Sort by';
    public isUpLg = () => this.screenDimensionService.upLg();
    public selectedSortOrder;
    public settingsStore$: Observable<SettingsStoreModel>;
    public filterLabel;
    public catalogAggregations$;
    public catalogHighProfitAggregations$;
    public selectedStaticFilters;
    public settings$: Observable<HotSettingsExtended>;
    public isAlcoholicQuickFilterEnabled: boolean;
    public isHighProfitMarginQuickFilterEnabled: boolean;

    public featureEnableAdvancedPriceAndSortingFilter: boolean = this.featuresService
        .EnableAdvancedPriceAndSortingFilter;
    public isNG = () => this.configurationService.getCurrentConfiguration() === 'NG';

    public isAlchoholicProduct$: Observable<any[]>;
    public isHighProfitProduct$: Observable<any[]>;

    constructor(
        @Inject(DOCUMENT) private readonly document: any,
        public readonly screenDimensionService: ScreenDimensionService,
        private readonly store: Store<AppState>,
        private readonly advanceFilterService: AdvanceFilterService,
        private readonly featuresService: FeaturesService,
        private readonly configurationService: ConfigurationService,

    ) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );

        this.selectedStaticFilters = this.advanceFilterService.activeFilter;
        this.settings$ = this.store.pipe(select(settingsData));
        this.isAlchoholicProduct$ = this.store.pipe(select(catalogIsAlcoholic));
        this.isHighProfitProduct$ = this.store.pipe(select(catalogIsHighProfitMargin));
    }

    @Input() filter;
    @Input() priceRange;
    @Input() brandOwner;
    @Input() public filtersChecked = [];
    @Input() public brandOwnerFiltersChecked = [];
    @Input() public activePrice;
    @Input() updateFilter;
    @Output() public filterSelect: EventEmitter<any> = new EventEmitter();
    @Output() public selectPrice: EventEmitter<any> = new EventEmitter();
    @Output() public selectBrandOwner: EventEmitter<any> = new EventEmitter();
    @Output() public staticFilterClick: EventEmitter<any> = new EventEmitter();
    @Output() public openPillsModalEvent: EventEmitter<any> = new EventEmitter();
    @Output() public openFiltePanel: EventEmitter<any> = new EventEmitter();
    private readonly subscriptionDestroy$ = new Subject<boolean>();

    ngOnInit(): void {
        this.selectedFilters = cloneDeep(this.filtersChecked);
        this.selectedBrandOwnerFilters = cloneDeep(this.brandOwnerFiltersChecked);
        this.filtersChecked = [];
        this.carouselConfig = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            variableWidth: true,
            touchThreshold: 100,
        };

        this.selectedFilters = cloneDeep(this.filtersChecked);
        this.selectedBrandOwnerFilters = cloneDeep(this.brandOwnerFiltersChecked);
        this.filtersChecked = [];
        this.isRTL = this.document.documentElement.dir === 'rtl';

        this.settings$
            .pipe(take(1), withLatestFrom(this.settingsStore$))
            .subscribe(([settings, storeSettings]: [HotSettingsExtended, SettingsStoreModel]) => {
                if (!storeSettings.excludeOutOfStockProductsFromCustomersCatalog && settings.enableInStockQuickFilter) {
                    this.staticFilters.push({
                        groupLabel: 'showOnlyInStock',
                        name: 'in-stock',
                        showModal: false,
                        value: false,
                    });
                }
                if (this.featuresService.ManageFavoriteProducts && settings.enableFavoriteQuickFilter) {
                    this.staticFilters.push({
                        groupLabel: 'is_favorite',
                        name: 'is-favorite',
                        showModal: false,
                        value: false,
                    });
                }

                this.isAlcoholicQuickFilterEnabled = settings.enableIsNonAlcoholicQuickFilter;
                this.isHighProfitMarginQuickFilterEnabled = settings.enableHighProfitQuickFilter;
            });

        this.isAlchoholicProduct$
            .pipe(        
                switchMap(value => of(value))
            )
            .subscribe((capturedAggregation: any) => {
                this.catalogAggregations$ = capturedAggregation;
                if (this.catalogAggregations$ && this.catalogAggregations$.length > 0 && this.updateFilter) {
                    const isAlcoholiFilter = {
                        groupLabel: this.catalogAggregations$[0].groupLabel,
                        name: 'is-alcoholic',
                        showModal: false,
                        value: false,
                    };
                    const isAlcolicprdcts = this.catalogAggregations$.filter(
                        (catalogAggFilter) => catalogAggFilter.value === 'True' && catalogAggFilter?.count > 0
                    );
                    const sortFilterExist = this.staticFilters.filter(
                        (sortFilter) => sortFilter.name == 'is-alcoholic'
                    );
                    if (isAlcolicprdcts && sortFilterExist?.length === 0 && this.isAlcoholicQuickFilterEnabled) {
                        this.staticFilters.push(isAlcoholiFilter);
                    } else if (!isAlcolicprdcts && sortFilterExist?.length > 0) {
                        this.staticFilters = this.staticFilters.filter((item) => item != sortFilterExist);
                    }
                }
            });

        this.isHighProfitProduct$
            .pipe(           
                switchMap(value => of(value))
            )
            .subscribe((capturedHighProfitAggregation: any) => {
                this.catalogHighProfitAggregations$ = capturedHighProfitAggregation;
                if (
                    this.catalogHighProfitAggregations$ &&
                    this.catalogHighProfitAggregations$.length > 0 &&
                    this.updateFilter
                ) {
                    const isAlcoholiFilter = {
                        groupLabel: this.catalogHighProfitAggregations$[0].groupLabel,
                        name: 'is-high-profit',
                        showModal: false,
                        value: false,
                    };
                    const isHighProfitprdcts = this.catalogHighProfitAggregations$.filter(
                        (catalogAggHighFilter) =>
                            catalogAggHighFilter.value === 'True' && catalogAggHighFilter?.count > 0
                    );
                    const highProfitExistInStatic = this.staticFilters.filter(
                        (sortFilter) => sortFilter.name == 'is-high-profit'
                    );
                    if (isHighProfitprdcts && highProfitExistInStatic?.length === 0 && this.isHighProfitMarginQuickFilterEnabled) {
                        this.staticFilters.push(isAlcoholiFilter);
                    } else if (!isHighProfitprdcts && highProfitExistInStatic?.length > 0) {
                        this.staticFilters = this.staticFilters.filter((item) => item != highProfitExistInStatic);
                    }
                }
            });           
         }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filter) {
            this.filter = changes.filter.currentValue;
            this.displaySelectedFilter(this.filter?.groupLabel);
        } else if (changes.priceRange) {
            this.priceRange = changes.priceRange.currentValue;
        } else if (changes.activePrice) {
            this.selectedPrice = changes.activePrice.currentValue;
        } else if (changes.filtersChecked) {
            this.filtersChecked = changes.filtersChecked.currentValue;
            this.displaySelectedFilter(this.filter?.groupLabel);
        } else if (changes.brandOwnerFiltersChecked) {
            this.brandOwnerFiltersChecked = changes.brandOwnerFiltersChecked.currentValue;
            this.displaySelectedFilter(this.brandOwner?.groupLabel);
        } else if (changes.brandOwner) {
            this.brandOwner = changes.brandOwner.currentValue;
            this.displaySelectedFilter(this.brandOwner?.groupLabel);
        }
    }

    public checkActive(item): boolean {
        return (
            findIndex(
                item.groupLabel === 'Sold by' ? this.brandOwnerFiltersChecked : this.filtersChecked,
                (activeFilter: any) =>
                    activeFilter?.groupLabel === item.groupLabel && item.value === activeFilter?.value
            ) !== -1
        );
    }

    public selectFilter(filterSelect) {
        this.filtersChecked = this.selectedFilters;
        this.filterSelect.emit(filterSelect);
    }

    public onSelectBrandOwner(brandFilter) {
        this.brandOwnerFiltersChecked = this.selectedBrandOwnerFilters;
        this.selectBrandOwner.emit(brandFilter);
    }

    public openPillsModal(pillsFilter) {
        this.openPillsModalEvent.emit(pillsFilter);
    }

    public openSortBy() {
        this.sortOrder['groupLabel'] = 'Sort by';
        this.sortOrder['isMultiselect'] = false;
        this.openPillsModal(this.sortOrder);
    }

    public onSelectPrice(selectedValue) {
        if (this.selectedPrice === selectedValue) {
            this.selectedPrice = null;
            this.selectPrice.emit(null);
        } else {
            this.selectedPrice = selectedValue;
            this.selectPrice.emit(this.selectedPrice);
        }
    }
    addCurrency(selectedPrice) {
        return this.advanceFilterService.addCurrencySymbol(selectedPrice);
    }

    public displaySelectedFilter(label) {
        if (label === 'Brand owner') {
            this.brandFiter(label);
        }
        else if (this.filtersChecked && this.filtersChecked?.length > 2) {
                this.filterLabel = label + ' (' + this.filtersChecked?.length + ')';
        }
        else if (this.filtersChecked?.length) {
                let data = this.filtersChecked[0]?.label;
                if (this.filtersChecked[1]) {
                    data = data + ', ' + this.filtersChecked[1]?.label;
                }
                this.filterLabel = data;
         }
         else {
                this.filterLabel = label;
        }
    }
    public brandFiter(label) {
        if (this.brandOwnerFiltersChecked && this.brandOwnerFiltersChecked.length > 2) {
            this.selectedBrandOwner = label + ' (' + this.brandOwnerFiltersChecked.length + ')';
        } else if (this.brandOwnerFiltersChecked.length) {
            let data = this.brandOwnerFiltersChecked[0]?.label;
            if (this.brandOwnerFiltersChecked[1]) {
                data = data + ', ' + this.brandOwnerFiltersChecked[1]?.label;
            }
            this.selectedBrandOwner = data;
        } else {
            this.selectedBrandOwner = label;
        }
    }

    public selectedStaticFilter(event) {
        let tempdata=cloneDeep(event);
        this.staticFilterClick.emit(tempdata);
    }

    public slideTo(direction: string) {
        let scrollMax = this.carousel.nativeElement.scrollWidth - this.carousel.nativeElement.offsetWidth;

        if (!this.isRTL) {
            this.slideRt(scrollMax, direction);
        } else {
            scrollMax = scrollMax * -1 + 1;

            if (this.carousel.nativeElement.scrollLeft <= scrollMax && direction === 'right') {
                this.carousel.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
                this.scrollPoint = null;
                return;
            } else if (this.carousel.nativeElement.scrollLeft >= 0 && direction === 'left') {
                this.carousel.nativeElement.scrollTo({ left: scrollMax, behavior: 'smooth' });
                this.scrollPoint = this.carousel.nativeElement.scrollLeft;
                return;
            }

            switch (direction) {
                case 'left':
                    const yLeft = this.carousel.nativeElement.scrollLeft + 200;
                    this.carousel.nativeElement.scrollTo({ left: yLeft, behavior: 'smooth' });
                    this.scrollPoint = this.carousel.nativeElement.scrollLeft;
                    break;
                case 'right':
                    const yRight = this.carousel.nativeElement.scrollLeft - 200;
                    this.carousel.nativeElement.scrollTo({ left: yRight, behavior: 'smooth' });
                    this.scrollPoint = this.carousel.nativeElement.scrollLeft;
                    break;
            }
        }
    }
    public slideRt(scrollMax, direction) {
        if (this.carousel.nativeElement.scrollLeft >= scrollMax && direction === 'right') {
            this.carousel.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
            this.scrollPoint = null;
            return;
        } else if (this.carousel.nativeElement.scrollLeft === 0 && direction === 'left') {
            this.carousel.nativeElement.scrollTo({ left: scrollMax, behavior: 'smooth' });
            this.scrollPoint = this.carousel.nativeElement.scrollLeft;
            return;
        }

        switch (direction) {
            case 'left':
                const yLeft = this.carousel.nativeElement.scrollLeft - 200;
                this.carousel.nativeElement.scrollTo({ left: yLeft, behavior: 'smooth' });
                this.scrollPoint = this.carousel.nativeElement.scrollLeft;
                break;
            case 'right':
                const yRight = this.carousel.nativeElement.scrollLeft + 200;
                this.carousel.nativeElement.scrollTo({ left: yRight, behavior: 'smooth' });
                this.scrollPoint = this.carousel.nativeElement.scrollLeft;
                break;
        }
    }

    toggleAdvancedFilterPanel() {
        this.openFiltePanel.emit(true);
    }

    public isActivePill(option) {
        let index = findIndex(
            this.selectedStaticFilters,
            (activePillFilter: any) => activePillFilter?.groupLabel === option.groupLabel && activePillFilter.active
        );
        if (index != -1 || option.active) {
            if(!option.active && option.active !=undefined){
                return false;
            }
            option.active=true;
            return true;
        } else {
            return false;
        }
    }
    public resetFilter(){
        this.staticFilters.forEach(element => {
            delete element.active;
        }); 
    }
   
}
