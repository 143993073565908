import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from '@hot-theme-nx/generated-api';

@Injectable({
    providedIn: 'root',
})
export class OutletService {
    constructor(private readonly httpClient: HttpClient, private readonly apiConfiguration: ApiConfiguration) {}

    setOutlet(outletId: string): Observable<object> {
        return this.httpClient.get<object>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/outlets/current/${outletId}`
        );
    }
}
