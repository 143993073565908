import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {


    parse(url: any): UrlTree {
        let defaultUrl = new DefaultUrlSerializer();
        return defaultUrl.parse(url);
    }

    serialize(tree: UrlTree): any {
        let url = new DefaultUrlSerializer(),
            path = url.serialize(tree);

        return decodeURIComponent(path);
    }
}