import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Theme } from '@hot-libs/shared-types';
import { ConfigurationService } from '../../shared/services/configuration.service';

@Injectable({
    providedIn: 'root',
})
export class StyleService {
    private readonly renderer: Renderer2;
    private readonly head: HTMLElement;
    public isMMB2b = () => this.configurationService.getCurrentConfiguration() === 'MM';

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        rendererFactory: RendererFactory2,
        private readonly configurationService: ConfigurationService
    ) {
        this.head = document.head;
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public async initialize() {
        const themeName = this.document.documentElement.dataset.theme;

        if (!themeName) {
            await this.setTheme(Theme.default);
        } else {
            await this.setTheme(themeName);
        }
    }

    public async setTheme(name: string) {
        const themeFilename = `/theme.${name}.css`;
        await this.loadCss(themeFilename);
    }

    public async includeRTL(isRTL: boolean) {
        const rtlFilename = `/rtl.css`;
        if (isRTL) {
            await this.loadCss(rtlFilename);
        } else {
            this.removeCss(rtlFilename);
        }
    }

    public async includeLangStyles(langCode: string) {
        const lang = langCode.split('-')[0];
        switch (lang) {
            case 'ar':
                await this.loadCss('/lang.ar.css');
                this.removeCss('/lang.am.css');
                this.removeCss('/lang.en.css');
                this.removeCss('/lang.my.css');
                this.removeCss('/lang.el.css');
                this.removeCss('/lang.vi.css');
                break;
            case 'am':
                await this.loadCss('/lang.am.css');
                this.removeCss('/lang.ar.css');
                this.removeCss('/lang.en.css');
                this.removeCss('/lang.my.css');
                this.removeCss('/lang.el.css');
                this.removeCss('/lang.vi.css');
                break;
            case 'lo':
                this.removeCss('/lang.ar.css');
                this.removeCss('/lang.am.css');
                this.removeCss('/lang.en.css');
                this.removeCss('/lang.my.css');
                this.removeCss('/lang.el.css');
                this.removeCss('/lang.vi.css');
                break;
            case 'my':
                await this.loadCss('/lang.my.css');
                this.removeCss('/lang.ar.css');
                this.removeCss('/lang.am.css');
                this.removeCss('/lang.en.css');
                this.removeCss('/lang.el.css');
                this.removeCss('/lang.vi.css');
                break;
            case 'el':
                await this.loadCss('/lang.el.css');
                this.removeCss('/lang.ar.css');
                this.removeCss('/lang.am.css');
                this.removeCss('/lang.en.css');
                this.removeCss('/lang.vi.css');
                break;
                case 'vi':
                    await this.loadCss('/lang.vi.css');
                    this.removeCss('/lang.ar.css');
                    this.removeCss('/lang.am.css');
                    this.removeCss('/lang.en.css');
                    this.removeCss('/lang.el.css');
                    break;
            case 'en':
                if (this.isMMB2b()) {
                    await this.loadCss('/lang.en.css');
                    this.removeCss('/lang.ar.css');
                    this.removeCss('/lang.am.css');
                    this.removeCss('/lang.my.css');
                    this.removeCss('/lang.el.css');
                    this.removeCss('/lang.vi.css');
                    break;
                } else {
                    this.removeCss('/lang.am.css');
                    this.removeCss('/lang.ar.css');
                    this.removeCss('/lang.en.css');
                    this.removeCss('/lang.my.css');
                    this.removeCss('/lang.el.css');
                    this.removeCss('/lang.vi.css');
                    break;
                }
            default:
                this.removeCss('/lang.am.css');
                this.removeCss('/lang.ar.css');
                this.removeCss('/lang.en.css');
                this.removeCss('/lang.my.css');
                this.removeCss('/lang.el.css');
                this.removeCss('/lang.vi.css');
        }
    }

    private styleLink(filename: string): any {
        return document.head.querySelector(`link[href="${filename}"]`);
    }

    private removeCss(filename: string): void {
        const link = this.styleLink(filename);
        if (link) {
            link.parentNode.removeChild(link);
        }
    }

    private async loadCss(filename: string): Promise<void> {
        if (this.styleLink(filename)) return;

        return new Promise((resolve) => {
            const linkEl: HTMLElement = this.renderer.createElement('link');
            this.renderer.setAttribute(linkEl, 'rel', 'stylesheet');
            this.renderer.setAttribute(linkEl, 'type', 'text/css');
            this.renderer.setAttribute(linkEl, 'href', filename);
            this.renderer.setProperty(linkEl, 'onload', resolve);
            this.renderer.appendChild(this.head, linkEl);
        });
    }
}
