export enum Theme {
    default = 'default',
    eg = 'eg',
    ng = 'ng',
    id = 'id',
    rw = 'rw',
    et = 'et',
    mz = 'mz',
    hea = 'hea',
    mdot = 'mdot',
}
