import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { HotCookies } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { CookiesService } from 'apps/hot-b2b/src/app/shared/services/cookies.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'apps/hot-b2b/src/environments/environment';
import { Observable, of } from 'rxjs';
import { enums } from '@optimizely/optimizely-sdk';
import { ConfigurationService } from 'apps/hot-b2b/src/app/shared/services/configuration.service';

@Injectable({
    providedIn: 'root',
})
export class OptimizelyService {
    public client: boolean = false;
    public optimizelyClient: any;
    public UUID: any;
    private browser: string;
    private device: string;
    private opco: string;

    constructor(private readonly cookieService: CookiesService,
        private readonly deviceDetectorService: DeviceDetectorService,
        private readonly configurationService: ConfigurationService) {
            this.setUserAttributes();
        }

    public createOptimizely(): Observable<boolean> {
        const optimizelySdk = require('@optimizely/optimizely-sdk');

        this.optimizelyClient = optimizelySdk.createInstance({
            sdkKey: environment.optimizelySdkKey,
        });
        let client = this.optimizelyClient
            .onReady()
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
        return of(client);
    }

    public readyOptimizely() {
        return new Promise((resolve) => {
            this.optimizelyClient
                .onReady()
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);
                });
        });
    }

    public initializeUserContext() {
        if (!this.cookieService.getCookie(HotCookies.CURRENT_OPTIMIZELY_ENDUSERID)) {
            this.UUID = UUID.UUID();
            this.cookieService.setExpireCookie(HotCookies.CURRENT_OPTIMIZELY_ENDUSERID, this.UUID);
        } else {
            this.UUID = this.cookieService.getCookie(HotCookies.CURRENT_OPTIMIZELY_ENDUSERID);
        }
    }
    public clearOptimizelyUserID() {
        this.cookieService.deleteCookie(HotCookies);
    }
    public isOptimizelyEnabled(optimizelyFlag) {
        this.UUID = this.cookieService.getCookie(HotCookies.CURRENT_OPTIMIZELY_ENDUSERID);
        const userAttributes = {
            Browser:this.browser,
            Device:this.device,
            OpCo:this.opco
        };
        let user = this.optimizelyClient?.createUserContext(this.UUID,userAttributes);
        let decision = user.decide(optimizelyFlag);

        if (decision.enabled) {
            this.optimizelyClient.notificationCenter.addNotificationListener(
                enums.NOTIFICATION_TYPES.DECISION,
                this.onDecision({ optimizelyFlag, decision })
            );
            return {
                enabled: true,
                ruleKey: decision.ruleKey,
                variationKey: decision.variationKey,
                user: user,
            };
        } else {
            return {
                enabled: false,
                ruleKey: decision.ruleKey,
                variationKey: decision.variationKey,
                user: user,
            };
        }
    }
    onDecision = ({ optimizelyFlag, decision }): void => {
        // Add a DECISION Notification Listener
        if (optimizelyFlag === 'a_a_test') {
            const objectPassedToEnsighten: any = {};
            objectPassedToEnsighten.optimizely_experiment = decision.ruleKey;
            objectPassedToEnsighten.optimizely_variant = decision.variationKey;
            window.digitalData = objectPassedToEnsighten;
            window.callBootstrapperTrigger('optimizely_decision_fx');
        }
    };
    private setUserAttributes() {
        this.browser = this.deviceDetectorService.browser;
        if(this.deviceDetectorService.isDesktop()) { 
            this.device = "desktop";
        }
        else if(this.deviceDetectorService.isMobile()) { 
            this.device = "mobile";
        }
        else if(this.deviceDetectorService.isTablet()) {
            this.device = "tablet";
        }
        else {
            this.device = "";
        }
        this.opco = this.configurationService.getCurrentConfiguration();
    }
}
