import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    AfterViewInit,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import  dayjs  from 'dayjs';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import {
    HotApiDistributorsService,
    HotConfirmOrderDeliveryRequest,
    HotOperationResult,
    CurrencyFormat,
    HotLanguage,
    HotOrder,
    HotProductSearchLightItem,
    HotUpdateOrderItem,
    HotOrderStatusReasonsWithLanguage,
    HotCancelOrderRequest,
    HotSettings,
} from '@hot-theme-nx/generated-api';
import {
    HotOrderExtended,
    HotOrderLineItemExtended,
    HotUserExtended,
    ICalendarConfig,
    SettingsStoreModel,
    NameValuePair,
} from '@hot-libs/shared-models';
import { ModalIds, OrderStatus, PageName, StorageKeys } from '@hot-libs/shared-types';

import { DistributorOrdersClient, VanSalesManOrdersClient } from 'apps/hot-b2b/src/app/distributor/services';
import {
    AlertService,
    FeaturesService,
    ModalService,
    ProductService,
    OrderDetailsService,
} from 'apps/hot-b2b/src/app/shared/services';
import { DateFormatParserService } from 'apps/hot-b2b/src/app/shared/services/date-format-parser.service';
import { LineItemsCountService } from 'apps/hot-b2b/src/app/shared/services/line-items-count.service';
import { ConfigurationService } from '../../../../shared/services/configuration.service';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import {
    settingsAppOnLine,
    settingsCurrencyFormat,
    settingsData,
    settingsLanguageCurrent,
    settingsLoyaltyCurrencyFormat,
    settingsStore,
} from '@hot-b2b/store/settings/selector';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { authUser } from '@hot-b2b/store/auth/selector';
import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { CalendarHelperService } from 'libs/helpers/src/lib/calendar-helper.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DistributorOrdersService } from 'apps/hot-b2b/src/app/distributor/services/distributor-orders.service';

@Component({
    selector: 'hot-distributor-order-modal',
    templateUrl: './distributor-order-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistributorOrderModalComponent implements OnInit, AfterViewInit, OnDestroy {
    private _order: HotOrderExtended;
    @Input() set order(value: HotOrderExtended) {
        this._order = value;
        this.setOrderItems();
    }
    get order(): HotOrderExtended {
        return this._order;
    }

    @Input() public useNewOrders: boolean;
    @Input() public canManage: boolean;
    @Input() public canManageItems: boolean;
    @Input() public isChanged: boolean;
    @Input() public isOpcoDistributorMode: boolean;
    @Input() public isVanSalesManMode: boolean;
    @Input() public exportOrdersToPDFSize: NameValuePair[];
    @Input() public confirmPartialDeliveryReasons: HotOrderStatusReasonsWithLanguage[];
    @Input() public rejectReasons: HotOrderStatusReasonsWithLanguage[];
    @Input() public isExternalPlatformSelected: boolean;
    @Input() public isExternalPlatformSelectedMobile: boolean;
    
    @Output() public updateData: EventEmitter<any> = new EventEmitter();
    @Output() public removeFromCart: EventEmitter<any> = new EventEmitter<any>();
    @Output() public sendChangeQuantity: EventEmitter<any> = new EventEmitter<any>();
    @Output() public cancelChanges: EventEmitter<any> = new EventEmitter();
    @Output() public confirmChanges: EventEmitter<any> = new EventEmitter();
    @Output() public changeDate: EventEmitter<any> = new EventEmitter();
    @Output() public exportToExcel: EventEmitter<string> = new EventEmitter();
    @Output() public exportOrderToPDF: EventEmitter<string> = new EventEmitter();

    public pageName = PageName.DISTRIBUTORORDERMODAL;
    public hotSettings$: Observable<HotSettings>;
    public calendarConfig: ICalendarConfig;
    public selectedDate: moment.Moment | string;
    public originalOrder: HotOrderExtended;
    public restricStoreId: boolean = true;
    public canEdit: boolean;
    public productToAdd: HotProductSearchLightItem;
    public dateFormatFromMoment: string;
    public dateFormatFromDatePipe: string;
    public itemsCount$: BehaviorSubject<number> = new BehaviorSubject(0);

    public addProductForm: UntypedFormGroup = new UntypedFormGroup({
        quantity: new UntypedFormControl('', Validators.required),
    });   
    
    public featureShowAndCalculateExpectedDeliveryTime  = this.featuresService.ShowAndCalculateExpectedDeliveryTime;

    public featureEstimatedDeliveryDate: boolean = this.featuresService.EstimatedDeliveryDate;
    public featureSelectDeliveryDate: boolean = this.featuresService.SelectDeliveryDate;
    public featureSummarizeInvoices: boolean = this.featuresService.SummarizeInvoices;
    public featureShipOrderByDistributor: boolean = this.featuresService.ShipOrderByDistributor;
    public featureAddReasonsForPartialDelivery: boolean = this.featuresService.AddReasonsForPartialDelivery;
    public featureShowReceiverPhoneNumberAtCheckOutPage: boolean = this.featuresService
        .ShowReceiverPhoneNumberAtCheckOutPage;
    public featureShowSalesRepPhoneNumber  = this.featuresService.ShowSalesRepPhoneNumber;

    public settingDistributorPortalSelectDeliveryDate: boolean;
    private settingAllowEstimatedDeliveryDateOptional: boolean;
    public modalConfirmPartialDeliveryModal$: Observable<ModalsState>;
    public modalDistributorOrderConfirmReceivedConditionState$: Observable<ModalsState>;
    public modalDistributorOrderRejectState$: Observable<ModalsState>;
    public modalDistributorOrderConfirmEditState$: Observable<ModalsState>;
    public settings$: Observable<HotSettingsExtended>;
    public settingsStore: SettingsStoreModel;
    public settingsStore$: Observable<SettingsStoreModel>;
    public currencyFormat$: Observable<CurrencyFormat>;
    public settingsAppOnline$: Observable<boolean>;
    public locale: string;
    public modalDistributorOrderRejectListState$: Observable<ModalsState>;

    public readonly orderStatus = OrderStatus;
    public preferredDeliveryDate: string;
    public estimatedDeliveryDate: string;
    public currentDeliveryDate: string;
    public createdDate: string;
    public deliveryDate: string;

    public isWeekCalendar: boolean;

    public minPreferredDeliveryDate: Date;
    public maxPreferredDeliveryDate: Date;

    public authUser$: Observable<HotUserExtended>;

    public newProductQuantity: number = 0;
    
    private distributorConfirmStatus: string;
    public salesRepPhoneNumber: string;
    private showDeliveryDateForDeliveredOrders: boolean;
    private showTheFutureDaysForEstimatedDelivery: boolean;
    public confirmOrderDeliveryRequest: HotConfirmOrderDeliveryRequest;
    public orderItems: HotOrderLineItemExtended[];
    public emptiesOrderItems: HotOrderLineItemExtended[];
    public featureReturnEmpties: boolean = this.featuresService.ReturnEmpties;
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    public isVnB2b = ()=> this.configurationService.getCurrentConfiguration()=== 'VN';

    public customerPartialDelivered = 'delivered.distributor.partialdelivered';

    // ======= GETTERS FOR TEMPLATE =======
    public get canAddItem(): boolean {
        return (
            (this.order.status === OrderStatus.NewOrder ||
                this.order.status === OrderStatus.SentToDistributor ||
                this.isVanSalesManMode) &&
            this.canManageItems &&
            !this.order.isBonusPointsOrder
        );
    }

    public get canConfirmOrder(): boolean {
        return (
            this.order.status !== 'Confirmed' &&
            this.order.items.length &&
            ((this.settingDistributorPortalSelectDeliveryDate ? !!this.selectedDate : true) ||
                this.settingAllowEstimatedDeliveryDateOptional)
        );
    }

    public get isOrderListReadonly(): boolean {
        return (
            !this.canManageItems ||
            this.order.isBonusPointsOrder ||
            this.order.status === OrderStatus.Cancelled ||
            this.order.status.toLowerCase().indexOf('cancelled') >= 0
        );
    }

    public get isShowDeliveredColumn(): boolean {
        const checkStatus =
            (this.order.status === this.orderStatus.Delivered || this.order.status === this.orderStatus.Confirmed) &&
            !this.isOpcoDistributorMode;
        const checkQuantity =
            this.featureSummarizeInvoices &&
            !this.order.hasInvoice &&
            this.order.items.some((item) => item.originalQuantity != null); // 0 - need to be a true
        return this.order.hasInvoice || checkStatus || checkQuantity;
    }

    public get isShowDeliveryDateInput(): boolean {
        return (
            !this.isVanSalesManMode &&
            (this.order.status === 'New Order' || this.order.status === 'Sent to distributor') &&
            ((this.user.userType === 'Distributor' &&
                this.featuresService.ManageOrdersOnDistributorPortalByDistributor) ||
                this.user.userType === 'SubDistributor') &&
            this.settingsStore.distributorPortalSelectDeliveryDate
        );
    }

    public get isShowSelectedDeliveryDateValue(): boolean {
        return (
            (!this.settingsStore.distributorPortalOrderStatusesForDisplayingDeliveryDate ||
                this.settingsStore.distributorPortalOrderStatusesForDisplayingDeliveryDate.some(
                    (s) => s === this.order.status
                )) &&
            (!this.settingDistributorPortalSelectDeliveryDate ||
                (!this.canManage && !this.isOpcoDistributorMode && !!this.selectedDate))
        );
    }

    public get isShowDeliveryDateValue(): boolean {
        const hasDeliveryDateOrIsNewOrder: boolean =
            this.order.deliveryDate.formattedValue !== 'Undefined' || this.order.status === 'New Order';
        const incorrectCase: boolean =
            !this.featuresService.ManageOrdersOnDistributorPortalByDistributor &&
            this.settingsStore.distributorPortalSelectDeliveryDate &&
            this.user.userType === 'Distributor';
        const isVisible: boolean =
            !this.isOpcoDistributorMode &&
            this.order.status !== 'Cancelled' &&
            hasDeliveryDateOrIsNewOrder &&
            !incorrectCase &&
            (!this.showDeliveryDateForDeliveredOrders ||
                (this.showDeliveryDateForDeliveredOrders && this.order.status === 'Delivered')) &&
            (!this.settingsStore.distributorPortalSelectDeliveryDate ||
                (this.settingsStore.distributorPortalSelectDeliveryDate && this.order.status !== 'New Order'));
        return isVisible;
    }

    public get isShowPreferredDeliveryDateValue(): boolean {
        return (
            !this.isOpcoDistributorMode &&
            this.featureSelectDeliveryDate &&
            this.order.preferredDeliveryDate.formattedValue !== 'Undefined' &&
            this.order.deliveryDate.formattedValue === 'Undefined'
        );
    }

    public get isShowEstimatedDeliveryDateValue(): boolean {
        return (
            !this.isOpcoDistributorMode &&
            this.featureEstimatedDeliveryDate &&
            this.order.estimatedDeliveryDate.formattedValue !== 'Undefined' &&
            this.order.deliveryDate.formattedValue === 'Undefined'
        );
    }
    // ======= END OF GETTERS FOR TEMPLATE =======

    private readonly settingsLanguageCurrent$: Observable<HotLanguage>;

    private readonly unsubscribe$ = new Subject();

    private user: HotUserExtended;
    public ShowDistributorOrderEditOption: boolean = true ;
    public isUpLg = () => this.screenDimensionService.upLg();
    public isRTL = this._document.documentElement.dir === 'rtl';
    public featureAllowDistributorsToCancelOrdersAfterConfirmation = this.featuresService
        .AllowDistributorsToCancelOrdersAfterConfirmation;

    constructor(
        private readonly store: Store<AppState>,
        private readonly distributorOrdersClient: DistributorOrdersClient,
        private readonly alertService: AlertService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        public readonly featuresService: FeaturesService,
        public readonly modalService: ModalService,
        private readonly dateFormatParserService: DateFormatParserService,
        private readonly lineItemsCountService: LineItemsCountService,
        private readonly vanSalesManOrdersClient: VanSalesManOrdersClient,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly productService: ProductService,
        public readonly calendarHelper: CalendarHelperService,
        private readonly configurationService: ConfigurationService,
        public readonly orderDetailsService: OrderDetailsService,
        private readonly hotApiDistributorsService: HotApiDistributorsService,
        @Inject(DOCUMENT) private readonly _document: Document,
        private readonly distributorOrdersService: DistributorOrdersService
    ) {
        this.hotSettings$ = this.store.pipe(select(settingsData));
        this.modalDistributorOrderRejectState$ = this.store.pipe(
            select(selectModalState(ModalIds.distributorOrderReject))
        );
        this.modalDistributorOrderConfirmEditState$ = this.store.pipe(
            select(selectModalState(ModalIds.distributorOrderConfirmEdit))
        );
        this.modalDistributorOrderConfirmReceivedConditionState$ = this.store.pipe(
            select(selectModalState(ModalIds.distributorOrderConfirmReceivedCondition))
        );
        this.modalConfirmPartialDeliveryModal$ = this.store.pipe(
            select(selectModalState(ModalIds.confirmPartialDeliveryModal))
        );
        this.settings$ = this.store.pipe(select(settingsData));
        this.settingsAppOnline$ = this.store.pipe(select(settingsAppOnLine));
        this.settingsLanguageCurrent$ = this.store.pipe(
            select(settingsLanguageCurrent),
            filter((language: HotLanguage) => language != null)
        );
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.authUser$ = this.store.pipe(select(authUser));
        this.modalDistributorOrderRejectListState$ = this.store.pipe(
            select(selectModalState(ModalIds.distributorOrderRejectList))
        );
    }

    public ngAfterViewInit(): void {
        if (!this.isUpLg()) {
            const headerElement = document.getElementById('statuses');
            if (headerElement) {
                window.scrollTo(0, 0);
            }
        }
    }

    private handelPlatformIsVn(){
        if(this.isVnB2b()){ this.hotSettings$.subscribe((settings:HotSettingsExtended)=>{
            this.ShowDistributorOrderEditOption = settings.showDistributorOrderEditOption;
            this.authUser$.subscribe((userData: HotUserExtended) => {
            const findRestricStoreId: boolean = settings.pilotDistributors.includes(userData.storeId);
            this.restricStoreId = !findRestricStoreId;
        })
        })
    }
    }

    private handelOrderRejectionReason(){
        if(this.order?.distributorRejectionReason && this.order.distributorRejectionReason.includes(',')) {
            this.order.distributorRejectionReason = this.order.distributorRejectionReason.split(',')[0];
        }
        if(this.order?.hotOrderRejectionReason?.length && this.order.hotOrderRejectionReason[0].includes(',')) {
            this.order.hotOrderRejectionReason = this.order.hotOrderRejectionReason.map((rejectionReason)=> rejectionReason.split(',')[0]);
        }
    }

    public ngOnInit(): void {
        this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
            this.dateFormatFromMoment = this.dateFormatParserService.parseDateFormatForMoment(settings.dateFormat);
            this.dateFormatFromDatePipe = this.dateFormatParserService.parseDateFormatForDatePipe(settings.dateFormat);
            this.showTheFutureDaysForEstimatedDelivery = settings?.showTheFutureDaysForEstimatedDelivery;


        });
        this.handelPlatformIsVn();
        this.settingsStore$
            .pipe(withLatestFrom(this.authUser$), takeUntil(this.unsubscribe$))
            .subscribe(([settings, user]: [SettingsStoreModel, HotUserExtended]) => {
                this.distributorConfirmStatus = settings?.distributorConfirmStatus;
                this.isWeekCalendar = settings?.preferredDeliveryDateAsWeekOnCheckoutPage;
                this.settingDistributorPortalSelectDeliveryDate = settings?.distributorPortalSelectDeliveryDate;
                this.settingAllowEstimatedDeliveryDateOptional = settings?.allowEstimatedDeliveryDateOptional;

                if (!this.settingDistributorPortalSelectDeliveryDate) {
                    this.distributorSettingPortal();
                }
                this.settingsStore = settings;
                this.user = user;

                if (settings.distributorDeliveryIntervalDays > 0) {
                    this.minPreferredDeliveryDate = this.showTheFutureDaysForEstimatedDelivery
                        ? moment().toDate()
                        : moment().add(-3, 'months').toDate();
                    this.maxPreferredDeliveryDate = moment()
                        .add(this.settingsStore.distributorDeliveryIntervalDays, 'days')
                        .toDate();
                }

                this.showDeliveryDateForDeliveredOrders =
                    this.settingsStore.distributorPortalOrderStatusesForDisplayingDeliveryDate &&
                    this.settingsStore.distributorPortalOrderStatusesForDisplayingDeliveryDate.indexOf('Delivered') >=
                        0;
                this.salesRepPhoneNumber = this.order?.salesRepresentativePhoneNumber;
            });

        this.settingsLanguageCurrent$.pipe(takeUntil(this.unsubscribe$)).subscribe((language: HotLanguage) => {
            this.locale = language.code;
        });

        this.lineItemsCountService.getItemsCount(of(this.order.items)).subscribe();
        this.itemsCount$ = this.lineItemsCountService.count$;

        this.setCalendarConfig();
        this.setDefaultDate();

        this.currencyFormat$ = this.order.isBonusPointsOrder
            ? this.store.pipe(select(settingsLoyaltyCurrencyFormat))
            : this.store.pipe(select(settingsCurrencyFormat));

        this.originalOrder = cloneDeep(this.order);

        this.preferredDeliveryDate = this.setDateFormat(this.order.preferredDeliveryDate.value);
        this.estimatedDeliveryDate = this.setDateFormat(this.order.estimatedDeliveryDate.value);
        this.createdDate = this.setDateFormat(this.order.createdDate);
        if (this.order.deliveryDate.formattedValue !== 'Undefined') {
            this.deliveryDate = this.setDateFormat(this.order.deliveryDate.value);
        }

        this.currentDeliveryDate = this.setDateFormat(moment().toString());

        this.modalService.modalAction$.pipe(takeUntil(this.unsubscribe$)).subscribe((action: string) => {
            this.serviceModalAction(action);
        });

        this.productService.removedProduct.subscribe((removedProduct: HotProductExtended) => {
            if (removedProduct) {
                this.removeFromCart.emit({ id: removedProduct.id, productId: removedProduct.productId });
                this.productService.removedProduct.next(null);
            }
        });

        this.setConfirmButtonCondition();
        this.setOrderItems();
        this.handelOrderRejectionReason();
    }
    public distributorSettingPortal() {
        if (this.featureShowAndCalculateExpectedDeliveryTime) {
            this.selectedDate =
                this.order.preferredDeliveryDate.formattedValue !== 'Undefined'
                    ? this.order.preferredDeliveryDate.formattedValue
                    : this.setDateFormat(new Date().toString());
        } else {
        this.selectedDate =
            this.order.deliveryDate.formattedValue !== 'Undefined'
                ? this.order.deliveryDate.formattedValue
                : this.setDateFormat(new Date().toString());
        }
    }
    public serviceModalAction(action) {
        if (action === 'DistributorOrderReject') {
            this.openRejectModal();
        } else if (action === 'DistributorOrderCancelEdit') {
            this.discardChanges();
        } else if (action === 'DistributorOrderConfirm') {
            this.confirmOrder(this.order.id);
        } else if (action === 'DistributorOrderConfirmEdited') {
            this.emitConfirmChanges();
        } else if (action === 'DispatchOrder') {
            this.shipOrder(this.order.id);
        } else if (action === 'VsmOrderConfirm') {
            this.confirmOrderVsm(this.order.id);
        } else if (action === 'DistributorOrderConfirmEdit') {
            this.openConfirmEditModal();
        } else if (action === 'DistributorOrderConfirmReceivedCondition') {
            this.modalService.toggleModal(ModalIds.distributorOrderConfirmReceivedCondition, true);
        } else if (action === 'confirmPartialDeliveryModal') {
            this.modalService.toggleModal(ModalIds.confirmPartialDeliveryModal, true);
        } else if (action === 'DistributorOrderRejectList') {
            this.modalService.toggleModal(ModalIds.distributorOrderRejectList, true);
        }
    }

    private setOrderItems(): void {
        this.orderItems = this.order.items.filter((item: HotOrderLineItemExtended) => !item.isReturnableEmpty);
        this.emptiesOrderItems = this.order.items.filter((item: HotOrderLineItemExtended) => item.isReturnableEmpty);
    }

    private setConfirmButtonCondition() {
        if (this.selectedDate) {
            this.modalService.disableDistributorConfirmEditButton$.next(false);
        }
        this.modalService.disableConfirmButton$.next(!this.canConfirmOrder && !this.isOpcoDistributorMode);
    }

    private setDateFormat(date: string): string {
        if (this.isWeekCalendar) {
            return this.dateFormatParserService.weekFormat(date);
        } else {
            return this.dateFormatParserService.dateFormat(date);
        }
    }

    private setCalendarConfig(): void {
        this.calendarConfig = {
            format: this.dateFormatFromMoment,
            disableKeypress: true,
            max: dayjs().add(this.settingsStore.distributorDeliveryIntervalDays, 'days'),
            drops: 'down',
            icon: false,
        };
    }

    private setDefaultDate(): void {
        if (this.featureShowAndCalculateExpectedDeliveryTime &&
            this.order.preferredDeliveryDate &&
            this.order.preferredDeliveryDate.formattedValue &&
            this.order.preferredDeliveryDate.formattedValue.toLowerCase() !== 'undefined' &&
            this.settingDistributorPortalSelectDeliveryDate
        ) {
            this.selectedDate = moment(this.order.preferredDeliveryDate.value, 'YYYY-MM-DD');
            this.modalService.disableDistributorConfirmEditButton$.next(false);  
        } else if (
            this.order.deliveryDate?.formattedValue &&
            this.order.deliveryDate.formattedValue.toLowerCase() !== 'undefined' &&
            this.settingDistributorPortalSelectDeliveryDate
        ) {
            this.selectedDate = moment(this.order.deliveryDate.value, 'YYYY-MM-DD');
            this.modalService.disableDistributorConfirmEditButton$.next(false);
        }
    }

    public setDate($event: moment.Moment): void {
        this.selectedDate = $event;
        this.setConfirmButtonCondition();
    }

    public discardChanges(): void {
        this.order = cloneDeep(this.originalOrder);
        this.isChanged = false;
        this.cancelChanges.emit();
        this.modalService.modalDistributorOrderChanged$.next(false);
        this.modalService.modalAction$.next(null);
        this.lineItemsCountService.getItemsCount(of(this.order.items)).subscribe();

        localStorage.setItem(
            this.isVanSalesManMode ? StorageKeys.vanSalesManOrderItems : StorageKeys.distributorOrderItems,
            JSON.stringify(this.order.items)
        );
    }

    public emitConfirmChanges(): void {
        if (this.featuresService.AddReasonsByDistributorWhenChangeItems) {
            this.openConfirmEditModal();
        } else {
            this.confirmChangesHandler(null);
        }
    }

    public confirmChangesHandler(reason: any): void {
        const formattedDate = this.getFormattedDeliveryDate();
        this.confirmChanges.emit({ order: this.order, deliveryDate: formattedDate, reason: reason });
        this.modalService.modalAction$.next(null);
    }
    public onPutOrderReason(request: any): void {
        if (this.featuresService.AddReasonsByDistributorWhenChangeItems) {
            this.closeConfirmEditModal();
            this.confirmChangesHandler(request.reason);
        }
    }
    public confirmOrder(orderId: string): void {
        const formattedDate = this.getFormattedDeliveryDate();

        this.distributorOrdersClient
            .confirmOrder(orderId, formattedDate)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.order.status = this.orderStatus.Confirmed;
                    this.order.extendedStatus = 'confirmed.distributor.default';
                    this.order.items.forEach(
                        (orderItem: HotOrderLineItemExtended) => (orderItem.orderStatus = this.orderStatus.Confirmed)
                    );
                    this.modalService.toggleModal(ModalIds.distributorOrderConfirmed, true);
                    this.modalService.toggleModal(ModalIds.distributorOrderConfirmEdit, false);
                    this.modalService.modalAction$.next(null);
                    this.updateData.emit(this.order);
                },
                error: (error: HttpErrorResponse) => {
                    if (error && error.error === 'Requested order cannot be updated in current status') {
                        this.modalService.toggleModal(ModalIds.distributorOrderConfirmEdit, false);

                        this.updateOrderAfterErrorAction();
                    }
                }
            });
        this.modalService.modalAction$.next(null);
    }

    public confirmOrderDeliveryByDistributor(request: HotConfirmOrderDeliveryRequest) {
        if (this.featureAddReasonsForPartialDelivery && !request.isDeliveredInFull) {
            this.confirmOrderDeliveryRequest = request;
            this.modalService.toggleModal(ModalIds.distributorOrderConfirmReceivedCondition, false);
            this.modalService.toggleModal(ModalIds.confirmPartialDeliveryModal, true);
        } else {
            this.confirmOrderDelivery(request);
        }
    }

    public confirmOrderDelivery(request: HotConfirmOrderDeliveryRequest) {
        this.hotApiDistributorsService
            .confirmOrderDeliveryByDistributor(request)
            .pipe(take(1))
            .subscribe({
                next: (operationResult: HotOperationResult) => {
                    this.finishOrderDeliveryByDistributorProcess();
                    if (!operationResult.succeeded) {
                        this.reportAnError(operationResult.errors);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    if (error?.error) {
                        this.finishOrderDeliveryByDistributorProcess();
                        this.reportAnError([error.error]);
                    }
                }
            });
    }

    private finishOrderDeliveryByDistributorProcess() {
        this.modalService.modalAction$.next(null);
        this.modalService.toggleModal(ModalIds.distributorOrderConfirmReceivedCondition, false);
        this.modalService.toggleModal(ModalIds.confirmPartialDeliveryModal, false);
        this.confirmOrderDeliveryRequest = {};
        this.updateOrderFromRemote();
    }

    private updateOrderFromRemote() {
        this.distributorOrdersService
            .getOrderById(this.order.id)
            .pipe(take(1))
            .subscribe((data: HotOrderExtended) => {
                this.order = data;
                this.updateData.emit(data);
            });
    }
    private updateOrderAfterErrorAction() {
        this.distributorOrdersService
            .getOrderById(this.order.id)
            .pipe(take(1))
            .subscribe((data: HotOrder) => {
                this.order = data;
                this.updateData.emit(data);
                this.modalService.toggleModal(ModalIds.notifyMe, true, {
                    title: 'shared.modals.cancel-order-confirmation.notification.title',
                    content: 'shared.modals.cancel-order-confirmation.order-changed',
                    isNotification: true,
                });
            });
    }

    public confirmOrderVsm(orderId: string): void {
        this.vanSalesManOrdersClient.confirmOrder(orderId).subscribe(() => {
            this.order.status = this.orderStatus.ConfirmedDelivery;
            this.order.extendedStatus = 'confirmed-delivery.distributor.default';
            this.order.items.forEach(
                (orderItem: HotOrderLineItemExtended) => (orderItem.orderStatus = this.orderStatus.ConfirmedDelivery)
            );
            this.updateData.emit(this.order);
        });

        this.modalService.modalAction$.next(null);
    }

    public shipOrder(orderId: string): void {
        this.distributorOrdersClient.shipOrder(orderId).subscribe(() => {
            this.modalService.modalAction$.next(null);
            this.order.status = this.orderStatus.OnTheWay;
            this.order.extendedStatus = 'on-the-way.distributor.default';
            this.order.items.forEach(
                (orderItem: HotOrderLineItemExtended) => (orderItem.orderStatus = this.orderStatus.OnTheWay)
            );
            this.updateData.emit(this.order);
        });
    }

    public addProduct(): void {
        const items: HotUpdateOrderItem[] = this.order.items.map((item: HotOrderLineItemExtended) => {
            return {
                sku: item.sku,
                quantity: this.isVanSalesManMode ? item.deliveredQuantity : item.quantity,
                lineitemId: item.id,
                isGift: item.isGift,
            };
        });

        if (this.productToAdd) {
            const existingItem: HotUpdateOrderItem = items.find(
                (item: HotOrderLineItemExtended) => item.sku === this.productToAdd.sku && !item.isGift
            );

            if (existingItem) {
                existingItem.quantity += this.newProductQuantity;
            } else {
                items.push({ sku: this.productToAdd.sku, quantity: this.newProductQuantity });
            }

            if (this.isVanSalesManMode) {
                this.vanSalesManOrdersClient.recalculateOrder(this.order.id, items).subscribe({
                    next: (order: HotOrder) => {
                        this.recalculateOrder(order, StorageKeys.vanSalesManOrderItems);
                        this.lineItemsCountService.getItemsCount(of(order.items)).subscribe();
                    },
                    error: (error: any) => {
                        this.alertService.error(error);
                    }
                });
            } else {
                this.distributorOrdersClient.recalculateOrder(this.order.id, items).subscribe({
                    next: (order: HotOrder) => {
                        this.recalculateOrder(order, StorageKeys.distributorOrderItems);
                        this.lineItemsCountService.getItemsCount(of(order.items)).subscribe();
                    },
                    error: (error: any) => {
                        this.alertService.error(error);
                    }
                });
            }

            this.newProductQuantity = 0;
        }
    }

    public onSelectItem(item: HotProductSearchLightItem): void {
        this.productToAdd = item;
    }

    public onSearchItems(items: HotProductSearchLightItem[]): void {
        if (items && items.length === 1) {
            window.addEventListener('keydown', (event: KeyboardEvent) => {
                if (event.key === 'Enter') {
                    this.productToAdd = items[0];
                }
            });
        }
    }

    public openRejectModal(): void {
        this.modalService.toggleModal(ModalIds.distributorOrderReject, true, { status: this.order.status });
        this.modalService.modalAction$.next(null);
    }

    public closeRejectModal(performAction = false): void {
        if (performAction) {
            this.order.status = this.orderStatus.Cancelled;
            this.order.extendedStatus = 'cancelled.distributor.default';
            if (typeof performAction === 'string') {
                this.order.distributorRejectionReason = performAction;
            }
            this.order.items.forEach(
                (item: HotOrderLineItemExtended) => (item.orderStatus = this.orderStatus.Cancelled)
            );
            this.updateData.emit(this.order);
        }
        this.modalService.toggleModal(ModalIds.distributorOrderReject, false, { status: this.order.status });
    }
    public buildCreationDate(date): Observable<string> {
        return this.orderDetailsService.setOrderCreationDateFormat(date);
    }
    public openConfirmEditModal(): void {
        this.modalService.toggleModal(ModalIds.distributorOrderConfirmEdit, true);
        this.modalService.modalAction$.next(null);
    }

    public closeConfirmEditModal(): void {
        this.modalService.toggleModal(ModalIds.distributorOrderConfirmEdit, false, { status: this.order.status });
    }
    public rejectOrderErrorHandler(_error: boolean) {
        this.modalService.toggleModal(ModalIds.distributorOrderReject, false);
        this.updateOrderAfterErrorAction();
    }

    private getFormattedDeliveryDate(): string {
        return moment(
            this.settingDistributorPortalSelectDeliveryDate ? this.selectedDate : this.currentDeliveryDate,
            'ddd, DD/MM/YYYY'
        )
            .utcOffset(0, true)
            .toISOString();
    }

    private recalculateOrder(order: HotOrder, localStorageKey: string): void {
        this.order = order;
        this.setOrderItems();
        this.isChanged = true;
        this.modalService.modalDistributorOrderChanged$.next(true);
        this.productToAdd = null;
        this.addProductForm.controls.quantity.setValue('');
        this.changeDetectorRef.markForCheck();
        localStorage.setItem(localStorageKey, JSON.stringify(order.items));
    }

    public setNewProductQuantity($event: any): void {
        this.newProductQuantity = $event.quantity;
    }

    public rejectOrderByDistributor(request: HotCancelOrderRequest) {
        this.distributorOrdersClient
            .cancelOrder(request)
            .pipe(take(1))
            .subscribe((updatedOrder: HotOrder) => {
                this.modalService.toggleModal(ModalIds.distributorOrderRejectList, false);
                this.modalService.modalAction$.next(null);

                this.order.status = updatedOrder.status;
                this.order.extendedStatus = updatedOrder.extendedStatus;
                this.order.cancelledBy = updatedOrder.cancelledBy;
                this.order.hotOrderRejectionReason = updatedOrder.hotOrderRejectionReason;

                this.updateData.emit(this.order);
            });
    }

    public rejectOrder() {
        this.modalService.toggleModal(ModalIds.distributorOrderRejectList, true);
    }

    public resize(): void {
        this.isUpLg = () => this.screenDimensionService.upLg();
    }
    private showNotificationModal(keyContent: string) {
        this.modalService.toggleModal(ModalIds.notifyMe, true, {
            title: 'shared.modals.cancel-order-confirmation.notification.title',
            content: keyContent,
            isNotification: true,
        });
    }

    private reportAnError(errors: string[]) {
        if (errors.some((error: string) => error === 'Order delivery cannot be confirmed in current order status')) {
            this.showNotificationModal('shared.modals.cancel-order-confirmation.order-changed');
        } else {
            this.showNotificationModal(errors.join('. ') + '.');
        }
    }

    public getReasons(orderReason: string): Array<string> {
        if (!orderReason) return [];
        return orderReason.split('|');
    }
    
    public ngOnDestroy(): void {
        this.modalService.modalDistributorOrderChanged$.next(false);
        if (!this.settingAllowEstimatedDeliveryDateOptional) {
            this.modalService.disableDistributorConfirmEditButton$.next(true);
        }
        this.unsubscribe$.next(undefined);
        this.unsubscribe$.complete();
    }
}
