import { modalsInitialState } from '@hot-b2b/store/modals/state';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { ModalsActions } from '@hot-b2b/store/modals/actions';
import { EModalsActions } from '@hot-b2b/store/modals/types';
import { toggleModal } from '@hot-b2b/store/modals/helpers';

export function modalsReducer(state = modalsInitialState, action: ModalsActions): ModalsState[] {
    if (action.type === EModalsActions.MODAL_TOGGLE) {
        return toggleModal(action.payload, state);
    } else {
        return state;
    }
}
