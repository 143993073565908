import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, from, of } from 'rxjs';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { ForceUpgrade } from '../shared/services/force-upgrade-service';
import { App } from '@capacitor/app';
import { AppLauncher } from '@capacitor/app-launcher';

@Component({
    selector: 'force-upgrade',
    templateUrl: './force-upgrade.component.html',
})
export class ForceUpgradeComponent implements OnInit {
    private readonly subscriptionDestroy$ = new Subject<boolean>();
    private settings$: Observable<HotSettingsExtended>;
    public showForceUpgradePopup = false;
    public static readonly DOT_URL_ANDROID = 'https://play.google.com/store/apps/details?id=com.heineken.dotb2b';
    public static readonly VN_URL_ANDROID = 'https://play.google.com/store/apps/details?id=com.hvbb.hvndathang2';
    public static readonly QUICK_DRINKS_URL_ANDROID =
        'https://play.google.com/store/apps/details?id=com.quickdrinks.dotb2b';
    public static readonly TINHTOS_URL_ANDROID = 'https://play.google.com/store/apps/details?id=com.tinhtos.dotb2b';
    public static readonly DOT_URL_IOS = 'https://apps.apple.com/us/app/heineken-b2b-dot/id1545367760';
    public static readonly QUICK_DRINKS_URL_IOS = 'https://apps.apple.com/us/app/quickdrinks-ng/id1575540265';
    public static readonly TINHTOS_URL_IOS = 'https://apps.apple.com/us/app/tinhtos/id1601405279';
    public static readonly VN_URL_IOS = 'https://apps.apple.com/in/app/hvn-%C4%91%E1%BA%B7t-h%C3%A0ng-2-0/id1606316732';

    constructor(private readonly store: Store<AppState>, public forceUpgradeService: ForceUpgrade) {
        this.settings$ = this.store.pipe(select(settingsData));
    }

    ngOnInit(): void {
        if (this.isNativeApp()) {
            let settingsValue = undefined;
            this.settings$
                .pipe(
                    take(1),
                    switchMap((settings: HotSettingsExtended) => {
                        settingsValue = settings;
                        return this.getAppVersionObservable();
                    }),
                    takeUntil(this.subscriptionDestroy$)
                )
                .subscribe((appVersion: any) => {
                    if (appVersion !== null) {
                        this.showForceUpgradePopup = this.forceUpgradeService.forceUpgradeApp(settingsValue);
                        settingsValue = undefined;
                    } else {
                        this.showForceUpgradePopup = false;
                    }
                });
        }
    }

    private isNativeApp(): boolean {
        return Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios';
    }

    private getAppVersionObservable(): Observable<any> {
        return from(this.forceUpgradeService.getAppVersion()).pipe(
            catchError((error) => {
                return of(null);
            })
        );
    }

    async gotoAppStore() {
        let applicationId: string;
        try {
            applicationId = (await App.getInfo()).id;
            if (applicationId != undefined) {
                if (Capacitor.getPlatform() === 'android') {
                    this.navigateToPlayStore(applicationId);
                } else if (Capacitor.getPlatform() === 'ios') {
                    this.navigateToAppStore(applicationId);
                } else {
                    console.log('Unsupported device');
                }
            }
        } catch (error) {
            console.log('error occured', error);
        }
    }

    navigateToPlayStore(packageName: string): void {
        if (packageName != '') {
            switch (packageName) {
                case 'com.quickdrinks.dotb2b':
                    {
                        AppLauncher.openUrl({ url: ForceUpgradeComponent.QUICK_DRINKS_URL_ANDROID });
                    }
                    break;
                case 'com.hvbb.hvndathang2':
                    {
                        AppLauncher.openUrl({ url: ForceUpgradeComponent.VN_URL_ANDROID });
                    }
                    break;
                case 'com.tinhtos.dotb2b':
                    {
                        AppLauncher.openUrl({ url: ForceUpgradeComponent.TINHTOS_URL_ANDROID });
                    }
                    break;
                case 'com.heineken.dotb2b': {
                    AppLauncher.openUrl({ url: ForceUpgradeComponent.DOT_URL_ANDROID });
                }
            }
        }
    }

    navigateToAppStore(packageName: string) {
        if (packageName != '') {
            switch (packageName) {
                case 'com.heineken.quickdrinks':
                    {
                        AppLauncher.openUrl({ url: ForceUpgradeComponent.QUICK_DRINKS_URL_IOS });
                    }
                    break;
                case 'com.hvbb.hvndathang2':
                    {
                        AppLauncher.openUrl({ url: ForceUpgradeComponent.VN_URL_IOS });
                    }
                    break;
                case 'com.heineken.tinhtos':
                    {
                        AppLauncher.openUrl({ url: ForceUpgradeComponent.TINHTOS_URL_IOS });
                    }
                    break;
                case 'com.heineken.b2bdot': {
                    AppLauncher.openUrl({ url: ForceUpgradeComponent.DOT_URL_IOS });
                }
            }
        }
    }
}
