import { CarouselsActions } from "./actions";
import { CarouselsState } from "./model";
import { ECarouselsActions } from "./types";
import { carouselInitialState } from '@hot-b2b/store/carousels/state';



export function carouselsReducer(state: CarouselsState = carouselInitialState, action: CarouselsActions): CarouselsState {
    switch (action.type) {
        case ECarouselsActions.CAROUSELS_PENDING: {
            return {
                ...state,
                fetched: false,
                pending: true,
                error: null,
            };
        }
        case ECarouselsActions.CAROUSELS_FETCHED: {
            return {
                ...state,
                fetched: true,
                pending: false,
                data: action.payload
            };
        }
        
        case ECarouselsActions.CAROUSELS_ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        default:
            return state;
    }
}
