/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotSettings } from '../models/hot-settings';
import { AzureAdOptions } from '../models/azure-ad-options';
import { HotStoreSettings } from '../models/hot-store-settings';
@Injectable({
  providedIn: 'root',
})
class HotApiSettingsService extends __BaseService {
  static readonly getSettingsPath = '/storefrontapi/hot/settings';
  static readonly getAzureAdSettingsPath = '/storefrontapi/hot/azure-ad-settings';
  static readonly getStoreSettingsPath = '/storefrontapi/hot/store-settings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  getSettingsResponse(): __Observable<__StrictHttpResponse<HotSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotSettings>;
      })
    );
  }
  /**
   * @return Success
   */
  getSettings(): __Observable<HotSettings> {
    return this.getSettingsResponse().pipe(
      __map(_r => _r.body as HotSettings)
    );
  }

  /**
   * @return Success
   */
  getAzureAdSettingsResponse(): __Observable<__StrictHttpResponse<AzureAdOptions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/azure-ad-settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AzureAdOptions>;
      })
    );
  }
  /**
   * @return Success
   */
  getAzureAdSettings(): __Observable<AzureAdOptions> {
    return this.getAzureAdSettingsResponse().pipe(
      __map(_r => _r.body as AzureAdOptions)
    );
  }

  /**
   * @param fulfillmentCenterId undefined
   * @return Success
   */
  getStoreSettingsResponse(fulfillmentCenterId?: string): __Observable<__StrictHttpResponse<HotStoreSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (fulfillmentCenterId != null) __params = __params.set('fulfillmentCenterId', fulfillmentCenterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/store-settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotStoreSettings>;
      })
    );
  }
  /**
   * @param fulfillmentCenterId undefined
   * @return Success
   */
  getStoreSettings(fulfillmentCenterId?: string): __Observable<HotStoreSettings> {
    return this.getStoreSettingsResponse(fulfillmentCenterId).pipe(
      __map(_r => _r.body as HotStoreSettings)
    );
  }
}

module HotApiSettingsService {
}

export { HotApiSettingsService }
