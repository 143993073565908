import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transformWeight',
})
export class TransformWeightPipe implements PipeTransform {
    public transform(weight: number): number {
        const defaultValue = 0;

        if (!weight) {
            return defaultValue;
        }

        return weight / 1000;
    }
}
