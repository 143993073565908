import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';

import { TelemetryEventType } from '@hot-libs/shared-types';

import { AuthUser } from '@hot-b2b/store/auth/model';
import { authData, dataLoadedForPage } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { ApplicationInsightsService } from './application-insights.service';
import { HotUserExtended } from 'libs/shared-models/src/lib/account/user-extended.model';

@Injectable({
    providedIn: 'root',
})
export class TelemetryService {
    private dataLoadedForPage$: Observable<string>;
    private authData$: Observable<AuthUser>;
    private trackedUrl: string;
    public ffcId: string;
    public userData: HotUserExtended;

    constructor(
        private readonly store: Store<AppState>,
        private readonly appInsightsService: ApplicationInsightsService
    ) { }

    public startTrackingRoutingFor(url: string): void {
        this.trackedUrl = url;
        this.appInsightsService.startTrackingPage(TelemetryEventType.HomePageLoadingTime);
    }

    public subscribeToHomePageLoadedEvent(): void {
        this.authData$ = this.store.pipe(select(authData));
        this.dataLoadedForPage$ = this.store.pipe(select(dataLoadedForPage));

        this.dataLoadedForPage$
            .pipe(
                withLatestFrom(this.authData$),
                filter(([url, auth]) => auth.loggedIn && url === this.trackedUrl),
                take(1)
            )
            .subscribe(([loadedPageUrl]) => {
                const eventType = TelemetryEventType.HomePageLoadingTime;
                this.appInsightsService.endTrackingPage(loadedPageUrl, eventType);
                this.appInsightsService.endTrackingEvent(TelemetryEventType.LoginFullResponseTime);
            });
    }

}
