import { Component, Input } from '@angular/core';

@Component({
    selector: 'hot-simple-tabs',
    templateUrl: './simple-tabs.component.html',
})
export class SimpleTabsComponent {
    tabs: SimpleTabComponent[] = [];

    selectTab(tab: SimpleTabComponent) {
        this.tabs.forEach((activeTab) => {
            activeTab.active = false;
        });
        tab.active = true;
    }

    addTab(tab: SimpleTabComponent) {
        if (this.tabs.length === 0) {
            tab.active = true;
        }
        this.tabs.push(tab);
    }
}

@Component({
    selector: 'hot-simple-tab',
    templateUrl: './simple-tab.component.html',
})
export class SimpleTabComponent {
    @Input() tabTitle: string;
    active: boolean = false;

    constructor(tabs: SimpleTabsComponent) {
        tabs.addTab(this);
    }
}
