import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OrderDetailsEditModeService {
    private readonly editMode$ = new Subject<boolean>();

    public isEditMode(): Observable<boolean> {
        return this.editMode$;
    }

    public setEditMode(isActive: boolean): void {
        this.editMode$.next(isActive);
    }
}
