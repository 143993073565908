/* tslint:disable */
type OutletAccessLevel =
  'Unrestricted' |
  'Normal' |
  'ViewOnly';
module OutletAccessLevel {
  export const UNRESTRICTED: OutletAccessLevel = 'Unrestricted';
  export const NORMAL: OutletAccessLevel = 'Normal';
  export const VIEW_ONLY: OutletAccessLevel = 'ViewOnly';
  export function values(): OutletAccessLevel[] {
    return [
      UNRESTRICTED,
      NORMAL,
      VIEW_ONLY
    ];
  }
}

export { OutletAccessLevel }