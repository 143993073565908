import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Host,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, take, takeWhile } from 'rxjs/operators';

import { CurrencyFormat, HotLoyaltyStatement } from '@hot-theme-nx/generated-api';

import { RouterService } from '../../services/router.service';
import { DistributorCatalogService } from 'apps/hot-b2b/src/app/distributor/services';
import { authUser } from '@hot-b2b/store/auth/selector';
import {OfflineCatalogGet, CartDataGet } from '@hot-b2b/store/catalog/actions';
import { loyalty } from '@hot-b2b/store/loyalty/selector';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { routerCurrentUrl } from '@hot-b2b/store/router/selector';
import { SettingsStoreGet, SettingsStoreGetSuccess } from '@hot-b2b/store/settings/actions';
import {
    settingsAppOnLine,
    settingsFeaturesLinks,
    settingsLoyaltyCurrencyFormat,
    settingsStore,
    settingsSupport,
    settingsData
} from '@hot-b2b/store/settings/selector';
import { ContactSupport } from '@hot-b2b/store/settings/model';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { HotUserExtended, NavigationItemModel, SettingsStoreModel } from '@hot-libs/shared-models';
import { AccountType, ModalIds, StorageKeys } from '@hot-libs/shared-types';
import { AuthenticationService } from 'apps/hot-b2b/src/app/account/services';
import { HeaderComponent } from 'apps/hot-b2b/src/app/shared/components/header/header.component';
import { FeaturesService, ModalService, SecurityService } from 'apps/hot-b2b/src/app/shared/services';
import { InitDataManagerService } from 'apps/hot-b2b/src/app/shared/services/initdata-manager.service';
import { LoyaltyService } from 'apps/hot-b2b/src/app/shared/services/loyalty.service';
import { NavigationService } from 'apps/hot-b2b/src/app/shared/services/navigation.service';
import { WarehouseService } from '../../services/warehouse.service';
import { ConfigurationService } from '../../services/configuration.service';
import { CookiesService } from '../../services/cookies.service';
import { HotCookies } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { Location } from '@angular/common';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';

@Component({
    selector: 'hot-nav',
    templateUrl: './nav.component.html',
})
export class NavComponent implements OnInit, AfterViewChecked, AfterViewInit {
    @ViewChild('menu', { static: true }) public menu: ElementRef;
    @ViewChild('subDistributor') public subDistributor: ElementRef;
    @ViewChildren('menuItems') public menuItems!: QueryList<any>;

    public navListFiltered = [];
    public dropdownList = [];
    public showDropDownSubdistr = false;
    private subDistributorWidth: number;

    @Input() public modifier: string;
    @Input() public isDistributor = false;
    @Input() public userOutlets: any;
    @Input() public isOutletRegistrationAvailable: boolean;
    @Output() public closeMenu: EventEmitter<any> = new EventEmitter();

    private readonly subDistributorCustomer = 'Customer';
    private readonly subDistributorDistributor = 'Distributor';
    private onlineSwitchChecked: boolean;

    public readonly accountType = AccountType;

    public routerCurrentUrl$: Observable<any>;
    public authUser$: Observable<HotUserExtended>;

    public navList: NavigationItemModel[];
    public userType: string;
    public subDistributorMode: string;

    public settingsSupport$: Observable<ContactSupport>;
    public settingsAppOnLine$: Observable<boolean>;
    public loyaltyStatement$: Observable<HotLoyaltyStatement>;
    public loyaltyBalanceEnabled$: Observable<boolean>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public settingsFeaturesLinks$: Observable<string[]>;
    public settingsLoyaltyCurrencyFormat$: Observable<CurrencyFormat>;

    public modalOrderTemplatesState$: Observable<ModalsState>;
    public loyaltyEnabled$: Observable<boolean>;

    public loyaltyCurrencyFormat: CurrencyFormat;
    public displayLogoutAsText: boolean;

    public showDropdownMenu: boolean;
    public showOfflineNotificationModal: boolean;

    private readonly defaultWidth: number = 700;
    public itemUrl: string;
    public selectedRoute: string;
    public isREB2b = () => this.configurationService.getCurrentConfiguration() === 'RE';
    public isGRB2b = () => this.configurationService.getCurrentConfiguration() === 'GR';
    public isVNB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';
    private readonly settings$: Observable<HotSettingsExtended>;
    public enableRefresh: boolean;
    public featureOffInvoicePerformance: boolean = this.featuresService.EnableOffInvoicePerformanceTracking;
    private readonly featureOrdersPageForGroupAccount: boolean = this.featuresService.OrdersPageForGroupAccount;

    constructor(
        private readonly navigationService: NavigationService,
        private readonly authenticationService: AuthenticationService,
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly securityService: SecurityService,
        public readonly featuresService: FeaturesService,
        public readonly loyaltyService: LoyaltyService,
        private readonly initDataManagerService: InitDataManagerService,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly cdr: ChangeDetectorRef,
        private readonly modalService: ModalService,
        private readonly routerService: RouterService,
        private readonly distributorCatalogService: DistributorCatalogService,
        private readonly warehouseService: WarehouseService,
        private readonly configurationService: ConfigurationService,
        public readonly cookiesService: CookiesService,
        @Host() private readonly _headerQuery: HeaderComponent,
        private location : Location
    ) {
        this.routerCurrentUrl$ = this.store.pipe(select(routerCurrentUrl));

        this.authUser$ = this.store.pipe(select(authUser));
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.settingsSupport$ = this.store.pipe(select(settingsSupport));
        this.settingsStore$ = this.store.pipe(select(settingsStore));
        this.settingsFeaturesLinks$ = this.store.pipe(select(settingsFeaturesLinks));
        this.settingsLoyaltyCurrencyFormat$ = this.store.pipe(select(settingsLoyaltyCurrencyFormat));
        this.modalOrderTemplatesState$ = this.store.pipe(select(selectModalState(ModalIds.unavailableInOffline)));
        this.settings$ = this.store.pipe(select(settingsData));
        this.settings$.subscribe((settings: HotSettingsExtended) => {
            this.enableRefresh = settings.enableReloadCustomerPortal;
        });
    }
    public ngAfterViewInit(): void {
        this.selectedRoute = null;
        this.routerCurrentUrl$.subscribe((url) => {
            this.selectedRoute = this.routerService.getActualNavItem(url);
        });
    }

    public ngOnInit(): void {
        this.loyaltyEnabled$ = this.loyaltyService.loyaltyEnable();
        this.loyaltyBalanceEnabled$ = this.loyaltyService.loyaltyBalanceEnable();
        this.loyaltyStatement$ = this.store.pipe(select(loyalty));
        this.setUserType();

        this.settingsAppOnLine$.subscribe(() => {
            this.onlineSwitchChecked = false;
            if (!this.userType) {
                return;
            }

            this.setNavLinks();
        });
        
        if(this.userType === "SubDistributor") {
            this.subDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);
            if(this.subDistributorMode === 'Distributor') {
                localStorage.removeItem(StorageKeys.fulfillmentCenter);
                this.cookiesService.deleteCookie(HotCookies.CURRENT_FULFILLMENT_CENTER);
                this.cookiesService.deleteCookie(HotCookies.CURRENT_STORE);
            }
        }

    }

    public menuCloseClick(): void {
        this.closeMenu.emit();
    }

    public isUpLg = (): boolean => this.screenDimensionService.upLg();

    public logOut(): void {
        this.settingsAppOnLine$.pipe(take(1)).subscribe((isOnline: boolean) => {
            if (isOnline) {
                this.authenticationService.logout();
                localStorage.removeItem(StorageKeys.changeLanguage)
            }
        });
    }

    public setUserType(): void {
        this.authUser$.pipe(first(Boolean)).subscribe((user: HotUserExtended) => {
            this.userType = user.userType;
        });
    }

    private setNavLinks(): void {
        this.navigationService
            .getNavigationItems()
            .pipe(
                filter((navItems) => !!navItems),
                takeWhile(() => !this.onlineSwitchChecked)
            )
            .subscribe((navigationItems: NavigationItemModel[]) => {
                if (this.userType === AccountType.SubDistributor) {
                    this.subDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);
                }

                this.navList = navigationItems.filter((x) => x.hiddenInMenu !== true);
                for (const element of this.navList) {
                    if (this.isGRB2b() && element.name === "promotions") {
                        const index = this.navList.indexOf(element);
                        if (index > -1) {
                            this.navList.splice(index, 1);
                        }
                    }
                }
                this.navListFiltered = [...this.navList];
           
                if (this.isVNB2b()) {
                    if (this.featureOffInvoicePerformance) {
                        this.updateNavigationLinksForPromotionTracking();
                    }else {
                        const hasPromotionTracking = this.navListFiltered.some(item => item.name === 'promotion-tracking');
                        if (hasPromotionTracking) {
                            const promotionTrackingIndex = this.navListFiltered.findIndex(item => item.name === 'promotion-tracking');
                            this.navListFiltered.splice(promotionTrackingIndex,1);
                        }
                    }
                }
                this.onlineSwitchChecked = true;
            });
    }

 private updateNavigationLinksForPromotionTracking(): void {
     const hasPromotionTracking = this.navListFiltered.some(item => item.name === 'promotion-tracking');
     const hasPromotions = this.navListFiltered.some(item => item.name === 'promotions');

     if (hasPromotionTracking && hasPromotions) {

         const promotionIndex = this.navListFiltered.findIndex(item => item.name === 'promotions');
         const promotionTrackingIndex = this.navListFiltered.findIndex(item => item.name === 'promotion-tracking');
         let promotionTrackingDetails = this.navListFiltered.filter(item => item.name === 'promotion-tracking');

         if (promotionTrackingIndex !== -1) {
             this.navListFiltered.splice(promotionTrackingIndex, 1);
         }
         if (promotionIndex !== -1) {
             this.navListFiltered.splice(promotionIndex + 1, 0, promotionTrackingDetails[0]);
         }
     } else if (hasPromotionTracking && hasPromotions == false) {

         const myOrderIndex = this.navListFiltered.findIndex(item => item.name === 'previous-orders');
         const promotionTrackingIndex = this.navListFiltered.findIndex(item => item.name === 'promotion-tracking');
         let promotionTrackingDetails = this.navListFiltered.filter(item => item.name === 'promotion-tracking');

         if (promotionTrackingIndex !== -1) {
             this.navListFiltered.splice(promotionTrackingIndex, 1);
         }
         this.navListFiltered.splice(myOrderIndex + 1, 0, promotionTrackingDetails[0]);
     } 
}
    public onSubDistributorModeClick(): void {
        this.distributorCatalogService.isChanged.pipe(take(1)).subscribe((isChanged: boolean) => {
            if (isChanged) {
                this.router.navigateByUrl('/');
                this.modalService.setConfirmResolver(this.onSubDistributorModeClickEvent.bind(this));
            } else {
                this.onSubDistributorModeClickEvent();
            }
        });
    }

    public closeModal() {
        this.modalService.toggleModal(ModalIds.unavailableInOffline, false);
    }

    public onClick(itemUrl: string, offlineDisabled?: boolean) {
        this.settingsAppOnLine$.pipe(take(1)).subscribe((isOnline: boolean) => {
            if (!isOnline && offlineDisabled) {
                this.itemUrl = itemUrl;
                this.modalService.toggleModal(ModalIds.unavailableInOffline, true);
            } else {
                this.menuCloseClick();
                this.modalService.closeAll();
                this.router.navigateByUrl(itemUrl);
            }
        });
    }

    public onDropdownClick(itemUrl: string): void {
        this.closeDropdown();
        this.onClick(itemUrl);
    }

    public closeDropdown(): void {
        this.showDropdownMenu = false;
    }

    public toggleDropdownMenu(): void {
        this.showDropdownMenu = !this.showDropdownMenu;
    }

    public hideDropdownMenu(): void {
        this.showDropdownMenu = false;
    }

    public trackByName(_index, item): any {
        return item ? item.name : undefined;
    }

    private addWidthProperty(_arr, name, width): any {
        const arr = _arr;
        const i = arr.findIndex((el) => el.name === name);
        if (i !== -1) {
            arr[i]['width'] = width;
        }
        return arr;
    }

    private menuItemsMatching(): void {
        this.menuItems.map((item: any) => {
            const style = window.getComputedStyle(item.nativeElement);
            const width = parseInt(style.getPropertyValue('width'));
            if (width) {
                const itemName = item.nativeElement.getAttribute('data-name');
                this.navListFiltered = this.addWidthProperty(this.navListFiltered, itemName, width);
            }
        });
    }

    private checkHeaderView(): void {
        if (this.isUpLg()) {
            this.navListManipulations();
        } else {
            this.resetLists();
        }
    }

    private navListManipulations(): void {
        this.menuItemsMatching();
        
        const menuWidth = this.menu.nativeElement.offsetWidth;
        let availableWidth = this._headerQuery.getNavAvailableWidth();

        if (this.subDistributor) {
            const _style = window.getComputedStyle(this.subDistributor.nativeElement);
            const _width = parseInt(_style.getPropertyValue('width'));

            this.subDistributorWidth = _width ? _width : this.subDistributorWidth;
        }

        if (this.navListFiltered?.length && menuWidth > availableWidth) {
            this.moveNavListItemsToDropdown();
        } else if (
            this.dropdownList &&
            this.dropdownList.length > 1 &&
            menuWidth + this.dropdownList[0].width + 55 < availableWidth
        ) {
            const firstItem = this.dropdownList.shift();
            this.navListFiltered.push(firstItem);
        } else if (
            this.dropdownList &&
            this.dropdownList.length === 1 &&
            menuWidth - 28 + this.dropdownList[0].width < availableWidth
        ) {
            const firstItem = this.dropdownList.shift();

            setTimeout(() => {
                this.navListFiltered.push(firstItem);
            }, 100);
        } else if (
            !this.dropdownList.length &&
            this.showDropDownSubdistr &&
            this.subDistributorWidth &&
            menuWidth + this.subDistributorWidth < availableWidth
        ) {
            this.showDropDownSubdistr = false;
        }
    }

    private moveNavListItemsToDropdown(): void {
        if (this.subDistributor && !this.showDropDownSubdistr) {
            this.showDropDownSubdistr = true;
        } else if (this.navListFiltered?.length) {
            const navListLastItem = this.navListFiltered[this.navListFiltered?.length - 1];

            if (navListLastItem.width) {
                this.dropdownList.unshift(this.navListFiltered.pop());
            }
        }
    }

    private resetLists(): void {
        this.navListFiltered = [...this.navList];
        if (this.isVNB2b()) {
            if (this.featureOffInvoicePerformance) {
                this.updateNavigationLinksForPromotionTracking();
            }else {
                const hasPromotionTracking = this.navListFiltered.some(item => item.name === 'promotion-tracking');
                if (hasPromotionTracking) {
                    const promotionTrackingIndex = this.navListFiltered.findIndex(item => item.name === 'promotion-tracking');
                    this.navListFiltered.splice(promotionTrackingIndex, 1);
                }
            }
        }
        this.dropdownList = [];
        this.showDropDownSubdistr = false;
    }
    private onSubDistributorModeClickEvent() {
        const subDistributorMode: string = localStorage.getItem(StorageKeys.subDistributorMode);

        // Do we need to use withCart method?
        const getData = (userType: string) =>
            this.securityService.setSubDistrType(userType).pipe(
                map(() => {
                    // this.store.dispatch(new OfflineCatalogWithCartGet())
                    this.store.dispatch(new OfflineCatalogGet());
                    this.store.dispatch(new CartDataGet());
                })
            );

        switch (subDistributorMode) {
            case this.subDistributorDistributor: {
                localStorage.setItem(StorageKeys.subDistributorMode, this.accountType.Customer);
                getData(this.accountType.Customer).subscribe(() => {
                    this.initDataManagerService.getCustomerData();
                    this.store.dispatch(new SettingsStoreGet());
                    this.navigateAndRefresh('/');
                });
                this.warehouseService.deleteFullfillmentCentreCache();
                break;
            }
            case this.subDistributorCustomer: {
                localStorage.setItem(StorageKeys.subDistributorMode, this.accountType.Distributor);
                getData(this.accountType.Distributor).subscribe(() => {
                    this.store.dispatch(new SettingsStoreGetSuccess([]));
                    this.store.dispatch(new SettingsStoreGet());
                    this.initDataManagerService.getDistributorData();
                    this.navigateAndRefresh('/distributor');
                });
                this.warehouseService.deleteFullfillmentCentreCache();     
                break;
            }
        }

        this.setNavLinks();
    }

    public ngAfterViewChecked(): void {
        this.checkHeaderView();
        this.cdr.detectChanges();
    }

    public getData(navItem) {
        return `{ "data_interaction": "link_click", "button_text": "${navItem.name}", "name":"Home" }`;
    }

    private navigateAndRefresh(targetURL :string ): void{
        if(this.enableRefresh) {
            this.router.navigateByUrl(targetURL, {skipLocationChange: true}).then(() => {
                this.location.replaceState(targetURL);
                window.location.reload();
             });
        }
        else {
            this.router.navigateByUrl(targetURL);
        }
      
        }

}
