import { Injectable, NgZone } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ScreenDimensionService {
    private readonly bootstrapSmPoint = 576;
    private readonly bootstrapMdPoint = 768;
    private readonly bootstrapLgPoint = 990;
    private readonly bootstrapXlPoint = 1200;

    public breakpoint = 'xs';

    constructor(private ngZone: NgZone) {
        this.setupScreenSizeListener();
    }

    private setupScreenSizeListener(): void {
        this.ngZone.runOutsideAngular(() => {
        fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(() => {
            this.ngZone.run(() => {
                this.setBreakpointName();
            })
        });
        });
        this.setBreakpointName();
    }
    private setBreakpointName(): void {
        const windowWidth = window.innerWidth;

        switch (true) {
            case windowWidth >= this.bootstrapXlPoint:
                this.breakpoint = 'xl';
                break;
            case windowWidth >= this.bootstrapLgPoint:
                this.breakpoint = 'lg';
                break;
            case windowWidth >= this.bootstrapMdPoint:
                this.breakpoint = 'md';
                break;
            case windowWidth >= this.bootstrapSmPoint:
                this.breakpoint = 'sm';
                break;
            case windowWidth < this.bootstrapSmPoint:
                this.breakpoint = 'xs';
                break;
        }
    }

    public upXl(): boolean {
        return window.innerWidth > this.bootstrapXlPoint;
    }

    public upLg(): boolean {
        return window.innerWidth > this.bootstrapLgPoint;
    }

    public upMd(): boolean {
        return window.innerWidth > this.bootstrapMdPoint;
    }

    public upSm(): boolean {
        return window.innerWidth > this.bootstrapSmPoint;
    }

    public downSm(): boolean {
        return window.innerWidth < this.bootstrapSmPoint;
    }
}
