import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HotFulfillmentCenter } from '@hot-theme-nx/generated-api';

import { AppState } from '@hot-b2b/store/reducers';
import { warehouses } from '@hot-b2b/store/warehouses/selector';
import { StorageKeys } from '@hot-libs/shared-types';
import { TranslateService } from '@ngx-translate/core';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

@Component({
    selector: 'hot-distributor-warehouse-selector',
    templateUrl: './distributor-warehouse-selector.component.html',
})
export class DistributorWarehouseSelectorComponent implements OnInit, OnDestroy {
    @ViewChild('search') public search: ElementRef;
    @Output() public onFulfillmentCenterSelection = new EventEmitter<string>();

    public PATHS = PATHS;
    public dropdownIsOpen = false;

    public semId: string;
    public notFoundSemId: string;
    public searchString = '';

    private _docClickSubscription: any;

    public fulfillmentCenters: HotFulfillmentCenter[];
    public selectedFfc: HotFulfillmentCenter;

    private readonly subscriptionsDestroy$ = new Subject<boolean>();

    private readonly allFulfillmentCenter: HotFulfillmentCenter = {
        id: null,
        name: this.translateService.instant('shared.distributor-warehouse-selector.all-available'),
    };

    constructor(
        private readonly store: Store<AppState>,
        public renderer: Renderer2,
        private readonly ref: ChangeDetectorRef,
        private readonly translateService: TranslateService
    ) {
        this._docClickSubscription = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));
    }

    public ngOnInit(): void {
        this.setInitData();
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
        this._docClickSubscription();
    }

    /**
     * Toggles the dropdown
     */
    public toggleDropdown(isOpen?: boolean): void {
        if (!this.dropdownIsOpen) {
            return;
        }

        setTimeout(() => {
            this.dropdownIsOpen = isOpen || !this.dropdownIsOpen;

            if (this.dropdownIsOpen) {
                this.searchString = '';
                setTimeout(() => this.search.nativeElement.focus());
            }
            this.ref.detectChanges();
        }, 100);
    }

    /**
     * Closes dropdown if click outside
     */
    public clickOutside(): void {
        this.dropdownIsOpen = false;
        this.ref.detectChanges();
    }

    public selectFfc(ffc: HotFulfillmentCenter, event?): void {
        if (event) {
            event.stopPropagation();
        }

        if (this.selectedFfc.id === ffc.id) {
            this.dropdownIsOpen = false;
        } else {
            this.selectedFfc = ffc;
            this.onFulfillmentCenterSelection.emit(ffc.id);
            this.dropdownIsOpen = false;
        }
    }

    public clickOnWarehouses() {
        if (!this.dropdownIsOpen && this.fulfillmentCenters.length > 1) {
            this.dropdownIsOpen = true;
            setTimeout(() => {
                this.searchString = '';
                this.search.nativeElement.focus();
            }, 100);
        }
    }

    private setInitData() {
        this.selectedFfc = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter));
        this.store
            .pipe(select(warehouses), takeUntil(this.subscriptionsDestroy$))
            .subscribe((fulfillmentCenters: HotFulfillmentCenter[]) => {
                if (fulfillmentCenters.length <= 1) {
                    this.fulfillmentCenters = fulfillmentCenters;

                    if (fulfillmentCenters.length) {
                        this.selectedFfc = fulfillmentCenters[0];
                    }
                } else {
                    this.fulfillmentCenters = [this.allFulfillmentCenter, ...fulfillmentCenters];
                    this.selectedFfc = this.allFulfillmentCenter;
                }
            });
    }

    private onDocumentClick(e: any): void {
        if (
            !e.target.closest('.w-select') &&
            !matches(e.target, '.w-select__title, .w-select__name') &&
            this.fulfillmentCenters.length > 1
        ) {
            this.clickOutside();
        }
    }
}
