import { OrdersState } from '@hot-b2b/store/orders/model';
import { config } from 'apps/hot-b2b/src/environments/config';
import moment from 'moment';

export const ordersInitialState: OrdersState = {
    pending: false,
    fetched: false,
    data: {
        previous: [],
        latestOrder: [],
        statistics: {
            itemsQuantity: null,
            netTotal: null,
            ordersCount: null,
            bonusPointsTotal: null,
        },
        ordersResponse: {
            orders: [],
            statuses: [],
            totalCount: null,
        },
        searchOrdersCriteria: {
            isBonusPointsOrder: false,
            startDate: moment().hours(0).minutes(0).seconds(0).add((config.currentConfigurationName==='HOP') ? -3 : -1 , 'months').toISOString(),
            endDate: moment().hours(0).minutes(0).seconds(0).add(1, 'day').subtract(1, 'seconds').toISOString(),
            pageNumber: 1,
            pageSize: 50,
            sort: 'CreatedDate:desc',
        },
        customerOrderRejectReasons: [],
    },
    error: null,
    latestOrdersFetchStatus: false,
    orderSimulation: {
        fetched: false,
        taxDetails: {
            errorCodes: [],
            errors: [],
            warnings: [],
            newErrors: [],
            subtotalWithoutTax: 0,
            succeeded: false,
            totalWithTax: 0,
            validationTaxDetails: [],
            plant: '',
            deliveryMethod: '',
            paymentTerms: ''
        }
    },
    validationRequest: {
        validationRequest: {
            preferredDeliveryDate: "",
            purchaseOrderNumber: ""
        }
    }


};
