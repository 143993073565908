import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { HotApiCartsService, HotApiOrderTemplatesService } from '@hot-theme-nx/generated-api';

import { CartGet, CartGetSuccess, CartSaveTemplate, CartSavePreviousOrder } from '@hot-b2b/store/cart/actions';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { OrdersTemplateDelete, OrdersTemplateUpdate, OrderTemplateSelect } from '@hot-b2b/store/order-template/actions';
import { getSelectedTemplate, isTemplateNameExist } from '@hot-b2b/store/order-template/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { ModalIds } from '@hot-libs/shared-types';
import { TranslateService } from '@ngx-translate/core';
import { OrderTemplateModel } from 'apps/hot-b2b/src/app/order-template/models/order-template.model';
import { OrderTemplateService } from 'apps/hot-b2b/src/app/order-template/services/order-template.service';
import { ModalService, FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import cloneDeep from 'lodash/cloneDeep';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { settingsStore } from '@hot-b2b/store/settings/selector';
import { ConfigurationService } from '../../../../shared/services/configuration.service';
import { OrderSimulationReset } from '@hot-b2b/store/orders/actions';

export type OrderTemplateCreateModalType = 'create' | 'createSuccess' | 'edit' | 'delete' | 'editSuccess' | 'addToCart';

@Component({
    selector: 'hot-order-templates-modal',
    templateUrl: './order-templates-modal.component.html',
})
export class OrderTemplatesModalComponent implements OnInit {
    private duplicatedNameErrorMessage: string;
    private template: OrderTemplateModel;
    private orderId: string;
    private displayOutOfStockProductsInCustomersCatalog = false;
    private readonly doubleErrorMassage: string = 'shared.modals.order-template.error-double-name';
    public replaceLineItemsParams: HotApiCartsService.ReplaceLineItemsParams;
    public modalType: OrderTemplateCreateModalType = 'create';
    public settingsAppOnLine$: Observable<boolean>;
    public orderTemplateForm: UntypedFormGroup;
    public modalOrderTemplateState$: Observable<ModalsState>;
    public selectedOrderTemplate$: Observable<OrderTemplateModel>;
    private storeSettings$: Observable<SettingsStoreModel>;

    public featureSplitOrdersBySuppliersWhenCheckout: boolean = this.featuresService.SplitOrdersBySuppliersWhenCheckout;
    public isLAB2b = () => this.configurationService.getCurrentConfiguration() === 'RE';
    
    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly hotApiOrderTemplatesService: HotApiOrderTemplatesService,
        private readonly cartService: HotApiCartsService,
        private readonly router: Router,
        private readonly orderTemplateService: OrderTemplateService,
        private readonly fb: UntypedFormBuilder,
        private readonly translateService: TranslateService,
        private readonly featuresService: FeaturesService,
        private readonly configurationService: ConfigurationService,
    ) {
        this.modalOrderTemplateState$ = this.store.pipe(select(selectModalState(ModalIds.orderTemplates)));
        this.selectedOrderTemplate$ = this.store.pipe(select(getSelectedTemplate));
        this.translateService
            .get(this.doubleErrorMassage)
            .pipe(take(1))
            .subscribe((errorMessage) => {
                this.duplicatedNameErrorMessage = errorMessage;
            });
        this.storeSettings$ = this.store.pipe(select(settingsStore));
    }

    public ngOnInit(): void {
        this.modalOrderTemplateState$.pipe(take(1)).subscribe((modalState: ModalsState) => {
            if (modalState.data) {
                this.modalType = modalState.data.modalType;
                this.orderId = modalState.data.orderId;
            }
        });

        this.selectedOrderTemplate$.pipe(take(1)).subscribe((selectedOrderTemplate: OrderTemplateModel) => {
            this.template = selectedOrderTemplate;
            this.orderTemplateForm = this.fb.group({
                templateName: new UntypedFormControl(selectedOrderTemplate?.name || '', Validators.required),
            });
        });
        this.storeSettings$.pipe(take(1)).subscribe((storeSettings: SettingsStoreModel) => {
            this.displayOutOfStockProductsInCustomersCatalog =
                storeSettings.displayOutOfStockProductsInCustomersCatalog;
        });
    }

    public cancel(): void {
        this.modalService.toggleModal(ModalIds.orderTemplates, false);
    }

    public saveSuccess(): void {
        this.orderTemplateService.saveOrderTemplateSuccess(true);
        this.modalService.toggleModal(ModalIds.orderTemplates, false);
    }

    public removeTemplate(): void {
        this.orderTemplateService.deleteOrderTemplate(this.template.id).subscribe(() => {
            this.orderTemplateService.deleteOrderTemplateCache().then(() => {
                this.store.dispatch(new OrdersTemplateDelete(this.template.id));
                this.cancel();
            });
        });
    }

    public renameTemplate(templateName: string): void {
        this.store.pipe(select(isTemplateNameExist, templateName), take(1)).subscribe((isExist: boolean) => {
            if (isExist) {
                this.orderTemplateForm.controls.templateName.setErrors({
                    custom: this.duplicatedNameErrorMessage,
                });
            } else {
                this.hotApiOrderTemplatesService
                    .renameOrderTemplate({ templateId: this.template.id, name: templateName })
                    .subscribe(() => {
                        this.orderTemplateService.deleteOrderTemplateCache().then(() => {
                            this.template.name = templateName;
                            this.store.dispatch(new OrdersTemplateUpdate(this.template));
                            this.store.dispatch(new OrderTemplateSelect(this.template));
                            this.cancel();
                        });
                    });
            }
        });
    }

    public addToCart(replaceExistingItems: boolean): void {
        if(replaceExistingItems && this.isLAB2b()) {
            this.store.dispatch(new OrderSimulationReset());
        }
        const templateItems = cloneDeep(
            this.template.items.filter((item) => item.quantity > 0 && item.isInStock && !item.isUnavailableForCustomers)
        );
        // [ru] Calculate bottles
        if (!this.featuresService.UsePackagesWithCartsAndOrders) {
            templateItems.forEach((item) => {
                item.quantity = item.packageSize !== 0 ? Math.round(item.quantity * item.packageSize) : item.quantity;
            });
        }

        if (this.featureSplitOrdersBySuppliersWhenCheckout) {
            templateItems.forEach((item) => item.isSelected = true);
        }
        
        if (replaceExistingItems) {
            this.replaceLineItemsParams = { body: templateItems };
            this.cartService.replaceLineItems(this.replaceLineItemsParams).subscribe(() => {
                this.store.dispatch(new CartGet());
                this.navigateCheckout();
            });
        } else {
            this.cartService.addLineItems({"body": templateItems }).subscribe((data) => {
                if (this.featureSplitOrdersBySuppliersWhenCheckout) {
                    this.store.dispatch(new CartGetSuccess(data));
                }

                this.navigateCheckout();
            });
        }
    }

    private navigateCheckout(): void {
        this.modalService.toggleModal(ModalIds.orderTemplates, false);
        this.router.navigateByUrl('/checkout');
    }

    public saveOrderTemplate(templateName: string) {
        this.store.pipe(select(isTemplateNameExist, templateName), take(1)).subscribe((isExist: boolean) => {
            if (isExist) {
                this.orderTemplateForm.controls.templateName.setErrors({
                    custom: this.duplicatedNameErrorMessage,
                });
            } else {
                if (this.orderId) {
                    this.store.dispatch(new CartSavePreviousOrder({ orderId: this.orderId, name: templateName }));
                } else {
                    this.store.dispatch(new CartSaveTemplate(templateName));
                }
                this.modalType = 'createSuccess';
            }
        });
    }

    public isDuplicatedName(): boolean {
        return !!this.orderTemplateForm.controls.templateName.value && !this.orderTemplateForm.valid;
    }
}
