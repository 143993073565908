import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { AlertService, FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import { CartOfflineRemoveItem, CartRemoveItem, CartUpdateItem } from '@hot-b2b/store/cart/actions';
import { cartItems } from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine } from '@hot-b2b/store/settings/selector';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'hot-alert',
    templateUrl: './alert.component.html',
})
export class HotAlertComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public message: any;
    @Input() public itemId: string;
    @Input() public param: any;

    public cartItems$: Observable<HotCartLineItemExtended[]>;
    private readonly settingsAppOnLine$: Observable<boolean>;
    private subscription: Subscription;
    public hideIcon:boolean;
    public unitOfMeasure: string;

    public featureExternalPriceValidation: boolean = this.featuresService.ExternalPriceValidation;
    public isNgB2b = () => this.configurationService.getCurrentConfiguration() === 'NG';

    constructor(private readonly alertService: AlertService, public readonly configurationService: ConfigurationService,
        private readonly translateService: TranslateService,
        private readonly store: Store<AppState>, private readonly featuresService: FeaturesService,) {
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.cartItems$ = this.store.pipe(select(cartItems));
    }
    ngOnChanges(): void {
        if(this.message !== undefined){
            if(this.message === 'HotProductMaxPurchasePerTimePeriodError'){
                this.unitOfMeasure = this.param.unitOfMeasure
                ? this.translateService.instant(
                      `shared.product.package-types.${this.param.unitOfMeasure?.toLowerCase()}`
                  )
                : '';
            }
        }
    }

    public ngOnInit(): void {
        if (!this.message) {
            this.subscription = this.alertService.getMessage().subscribe((message: any) => {
                this.message = message;
            });
            
            if(this.message === 'HotMinimumOrderQuantityProductError' || this.message === 'HotMinimumOrderQuantityCartError'
            || this.message === 'HotMinQuantityError' || this.message === 'HotInStockQuantityError' 
            || this.message === 'HotProductMaxPurchasePerTimePeriodError' || 
            this.message === 'HotProductLimitOrderQuantityWarning'
            || this.message === 'HotMaxQuantityError' || this.message === 'HotMinimumOrderQuantityError' ||
             this.message === 'HotProductLimitOrderQuantityError')
            {
                this.hideIcon = false;
            } else {
                this.hideIcon = true;
            }
        }
        else if (this.message.errorCode === 'HotQuantityError' && this.message.parameters.availableQuantity !== 0) {
            this.message = null;
        }
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public acceptNewPrice(): void {
        this.store.dispatch(new CartUpdateItem(this.itemId));
    }

    public removeFromCart(): void {
        if (!this.itemId) {
            return;
        }

        this.cartItems$.pipe(take(1)).subscribe((items: HotCartLineItemExtended[]) => {
            const cartItem: HotCartLineItemExtended = items.find(
                (item: HotCartLineItemExtended) => item.id === this.itemId
            );

            if (!cartItem) {
                return;
            }

            this.settingsAppOnLine$
                .pipe(take(1))
                .subscribe((online: boolean) =>
                    online
                        ? this.store.dispatch(new CartRemoveItem(cartItem))
                        : this.store.dispatch(new CartOfflineRemoveItem({ id: this.itemId }))
                );
        });
    }
}
