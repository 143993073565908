import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';

import { AppState } from '@hot-b2b/store/reducers';
import {
    countNewNotification,
    lastNotificationPopupShown,
    lastNotificationRead,
    newNotificationId,
    notificationData,
    notificationPending,
} from '@hot-b2b/store/notifications/selector';
import { NotificationMessage } from '@hot-theme-nx/generated-api';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData, settingsStore } from '@hot-b2b/store/settings/selector';
import { DateFormatParserService } from 'apps/hot-b2b/src/app/shared/services';
import { NotificationListState, SettingsStoreModel } from '@hot-libs/shared-models';
import {
    NotificationsMarkAsRead,
    NotificationsSetLastRead,
    NotificationsSetPopupShown,
} from '@hot-b2b/store/notifications/actions';

@Component({
    selector: 'hot-notifications',
    templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {
    private settings$: Observable<HotSettingsExtended>;
    private settingsStore$: Observable<SettingsStoreModel>;
    private newNotificationIds$: Observable<string[]>;
    private lastNotificationPopupShown$: Observable<boolean>;
    private lastNotificationRead$: Observable<boolean>;
    private isPopupShown: boolean;
    private isLastReaded: boolean;

    public newNotificationsCount$: Observable<number>;
    public notificationsPending$: Observable<boolean>;
    public notifications$: Observable<NotificationMessage[]>;

    public notificationListState: NotificationListState = { isOpened: false };
    public dateFormat: string;
    public locale: string;
    constructor(
        private readonly store: Store<AppState>,
        private readonly dateFormatParserService: DateFormatParserService
    ) {
        this.newNotificationsCount$ = this.store.pipe(select(countNewNotification));
        this.notificationsPending$ = this.store.pipe(select(notificationPending));
        this.notifications$ = this.store.pipe(select(notificationData));
        this.newNotificationIds$ = this.store.pipe(select(newNotificationId));
        this.lastNotificationPopupShown$ = this.store.pipe(select(lastNotificationPopupShown));
        this.lastNotificationRead$ = this.store.pipe(select(lastNotificationRead));
        this.settings$ = this.store.pipe(
            select(settingsData),
            filter((settings: HotSettingsExtended) => !!settings.currentLanguage)
        );
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((storeSettings: SettingsStoreModel) => !!storeSettings)
        );
    }

    public ngOnInit(): void {
        this.getDateFormat();
        this.lastNotificationPopupShown$
            .pipe(withLatestFrom(this.lastNotificationRead$), take(1))
            .subscribe(([shown, read]: [boolean, boolean]) => {
                this.isPopupShown = shown;
                if (!this.isPopupShown) {
                    this.showNewNotificationsPopup();
                }
                if (!read) {
                    this.toggleNotificationList({ isOpened: false, isCompact: true });
                }
            });
    }

    public toggleNotificationList(state: NotificationListState): void {
        this.notificationListState = state;
        this.markNotificationsAsRead(state);
    }

    public onClickOutside(): void {
        this.lastNotificationRead$.pipe(take(1)).subscribe((read: boolean) => {
            if (!this.isPopupShown && !read) {
                this.toggleNotificationList({ isOpened: false, isCompact: this.notificationListState.isCompact });
                this.isPopupShown = true;
                this.isLastReaded = true;
            }
        });
    }

    private getDateFormat(): void {
        this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
            this.dateFormat = this.dateFormatParserService.parseDateFormatForDatePipe(settings.dateFormat);
            this.locale = settings.currentLanguage.code;
        });
    }

    private showNewNotificationsPopup(): void {
        this.notifications$
            .pipe(
                filter((notifications: NotificationMessage[]) => !!notifications.length),
                take(1)
            )
            .subscribe((notifications: NotificationMessage[]) => {
                if (notifications.some((notification: NotificationMessage) => !notification.isRead)) {
                    this.toggleNotificationList({ isOpened: true, isCompact: true });
                    this.store.dispatch(new NotificationsSetPopupShown(true));
                }
            });
    }

    private markNotificationsAsRead(state: NotificationListState): void {
        this.newNotificationIds$
            .pipe(withLatestFrom(this.settingsStore$), take(1))
            .subscribe(([newNotificationIds, notificationSettings]: [string[], SettingsStoreModel]) => {
                if (newNotificationIds?.length) {
                    if (state.isOpened && !state.isCompact) {
                        this.autoReadNotifications(notificationSettings);
                    }
                    if (!state.isOpened) {
                        let idsToSend: string[] = null;
                        if (state.isCompact) {
                            idsToSend = [newNotificationIds[0]];
                            this.store.dispatch(new NotificationsSetLastRead(true));
                        }
                        this.store.dispatch(new NotificationsMarkAsRead(idsToSend));
                    }
                }
            });
    }

    private autoReadNotifications(readNotificationSettings: SettingsStoreModel): void {
        const timeoutInterval: number = readNotificationSettings.multipleNotificationsAutoReadInterval * 1000;
        if (timeoutInterval > 0) {
            setInterval(() => {
                this.store.dispatch(new NotificationsMarkAsRead());
            }, timeoutInterval);
        }
    }
}
