import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ScreenDimensionService } from '@hot-libs/browser-specific';

@Component({
  selector: 'hot-theme-nx-tab',
  templateUrl: './tab.component.html',
})
export class TabComponent implements OnInit {

  @Input() navList;
  @Input() activeItem;
  @Output() public categoryClick: EventEmitter<any> = new EventEmitter();
  @Output() public openCategoryModalEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('filtersCarousel', { static: false }) carousel: ElementRef;
  @ViewChild('menu', { static: true }) public menu: ElementRef;
  @ViewChild('main', { static: true }) public main: ElementRef;

  public selectedItem;
  dropdownList = [];
  private scrollPoint: number;
  private isRTL = this.document.documentElement.dir === 'rtl';

  public lhs = false;
  public rhs = true;
  public isUpLg = () => this.screenDimensionService.upLg();
  constructor(@Inject(DOCUMENT) private readonly document: any,
    public readonly screenDimensionService: ScreenDimensionService) {

  }

  ngOnInit(): void {
  
    this.selectedItem = this.activeItem || this.navList[0];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.activeItem) {
      this.selectedItem = changes.activeItem.currentValue
    }
  }

  public onClick(navitem) {
    if (navitem?.id === this.selectedItem?.id) {
      this.categoryClick.emit(null);
    } else {
      this.selectedItem = navitem;
      this.categoryClick.emit(navitem);
    }

  }

  ngAfterViewInit() {
    if (this.carousel.nativeElement.scrollLeft == 0) {
      this.lhs = false;
    }
  
    let tabListWidth = this.menu.nativeElement.offsetWidth;
    if( this.carousel.nativeElement.offsetWidth > tabListWidth) {
      this.rhs = false;
    }
  }

  setCarouselNavPosition(scrollMax, direction) {
    if ((this.carousel.nativeElement.scrollLeft >= scrollMax || this.carousel.nativeElement.scrollLeft + 60 >= scrollMax) && direction == "right") {
      this.rhs = false
    } 
    else if ((this.carousel.nativeElement.scrollLeft - 110 <= 0) && direction == "left") {
      this.lhs = false;
      this.rhs = true;
    }
    else if (this.carousel.nativeElement.scrollLeft <= scrollMax + 1) {
      this.rhs = true;
      this.lhs = true;
    }
  }

  public ngAfterViewChecked(): void {
    this.checkHeaderView();
  }

  public carouselElement(scrollMax , direction) {
    if (this.carousel.nativeElement.scrollLeft >= scrollMax && direction === 'right') {
      this.carousel.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
      this.scrollPoint = null;
    } else if (this.carousel.nativeElement.scrollLeft === 0 && direction === 'left') {
      this.carousel.nativeElement.scrollTo({ left: scrollMax, behavior: 'smooth' });
      this.scrollPoint = this.carousel.nativeElement.scrollLeft;
      this.setCarouselNavPosition(scrollMax, direction)
    }
  }

  private checkHeaderView(): void {
    if (this.isUpLg()) {
      this.navListManipulations();
    } else {
      this.resetLists();
    }
  }

  private navListManipulations(): void {
    const menuWidth = this.menu.nativeElement.offsetWidth;
    const mainWidth = this.main.nativeElement.offsetWidth;
    if (menuWidth >= (mainWidth - 150)) {
      this.dropdownList = this.navList;

    }
  }

  public resetLists() {
    this.dropdownList = [];
  }

  public openCategoryModal() {
    this.openCategoryModalEvent.emit(this.dropdownList);
  }

  public slideTo(direction: string) {
    const scrollDistance = this.isUpLg() ? 88 * 3 : 67 * 2;

    let scrollMax = this.carousel.nativeElement.scrollWidth - this.carousel.nativeElement.offsetWidth;

    if (!this.isRTL) {
      this.slideRtl(scrollMax, direction, scrollDistance);
        switch (direction) {
        case 'left':
          const yLeft = this.carousel.nativeElement.scrollLeft - scrollDistance;
          this.carousel.nativeElement.scrollTo({ left: yLeft, behavior: 'smooth' });
          this.scrollPoint = this.carousel.nativeElement.scrollLeft;
          this.setCarouselNavPosition(scrollMax, direction)
          break;
        case 'right':
          const yRight = this.carousel.nativeElement.scrollLeft + scrollDistance;
          this.carousel.nativeElement.scrollTo({ left: yRight, behavior: 'smooth' });
          this.scrollPoint = this.carousel.nativeElement.scrollLeft;
          this.setCarouselNavPosition(scrollMax, direction)
          break;
      }
    } else {
      scrollMax = scrollMax * -1 + 1;

      if (this.carousel.nativeElement.scrollLeft <= scrollMax && direction === 'right') {
        this.carousel.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
        this.scrollPoint = null;
        return;
      } else if (this.carousel.nativeElement.scrollLeft >= 0 && direction === 'left') {
        this.carousel.nativeElement.scrollTo({ left: scrollMax, behavior: 'smooth' });
        this.scrollPoint = this.carousel.nativeElement.scrollLeft;
        return;
      }

      switch (direction) {
        case 'left':
          const yLeft = this.carousel.nativeElement.scrollLeft + scrollDistance;
          this.carousel.nativeElement.scrollTo({ left: yLeft, behavior: 'smooth' });
          this.scrollPoint = this.carousel.nativeElement.scrollLeft;
          break;
        case 'right':
          const yRight = this.carousel.nativeElement.scrollLeft - scrollDistance;
          this.carousel.nativeElement.scrollTo({ left: yRight, behavior: 'smooth' });
          this.scrollPoint = this.carousel.nativeElement.scrollLeft;
          break;
      }
    }
  }
  public slideRtl(scrollMax,direction,scrollDistance) {
    if (this.carousel.nativeElement.scrollLeft >= scrollMax && direction === 'right') {
      this.carousel.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
      this.scrollPoint = null;
      return;
    } else if (this.carousel.nativeElement.scrollLeft === 0 && direction === 'left') {
      this.carousel.nativeElement.scrollTo({ left: scrollMax, behavior: 'smooth' });
      this.scrollPoint = this.carousel.nativeElement.scrollLeft;
      return;
    }

    switch (direction) {
      case 'left':
        const yLeft = this.carousel.nativeElement.scrollLeft - scrollDistance;
        this.carousel.nativeElement.scrollTo({ left: yLeft, behavior: 'smooth' });
        this.scrollPoint = this.carousel.nativeElement.scrollLeft;
        break;
      case 'right':
        const yRight = this.carousel.nativeElement.scrollLeft + scrollDistance;
        this.carousel.nativeElement.scrollTo({ left: yRight, behavior: 'smooth' });
        this.scrollPoint = this.carousel.nativeElement.scrollLeft;
        break;
    }
  }
}
