import { IWidgetsState } from '@hot-b2b/store/widgets/model';

export const widgetsInitialState: IWidgetsState = {
    pending: false,
    fetched: false,
    data: [
        {
            language: '',
            widgets: [],
        },
    ],
    error: null,
};
