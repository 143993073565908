export enum ESettingsActions {
    SETTINGS_PENDING = 'SETTINGS_PENDING',
    SETTINGS_FETCHED = 'SETTINGS_FETCHED',
    SETTINGS_ERROR = 'SETTINGS_ERROR',
    SETTINGS_CLEAR = 'SETTINGS_CLEAR',
    SETTINGS_UPDATE = 'SETTINGS_UPDATE',
    SETTINGS_ITEM_REMOVE = 'SETTINGS_ITEM_REMOVE',
    SETTINGS_ITEM_EDIT = 'SETTINGS_ITEM_EDIT',
    SETTINGS_LANGUAGE_PENDING = 'SETTINGS_LANGUAGE_PENDING',
    SETTINGS_LANGUAGE_FETCHED = 'SETTINGS_LANGUAGE_FETCHED',
    SETTINGS_LANGUAGE_CHANGE = 'SETTINGS_LANGUAGE_CHANGE',
    SETTINGS_USER_LANGUAGE_CLEAR = 'SETTINGS_USER_LANGUAGE_CLEAR',
    SETTINGS_STORE_PENDING = 'SETTINGS_STORE_PENDING',
    SETTINGS_STORE_FETCHED = 'SETTINGS_STORE_FETCHED',
    SETTINGS_STORE_ERROR = 'SETTINGS_STORE_ERROR',
    SETTINGS_TOGGLE_ONLINE = 'SETTINGS_TOGGLE_ONLINE',
    SETTINGS_CHANGE_FFF_ID = 'SETTINGS_CHANGE_FFF_ID',
}
