import { Injectable, NgZone } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ScreenDimensionService {
    protected screenWidth: number;

    private readonly bootstrapSmPoint = 768;
    private readonly bootstrapMdPoint = 1024;
    private readonly bootstrapLgPoint = 1440;
    private readonly bootstrapXlPoint = 1920;

    public breakpoint = 'xs';

    constructor(private ngZone: NgZone) {
        this.setupScreenSizeListener();
    }
    
private setupScreenSizeListener(): void {
    this.ngZone.runOutsideAngular(() => {
    fromEvent(window, 'resize')
    .pipe(debounceTime(200))
    .subscribe(() => {
        this.ngZone.run(() => {
            this.setBreakpointName(window.innerWidth);
        })
    });
    });
    this.setBreakpointName(window.innerWidth);
}

    public init(width: number) {
        this.screenWidth = width;
        this.setBreakpointName(width);
    }

    private setBreakpointName(width:number): void {
        let windowWidth = this.screenWidth = width;
        switch (true) {
            case windowWidth >= this.bootstrapXlPoint:
                this.breakpoint = 'xl';
                break;
            case windowWidth >= this.bootstrapLgPoint:
                this.breakpoint = 'lg';
                break;
            case windowWidth >= this.bootstrapMdPoint:
                this.breakpoint = 'md';
                break;
            case windowWidth >= this.bootstrapSmPoint:
                this.breakpoint = 'sm';
                break;
            case windowWidth < this.bootstrapSmPoint:
                this.breakpoint = 'xs';
                break;
        }
    }

    public upXl(): boolean {
        return this.screenWidth >= this.bootstrapXlPoint;
    }

    public upLg(): boolean {
        return this.screenWidth >= this.bootstrapLgPoint;
    }

    public downLg(): boolean {
        return this.screenWidth < this.bootstrapLgPoint;
    }

    public upMd(): boolean {
        return this.screenWidth >= this.bootstrapMdPoint;
    }

    public downMd(): boolean {
        return this.screenWidth < this.bootstrapMdPoint;
    }

    public upSm(): boolean {
        return this.screenWidth >= this.bootstrapSmPoint;
    }

    public downSm(): boolean {
        return this.screenWidth < this.bootstrapSmPoint;
    }
}
