import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ExportFileType } from 'apps/hot-b2b/src/app/shared/models/export-file.type';
import { ScreenDimensionService } from '@hot-libs/browser-specific';

@Component({
    selector: 'hot-order-export-modal',
    templateUrl: './order-export-modal.component.html'
})
export class OrderExportModalComponent {

    constructor(private readonly screenDimensionService: ScreenDimensionService) { }

    @Input() public exportFileTypes: ExportFileType[];
    @Output() public exportFile: EventEmitter<ExportFileType> = new EventEmitter();

    public isUpLg = () => this.screenDimensionService.upLg();
}
