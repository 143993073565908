/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotOtpOperationResult } from '../models/hot-otp-operation-result';
import { HotSimplifiedCustomerRegistrationRequest } from '../models/hot-simplified-customer-registration-request';
import { HotGenerateTokenRequest } from '../models/hot-generate-token-request';
import { HotChangePhoneNumberSelfRegistrationRequest } from '../models/hot-change-phone-number-self-registration-request';
import { HotCustomerRegistrationRequest } from '../models/hot-customer-registration-request';
@Injectable({
  providedIn: 'root',
})
class HotApiCustomerSelfRegistrationService extends __BaseService {
  static readonly verifySimplifiedTokenAsyncPath = '/storefrontapi/hot/customer-registration/verify-simplified-token';
  static readonly registerSimplifiedCustomerPath = '/storefrontapi/hot/customer-registration/simplified';
  static readonly updateSimplifiedPasswordPath = '/storefrontapi/hot/customer-registration/update-simplified-password';
  static readonly resendOtpRequestPath = '/storefrontapi/hot/customer-registration/resend-otp';
  static readonly changePhoneNumberRequestPath = '/storefrontapi/hot/customer-registration/change-phonenumber';
  static readonly registerPath = '/storefrontapi/hot/customer-registration';
  static readonly verifyTokenAsyncPath = '/storefrontapi/hot/customer-registration/verify-token';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  verifySimplifiedTokenAsyncResponse(body?: HotSimplifiedCustomerRegistrationRequest): __Observable<__StrictHttpResponse<HotOtpOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/customer-registration/verify-simplified-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOtpOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  verifySimplifiedTokenAsync(body?: HotSimplifiedCustomerRegistrationRequest): __Observable<HotOtpOperationResult> {
    return this.verifySimplifiedTokenAsyncResponse(body).pipe(
      __map(_r => _r.body as HotOtpOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  registerSimplifiedCustomerResponse(body?: HotSimplifiedCustomerRegistrationRequest): __Observable<__StrictHttpResponse<HotOtpOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/customer-registration/simplified`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOtpOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  registerSimplifiedCustomer(body?: HotSimplifiedCustomerRegistrationRequest): __Observable<HotOtpOperationResult> {
    return this.registerSimplifiedCustomerResponse(body).pipe(
      __map(_r => _r.body as HotOtpOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  updateSimplifiedPasswordResponse(body?: HotSimplifiedCustomerRegistrationRequest): __Observable<__StrictHttpResponse<HotOtpOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/customer-registration/update-simplified-password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOtpOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  updateSimplifiedPassword(body?: HotSimplifiedCustomerRegistrationRequest): __Observable<HotOtpOperationResult> {
    return this.updateSimplifiedPasswordResponse(body).pipe(
      __map(_r => _r.body as HotOtpOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  resendOtpRequestResponse(body?: HotGenerateTokenRequest): __Observable<__StrictHttpResponse<HotOtpOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/customer-registration/resend-otp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOtpOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  resendOtpRequest(body?: HotGenerateTokenRequest): __Observable<HotOtpOperationResult> {
    return this.resendOtpRequestResponse(body).pipe(
      __map(_r => _r.body as HotOtpOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  changePhoneNumberRequestResponse(body?: HotChangePhoneNumberSelfRegistrationRequest): __Observable<__StrictHttpResponse<HotOtpOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/customer-registration/change-phonenumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOtpOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  changePhoneNumberRequest(body?: HotChangePhoneNumberSelfRegistrationRequest): __Observable<HotOtpOperationResult> {
    return this.changePhoneNumberRequestResponse(body).pipe(
      __map(_r => _r.body as HotOtpOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  registerResponse(body?: HotCustomerRegistrationRequest): __Observable<__StrictHttpResponse<HotOtpOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/customer-registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOtpOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  register(body?: HotCustomerRegistrationRequest): __Observable<HotOtpOperationResult> {
    return this.registerResponse(body).pipe(
      __map(_r => _r.body as HotOtpOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  verifyTokenAsyncResponse(body?: HotCustomerRegistrationRequest): __Observable<__StrictHttpResponse<HotOtpOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/customer-registration/verify-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOtpOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  verifyTokenAsync(body?: HotCustomerRegistrationRequest): __Observable<HotOtpOperationResult> {
    return this.verifyTokenAsyncResponse(body).pipe(
      __map(_r => _r.body as HotOtpOperationResult)
    );
  }
}

module HotApiCustomerSelfRegistrationService {
}

export { HotApiCustomerSelfRegistrationService }
