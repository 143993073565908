import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { BaseControlValueAccessor, ValueAccessorProvider } from 'apps/hot-b2b/src/app/shared/internal';
import { config } from 'apps/hot-b2b/src/environments/config';
@Component({
    selector: 'hot-input',
    templateUrl: './input.component.html',
    providers: [ValueAccessorProvider(HotInputComponent)],
})
export class HotInputComponent extends BaseControlValueAccessor {
    @Input() public modifier: string = '';
    @Input() public lockModifier: string;
    @Input() public wrapperModifier: string;
    @Input() public hideErrors: boolean;
    @Input() public formControl: UntypedFormControl;
    @Input() public name: string;
    @Input() public disabled: boolean;
    @Input() public type = 'text';
    @Input() public caption: string;
    @Input() public placeholder = '';
    @Input() public required: boolean;
    @Input() public readonly: boolean;
    @Input() public isSubmitted: boolean;
    @Input() public maxlength: number;
    @Input() public pattern: string;
    @Input() public allowEnterOnlyByPattern: boolean;
    @Input() public autocomplete: boolean | string;
    @Input() public hideFloatingPlaceholder: boolean;
    @Input() public patternErrorText: string;
    @Input() public checked: boolean;
    @Input() public showErrorIcon: boolean;
    @Input() public patternErrors: Array<string> = [];
    @Input() public pageName: string;
    @Input() public hideBorder: boolean;
    @Input() public inputmode: string;
    @Input() public tooltip: boolean;
    @Input() public tooltiptext: string;
    public passwordVisible: boolean;

    private readonly exceptedKeys: number[] = [8, 37, 38, 39, 40, 46];

    public ngOnInit(): void {
        if (this.caption === 'Outlet phone' && config.currentConfigurationName === 'NG') {
            this.placeholder = '2348012345678';
        }
    }

    public togglePasswordVisible(): void {
        this.passwordVisible = !this.passwordVisible;
    }

    public testPattern(event: any): void {
        if (this.exceptedKeys.indexOf(event.keyCode) !== -1 || event.ctrlKey || event.shiftKey || event.altKey) {
            return;
        }

        if (event.target.value.length === 0) {
            if (!new RegExp(this.pattern).test(event.key)) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
        else if (!new RegExp(this.pattern).test(event.target.value + event.key)) {
                event.preventDefault();
                event.stopPropagation();
        }
    }

    public getTrackData() {
        return `{"data_interaction":"icon_click","button_text":"Eye","name":"${this.pageName}"}`;
    }
}
