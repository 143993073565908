import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImagePipe } from './pipes';

@NgModule({
    declarations: [ImagePipe],
    imports: [CommonModule],
    exports: [ImagePipe],
})
export class ImageModule { }
