import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, BehaviorSubject, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { HotOrderSearchCriteria, HotOrderSearchResult } from '@hot-theme-nx/generated-api';

import { LoaderService } from '../../shared/services/loader.service';

import { DistributorOrdersClient } from './distributor-orders.client';

import { getOrderDisplayedTotalAmount } from '@hot-b2b/store/orders/helpers';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsStore } from '@hot-b2b/store/settings/selector';
import { HotOrderExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import moment from 'moment';
import { FIleDownloadUtil } from '../../shared/services/file-download-util.service';

@Injectable({
    providedIn: 'root',
})
export class DistributorOrdersService {
    // select orders flow
    public selectedOrders: BehaviorSubject<HotOrderExtended[]> = new BehaviorSubject([]);
    public isAllSelected: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public dispatchAllEvent: Subject<any> = new Subject();
    public confirmAllEvent: Subject<any> = new Subject();
    public selectAllOrdersEvent: Subject<boolean> = new Subject();

    private static readonly exportNewOrdersEndpoint: string = '/storefrontapi/hot/distributor/orders/export/new';
    private static readonly exportConfirmedOrdersEndpoint: string =
        '/storefrontapi/hot/distributor/orders/export/confirmed';
    private static readonly exportDispatchedOrdersEndpoint: string =
        '/storefrontapi/hot/distributor/orders/export/dispatched';
    private static readonly exportCompletedOrdersEndpoint: string =
        '/storefrontapi/hot/distributor/orders/export/completed';

    private readonly settingsStore$: Observable<SettingsStoreModel>;

    constructor(
        private readonly distributorOrdersClient: DistributorOrdersClient,
        private readonly httpClient: HttpClient,
        private readonly store: Store<AppState>,
        private readonly loaderService: LoaderService
    ) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
    }

    public searchNewOrders(criteria: HotOrderSearchCriteria): Observable<HotOrderSearchResult> {
        const newOrdersSearchObservable = this.distributorOrdersClient.searchNewOrders(criteria);

        return this.setDisplayedTotalAmount(newOrdersSearchObservable);
    }

    public searchConfirmedOrders(criteria: HotOrderSearchCriteria): Observable<HotOrderSearchResult> {
        const confirmedOrdersSearchObservable = this.distributorOrdersClient.searchConfirmedOrders(criteria);

        return this.setDisplayedTotalAmount(confirmedOrdersSearchObservable);
    }

    public searchDispatchedOrders(criteria: HotOrderSearchCriteria): Observable<HotOrderSearchResult> {
        const result = this.distributorOrdersClient.searchDispatchedOrders(criteria);

        return this.setDisplayedTotalAmount(result);
    }

    public searchCompletedOrders(criteria: HotOrderSearchCriteria): Observable<HotOrderSearchResult> {
        const result = this.distributorOrdersClient.searchCompletedOrders(criteria);

        return this.setDisplayedTotalAmount(result);
    }

    public exportNewOrders(criteria: HotOrderSearchCriteria): void {
        this.exportOrders(DistributorOrdersService.exportNewOrdersEndpoint, criteria);
    }

    public exportConfirmedOrders(criteria: HotOrderSearchCriteria): void {
        this.exportOrders(DistributorOrdersService.exportConfirmedOrdersEndpoint, criteria);
    }
    public getOrderById(orderId: string): Observable<HotOrderExtended> {
        const orderObservable = this.distributorOrdersClient.getOrderById(orderId);

        return combineLatest([orderObservable, this.settingsStore$]).pipe(
            map(([order, settingsStoreModel]: [HotOrderExtended, SettingsStoreModel]) => {
                order.displayedTotalAmount = getOrderDisplayedTotalAmount(order, settingsStoreModel);
                return order;
            })
        );
    }

    public exportDispatchedOrders(criteria: HotOrderSearchCriteria): void {
        this.exportOrders(DistributorOrdersService.exportDispatchedOrdersEndpoint, criteria);
    }

    public exportCompletedOrders(criteria: HotOrderSearchCriteria): void {
        this.exportOrders(DistributorOrdersService.exportCompletedOrdersEndpoint, criteria);
    }
    public isOrderSelected(order: HotOrderExtended): Observable<boolean> {
        return this.selectedOrders.pipe(map((orders) => !!orders.find((item) => item.id === order.id)));
    }

    public getSelectedOrderIds(): Observable<string[]> {
        return this.selectedOrders.pipe(
            take(1),
            map((orders) => orders.map((order) => order.id))
        );
    }

    public selectOrderEventHandler(order: HotOrderExtended, isOrderChecked: boolean): void {
        this.selectedOrders.pipe(take(1)).subscribe((orders: HotOrderExtended[]) => {
            if (isOrderChecked) {
                orders.push(order);
            } else {
                orders = orders.filter((item: HotOrderExtended) => item.id !== order.id);
            }

            this.selectedOrders.next(orders);
        });
    }

    private setDisplayedTotalAmount(
        ordersSearchObservable: Observable<HotOrderSearchResult>
    ): Observable<HotOrderSearchResult> {
        return combineLatest([ordersSearchObservable, this.settingsStore$]).pipe(
            map(([ordersSearchResult, settingsStoreModel]: [HotOrderSearchResult, SettingsStoreModel]) => {
                ordersSearchResult.orders.forEach((order: HotOrderExtended) => {
                    order.displayedTotalAmount = getOrderDisplayedTotalAmount(order, settingsStoreModel);
                });

                return ordersSearchResult;
            })
        );
    }

    private exportOrders(endpoint: string, criteria: HotOrderSearchCriteria): void {
        const config: HttpHeaders = new HttpHeaders().set('Accept', 'application/json-patch+json, */*');
        this.loaderService.show();

        this.httpClient
            .post<Blob>(endpoint, criteria, {
                headers: config,
                responseType: 'blob' as 'json',
            })
            .subscribe((x: Blob) => {
                this.loaderService.hide();
                const filename: string = this.setExcelFilename(endpoint, criteria);
                FIleDownloadUtil.fileDownload(x, filename);
                this.loaderService.hide();
            });
    }

    private setExcelFilename(endpoint: string, criteria: HotOrderSearchCriteria): string {
        const formattedDate: string = moment().format('YYYY-MM-DD_HH-mm-ss');
        if (criteria.keyword) {
            return `${criteria.keyword}_${formattedDate}`;
        }
        else if (endpoint === DistributorOrdersService.exportNewOrdersEndpoint) {
            return `new_orders_${formattedDate}`;
        }
        else if (endpoint === DistributorOrdersService.exportConfirmedOrdersEndpoint) {
            return `confirmed_orders_${formattedDate}`;
        }
        else if (endpoint === DistributorOrdersService.exportDispatchedOrdersEndpoint) {
            return `dispatched_orders_${formattedDate}`;
        }
        else if (endpoint === DistributorOrdersService.exportCompletedOrdersEndpoint) {
            return `completed_orders_${formattedDate}`;
        }
    }
}
