import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HotApiOrdersService } from '@hot-theme-nx/generated-api';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FIleDownloadUtil } from '../../../services/file-download-util.service';

@Component({
    selector: 'hot-download-invoice',
    templateUrl: './download-invoice.component.html',
})
export class DownloadInvoiceComponent {
    @Input() order;
    @Output() public closeModal: EventEmitter<any> = new EventEmitter();

    private readonly subscriptionDestroy$ = new Subject<boolean>();

    public atleastOneDocumentChecked = true;
    public selectAllDocuments = true;

    constructor(private apiOrdersService: HotApiOrdersService) {}

    public selectAll() {
        this.selectAllDocuments = false;
        for (let doc of this.order.orderRelatedDocuments) {
            doc.isChecked = true;
        }
        this.atleastOneDocumentChecked = false;
    }

    public deselectAll() {
        this.selectAllDocuments = true;
        for (let doc of this.order.orderRelatedDocuments) {
            doc.isChecked = false;
        }
        this.atleastOneDocumentChecked = true;
    }

    public onChange(orderDocs) {
        this.atleastOneDocumentChecked = true;
        for (let doc of orderDocs) {
            if (doc.isChecked) {
                this.atleastOneDocumentChecked = false;
            }
        }
        this.selectAllDocuments = true;
        if (this.checkIfAllDocumentsSelected(orderDocs)) {
            this.selectAllDocuments = false;
        }
    }

    public checkIfAllDocumentsSelected(orderDocs) {
        let docSelected = true;
        for (let doc of orderDocs) {
            if (doc.isChecked === false) {
                docSelected = false;
            }
        }
        return docSelected;
    }

    public download(order) {
        for (let doc of order.orderRelatedDocuments) {
            if (doc.isChecked) {
                this.orderDeliveryDownload(doc);
            }
        }

        this.closeModal.emit();
    }

    public orderDeliveryDownload(doc) {
        let Urls = [];

        Urls.push({
            FilePathUrl: doc.url,
            FileType: doc.name.split('.').pop(),
        });

        this.apiOrdersService
            .exportFile(Urls)
            .pipe(takeUntil(this.subscriptionDestroy$))
            .subscribe((response: string) => {
                doc.response = response;
                FIleDownloadUtil.fileDownload(response, doc.name);
            });

        return doc;
    }
}
