import { Action } from '@ngrx/store';

import { HotUserExtended } from '@hot-libs/shared-models';

import { AuthUser } from '@hot-b2b/store/auth/model';
import { EAuthActions } from '@hot-b2b/store/auth/types';
import {
    HotApiChildContactsService,
    HotChildContactOutlet,
    HotChildContactOutletResponse,
    HotChildContactSearchResult,
} from '@hot-theme-nx/generated-api';

export class AuthGet implements Action {
    public readonly type = EAuthActions.AUTH_PENDING;
}

export class AuthGetSuccess implements Action {
    public readonly type = EAuthActions.AUTH_FETCHED;
    constructor(public payload: AuthUser) {}
}

export class AuthError implements Action {
    public readonly type = EAuthActions.AUTH_ERROR;
    constructor(public payload: string) {}
}

export class AuthLoginSuccess implements Action {
    public readonly type = EAuthActions.AUTH_USER_LOGIN_SUCCESS;
    constructor(public payload: { user: HotUserExtended, homePageUrl?: string}) {}
}

export class AuthEdit implements Action {
    public readonly type = EAuthActions.AUTH_USER_EDIT;
    constructor(public payload: HotUserExtended) {}
}

export class AuthLogout implements Action {
    public readonly type = EAuthActions.AUTH_USER_LOGOUT;
}

export class DataLoadedFor implements Action {
    public readonly type = EAuthActions.AUTH_USER_LOADED_PAGE;
    constructor(public payload: string) {}
}
export class AuthGetChildContacts implements Action {
    public readonly type = EAuthActions.AUTH_USER_GET_CHILD_CONTACTS;
    constructor(public payload: HotApiChildContactsService.GetChildContactsParams) {}
}

export class AuthGetChildContactsSuccess implements Action {
    public readonly type = EAuthActions.AUTH_USER_GET_CHILD_CONTACTS_SUCCESS;
    constructor(public payload: HotChildContactSearchResult) {}
}

export class AuthGetChildContact implements Action {
    public readonly type = EAuthActions.AUTH_USER_GET_CHILD_CONTACT;
    constructor(public payload: string) {}
}

export class AuthGetChildContactSuccess implements Action {
    public readonly type = EAuthActions.AUTH_USER_GET_CHILD_CONTACT_SUCCESS;
    constructor(public payload: HotChildContactOutletResponse) {}
}

export class AuthUpdateChildContactOutlets implements Action {
    public readonly type = EAuthActions.AUTH_USER_UPDATE_CHILD_CONTACT;
    constructor(public payload: HotApiChildContactsService.UpdateChildContactOutletsParams) {}
}

export class AuthSetChildContactOutletsToRemove implements Action {
    public readonly type = EAuthActions.AUTH_USER_SET_CHILD_CONTACT_OUTLETS_TO_REMOVE;
    constructor(public payload: HotChildContactOutlet[]) {}
}

export class AuthUserContactUpdateTutorialFlag implements Action {
    public readonly type = EAuthActions.AUTH_USER_UPDATE_TUTORIALHIDE_FLAG;
    constructor(public payload: boolean) {}
}

export class AuthChildContactOutletsNeedRemove implements Action {
    public readonly type = EAuthActions.AUTH_USER_CHILD_CONTACT_OUTLETS_NEED_REMOVE;
}

export type AuthActions =
    | AuthGet
    | AuthGetSuccess
    | AuthError
    | AuthLoginSuccess
    | AuthLogout
    | AuthEdit
    | DataLoadedFor
    | AuthGetChildContacts
    | AuthGetChildContactsSuccess
    | AuthGetChildContact
    | AuthGetChildContactSuccess
    | AuthUpdateChildContactOutlets
    | AuthSetChildContactOutletsToRemove
    | AuthUserContactUpdateTutorialFlag
    | AuthChildContactOutletsNeedRemove;
