import { Component, EventEmitter, Input,Output } from '@angular/core';
import { Router } from '@angular/router';
import { CartOfflineClear } from '@hot-b2b/store/cart/actions';
import { selectModalData } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { ModalIds } from '@hot-libs/shared-types';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ModalService } from '../../../services/modal.service';

@Component({
    selector: 'hot-theme-nx-kegs-return-page',
    templateUrl: './kegs-return-page.component.html',
})
export class KegsReturnPageComponent  {
    @Input() value;
    @Input() value1;
    @Output() public callback: EventEmitter<any> = new EventEmitter();
    private readonly modalData$: Observable<any>;
    constructor(
        private readonly modalService: ModalService,
        private readonly router: Router,
        private readonly store: Store<AppState>
    ) {
        this.modalData$ = this.store.pipe(select(selectModalData(ModalIds.kegsReturnPage)));
    }

    public navigate() {
        this.modalService.toggleModal('kegs-returnPage-modal', false);
        this.store.dispatch(new CartOfflineClear());
        window.location.reload();
    }

    public NavigateToKegsHistory() {
        this.modalService.toggleModal('kegs-returnPage-modal', false);
        this.router.navigateByUrl('/kegs-history');
    }
}
