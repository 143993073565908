import { Action } from '@ngrx/store';

import {
    HotCategory,
    HotLineItem,
    HotProductSearchCriteriaV2,
    HotProductSearchResult,
} from '@hot-theme-nx/generated-api';

import { HotCatalogExtended, HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { ECatalogActions } from '@hot-b2b/store/catalog/types';
import { MultiFilter, AdvanceFilter } from '../../shared/models/multi-filters.model';

export class OfflineCatalogWithCartGet implements Action {
    public readonly type = ECatalogActions.OFFLINE_CATALOG_PENDING;
}

export class CatalogGet implements Action {
    public readonly type = ECatalogActions.CATALOG_PENDING;
}

export class OfflineCatalogGet implements Action {
    public readonly type = ECatalogActions.OFFLINE_CATALOG_PENDING;
}

export class OfflineCatalogGetFromCenter implements Action {
    public readonly type = ECatalogActions.OFFLINE_CATALOG_PENDING_FROM_CENTER;
    constructor(public payload: string) { }
}

export class OfflineCatalogGetFromCenterSuccess implements Action {
    public readonly type = ECatalogActions.OFFLINE_CATALOG_FETCHED_FROM_CENTER;
    constructor(public payload: { data: HotCatalogExtended[] }) { }
}

export class CartDataGet implements Action {
    public readonly type = ECatalogActions.CART_DATA_GET_PENDING;
}

export class OfflineCatalogGetSuccess implements Action {
    public readonly type = ECatalogActions.OFFLINE_CATALOG_FETCHED;
    constructor(public payload: { data: HotCatalogExtended[]; cart: HotCartLineItemExtended[] }) { }
}

export class OfflineCatalogDataGetSuccess implements Action {
    public readonly type = ECatalogActions.OFFLINE_CATALOG_FETCHED;
    constructor(public payload: { data: HotCatalogExtended[] }) { }
}

export class CatalogItemUpdate implements Action {
    public readonly type = ECatalogActions.CATALOG_ITEM_UPDATE;
    constructor(public payload: { ffcId: string; product: HotProductExtended }) { }
}
export class CatalogApplyMultiFilters implements Action {
    public readonly type = ECatalogActions.CATALOG_APPLY_MULTI_FILTER;
    constructor(public payload: MultiFilter) { }
}

export class CatalogClearMultiFilters implements Action {
    public readonly type = ECatalogActions.CATALOG_CLEAR_MULTI_FILTER;
}

export class CatalogProductsSearch implements Action {
    public readonly type = ECatalogActions.CATALOG_PRODUCTS_SEARCH;
    constructor(public payload: { criteria: HotProductSearchCriteriaV2; ffcId: string }) { }
}

export class CatalogProductsSearchSuccess implements Action {
    public readonly type = ECatalogActions.CATALOG_PRODUCTS_SEARCH_SUCCESS;
    constructor(
        public payload: { data: HotProductSearchResult; ffcId: string; cartItems: HotCartLineItemExtended[] }
    ) { }
}

export class OfflineCatalogProductsSearch implements Action {
    public readonly type = ECatalogActions.OFFLINE_CATALOG_PRODUCTS_SEARCH;
    constructor(public payload: { criteria: HotProductSearchCriteriaV2; ffcId: string; loadOfflineDataForOnline: boolean }) { }
}

export class OfflineCatalogProductsSearchSuccess implements Action {
    public readonly type = ECatalogActions.OFFLINE_CATALOG_PRODUCTS_SEARCH_SUCCESS;
    constructor(
        public payload: {
            searchCriteria: HotProductSearchCriteriaV2;
            ffcId: string;
            cartItems: HotCartLineItemExtended[];
            loadOfflineDataForOnline: boolean;
        }
    ) { }
}

export class CatalogProductsReload implements Action {
    public readonly type = ECatalogActions.CATALOG_PRODUCTS_RELOAD;
    constructor(public payload: { criteria: HotProductSearchCriteriaV2; ffcId: string }) { }
}

export class CatalogProductsReloadSuccess implements Action {
    public readonly type = ECatalogActions.CATALOG_PRODUCTS_RELOAD_SUCCESS;
    constructor(
        public payload: { data: HotProductSearchResult; ffcId: string; cartItems: HotCartLineItemExtended[] }
    ) { }
}

export class CatalogProductsClear implements Action {
    public readonly type = ECatalogActions.CATALOG_PRODUCTS_CLEAR;
}

export class CatalogChangeQuantity implements Action {
    public readonly type = ECatalogActions.CATALOG_ITEM_CHANGE_QUANTITY;
    constructor(public payload: HotLineItem) { }
}

export class CatalogSynchronized implements Action {
    public readonly type = ECatalogActions.CATALOG_SYNCHRONIZED;
    constructor(public payload?: HotCartExtended) { }
}

export class CatalogSynchronizedSuccess implements Action {
    public readonly type = ECatalogActions.CATALOG_SYNCHRONIZED_SUCCESS;
    constructor(public payload: { ffc: string; cartItems: HotCartLineItemExtended[] }) { }
}

export class CatalogSynchronizedSuccessWithStoreId implements Action {
    public readonly type = ECatalogActions.CATALOG_SYNCHRONIZED_SUCCESS_WITH_STORE_ID;
    constructor(public payload: { ffc: string; cartItems: HotCartLineItemExtended[] }) {}
}

export class CatalogItemChangeSubscriptionSuccess implements Action {
    public readonly type = ECatalogActions.CATALOG_ITEM_CHANGE_SUBSCRIPTION_SUCCESS;
    constructor(public payload: { ffcId: string; itemId: string }) { }
}

export class CatalogItemChangeSubscription implements Action {
    public readonly type = ECatalogActions.CATALOG_ITEM_CHANGE_SUBSCRIPTION;
    constructor(public payload: string) { }
}

export class EmptiesCatalogGet implements Action {
    public readonly type = ECatalogActions.EMPTIES_CATALOG_PENDING;
}

export class EmptiesCatalogGetSuccess implements Action {
    public readonly type = ECatalogActions.EMPTIES_CATALOG_FETCHED;
    constructor(public payload: { data: HotCatalogExtended[]; cart: HotCartLineItemExtended[] }) { }
}

export class EmptiesCatalogSynchronized implements Action {
    public readonly type = ECatalogActions.EMPTIES_CATALOG_SYNCHRONIZED;
    constructor(public payload?: HotCartExtended) { }
}

export class EmptiesCatalogSynchronizedSuccess implements Action {
    public readonly type = ECatalogActions.EMPTIES_CATALOG_SYNCHRONIZED_SUCCESS;
    constructor(public payload: { ffc: string; cartItems: HotCartLineItemExtended[] }) { }
}

export class CatalogCategoriesSearch implements Action {
    public readonly type = ECatalogActions.CATALOG_CATEGORIES_SEARCH;
    constructor(public payload: { ffcId: string; criteria: HotProductSearchCriteriaV2 }) { }
}

export class CatalogCategoriesSearchSuccess implements Action {
    public readonly type = ECatalogActions.CATALOG_CATEGORIES_SEARCH_SUCCESS;
    constructor(public payload: { ffcId: string; categories: HotCategory[] }) { }
}

export class CatalogApplyAdvanceFilters implements Action {
    public readonly type = ECatalogActions.CATALOG_APPLY_ADVANCE_FILTER;
    constructor(public payload: AdvanceFilter[]) { }
}

export class CatalogClearAdvanceFilters implements Action {
    public readonly type = ECatalogActions.CATALOG_CLEAR_ADVANCE_FILTER;
}

export type CatalogActions =
    | CatalogChangeQuantity
    | CatalogSynchronized
    | CatalogSynchronizedSuccess
    | CatalogSynchronizedSuccessWithStoreId
    | CatalogItemChangeSubscriptionSuccess
    | CatalogItemChangeSubscription
    | EmptiesCatalogGet
    | EmptiesCatalogGetSuccess
    | EmptiesCatalogSynchronized
    | EmptiesCatalogSynchronizedSuccess
    | OfflineCatalogWithCartGet
    | OfflineCatalogGet
    | OfflineCatalogGetFromCenter
    | OfflineCatalogGetSuccess
    | CatalogProductsSearch
    | CatalogProductsSearchSuccess
    | CatalogProductsClear
    | OfflineCatalogProductsSearch
    | OfflineCatalogProductsSearchSuccess
    | CatalogApplyMultiFilters
    | CatalogClearMultiFilters
    | OfflineCatalogGetFromCenterSuccess
    | CatalogItemUpdate
    | CatalogCategoriesSearch
    | CatalogCategoriesSearchSuccess
    | CatalogProductsReload
    | CatalogProductsReloadSuccess
    | CatalogApplyAdvanceFilters
    | CatalogClearAdvanceFilters;
