import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import {
    HotApiFulfillmentCentersService,
    HotFulfillmentCenter,
    HotApiOrdersService,
    HotApiSettingsService,
    HotSettings,
} from '@hot-theme-nx/generated-api';

import { OrderService } from 'apps/hot-b2b/src/app/order/services/order.service';
import { HotCartExtended } from '../models';

import { LineItemsCountService } from './line-items-count.service';

import {
    CartAddPreviousOrder,
    CartAddPreviousOrderOffline,
    CartSavePreviousOrder,
    CartUpdate,
} from '@hot-b2b/store/cart/actions';
import { cartData } from '@hot-b2b/store/cart/selector';
import { OrdersChangeStatus, OrderSynchronize } from '@hot-b2b/store/orders/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsAppOnLine, settingsData, settingsStore } from '@hot-b2b/store/settings/selector';
import { warehouses } from '@hot-b2b/store/warehouses/selector';
import { HotOrderExtended, HotOrderLineItemExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { ModalIds, OrderStatus } from '@hot-libs/shared-types';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatParserService } from './date-format-parser.service';
import { FeaturesService } from './features.service';
import { ModalService } from './modal.service';
import { ConfigurationService } from './configuration.service';
import { OrderCreationService } from './order-creation.service';
import { GetSettingsSuccess } from '@hot-theme-nx/common-api';
import { CookiesService } from './cookies.service';
import { HotCookies } from './constants.service';
import { InsightsGetFromCenter } from '@hot-b2b/store/insights/actions';
import { PromotionsGetFromCenter } from '@hot-b2b/store/promotions/actions';
import { OfflineCatalogGetFromCenter } from '@hot-b2b/store/catalog/actions';

@Injectable({
    providedIn: 'root',
})
export class OrderDetailsService {
    public cart$: Observable<HotCartExtended>;
    public settingsAppOnLine$: Observable<boolean>;
    public warehouses$: Observable<HotFulfillmentCenter[]>;
    public storeSettings$: Observable<SettingsStoreModel>;
    public settingsData$: Observable<HotSettingsExtended>;
    public addReasonsByCustomerWhenCancelOrder: boolean;
    public featureCancelOrder: boolean = this.featuresService.CancelOrder;
    public featureConfirmDelivery: boolean = this.featuresService.ConfirmDelivery;
    public featureReorder: boolean = this.featuresService.Reorder;
    public featureOrderTemplates: boolean = this.featuresService.OrderTemplates;
    public featureStoreIsRequiredForOutlet: boolean = this.featuresService.StoreIsRequiredForOutlet;
    public featureSummarizeInvoices: boolean = this.featuresService.SummarizeInvoices;
    public featureOrderPaymentsProcessing: boolean = this.featuresService.OrderPaymentsProcessing;
    public featureExtendedOrderDeliveryConfirmationFlow: boolean = this.featuresService.ExtendedOrderDeliveryConfirmationFlow;
    public featureAddReasonsByCustomerWhenCancelOrder: boolean = this.featuresService.AddReasonsByCustomerWhenCancelOrder;

    public readonly orderStatus = OrderStatus;
    public orderFulfillmentCenter: HotFulfillmentCenter;
    public isWeekCalendar: boolean;
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    public hotSettings$: Observable<HotSettings>;
    settingsResponse: any;
    isMultiplePaymentActiveFlag: boolean;
    passOrder: any;
    orderPass: any;
    totAmount: any;
    public orderDetails$: BehaviorSubject<HotOrderExtended> = new BehaviorSubject<HotOrderExtended>(null);

    constructor(
        private readonly store: Store<AppState>,
        private readonly dateFormatParserService: DateFormatParserService,
        private readonly translateService: TranslateService,
        private readonly modalService: ModalService,
        private readonly featuresService: FeaturesService,
        private readonly orderService: OrderService,
        private readonly router: Router,
        private readonly hotApiFulfillmentCentersService: HotApiFulfillmentCentersService,
        private readonly hotApiOrdersService: HotApiOrdersService,
        private readonly lineItemsCountService: LineItemsCountService,
        private readonly location: Location,
        private readonly configurationService: ConfigurationService,
        private readonly orderCreationService: OrderCreationService,
        private readonly hotApiSettingsService: HotApiSettingsService,
        public readonly cookiesService: CookiesService
    ) {
        this.cart$ = this.store.pipe(select(cartData));
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.warehouses$ = this.store.pipe(select(warehouses));
        this.storeSettings$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.storeSettings$.pipe(take(1)).subscribe((value: SettingsStoreModel) => {
            this.addReasonsByCustomerWhenCancelOrder = value.addReasonsByCustomerWhenCancelOrder;
        });
        this.hotSettings$ = this.hotApiSettingsService.getSettings();
        this.hotApiSettingsService
            .getSettings()
            .pipe(map((settings) => GetSettingsSuccess({ settings })))
            .subscribe((settings) => {
                this.settingsResponse = settings.settings.features;
                this.settingsResponse.forEach((settingsRes) => {
                    if (
                        settingsRes.name != undefined &&
                        settingsRes.name == 'EnableMultiplePaymentProvider'
                    ) {
                        this.isMultiplePaymentActiveFlag = settingsRes.isActive;
                    }
                });
            });

        this.settingsData$ = this.store.pipe(select(settingsData));
    }

    public isShowDeliveredColumn(order: HotOrderExtended): boolean {
        const checkStatus =
            order.status === this.orderStatus.Delivered ||
            order.status === this.orderStatus.ConfirmedDelivery ||
            order.status === this.orderStatus.Confirmed ||
            order.status === this.orderStatus.OnTheWay;
        const checkInvoice = order.hasInvoice;
        const checkQuantity =
            this.featureSummarizeInvoices &&
            !order.hasInvoice &&
            order.items.some((item) => item.originalQuantity != null); // 0 - need to be a true
        return checkInvoice && (checkStatus || checkQuantity);
    }

    public isShowConfirmedColumn(order: HotOrderExtended): boolean {
        const checkStatus = order.status === this.orderStatus.Confirmed;
        const checkQuantity = order.items.some((item) => item.originalQuantity != null);
        return checkStatus && checkQuantity;
    }

    public reportOrderIssue(data) {
        return this.hotApiOrdersService.reportOrderIssue(data);
    }
    
    public setDateFormat(date): Observable<string> {
        return this.storeSettings$.pipe(
            take(1),
            map((storeSettings: SettingsStoreModel) => {
                const isWeekCalendar = storeSettings.preferredDeliveryDateAsWeekOnCheckoutPage;

                if (date) {
                    if (isWeekCalendar) {
                        return this.dateFormatParserService.weekFormat(date);
                    } else {
                        return this.dateFormatParserService.dateFormat(date);
                    }
                }

                return this.translateService.instant('shared.delivery.estimated-date-not-set');
            })
        );
    }

    public setDaysFormat(date): Observable<string> {
        return this.storeSettings$.pipe(
            take(1),
            map((storeSettings: SettingsStoreModel) => {
                const isWeekCalendar = storeSettings.preferredDeliveryDateAsWeekOnCheckoutPage;

                if (date) {
                    if (isWeekCalendar) {
                        return this.dateFormatParserService.weekDays(date);
                    } else {
                        return this.dateFormatParserService.dateFormat(date);
                    }
                }

                return this.translateService.instant('shared.delivery.estimated-date-not-set');
            })
        );
    }

    public setOrderCreationDateFormat(date): Observable<string> {
        return this.settingsData$.pipe(
            take(1),
            withLatestFrom(this.setDateFormat(date)),
            map(([data, defaultFormatDate]: [HotSettingsExtended, string]) => {
                const isShowTime = data.displayOrderCreationDateWithTime;

                if (date) {
                    if (isShowTime) {
                        const formattedTime = this.dateFormatParserService.getTime(date);
                        const formattedDate = this.dateFormatParserService.getDate(date);

                        return this.translateService.instant('shared.delivery.date-format', {
                            time: formattedTime,
                            date: formattedDate,
                        });
                    } else {
                        return defaultFormatDate;
                    }
                }

                return this.translateService.instant('shared.delivery.estimated-date-not-set');
            })
        );
    }

    public setDateFormatforOrder(date): Observable<string> {
        return this.storeSettings$.pipe(
            take(1),
            map((storeSettings: SettingsStoreModel) => {
                const isWeekCalendar = storeSettings.preferredDeliveryDateAsWeekOnCheckoutPage;

                if (date) {
                    if (isWeekCalendar) {
                        return this.dateFormatParserService.weekFormat(date);
                    } else {
                        return this.dateFormatParserService.dateFormatOrderDetails(date);
                    }
                }

                return this.translateService.instant('shared.delivery.estimated-date-not-set');
            })
        );
    }

    public addToCart(orderId: string, products: any): void {
        this.store.pipe(select(settingsAppOnLine), take(1)).subscribe((online: boolean) => {
            if (online) {
                this.store.dispatch(new CartAddPreviousOrder({ id: orderId, route: 'cart' }));
            } else {
                this.store.dispatch(new CartAddPreviousOrderOffline(products));
            }
            this.modalService.toggleModal(ModalIds.order, false);
        });
    }

    public closeOrderDetails() {
        this.modalService.toggleModal(ModalIds.orderDetails, false);
        this.location.replaceState('/orders');
    }
    public closeOrderDetailsWithUpdateOrder(order: HotOrderExtended) {
        this.store.dispatch(new OrderSynchronize(order));
        this.modalService.toggleModal(ModalIds.orderDetails, false);
        this.modalService.toggleModal(ModalIds.order, false);
        this.location.replaceState('/orders');
    }
    public setNewOrderStatus(order: HotOrderExtended, newStatus: string, newExtendedStatus: string) {
        order.status = newStatus;
        order.extendedStatus = newExtendedStatus;
        this.store.dispatch(
            new OrdersChangeStatus({
                orderId: order.id,
                status: newStatus,
                extendedStatus: newExtendedStatus,
            })
        );

        return order;
    }

    public reorder(order: HotOrderExtended): void {
        combineLatest([this.settingsAppOnLine$, this.cart$, this.warehouses$])
            .pipe(take(1))
            .subscribe((result: [boolean, HotCartExtended, HotFulfillmentCenter[]]) => {
                const isOnline: boolean = result[0];
                const cart: HotCartExtended = result[1];
                const fulfillmentCenters: HotFulfillmentCenter[] = result[2];
                const orderFfc = fulfillmentCenters.find(
                    (ffc: HotFulfillmentCenter) => ffc.id === order.fulfillmentCenterId
                );
                this.orderFulfillmentCenter = orderFfc;
                if (cart.items.length) {
                    this.modalService.toggleModal(ModalIds.reorderConfirmation, true, { ffc: orderFfc, order });
                } else {
                    if (!isOnline) {
                        cart.storeId = orderFfc.storeId;
                        cart.items = [];
                        this.store.dispatch(new CartUpdate(cart));
                        this.orderService.reorder(order, orderFfc, false);
                    } else {
                        this.cookiesService.setCookie(HotCookies.CURRENT_FULFILLMENT_CENTER, orderFfc.id);
                        this.cookiesService.setCookie(HotCookies.CURRENT_STORE, orderFfc.storeId);
                        this.store.dispatch(new InsightsGetFromCenter(orderFfc.id));
                        this.store.dispatch(new PromotionsGetFromCenter(orderFfc.id));
                        this.store.dispatch(new OfflineCatalogGetFromCenter(orderFfc.id));

                        this.orderService.reorder(order, orderFfc, true);
                    }

                    this.modalService.toggleModal(ModalIds.order, false);
                    this.modalService.toggleModal(ModalIds.cart, true);
                }
            });
    }

    public closeReorderConfirmationModal(): void {
        this.modalService.toggleModal(ModalIds.reorderConfirmation, false);
    }

    public closeCancelOrderConfirmationModal(): void {
        this.modalService.toggleModal(ModalIds.cancelOrderConfirmation, false);
    }

    public closeOrderHasUnfinishedPaymentsModal(): void {
        this.modalService.toggleModal(ModalIds.orderHasUnfinishedPayments, false);
    }

    public togglePayMethodModal(): void {
        this.modalService.toggleModal(ModalIds.payMethodModal, false);
    }

    public isAcceptDeliveryWarningVisible(
        order: HotOrderExtended,
        canConfirmOrderDelivery: boolean,
        formIsValid: boolean
    ): Observable<boolean> {
        return this.storeSettings$.pipe(
            map((settingsStoreModel: SettingsStoreModel) => {
                return (
                    settingsStoreModel.showAcceptDeliveryWarning &&
                    order.items.some((item) => item.quantity > item.deliveredQuantity) &&
                    settingsStoreModel.orderStatusesAvailableForDeliveryApprove.includes(order.status) &&
                    canConfirmOrderDelivery &&
                    formIsValid
                );
            })
        );
    }

    public calculateItemsCount(order: HotOrderExtended): Observable<number> {
        return this.settingsAppOnLine$.pipe(
            map((isOnline: boolean) => {
                const countableItems = isOnline
                    ? order.items
                    : order.items.filter((orderItem: HotOrderLineItemExtended) => !orderItem.isGift);
                return this.lineItemsCountService.getItemsCount(of(countableItems));
            }),
            switchMap((result: Observable<number>) => {
                return result;
            })
        );
    }

    public saveOrder(orderId: string): void {
        this.store.dispatch(new CartSavePreviousOrder({ orderId }));
    }

    public cancelOrder(order: HotOrderExtended): void {
        if (this.addReasonsByCustomerWhenCancelOrder) {
            this.modalService.toggleModal(ModalIds.orderCancelModal, true);
        } else {
            this.modalService.toggleModal(ModalIds.cancelOrderConfirmation, true, order);
        }
    }

    public toggleAddAmountOrderModal(
        isOpened: boolean,
        order?: any,
        orderData?: { orderNumber: string; orderTotal: string; orderHasUnfinishedPayments: boolean }
    ): void {
        this.storeSettings$.pipe(take(1)).subscribe((settingsStoreModel: SettingsStoreModel) => {
            if (orderData?.orderHasUnfinishedPayments && settingsStoreModel.allowUnfinishedPaymentsProcessing) {
                this.modalService.toggleModal(ModalIds.orderHasUnfinishedPayments, true, orderData);
            } else {
                this.proceedToOrderPayment(isOpened, order, orderData);
            }
        });
    }

    public proceedToOrderPayment(
        addAmountModalIsOpened: boolean,
        order?: any,
        orderData?: { orderNumber: string; orderTotal: string; orderHasUnfinishedPayments: boolean }
    ): void {
        this.totAmount = this.orderService.Total;
        this.storeSettings$.pipe(take(1)).subscribe((settingsStoreModel: SettingsStoreModel) => {
            if (settingsStoreModel.partialPaymentsEnabled) {
                this.modalService.toggleModal(ModalIds.addAmount, addAmountModalIsOpened, { orderData });
            } else {
                this.payNow(orderData.orderNumber, orderData.orderHasUnfinishedPayments, null, order);
            }
        });
    }

    public payNow(orderNumber: string, orderHasUnfinishedPayments: boolean, totalAmount?: number, order?: any): void {
        this.storeSettings$.pipe(take(1)).subscribe((settingsStoreModel: SettingsStoreModel) => {
            if (orderHasUnfinishedPayments && settingsStoreModel.allowUnfinishedPaymentsProcessing) {
                this.hotApiOrdersService
                    .cancelUnfinishedPayments(orderNumber)
                    .subscribe(() => this.navigateToPaymentProcessingPage(orderNumber, totalAmount, order));
            } else {
                this.navigateToPaymentProcessingPage(orderNumber, totalAmount, order);
            }
        });
    }

    public navigateToPaymentProcessingPage(orderNumber: string, totalAmount?: number, order?: any): void {
        if (this.isMultiplePaymentActiveFlag) {
            this.modalService.toggleModal(ModalIds.payMethodModal, this.isMultiplePaymentActiveFlag, { order });
        } else if (!this.isMultiplePaymentActiveFlag && order.paymentMethod == 'Dlocal.ExpressCheckout') {
            this.orderService.assignOrderNumber(orderNumber);
            this.router.navigate(['/smart-field']);
        }
        else if (totalAmount) {
            this.router.navigate(['/payment-process', orderNumber, totalAmount]);
        }
        else {
            this.router.navigate(['/payment-process', orderNumber]);
        }
    }

}
