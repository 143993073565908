import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ModalIds } from '@hot-libs/shared-types';

import { HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { FeaturesService, ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { cartFetched, cartItems, cartPending } from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine } from '@hot-b2b/store/settings/selector';
import { LineItemsCountService } from '../../services/line-items-count.service';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { Router } from '@angular/router';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';

@Component({
    selector: 'hot-cart-icon',
    templateUrl: './cart-icon.component.html',
})
export class CartIconComponent {
    public cartItems$: Observable<HotCartLineItemExtended[]>;
    public itemsCount$: Observable<number>;
    public settingsAppOnLine$: Observable<boolean>;
    public pending$: Observable<boolean>;
    public cartFetched$: Observable<boolean>;
    public isUpLg = () => this.screenDimensionService.upLg();
    public isBasketOverlay = this.featuresService?.EnableBasketOverlay;
    public basketOverlayPopupState$: Observable<ModalsState>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        public readonly lineItemsCountService: LineItemsCountService,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly router: Router,
        public readonly featuresService: FeaturesService
    ) {
        this.cartItems$ = this.store.pipe(
            select(cartItems),
            map((items: HotCartLineItemExtended[]) =>
                items.filter((item: HotCartLineItemExtended) => !item.isReturnableEmpty)
            )
        );
        this.itemsCount$ = this.lineItemsCountService.getItemsCount(this.cartItems$);
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.pending$ = this.store.pipe(select(cartPending));
        this.cartFetched$ = this.store.pipe(select(cartFetched));
        this.basketOverlayPopupState$ = this.store.pipe(select(selectModalState(ModalIds.basketOverlayPopup)));

    }

    public toggleModal(state: boolean): void {
        this.modalService.toggleModal(ModalIds.cart, state);
    }

    public openOverlay(): void{
        if(this.isBasketOverlay)
        {
	     this.modalService.toggleModal(ModalIds.basketOverlayPopup, true);
        }
        else
          this.navigate('/cart');
    }

    public navigate(url: string): void {
        this.router.navigateByUrl(url);
    }
    public getItemsWithoutGifts(): Observable<HotCartLineItemExtended[]> {
        return this.cartItems$.pipe(
            map((items: HotCartLineItemExtended[]) => items.filter((item: HotCartLineItemExtended) => !item.isGift))
        );
    }
    public closeBasketOverlay() {
        this.modalService.toggleModal(ModalIds.basketOverlayPopup, false);
    }
}
