import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderServiceV2 {
    public isLoading: Subject<boolean> = new Subject();
    public loadingText: Subject<string> = new Subject();

    public show(): void {
        this.isLoading.next(true);
    }

    public hide(): void {
        this.isLoading.next(false);
    }

    public setLoadingText(text: string): void {
        this.loadingText.next(text);
    }

    public clearLoadingText(): void {
        this.loadingText.next(null);
    }
}
