import { Injectable } from '@angular/core';

import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import { settingsStore, settingsCurrencyFormat } from 'apps/hot-b2b/src/app/store/settings/selector';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, switchMap, take } from 'rxjs/operators';
import { CurrencyFormat } from '@hot-theme-nx/generated-api';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { AppState } from '@hot-b2b/store/reducers';
import { catalogIsAlcoholic } from '@hot-b2b/store/catalog/selector';
@Injectable({
    providedIn: 'root',
})
export class AdvanceFilterService {
    public staticFilters = [];
    public settingsStore$: Observable<SettingsStoreModel>;
    public catalogAggregations$;
    public readonly selectedStaticFilter = [];
    public filterAttribute;
    public categoryName;

    public currencyFormat$: Observable<CurrencyFormat>;
    public currencySymbol: string = '';
    public isAlchoholicProduct$: Observable<any[]>;
    public isHighProfitMarginQuickFilterEnabled: boolean;

    constructor(private readonly store: Store<AppState>) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.settingsStore$ = this.store.pipe(select(settingsStore));
        this.isAlchoholicProduct$ = this.store.pipe(select(catalogIsAlcoholic));
    }

    public setActiveFilter(data) {
        let tempdata=cloneDeep(data);
        let index = this.selectedStaticFilter.findIndex((filterValue) => filterValue.groupLabel === tempdata.groupLabel);
       if(index>=0 && tempdata.active){
        this.selectedStaticFilter[index].active=true;
       } else if(index>=0 && !tempdata.active){
        this.selectedStaticFilter.splice(index, 1);
       }else{
        this.selectedStaticFilter.push(tempdata);
       }
    }
    public clearStaticFilter(){
        this.selectedStaticFilter.forEach(element => {
            element.active=false;
        });
    }

    public setActiveCategoryName(activeCategoryName) {
        this.categoryName = activeCategoryName;
    }

    get activeFilter() {
        return this.selectedStaticFilter;
    }

    public getStaticFilters(aggregation = null) {
        this.settingsStore$.pipe(take(1)).subscribe((settings: SettingsStoreModel) => {
            let index1 = findIndex(
                this.staticFilters,
                (staticFilter: any) => staticFilter?.groupLabel === 'is_high_profit_margin'
            );
            if(index1 == -1){
                this.staticFilters.push({
                    groupLabel: 'is_high_profit_margin',
                    name: 'is-high-profit',
                    showModal: false,
                    value: false ,
                });
            }
            let index = findIndex(
                this.staticFilters,
                (staticFilter: any) => staticFilter?.groupLabel === 'showOnlyInStock'
            );

            if (!settings.excludeOutOfStockProductsFromCustomersCatalog && index == -1) {
                this.staticFilters.push({
                    groupLabel: 'showOnlyInStock',
                    name: 'in-stock',
                    showModal: false,
                    value: false,
                });
            }
        });

        aggregation ? this.setIsAlcoholFiltersOnPanel(aggregation) : this.setIsAlcoholFilters();
        return this.staticFilters;
    }

    public setIsAlcoholFilters() {
        this.isAlchoholicProduct$
            .pipe(
                switchMap(value => of(value))
            )
            .subscribe((capturedAggregation: any) => {
                this.catalogAggregations$ = capturedAggregation;
                if (this.catalogAggregations$ && this.catalogAggregations$.length > 0) {
                    const isAlcoholiFilter = {
                        groupLabel: this.catalogAggregations$[0].groupLabel,
                        name: 'is-alcoholic',
                        showModal: false,
                        value: false,
                    };
                    const isAlcolicprdcts = this.catalogAggregations$.filter(
                        (AlcolicFilter) => AlcolicFilter.value === 'True' && AlcolicFilter?.count > 0
                    );
                    const sortFilterExist = this.staticFilters.filter(
                        (sortFilter) => sortFilter.name == 'is-alcoholic'
                    );
                    if (isAlcolicprdcts && sortFilterExist?.length === 0) {
                        this.staticFilters.push(isAlcoholiFilter);
                    } else if (!isAlcolicprdcts && sortFilterExist?.length > 0) {
                        this.staticFilters = this.staticFilters.filter((item) => item != sortFilterExist);
                    }
                }
            });
    }

    setIsAlcoholFiltersOnPanel(aggregation) {
        let capturedAggregation = aggregation.filter((agg) => agg.field == 'Is alcoholic');
        this.catalogAggregations$ = capturedAggregation[0];
        if (this.catalogAggregations$ && this.catalogAggregations$.length > 0) {
            const isAlcoholiFilter = {
                groupLabel: this.catalogAggregations$[0].groupLabel,
                name: 'is-alcoholic',
                showModal: false,
                value: false,
            };
            const isAlcolicprdcts = this.catalogAggregations$.filter(
                (productFilter) => productFilter.value === 'True' && productFilter?.count > 0
            )[0];
            const sortFilterExist = this.staticFilters.filter((filterSort) => filterSort.name == 'is-alcoholic')[0];
            if (isAlcolicprdcts && !sortFilterExist) {
                this.staticFilters.push(isAlcoholiFilter);
            } else if (!isAlcolicprdcts && sortFilterExist) {
                this.staticFilters = this.staticFilters.filter((item) => item != sortFilterExist);
            }
        }
    }

    public addCurrencySymbol(price) {
        this.settingsStore$.pipe(take(1)).subscribe((settingsStoreModel: SettingsStoreModel) => {
            this.currencySymbol = settingsStoreModel.currencyFormat?.currencySymbol?.toString();
        });

        if (price.lower && price.upper) {
            return this.currencySymbol + price.lower + ' - ' + this.currencySymbol + price.upper;
        } else if (price.lower && !price.upper) {
            return this.currencySymbol + price.lower + ' - over';
        } else if (!price.lower && price.upper) {
            return 'under - ' + this.currencySymbol + price.upper;
        } else {
            return price.label;
        }
    }
    public sendAnalyticFilterInteraction(criteria) {
        this.filterAttribute = [];
        if (this.categoryName) {
            this.filterAttribute.push({ name: 'category', value: this.categoryName });
        }
        if (criteria.brandGroup) {
            this.filterAttribute.push({ name: 'brand', value: criteria.brandGroup });
        }
        if (criteria.priceSortingType) {
            this.filterAttribute.push({ name: 'sort', value: criteria.priceSortingType });
        }
        if (criteria.priceRange) {
            const selectedPriceRange = criteria.priceRange.lower + '-' + criteria.priceRange.upper;
            this.filterAttribute.push({ name: 'price', value: selectedPriceRange });
        }
        if (criteria.terms.length > 0 || criteria.showOnlyInStock) {
            this.extractTermsFilter(criteria);
        }
        return this.filterAttribute;
    }

    public extractTermsFilter(criteria) {
        let otherStaticFilters = [];
        let packageTypeSelected = [];
        if (criteria.showOnlyInStock) {
            otherStaticFilters.push('In Stock');
        }
        criteria.terms.forEach((data) => {
            if (data.name == 'is_high_profit_margin') {
                otherStaticFilters.push('High profit');
            } else if (data.name == 'Is alcoholic') {
                otherStaticFilters.push('Is alcoholic');
            } else if (data.name == 'Package type') {
                packageTypeSelected.push(data.value);
            } else if (data.name == 'is_favorite') {
                otherStaticFilters.push('Favorites');
            }
        });

        if (otherStaticFilters.length > 0) {
            this.filterAttribute.push({ name: 'breakdown', value: otherStaticFilters });
        }
        if (packageTypeSelected.length > 0) {
            this.filterAttribute.push({ name: 'packagetype', value: packageTypeSelected });
        }
    }
}
