export enum ECartActions {
    CART_PENDING = 'CART_PENDING',
    CART_OFFLINE_PENDING = 'CART_OFFLINE_PENDING',
    CART_FETCHED = 'CART_FETCHED',
    CART_DATA_UPDATED = 'CART_DATA_UPDATED',
    CART_ERROR = 'CART_ERROR',
    CART_ERROR_CODES = 'CART_ERROR_CODES',
    CART_ERROR_CLEAR = 'CART_ERROR_CLEAR',
    CART_ORDER_PLACEMENT_ERROR = 'CART_ORDER_PLACEMENT_ERROR',
    CART_IN_PROGRESS = 'CART_IN_PROGRESS',
    CART_ITEMS_ADD = 'CART_ITEMS_ADD',
    CART_ITEMS_SELECT = 'CART_ITEMS_SELECT',
    CART_CLEAR_WITH_API_REQUEST = 'CART_CLEAR_WITH_API_REQUEST',
    CART_ITEM_REMOVE = 'CART_ITEM_REMOVE',
    CART_ITEM_EDIT = 'CART_ITEM_EDIT',
    CART_REMARKS_EDIT = 'CART_REMARKS_EDIT',
    CART_REMARKS_CLEAR = 'CART_REMARKS_CLEAR',
    CART_CREATE_ORDER = 'CART_CREATE_ORDER',
    CART_CREATE_ORDER_SUCCESS = 'CART_CREATE_ORDER_SUCCESS',
    CART_CREATE_ORDERS = 'CART_CREATE_ORDERS',
    CART_CREATE_ORDERS_SUCCESS = 'CART_CREATE_ORDERS_SUCCESS',
    CART_SET_DELIVERY_DATE = 'CART_SET_DELIVERY_DATE',
    CART_SET_SHIPMENT_METHOD = 'CART_SET_SHIPMENT_METHOD',
    CART_VALIDATE_SHIPMENT_METHOD = 'CART_VALIDATE_SHIPMENT_METHOD',
    CART_SET_PAYMENT_METHOD = 'CART_SET_PAYMENT_METHOD',
    CART_SET_COUPON = 'CART_SET_COUPON',
    CART_DELETE_COUPON = 'CART_DELETE_COUPON',
    CART_ADD_PREVIOUS_ORDER = 'CART_ADD_PREVIOUS_ORDER',
    CART_ADD_PREVIOUS_ORDER_AND_CHECKOUT = 'CART_ADD_PREVIOUS_ORDER_AND_CHECKOUT',
    CART_SAVE_PREVIOUS_ORDER = 'CART_SAVE_PREVIOUS_ORDER',
    CART_ADD_ORDER_TEMPLATE = 'CART_ADD_ORDER_TEMPLATE',
    CART_ADD_ORDER_TEMPLATE_AND_CHECKOUT = 'CART_ADD_ORDER_TEMPLATE_AND_CHECKOUT',
    CART_SAVE_TEMPLATE = 'CART_SAVE_TEMPLATE',
    CART_SAVE_TEMPLATE_SUCCESS = 'CART_SAVE_TEMPLATE_SUCCESS',
    CART_UPDATE_ITEM = 'CART_UPDATE_ITEM',
    CART_UPDATE_ITEMS = 'CART_UPDATE_ITEMS',
    CART_PRODUCT_UPDATE = 'CART_PRODUCT_UPDATE',
    CART_OFFLINE_ADD_ITEM = 'CART_OFFLINE_ADD_ITEM',
    CART_OFFLINE_CHANGE_ITEM = 'CART_OFFLINE_CHANGE_ITEM',
    CART_OFFLINE_REMOVE_ITEM = 'CART_OFFLINE_REMOVE_ITEM',
    CART_OFFLINE_SYNCHRONIZED = 'CART_OFFLINE_SYNCHRONIZED',
    CART_EMPTY_ACTION = 'CART_EMPTY_ACTION',
    CART_CREATE_REORDER = 'CART_CREATE_REORDER',
    CART_OFFLINE_CREATE_REORDER = 'CART_OFFLINE_CREATE_REORDER',
    CART_ADD_PREVIOUS_ORDER_OFFLINE = 'CART_ADD_PREVIOUS_ORDER_OFFLINE',
    CART_ADD_PREVIOUS_ORDER_OFFLINE_SUCCESS = 'CART_ADD_PREVIOUS_ORDER_OFFLINE_SUCCESS',
    CART_UPDATE = 'CART_UPDATE',
    CART_MANAGE_PRODUCTS_BUFFER = 'CART_MANAGE_PRODUCTS_BUFFER',
    CART_ADD_PENDING_CHANGES = 'CART_ADD_PENDING_CHANGES',
    CART_MOVE_PENDING_TO_PROCESS_CHANGES = 'CART_MOVE_PENDING_TO_PROCESS_CHANGES',
    CART_CLEAR_PROCESS_CHANGES = 'CART_CLEAR_PROCESS_CHANGES',
    CART_CLEAR_CHANGED_ITEMS = 'CART_CLEAR_CHANGED_ITEMS',
    CART_ITEMS_REMOVE_GIFTS = 'CART_ITEMS_REMOVE_GIFTS',
    CART_OFFLINE_CLEAR = 'CART_OFFLINE_CLEAR',
    CART_CLEAR_STORE = 'CART_CLEAR_STORE',
    CART_DEMO_DATA_SET = 'CART_DEMO_DATA_SET',
    CART_DEMO_DATA_CLEAR = 'CART_DEMO_DATA_CLEAR',
    CART_RESTORED = 'CART_RESTORED',
    CART_PROMO_ERROR = 'CART_PROMO_ERROR',
    CART_PROMO_ERROR_CLEAR = 'CART_PROMO_ERROR_CLEAR',
    CART_RECOMMENDED_PRODUCTS = 'CART_RECOMMENDED_PRODUCTS',
    CART_RECOMMENDED_PRODUCTS_CLEAR = 'CART_RECOMMENDED_PRODUCTS_CLEAR',
    CART_RECOMMENDED_PRODUCTS_SUCCESS = 'CART_RECOMMENDED_PRODUCTS_SUCCESS',
    CART_RECOMMENDED_PRODUCTS_LOADING_STATUS = 'CART_RECOMMENDED_PRODUCTS_LOADING_STATUS',
    CART_PAYMENT_ERROR = 'CART_PAYMENT_ERROR',
    CART_PAYMENT_ERROR_CLEAR = 'CART_PAYMENT_ERROR_CLEAR',
    GET_REDIRECT_URL = 'GET_REDIRECT_URL',
}
