import { CartActions } from '@hot-b2b/store/cart/actions';
import {
    changeProductInCart,
    getCartDemoData,
    getCartFromStorage,
    getEmptyCart,
    removeGiftItems,
    removeProductFromCart,
    setArrayOfOrdersInCart,
    setProductInCart,
    updateProductFromCatalog,
} from '@hot-b2b/store/cart/helpers';
import { CartState } from '@hot-b2b/store/cart/model';
import { cartInitialState } from '@hot-b2b/store/cart/state';
import { ECartActions } from '@hot-b2b/store/cart/types';
import { syncQuantityWithCart } from './helpers';

export function cartReducer(state: CartState = cartInitialState, action: CartActions): CartState {
    switch (action.type) {
        case ECartActions.CART_PENDING:
        case ECartActions.CART_OFFLINE_PENDING:
        case ECartActions.CART_DATA_UPDATED:
        case ECartActions.CART_FETCHED:
        case ECartActions.CART_RESTORED:
        case ECartActions.CART_ERROR:
        case ECartActions.CART_ERROR_CODES:
        case ECartActions.CART_PROMO_ERROR:
        case ECartActions.CART_PROMO_ERROR_CLEAR:
        case ECartActions.CART_ERROR_CLEAR:
        case ECartActions.CART_CLEAR_WITH_API_REQUEST:
        case ECartActions.CART_PRODUCT_UPDATE:
        case ECartActions.CART_REMARKS_EDIT:
        case ECartActions.CART_REMARKS_CLEAR:
        case ECartActions.CART_CREATE_ORDER_SUCCESS: {
            return switchReducer(state, action);
        }
        case ECartActions.CART_SET_DELIVERY_DATE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    remarks: {
                        ...state.data.remarks,
                        preferredDeliveryDate: action.payload,
                    },
                },
            };
        }
        case ECartActions.CART_SET_SHIPMENT_METHOD: {
            return {
                ...state,
                data: {
                    ...state.data,
                    remarks: {
                        ...state.data.remarks,
                        shipmentMethodCode: action.payload,
                    },
                },
            };
        }
        case ECartActions.CART_VALIDATE_SHIPMENT_METHOD: {
            return {
                ...state,
                shipmentMethod: {
                    ...state.shipmentMethod,
                    validateDeliveryMethod: action.payload,
                },
            };
        }
        case ECartActions.GET_REDIRECT_URL: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    rediretURL: action.payload,
                },
            };
        }
        case ECartActions.CART_SET_PAYMENT_METHOD: {
            return {
                ...state,
                data: {
                    ...state.data,
                    cart: {
                        ...state.data.cart,
                        selectedPaymentMethod: action.payload,
                    },
                    remarks: {
                        ...state.data.remarks,
                    },
                },
            };
        }
        case ECartActions.CART_IN_PROGRESS:
        case ECartActions.CART_ITEM_REMOVE:
        case ECartActions.CART_CREATE_ORDER:
        case ECartActions.CART_SET_COUPON:
        case ECartActions.CART_DELETE_COUPON:
        case ECartActions.CART_ADD_PREVIOUS_ORDER:
        case ECartActions.CART_ADD_PREVIOUS_ORDER_AND_CHECKOUT:
        case ECartActions.CART_ADD_ORDER_TEMPLATE:
        case ECartActions.CART_ADD_ORDER_TEMPLATE_AND_CHECKOUT:
        case ECartActions.CART_SAVE_TEMPLATE: {
            return {
                ...state,
                pending: true,
                fetched: false,
            };
        }
        case ECartActions.CART_SAVE_TEMPLATE_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
            };
        }
        case ECartActions.CART_OFFLINE_ADD_ITEM:
        case ECartActions.CART_OFFLINE_CHANGE_ITEM:
        case ECartActions.CART_OFFLINE_REMOVE_ITEM:
        case ECartActions.CART_OFFLINE_SYNCHRONIZED:
        case ECartActions.CART_ADD_PREVIOUS_ORDER_OFFLINE: {
            return {
                ...state,
                fetched: true,
                data: {
                    ...state.data,
                    cart: cartDataModel(state, action),
                },
            };
        }
        case ECartActions.CART_UPDATE: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    cart: action.payload,
                    remarks: null,
                },
                error: null,
            };
        }
        case ECartActions.CART_ADD_PENDING_CHANGES:
        case ECartActions.CART_MOVE_PENDING_TO_PROCESS_CHANGES:
        case ECartActions.CART_CLEAR_PROCESS_CHANGES:
        case ECartActions.CART_CLEAR_CHANGED_ITEMS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    changesBuffer: {
                        ...state.data.changesBuffer,
                        pending: action.payload.pending,
                        processing: action.payload.processing,
                        changedItems: action.payload.changedItems,
                    },
                },
            };
        }
        case ECartActions.CART_ITEMS_REMOVE_GIFTS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    cart: removeGiftItems(state.data.cart),
                },
            };
        }
        case ECartActions.CART_OFFLINE_CLEAR: {
            return {
                ...cartInitialState,
                fetched: true,
                data: {
                    ...cartInitialState.data,
                    cart: getEmptyCart(state.data.cart),
                    changesBuffer: {
                        processing: [],
                        pending: [],
                        changedItems: [],
                    },
                },
            };
        }
        case ECartActions.CART_CLEAR_STORE: {
            return {
                ...cartInitialState,
                data: {
                    ...cartInitialState.data,
                    changesBuffer: {
                        processing: [],
                        pending: [],
                        changedItems: [],
                    },
                },
            };
        }
        case ECartActions.CART_DEMO_DATA_SET: {
            return {
                ...state,
                data: {
                    ...state.data,
                    cart: {
                        ...state.data.cart,
                        items: getCartDemoData().items,
                        itemsQuantity: getCartDemoData().itemsQuantity,
                        itemsCount: getCartDemoData().itemsCount,
                        total: getCartDemoData().total,
                    },
                },
            };
        }
        case ECartActions.CART_DEMO_DATA_CLEAR: {
            return {
                ...state,
                data: {
                    ...state.data,
                    cart: {
                        ...state.data.cart,
                        items: [],
                        itemsQuantity: 0,
                        itemsCount: 0,
                        total: 0,
                    },
                },
            };
        }
        case ECartActions.CART_RECOMMENDED_PRODUCTS: {
            return {
                ...state,
            };
        }
        case ECartActions.CART_RECOMMENDED_PRODUCTS_LOADING_STATUS: {
            return {
                ...state,
                isProductRecommederIsLoading: action.payload,
            };
        }
        case ECartActions.CART_RECOMMENDED_PRODUCTS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    cart: {
                        ...state.data.cart,
                        recommendedProducts: syncQuantityWithCart(action.payload, state.data.cart.items),
                    },
                },
            };
        }
        case ECartActions.CART_RECOMMENDED_PRODUCTS_CLEAR: {
            return {
                ...state,
                data: {
                    ...state.data,
                    cart: {
                        ...state.data.cart,
                        recommendedProducts: [],
                    },
                },
            };
        }
        default:
            return state;
    }
}

function switchReducer(state: CartState, action: CartActions): CartState {
    switch (action.type) {
        case ECartActions.CART_PENDING: {
            return {
                ...state,
                fetched: false,
                pending: true,
                error: null,
            };
        }
        case ECartActions.CART_OFFLINE_PENDING: {
            return {
                ...state,
                fetched: false,
            };
        }
        case ECartActions.CART_DATA_UPDATED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    cart: { ...state.data.cart, ...action.payload },
                },
            };
        }
        case ECartActions.CART_FETCHED:
        case ECartActions.CART_RESTORED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    cart: {
                        ...action.payload,
                        recommendedProducts: action.payload?.recommendedProducts
                            ? syncQuantityWithCart(action.payload.recommendedProducts, action.payload.items)
                            : syncQuantityWithCart(state.data.cart.recommendedProducts, action.payload.items),
                    },
                },
            };
        }
        case ECartActions.CART_ERROR: {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        }
        case ECartActions.CART_ERROR_CODES: {
            return {
                ...state,
                pending: false,
                errorCodes: action.payload,
            };
        }
        case ECartActions.CART_PROMO_ERROR: {
            return {
                ...state,
                pending: false,
                error: null,
                promoError: action.payload,
            };
        }
        case ECartActions.CART_PROMO_ERROR_CLEAR: {
            return {
                ...state,
                promoError: null,
            };
        }

        case ECartActions.CART_ERROR_CLEAR: {
            return {
                ...state,
                error: null,
                errorCodes: null,
            };
        }
        case ECartActions.CART_CLEAR_WITH_API_REQUEST: {
            return {
                ...state,
                pending: true,
                fetched: false,
                data: {
                    ...cartInitialState.data,
                    changesBuffer: {
                        processing: [],
                        pending: [],
                        changedItems: [],
                    },
                },
            };
        }
        case ECartActions.CART_PRODUCT_UPDATE: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    cart: updateProductFromCatalog(state.data.cart, action.payload),
                },
            };
        }
        case ECartActions.CART_REMARKS_EDIT: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    remarks: {
                        ...state.data.remarks,
                        [action.payload.field]: action.payload.value,
                    },
                },
            };
        }
        case ECartActions.CART_REMARKS_CLEAR: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    remarks: cartInitialState.data.remarks,
                },
            };
        }
        case ECartActions.CART_CREATE_ORDER_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                error: null,
            };
        }
        default:
            return state;
    }
}

function cartDataModel(state: CartState, action: CartActions) {
    if (action.type === ECartActions.CART_OFFLINE_ADD_ITEM) return setProductInCart(state.data.cart, action.payload);
    else if (action.type === ECartActions.CART_OFFLINE_CHANGE_ITEM)
        return changeProductInCart(state.data.cart, action.payload);
    else if (action.type === ECartActions.CART_OFFLINE_REMOVE_ITEM)
        return removeProductFromCart(state.data.cart, action.payload.id);
    else if (action.type === ECartActions.CART_OFFLINE_SYNCHRONIZED) return getCartFromStorage();
    else if (action.type === ECartActions.CART_ADD_PREVIOUS_ORDER_OFFLINE)
        return setArrayOfOrdersInCart(state.data.cart, action.payload);
}
