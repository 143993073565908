import { OrderTemplateState } from './model';

export const orderTemplateInitialState: OrderTemplateState = {
    pending: false,
    fetched: false,
    data: {
        templates: [],
        selectedTemplate: null,
    },
    error: null,
};
