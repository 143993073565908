import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    HotApiDistributorsService,
    HotDeliveredOrdersStatistics,
    HotOrder,
    HotOrderSearchCriteria,
    HotOrderSearchResult,
    HotUpdateOrderItem,
    HotOrderStatusReasonsWithLanguage,
    HotCancelOrderRequest,
    HotOrderDispatchResult
} from '@hot-theme-nx/generated-api';

@Injectable({
    providedIn: 'root',
})
export class DistributorOrdersClient {
    constructor(
        private readonly distributorsApi: HotApiDistributorsService
    ) { }
    public searchNewOrders(criteria: HotOrderSearchCriteria): Observable<HotOrderSearchResult> {
        return this.distributorsApi.searchNewOrders(criteria);
    }

    public searchConfirmedOrders(criteria: HotOrderSearchCriteria): Observable<HotOrderSearchResult> {
        return this.distributorsApi.searchConfirmedOrders(criteria);
    }

    public searchDispatchedOrders(criteria: HotOrderSearchCriteria): Observable<HotOrderSearchResult> {
        return this.distributorsApi.searchDispatchedOrders(criteria);
    }

    public searchCompletedOrders(criteria: HotOrderSearchCriteria): Observable<HotOrderSearchResult> {
        return this.distributorsApi.searchCompletedOrders(criteria);
    }

    public confirmOrder(orderId: string, date: string): Observable<HotOrder> {
        return this.distributorsApi.confirmOrder({ orderId, body: { deliveryDate: date } });
    }

    public rejectOrder(orderId: string, reason: string): Observable<HotOrder> {
        return this.distributorsApi.rejectOrder({ orderId, body: { rejectionReason: reason } });
    }

    public shipOrder(orderId: string): Observable<HotOrder> {
        return this.distributorsApi.shipOrder(orderId);
    }

    public shipOrders(orderIds: string[]): Observable<HotOrderDispatchResult[]> {
        return this.distributorsApi.shipOrders(orderIds);
    }

    public confirmOrders(orderIds: string[]): Observable<HotOrderDispatchResult[]> {
        return this.distributorsApi.confirmOrders(orderIds);
    }

    public recalculateOrder(orderId: string, items: HotUpdateOrderItem[]): Observable<HotOrder> {
        return this.distributorsApi.recalculateOrder({ orderId, body: { items } });
    }

    public getOrderById(orderId: string): Observable<HotOrder> {
        return this.distributorsApi.getOrderById(orderId);
    }
    public putOrder(orderId: string, deliveryDate: string, items: HotUpdateOrderItem[]): Observable<HotOrder> {
        return this.distributorsApi.updateOrder({ orderId, body: { items, deliveryDate } });
    }
    public putOrderWithReason(
        orderId: string,
        deliveryDate: string,
        items: HotUpdateOrderItem[],
        reason: string
    ): Observable<HotOrder> {
        return this.distributorsApi.updateOrder({
            orderId,
            body: { items, deliveryDate, distributorAdjustmentReason: reason },
        });
    }

    public getOrdersStatisticsForDistributor(
        startDate?: string,
        endDate?: string
    ): Observable<HotDeliveredOrdersStatistics> {
        return this.distributorsApi.getDeliveredOrdersStatistics({ endDate, startDate });
    }

    public getOrderPartialDeliveryReasonsForDistributors(): Observable<HotOrderStatusReasonsWithLanguage[]> {
        return this.distributorsApi.getOrderPartialDeliveryReasons();
    }

    public getOrderRejectionReasonsForDistributors(): Observable<HotOrderStatusReasonsWithLanguage[]> {
        return this.distributorsApi.getOrderRejectionReasons();
    }

    public cancelOrder(request: HotCancelOrderRequest): Observable<HotOrder> {
        return this.distributorsApi.cancelOrder(request);
    }
}
