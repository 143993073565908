import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash/filter';

import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';

@Pipe({
    name: 'searchByValue',
})
export class SearchByValuePipe implements PipeTransform {
    public transform(data: HotProductExtended[], value: string): HotProductExtended[] {
        return value === undefined || value.length === 0
            ? data
            : filter(data, (item: HotProductExtended) => {
                  const nameSkuTypeString = `${item.name}::${item.sku || null}::${item.type || null}`.toLowerCase();
                  return nameSkuTypeString.includes(value.toLowerCase());
              });
    }
}
