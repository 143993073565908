import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageKeys } from 'libs/shared-types/src/lib/enums/storage-keys.enum';

@Component({
    selector: 'hot-theme-nx-digital-payment',
    templateUrl: './digital-payment.component.html',
})
export class DigitalPaymentComponent implements OnInit {
    public response: any;

    public src;
    public redirecttUrl = window.location.origin +'/storefrontapi/hot/pop/card-token-redirector';
    public projId= JSON.parse(localStorage.getItem(StorageKeys.popProjectId));
    public url = `https://checkout.processout.com/` + this.projId +`/hosted-tokenisation?redirect_url=${this.redirecttUrl}&button_color=%23017C28&expiry_label=Expiration&cvc_label=Card security code&button_label=Confirm&cardholder_name_label=Card holder name&`;
    
    constructor(private _sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        this.src = this._sanitizer.bypassSecurityTrustResourceUrl(
            this._sanitizer.sanitize(SecurityContext.URL, this.url)
        );
    }
}
