/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotOrderSearchResult } from '../models/hot-order-search-result';
import { HotGroupAccountOrderSearchCriteria } from '../models/hot-group-account-order-search-criteria';
import { HotGroupAccountOrdersStatisticsResponse } from '../models/hot-group-account-orders-statistics-response';
import { HotGroupAccountOrdersStatisticsRequest } from '../models/hot-group-account-orders-statistics-request';
@Injectable({
  providedIn: 'root',
})
class HotApiGroupAccountService extends __BaseService {
  static readonly searchOrdersPath = '/storefrontapi/hot/group-account/orders/search';
  static readonly getOrdersStatisticsPath = '/storefrontapi/hot/group-account/orders/statistics';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchOrdersResponse(body?: HotGroupAccountOrderSearchCriteria): __Observable<__StrictHttpResponse<HotOrderSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/group-account/orders/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchOrders(body?: HotGroupAccountOrderSearchCriteria): __Observable<HotOrderSearchResult> {
    return this.searchOrdersResponse(body).pipe(
      __map(_r => _r.body as HotOrderSearchResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  getOrdersStatisticsResponse(body?: HotGroupAccountOrdersStatisticsRequest): __Observable<__StrictHttpResponse<HotGroupAccountOrdersStatisticsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/group-account/orders/statistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotGroupAccountOrdersStatisticsResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  getOrdersStatistics(body?: HotGroupAccountOrdersStatisticsRequest): __Observable<HotGroupAccountOrdersStatisticsResponse> {
    return this.getOrdersStatisticsResponse(body).pipe(
      __map(_r => _r.body as HotGroupAccountOrdersStatisticsResponse)
    );
  }
}

module HotApiGroupAccountService {
}

export { HotApiGroupAccountService }
