import { createSelector, MemoizedSelector } from '@ngrx/store';

import { NotificationMessage } from '@hot-theme-nx/generated-api';

import { NotificationsState } from '@hot-b2b/store/notifications/model';
import { AppState } from '@hot-b2b/store/reducers';

const stateNotification = (state: AppState): NotificationsState => state.notifications;

export const notificationPending: MemoizedSelector<AppState, boolean> = createSelector(
    stateNotification,
    (state: NotificationsState) => state.pending
);

export const notificationFetched: MemoizedSelector<AppState, boolean> = createSelector(
    stateNotification,
    (state: NotificationsState) => state.fetched
);

export const notificationData: MemoizedSelector<AppState, NotificationMessage[]> = createSelector(
    stateNotification,
    (state: NotificationsState) => state.data.notifications
);

export const hasNotifications: MemoizedSelector<AppState, boolean> = createSelector(
    stateNotification,
    (state: NotificationsState) => {
        return state.data.notifications.length > 0;
    }
);

export const countNewNotification: MemoizedSelector<AppState, number> = createSelector(
    stateNotification,
    (state: NotificationsState) => {
        return state.data.notifications.filter((notify: NotificationMessage) => !notify.isRead).length;
    }
);

export const newNotificationId: MemoizedSelector<AppState, string[]> = createSelector(
    stateNotification,
    (state: NotificationsState) => {
        return state.data.notifications
            .filter((notify: NotificationMessage) => !notify.isRead)
            .map((notify: NotificationMessage) => notify.id);
    }
);

export const lastNotificationPopupShown: MemoizedSelector<AppState, boolean> = createSelector(
    stateNotification,
    (state: NotificationsState) => state.lastNotificationPopupShown
);

export const lastNotificationRead: MemoizedSelector<AppState, boolean> = createSelector(
    stateNotification,
    (state: NotificationsState) => state.lastNotificationRead
);
