import { Action } from '@ngrx/store';
import { HotLineItem, HotOrder, HotPaymentMethod, HotItemsRequest } from '@hot-theme-nx/generated-api';

import { ECartActions } from '@hot-b2b/store/cart/types';
import { HotOrderLineItemExtended, ProductsBufferModel } from '@hot-libs/shared-models';
import { HotProductExtended, ProductFunctionalityModel } from 'apps/hot-b2b/src/app/catalog/models';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';

export class CartGet implements Action {
    public readonly type = ECartActions.CART_PENDING;
    constructor(public payload?: any) {}
}

export class CartUpdateData implements Action {
    public readonly type = ECartActions.CART_DATA_UPDATED;
    constructor(public payload: HotCartExtended) {}
}

export class CartRestoreSuccess implements Action {
    public readonly type = ECartActions.CART_RESTORED;
    constructor(public payload: HotCartExtended) {}
}

export class CartOfflineGet implements Action {
    public readonly type = ECartActions.CART_OFFLINE_PENDING;
}

export class CartGetSuccess implements Action {
    public readonly type = ECartActions.CART_FETCHED;
    constructor(public payload: HotCartExtended) {}
}

export class CartError implements Action {
    public readonly type = ECartActions.CART_ERROR;
    constructor(public payload: any) {}
}
export class CartErrorCodes implements Action {
    public readonly type = ECartActions.CART_ERROR_CODES;
    constructor(public payload: any) {}
}
export class CartPromoError implements Action {
    public readonly type = ECartActions.CART_PROMO_ERROR;
    constructor(public payload: any) {}
}

export class CartPromoErrorClear implements Action {
    public readonly type = ECartActions.CART_PROMO_ERROR_CLEAR;
}

export class CartErrorClear implements Action {
    public readonly type = ECartActions.CART_ERROR_CLEAR;
}
export class CartOrderPlacementError implements Action {
    public readonly type = ECartActions.CART_ORDER_PLACEMENT_ERROR;
}

export class CartClearWithApiRequest implements Action {
    public readonly type = ECartActions.CART_CLEAR_WITH_API_REQUEST;
}

export class CartAddItem implements Action {
    public readonly type = ECartActions.CART_IN_PROGRESS;
    constructor(public payload: HotLineItem) {}
}

export class CartAddItems implements Action {
    public readonly type = ECartActions.CART_ITEMS_ADD;
    constructor(public payload: HotLineItem[]) {}
}

export class CartSelectItems implements Action {
    public readonly type = ECartActions.CART_ITEMS_SELECT;
    constructor(public payload: HotItemsRequest) {}
}

export class CartRemoveItem implements Action {
    public readonly type = ECartActions.CART_ITEM_REMOVE;
    constructor(public payload: HotCartLineItemExtended) {}
}

export class CartEditRemarks implements Action {
    public readonly type = ECartActions.CART_REMARKS_EDIT;
    constructor(public payload: { value: string; field: string }) {}
}

export class CartClearRemarks implements Action {
    public readonly type = ECartActions.CART_REMARKS_CLEAR;
}

export class CartCreateOrder implements Action {
    public readonly type = ECartActions.CART_CREATE_ORDER;
}

export class CartCreateOrderSuccess implements Action {
    public readonly type = ECartActions.CART_CREATE_ORDER_SUCCESS;
    constructor(public payload: HotOrder) {}
}

export class CartCreateOrders implements Action {
    public readonly type = ECartActions.CART_CREATE_ORDERS;
    constructor(public payload: boolean) {}
}

export class CartCreateOrdersSuccess implements Action {
    public readonly type = ECartActions.CART_CREATE_ORDERS_SUCCESS;
    constructor(public payload: string[]) {}
}

export class CartSetDeliveryDate implements Action {
    public readonly type = ECartActions.CART_SET_DELIVERY_DATE;
    constructor(public payload: string) {}
}

export class CartSetShipmentMethod implements Action {
    public readonly type = ECartActions.CART_SET_SHIPMENT_METHOD;
    constructor(public payload: string) {}
}
export class CartValidateShipmentMethodSelected implements Action {
    public readonly type = ECartActions.CART_VALIDATE_SHIPMENT_METHOD;
    constructor(public payload: boolean) {}
}

export class CartSetPaymentMethod implements Action {
    public readonly type = ECartActions.CART_SET_PAYMENT_METHOD;
    constructor(public payload: HotPaymentMethod) {}
}

export class CartSetCoupon implements Action {
    public readonly type = ECartActions.CART_SET_COUPON;
    constructor(public payload: string) {}
}

export class CartDeleteCoupon implements Action {
    public readonly type = ECartActions.CART_DELETE_COUPON;
}

export class CartAddPreviousOrder implements Action {
    public readonly type = ECartActions.CART_ADD_PREVIOUS_ORDER;
    constructor(public payload: { id: string; route: string | null }) {}
}

export class CartAddPreviousOrderAndCheckOut implements Action {
    public readonly type = ECartActions.CART_ADD_PREVIOUS_ORDER_AND_CHECKOUT;
    constructor(public payload: string) {}
}

export class CartSavePreviousOrder implements Action {
    public readonly type = ECartActions.CART_SAVE_PREVIOUS_ORDER;
    constructor(public payload: { orderId: string; name?: string }) {}
}

export class CartAddOrderTemplate implements Action {
    public readonly type = ECartActions.CART_ADD_ORDER_TEMPLATE;
    constructor(public payload: string) {}
}

export class CartAddOrderTemplateAndCheckOut implements Action {
    public readonly type = ECartActions.CART_ADD_ORDER_TEMPLATE_AND_CHECKOUT;
    constructor(public payload: string) {}
}

export class CartSaveTemplate implements Action {
    public readonly type = ECartActions.CART_SAVE_TEMPLATE;
    constructor(public payload: string) {}
}

export class CartSaveTemplateSuccess implements Action {
    public readonly type = ECartActions.CART_SAVE_TEMPLATE_SUCCESS;
}

export class CartCreateReorder implements Action {
    public readonly type = ECartActions.CART_CREATE_REORDER;
    constructor(public payload: string) {}
}

export class CartOfflineCreateReorder implements Action {
    public readonly type = ECartActions.CART_OFFLINE_CREATE_REORDER;
    constructor(public payload: HotOrderLineItemExtended[]) {}
}

export class CartUpdateItem implements Action {
    public readonly type = ECartActions.CART_UPDATE_ITEM;
    constructor(public payload: string) {}
}

export class CartUpdateItems implements Action {
    public readonly type = ECartActions.CART_UPDATE_ITEMS;
    constructor(public payload: string[]) {}
}

export class CartProductUpdate implements Action {
    public readonly type = ECartActions.CART_PRODUCT_UPDATE;
    constructor(public payload: HotProductExtended) {}
}

export class CartOfflineAddItem implements Action {
    public readonly type = ECartActions.CART_OFFLINE_ADD_ITEM;
    constructor(public payload: HotProductExtended) {}
}

export class CartOfflineChangeItem implements Action {
    public readonly type = ECartActions.CART_OFFLINE_CHANGE_ITEM;
    constructor(public payload: HotProductExtended) {}
}

export class CartOfflineRemoveItem implements Action {
    public readonly type = ECartActions.CART_OFFLINE_REMOVE_ITEM;
    constructor(public payload: { id: string }) {}
}

export class CartOfflineSynchronized implements Action {
    public readonly type = ECartActions.CART_OFFLINE_SYNCHRONIZED;
}

export class CartEmptyAction implements Action {
    public readonly type = ECartActions.CART_EMPTY_ACTION;
}

export class CartAddPreviousOrderOffline implements Action {
    public readonly type = ECartActions.CART_ADD_PREVIOUS_ORDER_OFFLINE;
    constructor(public payload: HotOrderLineItemExtended[]) {}
}

export class CartAddPreviousOrderOfflineSuccess implements Action {
    public readonly type = ECartActions.CART_ADD_PREVIOUS_ORDER_OFFLINE_SUCCESS;
}

export class CartUpdate implements Action {
    public readonly type = ECartActions.CART_UPDATE;
    constructor(public payload: HotCartExtended) {}
}

export class CartManageProductsBuffer implements Action {
    public readonly type = ECartActions.CART_MANAGE_PRODUCTS_BUFFER;
    constructor(public payload: ProductFunctionalityModel) {}
}

export class CartAddPendingChanges implements Action {
    public readonly type = ECartActions.CART_ADD_PENDING_CHANGES;
    constructor(public payload: ProductsBufferModel) {}
}

export class CartMovePendingToProcessChanges implements Action {
    public readonly type = ECartActions.CART_MOVE_PENDING_TO_PROCESS_CHANGES;
    constructor(public payload: ProductsBufferModel) {}
}

export class CartClearProcessChanges implements Action {
    public readonly type = ECartActions.CART_CLEAR_PROCESS_CHANGES;
    constructor(public payload: ProductsBufferModel) {}
}

export class CartClearChangedItems implements Action {
    public readonly type = ECartActions.CART_CLEAR_CHANGED_ITEMS;
    constructor(public payload: ProductsBufferModel) {}
}

export class CartItemsRemoveGifts implements Action {
    public readonly type = ECartActions.CART_ITEMS_REMOVE_GIFTS;
}

export class CartOfflineClear implements Action {
    public readonly type = ECartActions.CART_OFFLINE_CLEAR;
}

export class CartClearStore implements Action {
    public readonly type = ECartActions.CART_CLEAR_STORE;
}

export class CartDemoDataSet implements Action {
    public readonly type = ECartActions.CART_DEMO_DATA_SET;
}

export class CartDemoDataClear implements Action {
    public readonly type = ECartActions.CART_DEMO_DATA_CLEAR;
}

export class CartClearRecommendedProducts implements Action {
    public readonly type = ECartActions.CART_RECOMMENDED_PRODUCTS_CLEAR;
}

export class CartGetRecommendedProducts implements Action {
    public readonly type = ECartActions.CART_RECOMMENDED_PRODUCTS;
    constructor(public payload?: HotCartLineItemExtended[]) {}
}

export class CartGetRecommendedProductsSuccess implements Action {
    public readonly type = ECartActions.CART_RECOMMENDED_PRODUCTS_SUCCESS;
    constructor(public payload: HotProductExtended[]) {}
}

export class CartGetRecommendedLoadingStatus implements Action {
    public readonly type = ECartActions.CART_RECOMMENDED_PRODUCTS_LOADING_STATUS;
    constructor(public payload: boolean) {}
}

export class GetAPMRediretURL implements Action {
    public readonly type = ECartActions.GET_REDIRECT_URL;
    constructor(public payload: string) {}
}

export type CartActions =
    | CartGet
    | CartUpdateData
    | CartOfflineGet
    | CartGetSuccess
    | CartError
    | CartErrorCodes
    | CartPromoError
    | CartErrorClear
    | CartPromoErrorClear
    | CartClearWithApiRequest
    | CartAddItem
    | CartAddItems
    | CartRemoveItem
    | CartEditRemarks
    | CartClearRemarks
    | CartCreateOrder
    | CartCreateOrderSuccess
    | CartCreateOrders
    | CartCreateOrdersSuccess
    | CartSetDeliveryDate
    | CartSetPaymentMethod
    | CartSetCoupon
    | CartDeleteCoupon
    | CartAddPreviousOrder
    | CartAddPreviousOrderAndCheckOut
    | CartAddOrderTemplate
    | CartAddOrderTemplateAndCheckOut
    | CartSaveTemplate
    | CartSaveTemplateSuccess
    | CartUpdateItem
    | CartUpdateItems
    | CartProductUpdate
    | CartOfflineAddItem
    | CartOfflineChangeItem
    | CartOfflineRemoveItem
    | CartOfflineSynchronized
    | CartEmptyAction
    | CartCreateReorder
    | CartOfflineCreateReorder
    | CartAddPreviousOrderOffline
    | CartAddPreviousOrderOfflineSuccess
    | CartUpdate
    | CartManageProductsBuffer
    | CartAddPendingChanges
    | CartMovePendingToProcessChanges
    | CartClearProcessChanges
    | CartClearChangedItems
    | CartItemsRemoveGifts
    | CartOfflineClear
    | CartClearStore
    | CartDemoDataSet
    | CartDemoDataClear
    | CartRestoreSuccess
    | CartGetRecommendedProducts
    | CartGetRecommendedProductsSuccess
    | CartSetShipmentMethod
    | CartValidateShipmentMethodSelected
    | CartSelectItems
    | CartClearRecommendedProducts
    | CartGetRecommendedLoadingStatus
    | GetAPMRediretURL;
