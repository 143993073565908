export enum EInsightsActions {
    INSIGHTS_PENDING = 'INSIGHTS_PENDING',
    INSIGHTS_FETCHED = 'INSIGHTS_FETCHED',
    INSIGHTS_ERROR = 'INSIGHTS_ERROR',
    INSIGHTS_GET_PRODUCTS = 'INSIGHTS_GET_PRODUCTS',
    INSIGHTS_GET_PRODUCTS_SUCCESS = 'INSIGHTS_GET_PRODUCTS_SUCCESS',
    INSIGHTS_SYNCRONIED = 'INSIGHTS_SYNCRONIZED',
    INSIGHTS_SYNCRONIZED_SUCCESS = 'INSIGHTS_SYNCRONIZED_SUCCESS',
    INSIGHTS_CHANGE_FFF_ID = 'INSIGHTS_CHANGE_FFF_ID',
    INSIGHTS_PENDING_FROM_CENTER = 'INSIGHTS_PENDING_FROM_CENTER',
    INSIGHTS_FETCHED_FROM_CENTER = 'INSIGHTS_FETCHED_FROM_CENTER'
}
