import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TriggerService {
    private readonly trigger = new Subject<any>();

    get trigger$() {
        return this.trigger.asObservable();
    }

    public triggerNavigateByNotification(number: string): void {
        this.trigger.next({ orderNumber: number });
    }
}
