import { Injectable } from '@angular/core';
import { merge, Subscription } from 'rxjs';

import { ArbitraryNotification } from '@hot-theme-nx/generated-api';
import { StorageKeys } from '@hot-libs/shared-types';

import { ArbitraryNotificationClient } from 'apps/hot-b2b/src/app/shared/api-clients';
import { ArbitraryNotificationSignalRService } from 'apps/hot-b2b/src/app/shared/signalr';

const MAX_NOTIFICATIONS_COUNT = 100;
const NOTIFICATIONS_SEPARATOR = ';';

@Injectable({
    providedIn: 'root',
})
export class ArbitraryNotificationService {
    private notificationsSubscription: Subscription;

    constructor(
        private readonly arbitraryNotificationSignalRService: ArbitraryNotificationSignalRService,
        private readonly arbitraryNotificationClient: ArbitraryNotificationClient
    ) {}

    public subscribeToArbitraryNotificationPush(): void {
        this.arbitraryNotificationSignalRService.startConnection();

        this.notificationsSubscription = merge(
            this.arbitraryNotificationClient.getActiveNotifications(),
            this.arbitraryNotificationSignalRService.registerNewArbitraryNotificationsPushEvent()
        ).subscribe((notifications: ArbitraryNotification[]) => {
            this.pushActiveArbitraryNotifications(notifications);
        });
    }

    public unsubscribeFromArbitraryNotifications(): void {
        if (this.notificationsSubscription) {
            this.notificationsSubscription.unsubscribe();
        }

        this.arbitraryNotificationSignalRService.stopConnection();
    }

    private pushActiveArbitraryNotifications(notifications: ArbitraryNotification[]): void {
        if ('Notification' in window && Notification.permission === 'granted' && navigator.serviceWorker) {
            navigator.serviceWorker.getRegistration().then((registration) => {
                if (registration) {
                    this.showAllNewNotifications(notifications, registration);
                }
            });
        }
    }

    private showAllNewNotifications(
        arbitraryNotifications: ArbitraryNotification[],
        registration: ServiceWorkerRegistration
    ): void {
        const notificationIdsArr = this.getWatchedNotificationsIds();

        arbitraryNotifications.forEach((arbitraryNotification) => {
            if (!notificationIdsArr.some((id) => id === arbitraryNotification.id)) {
                registration
                    .showNotification(arbitraryNotification.title, {
                        body: arbitraryNotification.body,
                        icon: '../../../assets/app_icon/origin.png',
                        requireInteraction: true,
                        data: arbitraryNotification,
                        vibrate: [500, 100, 500],
                    })
                    .then(() => this.addWatchedNotification(arbitraryNotification.id));
            }
        });
    }

    private getWatchedNotificationsIds(): string[] {
        const arbitraryNotificationIdsString = localStorage.getItem(StorageKeys.watchedArbitraryNotificationIds);

        if (!arbitraryNotificationIdsString) {
            return [];
        }

        return arbitraryNotificationIdsString.split(NOTIFICATIONS_SEPARATOR);
    }

    private addWatchedNotification(notificationId: string): void {
        const watchedNotificationsIds = this.getWatchedNotificationsIds();
        watchedNotificationsIds.push(notificationId);

        if (watchedNotificationsIds.length > MAX_NOTIFICATIONS_COUNT) {
            watchedNotificationsIds.splice(0, 1);
        }

        localStorage.setItem(
            StorageKeys.watchedArbitraryNotificationIds,
            watchedNotificationsIds.join(NOTIFICATIONS_SEPARATOR)
        );
    }
}
