import { Component, Input, OnChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CartService } from '@hot-theme-nx/common-api';
import { CurrencyFormat } from '@hot-theme-nx/generated-api';

import { AppState } from '@hot-b2b/store/reducers';
import { settingsCurrencyFormat, settingsStore } from '@hot-b2b/store/settings/selector';
import { SettingsStoreModel } from '@hot-libs/shared-models';

import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import { ConfigurationService } from '../../../shared/services/configuration.service';


@Component({
    selector: 'hot-price',
    templateUrl: './price.component.html',
})
export class PriceComponent implements OnChanges {
    @Input() public listPrice: number;
    @Input() public actualPrice: number;
    @Input() public salePrice: number = 0;
    @Input() public total: number;
    @Input() public modifier: string;
    @Input() public isGift: boolean;
    @Input() public localCurrencyFormat: CurrencyFormat = null;
    @Input() public isOfflineOrder: boolean;
    @Input() public baseUnitPrice: number;
    @Input() public baseUnitOfMeasure: string;

    public currencyFormat$: Observable<CurrencyFormat>;
    public storeSettings$: Observable<SettingsStoreModel>;

    public oldPrice: number;

    public featureAddProductsWithNoPrice: boolean = this.featuresService.AddProductsWithNoPrice;

   public featureDisplayUnitPrice: boolean = this.featuresService.DisplayUnitPrice;
   public isLAB2b = () => this.configurationService.getCurrentConfiguration() === 'RE';
   public featureOrderSimulationLA: boolean = this.featuresService.OrderSimulationPricesAndTaxes;
   public currencyFormatLA : string;

    constructor(
        private readonly store: Store<AppState>,
        private readonly cartService: CartService,
        public readonly featuresService: FeaturesService,
        private readonly configurationService: ConfigurationService,
    ) {
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.storeSettings$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
    
      this.currencyFormat$.subscribe((format: CurrencyFormat) => {
        this.currencyFormatLA = format.currencySymbol;
    });
    
    }

    public ngOnChanges(): void {
        this.oldPrice = this.cartService.getOldPrice(this.listPrice, this.salePrice);
    }
}
