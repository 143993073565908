import { Action } from '@ngrx/store';

import { EOrderTemplateActions } from '@hot-b2b/store/order-template/types';

import { OrderTemplateModel } from 'apps/hot-b2b/src/app/order-template/models';

export class OrdersTemplatesSaveOffline implements Action {
    public readonly type = EOrderTemplateActions.ORDERS_TEMPLATES_SAVE_OFFLINE;
}

export class OrdersTemplateGet implements Action {
    public readonly type = EOrderTemplateActions.ORDERS_TEMPLATES_PENDING;
}

export class OrdersTemplateGetSuccess implements Action {
    public readonly type = EOrderTemplateActions.ORDERS_TEMPLATES_FETCHED;
    constructor(public payload: OrderTemplateModel[]) {}
}

export class OrdersTemplateUpdate implements Action {
    public readonly type = EOrderTemplateActions.ORDERS_TEMPLATE_UPDATE;
    constructor(public payload: OrderTemplateModel) {}
}

export class OrdersTemplateDelete implements Action {
    public readonly type = EOrderTemplateActions.ORDERS_TEMPLATE_DELETE;
    constructor(public payload: string) {}
}

export class OrderTemplateSelect implements Action {
    public readonly type = EOrderTemplateActions.ORDERS_TEMPLATE_SELECT;
    constructor(public payload: OrderTemplateModel) {}
}

export class OrderTemplateAddNew implements Action {
    public readonly type = EOrderTemplateActions.ORDERS_TEMPLATE_ADD_NEW;
    constructor(public payload: OrderTemplateModel) {}
}

export type OrderTemplateActions =
    | OrdersTemplatesSaveOffline
    | OrdersTemplateGet
    | OrdersTemplateGetSuccess
    | OrdersTemplateUpdate
    | OrdersTemplateDelete
    | OrderTemplateSelect
    | OrderTemplateAddNew;
