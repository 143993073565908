/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotCarouselBanner } from '../models/hot-carousel-banner';
import { HotPromotion } from '../models/hot-promotion';
import { HotOffInvoicePromotionResults } from '../models/hot-off-invoice-promotion-results';
import { HotOffInvoiceProgramResults } from '../models/hot-off-invoice-program-results';
import { HotInsight } from '../models/hot-insight';
import { OrderSuggestion } from '../models/order-suggestion';
import { HotSuggestedInsightSearchResponse } from '../models/hot-suggested-insight-search-response';
import { HotSuggestedInsightSearchCriteria } from '../models/hot-suggested-insight-search-criteria';
import { HotCouponSearchResult } from '../models/hot-coupon-search-result';
import { CouponSearchCriteriaHot } from '../models/coupon-search-criteria-hot';
import { HotOffInvoicePromotionDetail } from '../models/hot-off-invoice-promotion-detail';
@Injectable({
  providedIn: 'root',
})
class HotApiMarketingService extends __BaseService {
  static readonly getCarouselBannersPath = '/storefrontapi/hot/marketing/carousel-banners';
  static readonly getPromotionsPath = '/storefrontapi/hot/marketing/promotions';
  static readonly getOffInvoicePromotionsPath = '/storefrontapi/hot/marketing/offinvoice/promotions';
  static readonly getOffInvoiceProgramsPath = '/storefrontapi/hot/marketing/offinvoice/programs';
  static readonly getPromotionsHistoryPath = '/storefrontapi/hot/marketing/promotions/history';
  static readonly getPromotionPath = '/storefrontapi/hot/marketing/promotions/{code}';
  static readonly getInsightsPath = '/storefrontapi/hot/marketing/insights';
  static readonly getInsightPath = '/storefrontapi/hot/marketing/insights/{code}';
  static readonly getTokenPath = '/storefrontapi/hot/marketing/insights/getToken';
  static readonly getOrderSuggestionPath = '/storefrontapi/hot/marketing/suggestions';
  static readonly getOrderSuggestionV2Path = '/storefrontapi/hot/marketing/suggestions/v2';
  static readonly searchSuggestedInsightPath = '/storefrontapi/hot/marketing/insights/suggested-insight';
  static readonly searchApplicableCouponsPath = '/storefrontapi/hot/marketing/coupons';
  static readonly getOffInvoicePromotionDetailsPath = '/storefrontapi/hot/marketing/offinvoice/promotion-details';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  getCarouselBannersResponse(): __Observable<__StrictHttpResponse<Array<HotCarouselBanner>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/carousel-banners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotCarouselBanner>>;
      })
    );
  }
  /**
   * @return Success
   */
  getCarouselBanners(): __Observable<Array<HotCarouselBanner>> {
    return this.getCarouselBannersResponse().pipe(
      __map(_r => _r.body as Array<HotCarouselBanner>)
    );
  }

  /**
   * @param params The `HotApiMarketingService.GetPromotionsParams` containing the following parameters:
   *
   * - `includeInvisiblePromotions`:
   *
   * - `fulfillmentCenterId`:
   *
   * @return Success
   */
  getPromotionsResponse(params: HotApiMarketingService.GetPromotionsParams): __Observable<__StrictHttpResponse<Array<HotPromotion>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.includeInvisiblePromotions != null) __params = __params.set('includeInvisiblePromotions', params.includeInvisiblePromotions.toString());
    if (params.fulfillmentCenterId != null) __params = __params.set('fulfillmentCenterId', params.fulfillmentCenterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/promotions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotPromotion>>;
      })
    );
  }
  /**
   * @param params The `HotApiMarketingService.GetPromotionsParams` containing the following parameters:
   *
   * - `includeInvisiblePromotions`:
   *
   * - `fulfillmentCenterId`:
   *
   * @return Success
   */
  getPromotions(params: HotApiMarketingService.GetPromotionsParams): __Observable<Array<HotPromotion>> {
    return this.getPromotionsResponse(params).pipe(
      __map(_r => _r.body as Array<HotPromotion>)
    );
  }

  /**
   * @param params The `HotApiMarketingService.GetOffInvoicePromotionsParams` containing the following parameters:
   *
   * - `PromotionStatus`:
   *
   * - `ProgramId`:
   *
   * @return Success
   */
  getOffInvoicePromotionsResponse(params: HotApiMarketingService.GetOffInvoicePromotionsParams): __Observable<__StrictHttpResponse<HotOffInvoicePromotionResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.PromotionStatus != null) __params = __params.set('PromotionStatus', params.PromotionStatus.toString());
    if (params.ProgramId != null) __params = __params.set('ProgramId', params.ProgramId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/offinvoice/promotions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOffInvoicePromotionResults>;
      })
    );
  }
  /**
   * @param params The `HotApiMarketingService.GetOffInvoicePromotionsParams` containing the following parameters:
   *
   * - `PromotionStatus`:
   *
   * - `ProgramId`:
   *
   * @return Success
   */
  getOffInvoicePromotions(params: HotApiMarketingService.GetOffInvoicePromotionsParams): __Observable<HotOffInvoicePromotionResults> {
    return this.getOffInvoicePromotionsResponse(params).pipe(
      __map(_r => _r.body as HotOffInvoicePromotionResults)
    );
  }

  /**
   * @param params The `HotApiMarketingService.GetOffInvoiceProgramsParams` containing the following parameters:
   *
   * - `PromotionStatus`:
   *
   * - `ProgramId`:
   *
   * @return Success
   */
  getOffInvoiceProgramsResponse(params: HotApiMarketingService.GetOffInvoiceProgramsParams): __Observable<__StrictHttpResponse<HotOffInvoiceProgramResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.PromotionStatus != null) __params = __params.set('PromotionStatus', params.PromotionStatus.toString());
    if (params.ProgramId != null) __params = __params.set('ProgramId', params.ProgramId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/offinvoice/programs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOffInvoiceProgramResults>;
      })
    );
  }
  /**
   * @param params The `HotApiMarketingService.GetOffInvoiceProgramsParams` containing the following parameters:
   *
   * - `PromotionStatus`:
   *
   * - `ProgramId`:
   *
   * @return Success
   */
  getOffInvoicePrograms(params: HotApiMarketingService.GetOffInvoiceProgramsParams): __Observable<HotOffInvoiceProgramResults> {
    return this.getOffInvoiceProgramsResponse(params).pipe(
      __map(_r => _r.body as HotOffInvoiceProgramResults)
    );
  }

  /**
   * @param includeInvisiblePromotions undefined
   * @return Success
   */
  getPromotionsHistoryResponse(includeInvisiblePromotions?: boolean): __Observable<__StrictHttpResponse<Array<HotPromotion>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (includeInvisiblePromotions != null) __params = __params.set('includeInvisiblePromotions', includeInvisiblePromotions.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/promotions/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotPromotion>>;
      })
    );
  }
  /**
   * @param includeInvisiblePromotions undefined
   * @return Success
   */
  getPromotionsHistory(includeInvisiblePromotions?: boolean): __Observable<Array<HotPromotion>> {
    return this.getPromotionsHistoryResponse(includeInvisiblePromotions).pipe(
      __map(_r => _r.body as Array<HotPromotion>)
    );
  }

  /**
   * @param code undefined
   * @return Success
   */
  getPromotionResponse(code: string): __Observable<__StrictHttpResponse<HotPromotion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/promotions/${encodeURIComponent(String(code))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotPromotion>;
      })
    );
  }
  /**
   * @param code undefined
   * @return Success
   */
  getPromotion(code: string): __Observable<HotPromotion> {
    return this.getPromotionResponse(code).pipe(
      __map(_r => _r.body as HotPromotion)
    );
  }

  /**
   * @param params The `HotApiMarketingService.GetInsightsParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `fulfillmentCenterId`:
   *
   * @return Success
   */
  getInsightsResponse(params: HotApiMarketingService.GetInsightsParams): __Observable<__StrictHttpResponse<Array<HotInsight>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.fulfillmentCenterId != null) __params = __params.set('fulfillmentCenterId', params.fulfillmentCenterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/insights`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotInsight>>;
      })
    );
  }
  /**
   * @param params The `HotApiMarketingService.GetInsightsParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `fulfillmentCenterId`:
   *
   * @return Success
   */
  getInsights(params: HotApiMarketingService.GetInsightsParams): __Observable<Array<HotInsight>> {
    return this.getInsightsResponse(params).pipe(
      __map(_r => _r.body as Array<HotInsight>)
    );
  }

  /**
   * @param code undefined
   * @return Success
   */
  getInsightResponse(code: string): __Observable<__StrictHttpResponse<HotInsight>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/insights/${encodeURIComponent(String(code))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotInsight>;
      })
    );
  }
  /**
   * @param code undefined
   * @return Success
   */
  getInsight(code: string): __Observable<HotInsight> {
    return this.getInsightResponse(code).pipe(
      __map(_r => _r.body as HotInsight)
    );
  }

  /**
   * @return Success
   */
  getTokenResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/insights/getToken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  getToken(): __Observable<string> {
    return this.getTokenResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  getOrderSuggestionResponse(): __Observable<__StrictHttpResponse<OrderSuggestion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/suggestions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderSuggestion>;
      })
    );
  }
  /**
   * @return Success
   */
  getOrderSuggestion(): __Observable<OrderSuggestion> {
    return this.getOrderSuggestionResponse().pipe(
      __map(_r => _r.body as OrderSuggestion)
    );
  }

  /**
   * @return Success
   */
  getOrderSuggestionV2Response(): __Observable<__StrictHttpResponse<OrderSuggestion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/suggestions/v2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderSuggestion>;
      })
    );
  }
  /**
   * @return Success
   */
  getOrderSuggestionV2(): __Observable<OrderSuggestion> {
    return this.getOrderSuggestionV2Response().pipe(
      __map(_r => _r.body as OrderSuggestion)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchSuggestedInsightResponse(body?: HotSuggestedInsightSearchCriteria): __Observable<__StrictHttpResponse<HotSuggestedInsightSearchResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/marketing/insights/suggested-insight`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotSuggestedInsightSearchResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchSuggestedInsight(body?: HotSuggestedInsightSearchCriteria): __Observable<HotSuggestedInsightSearchResponse> {
    return this.searchSuggestedInsightResponse(body).pipe(
      __map(_r => _r.body as HotSuggestedInsightSearchResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchApplicableCouponsResponse(body?: CouponSearchCriteriaHot): __Observable<__StrictHttpResponse<HotCouponSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/marketing/coupons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCouponSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchApplicableCoupons(body?: CouponSearchCriteriaHot): __Observable<HotCouponSearchResult> {
    return this.searchApplicableCouponsResponse(body).pipe(
      __map(_r => _r.body as HotCouponSearchResult)
    );
  }

  /**
   * @param params The `HotApiMarketingService.GetOffInvoicePromotionDetailsParams` containing the following parameters:
   *
   * - `promotionId`:
   *
   * - `outletOuterId`:
   *
   * @return Success
   */
  getOffInvoicePromotionDetailsResponse(params: HotApiMarketingService.GetOffInvoicePromotionDetailsParams): __Observable<__StrictHttpResponse<HotOffInvoicePromotionDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.promotionId != null) __params = __params.set('promotionId', params.promotionId.toString());
    if (params.outletOuterId != null) __params = __params.set('outletOuterId', params.outletOuterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/marketing/offinvoice/promotion-details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOffInvoicePromotionDetail>;
      })
    );
  }
  /**
   * @param params The `HotApiMarketingService.GetOffInvoicePromotionDetailsParams` containing the following parameters:
   *
   * - `promotionId`:
   *
   * - `outletOuterId`:
   *
   * @return Success
   */
  getOffInvoicePromotionDetails(params: HotApiMarketingService.GetOffInvoicePromotionDetailsParams): __Observable<HotOffInvoicePromotionDetail> {
    return this.getOffInvoicePromotionDetailsResponse(params).pipe(
      __map(_r => _r.body as HotOffInvoicePromotionDetail)
    );
  }
}

module HotApiMarketingService {

  /**
   * Parameters for GetPromotions
   */
  export interface GetPromotionsParams {
    includeInvisiblePromotions?: boolean;
    fulfillmentCenterId?: string;
  }

  /**
   * Parameters for GetOffInvoicePromotions
   */
  export interface GetOffInvoicePromotionsParams {
    PromotionStatus?: string;
    ProgramId?: string;
  }

  /**
   * Parameters for GetOffInvoicePrograms
   */
  export interface GetOffInvoiceProgramsParams {
    PromotionStatus?: string;
    ProgramId?: string;
  }

  /**
   * Parameters for GetInsights
   */
  export interface GetInsightsParams {
    pageSize?: number;
    fulfillmentCenterId?: string;
  }

  /**
   * Parameters for GetOffInvoicePromotionDetails
   */
  export interface GetOffInvoicePromotionDetailsParams {
    promotionId?: string;
    outletOuterId?: string;
  }
}

export { HotApiMarketingService }
