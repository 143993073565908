import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
import map from 'lodash/map';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';

import { ConnectionService, ScreenDimensionService } from '@hot-libs/browser-specific';
import { HotLanguage } from '@hot-theme-nx/generated-api';

import { FeatureScript, SettingsStoreModel } from '@hot-libs/shared-models';
import { CmsImageType, FeatureNames } from '@hot-libs/shared-types';

import { ApplicationInsightsService } from 'apps/hot-b2b/src/app/shared/services/application-insights.service';
import { ConfigurationService } from 'apps/hot-b2b/src/app/shared/services/configuration.service';
import { DynamicScriptLoaderService } from 'apps/hot-b2b/src/app/shared/services/dynamic-script-loader.service';
import { InitDataManagerService } from 'apps/hot-b2b/src/app/shared/services/initdata-manager.service';
import { StyleService } from 'apps/hot-b2b/src/app/shared/services/style.service';

import { AppState } from '@hot-b2b/store/reducers';
import { FeaturesItem, HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData, settingsFeatures, settingsStore } from '@hot-b2b/store/settings/selector';

import { AnalyticRouteService } from './shared/services/analytic-route.service';

import { NavigationEnd, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services/features.service';
import { environment } from 'apps/hot-b2b/src/environments/environment';
import ngswconfig from '../ngsw-config.json';
import { NetworkConnectionService } from './shared/services/network-connection.service';
import { CmsImagesGet } from './store/cms-images/actions';
import { OptimizelyService } from '@hot-theme-nx/common-api';
import { NativeAppModel, NativeService } from './native/services';
import { nativeMobileAppDetails } from './store/nativemobileapp/selector';
import { UpdateNativeMobileAppDetails } from './store/nativemobileapp/actions';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    public isHOPb2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public settingsFeatures$: Observable<FeaturesItem[]>;
    private readonly settings$: Observable<HotSettingsExtended>;
    private readonly featuresScripts: FeatureScript[] = [
        { featureName: FeatureNames.VisualWebsiteOptimizer, scriptName: 'visualWebsite' },
        { featureName: FeatureNames.Hotjar, scriptName: 'hotJar' },
        { featureName: FeatureNames.GoogleAnalytics, scriptName: 'googleAnalytics' },
        { featureName: FeatureNames.EMulaCheckoutScript, scriptName: 'emula' },
        { featureName: FeatureNames.ChatbotClientScript, scriptName: 'chatbot' },
    ];
    public readonly settingsStore$: Observable<SettingsStoreModel>;
    public title: string;
    isChatbotEnable = false;
    public featureEnableChatBot: boolean = this.featuresService.EnableChatBot;
    public isFaqIconEnable = false;
    public isFaqTextTopDown = false;

    isDotTutorialBtnVisible = false;
    public featureEnableDotTutorial: boolean = this.featuresService.ShowDotTutorialButton;

    public currentRoute = '';
    public isVersionUpdated: boolean = true;
    public themeVersionFromSettings: string = '';
    public storefrontVersionFromSettings: string = '';

    constructor(
        private readonly initDataManagerService: InitDataManagerService,
        private readonly dynamicScriptLoaderService: DynamicScriptLoaderService,
        private readonly store: Store<AppState>,
        private readonly translateService: TranslateService,
        private readonly swUpdate: SwUpdate,
        private readonly connectionService: ConnectionService,
        private readonly analyticRouteService: AnalyticRouteService,
        private readonly applicationInsightsService: ApplicationInsightsService,
        private readonly titleService: Title,
        private readonly configurationService: ConfigurationService,
        private readonly featuresService: FeaturesService,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly optimizelyService: OptimizelyService,
        private styleService: StyleService,
        private readonly router: Router,
        private networkService: NetworkConnectionService,
        private nativeService: NativeService
    ) {
        this.settings$ = this.store.pipe(select(settingsData));
        this.settingsFeatures$ = this.store.pipe(select(settingsFeatures));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );

        this.title = this.configurationService.getAppTitle();
        this.titleService.setTitle(this.title);

    }

    public isUpLg = (): boolean => {
        return this.screenDimensionService.upLg();
    };

    public ngOnInit(): void {
        this.optimizelyService.createOptimizely();
        this.styleService.initialize();
        this.applicationInsightsService.initialize();
        this.initDataManagerService.initWorker();
        this.initializeSettings();
        this.loadImages();
        this.loadScriptFeature();
        this.analyticRouteService.loadAnalyticRouteEvent();
        this.checkConnectionStatus();

        let platform = Capacitor.getPlatform();

        if (platform === 'ios') {
            this.checkForUpdates();
        }

        try {
            localStorage.setItem('client_name', platform);
        } catch (err) {
            console.error('Error occured');
        }

        if (platform !== 'ios' && this.swUpdate.isEnabled && environment.pwa) {
            this.checkForUpdates();
            this.swUpdate.available.subscribe(() => {
                localStorage.setItem('New_Version_Update', 'AVAILABLE');
            });
        }
        this.platformNavigation();

        const loadingMark = document.getElementById('app-loader');
        loadingMark.parentNode.removeChild(loadingMark);

        //Load native application information and store the details in app state
        this.nativeService.getNativeAppDetails().then((data: NativeAppModel) => {
            //store the details in redux state
            console.log('app:component:getNativeAppDetails:start');
            console.log('appVersion' + data.appVersion);
            console.log('isMobileApp' + data.isMobileApp);
            console.log('packageName' + data.packageName);
            console.log('platform' + data.platform);
            console.log('mobileApp' + data.mobileApp.toString());
            console.log('app:component:getNativeAppDetails:end');
            this.store.dispatch(new UpdateNativeMobileAppDetails(data));
        });
    }

    public checkForUpdates() {
        let updateAvailable = localStorage.getItem('New_Version_Update') == 'AVAILABLE';
        let storeFrontVersion = localStorage.getItem('storeFrontVersion');
        let themeVersion = localStorage.getItem('themeVersion');
        if (
            storeFrontVersion != this.storefrontVersionFromSettings ||
            themeVersion != this.themeVersionFromSettings ||
            updateAvailable
        ) {
            if (storeFrontVersion !== null || themeVersion !== null) {
                this.clearCache();
            }
            this.checkOldSW()?.then((oldSW) => {
                if (oldSW) { // if the cache contains old angular cache details
                // Re-register the service worker
                navigator?.serviceWorker?.register('/ngsw-worker.js')
                ?.then(registration => { registration.unregister(); })
                .catch(err => console.log(`Service Worker Re-registration Error - ${err}`));
                }
            }).catch(err => console.log(`Service Worker Re-registration Error - ${err}`));

            this.isVersionUpdated = true;
            if (this.isVersionUpdated) {
                localStorage.setItem('storeFrontVersion', this.storefrontVersionFromSettings);
                localStorage.setItem('themeVersion', this.themeVersionFromSettings);
                this.isVersionUpdated = false;
            }

            if (updateAvailable) {
                localStorage.setItem('New_Version_Update', 'UPDATED');
            }
        }
    }

    checkOldSW(): Promise<boolean> {
        return navigator?.serviceWorker?.ready?.then(() => {
            const oldCacheName = ':dynamic';
            return this.cacheNameExists(oldCacheName)
            .then((exists: boolean) => {
                return exists;
            }).catch(()=> {
                return false;
            });
        });
    }
    async cacheNameExists(oldCacheName: string): Promise<boolean> {
        const cacheNames = await caches.keys();
        return !!cacheNames?.find(e => e.includes(oldCacheName));
    }

    public platformNavigation() {
        this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event) => {
            const currentRoute = event.url;
            this.currentRoute = event.url;
            if (this.featureEnableChatBot && currentRoute === '/') {
                this.isChatbotEnable = true;
            } else {
                this.isChatbotEnable = false;
            }
            if (!this.featureEnableChatBot && (currentRoute === '/' || currentRoute.includes('/thank-you'))) {
                this.isFaqIconEnable = true;
            } else {
                this.isFaqIconEnable = false;
            }
            if (
                this.featureEnableDotTutorial &&
                ((currentRoute === '/' && !this.isHOPb2b()) || currentRoute === '/ordering')
            ) {
                this.isDotTutorialBtnVisible = true;
            } else {
                this.isDotTutorialBtnVisible = false;
            }
            try {
                this.checkForUpdates();
            } catch (error) {
                console.log('version error');
            }
        });
    }

    private loadScriptFeature() :void{
        this.settingsFeatures$
            .pipe(first((featuresItems: FeaturesItem[]) => featuresItems.length > 1))
            .subscribe((features: FeaturesItem[]) => {
                this.featuresScripts.forEach((featureScript: FeatureScript) => {
                    const feature: FeaturesItem = find(features, {
                        name: featureScript.featureName,
                        isActive: true,
                    });

                    console.log(feature);
                    if (!isUndefined(feature)) {
                        this.dynamicScriptLoaderService.load(featureScript.scriptName);
                    }
                });
            });
    }

    private initializeSettings(): void {
        this.updateMomentLocale();
        this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
            if (settings.availableLanguages.length) {
                const languageCodes: string[] = map(
                    settings.availableLanguages,
                    (language: HotLanguage) => language.code
                );
                this.translateService.addLangs(languageCodes);

                const currentLanguageCode = settings.currentLanguage ? settings.currentLanguage.code : languageCodes[0];
                const defaultLanguageCode = 'en-US';
                const isDefaultLanguageAvailable = !!find(languageCodes, { code: defaultLanguageCode });

                this.translateService.setDefaultLang(
                    isDefaultLanguageAvailable ? defaultLanguageCode : currentLanguageCode
                );
                this.translateService.use(currentLanguageCode || defaultLanguageCode);
                moment.locale(currentLanguageCode || defaultLanguageCode);
                document.documentElement.lang = this.translateService.currentLang.split('-')[0];

                if (currentLanguageCode === 'zh-CN') {
                    this.isFaqTextTopDown = true;
                }
            }
            this.themeVersionFromSettings = settings.themeVersion; //To get Theme Version from Settings
            this.storefrontVersionFromSettings = settings.storefrontVersion; //To get Storefront Version from Settings
            this.setAnalyticsScriptsData(settings);
        });
    }

    private loadImages(): void {
        const types = [
            CmsImageType.SignInPageBackgroundDesktop,
            CmsImageType.SignInPageBackgroundMobile,
            CmsImageType.SignInPageLogoDesktop,
            CmsImageType.SignInPageLogoMobile,
            CmsImageType.PageHeaderLogo,
        ];

        this.store.dispatch(new CmsImagesGet(types));
    }

    private setAnalyticsScriptsData(settings: HotSettingsExtended): void {
        switch (settings.environment) {
            case 'QA':
                window['environmentType'] = 'quality';
                break;
            case 'ACC':
                window['environmentType'] = 'acc';
                break;
            case 'PROD':
                window['environmentType'] = 'prod';
                break;
            default:
                window['environmentType'] = 'quality';
                break;
        }

        window['nexusScriptsPath'] = environment.nexusScriptsPath;
        window['featureCookieWall'] = settings.features.find(
            (feature) => feature.name === FeatureNames.CookieWall
        )?.isActive;
        window['AnalyticsSettings'] = settings.analytics;
    }

    private checkConnectionStatus(): void {
        this.connectionService
            .monitor()
            .subscribe((status: boolean) =>
                !status ? console.log('The application has no internet connection') : null
            );
    }

    public clearCache(): void {
        this.clearPerformanceCache().then(() => {
            this.initWorker();
        });
    }

    public clearPerformanceCache(): Promise<Promise<boolean>[][]> {
        const dataGroupNames = ngswconfig.dataGroups
            .filter(
                (a) =>
                    a.name === 'performance' ||
                    a.name === 'performance2' ||
                    a.name === 'performance2d' ||
                    a.name === 'performance3' ||
                    a.name === 'performance8h'
            )

            .map((a) => a.name);
        return caches.keys().then((cacheKeys) =>
            Promise.all(
                cacheKeys.map((cacheKey) => {
                    if (dataGroupNames.some((v) => cacheKey.includes(v))) {
                        return caches
                            .open(cacheKey)
                            .then((cache) => cache.keys().then((requests) => requests.map((req) => cache.delete(req))));
                    }
                })
            )
        );
    }

    private initWorker(): void {
        this.initDataManagerService.initWorker(true);
    }

    private updateMomentLocale(): void {
        moment.updateLocale('rw', {
            months: [
                'Mutarama',
                'Gashyantare',
                'Werurwe',
                'Mata',
                'Gicuransi',
                'Kamena',
                'Nyakanga',
                'Kanama',
                'Nzeli',
                'Ukwakira',
                'Ugushyingo',
                'Ukuboza',
            ],
            monthsShort: ['Mut', 'Gas', 'Wer', 'Mat', 'Gic', 'Kam', 'Nya', 'Kan', 'Nze', 'Ukw', 'Ugu', 'Uku'],
            weekdays: [
                'Kwa mbere',
                'Kwa kabiri',
                'Kwa gatatu',
                'Kwa kane',
                'Kwa gatanu',
                'Kwa gatandatu',
                'Ku cyumweru',
            ],
            weekdaysShort: ['mbe', 'kab', 'gtu', 'kan', 'gnu', 'gnd', 'cyu'],
        });

        moment.updateLocale('am', {
            months: ['ጃንዋሪ', 'ፌብርዋሪ', 'ማርች', 'አፕሪል', 'ሜይ', 'ጁን', 'ጁላይ', 'ኦገስት', 'ሴፕቴምበር', 'ኦክቶበር', 'ኖቬምበር', 'ዲሴምበር'],
            monthsShort: [
                'ጃንዋሪ',
                'ፌብርዋሪ',
                'ማርች',
                'አፕሪል',
                'ሜይ',
                'ጁን',
                'ጁላይ',
                'ኦገስት',
                'ሴፕቴምበር',
                'ኦክቶበር',
                'ኖቬምበር',
                'ዲሴምበር',
            ],
            weekdays: ['ሰኞ', 'ማክሰ', 'እሮብ', 'ሐሙስ', 'አርብ', 'ቅዳሜ', 'እሁድ'],
            weekdaysShort: ['ሰኞ', 'ማክሰ', 'እሮብ', 'ሐሙስ', 'አርብ', 'ቅዳሜ', 'እሁድ'],
        });
    }
}
