import { Action } from '@ngrx/store';

import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { InsightSlotModel } from '@hot-b2b/store/insights/model';
import { EInsightsActions } from '@hot-b2b/store/insights/types';

export class InsightsGet implements Action {
    public readonly type = EInsightsActions.INSIGHTS_PENDING;
}

export class InsightsGetFromCenter implements Action {
    public readonly type = EInsightsActions.INSIGHTS_PENDING_FROM_CENTER;
    constructor(public payload: string) { }
}

export class InsightsGetSuccessFromCenter implements Action {
    public readonly type = EInsightsActions.INSIGHTS_FETCHED_FROM_CENTER;
    constructor(public payload: InsightSlotModel[]) { }
}

export class InsightsGetSuccess implements Action {
    public readonly type = EInsightsActions.INSIGHTS_FETCHED;
    constructor(public payload: InsightSlotModel[]) { }
}

export class InsightsError implements Action {
    public readonly type = EInsightsActions.INSIGHTS_ERROR;
    constructor(public payload: string | boolean) { }
}

export class InsightsGetProducts implements Action {
    public readonly type = EInsightsActions.INSIGHTS_GET_PRODUCTS;
    constructor(public payload: string[]) { }
}

export class InsightsGetProductsSuccess implements Action {
    public readonly type = EInsightsActions.INSIGHTS_GET_PRODUCTS_SUCCESS;
    constructor(public payload: HotProductExtended[]) { }
}

export class InsightsSyncronized implements Action {
    public readonly type = EInsightsActions.INSIGHTS_SYNCRONIED;
    constructor(public payload?: HotCartExtended) { }
}

export class InsightsSyncronizedSuccess implements Action {
    public readonly type = EInsightsActions.INSIGHTS_SYNCRONIZED_SUCCESS;
    constructor(public payload: HotCartLineItemExtended[]) { }
}

export type InsightsActions =
    | InsightsGet
    | InsightsGetFromCenter
    | InsightsGetSuccess
    | InsightsError
    | InsightsGetProducts
    | InsightsGetProductsSuccess
    | InsightsSyncronized
    | InsightsSyncronizedSuccess
    | InsightsGetSuccessFromCenter;
