/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotLoyaltyBanner } from '../models/hot-loyalty-banner';
import { HotFooterItem } from '../models/hot-footer-item';
import { HotHomePageWidgetsWithLanguage } from '../models/hot-home-page-widgets-with-language';
import { HotDistributorHomePageTilesWithLanguage } from '../models/hot-distributor-home-page-tiles-with-language';
import { HotLocalizedDashboardPageWidget } from '../models/hot-localized-dashboard-page-widget';
import { HotCmsImage } from '../models/hot-cms-image';
@Injectable({
  providedIn: 'root',
})
class HotApiCmsService extends __BaseService {
  static readonly getLoyaltyBannersPath = '/storefrontapi/hot/cms/loyalty-banners';
  static readonly getFooterItemsPath = '/storefrontapi/hot/cms/footer-items';
  static readonly getHomePageWidgetsPath = '/storefrontapi/hot/cms/home-page-widgets';
  static readonly getDistributorHomePageTilesPath = '/storefrontapi/hot/cms/distributor/home-page-tiles';
  static readonly getTotalItemsCountByTileTypesPath = '/storefrontapi/hot/cms/distributor/tiles-info';
  static readonly getDashboardPageWidgetsPath = '/storefrontapi/hot/cms/dashboard-page-widgets';
  static readonly getImagesByTypesPath = '/storefrontapi/hot/cms/images';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  getLoyaltyBannersResponse(): __Observable<__StrictHttpResponse<{[key: string]: HotLoyaltyBanner}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/cms/loyalty-banners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: HotLoyaltyBanner}>;
      })
    );
  }
  /**
   * @return Success
   */
  getLoyaltyBanners(): __Observable<{[key: string]: HotLoyaltyBanner}> {
    return this.getLoyaltyBannersResponse().pipe(
      __map(_r => _r.body as {[key: string]: HotLoyaltyBanner})
    );
  }

  /**
   * @return Success
   */
  getFooterItemsResponse(): __Observable<__StrictHttpResponse<Array<HotFooterItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/cms/footer-items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotFooterItem>>;
      })
    );
  }
  /**
   * @return Success
   */
  getFooterItems(): __Observable<Array<HotFooterItem>> {
    return this.getFooterItemsResponse().pipe(
      __map(_r => _r.body as Array<HotFooterItem>)
    );
  }

  /**
   * @return Success
   */
  getHomePageWidgetsResponse(): __Observable<__StrictHttpResponse<Array<HotHomePageWidgetsWithLanguage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/cms/home-page-widgets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotHomePageWidgetsWithLanguage>>;
      })
    );
  }
  /**
   * @return Success
   */
  getHomePageWidgets(): __Observable<Array<HotHomePageWidgetsWithLanguage>> {
    return this.getHomePageWidgetsResponse().pipe(
      __map(_r => _r.body as Array<HotHomePageWidgetsWithLanguage>)
    );
  }

  /**
   * @return Success
   */
  getDistributorHomePageTilesResponse(): __Observable<__StrictHttpResponse<Array<HotDistributorHomePageTilesWithLanguage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/cms/distributor/home-page-tiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotDistributorHomePageTilesWithLanguage>>;
      })
    );
  }
  /**
   * @return Success
   */
  getDistributorHomePageTiles(): __Observable<Array<HotDistributorHomePageTilesWithLanguage>> {
    return this.getDistributorHomePageTilesResponse().pipe(
      __map(_r => _r.body as Array<HotDistributorHomePageTilesWithLanguage>)
    );
  }

  /**
   * @return Success
   */
  getTotalItemsCountByTileTypesResponse(): __Observable<__StrictHttpResponse<{[key: string]: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/cms/distributor/tiles-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: number}>;
      })
    );
  }
  /**
   * @return Success
   */
  getTotalItemsCountByTileTypes(): __Observable<{[key: string]: number}> {
    return this.getTotalItemsCountByTileTypesResponse().pipe(
      __map(_r => _r.body as {[key: string]: number})
    );
  }

  /**
   * @return Success
   */
  getDashboardPageWidgetsResponse(): __Observable<__StrictHttpResponse<Array<HotLocalizedDashboardPageWidget>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/cms/dashboard-page-widgets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotLocalizedDashboardPageWidget>>;
      })
    );
  }
  /**
   * @return Success
   */
  getDashboardPageWidgets(): __Observable<Array<HotLocalizedDashboardPageWidget>> {
    return this.getDashboardPageWidgetsResponse().pipe(
      __map(_r => _r.body as Array<HotLocalizedDashboardPageWidget>)
    );
  }

  /**
   * @param types undefined
   * @return Success
   */
  getImagesByTypesResponse(types?: Array<string>): __Observable<__StrictHttpResponse<Array<HotCmsImage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (types || []).forEach(val => {if (val != null) __params = __params.append('types', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/cms/images`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotCmsImage>>;
      })
    );
  }
  /**
   * @param types undefined
   * @return Success
   */
  getImagesByTypes(types?: Array<string>): __Observable<Array<HotCmsImage>> {
    return this.getImagesByTypesResponse(types).pipe(
      __map(_r => _r.body as Array<HotCmsImage>)
    );
  }
}

module HotApiCmsService {
}

export { HotApiCmsService }
