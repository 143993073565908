import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FallbackSrcDirective } from './directives/fallback-src.directive';
import { PathSvgPipe } from './pipes/path-svg.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { CapitalizeFirstLetter } from './pipes/capitalizeFirstLetter.pipe';
import { AbsoluteValue } from './pipes/absoluteValue.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        PathSvgPipe,
        FallbackSrcDirective,
        SearchPipe,
        CapitalizeFirstLetter,
        AbsoluteValue,
    ],
    exports: [
        PathSvgPipe,
        FallbackSrcDirective,
        SearchPipe,
        CapitalizeFirstLetter,
        AbsoluteValue,
    ],
})
export class UiModule {}