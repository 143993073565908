/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotOrderSearchResult } from '../models/hot-order-search-result';
import { HotVsmOrderSearchCriteria } from '../models/hot-vsm-order-search-criteria';
import { HotDeliveredOrdersStatistics } from '../models/hot-delivered-orders-statistics';
import { HotOrder } from '../models/hot-order';
import { HotUpdateOrderRequest } from '../models/hot-update-order-request';
@Injectable({
  providedIn: 'root',
})
class HotApiVsmService extends __BaseService {
  static readonly searchOrdersPath = '/storefrontapi/hot/vsm/orders/search';
  static readonly getDeliveredOrdersStatisticsPath = '/storefrontapi/hot/vsm/orders/statistics/delivered';
  static readonly confirmOrderPath = '/storefrontapi/hot/vsm/orders/{orderId}/confirm';
  static readonly updateOrderPath = '/storefrontapi/hot/vsm/orders/{orderId}';
  static readonly recalculateOrderPath = '/storefrontapi/hot/vsm/orders/{orderId}/recalculate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchOrdersResponse(body?: HotVsmOrderSearchCriteria): __Observable<__StrictHttpResponse<HotOrderSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/vsm/orders/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchOrders(body?: HotVsmOrderSearchCriteria): __Observable<HotOrderSearchResult> {
    return this.searchOrdersResponse(body).pipe(
      __map(_r => _r.body as HotOrderSearchResult)
    );
  }

  /**
   * @param params The `HotApiVsmService.GetDeliveredOrdersStatisticsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `outletId`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getDeliveredOrdersStatisticsResponse(params: HotApiVsmService.GetDeliveredOrdersStatisticsParams): __Observable<__StrictHttpResponse<HotDeliveredOrdersStatistics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.outletId != null) __params = __params.set('outletId', params.outletId.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/vsm/orders/statistics/delivered`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotDeliveredOrdersStatistics>;
      })
    );
  }
  /**
   * @param params The `HotApiVsmService.GetDeliveredOrdersStatisticsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `outletId`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getDeliveredOrdersStatistics(params: HotApiVsmService.GetDeliveredOrdersStatisticsParams): __Observable<HotDeliveredOrdersStatistics> {
    return this.getDeliveredOrdersStatisticsResponse(params).pipe(
      __map(_r => _r.body as HotDeliveredOrdersStatistics)
    );
  }

  /**
   * @param orderId undefined
   * @return Success
   */
  confirmOrderResponse(orderId: string): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/vsm/orders/${encodeURIComponent(String(orderId))}/confirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param orderId undefined
   * @return Success
   */
  confirmOrder(orderId: string): __Observable<HotOrder> {
    return this.confirmOrderResponse(orderId).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param params The `HotApiVsmService.UpdateOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateOrderResponse(params: HotApiVsmService.UpdateOrderParams): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/vsm/orders/${encodeURIComponent(String(params.orderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param params The `HotApiVsmService.UpdateOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateOrder(params: HotApiVsmService.UpdateOrderParams): __Observable<HotOrder> {
    return this.updateOrderResponse(params).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param params The `HotApiVsmService.RecalculateOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  recalculateOrderResponse(params: HotApiVsmService.RecalculateOrderParams): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/vsm/orders/${encodeURIComponent(String(params.orderId))}/recalculate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param params The `HotApiVsmService.RecalculateOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  recalculateOrder(params: HotApiVsmService.RecalculateOrderParams): __Observable<HotOrder> {
    return this.recalculateOrderResponse(params).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }
}

module HotApiVsmService {

  /**
   * Parameters for GetDeliveredOrdersStatistics
   */
  export interface GetDeliveredOrdersStatisticsParams {
    startDate?: string;
    outletId?: string;
    endDate?: string;
  }

  /**
   * Parameters for UpdateOrder
   */
  export interface UpdateOrderParams {
    orderId: string;
    body?: HotUpdateOrderRequest;
  }

  /**
   * Parameters for RecalculateOrder
   */
  export interface RecalculateOrderParams {
    orderId: string;
    body?: HotUpdateOrderRequest;
  }
}

export { HotApiVsmService }
