import { Component, OnInit, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import map1 from 'lodash/map';
import { HotVolumetricPrice, HotVolumetricProduct, HotVolumetricPricelist, CurrencyFormat } from '@hot-theme-nx/generated-api';
import { ProductService } from 'apps/hot-b2b/src/app/shared/services';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsCurrencyFormat } from '@hot-b2b/store/settings/selector';

@Component({
    selector: 'hot-volume-pricing',
    templateUrl: './volume-pricing.component.html',
})
export class VolumePricingComponent implements OnInit {
    @Input() public product;

    public volumePricing$: Observable<HotVolumetricPrice>;
    public products: HotVolumetricProduct[];
    public priceLists: HotVolumetricPricelist[];
    public currencyFormat$: Observable<CurrencyFormat>;

    public baseUnit: string;
    public maxUnit: string;

    public isExpanded: boolean = true;

    constructor(
        private readonly store: Store<AppState>,
        private readonly productService: ProductService
    ) {
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
    }

    public ngOnInit(): void {
        this.getVolumePricing();
    }

    public getVolumePricing() : void {
        let ids: string[] = [this.product.id];

        if (this.product.unitsOfMeasure) {
            ids = map1(
                this.product.unitsOfMeasure,
                (unitsOfMeasure: any) => unitsOfMeasure.id
            );
        }

        this.volumePricing$ = this.productService.getProductVolumetricPrice(ids).pipe(
            map((volumetricPrice: HotVolumetricPrice) => {
                this.products = volumetricPrice.products;
                this.priceLists = volumetricPrice.priceLists;

                if (this.products) {
                    this.baseUnit = this.products[0].packageType;
                    this.maxUnit = this.products[this.products.length - 1].packageType;
                }

                return volumetricPrice;
            })
        );
    }

    public toggleSectionExpand(): void {
        this.isExpanded = !this.isExpanded
    }
}
