/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotDistributorPriceExportImportResult } from '../models/hot-distributor-price-export-import-result';
import { HotDistributorProductPrice } from '../models/hot-distributor-product-price';
@Injectable({
  providedIn: 'root',
})
class HotApiPriceManagementService extends __BaseService {
  static readonly exportPricesTemplateFilePath = '/storefrontapi/hot/distributor/prices/export/template';
  static readonly exportLatestPricesPath = '/storefrontapi/hot/distributor/prices/export/latest';
  static readonly importPricesPath = '/storefrontapi/hot/distributor/prices/import';
  static readonly getExportImportStatusPath = '/storefrontapi/hot/distributor/prices/jobs/{jobId}/status';
  static readonly getExportResultFilePath = '/storefrontapi/hot/distributor/prices/jobs/{jobId}/get-file';
  static readonly updateProductPricesPath = '/storefrontapi/hot/distributor/prices';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  exportPricesTemplateFileResponse(): __Observable<__StrictHttpResponse<HotDistributorPriceExportImportResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/prices/export/template`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotDistributorPriceExportImportResult>;
      })
    );
  }
  /**
   * @return Success
   */
  exportPricesTemplateFile(): __Observable<HotDistributorPriceExportImportResult> {
    return this.exportPricesTemplateFileResponse().pipe(
      __map(_r => _r.body as HotDistributorPriceExportImportResult)
    );
  }

  /**
   * @return Success
   */
  exportLatestPricesResponse(): __Observable<__StrictHttpResponse<HotDistributorPriceExportImportResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/prices/export/latest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotDistributorPriceExportImportResult>;
      })
    );
  }
  /**
   * @return Success
   */
  exportLatestPrices(): __Observable<HotDistributorPriceExportImportResult> {
    return this.exportLatestPricesResponse().pipe(
      __map(_r => _r.body as HotDistributorPriceExportImportResult)
    );
  }

  /**
   * @return Success
   */
  importPricesResponse(): __Observable<__StrictHttpResponse<HotDistributorPriceExportImportResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/prices/import`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotDistributorPriceExportImportResult>;
      })
    );
  }
  /**
   * @return Success
   */
  importPrices(): __Observable<HotDistributorPriceExportImportResult> {
    return this.importPricesResponse().pipe(
      __map(_r => _r.body as HotDistributorPriceExportImportResult)
    );
  }

  /**
   * @param jobId undefined
   * @return Success
   */
  getExportImportStatusResponse(jobId: string): __Observable<__StrictHttpResponse<HotDistributorPriceExportImportResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/distributor/prices/jobs/${encodeURIComponent(String(jobId))}/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotDistributorPriceExportImportResult>;
      })
    );
  }
  /**
   * @param jobId undefined
   * @return Success
   */
  getExportImportStatus(jobId: string): __Observable<HotDistributorPriceExportImportResult> {
    return this.getExportImportStatusResponse(jobId).pipe(
      __map(_r => _r.body as HotDistributorPriceExportImportResult)
    );
  }

  /**
   * @param jobId undefined
   * @return OK
   */
  getExportResultFileResponse(jobId: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/prices/jobs/${encodeURIComponent(String(jobId))}/get-file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param jobId undefined
   * @return OK
   */
  getExportResultFile(jobId: string): __Observable<string> {
    return this.getExportResultFileResponse(jobId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   */
  updateProductPricesResponse(body?: Array<HotDistributorProductPrice>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/prices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  updateProductPrices(body?: Array<HotDistributorProductPrice>): __Observable<null> {
    return this.updateProductPricesResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module HotApiPriceManagementService {
}

export { HotApiPriceManagementService }
