import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import {
    CurrencyFormat,
    HotPromotion,
    HotPromotionProduct,
    HotOutlet,
    HotOperationResult,
} from '@hot-theme-nx/generated-api';
import { PermissionsService } from '../../services/permissions.service';
import { orderSimulationtaxDetails } from '@hot-b2b/store/orders/selector';
import { CartProductUpdate, CartAddItem } from '@hot-b2b/store/cart/actions';
import { CatalogItemChangeSubscription } from '@hot-b2b/store/catalog/actions';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import {
    settingsAppOnLine,
    settingsCurrencyFormat,
    settingsLoyaltyCurrencyFormat,
    settingsStore,
    settingsStoreShowProductExtendedPrice,
    settingsData,
} from '@hot-b2b/store/settings/selector';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import {
    AccountType,
    CustomerPermissions,
    ModalIds,
    PageName,
    ProductStockStatus,
    StorageKeys,
} from '@hot-libs/shared-types';
import { TranslateService } from '@ngx-translate/core';
import { HotProductExtended, HotUnitOfMeasureExtended, HotUnitCurrentHop } from 'apps/hot-b2b/src/app/catalog/models';
import { CurrencyFormatPipe } from 'apps/hot-b2b/src/app/shared/pipes';
import {
    FeaturesService,
    ModalService,
    ProductService,
    DateFormatParserService,
} from 'apps/hot-b2b/src/app/shared/services';
import { environment } from 'apps/hot-b2b/src/environments/environment';
import {
    OrderService,
    AnalyticLocationConstant,
    CartErrorHandlerService,
    CartErrorsCodeConstant,
} from '@hot-theme-nx/common-api';
import { visiblePromotions } from '@hot-b2b/store/promotions/selector';
import { authContactOutlet } from '@hot-b2b/store/auth/selector';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { cartData, cartItems } from '@hot-b2b/store/cart/selector';
import { HotCartExtended, HotCartLineItemExtended, ChangeProductQuantityModel } from '../../models';
import { catalogProducts } from '@hot-b2b/store/catalog/selector';
import { CatalogService } from 'apps/hot-b2b/src/app/catalog/services';
import { config } from 'apps/hot-b2b/src/environments/config';
import isArray from 'lodash/isArray';
import { MarketingService } from 'apps/hot-b2b/src/app/marketing/services';

const unitOfMeasureSortingConfig = config?.unitOfMeasureSorting || false;
const userTypesAbleToEditQuantity: string[] = [
    AccountType.Distributor,
    AccountType.OpcoDistributor,
    AccountType.SalesRepresentative,
    AccountType.VanSalesMan,
];
const mockReportReasons = config?.mockReportReasons || [];

@Component({
    selector: 'hot-product',
    templateUrl: './product.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComponent implements OnInit, OnChanges, OnDestroy {
    public showRemoveModal: boolean;
    public clearCartSingleModalState$: Observable<ModalsState>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public settingsAppOnLine$: Observable<boolean>;

    public productStockStatus = ProductStockStatus;

    public productListPrice: number;
    public productActualPrice: number;
    public swapPrices: boolean;
    public showSku: boolean;
    public invoicePrice;
    public readonly contactOutlet$: Observable<HotOutlet>;
    public isCartSideBar = false;
    public cartItems$: Observable<HotCartLineItemExtended[]>;
    public cartItems: HotCartLineItemExtended[];
    public catalogProducts$: Observable<HotProductExtended[]>;
    public isExpanded: boolean = false;

    public moqError = null;
    public readonly cartErrorsCodeConstant = CartErrorsCodeConstant;
    public orderSimulationTaxDetails$: Observable<HotOperationResult>;
    public actualPriceLA: any;

    public orderSimulationDetails$: Observable<HotOperationResult>;
    public ShowLineItemDiscount: boolean;
    public deliveryItem: number;
    @Input() public product: any;
    @Input() public modifier: string;
    @Input() public isDistributor: boolean;
    @Input() public isVanSalesMan: boolean;
    @Input() public showDeliveredColumn: boolean;
    @Input() public showConfirmedColumn: boolean;
    @Input() public isCartSidebarItem: boolean;
    @Input() public isBasketOverlay: boolean;
    @Input() public isOrderTemplate: boolean;
    @Input() public isRewardProduct = false;
    @Input() public isPointsCurrency: boolean;
    @Input() public pageName: string;
    @Input() public userType: string;
    @Input() public isReadOnly: boolean;
    @Input() public isGridViewActive: boolean;
    @Input() public orderHasInvoice: boolean;
    @Input() public isEmpties: boolean;
    @Input() public canDelete = true;
    @Input() public isOfflineOrder: boolean;
    @Input() public isReportIssue: boolean;
    @Input() public groupingBySupplier = false;
    @Input() public issueForm: UntypedFormGroup;
    @Input() public isUpdateQuantityBaseOnProduct = false;
    @Input() public orderDeliveries: any[] = []

    @Output() public openModal: EventEmitter<HotProductExtended> = new EventEmitter();
    @Output() public removeFromCartVsm: EventEmitter<HotProductExtended> = new EventEmitter();
    @Output() public selectCartItem: EventEmitter<{
        status: boolean;
        product: HotProductExtended;
    }> = new EventEmitter();

    public featureShowDistributorLineItemSku: boolean = this.featuresService.ShowDistributorLineItemSku;
    public featureBackInStockSubscriptionWithQuantity: boolean = this.featuresService
        .BackInStockSubscriptionWithQuantity;
    public featureShowDistributorLineItemExtendedPrice: boolean = this.featuresService
        .ShowDistributorLineItemExtendedPrice;
    public featureSelectUnitOfMeasure: boolean = this.featuresService.SelectUnitOfMeasure;
    public featureDisplaySingleUnitPriceBasedOnPackageSize: boolean = this.featuresService
        .DisplaySingleUnitPriceBasedOnPackageSize;
    public featureBackInStockSubscription: boolean = this.featuresService.BackInStockSubscription;
    public featureSummarizeInvoices: boolean = this.featuresService.SummarizeInvoices;
    public featureAllowDistributorsToControlStoreSettings = this.featuresService
        .AllowDistributorsToControlStoreSettings;
    public featureEnableDiscountCalculationWhenPriceIsHidden = this.featuresService
        .EnableDiscountCalculationWhenPriceIsHidden;
    public featureApplyTierPriceModel: boolean = this.featuresService.ApplyTierPriceModel;
    public featureShowStockLevelOnLineItems: boolean = this.featuresService.ShowStockLevelOnLineItems;
    public showOutOfStockInsteadOfBackOrders: boolean = false;
    public featureValidateMinimumBackOrderQuantity = this.featuresService.ValidateMinimumBackOrderQuantity;
    public featureValidateMinimumNonBackOrderQuantity = this.featuresService.ValidateMinimumNonBackOrderQuantity;
    private hideInactiveProductsInCart: boolean = false;
    public isInactiveProduct = false;
    public featureValidateProductLimitOrderQuantity: boolean = this.featuresService.ValidateProductLimitOrderQuantity;

    public featureManageFavoriteProducts = this.featuresService.ManageFavoriteProducts;
    public featureEnableBasketOverlay = this.featuresService.EnableBasketOverlay;
    public configCustomPromoCondition = config?.customPromoCondition;
    public configIsSelectableItemInCartDisabled = config?.isSelectableItemInCartDisabled;

    public haveReadPricingPermission$: Observable<boolean> = this.permissionsService.checkPermission(
        CustomerPermissions.PricingRead
    );

    public isEmailMeButtonVisible: boolean = this.isCustomerMode() && this.featureBackInStockSubscription;

    public localCurrencyFormat: CurrencyFormat;
    public currencyFormat$: Observable<CurrencyFormat>;
    public settingsLoyaltyCurrencyFormat$: Observable<CurrencyFormat>;
    public settingsStoreShowProductExtendedPrice$: Observable<boolean>;

    public showPrice = false;
    public freePriceString: string;
    public filteredUnitsOfMeasure: any[];
    public prices: { listPrice: number; actualPrice: number; baseUnitPrice?: number };
    public baseUnitOfMeasure: string;

    public deleteConfirmationText: string;

    public dimensions: string;
    public isClick = false;
    public isdropdown = true;

    private productPromotionDiscountThreshold: number;

    public isUpLg = () => this.screenDimensionService.upLg();

    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    public isGR = () => this.configurationService.getCurrentConfiguration() === 'GR';
    public isCZ = () => this.configurationService.getCurrentConfiguration() === 'CZ';
    public isHopUnitPallet: boolean = false;
    public unitCurrentHop: any = 'Unit';
    private readonly promotions$: Observable<HotPromotion[]>;
    public promotion: HotPromotion;
    public countdownSeconds: number = 1;
    public currentOutlet: HotOutlet;
    public isFavorite = false;
    public fulfillmentCenter = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter));

    public outOfStockProductsAreEnabledByDistributor = false;
    public outOfStockIndicatorIsEnabledByDistributor = false;
    public displayOutOfStockIndicator = true;
    public isNotifiedMe = false;
    public featureOrderSimulationLA: boolean = this.featuresService.OrderSimulationPricesAndTaxes;
    public isLAB2b = () => this.configurationService.getCurrentConfiguration() === 'RE';

    public get reportReasons() {
        return mockReportReasons.map((reason: any) =>
            this.translateService.instant('shared.modals.order.reason.' + reason)
        );
    }
    public get isPromo(): Observable<boolean> {
        return this.productService.showPromoLabel(this.product);
    }

    public get outletId(): string {
        return this.currentOutlet?.id;
    }

    public get storeId(): string {
        return this.fulfillmentCenter?.storeId;
    }

    public get productPrice(): any {
        if (this.isVanSalesMan) {
            return this.currencyFormatPipe.transform(this.product.invoiceExtendedPrice, this.localCurrencyFormat);
        }

        if (this.product.isGift) {
            return this.freePriceString;
        }

        if (!(this.product.quantity === 0 && this.product.hasInvoice)) {
            if (this.isRewardProduct) {
                return this.currencyFormatPipe.transform(
                    this.product.extendedPrice || this.product.salePrice,
                    this.localCurrencyFormat
                );
            }

            if (!this.showPrice) {
                return null;
            }

            let price;
            if (this.product.placedPrice) {
                price =
                    this.product.unitCurrent?.extendedPrice ||
                    this.product.unitCurrent?.listPrice ||
                    this.product.extendedPrice ||
                    this.product.placedPrice;
            } else {
                price =
                    this.product.unitCurrent?.extendedPrice ||
                    this.product.unitCurrent?.salePrice ||
                    this.product.extendedPrice ||
                    this.product.salePrice;
            }

            return this.currencyFormatPipe.transform(price, this.localCurrencyFormat);
        }

        return null;
    }

    public get productExtendedPrice(): any {
        if (this.product.isGift) {
            return this.freePriceString;
        }

        return this.currencyFormatPipe.transform(this.product.invoiceExtendedPrice, this.localCurrencyFormat);
    }

    public get getPriceError(): any {
        return this.product?.validationErrors.find((error: any) => error.errorCode === 'HotPriceError');
    }

    private readonly enabledPagesForShowPrice: string[] = [
        PageName.BRAND,
        PageName.PRODUCTDETAILMODAL,
        PageName.PROMOTION,
        PageName.INSIGHT,
    ];

    private readonly unsubscribe$ = new Subject();
    public cartData$: Observable<HotCartExtended>;
    private cartObjectData: HotCartExtended;

    public disableRemoveIcon = false;
    public currentOpco = null;
    public promostatementValue: boolean;
    public promoCatalogDescExists: boolean;
    public firstProductPackageName: string = '';
    public hotConfirmOrderModalProductQuantity: number;
    public dateFormat: string;
    public hotSettings$: Observable<HotSettingsExtended>;
    public isErrorAvailable:boolean;
    public isWarningAvailable: boolean;

    public get isShowItemSelection() {
        return (
            this.groupingBySupplier &&
            this.pageName === PageName.CARTSIDEBAR &&
            !this.configIsSelectableItemInCartDisabled
        );
    }

    constructor(
        private readonly store: Store<AppState>,
        private readonly productService: ProductService,
        private readonly featuresService: FeaturesService,
        private readonly permissionsService: PermissionsService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly translateService: TranslateService,
        private readonly currencyFormatPipe: CurrencyFormatPipe,
        private readonly modalService: ModalService,
        public router: Router,
        public readonly screenDimensionService: ScreenDimensionService,
        public readonly orderService: OrderService,
        private readonly catalogService: CatalogService,
        private readonly cartErrorService: CartErrorHandlerService,

        private readonly configurationService: ConfigurationService,
        private readonly marketingService: MarketingService,
        private readonly dateFormatParserService: DateFormatParserService
    ) {
        this.catalogProducts$ = this.store.pipe(select(catalogProducts));
        this.cartItems$ = this.store.pipe(select(cartItems));
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.clearCartSingleModalState$ = this.store.pipe(
            select(selectModalState(ModalIds.clearCartConfirmationSingle))
        );
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.settingsLoyaltyCurrencyFormat$ = this.store.pipe(select(settingsLoyaltyCurrencyFormat));
        this.settingsStoreShowProductExtendedPrice$ = this.store.pipe(select(settingsStoreShowProductExtendedPrice));

        this.productService.markForCheck.pipe(takeUntil(this.unsubscribe$)).subscribe((productId: string) => {
            if (productId.includes(this.product.productId) || productId.includes(this.product.id)) {
                this.changeDetectorRef.detectChanges();
            }
        });

        this.promotions$ = this.store.pipe(select(visiblePromotions));
        this.contactOutlet$ = this.store.pipe(select(authContactOutlet));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.cartData$ = this.store.pipe(select(cartData));
        this.hotSettings$ = this.store.pipe(select(settingsData));
        this.orderSimulationTaxDetails$ = this.store.pipe(select(orderSimulationtaxDetails));
        this.orderSimulationDetails$ = this.store.pipe(select(orderSimulationtaxDetails));
    }

    public ngOnInit(): void {
        if (this.product.promoStatements === undefined) {
            this.promostatementValue = false;
        } else if (this.product.promoStatements.length >= 0) {
            this.promostatementValue = true;
        } else {
            this.promostatementValue = false;
        }
        this.getSettings();
        this.settingsStoreShowProductExtendedPrice$.pipe(takeUntil(this.unsubscribe$)).subscribe((value: boolean) => {
            this.showPrice = this.enabledPagesForShowPrice.some((page) => page === this.pageName) ? value : true;
        });

        this.translateService
            .get('shared.free')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((message: string) => {
                this.freePriceString = message;
            });

        this.swapPrices = environment.swapProductPrices && this.product.hasInvoice;
        this.prices = this.productService.setProductPrice(this.product, this.pageName, this.isEmpties);
        this.baseUnitOfMeasure = this.productService.setProductBaseUnitOfMeasure(this.product);
        this.filteredUnitsOfMeasure = this.productService.filterUnitsOfMeasure(this.product);

        if (this.router.url.indexOf('/order-templates') >= 0) {
            this.deleteConfirmationText = 'shared.modals.order-template.delete-product-text';
        } else {
            this.deleteConfirmationText = 'shared.cart-sidebar.clear-cart-confirmation-modal-single.content';
        }

        this.productService.removedProduct.pipe(takeUntil(this.unsubscribe$)).subscribe((product) => {
            if (product && product.id === this.product.id) {
                this.product.quantity = 0;
            }
        });
        this.setAccountType();
        this.ngOnInitExtended();
        if(this.orderDeliveries.length > 0) {
            let orderDeliveries =  this.orderDeliveries
            this.deliveryItem = orderDeliveries.reduce((sum, item) => sum + item.itemsQuantity ,  0);
        }
    }

    public ngOnChanges(): void {
        if (this.product.hasOwnProperty('product')) {
            if (this.product.product.promoStatements === undefined) {
                this.promostatementValue = false;
            } else if (this.product.product.promoStatements.length >= 0) {
                this.promostatementValue = true;
                this.product.isPromo = true;
            } else {
                this.promostatementValue = false;
            }
        }
        this.addProductPrice();
        if(this.pageName === 'CartSidebar' || this.pageName ==='Summary' || this.pageName ==='BasketOverlay'){
            this.errorAvailability();
            this.warningAvailability();
        }  
    }

    public errorAvailability() {
        if(this.product.validationErrors.length > 0){
            this.isErrorAvailable = true;
        }else{
            this.isErrorAvailable = false;
        }
    }

    public warningAvailability() {
        if(this.product.validationWarnings.length > 0){
            this.isWarningAvailable = true;
        }else{
            this.isWarningAvailable = false;
        }
    }

    public ngOnInitExtended() {
        this.cartData$.pipe(takeUntil(this.unsubscribe$)).subscribe((data: HotCartExtended) => {
            this.orderDataType(data);
        });
        this.orderSimulationTaxDetails$.subscribe((response) => {
            if (response.succeeded && this.isLAB2b() && this.featureOrderSimulationLA) {
                this.setNewPriceLA(response);
            }
        });
        this.contactOutlet$.subscribe((contactOutlet) => {
            this.currentOutlet = contactOutlet;
        });
        this.isFavorite = this.product?.isFavorite;
        this.currentOpco = this.configurationService.getCurrentConfiguration();
        this.setAccountPromo();
        this.hotConfirmOrderModalProductQuantity =
            (this.product.hasInvoice ? this.product.deliveredQuantity : this.product.quantity) || 0;
    }
    public setAccountPromo() {
        if (this.product.promoCatalogDescriptions == undefined || this.product.promoCatalogDescriptions.length == 0) {
            this.promoCatalogDescExists = false;
        } else if (this.product.promoCatalogDescriptions.length > 0) {
            this.promoCatalogDescExists = true;
        } else {
            this.promoCatalogDescExists = false;
        }
    }
    public setAccountType() {
        this.showSku =
            (this.userType === AccountType.Distributor || this.userType === AccountType.SubDistributor) &&
            this.featureShowDistributorLineItemSku;

        this.dimensions = this.productService.getProductMeasures(this.product);

        this.productService.inStockSubscription$.subscribe((subscriptionStatus: any) => {
            if (subscriptionStatus && this.product.sku === subscriptionStatus.sku) {
                this.product.isSubscribed = subscriptionStatus.isSubscribed;
            }
        });

        if (this.pageName !== PageName.CARTSIDEBAR && this.pageName !== PageName.ORDERMODAL) {
            this.getProductPromotion();
        }

        if (this.product.hotIsBackOrder && this.product.hotDeliveryComment) {
            this.product.hotDeliveryComment = this.product.hotDeliveryComment.replace(/(?:\r\n|\r|\n)/g, '<br />');
        }

        this.invoicePrice = this.orderHasInvoice
            ? this.product.invoiceExtendedPrice - (this.product.invoiceDiscountTotal || 0)
            : this.prices.actualPrice;

        if (this.product.packageName) {
            this.firstProductPackageName = this.product.packageName.replace(/\s.*/, '');
        }
    }

    public orderDataType(data) {
        if (data?.['orderType'] && data['orderType'].toLowerCase() === 'bcs') {
            this.disableRemoveIcon = true;
        } else {
            this.disableRemoveIcon = false;
        }

        this.cartObjectData = data;
        if (
            this.featureShowStockLevelOnLineItems &&
            this.product &&
            (this.featureValidateMinimumBackOrderQuantity || this.featureValidateMinimumNonBackOrderQuantity)
        ) {
            this.validateMinimumOrderQuantity();
        }
        if (this.hideInactiveProductsInCart) {
            this.isInactiveProduct = this.product.validationErrors?.some(
                (error) => error.errorCode === 'UnavailableError'
            );
        } else {
            this.isInactiveProduct = false;
        }
    }

    private getProductPromotion(): void {
        this.promotions$.subscribe((promotions: HotPromotion[]) => {
            this.promotion = promotions.find((promo: HotPromotion) =>
                promo.products?.find((product: HotPromotionProduct) => product.sku === this.product.sku)
            );
            let returnProduct;
            if (this.promotion && !this.promotion.isSet) {
                returnProduct = {
                    ...this.promotion.products.find((product: HotPromotionProduct) => product.sku === this.product.sku)
                        .conditionProduct,
                    ...this.product,
                };
            }

            this.prices = this.productService.setProductPrice(returnProduct, this.pageName, this.isEmpties);
            this.changeDetectorRef.markForCheck();
        });
    }

    public handleCountdown(seconds: number): void {
        this.countdownSeconds = seconds;
        this.changeDetectorRef.markForCheck();
    }

    public addProductPrice() {
        if (this.isHopB2b()) {
            this.isHopUnitPallet = true;
            this.setUnitCurrentHop();
            this.prices = this.productService.setProductPrice(this.product, this.pageName, this.isEmpties);
        } else if (!this.isHopB2b()) {
            if (!this.product?.unitCurrent) {
                this.setUnitCurrent();
            }
            if (this.product.unitCurrent) {
                if (this.product?.unitsOfMeasure) {
                    this.product.unitsOfMeasure.forEach((element) => {
                        if (element.id === this.product.id) {
                            this.product.unitCurrent.quantity = element.quantity;
                        }
                    });
                    this.prices = this.productService.setProductPrice(this.product, this.pageName, this.isEmpties);
                }
            } else {
                this.prices = this.productService.setProductPrice(this.product, this.pageName, this.isEmpties);
            }

            this.updatePackageTypeLocale();
        }
    }

    public getSettings(): void {
        this.currencyFormat$
            .pipe(withLatestFrom(this.settingsLoyaltyCurrencyFormat$), take(1))
            .subscribe(([format, loyalty]: [CurrencyFormat, CurrencyFormat]) => {
                this.localCurrencyFormat = this.isRewardProduct || this.isPointsCurrency ? loyalty : format;
            });
        this.settingsStore$.pipe(take(1)).subscribe((settings: SettingsStoreModel) => {
            this.ShowLineItemDiscount = settings.showLineItemDiscount;
            this.productPromotionDiscountThreshold = settings.productPromotionDiscountThreshold;
            this.hideInactiveProductsInCart = settings.hideInactiveProductsInCart;
            this.outOfStockProductsAreEnabledByDistributor =
                this.featureAllowDistributorsToControlStoreSettings &&
                settings.displayOutOfStockProductsInCustomersCatalog;
            this.outOfStockIndicatorIsEnabledByDistributor =
                this.featureAllowDistributorsToControlStoreSettings &&
                settings.displayIndicatorForOutOfStockProductsInCustomersCatalog;

            this.displayOutOfStockIndicator =
                !this.featureAllowDistributorsToControlStoreSettings ||
                (settings.displayOutOfStockProductsInCustomersCatalog &&
                    settings.displayIndicatorForOutOfStockProductsInCustomersCatalog);
            this.showOutOfStockInsteadOfBackOrders = settings.showOutOfStockInsteadOfBackOrders;
        });
        this.hotSettings$.pipe(takeUntil(this.unsubscribe$)).subscribe((settings: HotSettingsExtended) => {
            this.dateFormat = this.dateFormatParserService.parseDateFormatForDatePipe(settings.dateFormat);
        });
    }

    public openModalHandler(product: HotProductExtended): void {
        if (this.openModal) {
            this.openModal.emit(product);
        }
    }

    public emailMe(event: Event, productId: string, unitCurrentId: string, mainProductId: string): void {
        event.stopPropagation();
        this.isNotifiedMe = true;
        const id = productId || unitCurrentId || mainProductId;
        if (!this.featureBackInStockSubscriptionWithQuantity) {
            this.productService.putProductSubscription(id).subscribe(() => {
                this.product.isSubscribed = true;
                this.changeDetectorRef.markForCheck();
                this.store.dispatch(new CartProductUpdate(this.product));
                this.store.dispatch(new CatalogItemChangeSubscription(productId));
                this.modalService.toggleModal(ModalIds.notifyMe, true, {
                    isNotification: true,
                });
                this.productService.inStockSubscription$.next({ sku: this.product.sku, isSubscribed: true });
                this.productService.markForCheck.next(id);
                this.marketingService.deleteInsightCache();
            });
        } else {
            this.changeDetectorRef.markForCheck();
            this.modalService.toggleModal(ModalIds.notifyMe, true, this.product);
            this.productService.markForCheck.next(id);
            this.marketingService.deleteInsightCache();
        }
    }

    public changePackageType(event: HotUnitOfMeasureExtended): void {
        this.product.unitCurrent = event;
        this.prices = this.productService.setProductPrice(this.product, this.pageName, this.isEmpties);
        this.filteredUnitsOfMeasure = this.productService.filterUnitsOfMeasure(this.product);

        if (this.product.unitCurrent) {
            this.product.sku = this.product.unitCurrent.sku;
            this.product.name = this.product.unitCurrent.catalogName;
            if (
                (this.featureShowStockLevelOnLineItems && this.product.unitCurrent.stockStatus) ||
                !this.featureShowStockLevelOnLineItems
            ) {
                this.product.stockStatus = this.product.unitCurrent.stockStatus;
            }
            this.product.packageName = this.product.unitCurrent.packageName;
            this.product.images = this.product.unitCurrent.images;
            this.product.unitName = this.product.unitCurrent.unitName;
            this.product.unitType = this.product.unitCurrent.unitType;
            this.product.alcoholContent = this.product.unitCurrent.alcoholContent;
            this.product.emptiesDeposit = this.product.unitCurrent.emptiesDeposit;
            this.product.packagesPerPallet = this.product.unitCurrent.packagesPerPallet;
            this.product.excise = this.product.unitCurrent.excise;
            this.dimensions = this.productService.getProductMeasures(this.product);
        }
    }

    public click() {
        this.isClick = true;
        this.isdropdown = false;
    }

    public upClick() {
        this.isdropdown = true;
        this.isClick = false;
    }

    public changePackageTypeHop(event: HotUnitCurrentHop): void {
        let packagesPerPallet = this.product.packagesPerPallet
            ? this.product.packagesPerPallet
            : this.product['product']?.packagesPerPallet;
        let packagesPerFCL = this.product.packagesPerFCL
            ? this.product.packagesPerFCL
            : this.product['product']?.packagesPerFCL;
        let packagesPerFTL = this.product.packagesPerFTL
            ? this.product.packagesPerFTL
            : this.product['product']?.packagesPerFTL;
        this.unitCurrentHop = event;
        this.product.unitCurrent = event;
        this.prices = this.productService.setProductPrice(this.product, this.pageName, this.isEmpties);
        this.filteredUnitsOfMeasure = this.productService.filterUnitsOfMeasure(this.product);
        let previousPackageTypeHop = this.product.packageTypeHop;
        if (this.product.packageTypeHop === undefined) {
            previousPackageTypeHop = 'Unit';
        }
        this.product.packageTypeHop = event.packageType;
        if (previousPackageTypeHop !== this.product.packageTypeHop && previousPackageTypeHop !== undefined) {
            if (this.product.packageTypeHop == 'Pallet') {
                this.calculateQuantity(
                    previousPackageTypeHop,
                    'FCL',
                    'FTL',
                    packagesPerFCL,
                    packagesPerFTL,
                    event.quantity
                );
            } else if (this.product.packageTypeHop == 'FCL') {
                this.calculateQuantity(
                    previousPackageTypeHop,
                    'Pallet',
                    'FTL',
                    packagesPerPallet,
                    packagesPerFTL,
                    event.quantity
                );
            } else if (this.product.packageTypeHop == 'FTL') {
                this.calculateQuantity(
                    previousPackageTypeHop,
                    'Pallet',
                    'FCL',
                    packagesPerPallet,
                    packagesPerFCL,
                    event.quantity
                );
            } else {
                this.calculateUnitQuantity(
                    previousPackageTypeHop,
                    packagesPerPallet,
                    packagesPerFCL,
                    packagesPerFTL,
                    event.quantity
                );
            }
        }
    }

    public calculateQuantity(
        previousPackageTypeHop: string,
        packageTypeHop1: string,
        packageTypeHop2: string,
        package1: any,
        package2: any,
        quantity: number
    ): void {
        if (package1 > 0 && previousPackageTypeHop == packageTypeHop1) {
            let toUnitQuantity = Math.round(quantity / package1);
            this.productService.sendProductHandler(this.product, toUnitQuantity);
        } else if (package2 > 0 && previousPackageTypeHop == packageTypeHop2) {
            let toUnitQuantity = Math.round(quantity / package2);
            this.productService.sendProductHandler(this.product, toUnitQuantity);
        } else {
            this.productService.sendProductHandler(this.product, quantity);
        }
    }

    public calculateUnitQuantity(
        previousPackageTypeHop: string,
        packagesPerPallet: any,
        packagesPerFCL: any,
        packagesPerFTL: any,
        quantity: number
    ): void {
        if (packagesPerPallet > 0 && previousPackageTypeHop == 'Pallet') {
            let toUnitQuantity = Math.round(quantity / packagesPerPallet);
            this.productService.sendProductHandler(this.product, toUnitQuantity);
        } else if (packagesPerFCL > 0 && previousPackageTypeHop == 'FCL') {
            let toUnitQuantity = Math.round(quantity / packagesPerFCL);
            this.productService.sendProductHandler(this.product, toUnitQuantity);
        } else if (packagesPerFTL > 0 && previousPackageTypeHop == 'FTL') {
            let toUnitQuantity = Math.round(quantity / packagesPerFTL);
            this.productService.sendProductHandler(this.product, toUnitQuantity);
        } else {
            this.productService.sendProductHandler(this.product, quantity);
        }
    }

    public get isDistributorMode(): boolean {
        const userType = localStorage.getItem(StorageKeys.userType);
        const subDistrMode = localStorage.getItem(StorageKeys.subDistributorMode);

        return (
            userTypesAbleToEditQuantity.includes(userType) ||
            (userType === AccountType.SubDistributor && subDistrMode === AccountType.Distributor)
        );
    }

    private isCustomerMode(): boolean {
        const userType = localStorage.getItem(StorageKeys.userType);
        const subDistrMode = localStorage.getItem(StorageKeys.subDistributorMode);

        return (
            userType === AccountType.Customer ||
            (userType === AccountType.SubDistributor && subDistrMode === AccountType.Customer)
        );
    }

    public isCustomer(): boolean {
        const userType = localStorage.getItem(StorageKeys.userType);
        return userType === AccountType.Customer;
    }
    public displayProductAsInStock(): boolean {
        return (
            (this.product.isInStock || this.outOfStockProductsAreEnabledByDistributor) &&
            !this.product.isUnavailableForCustomers &&
            !(this.product.stockStatus === ProductStockStatus.NoStock && this.showOutOfStockInsteadOfBackOrders)
        );
    }

    public displayProductAsInStockIncludeBackOrder(): boolean {
        return (
            (this.product.isInStock || this.outOfStockProductsAreEnabledByDistributor) &&
            !this.product.isUnavailableForCustomers
        );
    }

    public displayStockStatusIndicator(): boolean {
        return !this.featureAllowDistributorsToControlStoreSettings
            ? !!this.product.stockStatus
            : this.outOfStockIndicatorIsEnabledByDistributor && this.product.stockStatus === ProductStockStatus.NoStock;
    }

    private setUnitCurrent(): void {
        if (this.product) {
            if (this.product.unitsOfMeasure) {
                if (!this.product.unitCurrent) {
                    this.product.unitCurrent = this.product.unitsOfMeasure.find(
                        (unit: HotUnitOfMeasureExtended) => unit.packageType === this.product.packageType
                    );
                }

                this.productPackageType();
            }

            this.prices = this.productService.setProductPrice(this.product, this.pageName, this.isEmpties);
        }
    }

    public setUnitCurrentHop(): void {
        if (this.product) {
            if (!this.product.unitsOfMeasure) {
                this.productMeasureUnit();
            }
            if (this.product?.packageTypeHop) {
                this.product.unitsOfMeasure.forEach((unit) => {
                    if (unit.packageTypeLocaled === this.product.packageTypeHop) {
                        this.product.unitCurrent = unit;
                    }
                });
            } else {
                this.product.unitCurrent = this.product.unitsOfMeasure[0];
            }
            this.prices = this.productService.setProductPrice(this.product, this.pageName, this.isEmpties);
        }
    }
    public productMeasureUnit() {
        this.product.unitsOfMeasure = [
            {
                packageType: 'Unit',
                quantity: this.product.quantity,
                sku: this.product.sku,
                listPrice: this.product.listPrice,
                id: this.product.id,
                salePrice: this.product.salePrice,
                packageTypeLocaled: 'Unit',
            },
        ];
        if (this.router.url === '/cart' || this.pageName == 'CartSidebar' || this.pageName === 'Summary') {
            this.productUnitsCheck(this.product['product']);
        } else {
            this.productUnitsCheck(this.product);
        }
    }

    public productUnitsCheck(product: any): void {
        if (product.packagesPerPallet != null && product.packagesPerPallet > 0) {
            this.productUnitsAdd('Pallet');
        }
        if (product.packagesPerFCL != null && product.packagesPerFCL > 0) {
            this.productUnitsAdd('FCL');
        }
        if (product.packagesPerFTL != null && product.packagesPerFTL > 0) {
            this.productUnitsAdd('FTL');
        }
    }

    public productUnitsAdd(unit: string): void {
        this.product.unitsOfMeasure.push({
            packageType: unit,
            quantity: this.product.quantity,
            sku: this.product.sku,
            listPrice: this.product.listPrice,
            id: this.product.id,
            salePrice: this.product.salePrice,
            packageTypeLocaled: unit,
        });
    }

    public updatePackageTypeLocale(): void {
        if (this.product.unitCurrent) {
            this.product.unitCurrent['packageTypeLocaled'] = this.translateService.instant(
                `shared.product.package-types.${this.product.unitCurrent.packageType?.toLowerCase()}`
            );
        }

        if (isArray(this.product?.unitsOfMeasure) && unitOfMeasureSortingConfig) {
            this.productPackageType();
        }
    }

    private productPackageType() {
        this.product.unitsOfMeasure.forEach((el) => {
            el['packageTypeLocaled'] = this.translateService.instant(
                `shared.product.package-types.${el.packageType?.toLowerCase()}`
            );
        });
    }

    public getStockStatusKey(stockStatus: ProductStockStatus): string {
        switch (stockStatus) {
            case ProductStockStatus.NoStock:
                if (this.featureShowStockLevelOnLineItems && !this.showOutOfStockInsteadOfBackOrders) {
                    return 'shared.product.stock-status.back-order';
                } else {
                    return 'shared.product.stock-status.out-of-stock';
                }

            case ProductStockStatus.LowStock:
                return 'shared.product.stock-status.low-stock';

            case ProductStockStatus.MediumStock:
                return 'shared.product.stock-status.medium-stock';

            case ProductStockStatus.HighStock:
                return 'shared.product.stock-status.high-stock';

            case ProductStockStatus.InStock:
                return 'shared.product.stock-status.in-stock';

            default:
                return '';
        }
    }

    public toggleProductRemoveModal(state: boolean): void {
        if (this.isVanSalesMan) {
            this.removeFromCartVsm.emit();
            return;
        }
        if (this.isDistributor) {
            this.productService.removedProduct.next(this.product);
            return;
        }
        this.modalService.toggleModal(ModalIds.clearCartConfirmationSingle, state, {
            innerHtmlTitle: 'shared.cart-sidebar.clear-cart-confirmation-modal-single.title',
            innerHtmlContent: this.deleteConfirmationText,
            product: this.product,
            pageName: this.router.url === '/cart' ? AnalyticLocationConstant.CART : this.pageName,
            isOrderTemplate: this.isOrderTemplate,
        });
    }
    public ProductRemove(): void {
        if (!this.isOrderTemplate) {
            this.productService.removeFromCartHandler(
                this.product,
                this.router.url === '/cart' ? AnalyticLocationConstant.CART : this.pageName
            );
            if (this.product?.isReturnableEmpty) {
                this.productService.addProductToAutoAddEmptiesRemovedFromCartTracker(this.product?.sku);
            }
        } else this.productService.removedProduct.next(this.product);
    }
    public toggleSectionExpand(): void {
        this.isExpanded = !this.isExpanded;
    }

    public isNotCartPage() {
        if (this.isCartSidebarItem && this.router.url !== '/cart') {
            this.isCartSideBar = true;
        }
        return this.router.url !== '/cart';
    }

    public getTrackData() {
        return `{"data_interaction":"icon_click","button_text":"Delete","name":"${this.pageName}"}`;
    }

    public getDetailsTrackData() {
        return `{"data_interaction":"link_click","button_text":"See details","name":"${this.pageName}"}`;
    }

    public getPackageTypeTrackData() {
        return `{ "data_interaction": "Dropdown_click", "button_text": "${this.product.unitCurrent.packageType}", "name":"${this.pageName}" }`;
    }

    public getProductTitleTrackData(name) {
        return `{"data_interaction":"Product_Title_click","button_text":"${name}","name":"${this.pageName}"}`;
    }

    public toggleFavorite() {
        this.isFavorite = !this.isFavorite;

        this.catalogService
            .addOrDeleteFavoriteProduct({
                StoreId: this.storeId,
                ProductSku: this.product.sku,
                ProductId: this.product.id,
                OutletId: this.outletId,
                IsFavorite: this.isFavorite,
            })
            .subscribe((result) => {
                if (!result.succeeded) {
                    this.isFavorite = !this.isFavorite;
                }
            });
    }

    public onSelectCartItem(status: boolean, product: HotProductExtended) {
        this.selectCartItem.emit({ status, product });
    }

    public changeEventHandler(event) {
        const changeModel: ChangeProductQuantityModel = {
            productId: this.product.productId ?? this.product.id,
            isGift: this.product.isGift,
            quantity: event.quantity,
        };

        this.hotConfirmOrderModalProductQuantity = event.quantity;
        this.productService.changeProductQuantityEvent.next(changeModel);
    }
    public validateMinimumOrderQuantity(): void {
        this.moqError = null;

        const cartDataItems = this.cartObjectData?.items;
        const cartValidationErrors = this.cartObjectData?.validationErrors;
        if (cartDataItems.length && cartValidationErrors.length) {
            for (let error of cartValidationErrors) {
                if (['HotMinimumOrderQuantityProductError'].includes(error.errorCode)) {
                    if (this.product.originalSku === error.parameters?.originalSku && this.isInCart(cartDataItems)) {
                        error.parameters = this.validateParameter(error);
                        const transformedError = this.cartErrorService.transformError(error);
                        this.moqError = this.cartErrorService.prepareToolbarData(transformedError);
                    }
                }
            }
        }
    }
    public validateParameter(error) {
        if (error.parameters.subCode === 'IndividualSku') {
            const packageType = error.parameters.unitOfMeasure;
            error.parameters.unitOfMeasure = this.translateService.instant(
                `shared.product.package-types.${packageType.toLowerCase()}`
            );
        }
        return error.parameters;
    }

    public isInCart(cartLineItems: HotCartLineItemExtended[]): boolean {
        return cartLineItems.some((item) => item.originalSku === this.product.originalSku);
    }

    public amendToMoq(): void {
        const cartAmendItems = this.cartObjectData?.items;
        const getItemByBaseId = cartAmendItems.find((item) => item.product.id === this.moqError['baseProductId']);
        const quantityToAmend = getItemByBaseId
            ? getItemByBaseId.baseQuantity + Number(this.moqError['missingBaseMiniumOrderQuantity'])
            : Number(this.moqError['missingBaseMiniumOrderQuantity']);
        this.store.dispatch(
            new CartAddItem({
                productId: this.moqError['baseProductId'],
                quantity: quantityToAmend,
            })
        );
    }

    public setNewPriceLA(response: HotOperationResult) {
        this.actualPriceLA = response.items.map((values) => values.listPrice);
        let getProducts = response.items.find((value) => value.sku === this.product.sku);
        if (getProducts.discountAmount != 0 || getProducts.listPrice != this.product.listPrice) {
            this.prices = this.productService.newPriceLA(
                this.product,
                getProducts.listPrice,
                getProducts.discountAmount
            );
            this.changeDetectorRef.detectChanges();
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(true);
    }
}
