import { ProductService } from '@hot-theme-nx/common-api';

import {
    HotOrderExtended,
    HotOrderLineItemExtended,
    HotOrderSearchResultExtended,
    SettingsStoreModel,
} from '@hot-libs/shared-models';
import { config } from 'apps/hot-b2b/src/environments/config';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { OrderStatus } from '@hot-libs/shared-types';

const updatedOrders = (previousOrders: HotOrderExtended[], updatedData): HotOrderExtended[] => {
    const orders: HotOrderExtended[] = Array.isArray(previousOrders) ? [...previousOrders] : [...[], previousOrders];
    const changedOrderIndex: number = orders.findIndex((order: HotOrderExtended) => order.id === updatedData.orderId);

    if (changedOrderIndex !== -1) {
        // what fields needs to update
        orders[changedOrderIndex].status = updatedData.status;
        orders[changedOrderIndex].extendedStatus = updatedData.extendedStatus;
        orders[changedOrderIndex].isPaymentCanPay =
            !orders[changedOrderIndex].isPaid &&
            orders[changedOrderIndex].hasPaymentProvider &&
            orders[changedOrderIndex].status !== OrderStatus.Cancelled;
    }

    return orders;
};

const updatedOrdersResponse = (
    ordersResponse: HotOrderSearchResultExtended,
    updatedData
): HotOrderSearchResultExtended => {
    const changedOrderIndex: number = ordersResponse.orders.findIndex(
        (order: HotOrderExtended) => order.id === updatedData.orderId
    );

    if (changedOrderIndex !== -1) {
        // what fields needs to update
        ordersResponse.orders[changedOrderIndex].status = updatedData.status;
        ordersResponse.orders[changedOrderIndex].extendedStatus = updatedData.extendedStatus;
        ordersResponse.orders[changedOrderIndex].isPaymentCanPay =
            !ordersResponse.orders[changedOrderIndex].isPaid &&
            ordersResponse.orders[changedOrderIndex].hasPaymentProvider &&
            ordersResponse.orders[changedOrderIndex].status !== OrderStatus.Cancelled;
    }

    return ordersResponse;
};

const getOrderDisplayedTotalAmount = (order: HotOrderExtended, storeSettings: SettingsStoreModel): number => {
    let displayedAmount = null;

    if (!!storeSettings) {
        if (order.hasInvoice) {
            displayedAmount = displayedInvoiceAmount(storeSettings, order, displayedAmount);
        }

        if (displayedAmount == null) {
            if (storeSettings.showSubtotalInOrderList) {
                displayedAmount = order.extendedPriceTotal ? order.extendedPriceTotal : order.subTotal;
            } else {
                displayedAmount = order.total;
            }
        }
    }

    return displayedAmount;
};
const displayedInvoiceAmount = (storeSettings, order, displayedAmount) => {
    if (
        storeSettings.totalInvoiceAmountAfterOrderIsDelivered &&
        (order.status === OrderStatus.Delivered || order.status === OrderStatus.ConfirmedDelivery)
    ) {
        displayedAmount = order.invoiceTotalAmount;
    } else {
        switch (storeSettings.orderTotalColumnSourceProperty) {
            case 'TotalNetAmount':
                displayedAmount = order.invoiceNetTotal;
                break;
            case 'TotalInvoiceAmount':
                displayedAmount = order.invoiceTotalAmount;
                break;
        }
    }
    return displayedAmount;
}
const normalizeItemsQuantity = (orderItems: HotOrderLineItemExtended[], usePackagesWithCartsAndOrders: boolean) => {
    if (!orderItems) {
        return [];
    }

    orderItems.forEach((item) => {
        if (item.product?.firstQuantityStep) {
            item.firstQuantityStep = item.product.firstQuantityStep;
            item.secondQuantityStep = item.product.secondQuantityStep;
        }
    });

    return calculatePackagePart(orderItems, usePackagesWithCartsAndOrders);
};

const calculatePackagePart = (orderItems: HotOrderLineItemExtended[], usePackagesWithCartsAndOrders: boolean) => {
    return cloneDeep(orderItems).map((item: HotOrderLineItemExtended) => {
        if (item.packageSize !== 0 && !usePackagesWithCartsAndOrders) {
            item.quantity = ProductService.calculateFractionalPart(item.quantity, item.packageSize);
        }
        return item;
    });
};

const setOrderPaymentsFields = (order: HotOrderExtended): void => {
    order.isPaymentCanPay =
        !order.isPaid &&
        order.hasPaymentProvider &&
        order.status !== OrderStatus.Cancelled &&
        !order.payments?.some((p) => p.status === 'New');
    order.isPaymentPaidByCash = order.isPaid && order.payments?.length === 0 && order.status !== OrderStatus.Cancelled;
    order.isPaymentPaid =
        order.isPaid &&
        order.payments?.length > 0 &&
        order.payments?.some((p) => p.status === 'Paid') &&
        order.status !== OrderStatus.Cancelled;
    order.isPaymentPending =
        order.isPaid &&
        order.payments?.length > 0 &&
        order.payments?.some((p) => p.status === 'Pending') &&
        order.status !== OrderStatus.Cancelled;
    order.isPaymentProcessing =
        !order.isPaid && order.payments?.some((p) => p.status === 'New') && order.status !== OrderStatus.Cancelled;
};

const getOrdersDemoData = () => {
    return [config.mockOrder];
};

const getDefaultSearchCriteria = () => {
        return {
        isBonusPointsOrder: false,
        startDate: moment().hours(0).minutes(0).seconds(0).add((config.currentConfigurationName==='HOP') ? -3 : -1 , 'months').toISOString(),
        endDate: moment().hours(0).minutes(0).seconds(0).add(1, 'day').subtract(1, 'seconds').toISOString(),
        pageNumber: 1,
        pageSize: 50,
        sort: 'CreatedDate:desc',
    };
};

export {
    updatedOrders,
    getOrderDisplayedTotalAmount,
    getOrdersDemoData,
    updatedOrdersResponse,
    getDefaultSearchCriteria,
    normalizeItemsQuantity,
    setOrderPaymentsFields,
};
