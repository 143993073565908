import { Component,OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { latestOrder } from '@hot-b2b/store/orders/selector';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '@hot-b2b/store/reducers';
import { HotOrderExtended, HotUserExtended } from '@hot-libs/shared-models';
import { filter } from 'rxjs/operators';
import { HotApiOrdersService, HotOrder } from '@hot-theme-nx/generated-api';
import {StorageKeys } from '@hot-libs/shared-types';
import { authUser } from '@hot-b2b/store/auth/selector';

@Component({
    selector: 'pop-retry-payment',
    templateUrl: './pop-retry-payment.component.html',
})
export class PopRetryPaymentComponent implements OnInit {
    public isClosed : boolean;
    public orderNumber;
    public latestOrder$: Observable<HotOrderExtended[]>;
    public pageNum;
    showpopUp: boolean = false;
    public isPopPilotUser : boolean =false; 
    public user$: Observable<HotUserExtended>;



    constructor(
        public readonly router: Router,
        private readonly store: Store<AppState>,
        private readonly latestNumber : HotApiOrdersService,
    ) {
        this.latestOrder$ = this.store.pipe(select(latestOrder));
        this.user$ = this.store.pipe(select(authUser))
    }

    ngOnInit(): void {
        this.isClosed = true
        this.showpopUp = JSON.parse(sessionStorage.getItem(StorageKeys.captureBannerClose));
        this.user$.subscribe((data) => {
            this.isPopPilotUser = data?.isPopPilotUser
        });
        if(this.isPopPilotUser){
            this.getLatestOrders();
        }
    }

    private getLatestOrders(): void {
        this.latestNumber.getLatestOrdersByStatus(this.pageNum).pipe(filter((data) => data.length>0 && data !== null)) 
        .subscribe((data: HotOrder[]) => {
            this.orderNumber = data[0].number;
            });
    }
       
    public close() {
        this.isClosed = false;
        sessionStorage.setItem(StorageKeys.captureBannerClose, 'true');
    }
}
