import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HotPromotion, HotPromotionProduct } from '@hot-theme-nx/generated-api';
import { SuggestedPromotion, SuggestedPromotionProduct } from '@hot-libs/shared-models';

import { HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { allPromotions } from '@hot-b2b/store/promotions/selector';
import { AppState } from '@hot-b2b/store/reducers';

@Injectable({
    providedIn: 'root',
})
export class PromotionSuggestionsService {
    private readonly allPromotions$: Observable<HotPromotion[]>;

    constructor(private readonly store: Store<AppState>) {
        this.allPromotions$ = this.store.select(allPromotions);
    }

    public getSuggestedPromotions(keyLineItems: HotCartLineItemExtended[]): Observable<SuggestedPromotion[]> {
        return this.allPromotions$.pipe(
            map((promotions: HotPromotion[]) =>
                promotions
                    .map((promotion: HotPromotion) => this.getSuggestionForPromotion(promotion, keyLineItems))
                    .filter((promotion: SuggestedPromotion) => !!promotion)
            )
        );
    }

    private getSuggestionForPromotion(
        promotion: HotPromotion,
        lineItems: HotCartLineItemExtended[]
    ): SuggestedPromotion {
        // Promotion is considered applicable if it contains products which:
        // - are added to the cart;
        // - are not gift items;
        // - their quantity in the cart is not divisible by the quantity required by the promotion;
        // - their quantity required by the promotion is not equal to 0 (this is a case for bundle promotions);
        // - correspond to a gift reward (i.e. have rewardProductId and rewardProductQuantity and
        //   do not have rewardDiscountAmount).

        let result: SuggestedPromotion = null;

        const suggestedProducts = promotion.products
            .filter((promotionProduct: HotPromotionProduct) => this.promotionProductCanBeSuggested(promotionProduct))
            .map((promotionProduct: HotPromotionProduct) => {
                let suggestedProduct: SuggestedPromotionProduct = null;

                const lineItem = lineItems.find(
                    (item: HotCartLineItemExtended) =>
                        !item.isGift &&
                        item.productId === promotionProduct.productId &&
                        item.quantity % promotionProduct.quantity !== 0
                );

                if (lineItem) {
                    suggestedProduct = {
                        product: promotionProduct,
                        suggestedQuantity: promotionProduct.quantity - (lineItem.quantity % promotionProduct.quantity),
                    };
                }

                return suggestedProduct;
            })
            .filter((product: SuggestedPromotionProduct) => !!product);

        if (suggestedProducts.length) {
            result = {
                promotion,
                products: suggestedProducts,
            };
        }

        return result;
    }

    private promotionProductCanBeSuggested(promotionProduct: HotPromotionProduct): boolean {
        return (
            promotionProduct.quantity !== 0 &&
            promotionProduct.rewardProductId &&
            promotionProduct.rewardProductQuantity &&
            !promotionProduct.rewardDiscountAmount
        );
    }
}
