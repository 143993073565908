import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, take, withLatestFrom, takeUntil, map } from 'rxjs/operators';

import {
    CurrencyFormat,
    HotApiDeliveriesService,
    HotApiSettingsService,
    HotSettings,
} from '@hot-theme-nx/generated-api';

import { PermissionsService } from '../../services/permissions.service';
import { ordersExist, ordersFetched } from '@hot-b2b/store/orders/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsCurrencyFormat, settingsData, settingsStore } from '@hot-b2b/store/settings/selector';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { HotOrderExtended, HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { CustomerPermissions, OrderListViewMode, ModalIds } from '@hot-libs/shared-types';
import { FeaturesService, ModalService, OrderDetailsService } from 'apps/hot-b2b/src/app/shared/services';
import { ConfigurationService } from 'apps/hot-b2b/src/app/shared/services/configuration.service';
import { DateFormatParserService } from 'apps/hot-b2b/src/app/shared/services/date-format-parser.service';
import { HotDelivery } from 'libs/generated-api/src/lib/models/hot-delivery';
import { OrderService } from 'apps/hot-b2b/src/app/order/services';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { GetSettingsSuccess } from '@hot-theme-nx/common-api';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DeliveryMethod } from '@hot-b2b/store/cart/model';
import { FIleDownloadUtil } from '../../services/file-download-util.service';
import { authUser } from '@hot-b2b/store/auth/selector';

@Component({
    selector: 'hot-order-list',
    templateUrl: './order-list.component.html',
})
export class OrderListComponent implements OnInit, AfterViewChecked {
    @Input() public id: string;
    @Input() public canOpenModal: boolean;
    @Input() public dateFormat: string;
    @Input() public orders: HotOrderExtended[] = [];
    @Input() public showDefaultColumns: boolean;
    @Input() public viewMode: OrderListViewMode = 'table';
    @Input() public isSearched: boolean;
    @Output() public openModal: EventEmitter<HotOrderExtended> = new EventEmitter();
    @Output() public scrolled: EventEmitter<any> = new EventEmitter();
    @Output() public downloadTimeout: EventEmitter<HotOrderExtended> = new EventEmitter();
    @Output() public openModal2: EventEmitter<any> = new EventEmitter();
    @Output() public addToCart: EventEmitter<any> = new EventEmitter();
    public imgUrl: string;
    public payclicked: boolean = false;
    public startDate: string;
    public endDate: string;
    public columns: string[] = ['purchase-order-number', 'status', 'total', 'payment'];
    public locale: string;
    public currencyFormat: CurrencyFormat;
    public haveReadPricingPermission: boolean;

    public isEven = (num: number) => num % 2 === 0;
    public isUpSm = () => this.screenDimensionService.upSm();

    public settings$: Observable<HotSettingsExtended>;
    private readonly settingsCurrencyFormat$: Observable<CurrencyFormat>;

    public featureOrderPaymentsProcessing: boolean = this.featuresService.OrderPaymentsProcessing;
    public featureShowCreditValidationWhenCheckout: boolean = this.featuresService.ShowCreditValidationWhenCheckout;
    public ordersFetched$: Observable<boolean>;
    public ordersExist$: Observable<boolean>;
    private payOnDeliveryPage: boolean;
    public isHop = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public isRw = () => this.configurationService.getCurrentConfiguration() === 'RW';
    public isVnB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';
    public isUpLg = () => this.screenDimensionService.upLg();
    public isLAB2b = () => this.configurationService.getCurrentConfiguration() === 'RE';

    private readonly subscriptionDestroy$ = new Subject<boolean>();
    public modalDeliveryDetailsState$: Observable<ModalsState>;
    public modalTrackAndTraceState$: Observable<ModalsState>;
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    public hotSettings$: Observable<HotSettings>;
    settingsResponse: any;
    isMultiplePaymentActiveFlag: boolean;
    public allowToDownloadInvoicesDocument: boolean = false;
    public user$: Observable<HotUserExtended>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public enableTrackAndTraceOrderView: boolean = false;
    public orderHiddenLabelPaid: boolean = false;
    public featureBoostOrderCheckout: boolean = this.featuresService.BoostOrderCheckout;
    public featureEnableCustomerPickup: boolean = this.featuresService.EnableCustomerPickup;
    public isPopPilotUser : boolean =false; 

    constructor(
        private readonly store: Store<AppState>,
        private readonly dateFormatParserService: DateFormatParserService,
        private readonly permissionsService: PermissionsService,
        private readonly configurationService: ConfigurationService,
        private readonly orderDetailsService: OrderDetailsService,
        private readonly activeRoute: ActivatedRoute,
        public readonly screenDimensionService: ScreenDimensionService,
        public readonly featuresService: FeaturesService,
        private readonly hotApiDeliveriesService: HotApiDeliveriesService,
        private readonly orderService: OrderService,
        private readonly modalService: ModalService,
        private readonly hotApiSettingsService: HotApiSettingsService,
        private readonly router: Router,
        private readonly translateService: TranslateService
    ) {
        this.settings$ = this.store.pipe(
            select(settingsData),
            filter((settings: HotSettingsExtended) => !!settings.currentLanguage)
        );
        this.settingsCurrencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.ordersFetched$ = this.store.pipe(select(ordersFetched));
        this.ordersExist$ = this.store.pipe(select(ordersExist));
        this.modalDeliveryDetailsState$ = this.store.pipe(select(selectModalState(ModalIds.deliveryDetails)));
        this.modalTrackAndTraceState$ = this.store.pipe(select(selectModalState(ModalIds.trackAndTraceModal)));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.user$ = this.store.pipe(select(authUser));
    }

    public ngOnInit(): void {
        this.hotSettings$ = this.hotApiSettingsService.getSettings();
        this.hotApiSettingsService
            .getSettings()
            .pipe(map((settings) => GetSettingsSuccess({ settings })))
            .subscribe((settings) => {
                this.settingsResponse = settings.settings.features;
                this.settingsResponse.forEach((settingsRes) => {
                    if (settingsRes.name != undefined && settingsRes.name == 'EnableMultiplePaymentProvider') {
                        this.isMultiplePaymentActiveFlag = settingsRes.isActive;
                    }
                });
            });
        this.payOnDeliveryPage = !!this.activeRoute.snapshot.data.payOnDeliveryPage;
        // Get currency format
        this.settingsCurrencyFormat$
            .pipe(
                withLatestFrom(this.settings$),
                filter(([format]: [CurrencyFormat, HotSettingsExtended]) => format && format.currencySymbol !== ''),
                take(1)
            )
            .subscribe(([format, settings]: [CurrencyFormat, HotSettingsExtended]) => {
                this.currencyFormat = format;
                this.locale = settings.currentLanguage.code;
                if (!this.dateFormat) {
                    this.dateFormat = this.dateFormatParserService.parseDateFormatForDatePipe(settings.dateFormat);
                }
            });
        // Get read permission
        this.permissionsService
            .checkPermission(CustomerPermissions.PricingRead)
            .subscribe((available: boolean) => (this.haveReadPricingPermission = available));
        this.orderService.orderRatingDetails
            .pipe(takeUntil(this.subscriptionDestroy$))
            .subscribe((orderDetails: HotOrderExtended) => {
                if (orderDetails) {
                    this.orders.forEach((elem) => {
                        if (elem.id === orderDetails.id) {
                            elem.isOrderRated = orderDetails.isOrderRated;
                            elem.rating = orderDetails.rating;
                        }
                    });
                }
            });

        this.settings$.pipe(takeUntil(this.subscriptionDestroy$)).subscribe((settings) => {
            this.allowToDownloadInvoicesDocument = settings.allowToDownloadInvoicesDocument;
            this.enableTrackAndTraceOrderView = settings.enableTrackAndTraceOrderView;
            this.orderHiddenLabelPaid = settings.orderHiddenLabelPaid;
        });
        this.user$.subscribe((data) => {
            this.isPopPilotUser = data.isPopPilotUser
        });
    }

    public ngAfterViewChecked(): void {
        if (!this.showDefaultColumns) {
            const isUpSm = this.screenDimensionService.upSm();
            this.columns = this.configurationService.getPreviousOrdersColumn()[isUpSm ? 'desktop' : 'mobile'];
            if (!this.featureBoostOrderCheckout) {
                const invoiceStatusColumnIndex = this.columns.indexOf('invoiceStatus');
                if (invoiceStatusColumnIndex > -1) {
                    this.columns.splice(invoiceStatusColumnIndex, 1);
                }
            }

            if (!this.featureOrderPaymentsProcessing || this.featureBoostOrderCheckout) {
                const paymentColumnIndex = this.columns.indexOf('payment');
                if (paymentColumnIndex > -1) {
                    this.columns.splice(paymentColumnIndex, 1);
                }
            }
        }
    }

    public toggleOrders(poOrder, event) {
        event.stopPropagation();
        poOrder.showDeliveries = !poOrder.showDeliveries;
    }

    public payNow(order: any, orderNumber: string, orderTotal: string, orderHasUnfinishedPayments: boolean): void {
        if (this.featureShowCreditValidationWhenCheckout) {
            this.modalService.toggleModal(ModalIds.paymentConfirmation, true, {
                orderNumber,
            });
        } else {
            this.orderService.orderNumber = order;

            this.orderService.Total = order.total;

            this.orderDetailsService.toggleAddAmountOrderModal(true, order, {
                orderNumber,

                orderTotal,

                orderHasUnfinishedPayments,
            });
        }
    }

    public downloadIconClick(order: HotOrderExtended, deliveries): void {
        if (order.orderRelatedDocuments.length > 0) {
            order.downloadDocument = true;
            for (let orderDoc of order.orderRelatedDocuments) {
                orderDoc['isChecked'] = false;
                orderDoc['fileType'] = orderDoc.name.split('.').pop();
            }
            order.downloadTimeout = false;
        }

        if (deliveries?.length === 1) {
            let deliveryDetails = {
                fileType: 'xlsx',
                name: deliveries[0].number + '.xlsx',
                isChecked: false,
                type: 'delivery',
                url: '',
                delivery: deliveries[0],
            };
            if (!order.orderRelatedDocuments.some((doc) => doc.name === deliveryDetails.name)) {
                order.downloadDocument = true;
                order.orderRelatedDocuments.push(deliveryDetails);
            }
        }
        setTimeout(() => {
            order.downloadTimeout = true;
            this.downloadTimeout.emit(order);
        }, 300000);
        if (this.allowToDownloadInvoicesDocument) {
            this.openModal.emit(order);
        }
    }

    public exportDelivery(event: Event, delivery: HotDelivery, download): void {
        event?.stopPropagation();
        if (download) {
            this.hotApiDeliveriesService
                .exportDelivery(delivery.id)
                .pipe(takeUntil(this.subscriptionDestroy$))
                .subscribe((response) => {
                    FIleDownloadUtil.fileDownload(response, `${delivery.number}.xlsx`);
                });
        }
    }
    public openDeliveryDetailsModal(delivery: HotDelivery): void {
        this.modalService.toggleModal(ModalIds.deliveryDetails, true, delivery);
    }
    public closeDeliveryDetailsModal(): void {
        this.modalService.toggleModal(ModalIds.deliveryDetails, false);
    }
    public openContainerUrl(event: Event, delivery: HotDelivery): void {
        if (delivery.containerUrl) {
            event?.stopPropagation();
            window.open(delivery.containerUrl, '_blank');
        }
    }
    public exportDeliveryDetails(event: Event, delivery: HotDelivery): void {
        event?.stopPropagation();

        this.hotApiDeliveriesService
            .exportDelivery(delivery.id)
            .pipe(takeUntil(this.subscriptionDestroy$))
            .subscribe((response) => {
                FIleDownloadUtil.fileDownload(response, `${delivery.number}.xlsx`);
            });
    }

    public setDateFormat(date): Observable<string> {
        let startDate = moment.utc(date).format('DD/MM/YYYY');
        let endDate = moment.utc(date).add(6, 'days').format('DD/MM/YYYY');
        this.startDate = startDate;
        this.endDate = endDate;
        return this.orderDetailsService.setDaysFormat(date);
    }

    public trackOrderIconClick(event: Event, order: HotOrderExtended): void {
        event?.stopPropagation();
        this.modalService.toggleModal(ModalIds.trackAndTraceModal, true, order);
    }

    public closeTrackAndTraceModal() {
        this.modalService.toggleModal(ModalIds.trackAndTraceModal, false);
    }

    public isCustomerPickupEnabled(): boolean {
        return this.featureEnableCustomerPickup;
    }

    public isPickupOrder(order: HotOrderExtended) {
        if(order.shipments.length > 0){
            return order.shipments[0].shipmentMethodCode == DeliveryMethod.PickUp;
        }
    }
}
