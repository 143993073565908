import { ECmsImagesActions } from './types';
import { CmsImagesActions } from './actions';
import { ICmsImagesState } from './model';
import { cmsImagesInitialState } from './state';

export function cmsImagesReducer(
    state: ICmsImagesState = cmsImagesInitialState,
    action: CmsImagesActions
): ICmsImagesState {
    switch (action.type) {
        case ECmsImagesActions.CMS_IMAGES_PENDING: {
            return {
                ...state,
                fetched: false,
                pending: true,
                error: null,
            };
        }
        case ECmsImagesActions.CMS_IMAGES_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload,
            };
        }
        default:
            return state;
    }
}
