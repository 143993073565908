import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { LocalStorageService } from '@hot-libs/browser-specific';
import { ApplicationMode } from '@hot-libs/shared-models';
import { ModalIds, StorageKeys } from '@hot-libs/shared-types';

import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { InitDataManagerService } from 'apps/hot-b2b/src/app/shared/services/initdata-manager.service';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsToggleOnline } from '@hot-b2b/store/settings/actions';

@Component({
    selector: 'hot-cache-warning-modal',
    templateUrl: './cache-warning-modal.component.html',
})
export class CacheWarningModalComponent {
    @Input() public showModal: boolean;
    @Input() public title: string;

    public PATHS = PATHS;
    public cacheDate: Observable<string> = this.localStorageService
        .getItem(StorageKeys.appMode)
        .pipe(
            map((mode: ApplicationMode) =>
                mode?.lastActivity ? moment(mode.lastActivity).format('Do MMMM YYYY, HH:mm') : ''
            )
        );

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly initDataManagerService: InitDataManagerService,
        private readonly localStorageService: LocalStorageService
    ) {}

    public confirm(): void {
        this.initDataManagerService.toOnline();
        this.store.dispatch(new SettingsToggleOnline(true));

        this.localStorageService
            .getItem(StorageKeys.appMode)
            .pipe(take(1))
            .subscribe((mode: ApplicationMode) => {
                this.localStorageService.setItem(StorageKeys.appMode, {
                    ...mode,
                    modalAvailable: false,
                    online: true,
                    lastActivity: null,
                });
            });
        this.closeModal();
    }

    public cancel(): void {
        this.localStorageService
            .getItem(StorageKeys.appMode)
            .pipe(take(1))
            .subscribe((mode: ApplicationMode) => {
                this.localStorageService.setItem(StorageKeys.appMode, {
                    ...mode,
                    modalAvailable: false,
                    online: false,
                });
            });
        this.closeModal();
    }

    private closeModal(): void {
        this.modalService.toggleModal(ModalIds.cacheWarning, false);
    }
}
