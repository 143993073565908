import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LocalStorageModel, serializable } from '@hot-libs/shared-models';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private readonly cache: LocalStorageModel;

    constructor() {
        this.cache = Object.create(null);
    }

    public setItem<T extends serializable>(key: string, value: T): BehaviorSubject<T> {
        localStorage.setItem(key, JSON.stringify(value));

        if (this.cache[key]) {
            this.cache[key].next(value);
            return this.cache[key];
        }
        this.cache[key] = new BehaviorSubject(value)
        return (this.cache[key] );
    }

    public getItem<T extends serializable>(key: string): BehaviorSubject<T> {
        if (this.cache[key]) {
            return this.cache[key];
        } else {
            this.cache[key] = new BehaviorSubject(JSON.parse(localStorage.getItem(key)))
            return (this.cache[key]);
        }
    }

    public removeItem(key: string): void {
        localStorage.removeItem(key);
        if (this.cache[key]) {
            this.cache[key].next(undefined);
        }
    }
}
