import { ModalsState } from '@hot-b2b/store/modals/model';

const toggleModal = (modalState: ModalsState, modals: ModalsState[]): ModalsState[] => {
    const modal = modals.find((modalData: ModalsState) => modalData.id === modalState.id);
    if (modal) {
        modal.isOpened = modalState.isOpened;
        modal.data = modalState.data;
    }

    return [...modals];
};

export { toggleModal };
