import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { ModalIds } from '@hot-libs/shared-types';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '@hot-b2b/store/reducers';

@Component({
    selector: 'pills-selection-modal',
    templateUrl: './pills-selection-modal.component.html',
})
export class PillsSelectionModalComponent implements OnInit {
    @Input() filters;
    @Input() filterType = '';
    @Input() activeFilter;
    @Output() public closePillsModal: EventEmitter<any> = new EventEmitter();
    @Output() public filterSelected: EventEmitter<any> = new EventEmitter();

    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    public resetFilter = false;
    public previouslySelectedFilter;
    public applyNull;
    modalPillsSelectionState$: Observable<ModalsState>;

    constructor(private readonly store: Store<AppState>) {
        this.modalPillsSelectionState$ = this.store.pipe(select(selectModalState(ModalIds.pillSelectionModal)));
    }

    ngOnInit(): void {
        this.modalPillsSelectionState$.pipe(takeUntil(this.subscriptionsDestroy$)).subscribe((modalState: ModalsState) => {
            if (modalState.data?.filters) {
                this.filters = modalState.data.filters;
                if (this.filters.isMultiselect) {
                    this.filters.forEach(filter => {
                        if (!filter['isChecked']) filter['isChecked'] = false;
                    })
                }
            }
            if (modalState.data?.filterType) {
                this.filterType = modalState.data.filterType;
            }
            if (modalState.data?.activeFilter) {
                this.activeFilter = modalState.data.activeFilter;
                this.previouslySelectedFilter = modalState.data.activeFilter;
            }
        })

    }

    selectFilter(event,filter) {
        if (this.activeFilter && this.activeFilter == filter) {
            this.activeFilter = null;
                 } else {
            this.activeFilter = filter;
        }
        event.preventDefault();
    }

    public filterIsSelected(item): boolean {
        return this.activeFilter?.includes(item);
    }

    public selectMultiFilter(filter, event): void {

        filter.isChecked = !filter.isChecked
        if (filter.isChecked) {
            this.activeFilter.push(filter);
        } else {
            this.activeFilter = this.activeFilter.filter((item) => item !== filter);
            if (this.activeFilter.length == 0) {
                this.activeFilter = null;
                this.applyNull = true;
            }
        }
        event.preventDefault();
    }

    applyFilters() {
        let applyFilter = {
            filterName: this.filterType,
            selectedFilter: this.activeFilter
        }
        this.filterSelected.emit(applyFilter)
        this.closePillsModal.emit();
    }

    unselectAll() {
        this.resetFilter = true;
        this.activeFilter = null;
        }

    disableApplyBtn() {
        if (this.filterType == 'Product Category') {
            return this.productCategoryBtn();
        }
        if (this.filters?.isMultiselect) {
            if (this.resetFilter || this.applyNull) {
                return false;
            }
            else if (this.activeFilter.length == 0) {
                return true;
            }

            return false;
        }
        else if (this.previouslySelectedFilter && this.activeFilter) {
            return this.previouslySelectedFilter === this.activeFilter
        }
        else if (this.previouslySelectedFilter && !this.activeFilter) {
            return false;
        }
        else if (this.activeFilter) {
            return false;
        }
        else {
            return true;
         }       
    }
    public productCategoryBtn() {
        if (this.activeFilter == null) {
            return true;
        } else {
            return this.previouslySelectedFilter === this.activeFilter
        }
}

}
