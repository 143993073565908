import { HotProductExtended, HotUnitOfMeasureExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { HotFulfillmentCenter } from '@hot-theme-nx/generated-api';
import { StorageKeys } from '@hot-libs/shared-types';

const mutableProducts = (payload) => {
    const products: HotProductExtended[] = payload.data.products || payload.data;
    return products.map((product: HotProductExtended) => {
        const temp: HotProductExtended = { ...product };

        // obsolete
        // set quantity on product
        payload.cart.map((cartItem: HotCartLineItemExtended) => {
            if (product.id === cartItem.productId && !cartItem.isGift) {
                temp.quantity = cartItem.quantity;
                temp.extendedPrice = cartItem.extendedPrice;
            }
        });
        return temp;
    });
};

const updateProductFromCart = (array: HotProductExtended[], payload: HotCartLineItemExtended): HotProductExtended[] => {
    const tempIndex = array.findIndex((x) => x.id === payload.productId);

    if (tempIndex !== -1) {
        // what fields needs to update
        array[tempIndex].isSubscribed = payload.isSubscribed;
    }

    return array;
};

const editQuantity = (
    products: HotProductExtended[],
    cartItems: HotCartLineItemExtended[],
    featureSplitOrdersBySuppliersWhenCheckout = false
): HotProductExtended[] => {
    const fulfillmentCenter = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter)) as HotFulfillmentCenter;

    return products.map((product: HotProductExtended) => {
        let temp: HotProductExtended = { ...product };
        temp.quantity = 0;
        temp.extendedPrice = 0;

        if (temp.unitsOfMeasure) {
            temp.unitsOfMeasure.forEach((unitOfMeasure: HotUnitOfMeasureExtended) => {
                unitOfMeasure.quantity = 0;
            });
        }

        cartItems.forEach((cartItem: HotCartLineItemExtended) => {
            if (temp.unitsOfMeasure) {
                temp.unitsOfMeasure.forEach((unitOfMeasure: HotUnitOfMeasureExtended) => {
                    if (unitOfMeasure.id === cartItem.productId && !cartItem.isGift) {
                        unitOfMeasure.quantity = cartItem.quantity;
                        unitOfMeasure.salePrice = cartItem.salePrice;
                        unitOfMeasure.listPrice = cartItem.listPrice;
                        unitOfMeasure.extendedPrice = cartItem.extendedPrice;
                    }
                });
            } else {
                temp = splitOrderFeature(temp, cartItem, featureSplitOrdersBySuppliersWhenCheckout, fulfillmentCenter);
            }
        });

        return temp;
    });
};
export { mutableProducts, editQuantity, updateProductFromCart };
const splitOrderFeature = (temp, cartItem, featureSplitOrdersBySuppliersWhenCheckout, fulfillmentCenter) => {
    if (temp.id === cartItem.productId && !cartItem.isGift) {
        if (featureSplitOrdersBySuppliersWhenCheckout) {
            if (cartItem.storeId === fulfillmentCenter?.storeId) {
                temp.quantity = cartItem.quantity;
                temp.extendedPrice = cartItem.extendedPrice;
            }
        } else {
            temp.quantity = cartItem.quantity;
            temp.extendedPrice = cartItem.extendedPrice;
        }
    }
    return temp;
};
