/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotAccountSettings } from '../models/hot-account-settings';
import { HotAccountSettingsUpdateRequest } from '../models/hot-account-settings-update-request';
import { HotOperationResult } from '../models/hot-operation-result';
import { HotLogoutRequest } from '../models/hot-logout-request';
import { HotChangePasswordRequest } from '../models/hot-change-password-request';
import { HotChangeEmailRequest } from '../models/hot-change-email-request';
import { HotChangePhoneNumberRequest } from '../models/hot-change-phone-number-request';
import { TermsAndConditionAndPrivacyPolicyResponse } from '../models/terms-and-condition-and-privacy-policy-response';
import { HotTermsAndConditions } from '../models/hot-terms-and-conditions';
import { HotPrivacyPolicy } from '../models/hot-privacy-policy';
import { MigratedUserResponse } from '../models/migrated-user-response';
import { HotPhoneNumberVerificationOtpResponseDto } from '../models/hot-phone-number-verification-otp-response-dto';
import { HotPhoneNumberVerificationOtpRequestDto } from '../models/hot-phone-number-verification-otp-request-dto';
@Injectable({
  providedIn: 'root',
})
class HotApiAccountService extends __BaseService {
  static readonly getAccountSettingsPath = '/storefrontapi/hot/account/settings';
  static readonly updateAccountSettingsPath = '/storefrontapi/hot/account/settings';
  static readonly getReminderPopupStatePath = '/storefrontapi/hot/account/{userId}/{popupReminderType}/GetReminderPopupState';
  static readonly logoutPath = '/storefrontapi/hot/account/logout';
  static readonly changePasswordPath = '/storefrontapi/hot/account/password';
  static readonly changeEmailPath = '/storefrontapi/hot/account/email';
  static readonly changePhoneNumberPath = '/storefrontapi/hot/account/phone';
  static readonly acceptRewardTermsPath = '/storefrontapi/hot/account/reward-terms';
  static readonly acceptTermsAndConditionsPath = '/storefrontapi/hot/account/terms-and-conditions/accepted';
  static readonly acceptPrivacyPolicyPath = '/storefrontapi/hot/account/privacy-policy/accepted';
  static readonly acceptAgeConfirmationPath = '/storefrontapi/hot/account/age-confirmation/accepted';
  static readonly migratedUserPath = '/storefrontapi/hot/account/migrated-user';
  static readonly verifyPhoneNumberPath = '/storefrontapi/hot/account/phone-number-verification';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  getAccountSettingsResponse(): __Observable<__StrictHttpResponse<HotAccountSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/account/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotAccountSettings>;
      })
    );
  }
  /**
   * @return Success
   */
  getAccountSettings(): __Observable<HotAccountSettings> {
    return this.getAccountSettingsResponse().pipe(
      __map(_r => _r.body as HotAccountSettings)
    );
  }

  /**
   * @param body undefined
   */
  updateAccountSettingsResponse(body?: HotAccountSettingsUpdateRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/account/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  updateAccountSettings(body?: HotAccountSettingsUpdateRequest): __Observable<null> {
    return this.updateAccountSettingsResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `HotApiAccountService.GetReminderPopupStateParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `popupReminderType`:
   *
   * @return Success
   */
  getReminderPopupStateResponse(params: HotApiAccountService.GetReminderPopupStateParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/account/${encodeURIComponent(String(params.userId))}/${encodeURIComponent(String(params.popupReminderType))}/GetReminderPopupState`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `HotApiAccountService.GetReminderPopupStateParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `popupReminderType`:
   *
   * @return Success
   */
  getReminderPopupState(params: HotApiAccountService.GetReminderPopupStateParams): __Observable<boolean> {
    return this.getReminderPopupStateResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  logoutResponse(body?: HotLogoutRequest): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/account/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  logout(body?: HotLogoutRequest): __Observable<HotOperationResult> {
    return this.logoutResponse(body).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  changePasswordResponse(body?: HotChangePasswordRequest): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/account/password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  changePassword(body?: HotChangePasswordRequest): __Observable<HotOperationResult> {
    return this.changePasswordResponse(body).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  changeEmailResponse(body?: HotChangeEmailRequest): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/account/email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  changeEmail(body?: HotChangeEmailRequest): __Observable<HotOperationResult> {
    return this.changeEmailResponse(body).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  changePhoneNumberResponse(body?: HotChangePhoneNumberRequest): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/account/phone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  changePhoneNumber(body?: HotChangePhoneNumberRequest): __Observable<HotOperationResult> {
    return this.changePhoneNumberResponse(body).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }
  acceptRewardTermsResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/account/reward-terms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  acceptRewardTerms(): __Observable<null> {
    return this.acceptRewardTermsResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  acceptTermsAndConditionsResponse(body?: HotTermsAndConditions): __Observable<__StrictHttpResponse<TermsAndConditionAndPrivacyPolicyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/account/terms-and-conditions/accepted`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TermsAndConditionAndPrivacyPolicyResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  acceptTermsAndConditions(body?: HotTermsAndConditions): __Observable<TermsAndConditionAndPrivacyPolicyResponse> {
    return this.acceptTermsAndConditionsResponse(body).pipe(
      __map(_r => _r.body as TermsAndConditionAndPrivacyPolicyResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  acceptPrivacyPolicyResponse(body?: HotPrivacyPolicy): __Observable<__StrictHttpResponse<TermsAndConditionAndPrivacyPolicyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/account/privacy-policy/accepted`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TermsAndConditionAndPrivacyPolicyResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  acceptPrivacyPolicy(body?: HotPrivacyPolicy): __Observable<TermsAndConditionAndPrivacyPolicyResponse> {
    return this.acceptPrivacyPolicyResponse(body).pipe(
      __map(_r => _r.body as TermsAndConditionAndPrivacyPolicyResponse)
    );
  }
  acceptAgeConfirmationResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/account/age-confirmation/accepted`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  acceptAgeConfirmation(): __Observable<null> {
    return this.acceptAgeConfirmationResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  migratedUserResponse(): __Observable<__StrictHttpResponse<MigratedUserResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/account/migrated-user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MigratedUserResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  migratedUser(): __Observable<MigratedUserResponse> {
    return this.migratedUserResponse().pipe(
      __map(_r => _r.body as MigratedUserResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  verifyPhoneNumberResponse(body?: HotPhoneNumberVerificationOtpRequestDto): __Observable<__StrictHttpResponse<HotPhoneNumberVerificationOtpResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/account/phone-number-verification`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotPhoneNumberVerificationOtpResponseDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  verifyPhoneNumber(body?: HotPhoneNumberVerificationOtpRequestDto): __Observable<HotPhoneNumberVerificationOtpResponseDto> {
    return this.verifyPhoneNumberResponse(body).pipe(
      __map(_r => _r.body as HotPhoneNumberVerificationOtpResponseDto)
    );
  }
}

module HotApiAccountService {

  /**
   * Parameters for GetReminderPopupState
   */
  export interface GetReminderPopupStateParams {
    userId: string;
    popupReminderType: string;
  }
}

export { HotApiAccountService }
