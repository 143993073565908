import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';

import { CustomHttpParamsEncoder } from '@hot-libs/helpers';
import { IBearerToken } from '@hot-libs/shared-models';
import { StorageKeys } from '@hot-libs/shared-types';
import { ApiConfiguration, PasswordOptions } from '@hot-theme-nx/generated-api';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsPasswordOptions } from '@hot-b2b/store/settings/selector';

const loginPagePath = '/account/sign-in';

@Injectable({
    providedIn: 'root',
})
export class SecurityService {
    private readonly passwordOptions$: Observable<PasswordOptions>;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly router: Router,
        private readonly apiConfiguration: ApiConfiguration,
        private readonly translateService: TranslateService,
        private readonly store: Store<AppState>
    ) {
        this.passwordOptions$ = this.store.pipe(select(settingsPasswordOptions));
    }

    public setSubDistrType(userType: string): Observable<null> {
        return this.httpClient.post<null>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/distributor/user-types/current/${userType}`,
            {}
        );
    }

    public refreshAuthenticationToken(token: string): Observable<null | IBearerToken> {
        const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const body: HttpParams = new HttpParams({ encoder: new CustomHttpParamsEncoder() })
            .set('grant_type', 'refresh_token')
            .set('scope', 'offline_access')
            .set('refresh_token', token);
        return this.httpClient
            .post<IBearerToken>(`${this.apiConfiguration.rootUrl}/storefrontapi/hot/security/tokens`, body.toString(), {
                headers,
            })
            .pipe(
                map((response: IBearerToken) => {
                    if (response.access_token) {
                        localStorage.setItem(StorageKeys.authToken, response.access_token);
                        this.setAuthTokenExpirationDate(response.expires_in);
                    } else {
                        this.router.navigateByUrl(loginPagePath);
                    }
                    return response;
                }),
                catchError((response: any) => {
                    if (
                        (response.status === 400 &&
                            (response.error.error_description !== 'User name is empty.' ||
                                response.error.error_description !== 'The username/password couple is invalid.' ||
                                response.error.error_description !== 'The Account is Locked')) ||
                        response.status === 401
                    ) {
                        this.errorMethodCall();
                    } else if (response.status === 403) {
                        localStorage.removeItem(StorageKeys.fcmToken);
                        localStorage.removeItem(StorageKeys.userId);
                        localStorage.removeItem(StorageKeys.bearerToken);
                        this.errorMethodCall();
                    }
                    return of(null);
                })
            );
    }

    private errorMethodCall() {
        localStorage.removeItem(StorageKeys.authToken);
        localStorage.removeItem(StorageKeys.refreshToken);
        this.router.navigateByUrl(loginPagePath);
    }

    public buildPasswordRequirementsText(): Observable<string> {
        return this.passwordOptions$.pipe(
            map((passwordOptions: PasswordOptions) => {
                let label: string = '';

                this.translateService
                    .get('account.reset-password.tooltip')
                    .subscribe((res: string) => (label += `${res}<br />`));

                if (passwordOptions.requiredLength > 0) {
                    this.translateService
                        .get('account.reset-password.tooltip-required-length', {
                            minLength: passwordOptions.requiredLength,
                        })
                        .subscribe((res: string) => (label += `${res}<br />`));
                }

                if (passwordOptions.requiredUniqueChars > 0) {
                    this.translateService
                        .get('account.reset-password.tooltip-required-unique-chars', {
                            minUniqueChars: passwordOptions.requiredUniqueChars,
                        })
                        .subscribe((res: string) => (label += `${res}<br />`));
                }

                if (passwordOptions.requireDigit) {
                    this.translateService
                        .get('account.reset-password.tooltip-required-digit')
                        .subscribe((res: string) => (label += `${res}<br />`));
                }

                if (passwordOptions.requireLowercase) {
                    this.translateService
                        .get('account.reset-password.tooltip-required-lowercase')
                        .subscribe((res: string) => (label += `${res}<br />`));
                }

                if (passwordOptions.requireNonAlphanumeric) {
                    this.translateService
                        .get('account.reset-password.tooltip-required-non-alphanumeric')
                        .subscribe((res: string) => (label += `${res}<br />`));
                }

                if (passwordOptions.requireUppercase) {
                    this.translateService
                        .get('account.reset-password.tooltip-required-uppercase')
                        .subscribe((res: string) => (label += `${res}<br />`));
                }

                return label;
            })
        );
    }

    private setAuthTokenExpirationDate(seconds: number): void {
        const currentDate: Date = new Date();
        const expirationDate: Date = currentDate;
        expirationDate.setSeconds(currentDate.getSeconds() + seconds);
        localStorage.setItem(StorageKeys.authTokenExpire, expirationDate.toString());
    }
}
