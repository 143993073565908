import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { StorageKeys } from '@hot-libs/shared-types';

/**
 * Allowed access only for authorized users.
 * Otherwise redirect to login page.
 */
@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuard  {
    constructor(private readonly router: Router) {}

    public canActivate(
        _route: ActivatedRouteSnapshot,
        routerState: RouterStateSnapshot
    ): boolean | Observable<boolean> {
        const authToken: string = localStorage.getItem(StorageKeys.bearerToken);
        if (!!authToken) {
            return true;
        }

        const returnUrlQueryParam: any = routerState.url !== '/' ? { returnUrl: routerState.url } : null;

        this.router.navigate(['/account/sign-in'], { queryParams: returnUrlQueryParam });
        return false;
    }
}
