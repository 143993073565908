import { Component, Input } from '@angular/core';
import { ConfigurationService } from 'apps/hot-b2b/src/app/shared/services/configuration.service';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { Router } from '@angular/router';
@Component({
    selector: 'hot-profile-update-modal',
    templateUrl: './profile-update-modal.component.html',
})
export class ProfileUpdateModalComponent {
    @Input() public title: string;
    @Input() public content: string;
    public isHop = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    constructor(private readonly modalService: ModalService,private readonly router:Router,
        private readonly configurationService: ConfigurationService,
        ) {
    }

    public close(): void {
       this.modalService.toggleModal(ModalIds.profileUpdate, false);
        if(!this.isHop())
        {
            this.router.navigateByUrl('/');
        }
        else{
            debugger;
            this.router.navigateByUrl('/ordering');
        }
       
    }
}
