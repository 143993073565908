import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';

import { LoaderService, RecommendedProductsService } from 'apps/hot-b2b/src/app/shared/services';
import { DataLoadedFor } from '@hot-b2b/store/auth/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { routerCurrentUrl } from '@hot-b2b/store/router/selector';

import { StorageKeys } from '@hot-libs/shared-types';

@Injectable({
    providedIn: 'root',
})
export class LoaderInterceptor implements HttpInterceptor {
    private requestCount = 0;
    private readonly router$: Observable<string>;

    private readonly ignoreAPI: string[] = [
        '/storefrontapi/hot/catalog/suggestions',
        '/storefrontapi/hot/cart/items',
        '/storefrontapi/hot/notifications/latest',
        '/storefrontapi/hot/arbitrary-notifications',
        '/storefrontapi/hot/catalog/categories/tree',
        '/storefrontapi/hot/catalog/products/types',
        '/storefrontapi/hot/cart/items/offline',
        '/storefrontapi/hot/catalog/offline',
        '/storefrontapi/hot/orders/statistics/delivered',
        '/storefrontapi/hot/cart',
        '/storefrontapi/hot/loyalty/statement',
        '/storefrontapi/hot/cart/delivery-dates',
        '/storefrontapi/hot/account/settings',
        '/storefrontapi/hot/marketing/promotions',
        '/storefrontapi/hot/tutorials',
        '/storefrontapi/hot/fulfillment-centers/search-unavailable',
        '/storefrontapi/hot/marketing/insights',
        '/storefrontapi/hot/catalog/products/search-by-sku',
        '/storefrontapi/hot/marketing/carousel-banners',
        '/storefrontapi/hot/marketing/coupons',
        '/storefrontapi/hot/cms/distributor/get-tiles-counts',
        '/storefrontapi/hot/cms/home-page-widgets',
        '/storefrontapi/hot/orders/close-information-banner',
    ];
    private readonly ignoreAPIWithParam: string[] = [
        '/storefrontapi/hot/orders/latest?pageSize=100',
        '/storefrontapi/hot/orders/latest?pageSize=1',
    ];
    private readonly ignoreAPIFirstTimeCall: string[] = [
        '/storefrontapi/hot/legal-agreements/terms-and-conditions',
        '/storefrontapi/hot/legal-agreements/privacy-policy',
        '/storefrontapi/hot/legal-agreements/cookie-policy',
        '/storefrontapi/hot/loyalty/status',
        '/storefrontapi/hot/cms/loyalty-banners',
        '/storefrontapi/hot/faq/items',
        '/GetReminderPopupState',
        '/storefrontapi/hot/catalog',
    ];
    private ignoreAPIFirstTime: string[] = [];
    constructor(
        private readonly loaderService: LoaderService,
        private readonly store: Store<AppState>,
        private readonly recommendedProductService: RecommendedProductsService
    ) {
        this.router$ = this.store.pipe(select(routerCurrentUrl));
        this.ignoreAPIFirstTime = [...this.ignoreAPIFirstTimeCall];
    }

    public intercept(request: HttpRequest<null>, next: HttpHandler): Observable<HttpEvent<null>> {
        const formatUrl: string = request.url.replace(/\?.*|\/[a-f0-9]{32}\/?(.*)/, '');
        const isNotSVG: boolean = formatUrl.indexOf('/assets/static/svg/') === -1;
        const formatUrlParam: string = request.urlWithParams;
        this.reInitializeOneTimeLazyCall();
        let containURL: number = -1;
        const URLFound: string = this.ignoreAPIFirstTime.find((url) => request.url.includes(url));
        if (URLFound) {
            containURL = this.ignoreAPIFirstTime.indexOf(URLFound);
            this.ignoreAPIFirstTime.splice(containURL, 1);
        }
        let isIgnore: boolean =
            isNotSVG &&
            this.ignoreAPI.indexOf(formatUrl) === -1 &&
            this.ignoreAPIWithParam.indexOf(formatUrlParam) === -1 &&
            containURL === -1;

        const productrecommendation =
            formatUrl.includes('/storefrontapi/hot/productrecommendation/evaluate/') ||
            formatUrl.includes('/storefrontapi/hot/dynamicassociations/');
        if (isIgnore && productrecommendation) {
            isIgnore = false;
        }

        if (location.href.includes('/brands')) {
            isIgnore = false;
        }

        if (isIgnore) {
            this.requestCount++;
            this.loaderService.show();
        }
        return next.handle(request).pipe(
            finalize(() => {
                if (isIgnore) {
                    this.requestCount--;
                }
                if (this.requestCount === 0) {
                    this.loaderService.hide();
                    this.router$
                        .pipe(
                            filter((route) => !(route === null || route === undefined)),
                            take(1)
                        )
                        .subscribe((url) => this.store.dispatch(new DataLoadedFor(url)));
                }
            })
        );
    }
    private reInitializeOneTimeLazyCall(): void {
        //when user log in setting this key
        //so that we can re initialize values and can make one time api call again on login
        if (localStorage.getItem(StorageKeys.reInitializeFirstTimeAPICall)) {
            this.ignoreAPIFirstTime = [...this.ignoreAPIFirstTimeCall];
            localStorage.removeItem(StorageKeys.reInitializeFirstTimeAPICall);
        }
    }
}
