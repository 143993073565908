import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { DistributorOrdersClient } from 'apps/hot-b2b/src/app/distributor/services';
import { AlertService } from 'apps/hot-b2b/src/app/shared/services';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { take } from 'rxjs/operators';
import {settingsData} from '@hot-b2b/store/settings/selector'

@Component({
    selector: 'hot-distributor-order-reject-modal',
    templateUrl: './distributor-order-reject-modal.component.html',
})
export class DistributorOrderRejectModalComponent implements OnInit {
    @Input() public orderId: string;
    @Output() public toggleModal: EventEmitter<any> = new EventEmitter();
    @Output() public callback: EventEmitter<any> = new EventEmitter();
    @Output() public errorStatusChangedEvent: EventEmitter<any> = new EventEmitter();

    public selectedReason = '';
    public otherText = '';
    public otherLabel: string;
    public settings$: Observable<HotSettingsExtended>;
    public featureEnableCHQIntegration: boolean;
    public orderRejectReasonCreditIssue: string;
    public orderRejectReasonOutOfStock: string;
    public orderRejectReasonRequestToCancel: string;
    public orderRejectReasonCodeCreditIssue: string;
    public orderRejectReasonCodeOutOfStock: string;
    public orderRejectReasonCodeRequestToCancel: string;
    public orderRejectReasonCodeOther: string;
    

    constructor(
        private readonly distributorOrdersClient: DistributorOrdersClient,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly store: Store<AppState>
    ) {
        this.settings$ = this.store.pipe(select(settingsData));
    }

    public ngOnInit(): void {
        this.otherLabel = this.translateService.instant('shared.modals.distributor-order-reject.reasons.other');
        this.assignRejectReasonsAndRejectReasonCodes();
        this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
            this.featureEnableCHQIntegration = settings.features.find((feature) => feature.name === "EnableCHQIntegration").isActive;
        });
    }

    public selectReason(reason: string): void {
        this.selectedReason = reason;
    }

    public rejectOrder(): void {
        let reason = this.selectedReason === this.otherLabel ? this.otherText : this.selectedReason;
        if (!reason) {
            return;
        }
        if(reason.includes(',')) {
            reason = reason.split(',').join(' ');
        }
        if(this.featureEnableCHQIntegration) {
            switch(reason) {
                case this.orderRejectReasonCreditIssue :
                    reason += ',' + this.orderRejectReasonCodeCreditIssue;
                    break;
                case this.orderRejectReasonOutOfStock :
                    reason += ',' + this.orderRejectReasonCodeOutOfStock;
                    break;
                case this.orderRejectReasonRequestToCancel :
                    reason += ',' + this.orderRejectReasonCodeRequestToCancel;
                    break;
                default:
                    reason += ',' + this.orderRejectReasonCodeOther;
            }
        }
        this.distributorOrdersClient.rejectOrder(this.orderId, reason).subscribe({
            next: () => {
                this.callback.emit(reason);
                this.closeModal(true);
            },
            error: (error: any) => {
                if (error && error.error === 'Requested order cannot be updated in current status') {
                    this.errorStatusChangedEvent.emit(true);
                } else {
                    this.closeModal(false);
                    this.alertService.error(error);
                }
            }
        });
    }

    public closeModal(performAction: boolean): void {
        this.toggleModal.emit(performAction);
    }

    public assignRejectReasonsAndRejectReasonCodes() : void {
        this.orderRejectReasonCreditIssue = this.translateService.instant('shared.modals.distributor-order-reject.reasons.credit-issues');
        this.orderRejectReasonOutOfStock = this.translateService.instant('shared.modals.distributor-order-reject.reasons.not-available');
        this.orderRejectReasonRequestToCancel = this.translateService.instant('shared.modals.distributor-order-reject.reasons.request-to-cancel');
        this.orderRejectReasonCodeCreditIssue = this.translateService.instant('shared.modals.distributor-order-reject.reasonCodes.credit-issues');
        this.orderRejectReasonCodeOutOfStock = this.translateService.instant('shared.modals.distributor-order-reject.reasonCodes.not-available');
        this.orderRejectReasonCodeRequestToCancel = this.translateService.instant('shared.modals.distributor-order-reject.reasonCodes.request-to-cancel');
        this.orderRejectReasonCodeOther = this.translateService.instant('shared.modals.distributor-order-reject.reasonCodes.other');
    }
}
