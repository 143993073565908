import { Action } from '@ngrx/store';
import { EFeedbackTopics } from '@hot-b2b/store/feedback/types';

export class FeedbackTopicsGet implements Action {
    public readonly type = EFeedbackTopics.FEEDBACK_TOPICS_PENDING;
}

export class FeedbackTopicsGetSuccess implements Action {
    public readonly type = EFeedbackTopics.FEEDBACK_TOPICS_FETCHED;
    constructor(public payload: string[]) {}
}

export class FeedbackTopicsGetError implements Action {
    public readonly type = EFeedbackTopics.FEEDBACK_TOPICS_ERROR;
    constructor(public payload: any) {}
}

export type FeedbackTopicsActions = FeedbackTopicsGet | FeedbackTopicsGetSuccess | FeedbackTopicsGetError;
