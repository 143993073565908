/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotOrderTemplate } from '../models/hot-order-template';
import { HotLineItem } from '../models/hot-line-item';
import { HotCart } from '../models/hot-cart';
@Injectable({
  providedIn: 'root',
})
class HotApiOrderTemplatesService extends __BaseService {
  static readonly getOrderTemplatesPath = '/storefrontapi/hot/order-templates';
  static readonly deleteOrderTemplatePath = '/storefrontapi/hot/order-templates/{templateId}';
  static readonly renameOrderTemplatePath = '/storefrontapi/hot/order-templates/{templateId}/name';
  static readonly updateLineItemsPath = '/storefrontapi/hot/order-templates/{templateId}/items';
  static readonly changeLineItemQuantityPath = '/storefrontapi/hot/order-templates/{templateId}/items/{itemId}/quantity/{quantity}';
  static readonly removeLineItemPath = '/storefrontapi/hot/order-templates/{templateId}/items/{itemId}';
  static readonly recalculateTemplatePath = '/storefrontapi/hot/order-templates/{templateId}/recalculate';
  static readonly addItemsToCartPath = '/storefrontapi/hot/order-templates/{templateId}/cart';
  static readonly replaceItemsInCartPath = '/storefrontapi/hot/order-templates/{templateId}/cart';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  getOrderTemplatesResponse(): __Observable<__StrictHttpResponse<Array<HotOrderTemplate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/order-templates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotOrderTemplate>>;
      })
    );
  }
  /**
   * @return Success
   */
  getOrderTemplates(): __Observable<Array<HotOrderTemplate>> {
    return this.getOrderTemplatesResponse().pipe(
      __map(_r => _r.body as Array<HotOrderTemplate>)
    );
  }

  /**
   * @param templateId undefined
   */
  deleteOrderTemplateResponse(templateId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/storefrontapi/hot/order-templates/${encodeURIComponent(String(templateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param templateId undefined
   */
  deleteOrderTemplate(templateId: string): __Observable<null> {
    return this.deleteOrderTemplateResponse(templateId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `HotApiOrderTemplatesService.RenameOrderTemplateParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `name`:
   *
   * @return Success
   */
  renameOrderTemplateResponse(params: HotApiOrderTemplatesService.RenameOrderTemplateParams): __Observable<__StrictHttpResponse<HotOrderTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/order-templates/${encodeURIComponent(String(params.templateId))}/name`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderTemplate>;
      })
    );
  }
  /**
   * @param params The `HotApiOrderTemplatesService.RenameOrderTemplateParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `name`:
   *
   * @return Success
   */
  renameOrderTemplate(params: HotApiOrderTemplatesService.RenameOrderTemplateParams): __Observable<HotOrderTemplate> {
    return this.renameOrderTemplateResponse(params).pipe(
      __map(_r => _r.body as HotOrderTemplate)
    );
  }

  /**
   * @param params The `HotApiOrderTemplatesService.UpdateLineItemsParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateLineItemsResponse(params: HotApiOrderTemplatesService.UpdateLineItemsParams): __Observable<__StrictHttpResponse<HotOrderTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/order-templates/${encodeURIComponent(String(params.templateId))}/items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderTemplate>;
      })
    );
  }
  /**
   * @param params The `HotApiOrderTemplatesService.UpdateLineItemsParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateLineItems(params: HotApiOrderTemplatesService.UpdateLineItemsParams): __Observable<HotOrderTemplate> {
    return this.updateLineItemsResponse(params).pipe(
      __map(_r => _r.body as HotOrderTemplate)
    );
  }

  /**
   * @param params The `HotApiOrderTemplatesService.ChangeLineItemQuantityParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `quantity`:
   *
   * - `itemId`:
   *
   * @return Success
   */
  changeLineItemQuantityResponse(params: HotApiOrderTemplatesService.ChangeLineItemQuantityParams): __Observable<__StrictHttpResponse<HotOrderTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/order-templates/${encodeURIComponent(String(params.templateId))}/items/${encodeURIComponent(String(params.itemId))}/quantity/${encodeURIComponent(String(params.quantity))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderTemplate>;
      })
    );
  }
  /**
   * @param params The `HotApiOrderTemplatesService.ChangeLineItemQuantityParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `quantity`:
   *
   * - `itemId`:
   *
   * @return Success
   */
  changeLineItemQuantity(params: HotApiOrderTemplatesService.ChangeLineItemQuantityParams): __Observable<HotOrderTemplate> {
    return this.changeLineItemQuantityResponse(params).pipe(
      __map(_r => _r.body as HotOrderTemplate)
    );
  }

  /**
   * @param params The `HotApiOrderTemplatesService.RemoveLineItemParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `itemId`:
   *
   * @return Success
   */
  removeLineItemResponse(params: HotApiOrderTemplatesService.RemoveLineItemParams): __Observable<__StrictHttpResponse<HotOrderTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/storefrontapi/hot/order-templates/${encodeURIComponent(String(params.templateId))}/items/${encodeURIComponent(String(params.itemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderTemplate>;
      })
    );
  }
  /**
   * @param params The `HotApiOrderTemplatesService.RemoveLineItemParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `itemId`:
   *
   * @return Success
   */
  removeLineItem(params: HotApiOrderTemplatesService.RemoveLineItemParams): __Observable<HotOrderTemplate> {
    return this.removeLineItemResponse(params).pipe(
      __map(_r => _r.body as HotOrderTemplate)
    );
  }

  /**
   * @param params The `HotApiOrderTemplatesService.RecalculateTemplateParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `body`:
   *
   * @return Success
   */
  recalculateTemplateResponse(params: HotApiOrderTemplatesService.RecalculateTemplateParams): __Observable<__StrictHttpResponse<HotOrderTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/order-templates/${encodeURIComponent(String(params.templateId))}/recalculate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderTemplate>;
      })
    );
  }
  /**
   * @param params The `HotApiOrderTemplatesService.RecalculateTemplateParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `body`:
   *
   * @return Success
   */
  recalculateTemplate(params: HotApiOrderTemplatesService.RecalculateTemplateParams): __Observable<HotOrderTemplate> {
    return this.recalculateTemplateResponse(params).pipe(
      __map(_r => _r.body as HotOrderTemplate)
    );
  }

  /**
   * @param templateId undefined
   * @return Success
   */
  addItemsToCartResponse(templateId: string): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/order-templates/${encodeURIComponent(String(templateId))}/cart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param templateId undefined
   * @return Success
   */
  addItemsToCart(templateId: string): __Observable<HotCart> {
    return this.addItemsToCartResponse(templateId).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param templateId undefined
   * @return Success
   */
  replaceItemsInCartResponse(templateId: string): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/order-templates/${encodeURIComponent(String(templateId))}/cart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param templateId undefined
   * @return Success
   */
  replaceItemsInCart(templateId: string): __Observable<HotCart> {
    return this.replaceItemsInCartResponse(templateId).pipe(
      __map(_r => _r.body as HotCart)
    );
  }
}

module HotApiOrderTemplatesService {

  /**
   * Parameters for RenameOrderTemplate
   */
  export interface RenameOrderTemplateParams {
    templateId: string;
    name?: string;
  }

  /**
   * Parameters for UpdateLineItems
   */
  export interface UpdateLineItemsParams {
    templateId: string;
    body?: Array<HotLineItem>;
  }

  /**
   * Parameters for ChangeLineItemQuantity
   */
  export interface ChangeLineItemQuantityParams {
    templateId: string;
    quantity: number;
    itemId: string;
  }

  /**
   * Parameters for RemoveLineItem
   */
  export interface RemoveLineItemParams {
    templateId: string;
    itemId: string;
  }

  /**
   * Parameters for RecalculateTemplate
   */
  export interface RecalculateTemplateParams {
    templateId: string;
    body?: Array<HotLineItem>;
  }
}

export { HotApiOrderTemplatesService }
