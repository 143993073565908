import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import {
    HotApiCartsService,
    HotApiGroupAccountService,
    HotApiOrdersService,
    HotDeliveredOrdersStatistics,
    HotFulfillmentCenter,
    HotGroupAccountOrderSearchCriteria,
    HotOperationResult,
    HotOrder,
    HotOrderConfirmDeliveryRequest,
    HotOrderSearchCriteria,
    HotOrderSearchResult,
    HotOrderStatusReasonsWithLanguage,
    HotRejectOrderRequest,
    HotConfirmOrderDeliveryRequest,
    HotCreateOrderRequest,
    HotPaymentMethod,
    HotCartValidationRequest,
} from '@hot-theme-nx/generated-api';

import {
    CartCreateReorder,
    CartOfflineClear,
    CartOfflineCreateReorder,
    CartClearRemarks,
    CartSetDeliveryDate,
    CartSetShipmentMethod,
} from '@hot-b2b/store/cart/actions';
import { OrdersTemplateGet } from '@hot-b2b/store/order-template/actions';
import { getOrderDisplayedTotalAmount, normalizeItemsQuantity } from '@hot-b2b/store/orders/helpers';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsChangeFFCID } from '@hot-b2b/store/settings/actions';
import { settingsStore, settingsPaymentMethods } from '@hot-b2b/store/settings/selector';
import { getFromStorage } from '@hot-libs/helpers';

import {
    HotBrandExtended,
    HotOrderExtended,
    HotOrderLineItemExtended,
    SettingsStoreModel,
    HotUserExtended,
} from '@hot-libs/shared-models';
import { StorageKeys, ModalIds } from '@hot-libs/shared-types';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services/features.service';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services/modal.service';
import { LoaderService } from 'apps/hot-b2b/src/app/shared/services/loader.service';
import { HotOrderEditRequest } from 'libs/generated-api/src/lib/models/hot-order-edit-request';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { cartData, cartRemarks } from '@hot-b2b/store/cart/selector';
import { HotCartExtended } from 'apps/hot-b2b/src/app/shared/models';
import { ApplicationInsightsService } from '../../shared/services/application-insights.service';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { ItemLocationService } from 'apps/hot-b2b/src/app/shared/services/Item-location.service';
@Injectable({
    providedIn: 'root',
})
export class OrderService {
    private readonly settingsStore$: Observable<SettingsStoreModel>;
    public authUser$: Observable<HotUserExtended>;
    public cartData$: Observable<HotCartExtended>;
    public cartRemarks$: Observable<HotCreateOrderRequest>;
    public settingsPaymentMethods$: Observable<HotPaymentMethod[]>;
    public offlineOrderCount = 0;
    public orderNumber: any;
    public selectedcode: any;
    public Total: any;
    public outletIDavailable: boolean;
    public selectedOrderNumber: string;
    public trackingDetails = new Subject<Object>();
    public orderRatingDetails = new Subject<Object>();
    public trackOrderDetails = new Subject<Object>();
    private requestButtonValidate = new Subject<any>();
    requestButtonValidate$ = this.requestButtonValidate.asObservable();
    public isDisabled$ = new Subject<boolean>();
    public isMobOtherPaymentMethod: boolean;
    public isMobCheckOutPaymentMethod: boolean;
    public mobOtherPaymentMethods: string;
    public mobCheckOutPaymentMethods: string;

    constructor(
        private readonly store: Store<AppState>,
        private readonly hotApiCartsService: HotApiCartsService,
        private readonly hotApiOrdersService: HotApiOrdersService,
        private readonly hotApiGroupAccountService: HotApiGroupAccountService,
        private readonly router: Router,
        private readonly loaderService: LoaderService,
        private readonly featuresService: FeaturesService,
        private readonly modalService: ModalService,
        private readonly appInsightsService: ApplicationInsightsService,
        private readonly analyticService: AnalyticService,
        private readonly itemlocationService: ItemLocationService
    ) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );

        this.cartData$ = this.store.pipe(select(cartData));
        this.cartRemarks$ = this.store.pipe(select(cartRemarks));
        this.settingsPaymentMethods$ = this.store.pipe(select(settingsPaymentMethods));
    }
    public assignOrderNumber(orderNumber: string) {
        this.selectedOrderNumber = orderNumber;
    }
    public fetchOrderNumber() {
        return this.selectedOrderNumber;
    }
    public getOrder(number: string): Observable<HotOrderExtended> {
        return this.hotApiOrdersService.getOrderByNumber({"orderNumber": number }).pipe(
            map((hotOrder: HotOrder) => {
                const order = hotOrder as HotOrderExtended;
                order.brands = this.getOrderBrands(order);
                this.trackingDetails.next(order);
                return order;
            })
        );
    }

    public getOrdersByOriginalNumber(number: string): Observable<HotOrderExtended[]> {
        return this.hotApiOrdersService.getOrdersByOriginalNumber(number).pipe(
            map((hotOrders: HotOrder[]) => {
                const orders = hotOrders as HotOrderExtended[];
                const brands = this.getOrderBrands(orders[0]);
                return orders.map((order: HotOrder) => ({ ...order, brands }));
            })
        );
    }

    public getOrders(criteriaOld: HotOrderSearchCriteria): Observable<HotOrderSearchResult> {
        return this.hotApiOrdersService.searchOrders(criteriaOld).pipe(
            map((apiResponse: HotOrderSearchResult) => {
                const response: HotOrderSearchResult = {
                    orders: apiResponse.orders as HotOrderExtended[],
                    statuses: apiResponse.statuses,
                    totalCount: apiResponse.totalCount,
                };
                return response;
            })
        );
    }

    public getOrdersByNumbers(orderIds: string[]): Observable<HotOrder[]> {
        return this.hotApiOrdersService.searchOrdersByNumbers(orderIds);
    }

    public getGroupAccountOrders(criteria: HotGroupAccountOrderSearchCriteria): Observable<HotOrderSearchResult> {
        return combineLatest([this.hotApiGroupAccountService.searchOrders(criteria), this.settingsStore$]).pipe(
            map(([ordersSearchResult, settingsStoreModel]: [HotOrderSearchResult, SettingsStoreModel]) => {
                const response: HotOrderSearchResult = {
                    orders: ordersSearchResult.orders as HotOrderExtended[],
                    statuses: ordersSearchResult.statuses,
                    totalCount: ordersSearchResult.totalCount,
                };

                response.orders.forEach((order: HotOrderExtended) => {
                    order.displayedTotalAmount = getOrderDisplayedTotalAmount(order, settingsStoreModel);
                });

                return response;
            })
        );
    }

    public getLatestOrders(): Observable<HotOrderExtended[]> {
        return this.hotApiOrdersService.getLatestOrders({}).pipe(
            map((hotOrders: HotOrder[]) => {
                const orders = hotOrders as HotOrderExtended[];
                orders.forEach((order: HotOrderExtended) => (order.brands = this.getOrderBrands(order)));
                return orders;
            })
        );
    }

    public editOrder(orderId: string, request: HotOrderEditRequest): Observable<HotOrderExtended> {
        const params: HotApiOrdersService.EditOrderParams = {
            orderId,
            body: request,
        };
        const editOrderObservable = this.hotApiOrdersService.editOrder(params);

        return this.setDisplayedTotalAmount(editOrderObservable).pipe(
            map((hotOrder: HotOrder) => {
                const order = hotOrder as HotOrderExtended;
                order.editable = order.status === 'Received' && moment.utc() < moment(order.editEndDate);

                order.brands = this.getOrderBrands(order);
                order.items = normalizeItemsQuantity(order.items, this.featuresService.UsePackagesWithCartsAndOrders);

                return order;
            })
        );
    }

    public confirmDelivery(request: HotOrderConfirmDeliveryRequest, orderId: string): Observable<HotOperationResult> {
        const params: HotApiOrdersService.ConfirmOrderDeliveryParams = {
            orderId,
            body: request,
        };
        return this.hotApiOrdersService.confirmOrderDelivery(params);
    }

    public cancelOrder(orderId: string, cancelReason?: string): Observable<HotOperationResult> {
        const params: HotApiOrdersService.CancelOrderParams = {
            orderId: orderId,
            cancelReason: cancelReason,
        };
        return this.hotApiOrdersService.cancelOrder(params);
    }

    public GetOrdersStatisticsForCustomer(
        startDate?: string,
        endDate?: string
    ): Observable<HotDeliveredOrdersStatistics> {
        const params: HotApiOrdersService.GetOrdersStatisticsParams = { startDate, endDate };
        return this.hotApiOrdersService.getDeliveredOrdersStatistics(params);
    }

    public setTemplateInStorage(id: string): void {
        const uniqVal = uniq([...getFromStorage(StorageKeys.offlineOrderTemplates), id]);
        localStorage.setItem(StorageKeys.offlineOrderTemplates, JSON.stringify(uniqVal));
    }

    public requestTemplateFromStorage(): void {
        const dataFromStorage: string[] = JSON.parse(localStorage.getItem(StorageKeys.offlineOrderTemplates));

        if (dataFromStorage) {
            forkJoin(dataFromStorage.map((item: string) => this.hotApiCartsService.saveCartAsTemplate(item))).subscribe(
                () => {
                    localStorage.setItem(StorageKeys.offlineOrderTemplates, JSON.stringify([]));
                    this.store.dispatch(new OrdersTemplateGet());
                }
            );
        }
    }

    public reorder(order: HotOrderExtended, orderFfc: HotFulfillmentCenter, isOnline: boolean): void {
        this.loaderService.show();
        localStorage.setItem(StorageKeys.fulfillmentCenter, JSON.stringify(orderFfc)); 
        this.itemlocationService.deleteProductUsingOutletId();
        this.itemlocationService.addItemListLocation(order.items, 'order modal');
        this.analyticService.sendAddCartEventInfo(order.items, 'order modal');

        if (isOnline) {
            this.store.dispatch(new SettingsChangeFFCID(orderFfc?.id));
            this.store.dispatch(new CartCreateReorder(order.id));
        } else {
            localStorage.removeItem(StorageKeys.offlineCart);
            this.store.dispatch(new CartOfflineClear());
            const filteredItems = order.items.filter(
                (orderItem: HotOrderLineItemExtended) => orderItem.quantity > 0 && !orderItem.isGift
            );

            this.store.dispatch(new SettingsChangeFFCID(orderFfc.id));
            this.store.dispatch(new CartOfflineCreateReorder(filteredItems));
        }

        this.loaderService.hide();
        this.router.navigateByUrl('/cart');
    }

    private getOrderBrands(order: HotOrderExtended): HotBrandExtended[] {
        const brands: HotBrandExtended[] = [];
        order.items.forEach((item: HotOrderLineItemExtended) => {
            const existingBrand = brands.find((brand: HotBrandExtended) => brand.name === item.brandGroup);
            if (!existingBrand) {
                brands.push({ name: item.brandGroup, imageUrl: item.brandGroupImageUrl, url: '', active: false });
            }
        });
        return brands;
    }

    private setDisplayedTotalAmount(
        hotOrderExtendedObservable: Observable<HotOrderExtended>
    ): Observable<HotOrderExtended> {
        return combineLatest([hotOrderExtendedObservable, this.settingsStore$]).pipe(
            map(([hotOrderExtended, settingsStoreModel]: [HotOrderExtended, SettingsStoreModel]) => {
                hotOrderExtended.displayedTotalAmount = getOrderDisplayedTotalAmount(
                    hotOrderExtended,
                    settingsStoreModel
                );

                return hotOrderExtended;
            })
        );
    }

    public orderofflineQueue() {
        let offlineOrderStorageKey = localStorage.getItem(StorageKeys.offlineOrderQueue);

        this.authUser$.subscribe((userData: HotUserExtended) => {
            if (userData) {
                if (userData.contact.outlet.id) {
                    this.offlineOrderData(offlineOrderStorageKey, userData);
                }
            }
        });
    }
    public offlineOrderData(offlineOrderStorageKey: string, userData) {
        if (offlineOrderStorageKey) {
            let offlineOrderArray = JSON.parse(offlineOrderStorageKey);
            this.outletIDavailable = false;
            offlineOrderArray.forEach((outlineBasedOrder, index) => {
                if (outlineBasedOrder.outletId === userData.contact.outlet.id) {
                    this.outletIDavailable = true;
                    this.getOrderDetails(offlineOrderArray, index);
                }
            });
            if (!this.outletIDavailable) {
                offlineOrderArray.push({
                    outletId: userData.contact.outlet.id,
                    orders: [],
                });
                this.getOrderDetails(offlineOrderArray, offlineOrderArray.length - 1);
            }
        } else {
            let offlineBase = [
                {
                    outletId: userData.contact.outlet.id,
                    orders: [],
                },
            ];
            this.getOrderDetails(offlineBase, 0);
        }
    }

    getOrderDetails(offlineBase, index) {
        this.settingsStore$
            .pipe(take(1), withLatestFrom(this.cartRemarks$, this.settingsPaymentMethods$))
            .subscribe(
                ([_SettingsStoreModel, remarks, payment]: [
                    SettingsStoreModel,
                    HotCreateOrderRequest,
                    HotPaymentMethod[]
                ]) => {
                    let fulfillmentCenter = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter));
                    let order = {
                        offlineOrderId: null,
                        items: this.setItemDetails(),
                        storeId: fulfillmentCenter.storeId,
                        fulfillmentCenterId: fulfillmentCenter.id,
                        fulfillmentCenterName: fulfillmentCenter.name,
                        orderRequests: {
                            preferredDeliveryDate: remarks.preferredDeliveryDate,
                            purchaseOrderNumber: remarks.purchaseOrderNumber ? remarks.purchaseOrderNumber : '',
                            comment: remarks.comment,
                            paymentMethod: payment[0] ? payment[0].code : '',
                            splitAlcoholicNonAlcoholicProducts: remarks.splitAlcoholicNonAlcoholicProducts,
                            cartValidationMessages: remarks.cartValidationMessages,
                        },
                    };
                    offlineBase[index].orders.push(order);
                    this.offlineOrderCount = 0;
                    offlineBase.forEach((outlet) => {
                        outlet.orders.forEach((outletOrder) => {
                            this.offlineOrderCount++;
                            outletOrder.offlineOrderId = this.offlineOrderCount;
                        });
                    });
                    localStorage.setItem(StorageKeys.offlineOrderQueue, JSON.stringify(offlineBase));
                    this.store.dispatch(new CartOfflineClear());
                    this.store.dispatch(new CartClearRemarks());
                    this.store.dispatch(new CartSetDeliveryDate(''));
                    this.store.dispatch(new CartSetShipmentMethod(''));
                }
            );
    }

    public setItemDetails() {
        let itemArray = [];
        let offlineCartItems = JSON.parse(localStorage.getItem(StorageKeys.offlineCart));
        offlineCartItems.items.forEach((item) => {
            itemArray.push({ quantity: item.quantity, sku: item.sku });
        });
        offlineCartItems.items = [];
        localStorage.setItem(StorageKeys.offlineCart, JSON.stringify(offlineCartItems));
        return itemArray;
    }
    public validateRequestButton(): void {
        this.requestButtonValidate.next(undefined);
    }
    public disableButton(value: boolean) {
        this.isDisabled$.next(value);
    }

    public otherPaymentMethod(value: boolean) {
        this.isMobOtherPaymentMethod = value;
    }
    public otherPaymentMethods(value: string) {
        this.mobOtherPaymentMethods = value;
    }
    public checkoutPaymentMethod(value: boolean) {
        this.isMobCheckOutPaymentMethod = value;
    }
    public checkoutPaymentMethods(value: string) {
        this.mobCheckOutPaymentMethods = value;
    }
    public reportAnError(errors: string[]) {
        if (errors.some((error: string) => error === 'Order delivery cannot be confirmed in current order status')) {
            this.showNotificationModal('shared.modals.cancel-order-confirmation.order-changed');
        } else {
            this.showNotificationModal(errors.join('. ') + '.');
        }
    }
    private showNotificationModal(keyContent: string) {
        this.modalService.toggleModal(ModalIds.notifyMe, true, {
            title: 'shared.modals.cancel-order-confirmation.notification.title',
            content: keyContent,
            isNotification: true,
        });
    }

    public getCustomerOrderRejectionReasons(): Observable<HotOrderStatusReasonsWithLanguage[]> {
        return this.hotApiOrdersService.getCustomerOrderRejectionReasons();
    }

    public rejectOrderByCustomer(rejectionReason: HotRejectOrderRequest): Observable<HotOperationResult> {
        return this.hotApiOrdersService.rejectOrderByCustomer(rejectionReason);
    }

    public confirmOrderByCustomer(confirmOrderDeliveryRequest: HotConfirmOrderDeliveryRequest) {
        return this.hotApiOrdersService.confirmOrderDeliveryByCustomer(confirmOrderDeliveryRequest);
    }

    public getOrderSimulationDetails(cartValidationRequest: HotCartValidationRequest) {
        return this.hotApiCartsService.validateCart(cartValidationRequest);
    }
}
