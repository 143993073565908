import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safeInnerHtml' })
export class SafeInnerHtml implements PipeTransform {
    constructor(private readonly domSanitizer: DomSanitizer) { }

    public transform(value: string): SafeHtml {
        return value ? this.domSanitizer.sanitize(SecurityContext.HTML, value) : null;
    }
}
