import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'hot-rewards-modal',
    templateUrl: './rewards-modal.component.html',
})
export class RewardsModalComponent  {
    @Input() content;
    @Output() redeemPoints: EventEmitter<any> = new EventEmitter();

}
