import { Component, EventEmitter, Input,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'hot-offline-catalog-download-warning-modal',
  templateUrl: './offline-catalog-download-warning-modal.component.html'
})
export class OfflineCatalogDownloadWarningModalComponent  {
  @Input() public title: string;
  @Input() public content: string;
  @Input() public enableOK: boolean;
  @Output() public close: EventEmitter<boolean> = new EventEmitter();
  public okText: string = this.translateService.instant('shared.mode-switcher.offline-catalog-download-warning.button.ok');
 
  constructor(
    private readonly modalService: ModalService,
    private readonly translateService: TranslateService,
  ) { }

  
  closeModal() {
    this.close.emit(true);
  }
}
