import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'timeFromNow',
})
export class TimeFromNowPipe implements PipeTransform {
    public transform(value: Date | string | null): string {
        if (!value) {
            return '';
        }
        return moment(value).fromNow();
    }
}
