import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OrderStatusModel } from '@hot-libs/shared-models';
import { ConfigurationService } from 'apps/hot-b2b/src/app/shared/services/configuration.service';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import isNil from 'lodash/isNil';

@Component({
    selector: 'hot-order-status',
    templateUrl: './order-status.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderStatusComponent {
    @Input() public orderStatus: string;
    @Input() public localizationOrderStatus: string;
    @Input() public showText = true;
    @Input() public colorStatus: string;

    public PATHS = PATHS;
    public orderStatuses: OrderStatusModel[];

    public get status(): OrderStatusModel {
        const normalizedExtendedStatus: string = this.orderStatus
            ? this.orderStatus.replace(/ /g, '-').toLowerCase()
            : '';
        const currentStatus = this.orderStatuses.find(
            (_status: OrderStatusModel) => _status.key === normalizedExtendedStatus
        );
        if (!isNil(currentStatus)) {
            return currentStatus;
        }
        const code: string = normalizedExtendedStatus.split('.')[0];
        return this.orderStatuses.find((_status: OrderStatusModel) => _status.key === code);
    }

    constructor(private readonly configurationService: ConfigurationService) {
        this.orderStatuses = this.configurationService.getOrderStatuses();
    }
}
