import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { LoaderServiceV2 } from 'apps/hot-b2b/src/app/shared/services/loader-v2.service';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { LoaderService } from '../../services';

@Component({
    selector: 'hot-loader-v2',
    templateUrl: './loader-v2.component.html',
})
export class LoaderComponentV2 implements OnInit {
    public isV1Loading$: Subject<boolean> = this.loaderService.isLoading;
    public isLoading$: Subject<boolean> = this.loaderServiceV2.isLoading;
    public loaderImage: any;
    public loadingText: string;

    private readonly loadingText$: Observable<string> = this.loaderServiceV2.loadingText.asObservable();
    public isUpLg = () => this.screenDimensionService.upLg();

    public imgSize = this.isUpLg() ? 'desktop' : 'mobile';

    constructor(
        private readonly loaderService: LoaderService,
        private readonly loaderServiceV2: LoaderServiceV2,
        private readonly screenDimensionService: ScreenDimensionService
    ) {}

    public ngOnInit(): void {
        this.isLoading$.subscribe((loading) => {
            this.loaderImage = loading ? `loader.${this.imgSize}.gif` : null;
        });

        this.loadingText$.subscribe((result) => {
            this.loadingText = result;
        });
    }
}
