import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CustomHttpParamsEncoder } from '@hot-libs/helpers';
import { IBearerToken } from '@hot-libs/shared-models';
import { ApiConfiguration } from '@hot-theme-nx/generated-api';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationClient {
    constructor(private readonly httpClient: HttpClient, private readonly apiConfiguration: ApiConfiguration) { }

    public getToken(
        userName: string,
        password: string,
        impersonateUserId: string = null,
        msalToken: string = null
    ): Observable<IBearerToken> {
        const config: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Accept', 'application/json');

        let data: HttpParams;
        if (msalToken) {
            data = new HttpParams({ encoder: new CustomHttpParamsEncoder() })
                .set('grant_type', 'id_token')
                .set('scope', 'offline_access')
                .set('token', msalToken);
        } else {
            data = new HttpParams({ encoder: new CustomHttpParamsEncoder() })
                .set('grant_type', 'password')
                .set('scope', 'offline_access')
                .set('username', userName)
                .set('password', password);
        }
        if (impersonateUserId !== null) {
            data = data.set('impersonateUserId', impersonateUserId);
        }

        return this.httpClient.post<IBearerToken>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/security/tokens`,
            data.toString(),
            {
                headers: config,
            }
        );
    }
}
