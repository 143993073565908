import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class FilesDownloadInterceptor implements HttpInterceptor {
    

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // change condition to ApiUrlsArray, if it's really necessary
        if (req.url.includes('storefrontapi') && req.url.includes('export') && !req.url.includes('prices')) {
            req = req.clone({
                setHeaders: {
                    Accept: 'application/json-patch+json, */*'
                },
                responseType: 'blob'
            });
        }

        return next.handle(req);
    }
}
