import { AuthState } from '@hot-b2b/store/auth/model';

export const authInitialState: AuthState = {
    pending: false,
    fetched: false,
    data: {
        user: null,
        loggedIn: false,
        loadedPage: '',
        homePageUrl: ''
    },
    error: null,
};
