import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({ name: 'safeStyleUrl' })
export class SafeStyleUrl implements PipeTransform {
    constructor(private readonly domSanitizer: DomSanitizer) { }

    public transform(value: string): SafeStyle {
        const stringValue = value ? `url(${value})` : null;
        return stringValue ? this.domSanitizer.sanitize(SecurityContext.STYLE, stringValue) : null;
    }
}
