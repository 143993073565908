import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TimerService } from 'apps/hot-b2b/src/app/shared/services/timer.service';

@Injectable({
    providedIn: 'root',
})
export class TimerInterceptor implements HttpInterceptor {
    constructor(private readonly timerService: TimerService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.timerService.reset();
        return next.handle(request);
    }
}
