import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';


import { FormValidationsService } from '@hot-theme-nx/common-api';
import { PasswordOptions } from '@hot-theme-nx/generated-api';

import { AppState } from '@hot-b2b/store/reducers';
import find from 'lodash/find';
import { settingsPasswordOptions } from '@hot-b2b/store/settings/selector';
import { take } from 'rxjs/operators';

@Component({
    selector: 'hot-patterns-check-list',
    templateUrl: './patterns-check-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatternsCheckListComponent implements OnInit {
    public passwordOptions: PasswordOptions;
    private passwordOptions$: Observable<PasswordOptions>;
    public passwordRequirements: {
        requiredLength: { value: number; hasError: boolean };
        requireDigit: { value: boolean; hasError: boolean };
        requireLowercase: { value: boolean; hasError: boolean };
        requireNonAlphanumeric: { value: boolean; hasError: boolean };
        requireUppercase: { value: boolean; hasError: boolean };
    };
    public hasValidationErrors: boolean;

    constructor(private readonly store: Store<AppState>, private readonly changeDetectorRef: ChangeDetectorRef) {
        this.passwordOptions$ = this.store.pipe(select(settingsPasswordOptions));
    }

    public ngOnInit(): void {
        this.passwordOptions$.pipe(take(1)).subscribe((passwordOptions: PasswordOptions): void => {
            this.passwordOptions = passwordOptions;
            this.initPasswordRequirements(passwordOptions);
        });
    }

    public validateNewPassword(newPassword): void {
        this.passwordRequirements = FormValidationsService.passwordValidationByRegexps(
            this.passwordRequirements,
            newPassword
        );

        this.hasValidationErrors = !!find(this.passwordRequirements, { hasError: true });
        this.changeDetectorRef.markForCheck();
    }

    public getRequirementsArray() {
        const result = Object.entries(this.passwordRequirements);
        return of(result);
    }

    private initPasswordRequirements(passwordOptions: PasswordOptions) {
        this.passwordRequirements = {
            requiredLength: {
                value: passwordOptions.requiredLength,
                hasError: false,
            },
            requireDigit: {
                value: passwordOptions.requireDigit,
                hasError: false,
            },
            requireLowercase: {
                value: passwordOptions.requireLowercase,
                hasError: false,
            },
            requireNonAlphanumeric: {
                value: passwordOptions.requireNonAlphanumeric,
                hasError: false,
            },
            requireUppercase: {
                value: passwordOptions.requireUppercase,
                hasError: false,
            },
        };
    }
}
