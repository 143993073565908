import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { HotDeliveredOrdersStatistics, HotOrderStatusReasonsWithLanguage, HotCartValidationRequest, HotOperationResult, HotApiCartsService } from '@hot-theme-nx/generated-api';

import { authUser } from '../auth/selector';
import { AppState } from '../reducers';
import { settingsBetaTesting, settingsStore } from '../settings/selector';

import { orders, searchOrdersCriteria, orderSimulationtaxDetails, orderSimulationSuccess, orderSimulationFailure, orderSimulationrequest } from './selector';

import {
    OrderLastGet,
    OrderLastGetSuccess,
    OrdersPreviousGet,
    OrdersPreviousGetSuccess,
    OrdersResponseGet,
    OrderUpdate,
    OrdersResponseGetSuccess,
    UpdateSearchOrdersCriteria,
    UpdateSearchOrdersCriteriaSuccess,
    OrderSynchronize,
    GetCustomerOrderRejectReasons,
    GetCustomerOrderRejectReasonsSuccess,
    RejectOrderByCustomer,
    RejectOrderByCustomerSuccess,
    ConfirmOrderByCustomer,
    ConfirmOrderByCustomerSuccess,
    OrdersChangeStatus,
    OrderSimulationPending,
    OrderSimulationFailure,
    OrderSimulationTaxCalculation,
    OrderSimulationSuccess,
    OrderSimulationSuccessResponse
} from '@hot-b2b/store/orders/actions';
import { getOrderDisplayedTotalAmount, normalizeItemsQuantity } from '@hot-b2b/store/orders/helpers';
import { EOrdersActions } from '@hot-b2b/store/orders/types';
import { HotOrderExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { FeatureNames, OrderStatus } from '@hot-libs/shared-types';
import { CartService } from 'apps/hot-b2b/src/app/cart/services';
import { OrderService } from 'apps/hot-b2b/src/app/order/services';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import { isUserBetaTester } from 'libs/common-api/src/lib/store/helpers';
import moment from 'moment';
import { OrderCreationService } from '../../shared/services/order-creation.service';

@Injectable()
export class OrdersEffects {
    constructor(
        private readonly _store: Store<AppState>,
        private readonly _actions$: Actions,
        private readonly cartService: CartService,
        private readonly orderService: OrderService,
        private readonly featuresService: FeaturesService,
        private readonly orderCreationService: OrderCreationService,
        private readonly hotApiCartsService: HotApiCartsService,
    ) { }

    public getOrders$: Observable<OrdersResponseGetSuccess> = createEffect(() => this._actions$.pipe(
            ofType<OrdersResponseGet>(EOrdersActions.ORDERS_RESPONSE_PENDING),
            withLatestFrom(this._store.select(searchOrdersCriteria)),
            switchMap(([_data, criteria]) => this.orderService.getOrders(criteria)),
            withLatestFrom(
                this._store.select(settingsStore),
                this._store.select(authUser),
                this._store.select(settingsBetaTesting)
            ),
            map(([response, storeSettings, user, betaTesting]) => {
                if (response.orders?.length) {
                    response.orders.forEach((order: HotOrderExtended) => {
                        order.displayedTotalAmount = getOrderDisplayedTotalAmount(order, storeSettings);
                        if (this.featuresService.ShowPayNowAtReceivedStatus) {
                            order.isPaymentCanPay =
                                !order.isPaid && order.hasPaymentProvider && order.status === OrderStatus.Received;
                            this.showPayNowAtReceviedStatus(order);
                        } else {
                            order.isPaymentCanPay =
                                !order.isPaid &&
                                order.hasPaymentProvider &&
                                order.status !== OrderStatus.Cancelled &&
                                !isUserBetaTester(user, betaTesting?.userGroup);
                            order.isPaymentPaidByCash = order.isPaid && order.payments?.length === 0;
                            order.isPaymentPaid =
                            order.isPaid && order.payments?.length > 0 && order.payments?.some((p) => p.status === 'Paid');
                            order.isPaymentPending =
                                order.isPaid &&
                                order.payments?.length > 0 &&
                                order.payments?.some((p) => p.status === 'Pending');
                        }

                        order.isPaymentPaidByInvoice =
                            order.isPaid && order.payments?.some((p) => p.status === 'Cancelled');
                        order.editable = order.status === 'Received' && moment.utc() < moment(order.editEndDate);
                        order.items = normalizeItemsQuantity(
                            order.items,
                            this.featuresService.UsePackagesWithCartsAndOrders
                        );
                    });
                }
                return new OrdersResponseGetSuccess(response);
            })
    ));

    public getOrdersPrevious$: Observable<OrdersPreviousGetSuccess> = createEffect(() => this._actions$.pipe(
            ofType<OrdersPreviousGet>(EOrdersActions.ORDERS_PREVIOUS_PENDING),
            withLatestFrom(
                this.featuresService.checkFeatures(FeatureNames.DeliveredOrdersStatisticsForCustomer),
                this.featuresService.checkFeatures(FeatureNames.Pwa),
                this._store.select(settingsStore)
            ),
        filter(([_data, _deliveredOrdersStatistics, pwa, _storeSettings]: [OrdersPreviousGet, boolean, boolean, SettingsStoreModel]) => pwa),
            switchMap(
            ([_data, deliveredOrdersStatistics, _pwa, storeSettings]: [OrdersPreviousGet, boolean, boolean, SettingsStoreModel]) => {
                    if (deliveredOrdersStatistics) {
                        return forkJoin([
                        this.cartService.latestOrders(100, moment().subtract(3, 'months').format('YYYY/MM/DD') ,moment().format('YYYY/MM/DD')),
                            this.orderService.GetOrdersStatisticsForCustomer(),
                        of(storeSettings)
                        ]);
                    } else {
                    return forkJoin([this.cartService.latestOrders(100, moment().subtract(3, 'months').format('YYYY/MM/DD'), moment().format('YYYY/MM/DD')), of(null), of(storeSettings)]);
                    }
                }
            ),
            map(
                ([orderItems, deliveryStatistics, storeSettings]: [
                    HotOrderExtended[],
                    HotDeliveredOrdersStatistics,
                    SettingsStoreModel
                ]) => {
                    orderItems.forEach((order: HotOrderExtended) => {
                        order.displayedTotalAmount = getOrderDisplayedTotalAmount(order, storeSettings);
                        order.editable = order.status === 'Received' && moment.utc() < moment(order.editEndDate);
                        order.items = normalizeItemsQuantity(
                            order.items,
                            this.featuresService.UsePackagesWithCartsAndOrders
                        );
                    });
                    return new OrdersPreviousGetSuccess({ orders: orderItems, statistics: deliveryStatistics });
                }
            )
    ));

    public getLastOrder$: Observable<OrderLastGetSuccess> = createEffect(() => this._actions$.pipe(
            ofType<OrderLastGet>(EOrdersActions.ORDERS_LAST_PENDING),
        withLatestFrom(
            this._store.select(settingsStore),
        ),
            switchMap(([_data, storeSetting]: [OrderLastGet, SettingsStoreModel]) => {
                return forkJoin([this.cartService.latestOrders(1), of(storeSetting)]);
            }),
            map(([latestOrder, storeSettings]: [HotOrderExtended[], SettingsStoreModel]) => {
                latestOrder.forEach((order: HotOrderExtended) => {
                    order.displayedTotalAmount = getOrderDisplayedTotalAmount(order, storeSettings);
                    order.editable = order.status === 'Received' && moment.utc() < moment(order.editEndDate);
                order.items = normalizeItemsQuantity(order.items, this.featuresService.UsePackagesWithCartsAndOrders);
                    if (order.invoiceType) {
                        this.orderCreationService.invoiceType = order.invoiceType;
                    } else {
                        this.orderCreationService.invoiceType = null;
                    }
                });
                return new OrderLastGetSuccess({ latestOrder });
            })
    ));

    public updateSearchCriteria$: Observable<UpdateSearchOrdersCriteriaSuccess> = createEffect(() => this._actions$.pipe(
            ofType<UpdateSearchOrdersCriteria>(EOrdersActions.ORDERS_UPDATE_SEARCH_CRITERIA),
            map((data) => {
                const searchCriteria = data.payload;

                return new UpdateSearchOrdersCriteriaSuccess(searchCriteria);
            })
    ));
    public orderSynchronize$: Observable<any> = createEffect(() => this._actions$.pipe(
            ofType<OrderSynchronize>(EOrdersActions.ORDER_SYNCHRONIZE),
            switchMap((data) => this.orderService.getOrder(data.payload.number)),
            map((data) => {
                return new OrderUpdate(data);
            })
    ));

    public getOrderCustomerRejectReasons$: Observable<GetCustomerOrderRejectReasonsSuccess> = createEffect(() => this._actions$.pipe(
            ofType<GetCustomerOrderRejectReasons>(EOrdersActions.ORDERS_CUSTOMER_REJECT_REASONS),
            switchMap(() => this.orderService.getCustomerOrderRejectionReasons()),
            map((data: HotOrderStatusReasonsWithLanguage[]) => {
                const reasons = data;

                return new GetCustomerOrderRejectReasonsSuccess(reasons);
            })
    ));

    public rejectOrderByCustomer$: Observable<RejectOrderByCustomerSuccess> = createEffect(() => this._actions$.pipe(
            ofType<RejectOrderByCustomer>(EOrdersActions.REJECT_ORDER_BY_CUSTOMER),
            switchMap((data) =>
                forkJoin([this.orderService.rejectOrderByCustomer(data.payload), of(data.payload.orderId)])
            ),
            map((data) => {
                return new RejectOrderByCustomerSuccess(data[1]);
            })
    ));

    public rejectOrderByCustomerSuccess$: Observable<any> = createEffect(() => this._actions$.pipe(
            ofType<RejectOrderByCustomerSuccess>(EOrdersActions.REJECT_ORDER_BY_CUSTOMER_SUCCESS),
            withLatestFrom(this._store.select(orders)),
            switchMap((data) => this.orderService.getOrder(data[1].find((item) => item.id === data[0].payload).number)),
            map((data) => {
                return this.orderStatus(data);
            })
    ));

    public confirmOrderByCustomer$: Observable<ConfirmOrderByCustomerSuccess> = createEffect(() => this._actions$.pipe(
            ofType<ConfirmOrderByCustomer>(EOrdersActions.CONFIRM_ORDER_BY_CUSTOMER),
            switchMap((data) =>
                forkJoin([this.orderService.confirmOrderByCustomer(data.payload), of(data.payload.orderId)])
            ),
            map((data) => {
                return new ConfirmOrderByCustomerSuccess(data[1]);
            })
    ));

    public confirmOrderByCustomerSuccess$: Observable<any> = createEffect(() => this._actions$.pipe(
            ofType<ConfirmOrderByCustomerSuccess>(EOrdersActions.CONFIRM_ORDER_BY_CUSTOMER_SUCCESS),
            withLatestFrom(this._store.select(orders)),
            switchMap((data) => this.orderService.getOrder(data[1].find((item) => item.id === data[0].payload).number)),
            map((data) => {
                return this.orderStatus(data);
            })
    ));
    public orderStatus(data) {
        return new OrdersChangeStatus({
            orderId: data.id,
            status: data.status,
            extendedStatus: data.extendedStatus,
        });
    }

    private showPayNowAtReceviedStatus(order: HotOrderExtended) {
        if (
            order.status === OrderStatus.Confirmed ||
            order.status === OrderStatus.OnTheWay ||
            order.status === OrderStatus.Delivered
        ) {
            order.isPaymentPaidByCash = order.isPaid && order.payments?.length === 0;
            order.isPaymentPaid =
                order.isPaid && order.payments?.length > 0 && order.payments?.some((p) => p.status === 'Paid');
            order.isPaymentPending =
                order.isPaid && order.payments?.length > 0 && order.payments?.some((p) => p.status === 'Pending');
        }
    }

    public OrderSimulationPending$: Observable<any> = createEffect(() => this._actions$.pipe(
            ofType<OrderSimulationPending>(EOrdersActions.ORDER_SIMULATION_PENDING),
            switchMap((data) =>
            this.orderService.getOrderSimulationDetails(data.payload).pipe(
                map((response: HotOperationResult) => new OrderSimulationFailure(response))
            )
        )));

    public orderSimulationTaxCaluclation$: Observable<OrderSimulationSuccess | OrderSimulationFailure |
        OrderSimulationPending | OrderSimulationSuccessResponse> =
        createEffect(() => this._actions$.pipe(
            ofType<OrderSimulationTaxCalculation>(EOrdersActions.ORDER_SIMULATION_TAXCALCULATION),
            withLatestFrom(
                this._store.select(orderSimulationSuccess),
                this._store.select(orderSimulationFailure),
                this._store.select(orderSimulationrequest),
                this._store.select(orderSimulationtaxDetails),
            ),
            switchMap(
                ([
                    _action,
                    _remarkCart,
                    _dataCart,
                    validationRequest,
                    _successfulResponse,
                ]: [OrderSimulationTaxCalculation, boolean, string[], HotCartValidationRequest,
                        HotOperationResult]) => {
                    const request: HotCartValidationRequest = {
                        preferredDeliveryDate: validationRequest.preferredDeliveryDate,
                        purchaseOrderNumber: validationRequest.purchaseOrderNumber

                    };
                    if (_action.payload?.preferredDeliveryDate) {
                        request.preferredDeliveryDate = _action.payload.preferredDeliveryDate;
                    }
                    return this.hotApiCartsService.validateCart(request).pipe(
                        map((response: HotOperationResult) => {
                            return this.createOrderHelper(response);
                        })
                    );
                }
            )));

    private createOrderHelper(response: HotOperationResult) {
        if (response.errorCodes.length == 0) {
            return new OrderSimulationSuccessResponse(response)
        }

        else if (response.errorCodes.length > 0) {
            if (response.errorCodes.length > 0) {
                return new OrderSimulationFailure(response)
            }
        }
    }
}
