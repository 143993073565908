/**
 * Work localStorage with async
 * @namespace asyncLocalStorage
 */
export const asyncLocalStorage = {
    setItem: (key: string, value: any) => {
        return localStorage.setItem(key, value);
    },
    getItem: (key: string) => {
        return localStorage.getItem(key);
    },
};

export const getFromStorage = (storageKey: string) => {
    let dataFromStorage = JSON.parse(localStorage.getItem(storageKey));

    if (!dataFromStorage) {
        dataFromStorage = [];
        localStorage.setItem(storageKey, JSON.stringify([]));
    }
    return dataFromStorage;
};
