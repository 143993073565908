import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DateFormatParserService,ConfigurationService } from 'apps/hot-b2b/src/app/shared/services';
import { StorageKeys } from 'libs/shared-types/src/lib/enums/storage-keys.enum';
import { CouponModel } from '@hot-libs/shared-models';
@Component({
  selector: 'hot-voucher',
  templateUrl: './voucher.component.html'
})
export class VoucherComponent implements OnInit {
  @Input() public voucherItem: CouponModel;
  @Input() public isRedeemed: boolean = false;
  @Output() public onClickUse: EventEmitter<CouponModel> = new EventEmitter();

  private readonly PRICE_OFF_TYPE_LIST: Array<string> = ['Price off absolute', 'Price off relative'];

  public settings$: Observable<HotSettingsExtended>;
  public dateFormat: string;
  public languageCode: string;
  public isHUb2b = () => this.configurationService.getCurrentConfiguration() === 'HU';
  
  constructor(private readonly store: Store<AppState>, private readonly dateFormatParserService: DateFormatParserService,private readonly configurationService: ConfigurationService) 
  {
    this.settings$ = this.store.pipe(select(settingsData))
  }

  public ngOnInit(): void {
    this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
      this.dateFormat = this.dateFormatParserService.parseDateFormatForDatePipe(settings.dateFormat);
  });
  this.languageCode = localStorage.getItem(StorageKeys.currentLanguage) || 'en-US';
  }

  public isPriceOffType(): boolean {
    return this.PRICE_OFF_TYPE_LIST.includes(this.voucherItem.promotionType);
  }
  public applyVoucher(): void {
    this.onClickUse.emit(this.voucherItem);
  }

}
