import { createSelector, MemoizedSelector } from '@ngrx/store';
import find from 'lodash/find';

import { ModalsState } from '@hot-b2b/store/modals/model';
import { AppState } from '@hot-b2b/store/reducers';

const stateModals = (state: AppState): ModalsState[] => state.modals;

export const selectModalState = (id: string): MemoizedSelector<AppState, ModalsState> =>
    createSelector(stateModals, (state: ModalsState[]) => find(state, (modal: ModalsState) => modal.id === id));

export const selectModalData = (id: string): MemoizedSelector<AppState, any> =>
    createSelector(stateModals, (state: ModalsState[]) => {
        const modalState = find(state, (modal: ModalsState) => modal.id === id);

        return modalState.data;
    });

export const isAllModalsClosed: MemoizedSelector<AppState, boolean> = createSelector(
    stateModals,
    (state: ModalsState[]) => !state.find((modalState) => modalState.isOpened && !modalState.data?.isNotModal)
);
