import { ControlValueAccessor } from '@angular/forms';

export abstract class BaseControlValueAccessor implements ControlValueAccessor {
    _value: any;
    disabled: boolean;

    onChange: Function;
    onTouched: Function;

    constructor() {
        this.onChange = (_: any) => { return null };
        this.onTouched = () => { return null };
    }
    get value() {
        return this._value;
    }
    set value(val: any) {
        if (val !== this._value) {
            this._value = val;
            this._value.onChange(val);
        }
    }

    writeValue(value: any) {
        this._value = value;
        this.onChange(value);
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}
