import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageKeys } from '@hot-libs/shared-types';
import { 
    HotConfirmOrderDeliveryRequest,
    HotOrderStatusReasonsWithLanguage
} from '@hot-theme-nx/generated-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hot-confirm-partial-delivery-modal',
  templateUrl: './confirm-partial-delivery-modal.component.html',
})
export class ConfirmPartialDeliveryModalComponent implements OnInit {
    @Input() public confirmPartialDeliveryReasons: HotOrderStatusReasonsWithLanguage[];
    @Input() public confirmOrderDeliveryRequest: HotConfirmOrderDeliveryRequest;
    @Output() public confirmedEvent: EventEmitter<HotConfirmOrderDeliveryRequest> = new EventEmitter();
    @Output() public backButtonClickEvent: EventEmitter<any> = new EventEmitter();

    public orderPartialDeliveryReasonForCurrentLanguage:HotOrderStatusReasonsWithLanguage;
    public customReasonText = '';
    public includeCustomReasonToRequest = false;
    public selectedReasons: string[] = [];
    public currentLanguageCode: string = localStorage.getItem(StorageKeys.currentLanguage);
    public otherLabel: string;

    constructor(
        private readonly translateService: TranslateService
    ) {}

    public ngOnInit(): void {
        this.otherLabel = this.translateService.instant('shared.modals.confirm-partial-delivery-modal.others');
        
        if (this.confirmPartialDeliveryReasons?.length) {
            this.orderPartialDeliveryReasonForCurrentLanguage =
                this.confirmPartialDeliveryReasons.find(
                    (reasonsWithLanguage:HotOrderStatusReasonsWithLanguage) =>
                        reasonsWithLanguage.language === this.currentLanguageCode
                ) ?? this.confirmPartialDeliveryReasons[0];
        } else {
            this.orderPartialDeliveryReasonForCurrentLanguage = {
                orderStatusReasons:[],
            };
        }
    }

    public confirmPartialDeliveryOrder() {
        const rejectionReasons = [...this.selectedReasons];

        if (this.includeCustomReasonToRequest && this.customReasonText) {
            rejectionReasons.push(this.otherLabel + this.customReasonText);
        }

        this.confirmOrderDeliveryRequest.reason = rejectionReasons.join('|');
        this.confirmedEvent.emit(this.confirmOrderDeliveryRequest);
    }

    public onReasonCheckboxChange(checked: boolean, reason: string) {
        if (checked)
        {
            this.selectedReasons.push(reason)
        }
        else
        {
        this.selectedReasons = this.selectedReasons.filter((selectedReason) => selectedReason != reason);    
     }
           
    }

    public onCustomReasonCheckboxChange(checked: boolean) {
        this.includeCustomReasonToRequest = checked;
    }
}
