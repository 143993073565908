export enum ETutorialsActions {
    TUTORIALS_PENDING = 'TUTORIALS_PENDING',
    TUTORIALS_FETCHED = 'TUTORIALS_FETCHED',
    TUTORIALS_STARTED = 'TUTORIALS_STARTED',
    TUTORIALS_ITEM_COMPLETE = 'TUTORIALS_ITEM_COMPLETE',
    TUTORIALS_SET_CURRENT = 'TUTORIALS_SET_CURRENT',
    TUTORIALS_SET_CURRENT_STEP = 'TUTORIALS_SET_CURRENT_STEP',
    SHOW_TUTORIALWITHID = 'SHOW_TUTORIALWITHID',
    CLEAR_TUTORIALWITHID ='CLEAR_TUTORIALWITHID'
}
