import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'hot-rewards-order-result',
    templateUrl: './rewards-order-result.component.html',
})
export class RewardsOrderResultComponent {
    @Input() resultContent;
    @Output() public toggleModal: EventEmitter<void> = new EventEmitter();
}
