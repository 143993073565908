import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UntypedFormArray } from '@angular/forms';

import { OrderTemplateService } from '../../../order-template/services/order-template.service';

import { authUser } from '@hot-b2b/store/auth/selector';
import {
    CartOfflineAddItem,
    CartOfflineChangeItem,
    CartOfflineRemoveItem,
    CartSelectItems,
} from '@hot-b2b/store/cart/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine, settingsData } from '@hot-b2b/store/settings/selector';
import { HotUserExtended } from '@hot-libs/shared-models';
import { HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { HotFulfillmentCenter,HotSettings } from '@hot-theme-nx/generated-api';
import { PageName, TelemetryEventType, ModalIds, StorageKeys } from '@hot-libs/shared-types';
import { CartService } from 'apps/hot-b2b/src/app/cart/services';
import { cartItems } from '@hot-b2b/store/cart/selector';
import {
    FeaturesService,
    ModalService,
    WarehouseService,
    ApplicationInsightsService,
    ProductService,
} from 'apps/hot-b2b/src/app/shared/services';
import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import _groupBy from 'lodash/groupBy';
import _find from 'lodash/find';
import _some from 'lodash/some';
import _every from 'lodash/every';
import { warehouses } from '../../../store/warehouses/selector';
import { config } from 'apps/hot-b2b/src/environments/config';
import { ItemLocationService } from 'apps/hot-b2b/src/app/shared/services/Item-location.service';

@Component({
    selector: 'hot-product-list',
    templateUrl: './product-list.component.html',
})
export class ProductListComponent implements OnInit {
    @Input() public products: any;
    @Input() public readonly: boolean;
    @Input() public isDistributor: boolean;
    @Input() public isVanSalesMan: boolean;
    @Input() public showDeliveredColumn: boolean;
    @Input() public showConfirmedColumn: boolean;
    @Input() public isCartSidebar: boolean;
    @Input() public isBasketOverlay: boolean;
    @Input() public isOrderTemplate: boolean;
    @Input() public isPointsCurrency: boolean;
    @Input() public pageName: string;
    @Input() public isGridViewActive: boolean;
    @Input() public orderHasInvoice: boolean;
    @Input() public isReportIssue: boolean;
    @Input() public issuesForm: UntypedFormArray;
    @Input() public canDelete = true;
    @Input() public groupingBySupplier = false;
    @Input() public orderDeliveries: any[] = []

    @Input() public useSeparateByAlcoType: boolean;
    @Input() public isEmpties: boolean;
    @Input() public isOfflineOrder: boolean;
    @Input() public orderStatus: string;
    @Input() public estimatedDeliveryDate: {
        formattedValue: string;
        value: string;
    };
    @Input() public isUpdateQuantityBaseOnProduct = false;

    @Output() public addToCart: EventEmitter<any> = new EventEmitter();
    @Output() public removeFromCart: EventEmitter<any> = new EventEmitter();
    @Output() public sendChangeQuantity: EventEmitter<any> = new EventEmitter();
    @Output() public openModal: EventEmitter<any> = new EventEmitter();
    @Output() public noSelectedItems: EventEmitter<any> = new EventEmitter();
    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public isGR = () => this.configurationService.getCurrentConfiguration() === 'GR';
    public isVNB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';

    public settingsAppOnLine$: Observable<boolean>;
    public user$: Observable<HotUserExtended>;
    public settings$: Observable<HotSettingsExtended>;
    public featureEnableCHQIntegration: boolean;
    public cartItems$: Observable<HotCartLineItemExtended[]>;
    public cartItems: HotCartLineItemExtended[];
    public settingShowDistributorNameOnMultiSupplierSections: boolean = false;
    public configIsSelectableItemInCartDisabled = config?.isSelectableItemInCartDisabled;
    public hotSettings$: Observable<HotSettings>;
    public showProductsWithSeparate: boolean;
    public alcoProducts: any;
    public nonAlcoProducts: any;
    public featureSplitOrdersBySuppliersWhenCheckout: boolean = this.featuresService.SplitOrdersBySuppliersWhenCheckout;
    public fulfillmentCenters: HotFulfillmentCenter[];
    public featureShowAndCalculateExpectedDeliveryTime = this.featuresService.ShowAndCalculateExpectedDeliveryTime;
    public restricStoreId: boolean = true;
    public ShowDistributorOrderEditOption: boolean = true;
    private readonly subscriptionsDestroy$ = new Subject<boolean>();

    public get productGroup() {
        let index = 1;
        let products = this.products;
        const productGroup = [];

        if (this.pageName === PageName.SUMMARY) {
            products = this.selectedProducts;
        }
        const grouping = _groupBy(products, 'storeId');

        for (let [storeId, groupProducts] of Object.entries(grouping)) {
            if (groupProducts?.length > 0) {
                if (this.settingShowDistributorNameOnMultiSupplierSections) {
                    groupProducts[0] = this.productFullfillmentCentre(storeId, groupProducts);
                }
                productGroup.push({
                    index,
                    totalSupplier: Object.keys(grouping).length,
                    storeId,
                    isSelected: _every(groupProducts, (product) => product.isSelected || product.isGift),
                    supplierName: groupProducts[0].supplierName,
                    productCount: groupProducts?.length,
                    totalQuantity: groupProducts.reduce((total, item) => item.quantity + total, 0),
                    products: groupProducts,
                });
                index = index + 1;
            }
        }

        this.noSelectedItems.emit(this.selectedProducts.length <= 0 ? true : false);

        return productGroup;
    }
    public productFullfillmentCentre(storeId, groupProducts) {
        for (const fulfillmentCenter of this.fulfillmentCenters) {
            if (fulfillmentCenter.storeId === storeId) {
                groupProducts[0].supplierName = fulfillmentCenter.storeName;
            }
        }
        return groupProducts[0];
    }

    public get isAllProductsSelected() {
        return _every(this.products, (product) => product.isSelected || product.isGift);
    }

    public get selectedProducts() {
        if (this.configIsSelectableItemInCartDisabled) {
            return this.products || [];
        }
        return this.products ? this.products.filter((item: HotProductExtended) => item.isSelected || item.isGift) : [];
    }

    public get nonSelectedProducts() {
        if (this.configIsSelectableItemInCartDisabled) {
            return [];
        }
        return this.products.filter((item: HotProductExtended) => !item.isSelected);
    }

    public get allProductIds() {
        return this.products.reduce((data, item) => [...data, item.id], []);
    }

    public get selectedProductIds() {
        return this.selectedProducts.reduce((data, item) => [...data, item.id], []);
    }

    public get isShowItemSelection() {
        return (
            this.groupingBySupplier &&
            this.pageName === PageName.CARTSIDEBAR &&
            !this.configIsSelectableItemInCartDisabled
        );
    }

    constructor(
        private readonly store: Store<AppState>,
        private readonly analyticService: AnalyticService,
        private readonly cartService: CartService,
        private readonly appInsightsService: ApplicationInsightsService,
        private readonly orderTemplatesService: OrderTemplateService,
        public readonly router: Router,
        private readonly productService: ProductService,
        private readonly featuresService: FeaturesService,
        private readonly configurationService: ConfigurationService,
        private readonly modalService: ModalService,
        private readonly warehouseService: WarehouseService,
        private readonly itemlocationService: ItemLocationService
    ) {
        this.hotSettings$ = this.store.pipe(select(settingsData));
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.user$ = this.store.pipe(select(authUser));
        this.settings$ = this.store.pipe(select(settingsData));
        this.cartItems$ = this.store.pipe(select(cartItems));
    }

    public ngOnInit(): void {
        if (
            this.pageName === 'DistributorNewOrderModal' ||
            this.pageName === 'DistributorConfirmedOrderModal' ||
            this.pageName === PageName.ORDERMODAL
        ) {
            const nonGiftProducts: any[] = this.products.filter((product: any) => !product.isGift);
            const giftProducts: any[] = this.products.filter((product: any) => product.isGift);
            this.products = nonGiftProducts.concat(giftProducts);
        }

        this.productService.removedProduct.subscribe((product: HotProductExtended) => {
            if (this.pageName === PageName.ORDERTEMPLATES || this.pageName === PageName.ORDERSUGGESTIONS) this.removeFromCartHandler(product, product);
        });
        if(this.isVNB2b()){ this.hotSettings$.subscribe((settings:HotSettingsExtended) => {
                this.ShowDistributorOrderEditOption = settings.showDistributorOrderEditOption;
                this.user$.subscribe((userData: HotUserExtended) => {
                    const findRestricStoreId: boolean = settings.pilotDistributors.includes(userData.storeId);
                    this.restricStoreId = !findRestricStoreId;
                })
            })
        }
 
        this.settings$.pipe(take(1)).subscribe((setting: HotSettingsExtended) => {
            this.settingShowDistributorNameOnMultiSupplierSections = setting.showDistributorNameOnMultiSupplierSections;
            this.featureEnableCHQIntegration = setting.features.find((feature) => feature.name === "EnableCHQIntegration").isActive;

        });

        this.store

            .pipe(select(warehouses), takeUntil(this.subscriptionsDestroy$))

            .subscribe((fulfillmentCenters: HotFulfillmentCenter[]) => {
                this.fulfillmentCenters = fulfillmentCenters;
            });

       
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }

    public showSeparateOrder(): boolean {
        return (
            this.router.url.includes('/checkout') &&
            this.useSeparateByAlcoType &&
            this.products?.some((product) => product.isAlcoholic) &&
            this.products?.some((product) => !product.isAlcoholic)
        );
    }

    public getAlcoProducts(): any {
        return this.products.filter((product) => product.isAlcoholic);
    }

    public getNonAlcoProducts(): any {
        return this.products.filter((product) => !product.isAlcoholic);
    }

    public getProductCart(productId: string, storeId: string) {
        return _find(this.cartItems, (item) => item.productId === productId && item.storeId === storeId);
    }

    public getFulfillmentByStoreId(storeId: string): HotFulfillmentCenter {
        return _find(this.fulfillmentCenters, (item) => item.storeId === storeId);
    }

    public checkProductIsValidForPromotion(currentFFCStoreId: string, storeIds?: string[]) {
        if (!storeIds || !currentFFCStoreId) {
            return false;
        }

        return _some(storeIds, (item) => item === currentFFCStoreId);
    }

    public checkIsValidPromotionStore(storeIds: string[], product: HotProductExtended, action: string): boolean {
        const fulfillmentCenter = JSON.parse(
            localStorage.getItem(StorageKeys.fulfillmentCenter)
        ) as HotFulfillmentCenter;

        if (!this.checkProductIsValidForPromotion(fulfillmentCenter.storeId, storeIds)) {
            this.modalService.toggleModal(ModalIds.confirmationModelCommon, true, {
                title: 'shared.modals.switch-supplier.title',
                content: 'shared.modals.switch-supplier.content',
                confirmAction: () => {
                    const rightStoreID = storeIds[0];
                    const event = {
                        quantity: 1,
                    };
                    let targetProduct = product;
                    const cartProduct = this.getProductCart(product.id, rightStoreID) as HotProductExtended;

                    if (cartProduct) {
                        targetProduct = cartProduct;
                        event.quantity = targetProduct.quantity + 1;
                    } else {
                        targetProduct.storeId = rightStoreID;
                    }

                    localStorage.setItem(StorageKeys.redirectUrlFfc, '/promotions');
                    this.warehouseService.setWarehouse(this.getFulfillmentByStoreId(rightStoreID),"product-list");
                    this.warehouseService
                        .selectFulfillmentCenter()
                        .pipe(take(1))
                        .subscribe(() => {
                            if (action === 'addToCartHandler') {
                                this.addToCartHandler(event, targetProduct, true);
                            }

                            if (action === 'sendChangeQuantityHandler') {
                                this.sendChangeQuantityHandler(event, targetProduct, true);
                            }
                        });
                },
            });

            return false;
        } else {
            return true;
        }
    }

    public addToCartHandler(event: any, product: HotProductExtended, _byPassStoreChecking = false): void {
        const tempProduct = { ...product };
        tempProduct.quantity = event.quantity;
        tempProduct.addMethod = event.addMethod;

        if (tempProduct.unitCurrent) {
            tempProduct.id = event.productId;
            tempProduct.unitCurrent.quantity = event.quantity;
            tempProduct.addMethod = event.addMethod;
        }

        this.settingsAppOnLine$.pipe(take(1)).subscribe((online: boolean) => {
            if (online) {
                this.addToCart.emit(tempProduct);
            } else {
                // We don`t need to show isFeatured label in shopping cart
                tempProduct.isFeatured = false;
                this.store.dispatch(new CartOfflineAddItem(tempProduct));
                this.cartService.calculateTaxesOffline();
            }
        });

        this.orderTemplatesService.saveOrderTemplateSuccess(false);
    }

    public sendChangeQuantityHandler(event: any, product: HotProductExtended, byPassStoreChecking = false): void {
        if (this.featureSplitOrdersBySuppliersWhenCheckout && product?.storeIds?.length > 0 && !byPassStoreChecking) {
            if (!this.checkIsValidPromotionStore(product.storeIds, product, 'addToCartHandler')) {
                return;
            }
        }

        const tempProduct = { ...product };

        if (tempProduct.unitCurrent) {
            tempProduct.id = event.productId;
            tempProduct.addMethod = event.addMethod;
            tempProduct.unitCurrent.quantity = event.quantity;
        }

        if (this.isVanSalesMan) {
            tempProduct.deliveredQuantity = event.quantity;
        } else {
            tempProduct.quantity = event.quantity;
            tempProduct.addMethod = event.addMethod;
        }

        this.settingsAppOnLine$.pipe(take(1)).subscribe((online: boolean) => {
            if (online) {
                this.sendChangeQuantity.emit(tempProduct);
            } else {
                this.store.dispatch(new CartOfflineChangeItem(tempProduct));
                this.cartService.calculateTaxesOffline();
            }
        });

        this.orderTemplatesService.saveOrderTemplateSuccess(false);
    }

    public openModalHandler(product: HotProductExtended): void {
        if (this.openModal.observers.length) {
            this.analyticService.sendProductClick(product, "select Product PLP");
            this.analyticService.sendProductDetailInfo(product);
            this.appInsightsService.trackEvent(TelemetryEventType.ProductItemClick, {
                productId: product.id,
                sku: product.sku,
            });
            this.openModal.emit(product);
        }
    }

    public removeFromCartHandler(event: any, product: HotProductExtended): void {
        const tempProduct: HotProductExtended = { ...product };
        tempProduct.addMethod = event.addMethod;
        if (tempProduct.unitCurrent) {
            tempProduct.id = event.id;
            tempProduct.addMethod = event.addMethod;
        }

        this.settingsAppOnLine$.pipe(take(1)).subscribe((online: boolean) => {
            if (online) {
                this.removeFromCart.emit(tempProduct);
            } else {
                this.store.dispatch(new CartOfflineRemoveItem({ id: event.id }));
                this.cartService.calculateTaxesOffline();
            }
        });

        this.orderTemplatesService.saveOrderTemplateSuccess(false);
    }

    public selectCartItems(itemIds: string[]) {
        this.store.dispatch(
            new CartSelectItems({
                itemIds,
            })
        );
    }

    public onSelectCartItem(payload: { status: boolean; product: HotProductExtended }) {
        if (payload.status) {
            this.selectCartItems([...this.selectedProductIds, payload.product.id]);
        } else {
            this.selectCartItems(this.selectedProductIds.filter((id: string) => id !== payload.product.id));
        }
    }

    public onSelectAllProductsSupplier(status: boolean, supplierGroup: any) {
        const products = supplierGroup.products;
        const productIds = products.reduce((data, item) => [...data, item.id], []);

        if (status) {
            this.selectCartItems([...this.selectedProductIds, ...productIds]);
        } else {
            this.selectCartItems(this.selectedProductIds.filter((id: string) => productIds.indexOf(id) === -1));
        }
    }

    public onSelectAllProductsInCart(status: boolean) {
        if (status) {
            this.selectCartItems(this.allProductIds);
        } else {
            this.selectCartItems([]);
        }
    }

    public toggleSelectedProductsRemoveModal() {
        const nonSelectedProductsExcludeGift = this.nonSelectedProducts.filter((item) => !item.isGift);

        this.modalService.toggleModal(ModalIds.removeSelectedItemCartConfirmationModal, true, {
            selectedProducts: this.selectedProducts,
            cartItems: nonSelectedProductsExcludeGift.map((item) => ({
                productId: item.productId,
                quantity: item.quantity,
                storeId: item?.storeId,
                isSelected: item?.isSelected,
            })),
            pageName: this.pageName,
            isClear: this.isAllProductsSelected ? true : false,
        });
    }

    public trackProduct(_index: number, product: any) {
        return product ? product.id : null;
    }

    public trackGroup(_index: number, group: any) {
        return group ? group.storeId : null;
    }
}
