/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotOrderSearchResult } from '../models/hot-order-search-result';
import { HotOrderSearchCriteria } from '../models/hot-order-search-criteria';
import { HotOrder } from '../models/hot-order';
import { HotInformationBannerSearchResult } from '../models/hot-information-banner-search-result';
import { HotInformationBannerSearchCriteria } from '../models/hot-information-banner-search-criteria';
import { HotLowRatingReason } from '../models/hot-low-rating-reason';
import { RatingAndReason } from '../models/rating-and-reason';
import { HotOrderStatusReasonsWithLanguage } from '../models/hot-order-status-reasons-with-language';
import { HotOrderStatistics } from '../models/hot-order-statistics';
import { HotExtendedOrderStatistics } from '../models/hot-extended-order-statistics';
import { HotDeliveredOrdersStatistics } from '../models/hot-delivered-orders-statistics';
import { HotOperationResult } from '../models/hot-operation-result';
import { HotRejectOrderRequest } from '../models/hot-reject-order-request';
import { HotConfirmOrderDeliveryRequest } from '../models/hot-confirm-order-delivery-request';
import { HotOrderEditRequest } from '../models/hot-order-edit-request';
import { HotOrderConfirmDeliveryRequest } from '../models/hot-order-confirm-delivery-request';
import { HotCart } from '../models/hot-cart';
import { HotOrderTemplate } from '../models/hot-order-template';
import { HotProcessOrderPaymentResult } from '../models/hot-process-order-payment-result';
import { MassOrderUploadTemplate } from '../models/mass-order-upload-template';
import { MyOrdersDownloadFilesRequest } from '../models/my-orders-download-files-request';
import { HotOrderInvoicesResults } from '../models/hot-order-invoices-results';
import { HotInvoiceSearchCriteria } from '../models/hot-invoice-search-criteria';
import { NotificationSendResult } from '../models/notification-send-result';
import { OrderIssue } from '../models/order-issue';
@Injectable({
  providedIn: 'root',
})
class HotApiOrdersService extends __BaseService {
  static readonly searchOrdersPath = '/storefrontapi/hot/orders/search';
  static readonly getLatestOrdersPath = '/storefrontapi/hot/orders/latest';
  static readonly closeInformationBannerPath = '/storefrontapi/hot/orders/close-information-banner';
  static readonly getLatestOrdersByStatusPath = '/storefrontapi/hot/orders/failed-payment';
  static readonly getReasonPath = '/storefrontapi/hot/orders/low-rating-reasons';
  static readonly addRatingReasonPath = '/storefrontapi/hot/orders/adding-rating-reasons';
  static readonly getCustomerOrderRejectionReasonsPath = '/storefrontapi/hot/orders/customer-rejection-reasons';
  static readonly getOrderPartialDeliveryReasonsPath = '/storefrontapi/hot/orders/customer-partial-delivery-reasons';
  static readonly getOrdersStatisticsPath = '/storefrontapi/hot/orders/statistics';
  static readonly getExtendedOrderStatisticsPath = '/storefrontapi/hot/orders/statistics/extended';
  static readonly getDeliveredOrdersStatisticsPath = '/storefrontapi/hot/orders/statistics/delivered';
  static readonly getOrderByNumberPath = '/storefrontapi/hot/orders/{orderNumber}';
  static readonly deleteOrderPath = '/storefrontapi/hot/orders/{orderNumber}';
  static readonly getOrdersByOriginalNumberPath = '/storefrontapi/hot/orders/get-orders-by-original-number/{originalNumber}';
  static readonly searchOrdersByNumbersPath = '/storefrontapi/hot/orders/search-by-number';
  static readonly confirmOrderPath = '/storefrontapi/hot/orders/{orderId}/confirmed';
  static readonly cancelOrderPath = '/storefrontapi/hot/orders/{orderId}/canceled';
  static readonly rejectOrderByCustomerPath = '/storefrontapi/hot/orders/reject-order';
  static readonly confirmOrderDeliveryByCustomerPath = '/storefrontapi/hot/orders/confirm-delivery';
  static readonly editOrderPath = '/storefrontapi/hot/orders/{orderId}/edit';
  static readonly confirmOrderDeliveryPath = '/storefrontapi/hot/orders/{orderId}/confirmed-delivery';
  static readonly addItemsToCartPath = '/storefrontapi/hot/orders/{orderId}/cart';
  static readonly replaceItemsInCartPath = '/storefrontapi/hot/orders/{orderId}/cart';
  static readonly restoreCartFromDeletedOrderPath = '/storefrontapi/hot/orders/{orderNumber}/restore';
  static readonly saveOrderAsTemplatePath = '/storefrontapi/hot/orders/{orderId}/template';
  static readonly processOrderPaymentPath = '/storefrontapi/hot/orders/payments/process';
  static readonly processOrdersPaymentPath = '/storefrontapi/hot/orders/payment-process';
  static readonly cancelUnfinishedPaymentsPath = '/storefrontapi/hot/orders/payments/cancel-unfinished';
  static readonly exportOrderPath = '/storefrontapi/hot/orders/{orderId}/export/{format}';
  static readonly getTemplatePath = '/storefrontapi/hot/orders/mou-template';
  static readonly exportFilePath = '/storefrontapi/hot/orders/export/attachments';
  static readonly searchCustomerInvoicesPath = '/storefrontapi/hot/orders/get-user-invoices';
  static readonly reportOrderIssuePath = '/storefrontapi/hot/orders/report-order-issue';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchOrdersResponse(body?: HotOrderSearchCriteria): __Observable<__StrictHttpResponse<HotOrderSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchOrders(body?: HotOrderSearchCriteria): __Observable<HotOrderSearchResult> {
    return this.searchOrdersResponse(body).pipe(
      __map(_r => _r.body as HotOrderSearchResult)
    );
  }

  /**
   * @param params The `HotApiOrdersService.GetLatestOrdersParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `pageSize`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getLatestOrdersResponse(params: HotApiOrdersService.GetLatestOrdersParams): __Observable<__StrictHttpResponse<Array<HotOrder>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/orders/latest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotOrder>>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.GetLatestOrdersParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `pageSize`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getLatestOrders(params: HotApiOrdersService.GetLatestOrdersParams): __Observable<Array<HotOrder>> {
    return this.getLatestOrdersResponse(params).pipe(
      __map(_r => _r.body as Array<HotOrder>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  closeInformationBannerResponse(body?: HotInformationBannerSearchCriteria): __Observable<__StrictHttpResponse<HotInformationBannerSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/close-information-banner`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotInformationBannerSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  closeInformationBanner(body?: HotInformationBannerSearchCriteria): __Observable<HotInformationBannerSearchResult> {
    return this.closeInformationBannerResponse(body).pipe(
      __map(_r => _r.body as HotInformationBannerSearchResult)
    );
  }

  /**
   * @param pageSize undefined
   * @return Success
   */
  getLatestOrdersByStatusResponse(pageSize?: number): __Observable<__StrictHttpResponse<Array<HotOrder>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pageSize != null) __params = __params.set('pageSize', pageSize.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/orders/failed-payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotOrder>>;
      })
    );
  }
  /**
   * @param pageSize undefined
   * @return Success
   */
  getLatestOrdersByStatus(pageSize?: number): __Observable<Array<HotOrder>> {
    return this.getLatestOrdersByStatusResponse(pageSize).pipe(
      __map(_r => _r.body as Array<HotOrder>)
    );
  }

  /**
   * @return Success
   */
  getReasonResponse(): __Observable<__StrictHttpResponse<Array<HotLowRatingReason>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/orders/low-rating-reasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotLowRatingReason>>;
      })
    );
  }
  /**
   * @return Success
   */
  getReason(): __Observable<Array<HotLowRatingReason>> {
    return this.getReasonResponse().pipe(
      __map(_r => _r.body as Array<HotLowRatingReason>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  addRatingReasonResponse(body?: RatingAndReason): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/adding-rating-reasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  addRatingReason(body?: RatingAndReason): __Observable<boolean> {
    return this.addRatingReasonResponse(body).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  getCustomerOrderRejectionReasonsResponse(): __Observable<__StrictHttpResponse<Array<HotOrderStatusReasonsWithLanguage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/orders/customer-rejection-reasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotOrderStatusReasonsWithLanguage>>;
      })
    );
  }
  /**
   * @return Success
   */
  getCustomerOrderRejectionReasons(): __Observable<Array<HotOrderStatusReasonsWithLanguage>> {
    return this.getCustomerOrderRejectionReasonsResponse().pipe(
      __map(_r => _r.body as Array<HotOrderStatusReasonsWithLanguage>)
    );
  }

  /**
   * @return Success
   */
  getOrderPartialDeliveryReasonsResponse(): __Observable<__StrictHttpResponse<Array<HotOrderStatusReasonsWithLanguage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/orders/customer-partial-delivery-reasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotOrderStatusReasonsWithLanguage>>;
      })
    );
  }
  /**
   * @return Success
   */
  getOrderPartialDeliveryReasons(): __Observable<Array<HotOrderStatusReasonsWithLanguage>> {
    return this.getOrderPartialDeliveryReasonsResponse().pipe(
      __map(_r => _r.body as Array<HotOrderStatusReasonsWithLanguage>)
    );
  }

  /**
   * @param params The `HotApiOrdersService.GetOrdersStatisticsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getOrdersStatisticsResponse(params: HotApiOrdersService.GetOrdersStatisticsParams): __Observable<__StrictHttpResponse<HotOrderStatistics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/orders/statistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderStatistics>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.GetOrdersStatisticsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getOrdersStatistics(params: HotApiOrdersService.GetOrdersStatisticsParams): __Observable<HotOrderStatistics> {
    return this.getOrdersStatisticsResponse(params).pipe(
      __map(_r => _r.body as HotOrderStatistics)
    );
  }

  /**
   * @param params The `HotApiOrdersService.GetExtendedOrderStatisticsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getExtendedOrderStatisticsResponse(params: HotApiOrdersService.GetExtendedOrderStatisticsParams): __Observable<__StrictHttpResponse<HotExtendedOrderStatistics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/orders/statistics/extended`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotExtendedOrderStatistics>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.GetExtendedOrderStatisticsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getExtendedOrderStatistics(params: HotApiOrdersService.GetExtendedOrderStatisticsParams): __Observable<HotExtendedOrderStatistics> {
    return this.getExtendedOrderStatisticsResponse(params).pipe(
      __map(_r => _r.body as HotExtendedOrderStatistics)
    );
  }

  /**
   * @param params The `HotApiOrdersService.GetDeliveredOrdersStatisticsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getDeliveredOrdersStatisticsResponse(params: HotApiOrdersService.GetDeliveredOrdersStatisticsParams): __Observable<__StrictHttpResponse<HotDeliveredOrdersStatistics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/orders/statistics/delivered`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotDeliveredOrdersStatistics>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.GetDeliveredOrdersStatisticsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getDeliveredOrdersStatistics(params: HotApiOrdersService.GetDeliveredOrdersStatisticsParams): __Observable<HotDeliveredOrdersStatistics> {
    return this.getDeliveredOrdersStatisticsResponse(params).pipe(
      __map(_r => _r.body as HotDeliveredOrdersStatistics)
    );
  }

  /**
   * @param params The `HotApiOrdersService.GetOrderByNumberParams` containing the following parameters:
   *
   * - `orderNumber`:
   *
   * - `languageCode`:
   *
   * @return Success
   */
  getOrderByNumberResponse(params: HotApiOrdersService.GetOrderByNumberParams): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.languageCode != null) __params = __params.set('languageCode', params.languageCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/orders/${encodeURIComponent(String(params.orderNumber))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.GetOrderByNumberParams` containing the following parameters:
   *
   * - `orderNumber`:
   *
   * - `languageCode`:
   *
   * @return Success
   */
  getOrderByNumber(params: HotApiOrdersService.GetOrderByNumberParams): __Observable<HotOrder> {
    return this.getOrderByNumberResponse(params).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param orderNumber undefined
   */
  deleteOrderResponse(orderNumber: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/storefrontapi/hot/orders/${encodeURIComponent(String(orderNumber))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param orderNumber undefined
   */
  deleteOrder(orderNumber: string): __Observable<null> {
    return this.deleteOrderResponse(orderNumber).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param originalNumber undefined
   * @return Success
   */
  getOrdersByOriginalNumberResponse(originalNumber: string): __Observable<__StrictHttpResponse<Array<HotOrder>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/orders/get-orders-by-original-number/${encodeURIComponent(String(originalNumber))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotOrder>>;
      })
    );
  }
  /**
   * @param originalNumber undefined
   * @return Success
   */
  getOrdersByOriginalNumber(originalNumber: string): __Observable<Array<HotOrder>> {
    return this.getOrdersByOriginalNumberResponse(originalNumber).pipe(
      __map(_r => _r.body as Array<HotOrder>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchOrdersByNumbersResponse(body?: Array<string>): __Observable<__StrictHttpResponse<Array<HotOrder>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/search-by-number`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotOrder>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchOrdersByNumbers(body?: Array<string>): __Observable<Array<HotOrder>> {
    return this.searchOrdersByNumbersResponse(body).pipe(
      __map(_r => _r.body as Array<HotOrder>)
    );
  }

  /**
   * @param orderId undefined
   * @return Success
   */
  confirmOrderResponse(orderId: string): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/orders/${encodeURIComponent(String(orderId))}/confirmed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param orderId undefined
   * @return Success
   */
  confirmOrder(orderId: string): __Observable<HotOperationResult> {
    return this.confirmOrderResponse(orderId).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }

  /**
   * @param params The `HotApiOrdersService.CancelOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `cancelReason`:
   *
   * @return Success
   */
  cancelOrderResponse(params: HotApiOrdersService.CancelOrderParams): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.cancelReason != null) __params = __params.set('cancelReason', params.cancelReason.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/orders/${encodeURIComponent(String(params.orderId))}/canceled`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.CancelOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `cancelReason`:
   *
   * @return Success
   */
  cancelOrder(params: HotApiOrdersService.CancelOrderParams): __Observable<HotOperationResult> {
    return this.cancelOrderResponse(params).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  rejectOrderByCustomerResponse(body?: HotRejectOrderRequest): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/orders/reject-order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  rejectOrderByCustomer(body?: HotRejectOrderRequest): __Observable<HotOperationResult> {
    return this.rejectOrderByCustomerResponse(body).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  confirmOrderDeliveryByCustomerResponse(body?: HotConfirmOrderDeliveryRequest): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/orders/confirm-delivery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  confirmOrderDeliveryByCustomer(body?: HotConfirmOrderDeliveryRequest): __Observable<HotOperationResult> {
    return this.confirmOrderDeliveryByCustomerResponse(body).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }

  /**
   * @param params The `HotApiOrdersService.EditOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  editOrderResponse(params: HotApiOrdersService.EditOrderParams): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/orders/${encodeURIComponent(String(params.orderId))}/edit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.EditOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  editOrder(params: HotApiOrdersService.EditOrderParams): __Observable<HotOrder> {
    return this.editOrderResponse(params).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param params The `HotApiOrdersService.ConfirmOrderDeliveryParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  confirmOrderDeliveryResponse(params: HotApiOrdersService.ConfirmOrderDeliveryParams): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/orders/${encodeURIComponent(String(params.orderId))}/confirmed-delivery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.ConfirmOrderDeliveryParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  confirmOrderDelivery(params: HotApiOrdersService.ConfirmOrderDeliveryParams): __Observable<HotOperationResult> {
    return this.confirmOrderDeliveryResponse(params).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }

  /**
   * @param params The `HotApiOrdersService.AddItemsToCartParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `languageCode`:
   *
   * @return Success
   */
  addItemsToCartResponse(params: HotApiOrdersService.AddItemsToCartParams): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.languageCode != null) __params = __params.set('languageCode', params.languageCode.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/${encodeURIComponent(String(params.orderId))}/cart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.AddItemsToCartParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `languageCode`:
   *
   * @return Success
   */
  addItemsToCart(params: HotApiOrdersService.AddItemsToCartParams): __Observable<HotCart> {
    return this.addItemsToCartResponse(params).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param orderId undefined
   * @return Success
   */
  replaceItemsInCartResponse(orderId: string): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/orders/${encodeURIComponent(String(orderId))}/cart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param orderId undefined
   * @return Success
   */
  replaceItemsInCart(orderId: string): __Observable<HotCart> {
    return this.replaceItemsInCartResponse(orderId).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param orderNumber undefined
   * @return Success
   */
  restoreCartFromDeletedOrderResponse(orderNumber: string): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/orders/${encodeURIComponent(String(orderNumber))}/restore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param orderNumber undefined
   * @return Success
   */
  restoreCartFromDeletedOrder(orderNumber: string): __Observable<HotCart> {
    return this.restoreCartFromDeletedOrderResponse(orderNumber).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param params The `HotApiOrdersService.SaveOrderAsTemplateParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `name`:
   *
   * @return Success
   */
  saveOrderAsTemplateResponse(params: HotApiOrdersService.SaveOrderAsTemplateParams): __Observable<__StrictHttpResponse<HotOrderTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/${encodeURIComponent(String(params.orderId))}/template`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderTemplate>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.SaveOrderAsTemplateParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `name`:
   *
   * @return Success
   */
  saveOrderAsTemplate(params: HotApiOrdersService.SaveOrderAsTemplateParams): __Observable<HotOrderTemplate> {
    return this.saveOrderAsTemplateResponse(params).pipe(
      __map(_r => _r.body as HotOrderTemplate)
    );
  }

  /**
   * @param params The `HotApiOrdersService.ProcessOrderPaymentParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `selectedPaymentCode`:
   *
   * - `paymentGatewayName`:
   *
   * - `paymentGatewayId`:
   *
   * - `orderNumber`:
   *
   * - `msisdn`:
   *
   * - `amount`:
   *
   * @return Success
   */
  processOrderPaymentResponse(params: HotApiOrdersService.ProcessOrderPaymentParams): __Observable<__StrictHttpResponse<HotProcessOrderPaymentResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null) __params = __params.set('token', params.token.toString());
    if (params.selectedPaymentCode != null) __params = __params.set('selectedPaymentCode', params.selectedPaymentCode.toString());
    if (params.paymentGatewayName != null) __params = __params.set('paymentGatewayName', params.paymentGatewayName.toString());
    if (params.paymentGatewayId != null) __params = __params.set('paymentGatewayId', params.paymentGatewayId.toString());
    if (params.orderNumber != null) __params = __params.set('orderNumber', params.orderNumber.toString());
    if (params.msisdn != null) __params = __params.set('msisdn', params.msisdn.toString());
    if (params.amount != null) __params = __params.set('amount', params.amount.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/payments/process`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotProcessOrderPaymentResult>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.ProcessOrderPaymentParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `selectedPaymentCode`:
   *
   * - `paymentGatewayName`:
   *
   * - `paymentGatewayId`:
   *
   * - `orderNumber`:
   *
   * - `msisdn`:
   *
   * - `amount`:
   *
   * @return Success
   */
  processOrderPayment(params: HotApiOrdersService.ProcessOrderPaymentParams): __Observable<HotProcessOrderPaymentResult> {
    return this.processOrderPaymentResponse(params).pipe(
      __map(_r => _r.body as HotProcessOrderPaymentResult)
    );
  }

  /**
   * @return Success
   */
  processOrdersPaymentResponse(): __Observable<__StrictHttpResponse<HotProcessOrderPaymentResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/payment-process`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotProcessOrderPaymentResult>;
      })
    );
  }
  /**
   * @return Success
   */
  processOrdersPayment(): __Observable<HotProcessOrderPaymentResult> {
    return this.processOrdersPaymentResponse().pipe(
      __map(_r => _r.body as HotProcessOrderPaymentResult)
    );
  }

  /**
   * @param orderNumber undefined
   * @return Success
   */
  cancelUnfinishedPaymentsResponse(orderNumber?: string): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orderNumber != null) __params = __params.set('orderNumber', orderNumber.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/payments/cancel-unfinished`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param orderNumber undefined
   * @return Success
   */
  cancelUnfinishedPayments(orderNumber?: string): __Observable<HotOrder> {
    return this.cancelUnfinishedPaymentsResponse(orderNumber).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param params The `HotApiOrdersService.ExportOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `format`:
   */
  exportOrderResponse(params: HotApiOrdersService.ExportOrderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/${encodeURIComponent(String(params.orderId))}/export/${encodeURIComponent(String(params.format))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `HotApiOrdersService.ExportOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `format`:
   */
  exportOrder(params: HotApiOrdersService.ExportOrderParams): __Observable<null> {
    return this.exportOrderResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  getTemplateResponse(): __Observable<__StrictHttpResponse<MassOrderUploadTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/orders/mou-template`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MassOrderUploadTemplate>;
      })
    );
  }
  /**
   * @return Success
   */
  getTemplate(): __Observable<MassOrderUploadTemplate> {
    return this.getTemplateResponse().pipe(
      __map(_r => _r.body as MassOrderUploadTemplate)
    );
  }

  /**
   * @param body undefined
   */
  exportFileResponse(body?: Array<MyOrdersDownloadFilesRequest>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/export/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  exportFile(body?: Array<MyOrdersDownloadFilesRequest>): __Observable<null> {
    return this.exportFileResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchCustomerInvoicesResponse(body?: HotInvoiceSearchCriteria): __Observable<__StrictHttpResponse<HotOrderInvoicesResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/get-user-invoices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderInvoicesResults>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchCustomerInvoices(body?: HotInvoiceSearchCriteria): __Observable<HotOrderInvoicesResults> {
    return this.searchCustomerInvoicesResponse(body).pipe(
      __map(_r => _r.body as HotOrderInvoicesResults)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  reportOrderIssueResponse(body?: OrderIssue): __Observable<__StrictHttpResponse<NotificationSendResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/orders/report-order-issue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NotificationSendResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  reportOrderIssue(body?: OrderIssue): __Observable<NotificationSendResult> {
    return this.reportOrderIssueResponse(body).pipe(
      __map(_r => _r.body as NotificationSendResult)
    );
  }
}

module HotApiOrdersService {

  /**
   * Parameters for GetLatestOrders
   */
  export interface GetLatestOrdersParams {
    startDate?: string;
    pageSize?: number;
    endDate?: string;
  }

  /**
   * Parameters for GetOrdersStatistics
   */
  export interface GetOrdersStatisticsParams {
    startDate?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetExtendedOrderStatistics
   */
  export interface GetExtendedOrderStatisticsParams {
    startDate?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetDeliveredOrdersStatistics
   */
  export interface GetDeliveredOrdersStatisticsParams {
    startDate?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetOrderByNumber
   */
  export interface GetOrderByNumberParams {
    orderNumber: string;
    languageCode?: string;
  }

  /**
   * Parameters for CancelOrder
   */
  export interface CancelOrderParams {
    orderId: string;
    cancelReason?: string;
  }

  /**
   * Parameters for EditOrder
   */
  export interface EditOrderParams {
    orderId: string;
    body?: HotOrderEditRequest;
  }

  /**
   * Parameters for ConfirmOrderDelivery
   */
  export interface ConfirmOrderDeliveryParams {
    orderId: string;
    body?: HotOrderConfirmDeliveryRequest;
  }

  /**
   * Parameters for AddItemsToCart
   */
  export interface AddItemsToCartParams {
    orderId: string;
    languageCode?: string;
  }

  /**
   * Parameters for SaveOrderAsTemplate
   */
  export interface SaveOrderAsTemplateParams {
    orderId: string;
    name?: string;
  }

  /**
   * Parameters for ProcessOrderPayment
   */
  export interface ProcessOrderPaymentParams {
    token?: string;
    selectedPaymentCode?: string;
    paymentGatewayName?: string;
    paymentGatewayId?: string;
    orderNumber?: string;
    msisdn?: string;
    amount?: number;
  }

  /**
   * Parameters for ExportOrder
   */
  export interface ExportOrderParams {
    orderId: string;
    format: string;
  }
}

export { HotApiOrdersService }
