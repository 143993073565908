import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { HotProductSearchLightItem } from '@hot-theme-nx/generated-api';

import { CatalogService } from 'apps/hot-b2b/src/app/catalog/services/catalog.service';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import { ProductsGet } from '@hot-b2b/store/products/actions';
import { AppState } from '@hot-b2b/store/reducers';

@Component({
    selector: 'hot-search-distr',
    templateUrl: './search-distr.component.html',
})
export class SearchDistrComponent {
    public items: HotProductSearchLightItem[] = [];
    public itemsCount: number;
    public showAutocomplete = false;
    public showResult = false;

    @Input() public modifier: string;
    @Input() public model: string;
    @Input() public ffcId: string;

    @Output() public foundedItems = new EventEmitter<HotProductSearchLightItem[]>();
    @Output() public selectItem = new EventEmitter<HotProductSearchLightItem>();

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store<AppState>,
        private readonly catalogService: CatalogService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly featuresService: FeaturesService
    ) {}

    private checkSearch(): void {
        this.showResult = this.modifier === 'show-result';
        const searchQuery = this.route.snapshot.queryParams['search'];
        if (searchQuery) {
            this.model = searchQuery;
            this.getSearchResult(searchQuery);
        } else {
            this.model = '';
        }
    }

    public getItems(): void {
        this.showAutocomplete = false;
        if (this.model && this.model.length >= 1) {
            const ffcId = this.featuresService.StoreIsRequiredForOutlet ? null : this.ffcId;

            this.catalogService.lightProductSearch(this.model, ffcId).subscribe((data) => {
                this.changeDetectorRef.markForCheck();
                this.foundedItems.emit(data.items);
                this.items = data.items;
                if (this.items.length) {
                    this.itemsCount = data.totalCount;
                    this.showAutocomplete = true;
                }
            });
        }
    }

    public navigate(item: HotProductSearchLightItem): void {
        this.selectItem.emit(item);
        if (this.model.length > 0) {
            this.showAutocomplete = false;
            this.model = item.name;
        }
    }

    public onSubmit(): void {
        if (this.model.length > 0) {
            this.showAutocomplete = false;
        }
    }

    private getSearchResult(keyword: string): void {
        this.store.dispatch(new ProductsGet(this.catalogService.searchBrandProducts({ keyword })));
    }

    public clickOutside(): void {
        this.showAutocomplete = false;
    }
}
