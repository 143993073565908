import { StorageKeys } from '@hot-libs/shared-types';
import { SuggestedPromotion, SuggestedPromotionProduct } from '@hot-libs/shared-models';
import { HotPromotion, HotPromotionProduct, OrderSuggestion } from '@hot-theme-nx/generated-api';
import { HotCartLineItemExtended } from '../../shared/models';

const SUGGESTIONS_SEPARATOR = ';';
const MAX_SUGGESTIONS_COUNT = 200;

export const getActiveOrderSuggestion = (orderSuggestion: OrderSuggestion): OrderSuggestion => {
    if (!orderSuggestion.products.length || isWatchedSuggestion(orderSuggestion.id)) {
        return null;
    }

    saveViewedOrderSuggestion(orderSuggestion);

    return orderSuggestion;
};

function saveViewedOrderSuggestion(suggestion: OrderSuggestion): void {
    const viewedSuggestions = getWatchedOrderSuggestionIds();

    if (!viewedSuggestions.some((id) => id === suggestion.id)) {
        viewedSuggestions.push(suggestion.id);
    }

    if (viewedSuggestions.length > MAX_SUGGESTIONS_COUNT) {
        viewedSuggestions.splice(0, 1);
    }

    localStorage.setItem(StorageKeys.orderSuggestions, viewedSuggestions.join(SUGGESTIONS_SEPARATOR));
}

function isWatchedSuggestion(suggestionId: string): boolean {
    const viewedSuggestions = getWatchedOrderSuggestionIds();

    return viewedSuggestions.some((id) => id === suggestionId);
}

function getWatchedOrderSuggestionIds(): string[] {
    const viewedSuggestionsString = localStorage.getItem(StorageKeys.orderSuggestions);

    if (!viewedSuggestionsString) {
        return [];
    }

    return viewedSuggestionsString.split(SUGGESTIONS_SEPARATOR);
}

export const getSuggestedPromotions = (
    promotions: HotPromotion[],
    cartItems: HotCartLineItemExtended[]
): SuggestedPromotion[] => {
    return (
        promotions?.map((promotion: HotPromotion) => getSuggestionForPromotion(promotion, cartItems)).filter(Boolean) ||
        []
    );
};

const getSuggestionForPromotion = (
    promotion: HotPromotion,
    lineItems: HotCartLineItemExtended[]
): SuggestedPromotion => {
    // Promotion is considered applicable if it contains products which:
    // - are added to the cart;
    // - are not gift items;
    // - their quantity in the cart is not divisible by the quantity required by the promotion;
    // - their quantity required by the promotion is not equal to 0 (this is a case for bundle promotions);
    // - correspond to a gift reward (i.e. have rewardProductId and rewardProductQuantity and
    //   do not have rewardDiscountAmount).

    let result: SuggestedPromotion = null;

    const suggestedProducts =
        promotion?.products
            .filter((promotionProduct: HotPromotionProduct) => promotionProductCanBeSuggested(promotionProduct))
            .map((promotionProduct: HotPromotionProduct) => {
                let suggestedProduct: SuggestedPromotionProduct = null;

                const lineItem = lineItems.find(
                    (item: HotCartLineItemExtended) =>
                        !item.isGift &&
                        item.productId === promotionProduct.productId &&
                        item.quantity % promotionProduct.quantity !== 0
                );

                if (lineItem) {
                    suggestedProduct = {
                        product: promotionProduct,
                        suggestedQuantity: promotionProduct.quantity - (lineItem.quantity % promotionProduct.quantity),
                    };
                }

                return suggestedProduct;
            })
            .filter((product: SuggestedPromotionProduct) => !!product) || [];

    // We need this condition to use promotion where only 1 product and 1 suggested product
    if (suggestedProducts.length === 1 && promotion.products.length === 1) {
        result = {
            promotion,
            products: suggestedProducts,
        };
    }

    return result;
};

const promotionProductCanBeSuggested = (promotionProduct: HotPromotionProduct): boolean => {
    return (
        promotionProduct.quantity !== 0 &&
        promotionProduct.rewardProductId &&
        promotionProduct.rewardProductQuantity &&
        !promotionProduct.rewardDiscountAmount
    );
};
