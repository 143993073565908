import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, take, withLatestFrom } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { OrderService } from 'apps/hot-b2b/src/app/order/services';
import {
    AlertService,
    ConfigurationService,
    ModalService,
    OrderDetailsService,
} from 'apps/hot-b2b/src/app/shared/services';
import { HotOperationResult, HotOrderStatusReasonsWithLanguage } from '@hot-theme-nx/generated-api';
import { Observable } from 'rxjs';
import { HotOrderExtended } from '@hot-libs/shared-models';
import { select, Store } from '@ngrx/store';
import { AppState } from '@hot-b2b/store/reducers';
import { getCustomerOrderRejectReasons, orders } from '@hot-b2b/store/orders/selector';
import { Router } from '@angular/router';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { ModalIds, StorageKeys } from '@hot-libs/shared-types';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { HotOrderStatusReason } from '@hot-theme-nx/generated-api';

@Component({
    selector: 'hot-order-cancel-modal',
    templateUrl: './order-cancel-modal.component.html',
})
export class OrderCancelModalComponent implements OnInit {
    @Input() public orderId: string;
    @Input() public orderNumber: number;
    @Input() public cancelReason: HotOrderStatusReasonsWithLanguage[];
    @Output() public toggleModal: EventEmitter<any> = new EventEmitter();
    @Output() public callback: EventEmitter<any> = new EventEmitter();
    public isUpLg = () => this.screenDimensionService.upLg();

    public selectedReason = '';
    public otherText = '';
    public otherLabel: string;
    public CancellationCode: string;
    public otherReasonCancellationCode: string;
    public settings$: Observable<HotSettingsExtended>;
    public featureEnableCHQIntegration: boolean;
    public cancelReasonsForCurrentLanguage: HotOrderStatusReasonsWithLanguage;
    public cancelReasonsData: any;
    public cancelReasons: HotOrderStatusReason[];
    public scrollNeeded: boolean = false;
    public orders$: Observable<HotOrderExtended[]>;
    public isHUb2b = () => this.configurationService.getCurrentConfiguration() === 'HU';
    public isMY = () => this.configurationService.getCurrentConfiguration() === 'MY';
    public isVn = () => this.configurationService.getCurrentConfiguration() === 'VN';
    public currentLanguageCode: string = localStorage.getItem(StorageKeys.currentLanguage);

    constructor(
        private readonly orderService: OrderService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly orderDetailsService: OrderDetailsService,
        private readonly modalService: ModalService,
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly configurationService: ConfigurationService,
        public readonly screenDimensionService: ScreenDimensionService
    ) {
        this.orders$ = this.store.pipe(select(orders));
        this.settings$ = this.store.pipe(select(settingsData));
    }

    public ngOnInit(): void {
        this.otherLabel = this.translateService.instant('shared.modals.order-cancel.reasons.other');
        this.otherReasonCancellationCode = this.translateService.instant('shared.modals.order-cancel.reasonCodeOthers');
        this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
            this.featureEnableCHQIntegration = settings.features.find(
                (feature) => feature.name === 'EnableCHQIntegration'
            ).isActive;
        });
        if (this.featureEnableCHQIntegration) {
            this.scrollNeeded = true;
            this.orderCancelReasonVN();
        } else {
            this.cancelReasonsData = [
                this.translateService.instant('shared.modals.order-cancel.reasons.change-of-mind'),
                this.translateService.instant('shared.modals.order-cancel.reasons.mistakenly-ordered'),
                this.translateService.instant('shared.modals.order-cancel.reasons.wrong-address'),
                this.translateService.instant('shared.modals.order-cancel.reasons.other'),
            ];
        }
    }

    public orderCancelReasonVN() {
        let item$ = this.store.pipe(
            select(getCustomerOrderRejectReasons),
            map((items) =>
                items.filter((item) => item.language === this.currentLanguageCode).length
                    ? items.filter((item) => item.language === this.currentLanguageCode)
                    : items
            )
        );
        item$.pipe(take(1)).subscribe((rejectReasons) => {
            let orderStatusReasons = rejectReasons[0]?.orderStatusReasons;
            this.cancelReasons = orderStatusReasons;
            this.cancelReasonsData = orderStatusReasons?.map((reasons) => reasons.text);
        });
    }

    public selectReason(reason: string): void {
        this.selectedReason = reason;
    }

    public cancelOrder(): void {
        let isUserSelectedOtherReason = this.selectedReason === this.otherLabel;
        let reason = isUserSelectedOtherReason ? this.otherText : this.selectedReason;
        if (!reason) {
            return;
        }
        if (reason.includes(',')) {
            reason = reason.split(',').join(' ');
        }
        if (this.featureEnableCHQIntegration) {
            let otherResonforCancalation = ',' + this.otherReasonCancellationCode;
            let cancelationCode = ",".concat(this.getCancellationCode(reason));
            let reasonValue = isUserSelectedOtherReason ? otherResonforCancalation : cancelationCode;
            reason += reasonValue;
        }
        this.orderService
            .cancelOrder(this.orderId, reason)
            .pipe(take(1), withLatestFrom(this.orders$))
            .subscribe({
                next: ([operationResult, orderItems]: [HotOperationResult, HotOrderExtended[]]) => {
                    if (operationResult.succeeded) {
                        this.callback.emit(reason);
                        this.closeModal();
                        const orderNo = this.orderNumber;
                        const route = orderNo ? `/orders/${orderNo}` : `/orders/`;
                        this.router.navigate([route])
                    } else {
                        const currentOrder = orderItems.find((order) => order.id === this.orderId);
                        this.orderService.reportAnError(operationResult.errors);
                        this.orderDetailsService.closeOrderDetailsWithUpdateOrder(currentOrder);
                        this.closeModal();
                    }
                    this.closeModal();
                    if (this.isMY() || this.isVn()) {
                        this.navigationMethod();
                    }
                },
                error: (error: any) => {
                    this.closeModal();
                    this.alertService.error(error);
                }
            });
    }

    private navigationMethod() {
        if (localStorage.getItem('cancelorderValueReasonPopup') === 'true') {
            this.modalService.toggleModal(ModalIds.order, false);
        } else {
            this.modalService.toggleModal(ModalIds.order, false);
            this.router.navigate([`/orders/`]);
        }
        localStorage.removeItem(StorageKeys.cancelorderValueReasonPopup);
    }

    public closeModal(): void {
        this.modalService.toggleModal('order-cancel-modal', false);
    }

    private getCancellationCode(reason: string): string{
        for(let cancelReason of this.cancelReasons) {
            if(cancelReason.text === reason) return cancelReason.code;
        }
        return "";
    }
}
