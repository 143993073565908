import { Action } from '@ngrx/store';

import { HotAccountSettings, HotLanguage } from '@hot-theme-nx/generated-api';

import { HotSettingsExtended, SettingsStoreSlots } from '@hot-b2b/store/settings/model';
import { ESettingsActions } from '@hot-b2b/store/settings/types';

export class SettingsGet implements Action {
    public readonly type = ESettingsActions.SETTINGS_PENDING;
}

export class SettingsGetSuccess implements Action {
    public readonly type = ESettingsActions.SETTINGS_FETCHED;
    constructor(public payload: HotSettingsExtended) {}
}

export class SettingsClear implements Action {
    public readonly type = ESettingsActions.SETTINGS_CLEAR;
}

export class SettingsError implements Action {
    public readonly type = ESettingsActions.SETTINGS_ERROR;
    constructor(public payload: string) {}
}

export class SettingsLanguagesGet implements Action {
    public readonly type = ESettingsActions.SETTINGS_LANGUAGE_PENDING;
}

export class SettingsLanguagesGetSuccess implements Action {
    public readonly type = ESettingsActions.SETTINGS_LANGUAGE_FETCHED;
    constructor(public payload: HotAccountSettings) {}
}

export class SettingsLanguagesChange implements Action {
    public readonly type = ESettingsActions.SETTINGS_LANGUAGE_CHANGE;
    constructor(public payload: HotLanguage) {}
}

export class SettingsUserLanguagesClear implements Action {
    public readonly type = ESettingsActions.SETTINGS_USER_LANGUAGE_CLEAR;
}

export class SettingsStoreGet implements Action {
    public readonly type = ESettingsActions.SETTINGS_STORE_PENDING;
}

export class SettingsStoreGetSuccess implements Action {
    public readonly type = ESettingsActions.SETTINGS_STORE_FETCHED;
    constructor(public payload: SettingsStoreSlots[]) {}
}

export class SettingsStoreGetError implements Action {
    public readonly type = ESettingsActions.SETTINGS_STORE_ERROR;
    constructor(public payload: string) {}
}

export class SettingsUpdate implements Action {
    public readonly type = ESettingsActions.SETTINGS_UPDATE;
    constructor(public payload: any) {}
}

export class SettingsToggleOnline implements Action {
    public readonly type = ESettingsActions.SETTINGS_TOGGLE_ONLINE;
    constructor(public payload: boolean) {}
}

export class SettingsChangeFFCID implements Action {
    public readonly type = ESettingsActions.SETTINGS_CHANGE_FFF_ID;
    constructor(public payload: string) {}
}

export type SettingsActions =
    | SettingsGet
    | SettingsGetSuccess
    | SettingsClear
    | SettingsError
    | SettingsLanguagesGet
    | SettingsLanguagesGetSuccess
    | SettingsLanguagesChange
    | SettingsUserLanguagesClear
    | SettingsStoreGet
    | SettingsStoreGetSuccess
    | SettingsUpdate
    | SettingsToggleOnline
    | SettingsChangeFFCID;
