import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { HotApiSettingsService } from '@hot-theme-nx/generated-api';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';

import { select, Store } from '@ngrx/store';
import { AppState } from '@hot-b2b/store/reducers';
import { routerCurrentUrl } from '@hot-b2b/store/router/selector';
import ngswconfig from '../../../ngsw-config.json';


@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(
        private readonly apiSettingsService: HotApiSettingsService,
        private readonly store: Store<AppState>
    ) {
        this.routerCurrentUrl$ = this.store.pipe(select(routerCurrentUrl));
    }

    private routerCurrentUrl$: Observable<string>;
    private offlineAllowedUrls: string[] = [
        '/warehouses',
        '/brands',
        '/orders',
        '/promotions',
        '/trends',
        '/profile',
        '/cart',
        '/checkout',
        '/distributor'
    ];

    public isUrlAllowedForOfflineMode(): Observable<boolean> {
        return this.routerCurrentUrl$.pipe(
            take(1),
            map((url: string) => {
                const isAllowed: boolean = this.offlineAllowedUrls.some(
                    (allowedUrl: string) => url.indexOf(allowedUrl) >= 0
                );
                return url === '/' || isAllowed;
            })
        );
    }

    public getSettings(): Observable<HotSettingsExtended> {
        return this.apiSettingsService.getSettings().pipe(map((response) => response as HotSettingsExtended));
    }

    public getStoreSettings(): Observable<SettingsStoreModel> {
        return this.apiSettingsService.getStoreSettings().pipe(map((response) => response as SettingsStoreModel));
    }

    public getStoreSettingsWithFFC(fulfillmentCenterId: string): Observable<SettingsStoreModel> {
        return this.apiSettingsService
            .getStoreSettings(fulfillmentCenterId)
            .pipe(map((response) => response as SettingsStoreModel));
    }

    clearSettingCache(): Promise<Promise<boolean>[][]> {
        const dataGroupNames = ngswconfig.dataGroups
            .filter((a) => a.name === 'performance' || a.name === 'performance2' || a.name === 'performance2d' || a.name === 'performance3' || a.name === 'performance8h')
            .map((a) => a.name);
        return caches.keys().then((cacheKeys) =>
            Promise.all(
                cacheKeys.map((cacheKey) => {
                    if (dataGroupNames.some((v) => cacheKey.includes(v))) {
                        return caches
                            .open(cacheKey)
                            .then((cache) => cache.keys().then((requests) => requests.map((req) => cache.delete(req))));
                    }
                })
            )
        );
    }
}
