import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { AuthenticationService } from '../../account/services';

import { ModalToggle } from '@hot-b2b/store/modals/actions';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { AppState } from '@hot-b2b/store/reducers';
import { ModalIds } from '@hot-libs/shared-types';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private isNavigationCompleted = false;

    constructor(
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly store: Store<AppState>
    ) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd))
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.isNavigationCompleted = false;
                } else if (event instanceof NavigationEnd) {
                    this.isNavigationCompleted = true;
                }
            });
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 409) {
                    // If navigation completed and password expired show change password modal
                    // If page cannot be loaded by 409 error than logout
                    if (this.isNavigationCompleted) {
                        const modalState: ModalsState = { id: ModalIds.passwordResetModal, isOpened: true };
                        this.store.dispatch(new ModalToggle(modalState));
                    } else {
                        this.authenticationService.logout();
                    }
                }
                return throwError(err);
            })
        );
    }
}
