import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

import { HotLanguage, HotLegalAgreement, HotApiLegalAgreementsService } from '@hot-theme-nx/generated-api';

import { LegalAgreementClient } from 'apps/hot-b2b/src/app/shared/api-clients';
import { LegalAgreementSignalRService } from 'apps/hot-b2b/src/app/shared/signalr';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsLanguageCurrent } from '@hot-b2b/store/settings/selector';

@Injectable({
    providedIn: 'root',
})
export class LegalAgreementService {
    public readonly currentTermsAndConditions$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentPrivacyPolicy$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentCookiePolicy$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentCartDisclaimer$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentDisclaimer$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentOperationProcess$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentPurchaseContract$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentShipmentService$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentRefundPolicy$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentPaymentService$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentMaintainPolicy$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentLitigationProcess$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentAnnouncement$ = new BehaviorSubject<HotLegalAgreement>(null);
    public readonly currentSubscription$ = new BehaviorSubject<HotLegalAgreement>(null);

    private currentLanguageCode: string;
    private termsAndConditionSubscription: Subscription;
    private privacyPolicySubscription: Subscription;
    private readonly currentLanguage$: Observable<HotLanguage>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly legalAgreementClient: LegalAgreementClient,
        private readonly legalAgreementSignalRService: LegalAgreementSignalRService,
        private readonly legalAgreementsService: HotApiLegalAgreementsService
    ) {
        this.currentLanguage$ = this.store.pipe(select(settingsLanguageCurrent));
    }

    public subscribeToTermsAndConditions(): void {
        this.termsAndConditionSubscription = this.currentLanguage$
            .pipe(
                filter(Boolean),
                switchMap((language: HotLanguage) => {
                    if (this.currentLanguageCode !== language.code) {
                        if (this.currentLanguageCode) {
                            this.legalAgreementSignalRService.changeGroup(this.currentLanguageCode, language.code);
                        } else {
                            this.legalAgreementSignalRService.startConnection(language.code);
                        }

                        this.currentLanguageCode = language.code;
                    }

                    return merge(
                        this.legalAgreementClient.getTermsAndConditionsByLanguage(language.code),
                        this.legalAgreementSignalRService.registerTermsAndConditionsChangeEvent()
                    );
                })
            )
            .subscribe((legalAgreement: HotLegalAgreement) => this.currentTermsAndConditions$.next(legalAgreement));
    }

    public subscribeToPrivacyPolicy(): void {
        this.privacyPolicySubscription = this.currentLanguage$
            .pipe(
                filter(Boolean),
                switchMap((language: HotLanguage) => {
                    if (this.currentLanguageCode !== language.code) {
                        if (this.currentLanguageCode) {
                            this.legalAgreementSignalRService.changeGroup(this.currentLanguageCode, language.code);
                        } else {
                            this.legalAgreementSignalRService.startConnection(language.code);
                        }

                        this.currentLanguageCode = language.code;
                    }

                    return merge(
                        this.legalAgreementClient.getPrivacyPolicyByLanguage(language.code),
                        this.legalAgreementSignalRService.registerPrivacyPolicyChangeEvent()
                    );
                })
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentPrivacyPolicy$.next(legalAgreement);
            });
    }

    public unsubscribeFromTermsAndConditions(): void {
        this.currentLanguageCode = null;

        if (this.termsAndConditionSubscription) {
            this.termsAndConditionSubscription.unsubscribe();
        }

        this.legalAgreementSignalRService.stopConnection();
    }

    public unsubscribeFromPrivacyPolicy(): void {
        this.currentLanguageCode = null;

        if (this.privacyPolicySubscription) {
            this.privacyPolicySubscription.unsubscribe();
        }

        this.legalAgreementSignalRService.stopConnection();
    }

    public getTermsAndConditionsForGuest(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) =>
                    this.legalAgreementClient.getTermsAndConditionsByLanguage(language.code)
                )
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentTermsAndConditions$.next(legalAgreement);
            });
    }

    public getPrivacyPolicyForGuest(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementClient.getPrivacyPolicyByLanguage(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentPrivacyPolicy$.next(legalAgreement);
            });
    }

    public subscribeToCookiePolicy(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementClient.getCookiePolicyByLanguage(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentCookiePolicy$.next(legalAgreement);
            });
    }

    public getCartDisclaimer(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) =>
                    this.legalAgreementClient.getCartDisclaimerByLanguage(language.code)
                )
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentCartDisclaimer$.next(legalAgreement);
            });
    }

    public getDisclaimer(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementClient.getDisclaimerByLanguage(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentDisclaimer$.next(legalAgreement);
            });
    }

    public getOperationProcess(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementsService.getOperationProcess(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentOperationProcess$.next(legalAgreement);
            });
    }

    public getPurchaseContract(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementsService.getPurchaseContract(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentPurchaseContract$.next(legalAgreement);
            });
    }

    public getShipmentService(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementsService.getShipmentService(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentShipmentService$.next(legalAgreement);
            });
    }

    public getRefundPolicy(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementsService.getRefundPolicy(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentRefundPolicy$.next(legalAgreement);
            });
    }

    public getPaymentService(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementsService.getPaymentService(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentPaymentService$.next(legalAgreement);
            });
    }

    public getMaintainPolicy(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementsService.getMaintainPolicy(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentMaintainPolicy$.next(legalAgreement);
            });
    }

    public getLitigationProcess(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementsService.getLitigationProcess(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentLitigationProcess$.next(legalAgreement);
            });
    }

    public getAnnouncement(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementsService.getAnnouncement(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentAnnouncement$.next(legalAgreement);
            });
    }

    public getSubscription(): void {
        this.currentLanguage$
            .pipe(
                filter(Boolean),
                mergeMap((language: HotLanguage) => this.legalAgreementsService.getSubscription(language.code))
            )
            .subscribe((legalAgreement: HotLegalAgreement) => {
                this.currentSubscription$.next(legalAgreement);
            });
    }
}
