import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalsState } from '@hot-b2b/store/modals/model';

import { Breadcrumb } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';
import { BreadcrumbsService, FeaturesService, ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { take } from 'rxjs/operators';

@Component({
    selector: 'hot-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent implements OnInit {
    @Input() private label: string;
    @Input() public disabled: boolean;
    @Input() public additionalText: string;

    public breadcrumbs: Breadcrumb[] = [];

    constructor(
        private readonly breadcrumbsService: BreadcrumbsService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly featureService: FeaturesService
    ) { }

    public ngOnInit(): void {
        this.breadcrumbs = this.breadcrumbsService.buildBreadcrumbs(this.label);
    }

    public navigate(url: string): void {
        this.modalService.modalState$.pipe(take(1)).subscribe((modalState: ModalsState) => {
            if (modalState.id === ModalIds.order && modalState.isOpened) {
                this.modalService.toggleModal(ModalIds.order, false);
            }
        });
        if (url == null || url === '/') {
            if (this.featureService.DashboardHomePage) {
                url = '/ordering';
            }
        }
        this.router.navigate([url]);
    }
}
