import { ESettingsActions } from './types';
import { SettingsActions } from './actions';
import { settingsInitialState } from './state';
import { SettingsState } from './model';

export function settingsReducer(state: SettingsState = settingsInitialState, action: SettingsActions): SettingsState {
    switch (action.type) {
        case ESettingsActions.SETTINGS_PENDING: {
            return {
                ...state,
                fetched: false,
                pending: true,
            };
        }
        case ESettingsActions.SETTINGS_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    ...action.payload,
                    currentLanguage: null,
                },
            };
        }
        case ESettingsActions.SETTINGS_ERROR: {
            return {
                ...state,
                fetched: false,
                pending: false,
                error: action.payload,
            };
        }
        case ESettingsActions.SETTINGS_CLEAR: {
            return settingsInitialState;
        }
        case ESettingsActions.SETTINGS_LANGUAGE_PENDING: {
            return {
                ...state,
                data: {
                    ...state.data,
                    isLanguagesLoaded: false,
                },
            };
        }
        case ESettingsActions.SETTINGS_LANGUAGE_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    availableLanguages: action.payload.availableLanguages,
                    currentLanguage: action.payload.currentLanguage,
                    isLanguagesLoaded: true,
                    enableEmailOrderNotifications: action.payload.enableEmailOrderNotifications,
                    enableSmsOrderNotifications: action.payload.enableSmsOrderNotifications,
                },
            };
        }
        case ESettingsActions.SETTINGS_LANGUAGE_CHANGE: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    currentLanguage: action.payload,
                },
            };
        }
        case ESettingsActions.SETTINGS_USER_LANGUAGE_CLEAR: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    isLanguagesLoaded: false,
                },
            };
        }
        case ESettingsActions.SETTINGS_STORE_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    settingsStore: action.payload,
                },
            };
        }
        case ESettingsActions.SETTINGS_UPDATE: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        }
        case ESettingsActions.SETTINGS_TOGGLE_ONLINE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    online: action.payload,
                },
            };
        }
        case ESettingsActions.SETTINGS_CHANGE_FFF_ID: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    ffc: {
                        ...state.data.ffc,
                        id: action.payload,
                    },
                },
            };
        }
        default:
            return state;
    }
}
