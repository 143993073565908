import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, withLatestFrom } from 'rxjs/operators';

import { FeatureNames } from '@hot-libs/shared-types';
import { AppState } from '@hot-b2b/store/reducers';
import { FeaturesItem } from '@hot-b2b/store/settings/model';
import { settingsFeatures, settingsFetched } from '@hot-b2b/store/settings/selector';

@Injectable({
    providedIn: 'root',
})
export class FeaturesService {
    public AllowPreorder: boolean;
    public ApplyTierPriceModel: boolean;
    public BackInStockSubscription: boolean;
    public BackInStockSubscriptionWithQuantity: boolean;
    public BoostOrderCheckout: boolean;
    public BrandsCarouselClickable: boolean;
    public BrandsFilter: boolean;
    public BrandsPage: boolean;
    public CancelOrder: boolean;
    public ChangeEmailAddress: boolean;
    public ChangeEmailAddressVerificationEmail: boolean;
    public ChangePhoneNumber: boolean;
    public ChangePhoneNumberVerificationSms: boolean;
    public ConfirmDelivery: boolean;
    public ConfirmedOrdersPageForDistributor: boolean;
    public CookieWall: boolean;
    public Coupons: boolean;
    public CreateInvoiceOnDistributorPortal: boolean;
    public CreateOfflineOrder: boolean;
    public CustomerPermissions: boolean;
    public DeliveredOrdersStatisticsForCustomer: boolean;
    public DeliveredOrdersStatisticsForDistributor: boolean;
    public EditOrder: boolean;
    public EstimatedDeliveryDate: boolean;
    public GoogleAnalytics: boolean;
    public Hotjar: boolean;
    public Insights: boolean;
    public LoyaltyProgram: boolean;
    public LoyaltyProgramBalance: boolean;
    public LoyaltyProgramTiers: boolean;
    public ManageOrdersOnDistributorPortalByDistributor: boolean;
    public OpcoDistributorHandlesAnyBonusOrders: boolean;
    public OrdersGridView: boolean;
    public OrderPaymentsProcessing: boolean;
    public OrderTemplates: boolean;
    public OrderSuggestions: boolean;
    public OrganizationIsRequiredForOutlet: boolean;
    public OutletCreditLimit: boolean;
    public PerformancePage: boolean;
    public PromotionSuggestions: boolean;
    public Pwa: boolean;
    public Reorder: boolean;
    public RequireRewardTermsAndConditionsToBeAccepted: boolean;
    public SelectDeliveryDate: boolean;
    public EnableCustomerPickup: boolean;
    public SelectUnitOfMeasure: boolean;
    public ShipOrderByDistributor: boolean;
    public ShowAddress: boolean;
    public ShowDistributorLineItemExtendedPrice: boolean;
    public ShowDistributorLineItemSku: boolean;
    public ShowLineItemsQuantitySum: boolean;
    public ShowProductProperties: boolean;
    public ShowStockLevelOnLineItems: boolean;
    public ReportOrderIssue: boolean;
    public SmsResetPassword: boolean;
    public EmailResetPassword: boolean;
    public StockControl: boolean;
    public StoreIsRequiredForOutlet: boolean;
    public SummarizeInvoices: boolean;
    public VisualWebsiteOptimizer: boolean;
    public SmsActivateAccount: boolean;
    public EmailActivateAccount: boolean;
    public UsePackagesWithCartsAndOrders: boolean;
    public SplitAlcoholicAndNonAlcoholicProductsWhenCreatingOrder: boolean;
    public ReturnEmpties: boolean;
    public EmptiesExclusiveOrder: boolean;
    public OrdersPageForGroupAccount: boolean;
    public EnableOfflineOrder: boolean;
    public EnableChatBot: boolean;
    public EnableCHQIntegration: boolean;
    public EnableGPSTracking: boolean;
    public DashboardHomePage: boolean;
    public EnableRating: boolean;
    public AppDownloadPopup: boolean;
    public EnableMultiplePaymentProvider: boolean;
    public EnableLoginWithOTP: boolean;
    public DisplayofPerformancevsTarget: boolean;
    public PasswordExpiration: boolean;
    public UseCategoriesAsPrimaryCatalogFilter: boolean;
    public ShowDotTutorialButton: boolean;
    public RestrictOrderPaymentsbyChannel: boolean;
    public ProductActivationFlow: boolean;
    public EnableViewAllCoupons: boolean;
    public DisplayDriverDetails: boolean;
    public ShowPricingIndicatorInCart: boolean;
    public ShowPricingIndicatorInCartFilterable: boolean;
    public EmailAndUsernameToBeTheSameInCustomerSelfRegistration: boolean;
    public AddReasonsByDistributorWhenChangeItems: boolean;
    public RefreshCatalogsAfterCartChanges: boolean;
    public RefreshCartAfterExternalValidation: boolean;
    public ShowAgeConfirmation: boolean;
    public ShowReceiverPhoneNumberAtCheckOutPage: boolean;
    public AddProductsWithNoPrice: boolean;
    public DistributorPerformancePage: boolean;
    public AllowDistributorsToControlStoreSettings: boolean;
    public DisplayCarouselBannerInDistributorPortal: boolean;
    public ManageFavoriteProducts: boolean;
    public ValidateProductLimitOrderQuantity: boolean;
    public DisplayUnitPrice: boolean;
    public EnableBasketOverlay: boolean;
    public EnableOffInvoicePerformanceTracking: boolean;
    public ValidateMinimumBackOrderQuantity: boolean;
    public ValidateMinimumNonBackOrderQuantity: boolean;

    public AddReasonsForPartialDelivery: boolean;
    public BuildFulfillmentCentersUsingOutletStores: boolean;
    public SplitOrdersBySuppliersWhenCheckout: boolean;
    public ExternalPriceValidation: boolean;
    public ExternalCartValidation: boolean;
    public ShowPayNowAtReceivedStatus: boolean;
    public ShowCreditValidationWhenCheckout: boolean;
    public OrderSimulationPricesAndTaxes: boolean;
    public EnableProductRecommendation: boolean;
    public EnableProductRecommendationOnHomePage: boolean;
    public EazleEnabledOpco: boolean;
    public HighlightMostOrderedDistributors: boolean;
    private features: FeaturesItem[];

    private readonly settingsFeatures$: Observable<FeaturesItem[]>;
    public AllowDistributorsToCancelOrdersAfterConfirmation: boolean;
    public AllowDispatchingMultipleOrders: boolean;
    public AllowConfirmingMultipleOrdersForNewOrders: boolean;
    public EnableAdvancedFilters: boolean;
    public EnableAdvancedPriceAndSortingFilter: boolean;
    public AllowPriceManagementOnDistributorPortal: boolean;
    public EnableDiscountCalculationWhenPriceIsHidden: boolean;
    public ShowSdipStatistics: boolean;
    public ExtendedOrderDeliveryConfirmationFlow: boolean;
    public AddReasonsByCustomerWhenCancelOrder: boolean;
    public SkipDistributorSelectionAfterSelectedDistributor: boolean;
    public OrganizationLevelCreditLimitCheck: boolean;
    public ShowTlpStatistics: boolean;
    public ShowAndCalculateExpectedDeliveryTime: boolean;
    public EnableOpenInvoice: boolean;
    public ShowSalesRepPhoneNumber: boolean;
    public GotItIntegration: boolean;
    public ShowPromotionAllocations: boolean;
    public ShowPromotionHistory: boolean;
    public EnableAdvanceCheckoutFlow: boolean;
    public DisplaySingleUnitPriceBasedOnPackageSize: boolean;
    public EnableHomePageActiveBanner: boolean;

    constructor(private readonly store: Store<AppState>) {
        this.settingsFeatures$ = this.store.pipe(select(settingsFeatures));
        this.settingsFeatures$
            .pipe(first((features: FeaturesItem[]) => !!features?.length))
            .subscribe((features: FeaturesItem[]) => {
                this.features = features;
                this.initFeatures();
            });
    }

    public checkFeatures(name: string): Observable<boolean> {
        return this.store.pipe(select(settingsFetched)).pipe(
            filter(Boolean),
            withLatestFrom(this.settingsFeatures$),
            map(([, features]: [boolean, FeaturesItem[]]) => {
                return this.checkFeatureByName(name, features);
            })
        );
    }

    public checkFeaturesIsFilterable(name: string): Observable<boolean> {
        return this.store.pipe(select(settingsFetched)).pipe(
            filter(Boolean),
            withLatestFrom(this.settingsFeatures$),
            map(([, features]: [boolean, FeaturesItem[]]) => {
                return this.checkFeatureIsFilterableByName(name, features);
            })
        );
    }

    private checkFeatureByName(featureName: string, features?: FeaturesItem[]): boolean {
        const feature = (features || this.features).find(
            (f: FeaturesItem) => f.name.toLocaleLowerCase() === featureName.toLocaleLowerCase()
        );

        if (!feature) {
            console.error(`Feature with name: '${featureName}' not found`);
            return false;
        }

        return feature.isActive;
    }

    private checkFeatureIsFilterableByName(featureName: string, features?: FeaturesItem[]): boolean {
        const feature = (features || this.features).find(
            (f: FeaturesItem) => f.name.toLocaleLowerCase() === featureName.toLocaleLowerCase()
        );

        if (!feature) {
            console.error(`Feature with name: '${featureName}' not found`);
            return false;
        }

        return feature.isFilterable != undefined && feature.isFilterable;
    }

    private initFeatures(): void {
        this.AllowPreorder = this.checkFeatureByName(FeatureNames.AllowPreorder);
        this.ApplyTierPriceModel = this.checkFeatureByName(FeatureNames.ApplyTierPriceModel);
        this.BackInStockSubscription = this.checkFeatureByName(FeatureNames.BackInStockSubscription);
        this.BackInStockSubscriptionWithQuantity = this.checkFeatureByName(
            FeatureNames.BackInStockSubscriptionWithQuantity
        );
        this.BoostOrderCheckout = this.checkFeatureByName(FeatureNames.BoostOrderCheckout);
        this.BrandsCarouselClickable = this.checkFeatureByName(FeatureNames.BrandsCarouselClickable);
        this.BrandsFilter = this.checkFeatureByName(FeatureNames.BrandsFilter);
        this.BrandsPage = this.checkFeatureByName(FeatureNames.BrandsPage);
        this.CancelOrder = this.checkFeatureByName(FeatureNames.CancelOrder);
        this.ChangeEmailAddress = this.checkFeatureByName(FeatureNames.ChangeEmailAddress);
        this.ChangeEmailAddressVerificationEmail = this.checkFeatureByName(
            FeatureNames.ChangeEmailAddressVerificationEmail
        );
        this.ChangePhoneNumber = this.checkFeatureByName(FeatureNames.ChangePhoneNumber);
        this.ChangePhoneNumberVerificationSms = this.checkFeatureByName(FeatureNames.ChangePhoneNumberVerificationSms);
        this.ConfirmDelivery = this.checkFeatureByName(FeatureNames.ConfirmDelivery);
        this.ConfirmedOrdersPageForDistributor = this.checkFeatureByName(
            FeatureNames.ConfirmedOrdersPageForDistributor
        );
        this.CookieWall = this.checkFeatureByName(FeatureNames.CookieWall);
        this.Coupons = this.checkFeatureByName(FeatureNames.Coupons);
        this.CreateInvoiceOnDistributorPortal = this.checkFeatureByName(FeatureNames.CreateInvoiceOnDistributorPortal);
        this.CreateOfflineOrder = this.checkFeatureByName(FeatureNames.CreateOfflineOrder);
        this.CustomerPermissions = this.checkFeatureByName(FeatureNames.CustomerPermissions);
        this.DeliveredOrdersStatisticsForCustomer = this.checkFeatureByName(
            FeatureNames.DeliveredOrdersStatisticsForCustomer
        );
        this.DeliveredOrdersStatisticsForDistributor = this.checkFeatureByName(
            FeatureNames.DeliveredOrdersStatisticsForDistributor
        );
        this.EditOrder = this.checkFeatureByName(FeatureNames.EditOrder);
        this.EstimatedDeliveryDate = this.checkFeatureByName(FeatureNames.EstimatedDeliveryDate);
        this.GoogleAnalytics = this.checkFeatureByName(FeatureNames.GoogleAnalytics);
        this.Hotjar = this.checkFeatureByName(FeatureNames.Hotjar);
        this.Insights = this.checkFeatureByName(FeatureNames.Insights);
        this.LoyaltyProgram = this.checkFeatureByName(FeatureNames.LoyaltyProgram);
        this.LoyaltyProgramBalance = this.checkFeatureByName(FeatureNames.LoyaltyProgramBalance);
        this.LoyaltyProgramTiers = this.checkFeatureByName(FeatureNames.LoyaltyProgramTiers);
        this.ManageOrdersOnDistributorPortalByDistributor = this.checkFeatureByName(
            FeatureNames.ManageOrdersOnDistributorPortalByDistributor
        );
        this.OpcoDistributorHandlesAnyBonusOrders = this.checkFeatureByName(
            FeatureNames.OpcoDistributorHandlesAnyBonusOrders
        );
        this.OrdersGridView = this.checkFeatureByName(FeatureNames.OrdersGridView);
        this.OrderPaymentsProcessing = this.checkFeatureByName(FeatureNames.OrderPaymentsProcessing);
        this.OrderTemplates = this.checkFeatureByName(FeatureNames.OrderTemplates);
        this.OrderSuggestions = this.checkFeatureByName(FeatureNames.OrderSuggestions);
        this.OrganizationIsRequiredForOutlet = this.checkFeatureByName(FeatureNames.OrganizationIsRequiredForOutlet);
        this.OutletCreditLimit = this.checkFeatureByName(FeatureNames.OutletCreditLimit);
        this.PerformancePage = this.checkFeatureByName(FeatureNames.PerformancePage);
        this.PromotionSuggestions = this.checkFeatureByName(FeatureNames.PromotionSuggestions);
        this.Pwa = this.checkFeatureByName(FeatureNames.Pwa);
        this.Reorder = this.checkFeatureByName(FeatureNames.Reorder);
        this.RequireRewardTermsAndConditionsToBeAccepted = this.checkFeatureByName(
            FeatureNames.RequireRewardTermsAndConditionsToBeAccepted
        );
        this.AddReasonsForPartialDelivery = this.checkFeatureByName(FeatureNames.AddReasonsForPartialDelivery);
        this.AllowDistributorsToControlStoreSettings = this.checkFeatureByName(
            FeatureNames.AllowDistributorsToControlStoreSettings
        );
        this.AllowDistributorsToControlStoreSettings = this.checkFeatureByName(
            FeatureNames.AllowDistributorsToControlStoreSettings
        );
        this.SelectDeliveryDate = this.checkFeatureByName(FeatureNames.SelectDeliveryDate);
        this.EnableCustomerPickup = this.checkFeatureByName(FeatureNames.EnableCustomerPickUp);
        this.SelectUnitOfMeasure = this.checkFeatureByName(FeatureNames.SelectUnitOfMeasure);
        this.ShipOrderByDistributor = this.checkFeatureByName(FeatureNames.ShipOrderByDistributor);
        this.ShowAddress = this.checkFeatureByName(FeatureNames.ShowAddress);
        this.ShowDistributorLineItemExtendedPrice = this.checkFeatureByName(
            FeatureNames.ShowDistributorLineItemExtendedPrice
        );
        this.ShowAndCalculateExpectedDeliveryTime = this.checkFeatureByName(
            FeatureNames.ShowAndCalculateExpectedDeliveryTime
        );
        this.ShowDistributorLineItemSku = this.checkFeatureByName(FeatureNames.ShowDistributorLineItemSku);
        this.ShowProductProperties = this.checkFeatureByName(FeatureNames.ShowProductProperties);
        this.SmsResetPassword = this.checkFeatureByName(FeatureNames.SmsResetPassword);
        this.EmailResetPassword = this.checkFeatureByName(FeatureNames.EmailResetPassword);
        this.StockControl = this.checkFeatureByName(FeatureNames.StockControl);
        this.StoreIsRequiredForOutlet = this.checkFeatureByName(FeatureNames.StoreIsRequiredForOutlet);
        this.SummarizeInvoices = this.checkFeatureByName(FeatureNames.SummarizeInvoices);
        this.VisualWebsiteOptimizer = this.checkFeatureByName(FeatureNames.VisualWebsiteOptimizer);
        this.SmsActivateAccount = this.checkFeatureByName(FeatureNames.SmsActivateAccount);
        this.EmailActivateAccount = this.checkFeatureByName(FeatureNames.EmailActivateAccount);
        this.UsePackagesWithCartsAndOrders = this.checkFeatureByName(FeatureNames.UsePackagesWithCartsAndOrders);
        this.SplitAlcoholicAndNonAlcoholicProductsWhenCreatingOrder = this.checkFeatureByName(
            FeatureNames.SplitAlcoholicAndNonAlcoholicProductsWhenCreatingOrder
        );
        this.ReportOrderIssue = this.checkFeatureByName(FeatureNames.ReportOrderIssue);
        this.ReturnEmpties = this.checkFeatureByName(FeatureNames.ReturnEmpties);
        this.EmptiesExclusiveOrder = this.checkFeatureByName(FeatureNames.EmptiesExclusiveOrder);
        this.OrdersPageForGroupAccount = this.checkFeatureByName(FeatureNames.OrdersPageForGroupAccount);
        this.EnableOfflineOrder = this.checkFeatureByName(FeatureNames.EnableOfflineOrder);
        this.EnableChatBot = this.checkFeatureByName(FeatureNames.EnableChatBot);
        this.EnableCHQIntegration = this.checkFeatureByName(FeatureNames.EnableCHQIntegration);
        this.DashboardHomePage = this.checkFeatureByName(FeatureNames.DashboardHomePage);
        this.EnableGPSTracking = this.checkFeatureByName(FeatureNames.EnableGPSTracking);
        this.EnableRating = this.checkFeatureByName(FeatureNames.EnableRating);
        this.AppDownloadPopup = this.checkFeatureByName(FeatureNames.AppDownloadPopup);
        this.EazleEnabledOpco = this.checkFeatureByName(FeatureNames.EazleEnabledOpco);
        this.HighlightMostOrderedDistributors = this.checkFeatureByName(FeatureNames.HighlightMostOrderedDistributors);
        this.EnableMultiplePaymentProvider = this.checkFeatureByName(FeatureNames.EnableMultiplePaymentProvider);
        this.EnableLoginWithOTP = this.checkFeatureByName(FeatureNames.EnableLoginWithOTP);
        this.DisplayofPerformancevsTarget = this.checkFeatureByName(FeatureNames.DisplayofPerformancevsTarget);
        this.PasswordExpiration = this.checkFeatureByName(FeatureNames.PasswordExpiration);
        this.UseCategoriesAsPrimaryCatalogFilter = this.checkFeatureByName(
            FeatureNames.UseCategoriesAsPrimaryCatalogFilter
        );
        this.DisplayDriverDetails = this.checkFeatureByName(FeatureNames.DisplayDriverDetails);
        this.ShowDotTutorialButton = this.checkFeatureByName(FeatureNames.ShowDotTutorialButton);
        this.RestrictOrderPaymentsbyChannel = this.checkFeatureByName(FeatureNames.RestrictOrderPaymentsbyChannel);
        this.ProductActivationFlow = this.checkFeatureByName(FeatureNames.ProductActivationFlow);
        this.EnableViewAllCoupons = this.checkFeatureByName(FeatureNames.EnableViewAllCoupons);
        this.ShowPricingIndicatorInCart = this.checkFeatureByName(FeatureNames.ShowPricingIndicatorInCart);
        this.ShowPricingIndicatorInCartFilterable = this.checkFeatureIsFilterableByName(
            FeatureNames.ShowPricingIndicatorInCart
        );
        this.EmailAndUsernameToBeTheSameInCustomerSelfRegistration = this.checkFeatureByName(
            FeatureNames.EmailAndUsernameToBeTheSameInCustomerSelfRegistration
        );
        this.AddReasonsByDistributorWhenChangeItems = this.checkFeatureByName(
            FeatureNames.AddReasonsByDistributorWhenChangeItems
        );
        this.RefreshCatalogsAfterCartChanges = this.checkFeatureByName(FeatureNames.RefreshCatalogsAfterCartChanges);
        this.RefreshCartAfterExternalValidation = this.checkFeatureByName(
            FeatureNames.RefreshCartAfterExternalValidation
        );
        this.ShowAgeConfirmation = this.checkFeatureByName(FeatureNames.ShowAgeConfirmation);
        this.ShowReceiverPhoneNumberAtCheckOutPage = this.checkFeatureByName(
            FeatureNames.ShowReceiverPhoneNumberAtCheckOutPage
        );
        this.AddProductsWithNoPrice = this.checkFeatureByName(FeatureNames.AddProductsWithNoPrice);
        this.DistributorPerformancePage = this.checkFeatureByName(FeatureNames.DistributorPerformancePage);
        this.DisplayCarouselBannerInDistributorPortal = this.checkFeatureByName(
            FeatureNames.DisplayCarouselBannerInDistributorPortal
        );
        this.ManageFavoriteProducts = this.checkFeatureByName(FeatureNames.ManageFavoriteProducts);
        this.AllowDistributorsToCancelOrdersAfterConfirmation = this.checkFeatureByName(
            FeatureNames.AllowDistributorsToCancelOrdersAfterConfirmation
        );
        this.AllowDispatchingMultipleOrders = this.checkFeatureByName(FeatureNames.AllowDispatchingMultipleOrders);
        this.AllowConfirmingMultipleOrdersForNewOrders = this.checkFeatureByName(
            FeatureNames.AllowConfirmingMultipleOrdersForNewOrders
        );
        this.EnableAdvancedFilters = this.checkFeatureByName(FeatureNames.EnableAdvancedFilters);
        this.EnableAdvancedPriceAndSortingFilter = this.checkFeatureByName(
            FeatureNames.EnableAdvancedPriceAndSortingFilter
        );
        this.AllowPriceManagementOnDistributorPortal = this.checkFeatureByName(
            FeatureNames.AllowPriceManagementOnDistributorPortal
        );
        this.BuildFulfillmentCentersUsingOutletStores = this.checkFeatureByName(
            FeatureNames.BuildFulfillmentCentersUsingOutletStores
        );
        this.ExtendedOrderDeliveryConfirmationFlow = this.checkFeatureByName(
            FeatureNames.ExtendedOrderDeliveryConfirmationFlow
        );
        this.AddReasonsByCustomerWhenCancelOrder = this.checkFeatureByName(
            FeatureNames.AddReasonsByCustomerWhenCancelOrder
        );
        this.EnableDiscountCalculationWhenPriceIsHidden = this.checkFeatureByName(
            FeatureNames.EnableDiscountCalculationWhenPriceIsHidden
        );
        this.OrganizationLevelCreditLimitCheck = this.checkFeatureByName(
            FeatureNames.OrganizationLevelCreditLimitCheck
        );
        this.EnableBasketOverlay = this.checkFeatureByName(FeatureNames.EnableBasketOverlay);
        this.EnableOffInvoicePerformanceTracking = this.checkFeatureByName(FeatureNames.EnableOffInvoicePerformanceTracking);
        this.ShowSdipStatistics = this.checkFeatureByName(FeatureNames.ShowSdipStatistics);
        this.SkipDistributorSelectionAfterSelectedDistributor = this.checkFeatureByName(
            FeatureNames.SkipDistributorSelectionAfterSelectedDistributor
        );
        this.ShowTlpStatistics = this.checkFeatureByName(FeatureNames.ShowTlpStatistics);
        this.EnableOpenInvoice = this.checkFeatureByName(FeatureNames.EnableOpenInvoice);
        this.ShowSalesRepPhoneNumber = this.checkFeatureByName(FeatureNames.ShowSalesRepPhoneNumber);
        this.GotItIntegration = this.checkFeatureByName(FeatureNames.GotItIntegration);
        this.ExternalPriceValidation = this.checkFeatureByName(FeatureNames.ExternalPriceValidation);
        this.ExternalCartValidation = this.checkFeatureByName(FeatureNames.ExternalCartValidation);
        this.ShowPromotionAllocations = this.checkFeatureByName(FeatureNames.ShowPromotionAllocations);
        this.ShowPromotionHistory = this.checkFeatureByName(FeatureNames.ShowPromotionHistory);
        this.SplitOrdersBySuppliersWhenCheckout = this.checkFeatureByName(
            FeatureNames.SplitOrdersBySuppliersWhenCheckout
        );
        this.ValidateMinimumBackOrderQuantity = this.checkFeatureByName(FeatureNames.ValidateMinimumBackOrderQuantity);
        this.ValidateMinimumNonBackOrderQuantity = this.checkFeatureByName(
            FeatureNames.ValidateMinimumNonBackOrderQuantity
        );
        this.ShowStockLevelOnLineItems = this.checkFeatureByName(FeatureNames.ShowStockLevelOnLineItems);
        this.ValidateProductLimitOrderQuantity = this.checkFeatureByName(
            FeatureNames.ValidateProductLimitOrderQuantity
        );
        this.DisplayUnitPrice = this.checkFeatureByName(FeatureNames.DisplayUnitPrice);

        this.ShowPayNowAtReceivedStatus = this.checkFeatureByName(FeatureNames.ShowPayNowAtReceivedStatus);
        this.ShowCreditValidationWhenCheckout = this.checkFeatureByName(FeatureNames.ShowCreditValidationWhenCheckout);
        this.OrderSimulationPricesAndTaxes = this.checkFeatureByName(FeatureNames.OrderSimulationPricesAndTaxes);
        this.EnableAdvanceCheckoutFlow = this.checkFeatureByName(FeatureNames.EnableAdvanceCheckoutFlow);
        this.EnableProductRecommendation = this.checkFeatureByName(FeatureNames.EnableProductRecommendation);
        this.EnableProductRecommendationOnHomePage = this.checkFeatureByName(
            FeatureNames.EnableProductRecommendationOnHomePage
        );
        this.DisplaySingleUnitPriceBasedOnPackageSize = this.checkFeatureByName(
            FeatureNames.DisplaySingleUnitPriceBasedOnPackageSize
        );
        this.EnableHomePageActiveBanner = this.checkFeatureByName(FeatureNames.EnableHomePageActiveBanner);
    }
}
