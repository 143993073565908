import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../../services';
import { ModalIds } from '@hot-libs/shared-types';

@Component({
    selector: 'hot-distributor-change-modal',
    templateUrl: './distributor-change-modal.component.html',
})
export class DistributorChangeModalComponent {
    @Input() public innerHtmlTitle: string;
    @Input() public innerHtmlContent: string;
    @Output() public changeDistributor = new EventEmitter();
    @Output() public cancelDistributorChange = new EventEmitter();

    constructor(private readonly modalService: ModalService) {}

    public close(): void {
        this.cancelDistributorChange.emit();
        this.modalService.toggleModal(ModalIds.distributorChangeModal, false);
    }
}
