import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { PageName } from '@hot-libs/shared-types';
import { OrderSuggestion, OrderSuggestionProduct } from '@hot-theme-nx/generated-api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductService } from '../../../services';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { AnalyticRouteService } from '../../../services/analytic-route.service';

@Component({
    selector: 'hot-fast-order-modal',
    templateUrl: './fast-order-modal.component.html',
})
export class FastOrderModalComponent implements OnInit, OnDestroy {
    @Input() public orderSuggestion: OrderSuggestion;
    @Input() public featureLocationFastOrder : string;
    @Output() public addProducts = new EventEmitter();
    @Output() public close = new EventEmitter<void>();

    public pageName = PageName.FASTORDERMODAL;

    private readonly subscriptionDestroy$ = new Subject<boolean>();
    private readonly analyticProductLocation = 'fast-order';
    private readonly analyticsFastOrderModalView = 'fast_order_modal_view';

    constructor(private readonly productService: ProductService, private readonly analyticService: AnalyticService, private readonly analyticRouteService: AnalyticRouteService) {}

    public ngOnInit(): void {
        this.productService.removedProduct
            .pipe(takeUntil(this.subscriptionDestroy$))
            .subscribe((product) => this.removeProduct(product));
        this.analyticRouteService.fastOrderModalEvents(this.analyticsFastOrderModalView);
        this.analyticService.sendFastModalAnalyticData(this.analyticProductLocation);
    }

    public addToCart(product: { productId: string; quantity: number }): void {
        this.setProductQuantity(product.productId, product.quantity);
    }

    public changeQuantity(product: OrderSuggestionProduct): void {
        this.setProductQuantity(product.id, product.quantity);
    }

    public removeProduct(product: OrderSuggestionProduct): void {
        this.setProductQuantity(product.id, 0);
        this.orderSuggestion.products = this.orderSuggestion.products.filter(suggestedProduct => suggestedProduct.id !== product.id);
    } 

    public addAllProductsToCart(): void {
        const productsToAdd = this.orderSuggestion.products.filter(
            (product: OrderSuggestionProduct) => product.quantity > 0
        );
        this.analyticService.sendAddAllProductsEventInfo(productsToAdd, this.analyticProductLocation, this.featureLocationFastOrder);
        this.addProducts.emit(productsToAdd);
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }

    private setProductQuantity(productId: string, quantity: number): void {
        const suggestionProduct = this.orderSuggestion.products.find((p: OrderSuggestionProduct) => p.id === productId);

        if (suggestionProduct) {
            suggestionProduct.quantity = quantity;
        }
    }
}
