import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { HotApiCartsService,HotCreateOrderRequest } from '@hot-theme-nx/generated-api';

import { AddProductToCartEvent, RemoveProductFromCartEvent } from '../analytic/store';
import { CartClear, CartUpdate } from '../cart/store/cart.actions';
import { getCartItems, IStateWithCart } from '../cart/store/cart.selector';
import { ICartLineItem } from '../models/cart-line-item.model';

import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    public cartItems: ICartLineItem[];
    public totalVolume: number;
    public isFirstAdding: boolean;

    private _location: string;
    public get location() {
        return this._location;
    }

    public set location(location: string) {
        this._location = location;
    }

    constructor(
        private readonly store: Store<IStateWithCart>,
        private readonly hotApiCartService: HotApiCartsService
    ) {}

    public changeQuantity(product: ICartLineItem) {
        const calculatedProduct: ICartLineItem = cloneDeep(product);
        calculatedProduct.quantity = Math.round(calculatedProduct.quantity * calculatedProduct.packageSize);

        if (!calculatedProduct.productId) {
            calculatedProduct.productId = product.id;
        }

        if (calculatedProduct.quantity === 0) {
            this.store.dispatch(RemoveProductFromCartEvent({ data: [calculatedProduct], location: this.location }));
        } else if (calculatedProduct.quantity > 0) {
            this.store.dispatch(AddProductToCartEvent({ data: [calculatedProduct], location: this.location }));
        }

        this.store.dispatch(CartUpdate({ product: calculatedProduct }));
    }

    public clearCart() {
        this.store
            .select(getCartItems)
            .pipe(first())
            .subscribe((items) => {
                this.store.dispatch(RemoveProductFromCartEvent({ data: items, location: this.location }));
            });

        this.store.dispatch(CartClear());
    }

    public calculateAlcoNonalcoVolume(withAlcohol: boolean, items: ICartLineItem[]) {
        this.totalVolume = 0;
        if (items?.length) {
            // tslint:disable-next-line: prefer-for-of
            items.forEach((item) => {
                if (item.isAlcoholic === withAlcohol) {
                    this.totalVolume += item.unitVolume * Math.round(item.quantity * item.packageSize);
                }
            });
        }

        return round(this.totalVolume, 2);
    }

    public calculateLineItemDiscount(oldPrice: number, actualPrice: number) {
        if (oldPrice > actualPrice) {
            return Math.round(100 - (actualPrice / oldPrice) * 100);
        }
        return 0;
    }

    public getOldPrice(salePrice: number, listPrice: number) {
        if (salePrice !== 0 && salePrice > listPrice) {
            return salePrice;
        } else {
            return listPrice;
        }
    }

    public createOrder(orderParams: HotCreateOrderRequest) {
        this.hotApiCartService.createOrder({ "body": orderParams }).toPromise();
    }

    public getDeliveryDates(): Observable<string[]> {
        return this.hotApiCartService.getDeliveryDates();
    }
}
