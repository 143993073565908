import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from './root.reducer';

export interface IState {
    root: fromRoot.IRootState;
}
export const getRootFeatureState = createFeatureSelector<fromRoot.IRootState>('root');

export const getCurrentUser = createSelector(getRootFeatureState, (state) => state.user);

export const getOutlets = createSelector(getRootFeatureState, (state) => state.user?.contact?.outlets ?? null);

export const getCurrentOutlet = createSelector(getRootFeatureState, (state) => state.user?.contact?.outlet ?? null);

export const getCurrentOutletOuterId = createSelector(
    getCurrentOutlet,
    (currentOutlet) => currentOutlet?.outerId ?? null
);

export const getSettings = createSelector(getRootFeatureState, (state) => state.settings);

export const getStoreSettings = createSelector(getRootFeatureState, (state) => state.storeSettings);

export const getCurrencySettings = createSelector(
    getRootFeatureState,
    (state) => state.storeSettings?.currencyFormat ?? null
);

export const getPaymentMethods = createSelector(getRootFeatureState, (state) =>
    state?.storeSettings?.paymentMethodSelectionEnabled ? state.storeSettings.paymentMethods ?? null : null
);

export const getStorefrontVersion = createSelector(
    getRootFeatureState,
    (state) => state?.settings?.storefrontVersion ?? null
);

export const getOutletDebt = createSelector(getRootFeatureState, (state) => state.outletDebt);

export const getFeatureByName = createSelector(
    getRootFeatureState,
    (state: fromRoot.IRootState, name: string) =>
        state?.settings?.features.find((f) => f.name.toLocaleLowerCase() === name.toLocaleLowerCase()) ?? null
);
