import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ModalService } from '../../../services';

import { ModalIds } from '@hot-libs/shared-types';

@Component({
    selector: 'hot-cart-has-unavailable-products-modal',
    templateUrl: './cart-has-unavailable-products-modal.component.html',
})
export class CartHasUnavailableProductsModalComponent {
    constructor(private readonly modalService: ModalService, private readonly router: Router) {}

    public goToCart(): void {
        this.modalService.toggleModal(ModalIds.cartHasUnavailableProductsModal, false);
        this.router.navigateByUrl('/cart');
    }
}
