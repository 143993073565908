import { Action } from '@ngrx/store';
import { EModalsActions } from '@hot-b2b/store/modals/types';
import { ModalsState } from '@hot-b2b/store/modals/model';

export class ModalToggle implements Action {
    public readonly type = EModalsActions.MODAL_TOGGLE;
    constructor(public payload: ModalsState) {}
}

export type ModalsActions = ModalToggle;
