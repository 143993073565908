import { Action } from '@ngrx/store';

import { HotLoyaltyBanner, HotLoyaltyStatement, HotRedemptionWindowStatus } from '@hot-theme-nx/generated-api';

import { ELoyaltyActions } from '@hot-b2b/store/loyalty/types';

export class LoyaltyUpdate implements Action {
    public readonly type = ELoyaltyActions.LOYALTY_FETCHED;
    constructor(public payload: HotLoyaltyStatement) {}
}

export class LoyaltyGet implements Action {
    public readonly type = ELoyaltyActions.LOYALTY_PENDING;
}

export class LoyaltyGetSuccess implements Action {
    public readonly type = ELoyaltyActions.LOYALTY_FETCHED;
    constructor(public payload: HotLoyaltyStatement) {}
}

export class LoyaltyStatusGet implements Action {
    public readonly type = ELoyaltyActions.LOYALTY_PENDING;
    constructor(public payload: string) {}
}

export class LoyaltyStatusGetSuccess implements Action {
    public readonly type = ELoyaltyActions.LOYALTY_STATUS_FETCHED;
    constructor(public payload: HotRedemptionWindowStatus) {}
}

export class LoyaltyBannersGet implements Action {
    public readonly type = ELoyaltyActions.LOYALTY_BANNERS_PENDING;
}

export class LoyaltyBannersGetSuccess implements Action {
    public readonly type = ELoyaltyActions.LOYALTY_BANNERS_FETCHED;
    constructor(public payload: { [key: string]: HotLoyaltyBanner }) {}
}

export class LoyaltyError implements Action {
    public readonly type = ELoyaltyActions.LOYALTY_ERROR;
    constructor(public payload: string | boolean) {}
}

export type LoyaltyActions =
    | LoyaltyGet
    | LoyaltyGetSuccess
    | LoyaltyStatusGet
    | LoyaltyStatusGetSuccess
    | LoyaltyBannersGet
    | LoyaltyBannersGetSuccess
    | LoyaltyError;
