import { Component, Input } from '@angular/core';
import { ConfigurationService } from '../shared/services/configuration.service';
import { HotLanguage } from '@hot-theme-nx/generated-api';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { settingsLanguageCurrent } from '@hot-b2b/store/settings/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotUserExtended } from '@hot-libs/shared-models';
import { authUser } from '@hot-b2b/store/auth/selector';
import { ModalService } from '../shared/services';
import { ModalIds } from '@hot-libs/shared-types';

@Component({
    selector: 'hot-dot-tutorial-btn',
    templateUrl: './dot-tutorial-btn.component.html',
})
export class HotDotTutorialBtnComponent {
    public currentConfiguration = this.configurationService.getCurrentConfiguration();
    public settingsLanguageCurrent$: Observable<HotLanguage>;
    @Input() public currentRoute: any;
    public authUser$: Observable<HotUserExtended>;

    constructor(
        private readonly modalService: ModalService,
        private readonly configurationService: ConfigurationService,
        private readonly store: Store<AppState>,
    ) {
        this.settingsLanguageCurrent$ = this.settingsLanguageCurrent$ = this.store.pipe(
            select(settingsLanguageCurrent)
        );
        this.authUser$ = this.store.pipe(select(authUser));
    }

    

    showTutorialPopup() {
        this.modalService.toggleModal(ModalIds.tutorials, true);
    }
}
