import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ApiConfiguration,
    Contact,
    HotActivateResponse,
    HotPhoneActivationTokenRequest,
    HotSecurityResponse,
    HotValidateActivationTokenRequest,
    HotValidateActivationTokenResponse,
    HotValidatePhoneTokenRequest,
    HotValidatePhoneTokenResponse,
    HotForgotPasswordRequest,
    HotTermsAndConditions,
    HotPrivacyPolicy,
} from '@hot-theme-nx/generated-api';

import { authContact } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services/features.service';
import { TermsAndConditionAndPrivacyPolicyResponse } from '@hot-theme-nx/generated-api';
import { CacheClearService } from '../../shared/services/cache-clear.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly forgotPasswordUserData = new BehaviorSubject<HotForgotPasswordRequest>(null);

    constructor(
        private readonly httpClient: HttpClient,
        private readonly store: Store<AppState>,
        private readonly featuresService: FeaturesService,
        private readonly apiConfiguration: ApiConfiguration,
        private readonly clearCache: CacheClearService
    ) {
        this.userContact$ = this.store.pipe(select(authContact));
    }

    private readonly userContact$: Observable<Contact>;
    public publishAppOpenedMobileEvent: boolean = true;

    public activateAccount(
        userId: string,
        token: string,
        password: string,
        langCode: string,
        isSms: boolean,
        fastOnboardingRequest?: boolean
    ): Observable<HotActivateResponse> {
        return this.httpClient.post<HotActivateResponse>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/security/activate`,
            {
                userId,
                token,
                newPassword: password,
                preferredLanguageCode: langCode,
                isSms: isSms,
                fastOnboardingRequest,
            }
        );
    }

    public sendResetPasswordToken(userName: string): Observable<HotSecurityResponse> {
        return this.httpClient.post<HotSecurityResponse>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/security/forgot-password`,
            {
                userName,
            }
        );
    }

    public childAccountActivationEmail(requestId: string): Observable<any> {
        return this.httpClient.get<any>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/child-contacts/${requestId}`
        );
    }

    public resetPassword(userId: string, password: string, token: string): Observable<HotSecurityResponse> {
        return this.httpClient.post<HotSecurityResponse>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/security/reset-password`,
            {
                userId,
                newPassword: password,
                token,
            }
        );
    }

    public activateChildAccount(userId: string, password: string, token: string): Observable<HotSecurityResponse> {
        return this.httpClient.post<HotSecurityResponse>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/child-contacts/activate`,
            {
                userId,
                newPassword: password,
                token,
            }
        );
    }

    public validateAccountToken(
        userId: string,
        expirationDate: string,
        token: string,
        isSms: boolean,
        fastOnboardingRequest?: boolean
    ): Observable<HotValidateActivationTokenResponse> {
        const params: HotValidateActivationTokenRequest = {
            userId,
            expirationDate,
            token,
            isSms,
            fastOnboardingRequest,
        };
        return this.httpClient.post<HotValidateActivationTokenResponse>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/security/validate-activation-token`,
            params
        );
    }

    public validatePhoneToken(userName: string, token: string): Observable<HotValidatePhoneTokenResponse> {
        const params: HotValidatePhoneTokenRequest = {
            userName,
            token,
        };
        return this.httpClient.post<HotValidatePhoneTokenResponse>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/security/validate-phone-token`,
            params
        );
    }

    public phoneActivationToken(userName: string): Observable<HotSecurityResponse> {
        const params: HotPhoneActivationTokenRequest = {
            userName,
        };
        return this.httpClient.post<HotSecurityResponse>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/security/phone-activation-token`,
            params
        );
    }

    public acceptRewardTerms(): Observable<any> {
        return this.httpClient.put(`${this.apiConfiguration.rootUrl}/storefrontapi/hot/account/reward-terms`, null);
    }

    public acceptTermsAndConditions(): Observable<TermsAndConditionAndPrivacyPolicyResponse> {
        let hotTermsAndConditions: HotTermsAndConditions = {
            eazleTermsAndConditionsAcceptedDate: null,
        };
        return this.httpClient.put<TermsAndConditionAndPrivacyPolicyResponse>(
            `${this.apiConfiguration.rootUrl}storefrontapi/hot/account/terms-and-conditions/accepted`,
            hotTermsAndConditions
        );
    }

    public acceptPrivacyPolicy(): Observable<TermsAndConditionAndPrivacyPolicyResponse> {
        let hotPrivacyPolicy: HotPrivacyPolicy = {
            eazlePrivacyPolicyAcceptedDate: null,
        };
        return this.httpClient.put<TermsAndConditionAndPrivacyPolicyResponse>(
            `${this.apiConfiguration.rootUrl}storefrontapi/hot/account/privacy-policy/accepted`,
            hotPrivacyPolicy
        );
    }

    public getSettings(): Observable<any> {
        return this.httpClient.get(`${this.apiConfiguration.rootUrl}/storefrontapi/hot/account/settings`);
    }

    public changePassword(currentPassword: string, newPassword: string, confirmNewPassword: string): Observable<any> {
        return this.httpClient.post<any>(`${this.apiConfiguration.rootUrl}/storefrontapi/hot/account/password`, {
            oldPassword: currentPassword,
            newPassword: newPassword,
            newPasswordConfirmation: confirmNewPassword,
        });
    }

    public changePhone(phone: string, token?: string): Observable<any> {
        return this.httpClient.post<any>('/storefrontapi/hot/account/phone', {
            newPhoneNumber: phone,
            token,
        });
    }

    public changeEmail(email: string, token?: string): Observable<any> {
        return this.httpClient.post<any>('/storefrontapi/hot/account/email', {
            newEmail: email,
            token,
        });
    }

    public resendActivationLink(userId: string): Observable<any> {
        return this.httpClient.post<any>('/storefrontapi/hot/security/invitation', { userId });
    }

    public showRewardsAcceptModal(): Observable<boolean> {
        return this.userContact$.pipe(
            map((userContact: Contact) => {
                return (
                    this.featuresService.RequireRewardTermsAndConditionsToBeAccepted &&
                    !userContact.isRewardTermsAccepted
                );
            })
        );
    }

    public getUser(): Observable<any> {
        return this.httpClient.get(`${this.apiConfiguration.rootUrl}/storefrontapi/hot/security/user`);
    }

    public deleteUserCache(): Promise<boolean> {
        const deleteURLs = [{ dataGroupName: 'performance', url: '/storefrontapi/hot/security/user' }];

        return this.clearCache
            .deleteCacheByAPIUrl(deleteURLs)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    setForgotPasswordUserData(userData: HotForgotPasswordRequest) {
        this.forgotPasswordUserData.next(userData);
    }

    getForgotPasswordUserData() {
        return this.forgotPasswordUserData.asObservable();
    }
}
