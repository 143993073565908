import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppState } from '@hot-b2b/store/reducers';
import { FeedbackTopicsState } from '@hot-b2b/store/feedback/model';

const stateFeedbackTopics = (state: AppState): FeedbackTopicsState => state.feedbackTopics;

export const feedbackTopics: MemoizedSelector<AppState, string[]> = createSelector(
    stateFeedbackTopics,
    (state: FeedbackTopicsState) => state.data.topics
);
export const feedbackPending: MemoizedSelector<AppState, boolean> = createSelector(
    stateFeedbackTopics,
    (state: FeedbackTopicsState) => state.pending
);