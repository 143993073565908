import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { HotApiMarketingService, HotPromotion, OrderSuggestion } from '@hot-theme-nx/generated-api';
import { FeatureNames, TelemetryEventType } from '@hot-libs/shared-types';

import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { CatalogService } from 'apps/hot-b2b/src/app/catalog/services';
import { MarketingService } from 'apps/hot-b2b/src/app/marketing/services';
import { ApplicationInsightsService, FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import { CartState } from '@hot-b2b/store/cart/model';
import {
    PromotionsError,
    PromotionsGet,
    PromotionsGetFromCenter,
    PromotionsGetFromCenterSuccess,
    PromotionsGetOrderSuggestions,
    PromotionsGetOrderSuggestionsSuccess,
    PromotionsGetProducts,
    PromotionsGetProductsSuccess,
    PromotionsGetSuccess,
    PromotionsSynchronized,
    PromotionsSynchronizedSuccess,
} from '@hot-b2b/store/promotions/actions';
import { EPromotionsActions } from '@hot-b2b/store/promotions/types';
import { AppState } from '@hot-b2b/store/reducers';

@Injectable()
export class PromotionsEffects {
    constructor(
        private readonly _store: Store<AppState>,
        private readonly _actions$: Actions,
        private readonly marketingService: MarketingService,
        private readonly hotApiMarketingService: HotApiMarketingService,
        private readonly catalogService: CatalogService,
        private readonly featuresService: FeaturesService,
        private readonly appInsightsService: ApplicationInsightsService
    ) { }

    public PromotionsGet$ = createEffect(() => this._actions$.pipe(
        ofType<PromotionsGet>(EPromotionsActions.PROMOTIONS_PENDING),
        withLatestFrom(
            this.featuresService.checkFeatures(FeatureNames.StoreIsRequiredForOutlet),
            this.featuresService.checkFeatures(FeatureNames.PromotionSuggestions)
        ),
        tap(() => this.appInsightsService.startTrackingEvent(TelemetryEventType.PromotionsLoadingTime)),
        switchMap(([, storeIsRequiredForOutlet, promotionSuggestions]: [PromotionsGet, boolean, boolean]) => {
            const params: HotApiMarketingService.GetPromotionsParams = {
                includeInvisiblePromotions: promotionSuggestions,
            };

            return forkJoin([
                storeIsRequiredForOutlet
                    ? this.hotApiMarketingService
                        .getPromotions(params)
                        .pipe(map((items: HotPromotion[]) => [{ id: '', promotions: items }]))
                    : this.marketingService.getPromotionsFromCenters(promotionSuggestions)
            ]);
        }),
        map((response: any) => response[0]),
        map((response: any) => new PromotionsGetSuccess(response)),
        tap(() => this.appInsightsService.endTrackingEvent(TelemetryEventType.PromotionsLoadingTime)),
        catchError((error: any) => {
            this._store.dispatch(new PromotionsError(error.error));
            return throwError(error);
        })
    ));

    public PromotionsGetFromCenter$ = createEffect(() => this._actions$.pipe(
        ofType<PromotionsGetFromCenter>(EPromotionsActions.PROMOTIONS_PENDING_FROM_CENTER),
        withLatestFrom(
            this.featuresService.checkFeatures(FeatureNames.PromotionSuggestions)
        ),
        tap(() => this.appInsightsService.startTrackingEvent(TelemetryEventType.PromotionsLoadingTime)),
        switchMap((data: [PromotionsGetFromCenter, boolean]) => {
            const params: HotApiMarketingService.GetPromotionsParams = {
                includeInvisiblePromotions: data[1],
                fulfillmentCenterId: data[0].payload
            };
            return this.hotApiMarketingService.getPromotions(params)
                .pipe(map((items: HotPromotion[]) => [{ id: data[0].payload, promotions: items }]));
        }),
        map((response: any) => [response[0]]),
        map((response: any) => new PromotionsGetFromCenterSuccess(response)),
        tap(() => this.appInsightsService.endTrackingEvent(TelemetryEventType.PromotionsLoadingTime)),
        catchError((error: any) => {
            this._store.dispatch(new PromotionsError(error.error));
            return throwError(error);
        })
    ));

    public PromotionsGetProducts$ = createEffect(() => this._actions$.pipe(
        ofType<PromotionsGetProducts>(EPromotionsActions.PROMOTIONS_GET_PRODUCTS),
        switchMap((action: PromotionsGetProducts) =>
            this.catalogService
                .getProducts(action.payload)
                .pipe(
                    switchMap((response: HotProductExtended[]) => [
                        new PromotionsGetProductsSuccess(response),
                        new PromotionsSynchronized(),
                    ])
                )
        )
    ));

    public PromotionsSynchronizedProducts$: Observable<PromotionsSynchronizedSuccess> = createEffect(() => this._actions$.pipe(
        ofType<PromotionsSynchronized>(EPromotionsActions.PROMOTIONS_SYNCHRONIZED),
        withLatestFrom(this._store.pipe(select((state: AppState) => state.cart))),
        map(
            (action: [PromotionsSynchronized, CartState]) =>
                new PromotionsSynchronizedSuccess(
                    action[0].payload ? action[0].payload.items : action[1].data.cart.items
                )
        )
    ));

    public PromotionsGetOrderSuggestion$ = createEffect(() => this._actions$.pipe(
        ofType<PromotionsGetOrderSuggestions>(EPromotionsActions.PROMOTIONS_GET_ORDER_SUGGESTIONS),
        switchMap(() =>
            this.hotApiMarketingService
                .getOrderSuggestionV2()
                .pipe(switchMap((response: OrderSuggestion) => [new PromotionsGetOrderSuggestionsSuccess(response)]))
        )
    ));
}
