import { Action } from '@ngrx/store';

import {
    HotDeliveredOrdersStatistics,
    HotOrderSearchCriteria,
    HotConfirmOrderDeliveryRequest,
    HotRejectOrderRequest,
    HotOrderStatusReasonsWithLanguage,
    HotOperationResult,
    HotCartValidationRequest
} from '@hot-theme-nx/generated-api';

import { EOrdersActions } from '@hot-b2b/store/orders/types';
import { HotOrderExtended, HotOrderSearchResultExtended } from '@hot-libs/shared-models';

export class OrdersResponseGet implements Action {
    public readonly type = EOrdersActions.ORDERS_RESPONSE_PENDING;
}

export class OrdersResponseGetSuccess implements Action {
    public readonly type = EOrdersActions.ORDERS_RESPONSE_FETCHED;
    constructor(public payload: HotOrderSearchResultExtended) { }
}

export class OrdersPreviousGet implements Action {
    public readonly type = EOrdersActions.ORDERS_PREVIOUS_PENDING;
}

export class OrdersPreviousGetSuccess implements Action {
    public readonly type = EOrdersActions.ORDERS_PREVIOUS_FETCHED;
    constructor(public payload: { orders: HotOrderExtended[]; statistics: HotDeliveredOrdersStatistics }) { }
}
export class OrderUpdate implements Action {
    public readonly type = EOrdersActions.UPDATE_ORDER;
    constructor(public payload: HotOrderExtended) { }
}

export class OrdersPreviousError implements Action {
    public readonly type = EOrdersActions.ORDERS_PREVIOUS_ERROR;
    constructor(public payload: string) { }
}

export class OrderLastGet implements Action {
    public readonly type = EOrdersActions.ORDERS_LAST_PENDING;
}

export class OrderLastGetSuccess implements Action {
    public readonly type = EOrdersActions.ORDERS_LAST_FETCHED;
    constructor(public payload: { latestOrder: HotOrderExtended[] }) { }
}

export class OrdersPreviousClear implements Action {
    public readonly type = EOrdersActions.ORDERS_PREVIOUS_CLEAR;
}

export class OrdersChangeStatus implements Action {
    public readonly type = EOrdersActions.ORDERS_CHANGE_STATUS;
    constructor(public payload: { orderId: string; status: string; extendedStatus: string }) { }
}

export class OrdersDemoDataSet implements Action {
    public readonly type = EOrdersActions.ORDERS_DEMO_DATA_SET;
}

export class OrdersDemoDataClear implements Action {
    public readonly type = EOrdersActions.ORDERS_DEMO_DATA_CLEAR;
}

export class UpdateSearchOrdersCriteria implements Action {
    public readonly type = EOrdersActions.ORDERS_UPDATE_SEARCH_CRITERIA;
    constructor(public payload: HotOrderSearchCriteria) { }
}

export class UpdateSearchOrdersCriteriaSuccess implements Action {
    public readonly type = EOrdersActions.ORDERS_UPDATE_SEARCH_CRITERIA_SUCCESS;
    constructor(public payload: HotOrderSearchCriteria) { }
}

export class OrderSynchronize implements Action {
    public readonly type = EOrdersActions.ORDER_SYNCHRONIZE;
    constructor(public payload: HotOrderExtended) { }
}

export class SetDefaultSearchCriteria implements Action {
    public readonly type = EOrdersActions.ORDERS_SET_DEFAULT_SEARCH_CRITERIA;
}

export class GetCustomerOrderRejectReasons implements Action {
    public readonly type = EOrdersActions.ORDERS_CUSTOMER_REJECT_REASONS;
}

export class GetCustomerOrderRejectReasonsSuccess implements Action {
    public readonly type = EOrdersActions.ORDERS_CUSTOMER_REJECT_REASONS_SUCCESS;
    constructor(public payload: HotOrderStatusReasonsWithLanguage[]) { }
}

export class RejectOrderByCustomer implements Action {
    public readonly type = EOrdersActions.REJECT_ORDER_BY_CUSTOMER;
    constructor(public payload: HotRejectOrderRequest) { }
}

export class RejectOrderByCustomerSuccess implements Action {
    public readonly type = EOrdersActions.REJECT_ORDER_BY_CUSTOMER_SUCCESS;
    constructor(public payload: string) { }
}

export class ConfirmOrderByCustomer implements Action {
    public readonly type = EOrdersActions.CONFIRM_ORDER_BY_CUSTOMER;
    constructor(public payload: HotConfirmOrderDeliveryRequest) { }
}

export class ConfirmOrderByCustomerSuccess implements Action {
    public readonly type = EOrdersActions.CONFIRM_ORDER_BY_CUSTOMER_SUCCESS;
    constructor(public payload: string) { }
}

export class OrderSimulationSuccess implements Action {
    public readonly type = EOrdersActions.ORDER_SIMULATION_SUCCCES;
    constructor(public payload: boolean) { }
}

export class OrderSimulationSuccessResponse implements Action {
    public readonly type = EOrdersActions.ORDER_SIMULATION_SUCCESSFUL_RESPONSE;
    constructor(public payload: HotOperationResult) { }
}

export class OrderSimulationFetched implements Action {
    public readonly type = EOrdersActions.ORDER_SIMULATION_FETCHED;
}

export class OrderSimulationFailure implements Action {
    public readonly type = EOrdersActions.ORDER_SIMULATION_FAILURE;
    constructor(public payload: HotOperationResult) { }
}

export class OrderSimulationPending implements Action {
    public readonly type = EOrdersActions.ORDER_SIMULATION_PENDING;
    constructor(public payload: HotCartValidationRequest) { }
}

export class OrderSimulationTaxCalculation implements Action {
    public readonly type = EOrdersActions.ORDER_SIMULATION_TAXCALCULATION;
    constructor(public payload: HotCartValidationRequest) {}
}

export class OrderSimulationReset implements Action {
    public readonly type = EOrdersActions.ORDER_SIMULATION_RESET;
}
export type OrdersActions =
    | OrdersResponseGet
    | OrdersResponseGetSuccess
    | OrdersPreviousGet
    | OrdersPreviousGetSuccess
    | OrdersPreviousError
    | OrdersPreviousClear
    | OrdersChangeStatus
    | OrdersDemoDataSet
    | OrdersDemoDataClear
    | UpdateSearchOrdersCriteria
    | SetDefaultSearchCriteria
    | UpdateSearchOrdersCriteriaSuccess
    | OrderLastGet
    | OrderLastGetSuccess
    | OrderUpdate
    | OrderSynchronize
    | GetCustomerOrderRejectReasons
    | GetCustomerOrderRejectReasonsSuccess
    | RejectOrderByCustomer
    | RejectOrderByCustomerSuccess
    | ConfirmOrderByCustomer
    | ConfirmOrderByCustomerSuccess
    | OrderSimulationSuccess
    | OrderSimulationPending
    | OrderSimulationSuccessResponse
    | OrderSimulationReset
    | OrderSimulationFailure
    | OrderSimulationFetched;
