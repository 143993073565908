import { HotCreateOrderRequest } from '@hot-theme-nx/generated-api';
import { ProductsBufferModel } from '@hot-libs/shared-models';

import { HotCartExtended } from 'apps/hot-b2b/src/app/shared/models';

export interface CartState {
    pending: boolean;
    fetched: boolean;
    shipmentMethod: ShipmentMethodModel;
    isProductRecommederIsLoading: boolean;
    data: CartDataModel;
    error: object;
    promoError?: object;
    errorCodes: object;
    payment: PaymentModel;
}

export interface CartDataModel {
    cart: HotCartExtended;
    remarks: HotCreateOrderRequest;
    changesBuffer: ProductsBufferModel;
}

export interface ShipmentMethodModel {
    validateDeliveryMethod: boolean;
}

export enum DeliveryMethod {
    PickUp = 'CustomerPickUp',
    Delivery = 'FixedRate',
}

export interface AvailableShipmentMethodModel {
    isDeliveryAvailable: boolean;
    isPickUpAvailable: boolean;
    pAddress: PickUpAddress;
    dAddress: DeliveryAddress;
}

export interface PickUpAddress {
    warehouseName: string;
    warehouseAddress: string;
}

export interface DeliveryAddress {
    outletName: string;
    address: string;
}

export interface PaymentModel {
    rediretURL: string;
}
