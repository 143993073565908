import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'hot-pill',
  templateUrl: './hot-pill.component.html'
})
export class HotPillComponent {
  
  @Input() caption;
  @Input() filter;
  @Input() active;
  @Input() icon: string;
  @Input() color:boolean;
  @Output() public selected: EventEmitter<any> = new EventEmitter();

  public selectFilter() {
    this.active = !this.active;
    this.filter['active'] = this.active;
    this.selected.emit(this.filter);
  }
  
}
