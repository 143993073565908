import { Component, EventEmitter,Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { CartOfflineClear } from '@hot-b2b/store/cart/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services/features.service';
import { OrderService } from 'apps/hot-b2b/src/app/order/services';
import { OfflineOrderMessageBuilderService } from 'apps/hot-b2b/src/app/shared/services/offline-order-message-builder.service';
import {
    SettingsStoreModel,
} from '@hot-libs/shared-models';

import {
    settingsStore,
} from '@hot-b2b/store/settings/selector';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';


@Component({
    selector: 'hot-offline-notification-modal',
    templateUrl: './offline-notification-modal.component.html',
})
export class OfflineNotificationModalComponent {
    @Output() public toggleModal: EventEmitter<void> = new EventEmitter();

    public storeSettings$: Observable<SettingsStoreModel>;


    public childElementCount = (element: HTMLElement) => element.childElementCount;
    public PATHS = PATHS;
    public featureCreateOfflineOrder: boolean = this.featuresService.CreateOfflineOrder;
    public featureCreateOfflineOrderQueue: boolean = this.featuresService.EnableOfflineOrder;


    constructor(
        private readonly store: Store<AppState>,
        private readonly offlineOrderMessageBuilder: OfflineOrderMessageBuilderService,
        private readonly featuresService: FeaturesService,
        private readonly router: Router,
        private orderServcie: OrderService
    ) {
        this.storeSettings$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
    }

    public onWaitClick(): void {
        this.close();
    }

    public onSmsClick(): void {
        const offlineOrderSmsLink: string = this.offlineOrderMessageBuilder.buildOfflineOrderMessage();
        window.location.href = offlineOrderSmsLink;
        this.store.dispatch(new CartOfflineClear());
        this.close();
        this.router.navigateByUrl('/');
    }

    public orderOfflineQueue() {
        this.orderServcie.orderofflineQueue();
        this.close();
    }

    public smsButtonIsVisible(): boolean {
        return this.offlineOrderMessageBuilder.canBuildOfflineOrderMessage();
    }

    private close(): void {
        this.toggleModal.emit();
    }
}
