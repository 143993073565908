import { Component, OnDestroy } from '@angular/core';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalData } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { ModalIds } from '@hot-libs/shared-types';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '../../../services';

@Component({
    selector: 'hot-decline-notification-modal',
    templateUrl: './decline-notification-modal.component.html',
})
export class DeclineNotificationModalComponent implements OnDestroy {
    private readonly subscriptionsDestroy$ = new Subject<boolean>();

    public modalDeclinedState$: Observable<ModalsState>;

    public acceptView: boolean;

    constructor(public readonly modalService: ModalService, private readonly store: Store<AppState>) {
        this.modalDeclinedState$ = this.store.pipe(select(selectModalData(ModalIds.termsAndConditionsDeclined)));

        this.modalDeclinedState$.pipe(takeUntil(this.subscriptionsDestroy$)).subscribe((data: any) => {
            if (data?.acceptView) {
                this.acceptView = true;
            }
        });
    }

    public onLink() {
        this.modalService.toggleModal('privacy-policy', false);
        this.modalService.toggleModal('update-terms-and-conditions', false);
        this.modalService.toggleModal('decline-notification', false);
        this.modalService.toggleModal(ModalIds.termsAndConditions, true, {
            isUpdateTerms: true,
            isDeclined: true
        });
    }

    public close() {
        this.modalService.toggleModal('decline-notification', false);
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }
}
