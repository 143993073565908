import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { take, withLatestFrom } from 'rxjs/operators';

import { MobileService } from '@hot-libs/browser-specific';
import { HotCreateOrderRequest } from '@hot-theme-nx/generated-api';
import { HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { FeatureNames } from '@hot-libs/shared-types';

import { HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services/features.service';
import { authUser } from '@hot-b2b/store/auth/selector';
import { cartItems, cartRemarks } from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsFFC } from '@hot-b2b/store/settings/model';
import { settingsFFC, settingsStore } from '@hot-b2b/store/settings/selector';

@Injectable({
    providedIn: 'root',
})
export class OfflineOrderMessageBuilderService {
    private readonly authUser$: Observable<HotUserExtended>;
    private readonly ffc$: Observable<SettingsFFC>;
    private readonly cartItems$: Observable<HotCartLineItemExtended[]>;
    private readonly cartRemarks$: Observable<HotCreateOrderRequest>;
    private readonly settingsStore$: Observable<SettingsStoreModel>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly featuresService: FeaturesService,
        private readonly mobileService: MobileService
    ) {
        this.authUser$ = store.pipe(select(authUser));
        this.ffc$ = store.pipe(select(settingsFFC));
        this.cartItems$ = store.pipe(select(cartItems));
        this.cartRemarks$ = store.pipe(select(cartRemarks));
        this.settingsStore$ = store.pipe(select(settingsStore));
    }

    private readonly storeIsRequiredForOutlet$: Observable<boolean> = this.featuresService.checkFeatures(
        FeatureNames.StoreIsRequiredForOutlet
    );

    public canBuildOfflineOrderMessage(): boolean {
        let result: boolean;

        this.storeIsRequiredForOutlet$
            .pipe(take(1), withLatestFrom(this.ffc$, this.cartRemarks$))
            .subscribe(
                ([storeIsRequiredForOutlet, catalogFfcModel, remarks]: [
                    boolean,
                    SettingsFFC,
                    HotCreateOrderRequest
                ]) => {
                    const isCatalogFfcSet = !storeIsRequiredForOutlet ? catalogFfcModel && !!catalogFfcModel.id : true;
                    result = isCatalogFfcSet && remarks && !!remarks.preferredDeliveryDate;
                }
            );

        return result;
    }

    public buildOfflineOrderMessage(): string {
        let smsLink: string;

        this.settingsStore$
            .pipe(
                take(1),
                withLatestFrom(
                    this.authUser$,
                    this.ffc$,
                    this.cartRemarks$,
                    this.cartItems$,
                    this.storeIsRequiredForOutlet$
                )
            )
            .subscribe(
                ([settingsStoreModel, userModel, catalogFfcModel, remarks, cartItemModels, storeIsRequiredForOutlet]: [
                    SettingsStoreModel,
                    HotUserExtended,
                    SettingsFFC,
                    HotCreateOrderRequest,
                    HotCartLineItemExtended[],
                    boolean
                ]) => {
                    const phoneNumber: string = settingsStoreModel.offlineOrderSmsPhoneNumber;

                    let messagePrefix = '';
                    if (settingsStoreModel.offlineOrderMessagePrefix) {
                        messagePrefix = settingsStoreModel.offlineOrderMessagePrefix + ' ';
                    }

                    const userId: string = userModel.id;
                    const fulfillmentCenterId: string = catalogFfcModel.id;

                    let preferredDeliveryDate = '';
                    if (remarks.preferredDeliveryDate) {
                        const dateValue = remarks.preferredDeliveryDate;
                        preferredDeliveryDate = moment(dateValue).format('YYYY-MM-DD');
                    }

                    const joinedCartItems = cartItemModels
                        .filter((cartItem: HotCartLineItemExtended) => !cartItem.isGift)
                        .map((cartItem: HotCartLineItemExtended) => `${cartItem.sku}:${cartItem.quantity}`)
                        .join(';');

                    let ffc = '';
                    if (!storeIsRequiredForOutlet) {
                        ffc = `FFC:${fulfillmentCenterId};`;
                    }

                    const messageBody = `${messagePrefix}USR:${userId};${ffc}PDD:${preferredDeliveryDate};${joinedCartItems}`;

                    smsLink = this.buildSmsLink(phoneNumber, messageBody);
                }
            );

        return smsLink;
    }

    private buildSmsLink(phoneNumber: string, messageBody: string): string {
        const mobileOperatingSystem: string = this.mobileService.getMobileOperatingSystem();
        const encodedMessageBody: string = encodeURIComponent(messageBody);

        return mobileOperatingSystem === 'iOS'
            ? `sms://${phoneNumber}/&body=${encodedMessageBody}`
            : `sms:${phoneNumber}?body=${encodedMessageBody}`;
    }
}
