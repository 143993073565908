import { Component, Input, OnInit } from '@angular/core';
import { CartDeleteCoupon, CartSetCoupon } from '@hot-b2b/store/cart/actions';
import { cartData } from '@hot-b2b/store/cart/selector';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { CouponModel } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';
import { Store,select } from '@ngrx/store';
import { Coupon } from 'libs/generated-api/src/lib/models/coupon';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HotCartExtended } from '../../models';
import { ModalService } from '../../services';

@Component({
  selector: 'hot-voucher-list',
  templateUrl: './voucher-list.component.html'
})
export class VoucherListComponent implements OnInit {
  @Input() public voucherItems:CouponModel[];

  public cartData$ : Observable<HotCartExtended>;
  private readonly subscriptionsDestroy$ = new Subject<boolean>();
  public coupon: Coupon = {
    appliedSuccessfully: false,
    code: '',
    };
  public usedVoucherItem: CouponModel;
  private _isRedeeming= false;
  public voucherModal$: Observable<ModalsState>;

  constructor(private readonly store: Store<AppState>, private readonly modalService: ModalService) { 
    this.cartData$ = this.store.pipe(select(cartData));
    this.voucherModal$ = this.store.pipe(select(selectModalState(ModalIds.voucherModal)));
  }

  public ngOnInit(): void {
    this.checkAppliedCoupons();
  }

  public checkAppliedCoupons(){
    this.cartData$.pipe(
      filter((cart) => !!cart.id),
      takeUntil(this.subscriptionsDestroy$)
    )
    .subscribe((cart: HotCartExtended) => {
      this.coupon = cart.coupons[0]
      this.usedVoucherItem = {
        ...this.coupon?.couponDetail,
      code: this.coupon?.code,
      appliedSuccessfully: this.coupon?.appliedSuccessfully,
      };
      if (this._isRedeeming) {
        if (!this.coupon.appliedSuccessfully) {
            this.modalService.toggleModal(ModalIds.voucherModal, true, { isError: true, usedVoucherItem: this.usedVoucherItem });
            this.store.dispatch(new CartDeleteCoupon());
        } else {
            this.modalService.toggleModal(ModalIds.voucherModal, true, { usedVoucherItem: this.usedVoucherItem });
        }
        this._isRedeeming = false;
    }
    })
  }
  public useVoucher(voucher:CouponModel): void {
    this._isRedeeming = true;
    this.store.dispatch(new CartSetCoupon(voucher.code));
  }

  public isCouponRedeemed(voucherItem: CouponModel): boolean {
    if (this.coupon?.code) {
        return voucherItem.code.toLowerCase() === this.coupon.code.toLowerCase() && this.coupon.appliedSuccessfully;
    }
    return false;
}

  public ngDestroy(){
    this.subscriptionsDestroy$.next(true);
    this.modalService.toggleModal(ModalIds.voucherModal, false);
  }

}
