import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class CacheClearService {

    deleteCacheByAPIUrl(dataGroupName: any[]): Promise<Promise<boolean>[][]> {
        let dataGroups = [];
        dataGroupName.forEach(element => {
            dataGroups.push({ 'dataGroupName': 'ngsw:/:1:data:' + element.dataGroupName + ':cache', 'url': element.url });
        });
        return caches.keys().then(cacheKeys =>
            Promise.all(
                cacheKeys.map((cacheKey) => {
                    const ngswRegex = /^(ngsw).*/;
                    let dataGroup = [];
                    dataGroup = dataGroups.filter((e) => e.dataGroupName === cacheKey);
                    if (ngswRegex.test(cacheKey) && dataGroup.length) {
                        return caches
                            .open(cacheKey)
                            .then(cache => {
                                return this.cacheDeleteBasedOnUrl(cache, dataGroup);
                            }
                        );
                    }
                })
            )
        );
    }

    cacheDeleteBasedOnUrl(cache, dataGroup) {
        return cache.keys().then(requests => {
            return requests.map(req => {
                dataGroup.forEach(data => {
                    if (req.url.includes(data.url)) {
                        return cache.delete(req);
                    }
                });
            });
        });
    }
}
