import { Injectable } from '@angular/core';
import { AppState } from '@hot-b2b/store/reducers';
import { Store, select, MemoizedSelector } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class StoreService {
    constructor(private readonly store: Store<AppState>) {}

    public getSelector<T>(selector: MemoizedSelector<AppState, T>): T {
        let selectorData: T;
        this.store
            .pipe(select(selector))
            .pipe(take(1))
            .subscribe((_selector: T) => (selectorData = _selector))
            .unsubscribe();

        return selectorData;
    }
}
