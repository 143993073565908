import { Component,Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'promotion-refresh-modal',
  templateUrl: './promotion-refresh-modal.component.html'
})
export class PromotionRefreshModalComponent  {
  public skuNameStr: string = '';

  @Input() promoList;
  @Input() placeOrderWithReevaluatedCart: boolean;
  @Output() public promoRefreshAction = new EventEmitter();

  ngOnInit(): void {
    if (this.placeOrderWithReevaluatedCart) {
      this.getSkuNameStr();
    }
  }

  public doAction(action) {
    this.promoRefreshAction.emit(action);
  }
  public getSkuNameStr(): string {
    if (this.promoList.errorCodes[0] === 'PromoExpired' || this.promoList.errorCodes[0] === 'DiscountExpired') {
      this.skuNameStr += this.promoList.errors[0];
      if (this.promoList.errorCodes[1] === 'PromoExpired' || this.promoList.errorCodes[1] === 'DiscountExpired') {
        this.skuNameStr += ', ' + this.promoList.errors[1];
      }
    }
    return this.skuNameStr;
  }

}
