import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';


import { User } from '@hot-theme-nx/generated-api';

import { IRootState } from 'libs/common-api/src/lib/store';
import { getCurrentUser } from 'libs/common-api/src/lib/store/root.selector';
import packageContents from '@package-json';
import { TelemetryEventType } from '@hot-libs/shared-types';
import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    private appInsights: ApplicationInsights;
    private userType: string;

    constructor(public readonly store: Store<IRootState>) {}

    public initialize(instrumentationKey: string): void {
        if (!!instrumentationKey) {
            this.store.select(getCurrentUser).subscribe((user: User) => {
                this.appInsights = new ApplicationInsights({
                    config: {
                        instrumentationKey,
                        enableAutoRouteTracking: true,
                    },
                });
             (this.appInsights.context.application as any) = { ver: packageContents.version, build: '' };             
                this.appInsights.loadAppInsights();
                this.updateAuthenticatedUserContext(user);
            });
        }
    }

    public setUser(currentUser: User): void {
        if (this.appInsights) {
            this.appInsights.setAuthenticatedUserContext(currentUser.id, currentUser.contactId);
        }
    }

    public clearUser(): void {
        if (this.appInsights) {
            this.appInsights.clearAuthenticatedUserContext();
        }
    }

    public startTrackingPage(eventType: TelemetryEventType): void {
        if (!this.appInsights?.context) {
            return;
        }

        const eventName = `${eventType}`;
        this.appInsights.startTrackPage(eventName);
    }

    public endTrackingPage(url: string, eventType: TelemetryEventType): void {
        if (!this.appInsights?.context) {
            return;
        }

        this.appInsights.stopTrackPage(`${eventType}`, url, { userType: this.userType });
    }

    public startTrackingEvent(eventType: TelemetryEventType): void {
        if (!this.appInsights?.context) {
            return;
        }

        const eventName = `${eventType}`;
        this.appInsights.context.telemetryTrace.name = eventName;
        this.appInsights.startTrackEvent(eventName);
    }

    public endTrackingEvent(eventType: TelemetryEventType): void {
        if (!this.appInsights?.context) {
            return;
        }

        this.appInsights.stopTrackEvent(`${eventType}`, { userType: this.userType });
    }

    public trackEvent(eventType: TelemetryEventType, params?: any): void {
        if (!this.appInsights?.context) {
            return;
        }

        const eventName: IEventTelemetry = { name: eventType.toString() };

        this.appInsights.trackEvent(eventName, params);
    }

    private updateAuthenticatedUserContext(currentUser?: User): void {
        if (currentUser) {
            this.appInsights.setAuthenticatedUserContext(currentUser.id, currentUser.contactId, true);
            this.userType = currentUser.userType;
        } else {
            this.appInsights.clearAuthenticatedUserContext();
            this.userType = null;
        }
    }
}
