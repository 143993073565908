import { Injectable } from '@angular/core';
import { Observable, merge, fromEvent, of } from 'rxjs';
import { mapTo, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConnectionService {

    constructor() {
        this.checkNetworkConnection();
    }

    public appIsOnline$: Observable<boolean>;

    public monitor(): Observable<boolean> {
        return merge(
            fromEvent(window, 'offline').pipe(mapTo(false)),
            fromEvent(window, 'online').pipe(mapTo(true)),
            of(navigator.onLine)
        );
    }

    public checkNetworkConnection() {

        if (!window || !navigator || !('onLine' in navigator)) return;

        this.appIsOnline$ = merge(
            of(null),
            fromEvent(window, 'offline'),
            fromEvent(window, 'online')
        ).pipe(map(() => navigator.onLine))

    }

}
