import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { take, takeUntil,map } from 'rxjs/operators';

import { HotFulfillmentCenter } from '@hot-theme-nx/generated-api';
import {cartItems} from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { warehouses } from '@hot-b2b/store/warehouses/selector';
import { StorageKeys, ModalIds } from '@hot-libs/shared-types';
import { FeaturesService, ModalService, WarehouseService } from 'apps/hot-b2b/src/app/shared/services';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { HotCartExtended } from '../../models/cart.model';
import { LineItemsCountService } from '../../services/line-items-count.service';
import { HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

@Component({
    selector: 'hot-warehouse-selector',
    templateUrl: './warehouse-selector.component.html',
})
export class WarehouseSelectorComponent implements OnInit, OnDestroy {
    @ViewChild('search') public search: ElementRef;

    public PATHS = PATHS;
    public dropdownIsOpen: boolean = false;

    public semId: string;
    public notFoundSemId: string;
    public searchString = '';
    private _docClickSubscription: any;
    public fulfillmentCenters: HotFulfillmentCenter[];
    public selectedFfc: HotFulfillmentCenter;
    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    public settings$: Observable<HotSettingsExtended>;
    public distributorChangeModal$: Observable<ModalsState>;
    public changedFfc: HotFulfillmentCenter;
    public cartDetails: HotCartExtended;
    public featureSplitOrdersBySuppliersWhenCheckout: boolean = this.featuresService.SplitOrdersBySuppliersWhenCheckout;
    public enableDistributorChangePopUp: boolean = false;
    public CheckItemCount: boolean = false ;
    public itemsCount$: Observable<number>;
    public cartItems$: Observable<HotCartLineItemExtended[]>;

    constructor(
        private readonly store: Store<AppState>,
        public renderer: Renderer2,
        private readonly warehouseService: WarehouseService,
        private readonly ref: ChangeDetectorRef,
        private readonly modalService: ModalService,
        private readonly featuresService: FeaturesService,
        private readonly lineItemsCountService: LineItemsCountService
    ) {
        this._docClickSubscription = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));
        this.settings$ = this.store.pipe(select(settingsData));
        this.distributorChangeModal$ = this.store.pipe(select(selectModalState(ModalIds.distributorChangeModal)));
    }

    public ngOnInit(): void {
        this.setInitData();
        this.settings$.subscribe((settings: HotSettingsExtended) => {
            this.enableDistributorChangePopUp = settings.enableDistributorChangePopUp;
            this.CheckItemCount = false;
            this.stopPopUp();
        });
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
        this._docClickSubscription();
    }
    private onDocumentClick(e: any): void {
        if (
            !e.target.closest('.w-select') &&
            !matches(e.target, '.w-select__title, .w-select__name') &&
            this.fulfillmentCenters.length > 1
        ) {
            this.clickOutside();
        }
    }
    public clickOnWarehouses() {
        if (!this.dropdownIsOpen && this.fulfillmentCenters.length > 1) {
            this.dropdownIsOpen = true;
            setTimeout(() => {
                this.searchString = '';
                this.search.nativeElement.focus();
            }, 100);
        }
    }
    /**
     * Toggles the dropdown
     */
    public toggleDropdown(isOpen?: boolean): void {
        if (!this.dropdownIsOpen) {
            return;
        }
        setTimeout(() => {
            this.dropdownIsOpen = isOpen || !this.dropdownIsOpen;
            if (this.dropdownIsOpen) {
                this.searchString = '';
                setTimeout(() => this.search.nativeElement.focus());
            }
            this.ref.detectChanges();
        }, 100);
    }

    /**
     * Closes dropdown if click outside
     */
    public clickOutside(): void {
        this.dropdownIsOpen = false;
        this.ref.detectChanges();
    }

    public selectFfc(ffc: HotFulfillmentCenter, event?): void {
        if (event) {
            event.stopPropagation();
        }

        if (this.selectedFfc?.id === ffc?.id) {
            this.dropdownIsOpen = false;
        } else {
            this.selectedFfc = ffc;
            this.warehouseService.reloadedWarehouseFromCatalog = true;
            localStorage.setItem(StorageKeys.redirectUrlFfc, '/brands/all');
            this.warehouseService.setWarehouse(ffc,"warehouse");
            this.warehouseService.selectFulfillmentCenter().pipe(take(1)).subscribe();
            this.dropdownIsOpen = false;
        }
        this.modalService.toggleModal('distributor-change-modal', false);
    }
    public toggleModal(ffc: HotFulfillmentCenter, event?, modalId?: string, state?: boolean) {
        if (state) {
            if (
                this.selectedFfc?.storeId != ffc?.storeId &&
                !this.featureSplitOrdersBySuppliersWhenCheckout &&
                this.enableDistributorChangePopUp && this.CheckItemCount
            ) {
                this.changedFfc = ffc;
                this.modalService.toggleModal(modalId, state);
            } else {
                this.modalService.toggleModal(modalId, false);
                this.selectFfc(ffc, event);
            }
        } else {
            this.modalService.toggleModal(modalId, state);
        }
    }
    public stopPopUp(){
        this.cartItems$ = this.store.pipe(
            select(cartItems),
            map((items: HotCartLineItemExtended[]) =>
                items.filter((item: HotCartLineItemExtended) => !item.isReturnableEmpty)
            )
        );
        this.itemsCount$ = this.lineItemsCountService.getItemsCount(this.cartItems$);
       this.itemsCount$.subscribe((itemCount)=>{
        if(itemCount > 0 ){
            this.CheckItemCount = true;
        }else{
            this.CheckItemCount = false;
        }
       })
    }
    public changeDistributor() {
        this.modalService.toggleModal('distributor-change-modal', false);
        this.selectFfc(this.changedFfc);
    }
    private setInitData() {
        this.modalService.toggleModal('distributor-change-modal', false);
        const _selectedFfc = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter));
        if (!_selectedFfc) {
            this.selectFfc(_selectedFfc);
        } else {
            this.selectedFfc = _selectedFfc;
        }
        this.store
            .pipe(select(warehouses), takeUntil(this.subscriptionsDestroy$))
            .subscribe((fulfillmentCenters: HotFulfillmentCenter[]) => {
                this.fulfillmentCenters = fulfillmentCenters;
            });
    }
}
