/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotLegalAgreement } from '../models/hot-legal-agreement';
@Injectable({
  providedIn: 'root',
})
class HotApiLegalAgreementsService extends __BaseService {
  static readonly getTermsAndConditionsPath = '/storefrontapi/hot/legal-agreements/terms-and-conditions/{language}';
  static readonly getPrivacyPolicyPath = '/storefrontapi/hot/legal-agreements/privacy-policy/{language}';
  static readonly getCookiePolicyPath = '/storefrontapi/hot/legal-agreements/cookie-policy/{language}';
  static readonly getCartDisclaimerPath = '/storefrontapi/hot/legal-agreements/cartdisclaimer/{language}';
  static readonly getDisclaimerPath = '/storefrontapi/hot/legal-agreements/disclaimer/{language}';
  static readonly getOperationProcessPath = '/storefrontapi/hot/legal-agreements/operation-process/{language}';
  static readonly getShipmentServicePath = '/storefrontapi/hot/legal-agreements/shipment-service/{language}';
  static readonly getPaymentServicePath = '/storefrontapi/hot/legal-agreements/payment-service/{language}';
  static readonly getPurchaseContractPath = '/storefrontapi/hot/legal-agreements/purchase-contract/{language}';
  static readonly getRefundPolicyPath = '/storefrontapi/hot/legal-agreements/refund-policy/{language}';
  static readonly getMaintainPolicyPath = '/storefrontapi/hot/legal-agreements/maintain-policy/{language}';
  static readonly getLitigationProcessPath = '/storefrontapi/hot/legal-agreements/litigation-process/{language}';
  static readonly getAgeConfirmationPath = '/storefrontapi/hot/legal-agreements/age-confirmation/{language}';
  static readonly getAnnouncementPath = '/storefrontapi/hot/legal-agreements/announcement/{language}';
  static readonly getSubscriptionPath = '/storefrontapi/hot/legal-agreements/subscription/{language}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param language undefined
   * @return Success
   */
  getTermsAndConditionsResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/terms-and-conditions/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getTermsAndConditions(language: string): __Observable<HotLegalAgreement> {
    return this.getTermsAndConditionsResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getPrivacyPolicyResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/privacy-policy/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getPrivacyPolicy(language: string): __Observable<HotLegalAgreement> {
    return this.getPrivacyPolicyResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getCookiePolicyResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/cookie-policy/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getCookiePolicy(language: string): __Observable<HotLegalAgreement> {
    return this.getCookiePolicyResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getCartDisclaimerResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/cartdisclaimer/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getCartDisclaimer(language: string): __Observable<HotLegalAgreement> {
    return this.getCartDisclaimerResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getDisclaimerResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/disclaimer/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getDisclaimer(language: string): __Observable<HotLegalAgreement> {
    return this.getDisclaimerResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getOperationProcessResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/operation-process/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getOperationProcess(language: string): __Observable<HotLegalAgreement> {
    return this.getOperationProcessResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getShipmentServiceResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/shipment-service/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getShipmentService(language: string): __Observable<HotLegalAgreement> {
    return this.getShipmentServiceResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getPaymentServiceResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/payment-service/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getPaymentService(language: string): __Observable<HotLegalAgreement> {
    return this.getPaymentServiceResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getPurchaseContractResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/purchase-contract/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getPurchaseContract(language: string): __Observable<HotLegalAgreement> {
    return this.getPurchaseContractResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getRefundPolicyResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/refund-policy/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getRefundPolicy(language: string): __Observable<HotLegalAgreement> {
    return this.getRefundPolicyResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getMaintainPolicyResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/maintain-policy/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getMaintainPolicy(language: string): __Observable<HotLegalAgreement> {
    return this.getMaintainPolicyResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getLitigationProcessResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/litigation-process/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getLitigationProcess(language: string): __Observable<HotLegalAgreement> {
    return this.getLitigationProcessResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getAgeConfirmationResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/age-confirmation/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getAgeConfirmation(language: string): __Observable<HotLegalAgreement> {
    return this.getAgeConfirmationResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getAnnouncementResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/announcement/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getAnnouncement(language: string): __Observable<HotLegalAgreement> {
    return this.getAnnouncementResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }

  /**
   * @param language undefined
   * @return Success
   */
  getSubscriptionResponse(language: string): __Observable<__StrictHttpResponse<HotLegalAgreement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/legal-agreements/subscription/${encodeURIComponent(String(language))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLegalAgreement>;
      })
    );
  }
  /**
   * @param language undefined
   * @return Success
   */
  getSubscription(language: string): __Observable<HotLegalAgreement> {
    return this.getSubscriptionResponse(language).pipe(
      __map(_r => _r.body as HotLegalAgreement)
    );
  }
}

module HotApiLegalAgreementsService {
}

export { HotApiLegalAgreementsService }
