import { Component,Inject,Renderer2,Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import { Router} from '@angular/router';
import { Observable} from 'rxjs';
import { ModalIds} from '@hot-libs/shared-types';
import { HotUserExtended } from '@hot-libs/shared-models';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { HotApiSecurityService } from '@hot-theme-nx/generated-api';
import { AppState } from '@hot-b2b/store/reducers';
import { authUser } from '@hot-b2b/store/auth/selector';
import { CartService } from '../../../../cart/services';
import { UserService } from 'apps/hot-b2b/src/app/account/services';
import { EventEmitter } from 'events';
import { ModalService } from '../../../services';

interface ITutorialCompleted {
    id?: string;
    completedDate?: Date;
}

@Component({
    selector: 'hot-remind-me-later-modal',
    templateUrl: './remind-me-later-modal.component.html',
})
export class RemindMeLaterModalComponent {
 
    public authUser$: Observable<HotUserExtended>;
    public showPopUp: boolean;
    @Output() public closeRemindMeLater = new EventEmitter();
    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly renderer: Renderer2,
        private readonly userService: UserService,
        private readonly hotApiSecurityService: HotApiSecurityService,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly cartService: CartService,
        private readonly modalService:ModalService
    ) {
        this.authUser$ = this.store.pipe(select(authUser));
    }
      public startPopUp(state: boolean): void {
       
         if(state)
         {
             this.showPopUp=true;
            this.modalService.toggleModal(ModalIds.remindMeLaterModal, false);
             this.router.navigateByUrl('/profile?section=account');
         }  
         else{
            this.showPopUp=false;
            this.modalService.toggleModal(ModalIds.remindMeLaterModal, false);
         }
      }

}
