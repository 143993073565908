import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { IframeRedirectorComponent, NotFoundComponent, EazleMigrationErrorScreenComponent } from 'apps/hot-b2b/src/app/core/components';
import { AuthenticationGuard } from 'apps/hot-b2b/src/app/auth/guards';
import { IsOnlineGuard } from '../shared/guards/is-online.guard';
import { AzureLoginFailedComponent } from './components/azure-login-failed/azure-login-failed.component';
const routes: Routes = [
    {
        path: 'home',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('../main/main.module').then((m) => m.MainModule),
    },
    { path: '', pathMatch: 'full', redirectTo: 'home' },
    {
        path: 'version',
        loadChildren: () => import('../debug/debug.module').then((m) => m.DebugModule),
    },
    {
        path: 'distributor',
        loadChildren: () =>
            import('../distributor/module/distributor/distributor2.module').then((m) => m.Distributor2Module),
    },
    {
        path: 'sales-rep',
        loadChildren: () =>
            import('../distributor/module/sales-rep/sales-rep.module').then((m) => m.SalesRepModule),
    },
    {
        path: 'vsm',
        loadChildren: () => import('../distributor/module/vsm/vsm.module').then((m) => m.VSMModule),
    },
    //auth module lazy load
    {
        path: '',
        children: [
            {
                path: 'account/sign-in',
                loadChildren: () => import('../auth/module/signin/signin.module').then((m) => m.SignInModule),
            },
            {
                path: 'account',
                loadChildren: () =>
                    import('../auth/module/auth-account/auth-account.module').then((m) => m.AuthAccountModule),
            },
            {
                path: 'self-registration',
                loadChildren: () =>
                    import('../auth/module/self-registration/self-registration.module').then(
                        (m) => m.SelfRegistrationModule
                    ),
            },
            {
                path: 'create-account',
                loadChildren: () =>
                    import('../auth/module/create-account/create-account.module').then((m) => m.CreateAccountModule),
            },
            {
                path: 'signin-oidc',
                loadChildren: () =>
                    import('../auth/module/signin-oidc/signin-oidc.module').then((m) => m.SignInOidcModule),
            },
            {
                path: 'customer-self-registration',
                loadChildren: () =>
                    import('../auth/module/customer-self-registration/customer-self-registration.module').then(
                        (m) => m.CustomerSelfRegistrationModule
                    ),
            },
            {
                path: 'child-account-activated',
                loadChildren: () =>
                    import('../auth/module/child-account-activated/child-account-activated.module').then(
                        (m) => m.ChildAccountActivatedModule
                    ),
            },
            {
                path: 'child-contact-registration',
                loadChildren: () =>
                    import('../auth/module/child-contact-registration/child-contact-registration.module').then(
                        (m) => m.ChildAccountActivationEmailModule
                    ),
            },
            {
                path: 'external-authentication',
                loadChildren: () =>
                    import('../auth/module/external-authentication/external-authentication.module').then(
                        (m) => m.ExternalAuthenticationModule
                    ),
            },
            {
                path: 'authentication-for-external-system',
                loadChildren: () =>
                    import(
                        '../auth/module/authentication-for-external-system/authentication-for-external-system.module'
                    ).then((m) => m.AuthenticationForExternalSystemModule),
            },
        ],
    },
    {
        path: '',
        canActivate: [IsOnlineGuard],
        children: [
            //marketing module lazy loading
            {
                path: 'promotions',
                loadChildren: () =>
                    import('../marketing/module/promotion/promotion.module').then((m) => m.PromotionModule),
            },
            {
                path: 'trends',
                loadChildren: () => import('../marketing/module/insight/insight.module').then((m) => m.InsightModule),
            },
            {
                path: 'vouchers',

                loadChildren: () =>
                    import('../marketing/module/vouchers/vouchers.module').then((m) => m.VouchersModule),
            },
            //catalog module lazy loading
            {
                path: 'brands',
                loadChildren: () => import('../catalog/module/brands/brands.module').then((m) => m.BrandsModule),
            },
            {
                path: 'empties',
                loadChildren: () => import('../catalog/module/empties/empties.module').then((m) => m.EmptiesModule),
            },
            {
                path: 'order-upload-template',
                loadChildren: () =>
                    import('../catalog/module/mass-order-upload/mass-order-upload.module').then(
                        (m) => m.MassOrderUploadModule
                    ),
            },
            //order module lazy loading
            {
                path: 'orders',
                loadChildren: () =>
                    import('../order/module/order-page/order-page.module').then((m) => m.OrderPageModule),
            },
            {
                path: 'group-account-orders',
                loadChildren: () =>
                    import('../order/module/group-account-order/group-account-orders.module').then(
                        (m) => m.GroupAccountOrdersModule
                    ),
            },
            {
                path: 'order-summary',
                loadChildren: () =>
                    import('../order/module/order-summary/order-summary.module').then((m) => m.OrderSummaryModule),
            },
            {
                path: 'pay-on-delivery-orders',
                loadChildren: () =>
                    import('../order/module/pay-on-delivery-orders/pay-on-delivery-orders.module').then(
                        (m) => m.PayOnDeliveryOrdersModule
                    ),
            },
        ],
    },
    {
        path: '',
        children: [
            //Notification module lazy load
            {
                path: 'notifications',
                loadChildren: () => import('../notification/notification.module').then((m) => m.NotificationModule),
            },
        ],
    },
    {
        path: '',
        canActivate: [IsOnlineGuard],
        children: [
            //account view lazy loading
            {
                path: 'account-view',
                loadChildren: () =>
                    import('../account-view/module/account-view-page/account-view-page.module').then(
                        (m) => m.AccountViewPageModule
                    ),
            },
            //account module lazy loading
            {
                path: 'profile',
                loadChildren: () => import('../account/module/profile/profile.module').then((m) => m.ProfileModule),
            },
            {
                path: 'performance',
                loadChildren: () =>
                    import('../account/module/performance/performance.module').then((m) => m.PerformanceModule),
            },
            {
                path: 'open-invoices-history',
                loadChildren: () =>
                    import('../account/module/open-invoices-history/open-invoices-history.module').then(
                        (m) => m.OpenInvoicesModule
                    ),
            },
            {
                path: 'parent-account-management',
                loadChildren: () =>
                    import('../account/module/parent-account-management/parent-account-management.module').then(
                        (m) => m.ParentAccountManagementModule
                    ),
            },
            {
                path: 'performance/sdip',
                loadChildren: () =>
                    import('../account/module/performance-sdip/performance-sdip.module').then(
                        (m) => m.PerformanceSdipModule
                    ),
            },
            //page module lazy loading
            {
                path: 'warehouses',
                loadChildren: () => import('../page/module/warehouse/warehouse.module').then((m) => m.WarehouseModule),
            },
            {
                path: 'feedback',
                loadChildren: () => import('../page/module/feedback/feedback.module').then((m) => m.FeedbackModule),
            },
            {
                path: 'privacy-policy',
                loadChildren: () =>
                    import('../page/module/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
            },
            {
                path: 'cookie-policy',
                loadChildren: () =>
                    import('../page/module/cookie-policy/cookie-policy.module').then((m) => m.CookiePolicyModule),
            },
            //cart module loaded lazily
            {
                path: 'cart',
                loadChildren: () => import('../cart/module/cart/cart2.module').then((m) => m.CartModule2),
            },
            {
                path: 'checkout',
                loadChildren: () => import('../cart/module/checkout/checkout.module').then((m) => m.CheckoutModule),
            },
            {
                path: 'failed/:orderNumber',
                loadChildren: () => import('../cart/module/failed/failed.module').then((m) => m.FailedModule),
            },
            {
                path: 'thank-you/:orderNumber',
                loadChildren: () => import('../cart/module/thank-you/thank-you.module').then((m) => m.ThankYouModule),
            },
            {
                path: 'smart-field',
                loadChildren: () =>
                    import('../cart/module/smart-field/smart-field.module').then((m) => m.SmartFieldModule),
            },
            {
                path: 'payment-process',
                loadChildren: () =>
                    import('../cart/module/payment-process/payment-process.module').then((m) => m.PaymentProcessModule),
            },          
            {
                path: 'payment-process/:orderNumber',
                loadChildren: () =>
                    import('../cart/module/payment-process/payment-process.module').then((m) => m.PaymentProcessModule),
            },
            {
                path: 'retry-payment/:orderNumber',
                loadChildren: () => import('../cart/module/retry-payment/retry-payment.module').then((m) => m.RetryPayamentModuleModule),
            },
            {
                path: 'apm-redirector/:orderNumber/payment-failed',
                loadChildren: () => import('../cart/module/apm-redirector/apm-redirector.module').then((m) => m.ApmRedirectorModule),
            },
            {
                path: 'apm-redirector/:orderNumber/process-apm',
                loadChildren: () => import('../cart/module/apm-redirector/apm-redirector.module').then((m) => m.ApmRedirectorModule),
            },
            {
                path: 'performance/tlp',
                loadChildren: () => import('../account/module/tlp/tlp.module').then((m) => m.TlpModule),
            },
            {
                path: 'performance/promotion-tracking',
                loadChildren: () => import('../account/module/promotion/promotion.module').then((m) => m.PromotionModule),
            },
            {
                path: 'thank-you-page/:orderNumber',
                loadChildren: () =>
                    import('../cart/module/thank-you-page/thank-you-page.module').then((m) => m.ThankYouPageModule),
            },
        ],
    },
    //Order Template Module lazy load
    {
        path: 'order-templates',
        loadChildren: () => import('../order-template/order-template.module').then((m) => m.OrderTemplateModule),
    },
    {
            path: 'order-suggestions',
            loadChildren: () =>import('../order-suggestions/order-suggestions.module').then((m) => m.OrderSuggestionsModule),
    },
    {
        path: 'order-suggestions/:weekNumber',
        loadChildren: () =>import('../order-suggestions/order-suggestions.module').then((m) => m.OrderSuggestionsModule),
    },
    //Rewards Module lazy load
    {
        path: 'rewards',
        loadChildren: () => import('../rewards/rewards.module').then((m) => m.RewardsModule),
    },
    //Native Module Lazy load
    {
        path: 'select-country',
        loadChildren: () => import('../native/native.module').then((m) => m.NativeModule),
    },
    {
        path: 'digital-payment',
        children: [{ path: '', component: IframeRedirectorComponent }],
    },
    {   
        path: 'eazle-migration-error-screen',
        pathMatch: 'full',
        component: EazleMigrationErrorScreenComponent,
        canActivate: [
            AuthenticationGuard
        ]
    },
    {
        path: 'login-failed',
        component: AzureLoginFailedComponent,
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

const config: ExtraOptions = {
    initialNavigation: 'enabledNonBlocking',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class CoreRoutingModule {}
