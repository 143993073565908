import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'translateFallback',
})
export class TranslateFallbackPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(key: string, fallbackKey: string) {
        const translation = this.translateService.instant(key);

        if (translation !== key) {
            return key;
        }
        return fallbackKey;
    }
}