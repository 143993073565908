import { Component } from '@angular/core';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { Location } from '@angular/common'
import { ProductService } from 'apps/hot-b2b/src/app/shared/services/product.service';



@Component({
    selector: 'hot-clear-cart-from-file-modal',
    templateUrl: './clear-cart-from-file-modal.component.html',
})
export class ClearCartFromFileModal  {
    constructor(
        private readonly modalService: ModalService,
        private readonly productService: ProductService,
        private location: Location
        ) {}
    public closeModal() {
        this.modalService.toggleModal(ModalIds.clearCartFromFileModal, false);
    }

    public confirmClear() {
       this.productService.validateRequest();
    }
   
}
