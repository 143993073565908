import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'withExtensionOrDefault',
})
export class WithExtensionOrDefaultPipe implements PipeTransform {
    public transform(value: number, ext?: string): string {
        const defaultValue = 'N/A';

        if (!value || value <= 0) {
            return defaultValue;
        }

        return `${value} ${ext ?? ''}`;
    }
}
