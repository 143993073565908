/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotCarouselBanner } from '../models/hot-carousel-banner';
import { HotUpdateDistributorSettingsRequest } from '../models/hot-update-distributor-settings-request';
import { HotProductAvailability } from '../models/hot-product-availability';
import { HotOrderSearchResult } from '../models/hot-order-search-result';
import { HotOrderSearchCriteria } from '../models/hot-order-search-criteria';
import { HotOrder } from '../models/hot-order';
import { HotUpdateOrderRequest } from '../models/hot-update-order-request';
import { HotOrderConfirmRequest } from '../models/hot-order-confirm-request';
import { HotOrderRejectRequest } from '../models/hot-order-reject-request';
import { HotDeliveredOrdersStatistics } from '../models/hot-delivered-orders-statistics';
import { HotExportOrderRequest } from '../models/hot-export-order-request';
import { HotDistributorExtendedOrderStatistics } from '../models/hot-distributor-extended-order-statistics';
import { SettingEntry } from '../models/setting-entry';
import { SeoInfo } from '../models/seo-info';
import { PaymentMethod } from '../models/payment-method';
import { Language } from '../models/language';
import { StorePagesInformationMessages } from '../models/store-pages-information-messages';
import { DynamicProperty } from '../models/dynamic-property';
import { HotOrderStatusReasonsWithLanguage } from '../models/hot-order-status-reasons-with-language';
import { HotOrderDispatchResult } from '../models/hot-order-dispatch-result';
import { HotCancelOrderRequest } from '../models/hot-cancel-order-request';
import { HotOperationResult } from '../models/hot-operation-result';
import { HotConfirmOrderDeliveryRequest } from '../models/hot-confirm-order-delivery-request';
@Injectable({
  providedIn: 'root',
})
class HotApiDistributorsService extends __BaseService {
  static readonly getDistributorCarouselBannersPath = '/storefrontapi/hot/distributor/marketing/distributor-carousel-banners';
  static readonly updateDistributorSettingsPath = '/storefrontapi/hot/distributor/distributor-settings';
  static readonly getProductAvailabilitiesPath = '/storefrontapi/hot/distributor/product-availabilities';
  static readonly updateProductAvailabilitiesPath = '/storefrontapi/hot/distributor/product-availabilities';
  static readonly searchNewOrdersPath = '/storefrontapi/hot/distributor/orders/search/new';
  static readonly searchConfirmedOrdersPath = '/storefrontapi/hot/distributor/orders/search/confirmed';
  static readonly searchDispatchedOrdersPath = '/storefrontapi/hot/distributor/orders/search/dispatched';
  static readonly searchCompletedOrdersPath = '/storefrontapi/hot/distributor/orders/search/completed';
  static readonly getOrderByIdPath = '/storefrontapi/hot/distributor/orders/{orderId}';
  static readonly updateOrderPath = '/storefrontapi/hot/distributor/orders/{orderId}';
  static readonly confirmOrderPath = '/storefrontapi/hot/distributor/orders/{orderId}/confirm';
  static readonly confirmOrdersPath = '/storefrontapi/hot/distributor/orders/confirm';
  static readonly rejectOrderPath = '/storefrontapi/hot/distributor/orders/{orderId}/reject';
  static readonly shipOrderPath = '/storefrontapi/hot/distributor/orders/{orderId}/ship';
  static readonly recalculateOrderPath = '/storefrontapi/hot/distributor/orders/{orderId}/recalculate';
  static readonly getDeliveredOrdersStatisticsPath = '/storefrontapi/hot/distributor/orders/statistics/delivered';
  static readonly changeSubDistributorUserTypePath = '/storefrontapi/hot/distributor/user-types/current/{userType}';
  static readonly exportNewOrdersToExcelPath = '/storefrontapi/hot/distributor/orders/export/new';
  static readonly exportConfirmedOrdersToExcelPath = '/storefrontapi/hot/distributor/orders/export/confirmed';
  static readonly exportDispatchedOrdersToExcelPath = '/storefrontapi/hot/distributor/orders/export/dispatched';
  static readonly exportCompletedOrdersToExcelPath = '/storefrontapi/hot/distributor/orders/export/completed';
  static readonly exportOrderToPDFPath = '/storefrontapi/hot/distributor/order/export';
  static readonly getDistributorOrdersStatisticsPath = '/storefrontapi/hot/distributor/order/statistics';
  static readonly getOrderRejectionReasonsPath = '/storefrontapi/hot/distributor/orders/rejection-reasons';
  static readonly getOrderPartialDeliveryReasonsPath = '/storefrontapi/hot/distributor/orders/partial-delivery-reasons';
  static readonly shipOrdersPath = '/storefrontapi/hot/distributor/orders/ship';
  static readonly cancelOrderPath = '/storefrontapi/hot/distributor/orders/cancel';
  static readonly confirmOrderDeliveryByDistributorPath = '/storefrontapi/hot/distributor/confirm-delivery';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  getDistributorCarouselBannersResponse(): __Observable<__StrictHttpResponse<Array<HotCarouselBanner>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/distributor/marketing/distributor-carousel-banners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotCarouselBanner>>;
      })
    );
  }
  /**
   * @return Success
   */
  getDistributorCarouselBanners(): __Observable<Array<HotCarouselBanner>> {
    return this.getDistributorCarouselBannersResponse().pipe(
      __map(_r => _r.body as Array<HotCarouselBanner>)
    );
  }

  /**
   * @param body undefined
   */
  updateDistributorSettingsResponse(body?: HotUpdateDistributorSettingsRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/distributor-settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  updateDistributorSettings(body?: HotUpdateDistributorSettingsRequest): __Observable<null> {
    return this.updateDistributorSettingsResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  getProductAvailabilitiesResponse(): __Observable<__StrictHttpResponse<Array<HotProductAvailability>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/distributor/product-availabilities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotProductAvailability>>;
      })
    );
  }
  /**
   * @return Success
   */
  getProductAvailabilities(): __Observable<Array<HotProductAvailability>> {
    return this.getProductAvailabilitiesResponse().pipe(
      __map(_r => _r.body as Array<HotProductAvailability>)
    );
  }

  /**
   * @param body undefined
   */
  updateProductAvailabilitiesResponse(body?: Array<HotProductAvailability>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/product-availabilities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  updateProductAvailabilities(body?: Array<HotProductAvailability>): __Observable<null> {
    return this.updateProductAvailabilitiesResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchNewOrdersResponse(body?: HotOrderSearchCriteria): __Observable<__StrictHttpResponse<HotOrderSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/search/new`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchNewOrders(body?: HotOrderSearchCriteria): __Observable<HotOrderSearchResult> {
    return this.searchNewOrdersResponse(body).pipe(
      __map(_r => _r.body as HotOrderSearchResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchConfirmedOrdersResponse(body?: HotOrderSearchCriteria): __Observable<__StrictHttpResponse<HotOrderSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/search/confirmed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchConfirmedOrders(body?: HotOrderSearchCriteria): __Observable<HotOrderSearchResult> {
    return this.searchConfirmedOrdersResponse(body).pipe(
      __map(_r => _r.body as HotOrderSearchResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchDispatchedOrdersResponse(body?: HotOrderSearchCriteria): __Observable<__StrictHttpResponse<HotOrderSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/search/dispatched`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchDispatchedOrders(body?: HotOrderSearchCriteria): __Observable<HotOrderSearchResult> {
    return this.searchDispatchedOrdersResponse(body).pipe(
      __map(_r => _r.body as HotOrderSearchResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchCompletedOrdersResponse(body?: HotOrderSearchCriteria): __Observable<__StrictHttpResponse<HotOrderSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/search/completed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchCompletedOrders(body?: HotOrderSearchCriteria): __Observable<HotOrderSearchResult> {
    return this.searchCompletedOrdersResponse(body).pipe(
      __map(_r => _r.body as HotOrderSearchResult)
    );
  }

  /**
   * @param orderId undefined
   * @return Success
   */
  getOrderByIdResponse(orderId: string): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/${encodeURIComponent(String(orderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param orderId undefined
   * @return Success
   */
  getOrderById(orderId: string): __Observable<HotOrder> {
    return this.getOrderByIdResponse(orderId).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param params The `HotApiDistributorsService.UpdateOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateOrderResponse(params: HotApiDistributorsService.UpdateOrderParams): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/${encodeURIComponent(String(params.orderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param params The `HotApiDistributorsService.UpdateOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateOrder(params: HotApiDistributorsService.UpdateOrderParams): __Observable<HotOrder> {
    return this.updateOrderResponse(params).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param params The `HotApiDistributorsService.ConfirmOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  confirmOrderResponse(params: HotApiDistributorsService.ConfirmOrderParams): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/${encodeURIComponent(String(params.orderId))}/confirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param params The `HotApiDistributorsService.ConfirmOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  confirmOrder(params: HotApiDistributorsService.ConfirmOrderParams): __Observable<HotOrder> {
    return this.confirmOrderResponse(params).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param body undefined
   */
  confirmOrdersResponse(body?: Array<string>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/confirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  confirmOrders(body?: Array<string>): __Observable<null> {
    return this.confirmOrdersResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `HotApiDistributorsService.RejectOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  rejectOrderResponse(params: HotApiDistributorsService.RejectOrderParams): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/${encodeURIComponent(String(params.orderId))}/reject`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param params The `HotApiDistributorsService.RejectOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  rejectOrder(params: HotApiDistributorsService.RejectOrderParams): __Observable<HotOrder> {
    return this.rejectOrderResponse(params).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param orderId undefined
   * @return Success
   */
  shipOrderResponse(orderId: string): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/${encodeURIComponent(String(orderId))}/ship`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param orderId undefined
   * @return Success
   */
  shipOrder(orderId: string): __Observable<HotOrder> {
    return this.shipOrderResponse(orderId).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param params The `HotApiDistributorsService.RecalculateOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  recalculateOrderResponse(params: HotApiDistributorsService.RecalculateOrderParams): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/${encodeURIComponent(String(params.orderId))}/recalculate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param params The `HotApiDistributorsService.RecalculateOrderParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  recalculateOrder(params: HotApiDistributorsService.RecalculateOrderParams): __Observable<HotOrder> {
    return this.recalculateOrderResponse(params).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param params The `HotApiDistributorsService.GetDeliveredOrdersStatisticsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getDeliveredOrdersStatisticsResponse(params: HotApiDistributorsService.GetDeliveredOrdersStatisticsParams): __Observable<__StrictHttpResponse<HotDeliveredOrdersStatistics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/statistics/delivered`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotDeliveredOrdersStatistics>;
      })
    );
  }
  /**
   * @param params The `HotApiDistributorsService.GetDeliveredOrdersStatisticsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getDeliveredOrdersStatistics(params: HotApiDistributorsService.GetDeliveredOrdersStatisticsParams): __Observable<HotDeliveredOrdersStatistics> {
    return this.getDeliveredOrdersStatisticsResponse(params).pipe(
      __map(_r => _r.body as HotDeliveredOrdersStatistics)
    );
  }

  /**
   * @param userType undefined
   */
  changeSubDistributorUserTypeResponse(userType: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/user-types/current/${encodeURIComponent(String(userType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param userType undefined
   */
  changeSubDistributorUserType(userType: string): __Observable<null> {
    return this.changeSubDistributorUserTypeResponse(userType).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  exportNewOrdersToExcelResponse(body?: HotOrderSearchCriteria): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/export/new`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  exportNewOrdersToExcel(body?: HotOrderSearchCriteria): __Observable<string> {
    return this.exportNewOrdersToExcelResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  exportConfirmedOrdersToExcelResponse(body?: HotOrderSearchCriteria): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/export/confirmed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  exportConfirmedOrdersToExcel(body?: HotOrderSearchCriteria): __Observable<string> {
    return this.exportConfirmedOrdersToExcelResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  exportDispatchedOrdersToExcelResponse(body?: HotOrderSearchCriteria): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/export/dispatched`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  exportDispatchedOrdersToExcel(body?: HotOrderSearchCriteria): __Observable<string> {
    return this.exportDispatchedOrdersToExcelResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  exportCompletedOrdersToExcelResponse(body?: HotOrderSearchCriteria): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/export/completed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  exportCompletedOrdersToExcel(body?: HotOrderSearchCriteria): __Observable<string> {
    return this.exportCompletedOrdersToExcelResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  exportOrderToPDFResponse(body?: HotExportOrderRequest): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/order/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  exportOrderToPDF(body?: HotExportOrderRequest): __Observable<string> {
    return this.exportOrderToPDFResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `HotApiDistributorsService.GetDistributorOrdersStatisticsParams` containing the following parameters:
   *
   * - `Store.Url`:
   *
   * - `Store.TrustedGroups`:
   *
   * - `Store.TimeZone`:
   *
   * - `Store.ThemeName`:
   *
   * - `Store.TaxCalculationEnabled`:
   *
   * - `Store.SubscriptionEnabled`:
   *
   * - `Store.StoreState`:
   *
   * - `Store.Status`:
   *
   * - `Store.SkipDistributorSelection`:
   *
   * - `Store.ShowPriceDecimalDigits`:
   *
   * - `Store.ShowNewOrdersOnDistributorPortal`:
   *
   * - `Store.ShowCreditLimit`:
   *
   * - `Store.Settings`:
   *
   * - `Store.SeoLinksType`:
   *
   * - `Store.SeoInfos`:
   *
   * - `Store.SecureUrl`:
   *
   * - `Store.Region`:
   *
   * - `Store.QuotesEnabled`:
   *
   * - `Store.PreferredDeliveryInterval`:
   *
   * - `Store.PaymentMethods`:
   *
   * - `Store.PaymentMethodSelectionEnabled`:
   *
   * - `Store.Name`:
   *
   * - `Store.MinimumOrderTotal`:
   *
   * - `Store.MinimumOrderQuantity`:
   *
   * - `Store.MaximumOrderQuantity`:
   *
   * - `Store.Languages`:
   *
   * - `Store.IsTodayDelivery`:
   *
   * - `Store.IsSubDistributor`:
   *
   * - `Store.IsRateIncluded`:
   *
   * - `Store.IsOwnedByHeineken`:
   *
   * - `Store.IsOnlinePaymentAvailable`:
   *
   * - `Store.IsB2C`:
   *
   * - `Store.InformationMessages`:
   *
   * - `Store.Id`:
   *
   * - `Store.Host`:
   *
   * - `Store.FixedTaxRate`:
   *
   * - `Store.Email`:
   *
   * - `Store.DynamicProperties`:
   *
   * - `Store.DistributorPhone`:
   *
   * - `Store.DisplayOutOfStockProductsInCustomersCatalog`:
   *
   * - `Store.DisplayIndicatorForOutOfStockProductsInCustomersCatalog`:
   *
   * - `Store.Description`:
   *
   * - `Store.DefaultLanguage.enName`:
   *
   * - `Store.DefaultLanguage.TwoLetterRegionName`:
   *
   * - `Store.DefaultLanguage.TwoLetterLanguageName`:
   *
   * - `Store.DefaultLanguage.ThreeLetterRegionName`:
   *
   * - `Store.DefaultLanguage.ThreeLeterLanguageName`:
   *
   * - `Store.DefaultLanguage.NativeName`:
   *
   * - `Store.DefaultLanguage.IsRightToLeft`:
   *
   * - `Store.DefaultLanguage.IsInvariant`:
   *
   * - `Store.DefaultLanguage.CultureName`:
   *
   * - `Store.DefaultFulfillmentCenterId`:
   *
   * - `Store.DefaultCurrencyCode`:
   *
   * - `Store.CurrenciesCodes`:
   *
   * - `Store.Country`:
   *
   * - `Store.Code`:
   *
   * - `Store.CheckAvailability`:
   *
   * - `Store.Catalog`:
   *
   * - `Store.CalculatedPackageValueSource`:
   *
   * - `Store.BackInStockSubscriptionEnabled`:
   *
   * - `Store.AvailFulfillmentCenterIds`:
   *
   * - `Store.AnonymousUsersAllowed`:
   *
   * - `Store.AdminEmail`:
   *
   * - `StartDate`:
   *
   * - `Page`:
   *
   * - `OutletKeyword`:
   *
   * - `EndDate`:
   *
   * @return Success
   */
  getDistributorOrdersStatisticsResponse(params: HotApiDistributorsService.GetDistributorOrdersStatisticsParams): __Observable<__StrictHttpResponse<HotDistributorExtendedOrderStatistics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.StoreUrl != null) __params = __params.set('Store.Url', params.StoreUrl.toString());
    (params.StoreTrustedGroups || []).forEach(val => {if (val != null) __params = __params.append('Store.TrustedGroups', val.toString())});
    if (params.StoreTimeZone != null) __params = __params.set('Store.TimeZone', params.StoreTimeZone.toString());
    if (params.StoreThemeName != null) __params = __params.set('Store.ThemeName', params.StoreThemeName.toString());
    if (params.StoreTaxCalculationEnabled != null) __params = __params.set('Store.TaxCalculationEnabled', params.StoreTaxCalculationEnabled.toString());
    if (params.StoreSubscriptionEnabled != null) __params = __params.set('Store.SubscriptionEnabled', params.StoreSubscriptionEnabled.toString());
    if (params.StoreStoreState != null) __params = __params.set('Store.StoreState', params.StoreStoreState.toString());
    if (params.StoreStatus != null) __params = __params.set('Store.Status', params.StoreStatus.toString());
    if (params.StoreSkipDistributorSelection != null) __params = __params.set('Store.SkipDistributorSelection', params.StoreSkipDistributorSelection.toString());
    if (params.StoreShowPriceDecimalDigits != null) __params = __params.set('Store.ShowPriceDecimalDigits', params.StoreShowPriceDecimalDigits.toString());
    if (params.StoreShowNewOrdersOnDistributorPortal != null) __params = __params.set('Store.ShowNewOrdersOnDistributorPortal', params.StoreShowNewOrdersOnDistributorPortal.toString());
    if (params.StoreShowCreditLimit != null) __params = __params.set('Store.ShowCreditLimit', params.StoreShowCreditLimit.toString());
    (params.StoreSettings || []).forEach(val => {if (val != null) __params = __params.append('Store.Settings', val.toString())});
    if (params.StoreSeoLinksType != null) __params = __params.set('Store.SeoLinksType', params.StoreSeoLinksType.toString());
    (params.StoreSeoInfos || []).forEach(val => {if (val != null) __params = __params.append('Store.SeoInfos', val.toString())});
    if (params.StoreSecureUrl != null) __params = __params.set('Store.SecureUrl', params.StoreSecureUrl.toString());
    if (params.StoreRegion != null) __params = __params.set('Store.Region', params.StoreRegion.toString());
    if (params.StoreQuotesEnabled != null) __params = __params.set('Store.QuotesEnabled', params.StoreQuotesEnabled.toString());
    if (params.StorePreferredDeliveryInterval != null) __params = __params.set('Store.PreferredDeliveryInterval', params.StorePreferredDeliveryInterval.toString());
    (params.StorePaymentMethods || []).forEach(val => {if (val != null) __params = __params.append('Store.PaymentMethods', val.toString())});
    if (params.StorePaymentMethodSelectionEnabled != null) __params = __params.set('Store.PaymentMethodSelectionEnabled', params.StorePaymentMethodSelectionEnabled.toString());
    if (params.StoreName != null) __params = __params.set('Store.Name', params.StoreName.toString());
    if (params.StoreMinimumOrderTotal != null) __params = __params.set('Store.MinimumOrderTotal', params.StoreMinimumOrderTotal.toString());
    if (params.StoreMinimumOrderQuantity != null) __params = __params.set('Store.MinimumOrderQuantity', params.StoreMinimumOrderQuantity.toString());
    if (params.StoreMaximumOrderQuantity != null) __params = __params.set('Store.MaximumOrderQuantity', params.StoreMaximumOrderQuantity.toString());
    (params.StoreLanguages || []).forEach(val => {if (val != null) __params = __params.append('Store.Languages', val.toString())});
    if (params.StoreIsTodayDelivery != null) __params = __params.set('Store.IsTodayDelivery', params.StoreIsTodayDelivery.toString());
    if (params.StoreIsSubDistributor != null) __params = __params.set('Store.IsSubDistributor', params.StoreIsSubDistributor.toString());
    if (params.StoreIsRateIncluded != null) __params = __params.set('Store.IsRateIncluded', params.StoreIsRateIncluded.toString());
    if (params.StoreIsOwnedByHeineken != null) __params = __params.set('Store.IsOwnedByHeineken', params.StoreIsOwnedByHeineken.toString());
    if (params.StoreIsOnlinePaymentAvailable != null) __params = __params.set('Store.IsOnlinePaymentAvailable', params.StoreIsOnlinePaymentAvailable.toString());
    if (params.StoreIsB2C != null) __params = __params.set('Store.IsB2C', params.StoreIsB2C.toString());
    (params.StoreInformationMessages || []).forEach(val => {if (val != null) __params = __params.append('Store.InformationMessages', val.toString())});
    if (params.StoreId != null) __params = __params.set('Store.Id', params.StoreId.toString());
    if (params.StoreHost != null) __params = __params.set('Store.Host', params.StoreHost.toString());
    if (params.StoreFixedTaxRate != null) __params = __params.set('Store.FixedTaxRate', params.StoreFixedTaxRate.toString());
    if (params.StoreEmail != null) __params = __params.set('Store.Email', params.StoreEmail.toString());
    (params.StoreDynamicProperties || []).forEach(val => {if (val != null) __params = __params.append('Store.DynamicProperties', val.toString())});
    if (params.StoreDistributorPhone != null) __params = __params.set('Store.DistributorPhone', params.StoreDistributorPhone.toString());
    if (params.StoreDisplayOutOfStockProductsInCustomersCatalog != null) __params = __params.set('Store.DisplayOutOfStockProductsInCustomersCatalog', params.StoreDisplayOutOfStockProductsInCustomersCatalog.toString());
    if (params.StoreDisplayIndicatorForOutOfStockProductsInCustomersCatalog != null) __params = __params.set('Store.DisplayIndicatorForOutOfStockProductsInCustomersCatalog', params.StoreDisplayIndicatorForOutOfStockProductsInCustomersCatalog.toString());
    if (params.StoreDescription != null) __params = __params.set('Store.Description', params.StoreDescription.toString());
    if (params.StoreDefaultLanguageEnName != null) __params = __params.set('Store.DefaultLanguage.enName', params.StoreDefaultLanguageEnName.toString());
    if (params.StoreDefaultLanguageTwoLetterRegionName != null) __params = __params.set('Store.DefaultLanguage.TwoLetterRegionName', params.StoreDefaultLanguageTwoLetterRegionName.toString());
    if (params.StoreDefaultLanguageTwoLetterLanguageName != null) __params = __params.set('Store.DefaultLanguage.TwoLetterLanguageName', params.StoreDefaultLanguageTwoLetterLanguageName.toString());
    if (params.StoreDefaultLanguageThreeLetterRegionName != null) __params = __params.set('Store.DefaultLanguage.ThreeLetterRegionName', params.StoreDefaultLanguageThreeLetterRegionName.toString());
    if (params.StoreDefaultLanguageThreeLeterLanguageName != null) __params = __params.set('Store.DefaultLanguage.ThreeLeterLanguageName', params.StoreDefaultLanguageThreeLeterLanguageName.toString());
    if (params.StoreDefaultLanguageNativeName != null) __params = __params.set('Store.DefaultLanguage.NativeName', params.StoreDefaultLanguageNativeName.toString());
    if (params.StoreDefaultLanguageIsRightToLeft != null) __params = __params.set('Store.DefaultLanguage.IsRightToLeft', params.StoreDefaultLanguageIsRightToLeft.toString());
    if (params.StoreDefaultLanguageIsInvariant != null) __params = __params.set('Store.DefaultLanguage.IsInvariant', params.StoreDefaultLanguageIsInvariant.toString());
    if (params.StoreDefaultLanguageCultureName != null) __params = __params.set('Store.DefaultLanguage.CultureName', params.StoreDefaultLanguageCultureName.toString());
    if (params.StoreDefaultFulfillmentCenterId != null) __params = __params.set('Store.DefaultFulfillmentCenterId', params.StoreDefaultFulfillmentCenterId.toString());
    if (params.StoreDefaultCurrencyCode != null) __params = __params.set('Store.DefaultCurrencyCode', params.StoreDefaultCurrencyCode.toString());
    (params.StoreCurrenciesCodes || []).forEach(val => {if (val != null) __params = __params.append('Store.CurrenciesCodes', val.toString())});
    if (params.StoreCountry != null) __params = __params.set('Store.Country', params.StoreCountry.toString());
    if (params.StoreCode != null) __params = __params.set('Store.Code', params.StoreCode.toString());
    if (params.StoreCheckAvailability != null) __params = __params.set('Store.CheckAvailability', params.StoreCheckAvailability.toString());
    if (params.StoreCatalog != null) __params = __params.set('Store.Catalog', params.StoreCatalog.toString());
    if (params.StoreCalculatedPackageValueSource != null) __params = __params.set('Store.CalculatedPackageValueSource', params.StoreCalculatedPackageValueSource.toString());
    if (params.StoreBackInStockSubscriptionEnabled != null) __params = __params.set('Store.BackInStockSubscriptionEnabled', params.StoreBackInStockSubscriptionEnabled.toString());
    (params.StoreAvailFulfillmentCenterIds || []).forEach(val => {if (val != null) __params = __params.append('Store.AvailFulfillmentCenterIds', val.toString())});
    if (params.StoreAnonymousUsersAllowed != null) __params = __params.set('Store.AnonymousUsersAllowed', params.StoreAnonymousUsersAllowed.toString());
    if (params.StoreAdminEmail != null) __params = __params.set('Store.AdminEmail', params.StoreAdminEmail.toString());
    if (params.StartDate != null) __params = __params.set('StartDate', params.StartDate.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OutletKeyword != null) __params = __params.set('OutletKeyword', params.OutletKeyword.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/order/statistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotDistributorExtendedOrderStatistics>;
      })
    );
  }
  /**
   * @param params The `HotApiDistributorsService.GetDistributorOrdersStatisticsParams` containing the following parameters:
   *
   * - `Store.Url`:
   *
   * - `Store.TrustedGroups`:
   *
   * - `Store.TimeZone`:
   *
   * - `Store.ThemeName`:
   *
   * - `Store.TaxCalculationEnabled`:
   *
   * - `Store.SubscriptionEnabled`:
   *
   * - `Store.StoreState`:
   *
   * - `Store.Status`:
   *
   * - `Store.SkipDistributorSelection`:
   *
   * - `Store.ShowPriceDecimalDigits`:
   *
   * - `Store.ShowNewOrdersOnDistributorPortal`:
   *
   * - `Store.ShowCreditLimit`:
   *
   * - `Store.Settings`:
   *
   * - `Store.SeoLinksType`:
   *
   * - `Store.SeoInfos`:
   *
   * - `Store.SecureUrl`:
   *
   * - `Store.Region`:
   *
   * - `Store.QuotesEnabled`:
   *
   * - `Store.PreferredDeliveryInterval`:
   *
   * - `Store.PaymentMethods`:
   *
   * - `Store.PaymentMethodSelectionEnabled`:
   *
   * - `Store.Name`:
   *
   * - `Store.MinimumOrderTotal`:
   *
   * - `Store.MinimumOrderQuantity`:
   *
   * - `Store.MaximumOrderQuantity`:
   *
   * - `Store.Languages`:
   *
   * - `Store.IsTodayDelivery`:
   *
   * - `Store.IsSubDistributor`:
   *
   * - `Store.IsRateIncluded`:
   *
   * - `Store.IsOwnedByHeineken`:
   *
   * - `Store.IsOnlinePaymentAvailable`:
   *
   * - `Store.IsB2C`:
   *
   * - `Store.InformationMessages`:
   *
   * - `Store.Id`:
   *
   * - `Store.Host`:
   *
   * - `Store.FixedTaxRate`:
   *
   * - `Store.Email`:
   *
   * - `Store.DynamicProperties`:
   *
   * - `Store.DistributorPhone`:
   *
   * - `Store.DisplayOutOfStockProductsInCustomersCatalog`:
   *
   * - `Store.DisplayIndicatorForOutOfStockProductsInCustomersCatalog`:
   *
   * - `Store.Description`:
   *
   * - `Store.DefaultLanguage.enName`:
   *
   * - `Store.DefaultLanguage.TwoLetterRegionName`:
   *
   * - `Store.DefaultLanguage.TwoLetterLanguageName`:
   *
   * - `Store.DefaultLanguage.ThreeLetterRegionName`:
   *
   * - `Store.DefaultLanguage.ThreeLeterLanguageName`:
   *
   * - `Store.DefaultLanguage.NativeName`:
   *
   * - `Store.DefaultLanguage.IsRightToLeft`:
   *
   * - `Store.DefaultLanguage.IsInvariant`:
   *
   * - `Store.DefaultLanguage.CultureName`:
   *
   * - `Store.DefaultFulfillmentCenterId`:
   *
   * - `Store.DefaultCurrencyCode`:
   *
   * - `Store.CurrenciesCodes`:
   *
   * - `Store.Country`:
   *
   * - `Store.Code`:
   *
   * - `Store.CheckAvailability`:
   *
   * - `Store.Catalog`:
   *
   * - `Store.CalculatedPackageValueSource`:
   *
   * - `Store.BackInStockSubscriptionEnabled`:
   *
   * - `Store.AvailFulfillmentCenterIds`:
   *
   * - `Store.AnonymousUsersAllowed`:
   *
   * - `Store.AdminEmail`:
   *
   * - `StartDate`:
   *
   * - `Page`:
   *
   * - `OutletKeyword`:
   *
   * - `EndDate`:
   *
   * @return Success
   */
  getDistributorOrdersStatistics(params: HotApiDistributorsService.GetDistributorOrdersStatisticsParams): __Observable<HotDistributorExtendedOrderStatistics> {
    return this.getDistributorOrdersStatisticsResponse(params).pipe(
      __map(_r => _r.body as HotDistributorExtendedOrderStatistics)
    );
  }

  /**
   * @return Success
   */
  getOrderRejectionReasonsResponse(): __Observable<__StrictHttpResponse<Array<HotOrderStatusReasonsWithLanguage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/rejection-reasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotOrderStatusReasonsWithLanguage>>;
      })
    );
  }
  /**
   * @return Success
   */
  getOrderRejectionReasons(): __Observable<Array<HotOrderStatusReasonsWithLanguage>> {
    return this.getOrderRejectionReasonsResponse().pipe(
      __map(_r => _r.body as Array<HotOrderStatusReasonsWithLanguage>)
    );
  }

  /**
   * @return Success
   */
  getOrderPartialDeliveryReasonsResponse(): __Observable<__StrictHttpResponse<Array<HotOrderStatusReasonsWithLanguage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/partial-delivery-reasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotOrderStatusReasonsWithLanguage>>;
      })
    );
  }
  /**
   * @return Success
   */
  getOrderPartialDeliveryReasons(): __Observable<Array<HotOrderStatusReasonsWithLanguage>> {
    return this.getOrderPartialDeliveryReasonsResponse().pipe(
      __map(_r => _r.body as Array<HotOrderStatusReasonsWithLanguage>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  shipOrdersResponse(body?: Array<string>): __Observable<__StrictHttpResponse<Array<HotOrderDispatchResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/ship`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotOrderDispatchResult>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  shipOrders(body?: Array<string>): __Observable<Array<HotOrderDispatchResult>> {
    return this.shipOrdersResponse(body).pipe(
      __map(_r => _r.body as Array<HotOrderDispatchResult>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  cancelOrderResponse(body?: HotCancelOrderRequest): __Observable<__StrictHttpResponse<HotOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/distributor/orders/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrder>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  cancelOrder(body?: HotCancelOrderRequest): __Observable<HotOrder> {
    return this.cancelOrderResponse(body).pipe(
      __map(_r => _r.body as HotOrder)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  confirmOrderDeliveryByDistributorResponse(body?: HotConfirmOrderDeliveryRequest): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/distributor/confirm-delivery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  confirmOrderDeliveryByDistributor(body?: HotConfirmOrderDeliveryRequest): __Observable<HotOperationResult> {
    return this.confirmOrderDeliveryByDistributorResponse(body).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }
}

module HotApiDistributorsService {

  /**
   * Parameters for UpdateOrder
   */
  export interface UpdateOrderParams {
    orderId: string;
    body?: HotUpdateOrderRequest;
  }

  /**
   * Parameters for ConfirmOrder
   */
  export interface ConfirmOrderParams {
    orderId: string;
    body?: HotOrderConfirmRequest;
  }

  /**
   * Parameters for RejectOrder
   */
  export interface RejectOrderParams {
    orderId: string;
    body?: HotOrderRejectRequest;
  }

  /**
   * Parameters for RecalculateOrder
   */
  export interface RecalculateOrderParams {
    orderId: string;
    body?: HotUpdateOrderRequest;
  }

  /**
   * Parameters for GetDeliveredOrdersStatistics
   */
  export interface GetDeliveredOrdersStatisticsParams {
    startDate?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetDistributorOrdersStatistics
   */
  export interface GetDistributorOrdersStatisticsParams {
    StoreUrl?: string;
    StoreTrustedGroups?: Array<string>;
    StoreTimeZone?: string;
    StoreThemeName?: string;
    StoreTaxCalculationEnabled?: boolean;
    StoreSubscriptionEnabled?: boolean;
    StoreStoreState?: any;
    StoreStatus?: string;
    StoreSkipDistributorSelection?: boolean;
    StoreShowPriceDecimalDigits?: boolean;
    StoreShowNewOrdersOnDistributorPortal?: boolean;
    StoreShowCreditLimit?: boolean;
    StoreSettings?: Array<SettingEntry>;
    StoreSeoLinksType?: any;
    StoreSeoInfos?: Array<SeoInfo>;
    StoreSecureUrl?: string;
    StoreRegion?: string;
    StoreQuotesEnabled?: boolean;
    StorePreferredDeliveryInterval?: number;
    StorePaymentMethods?: Array<PaymentMethod>;
    StorePaymentMethodSelectionEnabled?: boolean;
    StoreName?: string;
    StoreMinimumOrderTotal?: number;
    StoreMinimumOrderQuantity?: number;
    StoreMaximumOrderQuantity?: number;
    StoreLanguages?: Array<Language>;
    StoreIsTodayDelivery?: boolean;
    StoreIsSubDistributor?: boolean;
    StoreIsRateIncluded?: boolean;
    StoreIsOwnedByHeineken?: boolean;
    StoreIsOnlinePaymentAvailable?: boolean;
    StoreIsB2C?: boolean;
    StoreInformationMessages?: Array<StorePagesInformationMessages>;
    StoreId?: string;
    StoreHost?: string;
    StoreFixedTaxRate?: number;
    StoreEmail?: string;
    StoreDynamicProperties?: Array<DynamicProperty>;
    StoreDistributorPhone?: string;
    StoreDisplayOutOfStockProductsInCustomersCatalog?: boolean;
    StoreDisplayIndicatorForOutOfStockProductsInCustomersCatalog?: boolean;
    StoreDescription?: string;
    StoreDefaultLanguageEnName?: string;
    StoreDefaultLanguageTwoLetterRegionName?: string;
    StoreDefaultLanguageTwoLetterLanguageName?: string;
    StoreDefaultLanguageThreeLetterRegionName?: string;
    StoreDefaultLanguageThreeLeterLanguageName?: string;
    StoreDefaultLanguageNativeName?: string;
    StoreDefaultLanguageIsRightToLeft?: boolean;
    StoreDefaultLanguageIsInvariant?: boolean;
    StoreDefaultLanguageCultureName?: string;
    StoreDefaultFulfillmentCenterId?: string;
    StoreDefaultCurrencyCode?: string;
    StoreCurrenciesCodes?: Array<string>;
    StoreCountry?: string;
    StoreCode?: string;
    StoreCheckAvailability?: boolean;
    StoreCatalog?: string;
    StoreCalculatedPackageValueSource?: string;
    StoreBackInStockSubscriptionEnabled?: boolean;
    StoreAvailFulfillmentCenterIds?: Array<string>;
    StoreAnonymousUsersAllowed?: boolean;
    StoreAdminEmail?: string;
    StartDate?: string;
    Page?: number;
    OutletKeyword?: string;
    EndDate?: string;
  }
}

export { HotApiDistributorsService }
