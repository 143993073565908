import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ModalService } from './../../../services';

import { ModalIds } from '@hot-libs/shared-types';

@Component({
    selector: 'hot-remove-selected-item-cart-confirmation-modal',
    templateUrl: './remove-selected-item-cart-confirmation-modal.component.html',
})
export class RemoveSelectedItemCartConfirmationModalComponent {
    @Input() public innerHtmlTitle: string;
    @Input() public innerHtmlContent: string;
    @Output() public removeItems = new EventEmitter();

    constructor(private readonly modalService: ModalService) {}

    public close(): void {
        this.modalService.toggleModal(ModalIds.removeSelectedItemCartConfirmationModal, false);
    }
}
