import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HotLanguage } from '@hot-theme-nx/generated-api';

import { AppState } from '@hot-b2b/store/reducers';
import { settingsLanguageCurrent } from '@hot-b2b/store/settings/selector';
import { ICalendarConfig } from '@hot-libs/shared-models';
import moment from 'moment';
import  dayjs  from 'dayjs';
import { ISelectionEvent } from 'ng2-date-picker';

@Component({
    selector: 'hot-calendar',
    templateUrl: './calendar.component.html',
})
export class CalendarComponent implements OnInit {
    @Input() public selectedDate: moment.Moment;
    @Input() public isRequired: boolean;
    @Input() public config: ICalendarConfig;
    @Input() public maxDeliveryDaysInterval: number;
    @Output() public setDate = new EventEmitter<moment.Moment>();

    public defaultConfig: ICalendarConfig;

    constructor(private readonly store: Store<AppState>, @Inject(DOCUMENT) private readonly _document: Document) {
        this.currentLanguage$ = this.store.pipe(select(settingsLanguageCurrent));
    }

    public currentLanguage$: Observable<HotLanguage>;

    public ngOnInit(): void {
        this.initConfig();
        this.initLocale();
        if (this.maxDeliveryDaysInterval && this.maxDeliveryDaysInterval > 0) {
            this.config.max = dayjs().add(this.maxDeliveryDaysInterval, 'days').toString();
        }
    }

    public onChange($event: ISelectionEvent): void {
        const date = $event.date as moment.Moment;
        this.setDate.emit(date);
    }

    private initConfig(): void {
        const isRTL = this._document.documentElement.dir === 'rtl';

        this.defaultConfig = {
            drops: 'down',
            format: 'DD/MM/YYYY',
            icon: false,
            unSelectOnClick: false,
            isDayDisabledCallback: this.dateDisabledCallback,
            disableKeypress: true,
        };

        this.config = this.config ? { ...this.defaultConfig, ...this.config } : this.defaultConfig;

        if (this.config.opens === 'right') {
            this.config.opens = isRTL ? 'left' : 'right';
        } else if (this.config.opens === 'left') {
            this.config.opens = isRTL ? 'right' : 'left';
        }
    }

    private initLocale(): void {
        this.currentLanguage$.subscribe((language: HotLanguage) => {
            if (language) {
                this.config.locale = language.code;

                if (!!this.selectedDate) {
                    this.selectedDate.locale(language.code);
                }
            }
        });
    }

    private readonly dateDisabledCallback = (day: dayjs.Dayjs) => {
        return this.config.availableDates
            ? !this.config.availableDates.find((date: dayjs.Dayjs) => date.isSame(day, 'day'))
            : this.disableDateByMaxMin(day);
    };

    private disableDateByMaxMin(day: dayjs.Dayjs): boolean {
        return (this.config.min && day < this.config.min) || (this.config.max && day > this.config.max)
            ? true
            : undefined;
    }
}
