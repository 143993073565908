/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotCart } from '../models/hot-cart';
import { UpdateCartCommentRequest } from '../models/update-cart-comment-request';
import { HotOrderTemplate } from '../models/hot-order-template';
import { HotLineItem } from '../models/hot-line-item';
import { OfflineOrderResponse } from '../models/offline-order-response';
import { OfflineOrderRequest } from '../models/offline-order-request';
import { HotOperationResult } from '../models/hot-operation-result';
import { HotCartValidationRequest } from '../models/hot-cart-validation-request';
import { HotOfflineOrderParsingResponse } from '../models/hot-offline-order-parsing-response';
import { HotOfflineOrderRequest } from '../models/hot-offline-order-request';
import { HotCreateOrderResponse } from '../models/hot-create-order-response';
import { HotCreateOrderRequest } from '../models/hot-create-order-request';
import { HotItemsRequest } from '../models/hot-items-request';
import { HotCreateBonusPointsOrderRequest } from '../models/hot-create-bonus-points-order-request';
@Injectable({
  providedIn: 'root',
})
class HotApiCartsService extends __BaseService {
  static readonly getCartPath = '/storefrontapi/hot/cart';
  static readonly updateCartCommentPath = '/storefrontapi/hot/cart/comment';
  static readonly saveCartAsTemplatePath = '/storefrontapi/hot/cart/template';
  static readonly addLineItemsPath = '/storefrontapi/hot/cart/items';
  static readonly replaceLineItemsPath = '/storefrontapi/hot/cart/items';
  static readonly removeAllLineItemsPath = '/storefrontapi/hot/cart/items';
  static readonly processOrderOfflinePath = '/storefrontapi/hot/cart/items/offline-order-queue';
  static readonly updateLineItemPath = '/storefrontapi/hot/cart/items/{itemId}';
  static readonly removeLineItemPath = '/storefrontapi/hot/cart/items/{itemId}';
  static readonly updateLineItemsPath = '/storefrontapi/hot/cart/items/bulk';
  static readonly changeLineItemQuantityPath = '/storefrontapi/hot/cart/items/{itemId}/quantity/{quantity}';
  static readonly addCouponPath = '/storefrontapi/hot/cart/coupons/{code}';
  static readonly removeCouponPath = '/storefrontapi/hot/cart/coupons/{code}';
  static readonly removeAllCouponsPath = '/storefrontapi/hot/cart/coupons';
  static readonly getDeliveryDatesPath = '/storefrontapi/hot/cart/delivery-dates';
  static readonly validateCartPath = '/storefrontapi/hot/cart/validate';
  static readonly parseOfflineOrderMessagePath = '/storefrontapi/hot/cart/offline-order/parse';
  static readonly createOfflineOrderPath = '/storefrontapi/hot/cart/offline-order';
  static readonly createOrderPath = '/storefrontapi/hot/cart/order';
  static readonly selectItemsPath = '/storefrontapi/hot/cart/items/select';
  static readonly createOrdersPath = '/storefrontapi/hot/cart/orders';
  static readonly createBonusPointsOrderPath = '/storefrontapi/hot/cart/bonus-points-order';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param languageCode undefined
   * @return Success
   */
  getCartResponse(languageCode?: string): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (languageCode != null) __params = __params.set('languageCode', languageCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/cart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param languageCode undefined
   * @return Success
   */
  getCart(languageCode?: string): __Observable<HotCart> {
    return this.getCartResponse(languageCode).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  updateCartCommentResponse(body?: UpdateCartCommentRequest): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/cart/comment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  updateCartComment(body?: UpdateCartCommentRequest): __Observable<HotCart> {
    return this.updateCartCommentResponse(body).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param name undefined
   * @return Success
   */
  saveCartAsTemplateResponse(name?: string): __Observable<__StrictHttpResponse<HotOrderTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (name != null) __params = __params.set('name', name.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/cart/template`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOrderTemplate>;
      })
    );
  }
  /**
   * @param name undefined
   * @return Success
   */
  saveCartAsTemplate(name?: string): __Observable<HotOrderTemplate> {
    return this.saveCartAsTemplateResponse(name).pipe(
      __map(_r => _r.body as HotOrderTemplate)
    );
  }

  /**
   * @param params The `HotApiCartsService.AddLineItemsParams` containing the following parameters:
   *
   * - `languageCode`:
   *
   * - `isConsignment`:
   *
   * - `body`:
   *
   * @return Success
   */
  addLineItemsResponse(params: HotApiCartsService.AddLineItemsParams): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.languageCode != null) __params = __params.set('languageCode', params.languageCode.toString());
    if (params.isConsignment != null) __params = __params.set('isConsignment', params.isConsignment.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/cart/items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param params The `HotApiCartsService.AddLineItemsParams` containing the following parameters:
   *
   * - `languageCode`:
   *
   * - `isConsignment`:
   *
   * - `body`:
   *
   * @return Success
   */
  addLineItems(params: HotApiCartsService.AddLineItemsParams): __Observable<HotCart> {
    return this.addLineItemsResponse(params).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param params The `HotApiCartsService.ReplaceLineItemsParams` containing the following parameters:
   *
   * - `languageCode`:
   *
   * - `isConsignment`:
   *
   * - `body`:
   *
   * @return Success
   */
  replaceLineItemsResponse(params: HotApiCartsService.ReplaceLineItemsParams): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.languageCode != null) __params = __params.set('languageCode', params.languageCode.toString());
    if (params.isConsignment != null) __params = __params.set('isConsignment', params.isConsignment.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/cart/items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param params The `HotApiCartsService.ReplaceLineItemsParams` containing the following parameters:
   *
   * - `languageCode`:
   *
   * - `isConsignment`:
   *
   * - `body`:
   *
   * @return Success
   */
  replaceLineItems(params: HotApiCartsService.ReplaceLineItemsParams): __Observable<HotCart> {
    return this.replaceLineItemsResponse(params).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @return Success
   */
  removeAllLineItemsResponse(): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/storefrontapi/hot/cart/items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @return Success
   */
  removeAllLineItems(): __Observable<HotCart> {
    return this.removeAllLineItemsResponse().pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  processOrderOfflineResponse(body?: Array<OfflineOrderRequest>): __Observable<__StrictHttpResponse<Array<OfflineOrderResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/cart/items/offline-order-queue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OfflineOrderResponse>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  processOrderOffline(body?: Array<OfflineOrderRequest>): __Observable<Array<OfflineOrderResponse>> {
    return this.processOrderOfflineResponse(body).pipe(
      __map(_r => _r.body as Array<OfflineOrderResponse>)
    );
  }

  /**
   * @param itemId undefined
   * @return Success
   */
  updateLineItemResponse(itemId: string): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/cart/items/${encodeURIComponent(String(itemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param itemId undefined
   * @return Success
   */
  updateLineItem(itemId: string): __Observable<HotCart> {
    return this.updateLineItemResponse(itemId).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param itemId undefined
   * @return Success
   */
  removeLineItemResponse(itemId: string): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/storefrontapi/hot/cart/items/${encodeURIComponent(String(itemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param itemId undefined
   * @return Success
   */
  removeLineItem(itemId: string): __Observable<HotCart> {
    return this.removeLineItemResponse(itemId).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  updateLineItemsResponse(body?: Array<string>): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/cart/items/bulk`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  updateLineItems(body?: Array<string>): __Observable<HotCart> {
    return this.updateLineItemsResponse(body).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param params The `HotApiCartsService.ChangeLineItemQuantityParams` containing the following parameters:
   *
   * - `quantity`:
   *
   * - `itemId`:
   *
   * - `languageCode`:
   *
   * @return Success
   */
  changeLineItemQuantityResponse(params: HotApiCartsService.ChangeLineItemQuantityParams): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.languageCode != null) __params = __params.set('languageCode', params.languageCode.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/cart/items/${encodeURIComponent(String(params.itemId))}/quantity/${encodeURIComponent(String(params.quantity))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param params The `HotApiCartsService.ChangeLineItemQuantityParams` containing the following parameters:
   *
   * - `quantity`:
   *
   * - `itemId`:
   *
   * - `languageCode`:
   *
   * @return Success
   */
  changeLineItemQuantity(params: HotApiCartsService.ChangeLineItemQuantityParams): __Observable<HotCart> {
    return this.changeLineItemQuantityResponse(params).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param code undefined
   * @return Success
   */
  addCouponResponse(code: string): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/cart/coupons/${encodeURIComponent(String(code))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param code undefined
   * @return Success
   */
  addCoupon(code: string): __Observable<HotCart> {
    return this.addCouponResponse(code).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param code undefined
   * @return Success
   */
  removeCouponResponse(code: string): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/storefrontapi/hot/cart/coupons/${encodeURIComponent(String(code))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param code undefined
   * @return Success
   */
  removeCoupon(code: string): __Observable<HotCart> {
    return this.removeCouponResponse(code).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @return Success
   */
  removeAllCouponsResponse(): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/storefrontapi/hot/cart/coupons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @return Success
   */
  removeAllCoupons(): __Observable<HotCart> {
    return this.removeAllCouponsResponse().pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @return Success
   */
  getDeliveryDatesResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/cart/delivery-dates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @return Success
   */
  getDeliveryDates(): __Observable<Array<string>> {
    return this.getDeliveryDatesResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  validateCartResponse(body?: HotCartValidationRequest): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/cart/validate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  validateCart(body?: HotCartValidationRequest): __Observable<HotOperationResult> {
    return this.validateCartResponse(body).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  parseOfflineOrderMessageResponse(body?: HotOfflineOrderRequest): __Observable<__StrictHttpResponse<HotOfflineOrderParsingResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/cart/offline-order/parse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOfflineOrderParsingResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  parseOfflineOrderMessage(body?: HotOfflineOrderRequest): __Observable<HotOfflineOrderParsingResponse> {
    return this.parseOfflineOrderMessageResponse(body).pipe(
      __map(_r => _r.body as HotOfflineOrderParsingResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  createOfflineOrderResponse(body?: HotOfflineOrderRequest): __Observable<__StrictHttpResponse<HotCreateOrderResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/cart/offline-order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCreateOrderResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  createOfflineOrder(body?: HotOfflineOrderRequest): __Observable<HotCreateOrderResponse> {
    return this.createOfflineOrderResponse(body).pipe(
      __map(_r => _r.body as HotCreateOrderResponse)
    );
  }

  /**
   * @param params The `HotApiCartsService.CreateOrderParams` containing the following parameters:
   *
   * - `languageCode`:
   *
   * - `body`:
   *
   * @return Success
   */
  createOrderResponse(params: HotApiCartsService.CreateOrderParams): __Observable<__StrictHttpResponse<HotCreateOrderResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.languageCode != null) __params = __params.set('languageCode', params.languageCode.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/cart/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCreateOrderResponse>;
      })
    );
  }
  /**
   * @param params The `HotApiCartsService.CreateOrderParams` containing the following parameters:
   *
   * - `languageCode`:
   *
   * - `body`:
   *
   * @return Success
   */
  createOrder(params: HotApiCartsService.CreateOrderParams): __Observable<HotCreateOrderResponse> {
    return this.createOrderResponse(params).pipe(
      __map(_r => _r.body as HotCreateOrderResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  selectItemsResponse(body?: HotItemsRequest): __Observable<__StrictHttpResponse<HotCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/cart/items/select`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCart>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  selectItems(body?: HotItemsRequest): __Observable<HotCart> {
    return this.selectItemsResponse(body).pipe(
      __map(_r => _r.body as HotCart)
    );
  }

  /**
   * @param params The `HotApiCartsService.CreateOrdersParams` containing the following parameters:
   *
   * - `languageCode`:
   *
   * - `body`:
   *
   * @return Success
   */
  createOrdersResponse(params: HotApiCartsService.CreateOrdersParams): __Observable<__StrictHttpResponse<HotCreateOrderResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.languageCode != null) __params = __params.set('languageCode', params.languageCode.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/cart/orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCreateOrderResponse>;
      })
    );
  }
  /**
   * @param params The `HotApiCartsService.CreateOrdersParams` containing the following parameters:
   *
   * - `languageCode`:
   *
   * - `body`:
   *
   * @return Success
   */
  createOrders(params: HotApiCartsService.CreateOrdersParams): __Observable<HotCreateOrderResponse> {
    return this.createOrdersResponse(params).pipe(
      __map(_r => _r.body as HotCreateOrderResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  createBonusPointsOrderResponse(body?: HotCreateBonusPointsOrderRequest): __Observable<__StrictHttpResponse<HotCreateOrderResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/cart/bonus-points-order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCreateOrderResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  createBonusPointsOrder(body?: HotCreateBonusPointsOrderRequest): __Observable<HotCreateOrderResponse> {
    return this.createBonusPointsOrderResponse(body).pipe(
      __map(_r => _r.body as HotCreateOrderResponse)
    );
  }
}

module HotApiCartsService {

  /**
   * Parameters for AddLineItems
   */
  export interface AddLineItemsParams {
    languageCode?: string;
    isConsignment?: boolean;
    body?: Array<HotLineItem>;
  }

  /**
   * Parameters for ReplaceLineItems
   */
  export interface ReplaceLineItemsParams {
    languageCode?: string;
    isConsignment?: boolean;
    body?: Array<HotLineItem>;
  }

  /**
   * Parameters for ChangeLineItemQuantity
   */
  export interface ChangeLineItemQuantityParams {
    quantity: number;
    itemId: string;
    languageCode?: string;
  }

  /**
   * Parameters for CreateOrder
   */
  export interface CreateOrderParams {
    languageCode?: string;
    body?: HotCreateOrderRequest;
  }

  /**
   * Parameters for CreateOrders
   */
  export interface CreateOrdersParams {
    languageCode?: string;
    body?: HotCreateOrderRequest;
  }
}

export { HotApiCartsService }
