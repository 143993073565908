import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CartErrorHandlerService } from '@hot-theme-nx/common-api';
import { CurrencyFormat, HotApiSettingsService, HotOutlet } from '@hot-theme-nx/generated-api';

import { ConfigurationService } from '../../services/configuration.service';

import { CartClearWithApiRequest, CartOfflineClear } from '@hot-b2b/store/cart/actions';
import { cartData, cartFetched, cartItems, cartPending } from '@hot-b2b/store/cart/selector';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine, settingsCurrencyFormat, settingsStore } from '@hot-b2b/store/settings/selector';
import { isApplicationOnline } from '@hot-libs/helpers';
import {
    DeliverySettingsModel,
    SettingsStoreModel,
    SuggestedPromotion,
    SuggestedPromotionProduct,
} from '@hot-libs/shared-models';
import {
    CustomerPermissions,
    ModalIds,
    PageName,
    StorageKeys,
    TelemetryEventType,
    ProductStockStatus,
} from '@hot-libs/shared-types';
import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { PromotionSuggestionsService } from 'apps/hot-b2b/src/app/marketing/services';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import {
    ApplicationInsightsService,
    FeaturesService,
    ModalService,
    ProductService,
} from 'apps/hot-b2b/src/app/shared/services';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { PermissionsService } from 'apps/hot-b2b/src/app/shared/services/permissions.service';
import isEmpty from 'lodash/isEmpty';
import { suggestedPromotions } from '@hot-b2b/store/promotions/selector';
import { authContactOutlet, showPalletCounterForUserGroups } from '@hot-b2b/store/auth/selector';
import { CurrencyFormatPipe } from 'apps/hot-b2b/src/app/shared/pipes';
import { AlertService } from '../../../shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { config } from 'apps/hot-b2b/src/environments/config';
import { Location } from '@angular/common';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { ItemLocationService } from 'apps/hot-b2b/src/app/shared/services/Item-location.service';

@Component({
    selector: 'hot-cart-sidebar',
    templateUrl: './cart-sidebar.component.html',
})
export class CartSidebarComponent implements OnInit, OnDestroy {
    @ViewChild('validationErrorsBlock') private validationErrorsBlock: ElementRef;
    @ViewChild('cartSidebar') private cartSidebar: ElementRef;

    @Input() public isGridViewActive: boolean;
    @Input() public callFromPage: string;
    @Input() public contentLoaded: boolean = false;

    public isNgB2b = () => this.configurationService.getCurrentConfiguration() === 'NG';
    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';

    public cartData$: Observable<HotCartExtended>;
    public cartItems$: Observable<HotCartLineItemExtended[]>;
    public cartPending$: Observable<boolean>;
    public cartFetched$: Observable<boolean>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public deliverySettings$: Observable<DeliverySettingsModel>;
    public clearCartModalState$: Observable<ModalsState>;
    public isHasNoSelectedItems = false;

    public cartItems: HotCartLineItemExtended[];
    public emptiesReturnItems: HotCartLineItemExtended[];
    public pageName = PageName.CARTSIDEBAR;
    public featureOrderTemplates: boolean = this.featuresService.OrderTemplates;
    public featureReturnEmpties: boolean = this.featuresService.ReturnEmpties;
    public featureShowStockLevelOnLineItems: boolean = this.featuresService.ShowStockLevelOnLineItems;
    public settingHideAddEmptiesLink: boolean = this.productService.hideAddEmptiesLink;
    public featureShowPricingIndicatorInCart: boolean = this.featuresService.ShowPricingIndicatorInCart;
    public featureSplitOrdersBySuppliersWhenCheckout: boolean = this.featuresService.SplitOrdersBySuppliersWhenCheckout;
    public settingsAppOnLine$: Observable<boolean>;

    public featureValidateMinimumBackOrderQuantity: boolean = this.featuresService.ValidateMinimumBackOrderQuantity;
    public featureValidateMinimumNonBackOrderQuantity: boolean = this.featuresService
        .ValidateMinimumNonBackOrderQuantity;
    public featureValidateProductLimitOrderQuantity: boolean = this.featuresService.ValidateProductLimitOrderQuantity;
    public readonly currencyFormat$: Observable<CurrencyFormat>;
    public haveCreateOrderPermission: Observable<boolean> = this.permissionsService.checkPermission(
        CustomerPermissions.OrderCreate
    );

    public readonly goToBasketLocalizationKey = 'shared.cart-sidebar.go-to-my-basket';
    public readonly sendOrderLocalizationKey = 'shared.cart-sidebar.send-order';
    public readonly checkoutButtonLocalizationKey = 'shared.cart-sidebar.checkout';
    private sidebarMaxHeigthDefault = 'calc(125vh - 200px - 66px)';
    public sidebarMaxHeigth = this.sidebarMaxHeigthDefault;
    private customSidebarMaxHeightDefault = 'calc(100vh - 200px - 66px)';
    public cartErrors: {};
    public cartCrates: number;
    public cartMinTotal: string;
    public currencyFomatting: CurrencyFormatPipe = new CurrencyFormatPipe();
    public hideInactiveProductsInCart: boolean = false;
    public configIsSelectableItemInCartDisabled = config?.isSelectableItemInCartDisabled;

    private readonly analyticProductLocation = 'sidebar cart';
    private readonly unsubscribe$ = new Subject<boolean>();
    public minimumBackOrderQuantity: number;
    public minimumBackOrderType: string;

    public showPalletCounterForUserGroups$: Observable<boolean>;
    public featureShowPricingIndicatorIsFilterableInCart: boolean = this.featuresService
        .ShowPricingIndicatorInCartFilterable;
    public suggestedPromotions$: Observable<SuggestedPromotion[]>;
    public readonly contactOutlet$: Observable<HotOutlet>;
    private allowOutOfStockProductsInCart = false;
    public isMassOrderUpload: boolean = false;
    public isRedirectButtonVisible: boolean = false;
    public isVisibleAddMore: boolean = false;
    public isUpLg = () => this.screenDimensionService.upLg();

    public scrollbarWidth: number;
    public basketStatus: string;
    public isCheckoutDisabled = false;

    public moqProductErrorBackOrder: {
        backOrder?: string;
        basePackagePack?: string;
        basePackageKeg?: string;
        moqPack?: string;
        moqKeg?: string;
    };

    public moqProductErrorIndividualSku: {
        individualSku?: string;
    };

    public moqCartErrorTotalOrder: {
        moqTotalOrder?: string;
    };
    public moqCartErrorKegOnly: {
        moqKegOnly?: string;
    };
    public moqCartErrorKegAndPack: {
        moqPack?: string;
        moqKeg?: string;
    };
    unitOfMeasure: string;

    constructor(
        private readonly store: Store<AppState>,
        private readonly featuresService: FeaturesService,
        private readonly analyticService: AnalyticService,
        private readonly productService: ProductService,
        private readonly permissionsService: PermissionsService,
        private readonly appInsightsService: ApplicationInsightsService,
        private readonly promotionSuggestionsService: PromotionSuggestionsService,
        private readonly modalService: ModalService,
        public readonly cartErrorService: CartErrorHandlerService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,

        public readonly configurationService: ConfigurationService,
        public readonly router: Router,
        private location: Location,
        private readonly apiSettingsService: HotApiSettingsService,
        public readonly screenDimensionService: ScreenDimensionService,
        private readonly itemlocationService: ItemLocationService
    ) {
        this.cartData$ = this.store.pipe(select(cartData));
        this.cartItems$ = this.store.pipe(select(cartItems));
        this.cartPending$ = this.store.pipe(select(cartPending));
        this.cartFetched$ = this.store.pipe(select(cartFetched));
        this.contactOutlet$ = this.store.pipe(select(authContactOutlet));
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.clearCartModalState$ = this.store.pipe(select(selectModalState(ModalIds.clearCartConfirmation)));
        this.deliverySettings$ = this.getDeliverySettings();
        this.suggestedPromotions$ = this.store.pipe(select(suggestedPromotions));
        this.showPalletCounterForUserGroups$ = this.store.pipe(select(showPalletCounterForUserGroups));
    }

    public navigateToMassOrderPage(callFrom: string): void {
        if (callFrom === 'empty') {
            this.analyticService.sendMassOrderClickEventInfo('empty basket');
        } else {
            this.analyticService.sendMassOrderClickEventInfo('filled basket');
        }
        this.router.navigate(['/order-upload-template']);
    }

    public navigateToPlpPage(): void {
        this.location.back();
        this.analyticService.sendMassOrderAddMoreProductsClickEventInfo();
    }
    public ngOnInit(): void {
        this.onCreateViewDotCargo();

        this.cartItems$.pipe(takeUntil(this.unsubscribe$)).subscribe((cartLineItems: HotCartLineItemExtended[]) => {
            this.cartItems = cartLineItems.filter((item: HotCartLineItemExtended) => !item.isReturnableEmpty);
            this.emptiesReturnItems = cartLineItems.filter((item: HotCartLineItemExtended) => item.isReturnableEmpty);
        });

        this.cartItems$.pipe(takeUntil(this.unsubscribe$)).subscribe((cartLineItems: HotCartLineItemExtended[]) => {
            for (let a of cartLineItems) {
                if(a.validationErrors.length > 0){
                    this.isCheckoutDisabled = true;
                    break;
                }else{
                    this.isCheckoutDisabled = false;
                }
             }
        });
        this.apiSettingsService
            .getSettings()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((response: HotSettingsExtended) => {
                this.checkMassOrderSetting(response);
            });

        this.cartData$
            .pipe(
                filter((cart) => !!cart.id),
                withLatestFrom(this.settingsStore$),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(([cart, _settingsStore]: [HotCartExtended, SettingsStoreModel]) => {
                this.cartErrors = null;
                this.moqProductErrorIndividualSku = null;
                this.moqProductErrorBackOrder = null;
                this.moqCartErrorTotalOrder = null;
                this.moqCartErrorKegOnly = null;
                this.moqCartErrorKegAndPack = null;

                if (cart.items) {
                    this.checkProductStock(cart, _settingsStore);
                }
                this.checkCartError(cart);
            });
        this.stockLevelFeatureOnlineItems();

        this.settingsStore$.pipe(takeUntil(this.unsubscribe$)).subscribe((storeSettings: SettingsStoreModel) => {
            this.allowOutOfStockProductsInCart = storeSettings.displayOutOfStockProductsInCustomersCatalog;
        });

        // To get scrollbar width
        let scrollDiv = document.createElement('div');
        scrollDiv.className = 'scrollbar-measure';
        document.body.appendChild(scrollDiv);
        this.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
        this.cartMinOrderTotal();
    }

    private checkCartError(cart: HotCartExtended) {
        if (
            cart.items &&
            this.featureSplitOrdersBySuppliersWhenCheckout &&
            cart.items.every((item) => item.isSelected === false)
        ) {
            this.cartErrors = {
                ...this.cartErrors,
                noSelectedItem: true,
            };
        }
    }

    private checkProductStock(cart: HotCartExtended, _settingsStore: SettingsStoreModel) {
        this.validateCartErrors(cart);
        if (_settingsStore.showOutOfStockInsteadOfBackOrders) {
            if (cart.items.some((item) => item.stockStatus === ProductStockStatus.NoStock)) {
                this.cartErrors['itemOOS'] = true;
            }
        }
    }

    private checkMassOrderSetting(response: HotSettingsExtended) {
        if (response?.features.find((x) => x.name == 'EnableMassOrderUpload').isActive) {
            this.isMassOrderUpload = true;
        }
    }

    private onCreateViewDotCargo() {
        if (this.callFromPage === PageName.BRAND) {
            this.isRedirectButtonVisible = true;
        } else {
            this.isRedirectButtonVisible = false;
        }

        if (this.callFromPage === PageName.MASSORDER) {
            this.isVisibleAddMore = true;
        } else {
            this.isVisibleAddMore = false;
        }
    }

    public validateCartErrors(cart) {
        if (cart.validationErrors) {
            const transformedErrors = this.cartErrorService.transformErrorsArray(cart.validationErrors);
            this.cartErrors = this.cartErrorService.prepareToolbarData(transformedErrors);
            this.unitOfMeasure = this.cartErrors['unitOfMeasure']
                ? this.translateService.instant(
                      `shared.product.package-types.${this.cartErrors['unitOfMeasure']?.toLowerCase()}`
                  )
                : '';
            //conditions for checking moq and max-oq criteria
            //to be deleted
            if (this.cartErrors['itemsCount'] > cart.itemsQuantity) {
                this.cartCrates = this.cartErrors['itemsCount'] - cart.itemsQuantity;
            }
            if (this.cartErrors['maxItemsCount'] < cart.itemsQuantity) {
                this.cartCrates = cart.itemsQuantity - this.cartErrors['maxItemsCount'];
            }

            if (this.featureShowStockLevelOnLineItems) {
                this.stockLevelOnlineItems(cart);
            }
        }
    }
    public stockLevelOnlineItems(cart) {
        const groupError = this.cartErrorService.processMoqError(cart.validationErrors);

        if (this.featureValidateMinimumBackOrderQuantity || this.featureValidateMinimumNonBackOrderQuantity) {
            this.moqProductErrorBackOrder = groupError.moqProductErrorBackOrder;
            this.moqProductErrorIndividualSku = groupError.moqProductErrorIndividualSku;
        }

        if (this.featureValidateMinimumBackOrderQuantity && this.featureValidateMinimumNonBackOrderQuantity) {
            this.moqCartErrorTotalOrder = groupError.moqCartErrorTotalOrder;
            this.moqCartErrorKegOnly = groupError.moqCartErrorKegOnly;
            this.moqCartErrorKegAndPack = groupError.moqCartErrorKegAndPack;
        }
    }
    public stockLevelFeatureOnlineItems() {
        this.settingsStore$.pipe(takeUntil(this.unsubscribe$)).subscribe((storeSettings: SettingsStoreModel) => {
            this.allowOutOfStockProductsInCart = storeSettings.displayOutOfStockProductsInCustomersCatalog;
            this.hideInactiveProductsInCart = storeSettings.hideInactiveProductsInCart;
        });

        // To get scrollbar width
        let scrollDiv = document.createElement('div');
        scrollDiv.className = 'scrollbar-measure';
        document.body.appendChild(scrollDiv);
        this.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
        this.cartMinOrderTotal();
        if (config.customSidebarMaxHeightDefault) {
            this.sidebarMaxHeigth = this.customSidebarMaxHeightDefault;
        }
    }
    public onHasNoSelectedItems(status: boolean) {
        this.isHasNoSelectedItems = status;
    }
    public cartHasErrors(): Observable<boolean> {
        return this.cartData$.pipe(
            take(1),
            withLatestFrom(this.settingsAppOnLine$),
            map(
                ([cart, applicationIsOnline]: [HotCartExtended, boolean]) =>
                    !cart.items?.length ||
                    (applicationIsOnline && !cart.isValid && !!cart.validationErrors.length) ||
                    cart.items.every(
                        (item) =>
                            item.isUnavailableForCustomers || (!item.isInStock && !this.allowOutOfStockProductsInCart)
                    ) ||
                    (this.hideInactiveProductsInCart &&
                        cart.items.some((item) =>
                            item.validationErrors.some((error) => error.errorCode === 'UnavailableError')
                        ))
            )
        );
    }

    public saveOrderTemplate(): void {
        this.settingsAppOnLine$.pipe(take(1)).subscribe((isOnline: boolean) => {
            if (isOnline) {
                this.modalService.toggleModal(ModalIds.orderTemplates, true);
            }
        });
    }

    public navigate(url: string): void {
        this.suggestedPromotions$.pipe(take(1)).subscribe((suggestedNavigatePromotions: SuggestedPromotion[]) => {
            this.handleNavigateToCheckout(suggestedNavigatePromotions, url);
        });
    }

    private handleNavigateToCheckout(suggestedHandlePromotions: SuggestedPromotion[], url: string): void {
        if (this.featuresService.PromotionSuggestions && suggestedHandlePromotions.length) {
            this.modalService.toggleModal(ModalIds.suggestedPromotions, true);
        } else {
            this.router.navigateByUrl(url);
        }
    }

    public navigateToCheckout(): void {
        this.appInsightsService.trackEvent(TelemetryEventType.CheckoutButtonClick);

        this.cartData$
            .pipe(withLatestFrom(this.settingsAppOnLine$, this.suggestedPromotions$), take(1))
            .subscribe(
                ([data, isOnline, suggestedCartPromotions]: [HotCartExtended, boolean, SuggestedPromotion[]]) => {
                    if (!isEmpty(this.cartErrors)) {
                        document.getElementById('cart-errors').scrollIntoView();
                    }
                    if (!isOnline || (isOnline && isEmpty(this.cartErrors))) {
                        this.cartEmptiesData(data, isOnline, suggestedCartPromotions);
                    }
                }
            );
    }
    public cartEmptiesData(data, isOnline, suggestedCartEmptiesPromotions) {
        const hasEmpties = data.items.some((item: HotCartLineItemExtended) => item.isReturnableEmpty);

        let hasItemsWithReturnableSku: boolean = false;

        for (let item of data.items) {
            if (item.hasOwnProperty('product')) {
                if (item.product.hasOwnProperty('returnableEmptySku')) hasItemsWithReturnableSku = true;
            } else if (item.hasOwnProperty('emptiesDeposit')) {
                hasItemsWithReturnableSku = true;
            }

            if (hasItemsWithReturnableSku) {
                break;
            }
        }
        if (isOnline && this.featureReturnEmpties && hasItemsWithReturnableSku && !hasEmpties) {
            this.modalService.toggleModal(ModalIds.empties, true, { openCheckoutOnModalClose: true });
        } else if (!isOnline || data.isValid || !data.validationErrors?.length) {
            this.handleNavigateToCheckout(suggestedCartEmptiesPromotions, '/checkout');
        }
    }

    public addToCartHandler(product: HotProductExtended): void {
        this.itemlocationService.addItemLocation(product.sku, this.analyticProductLocation);
        this.analyticService.sendAddCartEventInfo([product], this.analyticProductLocation, false, product.addMethod);
        this.productService.sendProductHandler(product, product.quantity);
    }

    public removeFromCartHandler(product: HotProductExtended): void {
        if (product?.isReturnableEmpty) {
            this.productService?.addProductToAutoAddEmptiesRemovedFromCartTracker(product?.sku);
        }
        this.itemlocationService.removeItemLocation(product.sku,  location || this.analyticProductLocation);
        this.analyticService.sendRemoveCartEventInfo(product, this.analyticProductLocation, product.addMethod);
        this.productService.sendProductHandler(product, 0);
    }

    public sendChangeQuantityHandler(product: HotProductExtended): void {
        this.itemlocationService.addItemLocation(product.sku, this.analyticProductLocation);
        this.analyticService.sendAddCartEventInfo([product], this.analyticProductLocation, true, product.addMethod);
        this.productService.sendProductHandler(product, product.quantity);
    }

    public continueShopping(): void {
        this.router.navigateByUrl('/brands/all');
    }

    private getDeliverySettings(): Observable<DeliverySettingsModel> {
        return this.settingsStore$.pipe(
            filter((settings: SettingsStoreModel) => !!settings),
            map((settings: SettingsStoreModel) => {
                const deliverySettings: DeliverySettingsModel = {
                    isTodayDelivery: settings.isTodayDelivery,
                    pagesInformationMessages: settings.pagesInformationMessages,
                    isWeekCalendar: settings.preferredDeliveryDateAsWeekOnCheckoutPage,
                };
                return deliverySettings;
            })
        );
    }

    private printApplicablePromotionsToConsole(): void {
        this.cartItems$
            .pipe(
                switchMap((cartLineItems: HotCartLineItemExtended[]) =>
                    this.promotionSuggestionsService.getSuggestedPromotions(cartLineItems)
                ),
                take(1)
            )
            .subscribe((suggestedApplicablePromotions: SuggestedPromotion[]) => {
                console.log(this.formatApplicablePromotions(suggestedApplicablePromotions));
            });
    }

    private formatApplicablePromotions(suggestedFormatPromotions: SuggestedPromotion[]): string {
        let result: string = '';

        suggestedFormatPromotions.forEach((suggestedPromotion: SuggestedPromotion) => {
            result += `${suggestedPromotion.promotion.name}:\n`;
            suggestedPromotion.products.forEach((suggestedPromotionProduct: SuggestedPromotionProduct) => {
                const suggestedQuantity = suggestedPromotionProduct.suggestedQuantity;
                const promotionProductIdentifier =
                    suggestedPromotionProduct.product.sku || suggestedPromotionProduct.product.productId;
                result +=
                    `- Add ${suggestedQuantity} more items of product '${promotionProductIdentifier}' ` +
                    'to cart to trigger this promotion\n';
            });
        });

        if (!result.length) {
            result = 'Promotion suggestions: no suitable promotions found.';
        }

        return result;
    }

    public toggleModal(modalId: string, state: boolean): void {
        this.modalService.toggleModal(modalId, state);
    }

    public clearCart(): void {
        this.itemlocationService.deleteProductUsingOutletId();
        const isOnline: boolean = isApplicationOnline();
        if (isOnline) {
            this.store.dispatch(new CartClearWithApiRequest());
        } else {
            localStorage.removeItem(StorageKeys.offlineCart);
            this.store.dispatch(new CartOfflineClear());
        }

        this.toggleModal(ModalIds.clearCartConfirmation, false);
    }

    private setSidebarMaxHeight(): void {
        const footerVisibleHeight = window.scrollY + window.innerHeight - document.querySelector('footer').offsetTop;
        if (footerVisibleHeight > 0) {
            this.sidebarMaxHeigth = `calc(125vh - 200px - 56px - ${footerVisibleHeight}px)`;
            if (config.customSidebarMaxHeightDefault) {
                this.sidebarMaxHeigth = `calc(100vh - 200px - 56px - ${footerVisibleHeight}px)`;
            }
        } else {
            this.sidebarMaxHeigth = this.sidebarMaxHeigthDefault;
            if (config.customSidebarMaxHeightDefault) {
                this.sidebarMaxHeigth = this.customSidebarMaxHeightDefault;
            }
        }
    }

    public onScroll() {
        this.setSidebarMaxHeight();
    }

    public scrollToCartErrors(): void {
        this.cartSidebar.nativeElement.scrollTop = this.validationErrorsBlock.nativeElement.offsetTop;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(true);
    }

    public marginOnMouseOver() {
        let temp = '-' + this.scrollbarWidth + 'px';
        if (navigator.userAgent.indexOf('Firefox') != -1) {
            document.getElementById('cartSidebar').style.marginRight = temp;
        }
    }
    public marginOnMouseLeave() {
        document.getElementById('cartSidebar').style.marginRight = '0px';
    }

    //code for calculating the minimum order total to be added to the cart for mot
    public cartMinOrderTotal() {
        this.cartData$
            .pipe(
                filter((cart) => !!cart.id),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((cart: HotCartExtended) => {
                this.currencyFormat$.subscribe((currencyFormat: CurrencyFormat) => {
                    let cartTotal: any = this.currencyFomatting.transform(cart.total, currencyFormat)?.substring(1);
                    let cartMOT = this.cartErrors['minimumOrderTotal']?.substring(1);
                    this.cartMinTotal =
                        currencyFormat.currencySymbol +
                        (cartMOT?.replaceAll(',', '') - cartTotal?.replaceAll(',', ''))?.toLocaleString();
                });
            });
    }
}
