import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ModalService } from '../../../services';

import { ModalsState } from '@hot-b2b/store/modals/model';
import { SuggestedPromotion, SuggestedPromotionProduct } from '@hot-libs/shared-models';
import some from 'lodash/some';
import without from 'lodash/without';
import { take } from 'rxjs/operators';

@Component({
    selector: 'hot-promo-modal',
    templateUrl: './promo-modal.component.html',
})
export class PromoModalComponent {
    @Input() public suggestedPromotions: SuggestedPromotion[];

    @Output() public onClose: EventEmitter<string> = new EventEmitter<string>();
    @Output() public onApply: EventEmitter<any> = new EventEmitter<any>();

    public selectedProducts: SuggestedPromotionProduct[] = [];
    public hasChanges: boolean;

    constructor(private readonly modalService: ModalService) {}

    public productSelected(product: SuggestedPromotionProduct): boolean {
        return some(this.selectedProducts, product);
    }

    public addProductToSelected(product: SuggestedPromotionProduct): void {
        if (!this.productSelected(product)) {
            this.selectedProducts.push(product);
        }
        this.hasChanges = true;
    }

    public removeProductFromSelected(product: SuggestedPromotionProduct): void {
        if (this.productSelected(product)) {
            this.selectedProducts = without(this.selectedProducts, product);
        }
        this.hasChanges = !!this.selectedProducts.length;
    }

    public addAllProductsToSelected(): void {
        this.suggestedPromotions.forEach((suggestedPromotion: SuggestedPromotion) => {
            suggestedPromotion.products.forEach((suggestedProduct: SuggestedPromotionProduct) => {
                this.addProductToSelected(suggestedProduct);
            });
        });
        this.hasChanges = true;
    }

    public removeAllProductsFromSelected(): void {
        this.suggestedPromotions.forEach((suggestedPromotion: SuggestedPromotion) => {
            suggestedPromotion.products.forEach((suggestedProduct: SuggestedPromotionProduct) => {
                this.removeProductFromSelected(suggestedProduct);
            });
        });
        this.hasChanges = false;
    }

    public applyChanges(): void {
        const productsToAdd = this.selectedProducts.map((product: SuggestedPromotionProduct) => ({
            id: product.product.productId,
            quantity: product.suggestedQuantity,
        }));
        this.modalService.modalState$.pipe(take(1)).subscribe((modalState: ModalsState) => {
            this.onApply.emit({ selectedProducts: productsToAdd, redirectUrl: modalState.data?.redirectUrl });
        });
    }

    public close(): void {
        this.modalService.modalState$.subscribe((modalState: ModalsState) => {
            this.onClose.emit(modalState.data?.redirectUrl);
        });
    }
}
