export class CommonConstant {
    public static readonly MAX_PRODUCTS_TOTAL_COUNT = 1000;

    // If value equal 0 ms - will work once:
    public static readonly CATALOG_DATA_RELOAD_INTERVAL = 15 * 60 * 1000; // 15 min
    public static readonly CART_DATA_RELOAD_INTERVAL = 15 * 60 * 1000; // 15 min
    public static readonly ORDERS_DATA_RELOAD_INTERVAL = 15 * 60 * 1000; // 15 min

    public static readonly OPENED_MODALS_COUNT = 'openedModalsCount';

    public static readonly CUT_OFF_TIME = '17:00';
}
