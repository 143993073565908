import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HotVoucher } from '@hot-theme-nx/generated-api';

@Component({
    selector: 'hot-rewards-voucher-tc-modal',
    templateUrl: './rewards-voucher-tc-modal.component.html',
})
export class RewardsVoucherTCModalComponent {
    @Input() public product: HotVoucher;
    @Output() public buttonClose = new EventEmitter();

    public close(): void {
        this.buttonClose.emit();
    }
}
