import { Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';

import { ArbitraryNotification } from '@hot-theme-nx/generated-api';
import { StorageKeys } from '@hot-libs/shared-types';

@Injectable({
    providedIn: 'root',
})
export class ArbitraryNotificationSignalRService {
    private readonly arbitraryNotificationsHubUrl = '/hubs/arbitrary-notifications';
    private readonly newArbitratyNotificationsPushedMethod = 'PushNewArbitraryNotifications';

    private hubConnection: HubConnection;
    private isManualStop = false;

    private readonly newArbitratyNotificationsPushed$ = new Subject<ArbitraryNotification[]>();

    constructor() {
        this.createConnection(this.arbitraryNotificationsHubUrl);
    }

    public startConnection() {
        try {
            this.connectionState();
        } catch (error) {
            console.log(error);
            setTimeout(() => this.startConnection(), 500);
        }

        this.hubConnection.onclose(() => this.onConnectionClose());
    }

    public stopConnection(): void {
        if (this.hubConnection.state === HubConnectionState.Connected) {
            this.isManualStop = true;
            this.hubConnection.off(this.newArbitratyNotificationsPushedMethod);
            this.hubConnection.stop();
        }
    }

    public registerNewArbitraryNotificationsPushEvent(): Observable<ArbitraryNotification[]> {
        return this.newArbitratyNotificationsPushed$.asObservable();
    }

    private connectionState(): void {
        if (this.hubConnection.state === HubConnectionState.Disconnected) {
            this.hubConnection.start().then(() => this.subscribeToNotificationPushed());
        }
    }

    private subscribeToNotificationPushed(): void {
        this.hubConnection.on(this.newArbitratyNotificationsPushedMethod, (notifications: ArbitraryNotification[]) =>
            this.newArbitratyNotificationsPushed$.next(notifications)
        );
    }

    private onConnectionClose(): void {
        if (!this.isManualStop) {
            this.startConnection();
        }
    }

    private createConnection(url: string): void {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(url, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                accessTokenFactory: () =>
                    JSON.parse(localStorage.getItem(StorageKeys.bearerToken)).access_token,
            })
            .build();
    }
}
