import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { HotLanguage } from '@hot-theme-nx/generated-api';

import { authLoggedIn } from '@hot-b2b/store/auth/selector';
import { LoyaltyBannersGet } from '@hot-b2b/store/loyalty/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsStoreGet } from '@hot-b2b/store/settings/actions';
import { settingsLanguageCurrent, settingsLanguages } from '@hot-b2b/store/settings/selector';
import { TutorialsGet } from '@hot-b2b/store/tutorials/actions';
import { asyncLocalStorage } from '@hot-libs/helpers';
import { StorageKeys } from '@hot-libs/shared-types';
import { TranslateService } from '@ngx-translate/core';
import { ValueAccessorProvider } from 'apps/hot-b2b/src/app/shared/internal';
import { StoreService } from 'apps/hot-b2b/src/app/shared/services/store.service';
import { TranslateManagerService } from 'apps/hot-b2b/src/app/shared/services/translate-manager.service';
import { FeedbackTopicsGet } from '@hot-b2b/store/feedback/actions';
import { UserService } from '../../../account/services';
import { MarketingService } from '../../../marketing/services';
import { FeedbackService } from '../../../shared/services';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'lang-select',
    templateUrl: './lang-select.component.html',
    providers: [ValueAccessorProvider(LangSelectComponent)],
})
export class LangSelectComponent {
    @Input() public modifier: string;
    @Input() public disabled: boolean;
    @Input() public reloadLocation: boolean;
    @Input() public pageName: string;

    public settingsLanguages$: Observable<HotLanguage[]>;
    public settingsLanguageCurrent$: Observable<HotLanguage>;
    public currentLanguage;
    public reloadLocationEvent$ = new Subject<boolean>();
    private readonly subscriptionDestroy$ = new Subject<boolean>();

    constructor(
        public translateService: TranslateService,
        private readonly store: Store<AppState>,
        private readonly translateManager: TranslateManagerService,
        private readonly storeService: StoreService,
        private readonly userService: UserService,
        private readonly feedbackService: FeedbackService,
        private readonly marketService: MarketingService
    ) {
        this.settingsLanguages$ = this.store.pipe(select(settingsLanguages));
        this.settingsLanguageCurrent$ = this.store.pipe(select(settingsLanguageCurrent));
        this.settingsLanguageCurrent$.subscribe((data) => {
            this.currentLanguage = data.name;
        });
    }

    public langSelect(item: HotLanguage): void {
        this.currentLanguage = item.name;
        localStorage.setItem(StorageKeys.changeLanguage, '1');

        if (item.code !== this.translateService.currentLang) {
            const clearURLs = [
                this.userService.deleteUserCache(),
                this.marketService.deleteInsightCache(),
                this.marketService.deleteCarouselBannerCache(),
                this.feedbackService.deleteTopicCache()
            ];

            Promise.all(clearURLs).then(() => {
                this.loadNewLang(item);
            }).catch((err) => {
                console.log("Cache not cleared properly - ", err);
            });
        }
    }
    loadNewLang(item: HotLanguage) {
        if (this.storeService.getSelector(authLoggedIn)) {
            this.translateManager.putLanguages(item.code).subscribe(() => {
                this.store.dispatch(new SettingsStoreGet());
                this.store.dispatch(new TutorialsGet());
                this.store.dispatch(new LoyaltyBannersGet());
                this.store.dispatch(new FeedbackTopicsGet());
                this.reloadLocationEvent$.next(true);
            });
        }else {
            this.reloadLocationEvent$.next(true);
        }

        this.translateService.use(item.code);
        asyncLocalStorage.setItem(StorageKeys.currentLanguage, item.code);

        if (this.reloadLocation) {
            this.reloadLocationEvent$.pipe(takeUntil(this.subscriptionDestroy$)).subscribe((val) => {
                if (val) {
                    location.reload();
                }
            })
        }
    }

    public getTrackData() {
        return `{ "data_interaction": "button_click", "button_text": "${this.currentLanguage}","name":"${this.pageName}"} `;
    }
    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }
}
