export enum ECatalogActions {
    CATALOG_UPDATE = 'CATALOG_UPDATE',
    CATALOG_ITEM_REMOVE = 'CATALOG_ITEM_REMOVE',
    CATALOG_ITEM_EDIT = 'CATALOG_ITEM_EDIT',
    CATALOG_ITEM_CHANGE_QUANTITY = 'CATALOG_ITEM_CHANGE_QUANTITY',
    CATALOG_SYNCHRONIZED = 'CATALOG_SYNCHRONIZED',
    CATALOG_SYNCHRONIZED_SUCCESS = 'CATALOG_SYNCHRONIZED_SUCCESS',
    CATALOG_SYNCHRONIZED_SUCCESS_WITH_STORE_ID = 'CATALOG_SYNCHRONIZED_SUCCESS_WITH_STORE_ID',
    CATALOG_WITH_CART_GET = 'CATALOG_WITH_CART_GET',
    CATALOG_WITH_CART_GET_AND_FFC = 'CATALOG_WITH_CART_GET_AND_FFC',
    CATALOG_CHANGE_FFF_ID = 'CATALOG_CHANGE_FFF_ID',
    CATALOG_ITEM_CHANGE_SUBSCRIPTION = 'CATALOG_ITEM_CHANGE_SUBSCRIPTION',
    CATALOG_ITEM_CHANGE_SUBSCRIPTION_SUCCESS = 'CATALOG_ITEM_CHANGE_SUBSCRIPTION_SUCCESS',
    EMPTIES_CATALOG_PENDING = 'EMPTIES_CATALOG_PENDING',
    EMPTIES_CATALOG_FETCHED = 'EMPTIES_CATALOG_FETCHED',
    EMPTIES_CATALOG_SYNCHRONIZED = 'EMPTIES_CATALOG_SYNCHRONIZED',
    EMPTIES_CATALOG_SYNCHRONIZED_SUCCESS = 'EMPTIES_CATALOG_SYNCHRONIZED_SUCCESS',
    CATALOG_PRODUCTS_CLEAR = 'CATALOG_PRODUCTS_CLEAR',
    CATALOG_PRODUCTS_SEARCH = 'CATALOG_PRODUCTS_SEARCH',
    CATALOG_PRODUCTS_SEARCH_SUCCESS = 'CATALOG_PRODUCTS_SEARCH_SUCCESS',
    OFFLINE_CATALOG_PRODUCTS_SEARCH = 'OFFLINE_CATALOG_PRODUCTS_SEARCH',
    OFFLINE_CATALOG_PRODUCTS_SEARCH_SUCCESS = 'OFFLINE_CATALOG_PRODUCTS_SEARCH_SUCCESS',
    OFFLINE_CATALOG_PENDING = 'OFFLINE_CATALOG_PENDING',
    OFFLINE_CATALOG_FETCHED = 'OFFLINE_CATALOG_FETCHED',
    CATALOG_APPLY_MULTI_FILTER = 'CATALOG_APPLY_MULTI_FILTER',
    CATALOG_CLEAR_MULTI_FILTER = 'CATALOG_CLEAR_MULTI_FILTER',
    CATALOG_ITEM_UPDATE = 'CATALOG_ITEM_UPDATE',
    CART_DATA_GET_PENDING = 'CART_DATA_GET_PENDING',
    OFFLINE_CATALOG_FETCHED_FROM_CENTER = 'OFFLINE_CATALOG_FETCHED_FROM_CENTER',
    OFFLINE_CATALOG_PENDING_FROM_CENTER = 'OFFLINE_CATALOG_PENDING_FROM_CENTER',
    CATALOG_PRODUCTS_RELOAD = 'CATALOG_PRODUCTS_RELOAD',
    CATALOG_PRODUCTS_RELOAD_SUCCESS = 'CATALOG_PRODUCTS_RELOAD_SUCCESS',
    CATALOG_CATEGORIES_SEARCH = 'CATALOG_CATEGORIES_SEARCH',
    CATALOG_CATEGORIES_SEARCH_SUCCESS = 'CATALOG_CATEGORIES_SEARCH_SUCCESS',
    CATALOG_APPLY_ADVANCE_FILTER = 'CATALOG_APPLY_ADVANCE_FILTER',
    CATALOG_CLEAR_ADVANCE_FILTER = 'CATALOG_CLEAR_ADVANCE_FILTER',
    CATALOG_PENDING = 'CATALOG_PENDING',
}
