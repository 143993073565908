import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HotLineItem } from '@hot-theme-nx/generated-api';

import { HotProductExtended, ProductAttributesExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { EProductsActions } from '@hot-b2b/store/products/types';

export class ProductsGet implements Action {
    public readonly type = EProductsActions.PRODUCTS_PENDING;
    constructor(public payload: Observable<ProductAttributesExtended | HotProductExtended[]>) {}
}

export class ProductsGetSuccess implements Action {
    public readonly type = EProductsActions.PRODUCTS_FETCHED;
    constructor(public payload: { data: ProductAttributesExtended; cart: HotCartLineItemExtended[] }) {}
}

export class ProductsError implements Action {
    public readonly type = EProductsActions.PRODUCTS_ERROR;
    constructor(public payload: string) {}
}

export class ProductsClear implements Action {
    public readonly type = EProductsActions.PRODUCTS_CLEAR;
}

export class ProductsChangeQuantity implements Action {
    public readonly type = EProductsActions.PRODUCTS_ITEM_CHANGE_QUANTITY;
    constructor(public payload: HotLineItem) {}
}

export class ProductsItemUpdate implements Action {
    public readonly type = EProductsActions.PRODUCTS_ITEM_UPDATE;
    constructor(public payload: HotCartLineItemExtended) {}
}

export class ProductsSynchronized implements Action {
    public readonly type = EProductsActions.PRODUCTS_SYNCHRONIZED;
    constructor(public payload?: HotCartExtended) {}
}

export class ProductsSynchronizedSuccess implements Action {
    public readonly type = EProductsActions.PRODUCTS_SYNCHRONIZED_SUCCESS;
    constructor(public payload: HotCartLineItemExtended[]) {}
}

export class ProductsSynchronizedSuccessWithStoreId implements Action {
    public readonly type = EProductsActions.PRODUCTS_SYNCHRONIZED_SUCCESS_WITH_STORE_ID;
    constructor(public payload: HotCartLineItemExtended[]) {}
}

export type ProductsActions =
    | ProductsGet
    | ProductsGetSuccess
    | ProductsError
    | ProductsClear
    | ProductsChangeQuantity
    | ProductsItemUpdate
    | ProductsSynchronized
    | ProductsSynchronizedSuccess
    | ProductsSynchronizedSuccessWithStoreId;
