import { TutorialsState } from '@hot-b2b/store/tutorials/model';

export const tutorialsInitialState: TutorialsState = {
    pending: false,
    fetched: false,
    data: {
        tutorials: [],
        currentTutorial: null,
        currentStep: null,
        isStarted: false,
        showTutorialWithId:"",
    },
};
