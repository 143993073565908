import { CatalogState } from '@hot-b2b/store/catalog/model';

export const catalogInitialState: CatalogState = {
    pending: false,
    fetched: false,
    offlineCatalogFetched: false,
    data: {
        catalogs: [],
        offlineCatalogs: [],
        emptiesCatalogs: [],
        multiFilter: {
            categoryId: null,
            selectedTypes: [],
            isSoldByHeineken: false,
        },
        advanceFilter: [],
    },
    error: null,
};
