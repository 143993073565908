import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'quantityFormat',
})
export class QuantityFormatPipe implements PipeTransform {
    public transform(value: number, separator: string, decimalNumbers: number): string {
        if (!value) {
            return '0';
        }

        if (!separator) {
            return value.toString();
        }

        let result: string;

        const valueString = value.toString();
        const split = valueString.split(/[.,]/, 2);
        if (split.length > 1) {
            decimalNumbers = decimalNumbers ? decimalNumbers : split[1].length;
            result = split[0] + separator + split[1].substring(0, decimalNumbers);
        } else {
            result = valueString;
        }

        return result;
    }
}
