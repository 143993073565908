import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'asStyleUrl',
})
export class AsStyleUrlPipe implements PipeTransform {
    transform(path: string): string {
        return `url('${path}')`;
    }
}
