import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { OrderDetailsService } from 'apps/hot-b2b/src/app/shared/services';

@Component({
    selector: 'hot-report-issue-confirmation-modal',
    templateUrl: './report-issue-confirmation-modal.component.html',
})
export class ReportIssueConfirmationModalComponent {
    @Output() public buttonReportIssue: EventEmitter<{ isClose: boolean; isReported: boolean }> = new EventEmitter();
    @Input() public issuesForm: UntypedFormGroup;

    constructor(public readonly orderDetailsService: OrderDetailsService) {}

    public reportIssue(): void {
        const formData = this.issuesForm.value;
        formData.productIssues = formData.productIssues.filter(
            (issue) => issue['Issue'] !== '' || issue['Reason'] !== ''
        );

        if (formData.productIssues.length > 0) {
            this.orderDetailsService.reportOrderIssue(this.issuesForm.value).subscribe(() => {
                this.buttonReportIssue.emit({ isClose: true, isReported: true });
            });
        }
    }

    public close(): void {
        this.buttonReportIssue.emit({ isClose: true, isReported: false });
    }

}
