export enum EProductsActions {
    PRODUCTS_PENDING = 'PRODUCTS_PENDING',
    PRODUCTS_FETCHED = 'PRODUCTS_FETCHED',
    PRODUCTS_ERROR = 'PRODUCTS_ERROR',
    PRODUCTS_CLEAR = 'PRODUCTS_CLEAR',
    PRODUCTS_UPDATE = 'PRODUCTS_UPDATE',
    PRODUCTS_ITEM_REMOVE = 'PRODUCTS_ITEM_REMOVE',
    PRODUCTS_ITEM_EDIT = 'PRODUCTS_ITEM_EDIT',
    PRODUCTS_ITEM_CHANGE_QUANTITY = 'PRODUCTS_ITEM_CHANGE_QUANTITY',
    PRODUCTS_ITEM_UPDATE = 'PRODUCTS_ITEM_UPDATE',
    PRODUCTS_SYNCHRONIZED = 'PRODUCTS_SYNCHRONIZED',
    PRODUCTS_SYNCHRONIZED_SUCCESS = 'PRODUCTS_SYNCHRONIZED_SUCCESS',
    PRODUCTS_SYNCHRONIZED_SUCCESS_WITH_STORE_ID = 'PRODUCTS_SYNCHRONIZED_SUCCESS_WITH_STORE_ID',
}
