export class NotificationType {
    public static readonly ORDERSTATUSCHANGED = 'OrderStatusChangedEventNotification';
    public static readonly ORDERCANCELEDBYDISTRIBUTOR = 'OrderCancelledByDistributorEventNotification';
    public static readonly ORDERCANCELEDBYCUSTOMER = 'OrderCancelledByCustomerEventNotification';
    public static readonly ORDERCANCELEDBYCUSTOMERDISTRIBUTOR = 'OrderCancelledByCustomerDistributorEventNotification';
    public static readonly ORDERCREATEDDISTRIBUTOR = 'OrderCreatedDistributorEventNotification';
    public static readonly ORDERPAIDEVENT = 'OrderPaidEventNotification';
    public static readonly PROMOTION = 'PromotionAddEventNotification';
    public static readonly INSIGHT = 'InsightAddEventNotification';
    public static readonly GPSADDEVENT = 'GpsDataAddEventNotification';
    public static readonly GPSCHANGEDEVENT = 'GpsChangedEventNotification';
}
