import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';

import { CurrencyFormat, HotLoyaltyStatement } from '@hot-theme-nx/generated-api';
import { HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { AccountType, StorageKeys } from '@hot-libs/shared-types';

import { ConfigurationService, FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import { LoyaltyService } from 'apps/hot-b2b/src/app/shared/services/loyalty.service';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { authUser } from '@hot-b2b/store/auth/selector';
import { loyalty } from '@hot-b2b/store/loyalty/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine, settingsLoyaltyCurrencyFormat, settingsStore } from '@hot-b2b/store/settings/selector';
import { filter, take, takeUntil } from 'rxjs/operators';
import { OrderCreationService } from '../../services/order-creation.service';
import { OrderSimulation } from '@hot-b2b/store/orders/model';
import { HotCartExtended } from '../../models/cart.model';
import { cartData } from '@hot-b2b/store/cart/selector';
import { orderSimulationFailureErrorCode } from '@hot-b2b/store/orders/selector';

@Component({
    selector: 'hot-base-page',
    templateUrl: './base-page.component.html',
})
export class BasePageComponent implements OnInit {
    @Input() showOrderSimulationSuccess = false;
    @Input() showOrderSimulationError = false;
    @Input() showOrderSimulationSuccessGR = false;
    @Input() showOrderSimulationErrorGR = false;
    @Input() isMobileSidebarVisible = false;
    @Input() isMobileBarHidden = false;
    @Input() isGridStickyFilters: boolean;
    @Input() isGridStickyFiltersClosing: boolean;
    @Input() isNoSideBar: boolean;
    @Input() errorMessageInBanner: any;
    @Input() warningMessageInBanner: any;

    public orderSimulationErrorCodeDetails$: Observable<Array<string>>;
    private readonly subscriptionDestroy$ = new Subject<boolean>();
    public cartData$: Observable<HotCartExtended>;
    public readonly accountType = AccountType;
    public loyaltyStatement$: Observable<HotLoyaltyStatement>;

    public loyaltyCurrencyFormat: CurrencyFormat;
    public authUser$: Observable<HotUserExtended>;
    public settingsLoyaltyCurrencyFormat$: Observable<CurrencyFormat>;
    public settingsAppOnLine$: Observable<boolean>;
    public settingsStore$: Observable<SettingsStoreModel>;

    public isRewardPointsVisible: boolean;
    public isUpLg = () => this.screenDimensionService.upLg();

    public enableGridViewInCatalog: boolean;
    public showSearchProductsBar: boolean = false;

    public featureBuildFulfillmentCentersUsingOutletStores: boolean = this.featuresService
        .BuildFulfillmentCentersUsingOutletStores;

    public featureAdvanceFilterEnabled: boolean = this.featuresService.EnableAdvancedFilters;
    public isGR = () => this.configurationService.getCurrentConfiguration() === 'GR';
    public isCZ = () => this.configurationService.getCurrentConfiguration() === 'CZ';
    public isVN = () => this.configurationService.getCurrentConfiguration() === 'VN';
    public ishidden: boolean = false;
    bannerclose: boolean = false;
    public orderSimulationDetails$: Observable<OrderSimulation>;
    public responseMobile: any;
    public mobileResponse: any;
    public isSuccess: boolean;
    public isFail: boolean;
    cartData: HotCartExtended;
    public validateWarning: any;
    public validateWarningData: any = [];
    public showValidateWarningMessage: boolean = false;
    public errorCode: any;
    public showPaymentFailedError: boolean;
    constructor(
        private readonly store: Store<AppState>,
        public readonly featuresService: FeaturesService,
        public readonly loyaltyService: LoyaltyService,
        public readonly screenDimensionService: ScreenDimensionService,
        public readonly router: Router,
        private readonly configurationService: ConfigurationService,
        public readonly orderCreationService: OrderCreationService,
        private readonly changeDetector: ChangeDetectorRef
    ) {
        this.orderSimulationErrorCodeDetails$ = this.store.pipe(select(orderSimulationFailureErrorCode));
        this.authUser$ = this.store.pipe(select(authUser));
        this.settingsLoyaltyCurrencyFormat$ = this.store.pipe(select(settingsLoyaltyCurrencyFormat));
        this.loyaltyStatement$ = this.store.pipe(select(loyalty));
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.cartData$ = this.store.pipe(select(cartData));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.settingsStore$.pipe(take(1)).subscribe((value: SettingsStoreModel) => {
            this.enableGridViewInCatalog = value.enableGridViewInCatalog;
            this.showSearchProductsBar = value.showSearchProductsBar;
        });
    }

    public ngOnInit(): void {
        this.checkMenu();
        this.bannerclose = JSON.parse(sessionStorage.getItem(StorageKeys.bannerclose));
        this.cartData$
            .pipe(
                filter((cart) => !!cart.id),
                takeUntil(this.subscriptionDestroy$)
            )
            .subscribe((value) => {
                this.validateWarning = value.validationWarnings[0]?.warningCode;
                if (this.validateWarning == 'HotMinimumOrderValueWarning') {
                    this.showValidateWarningMessage = true;
                    this.validateWarningData = value.validationWarnings[0].parameters;
                } else {
                    this.showValidateWarningMessage = false;
                }
            });
    }

    public ngAfterViewInit(): void {
        this.orderSimulationErrorCodeDetails$.subscribe((orderSimulationErrorCode) => {
            this.errorCode = orderSimulationErrorCode;
        });
        this.orderCreationService.currentResponse
            .pipe(takeUntil(this.subscriptionDestroy$))
            .subscribe((res: OrderSimulation) => {
                this.mobileResponse = res;
                this.warningMessageInBanner = res?.taxDetails?.warnings;
                if (this.mobileResponse) {
                    if (res?.taxDetails?.succeeded) {
                        this.isSuccess = true;
                        this.isFail = false;
                    } else {
                        this.isSuccess = false;
                        this.isFail = true;
                    }
                } else {
                    this.isSuccess = false;
                    this.isFail = false;
                }
                if (
                    this.errorCode.includes('ExternalPriceChange') &&
                    !this.errorCode.includes('CartValidationError') &&
                    this.isGR()
                ) {
                    this.isSuccess = true;
                    this.isFail = false;
                }
                this.changeDetector.detectChanges();
            });
            this.warningMessageInBanner = [];
        this.orderCreationService.paymentFailedErrorFlag.pipe(takeUntil(this.subscriptionDestroy$)).subscribe((res) => {
            this.showPaymentFailedError = res;
            this.orderCreationService.callAnalyticEventPOPPaymentError(this.showPaymentFailedError);
            this.changeDetector.detectChanges();
        });
    }

    private checkMenu(): void {
        combineLatest([this.authUser$, this.loyaltyStatement$, this.settingsAppOnLine$]).subscribe(
            ([userAuth, loyaltyStatement, appSettingsOnLine]: [HotUserExtended, HotLoyaltyStatement, boolean]) => {
                this.isRewardPointsVisible = false;

                if (userAuth && loyaltyStatement && appSettingsOnLine) {
                    const loyaltyEnabled = this.loyaltyService.loyaltyBalanceEnable();
                    const subDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);
                    const userType = userAuth.userType || '';

                    this.isRewardPointsVisible =
                        loyaltyEnabled &&
                        (userType === this.accountType.Customer ||
                            (userType === this.accountType.SubDistributor && subDistributorMode === 'Customer'));
                }
            }
        );
    }

    public closeBanner() {
        this.ishidden = true;
        if (this.router.url.includes('/brands')) {
            sessionStorage.setItem(StorageKeys.bannerclose, 'true');
        }
    }

    public isGridViewActive(): boolean {
        const currentView: string = localStorage.getItem(StorageKeys.catalogView);
        return this.isUpLg() && (!currentView || currentView === 'grid') && this.enableGridViewInCatalog;
    }

    public isTutorialActive(): boolean {
        return !!document.getElementsByClassName('tutor').length;
    }
    public retryPaymentNotification() {
        return this.router.url === '/brands/all';
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
        this.subscriptionDestroy$.complete();
    }
}
