import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { StorageKeys } from '@hot-libs/shared-types';

import { SettingsService } from 'apps/hot-b2b/src/app/shared/services/settings.service';

@Injectable({
    providedIn: 'root',
})
export class IsOnlineGuard  {
    constructor(private readonly router: Router, private readonly settingsService: SettingsService) {}

    public canActivate(): Observable<boolean> {
        const appMode: any = localStorage.getItem(StorageKeys.appMode);
        if (appMode) {
            const isOnlineValue: boolean = JSON.parse(appMode).online;
            if (!isOnlineValue) {
                this.settingsService
                    .isUrlAllowedForOfflineMode()
                    .pipe(take(1))
                    .subscribe((isUrlAllowed: boolean) => {
                        if (!isUrlAllowed) {
                            this.router.navigate(['/']);
                            return of(false);
                        }
                    });
            }
        }

        return of(true);
    }
}
