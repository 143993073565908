import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { FilterModel } from '@hot-libs/helpers';
import findIndex from 'lodash/findIndex';

@Component({
    selector: 'hot-filters',
    templateUrl: './filters.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements OnDestroy {
    @Input() public filters = [];
    @Input() public filtersChecked: string[];
    @Input() public showAll: boolean;
    @Output() public filterSelect: EventEmitter<FilterModel> = new EventEmitter();
    @Output() public filterSelectAll: EventEmitter<void> = new EventEmitter();

    private readonly subscriptionDestroy$ = new Subject();

    public setItem(item: FilterModel): void {
        this.filterSelect.emit(item);
    }

    public setAll(): void {
        this.filterSelectAll.emit();
    }

    public checkActive(item: FilterModel): boolean {
        return (
            findIndex(
                this.filtersChecked,
                (filter: any) => filter.name === item.name && item.value === filter.value
            ) !== -1
        );
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }
}
