import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * This interceptor disables caching for GET requests to the storefront API to prevent IE11 from caching API responses.
 */
@Injectable({
    providedIn: 'root',
})
export class NoApiCacheInterceptor implements HttpInterceptor {
    // TO DO: remove this interceptor when IE11 will become obsolete for hot-theme customers

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.requestCachingShouldBeDisabled(request)) {
            const clonedRequest = request.clone({
                headers: request.headers
                    .set('Cache-Control', 'no-cache')
                    .set('Pragma', 'no-cache')
                    .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT'),
            });

            return next.handle(clonedRequest);
        }

        return next.handle(request);
    }

    private requestCachingShouldBeDisabled(request: HttpRequest<any>): boolean {
        if (
            request.url.includes('tutorials') ||
            request.url.includes('hot/cms/images') ||
            request.url.includes('en-US') ||
            request.url.includes('home-page-widgets') ||
            request.url.includes('fulfillment-centers') ||
            request.url.includes('carousel-banners') ||
            request.url.includes('loyalty-banners') ||
            request.url.includes('insights') ||
            request.url.includes('promotions') ||
            request.url.includes('user') ||
            request.url.includes('settings') ||
            request.url.includes('topics') ||
            request.url.includes('store-settings') ||
            request.url.includes('suggestions/v2') ||
            request.url.includes('order-templates') ||
            request.url.includes('low-rating-reasons')
        )
            return false;
        return request.method === 'GET' && request.url.includes('/storefrontapi/hot/');
    }
}
