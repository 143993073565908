import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import {
    HotApiMarketingService,
    HotSuggestedInsightSearchCriteria,
    HotSuggestedInsightSearchResponse,
} from '@hot-theme-nx/generated-api';
import { StorageKeys } from '@hot-libs/shared-types';

@Component({
    selector: 'hot-suggested-insight',
    templateUrl: './suggested-insight.component.html',
})
export class SuggestedInsightComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() public products: any;

    public suggestedInsight: HotSuggestedInsightSearchResponse;
    public showBanner = true;
    public isScrolling = false;
    public translate = '';

    private isMouseOver = false;
    private elH = 0;
    private translateX = '';
    private readonly delayTime = 500;
    private calculateTimeout;
    private excludedSuggestedInsights: HotSuggestedInsightSearchResponse[] = [];

    constructor(
        private readonly hotApiMarketingService: HotApiMarketingService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly el: ElementRef
    ) {}

    public ngOnInit(): void {
        this.excludedSuggestedInsights = this.getExcludedSuggestedInsights();
        this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
            if (params['search']) {
                const request: HotSuggestedInsightSearchCriteria = {
                    keyword: this.route.snapshot.queryParams['search'],
                    excludeIds: this.getExludedIds(),
                };
                this.searchSuggestedInsight(request);
            }
        });
        this.translateX = window.innerWidth >= 992 ? '-15px' : '0px';
    }

    public ngAfterViewInit(): void {
        this.calculateBannerPosition();
    }

    public ngOnChanges(): void {
        this.calculateBannerPosition(true);
    }

    public close(suggestedInsight: HotSuggestedInsightSearchResponse): void {
        if (!this.suggestedInsightExluded(suggestedInsight)) {
            this.excludedSuggestedInsights.push(suggestedInsight);
            localStorage.setItem(StorageKeys.suggestedInsights, JSON.stringify(this.excludedSuggestedInsights));
        }
        this.showBanner = false;
        this.isScrolling = false;
    }

    public onWindowScroll(_event: any): void {
        if (this.showBanner) {
            this.isScrolling = true;
            this.translate = this.getBannerTranslate();
        }
    }

    public onWindowResize(_event: any): void {
        if (this.showBanner) {
            this.elH = this.el.nativeElement.firstElementChild.getBoundingClientRect().height;
            this.translateX = window.innerWidth >= 992 ? '-15px' : '0px';
            this.translate = this.getBannerTranslate();
        }
    }

    public onMouseOver(_event: any): void {
        this.isMouseOver = true;
        if (this.showBanner && this.isScrolling) {
            this.translate = this.getBannerTranslate();
        }
    }

    public onMouseOut(_event: any): void {
        this.isMouseOver = false;
        if (this.showBanner && this.isScrolling) {
            this.translate = this.getBannerTranslate();
        }
    }

    public navigate(suggestedInsight: HotSuggestedInsightSearchResponse): void {
        this.close(suggestedInsight);
        if (suggestedInsight.insight) {
            this.router.navigateByUrl(`/trends/${suggestedInsight.insight.code}`);
        }
        if (suggestedInsight.promotion) {
            this.router.navigateByUrl(`/promotions/${suggestedInsight.promotion.code}`);
        }
    }

    private getBannerTranslate(): string {
        const _footerOffsetY = window.innerHeight - document.getElementById('app-footer').getBoundingClientRect().top;
        if (!this.isScrolling) {
            this.footerBanner(_footerOffsetY);
        }
        else if (_footerOffsetY >= 0) {
                if (this.isMouseOver) {
                    return `translate(${this.translateX}, ${-_footerOffsetY}px)`;
                } else {
                    return `translate(${this.translateX}, ${this.elH * 0.3 - _footerOffsetY}px)`;
                }
        }
        else if (this.isMouseOver) {
            return `translate(${this.translateX}, 0)`;
        }
        else {
            return `translate(${this.translateX}, ${this.elH * 0.3}px)`;
        }
    }
    public footerBanner(_footerOffsetY) {
        if (_footerOffsetY >= 0) {
            return `translate(${this.translateX}, ${-_footerOffsetY}px)`;
        } else {
            return `translate(${this.translateX}, 0px)`;
        }
    }

    private calculateBannerPosition(resetScroll: boolean = false): void {
        if (this.calculateTimeout) {
            clearTimeout(this.calculateTimeout);
        }
        this.calculateTimeout = setTimeout(() => {
            if (resetScroll) {
                this.isScrolling = false;
            }
            this.elH = this.el.nativeElement.firstElementChild
                ? this.el.nativeElement.firstElementChild.getBoundingClientRect().height
                : 0;
            this.translate = this.getBannerTranslate();
            this.calculateTimeout = null;
        }, this.delayTime);
    }

    private searchSuggestedInsight(request: HotSuggestedInsightSearchCriteria): void {
        this.hotApiMarketingService
            .searchSuggestedInsight(request)
            .subscribe((suggestedInsight: HotSuggestedInsightSearchResponse) => {
                this.showBanner =
                    (suggestedInsight.insight || suggestedInsight.promotion) &&
                    !this.suggestedInsightExluded(suggestedInsight);
                this.suggestedInsight = suggestedInsight;
            });
    }

    private getExludedIds(): string[] {
        const excludedIds: string[] = [];
        this.excludedSuggestedInsights.forEach((suggestedInsight: HotSuggestedInsightSearchResponse) => {
            if (suggestedInsight.insight) {
                excludedIds.push(suggestedInsight.insight.id);
            }
            if (suggestedInsight.promotion) {
                excludedIds.push(suggestedInsight.promotion.id);
            }
        });
        return excludedIds;
    }

    private getExcludedSuggestedInsights(): HotSuggestedInsightSearchResponse[] {
        return JSON.parse(localStorage.getItem(StorageKeys.suggestedInsights)) || [];
    }

    private suggestedInsightExluded(suggestedInsight: HotSuggestedInsightSearchResponse): boolean {
        const existingSuggestedInsight = this.excludedSuggestedInsights.find(
            (insight: HotSuggestedInsightSearchResponse) => insight === suggestedInsight
        );
        return !!existingSuggestedInsight;
    }
}
