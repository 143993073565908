import { Component, EventEmitter, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { Subject, Observable } from 'rxjs';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { select, Store } from '@ngrx/store';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotApiOrdersService, HotOrder } from '@hot-theme-nx/generated-api';

@Component({
    selector: 'hot-order-rating-modal',
    templateUrl: './order-rating-modal.component.html',
})
export class OrderRatingModalComponent implements OnInit, OnDestroy {
    @Input() public order: HotOrder;
    @Input() public lowRatingReasons;
    @Output() ratingSubmitted: EventEmitter<HotOrder> = new EventEmitter<HotOrder>();
    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    public modalRatingConfirmationState$: Observable<ModalsState>;
    public id = '';
    public atleastOneReasonChecked = true;
    public atleastOneRatingChecked = true;
    public rating = ['terrible-emoji', 'bad-emoji', 'so-so-emoji', 'good-emoji', 'awesome-emoji'];
    constructor(
        private readonly modalService: ModalService,
        private readonly store: Store<AppState>,
        private readonly apiOrdersService: HotApiOrdersService
    ) {
        this.modalRatingConfirmationState$ = this.store.pipe(select(selectModalState(ModalIds.ratingConfirmation)));
    }
    public ngOnInit(): void {
        this.lowRatingReasons.forEach((e) => (e.isChecked = false));
    }
    public buildRatingandReasonData(ratingPoint: number, reason: string) {
        return {
            orderId: this.order.id,
            fulfillmentCenterId: this.order.fulfillmentCenterId,
            rating: ratingPoint,
            lowRatingReason: ratingPoint <= 2 ? '{' + reason + '}' : null,
            isOrderRated: true,
        };
    }
    public submitRating(): void {
        let ratingPoint = 0;
        let reasons = [];
        for (let i in this.rating) {
            if (this.rating[i] === this.id) {
                ratingPoint = Number(i) + 1;
            }
        }
        this.lowRatingReasons.forEach(function (reason) {
            if (reason.isChecked) {
                reasons.push(reason.reason);
            }
        });
        this.apiOrdersService
            .addRatingReason(this.buildRatingandReasonData(ratingPoint, reasons.toString()))
            .subscribe(() => {
                this.order.isOrderRated = true;
                this.order.rating = ratingPoint;
            });

        this.ratingSubmitted.emit(this.order);
        this.modalService.toggleModal(ModalIds.orderRating, false);
        this.modalService.toggleModal(ModalIds.ratingConfirmation, true);
    }
    public getRating(id: string) {
        this.id = id;
        if (id === 'terrible-emoji') {
            this.atleastOneRatingChecked = true;
            document.getElementById('terrible-emoji').style.backgroundColor = 'green';
            document.getElementById('so-so-emoji').style.backgroundColor = '';
            document.getElementById('bad-emoji').style.backgroundColor = '';
            document.getElementById('good-emoji').style.backgroundColor = '';
            document.getElementById('awesome-emoji').style.backgroundColor = '';
        } else if (id === 'bad-emoji') {
            this.atleastOneRatingChecked = true;
            document.getElementById('terrible-emoji').style.backgroundColor = '';
            document.getElementById('so-so-emoji').style.backgroundColor = '';
            document.getElementById('bad-emoji').style.backgroundColor = 'green';
            document.getElementById('good-emoji').style.backgroundColor = '';
            document.getElementById('awesome-emoji').style.backgroundColor = '';
        } else if (id === 'so-so-emoji') {
            this.atleastOneRatingChecked = false;
            this.lowRatingReasons.forEach((e) => (e.isChecked = false));
            document.getElementById('terrible-emoji').style.backgroundColor = '';
            document.getElementById('so-so-emoji').style.backgroundColor = 'green';
            document.getElementById('bad-emoji').style.backgroundColor = '';
            document.getElementById('good-emoji').style.backgroundColor = '';
            document.getElementById('awesome-emoji').style.backgroundColor = '';
        } else if (id === 'good-emoji') {
            this.atleastOneRatingChecked = false;
            this.lowRatingReasons.forEach((e) => (e.isChecked = false));
            document.getElementById('terrible-emoji').style.backgroundColor = '';
            document.getElementById('so-so-emoji').style.backgroundColor = '';
            document.getElementById('bad-emoji').style.backgroundColor = '';
            document.getElementById('good-emoji').style.backgroundColor = 'green';
            document.getElementById('awesome-emoji').style.backgroundColor = '';
        } else {
            this.atleastOneRatingChecked = false;
            this.lowRatingReasons.forEach((e) => (e.isChecked = false));
            document.getElementById('terrible-emoji').style.backgroundColor = '';
            document.getElementById('so-so-emoji').style.backgroundColor = '';
            document.getElementById('bad-emoji').style.backgroundColor = '';
            document.getElementById('good-emoji').style.backgroundColor = '';
            document.getElementById('awesome-emoji').style.backgroundColor = 'green';
        }
    }
    public isDisabled() {
        if ((this.id === 'terrible-emoji' || this.id === 'bad-emoji') && !this.atleastOneReasonChecked) {
            return false;
        } else if (!this.atleastOneRatingChecked) {
            return false;
        } else {
            return true;
        }
    }
    public onChange(lowRatingReasons: any, _reason) {
        let flag = false;
        lowRatingReasons.forEach((element) => {
            if (element.isChecked) {
                flag = true;
            }
        });
        if (flag) {
            this.atleastOneReasonChecked = false;
        } else {
            this.atleastOneReasonChecked = true;
        }
    }
    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
        this.modalService.toggleModal(ModalIds.orderRating, false, null);
        this.modalService.toggleModal(ModalIds.ratingConfirmation, false);
    }
}
