import { MemoizedSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { MobileAppState } from './model';
import { NativeAppModel } from '../../native/services';

const stateNativeMobileApp = (state: AppState): MobileAppState => state.nativeMobileAppState;

export const nativeMobileAppDetails: MemoizedSelector<AppState, NativeAppModel> = createSelector(
    stateNativeMobileApp,
    (state: MobileAppState) => state.data
);
