import { createSelector, MemoizedSelector } from '@ngrx/store';

import { Coupon, HotCreateOrderRequest, HotLineItem, HotPaymentMethods } from '@hot-theme-nx/generated-api';
import { ProductsBufferModel } from '@hot-libs/shared-models';

import {
    AvailableShipmentMethodModel,
    CartState,
    DeliveryAddress,
    DeliveryMethod,
    PickUpAddress,
    ShipmentMethodModel,
    PaymentModel,
} from '@hot-b2b/store/cart/model';
import { AppState } from '@hot-b2b/store/reducers';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import find from 'lodash/find';
import { SettingsState } from '../settings/model';
import { stateSettings } from '../settings/selector';
import { normalizeItemsQuantity } from './helpers';
import { HotProductExtended } from '../../catalog/models';
import { HotCartShipment } from 'libs/generated-api/src/lib/models/hot-cart-shipment';

export const stateCart = (state: AppState): CartState => state.cart;

export const cartPending: MemoizedSelector<AppState, boolean> = createSelector(
    stateCart,
    (state: CartState) => state.pending
);

export const cartFetched: MemoizedSelector<AppState, boolean> = createSelector(
    stateCart,
    (state: CartState) => state.fetched
);

export const cartData: MemoizedSelector<AppState, HotCartExtended> = createSelector(
    stateCart,
    (state: CartState) => state.data.cart
);
export const cartValidationErrors: MemoizedSelector<AppState, any> = createSelector(
    stateCart,
    (state: CartState) => state.data.cart.validationErrors
);

export const checkoutCartData: MemoizedSelector<AppState, HotCartExtended> = createSelector(
    stateCart,
    (state: CartState) => (state.data.cart.checkoutCart ? state.data.cart.checkoutCart : state.data.cart)
);

export const cartError: MemoizedSelector<AppState, any> = createSelector(stateCart, (state: CartState) => state.error);

export const cartPromoError: MemoizedSelector<AppState, any> = createSelector(
    stateCart,
    (state: CartState) => state.promoError
);
export const cartErrorCodes: MemoizedSelector<AppState, any> = createSelector(
    stateCart,
    (state: CartState) => state.errorCodes
);

export const cartItems: MemoizedSelector<AppState, HotCartLineItemExtended[]> = createSelector(
    stateCart,
    stateSettings,
    (state: CartState, settingsState: SettingsState) =>
        normalizeItemsQuantity(
            state.data.cart.items,
            find(settingsState?.data?.features, { name: 'UsePackagesWithCartsAndOrders' })?.isActive
        )
);

export const checkOutCartItems: MemoizedSelector<AppState, HotCartLineItemExtended[]> = createSelector(
    stateCart,
    stateSettings,
    (state: CartState, settingsState: SettingsState) =>
        normalizeItemsQuantity(
            state.data.cart.checkoutCart?.items || [],
            find(settingsState?.data?.features, { name: 'UsePackagesWithCartsAndOrders' })?.isActive
        )
);

export const cartItemsLength: MemoizedSelector<AppState, boolean> = createSelector(
    stateCart,
    (state: CartState) => !!state.data.cart.items.length
);

export const cartItemsMin: MemoizedSelector<AppState, HotLineItem[]> = createSelector(stateCart, (state: CartState) =>
    state.data.cart.items
        .filter((item: HotCartLineItemExtended) => !item.isGift)
        .map((item: HotCartLineItemExtended) => {
            return {
                productId: item.productId,
                quantity: item.quantity,
                packageTypeHop: item.unitCurrent?.packageType,
            };
        })
);

export const cartCoupons: MemoizedSelector<AppState, Coupon[]> = createSelector(
    stateCart,
    (state: CartState) => state.data.cart.coupons
);

export const cartRemarks: MemoizedSelector<AppState, HotCreateOrderRequest> = createSelector(
    stateCart,
    (state: CartState) => state.data.remarks
);

export const cartPaymentMethod: MemoizedSelector<AppState, HotPaymentMethods> = createSelector(
    stateCart,
    (state: CartState) => state.data.cart.selectedPaymentMethod
);

export const cartChangesBuffer: MemoizedSelector<AppState, ProductsBufferModel> = createSelector(
    stateCart,
    (state: CartState) => state.data.changesBuffer
);

export const cartHasChanges: MemoizedSelector<AppState, boolean> = createSelector(
    stateCart,
    (state: CartState) => state.data.changesBuffer.pending.length > 0 || state.data.changesBuffer.processing.length > 0
);

export const cartTotals: MemoizedSelector<AppState, number> = createSelector(
    stateCart,
    (state: CartState) => state.data?.cart?.total
);

export const cartSubTotal: MemoizedSelector<AppState, number> = createSelector(
    stateCart,
    (state: CartState) => state.data?.cart?.subTotal
);

export const extendedPriceTotal: MemoizedSelector<AppState, number> = createSelector(
    stateCart,
    (state: CartState) => state.data?.cart?.extendedPriceTotal
);

export const cartRecommendedProducts: MemoizedSelector<AppState, HotProductExtended[]> = createSelector(
    stateCart,
    (state: CartState) => state.data?.cart?.recommendedProducts ?? []
);

export const productRecommendedLoadingStatue: MemoizedSelector<AppState, boolean> = createSelector(
    stateCart,
    (state: CartState) => state.isProductRecommederIsLoading ?? false
);

export const checkOutCartTotals: MemoizedSelector<AppState, number> = createSelector(
    stateCart,
    (state: CartState) => state.data?.cart?.checkoutCart?.total
);

export const cartItemsCount: MemoizedSelector<AppState, number> = createSelector(
    stateCart,
    (state: CartState) => state.data.cart.items.length
);

export const checkoutCartSubTotal: MemoizedSelector<AppState, number> = createSelector(
    stateCart,
    (state: CartState) => state.data?.cart?.checkoutCart?.subTotal
);

export const checkoutCartExtendedPriceTotal: MemoizedSelector<AppState, number> = createSelector(
    stateCart,
    (state: CartState) => state.data?.cart?.checkoutCart?.extendedPriceTotal
);

export const shipmentMethod: MemoizedSelector<AppState, ShipmentMethodModel> = createSelector(
    stateCart,
    (state: CartState) => state.shipmentMethod
);

export const checkShipmentMethodNotSelected: MemoizedSelector<AppState, boolean> = createSelector(
    stateCart,
    (state: CartState) => {
        if (state?.shipmentMethod?.validateDeliveryMethod) {
            return state.data?.remarks.shipmentMethodCode == '' || state.data?.remarks.shipmentMethodCode == undefined;
        }
        return false;
    }
);

export const getAvailableShipmentMethodAndAddress: MemoizedSelector<
    AppState,
    AvailableShipmentMethodModel
> = createSelector(stateCart, (state: CartState) => {
    const pickUpShipment = state?.data?.cart?.shipments?.find(
        (item: HotCartShipment) => item.shipmentMethodCode == DeliveryMethod.PickUp
    );
    const pAddress: PickUpAddress = { warehouseName: '', warehouseAddress: '' };
    if (pickUpShipment != undefined) {
        pAddress.warehouseName = pickUpShipment?.deliveryAddress?.firstName;
        pAddress.warehouseAddress = [
            pickUpShipment?.deliveryAddress?.countryName,
            pickUpShipment?.deliveryAddress?.city,
            pickUpShipment?.deliveryAddress?.line1,
        ]
            .filter(Boolean)
            .join(',');
    }
    const deliveryShipment = state?.data?.cart?.shipments?.find(
        (item: HotCartShipment) =>
            item.shipmentMethodCode == DeliveryMethod.Delivery && item.shipmentMethodOption == 'Ground'
    );

    const dAddress: DeliveryAddress = { outletName: '', address: '' };

    if (deliveryShipment != undefined) {
        dAddress.outletName =
            deliveryShipment.deliveryAddress?.firstName + ' ' + deliveryShipment.deliveryAddress?.lastName;

        dAddress.address = [
            deliveryShipment?.deliveryAddress?.countryName,
            deliveryShipment?.deliveryAddress?.city,
            deliveryShipment?.deliveryAddress?.line1,
            deliveryShipment?.deliveryAddress?.line2,
        ]
            .filter(Boolean)
            .join(',');
    }

    return {
        isDeliveryAvailable: deliveryShipment != undefined,
        isPickUpAvailable: pickUpShipment != undefined,
        pAddress: pAddress,
        dAddress: dAddress,
    };
});

export const checkPickUpEnabledForStore: MemoizedSelector<AppState, boolean> = createSelector(
    stateCart,
    (state: CartState) => {
        return (
            state?.data?.cart?.shipments?.find(
                (item: HotCartShipment) => item.shipmentMethodCode == DeliveryMethod.PickUp
            ) != undefined
        );
    }
);

export const showPickUpAvailableInfoForMoq: MemoizedSelector<AppState, boolean> = createSelector(
    stateCart,
    (state: CartState) => {
        const deliveryAvailable =
            state?.data?.cart?.shipments?.find(
                (item: HotCartShipment) =>
                    item.shipmentMethodCode == DeliveryMethod.Delivery && item.shipmentMethodOption == 'Ground'
            ) != undefined;
        const pickUpAvailable =
            state?.data?.cart?.shipments?.find(
                (item: HotCartShipment) => item.shipmentMethodCode == DeliveryMethod.PickUp
            ) != undefined;
        return pickUpAvailable && !deliveryAvailable;
    }
);

export const getAPMRediretURL: MemoizedSelector<AppState, PaymentModel> = createSelector(
    stateCart,
    (state: any) => state.payment.rediretURL
);
