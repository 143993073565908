import { createAction, props } from '@ngrx/store';

import { HotCart, HotCartLineItem, HotCreateOrderRequest, HotOrder } from '@hot-theme-nx/generated-api';

import { ICartBufferItem } from '../../models/cart-buffer-item.model';
import { IChangesBuffer } from '../../models/changes-buffer.model';

export const CartGet = createAction('[Cart] Get');

export const CartGetSuccess = createAction('[Cart] Get success', props<{ cart: HotCart }>());

export const CartClear = createAction('[Cart] Clear');

export const CartUpdate = createAction('[Cart] Update items', props<{ product: HotCartLineItem }>());

export const CartUpdateSuccess = createAction(
    '[Cart] Update success',
    props<{ cart: HotCart; isFirstAdding: boolean }>()
);

export const CartSwipeClose = createAction('[Cart] Swipe close');

export const CartSwipeOpen = createAction('[Cart] Swipe open');

export const CreateOrder = createAction('[Cart] Create order', props<{ createOrderRequest: HotCreateOrderRequest }>());

export const CreateOrderSuccess = createAction('[Cart] Create order success', props<{ items: HotOrder[] }>());

export const CartManageProductsBuffer = createAction(
    '[Cart] Manage products buffer',
    props<{ newItem: ICartBufferItem }>()
);

export const CartUpdateBuffer = createAction('[Cart] Update cart buffer', props<{ productsBuffer: IChangesBuffer }>());

export const CartClearProcessChanges = createAction(
    '[Cart] Clear items from process buffer',
    props<{ productsBuffer: IChangesBuffer }>()
);

export const CartClearChangedItems = createAction(
    '[Cart]  Clear items from changes buffer',
    props<{ productsBuffer: IChangesBuffer }>()
);

export const CartRemoveItem = createAction('[Cart]  Remove item from cart', props<{ productId: string }>());

export const CartError = createAction(
    '[Cart] Cart request error',
    props<{ actionName: string; failedRequestParams?: any; error: any }>()
);
