import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { HotApiCustomerService, HotApiOrdersService, HotCart, HotDeviceTokenRequestBody, HotOrder, HotOrderLineItem } from '@hot-theme-nx/generated-api';

import { CartUpdateSuccess } from '../cart/store/cart.actions';
import { ICartState } from '../cart/store/cart.reducer';

import { ProductService } from './product.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    pushbody: HotDeviceTokenRequestBody;
    constructor(
        private readonly hotApiCustomerService: HotApiCustomerService,
        private readonly hotApiOrdersService: HotApiOrdersService,
        private readonly router: Router,
        private readonly cartStore: Store<ICartState>
    ) {}

    public filterOrders(term: string, objectKey?: string, array?: HotOrder[]) {
        const filteredOrders: HotOrder[] = [];

        array.forEach((order: HotOrder) => {
            if (order[objectKey].toLowerCase().includes(term.toLowerCase())) {
                filteredOrders.push(order);
            }
        });
        return filteredOrders;
    }
    public SaveToken(userId: string, token: string) {
        this.pushbody = {
            userId: userId,
            token: token,
        };
        this.hotApiCustomerService.saveDeviceToken(this.pushbody).subscribe();
    }
    public normalizeStatus(status: string) {
        return status.replace(/\./g, '-').toLowerCase();
    }
    public calculateRegularLineItemPackagesCount(lineItem: HotOrderLineItem, hasInvoice: boolean) {
        const packageSize = lineItem.packageSize;
        const quantity =
            hasInvoice && lineItem.deliveredQuantity !== 0 ? lineItem.deliveredQuantity : lineItem.quantity;

        return { packages: ProductService.calculateFractionalPart(quantity, packageSize), quantity };
    }

    public calculateCanceledLineItemPackagesCount(lineItem: HotOrderLineItem, hasInvoice: boolean) {
        const packageSize = lineItem.packageSize;
        const quantity = hasInvoice ? lineItem.quantity - lineItem.deliveredQuantity : lineItem.quantity;

        return { packages: ProductService.calculateFractionalPart(quantity, packageSize), quantity };
    }

    public calculateRegularLineItemPrice(lineItem: HotOrderLineItem, hasInvoice: boolean) {
        return hasInvoice ? this.getInvoiceExtendedPriceWithDiscountForLineItem(lineItem) : lineItem.extendedPrice;
    }

    public calculateCanceledLineItemPrice(lineItem: HotOrderLineItem, hasInvoice: boolean) {
        const invoiceExtendedPriceWithDiscount = this.getInvoiceExtendedPriceWithDiscountForLineItem(lineItem);

        return hasInvoice ? lineItem.extendedPrice - invoiceExtendedPriceWithDiscount : lineItem.extendedPrice;
    }

    public getOrderTotals(order: HotOrder) {
        let subTotal = order.subTotal;
        let total = order.total;

        if (order.hasInvoice) {
            subTotal = order.invoiceTotalPrice;
            total = order.invoiceTotalAmount || order.total;
        }

        return { subTotal, total };
    }

    public addToCart(orderId: string) {
        this.hotApiOrdersService.addItemsToCart({"orderId":orderId }).subscribe((cart: HotCart) => {
            this.cartStore.dispatch(CartUpdateSuccess({ cart, isFirstAdding: false }));
            this.router.navigate(['/checkout']);
        });
    }

    private getInvoiceExtendedPriceWithDiscountForLineItem(lineItem: HotOrderLineItem) {
        const invoiceExtendedPrice = lineItem.invoiceExtendedPrice || 0;
        const invoiceDiscountTotal = lineItem.invoiceDiscountTotal || 0;

        return invoiceExtendedPrice - invoiceDiscountTotal;
    }
}
