import { EnvironmentModel } from '../app/shared/models/environment.model';

export const environment: EnvironmentModel = {
    production: false,
    appTitle: 'Heineken B2B theme',
    manifest: {
        name: 'Heineken B2B',
        short_name: 'Heineken B2B',
        description: 'Heineken B2B Web App',
    },
    pwa: true,
    nexusScriptsPath: 'heineken-b2b',
    swapProductPrices: true,
    loyaltyStatement: {
        loyaltyProgram: 'MyJara',
        monthsCount: '2',
    },
    exportOrdersTypes: ['pdf', 'xlsx'],
    heinekenValuesRedirectionLinks: ['/feedback', '/promotions', '/privacy-policy'],
};
