import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HotCarouselBanner, HotPromotion } from '@hot-theme-nx/generated-api';
import moment from 'moment';

@Component({
    selector: 'hot-countdown',
    templateUrl: './countdown.component.html',
})
export class CountdownComponent implements OnInit {
    @Input() public promoItem: HotPromotion | HotCarouselBanner;
    @Input() public modifier: string = '';
    @Input() public pageName: string;
    @Input() public isGridViewActive: boolean;

    @Output() public onTick: EventEmitter<number> = new EventEmitter();

    public hours: string[] = [];
    public minutes: string[] = [];
    public seconds: string[] = [];


    public ngOnInit(): void {
        this.startTimer();
    }

    private startTimer(): void {
        if ( !this.promoItem?.showTimer || !this.promoItem.endDate) {
            return;
        }

        const interval = setInterval(() => {
            let duration: moment.Duration = this.getDuration();
            let seconds: number = Math.abs(duration.asSeconds());

            this.hours = this.getDigits(Math.abs(Math.trunc(duration.asHours())));
            this.minutes = this.getDigits(Math.abs(duration.minutes()));
            this.seconds = this.getDigits(Math.abs(duration.seconds()));

            seconds--;
            this.onTick.emit(seconds);
            if (seconds <= 0) {
                clearInterval(interval);
            }
        }, 1000);
    }

    private getDuration(): moment.Duration {
        const currentDate: moment.Moment = moment();
        const promoItemEndDate: moment.Moment = moment(this.promoItem.endDate);
        const duration: moment.Duration = moment.duration(currentDate.diff(promoItemEndDate));

        return duration;
    }

    private getDigits(input: number): string[] {
        let digits: string[] = input.toString().split('');
        if (digits.length === 1) {
            digits = ['0', '0'].concat(digits);
        } else if (digits.length === 2) {
            digits = ['0'].concat(digits);
        }
        return digits;
    }
}
