import { Component } from '@angular/core';
import { ModalIds } from '@hot-libs/shared-types';

import { ModalService } from 'apps/hot-b2b/src/app/shared/services/modal.service';

@Component({
    selector: 'hot-distributor-orders-dispatched-modal',
    templateUrl: './distributor-orders-dispatched-modal.component.html',
})
export class DistributorOrdersDispatchedModalComponent {
    constructor(private readonly modalService: ModalService) {}

    public close(): void {
        this.modalService.toggleModal(ModalIds.distributorOrdersDispatchedModal, false);
    }
}
