/**
 * Camel case string
 */
export const camelCaseString = (value: string): string => {
    if (typeof value === 'string') {
        return value
            .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
                index === 0 ? letter.toLowerCase() : letter.toUpperCase()
            )
            .replace(/\s+/g, '');
    }
    return '';
};

/**
 * Get Query params from route
 * @param {string} queryStringTerms
 * @param {string} param
 * @return {string[]}
 */
export const getRouteQuery = (queryStringTerms: string, param: string): string[] => {
    const splitted: string | string[] = queryStringTerms ? queryStringTerms.split(':') : [];
    if (splitted.length < 2 || splitted[0].toLowerCase() !== param.toLowerCase()) {
        return [];
    }
    return splitted[1].split(',');
};

/**
 * Get CSS background image URL if it exists
 */
export const getBackgroundImageStyle = (string: string): string => {
    return string ? `url('${string}')` : null;
};
