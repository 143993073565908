import { AzureAdOptions } from '@hot-theme-nx/generated-api';
import { StorageKeys } from '@hot-libs/shared-types';
import { IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalGuardConfiguration } from '@azure/msal-angular';
import { environment } from 'apps/hot-b2b/src/environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Retrieves the Azure AD options from the storefront.
 * @returns The AzureAdOptions object if successful, or null if an error occurred.
 */
function getAzureAdOptions(): AzureAdOptions | null {
    const request = new XMLHttpRequest();
    request.open('GET', '/storefrontapi/hot/azure-ad-settings', false); // request application settings synchronous
    request.send(null);

    try {
        return JSON.parse(request.responseText);
    } catch (error) {
        localStorage.setItem(StorageKeys.msalAuthEnabled, 'false');
        return null;
    }
}

/**
 * Sets the values in the local storage based on the provided AzureAdOptions.
 * @param azureAdOptions - The AzureAdOptions object containing the authentication mode.
 */
function setLocalStorage(azureAdOptions: AzureAdOptions): void {
    if (azureAdOptions?.authMode === 'B2C') {
        localStorage.setItem(StorageKeys.msalAuthEnabledForB2C, 'true');
    } else {
        localStorage.setItem(StorageKeys.msalAuthEnabled, 'true');
    }
}

/**
 * Creates a configuration object for MSAL authentication.
 * @param azureAdOptions - The Azure AD options for configuring the authentication.
 * @returns The configuration object.
 */
function createConfig(azureAdOptions: AzureAdOptions): IPublicClientApplication {
    const authority = azureAdOptions.instance + azureAdOptions.tenantId;
    const b2cAuthority =
        azureAdOptions.instance + azureAdOptions.tenantName + '/' + azureAdOptions?.policies?.SignUpSignInPolicyId;
    const clientId = azureAdOptions.clientId;
    const redirectUri = window.location.origin + '/signin-oidc';
    const cacheLocation = azureAdOptions.authMode === 'B2B' ? 'localStorage' : 'sessionStorage';
    const storeAuthStateInCookie = isIE;

    return new PublicClientApplication({
        auth: {
            authority: azureAdOptions.authMode === 'B2B' ? authority : b2cAuthority,
            clientId: clientId,
            redirectUri: redirectUri,
            navigateToLoginRequestUrl: azureAdOptions.authMode !== 'B2B',
            knownAuthorities: [azureAdOptions.instance],
            postLogoutRedirectUri: '/',
        },
        cache: {
            cacheLocation: cacheLocation,
            storeAuthStateInCookie: storeAuthStateInCookie, // Set to true for Internet Explorer 11
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Verbose,
                piiLoggingEnabled: false,
            },
        },
    });
}
export function loggerCallback(logLevel: LogLevel, message: string) {
    if (!environment.production) {
        switch (logLevel) {
            case LogLevel.Error:
                console.error(message);
                return;
            case LogLevel.Info:
                console.info(message);
                return;
            case LogLevel.Verbose:
                console.debug(message);
                return;
            case LogLevel.Warning:
                console.warn(message);
                return;
        }
    }
}

/**
 * Factory function that creates the MSAL configuration object.
 * @returns The MSAL configuration object.
 */
export function MsalConfigFactory(): IPublicClientApplication {
    const azureAdOptions = getAzureAdOptions();
    if (azureAdOptions?.enabled) {
        setLocalStorage(azureAdOptions);
        return createConfig(azureAdOptions);
    } else {
        localStorage.setItem(StorageKeys.msalAuthEnabled, 'false');
        return new PublicClientApplication({
            auth: {
                authority: '',
                clientId: '',
                redirectUri: window.location.origin + '/signin-oidc',
            },
        });
    }
}

/**
 * Factory function that returns the configuration object for MSALAngular.
 * @returns The configuration object for MSALAngular.
 */
export function MsalAngularConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [''],
        },
        loginFailedRoute: '/login-failed', // Failed router path
    };
}
