import { Action } from '@ngrx/store';

import { ECarouselsActions } from '@hot-b2b/store/carousels/types';


export class CarouselsError implements Action {
    public readonly type = ECarouselsActions.CAROUSELS_ERROR;
    constructor(public payload: any[]) { }
}

export class CarouselsGet implements Action {
    public readonly type = ECarouselsActions.CAROUSELS_PENDING;
}

export class CarouselsGetSuccess implements Action {
    public readonly type = ECarouselsActions.CAROUSELS_FETCHED;
    constructor(public payload: any[]) { }
}

export type CarouselsActions =
    CarouselsError
    |CarouselsGet
    |CarouselsGetSuccess
