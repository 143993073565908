import { Component} from '@angular/core';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';

@Component({
  selector: 'hot-theme-nx-reward-maintainance-modal',
  templateUrl: './reward-maintainance-modal.component.html',
  })
export class RewardMaintainanceModalComponent {

  constructor(private readonly modalService: ModalService) { }

  //OTCS-4686 toggle Loyalty Maintainance popup for Reward shop 
  public toggleModal(){
    this.modalService.toggleModal(ModalIds.rewardMaintainance,false);
  }

   //OTCS-4686 close Loyalty Maintainance popup for Reward shop 
   public closeRewardMaintainance(): void {
    this.modalService.toggleModal(ModalIds.rewardMaintainance, false);
}

}
