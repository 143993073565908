import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { catchError, filter, finalize, first, map, take } from 'rxjs/operators';

import { ApiConfiguration, HotApiSecurityService, HotApiSecurityTokensService } from '@hot-theme-nx/generated-api';

import { CustomEncoder } from '../helpers/custom-encoder';

import { ApplicationInsightsService } from './application-insights.service';
import { AuthenticationTokenStorageService } from './authentication-token-storage.service';

import { IBearerToken } from '@hot-libs/shared-models';
import { StorageKeys, TelemetryEventType } from '@hot-libs/shared-types';
import { authData, authFetched, authLoggedIn } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { Store, select } from '@ngrx/store';
import { AuthUser } from '@hot-b2b/store/auth/model';

const signInPageUrl = '/account/sign-in';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private defaultHomePage = '/';
    public authFetched$: Observable<boolean>;
    public authData$: Observable<AuthUser>;
    public authLoggedIn$: Observable<boolean>;
    constructor(
        private readonly httpClient: HttpClient,
        private readonly store: Store<AppState>,
        private readonly hotApiSecurityService: HotApiSecurityService,
        private readonly hotApiSecurityTokensService: HotApiSecurityTokensService,
        private readonly authenticationTokenStorageService: AuthenticationTokenStorageService,
        private readonly appInsightsService: ApplicationInsightsService,
        private readonly router: Router,
        private readonly apiConfiguration: ApiConfiguration
    ) {
        this.authFetched$ = this.store.pipe(select(authFetched));
        this.authLoggedIn$ = this.store.pipe(select(authLoggedIn));
        this.authData$ = this.store.pipe(select(authData));
    }

    public authenticate(
        userName: string,
        password: string,
        redirectUrl: string,
        impersonateUserId?: string,
        isLoginWithOtp?: boolean,
        isExternalAuthentication?: boolean
    ) {
        this.appInsightsService.startTrackingPage(TelemetryEventType.HomePageLoadingTime);

        return new Promise((resolve) => {
            const headers: HttpHeaders = new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Accept', 'application/json');
            let data: HttpParams = new HttpParams({ encoder: new CustomEncoder() })
                .set('grant_type', 'password')
                .set('scope', 'offline_access')
                .set('username', userName)
                .set('password', password);

            if (isLoginWithOtp) {
                data = data
                    .set('grant_type', 'sms_token')
                    .set('scope', 'offline_access')
                    .set('username', userName)
                    .set('token', password);
            }

            if (impersonateUserId) {
                data = data.set('impersonateUserId', impersonateUserId);
            }

            if (isExternalAuthentication) {
                data = data
                    .set('grant_type', 'external_authentication')
                    .set('password', null)
                    .set('token', password)
                    .set('redirect_uri', redirectUrl);
            }

            this.updateToken(headers, data).subscribe({
                next: (response) => {
                    this.getHomePageURL(redirectUrl);
                    resolve(response);
                },
                error: (error) => {
                    resolve(error);
                }
            });
        });
    }
    getHomePageURL(redirectUrl: string) {
        combineLatest([this.authFetched$, this.authLoggedIn$])
            .pipe(
                filter((value) => !!value[0] && !!value[1]),
                take(1)
            )
            .subscribe(() => {
                this.authData$.pipe(first()).subscribe((authData: AuthUser) => {
                    if (this.isAuthenticated() && authData.user) {
                        localStorage.setItem(StorageKeys.userId, authData.user.id);
                        // TO DO: add logic for checking available routes for different user types
                        if (authData.homePageUrl === this.defaultHomePage) {
                            this.router.navigate([redirectUrl]);
                            this.trackEvent(redirectUrl);
                        } else {
                            this.router.navigate([authData.homePageUrl]);
                            this.trackEvent(authData.homePageUrl);
                        }
                    }
                });
            });
    }

    trackEvent(redirectUrl) {
        this.appInsightsService.endTrackingPage(redirectUrl, TelemetryEventType.HomePageLoadingTime);
        this.appInsightsService.endTrackingEvent(TelemetryEventType.LoginFullResponseTime);
    }

    public isAuthenticated(): boolean {
        // We assume that the user is authenticated if they have an access token.
        // If that token is invalid or expired, failures will be handled by an error interceptor.

        const currentToken = this.authenticationTokenStorageService.getToken();

        return !!currentToken && !!currentToken.access_token;
    }

    public signOut(): Observable<any> {
        return this.hotApiSecurityService.signOut().pipe(
            finalize(() => {
                this.authenticationTokenStorageService.clear();
                this.router.navigate([signInPageUrl]);
            })
        );
    }

    public refreshAuthenticationToken() {
        const currentToken: IBearerToken = this.authenticationTokenStorageService.getToken();
        const refreshTokenValue = currentToken ? currentToken.refresh_token : null;

        const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const data: HttpParams = new HttpParams({ encoder: new CustomEncoder() })
            .set('grant_type', 'refresh_token')
            .set('scope', 'offline_access')
            .set('refresh_token', refreshTokenValue);

        return this.updateToken(headers, data).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.authenticationTokenStorageService.clear();
                }

                return this.router.navigateByUrl(signInPageUrl);
            })
        );
    }

    private updateToken(headers: HttpHeaders, data: HttpParams): Observable<IBearerToken> {
        return this.httpClient
            .post<IBearerToken>(`${this.apiConfiguration.rootUrl}/storefrontapi/hot/security/tokens`, data.toString(), {
                headers,
            })
            .pipe(
                map((response: IBearerToken) => {
                    if (response.access_token) {
                        this.authenticationTokenStorageService.updateToken(response);
                    }

                    return response;
                })
            );
    }

    public getOtp(data: any): Observable<any> {
        return this.hotApiSecurityTokensService.sendOtpLoginRequest(data);
    }
    // public getBotToken(userId:string,name:string) : Observable<IBotBearerToken>{
    //     const headers: HttpHeaders = new HttpHeaders()
    //             .set('Content-Type', 'application/x-www-form-urlencoded')
    //             .set('Accept', 'application/json')
    //             .set('Authorization','Bearer HTHcO97KSt8.kMUHm94lH1BTRFblI6XGKIAKYqstV28FB8-PG2UnWIw')
    //             .set('skip','true');
    //     return this.httpClient
    //         .post<any>(`https://directline.botframework.com/v3/directline/tokens/generate`,
    //         {
    //             user: {
    //                     id: "john@doe.com",
    //                     name: "John Doe"
    //                   }
    //         },
    //         {headers},
    //         )
    //         .pipe(
    //             map((response: IBotBearerToken) => {
    //                 return response;
    //             })
    //         );
    // }
}
