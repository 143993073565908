import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { HotApiFaqService, HotFaqItem, HotLanguage } from '@hot-theme-nx/generated-api';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsLanguageCurrent } from '@hot-b2b/store/settings/selector';
import { AccordionItem } from '@hot-libs/shared-models';
import { ModalService, LoaderServiceV2 } from 'apps/hot-b2b/src/app/shared/services';
import { feedbackPending } from '@hot-b2b/store/feedback/selectors';
import { take, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'hot-faq',
    templateUrl: './faq.component.html',
})
export class FaqComponent implements OnInit , OnDestroy {
    @Input() public showMoreIsActive: boolean;

    private readonly unsubscribe$ = new Subject();
    public accordionItems: AccordionItem[] = [];
    private readonly currentLanguage$: Observable<HotLanguage>;
    public feedbackPending$: Observable<boolean>;

    constructor(
        private readonly store: Store<AppState>,
        public readonly modalService: ModalService,
        private readonly hotApiFaqService: HotApiFaqService,
        private readonly loaderServiceV2: LoaderServiceV2
    ) {
        this.currentLanguage$ = this.store.pipe(select(settingsLanguageCurrent));
        this.feedbackPending$ = this.store.pipe(select(feedbackPending));
    }

    ngOnInit(): void {
        this.getFaq();
    }

    private getFaq(): void {
        combineLatest([this.hotApiFaqService.getFaqItems(), this.feedbackPending$]).pipe(takeUntil(this.unsubscribe$))
            .subscribe(([items, itemsFeedbackPending]) => {
                this.currentLanguage$.pipe(take(1)).subscribe((currentLanguage) => {
                    this.accordionItems = items
                        .filter((item: HotFaqItem) => item.language === currentLanguage.code)
                        .map((item: HotFaqItem) => ({
                            language: item.language,
                            title: item.question,
                            description: item.answer,
                            isOpened: false,
                        }));
                });
                if (itemsFeedbackPending) {
                    this.loaderServiceV2.show();
                } else {
                    this.loaderServiceV2.hide();
                }
            });
    }
    public ngOnDestroy(): void {
        this.unsubscribe$.next(undefined);
        this.unsubscribe$.complete();
    }
}
