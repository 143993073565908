export enum ModalIds {
    cacheWarning = 'cache-warning',
    cancelOrderConfirmation = 'cancel-order-confirmation',
    cart = 'cart',
    CannotUpdatePricesModal = 'cannot-update-prices-modal',
    cartHasChangedModal = 'cart-has-changed-modal',
    clearCartConfirmation = 'clear-cart-confirmation-modal',
    clearCartConfirmationSingle = 'clear-cart-confirmation-modal-single',
    removeSelectedItemCartConfirmationModal = 'remove-selected-item-cart-confirmation-modal',
    distributorOrder = 'distributor-order-modal',
    distributorOrderReject = 'distributor-order-reject-modal',
    distributorOrderConfirmEdit = 'distributor-order-confirm-edit-modal',
    distributorCatalogChangesSaved = 'distributor-catalog-changes-saved-modal',
    distributorOrderConfirmed = 'distributor-order-confirmed-modal',
    duplicatetabCheckoutWarning = 'duplicatetab-Checkout-warning',
    emailEdit = 'email-edit',
    faq = 'faq-modal',
    feedbackSuccess = 'feedback-success-modal',
    networkUnavailable = 'network-unavailable',
    offlineNotification = 'modal-offline-notification',
    order = 'order',
    orderHasUnfinishedPayments = 'order-has-unfinished-payments',
    orderTemplates = 'order-templates',
    orderTemplateCreateFromOrder = 'order-template-create-from-order',
    outletNotFound = 'outlet-notfound',
    passwordExpired = 'password-expired',
    phoneEdit = 'phone-edit',
    productDetails = 'product-details',
    otpTechnicalIssue = 'otp-technical-issue-modal',
    reorderConfirmation = 'reorder-confirmation',
    reportIssueConfirmation = 'report-issue-confirmation',
    reportIssueThanks = 'report-issue-thanks',
    rewardConfirm = 'rewards-confirm',
    rewardProduct = 'rewards-product-modal',
    rewardMechanics = 'rewards-modal-mechanics',
    rewardOrderResult = 'rewards-modal-result',
    templateWarningOffline = 'template-warning-offline',
    termsAndConditions = 'terms-and-conditions',
    termsAndConditionsDeclined = 'decline-notification',
    privacyPolicyDeclined = 'decline-privacy-policy-notification',
    cartValidation = 'cart-validation-modal',
    unavailableInOffline = 'unavailable-in-offline-modal',
    profileUpdate = 'profile-update-modal',
    advancedCalendar = 'advanced-calendar',
    notifyMe = 'notify-me-modal',
    orderDetails = 'order-details',
    privacyPolicy = 'privacy-policy',
    updateTermsAndConditions = 'update-terms-and-conditions',
    updatePrivacyPolicy = 'update-privacy-policy',
    addAmount = 'add-amount-order-modal',
    empties = 'empties-modal',
    rewardsTermsAndConditions = 'rewards-terms-and-conditions',
    groupOrder = 'group-order',
    fastOrder = 'fast-order',
    suggestedPromotions = 'suggested-promotions-modal',
    orderExport = 'order-export-modal',
    deliveryDetails = 'delivery-details-modal',
    orderRating = 'order-rating-modal',
    ratingConfirmation = 'rating-confirmation-modal',
    rewardMaintainance = 'reward-maintainance-modal',//OTCS-4686 modal for Loyalty Maintainance
    imageZoom = 'image-zoom-modal',
    payMethodModal = 'paymethod-modal',
    clearCartConfirmationConsignment = 'clear-cart-confirmation-modal-consignment',
    passwordResetModal = 'password-reset-modal',
    remindMeLaterModal = 'remind-me-later-modal',
    kegsReturnPage = 'kegs-returnPage-modal',
    kegsHistoryPage = 'kegs-historyPage-modal',
    failedOutletRegistration = 'outlet-failed-registration-modal',
    promotionRefreshAlert = 'promotion-refresh-alert-modal',
    tutorials = 'tutorials-modal',
    transitionConfirmation = 'transition-confirmation-modal',
    orderCancelModal = 'order-cancel-modal',
    confirmPartialDeliveryModal = 'confirm-partial-delivery-modal',
    cartHasUnavailableProductsModal = 'cart-has-unavailable-products-modal',
    insightModal = 'insight-modal',
    voucherModal = 'voucher-modal',
    disclaimer = 'disclaimer',
    ageConfirmationModal = 'age-confirmation-modal',
    distributorOrderConfirmReceivedCondition = 'distributor-order-confirm-received-condition',
    distributorOrderRejectList = 'distributor-order-reject-list-modal',
    manageChildAccount = 'manage-child-account-modal',
    distributorOrdersDispatchedModal = 'distributor-orders-dispatched-modal',
    distributorOrdersConfirmedModal = 'distributor-orders-confirmed-modal',
    filterSelectionModal = 'filter-selection-modal',
    pillSelectionModal = 'pill-selection-model',
    productPrice = 'product-price-modal',
    confirmationModal = 'confirmation-modal',
    clearCartFromFileModal = 'clear-cart-from-file-modal',
    distributorPricesTableModal = 'distributor-prices-table-modal',
    updatePriceModal = 'update-price-modal',
    sdipGroupDetail = 'sdip-group-detail',
    rejectListModal = 'reject-list-modal',
    confirmationModelCommon = 'confirmation-model-common',
    customerOrderConfirmModal = 'customer-order-confirm-modal',
    informationModal = 'information-modal',
    rewardVoucherTC = 'reward-voucher-TC',
    trackAndTraceModal = 'track-and-trace-modal',
    paymentConfirmation = 'payment-confirmation',
    digitalPaymentMethod = 'digital-payment-method-modal',
    paymentFailed = 'payment-failed',
    downloadInvoiceModal = 'download-invoce-modal',
    distributorChangeModal = 'distributor-change-modal',
    digitalAlternatePayment = 'digital-alternate-payment-method-modal',
    basketOverlayPopup = 'basket-overlay-popup',
    confirmationOverlay = 'confirmation-overlay',
    recommendedOrder = 'recommended-order'
}
