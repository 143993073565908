import { createSelector, MemoizedSelector } from '@ngrx/store';

import {
    HotCartValidationRequest,
    HotDeliveredOrdersStatistics,
    HotOperationResult,
    HotOrderSearchCriteria,
    HotOrderStatusReasonsWithLanguage,
} from '@hot-theme-nx/generated-api';

import { OrdersDataModel, OrderSimulation, OrdersState } from '@hot-b2b/store/orders/model';
import { AppState } from '@hot-b2b/store/reducers';
import { HotOrderExtended, HotOrderSearchResultExtended } from '@hot-libs/shared-models';

const stateOrders = (state: AppState): OrdersState => state.orders;

export const ordersPending: MemoizedSelector<AppState, boolean> = createSelector(
    stateOrders,
    (state: OrdersState) => state.pending
);

export const ordersFetched: MemoizedSelector<AppState, boolean> = createSelector(
    stateOrders,
    (state: OrdersState) => state.fetched
);

export const ordersData: MemoizedSelector<AppState, OrdersDataModel> = createSelector(
    stateOrders,
    (state: OrdersState) => state.data
);

export const ordersResponse: MemoizedSelector<AppState, HotOrderSearchResultExtended> = createSelector(
    stateOrders,
    (state: OrdersState) => state.data.ordersResponse
);

export const orders: MemoizedSelector<AppState, HotOrderExtended[]> = createSelector(
    stateOrders,
    (state: OrdersState) => state.data.ordersResponse.orders
);

export const ordersPrevious: MemoizedSelector<AppState, HotOrderExtended[]> = createSelector(
    stateOrders,
    (state: OrdersState) => state.data.previous
);

export const latestOrder: MemoizedSelector<AppState, HotOrderExtended[]> = createSelector(
    stateOrders,
    (state: OrdersState) => state.data.latestOrder
);

export const ordersStatistic: MemoizedSelector<AppState, HotDeliveredOrdersStatistics> = createSelector(
    stateOrders,
    (state: OrdersState) => state.data.statistics
);

export const ordersExist: MemoizedSelector<AppState, boolean> = createSelector(
    stateOrders,
    (state: OrdersState) => !!state.data.ordersResponse.totalCount
);

export const searchOrdersCriteria: MemoizedSelector<AppState, HotOrderSearchCriteria> = createSelector(
    stateOrders,
    (state: OrdersState) => state.data.searchOrdersCriteria
);

export const latestOrdersFetchedStatus: MemoizedSelector<AppState, boolean> = createSelector(
    stateOrders,
    (state: OrdersState) => state.latestOrdersFetchStatus
);

export const orderSimulationtaxDetails: MemoizedSelector<AppState, HotOperationResult> = createSelector(
    stateOrders,
    (state: OrdersState) => {
        return (
            state.orderSimulation.taxDetails
        );
    }
);
export const orderSimulationDetails: MemoizedSelector<AppState, OrderSimulation> = createSelector(
    stateOrders,
    (state: OrdersState) => {
        return (
            state.orderSimulation
        );
    }
);

export const orderSimulationSuccess: MemoizedSelector<AppState, boolean> = createSelector(
    stateOrders,
    (state: OrdersState) => {
        return (
            state.orderSimulation.taxDetails.succeeded
        );
    }

);

export const orderSimulationFailure: MemoizedSelector<AppState, Array<string>> = createSelector(
    stateOrders,
    (state: OrdersState) => {
        return (
            state.orderSimulation.taxDetails.errors
        );
    }
);

export const orderSimulationFailureErrorCode: MemoizedSelector<AppState, Array<string>> = createSelector(
    stateOrders,
    (state: OrdersState) => {
        return (
            state.orderSimulation.taxDetails.errorCodes
        );
    }
);

export const orderSimulationFailureStatus: MemoizedSelector<AppState, boolean> = createSelector(
    stateOrders,
    (state: OrdersState) => {
        return (
            (state.orderSimulation.taxDetails.succeeded && state.orderSimulation.taxDetails.errors?.length > 0 && state.orderSimulation.taxDetails.errorCodes?.length > 0) ? true : false
        );
    }
);

export const orderSimulationrequest: MemoizedSelector<AppState, HotCartValidationRequest> = createSelector(
    stateOrders,
    (state: OrdersState) => {
        return (state.validationRequest.validationRequest);
    }
);

export const getCustomerOrderRejectReasons: MemoizedSelector<
    AppState,
    HotOrderStatusReasonsWithLanguage[]
> = createSelector(stateOrders, (state: OrdersState) => state.data.customerOrderRejectReasons);

