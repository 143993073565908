import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';

import { HotPromotion, OrderSuggestion } from '@hot-theme-nx/generated-api';

import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { PromotionsState } from '@hot-b2b/store/promotions/model';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsState } from '@hot-b2b/store/settings/model';
import { stateSettings } from '@hot-b2b/store/settings/selector';
import { getActiveOrderSuggestion, getSuggestedPromotions} from './helpers';
import { SuggestedPromotion } from '@hot-libs/shared-models';
import { stateCart } from '@hot-b2b/store/cart/selector';
import { CartState } from '../cart/model';

const statePromotions = (state: AppState): PromotionsState => state.promotions;

const getWithFfc = (id: string, data, key: string) => {
    if (data.length) {
        if (id?.length === 0) {
            return data[0][key];
        } else {
            const filtered = data.filter((item) => item.id === id);
            return filtered.length ? filtered[0][key] : filtered;
        }
    }
    return [];
};

export const allPromotions: MemoizedSelector<AppState, HotPromotion[]> = createSelector(
    statePromotions,
    stateSettings,
    (state: PromotionsState, settings: SettingsState) =>
        getWithFfc(settings.data.ffc.id, state.data.slots, 'promotions')
);

export const visiblePromotions: MemoizedSelector<AppState, HotPromotion[]> = createSelector(
    statePromotions,
    stateSettings,
    (state: PromotionsState, settings: SettingsState) =>
        getWithFfc(settings.data.ffc.id, state.data.slots, 'promotions')?.filter(
            (promotion: HotPromotion) => promotion.showOnStorefront
        )
);

export const suggestedPromotions: MemoizedSelector<AppState, SuggestedPromotion[]> = createSelector(
    statePromotions,
    stateSettings,
    stateCart,
    (state: PromotionsState, settings: SettingsState, cart: CartState) =>
        getSuggestedPromotions(getWithFfc(settings.data.ffc.id, state.data.slots, 'promotions'), cart.data.cart.items)
);

export const promotionByCode: MemoizedSelectorWithProps<
    AppState,
    string,
    HotPromotion
> = createSelector(statePromotions, stateSettings, (state: PromotionsState, settings: SettingsState, code: string) =>
    getWithFfc(settings.data.ffc.id, state.data.slots, 'promotions').find((item: HotPromotion) => item.code === code)
);

export const promotionProducts: MemoizedSelectorWithProps<AppState, string, HotProductExtended[]> = createSelector(
    statePromotions,
    (state: PromotionsState) => state.data.products
);

export const promotionFindProduct: MemoizedSelectorWithProps<
    AppState,
    string,
    HotProductExtended
> = createSelector(statePromotions, (state: PromotionsState, id: string) =>
    state.data.products.find((product: HotProductExtended) => product.id === id)
);

export const activeOrderSuggestion: MemoizedSelector<AppState, OrderSuggestion> = createSelector(
    statePromotions,
    (promotionsState: PromotionsState) => {
        const suggestion = promotionsState.data.orderSuggestion;

        if (!suggestion) {
            return null;
        }

        return suggestion ? getActiveOrderSuggestion(suggestion) : null;
    }
);

export const promotionPendingStatus: MemoizedSelector<AppState, boolean> = createSelector(
    statePromotions,
    (state: PromotionsState) => state.pending
);
