import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { OrderCreationService } from '../../../../shared/services/order-creation.service';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { selectModalData } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';

@Component({
    selector: 'hot-payment-confirmation-modal',
    templateUrl: './payment-confirmation-modal.component.html',
})
export class PaymentConfirmationModalComponent implements OnInit {
    
    public orderNumber: string;

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly router: Router,
        private readonly orderCreationService: OrderCreationService
    ) {}

    public ngOnInit(): void {
        this.store.pipe(select(selectModalData(ModalIds.paymentConfirmation))).subscribe((data: any) => {
            if (data) {
                this.orderNumber = data.orderNumber;
            }
        });
    }

    public createOrder(): void {
        if (this.orderNumber) {
            this.router.navigate(['/payment-process', this.orderNumber]);
        } else {
            this.orderCreationService.createOrder();
        }
        this.close();
    }

    public close(): void {
        this.modalService.toggleModal(ModalIds.paymentConfirmation, false);
    }

}
