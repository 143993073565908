import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { HotLegalAgreement } from '@hot-theme-nx/generated-api';
import { HotUserExtended } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';

import { FeaturesService, ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';

import { LegalAgreementService } from '../../services/legal-agreement.service';
import { Router } from '@angular/router';
import { ConfigurationService } from '../../services/configuration.service';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { config } from 'apps/hot-b2b/src/environments/config';

@Component({
    selector: 'hot-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit, OnDestroy {
    public isTermsAndConditionsExist: boolean;
    public termsModalTitle: string;
    public featureCookieWall: boolean = this.featuresService.CookieWall;

    public isNgB2b = () => this.configurationService.getCurrentConfiguration() === 'NG';
    public isVnB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';
    public isRe = () => this.configurationService.getCurrentConfiguration() === 'RE';
    public isGrB2b = () => this.configurationService.getCurrentConfiguration() === 'GR';

    private readonly user$: Observable<HotUserExtended>;
    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    public hotSettings$: Observable<HotSettingsExtended>;

    private readonly termsModalTitleLocalizationKey = 'shared.footer.terms-modal-title';
    private readonly termsModalUpdateTitleLocalizationKey = 'shared.modals.terms-and-use.update-title';
    private readonly termsOfUseTranslateKey = 'shared.footer.terms-title';
    private readonly privacyPolicyTranslateKey = 'shared.footer.privacy-policy-title';

    private termsOfUseVisibleState: boolean;
    private privacyPolicyVisibleState: boolean;
    public displayContactSupportEmail: boolean;
    public displayContactSupportPhone: boolean;
    public email: string;
    public phoneNumber: string;
    public isEmailAvailable: boolean = true;
    public isPhoneNumberAvailable: boolean = true;
    public announcementUrl: string;
    public subscriptionUrl: string;
    public layoutSpecific = config?.layoutSpecific || false;
    public cheersUrl = config?.cheersUrl || '/';
    public displayInstantMessagingSetting: boolean;
    public instantMessagingURL: string;
    public textForInstantMessagingLink: string;
    public showInstantMessaging: boolean;

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly featuresService: FeaturesService,
        private readonly translate: TranslateService,
        private readonly legalAgreementService: LegalAgreementService,
        private readonly configurationService: ConfigurationService,
        public readonly router: Router
    ) {
        this.user$ = this.store.pipe(select(authUser));
        this.hotSettings$ = this.store.pipe(select(settingsData));
    }

    public ngOnInit(): void {
        if (this.isVnB2b()) {
            this.legalAgreementService.currentAnnouncement$
                .pipe(takeUntil(this.subscriptionsDestroy$))
                .subscribe((result: HotLegalAgreement) => {
                    if (result?.content) {
                        this.announcementUrl = result.content;
                    }
                });

            this.legalAgreementService.currentSubscription$
                .pipe(takeUntil(this.subscriptionsDestroy$))
                .subscribe((result: HotLegalAgreement) => {
                    if (result?.content) {
                        this.subscriptionUrl = result.content;
                    }
                });
        } else {
            this.translate.onLangChange.subscribe((_event: LangChangeEvent) => {
                this.termsOfUseVisibleState = undefined;
                this.privacyPolicyVisibleState = undefined;
            });

            this.legalAgreementService.currentTermsAndConditions$
                .pipe(withLatestFrom(this.user$), takeUntil(this.subscriptionsDestroy$))
                .subscribe(([termsAndConditions, user]: [HotLegalAgreement, HotUserExtended]) => {
                    this.serviceAgreementCondition(termsAndConditions, user);
                });
        }
        this.hotSettings$.subscribe((settings: HotSettingsExtended) => {
            this.contactSupport(settings);
            this.instantMessagingRedirection(settings);
        });
    }
    public serviceAgreementCondition(termsAndConditions, user) {
        this.isTermsAndConditionsExist = !!termsAndConditions;
        if (termsAndConditions) {
            const isTermsAndConditionsAccepted =
                user.contact.termsAndConditionsAcceptedDate > termsAndConditions.modifiedDate;

            this.termsModalTitle =
                user.contact.termsAndConditionsAcceptedDate && !isTermsAndConditionsAccepted
                    ? this.termsModalUpdateTitleLocalizationKey
                    : this.termsModalTitleLocalizationKey;
        }
    }
    public contactSupport(settings) {
        this.email = settings.support.contactSupportEmail;
        this.phoneNumber = settings.support.contactSupportPhone;
        this.displayContactSupportEmail = settings.support.displayContactSupportEmail;
        this.displayContactSupportPhone = settings.support.displayContactSupportPhone;      
        if (this.email == null || this.email == undefined || this.email.trim() == '') {
            this.isEmailAvailable = false;
        }
        if (this.phoneNumber == null || this.phoneNumber == undefined || this.phoneNumber.trim() == '') {
            this.isPhoneNumberAvailable = false;
        }
    }

    public instantMessagingRedirection(settings) {
        this.displayInstantMessagingSetting = settings.support?.displayInstantMessagingDetails;
        this.instantMessagingURL = settings.support?.instantMessagingURL;
        this.textForInstantMessagingLink = settings.support?.textForInstantMessagingLink;
    
        if (!this.instantMessagingURL || !this.textForInstantMessagingLink || !this.displayInstantMessagingSetting) {
            this.showInstantMessaging = false;
        } else {
            this.showInstantMessaging = true;
        }
    }
    
    
    public toggleModal(name: string, state: boolean): void {
        this.modalService.toggleModal(ModalIds[name], state);
    }

    public get isTermsOfUseVisible(): boolean {
        if (this.termsOfUseVisibleState === undefined) {
            this.termsOfUseVisibleState = this.translateKeyExistsAndNotEmpty(this.termsOfUseTranslateKey);
        }

        return this.termsOfUseVisibleState;
    }

    public get isPrivacyPolicyVisible(): boolean {
        if (this.privacyPolicyVisibleState === undefined) {
            this.privacyPolicyVisibleState = this.translateKeyExistsAndNotEmpty(this.privacyPolicyTranslateKey);
        }

        return this.privacyPolicyVisibleState;
    }

    public onTermsAndConditionAccept(): void {
        this.termsModalTitle = this.termsModalTitleLocalizationKey;
    }

    public scrollToTop() {
        window.scrollTo(0, 0);
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }

    private translateKeyExistsAndNotEmpty(localizationKey: string): boolean {
        let keyIsNotNullAndNotEmpty: boolean;
        this.translate.get(localizationKey).subscribe((result: string) => {
            keyIsNotNullAndNotEmpty = result !== localizationKey && result !== '';
        });

        return keyIsNotNullAndNotEmpty;
    }

    public toggleInformationModal(type: string, state: boolean): void {
        this.modalService.toggleModal(ModalIds.informationModal, state, { type });
    }

    public openAnnouncement(): void {
        if (this.announcementUrl) {
            window.open(this.announcementUrl, '_blank');
        }       
    }

    public openTelegram(): void {
        if (this.instantMessagingURL) {
            window.open(this.instantMessagingURL, '_blank');
        }
    }

    public openSubscription(): void {
        if (this.subscriptionUrl) {
            window.open(this.subscriptionUrl, '_blank');
        }
    }
}
