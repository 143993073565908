import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { UiModule } from '@hot-theme-nx/ui';

import { SearchDistrComponent } from '../distributor/components/search-distr/search-distr.component';
import {
    AccordionComponent,
    AddContentComponent,
    AddSidebarComponent,
    BackButtonComponent,
    BannerComponent,
    BasePageComponent,
    BreadcrumbsComponent,
    CalendarComponent,
    CartIconComponent,
    CartInformerComponent,
    CartPalletCounterComponent,
    CartSidebarComponent,
    CartTotalsComponent,
    ContactComponent,
    ContentComponent,
    CountdownComponent,
    DateSelectorComponent,
    DeliveryComponent,
    DesktopMenuComponent,
    DistributorOrderItemPriceComponent,
    DividerComponent,
    FaqComponent,
    FiltersCarouselComponent,
    FiltersComponent,
    FooterComponent,
    HeaderComponent,
    PopRetryPaymentComponent,
    HotAlertComponent,
    HotButtonComponent,
    HotCheckboxComponent,
    HotInputComponent,
    HotInputErrorsComponent,
    HotInputOnlinePaymentComponent,
    HotModalComponent,
    HotNxCalendarComponent,
    HotSelectComponent,
    HotSwitchComponent,
    ImageCarouselComponent,
    InsightsListComponent,
    LangSelectComponent,
    LayoutComponent,
    ModeSwitcherComponent,
    MultiFiltersComponent,
    NavComponent,
    NotificationListComponent,
    NotificationsComponent,
    OfflineComponent,
    OpcoInfoComponent,
    OrderDeliveredComponent,
    OrderInformerComponent,
    OrderListComponent,
    OrderListTableComponent,
    OrderStatusComponent,
    OrderStepsComponent,
    OrderTotalComponent,
    OrderTotalsComponent,
    OutletSelectorComponent,
    PatternsCheckListComponent,
    PriceComponent,
    ProductComponent,
    ProductImageComponent,
    ProductListComponent,
    QuantityInputComponent,
    RewardPointsComponent,
    SidebarComponent,
    StringLengthInformerComponent,
    SuggestedInsightComponent,
    TierStatusComponent,
    TooltipComponent,
    TotalsComponent,
    UserInfoComponent,
    VolumePricingComponent,
    WarehouseSelectButtonComponent,
    WeekCalendarComponent,
    PromoInsightCarouselComponent,
    VoucherComponent,
    VoucherListComponent,
    WarehouseSelectorComponent,
    PerfVolumesComponent,
    PerfQuarterComponent,
    NotFoundBlockComponent,
    HotVolumeProgress,
    SimpleTabComponent,
    SimpleTabsComponent,
    DistributorWarehouseSelectorComponent,
    PhoneTopupComponent,
    ProductLimitMessageComponent,
    CashPaymentLoaderComponent
} from '../shared/components';
import {
    OrderCancelModalComponent,
    AddAmountModalComponent,
    CacheWarningModalComponent,
    CancelOrderConfirmationModalComponent,
    CartValidationModalComponent,
    ClearCartConfirmationModalComponent,
    CannotUpdatePricesModal,
    CartHasChangedModalComponent,
    DeclineNotificationModalComponent,
    DeclinePrivacyPolicyNotificationModalComponent,
    DistributorCatalogChangesSavedModalComponent,
    DistributorOrderConfirmEditModalComponent,
    DistributorOrderConfirmedModalComponent,
    DistributorOrderModalComponent,
    DistributorRejectListModalComponent,
    DistributorOrderRejectModalComponent,
    EmptiesModalComponent,
    FaqModalComponent,
    FastOrderModalComponent,
    FeedbackSuccessfulModalComponent,
    GroupOrderModalComponent,
    NetworkUnavailableModalComponent,
    NotifyMeModalComponent,
    OfflineNotificationModalComponent,
    OrderHasUnfinishedPaymentsModalComponent,
    OrderModalComponent,
    OrderTemplatesModalComponent,
    OutletNotFoundModalComponent,
    PasswordExpiredModalComponent,
    PasswordResetModalComponent,
    PaymentConfirmationModalComponent,
    PaymentFailedModalComponent,
    PrivacyPolicyModalComponent,
    ProductDetailsModalComponent,
    ProfileUpdateModalComponent,
    PromoModalComponent,
    OtpTechnicalIssueModalComponent,
    ReorderConfirmationModalComponent,
    ReportIssueConfirmationModalComponent,
    ReportIssueThanksModalComponent,
    RewardsConfirmComponent,
    RewardsModalComponent,
    RewardsOrderResultComponent,
    RewardsPointsModalComponent,
    RewardsProductModalComponent,
    RewardsTermsModalComponent,
    TemplateSaveWarningModalComponent,
    TermsAndConditionsModalComponent,
    UnavailableInOfflineModalComponent,
    UpdateTermsAndConditionsModalComponent,
    UpdatePrivacyPolicyModalComponent,
    VerificationCodeModalComponent,
    OrderExportModalComponent,
    OrderRatingModalComponent,
    RatingConfirmationModal,
    DeliveryDetailsModalComponent,
    RewardMaintainanceModalComponent,
    PaymentMethodModalComponent,
    RemindMeLaterModalComponent,
    TransitionConfirmationModalComponent,
    VoucherModalComponent,
    CartHasUnavailableProductsModalComponent,
    DisclaimerModalComponent,
    CartExternalValidationModalComponent,
    AgeConfirmationModalComponent,
    DistributorOrderConfirmReceivedConditionModalComponent,
    ConfirmPartialDeliveryModalComponent,
    ManageChildAccountModalComponent,
    SdipGroupDetailModalComponent,
    DistributorOrdersDispatchedModalComponent,
    ProductPriceModalComponent,
    ConfirmationModalComponent,
    UpdatePriceModalComponent,
    DistributorPricesTableModalComponent,
    RejectListModalComponent,
    ConfirmCustomerModalComponent,
    InformationModalComponent,
    RewardsVoucherTCModalComponent,
    RemoveSelectedItemCartConfirmationModalComponent,
    TrackAndTraceModalComponent,
    DistributorOrdersConfirmedModalComponent,
    DigitalPaymentComponent,
    DownloadInvoiceComponent,
    DistributorChangeModalComponent,
    RecommendedOrderModel
} from '../shared/components/modal-content';
import {
    AsStyleUrlPipe,
    CurrencyFormatPipe,
    CurrencyFormatOrderSimulationPipe,
    FilterBrandsPipe,
    FilterByArrayPipe,
    FilterByValuePipe,
    FilterProductsPipe,
    QuantityFormatPipe,
    SafeStyleUrl,
    SearchByValuePipe,
    SearchOutletPipe,
    ThumbPipe,
    TimeFromNowPipe,
    TransformWeightPipe,
    TranslateFallbackPipe,
    UndefinedValuePipe,
    GridProdcutNameFormatPipe,
    MaxCountTwentyTwoFormatPipe,
    ReplacePipe,
    SafeInnerHtml,
    SearchFfcPipe,
    QuarterRomanPipe,
} from '../shared/pipes';

import { ModalDialogsListComponent } from './components/modal-dialogs-list/modal-dialogs-list.component';

import { NguCarouselModule } from '@ngu/carousel';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { DpDatePickerModule } from 'ng2-date-picker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WithExtensionOrDefaultPipe } from './pipes/with-extension-or-default.pipe';
import { ConvertFrom24To12FormatPipe } from './pipes/convert-from24-to12-format.pipe';
import { FullscreenZoomModalComponent } from './components/modal-content/fullscreen-zoom-modal/fullscreen-zoom-modal.component';
import { CountdownModule } from 'ngx-countdown';
import { KegsReturnPageComponent } from './components/modal-content/kegs-return-page/kegs-return-page.component';
import { InsightVideoComponent } from './components/insight-video/insight-video.component';
import { OutletFailedRegistrationModalComponent } from './components/modal-content/outlet-failed-registration-modal/outlet-failed-registration-modal.component';
import { PromotionRefreshModalComponent } from './components/modal-content/promotion-refresh-modal/promotion-refresh-modal.component';
import { OfflineCatalogDownloadWarningModalComponent } from './components/modal-content/offline-catalog-download-warning-modal/offline-catalog-download-warning-modal.component';
import { TutorialsModalComponent } from './components/modal-content/tutorials-modal/tutorials-modal.component';
import { TabComponent } from './components/tab/tab.component';
import { QuickFiltersComponent } from './components/quick-filters/quick-filters.component';
import { FilterSelectionModalComponent } from './components/modal-content/filter-selection-modal/filter-selection-modal.component';
import { PillsSelectionModalComponent } from './components/modal-content/pills-selection-modal/pills-selection-modal.component';
import { HotPillComponent } from './components/hot-pill/hot-pill.component';
import { AdvanceFilterPanelComponent } from './components/advance-filter-panel/advance-filter-panel.component';
import { AdvancedBrandgroupFilterComponent } from './components/advanced-brandgroup-filter/advanced-brandgroup-filter.component';
import { ClearCartFromFileModal } from './components/modal-content/clear-cart-from-file/clear-cart-from-file-modal.component';
import { BasketOverlayComponent } from './components/basket-overlay/basket-overlay.component';
import { ConfirmationOverlayComponent } from './components/confirmation-overlay/confirmation-overlay.component';
import { DuplicatetabCheckoutWarningModalComponent } from './components/modal-content/duplicatetab-checkout-warning-modal/duplicatetab-checkout-warning-modal.component';
import { AccountDatatableComponent } from './components/account-datatable/account-datatable.component';
import { PaymentProcessModalComponent } from './components/modal-content/payment-process-modal/payment-process-modal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PromotionPerformanceListComponent } from './components/promotion-performance-list/promotion-performance-list.component';
import { BemModule } from './directives/bem';
import { DistributorCatalogProductComponent } from '../distributor/components/distributor-catalog-product/distributor-catalog-product.component';
import { CarouselOverlayEazleComponent } from '../catalog/components/carousel-overlay-eazle/carousel-overlay-eazle';
import { ImageModule } from './image.module';
@NgModule({
    declarations: [
        TranslateFallbackPipe,
        OrderCancelModalComponent,
        PopRetryPaymentComponent,
        HeaderComponent,
        LayoutComponent,
        FooterComponent,
        NavComponent,
        HotAlertComponent,
        HotInputComponent,
        HotInputOnlinePaymentComponent,
        HotButtonComponent,
        HotSwitchComponent,
        HotCheckboxComponent,
        HotSelectComponent,
        TooltipComponent,
        BackButtonComponent,
        BasePageComponent,
        ContentComponent,
        CartSidebarComponent,
        CartInformerComponent,
        BannerComponent,
        InsightsListComponent,
        PromotionPerformanceListComponent,
        AddSidebarComponent,
        AddContentComponent,
        ProductComponent,
        LangSelectComponent,
        HotInputErrorsComponent,
        ProductImageComponent,
        TransformWeightPipe,
        QuantityFormatPipe,
        QuantityInputComponent,
        ProductListComponent,
        FiltersComponent,
        OrderListComponent,
        OrderListTableComponent,
        OutletSelectorComponent,
        UserInfoComponent,
        VolumePricingComponent,
        TotalsComponent,
        HotModalComponent,
        SidebarComponent,
        DeliveryComponent,
        ProductDetailsModalComponent,
        CartValidationModalComponent,
        CartHasChangedModalComponent,
        TermsAndConditionsModalComponent,
        OrderModalComponent,
        OrderHasUnfinishedPaymentsModalComponent,
        OrderTemplatesModalComponent,
        DistributorOrderModalComponent,
        DistributorOrdersDispatchedModalComponent,
        DistributorRejectListModalComponent,
        DistributorOrderRejectModalComponent,
        CalendarComponent,
        DateSelectorComponent,
        OrderStatusComponent,
        OpcoInfoComponent,
        ModeSwitcherComponent,
        CartIconComponent,
        SafeStyleUrl,
        ThumbPipe,
        TimeFromNowPipe,
        SuggestedInsightComponent,
        PasswordExpiredModalComponent,
        PasswordResetModalComponent,
        RemindMeLaterModalComponent,
        OtpTechnicalIssueModalComponent,
        PaymentConfirmationModalComponent,
        PaymentFailedModalComponent,
        ReorderConfirmationModalComponent,
        ReportIssueConfirmationModalComponent,
        ReportIssueThanksModalComponent,
        VerificationCodeModalComponent,
        OrderDeliveredComponent,
        OrderTotalComponent,
        SearchDistrComponent,
        RewardsModalComponent,
        RewardsProductModalComponent,
        RewardsConfirmComponent,
        RewardsOrderResultComponent,
        OrderDeliveredComponent,
        SuggestedInsightComponent,
        TemplateSaveWarningModalComponent,
        CacheWarningModalComponent,
        OfflineNotificationModalComponent,
        CartHasUnavailableProductsModalComponent,
        OrderExportModalComponent,
        NetworkUnavailableModalComponent,
        RewardsTermsModalComponent,
        OutletNotFoundModalComponent,
        FaqComponent,
        FilterByArrayPipe,
        FilterByValuePipe,
        FilterProductsPipe,
        FilterBrandsPipe,
        SearchByValuePipe,
        SearchOutletPipe,
        QuantityFormatPipe,
        CurrencyFormatPipe,
        CurrencyFormatOrderSimulationPipe,
        WithExtensionOrDefaultPipe,
        RewardsModalComponent,
        NotificationsComponent,
        NotificationListComponent,
        RewardPointsComponent,
        FeedbackSuccessfulModalComponent,
        UndefinedValuePipe,
        GridProdcutNameFormatPipe,
        MaxCountTwentyTwoFormatPipe,
        StringLengthInformerComponent,
        CartPalletCounterComponent,
        CartTotalsComponent,
        OrderTotalsComponent,
        TierStatusComponent,
        WeekCalendarComponent,
        DeclineNotificationModalComponent,
        DeclinePrivacyPolicyNotificationModalComponent,
        OrderStepsComponent,
        ClearCartConfirmationModalComponent,
        CannotUpdatePricesModal,
        AccordionComponent,
        FaqModalComponent,
        CancelOrderConfirmationModalComponent,
        WarehouseSelectButtonComponent,
        UnavailableInOfflineModalComponent,
        DesktopMenuComponent,
        PriceComponent,
        ContactComponent,
        RewardsPointsModalComponent,
        ProfileUpdateModalComponent,
        BreadcrumbsComponent,
        FiltersCarouselComponent,
        NotifyMeModalComponent,
        FaqComponent,
        AsStyleUrlPipe,
        ReplacePipe,
        SafeInnerHtml,
        HotNxCalendarComponent,
        OrderInformerComponent,
        PatternsCheckListComponent,
        OfflineComponent,
        PrivacyPolicyModalComponent,
        UpdateTermsAndConditionsModalComponent,
        UpdatePrivacyPolicyModalComponent,
        ModalDialogsListComponent,
        DistributorCatalogChangesSavedModalComponent,
        DistributorOrderItemPriceComponent,
        DistributorOrderConfirmEditModalComponent,
        DistributorOrderConfirmedModalComponent,
        CountdownComponent,
        AddAmountModalComponent,
        DividerComponent,
        EmptiesModalComponent,
        ImageCarouselComponent,
        MultiFiltersComponent,
        GroupOrderModalComponent,
        FastOrderModalComponent,
        PromoModalComponent,
        CartExternalValidationModalComponent,
        DistributorCatalogProductComponent,
        ConvertFrom24To12FormatPipe,
        OrderRatingModalComponent,
        RatingConfirmationModal,
        ClearCartFromFileModal,
        DeliveryDetailsModalComponent,
        RewardMaintainanceModalComponent,
        FullscreenZoomModalComponent,
        PaymentMethodModalComponent,
        KegsReturnPageComponent,
        InsightVideoComponent,
        OutletFailedRegistrationModalComponent,
        PromotionRefreshModalComponent,
        OfflineCatalogDownloadWarningModalComponent,
        TutorialsModalComponent,
        TransitionConfirmationModalComponent,
        PromoInsightCarouselComponent,
        VoucherComponent,
        VoucherListComponent,
        VoucherModalComponent,
        DisclaimerModalComponent,
        AgeConfirmationModalComponent,
        DistributorOrderConfirmReceivedConditionModalComponent,
        ConfirmPartialDeliveryModalComponent,
        ManageChildAccountModalComponent,
        TabComponent,
        QuickFiltersComponent,
        DistributorPricesTableModalComponent,
        WarehouseSelectorComponent,
        SearchFfcPipe,
        FilterSelectionModalComponent,
        PillsSelectionModalComponent,
        ProductPriceModalComponent,
        ConfirmationModalComponent,
        UpdatePriceModalComponent,
        PerfVolumesComponent,
        PerfQuarterComponent,
        QuarterRomanPipe,
        SdipGroupDetailModalComponent,
        NotFoundBlockComponent,
        RejectListModalComponent,
        ConfirmCustomerModalComponent,
        HotPillComponent,
        AdvanceFilterPanelComponent,
        CashPaymentLoaderComponent,
        AdvancedBrandgroupFilterComponent,
        HotVolumeProgress,
        SimpleTabComponent,
        SimpleTabsComponent,
        InformationModalComponent,
        DistributorWarehouseSelectorComponent,
        RewardsVoucherTCModalComponent,
        PhoneTopupComponent,
        ConfirmPartialDeliveryModalComponent,
        RemoveSelectedItemCartConfirmationModalComponent,
        TrackAndTraceModalComponent,
        ProductLimitMessageComponent,
        BasketOverlayComponent,
        DistributorOrdersConfirmedModalComponent,
        ConfirmationOverlayComponent,
        DuplicatetabCheckoutWarningModalComponent,
        DigitalPaymentComponent,
        AccountDatatableComponent,
        DownloadInvoiceComponent,
        PaymentProcessModalComponent,
        DistributorChangeModalComponent,
        CarouselOverlayEazleComponent,
        RecommendedOrderModel
    ],
    imports: [
        TranslateModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ImageModule,
        AngularSvgIconModule.forRoot(),
        BemModule,
        NgClickOutsideDirective,
        NguCarouselModule,
        UiModule,
        DpDatePickerModule,
        InfiniteScrollModule,
        CountdownModule,
        NgxSkeletonLoaderModule
        ],
    exports: [
        BasketOverlayComponent,
        OrderCancelModalComponent,
        TranslateModule,
        RouterModule,
        AngularSvgIconModule,
        UiModule,
        PatternsCheckListComponent,
        BemModule,
        NgClickOutsideDirective,
        NguCarouselModule,
        InfiniteScrollModule,
        OtpTechnicalIssueModalComponent,
        ReorderConfirmationModalComponent,
        ReportIssueConfirmationModalComponent,
        ReportIssueThanksModalComponent,
        CartHasChangedModalComponent,
        PopRetryPaymentComponent,
        LayoutComponent,
        FooterComponent,
        HotAlertComponent,
        HotInputErrorsComponent,
        HotInputComponent,
        HotInputOnlinePaymentComponent,
        HotButtonComponent,
        HotSwitchComponent,
        HotCheckboxComponent,
        HotSelectComponent,
        TooltipComponent,
        BackButtonComponent,
        BasePageComponent,
        ContentComponent,
        CartSidebarComponent,
        CartInformerComponent,
        BannerComponent,
        InsightsListComponent,
        AddSidebarComponent,
        PromotionPerformanceListComponent,
        AddContentComponent,
        ProductComponent,
        LangSelectComponent,
        ProductImageComponent,
        TransformWeightPipe,
        QuantityFormatPipe,
        GridProdcutNameFormatPipe,
        MaxCountTwentyTwoFormatPipe,
        QuantityInputComponent,
        ProductListComponent,
        FiltersComponent,
        OrderListComponent,
        OrderListTableComponent,
        OutletSelectorComponent,
        UserInfoComponent,
        VolumePricingComponent,
        TotalsComponent,
        HotModalComponent,
        UnavailableInOfflineModalComponent,
        SidebarComponent,
        DeliveryComponent,
        ProductDetailsModalComponent,
        CartPalletCounterComponent,
        CartValidationModalComponent,
        DistributorOrderModalComponent,
        DistributorOrdersDispatchedModalComponent,
        DistributorRejectListModalComponent,
        DistributorOrderRejectModalComponent,
        OrderModalComponent,
        OrderHasUnfinishedPaymentsModalComponent,
        OrderTemplatesModalComponent,
        TermsAndConditionsModalComponent,
        CalendarComponent,
        DateSelectorComponent,
        OrderStatusComponent,
        OpcoInfoComponent,
        ModeSwitcherComponent,
        CartIconComponent,
        SuggestedInsightComponent,
        SafeStyleUrl,
        ThumbPipe,
        CurrencyFormatPipe,
        CurrencyFormatOrderSimulationPipe,
        TimeFromNowPipe,
        PasswordExpiredModalComponent,
        PasswordResetModalComponent,
        RemindMeLaterModalComponent,
        PaymentConfirmationModalComponent,
        PaymentFailedModalComponent,
        TemplateSaveWarningModalComponent,
        VerificationCodeModalComponent,
        OrderDeliveredComponent,
        CacheWarningModalComponent,
        OfflineNotificationModalComponent,
        CartHasUnavailableProductsModalComponent,
        NetworkUnavailableModalComponent,
        RewardsTermsModalComponent,
        OutletNotFoundModalComponent,
        FaqComponent,
        FilterByArrayPipe,
        FilterByValuePipe,
        FilterProductsPipe,
        FilterBrandsPipe,
        SearchByValuePipe,
        SearchOutletPipe,
        QuantityFormatPipe,
        WithExtensionOrDefaultPipe,
        SearchDistrComponent,
        PriceComponent,
        RewardsModalComponent,
        RewardsProductModalComponent,
        RewardsConfirmComponent,
        RewardsOrderResultComponent,
        NotificationsComponent,
        NotificationListComponent,
        RewardPointsComponent,
        FeedbackSuccessfulModalComponent,
        UndefinedValuePipe,
        StringLengthInformerComponent,
        CartTotalsComponent,
        OrderTotalsComponent,
        TierStatusComponent,
        WeekCalendarComponent,
        DeclineNotificationModalComponent,
        DeclinePrivacyPolicyNotificationModalComponent,
        OrderStepsComponent,
        AccordionComponent,
        FaqModalComponent,
        CancelOrderConfirmationModalComponent,
        ContactComponent,
        ProfileUpdateModalComponent,
        BreadcrumbsComponent,
        FiltersCarouselComponent,
        RewardsPointsModalComponent,
        NotifyMeModalComponent,
        ClearCartConfirmationModalComponent,
        CannotUpdatePricesModal,
        AsStyleUrlPipe,
        ReplacePipe,
        SafeInnerHtml,
        HotNxCalendarComponent,
        OrderInformerComponent,
        OfflineComponent,
        PrivacyPolicyModalComponent,
        DistributorCatalogChangesSavedModalComponent,
        DistributorOrderConfirmEditModalComponent,
        DistributorOrderConfirmedModalComponent,
        CountdownComponent,
        AddAmountModalComponent,
        DividerComponent,
        EmptiesModalComponent,
        ImageCarouselComponent,
        MultiFiltersComponent,
        GroupOrderModalComponent,
        FastOrderModalComponent,
        PromoModalComponent,
        CartExternalValidationModalComponent,
        DistributorCatalogProductComponent,
        OrderExportModalComponent,
        ConvertFrom24To12FormatPipe,
        OrderRatingModalComponent,
        RatingConfirmationModal,
        ClearCartFromFileModal,
        DeliveryDetailsModalComponent,
        RewardMaintainanceModalComponent,
        PaymentMethodModalComponent,
        CountdownModule,
        NgxSkeletonLoaderModule,
        InsightVideoComponent,
        OutletFailedRegistrationModalComponent,
        PromotionRefreshModalComponent,
        TutorialsModalComponent,
        PromoInsightCarouselComponent,
        TransitionConfirmationModalComponent,
        VoucherComponent,
        VoucherListComponent,
        VoucherModalComponent,
        DisclaimerModalComponent,
        AgeConfirmationModalComponent,
        DistributorOrderConfirmReceivedConditionModalComponent,
        ConfirmPartialDeliveryModalComponent,
        ManageChildAccountModalComponent,
        TabComponent,
        QuickFiltersComponent,
        DistributorPricesTableModalComponent,
        WarehouseSelectorComponent,
        SearchFfcPipe,
        FilterSelectionModalComponent,
        PillsSelectionModalComponent,
        ProductPriceModalComponent,
        ConfirmationModalComponent,
        UpdatePriceModalComponent,
        PerfVolumesComponent,
        PerfQuarterComponent,
        QuarterRomanPipe,
        SdipGroupDetailModalComponent,
        NotFoundBlockComponent,
        RejectListModalComponent,
        ConfirmCustomerModalComponent,
        HotPillComponent,
        AdvanceFilterPanelComponent,
        CashPaymentLoaderComponent,
        HotVolumeProgress,
        SimpleTabComponent,
        SimpleTabsComponent,
        InformationModalComponent,
        DistributorWarehouseSelectorComponent,
        RewardsVoucherTCModalComponent,
        PhoneTopupComponent,
        RemoveSelectedItemCartConfirmationModalComponent,
        TrackAndTraceModalComponent,
        ProductLimitMessageComponent,
        DistributorOrdersConfirmedModalComponent,
        DigitalPaymentComponent,
        AccountDatatableComponent,
        DownloadInvoiceComponent,
        PaymentProcessModalComponent,
        DistributorChangeModalComponent,
        CarouselOverlayEazleComponent,
        RecommendedOrderModel
    ],
    providers: [DatePipe]
})
export class SharedModule {}
