import { Component, Inject, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { NotificationMessage } from '@hot-theme-nx/generated-api';

import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { take } from 'rxjs/operators';
import { NotificationType } from '@hot-libs/shared-types';

@Component({
    selector: 'promo-insight-carousel',
    templateUrl: './promo-insight-carousel.component.html',
})
export class PromoInsightCarouselComponent implements OnInit, OnChanges {
    @ViewChild('promoInsightCarousel', { static: true }) public promoInsightCarousel: NguCarousel<any>;
    public carouselConfig: NguCarouselConfig;
    @Input() public notifications: NotificationMessage[];
    @Input() public dateFormat: string;
    @Input() public locale: string;

    public leftNavDisabled = true;
    public rightNavDisabled: boolean;
    public isLastSlide: boolean;

    private settings$: Observable<HotSettingsExtended>;
    private navigateToPromotionAndInsightDetail: boolean = false;

    constructor(
        @Inject(DOCUMENT) private readonly _document: any,
        private readonly store: Store<AppState>,
        private readonly router: Router
    ) {
        this.settings$ = this.store.pipe(select(settingsData));
    }

    ngOnChanges(): void {
        this.rightNavDisabled =
            this.notifications?.length <= 1 || this.notifications?.length <= this.promoInsightCarousel?.items;
    }

    public ngOnInit(): void {
        this.carouselConfig = {
            grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
            speed: 300,
            point: {
                visible: false,
            },
            velocity: 0,
            touch: true,
            easing: 'ease',
            loop: false,
            RTL: this._document.documentElement.dir === 'rtl',
        };
        this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
            this.navigateToPromotionAndInsightDetail = settings.navigateToPromotionAndInsightDetail;
        });
    }

    public onMoveFn(): void {
        this.leftNavDisabled = !!this.promoInsightCarousel.isFirst;
        this.rightNavDisabled = !!this.promoInsightCarousel.isLast;
        this.isLastSlide = !!this.promoInsightCarousel.isLast;
    }

    public navigate(notification) {
        if (notification.messageType === NotificationType.PROMOTION) {
            if (this.navigateToPromotionAndInsightDetail) {
                this.codeNavigation(notification);
            } else {
                this.router.navigateByUrl('/promotions');
            }
        } else if (notification.messageType === NotificationType.INSIGHT) {
            if (this.navigateToPromotionAndInsightDetail) {
                if (notification?.code) {
                    this.router.navigateByUrl(`/trends/${notification?.code}`);
                } else {
                    this.router.navigateByUrl('/trends');
                }
            } else {
                this.router.navigateByUrl('/trends');
            }
        }
    }
    public codeNavigation(notification) {
        if (notification?.code) {
            this.router.navigateByUrl(`/promotions/${notification?.code}`);
        } else {
            this.router.navigateByUrl('/promotions');
        }
    }
}
