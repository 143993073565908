import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { SettingsStoreModel } from '@hot-libs/shared-models';

import { AppState } from '@hot-b2b/store/reducers';
import { settingsStore } from '@hot-b2b/store/settings/selector';

@Injectable({
    providedIn: 'root',
})
export class RemarksFormService {
    public remarksInvalid$: Observable<boolean>;

    private startRemarksFormState = true;
    private readonly remarksInvalidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(private readonly store: Store<AppState>) {
        this.remarksInvalid$ = this.remarksInvalidSubject.asObservable();
        this.store
            .pipe(
                select(settingsStore),
                filter((value: SettingsStoreModel) => !!value),
                take(1)
            )
            .subscribe((settings: SettingsStoreModel) => {
                this.startRemarksFormState = settings.purchaseOrderNumberRequired;
                this.remarksInvalidSubject.next(this.startRemarksFormState);
            });
    }

    public setRemarksFormState(invalid: boolean): void {
        this.remarksInvalidSubject.next(invalid);
    }

    public setStartRemarksFormState(): void {
        this.remarksInvalidSubject.next(this.startRemarksFormState);
    }
}
