import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { FeaturesService, ModalService } from '../../services';

import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { ModalIds } from '@hot-libs/shared-types';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';

@Component({
    selector: 'hot-back-button',
    templateUrl: './back-button.component.html',
})
export class BackButtonComponent implements OnInit {
    public PATHS = PATHS;
    @Input() public backLink: string;
    @Input() public fromHistory: boolean;
    @Input() public fromOrdersPayOnDelivery: boolean;
    @Input() public hideBreadcrumbs: boolean;
    @Input() public fromOrders: boolean;
    @Input() public fromDistributorOrders: boolean;
    @Input() public showButtonOnly: boolean;
    @Input() public fromOrdersTemplate: boolean;
    @Input() public fromGroupOrders: boolean;
    @Input() public customLabel: string;
    @Input() public disabled: boolean;
    @Input() public customAction: boolean;
    @Input() public fromThankYouOrderModal: boolean;
    @Output() public beforeAction: EventEmitter<void> = new EventEmitter();
    @Input() public additionalText: string;

    private readonly modalOrderState$: Observable<ModalsState>;
    private readonly distributorOrderDetailsState$: Observable<ModalsState>;
    private readonly groupOrderDetailsState$: Observable<ModalsState>;

    constructor(
        private readonly location: Location,
        private readonly router: Router,
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly featureService: FeaturesService
    ) {
        this.modalOrderState$ = this.store.pipe(select(selectModalState(ModalIds.order)));
        this.distributorOrderDetailsState$ = this.store.pipe(select(selectModalState(ModalIds.distributorOrder)));
        this.groupOrderDetailsState$ = this.store.pipe(select(selectModalState(ModalIds.groupOrder)));
    }

    public ngOnInit(): void {
        const urlItems: string[] = this.location.path().split('/');
        const isTlp: boolean = urlItems.indexOf('tlp') >= 0;
        const isTlpOnGoing: boolean = isTlp && urlItems.indexOf('on-going') >= 0;
        const isTlpHistory: boolean = isTlp && urlItems.indexOf('history') >= 0;
        const isPromo:boolean = urlItems.indexOf('promotion-tracking') >= 0;

        if (this.backLink == null || this.backLink === '/') {
            if (this.featureService.DashboardHomePage) {
                this.backLink = '/ordering';
            }
            else if (isTlpOnGoing || isTlpHistory) {
                this.backLink = '/performance/tlp';
            }
            else if (isTlp || isPromo) {
                this.backLink = '/performance';
            }
            else {
                this.backLink = '/';
            }
        }
    }

    public navigateByUrl(): void {
        if (this.fromOrders && !this.fromOrdersPayOnDelivery) {
            this.orderNavigate();
        } else if (this.fromDistributorOrders) {
            this.distributorNavigate();
        } else if (this.fromGroupOrders) {
            this.groupOrderNavigate()
        } else if (this.fromHistory) {
            if (this.beforeAction.observers.length > 0) {
                this.beforeAction.emit();
                this.location.back();
            } else {
                this.location.back();
            }
        } else if (this.fromOrdersTemplate) {
            this.beforeAction.emit();
        } else if (this.fromOrdersPayOnDelivery) {
            this.deliveryNavigate();
        } else if (this.fromThankYouOrderModal) {
            this.thankYouNavigate();
        } else if (this.customAction) {
            this.beforeAction.emit();
        } else {
            this.router.navigateByUrl(this.backLink, { replaceUrl: true });
        }
    }
    public orderNavigate() {
        this.modalOrderState$.pipe(take(1)).subscribe((modalState) => {
            if (modalState.isOpened) {
                this.modalService.toggleModal(ModalIds.order, false);
                this.location.replaceState('/orders');
            } else {
                this.router.navigateByUrl(this.backLink, { replaceUrl: true });
            }
        });
    }
    public distributorNavigate() {
        this.distributorOrderDetailsState$.pipe(take(1)).subscribe((modalState) => {
            this.modalService.toggleModal(ModalIds.distributorOrderConfirmReceivedCondition, false);
            this.modalService.modalAction$.next(null);
            if (modalState.isOpened) {
                this.modalService.toggleModal(ModalIds.distributorOrder, false);
            } else {
                this.router.navigateByUrl(this.backLink, { replaceUrl: true });
            }
        });
    }
    public groupOrderNavigate() {
        this.groupOrderDetailsState$.pipe(take(1)).subscribe((modalState) => {
            if (modalState.isOpened) {
                this.modalService.toggleModal(ModalIds.groupOrder, false);
            } else {
                this.router.navigateByUrl(this.backLink, { replaceUrl: true });
            }
        });
    }
    public deliveryNavigate() {
        this.modalOrderState$.pipe(take(1)).subscribe((modalState) => {
            if (modalState.isOpened) {
                this.modalService.toggleModal(ModalIds.order, false);
                this.location.replaceState('/pay-on-delivery-orders');
            } else {
                this.router.navigateByUrl(this.backLink, { replaceUrl: true });
            }
        });
    }
    public thankYouNavigate() {
        this.modalOrderState$.pipe(take(1)).subscribe((modalState) => {
            if (modalState.isOpened) {
                this.modalService.toggleModal(ModalIds.order, false);
            } else {
                this.router.navigateByUrl(this.backLink, { replaceUrl: true });
            }
        });
    }
}
