import { Component, EventEmitter, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ModalIds } from '@hot-libs/shared-types';
import { selectModalData } from '@hot-b2b/store/modals/selector';

import { ModalService } from 'apps/hot-b2b/src/app/shared/services';

@Component({
    selector: 'hot-order-has-unfinished-payments-modal',
    templateUrl: './order-has-unfinished-payments-modal.component.html',
})
export class OrderHasUnfinishedPaymentsModalComponent {
    @Output() public callback: EventEmitter<any> = new EventEmitter();

    private readonly modalData$: Observable<any>;

    constructor(private readonly store: Store, private readonly modalService: ModalService) {
        this.modalData$ = this.store.pipe(select(selectModalData(ModalIds.orderHasUnfinishedPayments)));
    }

    public proceedToOrderPayment(): void {
        this.modalData$.pipe(take(1)).subscribe((modalData: any) => {
            if (modalData) {
                this.callback.emit(modalData);
            }
        });

        this.closeModal();
    }

    public closeModal(): void {
        this.modalService.toggleModal(ModalIds.orderHasUnfinishedPayments, false);
    }
}
