import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { authFetched, authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsStoreSlots } from '@hot-b2b/store/settings/model';
import {
    settingsAppOnLine,
    settingsFeaturesLinks,
    settingsStore,
    settingsStoreOrigin,
} from '@hot-b2b/store/settings/selector';
import { ConfigurationService } from './configuration.service';
import { HotUserExtended, NavigationItemModel, SettingsStoreModel } from '@hot-libs/shared-models';
import { AccountType, StorageKeys } from '@hot-libs/shared-types';
import { LoyaltyService } from 'apps/hot-b2b/src/app/shared/services/loyalty.service';
import navLinksDistributor from 'apps/hot-b2b/src/assets/settings/nav-distributor.json';
import navLinksOpcoDistributor from 'apps/hot-b2b/src/assets/settings/nav-opco-distributor.json';
import navLinksVanSalesMan from 'apps/hot-b2b/src/assets/settings/nav-van-sales-man.json';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services/features.service';
import navLinksDistributorPerformance from 'apps/hot-b2b/src/assets/settings/nav-distributor-performance.json';
import navLinksCommon from 'apps/hot-b2b/src/assets/settings/nav.json';
import navLinksNZ from 'apps/hot-b2b/src/assets-nz/settings/nav.json';
import navLinksHOP from 'apps/hot-b2b/src/assets-hop/settings/nav.json';
import navLinksVN from 'apps/hot-b2b/src/assets-vn/settings/nav.json';
import navLinksIT from 'apps/hot-b2b/src/assets-it/settings/nav.json';
import { config } from 'apps/hot-b2b/src/environments/config';
const useCustomNav = config?.options?.useCustomNav || false;
const navLinks = useCustomNav ? navLinksNZ : navLinksCommon;
@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private readonly onlineOnlyTabs: string[] = ['performance', 'order-templates', 'contact-us'];

    private readonly subDistributorCustomerMode = 'Customer';
    private readonly subDistributorDistributorMode = 'Distributor';

    private readonly accountType = AccountType;

    private readonly authUser$: Observable<HotUserExtended>;
    private readonly isApplicationOnline$: Observable<boolean>;
    private readonly isLoyaltyEnabled$: Observable<boolean>;
    private readonly settingsFeaturesLinks$: Observable<string[]>;
    private readonly settingsStore$: Observable<SettingsStoreModel>;
    public isHOPB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public isVNB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';
    public isITB2b = () => this.configurationService.getCurrentConfiguration() === 'IT';
    public featureDistributorPerformancePage = this.featuresService.DistributorPerformancePage;

    constructor(
        private readonly loyaltyService: LoyaltyService,
        private readonly store: Store<AppState>,
        public readonly featuresService: FeaturesService,
        private readonly configurationService: ConfigurationService
    ) {
        this.authUser$ = this.store.pipe(select(authUser));
        this.isLoyaltyEnabled$ = this.loyaltyService.loyaltyEnable();
        this.isApplicationOnline$ = this.store.pipe(select(settingsAppOnLine));
        this.settingsFeaturesLinks$ = this.store.pipe(select(settingsFeaturesLinks));
        this.settingsStore$ = this.store.pipe(select(settingsStore));
    }

    public getNavigationItems(): Observable<NavigationItemModel[]> {
        const settingsStoreOrigin$ = this.store.pipe(select(settingsStoreOrigin));
        const authFetched$ = this.store.pipe(select(authFetched));

        return combineLatest([authFetched$, settingsStoreOrigin$]).pipe(
            filter(([isUserLoaded, settings]: [boolean, SettingsStoreSlots[]]) => isUserLoaded && !!settings.length),
            withLatestFrom(
                this.authUser$,
                this.settingsStore$,
                this.isLoyaltyEnabled$,
                this.isApplicationOnline$,
                this.settingsFeaturesLinks$
            ),
            map(
                ([, user, storeSettings, isLoyaltyEnable, isOnline, featuresLinks]: [
                    [boolean, SettingsStoreSlots[]],
                    HotUserExtended,
                    SettingsStoreModel,
                    boolean,
                    boolean,
                    string[]
                ]) => {
                    const userType = user.userType as AccountType;
                    const availableNavigationItems = this.getAvailableNavigationItemsByUserType(userType);

                    return availableNavigationItems?.filter((navItem: NavigationItemModel) => {
                        if (
                            this.isClosedByFeaturesLinks(navItem.link, featuresLinks) &&
                            this.isClosedByStoreSettings(navItem.link, storeSettings) &&
                            this.isClosedForUser(navItem.link, user) &&
                            this.isLoyaltyEnabled(navItem.link, isOnline, isLoyaltyEnable) &&
                            this.isOnlyOnlineLink(navItem.name, isOnline)
                        ) {
                            return navItem;
                        }
                    });
                }
            )
        );
    }

    public getAvailableNavigationItemsByUserType(accountType: AccountType): NavigationItemModel[] {
        let navigationList: NavigationItemModel[];

        switch (accountType) {
            case this.accountType.Distributor: {
                if (this.featureDistributorPerformancePage) {
                    navigationList = navLinksDistributorPerformance;
                } else {
                    navigationList = navLinksDistributor;
                }

                break;
            }
            case this.accountType.SubDistributor: {
                const subDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);

                if (subDistributorMode === this.subDistributorCustomerMode) {
                    navigationList = navLinks;
                } else if (
                    subDistributorMode === this.subDistributorDistributorMode &&
                    this.featureDistributorPerformancePage
                ) {
                    navigationList = navLinksDistributorPerformance;
                } else if (subDistributorMode === this.subDistributorDistributorMode) {
                    navigationList = navLinksDistributor;
                }

                break;
            }
            case this.accountType.OpcoDistributor: {
                const distributorNavItems: NavigationItemModel[] = navLinksOpcoDistributor;
                navigationList = distributorNavItems.filter((item: NavigationItemModel) => item.name !== 'my-catalog');
                break;
            }
            case this.accountType.VanSalesMan: {
                navigationList = navLinksVanSalesMan;
                break;
            }
            default:
                if (this.isHOPB2b()) {
                    return navLinksHOP;
                } else if (this.isVNB2b()) {
                    return navLinksVN;
                } else if(this.isITB2b()){
                    return navLinksIT;
                } else {
                    navigationList = navLinks;
                    break;
                }
        }

        return navigationList;
    }

    private isClosedByFeaturesLinks(link: string, featuresLinks: string[]): boolean {
        return !featuresLinks.includes(link);
    }

    private isClosedByStoreSettings(link: string, storeSettings: SettingsStoreModel): boolean {
        let isLinkAvailable = true;

        if (link === '/distributor/new-orders') {
            isLinkAvailable = storeSettings.showNewOrdersOnDistributorPortal;
        }
        if (link === '/order-suggestions') {
            isLinkAvailable = storeSettings.orderSuggestion;
        }

        return isLinkAvailable;
    }

    private isOnlyOnlineLink(name: string, isOnline: boolean): boolean {
        let result = true;

        if (this.onlineOnlyTabs.indexOf(name) > -1) {
            result = isOnline;
        }

        return result;
    }

    private isLoyaltyEnabled(link: string, isOnline: boolean, isLoyaltyEnable: boolean): boolean {
        let result = true;

        if (link === '/rewards') {
            result = isOnline && isLoyaltyEnable;
        }

        return result;
    }

    private isClosedForUser(link: string, user: HotUserExtended): boolean {
        let result = true;

        if (link === '/self-registration') {
            result = user.contact?.canRegisterOutlets;
        }

        if (link === '/group-account-orders' || link === '/order-summary') {
            result = !user.contact?.isParentContact && user.contact?.outlets && user.contact.outlets.length > 1;
        }

        return result;
    }
}
