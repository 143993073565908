/// <reference path="../../typings/window.d.ts" />
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FeatureNames } from '@hot-libs/shared-types';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AnalyticPageModel } from 'apps/hot-b2b/src/app/shared/models/analytic.model';
import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { FeaturesItem } from '@hot-b2b/store/settings/model';
import { settingsFeatures } from '@hot-b2b/store/settings/selector';
import { NativeService } from './../../native/services/native.service';
import { AnalyticLocationConstant, AuthenticationService, OptimizelyService } from '@hot-theme-nx/common-api';
import { FeaturesService } from './features.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticRouteService {
    private readonly scriptsLoadingTimeout = 4000;
    public applicationSource = {};

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly store: Store<AppState>,
        private readonly nativeService: NativeService,
        private readonly authenticationService: AuthenticationService,
        private readonly featuresService: FeaturesService,
        private readonly optimizelyService: OptimizelyService
    ) {
        this.applicationSource = this.getDataSource();
    }

    public loadAnalyticRouteEvent(): void {
        combineLatest([this.router.events, this.store.select(settingsFeatures), this.store.select(authUser)])
            .pipe(
                filter(([event, featureItems, _user]) => {
                    if (!(event instanceof NavigationEnd)) {
                        return false;
                    }

                    const analyticFeature = featureItems.find(
                        (feature: FeaturesItem) => feature.name === FeatureNames.GoogleAnalytics
                    );
                    return analyticFeature?.isActive;
                })
            )
            .subscribe(([, , user]) => {
                const analyticsData: AnalyticPageModel = this.getAnalyticsDataFromRoute();
                if (!analyticsData) {
                    return;
                }

                window.digitalData = {
                    page: analyticsData,
                    application: this.applicationSource,
                };

                if (user) {
                    window.digitalData.user = {
                        userId: user.id,
                        loginState: this.authenticationService.isAuthenticated() ? 1 : 0,
                        companyType: user.contact.outlet.name,
                        companyId: user.contact.outlet.outerId,
                        region: user.contact.outlet.addresses?.length
                            ? user.contact.outlet.addresses[0].regionName
                            : null,
                        ...this.digitalDataUserDetails(user),
                    };
                }

                try {
                    window.callBootstrapperTrigger('pageData');
                } catch {
                    setTimeout(() => window.callBootstrapperTrigger('pageData'), this.scriptsLoadingTimeout);
                }
                this.optimizelyService.initializeUserContext();
                this.checkOptimizelyTest('a_a_test', analyticsData.pageType);
            });
    }
    public checkOptimizelyTest(optimizelyFlag, pageType) {
        this.optimizelyService.readyOptimizely().then((isClient) => {
            if (isClient && pageType != 'login') {
                this.optimizelyService.isOptimizelyEnabled(optimizelyFlag);
            } else if (isClient && pageType === 'login') {
                setTimeout(() => {
                    this.optimizelyService.isOptimizelyEnabled(optimizelyFlag);
                }, 5000);
            }
        });
    }
    public digitalDataUserDetails(user) {
        return {
            town: user.contact.outlet.addresses?.length ? user.contact.outlet.addresses[0].city : null,
            outletClassification: user.contact.outlet.classification ? user.contact.outlet.classification : null,
            subChannel: user.contact.outlet.subChannel ? user.contact.outlet.subChannel : null,
            channel: user.contact.outlet.channel ? user.contact.outlet.channel : null,
            outletType: user.contact.outlet.outletType ? user.contact.outlet.outletType : null,
            customerType: user.contact.outlet.customerType ? user.contact.outlet.customerType : null,
            userType: user.userType ? user.userType : null,
        };
    }

    // to do: we have to do this method because activatedRoute is not current route
    // and we need to get last child which has analytics object in route data
    private getAnalyticsDataFromRoute(): AnalyticPageModel {
        let child: ActivatedRoute = this.route.firstChild || this.route;
        let parent: ActivatedRoute = this.route;
        let analyticsData = null;

        while (child) {
            if (child.firstChild) {
                if (child.snapshot.data.analytics && !child.firstChild.snapshot.data.analytics) {
                    parent = child;
                }

                child = child.firstChild;
                continue;
            }
            break;
        }

        if (this.hasAnalyticsData(child)) {
            analyticsData = child.snapshot.data.analytics;
        } else if (this.hasAnalyticsData(parent)) {
            analyticsData = parent.snapshot.data.analytics;
        }

        return analyticsData;
    }

    private hasAnalyticsData(activatedRoute: ActivatedRoute): AnalyticPageModel {
        return activatedRoute.snapshot.data?.analytics;
    }

    private getDataSource() {
        let source = this.nativeService.isNativeSource()
            ? AnalyticLocationConstant.DATA_SOURCE_APP
            : AnalyticLocationConstant.DATA_SOURCE_WEB;
        return { source: source };
    }

    public sendScrollEvent(event) {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        const analyticsData: AnalyticPageModel = this.getAnalyticsDataFromRoute();
        if (!analyticsData) {
            return;
        }
        window.digitalData = {
            page: analyticsData,
            application: this.applicationSource,
            events: {
                event: 'recommender_scroll',
                scroll_type: event,
            },
            event: 'recommender_scroll',
        };
        window.callBootstrapperTrigger('recommender_scroll');
    }

    public fastOrderModalEvents(eventName: string) {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        const analyticsData: AnalyticPageModel = this.getAnalyticsDataFromRoute();
        if (!analyticsData) {
            return;
        }
        window.digitalData = {
            page: analyticsData,
            application: this.applicationSource,
            events: {
                event: eventName,
            },
            event: eventName,
        };
        window.callBootstrapperTrigger(eventName);
        Object.keys(window.digitalData).forEach((key) => delete window.digitalData[key]);
    }
}
