import { Injectable } from '@angular/core';

import { CalendarLocale, StorageKeys } from '@hot-libs/shared-types';
 import { config } from 'apps/hot-b2b/src/environments/config';
export interface ICalendarLocale {
    firstDayOfWeek?: number;
    dayNames: string[];
    dayNamesShort: string[];
    dayNamesMin: string[];
    monthNames: string[];
    monthNamesShort: string[];
    today: string;
    clear: string;
    dateFormat?: string;
    weekHeader?: string;
}
@Injectable({
    providedIn: 'root',
})
export class CalendarHelperService {
    public isEgB2b = () => config.currentConfigurationName === 'EG';
    public isHopB2b = () => config.currentConfigurationName === 'HOP';
    public getLocale(): ICalendarLocale {
        const currentLanguageCode: string = localStorage.getItem(StorageKeys.currentLanguage);
        if(this.isEgB2b() && !this.isHopB2b()){
            return CalendarLocale.EG;
        }
        else if(!this.isEgB2b() || this.isHopB2b()){
            switch (currentLanguageCode) {
                case 'vi-VN':
                    return CalendarLocale.VN;
                case 'ms-MY':
                    return CalendarLocale.MY;
                case 'zh-CN':
                    return CalendarLocale.CN;          
                case 'el-GR':
                    return CalendarLocale.GR;
                case 'th-TH':
                    return CalendarLocale.TH;
                case 'ko-KR':
                    return CalendarLocale.KR;
                case 'fr-HT':
                    return CalendarLocale.HT;
                case 'cs-CZ':
                        return CalendarLocale.CZ;        
                default:
                    return CalendarLocale.EN;
            }
        }
    }
}
