export class FeatureNames {
    public static readonly AllowPreorder = 'AllowPreorder';
    public static readonly ApplyTierPriceModel = 'ApplyTierPriceModel';
    public static readonly BackInStockSubscription = 'BackInStockSubscription';
    public static readonly BackInStockSubscriptionWithQuantity = 'BackInStockSubscriptionWithQuantity';
    public static readonly BoostOrderCheckout = 'BoostOrderCheckout';
    public static readonly BrandsCarouselClickable = 'BrandsCarouselClickable';
    public static readonly BrandsFilter = 'BrandsFilter';
    public static readonly BrandsPage = 'BrandsPage';
    public static readonly CancelOrder = 'CancelOrder';
    public static readonly ChangeEmailAddress = 'ChangeEmailAddress';
    public static readonly ChangeEmailAddressVerificationEmail = 'ChangeEmailAddressVerificationEmail';
    public static readonly ChangePhoneNumber = 'ChangePhoneNumber';
    public static readonly ChangePhoneNumberVerificationSms = 'ChangePhoneNumberVerificationSms';
    public static readonly ConfirmDelivery = 'ConfirmDelivery';
    public static readonly ConfirmedOrdersPageForDistributor = 'ConfirmedOrdersPageForDistributor';
    public static readonly CookieWall = 'CookieWall';
    public static readonly Coupons = 'Coupons';
    public static readonly CreateInvoiceOnDistributorPortal = 'CreateInvoiceOnDistributorPortal';
    public static readonly CreateOfflineOrder = 'CreateOfflineOrder';
    public static readonly CustomerPermissions = 'CustomerPermissions';
    public static readonly DeliveredOrdersStatisticsForCustomer = 'DeliveredOrdersStatisticsForCustomer';
    public static readonly DeliveredOrdersStatisticsForDistributor = 'DeliveredOrdersStatisticsForDistributor';
    public static readonly EditOrder = 'EditOrder';
    public static readonly EMulaCheckoutScript = 'EMulaCheckoutScript';
    public static readonly ChatbotClientScript = 'EnableChatBot';
    public static readonly EstimatedDeliveryDate = 'EstimatedDeliveryDate';
    public static readonly ExternalCartValidation = 'ExternalCartValidation';
    public static readonly GoogleAnalytics = 'GoogleAnalytics';
    public static readonly Hotjar = 'Hotjar';
    public static readonly Insights = 'Insights';
    public static readonly LoyaltyProgram = 'LoyaltyProgram';
    public static readonly LoyaltyProgramBalance = 'LoyaltyProgramBalance';
    public static readonly LoyaltyProgramTiers = 'LoyaltyProgramTiers';
    public static readonly ManageOrdersOnDistributorPortalByDistributor =
        'ManageOrdersOnDistributorPortalByDistributor';
    public static readonly OpcoDistributorHandlesAnyBonusOrders = 'OpcoDistributorHandlesAnyBonusOrders';
    public static readonly AddReasonsForPartialDelivery = 'AddReasonsForPartialDelivery';
    public static readonly OrdersGridView = 'OrdersGridView';
    public static readonly OrderPaymentsProcessing = 'OrderPaymentsProcessing';
    public static readonly OrderTemplates = 'OrderTemplates';
    public static readonly OrderSuggestions = 'OrderSuggestions';
    public static readonly OrganizationIsRequiredForOutlet = 'OrganizationIsRequiredForOutlet';
    public static readonly OutletCreditLimit = 'OutletCreditLimit';
    public static readonly PerformancePage = 'PerformancePage';
    public static readonly PromotionSuggestions = 'PromotionSuggestions';
    public static readonly Pwa = 'PWA';
    public static readonly Reorder = 'Reorder';
    public static readonly RequireRewardTermsAndConditionsToBeAccepted = 'RequireRewardTermsAndConditionsToBeAccepted';
    public static readonly SelectDeliveryDate = 'SelectDeliveryDate';
    public static readonly EnableCustomerPickUp = 'EnableCustomerPickUp';
    public static readonly SelectUnitOfMeasure = 'SelectUnitOfMeasure';
    public static readonly ShipOrderByDistributor = 'ShipOrderByDistributor';
    public static readonly ShowAddress = 'ShowAddress';
    public static readonly ShowDistributorLineItemExtendedPrice = 'ShowDistributorLineItemExtendedPrice';
    public static readonly ShowDistributorLineItemSku = 'ShowDistributorLineItemSku';
    public static readonly ShowProductProperties = 'ShowProductProperties';
    public static readonly SmsResetPassword = 'SmsResetPassword';
    public static readonly EmailResetPassword = 'EmailResetPassword';
    public static readonly PasswordExpiration = 'PasswordExpiration';
    public static readonly StockControl = 'StockControl';
    public static readonly StoreIsRequiredForOutlet = 'StoreIsRequiredForOutlet';
    public static readonly SummarizeInvoices = 'SummarizeInvoices';
    public static readonly VisualWebsiteOptimizer = 'VisualWebsiteOptimizer';
    public static readonly SmsActivateAccount = 'SmsActivateAccount';
    public static readonly EmailActivateAccount = 'EmailActivateAccount';
    public static readonly UsePackagesWithCartsAndOrders = 'UsePackagesWithCartsAndOrders';
    public static readonly SplitAlcoholicAndNonAlcoholicProductsWhenCreatingOrder =
        'SplitAlcoholicAndNonAlcoholicProductsWhenCreatingOrder';
    public static readonly ReturnEmpties = 'ReturnEmpties';
    public static readonly EmptiesExclusiveOrder = 'EmptiesExclusiveOrder';
    public static readonly OrdersPageForGroupAccount = 'OrdersPageForGroupAccount';
    public static readonly OrdersStatisticsForGroupAccount = 'OrdersStatisticsForGroupAccount';
    public static readonly EnableOfflineOrder = 'EnableOfflineOrder';
    public static readonly EnableChatBot = 'EnableChatBot';
    public static readonly EnableCHQIntegration = 'EnableCHQIntegration';

    public static readonly DashboardHomePage = 'DashboardHomePage';
    public static readonly EnableGPSTracking = 'EnableGPSTracking';
    public static readonly EnableRating = 'EnableRating';
    public static readonly AppDownloadPopup = 'AppDownloadPopup';
    public static readonly EnableMultiplePaymentProvider = 'EnableMultiplePaymentProvider';
    public static readonly EnableLoginWithOTP = 'EnableLoginWithOTP';
    public static readonly DisplayofPerformancevsTarget = 'DisplayofPerformancevsTarget';
    public static readonly UseCategoriesAsPrimaryCatalogFilter = 'UseCategoriesAsPrimaryCatalogFilter';
    public static readonly ShowDotTutorialButton = 'ShowDotTutorialButton';
    public static readonly RestrictOrderPaymentsbyChannel = 'RestrictOrderPaymentsbyChannel';
    public static readonly ProductActivationFlow = 'ProductActivationFlow';
    public static readonly EnableViewAllCoupons = 'EnableViewAllCoupons';
    public static readonly DisplayDriverDetails = 'DisplayDriverDetails';
    public static readonly AutoAddEmpties = 'AutoAddEmpties';
    public static readonly ShowPricingIndicatorInCart = 'ShowPricingIndicatorInCart';
    public static readonly EmailAndUsernameToBeTheSameInCustomerSelfRegistration =
        'EmailAndUsernameToBeTheSameInCustomerSelfRegistration';
    public static readonly ReportOrderIssue = 'ReportOrderIssue';
    public static readonly RefreshCatalogsAfterCartChanges = 'RefreshCatalogsAfterCartChanges';
    public static readonly RefreshCartAfterExternalValidation = 'RefreshCartAfterExternalValidation';
    public static readonly ShowAgeConfirmation = 'ShowAgeConfirmation';
    public static readonly ShowReceiverPhoneNumberAtCheckOutPage = 'ShowReceiverPhoneNumberAtCheckOutPage';
    public static readonly AddReasonsByDistributorWhenChangeItems = 'AddReasonsByDistributorWhenChangeItems';
    public static readonly AddProductsWithNoPrice = 'AddProductsWithNoPrice';
    public static readonly DistributorPerformancePage = 'DistributorPerformancePage';
    public static readonly AllowDistributorsToControlStoreSettings = 'AllowDistributorsToControlStoreSettings';
    public static readonly DisplayCarouselBannerInDistributorPortal = 'DisplayCarouselBannerInDistributorPortal';
    public static readonly ManageFavoriteProducts = 'ManageFavoriteProducts';
    public static readonly AllowDistributorsToCancelOrdersAfterConfirmation =
        'AllowDistributorsToCancelOrdersAfterConfirmation';
    public static readonly AllowDispatchingMultipleOrders = 'AllowDispatchingMultipleOrders';
    public static readonly AllowConfirmingMultipleOrdersForNewOrders = 'AllowConfirmingMultipleOrdersAtOnce';
    public static readonly EnableAdvancedFilters = 'EnableAdvancedFilters';
    public static readonly BuildFulfillmentCentersUsingOutletStores = 'BuildFulfillmentCentersUsingOutletStores';
    public static readonly AllowPriceManagementOnDistributorPortal = 'AllowPriceManagementOnDistributorPortal';
    public static readonly EnableDiscountCalculationWhenPriceIsHidden = 'EnableDiscountCalculationWhenPriceIsHidden';
    public static readonly ShowSdipStatistics = 'ShowSdipStatistics';
    public static readonly ExtendedOrderDeliveryConfirmationFlow = 'ExtendedOrderDeliveryConfirmationFlow';
    public static readonly AddReasonsByCustomerWhenCancelOrder = 'AddReasonsByCustomerWhenCancelOrder';
    public static readonly SkipDistributorSelectionAfterSelectedDistributor =
        'SkipDistributorSelectionAfterSelectedDistributor';
    public static readonly OrganizationLevelCreditLimitCheck = 'OrganizationLevelCreditLimitCheck';
    public static readonly ShowTlpStatistics = 'ShowTlpStatistics';
    public static readonly ShowAndCalculateExpectedDeliveryTime = 'ShowAndCalculateExpectedDeliveryTime';
    public static readonly EnableOpenInvoice = 'EnableOpenInvoice';
    public static readonly ShowSalesRepPhoneNumber = 'ShowSalesRepPhoneNumber';
    public static readonly GotItIntegration = 'GotItIntegration';
    public static readonly EnableCrossSelling = 'EnableCrossSelling';
    public static readonly EnableProductRecommendation = 'EnableProductRecommendation';
    public static readonly ExternalPriceValidation = 'ExternalPriceValidation';
    public static readonly ShowPromotionAllocations = 'ShowPromotionAllocations';
    public static readonly ShowPromotionHistory = 'ShowPromotionHistory';
    public static readonly SplitOrdersBySuppliersWhenCheckout = 'SplitOrdersBySuppliersWhenCheckout';
    public static readonly ValidateMinimumBackOrderQuantity = 'ValidateMinimumBackOrderQuantity';
    public static readonly ValidateMinimumNonBackOrderQuantity = 'ValidateMinimumNonBackOrderQuantity';
    public static readonly ShowStockLevelOnLineItems = 'ShowStockLevelOnLineItems';
    public static readonly ValidateProductLimitOrderQuantity = 'ValidateProductLimitOrderQuantity';
    public static readonly DisplayUnitPrice = 'DisplayUnitPrice';
    public static readonly ShowPayNowAtReceivedStatus = 'ShowPayNowAtReceivedStatus';
    public static readonly EnableAdvancedPriceAndSortingFilter = 'EnableAdvancedPriceFilterAndSorting';
    public static readonly SplitOrdersByPromotionWhenCheckout = 'SplitOrdersByPromotionWhenCheckout';
    public static readonly ShowCreditValidationWhenCheckout = 'ShowCreditValidationWhenCheckout';
    public static readonly EnableBasketOverlay = 'EnableBasketOverlay';
    public static readonly EnableOffInvoicePerformanceTracking = 'EnableOffInvoicePerformanceTracking';
    public static readonly OrderSimulationPricesAndTaxes = 'OrderSimulationPricesAndTaxes';
    public static readonly EnableAdvanceCheckoutFlow = 'EnableAdvanceCheckoutFlow';
    public static readonly EnableProductRecommendationOnHomePage = 'EnableProductRecommendationOnHomePage';
    public static readonly DisplaySingleUnitPriceBasedOnPackageSize = 'DisplaySingleUnitPriceBasedOnPackageSize';
    public static readonly EnableHomePageActiveBanner = 'EnableHomePageActiveBanner';
    public static readonly EazleEnabledOpco = 'EazleEnabledOpco';
    public static readonly HighlightMostOrderedDistributors = 'HighlightMostOrderedDistributors';
}
