import { Component, Input } from '@angular/core';

@Component({
    selector: 'hot-divider',
    templateUrl: './divider.component.html',
})
export class DividerComponent {
    @Input() public caption: string;
    @Input() public modifier: string;
}
