import isArray from 'lodash/isArray';
import findIndex from 'lodash/findIndex';
import mergeWith from 'lodash/mergeWith';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import compact from 'lodash/compact';
import cloneDeep from 'lodash/cloneDeep';
import indexOf from 'lodash/indexOf';

import {
    HotBrandGroupExtended,
    HotCatalogExtended,
    HotProductExtended,
    HotUnitOfMeasureExtended,
    ProductFunctionalityModel,
} from 'apps/hot-b2b/src/app/catalog/models';
import { HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import {
    HotCategory,
    HotProductSearchCriteriaV2,
    HotProductSearchResult,
    HotPromotion,
    HotPromotionProduct,
    Term,
    HotFulfillmentCenter,
} from '@hot-theme-nx/generated-api';
import { camelCaseString } from '@hot-libs/helpers';
import { StorageKeys } from '@hot-libs/shared-types';
import { config } from 'apps/hot-b2b/src/environments/config';
const unitOfMeasureSortingConfig = config?.unitOfMeasureSorting || false;

const mutableProducts = (payload: { data: HotCatalogExtended; cartItems: HotCartLineItemExtended[] }) => {
    const products: HotProductExtended[] = payload.data.products;
    return allCartItems(products, payload);
};

const editQuantity = (
    catalogProducts: HotProductExtended[],
    cartProducts: HotCartLineItemExtended[],
    featureSplitOrdersBySuppliersWhenCheckout = false
): HotProductExtended[] => {
    const fulfillmentCenter = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter)) as HotFulfillmentCenter;

    return catalogProducts?.map((node: HotProductExtended) => {
        let temp: HotProductExtended = { ...node };
        temp.quantity = 0;
        temp.extendedPrice = 0;

        if (temp.unitsOfMeasure) {
            temp.unitsOfMeasure.forEach((unitOfMeasureItem: HotUnitOfMeasureExtended) => {
                unitOfMeasureItem.quantity = 0;
                unitOfMeasureItem.extendedPrice = unitOfMeasureItem.salePrice;
            });
        }

        cartProducts.forEach((item: HotCartLineItemExtended) => {
            if (temp.unitsOfMeasure) {
                temp = assignQuantities(item, temp);
            }
            else if (node.id === item.productId && !item.isGift) {
                temp = orderSplit(item, temp, featureSplitOrdersBySuppliersWhenCheckout, fulfillmentCenter);
            }
        });

        return temp;
    });
};
const orderSplit = (
    item: HotCartLineItemExtended,
    temp: HotProductExtended,
    featureSplitOrdersBySuppliersWhenCheckout,
    fulfillmentCenter
) => {
    if (featureSplitOrdersBySuppliersWhenCheckout) {
        if (item.storeId === fulfillmentCenter?.storeId) {
            temp.quantity = item.quantity;
            temp.extendedPrice = item.extendedPrice;
        }
    } else {
        temp.quantity = item.quantity;
        temp.extendedPrice = item.extendedPrice;
    }
    return temp;
};
const assignQuantities = (item: HotCartLineItemExtended, temp: HotProductExtended) => {
    temp.unitsOfMeasure.forEach((unitOfMeasure: HotUnitOfMeasureExtended) => {
        if (unitOfMeasure.id === item.productId && !item.isGift) {
            temp.packageTypeHop = item.packageTypeHop ? item.packageTypeHop : ' ';
            unitOfMeasure.quantity = item.quantity;
        }
    });
    return temp;
};

const mutableBrands = (products: HotProductExtended[]): HotBrandGroupExtended[] => {
    const result: HotBrandGroupExtended[] = [];

    const uniqConcat = (dest, src) => {
        if (isArray(dest)) {
            return uniq(dest.concat(src));
        }
    };
    
    
  const uniqValues = (array, i) => {
    const seen = new Set();
    return (array || []).filter(item => {
      const key = i(item);
      if (seen.has(key)) {
        return false;
      } else {
        seen.add(key);
        return true;
      }
    })
  }

    const productFilter: HotBrandGroupExtended[] = products
        ?.map((item: HotProductExtended) => ({
            name: item.brandGroup,
            type: [item.type],
            imageUrl: item.brandGroupImageUrl,
        }))
        ?.filter((item: any) => item.name);
    
        const temp: HotBrandGroupExtended[] = uniqValues(productFilter, value => [value.name, value.type].join()).valueOf();
        for (const item of temp) {
        const index: number = findIndex(result, (brand: HotBrandGroupExtended) => brand.name === item.name);
        if (index === -1) {
            result.push(item);
        } else {
            const updateBrand = mergeWith(item, result[index], uniqConcat);
            result.splice(index, 1);
            result.push(updateBrand);
        }
    }

    return result;
};

const mutableCatalogs = (action: { data: HotCatalogExtended[] }): HotCatalogExtended[] =>
    action.data.map((item: HotCatalogExtended) => ({ ...item, brandGroups: mutableBrands(item.products) }));

const addAggregationsAndBrandsGroups = (action: { data: HotCatalogExtended[] }): HotCatalogExtended[] =>
    action.data.map((item: HotCatalogExtended) => ({
        id: item.id,
        aggregations: item.aggregations,
        products: [],
        brandGroups: item.brandGroups,
        totalCount: item.totalCount,
        productBrandGroups: [],
        categories: [],
        packageTypes: item.packageTypes,
        priceRanges: item.priceRanges,
        brandOwners: item.brandOwners,
    }));

const currentCatalog = (catalogs: HotCatalogExtended[], ffcId: string): HotCatalogExtended => {
    if (!catalogs?.length) {
        return null;
    }

    const foundCatalog = ffcId?.length ? catalogs.find((catalog) => catalog.id === ffcId) : null;

    return foundCatalog ?? catalogs[0];
};

const mutableCurrentCatalog = (
    catalogs: HotCatalogExtended[],
    cartItems: HotCartLineItemExtended[],
    ffcID: string,
    featureSplitOrdersBySuppliersWhenCheckout = false
): HotCatalogExtended[] => {
    const currentIndex: number =
        catalogs.length > 0 && ffcID && ffcID.length > 0
            ? catalogs.map((item: HotCatalogExtended) => item.id).indexOf(ffcID)
            : 0;

    if (catalogs.length > 0 && currentIndex !== -1) {
        catalogs[currentIndex].products = editQuantity(
            catalogs[currentIndex].products,
            cartItems,
            featureSplitOrdersBySuppliersWhenCheckout
        );

        if (unitOfMeasureSortingConfig) {
            catalogs[currentIndex].products = productCatalog(catalogs, currentIndex);
        }
    }

    return catalogs;
};
const productCatalog = (catalogs: HotCatalogExtended[], currentIndex) => {
    return (catalogs[currentIndex].products.map((product) => {
        if (isArray(product?.unitsOfMeasure)) {
            product.unitsOfMeasure = product.unitsOfMeasure
                .map((unit: any) => ({
                    ...unit,
                    sortId: unitOfMeasureSortingConfig[unit.unitOfMeasure?.toLowerCase()],
                }))
                .sort((a, b) => (a.sortId - b.sortId > 0 ? 1 : -1));
        }
        return product;
    }));
};

const getWithFfc = (id: string, data: HotCatalogExtended[], key: string) => {
    if (data.length > 0) {
        if (id?.length === 0) {
            return data[0][key];
        } else {
            const filtered: HotCatalogExtended[] = data.filter((item: HotCatalogExtended) => item.id === id);
            return filtered.length > 0 ? filtered[0][key] : filtered;
        }
    }
    return [];
};

const getTotalCountWithFfc = (ffcId: string, catalogs: HotCatalogExtended[]) => {
    if (catalogs.length) {
        if (!ffcId?.length) {
            return catalogs[0].totalCount;
        }

        const catalogByFfc = catalogs.find((item: HotCatalogExtended) => item.id === ffcId);
        return catalogByFfc?.totalCount;
    }

    return 0;
};

const getWithFfcAndGifts = (
    cartItems: HotCartLineItemExtended[],
    promotion: HotPromotion,
    usePackagesWithCartsAndOrders: boolean,
    splitOrdersBySuppliersWhenCheckout: boolean,
) => {
    const fulfillmentCenter = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter)) as HotFulfillmentCenter;
    let filteredItems = null;

    filteredItems = promotion.products
        .map((product: any) => {
            const canSetStoreID = product?.storeIds && product.conditionProduct;
            const productCheck = product?.conditionProduct && !promotion.isSet;
            if (canSetStoreID) {
                product.conditionProduct.storeIds = product.storeIds;
            }
            if (productCheck) {
                productQuantityHelper(splitOrdersBySuppliersWhenCheckout,product,cartItems,usePackagesWithCartsAndOrders,fulfillmentCenter);
            }
            return product.conditionProduct;
        })
        .filter((item: any) => !!item);

    if (filteredItems.length) {
        const productGiftIds = giftItemQuantity(promotion, filteredItems);

        if (productGiftIds?.length > 0) {
            const gifts = z(cartItems, promotion, productGiftIds, usePackagesWithCartsAndOrders);
            filteredItems = [...filteredItems, ...gifts];
        }
    }

    return filteredItems;
};

const conditionProductQuantity = (product,cartItemQuantity,usePackagesWithCartsAndOrders) => {
    if(!usePackagesWithCartsAndOrders && product.conditionProduct.packageSize !== 0) {
        return cartItemQuantity / product.conditionProduct.packageSize;
    }
    return cartItemQuantity;
}

const productQuantityHelper = (splitOrdersBySuppliersWhenCheckout, product,cartItems,usePackagesWithCartsAndOrders, fulfillmentCenter) => {
    let cartItemQuantity = find(cartItems, { sku: product.sku })?.quantity;
    product.conditionProduct.quantity = conditionProductQuantity(product,cartItemQuantity,usePackagesWithCartsAndOrders);

    if (splitOrdersBySuppliersWhenCheckout) {
        cartItemQuantity = find(cartItems, { sku: product.sku, storeId: fulfillmentCenter?.storeId })
            ?.quantity;
            product.conditionProduct.quantity = cartItemQuantity ? conditionProductQuantity(product,cartItemQuantity,usePackagesWithCartsAndOrders) : 0;
    }
}

const giftItemQuantity = (promotion, filteredItems) => {
    let isExtraPromoCondition = false;
    return promotion.products.map((promoProduct: HotPromotionProduct) => {
        const promoProductsExistInCart = find(filteredItems, { sku: promoProduct.sku })?.quantity > 0;
        isExtraPromoCondition = !!(!promoProduct.sku && promoProduct.rewardProductId);

        if (promoProductsExistInCart || isExtraPromoCondition) {
            return promoProduct.rewardProductId;
        }
    });
};
const z = (cartItems, promotion, productGiftIds, usePackagesWithCartsAndOrders) => {
    return cloneDeep(cartItems).filter((item: HotCartLineItemExtended) => {
        if (!usePackagesWithCartsAndOrders && item.packageSize !== 0 && !promotion.isSet) {
            item.quantity = item.quantity / item.packageSize;
        }
        return indexOf(productGiftIds, item.productId) !== -1 && item.isGift;
    });
};

const updateCatalogItems = (
    catalogs: HotCatalogExtended[],
    ffcId: string,
    updateProduct: HotProductExtended
): HotCatalogExtended[] => {
    const catalog: HotCatalogExtended =
        ffcId && ffcId.length > 0 ? catalogs.find((x: HotCatalogExtended) => x.id === ffcId) : catalogs[0];

    if (!!catalog) {
        let productIndex = catalog.products.findIndex((p) => p.id === updateProduct.id);

        if (productIndex !== -1) {
            catalog.products[productIndex] = { ...updateProduct };
        }
    }

    return catalogs;
};

const getSetQuantity = (cartItems: HotCartLineItemExtended[], promotion: HotPromotion) => {
    let promoProductsSetQuantity = 0;
    let promoProductFromCart;
    let setIsNotAdded = false;
    let setQuantity = 0;

    promotion.products.forEach((product: HotPromotionProduct, i: number) => {
        if (product.conditionProduct) {
            promoProductFromCart = find(cartItems, { productId: product.productId });
            if (!setIsNotAdded && promoProductFromCart?.quantity >= product.quantity) {
                promoProductsSetQuantity =
                    promoProductsSetQuantity !== 0 &&
                    promoProductsSetQuantity < Math.trunc(promoProductFromCart.quantity / product.quantity)
                        ? promoProductsSetQuantity
                        : Math.trunc(promoProductFromCart.quantity / product.quantity);
            } else {
                setIsNotAdded = true;
                setQuantity = 0;
            }
        }

        if (i + 1 === promotion.products.length && !setIsNotAdded) {
            setQuantity = promoProductsSetQuantity;
        }
    });
    return setQuantity;
};

const getItemsQuantityToAddInSet = (
    cartItems: HotCartLineItemExtended[],
    promotion: HotPromotion,
    newSetQuantity: number
) => {
    let changedItems: ProductFunctionalityModel[] = [];
    let calculatedQuantity = 0;

    promotion.products.forEach((product: HotPromotionProduct) => {
        if (!product.rewardProduct) {
            let promoProductFromCartQuantity = 0;

            promoProductFromCartQuantity = find(cartItems, { productId: product.productId })?.quantity || 0;
            calculatedQuantity =
                promoProductFromCartQuantity >= product.quantity
                    ? promoProductFromCartQuantity - product.quantity * getSetQuantity(cartItems, promotion)
                    : promoProductFromCartQuantity;

            changedItems.push({
                productId: product.productId,
                quantity: product.quantity * newSetQuantity + calculatedQuantity,
            });
        }
    });

    return changedItems;
};

const getSetProducts = (cartItems: HotCartLineItemExtended[], promotion: HotPromotion) => {
    const maxSetQuantity =
        getSetQuantity(cartItems, promotion) < promotion.productSetLimit || !promotion.productSetLimit
            ? getSetQuantity(cartItems, promotion)
            : promotion.productSetLimit;

    const filteredProducts = promotion.products.map((product: any) => {
        if (product.rewardProduct && maxSetQuantity) {
            product.rewardProduct.quantity = maxSetQuantity * product.rewardProductQuantity;
            product.rewardProduct.isGift = true;
            return product.rewardProduct;
        } else if (product.conditionProduct) {
            product.conditionProduct.quantity = product.quantity;
            return product.conditionProduct;
        } else if (!product.rewardProduct && !product.conditionProduct) {
            return product;
        }
    });

    return compact(filteredProducts);
};
const allCartItems = (products, payload) => {
    return products.map((product: HotProductExtended) => {
        const temp: HotProductExtended = { ...product };

        // set quantity on product
        payload.cartItems.map((cartItem: HotCartLineItemExtended) => {
            if (product.id === cartItem.productId && !cartItem.isGift) {
                temp.quantity = cartItem.quantity;
            }
        });
        return temp;
    });
};

const editItemSubscription = (catalogs: HotCatalogExtended[], productId: string, ffcId: string) => {
    const currentIndex: number =
        catalogs.length > 0 && ffcId && ffcId.length > 0
            ? catalogs.map((item: HotCatalogExtended) => item.id).indexOf(ffcId)
            : 0;

    if (catalogs.length > 0 && currentIndex !== -1) {
        catalogs[currentIndex].products = editSubscription(catalogs[currentIndex].products, productId);
    }

    return catalogs;
};

const editSubscription = (catalogProducts: HotProductExtended[], productId: string): HotProductExtended[] => {
    return catalogProducts?.map((node: HotProductExtended) => {
        const temp: HotProductExtended = { ...node };

        if (temp.id.includes(productId)) {
            temp.isSubscribed = true;
        }

        return temp;
    });
};

const clearProductsInAllCatalogs = (catalogs: HotCatalogExtended[]) => {
    catalogs.forEach((catalog) => {
        catalog.productBrandGroups = [];
        catalog.products = [];
        catalog.totalCount = 0;
    });

    return catalogs;
};

const updateProducts = (
    catalogs: HotCatalogExtended[],
    payload: {
        data: HotProductSearchResult;
        ffcId: string;
        cartItems: HotCartLineItemExtended[];
    }
) => {
    let catalogByFfc = catalogs.find((catalog: HotCatalogExtended) =>
        fulfillmentCenterIdsAreEqual(catalog.id, payload.ffcId)
    );

    if (!catalogByFfc) {
        [catalogs, catalogByFfc] = catalogFullfillmentCenter(catalogs, catalogByFfc, payload);
    }

    const products = payload.data.products;
    const finalProducts: HotProductExtended[] = catalogByFfc.products.map((catalogProduct) => {
        const targetProduct = find(products, (prod) => prod.id === catalogProduct.id) as HotProductExtended;

        if (targetProduct) {
            if (targetProduct?.unitsOfMeasure && catalogProduct?.unitsOfMeasure) {
                targetProduct.unitsOfMeasure = catalogProduct.unitsOfMeasure.map((unit) => {
                    return {
                        ...unit,
                        ...find(
                            targetProduct.unitsOfMeasure,
                            (targetUnit) => targetUnit.unitOfMeasure === unit.unitOfMeasure
                        ),
                    };
                });
                targetProduct.unitCurrent = find(
                    targetProduct.unitsOfMeasure,
                    (unit) => unit.packageType === catalogProduct?.unitCurrent.packageType
                );

                return { ...catalogProduct, ...targetProduct };
            } else {
                const tempProduct = { ...catalogProduct, ...targetProduct, quantity: 0 };

                // set quantity on product
                payload.cartItems.forEach((cartItem: HotCartLineItemExtended) => {
                    if (tempProduct.id === cartItem.productId && !cartItem.isGift) {
                        tempProduct.quantity = cartItem.quantity;
                    }
                });

                return tempProduct;
            }
        }

        return catalogProduct;
    });

    catalogByFfc.products = finalProducts;
    catalogByFfc.totalCount = payload.data.totalCount;

    return catalogs;
};
const catalogFullfillmentCenter = (catalogs, catalogByFfc, payload) => {
    if (catalogs?.length === 1) {
        catalogByFfc = catalogs[0];
    } else {
        catalogByFfc = {
            id: payload.ffcId,
            totalCount: payload.data.totalCount,
            products: [],
            brandGroups: [],
            productBrandGroups: [],
            aggregations: [],
            categories: [],
            packageTypes: [],
            priceRanges: [],
            brandOwners: [],
        };

        catalogs.push(catalogByFfc);
    }
    return [catalogs, catalogByFfc];
};
const concatProducts = (
    catalogs: HotCatalogExtended[],
    payload: {
        data: HotProductSearchResult;
        ffcId: string;
        cartItems: HotCartLineItemExtended[];
    }
) => {
    if (!catalogs.length) {
        return [];
    }

    let catalogByFfc = catalogs.find((catalog: HotCatalogExtended) => catalog.id === payload.ffcId);

    if (!catalogByFfc) {
        catalogByFfc = catalogs[0];
    }
    const products = payload.data.products;

    const productsWithQuantity = allCartItems(products, payload);

    catalogByFfc.productBrandGroups =
        payload.data.brandGroups?.map((brandGroup) => ({ ...brandGroup, type: [] })) ?? [];
    catalogByFfc.products = catalogByFfc.products.concat(productsWithQuantity);
    catalogByFfc.totalCount = payload.data.totalCount;
    catalogByFfc.packageTypes = payload.data.packageTypes;
    catalogByFfc.priceRanges = payload.data.priceRanges;
    catalogByFfc.brandOwners = payload.data.brandOwners;
    catalogByFfc.aggregations = catalogByFfc.aggregations = payload.data.aggregations;
    return catalogs;
};

const addOfflineProducts = (
    catalogs: HotCatalogExtended[],
    offlineCatalogs: HotCatalogExtended[],
    payload: {
        // maybe create our interface
        searchCriteria: HotProductSearchCriteriaV2;
        ffcId: string;
        cartItems: HotCartLineItemExtended[];
        loadOfflineDataForOnline: boolean;
    }
) => {
    if (!catalogs.length || !offlineCatalogs.length) {
        return [];
    }
    let catalogByFfc = catalogs.find((catalog: HotCatalogExtended) => catalog.id === payload.ffcId);
    let offlineCatalogByFfc = offlineCatalogs.find((catalog: HotCatalogExtended) => catalog.id === payload.ffcId);

    if (!catalogByFfc) {
        catalogByFfc = catalogs[0];
    }
    let offlineCatalogLength = offlineCatalogs[0]?.totalCount;

    if (!offlineCatalogByFfc) {
        offlineCatalogByFfc = offlineCatalogs[0];
    }

    let products = [...offlineCatalogByFfc.products];

    // filter and paginate offline catalog
    const criteria = payload.searchCriteria;
    if (criteria) {
        // filter by brand
        if (criteria.brandGroup) {
            products = products.filter((item: HotProductExtended) => item.brandGroup === criteria.brandGroup);
        }

        // filter by terms
        if (criteria.terms?.length) {
            const groupedTerms = groupBy(criteria.terms, 'name');

            products = products.filter((item: HotProductExtended) => {
                let isEqualProduct = true;

                Object.values(groupedTerms).forEach((terms: Term[]) => {
                    const propertyName = camelCaseString(terms[0].name);

                    isEqualProduct = terms.some((term: Term) => term.value === item[propertyName]);
                });

                return isEqualProduct;
            });
        }

        // filter by value
        products = productFilter(criteria, products);

        // set total count before skip take
        if (!payload.loadOfflineDataForOnline) {
            catalogByFfc.totalCount = products.length;
        } else {
            catalogByFfc.totalCount = offlineCatalogLength;
        }
        // fake pagination
        let skip = Math.max(1, criteria.pageNumber - 1) * criteria.pageSize;
        if (criteria.pageNumber < 2) {
            skip = 0;
        }

        products = products.splice(skip, criteria.pageSize);
    }

    const productsWithQuantity = allCartItems(products, payload);

    catalogByFfc.products = catalogByFfc.products.concat(productsWithQuantity);

    return catalogs;
};
const productFilter = (criteria, products) => {
    if (criteria.keyword?.length) {
        products = products.filter((item: HotProductExtended) => {
            const nameSkuTypeString = `${item.name}, ${item.unitName || null}, ${
                item.packageName || null
            }`.toLowerCase();

            return nameSkuTypeString.includes(criteria.keyword.toLowerCase());
        });
    }
    return products;
};

const updateCatalogCategories = (
    catalogs: HotCatalogExtended[],
    payload: {
        ffcId: string;
        categories: HotCategory[];
    }
) => {
    if (!catalogs.length) {
        return [];
    }

    let catalogByFfc = catalogs.find((catalog: HotCatalogExtended) => catalog.id === payload.ffcId);

    if (!catalogByFfc) {
        catalogByFfc = catalogs[0];
    }

    catalogByFfc.categories = payload.categories;

    return catalogs;
};

const fulfillmentCenterIdsAreEqual = (first: string, second: string) => (!first && !second) || first === second;

export {
    addAggregationsAndBrandsGroups,
    mutableProducts,
    editQuantity,
    mutableBrands,
    mutableCatalogs,
    mutableCurrentCatalog,
    getWithFfc,
    getWithFfcAndGifts,
    getSetQuantity,
    getItemsQuantityToAddInSet,
    getSetProducts,
    editItemSubscription,
    clearProductsInAllCatalogs,
    concatProducts,
    updateProducts,
    getTotalCountWithFfc,
    addOfflineProducts,
    updateCatalogItems,
    updateCatalogCategories,
    currentCatalog,
};
