import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import { HotApiCmsService, HotHomePageWidgetsWithLanguage } from '@hot-theme-nx/generated-api';

import { CartState } from '../cart/model';
import { AppState } from '../reducers';

import {
    PopularProductsSynchronized,
    PopularProductsSynchronizedSuccess,
    WidgetsGet,
    WidgetsGetSuccess,
} from './actions';
import { EWidgetsActions } from './types';

@Injectable()
export class WidgetsEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly apiCmsService: HotApiCmsService,
        private readonly _store: Store<AppState>
    ) {}

    public WidgetsGet$: Observable<WidgetsGetSuccess> = createEffect(() => this._actions$.pipe(
        ofType<WidgetsGet>(EWidgetsActions.WIDGETS_PENDING),
        switchMap(() =>
            this.apiCmsService
                .getHomePageWidgets()
                .pipe(map((response: HotHomePageWidgetsWithLanguage[]) => new WidgetsGetSuccess(response)))
        )
    ));

    public PopularProductsSynchronized$: Observable<PopularProductsSynchronizedSuccess> = createEffect(() => this._actions$.pipe(
        ofType<PopularProductsSynchronized>(EWidgetsActions.POPULAR_PRODUCTS_SYNCHRONIZED),
        withLatestFrom(this._store.pipe(select((state: AppState) => state.cart))),
        map(
            (action: [PopularProductsSynchronized, CartState]) =>
                new PopularProductsSynchronizedSuccess(
                    action[0].payload ? action[0].payload.items : action[1].data.cart.items
                )
        )
    ));
}
