import { Component, Input } from '@angular/core';

import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';

@Component({
    selector: 'hot-distributor-catalog-changes-saved-modal',
    templateUrl: './distributor-catalog-changes-saved-modal.component.html',
})
export class DistributorCatalogChangesSavedModalComponent {
    @Input() public title: string;
    @Input() public content: string;

    constructor(private readonly modalService: ModalService) {}

    public close(): void {
        this.modalService.toggleModal(ModalIds.distributorCatalogChangesSaved, false);
    }
}
