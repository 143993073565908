import { NotificationMessage } from '@hot-theme-nx/generated-api';
import without from 'lodash/without';

export const sortNotifications = (notifications: NotificationMessage[]): NotificationMessage[] => {
    const unreadNotifications: NotificationMessage[] = notifications.filter(
        (notification: NotificationMessage) => !notification.isRead
    );
    const withoutUnread: NotificationMessage[] = without(notifications, ...unreadNotifications);
    const modifiedArray: NotificationMessage[] = unreadNotifications.concat(withoutUnread);
    return modifiedArray;
};
