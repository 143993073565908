import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IBearerToken } from '@hot-libs/shared-models';
import { StorageKeys } from '@hot-libs/shared-types';
import { AuthenticationClient } from 'apps/hot-b2b/src/app/account/services/authentication.client';
import { ArbitraryNotificationService } from 'apps/hot-b2b/src/app/shared/services/arbitrary-notification.service';
import { LegalAgreementService } from 'apps/hot-b2b/src/app/shared/services/legal-agreement.service';
import { AuthLogout } from '@hot-b2b/store/auth/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsUserLanguagesClear } from '@hot-b2b/store/settings/actions';
import { MsalService } from '@azure/msal-angular';
const loginPagePath = '/account/sign-in';
import { CookiesService } from '../../shared/services/cookies.service';
import { HotCookies } from '../../shared/services/constants.service';
import { ItemLocationService } from 'apps/hot-b2b/src/app/shared/services/Item-location.service';
import { PasswordTimerService } from '../../shared/services/password-timer.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(
        private readonly authenticationClient: AuthenticationClient,
        private readonly router: Router,
        private readonly store: Store<AppState>,
        private readonly legalAgreementService: LegalAgreementService,
        private readonly arbitraryNotificationService: ArbitraryNotificationService,
        private readonly msalService: MsalService,
        private readonly cookiesService: CookiesService,
        private readonly itemlocationService: ItemLocationService,
        private readonly passwordTimerService: PasswordTimerService
    ) {}

    public logout(rememberUser: boolean = false): void {
        if (!rememberUser) {
            localStorage.removeItem(StorageKeys.rememberMe);
        }

        this.legalAgreementService.unsubscribeFromTermsAndConditions();
        this.arbitraryNotificationService.unsubscribeFromArbitraryNotifications();
        localStorage.removeItem('subdistributor_mode');
        localStorage.removeItem(StorageKeys.authToken);
        localStorage.removeItem(StorageKeys.msalToken);
        localStorage.removeItem(StorageKeys.userType);
        localStorage.removeItem(StorageKeys.fulfillmentCenter);
        localStorage.removeItem(StorageKeys.currentOutletSemId);
        localStorage.removeItem(StorageKeys.suggestedInsights);
        localStorage.removeItem(StorageKeys.refreshToken);
        localStorage.removeItem(StorageKeys.bearerToken);
        localStorage.removeItem(StorageKeys.bearerTokenExpirationDate);
        localStorage.removeItem(StorageKeys.catalogView);
        localStorage.removeItem(StorageKeys.fcmToken);
        localStorage.removeItem("resendButton");
        localStorage.removeItem("nextPossibleGenerationAttempt");
        localStorage.removeItem("OTPGenerationAttemptLeft");
        this.passwordTimerService.stoptheTimer();
        localStorage.removeItem(StorageKeys.userId);
        this.cookiesService.deleteCookie(HotCookies.CURRENT_USER_TYPE);
        this.store.dispatch(new SettingsUserLanguagesClear());
        this.store.dispatch(new AuthLogout());
        this.router.navigate([loginPagePath]);
        if (
            localStorage.getItem(StorageKeys.msalAuthEnabled) === 'true' ||
            localStorage.getItem(StorageKeys.msalAuthEnabledForB2C) === 'true'
        ) {
            this.msalService.initialize().subscribe( () => {
                this.msalService.logoutRedirect();
            });

        }
    }

    public logoutWithReturnUrl(route: string): void {
        const rememberMeState: string = localStorage.getItem(StorageKeys.rememberMe);
        let rememberMe: string;
        if (rememberMeState !== null) {
            rememberMe = rememberMeState;
        }
        this.store.dispatch(new AuthLogout());
        this.store.dispatch(new SettingsUserLanguagesClear());
        this.router.navigate([loginPagePath], { queryParams: { returnUrl: route, rememberMe } });
        localStorage.removeItem(StorageKeys.rememberMe);
        localStorage.removeItem(StorageKeys.authToken);
        localStorage.removeItem(StorageKeys.fcmToken);
        localStorage.removeItem(StorageKeys.userId);
        localStorage.removeItem(StorageKeys.refreshToken);
        localStorage.removeItem(StorageKeys.authTokenExpire);
        localStorage.removeItem(StorageKeys.subDistributorMode);
        localStorage.removeItem(StorageKeys.fulfillmentCenter);
    }

    public logoutWithoutRedirection(): void {
        this.legalAgreementService.unsubscribeFromTermsAndConditions();
        this.arbitraryNotificationService.unsubscribeFromArbitraryNotifications();
        localStorage.removeItem('subdistributor_mode');
        localStorage.removeItem(StorageKeys.userType);
        localStorage.removeItem(StorageKeys.fulfillmentCenter);
        localStorage.removeItem(StorageKeys.currentOutletSemId);
        localStorage.removeItem(StorageKeys.suggestedInsights);
        localStorage.removeItem(StorageKeys.refreshToken);
        localStorage.removeItem(StorageKeys.bearerToken);
        localStorage.removeItem(StorageKeys.bearerTokenExpirationDate);
        localStorage.removeItem(StorageKeys.catalogView);
        localStorage.removeItem(StorageKeys.fcmToken);
        localStorage.removeItem(StorageKeys.userId);
        this.cookiesService.deleteCookie(HotCookies.CURRENT_USER_TYPE);
        this.store.dispatch(new SettingsUserLanguagesClear());
        this.store.dispatch(new AuthLogout());
    }

    public getToken(
        userName: string,
        password: string,
        impersonateUserId: string = null,
        msalToken: string = null
    ): Observable<IBearerToken> {
        return this.authenticationClient.getToken(userName, password, impersonateUserId, msalToken).pipe(
            map((response: IBearerToken) => {
                if (response.access_token) {
                    localStorage.setItem(StorageKeys.authToken, response.access_token);
                    localStorage.setItem(StorageKeys.refreshToken, response.refresh_token);
                    this.setAuthTokenExpirationDate(response.expires_in);
                    const tokenValue: string = JSON.stringify(response);
                    localStorage.setItem(StorageKeys.bearerToken, tokenValue);
                }
                return response;
            }),
            catchError((response: any) => {
                if (
                    (response.status === 400 &&
                        (response.error.error_description !== 'User name is empty.' ||
                            response.error.error_description !== 'The username/password couple is invalid.')) ||
                    response.status === 401
                ) {
                    this.errorMethodCall();
                } else if (response.status === 403) {
                    localStorage.removeItem(StorageKeys.bearerToken);
                    this.errorMethodCall();
                }
                return throwError(response);
            })
        );
    }

    private errorMethodCall() {
        localStorage.removeItem(StorageKeys.authToken);
        localStorage.removeItem(StorageKeys.fcmToken);
        localStorage.removeItem(StorageKeys.userId);
        localStorage.removeItem(StorageKeys.refreshToken);
        this.router.navigateByUrl(loginPagePath);
    }

    private setAuthTokenExpirationDate(seconds: number): void {
        const currentDate: Date = new Date();
        const expirationDate: Date = currentDate;
        expirationDate.setSeconds(currentDate.getSeconds() + seconds);
        localStorage.setItem(StorageKeys.authTokenExpire, expirationDate.toString());
    }
}
