import { Pipe, PipeTransform } from '@angular/core';
import { HotFulfillmentCenter } from '@hot-theme-nx/generated-api';
import filter from 'lodash/filter';

@Pipe({
    name: 'searchFfc',
})
export class SearchFfcPipe implements PipeTransform {
    public transform(ffcArray: HotFulfillmentCenter[], searchValue: string): HotFulfillmentCenter[] {
        if (!searchValue || searchValue.length < 3) {
            return ffcArray;
        }

        return filter(ffcArray, (item: HotFulfillmentCenter) => {
            const nameFfcIdString = `${item.name}::${item.storeId || null}`.toLowerCase();
            return nameFfcIdString.includes(searchValue.toLowerCase());
        });
    }
}
