import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { HotApiCartsService } from '@hot-theme-nx/generated-api';

import { HotProductExtended, ProductAttributesExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { HotCartExtended } from 'apps/hot-b2b/src/app/shared/models';
import { CartState } from '@hot-b2b/store/cart/model';
import {
    ProductsChangeQuantity,
    ProductsGet,
    ProductsGetSuccess,
    ProductsSynchronized,
    ProductsSynchronizedSuccess,
    ProductsSynchronizedSuccessWithStoreId,
} from '@hot-b2b/store/products/actions';
import { FeatureNames } from '@hot-libs/shared-types';
import { EProductsActions } from '@hot-b2b/store/products/types';
import { AppState } from '@hot-b2b/store/reducers';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';

@Injectable()
export class ProductsEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly _store: Store<AppState>,
        private readonly hotApiCartsService: HotApiCartsService,
        private readonly featuresService: FeaturesService
    ) {}

    getProducts$: Observable<ProductsGetSuccess | ProductsSynchronized> = createEffect(() => this._actions$.pipe(
        ofType<ProductsGet>(EProductsActions.PRODUCTS_PENDING),
        withLatestFrom(this._store.pipe(select((state: AppState) => state.cart))),
        switchMap((data: [ProductsGet, CartState]) => {
            const observableProduct: Observable<ProductAttributesExtended | HotProductExtended[]> = data[0].payload;
            const cart: HotCartExtended = data[1].data.cart;
            return observableProduct.pipe(
                switchMap((response: ProductAttributesExtended) => [
                    new ProductsGetSuccess({
                        data: response,
                        cart: cart.items,
                    }),
                    new ProductsSynchronized(cart),
                ])
            );
        })
    ));

    synchronizedProducts$: Observable<
        ProductsSynchronizedSuccess | ProductsSynchronizedSuccessWithStoreId
    > = createEffect(() => this._actions$.pipe(
        ofType<ProductsSynchronized>(EProductsActions.PRODUCTS_SYNCHRONIZED),
        withLatestFrom(
            this._store.pipe(select((state: AppState) => state.cart)),
            this.featuresService.checkFeatures(FeatureNames.SplitOrdersBySuppliersWhenCheckout)
        ),
        map((action: [ProductsSynchronized, CartState, boolean]) => {
            if (action[2]) {
                return action[0].payload
                    ? new ProductsSynchronizedSuccessWithStoreId(action[0].payload.items)
                    : new ProductsSynchronizedSuccessWithStoreId(action[1].data.cart.items);
            }

            return action[0].payload
                ? new ProductsSynchronizedSuccess(action[0].payload.items)
                : new ProductsSynchronizedSuccess(action[1].data.cart.items);
        })
    ));

    productsChangeQuantity$: Observable<ProductsSynchronized> = createEffect(() => this._actions$.pipe(
        ofType<ProductsChangeQuantity>(EProductsActions.PRODUCTS_ITEM_CHANGE_QUANTITY),
        mergeMap((action: ProductsChangeQuantity) =>
            this.hotApiCartsService
                .updateLineItem(action.payload.productId)
                .pipe(map((response: HotCartExtended) => new ProductsSynchronized(response)))
        )
    ));
}
