import { Component, Input} from '@angular/core';

import { ModalService } from '../../../services';

import { ModalIds } from '@hot-libs/shared-types';

@Component({
    selector: 'hot-theme-update-price-modal',
    templateUrl: './update-price-modal.component.html',
})
export class UpdatePriceModalComponent {
    @Input() public title: string;
    @Input() public content: string;

    constructor(private readonly modalService: ModalService) {}

    public close(): void {
        this.modalService.toggleModal(ModalIds.updatePriceModal, false);
    }
}
