import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash/filter';
import difference from 'lodash/difference';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import isNumber from 'lodash/isNumber';
import groupBy from 'lodash/groupBy';
import indexOf from 'lodash/indexOf';

import { Aggregation } from '@hot-theme-nx/generated-api';
import { camelCaseString, FilterModel } from '@hot-libs/helpers';

import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';

@Pipe({
    name: 'filterProducts',
})
export class FilterProductsPipe implements PipeTransform {
    public transform(
        data: HotProductExtended[],
        filters: FilterModel[],
        aggregations: Aggregation[]
    ): HotProductExtended[] {
        if (filters === undefined || filters.length === 0) {
            return data;
        }
        let filterResult: HotProductExtended[] = data;

        const aggregation = difference(
            aggregations.map((aggr: Aggregation) => aggr.field),
            ['Brand group']
        );
        const groupedFilters = groupBy(filters, 'groupLabel');

        forEach(aggregation, (agr: string) => {
            const group = groupedFilters[agr];
            const propName = camelCaseString(agr);
            if (group && group.length > 0) {
                const values = map(group, 'value');
                filterResult = filter(filterResult, (item: HotProductExtended) => {
                    let safeValue = item[propName];
                    if (isNumber(safeValue)) {
                        safeValue = safeValue.toString();
                    }
                    return indexOf(values, safeValue) !== -1;
                });
            }
        });

        return filterResult;
    }
}
