import { InsightsState } from '@hot-b2b/store/insights/model';

export const insightsInitialState: InsightsState = {
    pending: false,
    fetched: false,
    data: {
        slots: [],
        products: [],
    },
    error: null,
};
