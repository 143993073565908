import { MemoizedSelector, createSelector } from '@ngrx/store';

import { stateSettings } from '../settings/selector';

import { getPopularProductsWidget, getWidgetsForCurrentLanguage } from './helpers';
import { IWidgetsState } from './model';

import { AppState } from '@hot-b2b/store/reducers';
import { SettingsState } from '@hot-b2b/store/settings/model';

export const stateWidgets = (state: AppState) => state.widgets;

export const widgetsData = createSelector(
    stateWidgets,
    stateSettings,
    (state: IWidgetsState, settingsState: SettingsState) => {
        if (state && settingsState && state.data && settingsState.data) {
            return getWidgetsForCurrentLanguage(state?.data, settingsState?.data.currentLanguage?.code);
        } else {
            return null;
        }
    }
);

export const popularProducts = createSelector(
    stateWidgets,
    stateSettings,
    (state: IWidgetsState, settingsState: SettingsState) => {
        if (state && settingsState && state.data && settingsState.data) {
            return getPopularProductsWidget(state?.data).products;
        } else {
            return null;
        }
    }
);

export const widgetsFetched: MemoizedSelector<AppState, boolean> = createSelector(
    stateWidgets,
    (state: IWidgetsState) => state.fetched
);
