import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ModalIds, PageName } from '@hot-libs/shared-types';

import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { DateFormatParserService } from 'apps/hot-b2b/src/app/shared/services/date-format-parser.service';
import { selectModalData } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData } from '@hot-b2b/store/settings/selector';

import { HotDelivery } from 'libs/generated-api/src/lib/models/hot-delivery';
import { HotDeliveryItem } from 'libs/generated-api/src/lib/models/hot-delivery-item';

@Component({
    selector: 'hot-delivery-details-modal',
    templateUrl: './delivery-details-modal.component.html',
})
export class DeliveryDetailsModalComponent implements OnInit, OnDestroy {
    @Output() public exportDeliveryDetails = new EventEmitter<HotDelivery>();
    @Output() public containerNumberClick = new EventEmitter<HotDelivery>();
    public delivery: HotDelivery;
    public deliveryProducts: HotProductExtended[] = [];

    public dateFormat: string;
    public locale: string;

    public pageName = PageName.DELIVERYDETAILSMODAL;

    private readonly subscriptionsDestroy$ = new Subject<boolean>();

    constructor(
        private readonly store: Store<AppState>,
        private readonly dateFormatParserService: DateFormatParserService
    ) {}

    public ngOnInit(): void {
        this.store
            .pipe(
                select(selectModalData(ModalIds.deliveryDetails)),
                filter((delivery: any) => !!delivery),
                takeUntil(this.subscriptionsDestroy$)
            )
            .subscribe((delivery: HotDelivery) => {
                this.delivery = delivery;
                this.deliveryProducts = delivery.items.map((item: HotDeliveryItem) => {
                    // tslint:disable-next-line: no-object-literal-type-assertion
                    return {
                        ...item.product,

                        quantity: item.quantity,
                        deliveredQuantity: item.quantity,
                        stockStatus: null,
                    } as HotProductExtended;
                });
            });

        this.store
            .select(settingsData)
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe((settings: HotSettingsExtended) => {
                this.dateFormat = this.dateFormatParserService.parseDateFormatForDatePipe(settings.dateFormat);

                if (settings.currentLanguage != null) {
                    this.locale = settings.currentLanguage.code;
                }
            });
    }

    public openContainerUrl(event: Event, delivery: HotDelivery): void {
        debugger;
        if (delivery.containerUrl) {
            event?.stopPropagation();
            window.open(delivery.containerUrl, '_blank');
        }
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }
}
