import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'hot-payment-failed-modal',
    templateUrl: './payment-failed-modal.component.html',
})
export class PaymentFailedModalComponent {
    public orderNumber: string;

    constructor(
        private readonly modalService: ModalService,
        private readonly router: Router,
        private readonly analyticService: AnalyticService,
        private readonly translateService:TranslateService
    ) {}
    public createOrder(): void {
       this.analyticService.ifSelectedAnotherPaymentEventInfo();
        this.router.navigate(['/checkout']);
        this.close();
    }
    public ngOnInit(): void {
        let paymentError =   this.translateService.instant('shared.modals.payment-failed-pop.content-1') +
        this.translateService.instant('shared.modals.payment-failed-pop.content-2');  
       this.analyticService.sendPOPPaymentErrorEventInfo(paymentError);  
    }
    public close(): void {
        this.modalService.toggleModal(ModalIds.paymentFailed, false);
    }
}
