import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { authContactOutlet } from '@hot-b2b/store/auth/selector';
import { selectModalData } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { PhoneNumberFormatModel } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';
import {
    HotApiChildContactsService,
    HotChildContact,
    HotChildContactRegistrationRequest,
    HotOutlet,
    OutletAccessLevel,
} from '@hot-theme-nx/generated-api';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OutletRegistrationService } from 'apps/hot-b2b/src/app/account/services';
import { forkJoin, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalService, ConfigurationService } from '../../../services';
import { FormValidationsService } from '@hot-theme-nx/common-api';

type StepType = 'create' | 'create-completed' | 'remove' | 'remove-completed';

@Component({
    selector: 'hot-theme-manage-child-account-modal',
    templateUrl: './manage-child-account-modal.component.html',
})
export class ManageChildAccountModalComponent implements OnInit {
    public settingsData$: Observable<HotSettingsExtended>;
    public authContactOutlet$: Observable<HotOutlet>;
    public manageChildAccountData$: Observable<any>;

    public currentStep: StepType = 'create';
    public accountInformationForm: UntypedFormGroup;
    public outlets: HotOutlet[] = [];
    public selectedToRemoveChildAccount: HotChildContact;

    public phoneNumberFormatErrors: Array<string> = [];
    public phoneNumberFormats: PhoneNumberFormatModel[] = [];
    public phonePrefix: string;
    public phoneNumberExist: boolean = false;
    public emailAddressExist: boolean = false;

    public phoneExistErrorMassage: string;
    public emailExistErrorMessage: string;

    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    public enableChildViaSms: boolean = false;

    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly store: Store<AppState>,
        private readonly outletRegistrationService: OutletRegistrationService,
        private readonly apiContactService: HotApiChildContactsService,
        private readonly modalService: ModalService,
        private readonly translateService: TranslateService,
        private readonly configurationService: ConfigurationService
    ) {
        this.settingsData$ = this.store.pipe(select(settingsData));
        this.settingsData$.subscribe((settings: HotSettingsExtended) => {
            this.enableChildViaSms = settings.enableChildViaSms;
        });

        this.authContactOutlet$ = this.store.pipe(select(authContactOutlet));
        this.manageChildAccountData$ = this.store.pipe(select(selectModalData(ModalIds.manageChildAccount)));
    }

    ngOnInit(): void {
        this.manageChildAccountData$.subscribe((data) => {
            if (data?.childContact) {
                this.selectedToRemoveChildAccount = data.childContact;
                this.currentStep = 'remove';
            }
        });

        this.initAccountInfoStep();
        this.initOutlet();

        this.translateService
            .get('shared.modals.manage-child-account.create.phone-number-already-used')
            .pipe(take(1))
            .subscribe((errorMessage) => {
                this.phoneExistErrorMassage = errorMessage;
            });
        if (!this.enableChildViaSms) {
            this.translateService
                .get('shared.modals.manage-child-account.create.email-address-already-used')
                .pipe(take(1))
                .subscribe((errorMessage) => {
                    this.emailExistErrorMessage = errorMessage;
                });
        }
    }

    public initAccountInfoStep(): void {
        this.settingsData$.pipe(take(1)).subscribe((settings) => {
            this.phoneNumberFormats = [];
            let patterns = [];
            let emailPattern = '^$|^[a-zA-Z0-9][^@]*@[^@]+[.]{1}[a-zA-Z]+$';

            settings.phoneNumberFormats.forEach((pattern: string) => {
                const rules = pattern.split('|');
                const prefix = rules[0];
                this.phonePrefix = prefix;
                const wholeLength = Number(rules[1]) + prefix.length;
                patterns.push(`(^\\d{${Number(rules[1])}}$)`);
                this.phoneNumberFormats.push({ prefix, length: wholeLength });
            });
            this.accountInformationForm = this.fb.group({
                firstName: new UntypedFormControl('', Validators.required),
                lastName: new UntypedFormControl(''),
                phone: new UntypedFormControl('', [Validators.required, Validators.pattern(patterns.join('|'))]),
                email: !this.enableChildViaSms
                    ? new UntypedFormControl('', [Validators.required, Validators.pattern(emailPattern)])

                    : '',
                confirmEmail: !this.enableChildViaSms
                    ? new UntypedFormControl('', Validators.required)
                    : '',
            },
                {
                    validators: [
                        FormValidationsService.matchingEmailValidator('email', 'confirmEmail'),
                    ],
                });

            if (!this.enableChildViaSms && this.isHopB2b()) {
                this.accountInformationForm.removeControl('phone');
            }
            this.setPhoneNumberFormatErrorMessage(settings.currentLanguage.code);
        });
    }

    public initOutlet(): void {
        this.authContactOutlet$.pipe(take(1)).subscribe((outlet) => {
            this.outlets = [outlet];
        });
    }

    public submitChooseAccessLevelsForm(): void {
        if (this.accountInformationForm.valid) {
            let { firstName, lastName, phone, email } = this.accountInformationForm.value;
            if (phone) {
                phone = this.phonePrefix + this.removeLeadingZero(phone);
            } else {
                phone = '';
            }
            let model: HotChildContactRegistrationRequest = {
                firstName,
                lastName,
                phone,
                email,
                outlets: this.outlets.map((o) => ({ accessLevel: OutletAccessLevel.UNRESTRICTED, id: o.outerId })),
            };

            this.apiContactService.save(model).subscribe({
                next: () => {
                    this.currentStep = 'create-completed';
                },
                error: (error: HttpErrorResponse) => {
                    if (
                        error.ok === false &&
                        error.status === 400 &&
                        error.error.includes('Phone number is already registered.')
                    ) {
                        this.phoneNumberExist = true;
                        this.accountInformationForm.controls.phone.setErrors({
                            custom: this.phoneExistErrorMassage,
                        });
                    } else if (
                        error.ok === false &&
                        error.status === 400 &&
                        error.error.includes('Email is already registered.')
                    ) {
                        this.emailAddressExist = true;
                        this.accountInformationForm.controls.email.setErrors({
                            custom: this.emailExistErrorMessage,
                        });
                    }
                }
            });
        }
    }

    public removeChildAccount() {
        this.apiContactService.delete(this.selectedToRemoveChildAccount.id).subscribe(() => {
            this.currentStep = 'remove-completed';
            this.outletRegistrationService.removedChildContactId.next(this.selectedToRemoveChildAccount.id);
        });
    }

    public closeModal() {
        this.modalService.toggleModal(ModalIds.manageChildAccount, false);
    }

    public phoneNumberChanged() {
        if (this.phoneNumberExist) {
            this.phoneNumberExist = false;
            this.accountInformationForm.controls.phone.setErrors(null);
        }
        if (this.emailAddressExist) {
            this.emailAddressExist = false;
            this.accountInformationForm.controls.email.setErrors(null);
        }
    }

    public isPatternAvailable(formControl: UntypedFormControl, localizedError: string | Array<string>) {
        if (formControl.invalid && formControl.errors?.pattern) {
            return localizedError;
        }
    }

    public setPhoneNumberFormatErrorMessage(lang: string): void {
        this.translateService.getTranslation(lang).subscribe(() => {
            const errorMessages = [];
            for (const phoneNumberFormat of this.phoneNumberFormats) {
                errorMessages.push(
                    this.translateService.get('shared.input.phone-number-error-details', {
                        prefix: phoneNumberFormat.prefix,
                        length: phoneNumberFormat.length,
                    })
                );
            }
            forkJoin(errorMessages).subscribe((messages: string[]) => {
                this.phoneNumberFormatErrors = messages;
            });
        });
    }

    public restrictCopyPaste(event) {
        event.preventDefault();
        return false;
    }


    ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }

    private removeLeadingZero(phoneNumber: string): string {
        return phoneNumber.startsWith('0')  ? phoneNumber.replace('0', '') : phoneNumber;
    }

    public openTermsAndConditions(): void {
        this.modalService.toggleModal(ModalIds.termsAndConditions, true, { fromManageChildAccount: true });
    }
}
