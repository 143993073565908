import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap } from '@ngrx/store';

import { OrderTemplateState } from './order-template/model';
import { orderTemplateReducer } from './order-template/reducer';
import { orderTemplateInitialState } from './order-template/state';
import { IWidgetsState } from './widgets/model';
import { widgetsReducer } from './widgets/reducer';
import { widgetsInitialState } from './widgets/state';

import { AuthState } from '@hot-b2b/store/auth/model';
import { authReducer } from '@hot-b2b/store/auth/reducer';
import { authInitialState } from '@hot-b2b/store/auth/state';
import { EAuthActions } from '@hot-b2b/store/auth/types';
import { CartState } from '@hot-b2b/store/cart/model';
import { cartReducer } from '@hot-b2b/store/cart/reducer';
import { cartInitialState } from '@hot-b2b/store/cart/state';
import { CatalogState } from '@hot-b2b/store/catalog/model';
import { catalogReducer } from '@hot-b2b/store/catalog/reducer';
import { catalogInitialState } from '@hot-b2b/store/catalog/state';
import { FeedbackTopicsState } from '@hot-b2b/store/feedback/model';
import { feedbackTopicsReducer } from '@hot-b2b/store/feedback/reducer';
import { feedbackTopicsInitialState } from '@hot-b2b/store/feedback/state';
import { InsightsState } from '@hot-b2b/store/insights/model';
import { insightsReducer } from '@hot-b2b/store/insights/reducer';
import { insightsInitialState } from '@hot-b2b/store/insights/state';
import { LoyaltyState } from '@hot-b2b/store/loyalty/model';
import { loyaltyReducer } from '@hot-b2b/store/loyalty/reducer';
import { loyaltyInitialState } from '@hot-b2b/store/loyalty/state';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { modalsReducer } from '@hot-b2b/store/modals/reducer';
import { modalsInitialState } from '@hot-b2b/store/modals/state';
import { NotificationsState } from '@hot-b2b/store/notifications/model';
import { notificationsReducer } from '@hot-b2b/store/notifications/reducer';
import { notificationsInitialState } from '@hot-b2b/store/notifications/state';
import { OrdersState } from '@hot-b2b/store/orders/model';
import { ordersReducer } from '@hot-b2b/store/orders/reducer';
import { ordersInitialState } from '@hot-b2b/store/orders/state';
import { ProductsState } from '@hot-b2b/store/products/model';
import { productsReducer } from '@hot-b2b/store/products/reducer';
import { productsInitialState } from '@hot-b2b/store/products/state';
import { PromotionsState } from '@hot-b2b/store/promotions/model';
import { promotionsReducer } from '@hot-b2b/store/promotions/reducer';
import { promotionsInitialState } from '@hot-b2b/store/promotions/state';
import { SettingsState } from '@hot-b2b/store/settings/model';
import { settingsReducer } from '@hot-b2b/store/settings/reducer';
import { TutorialsState } from '@hot-b2b/store/tutorials/model';
import { tutorialsReducer } from '@hot-b2b/store/tutorials/reducer';
import { tutorialsInitialState } from '@hot-b2b/store/tutorials/state';
import { WarehousesState } from '@hot-b2b/store/warehouses/model';
import { warehousesReducer } from '@hot-b2b/store/warehouses/reducer';
import { warehousesInitialState } from '@hot-b2b/store/warehouses/state';
import { ICmsImagesState } from './cms-images/model';
import { cmsImagesReducer } from './cms-images/reducer';
import { MobileAppState } from './nativemobileapp/model';
import { nativeMobileAppDetails } from './nativemobileapp/selector';
import { mobileAppDetailsInitialState } from './nativemobileapp/state';
import { nativeMobileAppDetailsReducer } from './nativemobileapp/reducer';

import { CarouselsState } from './carousels/model';
import { carouselsReducer } from './carousels/reducer';
import { carouselInitialState } from './carousels/state';

export interface AppState {
    router: RouterReducerState;
    cart: CartState;
    catalog: CatalogState;
    products: ProductsState;
    settings: SettingsState;
    modals: ModalsState[];
    auth: AuthState;
    promotions: PromotionsState;
    orders: OrdersState;
    templates: OrderTemplateState;
    notifications: NotificationsState;
    warehouses: WarehousesState;
    insights: InsightsState;
    carousels: CarouselsState;
    loyalty: LoyaltyState;
    tutorials: TutorialsState;
    feedbackTopics: FeedbackTopicsState;
    widgets: IWidgetsState;
    cmsImages: ICmsImagesState;
    nativeMobileAppState: MobileAppState;

}

export const rootReducer: ActionReducerMap<AppState> = {
    router: routerReducer,
    cart: cartReducer,
    catalog: catalogReducer,
    products: productsReducer,
    settings: settingsReducer,
    modals: modalsReducer,
    auth: authReducer,
    promotions: promotionsReducer,
    orders: ordersReducer,
    templates: orderTemplateReducer,
    notifications: notificationsReducer,
    warehouses: warehousesReducer,
    insights: insightsReducer,
    carousels: carouselsReducer,
    loyalty: loyaltyReducer,
    tutorials: tutorialsReducer,
    feedbackTopics: feedbackTopicsReducer,
    widgets: widgetsReducer,
    cmsImages: cmsImagesReducer,
    nativeMobileAppState: nativeMobileAppDetailsReducer,
};

export function clearAppStateReducer(reducer: ActionReducer<AppState, Action>): any {
    return (state: AppState, action: Action) => {
        if (action.type === EAuthActions.AUTH_USER_LOGOUT) {
            // clear all states, except settings (technical debt) and router (default by ngRx)
            state = {
                auth: authInitialState,
                cart: cartInitialState,
                catalog: catalogInitialState,
                insights: insightsInitialState,
                carousels: carouselInitialState,
                loyalty: loyaltyInitialState,
                modals: modalsInitialState,
                notifications: notificationsInitialState,
                orders: ordersInitialState,
                templates: orderTemplateInitialState,
                products: productsInitialState,
                promotions: promotionsInitialState,
                router: state.router,
                settings: state.settings,
                tutorials: tutorialsInitialState,
                warehouses: warehousesInitialState,
                feedbackTopics: feedbackTopicsInitialState,
                widgets: widgetsInitialState,
                cmsImages: state.cmsImages,
                nativeMobileAppState: mobileAppDetailsInitialState,
            };
        }

        return reducer(state, action);
    };
}
