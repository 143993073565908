import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, first, map, take, withLatestFrom } from 'rxjs/operators';

import { AuthenticationService } from '@hot-theme-nx/common-api';
import {
    HotApiAccountService,
    HotApiFulfillmentCentersService,
    HotApiSecurityService,
    HotCurrentUserResponse,
    HotFulfillmentCenter,
} from '@hot-theme-nx/generated-api';
import { selectModalState } from '@hot-b2b/store/modals/selector'; //remindpopup
import { ModalService } from 'apps/hot-b2b/src/app/shared/services/modal.service'; //remindpopup
import { Observable, combineLatest } from 'rxjs';
import { ApplicationInsightsService } from './application-insights.service';
import { ArbitraryNotificationService } from './arbitrary-notification.service';
import { LegalAgreementService } from './legal-agreement.service';
import { LoyaltyService } from './loyalty.service';
import { TelemetryService } from './telemetry.service';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { isApplicationOnline } from '@hot-libs/helpers';
import { HotUserResponseExtended, ProductsBufferModel, SettingsStoreModel } from '@hot-libs/shared-models';
import { AccountType, FeatureNames, StorageKeys, TelemetryEventType, ModalIds } from '@hot-libs/shared-types';
import { CartService } from 'apps/hot-b2b/src/app/cart/services';
import { ProductFunctionalityModel } from 'apps/hot-b2b/src/app/catalog/models';
import { OrderService } from 'apps/hot-b2b/src/app/order/services';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { AuthLoginSuccess, AuthEdit } from '@hot-b2b/store/auth/actions';
import { CartGetSuccess } from '@hot-b2b/store/cart/actions';
import { OfflineCatalogGet, CartDataGet, OfflineCatalogGetFromCenter } from '@hot-b2b/store/catalog/actions';
import { InsightsGet, InsightsGetFromCenter, InsightsSyncronized } from '@hot-b2b/store/insights/actions';
import { LoyaltyBannersGet, LoyaltyGet } from '@hot-b2b/store/loyalty/actions';
import {
    NotificationsGet,
    NotificationsSetLastRead,
    NotificationsSetPopupShown,
} from '@hot-b2b/store/notifications/actions';
import { OrderLastGet, OrdersPreviousGet } from '@hot-b2b/store/orders/actions';
import { PromotionsGet, PromotionsGetFromCenter, PromotionsSynchronized } from '@hot-b2b/store/promotions/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsChangeFFCID, SettingsStoreGet } from '@hot-b2b/store/settings/actions';
import { settingsFFCId, settingsStore } from '@hot-b2b/store/settings/selector';
import { TutorialsGet } from '@hot-b2b/store/tutorials/actions';
import { WarehousesGet } from '@hot-b2b/store/warehouses/actions';
import { WidgetsGet, PopularProductsSynchronized } from '@hot-b2b/store/widgets/actions';
import { ConfigurationService } from './configuration.service';
import { warehouses, warehousesState } from '@hot-b2b/store/warehouses/selector';
import { CookiesService } from './cookies.service';
import { HotCookies } from './constants.service';
import { SecurityService } from './security.service';
import { OutletService } from './outlet.service';
import { FeaturesService } from './features.service';
import { widgetsFetched } from '@hot-b2b/store/widgets/selector';
import { WarehouseService } from './warehouse.service';
import { LocalStorageService } from '@hot-libs/browser-specific';
import { CacheClearService } from 'apps/hot-b2b/src/app/shared/services/cache-clear.service';
const loginPagePath = '/account/sign-in';
@Injectable({
    providedIn: 'root',
})
export class InitDataManagerService {
    public authTokenIsValid: boolean;
    private Nigeria = 'NG';
    public isVnB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';
    showPasswordChangePopUp: boolean = false;
    userId: string;
    public featureEnablePasswordExpiration = this.featuresService.PasswordExpiration;
    public modalPasswordRemindMeLaterUpdateState$: Observable<ModalsState>;
    constructor(
        private readonly store: Store<AppState>,
        private readonly cartService: CartService,
        private readonly securityService: SecurityService,
        private readonly router: Router,
        private readonly outletService: OutletService,
        private readonly orderService: OrderService,
        private readonly featuresService: FeaturesService,
        private readonly hotApiFulfillmentCentersService: HotApiFulfillmentCentersService,
        private readonly loyaltyService: LoyaltyService,
        private readonly legalAgreementService: LegalAgreementService,
        private readonly telemetryService: TelemetryService,
        private readonly appInsightsService: ApplicationInsightsService,
        private readonly arbitraryNotificationService: ArbitraryNotificationService,
        private readonly apiSecurityService: HotApiSecurityService,
        private readonly authenticationService: AuthenticationService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly hotApiAccountService: HotApiAccountService,
        private readonly modalService: ModalService, //remindpopup
        private readonly configurationService: ConfigurationService,
        public readonly cookiesService: CookiesService,
        private readonly warehouseService: WarehouseService,
        private readonly localStorageService: LocalStorageService,
        private readonly clearCache: CacheClearService
    ) {
        this.modalPasswordRemindMeLaterUpdateState$ = this.store.pipe(
            select(selectModalState(ModalIds.remindMeLaterModal))
        ); //remindpopup      
    }

    public initWorker(afterLogin: boolean = false, switchOutlet: boolean = false): void {
        this.appInsightsService.startTrackingEvent(TelemetryEventType.LoginPageLoadingTime);
        const authToken: string = localStorage.getItem(StorageKeys.bearerToken);
        
        if (authToken && this.authenticationService.isAuthenticated()) {
            this.apiSecurityService
                .getCurrentUser()
                .pipe(
                    map((response: HotCurrentUserResponse) => response as HotUserResponseExtended),
                    filter((response: HotCurrentUserResponse) => this.checkIsEazleUser(response.user.contact.isMigratedUser))
                    )
                .subscribe({
                    next: (response: HotUserResponseExtended) => {
                        this.store.dispatch(
                            new AuthLoginSuccess({ user: response.user, homePageUrl: response.homePageUrl })
                        ); // adding homePageURL in store
                        this.userId = response.user.id;
                                               if (
                            response.user.contact.outlet &&
                            response.user.userType !== 'SalesRepresentative' &&
                            response.user.userType !== 'VanSalesMan' &&
                            response.user.userType !== 'Distributor' &&
                            !switchOutlet
                        ) {
                                                        this.outletService
                                .setOutlet(response.user.contact.outlet.id)
                                .pipe(first())
                                .subscribe({
                                    next: () => {
                                        this.setDataAfterLogin(response, afterLogin, switchOutlet);
                                    }
                                });
                        } else {
                            this.setDataAfterLogin(response, afterLogin, switchOutlet);
                        }

                        if (this.featureEnablePasswordExpiration) {
                            this.callReminderPasswordPopUp();
                        }
                    },
                    error: (error: any) => {
                        if (error.status === 403) {
                           this.handleResponse(error);
                        }
                    }
                });
        }
    }


       public handleResponse(error:any)
    {
        this.clearStorage();
        if(error.error === 'Missing store mapping'){
            this.cartService.validateRequest();
        }
    }

    public clearStorage()
    {
        localStorage.removeItem(StorageKeys.authToken);
        localStorage.removeItem(StorageKeys.fcmToken);
        localStorage.removeItem(StorageKeys.userId);
        localStorage.removeItem(StorageKeys.refreshToken);
        localStorage.removeItem(StorageKeys.bearerToken);
        this.router.navigateByUrl(loginPagePath);
    }

    public callReminderPasswordPopUp() {
        this.hotApiAccountService
            .getReminderPopupState({ userId: this.userId, popupReminderType: 'ChangePassword' })
            .subscribe((data) => {
                if (data) {
                    this.showPasswordChangePopUp = true;
                    this.openRemindmeLaterModal();
                } else {
                    this.showPasswordChangePopUp = false;
                }
            });
    }
    public openRemindmeLaterModal(): void {
        this.modalService.toggleModal(ModalIds.remindMeLaterModal, true);
    }
    private setDataAfterLogin(
        response: HotUserResponseExtended,
        afterLogin: boolean,
        switchOutlet: boolean = false
    ): void {
        localStorage.setItem(StorageKeys.outletExternalId, response?.user?.outlet?.outerId);
        const redirectUrlAfterLogin: string = JSON.parse(localStorage.getItem(StorageKeys.bearerToken))?.redirect_url;
        const returnUrl: string = this.activatedRoute.snapshot.queryParams['returnUrl'];
        localStorage.setItem(StorageKeys.reInitializeFirstTimeAPICall, 'true');

        this.store.dispatch(new SettingsStoreGet());

        const widgets$ = this.store.select(widgetsFetched).pipe(first());

        combineLatest([widgets$]).subscribe(([isWidgetsFetched]: [boolean]) => {
            if (!isWidgetsFetched[0] || switchOutlet) {
                this.store.dispatch(new WidgetsGet());
            }
        });

        localStorage.setItem(StorageKeys.userType, response.user.userType);

        this.store.dispatch(new NotificationsSetPopupShown(false));
        this.store.dispatch(new NotificationsSetLastRead(false));

        if (response.user.userType === 'SubDistributor') {
            this.setDistributorMode();
        }

        // get all data from server only if storeSettings is already loaded
        this.setDataAfterLoginSubFunction(response, afterLogin, switchOutlet, redirectUrlAfterLogin, returnUrl);
    }
    public setDataAfterLoginSubFunction(
        response: HotUserResponseExtended,
        afterLogin: boolean,
        switchOutlet: boolean,
        redirectUrlAfterLogin: string,
        returnUrl: string
    ) {
        this.store
            .select(settingsStore)
            .pipe(
                filter((settings: SettingsStoreModel) => !!settings),
                take(1)
            )
            .subscribe(() => {
                this.telemetryService.subscribeToHomePageLoadedEvent();
                this.store.dispatch(new TutorialsGet());

                this.getDataByUserType(response.user.userType);

                this.legalAgreementService.subscribeToTermsAndConditions();
                this.legalAgreementService.subscribeToPrivacyPolicy();
                this.legalAgreementService.subscribeToCookiePolicy();

                if (this.isVnB2b()) {
                    this.legalAgreementService.getOperationProcess();
                    this.legalAgreementService.getPurchaseContract();
                    this.legalAgreementService.getShipmentService();
                    this.legalAgreementService.getRefundPolicy();
                    this.legalAgreementService.getPaymentService();
                    this.legalAgreementService.getMaintainPolicy();
                    this.legalAgreementService.getLitigationProcess();
                    this.legalAgreementService.getAnnouncement();
                    this.legalAgreementService.getSubscription();
                }

                this.arbitraryNotificationService.subscribeToArbitraryNotificationPush();

                if (
                    this.configurationService.getCurrentConfiguration() === this.Nigeria &&
                    localStorage.getItem(StorageKeys.userType) === AccountType.SalesRepresentative
                ) {
                    response.homePageUrl = '/self-registration';
                }
                if (!afterLogin) {
                    return;
                }

                if (switchOutlet) {
                    // enable only for outlet switching case
                    this.switchOutletNavigation();
                } else {
                    // normal routing scenario
                    this.router.navigateByUrl(redirectUrlAfterLogin || returnUrl || response.homePageUrl);
                }

                this.telemetryService.startTrackingRoutingFor(response.homePageUrl);
            });
    }

    private switchOutletNavigation() {
        if (this.router.url.includes('order-suggestions') && this.router.url.includes('outletId')) {
            let url = this.router.url
            url = url.slice(0, url.lastIndexOf('?'));
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([url])
            });
        }
        else {
            this.router.navigateByUrl('/ordering', { skipLocationChange: true }).then(() => {
                if (this.featuresService.DashboardHomePage) {
                    this.router.navigate(['/ordering']);
                } else {
                    this.router.navigate(['/']);
                }
            });
        }
    }

    public getAppBaseData(): void {
        this.store.dispatch(new SettingsStoreGet());
        this.store.dispatch(new TutorialsGet());

        // get all data from server only if storeSettings is already loaded
        this.store
            .select(settingsStore)
            .pipe(
                filter((settings: SettingsStoreModel) => !!settings),
                take(1)
            )
            .subscribe(() => {
                this.store.dispatch(new PromotionsGet());
                this.cartService
                    .sendOfflineOnlineProducts()
                    .pipe(first())
                    .subscribe((data: HotCartExtended) => {
                        this.store.dispatch(new CartGetSuccess(data));
                        this.store.dispatch(new PromotionsSynchronized());
                        this.store.dispatch(new InsightsSyncronized());
                        this.store.dispatch(new PopularProductsSynchronized(data));
                        this.cartService.clearStorageWithOffline();
                    });

                this.store.dispatch(new OrdersPreviousGet());
                this.orderService.requestTemplateFromStorage();
            });
    }

    private getDataByUserType(userType: string): void {
        const currentSubDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);
        if (
            userType === AccountType.Customer ||
            (userType === AccountType.SubDistributor && currentSubDistributorMode === 'Customer')
        ) {
            this.getCustomerData();
        } else if (
            userType === AccountType.Distributor ||
            (userType === AccountType.SubDistributor && currentSubDistributorMode === 'Distributor')
        ) {
            this.getDistributorData();
        }
    }

    private getlastOrder() {
        if (this.router.url !== '/' &&  this.router.url !== '/ordering') { //The api is already calling in main component for home page
            this.store.dispatch(new OrderLastGet());
        }
    }
    public getCustomerData(): void {
        this.store.dispatch(new PromotionsGet());
        this.manageOfflineProducts();
        this.store.dispatch(new CartDataGet());
        this.processProductsBuffer();
        this.getlastOrder();
        this.store.dispatch(new OrdersPreviousGet());
        this.getFFCData();
        this.orderService.requestTemplateFromStorage();
        this.store.dispatch(new NotificationsGet());
        this.store.dispatch(new WarehousesGet());
        this.store.dispatch(new LoyaltyBannersGet());

        this.store
            .select(warehousesState)
            .pipe(
                filter((state) => !!state.fetched),
                take(1)
            )
            .subscribe(() => {
                this.store.dispatch(new OfflineCatalogGet());
                if (this.featuresService.Insights) {
                    this.store.dispatch(new InsightsGet());
                }
            });

        this.loyaltyService
            .loyaltyBalanceEnable()
            .pipe(take(1))
            .subscribe((isEnable: boolean) => {
                if (isEnable) {
                    this.store.dispatch(new LoyaltyGet());
                }
            });
    }

    public getDistributorData(): void {
        this.store.dispatch(new NotificationsGet());
        this.store.dispatch(new WarehousesGet());
    }

    private setDistributorMode(): void {
        const currentSubDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);
        let userMode = currentSubDistributorMode;
        if (!currentSubDistributorMode) {
            localStorage.setItem(StorageKeys.subDistributorMode, 'Distributor');
            userMode = 'Distributor';
        }
        this.securityService.setSubDistrType(userMode.toLowerCase()).pipe(take(1));
    }

    private manageOfflineProducts(): void {
        const offlineCartStorageValue = localStorage.getItem(StorageKeys.offlineCart);
        if (offlineCartStorageValue && isApplicationOnline()) {
            const offlineCart: HotCartExtended = JSON.parse(offlineCartStorageValue);
            const offlineProducts: ProductFunctionalityModel[] =
                offlineCart.items.map((cartItem: HotCartLineItemExtended) => ({
                    id: cartItem.productId,
                    productId: cartItem.productId,
                    quantity: cartItem.quantity,
                })) || [];
            if (offlineProducts.length) {
                const productsBuffer: ProductsBufferModel = {
                    pending: offlineProducts,
                    processing: [],
                    changedItems: [],
                };
                localStorage.setItem(StorageKeys.productsBuffer, JSON.stringify(productsBuffer));
                localStorage.removeItem(StorageKeys.offlineCart);
            }
        }
    }

    private processProductsBuffer(): void {
        const productBufferLocalStorage = localStorage.getItem(StorageKeys.productsBuffer);
        if (productBufferLocalStorage) {
            const productsBuffer: ProductsBufferModel = JSON.parse(productBufferLocalStorage);
            productsBuffer.changedItems = [];
            if (productsBuffer.pending.length || productsBuffer.processing.length) {
                if (productsBuffer.processing.length) {
                    const merged: ProductFunctionalityModel[] = [
                        ...productsBuffer.processing,
                        ...productsBuffer.pending,
                    ];
                    productsBuffer.pending = merged.reduce(
                        (acc: ProductFunctionalityModel[], current: ProductFunctionalityModel) => [
                            ...acc.filter((obj: ProductFunctionalityModel) => obj.productId !== current.productId),
                            current,
                        ],
                        []
                    );

                    productsBuffer.processing = [];
                }
                this.cartService.manageProductsBuffer(productsBuffer, null);
            }
        }
    }

    /**
     * @deprecated we should use method from auth service (common-api).
     */
    private validateAuthenticationToken(): void {
        const currentDate: Date = new Date();
        const expirationDate: Date = new Date(localStorage.getItem(StorageKeys.authTokenExpire));
        if (expirationDate.getTime() <= currentDate.getTime()) {
            const refreshToken: string = localStorage.getItem(StorageKeys.refreshToken);
            this.securityService
                .refreshAuthenticationToken(refreshToken)
                .subscribe(() => (this.authTokenIsValid = true));
        }
        this.authTokenIsValid = true;
    }

    public toOnline(): void {
        this.featuresService
            .checkFeatures(FeatureNames.StoreIsRequiredForOutlet)
            .pipe(withLatestFrom(this.store.pipe(select(settingsFFCId)), this.store.pipe(select(warehouses))), take(1))
            .subscribe(([storeIsRequired, ffcId, warehousesData]: [boolean, string, HotFulfillmentCenter[]]) => {
                if (storeIsRequired) {
                    this.getAppBaseData();
                } else if (!!ffcId) {
                    const ffc = warehousesData.find((data) => data.id === ffcId);
                    this.cookiesService.setCookie(HotCookies.CURRENT_FULFILLMENT_CENTER, ffcId);
                    this.cookiesService.setCookie(HotCookies.CURRENT_STORE, ffc.storeId);
                    this.store.dispatch(new InsightsGetFromCenter(ffc.id));
                    this.store.dispatch(new PromotionsGetFromCenter(ffc.id));
                    this.store.dispatch(new OfflineCatalogGetFromCenter(ffc.id));

                    this.getAppBaseData();
                }
            });
    }

    private getFFCData(): void {
        this.featuresService
            .checkFeatures(FeatureNames.StoreIsRequiredForOutlet)
            .pipe(take(1))
            .subscribe((value: boolean) => {
                if (!value) {
                    const localStorageFfc: string | null = localStorage.getItem(StorageKeys.fulfillmentCenter);
                    if (localStorageFfc) {
                        const selectedFulfillmentCenter: HotFulfillmentCenter = JSON.parse(localStorageFfc);
                        if (selectedFulfillmentCenter) {
                            this.store.dispatch(new SettingsChangeFFCID(selectedFulfillmentCenter.id));
                        }
                    }
                }
            });
    }

    private checkIsEazleUser(isEazleUser: boolean): boolean{
        if(isEazleUser) {
            this.router.navigate(['/eazle-migration-error-screen']);
            return false;
        }
        return true;
    }
    public cacheDelete(userData, outlet) {
        if (userData) {
            const deleteURLs = [
                { 'dataGroupName': 'performance', 'url': '/storefrontapi/hot/security/user' },
                { 'dataGroupName': 'performance8h', 'url': '/storefrontapi/hot/fulfillment-centers' },
                { 'dataGroupName': 'performance8h', 'url': '/storefrontapi/hot/marketing/suggestions/v2' },
                { 'dataGroupName': 'performance2', 'url': '/storefrontapi/hot/order-templates' },
                { 'dataGroupName': 'performance3', 'url': '/storefrontapi/hot/cms/home-page-widgets' },
                { 'dataGroupName': 'performance', 'url': '/storefrontapi/hot/marketing/promotions' },
                { 'dataGroupName': 'performance', 'url': '/storefrontapi/hot/marketing/insights' },
                { 'dataGroupName': 'performance8h', 'url': '/storefrontapi/hot/marketing/carousel-banners' },
            ];

            this.clearCache.deleteCacheByAPIUrl(deleteURLs).then(() => {
                userData.contact.outlet = outlet;
                this.store.dispatch(new AuthEdit(userData));
                this.localStorageService.removeItem(StorageKeys.fulfillmentCenter);
                this.localStorageService.removeItem(StorageKeys.rewardsFulfillmentCenter);
                this.initWorker(true, true);
            });
        }
    }
}
