// en-US loaded by default
import localeAmEt from '@angular/common/locales/am';
import localeArEg from '@angular/common/locales/ar-EG';
import localeEnBs from '@angular/common/locales/en-BS';
import localeEsPa from '@angular/common/locales/es-PA';
import localeFrRw from '@angular/common/locales/fr-RW';
import localePtMz from '@angular/common/locales/pt-MZ';
import localeRw from '@angular/common/locales/rw';
import localeZhHans from '@angular/common/locales/zh-Hans';
import localefr from '@angular/common/locales/fr';
import localekm from '@angular/common/locales/km';
import localeHT from '@angular/common/locales/fr-HT';
import localeNC from '@angular/common/locales/fr-NC';
import localeEnJm from '@angular/common/locales/en-JM';
import localehuHU from '@angular/common/locales/hu';
import localeesES from '@angular/common/locales/es';
import localeFrRE from '@angular/common/locales/fr-RE';
import localeLA from '@angular/common/locales/lo';
import localemyMM from '@angular/common/locales/my';
import localeIndonesian from '@angular/common/locales/id';
import localeVi from '@angular/common/locales/vi';
import localeelGR from '@angular/common/locales/el';
import localeEnCA from '@angular/common/locales/en-CA';
import localeFrCA from '@angular/common/locales/fr-CA';
import localeczCA from '@angular/common/locales/cs';

export const languages = {
    'am-ET': localeAmEt,
    'ar-EG': localeArEg,
    'en-BS': localeEnBs,
    'es-PA': localeEsPa,
    'fr-RW': localeFrRw,
    'pt-MZ': localePtMz,
    'rw-RW': localeRw,
    'zh-Hans': localeZhHans,
    'fr-FR': localefr,
    'zh-CN': localeZhHans,
    'km-KH': localekm,
    'fr-HT': localeHT,
    'fr-NC': localeNC,
    'en-JM': localeEnJm,
    'hu-HU': localehuHU,
    'es-ES': localeesES,
    'fr-RE': localeFrRE,
    'lo-LA': localeLA,
    'my-MM': localemyMM,
    'id-ID': localeIndonesian,
    'vi-VN': localeVi,
    'el-GR': localeelGR,
    'en-CA': localeEnCA,
    'fr-CA': localeFrCA,
    'cs-CZ': localeczCA,
};
