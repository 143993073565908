import { Component, Input, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { HotApiFulfillmentCentersService, HotFulfillmentCenter } from '@hot-theme-nx/generated-api';

import { CartUpdate } from '@hot-b2b/store/cart/actions';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine } from '@hot-b2b/store/settings/selector';
import { HotOrderExtended } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';
import { OrderService } from 'apps/hot-b2b/src/app/order/services';
import { HotCartExtended } from 'apps/hot-b2b/src/app/shared/models';
import { ModalService, OrderDetailsService } from 'apps/hot-b2b/src/app/shared/services';
import { CartService } from 'apps/hot-b2b/src/app/cart/services';
import { CookiesService } from '../../../services/cookies.service';
import { HotCookies } from '../../../services/constants.service';
import { InsightsGetFromCenter } from '@hot-b2b/store/insights/actions';
import { PromotionsGetFromCenter } from '@hot-b2b/store/promotions/actions';
import { OfflineCatalogGetFromCenter } from '@hot-b2b/store/catalog/actions';

@Component({
    selector: 'hot-reorder-confirmation-modal',
    templateUrl: './reorder-confirmation-modal.component.html',
})
export class ReorderConfirmationModalComponent implements OnDestroy {
    private readonly settingsAppOnLine$: Observable<boolean>;

    @Input() public order: HotOrderExtended;
    @Input() public cart: HotCartExtended;
    @Input() public fulfillmentCenter: HotFulfillmentCenter;

    private readonly subscriptionDestroy$ = new Subject<boolean>();
    private readonly modalReorderConfirmationState$: Observable<ModalsState>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly hotApiFulfillmentCentersService: HotApiFulfillmentCentersService,
        private readonly orderService: OrderService,
        private readonly orderDetailsService: OrderDetailsService,
        private readonly cartService: CartService,
        public readonly cookiesService: CookiesService
    ) {
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.modalReorderConfirmationState$ = this.store.pipe(select(selectModalState(ModalIds.reorderConfirmation)));
    }

    public reorder(): void {
        this.settingsAppOnLine$.pipe(takeUntil(this.subscriptionDestroy$)).subscribe((isOnline: boolean) => {
            this.modalReorderConfirmationState$
                .pipe(
                    takeUntil(this.subscriptionDestroy$),
                    filter(
                        (modalState) => (!!modalState.data.ffc && !!modalState.data.order) || !!modalState.data.storeId
                    )
                )
                .subscribe((modalState: ModalsState) => {
                    const newFfc = modalState.data.ffc;
                    if (isOnline) {
                        this.cookiesService.setCookie(HotCookies.CURRENT_FULFILLMENT_CENTER, newFfc.id);
                        this.cookiesService.setCookie(HotCookies.CURRENT_STORE, newFfc.storeId);
                        this.store.dispatch(new InsightsGetFromCenter(newFfc.id));
                        this.store.dispatch(new PromotionsGetFromCenter(newFfc.id));
                        this.store.dispatch(new OfflineCatalogGetFromCenter(newFfc.id));
                        this.orderService.reorder(this.order, newFfc, true);
                        this.orderDetailsService.closeOrderDetails();
                    } else {
                        this.cart.storeId = modalState.data.storeId;
                        this.cart.items = [];
                        this.store.dispatch(new CartUpdate(this.cart));
                        this.orderService.reorder(this.order, newFfc, false);
                        this.cartService.keepOfflineCartLineItems();
                        this.orderDetailsService.closeOrderDetails();
                    }

                    this.closeOnConfirm();
                });
        });
    }

    public closeOnConfirm(): void {
        this.modalService.toggleModal(ModalIds.reorderConfirmation, false);
        this.modalService.toggleModal(ModalIds.order, false);
    }

    public close(): void {
        this.modalService.toggleModal(ModalIds.reorderConfirmation, false);
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }
}
