export enum TelemetryEventType {
    HomePageLoadingTime = 'HomePageLoadingTime',
    CatalogLoadingTime = 'CatalogLoadingTime',
    CatalogPageLoadingTime = 'CatalogPageLoadingTime',
    PromotionsLoadingTime = 'PromotionsLoadingTime',
    InsightsLoadingTime = 'InsightsLoadingTime',
    CartChangedOnline = 'CartChangedOnline',
    CartChangedOffline = 'CartChangedOffline',
    OrderCreateResponseTime = 'OrderCreateResponseTime',
    OrderGetResponseTime = 'OrderGetResponseTime',
    OrderCreateFullResponseTime = 'OrderCreateFullResponseTime',
    CheckoutButtonClick = 'CheckoutButtonClick',
    ProductItemClick = 'ProductItemClick',
    BrandItemClick = 'BrandItemClick',
    SearchKeyword = 'SearchKeyword',
    CartTotal = 'CartTotal',
    LoginPageLoadingTime = 'LoginPageLoadingTime',
    LoginEventResponseTime = 'LoginEventResponseTime',
    LoginFailedAttempt = 'LoginFailedAttempt',
    LoginFullResponseTime = 'LoginFullResponseTime',
    CartItem = 'CartItem',
}
