import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';

import { HotInsight } from '@hot-theme-nx/generated-api';

import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { InsightsState } from '@hot-b2b/store/insights/model';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsState } from '@hot-b2b/store/settings/model';
import { stateSettings } from '@hot-b2b/store/settings/selector';

const stateInsights = (state: AppState): InsightsState => state.insights;

const getWithFfc = (id: string, data, key: string) => {
    if (data.length) {
        if (id?.length === 0) {
            return data[0][key];
        } else {
            const filtered = data.filter((item) => item.id === id);
            return filtered.length ? filtered[0][key] : filtered;
        }
    }
    return [];
};

export const insights: MemoizedSelector<AppState, HotInsight[]> = createSelector(
    stateInsights,
    stateSettings,
    (state: InsightsState, settings: SettingsState) => getWithFfc(settings.data.ffc.id, state.data.slots, 'insights')
);

export const insightByCode: MemoizedSelectorWithProps<AppState, string, HotInsight> = createSelector(
    stateInsights,
    stateSettings,
    (state: InsightsState, settings: SettingsState, code: string) =>
        getWithFfc(settings.data.ffc.id, state.data.slots, 'insights').find((item: HotInsight) => item.code === code)
);

export const insightById: MemoizedSelectorWithProps<AppState, string, HotInsight> = createSelector(
    stateInsights,
    stateSettings,
    (state: InsightsState, settings: SettingsState, id: string) =>
        getWithFfc(settings.data.ffc.id, state.data.slots, 'insights').find((item: HotInsight) => item.id === id)
);

export const insightProducts: MemoizedSelector<AppState, HotProductExtended[]> = createSelector(
    stateInsights,
    (state: InsightsState) => state.data.products
);

export const insightPending: MemoizedSelector<AppState, boolean> = createSelector(
    stateInsights,
    (state: InsightsState) => state.pending
);