import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';

import {
    ApiConfiguration,
    HotAggregationItem,
    HotApiCatalogService,
    HotCategory,
    HotFulfillmentCenter,
    HotProductSearchCriteria,
    HotProductSearchLightResult,
    HotSuggestionsResult,
    HotFavoriteProductReponse,
    MassOrderUploadTemplate,
    HotCart,
} from '@hot-theme-nx/generated-api';

import { HotBrandExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { AuthenticationService } from 'apps/hot-b2b/src/app/account/services';
import {
    BrandSearchResponseModel,
    BrandsSearchResponseModel,
    HotCatalogExtended,
    HotProductExtended,
    ProductAttributesExtended,
} from 'apps/hot-b2b/src/app/catalog/models';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import { StorageKeys } from '@hot-libs/shared-types';
import { HotCookies } from '../../shared/services/constants.service';
import { CookiesService } from '../../shared/services/cookies.service';
import { Store, select } from '@ngrx/store';
import { settingsStore } from '@hot-b2b/store/settings/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { warehousesState } from '@hot-b2b/store/warehouses/selector';
import { WarehousesState } from '@hot-b2b/store/warehouses/model';

@Injectable({
    providedIn: 'root',
})
export class CatalogService {
    public settingsStore$: Observable<SettingsStoreModel>;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly authenticationService: AuthenticationService,
        private readonly apiCatalogService: HotApiCatalogService,
        private readonly apiConfiguration: ApiConfiguration,
        private readonly cookiesService: CookiesService,
        private readonly featuresService: FeaturesService,
        private readonly store: Store<AppState>
    ) {
        this.settingsStore$ = this.store.pipe(select(settingsStore));
    }

    public getProducts(ids: string[]): Observable<HotProductExtended[]> {
        let params: HttpParams = new HttpParams();
        ids.forEach((id: string) => (params = params.append('ids', id)));
        return this.httpClient.get<HotProductExtended[]>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/catalog/products`,
            { params }
        );
    }

    public getBrands(request: string[]): Observable<BrandsSearchResponseModel> {
        return this.httpClient.post<BrandsSearchResponseModel>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/catalog/brand-groups/search`,
            request
        );
    }

    public getSuggestions(query: string, ffcId?: string): Observable<HotSuggestionsResult> {
        let queryParams: HttpParams = new HttpParams().set('keyword', query).set('take', '5');

        if (!!ffcId) {
            queryParams = queryParams.set('fulfillmentCenterId', ffcId);
        }

        return this.httpClient.get<HotSuggestionsResult>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/catalog/suggestions`,
            {
                params: queryParams,
            }
        );
    }

    public lightProductSearch(query: string, ffcId?: string): Observable<HotProductSearchLightResult> {
        let queryParams: HttpParams = new HttpParams().set('keyword', query);

        if (!!ffcId) {
            queryParams = queryParams.set('fulfillmentCenterId', ffcId);
        }

        return this.httpClient.get<HotProductSearchLightResult>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/catalog/products/search-light`,
            {
                params: queryParams,
            }
        );
    }

    public convertToBrandModel(searchBrandModel: BrandSearchResponseModel): HotBrandExtended {
        const tokenizedUrl: string = searchBrandModel.name.toLowerCase().replace(' ', '-');

        return {
            name: searchBrandModel.name,
            imageUrl: searchBrandModel.imageUrl,
            url: '/brands/' + tokenizedUrl,
            active: false,
        };
    }

    public searchBrandProducts(query: HotProductSearchCriteria): Observable<ProductAttributesExtended> {
        return this.httpClient.post<ProductAttributesExtended>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/catalog/products/search`,
            query
        );
    }

    public getOfflineCatalog(fulfillmentCenterId?: string): Observable<HotCatalogExtended> {
        const mode = this.plpFeatureCheck();
        switch (mode) {
            case 'SearchV2Mode':
                return this.searchProductsWithParams(false); //Search/v2 without categories
            case 'SearchV2CategoriesMode':
                return this.searchProductsWithParams(true); //Search/v2 with categories
            case 'PWACategoriesMode':
            case 'PWAMode':
            case 'AdvancedFilterPWAMode' : {
                return this.apiCatalogService
                    .getOfflineCatalog(fulfillmentCenterId)
                    .pipe(map((catalog) => catalog as HotCatalogExtended)); // Offline API
            }
            default:
                return of({id : fulfillmentCenterId }).pipe(map((catalog) => catalog as HotCatalogExtended));
        }
    }
    private searchProductsWithParams(categories: boolean = false) {
        let productPerPage = 30;
        this.settingsStore$.pipe(take(1)).subscribe((value: SettingsStoreModel) => {
            productPerPage = value.productsPerPage;
        });
        let request = {
            keyword: '',
            terms: [],
            pageNumber: 1,
            pageSize: productPerPage,
            withAggregations: true,
            withBrandGroups: true,
            withPackageTypes: true,
            withPriceRanges: true,
            withCategories: categories,
        };
        return this.apiCatalogService.searchProductsV2(request).pipe(map((catalog) => catalog as HotCatalogExtended));
    }

    private fulfillmentCenterStatus(fulfillmentCenters: HotFulfillmentCenter[]) {
        return fulfillmentCenters.length && !this.isOnline() ? forkJoin(this.getAllOfflineCatalog(fulfillmentCenters)) : of([]);
    }

    private getSelectedFulFillmentCenters(fulfillmentCenters: HotFulfillmentCenter[]) {
        
       let selectedFulfillmentCenter= (JSON.parse(
        localStorage.getItem(StorageKeys.fulfillmentCenter)
    ) as HotFulfillmentCenter)?.id;
        if(selectedFulfillmentCenter == undefined || selectedFulfillmentCenter == null || selectedFulfillmentCenter == "") {
            if(fulfillmentCenters.length === 1 && fulfillmentCenters[0]?.id) {
                selectedFulfillmentCenter = fulfillmentCenters[0]?.id;
            } else {
                selectedFulfillmentCenter = this.cookiesService.getCookie(HotCookies.CURRENT_FULFILLMENT_CENTER);
            }
        }
        return selectedFulfillmentCenter ? [{ id: selectedFulfillmentCenter }] : Array(fulfillmentCenters[0]);
    }

    public getOfflineCatalogsFromCenters(): Observable<HotCatalogExtended[]> {  //Need to update any datatype to HotExtend
        return this.store.pipe(select(warehousesState),
            filter(state => !!state.fetched),
            first(),
            switchMap((warehouses: WarehousesState) => {
                // If data is empty array then we need to return empty catalog array for next chain calls
                const fulfillmentCenters: HotFulfillmentCenter[] = warehouses?.data?.warehouses; // rewardsCentersOnly: false
                 return fulfillmentCenters.length && this.isOnline()
                         ? forkJoin(this.getAllOfflineCatalog(this.getSelectedFulFillmentCenters(fulfillmentCenters)))
                         : this.fulfillmentCenterStatus(fulfillmentCenters)
            }))
    }
    getAllOfflineCatalog(fulfillmentCenters: HotFulfillmentCenter[]) {
        return fulfillmentCenters.map((item: HotFulfillmentCenter) =>
            this.getOfflineCatalog(item.id).pipe(map((catalog: HotCatalogExtended) => ({ id: item.id, ...catalog })))
        .pipe(map((catalog: HotCatalogExtended) => catalog as HotCatalogExtended))
        );
    }
    public isOnline() {
        const mode = JSON.parse(localStorage.getItem(StorageKeys.appMode));
        return mode ? mode.online : true;
    }

    public getCatalog(
        fulfillmentCenterId?: string,
        returnableEmpties: boolean = false
    ): Observable<HotCatalogExtended> {
        return this.apiCatalogService
            .getCatalog({ fulfillmentCenterId, returnableEmpties })
            .pipe(map((catalog) => catalog as HotCatalogExtended));
    }
    plpFeatureCheck(): string {
        if (!this.featuresService.EnableAdvancedFilters) {
            // Not Advanced Filter(Normal PLP Screen)
            if (this.featuresService.Pwa) {
                // Home initial - offline API
                return this.categoriesAsPrimaryCatalogFilter() ? 'PWACategoriesMode' : 'PWAMode';
            } else {
                return this.categoriesAsPrimaryCatalogFilter() ? 'SearchV2CategoriesMode' : 'SearchV2Mode';
            }
        } else if (this.featuresService.EnableAdvancedFilters) {
            // Advanced Filter
            return this.featuresService.Pwa ? 'AdvancedFilterPWAMode' : 'AdvancedFilterMode';
        }
    }
    private categoriesAsPrimaryCatalogFilter(): boolean {
        return (
            this.featuresService.UseCategoriesAsPrimaryCatalogFilter &&
            !this.featuresService.BrandsFilter &&
            !this.featuresService.EnableAdvancedFilters
        );
    }

    public getCatalogsFromCenters(returnableEmpties: boolean = false): Observable<HotCatalogExtended[]> {
        const selectedFulfillmentCenter = this.cookiesService.getCookie(HotCookies.CURRENT_FULFILLMENT_CENTER);
        return this.store.pipe(
            select(warehousesState),
            filter((state) => !!state.fetched),
            first(),
            switchMap((warehouses: WarehousesState) => {
                let fulfillmentCenter: any[];
                const centers: HotFulfillmentCenter[] = warehouses?.data?.warehouses; // rewardsCentersOnly: false
                if (selectedFulfillmentCenter) {
                    fulfillmentCenter = [{ id: selectedFulfillmentCenter }];
                } else {
                    fulfillmentCenter = Array(centers[0]);
                }
                // If data is empty array then we need to return empty catalog array for next chain calls
                return centers.length ? forkJoin(this.warehouseSelector(fulfillmentCenter, returnableEmpties)) : of([])
            }),
        )
    }
    private warehouseSelector(data: HotFulfillmentCenter[], returnableEmpties: boolean) {
        return data.map((item: HotFulfillmentCenter) =>
            this.getCatalog(item.id, returnableEmpties).pipe(
                map((catalog: HotCatalogExtended) => ({ id: item.id, ...catalog }))
            ).pipe(map((catalog: HotCatalogExtended) => catalog as HotCatalogExtended))
        );
    }
    public authenticaionError(err) {
        if (err.status === 401) {
            this.authenticationService.logout();
        }
    }

    public getCategoryTree(): Observable<HotCategory[]> {
        return this.apiCatalogService.getCategoryTree();
    }

    public getProductTypesByCategoryId(categoryId: string): Observable<HotAggregationItem[]> {
        return this.apiCatalogService.getProductTypesByCategoryId(categoryId);
    }

    public addOrDeleteFavoriteProduct(
        params: HotApiCatalogService.AddOrDeleteParams
    ): Observable<HotFavoriteProductReponse> {
        return this.apiCatalogService.addOrDelete(params);
    }

    public getTemplateLink(): Observable<MassOrderUploadTemplate> {
        return this.httpClient.get<MassOrderUploadTemplate>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/orders/mou-template`
        );
    }

    public callClearCart(): Observable<HotCart> {
        return this.httpClient.delete<HotCart>(`${this.apiConfiguration.rootUrl}/storefrontapi/hot/cart/items`);
    }
}
