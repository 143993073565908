import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppState } from '@hot-b2b/store/reducers';
import { ModalService } from '../../../services';
import { InitDataManagerService } from '../../../services/initdata-manager.service';

import { LocalStorageService } from '@hot-libs/browser-specific';
import { StorageKeys } from '@hot-libs/shared-types';
import { SettingsToggleOnline } from '@hot-b2b/store/settings/actions';
@Component({
    selector: 'hot-unavailable-in-offline-modal',
    templateUrl: './unavailable-in-offline-modal.component.html',
})
export class UnavailableInOfflineModalComponent {
    @Input() public modalId: string;
    @Input() public itemUrl: string;
    @Output() public closeNavMenu: EventEmitter<void> = new EventEmitter();

    constructor(
        private readonly modalService: ModalService,
        private readonly store: Store<AppState>,
        private readonly initDataManagerService: InitDataManagerService,
        private readonly localStorageService: LocalStorageService,
        private readonly router: Router
    ) {}

    public closeModal(): void {
        this.modalService.toggleModal(this.modalId, false);
    }

    public switchToOnline(): void {
        this.store.dispatch(new SettingsToggleOnline(true));
        this.initDataManagerService.toOnline();
        this.localStorageService.setItem(StorageKeys.appMode, {
            online: true,
            lastActivity: null,
            modalAvailable: false,
        });
        this.router.navigateByUrl(this.itemUrl);
        this.closeNavMenu.emit();
        this.modalService.toggleModal(this.modalId, false);
    }
}
