import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ModalToggle } from '@hot-b2b/store/modals/actions';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { AppState } from '@hot-b2b/store/reducers';
import { BehaviorSubject} from 'rxjs';
import { ModalIds } from '@hot-libs/shared-types';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    public modalState$ = new BehaviorSubject<ModalsState>(null);
    public modalAction$ = new BehaviorSubject<string>(null);
    public modalDistributorOrderChanged$ = new BehaviorSubject<boolean>(false);
    public disableConfirmButton$ = new BehaviorSubject<boolean>(true);
    public disableDistributorConfirmEditButton$ = new BehaviorSubject<boolean>(true);
    private confirmResolver: Function;
    constructor(private readonly store: Store<AppState>) {}

    public toggleModal(id: string, state: boolean, data: any = null): void {
        const modalState: ModalsState = { id, isOpened: state, data };
        this.store.dispatch(new ModalToggle(modalState));
        this.modalState$.next(modalState);
    }

    public closeAll(): void {
        Object.values(ModalIds).forEach((value: string) => {
           if (value !== ModalIds?.transitionConfirmation)
             this.toggleModal(value, false);
        });
    }
    public setConfirmResolver(callBack: Function) {
        this.confirmResolver = callBack;
    }

    public callConfirmResolver(confirmResolver?: Function) {
        let confirmCallback = this.confirmResolver || confirmResolver;

        if (confirmCallback) {
            confirmCallback();
        }

        this.toggleModal(ModalIds.transitionConfirmation, false);
        this.confirmResolver = null;
    }
}
