import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { advanceFilter } from '@hot-b2b/store/catalog/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CatalogApplyAdvanceFilters } from '@hot-b2b/store/catalog/actions';
import findIndex from 'lodash/findIndex';
import { AdvanceFilterService } from 'apps/hot-b2b/src/app/shared/services/advance-filter.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'hot-advance-filter-panel',
    templateUrl: './advance-filter-panel.component.html',
})
export class AdvanceFilterPanelComponent implements OnInit {
    @Output() public toggle = new EventEmitter();
    @Output() public reset = new EventEmitter();
    @Output() public applyFilter = new EventEmitter();
    @Output() public brandSelected = new EventEmitter();
    @Output() public filterChaged = new EventEmitter();
    
    public advanceFilter;
    public advanceFilter$;
    public activeBrand;
    public isNG = () => this.configurationService.getCurrentConfiguration() === 'NG';

    private readonly subscriptionDestroy$ = new Subject<boolean>();

    constructor(private readonly store: Store<AppState>, 
        private readonly advanceFilterService: AdvanceFilterService,
        private readonly configurationService: ConfigurationService,
        private readonly route: ActivatedRoute,
        ) {
        this.advanceFilter$ = this.store.pipe(select(advanceFilter));
    }

    ngOnInit(): void {
        this.activeBrand = this.route.snapshot.queryParams.brand;
        this.initFilters();
    }

    public initFilters() {
        this.store.pipe(select(advanceFilter), takeUntil(this.subscriptionDestroy$)).subscribe((appliedFilter) => {
            this.advanceFilter = appliedFilter;        
        });
    }

    public expandFilter(selectedFilter) {
        selectedFilter.isOpened = !selectedFilter.isOpened;
    }
    
    public selectFilter(event, optionSelected, mainFilter) {
        if (mainFilter.showModal && mainFilter.isMultiselect) {
            optionSelected['isActive'] = !optionSelected.isActive;
            optionSelected['isChecked'] = !optionSelected.isChecked;
            this.setActiveFilters(mainFilter);
        } else if (mainFilter.showModal && !mainFilter.isMultiselect) {
            optionSelected['isActive'] = !optionSelected.isActive;
            if (optionSelected.isActive) {
                mainFilter.activeFilter = optionSelected;
            } else {
                mainFilter.activeFilter = null;
            }
        }
        else if (mainFilter.activeFilter != optionSelected) {
                mainFilter.activeFilter = optionSelected;
                this.filterChaged.emit(mainFilter);
                mainFilter.isOpened = false;
        }

        event.preventDefault();
    }

    brandSelect(optionSelected) {
        this.activeBrand = optionSelected;
        this.brandSelected.emit(this.activeBrand);
    }

    public close(): void {
        this.resetStaticFilter();
        this.toggle.emit();
    }
    public resetStaticFilter(){
        let staticFilter = this.advanceFilter.filter((filter) => filter.filterType == 'staticFilter')[0];
            staticFilter.filters.forEach(element => {
                delete element.active;          
            });
    }
    public resetFilter() {  
          let staticFilter = this.advanceFilter.filter((filter) => filter.filterType == 'staticFilter')[0];
          staticFilter.filters.forEach(element => {
               element.active=false;          
          });
         this.activeBrand = 'all';
         this.reset.emit();
         let packageFiler = this.advanceFilter.filter((filter) => filter.filterType == 'Package type')[0];
         if(packageFiler){
            packageFiler?.filters.forEach(element => {
                element.isActive=false;   
                element.isChecked=false;       
        });
        packageFiler.activeFilter=[];
         }
    }

    public applyFilters() {
        this.store.dispatch(new CatalogApplyAdvanceFilters(this.advanceFilter));
        this.applyFilter.emit(true);
    } 

    public isActive(option, activeFilters) {
        let index = findIndex(
        activeFilters,
            (filter: any) => filter?.groupLabel === option.groupLabel && filter.active
        );
        if (index != -1 || option.active) {
            if(!option.active && option.active !=undefined){
                return false;
            }
            option.active=true;
            return true;
        } else {
            return false;
        }
    }

    addCurrency(option) {
        if (option.groupType == 'pricerange') {
            return this.advanceFilterService.addCurrencySymbol(option);
        } else {
            return option.label;
        }
    }

    public selectOtherFilter(option) {
        if (option.groupType == 'pricerange') {
            let otherFilter = this.advanceFilter.filter((filter) => filter.filterType == 'staticFilter')[0];
            otherFilter.filters.forEach((filterType) => {
                if (filterType.groupType == 'pricerange') {
                    if (option == filterType) {
                        filterType.active = true;
                    } else {
                        filterType.active = false;
                    }
                }
            });
        } else {
            option.active = !option.active;
        }
    }

    private setActiveFilters(mainFilter : any) {
        if(mainFilter.filterType === "Package type") {
            mainFilter.activeFilter = mainFilter['filters'].filter((filters) => filters.isChecked);
        }
        else {
            mainFilter.activeFilter = mainFilter['filters'].filter((filters) => filters.isActive || filters.isChecked);
        }
    }   
}
