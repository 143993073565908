import { Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { AuthenticationGuard } from '../auth/guards';

const dashboardRoutes: Routes = [
  {
    path: 'home',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../main/components/dashboard-main-page/dashboard-main.module').then((m) => m.DashboardMainModule)
  },
  {
    path: 'ordering',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../main/main.module').then((m) => m.MainModule)
  }
 ];
@Injectable({
  providedIn: 'root'
})
export class CoreService {

  private isCurrentRoutingWithDashboard = false;

 

  constructor(private readonly router: Router) {}

  public setMainRoutingWithDashboard(): void {
      if (!this.isCurrentRoutingWithDashboard) {
          const routeConfig = this.router.config;
          routeConfig.splice(0, 1, dashboardRoutes[0], dashboardRoutes[1]);
          this.router.resetConfig(routeConfig);

          this.isCurrentRoutingWithDashboard = true;
      }
  }
}
