import { DOCUMENT } from '@angular/common';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ElementRef,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import { CartGet } from '@hot-b2b/store/cart/actions';
import { AppState } from '@hot-b2b/store/reducers';

import { FilterModel } from '@hot-libs/helpers';
import { StorageKeys } from '@hot-libs/shared-types';
import { Store } from '@ngrx/store';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';

@Component({
    selector: 'hot-filters-carousel',
    templateUrl: './filters-carousel.component.html',
})
export class FiltersCarouselComponent implements OnInit, AfterViewInit {
    @ViewChild('carousel', { static: false }) carousel: ElementRef;

    private scrollPoint: number;
    public carouselConfig: any;
    public selectedFilters: FilterModel[];
    private isRTL: boolean;

    @Input() public carouselTileItems = [];
    @Input() public filtersChecked: FilterModel[] = [];
    @Input() public showAll: boolean = true;
    @Output() public filterSelect: EventEmitter<FilterModel> = new EventEmitter();
    @Output() public filterSelectAll: EventEmitter<void> = new EventEmitter();

    constructor(@Inject(DOCUMENT) private readonly document: any, private readonly store: Store<AppState>) {}

    ngOnInit(): void {
        this.carouselConfig = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            variableWidth: true,
            touchThreshold: 100,
        };

        this.selectedFilters = cloneDeep(this.filtersChecked);
        this.filtersChecked = [];
        this.isRTL = this.document.documentElement.dir === 'rtl';
    }

    public ngAfterViewInit(): void {
        this.filtersChecked = this.selectedFilters;
    }

    public selectFilter(filter: FilterModel) {
        this.filtersChecked = [cloneDeep(filter)];
        this.filterSelect.emit(filter);
        const currentTabTimestamp = sessionStorage.getItem(StorageKeys.tabTimestamp);
        const actualTabTimestamp = localStorage.getItem(StorageKeys.tabTimestamp);
        if (actualTabTimestamp !== currentTabTimestamp) {
            this.store.dispatch(new CartGet());
        }
    }

    public setAll(): void {
        this.filterSelectAll.emit();
    }

    public checkActive(item: FilterModel): boolean {
        return (
            findIndex(
                this.filtersChecked,
                (filter: any) => filter?.name === item.name && item.value === filter?.value
            ) !== -1
        );
    }

    public getTrackData(label) {
        return `{ "data_interaction": "link_click", "button_text": "${label}", "name":"PLP" }`;
    }

    public slideTo(direction: string) {
        let scrollMax = this.carousel.nativeElement.scrollWidth - this.carousel.nativeElement.offsetWidth;

        if (!this.isRTL) {
            // Detect scroll start position & scroll end position
            this.carouselNative(scrollMax, direction);

            // Action on button click
            switch (direction) {
                case 'left':
                    const yLeft = this.carousel.nativeElement.scrollLeft - 200;
                    this.carousel.nativeElement.scrollTo({ left: yLeft, behavior: 'smooth' });
                    this.scrollPoint = this.carousel.nativeElement.scrollLeft;
                    break;
                case 'right':
                    const yRight = this.carousel.nativeElement.scrollLeft + 200;
                    this.carousel.nativeElement.scrollTo({ left: yRight, behavior: 'smooth' });
                    this.scrollPoint = this.carousel.nativeElement.scrollLeft;
                    break;
            }
        } else {
            scrollMax = scrollMax * -1 + 1;

            if (this.carousel.nativeElement.scrollLeft <= scrollMax && direction === 'right') {
                this.carousel.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
                this.scrollPoint = null;
                return;
            } else if (this.carousel.nativeElement.scrollLeft >= 0 && direction === 'left') {
                this.carousel.nativeElement.scrollTo({ left: scrollMax, behavior: 'smooth' });
                this.scrollPoint = this.carousel.nativeElement.scrollLeft;
                return;
            }

            switch (direction) {
                case 'left':
                    const yLeft = this.carousel.nativeElement.scrollLeft + 200;
                    this.carousel.nativeElement.scrollTo({ left: yLeft, behavior: 'smooth' });
                    this.scrollPoint = this.carousel.nativeElement.scrollLeft;
                    break;
                case 'right':
                    const yRight = this.carousel.nativeElement.scrollLeft - 200;
                    this.carousel.nativeElement.scrollTo({ left: yRight, behavior: 'smooth' });
                    this.scrollPoint = this.carousel.nativeElement.scrollLeft;
                    break;
            }
        }
    }
    public carouselNative(scrollMax, direction) {
        if (this.carousel.nativeElement.scrollLeft >= scrollMax && direction === 'right') {
            this.carousel.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
            this.scrollPoint = null;
        } else if (this.carousel.nativeElement.scrollLeft === 0 && direction === 'left') {
            this.carousel.nativeElement.scrollTo({ left: scrollMax, behavior: 'smooth' });
            this.scrollPoint = this.carousel.nativeElement.scrollLeft;
        }
    }
}
