import { Component,Input } from '@angular/core';

@Component({
    selector: 'hot-string-length-informer',
    templateUrl: './string-length-informer.component.html',
})
export class StringLengthInformerComponent  {
    @Input()
    public value: string;

    @Input()
    public maxLength: number;


}
