import { Component, Input } from '@angular/core';
import { AuthenticationService } from '@hot-theme-nx/common-api';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { ConfigurationService } from '../shared/services/configuration.service';
import { HotLanguage } from '@hot-theme-nx/generated-api';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { settingsData, settingsLanguageCurrent, settingsStore } from '@hot-b2b/store/settings/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { authUser } from '@hot-b2b/store/auth/selector';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import Utf8 from 'crypto-js/enc-utf8';
import WordArray from 'crypto-js/lib-typedarrays';
import { decrypt } from 'crypto-js/aes';
import secretKey from 'apps/hot-b2b/src/assets/settings/secret-key.json';

@Component({
    selector: 'hot-chatbot',
    templateUrl: './chatbot.component.html',
    // styleUrls: ['./chatbot.component.css'],
})
export class HotChatbotComponent {
    @Input() private userDetails = {
        userId: null,
        firstName: null,
        phone: null,
        emailId: null,
    };
    public isUpLg = () => this.screenDimensionService.upLg();
    // public isNg = () => this.configurationService.getCurrentConfiguration() === 'NG';
    public currentConfiguration = this.configurationService.getCurrentConfiguration();
    public opcoLocale: string;
    public opCoStoreId: string;
    public settingsLanguageCurrent$: Observable<HotLanguage>;
    @Input() public currentRoute: any;
    public authUser$: Observable<HotUserExtended>;
    private readonly settings$: Observable<HotSettingsExtended>;
    public currentOpco?: string;
    public encryptedText?: string;
    public decryptedText?: string;
    public email: string = '';
    public phoneNumber: string = '';
    public currencySymbol: string = '';
    public settingsStore$: Observable<SettingsStoreModel>;
    public currencySymbolASCII: any;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly configurationService: ConfigurationService,
        private readonly store: Store<AppState>
    ) {
        this.settingsLanguageCurrent$ = this.settingsLanguageCurrent$ = this.store.pipe(
            select(settingsLanguageCurrent)
        );
        this.authUser$ = this.store.pipe(select(authUser));
        this.settings$ = this.store.pipe(select(settingsData));
        this.settingsStore$ = this.store.pipe(select(settingsStore));
    }

    ngOnInit() {
        this.launchBot();
        this.getLangSettings();
        this.authUser$.subscribe((user: HotUserExtended) => {
            if (user) {
                this.userDetails.emailId = user.emails[0] ?? '';
                this.userDetails.firstName = user.firstName;
                this.userDetails.phone = user.phones[0] ?? '';
                this.userDetails.userId = user.contactId;
            }
        });

        this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
            this.contactSupport(settings);
        });
        this.settingsStore$.pipe(take(1)).subscribe((settingsStoreModel: SettingsStoreModel) => {
            this.currencySymbol = settingsStoreModel.currencyFormat?.currencySymbol?.toString();
        });
        if (this.currencySymbol.length == 1) {
            this.currencySymbolASCII = this.currencySymbol.charCodeAt(0);
        } else {
            this.currencySymbolASCII = '';
        }
    }
    public contactSupport(settings) {
        this.encryptedText = settings.webChatSecretKey ?? '';
        this.currentOpco = settings.opcoName ?? '';
        this.decryptedText = this.decryptData(this.encryptedText);
        if (settings.support?.contactSupportEmail?.length > 0 && settings.support?.displayContactSupportEmail) {
            this.email = settings.support?.contactSupportEmail?.toString();
        } else {
            this.email = '';
        }
        if (settings.support?.contactSupportPhone?.length > 0 && settings.support?.displayContactSupportPhone) {
            this.phoneNumber = settings.support?.contactSupportPhone?.toString();
        } else {
            this.phoneNumber = '';
        }
    }

    decryptData(ciphertextB64) {
        let keyModel: any;
        keyModel = secretKey; // Base64 encoded ciphertext, 32 bytes string as key
        let key = Utf8.parse(keyModel[0].name); // Convert into WordArray (using Utf8)
        let iv = WordArray.create([0x00, 0x00, 0x00, 0x00]); // Use zero vector as IV
        let decrypted = decrypt(ciphertextB64, key, { iv: iv }); // By default: CBC, PKCS7
        return decrypted.toString(Utf8); // Convert into string (using Utf8)
    }

    //code to get the locale (language selected from Settings)
    public getLangSettings() {
        this.settingsLanguageCurrent$.pipe(take(1)).subscribe((language: HotLanguage) => {
            if (language != null) {
                this.opcoLocale = language.code;
            }
        });
    }
    launchBot() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'scripts/hnkcb-client.js';
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    //to get the botAvatarImage based on OpCo
    public getBotAvatarImageURL() {
        if (this.currentOpco === 'ng') {
            return 'https://heigisivydotcbdazwesta1.blob.core.windows.net/assets/profilepicivyblue.png';
        } else {
            return 'https://heigisivydotcbdazwesta1.blob.core.windows.net/assets/profilepicchatbot2x.png';
        }
    }
}
