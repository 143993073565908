import { PromotionsState } from '@hot-b2b/store/promotions/model';

export const promotionsInitialState: PromotionsState = {
    pending: false,
    fetched: false,
    data: {
        slots: [],
        products: [],
        orderSuggestion: null,
    },
    error: null,
};
