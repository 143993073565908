import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'undefinedValue',
})
export class UndefinedValuePipe implements PipeTransform {
    public transform(value: string): string {
        const defaultValue = '';

        if (!value) {
            return defaultValue;
        }

        return value.toLowerCase() === 'undefined' ? defaultValue : value;
    }
}
