import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ArbitraryNotification } from '@hot-theme-nx/generated-api';

@Injectable({
    providedIn: 'root',
})
export class ArbitraryNotificationClient {
    constructor(private readonly http: HttpClient) {}

    public getActiveNotifications(): Observable<ArbitraryNotification[]> {
        return this.http.get<ArbitraryNotification[]>('/storefrontapi/hot/arbitrary-notifications');
    }
}
