import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CartErrorHandlerService } from '@hot-theme-nx/common-api';
import {
    Coupon,
    CurrencyFormat,
    HotOutlet,
    HotLegalAgreement,
    CouponSearchCriteriaHot,
    HotCouponSearchResult,
    HotOperationResult,
} from '@hot-theme-nx/generated-api';

import { PermissionsService } from '../../services/permissions.service';

import {
    authOrganizationIsCashCreditTerms,
    authUser,
    authContactOutlet,
    authOutletIsCashCreditTerms,
} from '@hot-b2b/store/auth/selector';
import { CartDeleteCoupon, CartSetCoupon } from '@hot-b2b/store/cart/actions';
import { cartData } from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsData, settingsStore, settingsCurrencyFormat } from '@hot-b2b/store/settings/selector';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { CouponModel, HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { CustomerPermissions, ModalIds } from '@hot-libs/shared-types';
import { TranslateService } from '@ngx-translate/core';
import { HotCartExtended } from 'apps/hot-b2b/src/app/shared/models';
import { FeaturesService, ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { LegalAgreementService } from '../../../shared/services/legal-agreement.service';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { StoreService } from 'apps/hot-b2b/src/app/shared/services/store.service';
import { CouponService } from '../../../marketing/services/coupon.service';
import { orderSimulationtaxDetails } from '@hot-b2b/store/orders/selector';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';

@Component({
    selector: 'hot-cart-totals',
    templateUrl: './cart-totals.component.html',
})
export class CartTotalsComponent implements OnChanges, OnInit, OnDestroy {
    @Input() public pageName: string;
    @Input() public modifier: string;
    @Input() public cart: HotCartExtended;
    @Input() public settingsStore: SettingsStoreModel;
    @Input() public currencyFormat: CurrencyFormat;
    @Input() public isAppOnline: boolean;
    @Input() public isCouponHidden: boolean;
    @Input() public isCartEmpty: boolean;
    @Input() public hideDiscounts: boolean;
    @Input() public showPalletTotal: boolean;
    @Input() public hideTotal: boolean;
    @Input() public cartItems: any;
    @Input() public isOrderSimulationSuccess: boolean;
    public currencyFormat$: Observable<CurrencyFormat>;

    public isUpLg = () => this.screenDimensionService.upLg();
    public cartData$: Observable<HotCartExtended>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public authUser$: Observable<HotUserExtended>;
    public user$: Observable<HotUserExtended>;
    public readonly contactOutlet$: Observable<HotOutlet>;
    public hotSettings$: Observable<HotSettingsExtended>;
    public orderSimulationTaxDetails$: Observable<HotOperationResult>;
    public featureCoupons: boolean = this.featuresService.Coupons;
    public isEmptiesReturnVisible = false;
    public featureOutletCreditLimit: boolean = this.featuresService.OutletCreditLimit;
    public featureReturnEmpties: boolean = this.featuresService.ReturnEmpties;
    public remainingBalance = 0;
    public creditTerms: string;
    public currentOpco = null;
    public creditLimit: boolean;
    public creditLimitNumber = 0;
    public outstandingAmount = 0;
    public cartDisclaimer: HotLegalAgreement;
    public isCartDisclaimerExist: boolean;
    public isCashCreditTerms: boolean;
    public overlayVisible: boolean = false;
    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public isCZ = () => this.configurationService.getCurrentConfiguration() === 'CZ';
    public isLAB2b = () => this.configurationService.getCurrentConfiguration() === 'RE';
    public isGRB2b = () => this.configurationService.getCurrentConfiguration() === 'GR';
    public VAT1: string;
    public VAT2: string;
    public subtotalWithoutTax: number;
    public totalWithTax: number;
    public discounts: number;
    public currencyFormatLA$: Observable<CurrencyFormat>;
    public currencyFormatLA: string;
    public emptiesDepositReturnValue: number;
    public orderSimulationValidation: boolean;
    public subtotalWithDiscountLA: number;
    public isPopPilotUser : boolean =false;   

    private readonly subscriptionsDestroy$ = new Subject<boolean>();

    public readonly invalidCouponErrorMessage = 'cart.summary-page.invalid-coupon-title';

    public haveReadPricingPermission: Observable<boolean> = this.permissionsService.checkPermission(
        CustomerPermissions.PricingRead
    );

    public coupon: Coupon = {
        appliedSuccessfully: false,
        code: '',
    };

    public couponForm: UntypedFormGroup = new UntypedFormGroup({
        couponValue: new UntypedFormControl(''),
    });
    public cartErrors: {};
    public totalSavings = 0;
    public taxRate = 0;
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    public settingDisplayCombinedCreditInformation: boolean;
    public settingDisplayCreditInformationBasedOnCreditTerms: boolean;
    public settingDisplayDefaultCreditInformation: boolean;
    public featureEnableViewAllCoupons: boolean = this.featuresService.EnableViewAllCoupons;
    public featureOrganizationLevelCreditLimitCheck = this.featuresService.OrganizationLevelCreditLimitCheck;
    public usedVoucherItem: CouponModel;
    private _isRedeeming = false;
    public suggestedVoucherItem: CouponModel;
    public showAddCouponModel: boolean;
    public showCouponCodeInOrderSummery: boolean;

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly permissionsService: PermissionsService,
        private readonly featuresService: FeaturesService,
        private readonly storeService: StoreService,
        private readonly translateService: TranslateService,
        private readonly cartErrorService: CartErrorHandlerService,
        private readonly screenDimensionService: ScreenDimensionService,
        public readonly router: Router,
        private readonly configurationService: ConfigurationService,
        private readonly legalAgreementService: LegalAgreementService,
        private readonly couponService: CouponService,
        private readonly analyticService: AnalyticService,

    ) {
        this.contactOutlet$ = this.store.pipe(select(authContactOutlet));
        this.cartData$ = this.store.pipe(select(cartData));
        this.authUser$ = this.store.pipe(select(authUser));
        this.user$ = this.store.pipe(select(authUser));
        this.orderSimulationTaxDetails$ = this.store.pipe(select(orderSimulationtaxDetails));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.hotSettings$ = this.store.pipe(select(settingsData));
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));

        if (this.featureOrganizationLevelCreditLimitCheck) {
            this.isCashCreditTerms = this.storeService.getSelector(authOrganizationIsCashCreditTerms);
        } else {
            this.isCashCreditTerms = this.storeService.getSelector(authOutletIsCashCreditTerms);
        }
        this.currencyFormatLA$ = this.store.pipe(select(settingsCurrencyFormat));
    }

    public ngOnInit() {
        this.totalSavings =
            (this.cart?.promotionalDiscountAmount ? this.cart?.promotionalDiscountAmount : 0) +
            (this.cart?.couponDiscountAmount ? this.cart?.couponDiscountAmount : 0);
        this.checkAppliedCoupons();
        this.currentOpco = this.configurationService.getCurrentConfiguration();
        this.settingsStore$.pipe(take(1)).subscribe((value: SettingsStoreModel) => {
            if (value.showCartDisclaimer) {
                this.getCartDisclaimer();
            }
        });
        this.user$.subscribe((data) => {
            this.isPopPilotUser = data.isPopPilotUser
        });

        this.hotSettings$.subscribe((settings: HotSettingsExtended) => {
            this.settingDisplayCombinedCreditInformation = settings.displayCombinedCreditInformation;
            this.settingDisplayCreditInformationBasedOnCreditTerms =
                settings.displayCreditInformationBasedOnCreditTerms;
            this.settingDisplayDefaultCreditInformation = settings.displayDefaultCreditInformation;
        });
        if (this.featureEnableViewAllCoupons) {
            this.loadSuggestVoucher();
        }

        this.orderSimulationTaxDetails$.subscribe((orderSimulationResponse) => {
            this.validateTaxOrderSimulation(orderSimulationResponse);
        });
    }

    public validateTaxOrderSimulation(orderSimulationResponse: HotOperationResult) {
        // Get currency format
        this.currencyFormatLA$.pipe(take(1)).subscribe((format: CurrencyFormat) => {
            this.currencyFormatLA = format.currencySymbol;
        });

        if (orderSimulationResponse.succeeded) {
            if (orderSimulationResponse.validationTaxDetails.length > 0) {
                this.orderSimulationSuccess(orderSimulationResponse);
            } else {
                this.orderSimulationValidation = false;
                this.VAT1 = '0';
                this.VAT2 = '0';
            }
        }
    }

    public orderSimulationSuccess(orderSimulationResponse: HotOperationResult) {
        this.orderSimulationValidation = true;
        this.subtotalWithoutTax = orderSimulationResponse.subtotalWithoutTax + orderSimulationResponse.discount;
        this.subtotalWithoutTax = parseFloat(this.subtotalWithoutTax.toFixed(2));
        this.subtotalWithDiscountLA = orderSimulationResponse.subtotalWithoutTax;
        this.totalWithTax = orderSimulationResponse.totalWithTax;
        this.discounts = orderSimulationResponse.discount;
        if (orderSimulationResponse.validationTaxDetails.length == 4) {
            if (orderSimulationResponse.validationTaxDetails[0].fieldValue == '2.10') {
                this.VAT2 = orderSimulationResponse.validationTaxDetails[3].fieldValue;
                this.VAT1 = orderSimulationResponse.validationTaxDetails[2].fieldValue;
            } else if (orderSimulationResponse.validationTaxDetails[0].fieldValue == '8.5') {
                this.VAT2 = orderSimulationResponse.validationTaxDetails[2].fieldValue;
                this.VAT1 = orderSimulationResponse.validationTaxDetails[3].fieldValue;
            }
        } else if (orderSimulationResponse.validationTaxDetails.length == 2) {
            if (orderSimulationResponse.validationTaxDetails[0].fieldValue == '8.50') {
                this.VAT2 = orderSimulationResponse.validationTaxDetails[1].fieldValue;
                this.VAT1 = '0';
            } else if (orderSimulationResponse.validationTaxDetails[0].fieldValue == '2.10') {
                this.VAT1 = orderSimulationResponse.validationTaxDetails[1].fieldValue;
                this.VAT2 = '0';
            }
        }
    }

    public checkAppliedCoupons() {
        this.cartData$
            .pipe(
                filter((cart) => !!cart.id),
                withLatestFrom(this.translateService.get(this.invalidCouponErrorMessage), this.authUser$),
                takeUntil(this.subscriptionsDestroy$)
            )
            .subscribe(([cart, invalidCouponMessage, user]: [HotCartExtended, string, HotUserExtended]) => {
                this.coupon = cart?.coupons[0];
                if (this.coupon) {
                    this.couponForm.controls['couponValue'].setValue(this.coupon.code);
                    if (!this.coupon.appliedSuccessfully) {
                        this.couponForm.controls['couponValue'].setErrors({
                            custom: invalidCouponMessage,
                        });
                    }
                } else if (!cart.items.length) {
                    this.resetCouponForm();
                }
                this.couponItem(cart);
                if (cart.items && cart.validationErrors) {
                    const transformedErrors = this.cartErrorService.transformErrorsArray(cart.validationErrors);
                    this.cartErrors = this.cartErrorService.prepareToolbarData(transformedErrors);
                } else {
                    this.cartErrors = null;
                }

                // Set credit limit
                if (cart && user && user.contact) {
                    if (this.featureOrganizationLevelCreditLimitCheck && user?.contact?.organization) {
                        this.remainingBalance = user.contact.organization.creditAmount - cart.total;
                        this.creditTerms = user.contact.organization.creditTerms;
                        this.creditLimit = user.contact.organization.creditAmount == 0;
                        this.creditLimitNumber = user.contact.organization.creditAmount;
                        this.outstandingAmount = user.contact.organization.outstandingAmount;
                    } else if (!this.featureOrganizationLevelCreditLimitCheck && user?.contact?.outlet) {
                        this.remainingBalance = user.contact.outlet.creditAmount - cart.total;
                        this.creditTerms = user.contact.outlet.creditTerms;
                        this.creditLimit = user.contact.outlet.creditAmount == 0;
                        this.creditLimitNumber = user.contact.outlet.creditAmount;
                        this.outstandingAmount = user.contact.outlet.outstandingAmount;
                    }
                }
            });
    }
    public couponItem(cart) {
        if (this.featureEnableViewAllCoupons) {
            this.usedVoucherItem = null;
            if (this.coupon) {
                this.usedVoucherItem = {
                    ...this.coupon.couponDetail,
                    code: this.coupon.code,
                    appliedSuccessfully: this.coupon.appliedSuccessfully,
                };
                if (this._isRedeeming) {
                    if (!this.coupon.appliedSuccessfully) {
                        this.modalService.toggleModal(ModalIds.voucherModal, true, {
                            isError: true,
                            usedVoucherItem: this.usedVoucherItem,
                        });
                    }
                    this._isRedeeming = false;
                }
            }
            this.couponFeature(cart);
        }
    }
    public couponFeature(cart) {
        this.usedVoucherItem = null;
        if (this.coupon) {
            this.usedVoucherItem = {
                ...this.coupon.couponDetail,
                code: this.coupon.code,
                appliedSuccessfully: this.coupon.appliedSuccessfully,
            };
            if (this._isRedeeming) {
                if (!this.coupon.appliedSuccessfully) {
                    this.modalService.toggleModal(ModalIds.voucherModal, true, {
                        isError: true,
                        usedVoucherItem: this.usedVoucherItem,
                    });
                }
                this._isRedeeming = false;
            }
        }
        if (!this.coupon?.appliedSuccessfully && !!cart.items.length) {
            this.loadSuggestVoucher();
        }
    }

    public ngOnChanges(): void {

        this.cartDiscount();
        if (this.featureReturnEmpties) {
            if (!this.cart?.emptiesDepositTotal) {
                this.cart.emptiesDepositTotal = 0;
            }

            if (!this.cart?.emptiesReturnTotal) {
                this.cart.emptiesReturnTotal = 0;
            }

            this.emptiesDepositReturnValue = Math.abs(this.cart?.emptiesDepositTotal - this.cart?.emptiesReturnTotal);
            this.isEmptiesReturnVisible = this.cart?.emptiesDepositTotal <= this.cart?.emptiesReturnTotal;
        }
        this.totalSavings =
            (this.cart?.promotionalDiscountAmount ? this.cart?.promotionalDiscountAmount : 0) +
            (this.cart?.couponDiscountAmount ? this.cart?.couponDiscountAmount : 0);
        if (this.cart?.items && this.cart?.items.length > 0) {
            this.taxRate = this.cart?.items[0].taxPercentRate * 100;
        } else {
            this.taxRate = 0;
        }
        if (!!this.coupon && this.coupon.appliedSuccessfully) {
            this.showAddCouponModel = false;
        }
        if (!!this.coupon && this.coupon.appliedSuccessfully) {
            this.showCouponCodeInOrderSummery = true;
        } else {
            this.showCouponCodeInOrderSummery = false;
        }
    }
    public cartDiscount(){
        if (this.cart) {
            this.cart.promotionalDiscountAmount = this.cart?.couponDiscountAmount
                ? this.cart?.discountTotal - this.cart?.couponDiscountAmount
                : this.cart?.discountTotal;
        }
    }

    public showTermsAndUseModal(): void {
        this.modalService.toggleModal(ModalIds.termsAndConditions, true);
    }

    public applyCoupon(): void {
        if (this.featureEnableViewAllCoupons) {
            this._isRedeeming = true;
        }
        this.store.dispatch(new CartSetCoupon(this.couponForm.value.couponValue.trim()));
    }

    public clearCoupon(): void {
        this.store.dispatch(new CartDeleteCoupon());
        this.resetCouponForm();
    }

    public resetCouponForm(): void {
        this.couponForm.reset({
            couponValue: '',
        });
    }

    public getTrackData() {
        let couponCode = this.couponForm.controls.couponValue.value ? this.couponForm.controls.couponValue.value : '';
        return `{ "data_interaction": "Input_click", "button_text": "couponCode : ${couponCode}", "name":"${this.pageName}" }`;
    }

    public getCartDisclaimer(): void {
        this.legalAgreementService.getCartDisclaimer();
        this.legalAgreementService.currentCartDisclaimer$
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe((cartDisclaimer: HotLegalAgreement) => {
                this.isCartDisclaimerExist = !!cartDisclaimer;
                if (this.isCartDisclaimerExist) {
                    this.cartDisclaimer = cartDisclaimer;
                }
            });
    }
    public loadSuggestVoucher(): void {
        const couponRequest: CouponSearchCriteriaHot = {
            pageSize: 1,
            pageNumber: 0,
        };
        this.couponService
            .getCoupons(couponRequest)
            .pipe(take(1))
            .subscribe((couponResponse: HotCouponSearchResult) => {
                if (couponResponse.coupons.length) {
                    this.suggestedVoucherItem = couponResponse.coupons[0];
                }
            });
    }

    public applyVoucher(voucher: CouponModel): void {
        if (voucher.code) {
            this.couponForm.patchValue({
                couponValue: voucher.code,
            });
            this.applyCoupon();
        }
    }

    public showProducts() {
        if (this.overlayVisible) {
            this.overlayVisible = false;
        } else {
            this.overlayVisible = true;
            this.analyticService.sendClickEventInfo('expand_products_subtotal_checkout');
        }
    }

    public toggleAddCouponModel(isActive: boolean) {
        this.showAddCouponModel = isActive;
        if (!isActive) {
            this.resetCouponForm();
        }else{
            this.analyticService.sendClickEventInfo('add_coupon_code');
        }
    }

    public removeCouponCode() {
        this.showCouponCodeInOrderSummery = false;
        this.clearCoupon();
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
        this.modalService.toggleModal(ModalIds.voucherModal, false);
    }
}
