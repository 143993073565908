export class ProductService {
    public static calculateFractionalPart(quantity: number, packageSize: number): number {
        let transformedQuantity = quantity / packageSize;
        if (0 < transformedQuantity && transformedQuantity < 1) {
            const mantissa = transformedQuantity.toString().split('.');
            transformedQuantity = +('0.' + mantissa[mantissa.length - 1].substring(0, 2));
        }

        return transformedQuantity;
    }
}
