import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyFormat } from '@hot-theme-nx/generated-api';

@Pipe({ name: 'currencyFormatOrderSimulation' })
export class CurrencyFormatOrderSimulationPipe implements PipeTransform {
    public transform(
        value: number,
        settings: CurrencyFormat,
        hideCurrencySymbol?: boolean,
        isConvertedNegative?: boolean
    ): string {
        if (!value) {
            value = 0;
        }

        if (!settings) {
            return value.toString();
        }

       if(!Number.isInteger(value)) {
        value = value * 100;
       } 
        

        const regex = new RegExp(`(\\d)(?=(\\d{${settings.groupSize}})+(?!\\d))`, 'g');
        const stringValue = settings.showDecimalDigits
            ? (value / Math.pow(10, settings.decimalDigits))
                  .toFixed(settings.decimalDigits)
                  .replace('.', settings.decimalSeparator)
                  .toString()
            : (value / Math.pow(10, settings.decimalDigits)).toFixed().toString();

        let formattedCurrency = stringValue.replace(regex, '$1' + settings.groupSeparator);
        if (value < 0 && isConvertedNegative) {
            formattedCurrency = formattedCurrency.replace('-', '+');
        }

        // Virto Currency dosen't support space separation
        // As a work around use \s in Group separator
        formattedCurrency = formattedCurrency.split('\\s').join(' ');

        return hideCurrencySymbol
            ? formattedCurrency
            : settings.format.replace('n', formattedCurrency).replace('$', settings.currencySymbol);
    }

    public transformToFloat(value: number, settings: CurrencyFormat): number {
        if (!settings) {
            return parseFloat(value.toString());
        }

        return parseFloat(
            this.transform(value, settings).replace(settings.currencySymbol, '').replace(settings.groupSeparator, '')
        );
    }
}
