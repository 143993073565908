import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, take, withLatestFrom } from 'rxjs/operators';

import { AccountService } from '@hot-theme-nx/common-api';
import { CurrencyFormat, HotCmsImage, HotLoyaltyStatement, HotOutlet } from '@hot-theme-nx/generated-api';

import { DistributorCatalogService } from '../../../distributor/services';
import { ModalService } from '../../services';
import { HeaderNotificationsService } from '../../services/header-notification.service';
import { LoyaltyService } from '../../services/loyalty.service';
import { OrderDetailsNotificationsService } from '../../services/order-details-notifications.service';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services/features.service';

import { authUser } from '@hot-b2b/store/auth/selector';
import { cmsImages } from '@hot-b2b/store/cms-images/selector';
import { loyalty, loyaltyError } from '@hot-b2b/store/loyalty/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import {
    settingsAppOnLine,
    settingsData,
    settingsLoyaltyCurrencyFormat,
    settingsStore,
} from '@hot-b2b/store/settings/selector';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { AccountType, CmsImageType, ModalIds, StorageKeys } from '@hot-libs/shared-types';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../account/services';
import { Capacitor, Plugins } from '@capacitor/core';
import { DOCUMENT } from '@angular/common';
const { CapacitorBroadcaster } = Plugins;
import { ConfigurationService } from '../../services/configuration.service';
import { CommonConstant } from 'libs/common-api/src/lib/constants/common.constant';
import moment from 'moment';
import { nativeMobileAppDetails } from '@hot-b2b/store/nativemobileapp/selector';
import { DotMobileApp, DotPlatform, NativeAppModel } from '../../../native/services';

@Component({
    selector: 'hot-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
    @ViewChild('headerElem', { static: true }) public headerElem: ElementRef;

    @Input() public userOutlets: any;
    @Input() public userType: string;

    public readonly accountType = AccountType;

    public openMenu: boolean;
    public hideBackButton: boolean;
    public subDistributorMode: string;
    public showForCustomerOrSubDistributor: boolean;
    public showForDistributorOrSubDistributor: boolean;

    public loyaltyStatement$: Observable<HotLoyaltyStatement>;
    public loyaltyStatementError$: Observable<any>;
    public loyaltyBalanceEnabled$: Observable<boolean>;
    public settingsLoyaltyCurrencyFormat$: Observable<CurrencyFormat>;
    public authUser$: Observable<HotUserExtended>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public isOnline$: Observable<boolean>;
    public isDesktopMenuShown = false;
    public showNotification$: Observable<boolean>;
    public logoUrl: string;
    public isOutletRegistrationAvailable: boolean;
    public hotSettings$: Observable<HotSettingsExtended>;
    public displayContactSupportPhone: boolean;
    public phoneNumber: string;
    public phone: string;
    public window: any;

    isDotTutorialBtnVisible = false;
    public featureEnableDotTutorial: boolean = this.featuresService.ShowDotTutorialButton;
    public featureOrganizationLevelCreditLimitCheck = this.featuresService.OrganizationLevelCreditLimitCheck;

    // mobile menu closing animation
    public menuClosing: boolean;

    public cutOffTime = CommonConstant.CUT_OFF_TIME;

    public get isAvailableForCustomerOrDistr(): boolean {
        return this.showForCustomerOrSubDistributor || this.showForDistributorOrSubDistributor;
    }

    public isUpLg = (): boolean => {
        if (this.screenDimensionService.upLg()) {
            this.openMenu = false;
        }

        return this.screenDimensionService.upLg();
    };

    constructor(
        public readonly orderDetailsNotificationsService: OrderDetailsNotificationsService,
        public readonly headerNotificationsService: HeaderNotificationsService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly screenDimensionService: ScreenDimensionService,
        public readonly loyaltyService: LoyaltyService,
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        public readonly router: Router,
        public readonly accountService: AccountService,
        private readonly featuresService: FeaturesService,
        private readonly translateService: TranslateService,
        public readonly distributorCatalogService: DistributorCatalogService,
        private readonly userService: UserService,
        @Inject(DOCUMENT) private document: Document,
        private configServcie: ConfigurationService
    ) {
        this.isOnline$ = this.store.pipe(select(settingsAppOnLine));
        activatedRoute.url.subscribe((url: any) => {
            this.hideBackButton = url.length === 0;
        });
        this.showNotification$ = this.distributorCatalogService.showNotification;
        this.authUser$ = this.store.pipe(select(authUser));
        this.settingsStore$ = this.store.pipe(select(settingsStore));
        this.hotSettings$ = this.store.pipe(select(settingsData));
        this.window = this.document.defaultView;
    }

    public ngOnInit(): void {
        if (this.userService.publishAppOpenedMobileEvent) {
            this.userPlatform();
        }

        this.subDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);
        this.loyaltyBalanceEnabled$ = this.loyaltyService.loyaltyBalanceEnable();
        this.loyaltyStatement$ = this.store.pipe(select(loyalty));
        this.loyaltyStatementError$ = this.store.pipe(select(loyaltyError));
        this.settingsLoyaltyCurrencyFormat$ = this.store.pipe(select(settingsLoyaltyCurrencyFormat));
        this.loadLogoImage();

        if (this.featureEnableDotTutorial) {
            this.isDotTutorialBtnVisible = true;
        } else {
            this.isDotTutorialBtnVisible = false;
        }

        combineLatest([this.store.pipe(select(settingsData)), this.authUser$])
            .pipe(take(1))
            .subscribe(([settings, user]: [HotSettingsExtended, HotUserExtended]) => {
                this.userAccount(user, settings);
            });
        this.hotSettings$.subscribe((settings: HotSettingsExtended) => {
            this.displayContactSupportPhone = settings.support.displayContactSupportPhone;
            this.phoneNumber = settings.support.contactSupportPhone;
            if (!this.displayContactSupportPhone) {
                this.phone = this.translateService.instant('cart.summary-page.outlet-is-blocked');
            } else {
                this.phone =
                    this.translateService.instant('cart.summary-page.outlet-is-blocked') +
                    this.translateService.instant('cart.summary-page.outlet-is-blocked1', {
                        phoneNumber: this.phoneNumber,
                    });
            }
        });
    }
    public userPlatform() {
        let platform = Capacitor.getPlatform();
        combineLatest([this.store.pipe(select(nativeMobileAppDetails)), this.authUser$, this.hotSettings$])
            .pipe(take(1))
            .subscribe(([nativeAppDetails, data, settings]: [NativeAppModel, HotUserExtended, HotSettingsExtended]) => {
                if (data) {
                    if (
                        nativeAppDetails.mobileApp == DotMobileApp.DOT &&
                        this.configServcie.isSfmcIntegrationEnabled()
                    ) {
                        this.window?.mobile?.onOpenDotApp(
                            data?.contactId,
                            this.configServcie.getCurrentConfiguration(),
                            settings.environment == 'PROD' // isProd
                        );
                        if (platform === 'android') {
                            window.broadcaster.fireNativeEvent('com.heineken.event.app-opened', {
                                contactId: data?.contactId,
                                selectedOpco: this.configServcie.getCurrentConfiguration(),
                                isProduction: settings.environment == 'PROD', // isProd,
                            });
                        } else if (platform === 'ios') {
                            CapacitorBroadcaster.fireNativeEvent({
                                event: 'com.heineken.event.app-opened',
                                contactId: data?.contactId,
                                selectedOpco: this.configServcie.getCurrentConfiguration(),
                                isProduction: settings.environment == 'PROD', // isProd,
                            });
                            this.window?.webkit?.messageHandlers?.jsMessageHandler?.postMessage(data?.contactId);
                        }
                        this.userService.publishAppOpenedMobileEvent = false;
                    } else {
                        this.window?.mobile?.onOpen(data?.contactId);
                        if (platform === 'android') {
                            window.broadcaster.fireNativeEvent('com.heineken.event.app-opened', {
                                contactId: data?.contactId,
                            });
                        } else if (platform === 'ios') {
                            CapacitorBroadcaster.fireNativeEvent({
                                event: 'com.heineken.event.app-opened',
                                contactId: data?.contactId,
                            });
                            this.window?.webkit?.messageHandlers?.jsMessageHandler?.postMessage(data?.contactId);
                        }
                        this.userService.publishAppOpenedMobileEvent = false;
                    }
                }
            });
    }

    public userAccount(user, settings) {
        if (user) {
            if (this.subDistributorMode === null && user.userType === this.accountType.SubDistributor) {
                localStorage.setItem(StorageKeys.subDistributorMode, 'Distributor');
                this.subDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);
            }

            this.isOutletRegistrationAvailable =
                user.userType === AccountType.Customer &&
                user.contact.outlets &&
                user.contact.outlets.length > 1 &&
                user.contact.canRegisterOutlets &&
                settings.isGroupAccountOutletRegistrationEnabled;

            this.showForCustomerOrSubDistributor =
                user.userType === this.accountType.Customer ||
                (user.userType === this.accountType.SubDistributor && this.subDistributorMode === 'Customer');

            this.showForDistributorOrSubDistributor =
                user.userType === this.accountType.Distributor ||
                (user.userType === this.accountType.SubDistributor && this.subDistributorMode === 'Distributor');
        }
    }

    private checkMenu(): void {
        this.authUser$.subscribe((data: HotUserExtended) => {
            if (data) {
                this.subDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);
                this.userType = data.userType || '';
            }
        });
    }

    public toggleMenu(): void {
        this.openMenu = !this.openMenu;
    }

    showTutorialPopup() {
        this.modalService.toggleModal(ModalIds.tutorials, true);
        this.toggleMenu();
    }

    public toggleDesktopMenu(): void {
        this.isDesktopMenuShown = !this.isDesktopMenuShown;
    }

    public closeMenu(): void {
        if (!this.isUpLg()) {
            this.menuClosing = true;
            setTimeout(() => {
                this.openMenu = false;
                this.menuClosing = false;
            }, 250);
        }
    }

    public getNavAvailableWidth(): number {
        const headerWidth = this.headerElem.nativeElement.offsetWidth;
        const style = window.getComputedStyle(this.headerElem.nativeElement);
        const paddingLeft = parseInt(style.getPropertyValue('padding-left'));
        const paddingRight = parseInt(style.getPropertyValue('padding-right'));

        return headerWidth - paddingLeft - paddingRight;
    }

    public showDesktopMenu(): void {
        this.isDesktopMenuShown = true;
    }

    public showBlockInformer(): Observable<boolean> {
        return this.authUser$.pipe(
            withLatestFrom(this.settingsStore$),
            take(1),
            map(([user, storeSettings]: [HotUserExtended, SettingsStoreModel]) => {
                const outlet: HotOutlet = user?.contact?.outlet;

                let isBlocked = false;
                isBlocked = user?.contact?.outlet?.isBlocked;

                return (
                    ((this.userType === 'Customer' || this.subDistributorMode === 'Customer') &&
                        isBlocked &&
                        !outlet?.isCashOutlet &&
                        storeSettings.isOwnedByHeineken) ||
                    ((this.userType === 'Customer' || this.subDistributorMode === 'Customer') &&
                        isBlocked &&
                        this.featureOrganizationLevelCreditLimitCheck)
                );
            })
        );
    }

    public onClick(itemUrl: string, offlineDisabled?: boolean) {
        this.isOnline$.pipe(take(1)).subscribe((isOnline: boolean) => {
            if (!isOnline && offlineDisabled) {
                this.modalService.toggleModal(ModalIds.unavailableInOffline, true);
            } else {
                this.closeMenu();
                this.modalService.closeAll();
                this.router.navigateByUrl(itemUrl);
            }
        });
    }

    private loadLogoImage(): void {
        this.store
            .pipe(
                select(cmsImages),
                filter((images: HotCmsImage[]) => images.some((i) => i.type === CmsImageType.PageHeaderLogo)),
                take(1)
            )
            .subscribe((logoImage: HotCmsImage[]) => {
                this.logoUrl = logoImage.find((image: HotCmsImage) => image.type === CmsImageType.PageHeaderLogo)?.url;
            });
    }
    public showHopCutOffMessage() {
        return (
            (this.router.url === '/brands/all' ||
                this.router.url === '/checkout' ||
                this.router.url === '/cart' ||
                this.router.url === '/ordering') &&
            this.configServcie.getCurrentConfiguration() === 'HOP' &&
            this.userType === 'Customer'
        );
    }

    public checkDisplayTime() {
        let currentTime = moment();
        let currentDay = currentTime.day();
        if (currentDay >= 3 || currentDay == 5) {
            switch (currentDay) {
                case 3:
                    let startTime = moment('12:01pm', 'h:mma');
                    if (currentTime.isAfter(startTime)) {
                        return true;
                    }
                    break;
                case 5:
                    let endTime = moment('05:01pm', 'h:mma');
                    if (currentTime.isBefore(endTime)) {
                        return true;
                    }
                    break;
                default:
                    return true;
            }
        }
    }
}
