import { DOCUMENT, DatePipe } from '@angular/common';
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Address, HotFulfillmentCenter, HotOperationResult,HotCreateOrderRequest } from '@hot-theme-nx/generated-api';

import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { ContactSupport, HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData, settingsStore, settingsSupport } from '@hot-b2b/store/settings/selector';
import { DeliverySettingsModel, HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { ConfigurationService, FeaturesService,ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { DateFormatParserService } from 'apps/hot-b2b/src/app/shared/services/date-format-parser.service';
import { warehouses } from '@hot-b2b/store/warehouses/selector';
import { takeUntil } from 'rxjs/operators';
import { CalendarHelperService } from 'libs/helpers/src/lib/calendar-helper.service';
import moment, { Moment } from 'moment';
import { CartSetDeliveryDate } from '@hot-b2b/store/cart/actions';
import { cartRemarks } from '@hot-b2b/store/cart/selector';
import { ModalIds, StorageKeys } from '@hot-libs/shared-types';

const isoDateFormat = 'YYYY-MM-DD';
import { DeliveryAddress, DeliveryMethod, PickUpAddress } from '@hot-b2b/store/cart/model';
import { ScreenDimensionService } from '@hot-theme-nx/common-api';
import { orderSimulationtaxDetails } from '@hot-b2b/store/orders/selector';
import { OrderSimulation } from '@hot-b2b/store/orders/model';
import { OrderCreationService } from '../../services/order-creation.service';
import { Router } from '@angular/router';

@Component({
    selector: 'hot-delivery',
    templateUrl: './delivery.component.html',
})
export class DeliveryComponent implements AfterViewChecked, OnInit {
    @Input() public modifier: string;
    @Input() public date: string;
    @Input() public preferredDate: string;
    @Input() public isCheckout: boolean;
    @Input() public address: Address;
    @Input() public outlet: string;
    @Input() public outletName: string;
    @Input() public warehouse: string;
    @Input() public warehouseNames: string[];
    @Input() public orderNumber: string;
    @Input() public orderNumbers: string[];
    @Input() public orderNumbersAndStatus: any[];
    @Input() public settings: DeliverySettingsModel;
    @Input() public remarks: string;
    @Input() public poNumber: string;
    @Input() public receiverPhoneNumber: string;
    @Input() public summaryPage: boolean;
    @Input() public salesRepsPhoneNumber: string;
    @Input() public customerPickUpEnabled: boolean = false;
    @Input() public selectedShipmentMethod: string = '';
    @Input() public isPickupOrder: boolean = false;
    @Input() public pickUpAdress: PickUpAddress;
    @Input() public pageName: string;
    @Input() public deliveryAdress: DeliveryAddress;
    @Input() public deliveryDetails: any;
    @Input() isDayDisabledCallback?: (date: Moment) => boolean;
    @Input() selectedDate: moment.Moment;
    @Input() availableDates?: Moment[];
    @Output() public closeCalendar = new EventEmitter<boolean>();

    public openingHours: string;
    private readonly subscriptionDestroy$ = new Subject<boolean>();

    public featureShowAndCalculateExpectedDeliveryTime = this.featuresService.ShowAndCalculateExpectedDeliveryTime;
    public featureSelectDeliveryDate: boolean = this.featuresService.SelectDeliveryDate;
    public featureEstimatedDeliveryDate: boolean = this.featuresService.EstimatedDeliveryDate;
    public featureShowReceiverPhoneNumberAtCheckOutPage: boolean = this.featuresService
        .ShowReceiverPhoneNumberAtCheckOutPage;
    public featureShowSalesRepPhoneNumber = this.featuresService.ShowSalesRepPhoneNumber;
    public cartValidation: boolean = this.featuresService.ExternalCartValidation;
    public priceValidation: boolean = this.featuresService.ExternalPriceValidation;
    public showLess = false;
    public isShowMoreVisible = false;
    public settingsSupport$: Observable<ContactSupport>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public ShowDeliveryInformationFromOrderSimulationAPI: boolean;

    public authUser$: Observable<HotUserExtended>;
    public warehouses$: Observable<HotFulfillmentCenter[]>;
    public fulfillmentCenters: HotFulfillmentCenter[];
    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    public settingShowDistributorNameOnMultiSupplierSections: boolean = false;
    public isCalendar: boolean = false;
    public isPreferredDeliveryDateSet: boolean = false;

    public settings$: Observable<HotSettingsExtended>;
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';

    public cartRemarks$: Observable<HotCreateOrderRequest>;

    public isGR = () => this.configurationService.getCurrentConfiguration() === 'GR';
    public plant: any;
    public deliveryMethod: any;
    public paymentTerms: any;
    public orderSimulationTaxDetails$: Observable<HotOperationResult>;
    public details: any;
    public deliveryMethodMobile: any;
    public paymentTermsMobile: any;
    public location: any;
    public currentdate: Date;
    public showChangeLink: boolean;
    public page : any;
    public isPopPilotUser: boolean = false;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly featuresService: FeaturesService,
        private readonly dateFormatParserService: DateFormatParserService,
        private readonly store: Store<AppState>,
        private readonly cdr: ChangeDetectorRef,
        private modalService: ModalService,
        public readonly calendarHelper: CalendarHelperService,
        public readonly screenDimensionService: ScreenDimensionService,
        private readonly configurationService: ConfigurationService,
        public readonly orderCreationService: OrderCreationService,
        public datepipe: DatePipe,
        public readonly router: Router,
    ) {
        this.authUser$ = this.store.pipe(select(authUser));
        this.settingsStore$ = this.store.pipe(select(settingsStore));
        this.settingsSupport$ = this.store.pipe(select(settingsSupport));
        this.warehouses$ = this.store.pipe(select(warehouses));
        this.settings$ = this.store.pipe(select(settingsData));
        this.cartRemarks$ = this.store.pipe(select(cartRemarks));
        this.orderSimulationTaxDetails$ = this.store.pipe(select(orderSimulationtaxDetails));
        this.currentdate = new Date();
    }

    public ngOnInit(): void {
        if (this.summaryPage || this.pageName == "checkout") {
            this.authUser$.subscribe((userData: HotUserExtended) => {
                this.salesRepsPhoneNumber = userData?.contact?.outlet?.apbRepresentativePhoneNumber;
                this.isPopPilotUser = userData?.isPopPilotUser;       
            });
        }

        this.settings$.subscribe((settings: HotSettingsExtended) => {
            this.settingShowDistributorNameOnMultiSupplierSections =
                settings.showDistributorNameOnMultiSupplierSections;
        });

        if (this.settingShowDistributorNameOnMultiSupplierSections) {
            this.warehouses$
                .pipe(takeUntil(this.subscriptionsDestroy$))
                .subscribe((fulfillmentCenters: HotFulfillmentCenter[]) => {
                    this.fulfillmentCenters = fulfillmentCenters;
                });
        }
        this.loadOpeningHours();
    }

    ngOnChanges()  {
        this.page = this.router.url.includes('/retry-payment')
            if(this.page) {
                let getDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
                let currentData = this.datepipe.transform(this.currentdate, 'yyyy-MM-dd');
                this.showChangeLink = getDate < currentData ? true : false ;
            }
            else {
                this.showChangeLink = true; 
            }
    }

    public isUpLg = () => this.screenDimensionService.upLg();

    public getFormattedDateWeek(date): string {
        if (date) {
            if (this.settings.isWeekCalendar) {
                return this.dateFormatParserService.weekFormat(date);
            } else {
                return this.dateFormatParserService.dateFormat(date);
            }
        }

        return '';
    }

    toggleShowLess() {
        this.showLess = !this.showLess;
    }

    calculateCheckoutInfoStringNumber() {
        const checkoutInfo = this.document.getElementById('checkout-info');

        if (checkoutInfo && checkoutInfo.offsetHeight / 22 > 2 && !this.isShowMoreVisible) {
            this.showLess = true;
            this.isShowMoreVisible = true;
        }
    }

    resize() {
        this.showLess = false;
        this.isShowMoreVisible = false;

        this.calculateCheckoutInfoStringNumber();
    }

    public ngAfterViewChecked(): void {
        this.calculateCheckoutInfoStringNumber();
        this.cdr.detectChanges();
    }

    public locationMethod() {
        return this.plant || this.location;
    }

    public getStoreNameByFfcId(storeId: string) {
        for (const fulfillmentCenter of this.fulfillmentCenters) {
            if (fulfillmentCenter.storeId === storeId) {
                return fulfillmentCenter.storeName;
            }
        }
    }

    public showCalendar() {
        this.isCalendar = true;
    }
    public closeProfileUpdateModal(state: boolean) {
        this.isCalendar = false;
        this.modalService.toggleModal(ModalIds.advancedCalendar, state);
    }

    public closeCalender(state: boolean) {
        this.modalService.toggleModal(ModalIds.advancedCalendar, state);
    }

    public onselectCalendar(selectedDate: string) {
        this.isCalendar = false;
        this.closeCalendar.emit(this.isCalendar);
        this.date = selectedDate;
        let changedDate = moment(selectedDate, isoDateFormat);
        this.isPreferredDeliveryDateSet = true;
        this.store.dispatch(
            new CartSetDeliveryDate(this.dateFormatParserService.getFormattedDeliveryDate(changedDate))
        );
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }

    public isPickUpSelected() {
        return this.selectedShipmentMethod == DeliveryMethod.PickUp;
    }

    public isDeliverySelected() {
        return this.selectedShipmentMethod == DeliveryMethod.Delivery;
    }

    public loadOpeningHours(): any {
        const selectedSupplier = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter));
        this.openingHours = '';
        if (selectedSupplier?.operatingHours.length > 0) {
            try {
                const displayOpeningDayStr = this.getOpeningDays(selectedSupplier?.operatingHours);

                this.openingHours =
                    displayOpeningDayStr +
                    this.convertTo12HourFormat(selectedSupplier?.operatingHours[0].startTime) +
                    '-' +
                    this.convertTo12HourFormat(selectedSupplier?.operatingHours[0].endTime);
            } catch (error) {}
        }
    }

    public getOpeningDays(openingHours) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const openingDays = openingHours.map((openingHour) => openingHour.name);
        const openingDaysList = [];
        let openingDayStr = '';
        let index = 0;
        for (const day of daysOfWeek) {
            if (openingDays.includes(day) && openingDayStr == '') {
                openingDayStr = day;
            } else if (!openingDays.includes(day) && index > 0) {
                if (!openingDayStr.includes(daysOfWeek[index - 1])) {
                    openingDayStr += '-' + daysOfWeek[index - 1];
                    openingDaysList.push(openingDayStr);
                    openingDayStr = '';
                } else {
                    openingDayStr = daysOfWeek[index - 1];
                    openingDaysList.push(openingDayStr);
                    openingDayStr = '';
                }
            }

            if (index == daysOfWeek.length - 1 && openingDayStr != '' && !openingDayStr.includes(daysOfWeek[index])) {
                openingDayStr += '-' + daysOfWeek[index];
            }

            index++;
        }

        if (openingDayStr != '') {
            openingDaysList.push(openingDayStr);
        }

        return openingDaysList.join(',') + ' ';
    }

    public convertTo12HourFormat(time) {
        const [hours, minutes] = time.split(':');
        let hour = parseInt(hours, 10);
        const suffix = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12 || 12;
        return `${hour}:${minutes} ${suffix}`;
    }
}
