import { Injectable, OnDestroy } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { Subject } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { App } from '@capacitor/app';

@Injectable({
    providedIn: 'root',
})
export class ForceUpgrade implements OnDestroy {
    private appVersion: number;
    private packageName: string;
    private readonly subscriptionDestroy$ = new Subject<boolean>();
    public showForceUpgradePopup = false;
    public isKhB2b = () => this.configurationService.getCurrentConfiguration() === 'KH';
    public isVnB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';
    public isNgB2b = () => this.configurationService.getCurrentConfiguration() === 'NG';

    constructor(private readonly configurationService: ConfigurationService) {}

    async getAppVersion() {
        try {
            const versionCode = (await App.getInfo()).build;
            const appId = (await App.getInfo()).id;
            this.appVersion = Number(versionCode);
            this.packageName = appId;

            try {
                localStorage.setItem('app_id', this.packageName);
                localStorage.setItem('app_versioln', versionCode);
            } catch (err) {
                console.error('Error occured');
            }
        } catch (error) {
            console.log('error occured', error);
            this.appVersion = undefined;
            return;
        }
    }

    public forceUpgradeApp(settings: HotSettingsExtended): boolean {
        if (this.appVersion != undefined) {
            switch (this.packageName) {
                case 'com.tinhtos.dotb2b':
                case 'com.heineken.tinhtos':
                    {
                        this.tinhtosForceUpgradeCheck(settings);
                    }
                    break;
                case 'com.quickdrinks.dotb2b':
                case 'com.heineken.quickdrinks':
                    {
                        this.quickDrinksForceUpgradeCheck(settings);
                    }
                    break;

                case 'com.hvbb.hvndathang2':
                    {
                        this.vnForceUpgradeCheck(settings);
                    }
                    break;
                case 'com.heineken.dotb2b':
                case 'com.heineken.b2bdot':
                    {
                        if (!(this.isKhB2b() || this.isVnB2b() || this.isNgB2b())) this.dotForceUpgradeCheck(settings);
                    }
                    break;
            }
        } else {
            this.showForceUpgradePopup = false;
        }

        return this.showForceUpgradePopup;
    }

    tinhtosForceUpgradeCheck(settings: HotSettingsExtended) {
        if (Capacitor.getPlatform() === 'android') {
            this.androidTinhtosForceUpgradeCheck(settings);
        } else if (Capacitor.getPlatform() === 'ios') {
            this.iOSTinhtosForceUpgradeCheck(settings);
        }
    }

    androidTinhtosForceUpgradeCheck(settings: HotSettingsExtended): boolean {
        switch (settings.environment) {
            case 'QA':
            case 'ACC':
            case 'DEV': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.tihntos['devAndroidMinimumVersion'];
                return this.showForceUpgradePopup;
            }

            case 'PROD': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.tihntos['prodAndroidMinimumVersion'];
                return this.showForceUpgradePopup;
            }
        }
    }

    iOSTinhtosForceUpgradeCheck(settings: HotSettingsExtended): boolean {
        switch (settings.environment) {
            case 'QA':
            case 'ACC':
            case 'DEV': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.tihntos['devIosMinimumVersion'];
                return this.showForceUpgradePopup;
            }

            case 'PROD': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.tihntos['prodIosMinimumVersion'];
                return this.showForceUpgradePopup;
            }
        }
    }
    quickDrinksForceUpgradeCheck(settings: HotSettingsExtended) {
        if (Capacitor.getPlatform() === 'android') {
            this.androidQuickDrinksForceUpgradeCheck(settings);
        } else if (Capacitor.getPlatform() === 'ios') {
            this.iOSQuickDrinksForceUpgradeCheck(settings);
        }
    }

    androidQuickDrinksForceUpgradeCheck(settings: HotSettingsExtended): boolean {
        switch (settings.environment) {
            case 'QA':
            case 'DEV':
            case 'ACC': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.quickdrinks['devAndroidMinimumVersion'];
                return this.showForceUpgradePopup;
            }

            case 'PROD': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.quickdrinks['prodAndroidMinimumVersion'];
                return this.showForceUpgradePopup;
            }
        }
    }

    iOSQuickDrinksForceUpgradeCheck(settings: HotSettingsExtended): boolean {
        switch (settings.environment) {
            case 'QA':
            case 'DEV':
            case 'ACC': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.quickdrinks['devIosMinimumVersion'];
                return this.showForceUpgradePopup;
            }
            case 'PROD': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.quickdrinks['prodIosMinimumVersion'];
                return this.showForceUpgradePopup;
            }
        }
    }

    vnForceUpgradeCheck(settings: HotSettingsExtended) {
        if (Capacitor.getPlatform() === 'android') {
            this.androidVNForceUpgradeCheck(settings);
        } else if (Capacitor.getPlatform() === 'ios') {
            this.iOSVnForceUpgradeCheck(settings);
        }
    }

    androidVNForceUpgradeCheck(settings: HotSettingsExtended): boolean {
        switch (settings.environment) {
            case 'QA':
            case 'DEV':
            case 'ACC': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.hvnDatHang['devAndroidMinimumVersion'];
                return this.showForceUpgradePopup;
            }

            case 'PROD': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.hvnDatHang['prodAndroidMinimumVersion'];
                return this.showForceUpgradePopup;
            }
        }
    }

    iOSVnForceUpgradeCheck(settings: HotSettingsExtended): boolean {
        switch (settings.environment) {
            case 'QA':
            case 'DEV':
            case 'ACC': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.hvnDatHang['devIosMinimumVersion'];
                return this.showForceUpgradePopup;
            }

            case 'PROD': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.hvnDatHang['prodIosMinimumVersion'];
                return this.showForceUpgradePopup;
            }
        }
    }
    dotForceUpgradeCheck(settings: HotSettingsExtended) {
        if (Capacitor.getPlatform() === 'android') {
            this.androidDotForceUpgradeCheck(settings);
        } else if (Capacitor.getPlatform() === 'ios') {
            this.iOSDotForceUpgradeCheck(settings);
        }
    }

    androidDotForceUpgradeCheck(settings: HotSettingsExtended): boolean {
        switch (settings.environment) {
            case 'QA':
            case 'DEV':
            case 'ACC': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.dot['devAndroidMinimumVersion'];
                return this.showForceUpgradePopup;
            }

            case 'PROD': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.dot['prodAndroidMinimumVersion'];
                return this.showForceUpgradePopup;
            }
        }
    }

    iOSDotForceUpgradeCheck(settings: HotSettingsExtended): boolean {
        switch (settings.environment) {
            case 'QA':
            case 'DEV':
            case 'ACC': {
                this.showForceUpgradePopup = this.appVersion < settings.forAppUpgradeFields.dot['devIosMinimumVersion'];
                return this.showForceUpgradePopup;
            }

            case 'PROD': {
                this.showForceUpgradePopup =
                    this.appVersion < settings.forAppUpgradeFields.dot['prodIosMinimumVersion'];
                return this.showForceUpgradePopup;
            }
        }
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }
}
