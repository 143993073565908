import { InsightsState } from '@hot-b2b/store/insights/model';
import { insightsInitialState } from '@hot-b2b/store/insights/state';
import { InsightsActions } from '@hot-b2b/store/insights/actions';
import { EInsightsActions } from '@hot-b2b/store/insights/types';
import { editQuantity } from '@hot-b2b/store/products/helpers';

export function insightsReducer(state: InsightsState = insightsInitialState, action: InsightsActions): InsightsState {
    switch (action.type) {
        case EInsightsActions.INSIGHTS_PENDING:
        case EInsightsActions.INSIGHTS_PENDING_FROM_CENTER: {
            return {
                ...state,
                fetched: false,
                pending: true,
                error: null,
            };
        }
        case EInsightsActions.INSIGHTS_FETCHED: {
            return {
                ...state,
                fetched: true,
                pending: false,
                data: {
                    ...state.data,
                    slots: action.payload,
                },
            };
        }
        case EInsightsActions.INSIGHTS_FETCHED_FROM_CENTER: {
            let newSlots = state.data.slots;

            newSlots.forEach((element, index) => {
                if (element.id === action.payload[0]['id']) newSlots.splice(index, 1);
            });
            newSlots.push(...action.payload);

            return {
                ...state,
                fetched: true,
                pending: false,
                data: {
                    ...state.data,
                    slots: newSlots,
                },
            };
        }
        case EInsightsActions.INSIGHTS_ERROR: {
            return {
                ...state,
                fetched: false,
                pending: false,
                error: action.payload,
            };
        }
        case EInsightsActions.INSIGHTS_GET_PRODUCTS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    products: action.payload,
                },
            };
        }
        case EInsightsActions.INSIGHTS_SYNCRONIZED_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    products: editQuantity(state.data.products, action.payload),
                },
            };
        }
        default:
            return state;
    }
}
