import { Component } from '@angular/core';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { Observable } from 'rxjs';
import { AppState } from '@hot-b2b/store/reducers';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'hot-otp-technical-issue-modal',
    templateUrl: './otp-technical-issue-modal.component.html',
})
export class OtpTechnicalIssueModalComponent {
    public hotSettings$: Observable<HotSettingsExtended>;
    public displayContactSupportPhone: boolean;
    public phoneNumber: string;
    public phone: string;

    constructor(private readonly store: Store<AppState>, private readonly translateService: TranslateService) {
        this.hotSettings$ = this.store.pipe(select(settingsData));
    }
    public ngOnInit(): void {
        this.hotSettings$.subscribe((settings: HotSettingsExtended) => {
            this.displayContactSupportPhone = settings.support.displayContactSupportPhone;
            this.phoneNumber = settings.support.contactSupportPhone;
            if (!this.displayContactSupportPhone) {
                this.phoneNumber = '';
                this.phone = this.translateService.instant('shared.modals.otp-technical-issue.text', {
                    phoneNumber: this.phoneNumber,
                });
            } else {
                this.phone = this.translateService.instant('shared.modals.otp-technical-issue.text', {
                    phoneNumber: this.phoneNumber,
                });
            }
        });
    }

    public close(): void {
        window.location.reload();
    }
}
