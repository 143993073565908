import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'thumb' })
export class ThumbPipe implements PipeTransform {
    public transform(value: string, param: string): string {
        if (!value) {
            return 'assets/static/images/product-placeholder.svg';
        }  
        if(value.includes('_externalImageUrl')){
            value=value.replace('_externalImageUrl','');
            return value;
        }      
        const extension = value.split('.').pop();
        const valueWithoutExtension = value.replace(`.${extension}`, '');
        return `${valueWithoutExtension}_thumb_${param}.${extension}`;
    }
}
