import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { HotApiCmsService } from '@hot-theme-nx/generated-api';

import { CmsImagesGet, CmsImagesGetSuccess } from './actions';
import { ECmsImagesActions } from './types';
import { HotCmsImage } from 'libs/generated-api/src/lib/models/hot-cms-image';

@Injectable()
export class CmsImagesEffects {
    constructor(private readonly _actions$: Actions, private readonly apiCmsService: HotApiCmsService) {}

    public CmsImagesGet$: Observable<CmsImagesGetSuccess> = createEffect(() => this._actions$.pipe(
        ofType<CmsImagesGet>(ECmsImagesActions.CMS_IMAGES_PENDING),
        switchMap((data: CmsImagesGet) =>
            this.apiCmsService
                .getImagesByTypes(data.payload)
                .pipe(map((response: HotCmsImage[]) => new CmsImagesGetSuccess(response)))
        )
    ));
}
