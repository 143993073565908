import { notificationsInitialState } from '@hot-b2b/store/notifications/state';
import { NotificationsState } from '@hot-b2b/store/notifications/model';
import { NotificationAction } from '@hot-b2b/store/notifications/actions';
import { ENotificationSActions } from '@hot-b2b/store/notifications/types';
import { sortNotifications } from '@hot-b2b/store/notifications/helpers';

export function notificationsReducer(
    state: NotificationsState = notificationsInitialState,
    action: NotificationAction
): NotificationsState {
    switch (action.type) {
        case ENotificationSActions.NOTIFICATIONS_PENDING: {
            return {
                ...state,
                fetched: false,
                pending: true,
                error: null,
            };
        }
        case ENotificationSActions.NOTIFICATIONS_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    notifications: sortNotifications(action.payload),
                },
            };
        }
        case ENotificationSActions.NOTIFICATIONS_SET_POPUP_SHOWN: {
            return {
                ...state,
                pending: false,
                fetched: true,
                lastNotificationPopupShown: action.payload,
            };
        }
        case ENotificationSActions.NOTIFICATIONS_SET_LAST_READ: {
            return {
                ...state,
                pending: false,
                fetched: true,
                lastNotificationRead: action.payload,
            };
        }
        default:
            return state;
    }
}
