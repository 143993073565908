import { createAction, props } from '@ngrx/store';

import { HotOrder, HotProduct, HotPromotion } from '@hot-theme-nx/generated-api';

import { ICartLineItem, Product } from '../../models';

export enum AnalyticActionsType {
    ProductDetailView = '[Analytics] Product detail view',
    PromotionDetailView = '[Analytics] Promotion detail view',
    AddProductToCart = '[Analytics] Add product to cart',
    RemoveProductFromCart = '[Analytics] Remove product to cart',
    Purchase = '[Analytics] Purchase',
    Checkout = '[Analytics] Checkout',
}

export const ProductDetailViewEvent = createAction(
    AnalyticActionsType.ProductDetailView,
    props<{ data: HotProduct; location: string }>()
);

export const PromotionDetailViewEvent = createAction(
    AnalyticActionsType.PromotionDetailView,
    props<{ data: HotPromotion; location: string }>()
);

export const AddProductToCartEvent = createAction(
    AnalyticActionsType.AddProductToCart,
    props<{ data: HotProduct[] | Product[]; location: string }>()
);

export const RemoveProductFromCartEvent = createAction(
    AnalyticActionsType.RemoveProductFromCart,
    props<{ data: HotProduct[] | Product[]; location: string }>()
);

export const CheckoutEvent = createAction(
    AnalyticActionsType.Checkout,
    props<{ data: ICartLineItem[]; location: string }>()
);

export const PurchaseEvent = createAction(
    AnalyticActionsType.Purchase,
    props<{ data: HotOrder[]; location?: string }>()
);
