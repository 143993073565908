import { Injectable } from '@angular/core';
import { authUser } from '@hot-b2b/store/auth/selector';
import { cartData } from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsStore } from '@hot-b2b/store/settings/selector';
import { HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { CouponSearchCriteriaHot, HotApiMarketingService, HotCouponSearchResult } from '@hot-theme-nx/generated-api';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { HotCartExtended } from '../../shared/models';

@Injectable({
    providedIn: 'root',
})
export class CouponService {
    private readonly settingsStore$: Observable<SettingsStoreModel>;
    public authUser$: Observable<HotUserExtended>;
    public cartData$: Observable<HotCartExtended>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly hotApiMarketingService: HotApiMarketingService
    ) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.authUser$ = this.store.pipe(select(authUser));
        this.cartData$ = this.store.pipe(select(cartData));
    }

    public getCoupons(couponsParams: CouponSearchCriteriaHot): Observable<HotCouponSearchResult> {
        return this.hotApiMarketingService.searchApplicableCoupons(couponsParams).pipe(
            map((hotCoupons: HotCouponSearchResult) => {
                return hotCoupons;
            })
        );
    }
}
