import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { HotOrderLineItemExtended, SettingsStoreModel } from '@hot-libs/shared-models';

import { AppState } from '@hot-b2b/store/reducers';
import { settingsStore } from '@hot-b2b/store/settings/selector';

@Injectable({
    providedIn: 'root',
})
export class LineItemsCountService {
    public count$: BehaviorSubject<number> = new BehaviorSubject(0);

    private readonly settingsStore$: Observable<SettingsStoreModel>;

    constructor(private readonly store: Store<AppState>) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
    }

    public getItemsCount(items: Observable<any[]>): Observable<number> {
        return combineLatest([items, this.settingsStore$]).pipe(
            filter((result) => !!result[1]),
            map((results: [any[], SettingsStoreModel]) => {
                const lineItems = results[0];
                let result = lineItems.length || 0;

                if (results[1].showLineItemsQuantitySum) {
                    result = lineItems.reduce((sum: number, item: HotOrderLineItemExtended) => sum + item.quantity, 0);
                }

                this.count$.next(result);

                return result;
            })
        );
    }
}
