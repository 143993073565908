import { Action } from '@ngrx/store';
import { HotFulfillmentCenter } from '@hot-theme-nx/generated-api';
import { EWarehousesActions } from '@hot-b2b/store/warehouses/types';

export class WarehousesGet implements Action {
    public readonly type = EWarehousesActions.WAREHOUSES_PENDING;
}

export class WarehouseReward implements Action {
    public readonly type = EWarehousesActions.WAREHOUSES_REWARDS_PENDING;
}

export class WarehousesGetSuccess implements Action {
    public readonly type = EWarehousesActions.WAREHOUSES_FETCHED;
    constructor(public payload: HotFulfillmentCenter[]) {}
}

export class WarehousesRewardsGetSuccess implements Action {
    public readonly type = EWarehousesActions.WAREHOUSES_REWARDS_FETCHED;
    constructor(public payload: HotFulfillmentCenter[]) {}
}

export class WarehousesError implements Action {
    public readonly type = EWarehousesActions.WAREHOUSES_ERROR;
    constructor(public payload: string | boolean) {}
}

export type WarehousesActions = WarehousesGet| WarehouseReward | WarehousesGetSuccess | WarehousesRewardsGetSuccess | WarehousesError;
