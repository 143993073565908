import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, retry, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { HotApiCartsService, HotApiCatalogService, HotProductSearchResult } from '@hot-theme-nx/generated-api';

import { CartGetSuccess, CartOfflineSynchronized } from '@hot-b2b/store/cart/actions';
import { cartData, cartItems } from '@hot-b2b/store/cart/selector';
import {
    CatalogChangeQuantity,
    CatalogItemChangeSubscription,
    CatalogCategoriesSearch,
    CatalogCategoriesSearchSuccess,
    CatalogItemChangeSubscriptionSuccess,
    CatalogProductsReload,
    CatalogProductsReloadSuccess,
    CatalogProductsSearch,
    CatalogProductsSearchSuccess,
    CatalogSynchronized,
    CatalogSynchronizedSuccess,
    CatalogSynchronizedSuccessWithStoreId,
    EmptiesCatalogGet,
    EmptiesCatalogGetSuccess,
    EmptiesCatalogSynchronized,
    EmptiesCatalogSynchronizedSuccess,
    OfflineCatalogProductsSearch,
    OfflineCatalogProductsSearchSuccess,
    OfflineCatalogGet,
    OfflineCatalogDataGetSuccess,
    CartDataGet,
    OfflineCatalogGetFromCenter,
    OfflineCatalogGetFromCenterSuccess,
} from '@hot-b2b/store/catalog/actions';
import { ECatalogActions } from '@hot-b2b/store/catalog/types';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsFFCId } from '@hot-b2b/store/settings/selector';
import { isApplicationOnline } from '@hot-libs/helpers';
import { FeatureNames, TelemetryEventType } from '@hot-libs/shared-types';
import { AuthenticationService } from 'apps/hot-b2b/src/app/account/services';
import { CartService } from 'apps/hot-b2b/src/app/cart/services';
import { HotCatalogExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { CatalogService } from 'apps/hot-b2b/src/app/catalog/services';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { ApplicationInsightsService, FeaturesService } from 'apps/hot-b2b/src/app/shared/services';

@Injectable()
export class CatalogEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly _store: Store<AppState>,
        private readonly cartService: CartService,
        private readonly catalogService: CatalogService,
        private readonly hotApiCartsService: HotApiCartsService,
        private readonly hotApiCatalogService: HotApiCatalogService,
        private readonly featuresService: FeaturesService,
        private readonly authenticationService: AuthenticationService,
        private readonly appInsightsService: ApplicationInsightsService
    ) {}

    public getEmtpiesCatalog$: Observable<EmptiesCatalogGetSuccess | EmptiesCatalogSynchronized> = createEffect(() => this._actions$.pipe(
        ofType<EmptiesCatalogGet>(ECatalogActions.EMPTIES_CATALOG_PENDING),
        withLatestFrom(this.featuresService.checkFeatures(FeatureNames.StoreIsRequiredForOutlet)),
        switchMap((data: [EmptiesCatalogGet, boolean]) =>
            data[1]
                ? this.catalogService.getCatalog(null, true).pipe(
                      catchError((err: any) => {
                          if (err.status === 401) {
                              this.authenticationService.logout();
                          }
                          return throwError(err);
                      }),
                      retry(),
                      shareReplay(),
                      map((items: HotCatalogExtended) => [items])
                  )
                : this.catalogService.getCatalogsFromCenters(true)
        ),
        withLatestFrom(this._store.pipe(select(cartItems)), this._store.pipe(select(cartData))),
        switchMap((data: [HotCatalogExtended[], HotCartLineItemExtended[], HotCartExtended]) => [
            new EmptiesCatalogGetSuccess({
                data: data[0],
                cart: data[1],
            }),
            new EmptiesCatalogSynchronized(data[2]),
        ])
    ));

    public searchProducts$: Observable<CatalogProductsSearchSuccess | CatalogSynchronized> = createEffect(() => this._actions$.pipe(
        ofType<CatalogProductsSearch>(ECatalogActions.CATALOG_PRODUCTS_SEARCH),
        switchMap((data: CatalogProductsSearch) =>
            forkJoin([this.hotApiCatalogService.searchProductsV2(data.payload.criteria), of(data.payload.ffcId)])
        ),
        withLatestFrom(this._store.pipe(select(cartItems)), this._store.pipe(select(cartData))),
        switchMap((data: [[HotProductSearchResult, string], HotCartLineItemExtended[], HotCartExtended]) => [
            new CatalogProductsSearchSuccess({
                data: data[0][0],
                ffcId: data[0][1],
                cartItems: data[1],
            }),
            new CatalogSynchronized(data[2]),
        ])
    ));

    public reloadProductsCatalog$: Observable<CatalogProductsReloadSuccess | CatalogSynchronized> = createEffect(() => this._actions$.pipe(
        ofType<CatalogProductsReload>(ECatalogActions.CATALOG_PRODUCTS_RELOAD),
        switchMap((data: CatalogProductsReload) =>
            forkJoin([this.hotApiCatalogService.searchProductsV2(data.payload.criteria), of(data.payload.ffcId)])
        ),
        withLatestFrom(this._store.pipe(select(cartItems)), this._store.pipe(select(cartData))),
        switchMap((data: [[HotProductSearchResult, string], HotCartLineItemExtended[], HotCartExtended]) => [
            new CatalogProductsReloadSuccess({
                data: data[0][0],
                ffcId: data[0][1],
                cartItems: data[1],
            }),
        ])
    ));

    public searchOfflineProducts$: Observable<
        OfflineCatalogProductsSearchSuccess | CatalogSynchronized
    > = createEffect(() => this._actions$.pipe(
        ofType<OfflineCatalogProductsSearch>(ECatalogActions.OFFLINE_CATALOG_PRODUCTS_SEARCH),
        withLatestFrom(this._store.pipe(select(cartItems)), this._store.pipe(select(cartData))),
        switchMap((data: [OfflineCatalogProductsSearch, HotCartLineItemExtended[], HotCartExtended]) => [
            new OfflineCatalogProductsSearchSuccess({
                searchCriteria: data[0].payload.criteria,
                ffcId: data[0].payload.ffcId,
                cartItems: data[1],
                loadOfflineDataForOnline: data[0].payload.loadOfflineDataForOnline,
            }),
            new CatalogSynchronized(data[2]),
        ])
    ));

    public synchronizedCatalog$: Observable<
        CatalogSynchronizedSuccess | CatalogSynchronizedSuccessWithStoreId
    > = createEffect(() => this._actions$.pipe(
        ofType<CatalogSynchronized>(ECatalogActions.CATALOG_SYNCHRONIZED),
        withLatestFrom(
            this._store.select(cartItems),
            this._store.select(settingsFFCId),
            this.featuresService.checkFeatures(FeatureNames.SplitOrdersBySuppliersWhenCheckout)
        ),
        map(
            ([_action, itemData, ffc, featureSplitOrdersBySuppliersWhenCheckout]: [
                CatalogSynchronized,
                HotCartLineItemExtended[],
                string,
                boolean
            ]) => {
                return featureSplitOrdersBySuppliersWhenCheckout
                ? new CatalogSynchronizedSuccessWithStoreId({ ffc, cartItems: itemData })
                : new CatalogSynchronizedSuccess({ ffc, cartItems: itemData })
            }
        )
    ));

    public synchronizedEmptiesCatalog$: Observable<EmptiesCatalogSynchronizedSuccess> = createEffect(() => this._actions$.pipe(
        ofType<EmptiesCatalogSynchronized>(ECatalogActions.EMPTIES_CATALOG_SYNCHRONIZED),
        withLatestFrom(this._store.select(cartItems), this._store.select(settingsFFCId)),
        map(
            ([_action, items, ffc]: [EmptiesCatalogSynchronized, HotCartLineItemExtended[], string]) =>
                new EmptiesCatalogSynchronizedSuccess({ ffc, cartItems : items })
        )
    ));

    public catalogChangeQuantity$: Observable<CatalogSynchronized> = createEffect(() => this._actions$.pipe(
        ofType<CatalogChangeQuantity>(ECatalogActions.CATALOG_ITEM_CHANGE_QUANTITY),
        mergeMap((action: CatalogChangeQuantity) =>
            this.hotApiCartsService
                .updateLineItem(action.payload.productId)
                .pipe(map((response: HotCartExtended) => new CatalogSynchronized(response)))
        )
    ));

    public offlineCatalogGet$ = createEffect(() => this._actions$.pipe(
        ofType<OfflineCatalogGet>(ECatalogActions.OFFLINE_CATALOG_PENDING),
        withLatestFrom(this.featuresService.checkFeatures(FeatureNames.StoreIsRequiredForOutlet)),
        tap(() => {
            this.appInsightsService.startTrackingEvent(TelemetryEventType.CatalogLoadingTime);
        }),
        switchMap((data: [OfflineCatalogGet, boolean]) =>
            forkJoin([
                data[1]
                    ? this.catalogService.getOfflineCatalog().pipe(map((items: HotCatalogExtended) => [items]))
                    : this.catalogService.getOfflineCatalogsFromCenters(),
            ])
        ),
        withLatestFrom(this._store.select(settingsFFCId)),
        tap(() => {
            this.appInsightsService.endTrackingEvent(TelemetryEventType.CatalogLoadingTime);
        }),
        switchMap((data: [[HotCatalogExtended[]], string]) => {
            const catalog: HotCatalogExtended[] = data[0][0];
            return [
                new OfflineCatalogDataGetSuccess({
                    data: catalog,
                }),
                new CatalogCategoriesSearchSuccess({ ffcId: data[1], categories: catalog[0].categories }),
                new CatalogSynchronized(),
                // TO DO: Here can be a problem
                new EmptiesCatalogSynchronized(),
            ];
        })
    ));

    public offlineCatalogGetFromCenter$ = createEffect(() => this._actions$.pipe(
        ofType<OfflineCatalogGetFromCenter>(ECatalogActions.OFFLINE_CATALOG_PENDING_FROM_CENTER),
        withLatestFrom(this.featuresService.checkFeatures(FeatureNames.StoreIsRequiredForOutlet)),
        tap(() => {
            this.appInsightsService.startTrackingEvent(TelemetryEventType.CatalogLoadingTime);
        }),
        switchMap((data: [OfflineCatalogGetFromCenter, boolean]) =>
            forkJoin([
                data[1]
                    ? this.catalogService.getOfflineCatalog().pipe(map((items: HotCatalogExtended) => [items]))
                    : this.catalogService
                          .getOfflineCatalog(data[0].payload)
                          .pipe(map((items: HotCatalogExtended) => [{ ...items, id: data[0].payload }])),
            ])
        ),
        withLatestFrom(this._store.select(settingsFFCId)),
        tap(() => {
            this.appInsightsService.endTrackingEvent(TelemetryEventType.CatalogLoadingTime);
        }),
        switchMap((data: [[HotCatalogExtended[]], string]) => {
            const catalog: HotCatalogExtended[] = data[0][0];
            return [
                new OfflineCatalogGetFromCenterSuccess({
                    data: catalog,
                }),
                new CatalogCategoriesSearchSuccess({ ffcId: data[1], categories: catalog[0].categories }),
                new CatalogSynchronized(),
                // TO DO: Here can be a problem
                new EmptiesCatalogSynchronized(),
            ];
        })
    ));

    public CartDataGet$ = createEffect(() => this._actions$.pipe(
        ofType<CartDataGet>(ECatalogActions.CART_DATA_GET_PENDING),
        withLatestFrom(this.featuresService.checkFeatures(FeatureNames.StoreIsRequiredForOutlet)),
        switchMap((_data: [CartDataGet, boolean]) => forkJoin([this.cartService.getCart()])),
        withLatestFrom(this._store.select(cartData)),
        switchMap((data: [[HotCartExtended], HotCartExtended]) => {
            const cart: HotCartExtended = data[0][0] ? data[0][0] : data[1];
            return [isApplicationOnline() ? new CartGetSuccess(cart) : new CartOfflineSynchronized()];
        })
    ));

    public itemChangeSubscription$: Observable<CatalogItemChangeSubscriptionSuccess> = createEffect(() => this._actions$.pipe(
        ofType<CatalogItemChangeSubscription>(ECatalogActions.CATALOG_ITEM_CHANGE_SUBSCRIPTION),
        withLatestFrom(this._store.select(settingsFFCId)),
        map(
            ([action, ffc]: [CatalogItemChangeSubscription, string]) =>
                new CatalogItemChangeSubscriptionSuccess({ ffcId: ffc, itemId: action.payload })
        )
    ));

    public categoriesSearch$: Observable<CatalogCategoriesSearchSuccess> = createEffect(() => this._actions$.pipe(
        ofType<CatalogCategoriesSearch>(ECatalogActions.CATALOG_CATEGORIES_SEARCH),
        switchMap((action: CatalogCategoriesSearch) =>
            forkJoin([this.hotApiCatalogService.searchProductsV2(action.payload.criteria), of(action.payload.ffcId)])
        ),
        map(
            ([searchResult, ffcId]: [HotProductSearchResult, string]) =>
                new CatalogCategoriesSearchSuccess({ ffcId, categories: searchResult.categories })
        )
    ));
}
