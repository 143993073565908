import { MemoizedSelector, createSelector } from '@ngrx/store';

import { HotTutorial, HotTutorialStep } from '@hot-theme-nx/generated-api';

import { AppState } from '@hot-b2b/store/reducers';
import { TutorialsState } from '@hot-b2b/store/tutorials/model';

const stateTutorials = (state: AppState): TutorialsState => state.tutorials;

export const tutorialsPending: MemoizedSelector<AppState, boolean> = createSelector(
    stateTutorials,
    (state: TutorialsState) => state.pending
);

export const tutorialsFetched: MemoizedSelector<AppState, boolean> = createSelector(
    stateTutorials,
    (state: TutorialsState) => state.fetched
);

export const tutorialsStarted: MemoizedSelector<AppState, boolean> = createSelector(
    stateTutorials,
    (state: TutorialsState) => state.data.isStarted
);

export const tutorialsData: MemoizedSelector<AppState, HotTutorial[]> = createSelector(
    stateTutorials,
    (state: TutorialsState) => state.data.tutorials
);

export const tutorialsCurrent: MemoizedSelector<AppState, HotTutorial> = createSelector(
    stateTutorials,
    (state: TutorialsState) => state.data.currentTutorial
);

export const tutorialsCurrentStep: MemoizedSelector<AppState, HotTutorialStep> = createSelector(
    stateTutorials,
    (state: TutorialsState) => state.data.currentStep
);

export const tutorialsSelectedId: MemoizedSelector<AppState, string> = createSelector(
    stateTutorials,
    (state: TutorialsState) => {
        if (state.data.showTutorialWithId == undefined) return '';
        return state.data.showTutorialWithId;
    }
);
