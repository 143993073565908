export enum StorageKeys {
    appMode = 'mode',
    authToken = 'auth_token',
    authTokenExpire = 'auth_token_expire',
    bearerToken = 'bearer_token',
    bearerTokenExpirationDate = 'bearer_token_expiration_date',
    currentLanguage = 'current-language',
    currentOutletSemId = 'current_outlet_sem_id',
    distributorOrderItems = 'distributor-order-items',
    fulfillmentCenter = 'fulfillment_center',
    lastAction = 'lastAction',
    lastUsedPaymentMethod = 'last_used_payment_method',
    location = 'location',
    offlineCart = 'offline_cart',
    offlineOrderTemplates = 'offline_order_templates',
    productsBuffer = 'products_buffer',
    pwaReload = 'pwa-reload',
    redirectUrlFfc = 'ffc_select_redirect_url',
    redirectUrlRewardsFfc = 'ffc_rewards_select_redirect_url',
    rewardsFulfillmentCenter = 'fulfillment_center_rewards',
    refreshToken = 'refresh_token',
    rememberMe = 'rememberMe',
    changeLanguage = 'change_language',
    subDistributorMode = 'subdistributor_mode',
    suggestedInsights = 'hot-suggested-insights',
    tabTimestamp = 'hot-tab-timestamp',
    tutorials = 'hot-tutorials',
    userType = 'user_type',
    vanSalesManOrderItems = 'van-sales-man-order-items',
    watchedArbitraryNotificationIds = 'watchedArbitraryNotificationIds',
    activeRoute = 'activeRoute',
    selectedTemplate = 'selectedTemplate',
    catalogView = 'hot-catalog-view',
    orderSuggestions = 'order-suggestions',
    msalAuthEnabled = 'msal-auth-enabled',
    msalAuthEnabledForB2C = 'msal-auth-enabled-for-b2c',
    impersonateUserId = 'impersonate-user-id',
    msalToken = 'msal.idtoken',
    offlineOrderQueue = 'offline_order_queue',
    userName = 'username',
    password = 'password',
    popUpclose = 'popup_closed',
    offlineFailedOrderQueue = 'offline_failed_order_queue',
    browserCacheClearTime = 'browser_cache_clear_time',
    skipWarehouseSelectionPage = 'skip_warehouse_selection_page',
    reInitializeFirstTimeAPICall = 're_initialize_first_time_api_call',
    acceptDisclaimer = 'accept_disclaimer',
    fcmToken = 'fcm_token',
    userId = 'userId',
    pricelistUploadingJobId = 'pricelist_uploading_job_id',
    pricelistUploadingEndDateTime = 'pricelist_uploading_end_datetime',
    pricelistDownloadingJobId = 'pricelist_downloading_job_id',
    pricelistDownloadingEndDateTime = 'pricelist_downloading_end_datetime',
    cancelorderValue = 'cancelorderValue',
    cancelorderValueReasonPopup = 'cancelorderValueReasonPopup',
    bannerclose = 'banner_closed',
    outletExternalId = 'company_id',
    promotionDetails = 'promotion_details',
    promotionMechanics = 'promotion-mechanics',
    customerId = 'pop-customer-id',
    cardToken = 'card-token',
    cardSelect = 'card_select',
    captureBannerClose = 'capturebanner_closed',
    popProjectId = 'project_id',
    APMGateWayCode = 'apm-gate-way-code'
}
