import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'hot-switch',
    templateUrl: './switch.component.html',
})
export class HotSwitchComponent {
    @Input() value: boolean = false;
    @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public onChange(event: Event) {
        this.value = !this.value;
        this.valueChanged.emit(this.value);

        event.preventDefault();
    }
}
