import { CartState } from '@hot-b2b/store/cart/model';

export const cartInitialState: CartState = {
    pending: false,
    fetched: false,
    data: {
        cart: {
            id: '',
            outletId: '',
            outletOuterId: '',
            outletName: '',
            customerId: '',
            customerName: '',
            type: '',
            name: '',
            recentlyAddedItemId: '',
            isValid: true,
            extendedPriceTotal: 0,
            subTotal: 0,
            promotionalDiscountAmount: 0,
            couponDiscountAmount: 0,
            discountAmount: 0,
            discountTotal: 0,
            emptiesDepositTotal: 0,
            taxType: '',
            taxPercentRate: null,
            taxTotal: 0,
            total: 0,
            totalVolume: null,
            estimatedDeliveryDate: {
                value: '',
                formattedValue: '',
            },
            paymentMethod: null,
            purchaseOrderNumber: '',
            recommendedProducts: [],
            comment: '',
            coupons: [],
            itemsCount: 0,
            itemsQuantity: 0,
            itemsVolume: 0,
            items: [],
            validationErrors: [
                {
                    errorCode: '',
                },
            ],
            storeId: '',
        },
        remarks: {
            cartValidationMessages: [],
            comment: '',
            splitAlcoholicNonAlcoholicProducts: false,
        },
        changesBuffer: {
            processing: [],
            pending: [],
            changedItems: [],
        },
    },
    shipmentMethod: {
        validateDeliveryMethod: false,
    },
    isProductRecommederIsLoading: false,
    payment: {
        rediretURL: '',
    },
    error: null,
    errorCodes: null,
};
