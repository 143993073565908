import { Component, Input, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import { SdipStatisticsByGroup } from '@hot-theme-nx/generated-api';

@Component({
    selector: 'hot-perf-volumes',
    templateUrl: './perf-volumes.component.html',
})
export class PerfVolumesComponent {
    @Input() public name: string;
    @Input() public title: string;
    @Input() public number: any;
    @Input() public list: any;
    @Input() public totalLabel: string;
    @Input() public totalValue: number;
    @Output() public toggleModal: EventEmitter<any> = new EventEmitter();

    public monthFormat(month: number): string {
        return moment(month, 'M').format('MMM');
    }

    public openSdipGroupDetail(groupData: SdipStatisticsByGroup): void {
        this.toggleModal.emit(groupData);
    }

    public truncatePercent(n) {
        const step = Math.pow(10, 1);
        const temp = step * (n * 100);
        return Number((temp / step).toFixed(1));
    }
}
