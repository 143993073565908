import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HotApiLoyaltyService, HotRedemptionWindowStatus } from '@hot-theme-nx/generated-api';
import { HotUserExtended } from '@hot-libs/shared-models';

import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services/features.service';
import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';

@Injectable({
    providedIn: 'root',
})
export class LoyaltyService {
    constructor(
        private readonly store: Store<AppState>,
        public readonly featuresService: FeaturesService,
        private readonly apiLoyaltyService: HotApiLoyaltyService
    ) { }

    public getState(fulfillmentCenterId: string): Observable<HotRedemptionWindowStatus> {
        return this.apiLoyaltyService.getStatus(fulfillmentCenterId);
    }

    public loyaltyEnable(): Observable<boolean> {
        return this.store.select(authUser).pipe(
            map((user: HotUserExtended) => {
                if (user) {
                    return user.contact.outlet
                        ? !user.contact.outlet.isLoyaltyDisabled && this.featuresService.LoyaltyProgram
                        : false;
                } else {
                    return false;
                }
            })
        );
    }

    public loyaltyBalanceEnable(): Observable<boolean> {
        return this.loyaltyEnable().pipe(
            map((isLoyaltyEnable: boolean) => isLoyaltyEnable && this.featuresService.LoyaltyProgramBalance)
        );
    }

    public loyaltyTiersEnable(): Observable<boolean> {
        return this.loyaltyBalanceEnable().pipe(
            map((isLoyaltyBalanceEnable: boolean) => isLoyaltyBalanceEnable && this.featuresService.LoyaltyProgramTiers)
        );
    }
}
