import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OrderDetailsEditEventsService {
    private readonly hasChangesEvent$ = new Subject<boolean>();
    private readonly saveEvent$ = new Subject();
    private readonly cancelEvent$ = new Subject();

    public getHasChangesEvent(): Observable<boolean> {
        return this.hasChangesEvent$;
    }

    public pushHasChangesEvent(hasChanges: boolean): void {
        this.hasChangesEvent$.next(hasChanges);
    }

    public getSaveEvent(): Observable<any> {
        return this.saveEvent$;
    }

    public pushSaveEvent(): void {
        this.saveEvent$.next(undefined);
    }

    public getCancelEvent(): Observable<any> {
        return this.cancelEvent$;
    }

    public pushCancelEvent(): void {
        this.cancelEvent$.next(undefined);
    }
}
