import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { AppState } from '@hot-b2b/store/reducers';
import { pricingIndicatorIndexes, settingsStore } from '@hot-b2b/store/settings/selector';
import { HotCartExtended } from 'apps/hot-b2b/src/app/shared/models';
import { cartData } from '@hot-b2b/store/cart/selector';
import { SettingsStoreModel } from '@hot-libs/shared-models';

@Component({
    selector: 'hot-cart-pallet-counter',
    templateUrl: './cart-pallet-counter.component.html',
})
export class CartPalletCounterComponent implements OnInit {
    @Input() public modifier: string;

    public cartData$: Observable<HotCartExtended>;
    public pricingIndicatorIndexes$: Observable<Array<number>>;
    public pricingIndicatorIndexes: Array<number>;
    public settingsStore$: Observable<SettingsStoreModel>;

    public tierPriceIndicator: number;
    public percentProgress: number;
    public percentItem: number;
    public isCartItems: boolean = false;
    public isEligible: boolean = false;
    public hideWarningMessagesAndShowDecimalValuesForPalletCounterBar: boolean = false;

    private readonly subscriptionDestroy$ = new Subject<boolean>();

    constructor(private readonly store: Store<AppState>) {
        this.cartData$ = this.store.pipe(select(cartData));
        this.pricingIndicatorIndexes$ = this.store.pipe(select(pricingIndicatorIndexes));  
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
}

    public truncate(n, d) {
        const step = Math.pow(10, d || 0);
        const temp = Math.trunc(step * n);
        if (this.hideWarningMessagesAndShowDecimalValuesForPalletCounterBar) {
            const tempValue = step * n;
            return Number((tempValue / step).toFixed(2));
        }
        return temp / step;
    }

    public ngOnInit(): void {
        this.settingsStore$.pipe(takeUntil(this.subscriptionDestroy$)).subscribe((data: SettingsStoreModel) => {
            this.hideWarningMessagesAndShowDecimalValuesForPalletCounterBar = data.hideWarningMessagesAndShowDecimalValuesForPalletCounterBar;
        })

        this.cartData$
            .pipe(withLatestFrom(this.pricingIndicatorIndexes$), takeUntil(this.subscriptionDestroy$))
            .subscribe(([cart,indicatorPricingIndexes]: [HotCartExtended, Array<number>]) => {
                if (cart.items.length > 0) {
                    this.isCartItems = true;
                    this.tierPriceIndicator = cart?.tierPriceIndicator || 0;
                    this.pricingIndicatorIndexes = indicatorPricingIndexes;
                    this.percentItem = 100 / this.pricingIndicatorIndexes[this.pricingIndicatorIndexes.length - 1];

                    if (this.tierPriceIndicator > 100) {
                        this.tierPriceIndicator = this.truncate(this.tierPriceIndicator, 0);
                    } else if (this.tierPriceIndicator > 10) {
                        this.tierPriceIndicator = this.truncate(this.tierPriceIndicator, 1);
                    } else {
                        this.tierPriceIndicator = this.truncate(this.tierPriceIndicator, 2);
                    }

                    this.percentProgress = this.percentItem * this.tierPriceIndicator;

                    if (this.percentProgress > 100) {
                        this.percentProgress = 100;
                        this.isEligible = true;
                    }else{
                        this.isEligible = false;
                    }
                } else {
                    this.isCartItems = false;
                }
            });
    }
    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }
}
