import { Action } from '@ngrx/store';

import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { PromotionSlotModel } from '@hot-b2b/store/promotions/model';
import { EPromotionsActions } from '@hot-b2b/store/promotions/types';
import { OrderSuggestion } from '@hot-theme-nx/generated-api';

export class PromotionsGet implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_PENDING;
}

export class PromotionsGetFromCenter implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_PENDING_FROM_CENTER;
    constructor(public payload: string) { }
}

export class PromotionsGetFromCenterSuccess implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_FETCHED_FROM_CENTER;
    constructor(public payload: PromotionSlotModel[]) { }
}

export class PromotionsGetSuccess implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_FETCHED;
    constructor(public payload: PromotionSlotModel[]) { }
}

export class PromotionsError implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_ERROR;
    constructor(public payload: string | boolean) { }
}

export class PromotionsGetProducts implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_GET_PRODUCTS;
    constructor(public payload: string[]) { }
}

export class PromotionsGetProductsSuccess implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_GET_PRODUCTS_SUCCESS;
    constructor(public payload: HotProductExtended[]) { }
}

export class PromotionsClearProducts implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_CLEAR_PRODUCTS;
}

export class PromotionsSynchronized implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_SYNCHRONIZED;
    constructor(public payload?: HotCartExtended) { }
}

export class PromotionsSynchronizedSuccess implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_SYNCHRONIZED_SUCCESS;
    constructor(public payload: HotCartLineItemExtended[]) { }
}

export class PromotionsGetOrderSuggestions implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_GET_ORDER_SUGGESTIONS;
}

export class PromotionsGetOrderSuggestionsSuccess implements Action {
    public readonly type = EPromotionsActions.PROMOTIONS_GET_ORDER_SUGGESTIONS_SUCCESS;
    constructor(public payload: OrderSuggestion) { }
}

export type PromotionActions =
    | PromotionsGet
    | PromotionsGetFromCenter
    | PromotionsGetSuccess
    | PromotionsError
    | PromotionsGetProducts
    | PromotionsGetProductsSuccess
    | PromotionsClearProducts
    | PromotionsSynchronized
    | PromotionsSynchronizedSuccess
    | PromotionsGetFromCenterSuccess
    | PromotionsGetOrderSuggestions
    | PromotionsGetOrderSuggestionsSuccess;
