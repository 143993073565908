import { CartErrorHandlerService } from '../../../../../../../libs/common-api/src/lib/services/cart-error-handler.service';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { cartData } from "@hot-b2b/store/cart/selector";
import { AppState } from "@hot-b2b/store/reducers";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { HotCartExtended } from "../../models";
import { FeaturesService } from "../../services";
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hot-product-limit-message',
    templateUrl: './product-limit-message.component.html'
})

export class ProductLimitMessageComponent implements OnInit, OnDestroy {
    @Input() product: any;

    public productLimitOrderQuantity: boolean = false;
    
    private readonly unsubscribe$ = new Subject();
    public cartData$: Observable<HotCartExtended>;

    public featureValidateProductLimitOrderQuantity: boolean = this.featuresService.ValidateProductLimitOrderQuantity;
    
    constructor(
        private readonly store: Store<AppState>,
        private readonly featuresService: FeaturesService,
        private readonly cartErrorService: CartErrorHandlerService
    ) {
        this.cartData$ = this.store.pipe(select(cartData));
    }

    public ngOnInit(): void {
        this.cartData$.pipe(takeUntil(this.unsubscribe$)).subscribe((data: HotCartExtended) => {
            this.productLimitOrderQuantity = false;
            let validationError: any = [];
            let validationWarning: any = [];
            for (let error of data.items) {
                if(error.validationErrors.length > 0){
                    for(let error1 of error.validationErrors){
                        validationError.push(error1);
                    }
                }
            }
            for (let error of data.items) {
                if(error.validationWarnings.length > 0){
                    for(let error1 of error.validationWarnings){
                        validationWarning.push(error1);
                    }
                }  
            }

            if (data?.id && this.product) {
                const groupLimitErrorAndWarning = this.cartErrorService.processProductLimitOrderQuantity(validationError, validationWarning);
                this.productLimitOrderQuantity = groupLimitErrorAndWarning.some(elem => elem.sku === this.product.sku);
            }

        })
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(true);
    }
}
