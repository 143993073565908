/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotPopConfirmationResponse } from '../models/hot-pop-confirmation-response';
import { HotPopInvoiceResponse } from '../models/hot-pop-invoice-response';
import { HotPopInvoice } from '../models/hot-pop-invoice';
import { HotPopCustomerCapture } from '../models/hot-pop-customer-capture';
import { HotInvoiceCapture } from '../models/hot-invoice-capture';
import { HotPopCustomerToken } from '../models/hot-pop-customer-token';
import { HotPopCardId } from '../models/hot-pop-card-id';
import { HotPaymentMethods } from '../models/hot-payment-methods';
import { OperationResult } from '../models/operation-result';
import { HotCardResponse } from '../models/hot-card-response';
import { HotPopTokenResponse } from '../models/hot-pop-token-response';
@Injectable({
  providedIn: 'root',
})
class HotPopService extends __BaseService {
  static readonly createPopCustomerPath = '/storefrontapi/hot/pop/create-pop-customer';
  static readonly createInvoicePath = '/storefrontapi/hot/pop/create-pop-invoice';
  static readonly capturePopCustomerInvoicePath = '/storefrontapi/hot/pop/capture-pop-customer-invoice';
  static readonly createCustomerTokenPath = '/storefrontapi/hot/pop/create-customer-token';
  static readonly getPaymentMethodPath = '/storefrontapi/hot/pop/get-payment-method';
  static readonly updatePopCaptureStatusPath = '/storefrontapi/hot/pop/update-popauthorization-status';
  static readonly cardTokenRedirectorPath = '/storefrontapi/hot/pop/card-token-redirector';
  static readonly getCardPath = '/storefrontapi/hot/pop/getcard';
  static readonly apmRedirectorPath = '/storefrontapi/hot/pop/apm-redirector';
  static readonly getCustomerTokensPath = '/storefrontapi/hot/pop/get-customer-tokens';
  static readonly deletePopCardTokenPath = '/storefrontapi/hot/pop/customers/{customerId}/tokens/{tokenId}';
  static readonly assignCustomerTokenPath = '/storefrontapi/hot/pop/customers/{customerId}/tokens/{tokenId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  createPopCustomerResponse(): __Observable<__StrictHttpResponse<HotPopConfirmationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/pop/create-pop-customer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotPopConfirmationResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  createPopCustomer(): __Observable<HotPopConfirmationResponse> {
    return this.createPopCustomerResponse().pipe(
      __map(_r => _r.body as HotPopConfirmationResponse)
    );
  }

  /**
   * @param params The `HotPopService.CreateInvoiceParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  createInvoiceResponse(params: HotPopService.CreateInvoiceParams): __Observable<__StrictHttpResponse<HotPopInvoiceResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orderId != null) __params = __params.set('orderId', params.orderId.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/pop/create-pop-invoice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotPopInvoiceResponse>;
      })
    );
  }
  /**
   * @param params The `HotPopService.CreateInvoiceParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `body`:
   *
   * @return Success
   */
  createInvoice(params: HotPopService.CreateInvoiceParams): __Observable<HotPopInvoiceResponse> {
    return this.createInvoiceResponse(params).pipe(
      __map(_r => _r.body as HotPopInvoiceResponse)
    );
  }

  /**
   * @param params The `HotPopService.CapturePopCustomerInvoiceParams` containing the following parameters:
   *
   * - `invoiceId`:
   *
   * - `body`:
   *
   * @return Success
   */
  capturePopCustomerInvoiceResponse(params: HotPopService.CapturePopCustomerInvoiceParams): __Observable<__StrictHttpResponse<HotPopCustomerCapture>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.invoiceId != null) __params = __params.set('invoiceId', params.invoiceId.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/pop/capture-pop-customer-invoice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotPopCustomerCapture>;
      })
    );
  }
  /**
   * @param params The `HotPopService.CapturePopCustomerInvoiceParams` containing the following parameters:
   *
   * - `invoiceId`:
   *
   * - `body`:
   *
   * @return Success
   */
  capturePopCustomerInvoice(params: HotPopService.CapturePopCustomerInvoiceParams): __Observable<HotPopCustomerCapture> {
    return this.capturePopCustomerInvoiceResponse(params).pipe(
      __map(_r => _r.body as HotPopCustomerCapture)
    );
  }

  /**
   * @param params The `HotPopService.CreateCustomerTokenParams` containing the following parameters:
   *
   * - `customerId`:
   *
   * - `body`:
   *
   * @return Success
   */
  createCustomerTokenResponse(params: HotPopService.CreateCustomerTokenParams): __Observable<__StrictHttpResponse<HotPopCustomerToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.customerId != null) __params = __params.set('customerId', params.customerId.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/pop/create-customer-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotPopCustomerToken>;
      })
    );
  }
  /**
   * @param params The `HotPopService.CreateCustomerTokenParams` containing the following parameters:
   *
   * - `customerId`:
   *
   * - `body`:
   *
   * @return Success
   */
  createCustomerToken(params: HotPopService.CreateCustomerTokenParams): __Observable<HotPopCustomerToken> {
    return this.createCustomerTokenResponse(params).pipe(
      __map(_r => _r.body as HotPopCustomerToken)
    );
  }

  /**
   * @return Success
   */
  getPaymentMethodResponse(): __Observable<__StrictHttpResponse<Array<HotPaymentMethods>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/pop/get-payment-method`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotPaymentMethods>>;
      })
    );
  }
  /**
   * @return Success
   */
  getPaymentMethod(): __Observable<Array<HotPaymentMethods>> {
    return this.getPaymentMethodResponse().pipe(
      __map(_r => _r.body as Array<HotPaymentMethods>)
    );
  }

  /**
   * @param params The `HotPopService.UpdatePopCaptureStatusParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `id`:
   *
   * @return Success
   */
  updatePopCaptureStatusResponse(params: HotPopService.UpdatePopCaptureStatusParams): __Observable<__StrictHttpResponse<OperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/pop/update-popauthorization-status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OperationResult>;
      })
    );
  }
  /**
   * @param params The `HotPopService.UpdatePopCaptureStatusParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `id`:
   *
   * @return Success
   */
  updatePopCaptureStatus(params: HotPopService.UpdatePopCaptureStatusParams): __Observable<OperationResult> {
    return this.updatePopCaptureStatusResponse(params).pipe(
      __map(_r => _r.body as OperationResult)
    );
  }
  cardTokenRedirectorResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/pop/card-token-redirector`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  cardTokenRedirector(): __Observable<null> {
    return this.cardTokenRedirectorResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param cardId undefined
   * @return Success
   */
  getCardResponse(cardId?: string): __Observable<__StrictHttpResponse<HotCardResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (cardId != null) __params = __params.set('cardId', cardId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/pop/getcard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCardResponse>;
      })
    );
  }
  /**
   * @param cardId undefined
   * @return Success
   */
  getCard(cardId?: string): __Observable<HotCardResponse> {
    return this.getCardResponse(cardId).pipe(
      __map(_r => _r.body as HotCardResponse)
    );
  }

  /**
   * @param params The `HotPopService.ApmRedirectorParams` containing the following parameters:
   *
   * - `order_id`:
   *
   * - `invoice_id`:
   */
  apmRedirectorResponse(params: HotPopService.ApmRedirectorParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orderId != null) __params = __params.set('order_id', params.orderId.toString());
    if (params.invoiceId != null) __params = __params.set('invoice_id', params.invoiceId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/pop/apm-redirector`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `HotPopService.ApmRedirectorParams` containing the following parameters:
   *
   * - `order_id`:
   *
   * - `invoice_id`:
   */
  apmRedirector(params: HotPopService.ApmRedirectorParams): __Observable<null> {
    return this.apmRedirectorResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param customerId undefined
   * @return Success
   */
  getCustomerTokensResponse(customerId?: string): __Observable<__StrictHttpResponse<HotPopCustomerToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (customerId != null) __params = __params.set('customerId', customerId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/pop/get-customer-tokens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotPopCustomerToken>;
      })
    );
  }
  /**
   * @param customerId undefined
   * @return Success
   */
  getCustomerTokens(customerId?: string): __Observable<HotPopCustomerToken> {
    return this.getCustomerTokensResponse(customerId).pipe(
      __map(_r => _r.body as HotPopCustomerToken)
    );
  }

  /**
   * @param params The `HotPopService.DeletePopCardTokenParams` containing the following parameters:
   *
   * - `tokenId`:
   *
   * - `customerId`:
   *
   * @return Success
   */
  deletePopCardTokenResponse(params: HotPopService.DeletePopCardTokenParams): __Observable<__StrictHttpResponse<HotPopConfirmationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/storefrontapi/hot/pop/customers/${encodeURIComponent(String(params.customerId))}/tokens/${encodeURIComponent(String(params.tokenId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotPopConfirmationResponse>;
      })
    );
  }
  /**
   * @param params The `HotPopService.DeletePopCardTokenParams` containing the following parameters:
   *
   * - `tokenId`:
   *
   * - `customerId`:
   *
   * @return Success
   */
  deletePopCardToken(params: HotPopService.DeletePopCardTokenParams): __Observable<HotPopConfirmationResponse> {
    return this.deletePopCardTokenResponse(params).pipe(
      __map(_r => _r.body as HotPopConfirmationResponse)
    );
  }

  /**
   * @param params The `HotPopService.AssignCustomerTokenParams` containing the following parameters:
   *
   * - `tokenId`:
   *
   * - `customerId`:
   *
   * @return Success
   */
  assignCustomerTokenResponse(params: HotPopService.AssignCustomerTokenParams): __Observable<__StrictHttpResponse<HotPopTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/pop/customers/${encodeURIComponent(String(params.customerId))}/tokens/${encodeURIComponent(String(params.tokenId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotPopTokenResponse>;
      })
    );
  }
  /**
   * @param params The `HotPopService.AssignCustomerTokenParams` containing the following parameters:
   *
   * - `tokenId`:
   *
   * - `customerId`:
   *
   * @return Success
   */
  assignCustomerToken(params: HotPopService.AssignCustomerTokenParams): __Observable<HotPopTokenResponse> {
    return this.assignCustomerTokenResponse(params).pipe(
      __map(_r => _r.body as HotPopTokenResponse)
    );
  }
}

module HotPopService {

  /**
   * Parameters for CreateInvoice
   */
  export interface CreateInvoiceParams {
    orderId?: string;
    body?: HotPopInvoice;
  }

  /**
   * Parameters for CapturePopCustomerInvoice
   */
  export interface CapturePopCustomerInvoiceParams {
    invoiceId?: string;
    body?: HotInvoiceCapture;
  }

  /**
   * Parameters for CreateCustomerToken
   */
  export interface CreateCustomerTokenParams {
    customerId?: string;
    body?: HotPopCardId;
  }

  /**
   * Parameters for UpdatePopCaptureStatus
   */
  export interface UpdatePopCaptureStatusParams {
    status?: string;
    id?: string;
  }

  /**
   * Parameters for ApmRedirector
   */
  export interface ApmRedirectorParams {
    orderId?: string;
    invoiceId?: string;
  }

  /**
   * Parameters for DeletePopCardToken
   */
  export interface DeletePopCardTokenParams {
    tokenId: string;
    customerId: string;
  }

  /**
   * Parameters for AssignCustomerToken
   */
  export interface AssignCustomerTokenParams {
    tokenId: string;
    customerId: string;
  }
}

export { HotPopService }
