import { HotOrderExtended, HotOrderLineItemExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { 
    CurrencyFormat,
    HotConfirmOrderDeliveryRequest,
    HotConfirmOrderDeliveryRequestItem,
} from '@hot-theme-nx/generated-api';
import { Observable, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { OrderDetailsService, ProductService, FeaturesService, ModalService } from '../../../services';
import { Store, select } from '@ngrx/store';
import { AppState } from '@hot-b2b/store/reducers';
import { ChangeProductQuantityModel } from '../../../models';
import { ConfirmOrderByCustomer } from '@hot-b2b/store/orders/actions';
import { settingsStore } from '@hot-b2b/store/settings/selector';
import { ModalIds } from '@hot-libs/shared-types';

@Component({
    selector: 'hot-theme-customer-order-confirm-modal',
    templateUrl: './confirm-customer-modal.component.html',
})
export class ConfirmCustomerModalComponent implements OnInit, OnDestroy {
    @Input() public order: HotOrderExtended;
    @Input() public dateFormat: string;

    @Output() public confirmEvent: EventEmitter<any> = new EventEmitter();
    @Output() public rejectButtonClickEvent: EventEmitter<any> = new EventEmitter();

    public featureAddReasonsForPartialDelivery: boolean = this.featuresService.AddReasonsForPartialDelivery;
    
    public pageName = 'ConfirmCustomerOrderModal';
    public locale: string;
    public currencyFormat: CurrencyFormat;
    public readonly settingsStore$: Observable<SettingsStoreModel>;

    public confirmOrderRequest: HotConfirmOrderDeliveryRequest = {
        isDeliveredInFull: true,
        items: [],
        orderId: '',
    };

    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    private initOrderItems: HotConfirmOrderDeliveryRequestItem[] = [];

    constructor(
        public readonly orderDetailsService: OrderDetailsService,
        private readonly productService: ProductService,
        private readonly store: Store<AppState>,
        public readonly featuresService: FeaturesService,
        private readonly modalService: ModalService
    ) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
    }

    ngOnInit(): void {
        this.initOrderConfirmRequest();
        this.productService.changeProductQuantityEvent
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe((data: ChangeProductQuantityModel) => {
                if (data?.productId) {
                    this.updateProductQuantity(data);
                }
            });
    }

    public confirmOrder() {
        const request = {
            ...this.confirmOrderRequest,
            items: this.confirmOrderRequest.isDeliveredInFull ? null : this.confirmOrderRequest.items,
        };

        
        if (this.featureAddReasonsForPartialDelivery && !this.confirmOrderRequest.isDeliveredInFull) {
            this.confirmEvent.emit({ request });
        } else {
            this.store.dispatch(new ConfirmOrderByCustomer(request));
            this.confirmEvent.emit();
        }
        this.modalService.toggleModal(ModalIds.customerOrderConfirmModal, false);
    }

    public setFullyDeliveryCondition(condition: boolean) {
        this.confirmOrderRequest.isDeliveredInFull = condition;
    }

    public setPartiallyDeliveryCondition(condition: boolean) {
        this.confirmOrderRequest.isDeliveredInFull = !condition;
    }

    public setDateFormat(date): Observable<string> {
        return this.orderDetailsService.setDateFormat(date);
    }

    public isOrderChanged(): boolean {
        return !this.initOrderItems.every(
            (initItem: HotConfirmOrderDeliveryRequestItem) =>
                initItem.quantity ===
                this.confirmOrderRequest.items.find(
                    (reqItem: HotConfirmOrderDeliveryRequestItem) => reqItem.productId === initItem.productId && reqItem.isGift === initItem.isGift
                ).quantity
        );
    }

    public isConfirmButtonDisabled(): boolean {
        return !this.confirmOrderRequest.isDeliveredInFull && !this.isOrderChanged();
    }

    private updateProductQuantity(changeModel: ChangeProductQuantityModel) {
        const index = this.confirmOrderRequest.items.findIndex((item) => item.productId === changeModel.productId && item.isGift === changeModel.isGift);
        this.confirmOrderRequest.items[index].quantity = changeModel.quantity;
    }
    private initOrder(item)
    {   
        return {
            isGift: item.isGift,
            productId: item.productId,
            quantity: this.order.hasInvoice ? item.deliveredQuantity : item.quantity,
        };
    }

    private initOrderConfirmRequest() {
        this.confirmOrderRequest.orderId = this.order.id;
        this.confirmOrderRequest.items = this.order.items.map((item: HotOrderLineItemExtended) => {
           return  this.initOrder(item);
           
        });
        this.initOrderItems = this.order.items.map((item: HotOrderLineItemExtended) => {
            return this.initOrder(item)
        });
    }

    ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }
}
