import { Injectable } from '@angular/core';
import { HotApiFeedbackService } from '@hot-theme-nx/generated-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FeedbackTopicsGetSuccess, FeedbackTopicsGetError, FeedbackTopicsGet } from '@hot-b2b/store/feedback/actions';
import { EFeedbackTopics } from '@hot-b2b/store/feedback/types';

@Injectable()
export class FeedbackTopicsEffects {
    constructor(private readonly actions$: Actions, private readonly hotApiFeedbackService: HotApiFeedbackService) {}

    public feedbackTopicsGet$: Observable<FeedbackTopicsGetSuccess | FeedbackTopicsGetError> = createEffect(() => this.actions$.pipe(
        ofType<FeedbackTopicsGet>(EFeedbackTopics.FEEDBACK_TOPICS_PENDING),
        switchMap(() =>
            this.hotApiFeedbackService.getTopics().pipe(map((topics: string[]) => new FeedbackTopicsGetSuccess(topics)))
        )
    ));
}
