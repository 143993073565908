import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import { Router, ActivationStart } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import moment from 'moment';

import {
    HotTutorialStep,
    HotTutorial,
    HotOrderSearchResult,
    User,
    HotApiSecurityService,
} from '@hot-theme-nx/generated-api';
import { AccountType, StorageKeys } from '@hot-libs/shared-types';
import { HotUserExtended } from '@hot-libs/shared-models';
import { ScreenDimensionService } from '@hot-libs/browser-specific';

import { AppState } from '@hot-b2b/store/reducers';
import { tutorialsData, tutorialsCurrentStep, tutorialsSelectedId } from '@hot-b2b/store/tutorials/selector';
import {
    TutorialSetCurrentStepAction,
    TutorialSetCurrentAction,
    TutorialStartedAction,
    TutorialClearTutorialWithIdAction,
} from '@hot-b2b/store/tutorials/actions';
import { authUser } from '@hot-b2b/store/auth/selector';
import { ordersResponse } from '@hot-b2b/store/orders/selector';
import { OrdersDemoDataSet, OrdersDemoDataClear, OrdersResponseGet } from '@hot-b2b/store/orders/actions';
import { cartItemsLength } from '@hot-b2b/store/cart/selector';
import { CartDemoDataSet, CartDemoDataClear, CartGetSuccess } from '@hot-b2b/store/cart/actions';
import { CartService } from '../../../cart/services';
import { HotCartExtended } from '../../models';
import { AuthUserContactUpdateTutorialFlag } from '@hot-b2b/store/auth/actions';

interface ITutorialCompleted {
    id?: string;
    completedDate?: Date;
}

@Component({
    selector: 'hot-tutorial',
    templateUrl: './tutorial.component.html',
})
export class TutorialComponent implements OnInit {
    @ViewChild('tutorialWindow') private readonly tutorialWindowElement: ElementRef;

    public offsetLeft;
    public offsetTop;
    public offsetRight;
    public positionIsTop = false;
    public positionIsRight = false;
    public isRTL: boolean;
    public isArrowFlipped = false;

    public showTutorialWindow = false;
    public allTutorials: HotTutorial[] = [];
    public currentTutorial: HotTutorial;
    public currentStep: HotTutorialStep;
    public currentPage: string;
    public currentStepIndex: number;
    public isTutorialStart: boolean;
    public tutorialWindowLoading = true;

    public currentStep$: Observable<HotTutorialStep>;
    public authUser$: Observable<HotUserExtended>;
    public tutorialsSelectedId$: Observable<string>;
    private user: User;

    public isUpLg: boolean;
    public readonly accountType = AccountType;

    private readonly windowMargin = 10;
    private readonly windowMarginMobile = 25;
    private browserWidth: number;
    private hasSetDemoData = false;
    private scrollToTop: boolean;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly renderer: Renderer2,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly cartService: CartService,
        private readonly hotApiSecurityService: HotApiSecurityService
    ) {
        this.authUser$ = this.store.pipe(select(authUser));
        this.currentStep$ = this.store.select(tutorialsCurrentStep);
        this.tutorialsSelectedId$ = this.store.select(tutorialsSelectedId);
    }

    public ngOnInit(): void {
        this.isRTL = this.document.documentElement.dir === 'rtl';

        // get tutorials and set router subscription
        combineLatest([
            this.router.events.pipe(
                filter((event: ActivationStart) => event instanceof ActivationStart && !!event.snapshot.data)
            ),
            this.store.select(tutorialsData).pipe(filter((tutorials: HotTutorial[]) => !!tutorials.length)),
            this.authUser$,
            this.tutorialsSelectedId$,
        ]).subscribe(
            ([event, tutorials, user, tutorialId]: [ActivationStart, HotTutorial[], HotUserExtended, string]) => {
                this.clearTutorialWindow();
                const pageName = event.snapshot.data.tutorialPageName;
                const subDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);
                this.user = user;

                if (
                    (user?.userType === AccountType.Customer ||
                        (user?.userType === AccountType.SubDistributor && subDistributorMode === 'Customer')) &&
                    pageName
                ) {
                    this.allTutorials = tutorials;
                    this.currentPage = pageName;

                    let hasSelectedTutorials =
                        this.allTutorials.filter((t) => t.page === this.currentPage && t.id === tutorialId).length > 0;

                    let showTutorial = !this.user.contact.isTutorialHide || hasSelectedTutorials;
                    if (showTutorial) this.setCurrentTutorial();
                }
            }
        );

        this.currentStep$.pipe(filter((step: HotTutorialStep) => !!step)).subscribe((step: HotTutorialStep) => {
            this.currentStep = step;
            this.renderer.removeAttribute(this.document.body, 'class');
            this.renderer.addClass(this.document.body, 'demo');
            // IE doesn't support template strings
            this.renderer.addClass(this.document.body, 'demo--' + step.anchor);

            this.tutorialWindowLoading = true;
            setTimeout(() => {
                this.calculateWindowPosition();
                this.tutorialWindowLoading = false;
            }, 0);

            if (this.currentStepIndex === this.currentTutorial.steps.length - 1) {
                this.setTutorialCompleted(this.currentTutorial);
            }
        });
    }

    public resize(): void {
        const browserCurrentWidth = window.innerWidth;

        if (browserCurrentWidth !== this.browserWidth) {
            this.browserWidth = browserCurrentWidth;
            this.calculateWindowPosition();
        }
    }

    private calculateWindowPosition(): void {
        this.isUpLg = this.screenDimensionService.upLg();
        this.offsetLeft = null;
        this.offsetTop = null;
        this.offsetRight = null;
        this.positionIsRight = false;
        this.positionIsTop = false;
        this.isArrowFlipped = false;
        this.scrollToTop = false;

        if (!(this.currentStep && this.isTutorialStart)) {
            return;
        }

        const anchor = this.currentStep.anchor;
        const anchorElement = this.getAnchorElement(anchor);

        if (anchorElement) {
            const anchorRect = anchorElement.getBoundingClientRect();
            const bodyRect = this.document.body.getBoundingClientRect();

            const tutWindowRectHeight: number = this.tutorialWindowElement.nativeElement.offsetHeight;
            const tutWindowRectWidth: number = this.tutorialWindowElement.nativeElement.offsetWidth;

            if (anchor === 'checkout-button') {
                this.anchorCheckoutButton(anchorRect, bodyRect, tutWindowRectHeight, tutWindowRectWidth);
            } else if (anchor === 'search-bar') {
                this.calcPositionSearchBar(anchorRect, bodyRect, tutWindowRectHeight);
            } else if (this.currentPage === 'loyalty' && anchor === 'banners' && !this.isUpLg) {
                this.calcPositionLoyaltyBanners(anchorRect, bodyRect);
            } else if (anchor === 'date-filter') {
                this.calcPositionDateFilter(anchorRect, bodyRect);
            } else if (anchor === 'order-status' && this.isUpLg) {
                this.calcPositionOrderStatus(anchorRect, bodyRect, tutWindowRectHeight);
            } else if (anchor === 'notifications') {
                this.calcPositionNotifications(anchorRect, bodyRect, tutWindowRectWidth);
            } else if (anchor === 'navigation' && this.isUpLg) {
                this.isArrowFlipped = true;
                this.calcPositionVertical(anchorRect, bodyRect, tutWindowRectHeight);
                this.calcPositionHorizontal(anchorRect, bodyRect, tutWindowRectWidth);
            } else {
                this.calcPositionVertical(anchorRect, bodyRect, tutWindowRectHeight);
                this.calcPositionHorizontal(anchorRect, bodyRect, tutWindowRectWidth);
            }

            this.scroll(anchorRect.height);
        }
    }
    public anchorCheckoutButton(anchorRect, bodyRect, tutWindowRectHeight, tutWindowRectWidth) {
        if (this.isUpLg) {
            this.calcPositionDesktopCartSidebar(anchorRect, bodyRect, tutWindowRectWidth);
        } else {
            this.calcPositionMobileCheckoutBtn(anchorRect.height, tutWindowRectHeight);
        }
    }

    private calcPositionNotifications(anchorRect: DOMRect, bodyRect: DOMRect, _tutWindowRectWidth: number) {
        this.isArrowFlipped = true;
        this.scrollToTop = true;

        if (!this.isUpLg) {
            this.offsetRight = 25;
            this.offsetTop = anchorRect.top - bodyRect.top + anchorRect.height + 10;
        } else {
            this.offsetTop = anchorRect.top - bodyRect.top + anchorRect.height + 25;

            if (!this.isRTL) {
                this.offsetRight = bodyRect.width - anchorRect.right - 13;
            } else {
                this.offsetLeft = anchorRect.left - 13;
            }
        }
    }

    private calcPositionOrderStatus(anchorRect: DOMRect, bodyRect: DOMRect, tutWindowRectHeight: number) {
        this.positionIsTop = true;
        this.offsetTop = anchorRect.top - bodyRect.top - tutWindowRectHeight - 34;

        if (!this.isRTL) {
            this.offsetLeft = anchorRect.left - 30;
        } else {
            this.offsetRight = bodyRect.width - anchorRect.right - 30;
        }
    }

    private calcPositionDateFilter(anchorRect: DOMRect, bodyRect: DOMRect) {
        this.isArrowFlipped = true;

        if (!this.isUpLg) {
            this.offsetTop = anchorRect.top - bodyRect.top + anchorRect.height + 10;
            this.offsetLeft = anchorRect.left + 10;
        } else {
            this.offsetTop = anchorRect.top - bodyRect.top + anchorRect.height + 30;

            if (!this.isRTL) {
                this.offsetLeft = anchorRect.left - 16;
            } else {
                this.offsetRight = bodyRect.width - anchorRect.right - 16;
            }
        }
    }

    private calcPositionLoyaltyBanners(_anchorRect: DOMRect, _bodyRect: DOMRect) {
        if (!this.isUpLg) {
            this.positionIsTop = true;
            this.isArrowFlipped = true;
            this.positionIsRight = true;
            this.offsetTop = 10;
            this.offsetRight = this.windowMarginMobile;
        }
    }

    private calcPositionSearchBar(anchorRect: DOMRect, bodyRect: DOMRect, tutWindowRectHeight: number): void {
        this.positionIsRight = !this.isRTL;

        if (this.isUpLg) {
            this.positionIsTop = true;
            this.offsetTop = anchorRect.top - bodyRect.top - tutWindowRectHeight - 10;

            if (!this.isRTL) {
                this.offsetRight = bodyRect.width - anchorRect.right;
            } else {
                this.offsetLeft = anchorRect.left;
                this.positionIsRight = true;
            }
        } else {
            this.isArrowFlipped = true;
            this.offsetTop = anchorRect.top - bodyRect.top + anchorRect.height + 10;

            if (!this.isRTL) {
                this.offsetRight = bodyRect.width - anchorRect.right + 11;
            } else {
                this.positionIsRight = true;
                this.offsetLeft = anchorRect.left + 10;
            }
        }
    }

    private calcPositionMobileCheckoutBtn(anchorRectHeight: number, tutWindowRectHeight: number): void {
        this.positionIsTop = true;
        this.offsetTop = window.innerHeight - anchorRectHeight - tutWindowRectHeight - 10;
        if (this.isRTL) {
            this.offsetRight = this.windowMarginMobile;
        } else {
            this.offsetLeft = this.windowMarginMobile;
        }
    }

    private calcPositionDesktopCartSidebar(anchorRect: DOMRect, bodyRect: DOMRect, tutWindowRectWidth: number): void {
        const grid = this.document.querySelectorAll('.product-grid');

        if (grid.length > 0) {
            this.isArrowFlipped = true;
            this.offsetTop = anchorRect.top - bodyRect.top + anchorRect.height + 10;
            this.offsetLeft = anchorRect.left;
        } else {
            this.positionIsRight = true;
            this.positionIsTop = true;
            this.offsetTop = anchorRect.top - bodyRect.top;

            if (!this.isRTL) {
                this.offsetLeft = anchorRect.left - tutWindowRectWidth - this.windowMargin;
            } else {
                this.offsetLeft = anchorRect.right + this.windowMargin;
            }
        }
    }

    private calcPositionVertical(anchorRect: DOMRect, bodyRect: DOMRect, tutWindowRectHeight: number): void {
        if (anchorRect.top - bodyRect.top >= tutWindowRectHeight) {
            this.offsetTop = anchorRect.top - bodyRect.top - tutWindowRectHeight - this.windowMargin;
            this.positionIsTop = true;
        } else {
            this.offsetTop = anchorRect.bottom - bodyRect.top + this.windowMargin;
        }
    }

    private calcPositionHorizontal(anchorRect: DOMRect, bodyRect: DOMRect, tutWindowRectWidth: number): void {
        if (this.isRTL) {
            if (anchorRect.right - tutWindowRectWidth >= 0) {
                this.offsetRight = bodyRect.width - anchorRect.right;
            } else {
                this.offsetRight = anchorRect.right;
                this.positionIsRight = true;
            }
        }
        else if (anchorRect.left + tutWindowRectWidth <= bodyRect.width) {
             if (!this.isUpLg) {
                 this.offsetLeft = this.windowMarginMobile;
            } else {
                this.offsetLeft = anchorRect.left;
            }
        }
        else {
            this.offsetLeft = anchorRect.right - tutWindowRectWidth;
            this.positionIsRight = true;
        }
    }

    private scroll(anchorRectHeight: number) {
        if (this.scrollToTop) {
            window.scrollTo(0, 0);
            return;
        }

        if (this.positionIsTop) {
            window.scrollTo(0, this.offsetTop - this.windowMargin);
        } else {
            window.scrollTo(0, this.offsetTop - anchorRectHeight - this.windowMargin);
        }
    }

    public goToStep(nextStep: boolean): void {
        if (nextStep) {
            if (this.currentStepIndex >= this.currentTutorial.steps.length - 1) {
                return;
            }
            this.currentStepIndex++;
        } else {
            if (!this.currentStepIndex) {
                return;
            }
            this.currentStepIndex--;
        }

        this.setCurrentStep();
    }

    public repeatTutorial(): void {
        this.currentStepIndex = 0;
        this.setCurrentStep();
    }

    public startTutorial(state: boolean): void {
        if (state) {
            this.isTutorialStart = true;
            this.currentStepIndex = 0;
            this.showTutorialWindow = true;
            this.setCurrentStep();
            this.store.dispatch(new TutorialSetCurrentAction(this.currentTutorial));
            this.setDemoData();
        } else {
            this.showTutorialWindow = false;

            // update the tutorial flag in back end
            this.hideTutorialFlagRemote();

            this.renderer.removeAttribute(this.document.body, 'class');
            this.setTutorialCompleted(this.currentTutorial);
            this.clearTutorialWindow();
            if (this.router.url.indexOf('/brands/all') >= 0) {
                this.cartService
                    .getCart()
                    .pipe(take(1))
                    .subscribe((cart: HotCartExtended) => this.store.dispatch(new CartGetSuccess(cart)));
            }
            if (this.router.url.indexOf('/orders') >= 0) {
                this.store.dispatch(new OrdersResponseGet());
            }
            this.store.dispatch(new TutorialClearTutorialWithIdAction());
        }
        this.store.dispatch(new TutorialStartedAction(state));
    }

    private setDemoData(): void {
        switch (this.currentTutorial.page) {
            case 'previous-orders':
                this.setPreviousOrdersDemoData();
                this.hasSetDemoData = true;
                break;
            case 'new-order':
                this.setCatalogDemoData();
                this.hasSetDemoData = true;
                break;
        }
    }

    private setPreviousOrdersDemoData(): void {
        if (!this.hasSetDemoData) {
            this.store
                .select(ordersResponse)
                .pipe(take(1))
                .subscribe((response: HotOrderSearchResult) => {
                    if (!response.orders.length) {
                        this.hasSetDemoData = true;
                        this.store.dispatch(new OrdersDemoDataSet());
                    }
                });
        }
    }

    private setCatalogDemoData(): void {
        if (!this.hasSetDemoData) {
            this.store
                .select(cartItemsLength)
                .pipe(take(1))
                .subscribe((cartHasItems) => {
                    if (!cartHasItems) {
                        this.hasSetDemoData = true;
                        this.store.dispatch(new CartDemoDataSet());
                    }
                });
        }
    }

    private clearDemoData(): void {
        if (this.hasSetDemoData) {
            switch (this.currentTutorial.page) {
                case 'previous-orders':
                    this.store.dispatch(new OrdersDemoDataClear());
                    break;
                case 'new-order':
                    this.store.dispatch(new CartDemoDataClear());
                    break;
            }
        }

        this.hasSetDemoData = false;
    }

    public clearTutorialWindow(): void {
        this.clearDemoData();
        this.showTutorialWindow = false;
        this.isTutorialStart = false;
        this.offsetLeft = null;
        this.positionIsRight = false;
        this.positionIsTop = false;
        this.offsetRight = null;
        this.offsetTop = null;
        this.currentTutorial = null;
        this.currentStepIndex = 0;
        this.store.dispatch(new TutorialStartedAction(false));
        this.renderer.removeAttribute(this.document.body, 'class');
    }

    private setCurrentTutorial(): void {
        const completedTutorials = this.getLocalStorageTutorials();

        let needTutorial = true;
        let tutorialIndex = 0;
        const currentPageTutorials = this.allTutorials.filter((t) => t.page === this.currentPage);

        if (!currentPageTutorials.length) {
            return;
        }

        do {
            const completedTutorial = completedTutorials.find((t) => t.id === currentPageTutorials[tutorialIndex].id);
            if (!completedTutorial) {
                this.currentTutorial = currentPageTutorials[tutorialIndex];
                needTutorial = false;
            } else {
                const frequencyPeriodActual = this.checkFrequency(
                    completedTutorial,
                    currentPageTutorials[tutorialIndex].frequency
                );
                if (frequencyPeriodActual) {
                    this.currentTutorial = currentPageTutorials[tutorialIndex];
                    needTutorial = false;
                } else {
                    tutorialIndex++;
                }
            }
            if (tutorialIndex === currentPageTutorials.length) {
                needTutorial = false;
            }
        } while (needTutorial);

        if (this.currentTutorial?.steps.length) {
            this.showTutorialWindow = true;
        }
    }

    private checkFrequency(completedTutorial: ITutorialCompleted, frequency: string): boolean {
        const currentDate = moment(new Date());
        const completedDate = moment(completedTutorial.completedDate);

        const monthsBetween = currentDate.diff(completedDate, 'months');

        switch (frequency) {
            case 'first-sign-in':
                return false;
            case 'every-month':
                return monthsBetween >= 1;
            case 'every-2-months':
                return monthsBetween >= 2;
            case 'every-3-months':
                return monthsBetween >= 3;
            case 'every-6-months':
                return monthsBetween >= 6;
            case 'every-year':
                return monthsBetween >= 12;
            default:
                return true;
        }
    }

    private getLocalStorageTutorials(): ITutorialCompleted[] {
        const tutorials = localStorage.getItem(StorageKeys.tutorials);
        if (tutorials) {
            return JSON.parse(tutorials) as ITutorialCompleted[];
        }

        return [];
    }

    private hideTutorialFlagRemote() {
        this.hotApiSecurityService.updateTutorialPopup().subscribe((response: boolean) => {
            if (response != null) {
                this.store.dispatch(new AuthUserContactUpdateTutorialFlag(response));
            }
        });
    }

    private setTutorialCompleted(tutorial: HotTutorial): void {
        const tutorials = this.getLocalStorageTutorials();
        const existedTutorialIndex = tutorials.findIndex((t: ITutorialCompleted) => t.id === tutorial.id);

        const completedDate = new Date();
        if (existedTutorialIndex !== -1) {
            tutorials[existedTutorialIndex].completedDate = completedDate;
        } else {
            const completedTutorial: ITutorialCompleted = { id: tutorial.id, completedDate };
            tutorials.push(completedTutorial);
        }

        localStorage.setItem(StorageKeys.tutorials, JSON.stringify(tutorials));
    }

    // Get HTML Element by anchor name
    private getAnchorElement(anchorName: string): HTMLElement {
        const queryList = this.document.querySelectorAll(`[data-hot-tutorial='${anchorName}']`);
        const elements: HTMLElement[] = [];

        // workable way for IE11 (it doesn't support querySelectorAll)
        Array.prototype.forEach.call(queryList, (x: HTMLElement) => {
            elements.push(x);
        });

        return elements.filter((x: HTMLElement) => !!x.offsetHeight && !!x.offsetWidth)[0];
    }

    private setCurrentStep(): void {
        this.store.dispatch(new TutorialSetCurrentStepAction(this.currentTutorial.steps[this.currentStepIndex]));
    }
}
