import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'hot-theme-nx-video',
  templateUrl: './insight-video.component.html'
})
export class InsightVideoComponent implements OnInit {

  @Input() url: "";
  public src;


  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.src = this._sanitizer.bypassSecurityTrustResourceUrl(this._sanitizer.sanitize(SecurityContext.URL, this.getLinkType()));
  }

  getLinkType() {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = this.url.match(regExp);

    return (match && match[2].length === 11)
      ? this.url
      : "https://www.youtube.com/embed/null";
  }
}
