import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap} from 'rxjs/operators';

import { HotApiMarketingService } from '@hot-theme-nx/generated-api';

import { ECarouselsActions } from '@hot-b2b/store/carousels/types';
import { CarouselsError,  CarouselsGet, CarouselsGetSuccess } from './actions';
import { Observable } from 'rxjs';



@Injectable()
export class CarouselsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly hotApiMarketingService: HotApiMarketingService,
        
    ) { }

    public carouselGet$: Observable<CarouselsGetSuccess| CarouselsError> = createEffect(() =>
        this.actions$.pipe(
        ofType<CarouselsGet>(ECarouselsActions.CAROUSELS_PENDING),
        switchMap(() => {
            return this.hotApiMarketingService
                .getCarouselBanners().pipe(map((value: any) => new CarouselsGetSuccess(value)))
        }
        ),
    ));

}
