import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AccordionItem } from '@hot-libs/shared-models';

@Component({
    selector: 'hot-faq-modal',
    templateUrl: './faq-modal.component.html',
})
export class FaqModalComponent {
    @Input() public accordionItems: AccordionItem[];
    @Input() public showModal: boolean;
    @Output() public closeModal = new EventEmitter();
}
