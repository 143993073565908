import { createAction, props } from '@ngrx/store';

import { HotOrganizationBalance, HotOutlet, HotSettings, HotStoreSettings, User } from '@hot-theme-nx/generated-api';

export const LoginSuccess = createAction('[Auth] Login Success');

export const Logout = createAction('[Auth] Logout');

export const GetCurrentUser = createAction('[User] Get current user');
export const GetCurrentUserSuccess = createAction('[User] Get current user success', props<{ user: User }>());
export const UserAgreeTermsAnsConditions = createAction('[User] Agree terms and conditions', props<{ user: User }>());

export const GetSettings = createAction('[Settings] Get settings');
export const GetSettingsSuccess = createAction('[Settings] Get settings success', props<{ settings: HotSettings }>());

export const GetStoreSettings = createAction('[Store] Get store settings');
export const GetStoreSettingsSuccess = createAction(
    '[Store] Get store settings success',
    props<{ storeSettings: HotStoreSettings }>()
);

export const UpdateContactInfoSuccess = createAction('[User] Update contact info success');
export const SetCurrentOutletSuccess = createAction('[User] Set current outlet', props<{ outlet: HotOutlet }>());

export const GetOutletDebt = createAction('[Outlet] Get outlet debt');
export const GetOutletDebtSuccess = createAction(
    '[Outlet] Get outlet debt success',
    props<{ organizationBalance: HotOrganizationBalance }>()
);

export const RootError = createAction(
    '[Root] Root request error',
    props<{ actionName: string; failedRequestParams?: any; error: any }>()
);
