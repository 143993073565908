import { Injectable, isDevMode } from '@angular/core';
import { App } from '@capacitor/app';

import { Capacitor } from '@capacitor/core';
import { Device, GetLanguageCodeResult } from '@capacitor/device';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class NativeService {
    constructor(public platform: Platform) {}

    isNativeApplication(): boolean {
        const platform = Capacitor.getPlatform();

        return this.isLocalRun() && (platform === 'android' || platform === 'ios');
    }

    isNativeSource(): boolean {
        const platform = Capacitor.getPlatform();

        return platform === 'android' || platform === 'ios';
    }

    isLocalRun(): boolean {
        return window.location.hostname.includes('localhost');
    }

    isDebug(): boolean {
        return isDevMode() && (this.platform.is('android') || this.platform.is('ios'));
    }

    getLanguageCode(): Promise<GetLanguageCodeResult> {
        return Device.getLanguageCode();
    }

    async getNativeAppDetails(): Promise<NativeAppModel> {
        const versionCode = Number((await App.getInfo()).build);
        const versionName = (await App.getInfo()).build;
        const packageName = (await App.getInfo()).id;
        const platform = this.getPlatform(Capacitor.getPlatform());
        const mobileAppDetails = this.getMobileAppDetails(platform, packageName);

        return {
            isMobileApp: platform != DotPlatform.WEB,
            platform: platform,
            packageName: packageName,
            appVersion: versionName,
            versionCode: versionCode,
            mobileApp: mobileAppDetails,
        };
    }

    private getMobileAppDetails(platform: DotPlatform, packageName: string): DotMobileApp {
        if (platform != DotPlatform.WEB) {
            switch (packageName) {
                case 'com.tinhtos.dotb2b':
                case 'com.heineken.tinhtos': {
                    return DotMobileApp.TINHTOS;
                }
                case 'com.quickdrinks.dotb2b':
                case 'com.heineken.quickdrinks': {
                    return DotMobileApp.QUICK_DRINKS;
                }

                case 'com.hvbb.hvndathang2': {
                    return DotMobileApp.HVNDATHANGS;
                }
                case 'com.heineken.dotb2b':
                case 'com.heineken.b2bdot': {
                    return DotMobileApp.DOT;
                }
            }
        } else {
            return undefined;
        }
    }

    private getPlatform(platform: string): DotPlatform {
        switch (platform) {
            case 'web':
                return DotPlatform.WEB;
            case 'android':
                return DotPlatform.ANDROID;
            case 'ios':
                return DotPlatform.IOS;
        }
    }
}

export interface NativeAppModel {
    isMobileApp?: boolean;
    platform?: DotPlatform;
    packageName?: string;
    appVersion?: string;
    versionCode?: Number;
    mobileApp?: DotMobileApp;
}

export enum DotMobileApp {
    DOT,
    QUICK_DRINKS,
    TINHTOS,
    HVNDATHANGS,
}

export enum DotPlatform {
    WEB,
    ANDROID,
    IOS,
}
