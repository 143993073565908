import { LoyaltyState } from '@hot-b2b/store/loyalty/model';

export const loyaltyInitialState: LoyaltyState = {
    pending: false,
    fetched: false,
    data: {
        loyaltyStatement: null,
        redemptionWindowStatus: null,
        banners: null,
    },
    error: null,
};
