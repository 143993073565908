import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
    selector: '[hotFallbackSrc]',
})
export class FallbackSrcDirective implements OnDestroy {
    @Input('hotFallbackSrc') imgSrc: string;

    private nativeElement: HTMLElement;
    private ERROR_EVENT_TYPE = 'error';
    private cancelOnError: () => void;

    constructor(private readonly elRef: ElementRef, private readonly renderer: Renderer2) {
        this.nativeElement = elRef.nativeElement;
        this.onError = this.onError.bind(this);
        this.cancelOnError = this.renderer.listen(this.nativeElement, this.ERROR_EVENT_TYPE, this.onError);
    }

    private onError() {
        if (this.nativeElement.getAttribute('src') !== this.imgSrc) {
            this.renderer.setAttribute(this.nativeElement, 'src', this.imgSrc);
        }
    }

    public ngOnDestroy(): void {
        if (this.cancelOnError) {
            this.cancelOnError();
        }
    }
}
