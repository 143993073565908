import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { loyalty } from '@hot-b2b/store/loyalty/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotLoyaltyStatement } from '@hot-theme-nx/generated-api';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
    selector: 'hot-reward-points',
    templateUrl: './reward-points.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardPointsComponent {
    @Input() public points: number;
    public loyaltyStatement$: Observable<HotLoyaltyStatement>;
    
    constructor( private readonly store: Store<AppState>,) {
        this.loyaltyStatement$ = this.store.pipe(select(loyalty));
    }
}
