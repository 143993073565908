import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { NavigationService } from './navigation.service';

import { AppState } from '@hot-b2b/store/reducers';
import { routerCurrentUrl } from '@hot-b2b/store/router/selector';
import { AccountType, StorageKeys } from '@hot-libs/shared-types';

@Injectable({
    providedIn: 'root',
})
export class RouterService {
    public routerCurrentUrl$: Observable<any>;
    private untrackedRoutes = ['checkout', 'checkout/payment-method', 'thank-you', 'payment-process', 'failed'];
    private warehousesPageUrl = '/warehouses';
    private BrandsPageUrl = '/brands';
    private customer = 'Customer';
    private subDistrMode: AccountType;
    private userType: AccountType;

    constructor(private readonly store: Store<AppState>, private readonly navigationService: NavigationService) {
        this.routerCurrentUrl$ = this.store.pipe(select(routerCurrentUrl));
    }

    public getActualNavItem(url: string) {
        let currentUrl = url;

        this.userType = localStorage.getItem(StorageKeys.userType) as AccountType;
        const availableNavigationItems = this.navigationService.getAvailableNavigationItemsByUserType(this.userType);

        if (url.includes(this.warehousesPageUrl)) {
            currentUrl = `/${localStorage.getItem(StorageKeys.redirectUrlFfc).split('/')[1]}`;
            localStorage.setItem(StorageKeys.activeRoute, currentUrl);

            return currentUrl;
        } else {
            this.subDistrMode = localStorage.getItem(StorageKeys.subDistributorMode) as AccountType;

            const rootPath = this.buildRootPath(currentUrl);
            const actualRoute = this.getExistingRoute(availableNavigationItems, rootPath);

            if (actualRoute) {
                localStorage.setItem(StorageKeys.activeRoute, actualRoute);
            } else {
                localStorage.removeItem(StorageKeys.activeRoute);
            }

            return actualRoute;
        }
    }

    private getExistingRoute(availableNavigationItems, rootPath) {
        let navItemLink = this.findTrackedNavLink(availableNavigationItems, rootPath)[0]?.link;
        if (!navItemLink) {
            navItemLink = this.findUntrackedNavLink(rootPath);
        }

        return navItemLink;
    }

    private findTrackedNavLink(availableNavigationItems, rootPath) {
        return availableNavigationItems.filter(
            (navItem) => rootPath !== '/' && navItem.link.includes(rootPath)
        );
    }

    private findUntrackedNavLink(rootPath) {
        if (this.untrackedRoutes.indexOf(rootPath.split('/')[1]) !== -1) {
            return this.BrandsPageUrl;
        } else {
            return null;
        }
    }

    private buildRootPath(currentUrl) {
        return this.customer === this.getUserMode() ? `/${currentUrl.split('/')[1]}` : currentUrl;
    }

    private getUserMode() {
        return this.subDistrMode === this.customer || this.userType === this.customer ? this.customer : this.userType;
    }
}
