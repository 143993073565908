import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CookiesService {
    public setCookie(cname, cvalue) {
        document.cookie = cname + '=' + cvalue + ';path=/;secure;';
    }

    public getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    public deleteCookie(name) {
        document.cookie = name + '= ; path=/; expires=' + new Date(0).toString() + ';';
    }

    public setExpireCookie(cname, cvalue) {
        document.cookie = cname + '=' + cvalue + ';max-age=31536000; path=/;secure;';
    }

    public setExpireItemListCookie(cname, cvalue) {
        document.cookie = cname + '=' + cvalue + ';max-age=2592000; path=/;secure;';  
    }
}
