import { Injectable } from '@angular/core';

import { ICartLineItem } from '../models/cart-line-item.model';
import { Product } from '../models/product.model';

import { CartService } from './cart.service';



/**
 * Service for working product-quantity
 * NOT SINGLETON
 */
@Injectable()
export class QuantityService {
    public minValue = 0;
    public maxValue = 999;
    private _location = '';

    public get location() {
        return this._location;
    }

    public set location(location: string) {
        this._location = location;
    }

    constructor(private readonly cartService: CartService) {}

    public changeQuantity(product: Product | ICartLineItem) {
        if (
            this.validateQuantity(product.quantity) ||
            product.quantity !== this.minValue ||
            product.quantity !== this.maxValue
        ) {
            this.cartService.location = this.location;
            this.cartService.changeQuantity(product);
        }
    }

    public onChange(product: Product | ICartLineItem, tempQuantity: number): void {
        if (product.quantity !== tempQuantity && this.validateQuantity(product.quantity)) {
            this.cartService.location = this.location;
            this.cartService.changeQuantity(product);
        }
    }

    private validateQuantity(quantity: number): boolean {
        const qty = quantity;
        return !(Number.isNaN(qty) || qty < this.minValue || qty > this.maxValue);
    }

    public incrementQuantityValue(quantity: number, firstQuantityStep: number, secondQuantityStep: number) {
        if (quantity < firstQuantityStep) {
            quantity = firstQuantityStep;
        } else if (quantity < secondQuantityStep) {
            quantity = secondQuantityStep;
        } else if (quantity < 1) {
            quantity = 1;
        } else {
            quantity += 1;
        }
        return quantity;
    }

    public decrementQuantityValue(quantity: number, firstQuantityStep: number, secondQuantityStep: number) {
        if (quantity === 1 && secondQuantityStep < 1) {
            quantity = secondQuantityStep;
        } else if (quantity === secondQuantityStep && quantity !== firstQuantityStep) {
            quantity = firstQuantityStep;
        } else if (quantity === firstQuantityStep) {
            quantity = 0;
        } else {
            quantity -= 1;
        }
        return quantity;
    }

    public calculatePackagePart(product: Product) {
        const totalQuantity = Math.round(product.quantity * product.packageSize);
        const gcd = this.gcd(totalQuantity, product.packageSize);

        const packageSize: string | number =
            product.quantity >= 1
                ? Math.round(product.quantity)
                : `${Math.round(totalQuantity / gcd)}/${Math.round(product.packageSize / gcd)}`;

        return { packageSize, totalQuantity };
    }

    private gcd(a: number, b: number): number {
        return b ? this.gcd(b, a % b) : Math.abs(a);
    }
}
