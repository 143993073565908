import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '@hot-b2b/store/reducers';
import { SdipStatisticsByGroup, SdipStatisticsByGroupDetail } from '@hot-theme-nx/generated-api';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { OrderSortItem } from 'apps/hot-b2b/src/app/distributor/models'; 

@Component({
    selector: 'hot-sdip-group-detail-modal',
    templateUrl: './sdip-group-detail-modal.component.html',
})
export class SdipGroupDetailModalComponent implements OnInit {
    public groupData: SdipStatisticsByGroup;
    public groupDetails: SdipStatisticsByGroupDetail[];
    public modalSdipGroupDetail$: Observable<ModalsState>;

    public groupSort: OrderSortItem[] = [
        {
            name: this.translateService.instant('account.performance.sdip.sort-sku-name'),
            value: 'SkuName',
        },
        {
            name: this.translateService.instant('account.performance.sdip.sort-volume'),
            value: 'Volume:desc',
        },
    ];

    public readonly selectedFilters = {
        sort: this.groupSort[0],
    };

    constructor(private readonly store: Store<AppState>, private readonly translateService: TranslateService) {
        this.modalSdipGroupDetail$ = this.store.pipe(select(selectModalState(ModalIds.sdipGroupDetail)));
    }

    public ngOnInit(): void {
        this.modalSdipGroupDetail$.subscribe((modalState: ModalsState) => {
            if (modalState?.data?.groupData) {
                this.groupData = modalState.data.groupData;
                this.getGroupDetails();
            }
        });
    }

    public getGroupDetails(): void {
        if (this.groupData?.sdipStatisticsByGroupDetails) {
            if (this.selectedFilters.sort.value === 'SkuName') {
                this.groupData.sdipStatisticsByGroupDetails.sort((a, b) => {
                    if (a.brandGroup > b.brandGroup) {
                        return 1;
                    } else if (a.brandGroup === b.brandGroup) {
                        return 0;
                    } else {
                        return -1;
                    }
                });
            } else if (this.selectedFilters.sort.value === 'Volume:desc') {
                 this.groupData.sdipStatisticsByGroupDetails.sort((a, b) => b.actual - a.actual);
            }
        }
    }

    public setFilter(value: any): void {
        this.selectedFilters.sort = this.groupSort.find((sortItem: OrderSortItem) => sortItem.value === value.value);
        this.getGroupDetails();
    }
}
