import { Injectable } from '@angular/core';
import { ScriptModel } from '@hot-libs/shared-models';
declare let document: any;
declare const HnkCbClient: any;

@Injectable({
    providedIn: 'root',
})
export class DynamicScriptLoaderService {
    private readonly scripts = {
        visualWebsite: {
            src: 'scripts/visualWebsite.js',
            loaded: false,
        },
        hotJar: {
            src: 'scripts/hotJar.js',
            loaded: false,
        },
        googleAnalytics: {
            src: 'scripts/googleAnalytics.js',
            loaded: false,
        },
        ensighten: {
            src: 'scripts/ensighten.js',
            loaded: false,
        },
        emula: {
            src: 'scripts/emula.js',
            loaded: false,
        },
        chatbot: {
            src: 'scripts/webchat.js',
            loaded: false,
        },
    };

    public load(...scripts: string[]): Promise<ScriptModel[]> {
        const promises = [];
        scripts.forEach((script: string) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    private loadScript(name: string): Promise<{}> {
        if (name === 'chatbot') {
            this.chatBotLoadScript(name);
        } else {
            return new Promise((resolve) => {
                if (!this.scripts[name].loaded) {
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = this.scripts[name].src;
                    if (script.readyState) {
                        // IE
                        script.onreadystatechange = (): void => {
                            this.readyStateChange(script, resolve, name);
                        };
                    } else {
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    }
                    script.onerror = (_error: string): void => {
                        console.error('Error with injecting script ' + name);
                        resolve({ script: name, loaded: false, status: 'Loaded' });
                    };
                    if (this.scripts[name] !== 'chatbot') {
                        document.getElementsByTagName('head')[0].appendChild(script);
                    }
                } else {
                    resolve({ script: name, loaded: true, status: 'Already Loaded' });
                }
            });
        }
    }
    public chatBotLoadScript(name) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = this.scripts[name].src;
            if (script.readyState) {
                // IE
                script.onreadystatechange = (): void => {
                    this.readyStateChange(script, resolve, name);
                };
            } else {
                resolve({ script: name, loaded: true, status: 'Loaded' });
                resolve({ script: name, loaded: true, status: 'Loaded' });
            }
            script.onerror = (_error: string): void => {
                console.error('Error with injecting script ' + name);
                resolve({ script: name, loaded: false, status: 'Loaded' });
            };

            document.getElementsByTagName('body')[0].appendChild(script);
        });
    }
    public readyStateChange(script, resolve, name: string) {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
        }
    }
}
