import { Component, Input } from '@angular/core';
import { getBackgroundImageStyle } from '@hot-libs/helpers';

@Component({
    selector: 'hot-banner',
    templateUrl: './banner.component.html',
})
export class BannerComponent  {
    @Input() modifier: string;
    @Input() bannerData: any;


    backgroundImage(url: string) {
        return getBackgroundImageStyle(url);
    }
}
