import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ScreenDimensionService } from '@hot-libs/browser-specific';

import { isAllModalsClosed } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import includes from 'lodash/includes';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
    selector: 'hot-modal',
    templateUrl: './modal.component.html',
})
export class HotModalComponent implements OnDestroy {
    @HostListener('window:keydown', ['$event'])
    handleEscKeyDown(event: KeyboardEvent) {
        if ((event.key === 'Esc' || event.key === 'Escape') && this.modalOpening) {
            this.closeModal();
        }
    }

    public modalClosing: boolean;
    public modalOpening: boolean;
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    public isUpLg = () => this.screenDimensionService.upLg();

    @Input() public showCloseButton: boolean = true;
    @Input() public isFullscreen: boolean;
    @Input() public modalTransition: boolean = false;
    @Input() public modifier: string;
    @Input() public id: string;
    @Input() public title: string;
    @Input() public overlayClosed: boolean;
    @Input() public noBackground;
    @Input() public closable = true;
    @Input() public isBottomSheet = false;
    @Input() public isBlobModal = false;
    @Input() public closeIconHidden = false;
    @Input() public whiteCloseButton: boolean;
    @Input() public noBackgroundClose: boolean;
    @Input() pageName: string;
    @Input() isPopPilotUser:boolean = false;
    @Input() hasCalendar: boolean = false;
    @Input() set showModal(showModal: boolean) {
        this.modalOpening = showModal;
        if (showModal) {
            this.renderer.addClass(this.document.body, 'modal-opened');
            if (this.isFullscreen) {
                this.renderer.addClass(this.document.body, 'modal-opened--full-screen');
            }
            this.overlayClosed = true;
        } else {
            this.renderer.removeClass(this.document.body, 'modal-opened');
            this.renderer.removeClass(this.document.body, 'modal-opened--full-screen');
        }
    }
    get showModal() {
        return this.modalOpening;
    }
    @Output() public toggleModal: EventEmitter<void> = new EventEmitter();
    @Output() public onCloseClickEvent: EventEmitter<void> = new EventEmitter();

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly renderer: Renderer2,
        private readonly store: Store<AppState>,
        private readonly configurationService: ConfigurationService,
        public readonly screenDimensionService: ScreenDimensionService
    ) {}

    public onOverlayClicked(event: any): void {
        if (event.target) {
            const lsClass: any[] = event.target.classList || [];
            const isOverlay: boolean = this.noBackgroundClose ? false :  includes(lsClass, 'modal__dialog') || includes(lsClass, 'modal--opening');
          if (isOverlay) {
            if (this.closable) {
                this.overlayClosed = false;
            }

            this.closeModal();
        }
    }
}

    /**
     * close modal and remove body class 'modal-opened'
     */
    public closeModal(): void {
        this.onCloseClickEvent.emit();
        if (this.closable) {
            this.modalClosing = true;
            setTimeout(() => {
                this.toggleModal.emit();
                this.modalClosing = false;
            }, 300);
        }
    }

    public ngOnDestroy(): void {
        this.store.pipe(select(isAllModalsClosed), take(1)).subscribe((isAllModalClosed: boolean) => {
            if (isAllModalClosed) {
                this.renderer.removeClass(this.document.body, 'modal-opened');
                this.renderer.removeClass(this.document.body, 'modal-opened--full-screen');
            }
        });
    }
}
