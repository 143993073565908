import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { LocalStorageService, ScreenDimensionService } from '@hot-libs/browser-specific';
import { HotUserExtended } from '@hot-libs/shared-models';
import { StorageKeys } from '@hot-libs/shared-types';
import { TranslateService } from '@ngx-translate/core';
import { OutletService } from 'apps/hot-b2b/src/app/shared/services';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { filter, first } from 'rxjs/operators';
import { InitDataManagerService } from '../../services/initdata-manager.service';
import { HotCarouselBanner } from '@hot-theme-nx/generated-api';
import { FeaturesService } from '../../services/features.service';
import { carouselDetails, carouselFetched } from '@hot-b2b/store/carousels/selector';
import { CacheClearService } from '../../services/cache-clear.service';

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);
@Component({
    selector: 'hot-outlet-selector',
    templateUrl: './outlet-selector.component.html',
})
export class OutletSelectorComponent implements OnInit {
    @ViewChild('search') public search: ElementRef;
    @ViewChild('selected') public selected: ElementRef;

    @Input() public userOutlets: any;
    @Input() public mode: string;
    @Input() public selectView: boolean;
    @Input() public isVsm: boolean;
    @Output() public setSelectedOutlet = new EventEmitter();

    public PATHS = PATHS;
    public dropdownIsOpen = false;
    public authUser$: Observable<HotUserExtended>;
    public carouselFetched$: Observable<boolean>;
    public carouselDetails$: Observable<HotCarouselBanner[]>;

    public semId: string;
    public notFoundSemId: string;
    public searchString = '';

    private _docClickSubscription: any;

    public applyFade = true;
    public isUpLg = () => this.screenDimensionService.upLg();
    public isMainPage = true;
    public carouselItems: HotCarouselBanner[] = [];

    constructor(
        private readonly route: ActivatedRoute,
        private readonly outletService: OutletService,
        private readonly store: Store<AppState>,
        private readonly localStorageService: LocalStorageService,
        private readonly translateService: TranslateService,
        private readonly featuresService: FeaturesService,
        public renderer: Renderer2,
        public screenDimensionService: ScreenDimensionService,
        private readonly initDataManagerService: InitDataManagerService,
        public readonly router: Router,
        private readonly clearCache: CacheClearService
    ) {
        this.authUser$ = this.store.pipe(select(authUser));
        if(this.router.url === '/') {
            this.isMainPage = true;
        } else {
            this.isMainPage =false;
        }
        this.carouselDetails$ = this.store.pipe(select(carouselDetails));
        this.carouselFetched$ = this.store.pipe(select(carouselFetched));
    }

    public ngOnInit(): void {
        this._docClickSubscription = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));

        if (this.mode === 'filter') {
            const allObject = {
                id: 'all',
                name: this.translateService.instant('shared.outlet-selector-all'),
            };
            const hasAllObject = this.userOutlets?.outlets[0].id === allObject.id;

            if (!hasAllObject) {
                this.userOutlets.outlets.unshift({});
            }

            this.userOutlets.outlets[0] = allObject;
            this.userOutlets.currentOutlet = allObject;
        }

        const currentSemId =
            this.route.snapshot.queryParams.semId ||
            this.route.snapshot.queryParams.semid ||
            localStorage.getItem(StorageKeys.currentOutletSemId);

        if (currentSemId) {
            this.selectOutletWithSemId(currentSemId);
        }
        if (this.featuresService.EazleEnabledOpco) {
            combineLatest([this.carouselDetails$, this.carouselFetched$])
            .pipe(
                filter((state) =>  !!state[1])
            ).subscribe((res) => {
                this.carouselItems = res[ 0 ].filter((banner: HotCarouselBanner) => banner.isActive && banner.heroBannerTitle);
            });
        }
    }

    private onDocumentClick(e: any): void {
        if (
            !e.target.closest('.outlets') &&
            !matches(e.target, '.outlets__title, .outlets__name') &&
            this.userOutlets.outlets?.length > 1
        ) {
            this.clickOutside();
        }
    }
    public clickOnOutlets() {
        if (!this.dropdownIsOpen && this.userOutlets.outlets?.length > 1) {
            this.dropdownIsOpen = true;

            setTimeout(() => {
                this.searchString = '';
                this.search.nativeElement.focus();
            }, 100);
        }
    }

    /**
     * Toggles the dropdown
     */
    public toggleDropdown(isOpen?: boolean): void {
        if (!this.dropdownIsOpen) {
            return;
        }

        setTimeout(() => {
            this.dropdownIsOpen = isOpen || !this.dropdownIsOpen;

            if (this.dropdownIsOpen) {
                this.searchString = '';
                setTimeout(() => this.search.nativeElement.focus());
            }
        }, 100);
    }

    /**
     * Closes dropdown if click outside
     */
    public clickOutside(): void {
        this.dropdownIsOpen = false;
    }

    public selectOutlet(outlet, event?): void {
        if (event) {
            event.stopPropagation();
        }

        if (this.userOutlets.currentOutlet.id === outlet.id) {
            this.dropdownIsOpen = false;
        }
        else if (this.mode === 'filter') {
                this.userOutlets.currentOutlet = outlet;
                this.dropdownIsOpen = false;
                this.setSelectedOutlet.emit(outlet);
        }
        else {
                this.outletService.setOutlet(outlet.id).subscribe(() => {
                    this.userOutlets.currentOutlet = outlet;
                    this.authUser$.pipe(first()).subscribe((userData: HotUserExtended) => {
                        this.dropdownIsOpen = false;
                        this.initDataManagerService.cacheDelete(userData, outlet);
                    });
                });
        }
    }  

    public selectOutletWithSemId(semId: string): void {
        const outlet = this.userOutlets.outlets.find((x) => x.semId === semId);

        if (!!outlet) {
            this.semId = semId;
            if (this.userOutlets.currentOutlet.id !== outlet.id) {
                localStorage.setItem(StorageKeys.currentOutletSemId, semId);
                this.selectOutlet(outlet);
            }
        } else {
            this.notFoundSemId = semId;
        }
    }

    ngAfterViewInit() {
        const outletElemWidth = this.selected.nativeElement.offsetWidth;
        if (this.selectView || this.isVsm) {
            this.applyFade = true;
        } else if (this.isUpLg() && outletElemWidth !== 0) {
            if (outletElemWidth >= 475 || this.dropdownIsOpen) {
                this.applyFade = false;
            }
        } else if (!this.isUpLg()) {
            this.applyFade = false;
        }
    }
    
}
