import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment, { Moment } from 'moment';

import { DateRangeModel } from '@hot-libs/shared-models';

@Component({
    selector: 'hot-date-selector',
    templateUrl: './date-selector.component.html',
})
export class DateSelectorComponent  {
    @Input() public dateRange: DateRangeModel;
    @Input() public minDate: Moment;
    @Output() public setDateRange = new EventEmitter<DateRangeModel>();

    public config = null;

    public ngOnInit(): void {
        this.config = {
            opens: 'left',
            min: this.minDate,
        };
    }

    public setStartDate($event: moment.Moment): void {
        this.dateRange.fromDate = $event.hours(0).minutes(0).seconds(0);
        this.setDateRange.emit(this.dateRange);
    }

    public setEndDate($event: moment.Moment): void {
        this.dateRange.toDate = $event.hours(23).minutes(59).seconds(59);
        this.setDateRange.emit(this.dateRange);
    }
}
