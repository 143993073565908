import { Action } from '@ngrx/store';

import { ECmsImagesActions } from './types';
import { HotCmsImage } from 'libs/generated-api/src/lib/models/hot-cms-image';

export class CmsImagesGet implements Action {
    public readonly type = ECmsImagesActions.CMS_IMAGES_PENDING;
    constructor(public payload: string[]) {}
}

export class CmsImagesGetSuccess implements Action {
    public readonly type = ECmsImagesActions.CMS_IMAGES_FETCHED;
    constructor(public payload: HotCmsImage[]) {}
}

export type CmsImagesActions = CmsImagesGet | CmsImagesGetSuccess;
