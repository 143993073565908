import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PrimeTemplate } from 'primeng/api';
import { DomHandler } from 'primeng/dom';

import moment, { Moment } from 'moment';
import { DateRangeModel } from '@hot-libs/shared-models';
import { StorageKeys } from 'libs/shared-types/src/lib/enums/storage-keys.enum';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { ConfigurationService } from '../../../shared/services/configuration.service';

export const CALENDAR_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HotNxCalendarComponent),
    multi: true,
};

export interface LocaleSettings {
    firstDayOfWeek?: number;
    dayNames: string[];
    dayNamesShort: string[];
    dayNamesMin: string[];
    monthNames: string[];
    monthNamesShort: string[];
    today: string;
    clear: string;
    dateFormat?: string;
    weekHeader?: string;
}

@Component({
    selector: 'hot-nx-calendar',
    templateUrl: './hot-nx-calendar.component.html',
    animations: [
        trigger('overlayAnimation', [
            state(
                'visible',
                style({
                    transform: 'translateY(0)',
                    opacity: 1,
                })
            ),
            state(
                'visibleTouchUI',
                style({
                    transform: 'translate(-50%,-50%)',
                    opacity: 1,
                })
            ),
            transition('void => visible', [
                style({ transform: 'translateY(5%)', opacity: 0 }),
                animate('{{showTransitionParams}}'),
            ]),
            transition('visible => void', [
                animate(
                    '{{hideTransitionParams}}',
                    style({
                        opacity: 0,
                        transform: 'translateY(5%)',
                    })
                ),
            ]),
            transition('void => visibleTouchUI', [
                style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)' }),
                animate('{{showTransitionParams}}'),
            ]),
            transition('visibleTouchUI => void', [
                animate(
                    '{{hideTransitionParams}}',
                    style({
                        opacity: 0,
                        transform: 'translate3d(-50%, -40%, 0) scale(0.9)',
                    })
                ),
            ]),
        ]),
    ],
    providers: [CALENDAR_VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotNxCalendarComponent implements OnInit, OnDestroy, ControlValueAccessor {
    public languageCode: string;

    @Input() modifier: string = '';

    public mondayOfSelectedWeek: moment.Moment;

    @Input() set selectedDate(date: moment.Moment) {
        // Workaround for errors with negative timezones
        if (date) {
            this.value = new Date(date.year(), date.month(), date.date());
        } else {
            this.value = null;
        }
    }

    @Input() set dateRange(range: DateRangeModel) {
        if (range) {
            this.value = [];
            this.value[0] = moment(range.fromDate).toDate();
            this.value[1] = moment(range.toDate).toDate();
            this.currentDate = moment(range.toDate).toDate();
            this.pastDate = moment(range.fromDate).toDate();
            this.defaultSelected = moment(range.fromDate).toDate();
            this.defaultToSelected = moment(range.toDate).toDate();
        }
    }

    @Input() isDayDisabledCallback?: (date: Moment) => boolean;

    @Input() availableDates?: Moment[];

    @Input() defaultDate: Date;

    @Input() style: any;

    @Input() styleClass: string;

    @Input() inputStyle: any;

    @Input() inputId: string;

    @Input() name: string;

    @Input() inputStyleClass: string;

    @Input() placeholder: string;

    @Input() ariaLabelledBy: string;

    @Input() disabled: any;

    @Input() dateFormat = 'dd.mm.yy';

    @Input() multipleSeparator = ',';

    @Input() rangeSeparator = '-';

    @Input() inline = false;

    @Input() showOtherMonths = true;

    @Input() selectOtherMonths: boolean;

    @Input() showIcon: boolean;

    @Input() appendTo: any;
    @Input() isPopPilotUser= false;

    @Input() readonlyInput: boolean;

    @Input() shortYearCutoff: any = '+10';

    @Input() monthNavigator: boolean;

    @Input() yearNavigator: boolean;

    @Input() hourFormat = '24';

    @Input() timeOnly: boolean;

    @Input() stepHour = 1;

    @Input() stepMinute = 1;

    @Input() stepSecond = 1;

    @Input() showSeconds = false;

    @Input() required: boolean;

    @Input() showOnFocus = true;

    @Input() dataType = 'date';

    @Input() selectionMode = 'single';

    @Input() maxDateCount: number;

    @Input() autoZIndex = true;

    @Input() baseZIndex = 0;

    @Input() panelStyleClass: string;

    @Input() panelStyle: any;

    @Input() keepInvalid = false;

    @Input() hideOnDateTimeSelect = true;

    @Input() numberOfMonths = 1;

    @Input() view = 'date';

    @Input() touchUI: boolean;

    @Input() timeSeparator = ':';

    @Input() showTransitionOptions = '225ms ease-out';

    @Input() hideTransitionOptions = '195ms ease-in';

    @Input() position: 'left' | 'right' = 'right';

    @Output() onFocus: EventEmitter<any> = new EventEmitter();

    @Output() onBlur: EventEmitter<any> = new EventEmitter();

    @Output() onClose: EventEmitter<any> = new EventEmitter();

    @Output() onSelect: EventEmitter<any> = new EventEmitter();

    @Output() onInput: EventEmitter<any> = new EventEmitter();

    @Output() onMonthChange: EventEmitter<any> = new EventEmitter();

    @Output() onYearChange: EventEmitter<any> = new EventEmitter();

    @Output() onClickOutside: EventEmitter<any> = new EventEmitter();

    @Output() onShow: EventEmitter<any> = new EventEmitter();

    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    _locale: LocaleSettings = this.locale;

    @Input() tabindex: number;

    @Input() pageName: string;

    @ViewChild('inputfield', { static: false }) inputfieldViewChild: ElementRef;

    @ViewChild('contentWrapper', { static: false }) set content(content: ElementRef) {
        this.contentViewChild = content;

        if (this.contentViewChild) {
            if (this.isMonthNavigate) {
                Promise.resolve(null).then(() => this.updateFocus());
                this.isMonthNavigate = false;
            } else {
                this.initFocusableCell();
            }
        }
    }

    value: any;

    contentViewChild: ElementRef;

    dates: any[];

    months: any[];

    monthPickerValues: any[];

    weekDays: string[];

    currentMonth: number;

    currentYear: number;

    currentHour: number;

    currentMinute: number;

    currentSecond: number;

    pm: boolean;

    mask: HTMLDivElement;

    maskClickListener: Function;

    overlay: HTMLDivElement;

    overlayVisible: boolean;

    onModelChange: Function = () => {
        // defining onModelChange property
    };

    onModelTouched: Function = () => {
        // defining onModelTouched property
    };

    calendarElement: any;

    timePickerTimer: any;

    documentClickListener: any;

    ticksTo1970: number;

    yearOptions: number[];

    focus: boolean;

    isKeydown: boolean;

    filled: boolean;

    inputFieldValue: string = null;

    _minDate: Date;

    _maxDate: Date;

    _showTime: boolean;

    _yearRange: string;

    preventDocumentListener: boolean;

    dateTemplate: TemplateRef<any>;

    disabledDateTemplate: TemplateRef<any>;

    monthNavigatorTemplate: TemplateRef<any>;

    yearNavigatorTemplate: TemplateRef<any>;

    _disabledDates: Date[];

    _disabledDays: number[];

    selectElement: any;

    todayElement: any;

    focusElement: any;

    documentResizeListener: any;

    navigationState: any = null;

    isMonthNavigate: boolean;

    year: number;

    e: string;

    isWeekCalendarEnabled: boolean = false;

    public mobRows = [1, 2, 3, 4];

    public webRows = [1, 2, 3];

    public selectedYear = null;

    public selectedMonth = null;

    private yearThreshold = 9;

    public isOpenedFirstTime: boolean;

    public dateTypeSelected: any;

    public currentDate: any;

    public pastDate: any;

    public spareTo: any;

    public spareFrom: any;

    public sFrom: any;

    public sTo: any;

    public defaultSelected: any;

    public defaultToSelected: any;

    public isHop = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';

    @Input() get minDate(): Date {
        return this._minDate;
    }

    set minDate(date: Date) {
        this._minDate = date;

        if (this.currentMonth != undefined && this.currentMonth != null && this.currentYear) {
            this.createMonths(this.currentMonth, this.currentYear);
        }
    }

    @Input() get maxDate(): Date {
        return this._maxDate;
    }

    set maxDate(date: Date) {
        this._maxDate = date;

        if (this.currentMonth != undefined && this.currentMonth != null && this.currentYear) {
            this.createMonths(this.currentMonth, this.currentYear);
        }
    }

    @Input() get disabledDates(): Date[] {
        return this._disabledDates;
    }

    set disabledDates(disabledDates: Date[]) {
        this._disabledDates = disabledDates;
        if (this.currentMonth != undefined && this.currentMonth != null && this.currentYear) {
            this.createMonths(this.currentMonth, this.currentYear);
        }
    }

    @Input() get disabledDays(): number[] {
        return this._disabledDays;
    }

    set disabledDays(disabledDays: number[]) {
        this._disabledDays = disabledDays;

        if (this.currentMonth != undefined && this.currentMonth != null && this.currentYear) {
            this.createMonths(this.currentMonth, this.currentYear);
        }
    }

    @Input() get yearRange(): string {
        return this._yearRange;
    }

    set yearRange(yearRange: string) {
        this._yearRange = yearRange;

        if (yearRange) {
            const years = yearRange.split(':');
            const yearStart = parseInt(years[0]);
            const yearEnd = parseInt(years[1]);

            this.populateYearOptions(yearStart, yearEnd);
        }
    }

    @Input() get showTime(): boolean {
        return this._showTime;
    }

    set showTime(showTime: boolean) {
        this._showTime = showTime;

        if (this.currentHour === undefined) {
            this.initTime(this.value || new Date());
        }
        this.updateInputfield();
    }

    get locale() {
        return this._locale;
    }

    @Input()
    set locale(newLocale: LocaleSettings) {
        this._locale = newLocale;

        if (this.view === 'date') {
            this.createWeekDays();
            this.createMonths(this.currentMonth, this.currentYear);
        } else if (this.view === 'month') {
            this.createMonthPickerValues();
        }
    }

    constructor(
        public el: ElementRef,
        public renderer: Renderer2,
        public cd: ChangeDetectorRef,
        public zone: NgZone,
        public readonly screenDimensionService: ScreenDimensionService,
        public readonly configurationService: ConfigurationService
    ) {}

    ngOnInit() {
        const date = this.defaultDate || new Date();
        this.languageCode = localStorage.getItem(StorageKeys.currentLanguage) || 'en-US';
        this.currentMonth = date.getMonth();
        this.currentYear = date.getFullYear();
    
        if (this.view === 'date') {
            this.createWeekDays();
            this.initTime(date);
            this.createMonths(this.currentMonth, this.currentYear);
            this.ticksTo1970 =
                ((1970 - 1) * 365 + Math.floor(1970 / 4) - Math.floor(1970 / 100) + Math.floor(1970 / 400)) *
                24 *
                60 *
                60 *
                10000000;
        } else if (this.view === 'month') {
            this.createMonthPickerValues();
        }

        const ua = navigator.userAgent;
        this.e = ua.match(/iPad/i) || ua.match(/iPhone/) ? 'touchstart' : 'click';
        this.isOpenedFirstTime = true;
        if (this.isPopPilotUser && this.pageName === 'checkout') {
            this.showOverlay();
        }
    }

    populateYearOptions(start, end) {
        this.yearOptions = [];

        for (let i = start; i <= end; i++) {
            this.yearOptions.push(i);
        }
    }

    createWeekDays() {
        this.weekDays = [];
        let dayIndex = this.locale.firstDayOfWeek;
        for (let i = 0; i < 7; i++) {
            this.weekDays.push(this.locale.dayNamesShort[dayIndex]);
            dayIndex = dayIndex == 6 ? 0 : dayIndex + 1;
        }
    }

    createMonthPickerValues() {
        this.monthPickerValues = [];
        this.locale.monthNames.forEach((month, index) => {
            this.monthPickerValues.push({
                name: month,
                index: index,
            });
        });
    }

    createMonths(month: number, year: number) {
        this.months = this.months = [];
        for (let i = 0; i < this.numberOfMonths; i++) {
            let m = month + i;
            let y = year;
            if (m > 11) {
                m = (m % 11) - 1;
                y = year + 1;
            }

            this.months.push(this.createMonth(m, y));
        }
    }

    getWeekNumber(date: Date) {
        const checkDate = new Date(date.getTime());
        checkDate.setDate(checkDate.getDate() + 4 - (checkDate.getDay() || 7));
        const time = checkDate.getTime();
        checkDate.setMonth(0);
        checkDate.setDate(1);
        return Math.floor(Math.round((time - checkDate.getTime()) / 86400000) / 7) + 1;
    }

    createMonth(month: number, year: number) {
        const dates = [];
        const firstDay = this.getFirstDayOfMonthIndex(month, year);
        const daysLength = this.getDaysCountInMonth(month, year);
        const prevMonthDaysLength = this.getDaysCountInPrevMonth(month, year);
        let dayNo = 1;
        const today = new Date();
        const weekNumbers = [];
        const monthRows = Math.ceil((daysLength + firstDay) / 7);

        for (let i = 0; i < monthRows; i++) {
            let week = [];
            weekNumbers.push(this.getWeekNumber(new Date(year, month, dayNo)));
            if (i == 0) {
                [week, dayNo] = this.weekMonth(week, month, year, today, firstDay, prevMonthDaysLength, dayNo);
            } else {
                for (let j = 0; j < 7; j++) {
                    if (dayNo > daysLength) {
                        const next = this.getNextMonthAndYear(month, year);
                        week.push({
                            day: dayNo - daysLength,
                            month: next.month,
                            year: next.year,
                            otherMonth: true,
                            today: this.isToday(today, dayNo - daysLength, next.month, next.year),
                            selectable: this.isSelectable(dayNo - daysLength, next.month, next.year, true),
                        });
                    } else {
                        week.push({
                            day: dayNo,
                            month,
                            year,
                            today: this.isToday(today, dayNo, month, year),
                            selectable: this.isSelectable(dayNo, month, year, false),
                        });
                    }

                    dayNo++;
                }
            }

            dates.push(week);
        }

        return {
            month,
            year,
            dates,
            weekNumbers,
        };
    }
    public weekMonth(week, month, year, today, firstDay, prevMonthDaysLength, dayNo) {
        for (let j = prevMonthDaysLength - firstDay + 1; j <= prevMonthDaysLength; j++) {
            const prev = this.getPreviousMonthAndYear(month, year);
            week.push({
                day: j,
                month: prev.month,
                year: prev.year,
                otherMonth: true,
                today: this.isToday(today, j, prev.month, prev.year),
                selectable: this.isSelectable(j, prev.month, prev.year, true),
            });
        }

        const remainingDaysLength = 7 - week.length;
        for (let j = 0; j < remainingDaysLength; j++) {
            week.push({
                day: dayNo,
                month,
                year,
                today: this.isToday(today, dayNo, month, year),
                selectable: this.isSelectable(dayNo, month, year, false),
            });
            dayNo++;
        }
        return [week, dayNo];
    }

    initTime(date: Date) {
        this.pm = date.getHours() > 11;

        if (this.showTime) {
            this.currentMinute = date.getMinutes();
            this.currentSecond = date.getSeconds();
            this.setCurrentHourPM(date.getHours());
        } else if (this.timeOnly) {
            this.currentMinute = 0;
            this.currentHour = 0;
            this.currentSecond = 0;
        }
    }

    navBackward(event) {
        event.stopPropagation();

        if (this.disabled) {
            event.preventDefault();
            return;
        }

        if (this.view === 'year') {
            const minYear = this.yearOptions[0];
            this.populateYearOptions(minYear - this.yearThreshold, minYear - 1);
        } else {
            this.isMonthNavigate = true;
            if (this.view === 'month') {
                this.decrementYear();
                setTimeout(() => {
                    this.updateFocus();
                }, 1);
            } else {
                if (this.currentMonth === 0) {
                    this.currentMonth = 11;
                    this.decrementYear();
                } else {
                    this.currentMonth--;
                }

                this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
                this.createMonths(this.currentMonth, this.currentYear);
            }
        }
    }

    navForward(event) {
        event.stopPropagation();

        if (this.disabled) {
            event.preventDefault();
            return;
        }
        if (this.view === 'year') {
            const maxYear = this.yearOptions[this.yearOptions.length - 1];
            this.populateYearOptions(maxYear + 1, maxYear + this.yearThreshold);
        } else {
            this.isMonthNavigate = true;

            if (this.view === 'month') {
                this.incrementYear();
                setTimeout(() => {
                    this.updateFocus();
                }, 1);
            } else {
                if (this.currentMonth === 11) {
                    this.currentMonth = 0;
                    this.incrementYear();
                } else {
                    this.currentMonth++;
                }

                this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
                this.createMonths(this.currentMonth, this.currentYear);
            }
        }
    }

    decrementYear() {
        this.currentYear--;

        if (this.yearNavigator && this.currentYear < this.yearOptions[0]) {
            const difference = this.yearOptions[this.yearOptions.length - 1] - this.yearOptions[0];
            this.populateYearOptions(
                this.yearOptions[0] - difference,
                this.yearOptions[this.yearOptions.length - 1] - difference
            );
        }
    }

    incrementYear() {
        this.currentYear++;

        if (this.yearNavigator && this.currentYear > this.yearOptions[this.yearOptions.length - 1]) {
            const difference = this.yearOptions[this.yearOptions.length - 1] - this.yearOptions[0];
            this.populateYearOptions(
                this.yearOptions[0] + difference,
                this.yearOptions[this.yearOptions.length - 1] + difference
            );
        }
    }

    onDateSelect(event, dateMeta) {
        if (this.disabled || !dateMeta.selectable) {
            event.preventDefault();
            return;
        }

        if (this.isMultipleSelection() && this.isSelected(dateMeta)) {
            this.value = this.value.filter((date) => {
                return !this.isDateEquals(date, dateMeta);
            });
            this.updateModel(this.value);
        }
        else if (this.shouldSelectDate(dateMeta)) {
                this.selectDate(dateMeta);
        }

        if (this.isSingleSelection() && this.hideOnDateTimeSelect) {
            setTimeout(() => {
                event.preventDefault();
                this.hideOverlay();

                if (this.mask) {
                    this.disableModality();
                }

                this.cd.markForCheck();
            }, 150);
        }
        this.updateInputfield();
        event.preventDefault();
    }

    shouldSelectDate(_dateMeta) {
        if (this.isMultipleSelection()) {
            const checkValue = this.value ? this.value.length : 0;

            return this.maxDateCount != null ? this.maxDateCount > checkValue : true;
        } else {
            return true;
        }
    }

    onMonthSelect(event, index) {
        if (!DomHandler.hasClass(event.target, 'ui-state-disabled')) {
            this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
        }
    }

    updateInputfield() {
        let formattedValue = '';

        if (this.value) {
            if (this.isSingleSelection()) {
                formattedValue = this.formatDateTime(this.value);
            } else if (this.isMultipleSelection()) {
                for (let i = 0; i < this.value.length; i++) {
                    const dateAsString = this.formatDateTime(this.value[i]);
                    formattedValue += dateAsString;
                    if (i !== this.value.length - 1) {
                        formattedValue += this.multipleSeparator + ' ';
                    }
                }
            } else if (this.isRangeSelection()) {
                formattedValue = this.rangeSelection(formattedValue);
            }
        }

        this.inputFieldValue = formattedValue;
        this.updateFilledState();
        if (this.inputfieldViewChild?.nativeElement) {
            this.inputfieldViewChild.nativeElement.value = this.inputFieldValue;
        }
    }
    public rangeSelection(formattedValue) {
        if (this.value?.length) {
            const startDate = this.value[0];
            const endDate = this.value[1];

            formattedValue = this.formatDateTime(startDate);
            if (endDate) {
                formattedValue += ' ' + this.rangeSeparator + ' ' + this.formatDateTime(endDate);
            }
        }
        return formattedValue;
    }

    formatDateTime(date) {
        let formattedValue = null;
        if (date) {
            if (this.timeOnly) {
                formattedValue = this.formatTime(date);
            } else {
                formattedValue = this.formatDate(date, this.getDateFormat());
                if (this.showTime) {
                    formattedValue += ' ' + this.formatTime(date);
                }
            }
        }

        return formattedValue;
    }

    setCurrentHourPM(hours: number) {
        if (this.hourFormat == '12') {
            this.pm = hours > 11;
            if (hours >= 12) {
                this.currentHour = hours == 12 ? 12 : hours - 12;
            } else {
                this.currentHour = hours == 0 ? 12 : hours;
            }
        } else {
            this.currentHour = hours;
        }
    }

    selectDate(dateMeta) {
        let date = new Date(dateMeta.year, dateMeta.month, dateMeta.day);
        if (this.showTime) {
            date = this.selectHour(date);

            date.setMinutes(this.currentMinute);
            date.setSeconds(this.currentSecond);
        }

        if (this.minDate && this.minDate > date) {
            date = this.minDate;
            this.setCurrentHourPM(date.getHours());
            this.currentMinute = date.getMinutes();
            this.currentSecond = date.getSeconds();
        }

        if (this.maxDate && this.maxDate < date) {
            date = this.maxDate;
            this.setCurrentHourPM(date.getHours());
            this.currentMinute = date.getMinutes();
            this.currentSecond = date.getSeconds();
        }

        if (this.isSingleSelection()) {
            this.updateModel(date);
            const momentDate = moment(date);
            this.mondayOfSelectedWeek = momentDate;
            this.onSelect.emit(momentDate);
        } else if (this.isMultipleSelection()) {
            this.updateModel(this.value ? [...this.value, date] : [date]);
        } else if (this.isRangeSelection()) {
            if (this.value?.length) {
                let startDate = this.value[0];
                let endDate = this.value[1];
                [startDate, endDate] = this.dateRangeFunction(startDate, endDate, date);
                this.updateModel([startDate, endDate]);
                this.updateValueModel();
                this.updateDataTypeValueModel();
            } else {
                this.updateModel([date, null]);
            }
        }
    }
    public updateValueModel() {
        if (this.value[0] && this.isHop()) {
            document.getElementById('main-wrapper').style.backgroundColor = 'white';
            document.getElementById('ranget').style.backgroundColor = '#ebebeb';
        }
        if (this.value[0] && this.value[1] && this.isHop()) {
            document.getElementById('ranget').style.backgroundColor = 'white';
            this.hideOverlay();
        }
    }
    public updateDataTypeValueModel() {
        if (this.value[0] && this.isHop() && this.dateTypeSelected == 'To') {
            this.hideOverlay();
        }
        if (this.value[0] && this.value[1] && !this.isHop()) {
            this.hideOverlay();
        }
    }

    public dateRangeFunction(startDate, endDate, date) {
        const range: DateRangeModel = {};
        if (!endDate && date.getTime() >= startDate.getTime()) {
            endDate = date;
            this.spareTo = endDate;
        } else {
            startDate = date;
            endDate = null;
            if (this.isHop()) {
                this.fromDataType(range, date);
                if (this.dateTypeSelected == 'To') {
                    [startDate, endDate] = this.toDataType(endDate, date);
                }
            }
        }
        return [startDate, endDate];
    }
    public fromDataType(range, date) {
        this.sFrom = null;
        this.defaultSelected = null;
        this.currentDate = this.spareTo ? this.spareTo : moment(range.toDate).toDate();
        if (this.dateTypeSelected == 'From') {
            this.spareFrom = date;
        }
    }
    public toDataType(endDate, date) {
        this.currentDate = date;
        let startDate = this.spareFrom ? this.spareFrom : this.pastDate;
        endDate = startDate < date ? date : endDate;
        this.spareTo = endDate;
        return [startDate, endDate];
    }

    public selectHour(date) {
        if (this.hourFormat == '12') {
            if (this.currentHour === 12) {
                date.setHours(this.pm ? 12 : 0);
            } else {
                date.setHours(this.pm ? this.currentHour + 12 : this.currentHour);
            }
        } else {
            date.setHours(this.currentHour);
        }
        return date;
    }

    updateModel(value) {
        this.value = value;

        if (this.dataType == 'date') {
            this.onModelChange(this.value);
        } else if (this.dataType == 'string') {
            if (this.isSingleSelection()) {
                this.onModelChange(this.formatDateTime(this.value));
            } else {
                let stringArrValue = null;
                if (this.value) {
                    stringArrValue = this.value.map((date) => this.formatDateTime(date));
                }
                this.onModelChange(stringArrValue);
            }
        }
    }

    getFirstDayOfMonthIndex(month: number, year: number) {
        const day = new Date();
        day.setDate(1);
        day.setMonth(month);
        day.setFullYear(year);

        const dayIndex = day.getDay() + this.getSundayIndex();
        return dayIndex >= 7 ? dayIndex - 7 : dayIndex;
    }

    getDaysCountInMonth(month: number, year: number) {
        return 32 - this.daylightSavingAdjust(new Date(year, month, 32)).getDate();
    }

    getDaysCountInPrevMonth(month: number, year: number) {
        const prev = this.getPreviousMonthAndYear(month, year);
        return this.getDaysCountInMonth(prev.month, prev.year);
    }

    getPreviousMonthAndYear(month: number, year: number) {
        let m, y;

        if (month === 0) {
            m = 11;
            y = year - 1;
        } else {
            m = month - 1;
            y = year;
        }

        return { month: m, year: y };
    }

    getNextMonthAndYear(month: number, year: number) {
        let m, y;

        if (month === 11) {
            m = 0;
            y = year + 1;
        } else {
            m = month + 1;
            y = year;
        }

        return { month: m, year: y };
    }

    getSundayIndex() {
        return this.locale.firstDayOfWeek > 0 ? 7 - this.locale.firstDayOfWeek : 0;
    }

    isSelected(dateMeta): boolean {
        if (this.value) {
            if (this.isSingleSelection()) {
                return this.isDateEquals(this.value, dateMeta);
            } else if (this.isMultipleSelection()) {
                let selected = false;
                for (const date of this.value) {
                    selected = this.isDateEquals(date, dateMeta);
                    if (selected) {
                        break;
                    }
                }

                return selected;
            } else if (this.isRangeSelection()) {
                return this.selectedRange(dateMeta);
            }
        } else {
            return false;
        }
    }
    public selectedRange(dateMeta) {
        if (this.value[1]) {
            return (
                this.isDateEquals(this.value[0], dateMeta) ||
                this.isDateEquals(this.value[1], dateMeta) ||
                this.isDateBetween(this.value[0], this.value[1], dateMeta)
            );
        } else {
            return this.isDateEquals(this.value[0], dateMeta);
        }
    }

    isDateStart(dateMeta): boolean {
        if (this.isFullRangeExist()) {
            const date: Date = new Date(dateMeta.year, dateMeta.month, dateMeta.day);
            return this.isEqDate(date, this.value[0]) && this.value[1].getTime() > date.getTime();
        } else {
            return false;
        }
    }

    isDateEnd(dateMeta): boolean {
        if (this.isFullRangeExist()) {
            const date: Date = new Date(dateMeta.year, dateMeta.month, dateMeta.day);
            return this.isEqDate(date, this.value[1]) && this.value[0].getTime() < date.getTime();
        } else {
            return false;
        }
    }

    private isEqDate(date1: Date, date2: Date) {
        return moment(date1).format('DD/MM/YYYY') === moment(date2).format('DD/MM/YYYY');
    }

    isInSelectedWeek(dateMeta): boolean {
        if (this.mondayOfSelectedWeek) {
            let startDate = this.mondayOfSelectedWeek.toDate();
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
            let endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6, 0, 0, 0, 0);
            let inputDate = new Date(dateMeta.year, dateMeta.month, dateMeta.day, 0, 0, 0, 0);
            return inputDate >= startDate && inputDate <= endDate;
        }
        return false;
    }
    isInsideRange(dateMeta): boolean {
        if (this.isSingleSelection()) {
            return true;
        }

        const between = false;
        if (this.value?.[0] && this.value?.[1]) {
            const date: Date = new Date(dateMeta.year, dateMeta.month, dateMeta.day);
            return this.value[0].getTime() < date.getTime() && this.value[1].getTime() > date.getTime();
        }

        return between;
    }

    isMonthSelected(month: number): boolean {
        const checkMonth = Array.isArray(this.value) ? this.value[0].getDate() : this.value.getDate();
        const day = this.value ? checkMonth : 1;

        return this.isSelected({ year: this.currentYear, month, day, selectable: true });
    }

    isDateEquals(value, dateMeta) {
        if (value) {
            return (
                value.getDate() === dateMeta.day &&
                value.getMonth() === dateMeta.month &&
                value.getFullYear() === dateMeta.year
            );
        } else {
            return false;
        }
    }

    isDateBetween(start, end, dateMeta) {
        const between = false;
        if (start && end) {
            const date: Date = new Date(dateMeta.year, dateMeta.month, dateMeta.day);
            return start.getTime() <= date.getTime() && end.getTime() >= date.getTime();
        }

        return between;
    }

    isSingleSelection(): boolean {
        return this.selectionMode === 'single';
    }

    isRangeSelection(): boolean {
        return this.selectionMode === 'range';
    }

    isMultipleSelection(): boolean {
        return this.selectionMode === 'multiple';
    }

    isToday(today, day, month, year): boolean {
        return today.getDate() === day && today.getMonth() === month && today.getFullYear() === year;
    }

    isSelectable(day, month, year, _otherMonth): boolean {
        let validMin = true;
        let validMax = true;
        let validDate = true;
        let validDay = true;

        if (this.minDate) {
            validMin = this.minDateYear(year, month, day);
        }

        if (this.maxDate) {
            validMax = this.maxDateYear(year, month, day);
        }

        if (this.disabledDates) {
            validDate = !this.isDateDisabled(day, month, year);
        }

        if (this.disabledDays) {
            validDay = !this.isDayDisabled(day, month, year);
        }

        return validMin && validMax && validDate && validDay;
    }
    public minDateYear(year, month, day) {
        if (this.minDate.getFullYear() > year) {
            return false;
        } else if (this.minDate.getFullYear() === year) {
            if (this.minDate.getMonth() > month) {
                return false;
            } else if (this.minDate.getMonth() === month) {
                if (this.minDate.getDate() > day) {
                    return false;
                }
            }
        }
        return true;
    }

    public maxDateYear(year, month, day) {
        if (this.maxDate.getFullYear() < year) {
            return false;
        } else if (this.maxDate.getFullYear() === year) {
            if (this.maxDate.getMonth() < month) {
                return false;
            } else if (this.maxDate.getMonth() === month) {
                if (this.maxDate.getDate() < day) {
                    return false;
                }
            }
        }
        return true;
    }

    isDateDisabled(day: number, month: number, year: number): boolean {
        if (this.disabledDates) {
            for (const disabledDate of this.disabledDates) {
                if (
                    disabledDate.getFullYear() === year &&
                    disabledDate.getMonth() === month &&
                    disabledDate.getDate() === day
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    isDayDisabled(day: number, month: number, year: number): boolean {
        if (this.disabledDays) {
            const weekday = new Date(year, month, day);
            const weekdayNumber = weekday.getDay();
            return this.disabledDays.indexOf(weekdayNumber) !== -1;
        }
        return false;
    }

    isDayAvailable(date: any) {
        if (!this.isDayDisabledCallback) {
            return false;
        }

        const momentDate = moment(date);
        return this.isDayDisabledCallback(momentDate);
    }

    onInputFocus(event: Event) {
        this.focus = true;
        if (this.showOnFocus) {
            this.showOverlay();
        }
        this.onFocus.emit(event);
    }

    onInputClick(_event: Event) {
        if (this.overlay && this.autoZIndex) {
            this.overlay.style.zIndex = String(this.baseZIndex + ++DomHandler.zindex);
        }
        if (this.showOnFocus && !this.overlayVisible) {
            this.showOverlay();
        }
    }

    onInputBlur(event: Event) {
        this.focus = false;
        this.onBlur.emit(event);
        if (!this.keepInvalid) {
            this.updateInputfield();
        }
        this.onModelTouched();
    }

    onButtonClick(_event, inputfield) {
        if (!this.overlayVisible) {
            inputfield.focus();
            this.showOverlay();
        } else {
            this.hideOverlay();
        }
    }

    onPrevButtonClick(event) {
        this.navigationState = { backward: true, button: true };
        this.navBackward(event);
    }

    onNextButtonClick(event) {
        this.navigationState = { backward: false, button: true };
        this.navForward(event);
    }

    //TO DO: remove
    updateFocus() {
        let cell;
        if (this.navigationState) {
            this.navigationState = null;
        } else {
            this.initFocusableCell();
        }
    }

    initFocusableCell() {
        let cell;
        if (this.view === 'month') {
            const cells = DomHandler.find(
                this.contentViewChild.nativeElement,
                '.p-monthpicker .p-monthpicker-month:not(.p-state-disabled)'
            );
            const selectedCell = DomHandler.findSingle(
                this.contentViewChild.nativeElement,
                '.p-monthpicker .p-monthpicker-month.p-state-highlight'
            );
            cells.forEach((tabCell) => (tabCell.tabIndex = -1));
            cell = selectedCell || cells[0];

            if (cells.length === 0) {
                const disabledCells = DomHandler.find(
                    this.contentViewChild.nativeElement,
                    '.p-monthpicker .p-monthpicker-month.p-state-disabled[tabindex = "0"]'
                );
                disabledCells.forEach((disabledCell) => (disabledCell.tabIndex = -1));
            }
        } else {
            cell = DomHandler.findSingle(this.contentViewChild.nativeElement, 'a.p-state-active');
            if (!cell) {
                const todayCell = DomHandler.findSingle(
                    this.contentViewChild.nativeElement,
                    'td.p-datepicker-today a:not(.p-state-disabled)'
                );
                if (todayCell) {
                    cell = todayCell;
                } else {
                    cell = DomHandler.findSingle(this.contentViewChild.nativeElement, '.p-datepicker-calendar td a');
                }
            }
        }

        if (cell) {
            cell.tabIndex = '0';
        }
    }

    trapFocus(event) {
        event.preventDefault();
        let focusableElements = DomHandler.getFocusableElements(this.contentViewChild.nativeElement);

        if (focusableElements && focusableElements.length > 0) {
            if (!document.activeElement) {
                focusableElements[0].focus();
            } else {
                this.indexedFocus(focusableElements, event);
            }
        }
    }
    public indexedFocus(focusableElements, event) {
        const focusedIndex = focusableElements.indexOf(document.activeElement);

        if (event.shiftKey) {
            if (focusedIndex == -1 || focusedIndex === 0) {
                focusableElements[focusableElements.length - 1].focus();
            } else {
                focusableElements[focusedIndex - 1].focus();
            }
        }
        else if (focusedIndex == -1 || focusedIndex === focusableElements.length - 1) {
                focusableElements[0].focus();
        }
        else {
                focusableElements[focusedIndex + 1].focus();
        }
    }
    onMonthDropdownChange(m: string) {
        this.currentMonth = parseInt(m);
        this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
        this.createMonths(this.currentMonth, this.currentYear);
    }

    onYearDropdownChange(y: string) {
        this.currentYear = parseInt(y);
        this.onYearChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
        this.createMonths(this.currentMonth, this.currentYear);
    }

    convertTo24Hour = function (hours: number, pm: boolean) {
        if (this.hourFormat == '12') {
            if (hours === 12) {
                return pm ? 12 : 0;
            } else {
                return pm ? hours + 12 : hours;
            }
        }
        return hours;
    };

    validateTime(hour: number, minute: number, second: number, pm: boolean) {
        let value = this.value;
        const convertedHour = this.convertTo24Hour(hour, pm);
        if (this.isRangeSelection()) {
            value = this.value[1] || this.value[0];
        }
        if (this.isMultipleSelection()) {
            value = this.value[this.value.length - 1];
        }
        const valueDateString = value ? value.toDateString() : null;
        if (this.minDate && valueDateString && this.minDate.toDateString() === valueDateString) {
            return this.getMinHours(convertedHour, minute, second);
        }

        if (this.maxDate && valueDateString && this.maxDate.toDateString() === valueDateString) {
            return this.getMaxHours(convertedHour, minute, second);
        }
        return true;
    }
    public getMinHours(convertedHour, minute, second) {
        if (this.minDate.getHours() > convertedHour) {
            return false;
        }
        if (this.minDate.getHours() === convertedHour) {
            if (this.minDate.getMinutes() > minute) {
                return false;
            }
            if (this.minDate.getMinutes() === minute) {
                if (this.minDate.getSeconds() > second) {
                    return false;
                }
            }
        }
    }
    public getMaxHours(convertedHour, minute, second) {
        if (this.maxDate.getHours() < convertedHour) {
            return false;
        }
        if (this.maxDate.getHours() === convertedHour) {
            if (this.maxDate.getMinutes() < minute) {
                return false;
            }
            if (this.maxDate.getMinutes() === minute) {
                if (this.maxDate.getSeconds() < second) {
                    return false;
                }
            }
        }
    }

    incrementHour(event) {
        const prevHour = this.currentHour;
        let newHour = this.currentHour + this.stepHour;
        let newPM = this.pm;

        if (this.hourFormat == '24') {
            newHour = newHour >= 24 ? newHour - 24 : newHour;
        } else if (this.hourFormat == '12') {
            // Before the AM/PM break, now after
            if (prevHour < 12 && newHour > 11) {
                newPM = !this.pm;
            }
            newHour = newHour >= 13 ? newHour - 12 : newHour;
        }

        if (this.validateTime(newHour, this.currentMinute, this.currentSecond, newPM)) {
            this.currentHour = newHour;
            this.pm = newPM;
        }
        event.preventDefault();
    }

    onTimePickerElementMouseDown(event: Event, type: number, direction: number) {
        if (!this.disabled) {
            this.repeat(event, null, type, direction);
            event.preventDefault();
        }
    }

    onTimePickerElementMouseUp(_event: Event) {
        if (!this.disabled) {
            this.clearTimePickerTimer();
            this.updateTime();
        }
    }

    repeat(event: Event, interval: number, type: number, direction: number) {
        const i = interval || 500;

        this.clearTimePickerTimer();
        this.timePickerTimer = setTimeout(() => {
            this.repeat(event, 100, type, direction);
        }, i);

        switch (type) {
            case 0:
                if (direction === 1) {
                    this.incrementHour(event);
                } else {
                    this.decrementHour(event);
                }
                break;

            case 1:
                if (direction === 1) {
                    this.incrementMinute(event);
                } else {
                    this.decrementMinute(event);
                }
                break;

            case 2:
                if (direction === 1) {
                    this.incrementSecond(event);
                } else {
                    this.decrementSecond(event);
                }
                break;
        }

        this.updateInputfield();
    }

    clearTimePickerTimer() {
        if (this.timePickerTimer) {
            clearTimeout(this.timePickerTimer);
        }
    }

    decrementHour(event) {
        let newHour = this.currentHour - this.stepHour;
        let newPM = this.pm;

        if (this.hourFormat == '24') {
            newHour = newHour < 0 ? 24 + newHour : newHour;
        } else if (this.hourFormat == '12') {
            // If we were at noon/midnight, then switch
            if (this.currentHour === 12) {
                newPM = !this.pm;
            }
            newHour = newHour <= 0 ? 12 + newHour : newHour;
        }

        if (this.validateTime(newHour, this.currentMinute, this.currentSecond, newPM)) {
            this.currentHour = newHour;
            this.pm = newPM;
        }

        event.preventDefault();
    }

    incrementMinute(event) {
        let newMinute = this.currentMinute + this.stepMinute;
        newMinute = newMinute > 59 ? newMinute - 60 : newMinute;
        if (this.validateTime(this.currentHour, newMinute, this.currentSecond, this.pm)) {
            this.currentMinute = newMinute;
        }

        event.preventDefault();
    }

    decrementMinute(event) {
        let newMinute = this.currentMinute - this.stepMinute;
        newMinute = newMinute < 0 ? 60 + newMinute : newMinute;
        if (this.validateTime(this.currentHour, newMinute, this.currentSecond, this.pm)) {
            this.currentMinute = newMinute;
        }

        event.preventDefault();
    }

    incrementSecond(event) {
        let newSecond = this.currentSecond + this.stepSecond;
        newSecond = newSecond > 59 ? newSecond - 60 : newSecond;
        if (this.validateTime(this.currentHour, this.currentMinute, newSecond, this.pm)) {
            this.currentSecond = newSecond;
        }

        event.preventDefault();
    }

    decrementSecond(event) {
        let newSecond = this.currentSecond - this.stepSecond;
        newSecond = newSecond < 0 ? 60 + newSecond : newSecond;
        if (this.validateTime(this.currentHour, this.currentMinute, newSecond, this.pm)) {
            this.currentSecond = newSecond;
        }

        event.preventDefault();
    }

    updateTime() {
        let value = this.value;
        if (this.isRangeSelection()) {
            value = this.value[1] || this.value[0];
        }
        if (this.isMultipleSelection()) {
            value = this.value[this.value.length - 1];
        }
        value = value ? new Date(value.getTime()) : new Date();

        if (this.hourFormat == '12') {
            value = this.currentHourFormat(value);
        } else {
            value.setHours(this.currentHour);
        }

        value.setMinutes(this.currentMinute);
        value.setSeconds(this.currentSecond);
        if (this.isRangeSelection()) {
            if (this.value[1]) {
                value = [this.value[0], value];
            } else {
                value = [value, null];
            }
        }

        if (this.isMultipleSelection()) {
            value = [...this.value.slice(0, -1), value];
        }

        this.updateModel(value);
        this.onSelect.emit(value);
        this.updateInputfield();
    }
    public currentHourFormat(value) {
        if (this.currentHour === 12) {
            value.setHours(this.pm ? 12 : 0);
        } else {
            value.setHours(this.pm ? this.currentHour + 12 : this.currentHour);
        }
        return value;
    }

    toggleAMPM(event) {
        const newPM = !this.pm;
        if (this.validateTime(this.currentHour, this.currentMinute, this.currentSecond, newPM)) {
            this.pm = newPM;
            this.updateTime();
        }
        event.preventDefault();
    }

    onUserInput(event) {
        // IE 11 Workaround for input placeholder : https://github.com/primefaces/primeng/issues/2026
        if (!this.isKeydown) {
            return;
        }
        this.isKeydown = false;

        const val = event.target.value;
        try {
            const value = this.parseValueFromString(val);
            if (this.isValidSelection(value)) {
                this.updateModel(value);
                this.updateUI();
            }
        } catch (err) {
            // invalid date
            this.updateModel(null);
        }

        this.filled =  val?.length ?? false;
        this.onInput.emit(event);
    }

    isValidSelection(value): boolean {
        let isValid = true;
        if (this.isSingleSelection()) {
            if (!this.isSelectable(value.getDate(), value.getMonth(), value.getFullYear(), false)) {
                isValid = false;
            }
        } else if (value.every((v) => this.isSelectable(v.getDate(), v.getMonth(), v.getFullYear(), false))) {
            if (this.isRangeSelection()) {
                isValid = value.length > 1 && value[1] > value[0] ? true : false;
            }
        }
        return isValid;
    }

    parseValueFromString(text: string): Date | Date[] {
        if (!text || text.trim().length === 0) {
            return null;
        }

        let value: any;

        if (this.isSingleSelection()) {
            value = this.parseDateTime(text);
        } else if (this.isMultipleSelection()) {
            const tokens = text.split(this.multipleSeparator);
            value = [];
            for (const token of tokens) {
                value.push(this.parseDateTime(token.trim()));
            }
        } else if (this.isRangeSelection()) {
            const tokens = text.split(' ' + this.rangeSeparator + ' ');
            value = [];
            for (let i = 0; i < tokens.length; i++) {
                value[i] = this.parseDateTime(tokens[i].trim());
            }
        }

        return value;
    }

    parseDateTime(text): Date {
        let date: Date;
        const parts: string[] = text.split(' ');

        if (this.timeOnly) {
            date = new Date();
            this.populateTime(date, parts[0], parts[1]);
        } else {
            const dateFormat = this.getDateFormat();
            if (this.showTime) {
                const ampm = this.hourFormat == '12' ? parts.pop() : null;
                const timeString = parts.pop();

                date = this.parseDate(parts.join(' '), dateFormat);
                this.populateTime(date, timeString, ampm);
            } else {
                date = this.parseDate(text, dateFormat);
            }
        }

        return date;
    }

    populateTime(value, timeString, ampm) {
        if (this.hourFormat == '12' && !ampm) {
            throw new Error('Invalid Time');
        }

        this.pm = ampm === 'PM' || ampm === 'pm';
        const time = this.parseTime(timeString);
        value.setHours(time.hour);
        value.setMinutes(time.minute);
        value.setSeconds(time.second);
    }

    updateUI() {
        let date = null;
        if (this.isHop() && this.isRangeSelection()) {
            date = this.dateTypeSelected == 'From' ? this.value[0] : this.value[1];
        } else {
            date = this.defaultDate || new Date();
        }
        this.currentMonth = date.getMonth();
        this.currentYear = date.getFullYear();
        this.createMonths(this.currentMonth, this.currentYear);

        if (this.showTime || this.timeOnly) {
            this.setCurrentHourPM(date.getHours());
            this.currentMinute = date.getMinutes();
            this.currentSecond = date.getSeconds();
        }
    }

    showOverlay() {
        if (!this.overlayVisible) {
            this.updateUI();
            this.overlayVisible = true;
        }
    }

    hideOverlay() {
        this.overlayVisible = false;
        this.clearTimePickerTimer();

        if (this.touchUI) {
            this.disableModality();
        }
        this.sFrom = this.value[0];
        this.sTo = this.value[1] ? this.value[1] : this.spareTo;
    }

    toggle(event, dateselect?: string) {
        const path = event?.path || (event?.composedPath && event.composedPath());
        if (!this.inline && path.id != 'caption') {
            this.visibleOverlay(dateselect);
            this.inputfieldViewChild.nativeElement.blur();
        }
    }
    public visibleOverlay(dateselect) {
        if (!this.overlayVisible) {
            if (this.isHop() && this.isRangeSelection()) {
                if (dateselect == 'From') {
                    document.getElementById('main-wrapper').style.backgroundColor = '#ebebeb';
                } else {
                    document.getElementById('ranget').style.backgroundColor = '#ebebeb';
                }
                this.dateTypeSelected = dateselect;
            }
            this.showOverlay();
        } else {
            this.hideOverlay();
        }
    }

    setDateHighlight(date) {
        if (this.isHop() && this.isRangeSelection()) {
            let spared = this.sFrom ? this.sFrom : this.defaultSelected;
            let sparet = this.sTo ? this.sTo : this.defaultToSelected;
            return (
                this.isDateEquals(spared, date) || (this.isDateEquals(sparet, date) && moment(date).toDate() > spared)
            );
        } else {
            return false;
        }
    }

    onOverlayAnimationStart(event: AnimationEvent) {
        switch (event.toState) {
            case 'visible':
            case 'visibleTouchUI':
                if (!this.inline) {
                    this.inlineElement(event);
                }
                break;

            case 'void':
                this.onOverlayHide();
                if (this.isRangeSelection()) {
                    const range: DateRangeModel = {};
                    if (
                        this.isHop() &&
                        (document.getElementById('main-wrapper').style.backgroundColor == 'rgb(235, 235, 235)' ||
                            document.getElementById('ranget').style.backgroundColor == 'rgb(235, 235, 235)')
                    ) {
                        document.getElementById('main-wrapper').style.backgroundColor = 'white';
                        document.getElementById('ranget').style.backgroundColor = 'white';
                    }
                    if (!this.value[1]) {
                        this.valueForHop(range);
                    }

                    range.fromDate = moment(this.value[0]);
                    range.toDate = moment(this.value[1]);
                    this.onClose.emit(range);
                    break;
                }
                this.onClose.emit(this.value);
                break;
        }
    }
    public valueForHop(range) {
        if (this.isHop()) {
            this.value[1] = this.spareTo ? this.spareTo : moment(range.toDate).toDate();
            document.getElementById('ranget').style.backgroundColor = 'white';
        } else {
            this.value[1] = this.value[0];
        }
    }
    public inlineElement(event) {
        this.overlay = event.element;
        this.appendOverlay();
        if (this.autoZIndex) {
            this.overlay.style.zIndex = String(this.baseZIndex + ++DomHandler.zindex);
        }
        this.alignOverlay();
        this.onShow.emit(event);
    }

    private isFullRangeExist(): boolean {
        return this.value?.[0] && this.value?.[1];
    }

    onOverlayAnimationDone(event: AnimationEvent) {
        switch (event.toState) {
            case 'visible':
            case 'visibleTouchUI':
                if (!this.inline) {
                    this.bindDocumentClickListener();
                    this.bindDocumentResizeListener();
                }
                break;
        }
    }

    appendOverlay() {
        if (this.appendTo) {
            if (this.appendTo === 'body') {
                document.body.appendChild(this.overlay);
            } else {
                DomHandler.appendChild(this.overlay, this.appendTo);
            }
        }
    }

    restoreOverlayAppend() {
        if (this.overlay && this.appendTo) {
            this.el.nativeElement.appendChild(this.overlay);
        }
    }

    alignOverlay() {
        if (this.touchUI) {
            this.enableModality(this.overlay);
        } else if (this.appendTo) {
                DomHandler.absolutePosition(this.overlay, this.inputfieldViewChild.nativeElement);
        }
        else {
                DomHandler.relativePosition(this.overlay, this.inputfieldViewChild.nativeElement);
                this.overlay.style.top = '100%';

                if (this.position === 'left') {
                    this.overlay.style.right = '0px';
                    this.overlay.style.left = 'unset';
                }
        }
    }

    enableModality(element) {
        if (!this.mask) {
            this.mask = document.createElement('div');
            this.mask.style.zIndex = String(parseInt(element.style.zIndex) - 1);

            const maskStyleClass = 'ui-widget-overlay ui-datepicker-mask ui-datepicker-mask-scrollblocker';
            DomHandler.addMultipleClasses(this.mask, maskStyleClass);

            this.maskClickListener = this.renderer.listen(this.mask, 'click', (_event: any) => {
                this.disableModality();
            });
            document.body.appendChild(this.mask);
            DomHandler.addClass(document.body, 'ui-overflow-hidden');
        }
    }

    disableModality() {
        if (this.mask) {
            document.body.removeChild(this.mask);
            const bodyChildren = document.body.children;
            let hasBlockerMasks: boolean;
            hasBlockerMasks = Array.from(bodyChildren).some((bodyChild) =>
                DomHandler.hasClass(bodyChild, 'ui-datepicker-mask-scrollblocker')
            );

            if (!hasBlockerMasks) {
                DomHandler.removeClass(document.body, 'ui-overflow-hidden');
            }

            this.unbindMaskClickListener();

            this.mask = null;
        }
    }

    unbindMaskClickListener() {
        if (this.maskClickListener) {
            this.maskClickListener();
            this.maskClickListener = null;
        }
    }

    writeValue(value: any): void {
        this.value = value;
        if (this.value && typeof this.value === 'string') {
            this.value = this.parseValueFromString(this.value);
        }

        this.updateInputfield();
        this.updateUI();
    }

    getLastMonday(date: Date): Date {
        let day = date.getDay();
        date.setDate(date.getDate() - day);
        return date;
    }

    registerOnChange(fn: Function): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onModelTouched = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
    }

    getDateFormat() {
        return this.dateFormat || this.locale.dateFormat;
    }

    // Ported from jquery-ui datepicker formatDate
    formatDate(date, format) {
        if (!date) {
            return '';
        }

        let iFormat;
        const lookAheads = (match) => {
                const [imatches, iFormatsNext] = this.lookAhead(match, iFormat, format);
                iFormat = iFormatsNext;
                return imatches;
            },
            formatNumber = (match, value, len) => {
                let num = '' + value;
                if (lookAheads(match)) {
                    while (num.length < len) {
                        num = '0' + num;
                    }
                }
                return num;
            },
            formatName = (match, value, shortNames, longNames) => {
                return lookAheads(match) ? longNames[value] : shortNames[value];
            };
        let output = '';

        ({ iFormat, output } = this.dateFormatType(
            date,
            iFormat,
            format,
            lookAheads,
            output,
            formatNumber,
            formatName
        ));
        return output;
    }

    private dateFormatType(
        date: any,
        iFormat: any,
        format: any,
        lookAhead: (match: any) => any,
        output: string,
        formatNumber: (match: any, value: any, len: any) => string,
        formatName: (match: any, value: any, shortNames: any, longNames: any) => any
    ) {
        let literal = false;
        if (date) {
            for (iFormat = 0; iFormat < format.length; iFormat++) {
                if (literal) {
                    ({ literal, output } = this.dateLiteral(format, iFormat, lookAhead, literal, output));
                } else {
                    switch (format.charAt(iFormat)) {
                        case 'd':
                            output += formatNumber('d', date.getDate(), 2);
                            break;
                        case 'D':
                            output += formatName('D', date.getDay(), this.locale.dayNamesShort, this.locale.dayNames);
                            break;
                        case 'o':
                            output += formatNumber(
                                'o',
                                Math.round(
                                    (new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() -
                                        new Date(date.getFullYear(), 0, 0).getTime()) /
                                        86400000
                                ),
                                3
                            );
                            break;

                        case 'w':
                            let tempDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                            this.selectedDate = moment(tempDate);
                            output += formatNumber('w', this.getWeekNumber(tempDate), 2);
                            break;
                        case 'm':
                            output += formatNumber('m', date.getMonth() + 1, 2);
                            break;
                        case 'M':
                            output += formatName(
                                'M',
                                date.getMonth(),
                                this.locale.monthNamesShort,
                                this.locale.monthNames
                            );
                            break;
                        case 'y':
                            output += this.fullYear(date, lookAhead);
                            break;
                        case '@':
                            output += date.getTime();
                            break;
                        case '!':
                            output += date.getTime() * 10000 + this.ticksTo1970;
                            break;
                        case "'":
                            ({ output, literal } = this.literalLookAhead(lookAhead, output, literal));
                            break;
                        default:
                            output += format.charAt(iFormat);
                    }
                }
            }
        }
        return { iFormat, output };
    }

    private literalLookAhead(lookAhead: (match: any) => any, output: string, literal: boolean) {
        if (lookAhead("'")) {
            output += "'";
        } else {
            literal = true;
        }
        return { output, literal };
    }

    private dateLiteral(format: any, iFormat: any, lookAhead: (match: any) => any, literal: boolean, output: string) {
        if (format.charAt(iFormat) === "'" && !lookAhead("'")) {
            literal = false;
        } else {
            output += format.charAt(iFormat);
        }
        return { literal, output };
    }

    public fullYear(date, lookAhead) {
        const yearPrefix = date.getFullYear() % 100 < 10 ? '0' : '';
        return lookAhead('y') ? date.getFullYear() : yearPrefix + (date.getFullYear() % 100);
    }

    formatTime(date) {
        if (!date) {
            return '';
        }

        let output = '';
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        if (this.hourFormat == '12' && hours > 11 && hours != 12) {
            hours -= 12;
        }

        if (this.hourFormat == '12') {
            output = this.formatHour(hours, output);
        } else {
            output += hours < 10 ? '0' + hours : hours;
        }
        output += ':';
        output += minutes < 10 ? '0' + minutes : minutes;

        if (this.showSeconds) {
            output += ':';
            output += seconds < 10 ? '0' + seconds : seconds;
        }

        if (this.hourFormat == '12') {
            output += date.getHours() > 11 ? ' PM' : ' AM';
        }

        return output;
    }
    public formatHour(hours, output: string) {
        const hourPrefix = hours < 10 ? '0' + hours : hours;
        output += hours === 0 ? 12 : hourPrefix;
        return output;
    }

    parseTime(value) {
        const tokens: string[] = value.split(':');
        const validTokenLength = this.showSeconds ? 3 : 2;

        if (tokens.length !== validTokenLength) {
            throw new Error('Invalid time');
        }

        let h = parseInt(tokens[0]);
        const m = parseInt(tokens[1]);
        const s = this.showSeconds ? parseInt(tokens[2]) : null;

        if (
            isNaN(h) ||
            isNaN(m) ||
            h > 23 ||
            m > 59 ||
            (this.hourFormat == '12' && h > 12) ||
            (this.showSeconds && (isNaN(s) || s > 59))
        ) {
            throw new Error('Invalid time');
        } else {
            h = this.parseTimeHourFormat(h);
            return { hour: h, minute: m, second: s };
        }
    }
    public parseTimeHourFormat(h) {
        if (this.hourFormat == '12') {
            if (h !== 12 && this.pm) {
                h += 12;
            } else if (!this.pm && h === 12) {
                h -= 12;
            }
        }
        return h;
    }

    // Ported from jquery-ui datepicker parseDate
    parseDate(value, format) {
        this.formatValue(format, value);

        value = typeof value === 'object' ? value.toString() : value + '';
        if (value === '') {
            return null;
        }

        let iFormat,
            dim,
            extra,
            iValue = 0,
            shortYearCutoff =
                typeof this.shortYearCutoff !== 'string'
                    ? this.shortYearCutoff
                    : (new Date().getFullYear() % 100) + parseInt(this.shortYearCutoff, 10),
            year = -1,
            month = -1,
            day = -1,
            doy = -1,
            literal = false,
            date,
            lookAhead = (match) => {
                const [matches, iFormats] = this.lookAhead(match, iFormat, format);
                iFormat = iFormats;
                return matches;
            },
            getNumber = (match) => {
                let __return;
                ({ __return, iValue } = this.getNumberDate(lookAhead, match, value, iValue));
                return __return;
            },
            getName = (match, shortNames, longNames) => {
                let index = -1;
                const arr = lookAhead(match) ? longNames : shortNames;
                const names = [];

                ({ iValue, index } = this.getIndexName(arr, names, value, iValue));

                if (index !== -1) {
                    return index + 1;
                } else {
                    throw new Error('Unknown name at position ' + iValue);
                }
            },
            checkLiteral = () => {
                this.errorValue(value, iValue, format, iFormat);
                iValue++;
            };

        if (this.view === 'month') {
            day = 1;
        }

        ({ iFormat, day, doy, month, year } = this.getCalendarDate(format, day, doy, month, year, date, {
            getName,
            getNumber,
            checkLiteral,
            lookAhead,
            literal,
        }));

        year = this.dateValueLength(iValue, value, extra, year, shortYearCutoff);

        date = this.countDays(doy, month, day, dim, year);

        return date;
    }
    private getNumberDate(lookAhead: (match: any) => any, match: any, value: any, iValue: number) {
        const isDoubled = lookAhead(match),
            size = (matches) => {
                if (matches === '@') {
                    return 14;
                } else if (matches === '!') {
                    return 20;
                } else if (matches === 'y' && isDoubled) {
                    return 4;
                } else if (matches === 'o') {
                    return 3;
                } else {
                    return 2;
                }
            },
            minSize = match === 'y' ? size : 1,
            digits = new RegExp('^\\d{' + minSize + ',' + size + '}'),
            num = value.substring(iValue).match(digits);
        this.errorNumber(num, iValue);
        iValue += num[0].length;
        return { __return: parseInt(num[0], 10), iValue };
    }

    private formatValue(format: any, value: any) {
        if (format == null || value == null) {
            throw new Error('Invalid arguments');
        }
    }

    private errorNumber(num: any, iValue: number) {
        if (!num) {
            throw new Error('Missing number at position ' + iValue);
        }
    }

    private errorValue(value: any, iValue: number, format: any, iFormat: any) {
        if (value.charAt(iValue) !== format.charAt(iFormat)) {
            throw new Error('Unexpected literal at position ' + iValue);
        }
    }

    private countDays(doy: number, month: number, day: number, dim: any, year: number) {
        if (doy > -1) {
            month = 1;
            day = doy;
            do {
                dim = this.getDaysCountInMonth(year, month - 1);
                if (day <= dim) {
                    break;
                }
                month++;
                day -= dim;
            } while (true);
        }

        let date = this.daylightSavingAdjust(new Date(year, month - 1, day));
        if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
            throw new Error('Invalid date'); // E.g. 31/02/00
        }
        return date;
    }

    private dateValueLength(iValue: number, value: any, extra: any, year: number, shortYearCutoff: any) {
        if (iValue < value.length) {
            extra = value.substr(iValue);
            if (!/^\s+/.test(extra)) {
                throw new Error('Extra/unparsed characters found in date: ' + extra);
            }
        }

        if (year === -1) {
            year = new Date().getFullYear();
        } else if (year < 100) {
            year += new Date().getFullYear() - (new Date().getFullYear() % 100) + (year <= shortYearCutoff ? 0 : -100);
        }
        return year;
    }

    private getIndexName(arr: any, names: any[], value: any, iValue: number) {
        let index = -1;
        for (let i = 0; i < arr.length; i++) {
            names.push([i, arr[i]]);
        }
        names.sort((a, b) => {
            return -(a[1].length - b[1].length);
        });

        for (let nameArr of names) {
            const name = nameArr[1];
            if (value.substr(iValue, name.length).toLowerCase() === name.toLowerCase()) {
                index = nameArr[0];
                iValue += name.length;
                break;
            }
        }
        return { iValue, index };
    }

    private getCalendarDate(
        format: any,
        day: number,
        doy: number,
        month: number,
        year: number,
        date: any,
        data: {
            getName: (match: any, shortNames: any, longNames: any) => number;
            getNumber: (match: any) => number;
            checkLiteral: () => void;
            lookAhead: (match: any) => any;
            literal: boolean;
        }
    ) {
        let iFormat;
        for (iFormat = 0; iFormat < format.length; iFormat++) {
            if (data.literal) {
                data.literal = this.formatCheckLiteral(
                    format,
                    iFormat,
                    data.lookAhead,
                    data.literal,
                    data.checkLiteral
                );
            } else {
                switch (format.charAt(iFormat)) {
                    case 'd':
                        day = data.getNumber('d');
                        break;
                    case 'D':
                        data.getName('D', this.locale.dayNamesShort, this.locale.dayNames);
                        break;
                    case 'o':
                        doy = data.getNumber('o');
                        break;
                    case 'm':
                        month = data.getNumber('m');
                        break;
                    case 'M':
                        month = data.getName('M', this.locale.monthNamesShort, this.locale.monthNames);
                        break;
                    case 'y':
                        year = data.getNumber('y');
                        break;
                    case '@':
                        date = new Date(data.getNumber('@'));
                        year = date.getFullYear();
                        month = date.getMonth() + 1;
                        day = date.getDate();
                        break;
                    case '!':
                        date = new Date((data.getNumber('!') - this.ticksTo1970) / 10000);
                        year = date.getFullYear();
                        month = date.getMonth() + 1;
                        day = date.getDate();
                        break;
                    case "'":
                        if (data.lookAhead("'")) {
                            data.checkLiteral();
                        } else {
                            data.literal = true;
                        }
                        break;
                    default:
                        data.checkLiteral();
                }
            }
        }
        return { iFormat, day, doy, month, year };
    }

    private formatCheckLiteral(
        format: any,
        iFormat: any,
        lookAhead: (match: any) => any,
        literal: boolean,
        checkLiteral: () => void
    ) {
        if (format.charAt(iFormat) === "'" && !lookAhead("'")) {
            literal = false;
        } else {
            checkLiteral();
        }
        return literal;
    }

    lookAhead(match, iFormat, format) {
        const matches = iFormat + 1 < format.length && format.charAt(iFormat + 1) === match;
        if (matches) {
            iFormat++;
        }
        return [matches, iFormat];
    }
    daylightSavingAdjust(date) {
        if (!date) {
            return null;
        }

        date.setHours(date.getHours() > 12 ? date.getHours() + 2 : 0);

        return date;
    }

    updateFilledState() {
        this.filled = this.inputFieldValue && this.inputFieldValue != '';
    }

    bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                this.documentClickListener = this.renderer.listen('document', 'click', (event) => {
                    if (this.isOutsideClicked(event) && this.overlayVisible) {
                        this.zone.run(() => {
                            this.hideOverlay();
                            this.onClickOutside.emit(event);

                            this.cd.markForCheck();
                        });
                    }
                });
            });
        }
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    bindDocumentResizeListener() {
        if (!this.documentResizeListener && !this.touchUI) {
            this.documentResizeListener = this.onWindowResize.bind(this);
            window.addEventListener('resize', this.documentResizeListener);
        }
    }

    unbindDocumentResizeListener() {
        if (this.documentResizeListener) {
            window.removeEventListener('resize', this.documentResizeListener);
            this.documentResizeListener = null;
        }
    }

    isOutsideClicked(event: Event) {
        return !(
            this.el.nativeElement.isSameNode(event.target) ||
            this.isNavIconClicked(event) ||
            this.isCustomClicked(event) ||
            this.el.nativeElement.contains(event.target) ||
            ( this.overlay?.contains(event.target as Node))
        );
    }

    isCustomClicked(event: Event) {
        return DomHandler.hasClass(event.target, 'ui-datepicker-custom-template');
    }

    isNavIconClicked(event: Event) {
        return (
            DomHandler.hasClass(event.target, 'ui-datepicker-prev') ||
            DomHandler.hasClass(event.target, 'ui-datepicker-prev-icon') ||
            DomHandler.hasClass(event.target, 'ui-datepicker-next') ||
            DomHandler.hasClass(event.target, 'ui-datepicker-next-icon')
        );
    }

    onWindowResize() {
        if (this.overlayVisible && !DomHandler.isAndroid()) {
            this.hideOverlay();
        }
    }

    onOverlayHide() {
        this.unbindDocumentClickListener();
        this.unbindMaskClickListener();
        this.unbindDocumentResizeListener();
        this.overlay = null;
        this.disableModality();
        this.view = 'date';
    }

    public createMonthWiseViews(event, selectedMonth) {
        this.view = 'month';
        event.stopPropagation();
        if (this.disabled) {
            event.preventDefault();
            return;
        }
        this.selectedMonth = selectedMonth;
        this.createMonthPickerValues();
    }

    public onSelectMonth(event, month) {
        this.view = 'date';
        event.stopPropagation();
        if (this.disabled) {
            event.preventDefault();
            return;
        }
        this.isMonthNavigate = true;
        this.selectedMonth = month;
        this.onMonthChange.emit({ month: this.selectedMonth + 1, year: this.currentYear });
        this.createMonths(this.selectedMonth, this.selectedYear ? this.selectedYear : this.currentYear);
        this.currentMonth = this.selectedMonth;
    }

    public createYearWiseViews(event, selectedYear) {
        this.view = 'year';
        event.stopPropagation();
        if (this.disabled) {
            event.preventDefault();
            return;
        }
        this.selectedYear = selectedYear;
        if (this.isOpenedFirstTime) {
            this.populateYearOptions(this.currentYear - this.yearThreshold, this.currentYear);
        } else {
            this.populateYearOptions(this.selectedYear, this.selectedYear + this.yearThreshold);
        }
    }

    public onSelectYear(event, year) {
        this.view = 'date';
        event.stopPropagation();
        if (this.disabled) {
            event.preventDefault();
            return;
        }
        this.isMonthNavigate = true;
        this.selectedYear = year;
        this.currentYear = year;
        this.isOpenedFirstTime = false;
        this.createMonths(this.currentMonth, this.selectedYear);
    }

    public getTrackData() {
        return `{"data_interaction":"icon_click","button_text":"Date input","name":"${this.pageName}"}`;
    }

    public isUpLg = () => this.screenDimensionService.upLg();

    ngOnDestroy() {
        this.clearTimePickerTimer();
        this.restoreOverlayAppend();
        this.onOverlayHide();
    }
}
