import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, withLatestFrom } from 'rxjs/operators';

import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { NameValuePair, HotUserExtended } from '@hot-libs/shared-models';
import { authUser } from '@hot-b2b/store/auth/selector';

@Component({
    selector: 'hot-phone-topup',
    templateUrl: './phone-topup.component.html',
})
export class PhoneTopupComponent implements OnInit {
    @Input() public product: any;
    @Output() public buttonTopUp = new EventEmitter();

    public readonly phoneLength = 10;
    public phoneTopupForm: UntypedFormGroup;
    public isShowForm: boolean = true;
    public settings$: Observable<HotSettingsExtended>;
    public user$: Observable<HotUserExtended>;
    public topupCarriers: NameValuePair[] = [];

    constructor(
        private readonly store: Store<AppState>,
        private readonly fb: UntypedFormBuilder
    ) {
        this.settings$ = this.store.pipe(select(settingsData));
        this.user$ = this.store.pipe(select(authUser));
    }

    public ngOnInit(): void {
        this.settings$.pipe(take(1), withLatestFrom(this.user$)).subscribe(([settings, user]:[HotSettingsExtended, HotUserExtended]) => {
            if (this.product.externalProductType === 'Voucher' && !this.product.isRequiredAuthentication) {
                this.isShowForm = false;
            } else {
                this.phoneTopupForm = this.fb.group({
                    phoneNumber: new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9]{"+ this.phoneLength +"}$")])
                });
    
                if (this.product.externalProductType === 'Topup') {
                    this.phoneTopupForm.addControl('carrier', new UntypedFormControl('', [Validators.required]));
                }

                settings.topupCarriers.forEach((pattern: string) => {
                    const rules = pattern.split('|');
                    const text = rules[1] || rules[0];
                    this.topupCarriers.push({value: rules[0], name: text})
                });
    
                if (user.phones && user.phones.length > 0) {
                    this.phoneTopupForm.get('phoneNumber').setValue(this.formatPhoneNumber(user.phones[0]));
                }
            }
        });
    }

    public isPatternAvailable(formControl: UntypedFormControl, localizedError: string | Array<string>) {
        if (
            formControl.invalid &&
            formControl.errors?.pattern
        ) {
            return localizedError;
        }
    }

    public formatPhoneNumber(phoneNumber: string) {
        if (phoneNumber.startsWith('+840')) {
            return phoneNumber.replace('+84', '')
        } else if (phoneNumber.startsWith('+84')) {
            return phoneNumber.replace('+84', '0')
        } else if (phoneNumber.startsWith('0840')) {
            return phoneNumber.replace('084', '')
        } else if (phoneNumber.startsWith('084')) {
            return phoneNumber.replace('084', '0')
        } else if (phoneNumber.startsWith('840')) {
            return phoneNumber.replace('84', '')
        } else if (phoneNumber.startsWith('84')) {
            return phoneNumber.replace('84', '0')
        } else return phoneNumber;
    }

    public topUp(): void {
        const product = this.product;
        let phoneNumber;
        let carrier;

        if (this.phoneTopupForm?.valid) {
            phoneNumber = this.phoneTopupForm.value.phoneNumber;
            carrier = this.phoneTopupForm.value.carrier?.value;
        }

        this.buttonTopUp.emit({product, phoneNumber, carrier});
    }
}
