import { SettingsState } from '@hot-b2b/store/settings/model';
import { StorageKeys } from '@hot-libs/shared-types';

export const settingsInitialState: SettingsState = {
    pending: false,
    fetched: false,
    data: {
        applicationInsightsInstrumentationKey: '',
        phoneNumberFormats: [],
        availableLanguages: [],
        enableEmailOrderNotifications: false,
        enableSmsOrderNotifications: false,
        currentLanguage: null,
        isLanguagesLoaded: false,
        dateFormat: '',
        features: [],
        opcoId: '',
        timeZoneId: '',
        storefrontVersion: '',
        themeVersion: '',
        iosDownloadLink: '',
        androidDownloadLink: '',
        enablePhoneNumberSelfRegistrationAsReferralCode: false,
        passwordOptions: {
            requireDigit: true,
            requireLowercase: true,
            requireNonAlphanumeric: false,
            requireUppercase: true,
            requiredLength: 8,
            requiredUniqueChars: 1,
        },
        ffc: {
            id: '',
        },
        settingsStore: [],
        analytics: {
            googleAnalyticsTrackingId: '',
            googleAnalyticsTagManagerId: '',
            googleAnalyticsEnvironment: '',
        },
        online: JSON.parse(localStorage.getItem(StorageKeys.appMode))
            ? JSON.parse(localStorage.getItem(StorageKeys.appMode)).online
            : true,
        support: {
            displayContactSupportEmail: false,
            displayContactSupportPhone: false,
            contactSupportEmail: '',
            contactSupportPhone: '',
        },
        isAnonymousUserOutletRegistrationEnabled: false,
        isGroupAccountOutletRegistrationEnabled: false,
        outletRegistrationFields: {
            anonymousUserFields: [],
            groupAccountFields: [],
            salesRepresentativeFields: [],
        },
        invalidateBrowserCache: false,
        cacheClearanceEffectiveTime: '',
        productRecommendationInterval: 0
    },
    error: null,
};
