import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CartItemsRemoveGifts } from '@hot-b2b/store/cart/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsToggleOnline } from '@hot-b2b/store/settings/actions';
import { settingsAppOnLine } from '@hot-b2b/store/settings/selector';
import { ConnectionService, LocalStorageService } from '@hot-libs/browser-specific';
import { StorageKeys } from '@hot-libs/shared-types';
import { CartService } from 'apps/hot-b2b/src/app/cart/services';
import { InitDataManagerService } from 'apps/hot-b2b/src/app/shared/services/initdata-manager.service';
import { HotUserExtended } from '@hot-libs/shared-models';
import { authUser } from '@hot-b2b/store/auth/selector';
import { HotApiCartsService, HotApiOrdersService } from '@hot-theme-nx/generated-api';
import { ModalService } from './modal.service';
import { StoreService } from './store.service';
import { FeaturesService } from './features.service';

@Injectable({
    providedIn: 'root',
})
export class NetworkConnectionService {
    public settingsAppOnLine$: Observable<boolean>;

    public featurePwa: boolean = this.featuresService.Pwa;
    public authUser$: Observable<HotUserExtended>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly localStorageService: LocalStorageService,
        private readonly connectionService: ConnectionService,
        private readonly modalService: ModalService,
        private readonly storeService: StoreService,
        private readonly initDataManagerService: InitDataManagerService,
        private readonly cartService: CartService,
        public readonly featuresService: FeaturesService,
        private readonly apiOrdersService: HotApiOrdersService,
        private readonly apiCartsService: HotApiCartsService
    ) {
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.authUser$ = this.store.pipe(select(authUser));
    }

    public onChange(status): void {
        const setMode = (lastActivity: number, modalAvailable: boolean): void => {
            this.localStorageService.setItem(StorageKeys.appMode, { online: status, lastActivity, modalAvailable });
        };
        if (status) {
            this.store.dispatch(new SettingsToggleOnline(status));
            this.initDataManagerService.toOnline();
            const offlineOrders = JSON.parse(localStorage.getItem(StorageKeys.offlineOrderQueue));
            if (offlineOrders && offlineOrders.length > 0) {
                this.submitOffline(this.submitOfflineOrder());
            }
            setMode(null, false);
        } else {
            this.store.dispatch(new SettingsToggleOnline(status));
            setMode(Date.now(), true);
            this.store.dispatch(new CartItemsRemoveGifts());
            this.cartService.keepOfflineCartLineItems();
            this.cartService.calculateTaxesOffline();
        }
    }

    public submitOfflineOrder() {
        let outletId = null;
        this.authUser$.subscribe((userData: HotUserExtended) => {
            outletId = userData.outlet.id;
        });
        let offlineOrders = JSON.parse(localStorage.getItem(StorageKeys.offlineOrderQueue));
        let offlineArray = [];
        //selecting the current outlet orders
        if (offlineOrders !== null) {
            offlineOrders.forEach((element) => {
                if (element.outletId === outletId) {
                    offlineArray.push(element);
                }
            });
            //selecting rest outlet orders
            if (offlineArray.length < 5) {
                offlineOrders.forEach((element) => {
                    if (offlineArray.length < 5 && element.outletId !== outletId) {
                        offlineArray.push(element);
                    }
                });
            }
        }

        return offlineArray;
    }

    public submitOffline(data) {
        let offlineOrderItems = JSON.parse(localStorage.getItem(StorageKeys.offlineOrderQueue));
        let index;
        let failedOrders = [];
        ///getting the offline failed orders
        let offlineOrderErrorItems = JSON.parse(localStorage.getItem(StorageKeys.offlineFailedOrderQueue));
        if (offlineOrderErrorItems !== null) {
            offlineOrderErrorItems.forEach((element) => {
                failedOrders.push(element);
            });
        }
        if (offlineOrderItems !== null) {
            this.apiCartsService.processOrderOffline(data).subscribe((response: any) => {
                //pushing the failed order in an array
                response.forEach((element) => {
                    if (element.succeeded === false) {
                        failedOrders.push(element);
                    }
                });

                localStorage.setItem(StorageKeys.offlineFailedOrderQueue, JSON.stringify(failedOrders));

                // removing the order which has been sent to backend
                data.forEach((element1) => {
                    index = offlineOrderItems.findIndex((element) => {
                        if (element.outletId === element1.outletId) {
                            return true;
                        }
                    });
                    offlineOrderItems.splice(index, 1);
                });
                //storing the remaining orders in cache
                localStorage.setItem(StorageKeys.offlineOrderQueue, JSON.stringify(offlineOrderItems));
                const length = JSON.parse(localStorage.getItem(StorageKeys.offlineOrderQueue)).length;
                if (length > 0) {
                    this.submitOffline(this.submitOfflineOrder());
                }
            });
        }
    }
}
