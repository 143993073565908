import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CartErrorHandlerService, AnalyticLocationConstant } from '@hot-theme-nx/common-api';
import { CurrencyFormat, HotFulfillmentCenter, HotOutlet } from '@hot-theme-nx/generated-api';
import { OrderTemplateService } from '../../../order-template/services/order-template.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { isApplicationOnline } from '@hot-libs/helpers';
import {
    DeliverySettingsModel,
    HotUserExtended,
    SettingsStoreModel,
    SuggestedPromotion,
} from '@hot-libs/shared-models';
import { CustomerPermissions, ModalIds, PageName, StorageKeys, TelemetryEventType } from '@hot-libs/shared-types';
import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import {
    ApplicationInsightsService,
    FeaturesService,
    ModalService,
    ProductService,
} from 'apps/hot-b2b/src/app/shared/services';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { PermissionsService } from 'apps/hot-b2b/src/app/shared/services/permissions.service';
import { CartClearWithApiRequest, CartOfflineClear } from 'apps/hot-b2b/src/app/store/cart/actions';
import {
    cartData,
    checkoutCartData,
    cartFetched,
    cartItems,
    checkOutCartItems,
    cartPending,
    cartValidationErrors,
} from 'apps/hot-b2b/src/app/store/cart/selector';
import { AppState } from 'apps/hot-b2b/src/app/store/reducers';
import { settingsAppOnLine, settingsCurrencyFormat, settingsStore } from 'apps/hot-b2b/src/app/store/settings/selector';
import isEmpty from 'lodash/isEmpty';
import { suggestedPromotions } from '@hot-b2b/store/promotions/selector';
import { authContactOutlet, authUser, showPalletCounterForUserGroups } from '@hot-b2b/store/auth/selector';
import { CurrencyFormatPipe } from 'apps/hot-b2b/src/app/shared/pipes';
import { AlertService } from '../../../shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { config } from 'apps/hot-b2b/src/environments/config';
import { CartService } from '../../../cart/services/cart.service';
import { warehouses } from '@hot-b2b/store/warehouses/selector';
import { OrderTemplateModel } from '../../../order-template/models';
import { orderTemplates } from '@hot-b2b/store/order-template/selector';
import { ItemLocationService } from 'apps/hot-b2b/src/app/shared/services/Item-location.service';

@Component({
    selector: 'hot-basket-overlay',
    templateUrl: './basket-overlay.component.html',
})
export class BasketOverlayComponent implements OnInit {
    @ViewChild('desktopValidationErrorsBlock') private desktopValidationErrorsBlock: ElementRef;
    @ViewChild('mobileValidationErrorsBlock') private mobileValidationErrorsBlock: ElementRef;
    @Input() data;
    
    public authUser$: Observable<HotUserExtended>;
    public cartData$: Observable<HotCartExtended>;
    public checkoutCartData$: Observable<HotCartExtended>;
    public cartItems$: Observable<HotCartLineItemExtended[]>;
    public checkoutCartItems$: Observable<HotCartLineItemExtended[]>;
    public cartPending$: Observable<boolean>;
    public cartFetched$: Observable<boolean>;
    public cartValidationErrors$: Observable<any>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public deliverySettings$: Observable<DeliverySettingsModel>;
    public clearCartModalState$: Observable<ModalsState>;
    public readonly contactOutlet$: Observable<HotOutlet>;
    public cartItems: HotCartLineItemExtended[];
    public emptiesReturnItems: HotCartLineItemExtended[];
    public pageName = PageName.BASKETOVERLAY;
    public featureValidateProductLimitOrderQuantity: boolean = this.featuresService.ValidateProductLimitOrderQuantity;
    public featureOrderTemplates: boolean = this.featuresService.OrderTemplates;
    public featureReturnEmpties: boolean = this.featuresService.ReturnEmpties;
    public featureShowStockLevelOnLineItems: boolean = this.featuresService.ShowStockLevelOnLineItems;
    public settingHideAddEmptiesLink: boolean = this.productService.hideAddEmptiesLink;
    public featureShowPricingIndicatorInCart: boolean = this.featuresService.ShowPricingIndicatorInCart;
    public featureSplitOrdersBySuppliersWhenCheckout: boolean = this.featuresService.SplitOrdersBySuppliersWhenCheckout;
    public featureValidateMinimumBackOrderQuantity: boolean = this.featuresService.ValidateMinimumBackOrderQuantity;
    public featureValidateMinimumNonBackOrderQuantity: boolean = this.featuresService
        .ValidateMinimumNonBackOrderQuantity;
    public settingsAppOnLine$: Observable<boolean>;
    public showPalletCounterForUserGroups$: Observable<boolean>;
    public featureShowPricingIndicatorIsFilterableInCart: boolean = this.featuresService
        .ShowPricingIndicatorInCartFilterable;
    public readonly currencyFormat$: Observable<CurrencyFormat>;

    public haveReadPricingPermission: Observable<boolean> = this.permissionsService.checkPermission(
        CustomerPermissions.PricingRead
    );

    public hideInactiveProductsInCart: boolean = false;
    public configIsSelectableItemInCartDisabled = config?.isSelectableItemInCartDisabled;
    public isUpLg = () => this.screenDimensionService.upLg();
    public isNgB2b = () => this.configurationService.getCurrentConfiguration() === 'NG';
    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public cartErrors: {};
    public cartCrates: number;
    public cartMinTotal: string;
    public currencyFomatting: CurrencyFormatPipe = new CurrencyFormatPipe();
    public isHasNoSelectedItems = false;
    public readonly checkoutButtonLocalizationKey = 'cart.sidebar.button.checkout';
    public readonly sendOrderLocalizationKey = 'shared.cart-sidebar.send-order';
    public readonly emptyWithSavedOrderKey = 'shared.cart-sidebar.saved-orders.empty-content';
    public readonly emptyWithoutSavedOrderKey = 'shared.cart-sidebar.saved-orders.empty-saved-orders';
    private readonly analyticProductLocation = AnalyticLocationConstant.CART;
    private readonly unsubscribe$ = new Subject<boolean>();
    private readonly suggestedPromotions$: Observable<SuggestedPromotion[]>;
    private allowOutOfStockItemsInCart = false;


    public isWidget: boolean = false;
    public isBottomSheetCheckout: boolean = false;

    public distributorName: string;
    public outletName: string;
    public orderTemplates$: Observable<OrderTemplateModel[]>;
    public promotionalDiscountAmount: number;

    public confirmationOverlayState$: Observable<ModalsState>;
    public isPlaceOrderDisabled = false;

    public moqProductErrorBackOrder: {
        backOrder?: string;
        basePackagePack?: string;
        basePackageKeg?: string;
        moqPack?: string;
        moqKeg?: string;
    };

    public moqProductErrorIndividualSku: {
        individualSku?: string;
    };
    public moqCartErrorTotalOrder: {
        moqTotalOrder?: string;
    };
    public moqCartErrorKegOnly: {
        moqKegOnly?: string;
    };
    public moqCartErrorKegAndPack: {
        moqPack?: string;
        moqKeg?: string;
    };
    unitOfMeasure: string;

    constructor(
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly featuresService: FeaturesService,
        private readonly analyticService: AnalyticService,
        private readonly productService: ProductService,
        private readonly permissionsService: PermissionsService,
        private readonly appInsightsService: ApplicationInsightsService,
        private readonly modalService: ModalService,
        private readonly orderTemplateService: OrderTemplateService,
        private readonly cartErrorService: CartErrorHandlerService,
        private readonly cartService: CartService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly configurationService: ConfigurationService,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly itemlocationService: ItemLocationService

    ) {
        this.cartData$ = this.store.pipe(select(cartData));
        this.checkoutCartData$ = this.store.pipe(select(checkoutCartData));
        this.cartItems$ = this.store.pipe(select(cartItems));
        this.checkoutCartItems$ = this.store.pipe(select(checkOutCartItems));
        this.cartPending$ = this.store.pipe(select(cartPending));
        this.cartFetched$ = this.store.pipe(select(cartFetched));
        this.cartValidationErrors$ = this.store.pipe(select(cartValidationErrors));
        this.contactOutlet$ = this.store.pipe(select(authContactOutlet));
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.orderTemplates$ = this.store.pipe(
            select(orderTemplates),
            filter((templates) => !!templates)
        );

        this.authUser$ = this.store.pipe(select(authUser));
        this.deliverySettings$ = this.getDeliverySettings();
        this.clearCartModalState$ = this.store.pipe(select(selectModalState(ModalIds.clearCartConfirmation)));
        this.suggestedPromotions$ = this.store.pipe(select(suggestedPromotions));
        this.showPalletCounterForUserGroups$ = this.store.pipe(select(showPalletCounterForUserGroups));
        this.confirmationOverlayState$ = this.store.pipe(select(selectModalState(ModalIds.confirmationOverlay)));
    }

    public ngOnInit(): void {
        this.cartItems$.pipe(takeUntil(this.unsubscribe$)).subscribe((cartLineItems: HotCartLineItemExtended[]) => {
            this.cartItemInnerCall(cartLineItems);
        });

        this.authUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user: HotUserExtended) => {
            if (user) {
                this.outletName = user.contact.outlet.name;
            }
        });

        this.isWidget = this.data === 'widget';
        this.isBottomSheetCheckout = this.data === 'bottomSheet';

        this.cartData$
            .pipe(
                filter((cart) => !!cart.id),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((cart: HotCartExtended) => {
                this.cartErrors = null;
                this.moqProductErrorIndividualSku = null;
                this.moqProductErrorBackOrder = null;
                this.moqCartErrorTotalOrder = null;
                this.moqCartErrorKegOnly = null;
                this.moqCartErrorKegAndPack = null;

                if (cart.discountTotal)
                    this.promotionalDiscountAmount = cart.couponDiscountAmount
                        ? cart.discountTotal - cart.couponDiscountAmount
                        : cart.discountTotal;
                else this.promotionalDiscountAmount = 0;

                if (cart.itemsQuantity && cart.validationErrors) {
                    this.cartValidationErrors(cart);
                }
            });

        this.settingsStore$.pipe(takeUntil(this.unsubscribe$)).subscribe((storeSettings: SettingsStoreModel) => {
            this.allowOutOfStockItemsInCart = storeSettings.displayOutOfStockProductsInCustomersCatalog;
            this.hideInactiveProductsInCart = storeSettings.hideInactiveProductsInCart;
        });

        this.cartService.cartIsValid$.pipe(takeUntil(this.unsubscribe$)).subscribe((isValid) => {
            if (!isValid) {
                this.scrollToCartErrors();
            }
        });

        if (this.featureShowStockLevelOnLineItems) {
            this.cartValidationErrors$.pipe(takeUntil(this.unsubscribe$)).subscribe((validationErrors) => {
                this.validationError(validationErrors);
            });
        }

        this.cartMinOrderTotal();
        this.getFulfillmentFromStore()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((fulfillmentCenter: HotFulfillmentCenter) => {
                this.distributorName = fulfillmentCenter?.name;
            });

        this.checkPlaceButton();
    }

    private cartItemInnerCall(cartLineItems: HotCartLineItemExtended[]) {
        this.cartItems = cartLineItems.filter((item: HotCartLineItemExtended) => !item.isReturnableEmpty);
        this.emptiesReturnItems = cartLineItems.filter((item: HotCartLineItemExtended) => item.isReturnableEmpty);
    }

    private checkPlaceButton() {
        this.cartItems$.pipe(takeUntil(this.unsubscribe$)).subscribe((cartLineItems: HotCartLineItemExtended[]) => {
            for (let a of cartLineItems) {
                if (a.validationErrors.length > 0) {
                    this.isPlaceOrderDisabled = true;
                    break;
                } else {
                    this.isPlaceOrderDisabled = false;
                }
            }
        });
    }

    public cartValidationErrors(cart) {
        const transformedErrors = this.cartErrorService.transformErrorsArray(cart.validationErrors);
        this.cartErrors = this.cartErrorService.prepareToolbarData(transformedErrors);
        this.unitOfMeasure = this.cartErrors['unitOfMeasure']
            ? this.translateService.instant(
                  `shared.product.package-types.${this.cartErrors['unitOfMeasure']?.toLowerCase()}`
              )
            : '';
        //conditions for checking moq and max-oq criteria
        if (this.cartErrors['itemsCount'] > cart.itemsQuantity) {
            this.cartCrates = this.cartErrors['itemsCount'] - cart.itemsQuantity;
        }
        if (this.cartErrors['maxItemsCount'] < cart.itemsQuantity) {
            this.cartCrates = cart.itemsQuantity - this.cartErrors['maxItemsCount'];
        }

        if (this.featureShowStockLevelOnLineItems) {
            this.stockLevelFeature(cart);
        }
    }

    private getFulfillmentFromStore(): Observable<HotFulfillmentCenter> {
        return this.store.pipe(
            select(warehouses),
            map((fulfillmentCenters: HotFulfillmentCenter[]) => {
                return fulfillmentCenters[0];
            })
        );
    }
    public stockLevelFeature(cart) {
        const groupError = this.cartErrorService.processMoqError(cart.validationErrors);

        if (this.featureValidateMinimumBackOrderQuantity || this.featureValidateMinimumNonBackOrderQuantity) {
            this.moqProductErrorBackOrder = groupError.moqProductErrorBackOrder;
            this.moqProductErrorIndividualSku = groupError.moqProductErrorIndividualSku;
        }
        if (this.featureValidateMinimumBackOrderQuantity && this.featureValidateMinimumNonBackOrderQuantity) {
            this.moqCartErrorTotalOrder = groupError.moqCartErrorTotalOrder;
            this.moqCartErrorKegOnly = groupError.moqCartErrorKegOnly;
            this.moqCartErrorKegAndPack = groupError.moqCartErrorKegAndPack;
        }
    }
    public validationError(validationErrors) {
        if (validationErrors.length > 0) {
            const validationError = validationErrors[0];
            const cartErrorCode = validationError.errorCode;
            if (cartErrorCode === 'HotMinimumBackOrderQuantityError') {
                const { minimumBackOrderQuantity, minimumBackOrderType } = validationError.parameters;
                this.alertService.error(
                    this.translateService.instant('cart.errors.' + cartErrorCode, {
                        minimumBackOrderQuantity,
                        minimumBackOrderType,
                    })
                );
            }
        } else {
            this.alertService.reset();
        }
    }
    public onHasNoSelectedItems(status: boolean) {
        this.isHasNoSelectedItems = status;
    }

    public cartHasErrors(): Observable<boolean> {
        return this.cartData$.pipe(
            take(1),
            withLatestFrom(this.settingsAppOnLine$),
            map(
                ([cart, applicationIsOnline]: [HotCartExtended, boolean]) =>
                    !cart.items?.length ||
                    (applicationIsOnline && !cart.isValid && !!cart.validationErrors?.length) ||
                    cart.items.every(
                        (item) =>
                            item.isUnavailableForCustomers || (!item.isInStock && !this.allowOutOfStockItemsInCart)
                    ) ||
                    (this.featureSplitOrdersBySuppliersWhenCheckout &&
                        cart.items.every((item) => item.isSelected === false)) ||
                    (this.hideInactiveProductsInCart &&
                        cart.items.some((item) =>
                            item.validationErrors.some((error) => error.errorCode === 'UnavailableError')
                        ))
            )
        );
    }

    public saveOrderTemplate(): void {
        this.settingsAppOnLine$.pipe(take(1)).subscribe((isOnline: boolean) => {
            if (isOnline) {
                this.orderTemplateService.openOrderTemplateModal();
            }
        });
    }

    public navigate(url: string): void {
        this.modalService.toggleModal(ModalIds.basketOverlayPopup, false);
        this.modalService.toggleModal(ModalIds.confirmationOverlay, false);
        this.router.navigateByUrl(url);
    }

    public navigateToCheckout(): void {
        console.log('Navigate to checkout page..');
        this.appInsightsService.trackEvent(TelemetryEventType.CheckoutButtonClick);

        this.cartData$
            .pipe(withLatestFrom(this.settingsAppOnLine$, this.suggestedPromotions$), take(1))
            .subscribe(([data, isOnline]: [HotCartExtended, boolean, SuggestedPromotion[]]) => {
                if (!isEmpty(this.cartErrors)) {
                    document.getElementById('cart-errors').scrollIntoView({ block: 'center' });
                }
                if (!isOnline || (isOnline && isEmpty(this.cartErrors))) {
                    const hasEmpties = data.items.some((item: HotCartLineItemExtended) => item.isReturnableEmpty);

                    let hasItemsWithReturnableSku: boolean = false;
                    hasItemsWithReturnableSku = this.validateForEmpties(data);
                    if (isOnline && this.featureReturnEmpties && hasItemsWithReturnableSku && !hasEmpties) {
                        this.modalService.toggleModal(ModalIds.confirmationOverlay, true);
                    } else if (!isOnline || data.isValid || !data.validationErrors?.length) {
                        this.modalService.toggleModal(ModalIds.basketOverlayPopup, false);
                        this.navigate('/checkout');
                    }
                }
            });
    }

    public validateForEmpties(data) {
        let hasItemsWithReturnableSku: boolean = false;
        for (let item of data.items) {
            if (item.hasOwnProperty('product')) {
                if (item.product.hasOwnProperty('returnableEmptySku')) hasItemsWithReturnableSku = true;
            } else if (item.hasOwnProperty('emptiesDeposit')) {
                hasItemsWithReturnableSku = true;
            }

            if (hasItemsWithReturnableSku) {
                break;
            }
        }
        return hasItemsWithReturnableSku;
    }

    public addToCartHandler(product: HotProductExtended): void {
        this.itemlocationService.addItemLocation(product.sku, this.analyticProductLocation);
        this.analyticService.sendAddCartEventInfo([product], this.analyticProductLocation, false, product.addMethod);
        this.productService.sendProductHandler(product, product.quantity);
    }

    public removeFromCartHandler(product: HotProductExtended): void {
        this.itemlocationService.removeItemLocation(product.sku,  location || this.analyticProductLocation);
        this.analyticService.sendRemoveCartEventInfo(product, this.analyticProductLocation, product.addMethod);
        this.productService.sendProductHandler(product, 0);
    }

    public sendChangeQuantityHandler(product: HotProductExtended): void {
        this.itemlocationService.addItemLocation(product.sku, this.analyticProductLocation);
        this.analyticService.sendAddCartEventInfo([product], this.analyticProductLocation, true, product.addMethod);
        this.productService.sendProductHandler(product, product.quantity);
    }

    private getDeliverySettings(): Observable<DeliverySettingsModel> {
        return this.settingsStore$.pipe(
            filter((settings: SettingsStoreModel) => !!settings),
            map((settings: SettingsStoreModel) => {
                const deliverySettings: DeliverySettingsModel = {
                    isTodayDelivery: settings.isTodayDelivery,
                    pagesInformationMessages: settings.pagesInformationMessages,
                    isWeekCalendar: settings.preferredDeliveryDateAsWeekOnCheckoutPage,
                };
                return deliverySettings;
            })
        );
    }

    public closeConfirmationOverlay(): void {
        this.modalService.toggleModal(ModalIds.confirmationOverlay, false);
    }

    public clearCart(): void {
        this.cartItems$.pipe(takeUntil(this.unsubscribe$)).subscribe((items: HotCartLineItemExtended[]) => {
            if (items?.length) {
                this.analyticService.sendDeleteAllFromCartEventInfo(items);
            }
        });
        const isOnline: boolean = isApplicationOnline();
        if (isOnline) {
            this.store.dispatch(new CartClearWithApiRequest());
        } else {
            localStorage.removeItem(StorageKeys.offlineCart);
            this.store.dispatch(new CartOfflineClear());
        }
        this.modalService.toggleModal(ModalIds.confirmationOverlay, false);
    }

    public scrollToCartErrors(): void {
        const anchorElement = this.isUpLg()
            ? this.desktopValidationErrorsBlock.nativeElement
            : this.mobileValidationErrorsBlock.nativeElement;

        anchorElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });

        this.cartService.cartIsValid$.next(true);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }
    //code for calculating the minimum order total to be added to the cart for mot
    public cartMinOrderTotal() {
        this.cartData$
            .pipe(
                filter((cart) => !!cart.id),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((cart: HotCartExtended) => {
                this.currencyFormat$.subscribe((currencyFormat: CurrencyFormat) => {
                    let cartTotal: any = this.currencyFomatting.transform(cart.total, currencyFormat)?.substring(1);
                    let cartMOT = this.cartErrors?.['minimumOrderTotal']?.substring(1);
                    this.cartMinTotal =
                        currencyFormat.currencySymbol +
                        (cartMOT?.replaceAll(',', '') - cartTotal?.replaceAll(',', ''))?.toLocaleString();
                });
            });
    }

    public closeOverlay(): void {
        this.modalService.toggleModal(ModalIds.basketOverlayPopup, false);
    }

}
