import { Component, Input } from '@angular/core';

import { ModalIds } from '@hot-libs/shared-types';

import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';

@Component({
    selector: 'hot-template-save-warning-modal',
    templateUrl: './template-save-warning-modal.component.html',
})
export class TemplateSaveWarningModalComponent {
    @Input() public showModal: boolean;

    constructor(private readonly modalService: ModalService) {}

    public PATHS = PATHS;

    public onClose(): void {
        this.modalService.toggleModal(ModalIds.templateWarningOffline, false);
    }
}
