import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { selectModalData } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { ModalIds } from '@hot-libs/shared-types';
import { LegalAgreementService } from 'apps/hot-b2b/src/app/shared/services/legal-agreement.service';
import { HotLegalAgreement } from '@hot-theme-nx/generated-api';

@Component({
    selector: 'hot-information-modal',
    templateUrl: './information-modal.component.html',
})
export class InformationModalComponent implements OnInit {
    public type: string;
    public content: string = '';
    public modalInformation$: Observable<any>;

    private readonly subscriptionsDestroy$ = new Subject<boolean>();

    constructor(
        private readonly store: Store<AppState>,
        private readonly legalAgreementService: LegalAgreementService
    ) {
        this.modalInformation$ = this.store.pipe(select(selectModalData(ModalIds.informationModal)));
    }

    public ngOnInit(): void {
        this.modalInformation$.subscribe((data) => {
            if (data?.type) {
                this.type = data.type;

                if (this.type === 'OperationProcess') {
                    this.legalAgreementService.currentOperationProcess$
                        .pipe(takeUntil(this.subscriptionsDestroy$))
                        .subscribe((result: HotLegalAgreement) => {
                            this.modalInformation(result);
                        });
                } else if (this.type === 'PurchaseContract') {
                    this.legalAgreementService.currentPurchaseContract$
                        .pipe(takeUntil(this.subscriptionsDestroy$))
                        .subscribe((result: HotLegalAgreement) => {
                            this.modalInformation(result);
                        });
                } else if (this.type === 'ShipmentService') {
                    this.legalAgreementService.currentShipmentService$
                        .pipe(takeUntil(this.subscriptionsDestroy$))
                        .subscribe((result: HotLegalAgreement) => {
                            this.modalInformation(result);
                        });
                } else if (this.type === 'RefundPolicy') {
                    this.legalAgreementService.currentRefundPolicy$
                        .pipe(takeUntil(this.subscriptionsDestroy$))
                        .subscribe((result: HotLegalAgreement) => {
                            this.modalInformation(result);
                        });
                } else if (this.type === 'PaymentService') {
                    this.legalAgreementService.currentPaymentService$
                        .pipe(takeUntil(this.subscriptionsDestroy$))
                        .subscribe((result: HotLegalAgreement) => {
                            this.modalInformation(result);
                        });
                } else if (this.type === 'MaintainPolicy') {
                    this.legalAgreementService.currentMaintainPolicy$
                        .pipe(takeUntil(this.subscriptionsDestroy$))
                        .subscribe((result: HotLegalAgreement) => {
                            this.modalInformation(result);
                        });
                } else if (this.type === 'PrivacyPolicy') {
                    this.legalAgreementService.currentPrivacyPolicy$
                        .pipe(takeUntil(this.subscriptionsDestroy$))
                        .subscribe((result: HotLegalAgreement) => {
                            this.modalInformation(result);
                        });
                } else if (this.type === 'LitigationProcess') {
                    this.legalAgreementService.currentLitigationProcess$
                        .pipe(takeUntil(this.subscriptionsDestroy$))
                        .subscribe((result: HotLegalAgreement) => {
                            this.modalInformation(result);
                        });
                }
            }
        });
    }
    public modalInformation(result): void{
        if (result?.content) {
            this.content = result.content;
        }
    }
}
