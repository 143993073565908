import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    HotApiCartsService,
    HotCreateOrderRequest,
    HotCreateOrderResponse,
    HotOrderTemplate,
    HotPaymentMethod,
    HotApiOrdersService,
    HotOrder,
    HotPopService,
    HotPaymentMethods,
} from '@hot-theme-nx/generated-api';
import { ProductsBufferModel, SettingsStoreModel, HotUserExtended, HotOrderExtended } from '@hot-libs/shared-models';
import { FeatureNames, StorageKeys, TelemetryEventType, AccountType, ModalIds } from '@hot-libs/shared-types';
import { OrderService } from 'apps/hot-b2b/src/app/order/services';

import {
    CartAddItem,
    CartAddOrderTemplate,
    CartAddOrderTemplateAndCheckOut,
    CartAddPendingChanges,
    CartAddPreviousOrder,
    CartAddPreviousOrderAndCheckOut,
    CartAddPreviousOrderOffline,
    CartAddPreviousOrderOfflineSuccess,
    CartClearChangedItems,
    CartClearProcessChanges,
    CartClearWithApiRequest,
    CartCreateOrder,
    CartCreateOrders,
    CartCreateOrdersSuccess,
    CartCreateOrderSuccess,
    CartCreateReorder,
    CartDeleteCoupon,
    CartError,
    CartGet,
    CartUpdateData,
    CartGetSuccess,
    CartItemsRemoveGifts,
    CartManageProductsBuffer,
    CartMovePendingToProcessChanges,
    CartOfflineAddItem,
    CartOfflineChangeItem,
    CartOfflineClear,
    CartOfflineCreateReorder,
    CartOfflineGet,
    CartOfflineRemoveItem,
    CartOfflineSynchronized,
    CartRemoveItem,
    CartSavePreviousOrder,
    CartSaveTemplate,
    CartSaveTemplateSuccess,
    CartSetCoupon,
    CartUpdateItem,
    CartRestoreSuccess,
    CartAddItems,
    CartUpdateItems,
    CartSelectItems,
    CartPromoError,
    CartGetRecommendedProducts,
    CartGetRecommendedProductsSuccess,
    CartOrderPlacementError,
    CartErrorCodes,
} from '@hot-b2b/store/cart/actions';
import {
    cartChangesBuffer,
    cartData,
    cartError,
    cartItems,
    cartPaymentMethod,
    cartRemarks,
} from '@hot-b2b/store/cart/selector';
import { ECartActions } from '@hot-b2b/store/cart/types';
import { CatalogSynchronized, EmptiesCatalogSynchronized } from '@hot-b2b/store/catalog/actions';
import { OrdersPreviousGet } from '@hot-b2b/store/orders/actions';
import { ProductsSynchronized } from '@hot-b2b/store/products/actions';
import { PromotionsSynchronized } from '@hot-b2b/store/promotions/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine, settingsStore } from '@hot-b2b/store/settings/selector';
import { PopularProductsSynchronized } from '@hot-b2b/store/widgets/actions';
import { CartService, PopService } from 'apps/hot-b2b/src/app/cart/services';
import { HotProductExtended, ProductFunctionalityModel } from 'apps/hot-b2b/src/app/catalog/models';
import { OrderTemplateItemModel, OrderTemplateModel } from 'apps/hot-b2b/src/app/order-template/models';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import {
    ApplicationInsightsService,
    FeaturesService,
    LoaderService,
    WarehouseService,
    ModalService,
    LoaderServiceV2,
} from 'apps/hot-b2b/src/app/shared/services';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { RecommendedProductsService } from 'apps/hot-b2b/src/app/shared/services/recommended-products.service';
import { OrderTemplateAddNew } from '../order-template/actions';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { OrderCreationService } from '../../shared/services/order-creation.service';
import { OrderTemplateService } from '../../order-template/services';
import { SettingsStoreGet } from '../settings/actions';
import { authUser } from '@hot-b2b/store/auth/selector';
import { CookiesService } from '../../shared/services/cookies.service';
import { TranslateService } from '@ngx-translate/core';
import { ItemLocationService } from 'apps/hot-b2b/src/app/shared/services/Item-location.service';
declare let ProcessOut: any;

type cartUpdateRemoveActions =
    | CatalogSynchronized
    | EmptiesCatalogSynchronized
    | ProductsSynchronized
    | PromotionsSynchronized
    | PopularProductsSynchronized
    | CartGetRecommendedProducts
    | CartGetSuccess;

type cartAddActions =
    | CatalogSynchronized
    | ProductsSynchronized
    | PromotionsSynchronized
    | PopularProductsSynchronized
    | CartGetSuccess;

@Injectable()
export class CartEffects {
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    client: any;
    private readonly storeSettings$: Observable<SettingsStoreModel>;
    public authorizationResponse: boolean = false;
    public redirectURLresponse: boolean = false;
    public src: any;
    public paymentMethodData: string;
    public APMGateWayCode: string;

    constructor(
        private readonly _actions$: Actions,
        private readonly _store: Store<AppState>,
        private readonly router: Router,
        private readonly cartService: CartService,
        private readonly hotApiCartsService: HotApiCartsService,
        private readonly warehouseService: WarehouseService,
        private readonly analyticService: AnalyticService,
        private readonly appInsightsService: ApplicationInsightsService,
        private readonly featuresService: FeaturesService,
        private readonly loaderService: LoaderService,
        private readonly hotApiOrdersService: HotApiOrdersService,
        private readonly configurationService: ConfigurationService,
        private readonly orderCreationService: OrderCreationService,
        private readonly orderTemplateService: OrderTemplateService,
        private readonly orderService: OrderService,
        private readonly recommendedProductsService: RecommendedProductsService,
        private readonly popService: PopService,
        private readonly modalService: ModalService,
        private readonly popApiService: HotPopService,
        private readonly apiOrdersService: HotApiOrdersService,
        private readonly cookieService: CookiesService,
        private readonly loaderServiceV2: LoaderServiceV2,
        private readonly translateService: TranslateService,
        private readonly itemlocationService: ItemLocationService
    ) {
        this.storeSettings$ = this._store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
    }

    public CartGet$: Observable<
        | CartGetSuccess
        | CartUpdateData
        | CatalogSynchronized
        | EmptiesCatalogSynchronized
        | ProductsSynchronized
        | PromotionsSynchronized
        | PopularProductsSynchronized
        | CartGetRecommendedProducts
        | CartError
    > = createEffect(() => this._actions$.pipe(
        ofType<CartGet>(ECartActions.CART_PENDING),
        withLatestFrom(this._store.pipe(select(cartItems))),
        switchMap(([action]) => {
            const isKeepPaymentMethod = action.payload;
            return this.cartService.getCart().pipe(
                withLatestFrom(
                    this._store.pipe(select(settingsAppOnLine)),
                    this.featuresService.checkFeatures(FeatureNames.SplitOrdersBySuppliersWhenCheckout)
                ),
                switchMap(
                    ([cart, _isOnline, splitOrdersBySuppliersWhenCheckout]: [HotCartExtended, boolean, boolean]) => {
                        const userType = localStorage.getItem(StorageKeys.userType);
                        const subDMode = localStorage.getItem(StorageKeys.subDistributorMode);
                        if (
                            cart.items.length &&
                            !splitOrdersBySuppliersWhenCheckout &&
                            (userType === AccountType.Customer || subDMode === AccountType.Customer)
                        ) {
                            this.warehouseService.setWarehouseFromCart(cart);
                        }
                        return [
                            isKeepPaymentMethod ? new CartUpdateData(cart) : new CartGetSuccess(cart),
                            new CatalogSynchronized(),
                            new EmptiesCatalogSynchronized(),
                            new ProductsSynchronized(),
                            new PromotionsSynchronized(),
                            new PopularProductsSynchronized(),
                            new CartGetRecommendedProducts(),
                        ];
                    }
                ),
                catchError((error: any) => {
                    if (error.status === 500) {
                        // TO DO: this conditions aren't correct
                        return of(new CartError(error.status === 500 || (error.status === 403 && 'server error')));
                    }
                })
            );
        })
    ));

    public CartRestored$: Observable<
        CartGetSuccess | CatalogSynchronized | ProductsSynchronized | PromotionsSynchronized
    > = createEffect(() => this._actions$.pipe(
        ofType<CartRestoreSuccess>(ECartActions.CART_RESTORED),
        switchMap((action: { payload: HotCartExtended }) => {
            this.orderCreationService.setDotPaymentFailedError(false);
            if (action.payload.items.length) {
                this.warehouseService.setWarehouseFromCart(action.payload);
            }
            return [
                new CartGetSuccess(action.payload),
                new CatalogSynchronized(),
                new ProductsSynchronized(),
                new PromotionsSynchronized(),
            ];
        })
    ));

    public CartAddItem$: Observable<CartManageProductsBuffer> = createEffect(() => this._actions$.pipe(
        ofType<CartAddItem>(ECartActions.CART_IN_PROGRESS),
        withLatestFrom(this._store.pipe(select(cartItems))),
        map(
            (data: [CartAddItem, HotCartLineItemExtended[]]) =>
                new CartManageProductsBuffer(data[0].payload as ProductFunctionalityModel)
        )
    ));

    public CartSelectItems$: Observable<any> = createEffect(() => this._actions$.pipe(
        ofType<CartSelectItems>(ECartActions.CART_ITEMS_SELECT),
        switchMap(({ payload }) => this.hotApiCartsService.selectItems(payload)),
        switchMap((cart) => [new CartGetSuccess(cart)])
    ));

    public CartAddItems$: Observable<
        CartGetSuccess | CatalogSynchronized | ProductsSynchronized | PromotionsSynchronized
    > = createEffect(() => this._actions$.pipe(
        ofType<CartAddItems>(ECartActions.CART_ITEMS_ADD),
        switchMap(({payload}) => this.hotApiCartsService.addLineItems({"body": payload})),
        switchMap((cart) => [
            new CartGetSuccess(cart),
            new CatalogSynchronized(),
            new ProductsSynchronized(),
            new PromotionsSynchronized(),
        ])
    ));

    public CartManageProductsBuffer$: Observable<void> = createEffect(() => this._actions$.pipe(
        ofType<CartManageProductsBuffer>(ECartActions.CART_MANAGE_PRODUCTS_BUFFER),
        withLatestFrom(this._store.pipe(select(cartChangesBuffer)), this._store.pipe(select(cartItems))),
        map((data: [CartManageProductsBuffer, ProductsBufferModel, HotCartLineItemExtended[]]) => {
            this.cartService.manageProductsBuffer(data[1], data[0].payload);
        })
    ),{ dispatch: false }
    );

    public CartAddPendingChanges$: Observable<void> = createEffect(() => this._actions$.pipe(
        ofType<CartAddPendingChanges>(ECartActions.CART_ADD_PENDING_CHANGES),
        map((data: CartAddPendingChanges) => {
            localStorage.setItem(StorageKeys.productsBuffer, JSON.stringify(data.payload));
            this.appInsightsService.startTrackingEvent(TelemetryEventType.CartChangedOnline);
        })
    ),{ dispatch: false }
    );

    public CartMovePendingToProcessingChanges$: Observable<void> = createEffect(() => this._actions$.pipe(
        ofType<CartMovePendingToProcessChanges>(ECartActions.CART_MOVE_PENDING_TO_PROCESS_CHANGES),
        map((data: CartMovePendingToProcessChanges) => {
            const merged: ProductFunctionalityModel[] = [...data.payload.changedItems, ...data.payload.pending];
            data.payload.changedItems = merged.reduce(
                (acc: ProductFunctionalityModel[], current: ProductFunctionalityModel) => [
                    ...acc.filter((obj: ProductFunctionalityModel) => obj.productId !== current.productId),
                    current,
                ],
                []
            );
            localStorage.setItem(StorageKeys.productsBuffer, JSON.stringify(data.payload));
        })
    ),{ dispatch: false }
    );

    public CartClearProcessChanges$: Observable<void> = createEffect(() => this._actions$.pipe(
        ofType<CartClearProcessChanges>(ECartActions.CART_CLEAR_PROCESS_CHANGES),
        map((data: CartClearProcessChanges) => {
            localStorage.setItem(StorageKeys.productsBuffer, JSON.stringify(data.payload));
            this.appInsightsService.endTrackingEvent(TelemetryEventType.CartChangedOnline);
        })
    ),{ dispatch: false }
    );

    public CartClearChangedItems$: Observable<void> = createEffect(() => this._actions$.pipe(
        ofType<CartClearChangedItems>(ECartActions.CART_CLEAR_CHANGED_ITEMS),
        map((data: CartClearChangedItems) => {
            localStorage.setItem(StorageKeys.productsBuffer, JSON.stringify(data.payload));
        })
    ),{ dispatch: false }
    );

    public CartRemoveItem$: Observable<cartUpdateRemoveActions> = createEffect(() => this._actions$.pipe(
        ofType<CartRemoveItem>(ECartActions.CART_ITEM_REMOVE),
        filter(() => true),
        withLatestFrom(this._store.pipe(select(cartItems))),
        mergeMap(([data]) =>
            this.hotApiCartsService.removeLineItem(data.payload.id).pipe(
                switchMap((response: HotCartExtended) => {
                    this.itemlocationService.removeItemLocation(data.payload.sku, 'cart sidebar');
                    this.analyticService.sendRemoveCartEventInfo(data.payload, 'cart sidebar');
                    return [
                        new CartGetSuccess(response),
                        new CatalogSynchronized(),
                        new EmptiesCatalogSynchronized(),
                        new ProductsSynchronized(),
                        new PromotionsSynchronized(),
                        new PopularProductsSynchronized(),
                        new CartGetRecommendedProducts(),
                    ];
                })
            )
        )
    ));

    public cartCreateOrder$: Observable<CartCreateOrderSuccess | CartError | CartPromoError> = createEffect(() => this._actions$.pipe(
        ofType<CartCreateOrder>(ECartActions.CART_CREATE_ORDER),
        withLatestFrom(
            this._store.pipe(select(cartRemarks)),
            this._store.pipe(select(cartData)),
            this._store.pipe(select(cartError)),
            this.featuresService.checkFeatures(FeatureNames.SplitOrdersByPromotionWhenCheckout),
            this.featuresService.checkFeatures(FeatureNames.SplitOrdersBySuppliersWhenCheckout)
        ),
        switchMap(
            ([
                _action,
                remarkCart,
                dataCart,
                errorCart,
                featureSplitOrdersByPromotionWhenCheckout,
                featureSplitOrdersBySuppliersWhenCheckout,
            ]: [CartCreateOrder, HotCreateOrderRequest, HotCartExtended, any, boolean, boolean]) => {
                const tempCartError = [];
                errorCart?.forEach((errorData) => {
                    let cartErrorDesc = errorData?.description || errorData;
                    tempCartError.push(cartErrorDesc);
                });
                const request: HotCreateOrderRequest = {
                    ...remarkCart,
                    paymentMethod: dataCart.selectedPaymentMethod?.code || dataCart.paymentMethod,
                    cartValidationMessages: tempCartError,
                };

                if (this.orderCreationService.invoiceType) {
                    request.invoiceType = this.orderCreationService.invoiceType;
                }

                const isSplitOrderByPromoOrSupplier =
                    featureSplitOrdersByPromotionWhenCheckout || featureSplitOrdersBySuppliersWhenCheckout;

                if (isSplitOrderByPromoOrSupplier) {
                    return this.hotApiCartsService.createOrders({"body": request}).pipe(
                        map((response: HotCreateOrderResponse) => {
                            return this.createOrdersHelper(response);
                        })
                    );
                } else {
                    return this.hotApiCartsService.createOrder({"body": request}).pipe(
                        map((response: HotCreateOrderResponse) => {
                            return this.createOrderHelper(response);
                        })
                    );
                }
            }
        )
    ));

    private createOrdersHelper(response: HotCreateOrderResponse) {
        if (response.succeeded) {
            this.cartService.orderNo = response.orders[0].number;
            this.appInsightsService.endTrackingEvent(TelemetryEventType.OrderCreateResponseTime);
            return new CartCreateOrderSuccess(response.orders[0]);
        }
        if (
            response.errorCodes.includes('PromoExpired') ||
            response.errorCodes.includes('DiscountExpired') ||
            response.errorCodes.includes('CartDiscountExpired')
        ) {
            return new CartPromoError(response);
        }
        return this.checkCartErrors(response);
    }

    private createOrderHelper(response: HotCreateOrderResponse) {
        if (response.succeeded) {
            this.cartService.orderNo = response.order.number;
            this.appInsightsService.endTrackingEvent(TelemetryEventType.OrderCreateResponseTime);
            return new CartCreateOrderSuccess(response.order);
        }
        if (
            response.errorCodes.includes('PromoExpired') ||
            response.errorCodes.includes('DiscountExpired') ||
            response.errorCodes.includes('CartDiscountExpired')
        ) {
            return new CartPromoError(response);
        }
        return this.checkCartErrors(response);
    }

    private checkCartErrors(response: HotCreateOrderResponse) {
        if (!response.errorCodes.includes('CartHasChangedError')) {
            this._store.dispatch(new CartOrderPlacementError());
        }
        this._store.dispatch(new CartErrorCodes(response.errorCodes));
        return new CartError(response.errors);
    }

    public cartCreateOrderSuccess$: Observable<OrdersPreviousGet | CartClearWithApiRequest> = createEffect(() => this._actions$.pipe(
        ofType<CartCreateOrderSuccess>(ECartActions.CART_CREATE_ORDER_SUCCESS),
        withLatestFrom(
            this.featuresService.checkFeatures(FeatureNames.OrderPaymentsProcessing),
            this.featuresService.checkFeatures(FeatureNames.BoostOrderCheckout),
            this.featuresService.checkFeatures(FeatureNames.SplitOrdersByPromotionWhenCheckout),
            this._store.pipe(select(cartPaymentMethod)),
            this._store.pipe(select(authUser))
        ),
        map(
            ([
                action,
                featureOrderPaymentsProcessing,
                featureBoostOrderCheckout,
                featureSplitOrdersByPromotionWhenCheckout,
                paymentMethod,
                authUser,
            ]: [CartCreateOrderSuccess, boolean, boolean, boolean, HotPaymentMethods, HotUserExtended]) => {
                let orderNumber = action.payload?.number;
                let orderId = action.payload?.id;
                const Haschangedordernumber =
                    featureSplitOrdersByPromotionWhenCheckout && action.payload?.originalNumber;
                const checkFeatures =
                    featureOrderPaymentsProcessing &&
                    !featureBoostOrderCheckout &&
                    paymentMethod?.paymentHandlingOrderPhase === 'Checkout';
                const isFeatureOrderPaymentsProcessingandCheckout =
                    featureOrderPaymentsProcessing && paymentMethod?.paymentHandlingOrderPhase === 'Checkout';
                const lastUsedPaymentMethod = JSON.parse(localStorage.getItem(StorageKeys.lastUsedPaymentMethod));
                if (!paymentMethod) {
                    paymentMethod = lastUsedPaymentMethod;
                }
                const isPopPilotUser = authUser.isPopPilotUser;
                const isMDOTAndExpressCheckout = this.isMDOT() && paymentMethod?.code == 'Dlocal.ExpressCheckout';
                const isMDOTAndNotExpressCheckout = this.isMDOT() && paymentMethod?.code != 'Dlocal.ExpressCheckout';
                const isPopDigitalCheckout = isPopPilotUser && paymentMethod?.code !== 'CASH ORDER';
                if (Haschangedordernumber) {
                    orderNumber = action.payload.originalNumber;
                }
                if (isPopDigitalCheckout) {
                    this.popDigitalCheckout(orderNumber, paymentMethod, orderId, action.payload);
                } else if (isMDOTAndExpressCheckout) {
                    this.paymentProcessAndSmartFieldSwitch(isFeatureOrderPaymentsProcessingandCheckout, orderNumber);
                } else if (isMDOTAndNotExpressCheckout) {
                    this.paymentProcessAndThankYouSwitch(
                        isFeatureOrderPaymentsProcessingandCheckout,
                        orderNumber,
                        action,
                        isPopPilotUser
                    );
                } else if (!this.isMDOT()) {
                    if (checkFeatures) {
                        this.router.navigate(['/payment-process', orderNumber]);
                    } else {
                        this.cartCreateOrderSuccessHelper(
                            featureBoostOrderCheckout,
                            featureSplitOrdersByPromotionWhenCheckout,
                            paymentMethod,
                            orderNumber,
                            action
                        );
                    }
                }

                return new CartClearWithApiRequest();
            }
        )
    ));

    public popDigitalCheckout(
        orderNumber: any,
        paymentMethod: HotPaymentMethods,
        orderId: any,
        order: HotOrderExtended
    ) {
        const params: HotApiOrdersService.ProcessOrderPaymentParams = {
            orderNumber: orderNumber,
        };
        if (this.router.url.includes('retry-payment')) {
            paymentMethod = JSON.parse(localStorage.getItem(StorageKeys.lastUsedPaymentMethod));
        }
        if (paymentMethod?.code == 'Pop.Card') {
            params.selectedPaymentCode = paymentMethod?.code;
            this.processOrderPayment(params, orderNumber, paymentMethod, orderId, order);
        } else {
            this.APMGateWayCode = paymentMethod?.gatewayName;
            localStorage.setItem('apm-gate-way-code', this.APMGateWayCode);
            this.router.navigate(['/apm-redirector', orderNumber, 'process-apm']);
        }
    }
    public processOrderPayment(
        params: any,
        orderNumber: any,
        paymentMethod: HotPaymentMethods,
        orderId: any,
        order: HotOrderExtended
    ) {
        this.hotApiOrdersService.processOrderPayment(params).subscribe((response) => {
            if (response.isSuccess && paymentMethod?.code === 'Pop.Card') {
                let invoiceId = response.outerId;
                this.orderCreationService.setDotPaymentFailedError(false);
                this.loaderServiceV2.show();
                this.processoutCardTokenized(invoiceId, orderNumber, orderId, order, paymentMethod);
            } else {
                this.orderCreationService.setDotPaymentFailedError(true);
                this.paymentFailed(orderNumber);
            }
        });
    }

    private processoutCardTokenized(invoice_id, orderNumber, orderId, order, paymentMethod) {
        let invoiceID = invoice_id;
        let token = localStorage.getItem('card-token');
        const self = this;
        this.client = new ProcessOut.ProcessOut(JSON.parse(localStorage.getItem(StorageKeys.popProjectId)));
        let paymentError =
            this.translateService.instant('shared.modals.payment-failed-pop.content-1') +
            this.translateService.instant('shared.modals.payment-failed-pop.content-2');
        this.client.makeCardPayment(
            invoiceID,
            token,
            {
                authorize_only: true,
            },
            function (iv) {
                self.loaderServiceV2.hide();
                let field = document.createElement('input');
                field.type = 'hidden';
                field.name = 'invoice_id';
                field.value = iv;
                self.authorizationResponse = true;
                self.updateAuthorizationStatus(orderId);
                self.itemlocationService.addItemListLocation(order.items, 'noUpdate');
                self.analyticService.sendPurchaseEventInfo(order, paymentMethod.name);
                self.itemlocationService.deleteProductUsingOutletId();
                self.navigateToThankYouPage(orderNumber);
            },
            function (err) {
                self.loaderServiceV2.hide();
                self.authorizationResponse = false;
                self.updateAuthorizationStatus(orderId);
                self.modalService.toggleModal(ModalIds.paymentFailed, true);
                self.analyticService.sendPOPPaymentErrorEventInfo(paymentError);
                self.paymentFailed(orderNumber);
            }
        );
    }

    public updateAuthorizationStatus(orderId) {
        let authorizationStatus = {
            id: orderId,
            status: this.authorizationResponse ? 'PreAuthorized' : 'PreAuthorizedFailed',
        };
        this.popApiService.updatePopCaptureStatus(authorizationStatus).subscribe();
    }

    public navigateToThankYouPage(orderNumber): void {
        this.router.navigate(['/thank-you', orderNumber]);
    }

    public paymentFailed(orderNumber: any) {
        this.apiOrdersService
            .getOrderByNumber(orderNumber)
            .pipe(withLatestFrom(this.storeSettings$))
            .subscribe(([order, storeSettings]: [HotOrder, SettingsStoreModel]) => {
                if (storeSettings.allowUnfinishedPaymentsProcessing) {
                    this.popService.rollBackOrder(order);
                }
            });
    }

    private paymentProcessAndSmartFieldSwitch(isFeatureOrderPaymentsProcessingandCheckout, orderNumber) {
        if (isFeatureOrderPaymentsProcessingandCheckout) {
            this.orderService.assignOrderNumber(orderNumber);
            this.router.navigate(['/smart-field']);
        } else {
            this.router.navigate(['/payment-process', orderNumber]);
        }
    }

    private paymentProcessAndThankYouSwitch(
        isFeatureOrderPaymentsProcessingandCheckout,
        orderNumber,
        action,
        isPopPilotUser
    ) {
        if (isFeatureOrderPaymentsProcessingandCheckout) {
            this.router.navigate(['/payment-process', orderNumber]);
        } else {
            if (isPopPilotUser && action.payload.paymentMethod == 'CASH ORDER') {
                this.paymentMethodData = 'Pay with Cash';
            } else {
                this.paymentMethodData = 'default';
            }
            this.itemlocationService.addItemListLocation(action.payload.items, 'noUpdate');
            this.analyticService.sendPurchaseEventInfo(action.payload, this.paymentMethodData);
            this.itemlocationService.deleteProductUsingOutletId();
            this.router.navigate(['/thank-you', orderNumber]);
        }
    }

    private cartCreateOrderSuccessHelper(
        featureBoostOrderCheckout,
        featureSplitOrdersByPromotionWhenCheckout,
        paymentMethod,
        orderNumber,
        action
    ): void {
        if (featureBoostOrderCheckout && paymentMethod?.paymentHandlingOrderPhase === 'Checkout') {
            this.router.navigate(['/payment-process', orderNumber]);
        } else {
            this.itemlocationService.addItemListLocation(action.payload.items, 'noUpdate');
            this.analyticService.sendPurchaseEventInfo(action.payload, paymentMethod?.name ?? '');
            this.itemlocationService.deleteProductUsingOutletId();
            if (featureSplitOrdersByPromotionWhenCheckout) {
                this.router.navigate(['/thank-you-page', orderNumber]);
            } else {
                this.router.navigate(['/thank-you', orderNumber]);
            }
        }
    }

    public cartOrderPlacementError$: Observable<CartGet | SettingsStoreGet> = createEffect(() => this._actions$.pipe(
        ofType<CartOrderPlacementError>(ECartActions.CART_ORDER_PLACEMENT_ERROR),
        switchMap((_: CartOrderPlacementError) => [new CartGet(), new SettingsStoreGet()])
    ));

    public cartCreateOrders$: Observable<CartCreateOrdersSuccess | CartError | CartPromoError> = createEffect(() => this._actions$.pipe(
        ofType<CartCreateOrders>(ECartActions.CART_CREATE_ORDERS),
        withLatestFrom(
            this._store.pipe(select(cartRemarks)),
            this._store.pipe(select(cartData)),
            this._store.pipe(select(cartError))
        ),
        switchMap(
            ([action, remarkItems, dataItems, errorItems]: [
                CartCreateOrders,
                HotCreateOrderRequest,
                HotCartExtended,
                any
            ]) => {
                const tempCartError = [];
                errorItems?.forEach((errorItem) => {
                    tempCartError.push(errorItem.description ? errorItem.description : errorItem);
                });

                const request: HotCreateOrderRequest = {
                    paymentMethod: dataItems.selectedPaymentMethod?.code || dataItems.paymentMethod,
                    cartValidationMessages: tempCartError,
                    ...remarkItems,
                };
                request.splitAlcoholicNonAlcoholicProducts = action.payload;

                return this.hotApiCartsService.createOrders({"body": request }).pipe(
                    map((response: HotCreateOrderResponse) => {
                        if (response.succeeded) {
                            this.appInsightsService.endTrackingEvent(TelemetryEventType.OrderCreateResponseTime);
                            this.itemlocationService.addItemListLocation(response.orders[0].items, 'noUpdate');
                            this.analyticService.sendPurchaseEventInfo(response.orders[0], null);
                            this.itemlocationService.deleteProductUsingOutletId();
                            return new CartCreateOrdersSuccess(response.orders.map((order) => order.number));
                        } else if (response.errorCodes.includes('NoLineItems')) {
                            return new CartPromoError(response.errors);
                        } else {
                            return new CartError(response.errors);
                        }
                    })
                );
            }
        )
    ));

    public cartCreateOrdersSuccess$: Observable<
        OrdersPreviousGet | CartClearWithApiRequest | CartGet
    > = createEffect(() => this._actions$.pipe(
        ofType<CartCreateOrdersSuccess>(ECartActions.CART_CREATE_ORDERS_SUCCESS),
        withLatestFrom(
            this.featuresService.checkFeatures(FeatureNames.OrderPaymentsProcessing),
            this.featuresService.checkFeatures(FeatureNames.SplitOrdersBySuppliersWhenCheckout),
            this._store.pipe(select(cartPaymentMethod))
        ),
        map(
            ([action, featureOrderPaymentsProcessing, featureSplitOrdersBySuppliersWhenCheckout, paymentMethod]: [
                CartCreateOrdersSuccess,
                boolean,
                boolean,
                HotPaymentMethod
            ]) => {
                if (featureOrderPaymentsProcessing && paymentMethod?.paymentHandlingOrderPhase === 'Checkout') {
                    this.router.navigate(['/payment-process', action.payload]);
                } else {
                    this.router.navigate(['/thank-you', action.payload[0]], { fragment: action.payload.join(',') });
                }
                if (featureSplitOrdersBySuppliersWhenCheckout) {
                    return new CartGet();
                } else {
                    return new CartClearWithApiRequest();
                }
            }
        )
    ));

    public cartSetCoupon$: Observable<CartGetSuccess> = createEffect(() => this._actions$.pipe(
        ofType<CartSetCoupon>(ECartActions.CART_SET_COUPON),
        switchMap((action: CartSetCoupon) =>
            this.hotApiCartsService
                .addCoupon(action.payload)
                .pipe(map((response: HotCartExtended) => new CartGetSuccess(response)))
        )
    ));

    public cartRemoveCoupon$: Observable<CartGetSuccess> = createEffect(() => this._actions$.pipe(
        ofType<CartDeleteCoupon>(ECartActions.CART_DELETE_COUPON),
        switchMap(() =>
            this.hotApiCartsService
                .removeAllCoupons()
                .pipe(map((response: HotCartExtended) => new CartGetSuccess(response)))
        )
    ));

    public cartAddPreviousOrder$: Observable<cartAddActions> = createEffect(() => this._actions$.pipe(
        ofType<CartAddPreviousOrder>(ECartActions.CART_ADD_PREVIOUS_ORDER),
        switchMap((action: CartAddPreviousOrder) =>
            this.cartService.addPreviousOrder(action.payload.id).pipe(
                switchMap((response: HotCartExtended) => {
                    this.itemlocationService.addItemListLocation(response.items, 'previous orders add to cart');
                    this.analyticService.sendAddCartEventInfo(response.items, 'previous orders add to cart');
                    if (action.payload.route) {
                        this.router.navigate([action.payload.route]);
                    }
                    return [
                        new CartGetSuccess(response),
                        new CatalogSynchronized(),
                        new ProductsSynchronized(),
                        new PromotionsSynchronized(),
                        new PopularProductsSynchronized(),
                    ];
                })
            )
        )
    ));

    public cartAddPreviousOrderAndCheckOut$: Observable<cartAddActions> = createEffect(() => this._actions$.pipe(
        ofType<CartAddPreviousOrderAndCheckOut>(ECartActions.CART_ADD_PREVIOUS_ORDER_AND_CHECKOUT),
        switchMap((action: CartAddPreviousOrderAndCheckOut) =>
            this.cartService.addPreviousOrderAndCheckOut(action.payload).pipe(
                switchMap((response: HotCartExtended) => {
                    this.router.navigate(['/checkout']);
                    return [
                        new CartGetSuccess(response),
                        new CatalogSynchronized(),
                        new ProductsSynchronized(),
                        new PromotionsSynchronized(),
                        new PopularProductsSynchronized(),
                    ];
                })
            )
        )
    ));

    public cartSavePreviousOrder$: Observable<CartSaveTemplateSuccess | OrderTemplateAddNew> = createEffect(() => this._actions$.pipe(
        ofType<CartSavePreviousOrder>(ECartActions.CART_SAVE_PREVIOUS_ORDER),
        switchMap((action: CartSavePreviousOrder) => {
            return this.hotApiOrdersService
                .saveOrderAsTemplate({ orderId: action.payload.orderId, name: action.payload.name })
                .pipe(
                    withLatestFrom(this._store.select(settingsStore)),
                    switchMap(([response, storeSettings]: [HotOrderTemplate, SettingsStoreModel]) => {
                        this.cartService.orderSaved.next(true);
                        // Convert HotOrderTemplate to OrderTemplateModel because some properties is missing
                        const orderTemplate = ({ ...response } as unknown) as OrderTemplateModel;
                        const allowOutOfStockItems =
                            this.featuresService.AllowDistributorsToControlStoreSettings &&
                            storeSettings.displayOutOfStockProductsInCustomersCatalog;
                        orderTemplate.isValid = orderTemplate.items.some(
                            (item: OrderTemplateItemModel) =>
                                (item.isInStock || allowOutOfStockItems) && !item.isUnavailableForCustomers
                        );

                        return [new CartSaveTemplateSuccess(), new OrderTemplateAddNew(orderTemplate)];
                    })
                );
        })
    ));

    public cartAddOrderTemplate$: Observable<cartAddActions> = createEffect(() => this._actions$.pipe(
        ofType<CartAddOrderTemplate>(ECartActions.CART_ADD_ORDER_TEMPLATE),
        switchMap((action: CartAddOrderTemplate) =>
            this.cartService.addOrderTemplate(action.payload).pipe(
                switchMap((response: HotCartExtended) => {
                    this.itemlocationService.addItemListLocation(response.items, 'order templates add to cart');
                    this.analyticService.sendAddCartEventInfo(response.items, 'order templates add to cart');
                    this.router.navigate(['/checkout']);
                    return [
                        new CartGetSuccess(response),
                        new CatalogSynchronized(),
                        new ProductsSynchronized(),
                        new PromotionsSynchronized(),
                        new PopularProductsSynchronized(),
                    ];
                })
            )
        )
    ));

    public cartAddOrderTemplateAndCheckOut$: Observable<cartAddActions> = createEffect(() => this._actions$.pipe(
        ofType<CartAddOrderTemplateAndCheckOut>(ECartActions.CART_ADD_ORDER_TEMPLATE_AND_CHECKOUT),
        switchMap((action: CartAddOrderTemplateAndCheckOut) =>
            this.cartService.addOrderTemplateAndCheckOut(action.payload).pipe(
                switchMap((response: HotCartExtended) => {
                    this.itemlocationService.addItemListLocation(response.items, 'order templates checkout');
                    this.analyticService.sendAddCartEventInfo(response.items, 'order templates checkout');
                    this.router.navigate(['/checkout']);
                    return [
                        new CartGetSuccess(response),
                        new CatalogSynchronized(),
                        new ProductsSynchronized(),
                        new PromotionsSynchronized(),
                        new PopularProductsSynchronized(),
                    ];
                })
            )
        )
    ));

    public cartSaveTemplate$: Observable<CartSaveTemplateSuccess | OrderTemplateAddNew> = createEffect(() => this._actions$.pipe(
        ofType<CartSaveTemplate>(ECartActions.CART_SAVE_TEMPLATE),
        switchMap((action: CartSaveTemplate) => {
            return this.hotApiCartsService.saveCartAsTemplate(action.payload).pipe(
                withLatestFrom(this._store.select(settingsStore)),
                switchMap(([response, storeSettings]: [HotOrderTemplate, SettingsStoreModel]) => {
                    this.orderTemplateService.deleteOrderTemplateCache();

                    // Convert HotOrderTemplate to OrderTemplateModel because some properties is missing
                    const orderTemplate = ({ ...response } as unknown) as OrderTemplateModel;
                    orderTemplate.isValid = orderTemplate.items.some((item: OrderTemplateItemModel) => item.isInStock);

                    const allowOutOfStockItems =
                        this.featuresService.AllowDistributorsToControlStoreSettings &&
                        storeSettings.displayOutOfStockProductsInCustomersCatalog;
                    orderTemplate.isValid = orderTemplate.items.some(
                        (item: OrderTemplateItemModel) => item.isInStock || allowOutOfStockItems
                    );

                    return [new CartSaveTemplateSuccess(), new OrderTemplateAddNew(orderTemplate)];
                })
            );
        })
    ));

    public cartCreateReorder$ = createEffect(() => this._actions$.pipe(
        ofType<CartCreateReorder>(ECartActions.CART_CREATE_REORDER),
        switchMap((action: CartCreateReorder) =>
            this.hotApiCartsService
                .removeAllLineItems()
                .pipe(mergeMap(() => this.cartService.addPreviousOrder(action.payload)))
        ),
        switchMap((data: HotCartExtended) => [
            new CartGetSuccess(data),
            new CatalogSynchronized(),
            new ProductsSynchronized(),
            new PromotionsSynchronized(),
            new PopularProductsSynchronized(),
        ]),
        tap(() => this.loaderService.hide())
    ));

    public cartOfflineCreateReorder$ = createEffect(() => this._actions$.pipe(
        ofType<CartOfflineCreateReorder>(ECartActions.CART_OFFLINE_CREATE_REORDER),
        switchMap((action: CartOfflineCreateReorder) => [
            new CartAddPreviousOrderOffline(action.payload),
            new CartOfflineSynchronized(),
        ])
    ));

    public cartUpdateItem$: Observable<cartUpdateRemoveActions> = createEffect(() => this._actions$.pipe(
        ofType<CartUpdateItem>(ECartActions.CART_UPDATE_ITEM),
        switchMap((action: CartUpdateItem) => this.cartUpdate(action))
    ));
    public cartUpdate(action) {
        const actionMethod = action.type === 'updateLineItem' ? 'updateLineItems' : 'updateLineItem';
        return this.hotApiCartsService[actionMethod](action.payload).pipe(
            switchMap((response: HotCartExtended) => [
                new CartGetSuccess(response),
                new CatalogSynchronized(),
                new EmptiesCatalogSynchronized(),
                new ProductsSynchronized(),
                new PromotionsSynchronized(),
                new PopularProductsSynchronized(),
                new CartGetRecommendedProducts(),
            ])
        );
    }

    public cartUpdateItems$: Observable<cartUpdateRemoveActions> = createEffect(() => this._actions$.pipe(
        ofType<CartUpdateItems>(ECartActions.CART_UPDATE_ITEMS),
        switchMap((action: CartUpdateItems) => this.cartUpdate(action))
    ));

    public cartOfflineAddItem$: Observable<CartOfflineGet | CartOfflineSynchronized> = createEffect(() => this._actions$.pipe(
        ofType<CartOfflineAddItem>(ECartActions.CART_OFFLINE_ADD_ITEM),
        switchMap(() => [new CartOfflineGet(), new CartOfflineSynchronized()])
    ));

    public cartOfflineChangeItem$: Observable<CartOfflineGet | CartOfflineSynchronized> = createEffect(() => this._actions$.pipe(
        ofType<CartOfflineChangeItem>(ECartActions.CART_OFFLINE_CHANGE_ITEM),
        switchMap(() => [new CartOfflineGet(), new CartOfflineSynchronized()])
    ));

    public cartOfflineRemoveItem$: Observable<CartOfflineGet | CartOfflineSynchronized> = createEffect(() => this._actions$.pipe(
        ofType<CartOfflineRemoveItem>(ECartActions.CART_OFFLINE_REMOVE_ITEM),
        switchMap(() => [new CartOfflineGet(), new CartOfflineSynchronized()])
    ));

    public cartOfflineSynchronized$: Observable<
        | CartItemsRemoveGifts
        | CatalogSynchronized
        | EmptiesCatalogSynchronized
        | ProductsSynchronized
        | PromotionsSynchronized
        | PopularProductsSynchronized
    > = createEffect(() => this._actions$.pipe(
        ofType<CartOfflineSynchronized>(ECartActions.CART_OFFLINE_SYNCHRONIZED),
        switchMap(() => [
            new CartItemsRemoveGifts(),
            new CatalogSynchronized(),
            new EmptiesCatalogSynchronized(),
            new ProductsSynchronized(),
            new PromotionsSynchronized(),
            new PopularProductsSynchronized(),
        ])
    ));

    public cartClear$: Observable<CartGetSuccess | CartOfflineSynchronized> = createEffect(() => this._actions$.pipe(
        ofType<CartClearWithApiRequest>(ECartActions.CART_CLEAR_WITH_API_REQUEST),
        switchMap(() =>
            this.hotApiCartsService
                .removeAllLineItems()
                .pipe(
                    switchMap((response: HotCartExtended) => [
                        new CartGetSuccess(response),
                        new CartOfflineSynchronized(),
                    ])
                )
        )
    ));

    public cartAddPreviousOrderOffline$ = createEffect(() => this._actions$.pipe(
        ofType<CartAddPreviousOrderOffline>(ECartActions.CART_ADD_PREVIOUS_ORDER_OFFLINE),
        switchMap(() => [new CartAddPreviousOrderOfflineSuccess()])
    ));

    public cartOfflineClear$: Observable<CartOfflineGet | CartOfflineSynchronized> = createEffect(() => this._actions$.pipe(
        ofType<CartOfflineClear>(ECartActions.CART_OFFLINE_CLEAR),
        switchMap(() => [new CartOfflineGet(), new CartOfflineSynchronized()])
    ));

    public CartGetRecommendedProducts$: Observable<CartGetRecommendedProductsSuccess> = createEffect(() => this._actions$.pipe(
        ofType<CartGetRecommendedProducts>(ECartActions.CART_RECOMMENDED_PRODUCTS),
        withLatestFrom(
            this.featuresService.checkFeatures(FeatureNames.EnableCrossSelling),
            this.featuresService.checkFeatures(FeatureNames.EnableProductRecommendation),
            this._store.pipe(select(cartItems))
        ),
        switchMap(([action, isCrossSellingEnabled, EnableProductRecommendation, itemCart]) => {
            if (isCrossSellingEnabled || EnableProductRecommendation) {
                let items = action.payload?.length ?? false ? action.payload : itemCart;
                return this.recommendedProductsService
                    .SearchRecommendedProducts(items, EnableProductRecommendation)
                    .pipe(
                        switchMap((recommendedProducts) => [new CartGetRecommendedProductsSuccess(recommendedProducts)])
                    );
            }
            return [new CartGetRecommendedProductsSuccess(new Array<HotProductExtended>())];
        })
    ));
}
