import { FeedbackTopicsState } from '@hot-b2b/store/feedback/model';
import { FeedbackTopicsActions } from '@hot-b2b/store/feedback/actions';
import { feedbackTopicsInitialState } from '@hot-b2b/store/feedback/state';
import { EFeedbackTopics } from './types';

export function feedbackTopicsReducer(
    state: FeedbackTopicsState = feedbackTopicsInitialState,
    action: FeedbackTopicsActions
): FeedbackTopicsState {
    switch (action.type) {
        case EFeedbackTopics.FEEDBACK_TOPICS_PENDING:
            return { ...state, pending: true, data: { topics: [] }, fetched: false, error: null };
        case EFeedbackTopics.FEEDBACK_TOPICS_FETCHED:
            return {
                ...state,
                pending: false,
                data: { ...state.data, topics: action.payload },
                fetched: true,
                error: null,
            };
        case EFeedbackTopics.FEEDBACK_TOPICS_ERROR:
            return { ...state, pending: false, data: { topics: [] }, fetched: false, error: action.payload };
        default:
            return state;
    }
}
