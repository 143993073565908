import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';

import { SettingsState } from '../settings/model';
import { stateSettings } from '../settings/selector';

import { calculateTemplateDiscountTotal, normalizeItemsQuantity } from './helpers';
import { OrderTemplateState } from './model';

import { AppState } from '@hot-b2b/store/reducers';
import { OrderTemplateModel } from 'apps/hot-b2b/src/app/order-template/models';
import find from 'lodash/find';

const stateOrderTemplate = (state: AppState): OrderTemplateState => state.templates;

export const orderTemplatesFetched: MemoizedSelector<AppState, boolean> = createSelector(
    stateOrderTemplate,
    (state: OrderTemplateState) => state.fetched
);

export const orderTemplates: MemoizedSelector<AppState, OrderTemplateModel[]> = createSelector(
    stateOrderTemplate,
    (state: OrderTemplateState) => (state.data.templates ? state.data.templates : null)
);

export const isTemplateNameExist: MemoizedSelectorWithProps<AppState, string, boolean> = createSelector(
    stateOrderTemplate,
    (state: OrderTemplateState, templateName: string) => !!find(state.data.templates, { name: templateName })
);

export const getSelectedTemplate = createSelector(
    stateOrderTemplate,
    stateSettings,
    (state: OrderTemplateState, settingsState: SettingsState) =>
        state.data.selectedTemplate
            ? normalizeItemsQuantity(
                  calculateTemplateDiscountTotal(state.data.selectedTemplate),
                  find(settingsState?.data?.features, { name: 'UsePackagesWithCartsAndOrders' })?.isActive
              )
            : null
);
