import { Component, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormValidationsService } from '@hot-theme-nx/common-api';
import { HotApiSecurityService, HotForgotPasswordRequest, HotSecurityResponse } from '@hot-theme-nx/generated-api';
import { AlertService, FeaturesService, ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { ModalIds, StorageKeys } from '@hot-libs/shared-types';
import { AuthenticationService, UserService } from 'apps/hot-b2b/src/app/account/services';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { HotResetPasswordTokenResponse } from 'libs/generated-api/src/lib/models/hot-reset-password-token-response';
import { PasswordTimerService } from 'apps/hot-b2b/src/app/shared/services/password-timer.service';

@Component({
    selector: 'hot-password-reset-modal',
    templateUrl: './password-reset-modal.component.html',
})
export class PasswordResetModalComponent implements OnDestroy {
    public modalPassExpiredState$: Observable<ModalsState>;
    public isSubmitted = false;
    public forgotPasswordForm: UntypedFormGroup = new UntypedFormGroup(
        {
            userName: new UntypedFormControl(null, FormValidationsService.fieldIsEmpty),
        },
        {
            validators: [FormValidationsService.simpleFormValidation],
        }
    );
    private readonly subscriptionDestroyer$ = new Subject<boolean>();
    @Input() userName;

    constructor(
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly hotApiSecurityService: HotApiSecurityService,
        private readonly featuresService: FeaturesService,
        private readonly alertService: AlertService,
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly userService: UserService,
        private readonly translateService: TranslateService,
        private readonly passwordService: PasswordTimerService
    ) {
        this.modalPassExpiredState$ = this.store.pipe(select(selectModalState(ModalIds.passwordResetModal)));
    }
    public featureSmsResetPassword: boolean = this.featuresService.SmsResetPassword;
    public featureEmailResetPassword: boolean = this.featuresService.EmailResetPassword;
    PATHS = PATHS;

    public onChangePasswordClick(): void {
        this.hotApiSecurityService
            .requestPasswordResetToken()
            .pipe(takeUntil(this.subscriptionDestroyer$))
            .subscribe((_response: HotResetPasswordTokenResponse) => {
                localStorage.removeItem(StorageKeys.bearerToken);
                this.modalService.toggleModal(ModalIds.passwordResetModal, false);
                this.sendResetPassword();
            });
    }
    public sendResetPassword(): void {
        this.isSubmitted = true;
        const forgotPasswordRequest: HotForgotPasswordRequest = {
            userName: this.userName,
            retrieveUserInfo: false,
        };
        if (this.featureSmsResetPassword && this.featureEmailResetPassword) {
            forgotPasswordRequest.retrieveUserInfo = true;
        }
        this.sendResetPasswordSubFunction(forgotPasswordRequest);
    }

    public sendResetPasswordSubFunction(forgotPasswordRequest) {
        this.hotApiSecurityService.forgotPassword(forgotPasswordRequest).subscribe({
            next: (response: HotSecurityResponse) => {
                this.alertService.reset();
                if (
                    response.errorCodes.length == 0 &&
                    response.errors[0] != 'cannot_send_otp' &&
                    response.errors[0] != 'resend_token_not_enough_time'
                ) {
                    if (this.featureSmsResetPassword && this.featureEmailResetPassword) {
                        this.passwordService.enableSMSandEmailOption(response, this.userName, 'password-reset');
                    } else if (this.featureSmsResetPassword && !this.featureEmailResetPassword) {
                        this.passwordService.enableSMSOption(response, this.userName, 'password-reset');
                    } else if (!this.featureSmsResetPassword && this.featureEmailResetPassword) {
                        this.passwordService.enableEmailOption(response, this.userName, 'password-reset');
                    } else {
                        this.router.navigateByUrl('/account/forgot-password/we-are-sorry');
                    }
                    const forgotPasswordUserDataRequest = {
                        userName: this.userName,
                        phoneNumber: response.phoneNumber,
                        emailId: response.emailId,
                        securePhone: this.passwordService.partiallyHidePhone(response.phoneNumber),
                        secureEmail: this.passwordService.partiallyHideEmail(response.emailId),
                    };
                    this.userService.setForgotPasswordUserData(forgotPasswordUserDataRequest);
                } else {
                    this.handleOtpGenerationError(response);
                }
            },
            error: (error: string) => {
                this.alertService.error(error);
            },
        });
    }

    handleOtpGenerationError(response: HotSecurityResponse) {
        if (response.errors[0] == 'cannot_send_otp') {
            this.alertService.error(this.translateService.instant('account.self-registration.errors.cannot_send_otp'));
        } else if (response.errors[0] == 'resend_token_not_enough_time') {
            this.alertService.error(
                this.translateService.instant('account.login.errors-messages.resend_token_not_enough_time')
            );
        } else {
            this.alertService.error(response.errors[0]);
        }
    }

    public onClose(): void {
        this.modalService.toggleModal(ModalIds.passwordResetModal, false);
        this.authenticationService.logout();
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroyer$.next(true);
    }
}
