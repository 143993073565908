import { Observable } from 'rxjs';
import { Component, EventEmitter, Input,OnInit,Output } from '@angular/core';
import {
    HotOrderStatusReasonsWithLanguage,
    HotOrderStatusReason,
    HotRejectOrderRequest,
    
} from '@hot-theme-nx/generated-api';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from 'apps/hot-b2b/src/app/order/services';
import { HotOrderExtended } from '@hot-libs/shared-models';
import { AppState } from '@hot-b2b/store/reducers';
import { select, Store } from '@ngrx/store';
import { getCustomerOrderRejectReasons } from '@hot-b2b/store/orders/selector';
import { RejectOrderByCustomer } from '@hot-b2b/store/orders/actions';
import { StorageKeys } from '@hot-libs/shared-types';
import { map,take } from 'rxjs/operators';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import {settingsData} from '@hot-b2b/store/settings/selector';

@Component({
    selector: 'hot-theme-reject-list-modal',
    templateUrl: './reject-list-modal.component.html',
})
export class RejectListModalComponent implements OnInit  {
    @Input() public order: HotOrderExtended;
    @Output() public rejectedEvent: EventEmitter<any> = new EventEmitter();
    @Output() public backButtonClickEvent: EventEmitter<any> = new EventEmitter();
    public settings$: Observable<HotSettingsExtended>;
    public featureEnableCHQIntegration: boolean;
    public otherReasonCancellationCode: string;

    public rejectReasons$: Observable<HotOrderStatusReasonsWithLanguage[]>;
    public rejectReasonsForCurrentLanguage: HotOrderStatusReasonsWithLanguage;
    public customReasonText: string = '';
    public includeCustomReasonToRequest = false;
    public selectedReasons: string[] = [];
    public currentLanguageCode: string = localStorage.getItem(StorageKeys.currentLanguage);

    constructor(
        private readonly orderService: OrderService, 
        private readonly store: Store<AppState>,
        private readonly translateService: TranslateService
        ) {
        this.rejectReasons$ = this.store.pipe(
            select(getCustomerOrderRejectReasons),
            map((items) =>
                items.filter((item) => item.language === this.currentLanguageCode).length
                    ? items.filter((item) => item.language === this.currentLanguageCode)
                    : items
            )
        );
        this.settings$ = this.store.pipe(select(settingsData));
    }

    ngOnInit(): void {
        this.otherReasonCancellationCode = this.translateService.instant('shared.modals.order-cancel.reasonCodeOthers');
        this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
            this.featureEnableCHQIntegration = settings.features.find((feature) => feature.name === "EnableCHQIntegration").isActive;
        });
        this.rejectReasons$.pipe(take(1)).subscribe((rejectReasons) => {
            this.rejectReasonsForCurrentLanguage= rejectReasons[0];
        } )
    }
    

    public rejectOrder() {
        const rejectionReasons = [...this.selectedReasons];

        if (this.includeCustomReasonToRequest && this.customReasonText) {
            rejectionReasons.push(this.customReasonText);
        }
        if(this.featureEnableCHQIntegration) {
            const rejectionReasonsWithCodes : HotOrderStatusReason[] = []
            rejectionReasons.forEach((reason) => {
                let hotOrderStatusReason : HotOrderStatusReason = {};
                if(reason.includes(',')) {
                    reason = reason.split(',').join(' ');
                }
                hotOrderStatusReason.text = reason;
                this.rejectReasonsForCurrentLanguage.orderStatusReasons.forEach((reason) => {
                    if(reason.text === hotOrderStatusReason.text) {
                        hotOrderStatusReason.code = reason.code; 
                    }
                })
                if(hotOrderStatusReason.code === undefined) {
                    hotOrderStatusReason.code = this.otherReasonCancellationCode;
                }
                rejectionReasonsWithCodes.push(hotOrderStatusReason);
            })
            let rejectReasonStringForCHQ : string = "";
            rejectionReasonsWithCodes.forEach((reasonWithCode) => {
                rejectReasonStringForCHQ += reasonWithCode.text+','+reasonWithCode.code+'|';
            })
            rejectReasonStringForCHQ = rejectReasonStringForCHQ.slice(0,-1);
            const rejectRequest: HotRejectOrderRequest = {
                orderId: this.order.id,
                rejectionReason: rejectReasonStringForCHQ,
            };
            this.store.dispatch(new RejectOrderByCustomer(rejectRequest));
            this.rejectedEvent.emit();
        }
        else {
            const rejectRequest: HotRejectOrderRequest = {
                orderId: this.order.id,
                rejectionReason: rejectionReasons.join('|'),
            };
    
            this.store.dispatch(new RejectOrderByCustomer(rejectRequest));
            this.rejectedEvent.emit();
        }
    }

    public onReasonCheckboxChange(checked: boolean, reason: string) {
        if (checked) {
             this.selectedReasons.push(reason)   
        }
        else {
            this.selectedReasons = this.selectedReasons.filter((selectedReason) => selectedReason != reason);
        }
    }

    public onCustomReasonCheckboxChange(checked: boolean) {
        this.includeCustomReasonToRequest = checked;
    }
}
