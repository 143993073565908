import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HotUserExtended } from '@hot-libs/shared-models';
import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { FeaturesService } from './features.service';
import { OpenItemPermissions } from '@hot-libs/shared-types';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    private readonly user$: Observable<HotUserExtended>;

    constructor(private readonly featureService: FeaturesService, private readonly store: Store<AppState>) {
        this.user$ = this.store.pipe(select(authUser));
    }

    public checkPermission(name: string): Observable<boolean> {
        return this.user$.pipe(
            map((user: HotUserExtended) => {
                if (!this.featureService.CustomerPermissions) {
                    return true;
                }

                return user?.hotPermissions?.some((p) => p === name);
            })
        );
    }

    public canUserAccessOpenItem(): Observable<boolean> {
        return this.user$.pipe(
            map((user: HotUserExtended) => {
                if (this.featureService.EnableOpenInvoice) {
                    return user?.hotPermissions?.some((p) => p === OpenItemPermissions.OpenItemAccess);
                }
                return false;
            })
        );
    }
}
