import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { CurrencyFormat } from '@hot-theme-nx/generated-api';

import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { settingsCurrencyFormat, settingsStore } from '@hot-b2b/store/settings/selector';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';

import { OrderDetailsService } from 'apps/hot-b2b/src/app/shared/services';

@Component({
    selector: 'hot-add-amount-modal',
    templateUrl: './add-amount-modal.component.html',
})
export class AddAmountModalComponent implements OnInit {
    public addAmountModalState$: Observable<ModalsState>;
    private orderNumber: string;
    private orderHasUnfinishedPayments: boolean;
    public amount = '0';
    public currencyFormat$: Observable<CurrencyFormat>;
    public isFirstClick = true;
    public hideCurrencySymbol = true;
    public submitDisabled: boolean;
    public isSubmitted: boolean;
    public totalAmount: string;
    public showError: boolean;

    public storeSettings$: Observable<SettingsStoreModel>;
    public partialPaymentsMinimumAmount$: Observable<number>;
    private readonly amountPattern = new RegExp(/[^\d.]/g);
    private amountFieldEl: HTMLElement;

    constructor(private readonly orderDetailsService: OrderDetailsService, private readonly store: Store) {
        this.addAmountModalState$ = this.store.pipe(select(selectModalState(ModalIds.addAmount)));
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.partialPaymentsMinimumAmount$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value),
            map((storeSettings: SettingsStoreModel) => storeSettings.partialPaymentsMinimumAmount)
        );
    }

    ngOnInit(): void {
        this.addAmountModalState$.pipe(take(1)).subscribe((modalState: ModalsState) => {
            if (modalState.data) {
                this.orderNumber = modalState.data.orderData.orderNumber;
                this.amount = modalState.data.orderData.orderTotal.toString();
                this.orderHasUnfinishedPayments = modalState.data.orderData.orderHasUnfinishedPayments;
            }
        });
    }

    public clearFieldOnFirstClick(): void {
        this.amountFieldEl = document.getElementById('amountField');
        this.amountFieldEl.focus();
        if (this.isFirstClick) {
            this.amount = '0';
            this.submitDisabled = true;
            this.isFirstClick = false;
        }
    }

    public onInputChange(event) {
        if (this.amountPattern.test(event.target.innerText)) {
            event.target.innerText = event.target.innerText.replace(this.amountPattern, '');
            event.target.innerText.replace(/,/g, '.');
            this.setCursorPosition(event.target.innerText.length);
        }

        if (!this.totalAmount) {
            event.target.innerText = event.target.innerText.substr(0, 1);
            this.setCursorPosition();
        }

        this.validateAmount(event.target.innerText);
    }

    public validateAmount(amount) {
        this.isSubmitted = false;
        this.showError = false;

        this.totalAmount = amount.replace(this.amountPattern, '');
        this.submitDisabled = +this.totalAmount === 0 || isNaN(+this.totalAmount);
    }

    // After first input we should move cursor to last position
    private setCursorPosition(position: number = 1) {
        // Creates range object
        const range = document.createRange();

        // Creates object for selection
        const selectionEl = window.getSelection();

        // Set start position of range
        range.setStart(this.amountFieldEl.childNodes[0], position);

        range.collapse(true);
        selectionEl.removeAllRanges();
        selectionEl.addRange(range);
        this.amountFieldEl.focus();
    }

    public payNow() {
        if (this.isFirstClick) {
            this.validateAmount(document.getElementById('amountField').textContent);
        }

        this.partialPaymentsMinimumAmount$.pipe(take(1)).subscribe((value: number) => {
            if (!value || value <= +this.totalAmount) {
                this.orderDetailsService.payNow(
                    this.orderNumber,
                    this.orderHasUnfinishedPayments,
                    Number(this.totalAmount)
                );
            } else {
                this.showError = true;
            }
        });
    }
}
