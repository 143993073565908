import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookiesService } from '../../../shared/services/cookies.service';

@Component({
    selector: 'iframe-redirector',
    templateUrl: './iframe-redirector.component.html',
})
export class IframeRedirectorComponent implements OnInit {
    cardId: string;
    paramsObject;

    constructor(private readonly route: ActivatedRoute, private cookieService: CookiesService) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.cardId = params.card_id;
            localStorage.setItem('card-token', this.cardId);
            localStorage.setItem('card_select', 'true');
            parent.location.reload();
        });
    }
}
