import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';

import { ConfigurationService, ModalService, OrderDetailsService } from '../../../services';

import { HotOrderExtended } from '@hot-libs/shared-models';
import { OrderService } from 'apps/hot-b2b/src/app/order/services/order.service';
import { HotOperationResult } from '@hot-theme-nx/generated-api';
import { Router } from '@angular/router';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { ModalIds, StorageKeys } from '@hot-libs/shared-types';

@Component({
    selector: 'hot-cancel-order-confirmation-modal',
    templateUrl: './cancel-order-confirmation-modal.component.html',
})
export class CancelOrderConfirmationModalComponent {
    @Input() public order: HotOrderExtended;
    @Input() public orderNumber: HotOrderExtended;
    @Output() public buttonCancelOrder: EventEmitter<any> = new EventEmitter();
    @Output() public closeButton: EventEmitter<void> = new EventEmitter();
    @Output() public callback: EventEmitter<any> = new EventEmitter();
    public isUpLg = () => this.screenDimensionService.upLg();
    public isMY = () => this.configurationService.getCurrentConfiguration() === 'MY';
    public isVn = () => this.configurationService.getCurrentConfiguration() === 'VN';

    private readonly cancelledStatus = 'Cancelled';
    private readonly cancelledExtendedStatus = 'cancelled.customer.default';

    constructor(
        private readonly orderDetailsService: OrderDetailsService,
        private readonly orderService: OrderService,
        private readonly router: Router,
        public readonly screenDimensionService: ScreenDimensionService,
        private readonly modalService: ModalService,
        private readonly configurationService: ConfigurationService,
        ) { }

    public closeModal(): void {
        this.closeButton.emit();
    }

    public cancelOrder(): void {
        this.callback.emit(true);
        this.orderService
            .cancelOrder(this.order.id)
            .pipe(take(1))
            .subscribe((operationResult: HotOperationResult) => {
                if (operationResult.succeeded) {
                        this.orderDetailsService.setNewOrderStatus(
                        this.order,
                        this.cancelledStatus,
                        this.cancelledExtendedStatus
                    );
                    this.buttonCancelOrder.emit(this.order);
              if(this.orderNumber){
                this.router.navigate([`/orders/${this.orderNumber}`]);
              }
                else{
                     this.orderDetailsService.closeOrderDetailsWithUpdateOrder(this.order);
              }
                } else {
                    this.orderService.reportAnError(operationResult.errors);
                    this.orderDetailsService.closeOrderDetailsWithUpdateOrder(this.order);
                    this.orderDetailsService.closeCancelOrderConfirmationModal();
                }
                this.orderDetailsService.closeCancelOrderConfirmationModal();
                if(this.isMY() || this.isVn()){
                if(localStorage.getItem("cancelorderValue") === "true"){
                    this.modalService.toggleModal(ModalIds.order, false);
                }
                else{
                    this.modalService.toggleModal(ModalIds.order, false);
                    this.orderDetailsService.closeOrderDetailsWithUpdateOrder(this.order);
                }
                localStorage.removeItem(StorageKeys.cancelorderValue);
                }
                });
    }
}
