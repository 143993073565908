import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CurrencyFormat, HotDeliveredOrdersStatistics } from '@hot-theme-nx/generated-api';

import { PermissionsService } from '../../services/permissions.service';

import { AppState } from '@hot-b2b/store/reducers';
import {
    settingsCurrencyFormat,
    settingsData,
    settingsStore,
    settingsLoyaltyCurrencyFormat,
} from '@hot-b2b/store/settings/selector';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { CustomerPermissions } from '@hot-libs/shared-types';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { filter } from 'rxjs/operators';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';

@Component({
    selector: 'hot-order-delivered',
    templateUrl: './order-delivered.component.html',
})
export class OrderDeliveredComponent {
    @Input() public statistics: HotDeliveredOrdersStatistics;
    @Input() public pageName: string;

    public PATHS = PATHS;
    public currencyFormat$: Observable<CurrencyFormat>;
    public loyaltyCurrencyFormat$: Observable<CurrencyFormat>;
    public haveReadPricingPermission: Observable<boolean> = this.permissionsService.checkPermission(
        CustomerPermissions.PricingRead
    );

    public settings$: Observable<HotSettingsExtended>;

    public isUpLg = () => this.screenDimensionService.upLg();

    public settingsStore$: Observable<SettingsStoreModel>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly permissionsService: PermissionsService,
        public readonly screenDimensionService: ScreenDimensionService
    ) {
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.loyaltyCurrencyFormat$ = this.store.pipe(select(settingsLoyaltyCurrencyFormat));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.settings$ = this.store.pipe(select(settingsData));
    }
}
