import { ProductAttributesExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { ProductsActions } from '@hot-b2b/store/products/actions';
import { editQuantity, mutableProducts, updateProductFromCart } from '@hot-b2b/store/products/helpers';
import { ProductsState } from '@hot-b2b/store/products/model';
import { productsInitialState } from '@hot-b2b/store/products/state';
import { EProductsActions } from '@hot-b2b/store/products/types';

export function productsReducer(state = productsInitialState, action: ProductsActions): ProductsState {
    switch (action.type) {
        case EProductsActions.PRODUCTS_PENDING: {
            return {
                ...state,
                fetched: false,
                pending: true,
            };
        }
        case EProductsActions.PRODUCTS_FETCHED: {
            const productAttributes: ProductAttributesExtended | undefined = action.payload.data.products
                ? { ...action.payload.data }
                : undefined;
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    ...productAttributes,
                    products: mutableProducts(action.payload),
                },
            };
        }
        case EProductsActions.PRODUCTS_ITEM_UPDATE: {
            return {
                ...state,
                fetched: false,
                pending: true,
                data: {
                    ...state.data,
                    products: updateProductFromCart(state.data.products, action.payload),
                },
            };
        }
        case EProductsActions.PRODUCTS_CLEAR: {
            return productsInitialState;
        }
        case EProductsActions.PRODUCTS_SYNCHRONIZED_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    products: editQuantity(state.data.products, action.payload),
                },
            };
        }
        case EProductsActions.PRODUCTS_SYNCHRONIZED_SUCCESS_WITH_STORE_ID: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    products: editQuantity(state.data.products, action.payload, true),
                },
            };
        }
        default:
            return state;
    }
}
