import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { HotProductAvailability, ApiConfiguration } from '@hot-theme-nx/generated-api';


@Injectable({
    providedIn: 'root',
})
export class DistributorCatalogService {
    public isChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public save = new Subject();
    public updateCachedItems = new Subject();
    public showNotification: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private readonly httpClient: HttpClient, private readonly apiConfiguration: ApiConfiguration) {}

    public getProductAvailabilities(): Observable<HotProductAvailability[]> {
        return this.httpClient.get<HotProductAvailability[]>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/distributor/product-availabilities`
        );
    }

    public saveProductAvailabilities(productAvailabilities: HotProductAvailability[]): Observable<any> {
        return this.httpClient.post<any>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/distributor/product-availabilities`,
            productAvailabilities
        );
    }
    
}
