import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';

import { Aggregation, HotPromotion, HotCategory } from '@hot-theme-nx/generated-api';

import { CartState } from '../cart/model';

import {
    currentCatalog,
    getItemsQuantityToAddInSet,
    getSetProducts,
    getSetQuantity,
    getTotalCountWithFfc,
    getWithFfc,
    getWithFfcAndGifts,
} from './helpers';

import { CatalogState } from '@hot-b2b/store/catalog/model';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsState } from '@hot-b2b/store/settings/model';
import { stateSettings } from '@hot-b2b/store/settings/selector';
import { ProductFunctionalityModel } from '@hot-libs/shared-models';
import { HotBrandGroupExtended, HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import find from 'lodash/find';
import { MultiFilter, AdvanceFilter } from '../../shared/models/multi-filters.model';

const stateCatalog = (state: AppState): CatalogState => state.catalog;
const stateCart = (state: AppState): CartState => state.cart;

export const offlineCatalogFetched: MemoizedSelector<AppState, boolean> = createSelector(
    stateCatalog,
    (state: CatalogState) => state.offlineCatalogFetched
);

export const catalogFetched: MemoizedSelector<AppState, boolean> = createSelector(
    stateCatalog,
    (state: CatalogState) => state.fetched
);

export const catalogPending: MemoizedSelector<AppState, boolean> = createSelector(
    stateCatalog,
    (state: CatalogState) => state.pending
);

export const currentCatalogIsReady: MemoizedSelector<AppState, boolean> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) => !!currentCatalog(state.data.catalogs, settings.data.ffc.id)
);

export const offlineCatalogProducts: MemoizedSelector<AppState, HotProductExtended[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) =>
        getWithFfc(settings.data.ffc.id, state.data.offlineCatalogs, 'products')
);

export const catalogProducts: MemoizedSelector<AppState, HotProductExtended[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) => getWithFfc(settings.data.ffc.id, state.data.catalogs, 'products')
);

export const catalogProductsCount: MemoizedSelector<AppState, number> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) => getTotalCountWithFfc(settings.data.ffc.id, state.data.catalogs)
);

export const catalogCategories: MemoizedSelector<AppState, HotCategory[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) =>
        getWithFfc(settings.data.ffc.id, state.data.catalogs, 'categories')
);

export const catalogAggregations: MemoizedSelector<AppState, Aggregation[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) =>
        getWithFfc(settings.data.ffc.id, state.data.catalogs, 'aggregations')
);

export const catalogBrands: MemoizedSelector<AppState, HotBrandGroupExtended[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) =>
        getWithFfc(settings.data.ffc.id, state.data.catalogs, 'brandGroups')
);

export const catalogPackageType: MemoizedSelector<AppState, any[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) =>
        getWithFfc(settings.data.ffc.id, state.data.catalogs, 'packageTypes')
);

export const catalogPriceRange: MemoizedSelector<AppState, any[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) =>
        getWithFfc(settings.data.ffc.id, state.data.catalogs, 'priceRanges')
);

export const catalogBrandOwner: MemoizedSelector<AppState, any[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) =>
        getWithFfc(settings.data.ffc.id, state.data.catalogs, 'brandOwners')
);

export const catalogProductBrands: MemoizedSelector<AppState, HotBrandGroupExtended[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) =>
        getWithFfc(settings.data.ffc.id, state.data.catalogs, 'productBrandGroups')
);

export const catalogBrandTypes: MemoizedSelector<AppState, any[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) => {
        const aggregations = getWithFfc(settings.data.ffc.id, state.data.catalogs, 'aggregations');
        return !aggregations ? [] : aggregations
            .filter((aggr: Aggregation) => aggr.field === 'Type')
            .map((aggr: Aggregation) => aggr.items)
            .flat()
            .valueOf()
    }
);

export const catalogIsAlcoholic: MemoizedSelector<AppState, any[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) => {
        const aggregations = getWithFfc(settings.data.ffc.id, state.data.catalogs, 'aggregations');
        return !aggregations ? [] : aggregations
            .filter((aggr: Aggregation) => aggr.field === 'Is alcoholic')
            .map((aggr: Aggregation) => aggr.items)
            .flat()
            .valueOf()
    }
);

export const catalogIsHighProfitMargin: MemoizedSelector<AppState, any[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) => {
        const aggregations = getWithFfc(settings.data.ffc.id, state.data.catalogs, 'aggregations');
        return !aggregations ? [] : aggregations
            .filter((aggr: Aggregation) => aggr.field === 'Is high profit margin')
            .map((aggr: Aggregation) => aggr.items)
            .flat()
            .valueOf()
    }
);

export const offlineCatalogSearchList: MemoizedSelector<AppState, string[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) => {
        const products = getWithFfc(settings.data.ffc.id, state.data.offlineCatalogs, 'products');
        return !products ? [] : products
            .map(
                (item: any) =>
                    item.name +
                    (item.unitName ? `, ${item.unitName}` : '') +
                    (item.packageName ? `, ${item.packageName}` : '')
            )
            .flat()
            .valueOf()
    }
);

// Why this is in catalog
export const catalogProductsWithCartGifts: MemoizedSelectorWithProps<
    AppState,
    HotPromotion,
    HotProductExtended[]
> = createSelector(
    stateCart,
    stateSettings,
    (cartState: CartState, settingsState: SettingsState, promotion: HotPromotion) =>
        getWithFfcAndGifts(
            cartState.data?.cart?.items,
            promotion,
            find(settingsState?.data?.features, { name: 'UsePackagesWithCartsAndOrders' })?.isActive,
            find(settingsState?.data?.features, { name: 'SplitOrdersBySuppliersWhenCheckout' })?.isActive
        )
);

// Why this is in catalog
export const calculateSetQuantity: MemoizedSelectorWithProps<AppState, HotPromotion, number> = createSelector(
    stateCart,
    (cartState: CartState, promotion: HotPromotion) => getSetQuantity(cartState.data?.cart?.items, promotion)
);

// Why this is in catalog?
export const setProductsInSet: MemoizedSelectorWithProps<AppState, HotPromotion, unknown[]> = createSelector(
    stateCart,
    (cartState: CartState, promotion: HotPromotion) => getSetProducts(cartState.data?.cart?.items, promotion)
);

// Why this is in catalog?
export const calculateItemsQuantityToAddInSet: MemoizedSelectorWithProps<
    AppState,
    { promotion: HotPromotion; newSetQuantity: number },
    ProductFunctionalityModel[]
> = createSelector(stateCart, (cartState: CartState, { promotion, newSetQuantity }) =>
    getItemsQuantityToAddInSet(cartState.data?.cart?.items, promotion, newSetQuantity)
);

export const catalogFindProduct: MemoizedSelectorWithProps<
    AppState,
    string,
    HotProductExtended
> = createSelector(stateCatalog, stateSettings, (state: CatalogState, settings: SettingsState, id: string) =>
    getWithFfc(settings.data.ffc.id, state.data.catalogs, 'products').find(
        (product: HotProductExtended) => product.id === id
    )
);

export const emptiesCatalogProducts: MemoizedSelector<AppState, HotProductExtended[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState, settings: SettingsState) =>
        getWithFfc(settings.data.ffc.id, state.data.emptiesCatalogs, 'products')
);

export const multiFilter: MemoizedSelector<AppState, MultiFilter> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState) => state.data.multiFilter
);

export const isMultiFilterActive: MemoizedSelector<AppState, any> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState) =>
        !!state.data.multiFilter.categoryId ||
        state.data.multiFilter.isSoldByHeineken ||
        !!state.data.multiFilter.selectedTypes?.length
);

export const advanceFilter: MemoizedSelector<AppState, AdvanceFilter[]> = createSelector(
    stateCatalog,
    stateSettings,
    (state: CatalogState) => state.data.advanceFilter
);
