import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ScreenDimensionService } from '@hot-libs/browser-specific';

@Component({
    selector: 'hot-button',
    templateUrl: './button.component.html',
})
export class HotButtonComponent {
    @Input() public buttonType: string;
    @Input() public modifier: string;
    @Input() public caption: string;
    @Input() public disabled: boolean;
    @Input() public icon: string;
    @Input() public iconLeft: boolean;
    @Input() public pageName = '';
    @Input() public buttonClassName = '';
    @Input() public opacity: boolean;

    @Output() public hotClick = new EventEmitter();

    public isCheckoutButton: boolean = false;
    public isUpLg = () => this.screenDimensionService.upLg();

    constructor(private readonly screenDimensionService: ScreenDimensionService) {}

    public ngOnInit(): void {
        this.isCheckoutButton = !this.isUpLg() && this.pageName === 'CartOverlay';
    }

    public getTrackData() {
        return `{"data_interaction":"button_click","button_text":"${this.caption}","name":"${this.pageName}"}`;
    }

    public getOpacity(): number {
        if (this.opacity) {
            return 1;
        }
    }
}
