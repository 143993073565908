import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { CurrencyFormat } from '@hot-theme-nx/generated-api';

import { DateFormatParserService, FeaturesService, OrderDetailsService } from '../../../services';
import { LineItemsCountService } from '../../../services/line-items-count.service';

import { AppState } from '@hot-b2b/store/reducers';
import {
    settingsAppOnLine,
    settingsCurrencyFormat,
    settingsLoyaltyCurrencyFormat,
    settingsStore,settingsData
} from '@hot-b2b/store/settings/selector';
import { HotOrderExtended, HotOrderLineItemExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { OrderStatus, PageName } from '@hot-libs/shared-types';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
@Component({
    selector: 'hot-group-order-modal',
    templateUrl: './group-order-modal.component.html',
})
export class GroupOrderModalComponent implements OnInit {
    @Input() public order: HotOrderExtended;

    public pageName = PageName.GROUPACCOUNTORDERMODAL;

    public itemsCount$: Observable<number>;
    public readonly settings$: Observable<HotSettingsExtended>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public currencyFormat$: Observable<CurrencyFormat>;
    public settingsAppOnline$: Observable<boolean>;
    public settingsLoyaltyCurrencyFormat$: Observable<CurrencyFormat>;

    public preferredDeliveryDate: string;
    public estimatedDeliveryDate: string;
    public createdDate: string;
    public deliveryDate: string;

    public readonly orderStatus = OrderStatus;

    private readonly featureEstimatedDeliveryDate: boolean = this.featuresService.EstimatedDeliveryDate;
    private readonly featureSelectDeliveryDate: boolean = this.featuresService.SelectDeliveryDate;
    private readonly featureSummarizeInvoices: boolean = this.featuresService.SummarizeInvoices;

    public get isShowDeliveredColumn(): boolean {
        const checkStatus = this.order.status === this.orderStatus.Delivered;
        const checkInvoice = this.featureSummarizeInvoices && this.order.hasInvoice;
        const checkQuantity =
            this.featureSummarizeInvoices &&
            !this.order.hasInvoice &&
            // tslint:disable-next-line: max-line-length
            this.order.items.some((item: HotOrderLineItemExtended) => item.originalQuantity != null); // 0 - need to be a true
        return checkStatus || checkInvoice || checkQuantity;
    }

    public get isShowDeliveryDateValue(): boolean {
        return this.featureSelectDeliveryDate && this.order.deliveryDate.formattedValue !== 'Undefined';
    }

    public get isShowPreferredDeliveryDateValue(): boolean {
        return (
            this.featureSelectDeliveryDate &&
            this.order.preferredDeliveryDate.formattedValue !== 'Undefined' &&
            this.order.deliveryDate.formattedValue === 'Undefined'
        );
    }

    public get isShowEstimatedDeliveryDateValue(): boolean {
        return (
            this.featureEstimatedDeliveryDate &&
            this.order.estimatedDeliveryDate.formattedValue !== 'Undefined' &&
            this.order.deliveryDate.formattedValue === 'Undefined'
        );
    }

    constructor(
        private readonly store: Store<AppState>,
        private readonly featuresService: FeaturesService,
        private readonly dateFormatParserService: DateFormatParserService,
        private readonly lineItemsCountService: LineItemsCountService,
        public readonly orderDetailsService: OrderDetailsService,

    ) {
        this.settingsAppOnline$ = this.store.pipe(select(settingsAppOnLine));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.settingsLoyaltyCurrencyFormat$ = this.store.pipe(select(settingsLoyaltyCurrencyFormat));
        this.settings$ = this.store.pipe(select(settingsData),
        filter((settings: HotSettingsExtended) => !!settings.currentLanguage)
        );
    }

    public ngOnInit(): void {
        this.itemsCount$ = this.lineItemsCountService.getItemsCount(of(this.order.items));

        this.currencyFormat$ = this.order.isBonusPointsOrder
            ? this.store.pipe(select(settingsLoyaltyCurrencyFormat))
            : this.store.pipe(select(settingsCurrencyFormat));
        
        if (this.order.preferredDeliveryDate.formattedValue !== 'Undefined') {
            this.preferredDeliveryDate = this.dateFormatParserService.dateFormat(this.order.preferredDeliveryDate.value);
        }
        if (this.order.estimatedDeliveryDate.formattedValue !== 'Undefined') {
            this.estimatedDeliveryDate = this.dateFormatParserService.dateFormat(this.order.estimatedDeliveryDate.value);
        }
        if (this.order.createdDate) {
            this.createdDate = this.dateFormatParserService.dateFormat(this.order.createdDate);
        }
        if (this.order.deliveryDate.formattedValue !== 'Undefined') {
            this.deliveryDate = this.dateFormatParserService.dateFormat(this.order.deliveryDate.value);
        }
    }

    public getCurrency(order: HotOrderExtended): Observable<CurrencyFormat> {
        return this.currencyFormat$.pipe(
            withLatestFrom(this.settingsLoyaltyCurrencyFormat$),
            map(([currencyFormat, loyaltyCurrencyFormat]: [CurrencyFormat, CurrencyFormat]) => {
                return order.isBonusPointsOrder ? loyaltyCurrencyFormat : currencyFormat;
            })
        );
    }
    public buildCreationDate(date): Observable<string> {
        return this.orderDetailsService.setOrderCreationDateFormat(date);
    }
}
