import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, AfterViewInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import moment from 'moment';

import { HotLegalAgreement } from '@hot-theme-nx/generated-api';
import { HotUserExtended } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';

import { AuthenticationService, UserService } from 'apps/hot-b2b/src/app/account/services';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { LegalAgreementService } from 'apps/hot-b2b/src/app/shared/services/legal-agreement.service';
import { AuthEdit } from '@hot-b2b/store/auth/actions';
import { authUser } from '@hot-b2b/store/auth/selector';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalData, selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { TermsAndConditionAndPrivacyPolicyResponse } from '@hot-theme-nx/generated-api'

@Component({
    selector: 'hot-terms-and-conditions-modal',
    templateUrl: './terms-and-conditions-modal.component.html',
})
export class TermsAndConditionsModalComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() public termsAndConditionAccepted = new EventEmitter();
    @Output() public setAcceptTermsEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public onDeclineEvent: EventEmitter<void> = new EventEmitter();
    @Input() public from: string;
    @Input() public isDeclined: boolean;

    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    private readonly user$: Observable<HotUserExtended>;
    private isDeclineNotificationShown: boolean;

    public termsAndConditions: HotLegalAgreement;

    public isTermsAndConditionsAccepted: boolean;
    public isFirstTimeAccept: boolean;
    public displayTermsAndConditions: boolean;
    public termsAndConditionsModifiedDate: string;
    public updateView: boolean;

    public modalTermsState$: Observable<ModalsState>;
    public modalDeclineState$: Observable<ModalsState>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly userService: UserService,
        private readonly legalAgreementService: LegalAgreementService,
        private readonly authenticationService: AuthenticationService
    ) {
        this.modalTermsState$ = this.store.pipe(select(selectModalData(ModalIds.termsAndConditions)));
        this.modalDeclineState$ = this.store.pipe(select(selectModalState(ModalIds.termsAndConditionsDeclined)));
        this.user$ = this.store.pipe(select(authUser));

        this.modalTermsState$.pipe(takeUntil(this.subscriptionsDestroy$)).subscribe((data: any) => {
            if (data?.isAccountActivation) {
                this.displayTermsAndConditions = true;
                this.isTermsAndConditionsAccepted = true;
            } else if (data?.isUpdateTerms) {
                this.updateView = true;
                this.isDeclineNotificationShown = data.isDeclined;
            }
        });
    }

    public ngOnInit(): void {
        this.legalAgreementService.currentTermsAndConditions$
            .pipe(withLatestFrom(this.user$), takeUntil(this.subscriptionsDestroy$))
            .subscribe(([termsAndConditions, user]: [HotLegalAgreement, HotUserExtended]) => {
                this.termsAndConditionsModifiedDate = moment(termsAndConditions.modifiedDate).format('D MMMM YYYY');
                if (termsAndConditions) {
                    const termsAndConditionsAcceptedDate =
                        (user?.contact?.termsAndConditionsAcceptedDate) || false;
                    this.isTermsAndConditionsAccepted =
                        termsAndConditionsAcceptedDate > termsAndConditions.modifiedDate;
                    this.displayTermsAndConditions = this.isTermsAndConditionsAccepted;
                    this.termsAndConditions = termsAndConditions;

                    this.isFirstTimeAccept = !termsAndConditionsAcceptedDate;

                    if (!this.isTermsAndConditionsAccepted && user) {
                        this.toggleTermsAndConditionsModal(true);
                    }
                }
            });
    }

    public agreeTermsAndConditions(): void {
        this.userService
            .acceptTermsAndConditions()
            .pipe(withLatestFrom(this.user$))
            .subscribe(([termsAndConditionAndPrivacyPolicyResponse, user]: [TermsAndConditionAndPrivacyPolicyResponse, HotUserExtended]) => {
                if (termsAndConditionAndPrivacyPolicyResponse.acceptedDate && user.contact) {
                    user.contact.termsAndConditionsAcceptedDate = termsAndConditionAndPrivacyPolicyResponse.acceptedDate;
                    this.store.dispatch(new AuthEdit(user));
                }
                this.isTermsAndConditionsAccepted = true;
                this.displayTermsAndConditions = true;
                this.termsAndConditionAccepted.emit();
                this.modalService.toggleModal(ModalIds.termsAndConditions, false);
            });
    }

    public declineTermsAndConditions(): void {
        if (!this.isDeclined) {
            this.onDeclineEvent.emit();
            this.modalService.toggleModal(ModalIds.termsAndConditionsDeclined, true);
        } else {
            this.modalService.toggleModal(ModalIds.termsAndConditions, false);
            this.authenticationService.logout();
        }
    }

    public toggleDisplayTermsAndConditions(): void {
        this.displayTermsAndConditions = !this.displayTermsAndConditions;
    }

    public toggleTermsAndConditionsModal(state: boolean): void {
        this.modalService.toggleModal(ModalIds.termsAndConditions, state);
    }

    public toggleDeclineNotificationModal(state: boolean): void {
        this.modalService.toggleModal(ModalIds.termsAndConditionsDeclined, state);
    }

    public setAcceptTerms(value: boolean) {
        if (value) {
            this.setAcceptTermsEvent.emit(value);
        } else {
            this.modalService.toggleModal(ModalIds.termsAndConditionsDeclined, true, { acceptView: true });
        }
    }


    ngAfterViewInit() {
        let element = document.getElementsByClassName('privacy-policy');
        Array.from(element).forEach((ele) => {
            ele.addEventListener('click', () => {
                this.onLink()
            })
        });
    }

    public onLink() {
        this.modalService.toggleModal(ModalIds.privacyPolicy, true, {
            isUpdateTerms: true,
            isDeclined: this.isDeclined,
            isTermsAccepted: this.isTermsAndConditionsAccepted
        });
        this.close();
    }

    public close(): void {
        this.modalService.toggleModal(ModalIds.termsAndConditions, false);
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }
}
