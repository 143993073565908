import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    private readonly subject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private keepAfterNavigationChange = false;

    constructor(private readonly router: Router) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    this.keepAfterNavigationChange = false;
                } else {
                    this.subject.next(null);
                }
            }
        });
    }

    public success(message: string, keepAfterNavigationChange = false, key?: string): void {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message, key });
    }

    public reset(): void {
        this.subject.next(null);
    }

    public error(message: string, keepAfterNavigationChange = false, key?: string): void {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message, key });
    }

    public getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
