export enum AccountType {
    Anonymous = 'Anonymous',
    Consumer = 'Consumer',
    Customer = 'Customer',
    Distributor = 'Distributor',
    SubDistributor = 'SubDistributor',
    OpcoDistributor = 'OpcoDistributor',
    SalesRepresentative = 'SalesRepresentative',
    VanSalesMan = 'VanSalesMan',
}
