import { Injectable } from '@angular/core';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class DateFormatParserService {
    constructor(private readonly translateService: TranslateService) {}

    private readonly formatItemsMapForMoment: { [key: string]: string } = {
        dd: 'DD',
        yyyy: 'YYYY',
    };

    private readonly formatItemsMapForDatePipe: { [key: string]: string } = {
        ddd: 'EEE',
    };

    public getFormattedDeliveryDate(deliveryMoment: moment.Moment): string {
        return deliveryMoment.utcOffset(0, true).toISOString();
    }

    // To Do create selector for dateformat
    public parseDateFormatForDatePipe(dateFormat: string): string {
        return this.mapDateFormat(dateFormat, this.formatItemsMapForDatePipe);
    }

    public parseDateFormatForMoment(dateFormat: string): string {
        return this.mapDateFormat(dateFormat, this.formatItemsMapForMoment);
    }
    private mapDateFormat(dateFormat: string, formatMapper: any): string {
        for (const key of Object.keys(formatMapper)) {
            const regExp = new RegExp(`\\b${key}\\b`, 'g');
            const value = formatMapper[key];

            dateFormat = dateFormat.replace(regExp, value);
        }

        return dateFormat;
    }

    public weekFormat(date: string): string {
        const weekTranslate = this.translateService.instant('shared.week-calendar.week');
        if (date.indexOf('T00:00:00Z') > -1) {
            return moment.utc(date).format(`[${weekTranslate}] WW, YYYY`);
        } else {
            return moment(date).format(`[${weekTranslate}] WW, YYYY`);
        }
    }
    public weekDays(date: string): string {
        const weekTranslate = this.translateService.instant('shared.week-calendar.week');
        return moment.utc(date).format(`[${weekTranslate}] W,YYYY`);
    }

    public dateFormat(date: string): string {
        if (date?.indexOf('T00:00:00Z') > -1) {
            return moment.utc(date).format('ddd, DD/MM/YYYY');
        } else {
            return moment(date).format('ddd, DD/MM/YYYY');
        }
    }

    public dateFormatOrderDetails(date: string): string {
        if (date.indexOf('T00:00:00Z') > -1) {
            return moment.utc(date).format('ddd, DD-MM-YYYY');
        } else {
            return moment(date).format('ddd, DD-MM-YYYY');
        }
    }

    public getDate(date: string): string {
        return moment(date).format('DD/MM/YYYY');
    }

    public getTime(date: string): string {
        return moment(date).format('HH:mm');
    }

    public dateFormatLoyalty(date: string): string {
        return moment.utc(date).format('DD-MMMM-YYYY');
    }

    public dateFormatForPromotionMechanicsHistory(date: string): string {
        if (date.indexOf('T00:00:00Z') > -1) {
            return moment.utc(date).format('DD MMM YYYY');
        } else {
            return moment(date).format('DD MMM YYYY');
        }
    }

    public dateFormatForProgramDetails(date: string): string {
        if (date.indexOf('T00:00:00Z') > -1) {
            return moment.utc(date).format('YYYY-MM-DD');
        } else {
            return moment(date).format('YYYY-MM-DD');
        }
    }
}
