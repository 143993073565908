import { Injectable } from '@angular/core';
import { CacheClearService } from './cache-clear.service';

@Injectable({
    providedIn: 'root',
})
export class FeedbackService {

    constructor(private readonly clearCache: CacheClearService) {}

    public deleteTopicCache(): Promise<boolean> {
        const deleteURLs = [
            { 'dataGroupName': 'performance8h', 'url': '/storefrontapi/hot/feedback/topics' }
        ];

        return this.clearCache.deleteCacheByAPIUrl(deleteURLs)
        .then(() => {return true;})
        .catch(() => {return false;});

    }
}
