import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalData, selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { CouponModel } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from '../../../services';

@Component({
    selector: 'hot-voucher-modal',
    templateUrl: './voucher-modal.component.html',
})
export class VoucherModalComponent implements OnInit {
    public voucherItem: CouponModel;

    public voucherModal$: Observable<ModalsState>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly router: Router
    ) {
        this.voucherModal$ = this.store.pipe(select(selectModalState(ModalIds.voucherModal)));
    }

    ngOnInit() {
        this.store.pipe(select(selectModalData(ModalIds.voucherModal))).subscribe((data: any) => {
            if (data) {
                this.voucherItem = data.usedVoucherItem;
            }
        })
    }

    public close(): void {
        this.modalService.toggleModal(ModalIds.voucherModal, false);
    }

    public backToBasket(): void {
        this.close();
        this.router.navigateByUrl('/cart');
    }
}
