import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/internal/operators/first';

import { NativeService } from './native/services';

import { AppState } from '@hot-b2b/store/reducers';
import { SettingsGet } from '@hot-b2b/store/settings/actions';
import { settingsFetched } from '@hot-b2b/store/settings/selector';

export function initializeSettings(
    store: Store<AppState>,
    router: Router,
    nativeService: NativeService
): () => Promise<{}> {
    if (nativeService.isNativeApplication() || nativeService.isDebug()) {
        return () => router.navigate(['/select-country'], { replaceUrl: true });
    }
    return () =>
        new Promise((resolve) => {
            store.dispatch(new SettingsGet());
            store
                .select(settingsFetched)
                .pipe(first(Boolean))
                .subscribe(() => {
                    resolve(true);
                });
        });
}
