import { Pipe, PipeTransform } from '@angular/core';
import { ScreenDimensionService } from '@hot-libs/browser-specific';

@Pipe({
    name: 'gridProductNameFormat',
})
export class GridProdcutNameFormatPipe implements PipeTransform {

    constructor(private screenDimension: ScreenDimensionService) {

    }
    public isUpLg = () => this.screenDimension.upLg();

    public transform(name): string {
        if (name && name.length > 55) {
            return name.substring(0, 55) + "...";
        } else {
            return name;
        }
        
    }
}