import { OrderTemplateActions } from './actions';
import { OrderTemplateState } from './model';
import { orderTemplateInitialState } from './state';
import { EOrderTemplateActions } from './types';

import { addNewOrderTemplate, updateOrderTemplate } from '@hot-b2b/store/order-template/helpers';
import filter from 'lodash/filter';

export function orderTemplateReducer(
    state: OrderTemplateState = orderTemplateInitialState,
    action: OrderTemplateActions
): OrderTemplateState {
    switch (action.type) {
        case EOrderTemplateActions.ORDERS_TEMPLATES_PENDING: {
            return {
                ...state,
                pending: true,
                fetched: false,
            };
        }

        case EOrderTemplateActions.ORDERS_TEMPLATES_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    templates: action.payload,
                },
            };
        }

        case EOrderTemplateActions.ORDERS_TEMPLATE_UPDATE: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    templates: updateOrderTemplate(state.data.templates, action.payload),
                },
            };
        }

        case EOrderTemplateActions.ORDERS_TEMPLATE_DELETE: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    templates: filter(state.data.templates, (template) => template.id !== action.payload),
                    selectedTemplate: null,
                },
            };
        }

        case EOrderTemplateActions.ORDERS_TEMPLATE_SELECT: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    selectedTemplate: action.payload,
                },
            };
        }

        case EOrderTemplateActions.ORDERS_TEMPLATE_ADD_NEW: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    templates: addNewOrderTemplate(state.data.templates, action.payload),
                    selectedTemplate: state.data.selectedTemplate ? state.data.selectedTemplate : null,
                },
            };
        }

        default:
            return state;
    }
}
