import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AnalyticLocationConstant, CartErrorHandlerService, QuantityService } from '@hot-theme-nx/common-api';
import { CurrencyFormat, HotOutlet } from '@hot-theme-nx/generated-api';

import { HotProductExtended } from '../../../catalog/models';
import { FeaturesService } from '../../services/features.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { cartData, cartItems } from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { settingsCurrencyFormat, settingsData, settingsStore } from '@hot-b2b/store/settings/selector';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { QuantityFormatPipe } from 'apps/hot-b2b/src/app/shared/pipes';
import isNumber from 'lodash/isNumber';
import find from 'lodash/find';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import { authContactOutlet } from '@hot-b2b/store/auth/selector';
import { PageName, StorageKeys } from '@hot-libs/shared-types';
import { Router } from '@angular/router';
import { HotApiCartsService } from 'libs/generated-api/src/lib/services/hot-api-carts.service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticService } from '../../services/analytic.service';
import { config } from 'apps/hot-b2b/src/environments/config';
import { CartGet } from '@hot-b2b/store/cart/actions';
const updateQuantityBaseOnProductConfig = config?.updateQuantityBaseOnProduct || false;
@Component({
    selector: 'hot-quantity-input',
    templateUrl: './quantity-input.component.html',
    providers: [QuantityFormatPipe, QuantityService],
})
export class QuantityInputComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public readonly: boolean;
    @Input() public product: HotProductExtended;
    @Input() public quantity: number;
    @Input() public id: string;
    @Input() public productId: string;
    @Input() public sku: string;
    @Input() private isVanSalesMan: boolean;
    @Input() private maxInputValue = 99999;
    @Input() private minInputValue = 0;
    @Input() public isEmpties: boolean;
    @Input() public isUpdateQuantityBaseOnProduct = false;
    @Input() public showWeightBlock: boolean;
    @Input() public pageName: string;
    @Input() private resetCount: any;
    @Output() public addToCart: EventEmitter<any> = new EventEmitter();
    @Output() public removeFromCart: EventEmitter<any> = new EventEmitter();
    @Output() public sendChangeQuantity: EventEmitter<any> = new EventEmitter();
    public featureValidateProductLimitOrderQuantity: boolean = this.featureService.ValidateProductLimitOrderQuantity;
    public add: string;
    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    private decimalSeparator: string;
    public cartItems$: Observable<HotCartLineItemExtended[]>;
    public inputModel: string;
    public settings$: Observable<HotSettingsExtended>;
    public currencyFormat$: Observable<CurrencyFormat>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public minValue = 0;
    public readonly contactOutlet$: Observable<HotOutlet>;
    public cartData$: Observable<HotCartExtended>;
    private unSubscribe$ = new Subject<boolean>();
    public isBcsOrder = false;
    private readonly analyticProductLocation = AnalyticLocationConstant.BRAND_PAGE;
    public productLimitOrderQuantity: boolean = false;
    
    constructor(
        private readonly store: Store<AppState>,
        private readonly quantityFormat: QuantityFormatPipe,
        public readonly quantityService: QuantityService,
        public readonly featureService: FeaturesService,
        public readonly router: Router,
        private readonly configurationService: ConfigurationService,
        private readonly translateService: TranslateService,
        private readonly hotApiCartsService: HotApiCartsService,
        private readonly analyticService: AnalyticService,
        private readonly cartErrorService: CartErrorHandlerService
    ) {
        this.cartItems$ = this.store.pipe(select(cartItems));
        this.contactOutlet$ = this.store.pipe(select(authContactOutlet));
        this.settings$ = this.store.pipe(select(settingsData));
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.currencyFormat$.subscribe((settings: CurrencyFormat) => {
            this.decimalSeparator = settings.decimalSeparator;
        });
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.cartData$ = this.store.pipe(select(cartData));
    }

    public ngOnInit(): void {
        this.checkQuantity(); // add separator;

        if (this.pageName === PageName.SUMMARY) {
            this.showWeightBlock = true;
        }
        this.cartData$.pipe(takeUntil(this.unSubscribe$)).subscribe((data: HotCartExtended) => {
            this.productLimitOrderQuantity = false;
            if (data?.['orderType'] && data['orderType'].toLowerCase() === 'bcs' && data['itemsCount'] !== 0) {
                this.isBcsOrder = true;
            } else {
                this.isBcsOrder = false;
            }
        });

        this.cartData$.pipe(takeUntil(this.unSubscribe$)).subscribe((data: HotCartExtended) => {
            let validationErrorArray: any = [];
            let validationWarningArray: any = [];
            validationErrorArray = this.validationErrorCall(data);
            validationWarningArray = this.validationWarningCall(data);
            
            if (data?.id && this.featureValidateProductLimitOrderQuantity) {
                const groupLimitErrorAndWarning = this.cartErrorService.processProductLimitOrderQuantity(validationErrorArray, validationWarningArray);
                if (groupLimitErrorAndWarning.length) {
                    this.productLimitOrderQuantity = groupLimitErrorAndWarning.some(elem => elem.sku === this.product?.sku);
                }
            }
        });
    }

    private validationWarningCall(data: HotCartExtended) : [] {
        let validationWarning: any = [];
        for (let error of data.items) {
            validationWarning = this.validationWarningInnerCall(error);
        }
        return validationWarning;
    }

    private validationWarningInnerCall(error: HotCartLineItemExtended) : [] {
        let data: any = [];
        if (error.validationWarnings.length > 0) {
            for (let error1 of error.validationWarnings) {
                data.push(error1);
            }
        }
        return data;
    }

    private validationErrorCall(data: HotCartExtended): [] {
        let validationError: any = [];
        for (let error of data.items) {
            validationError = this.validationErrorInnerCall(error);
        }
        return validationError;
    }

    private validationErrorInnerCall(error: HotCartLineItemExtended) : [] {
        let dataError : any = [];
        if (error.validationErrors.length > 0) {
            for (let error1 of error.validationErrors) {
                dataError.push(error1);
            }
        }
        return dataError;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.isHopB2b()) {
            this.checkQuantityHop();
        }
        if (
            changes.hasOwnProperty('quantity') &&
            (changes.quantity.currentValue || changes.quantity.currentValue === 0)
        ) {
            this.inputModel =
                0 < this.quantity && this.quantity < 1
                    ? this.quantityFormat.transform(this.quantity, '.', 2)
                    : this.quantityFormat.transform(this.quantity, this.decimalSeparator, 2);
        }
        if (changes.hasOwnProperty('resetCount') && changes.resetCount.currentValue.quantity) {
            this.inputModel = String(Number(this.inputModel) - changes.resetCount.currentValue.quantity);
        }
        this.updatingQuantity(changes);

        if (
            changes.hasOwnProperty('product') &&
            changes.product.currentValue.quantity === 0 &&
            !changes.product.currentValue.unitsOfMeasure
        ) {
            this.assignQuantity(0);
        }
    }

    public updatingQuantity(changes) {

        if (updateQuantityBaseOnProductConfig && this.isUpdateQuantityBaseOnProduct && changes.hasOwnProperty('product')) {
            const { product } = changes;
            if (
                product.currentValue.unitCurrent &&
                isNumber(product.currentValue.unitCurrent.quantity) &&
                this.quantity !== undefined &&
                (product.currentValue.unitCurrent?.quantity !== this.inputModel) || product.currentValue.unitCurrent?.quantity !== this.quantity
            ) {
                const currentUnitType = product.currentValue.unitCurrent?.packageType;
                const currentUnit = find(product.currentValue.unitsOfMeasure || [], unit => unit.packageType === currentUnitType)
                const quantity = currentUnit?.quantity;

                if (isNumber(quantity)) {
                    this.quantity = quantity;
                    this.assignQuantity(this.quantity);
                }
            }
        }
    }
    public checkQuantityHop(): void {
        if (this.pageName == PageName.BRAND) {
            const packagesPerPallet = this.product.packagesPerPallet;
            const packagesPerFCL = this.product.packagesPerFCL;
            const packagesPerFTL = this.product.packagesPerFTL;
            this.setQuantity(packagesPerPallet, packagesPerFCL, packagesPerFTL);
        } else if (this.router.url == '/cart') {
            const packagesPerPallet = this.product['product'].packagesPerPallet;
            const packagesPerFCL = this.product['product'].packagesPerFCL;
            const packagesPerFTL = this.product['product'].packagesPerFTL;
            this.setQuantity(packagesPerPallet, packagesPerFCL, packagesPerFTL);
        }
    }

    public setQuantity(packagesPerPallet: any, packagesPerFCL: any, packagesPerFTL: any): void {
        if (packagesPerPallet > 0 && this.product.packageTypeHop == 'Pallet') {
            this.quantity = Math.round(this.quantity / packagesPerPallet);
        } else if (packagesPerFCL > 0 && this.product.packageTypeHop == 'FCL') {
            this.quantity = Math.round(this.quantity / packagesPerFCL);
        } else if (packagesPerFTL > 0 && this.product.packageTypeHop == 'FTL') {
            this.quantity = Math.round(this.quantity / packagesPerFTL);
        }
    }

    public checkQuantity(): void {
        if (this.quantity) {
            this.inputModel =
                0 < this.quantity && this.quantity < 1
                    ? this.quantityFormat.transform(this.quantity, '.', 2)
                    : this.quantityFormat.transform(this.quantity, this.decimalSeparator, 2);
        }
    }

    public assignQuantity(quantity): void {
        this.inputModel =
            0 < quantity && quantity < 1
                ? this.quantityFormat.transform(quantity, '.', 2)
                : this.quantityFormat.transform(quantity, this.decimalSeparator, 2);
    }

    public increment(): void {
        this.duplicate();
        this.add = 'plus button';

        if (!this.checkInputModel()) {
            return;
        }

        if (!this.product?.firstQuantityStep) {
            this.inputModel = (Number(this.inputModel) + 1).toString();
        }

        if (this.isVanSalesMan) {
            this.onChange();
        } else {
            if (this.product?.firstQuantityStep) {
                const updatedValue = this.quantityService.incrementQuantityValue(
                    Number(this.inputModel),
                    this.product.firstQuantityStep,
                    this.product.secondQuantityStep
                );

                this.inputModel =
                    updatedValue > this.maxInputValue ? this.maxInputValue.toString() : updatedValue.toString();
            }
            this.cartAction();
        }
    }

    private duplicate() {
        const actualTabTimestamp = Date.now().toString();
        localStorage.setItem(StorageKeys.tabTimestamp, actualTabTimestamp);
        sessionStorage.setItem(StorageKeys.tabTimestamp, actualTabTimestamp);
        const currentTabTimestamp = sessionStorage.getItem(StorageKeys.tabTimestamp);
        const tabActualTimestamp = localStorage.getItem(StorageKeys.tabTimestamp);
        if (tabActualTimestamp !== currentTabTimestamp) {
            this.store.dispatch(new CartGet());
        }
    }

    public decrement(): void {
        this.duplicate();
        this.add = 'minus button';
        if (!this.checkInputModel()) {
            return;
        }

        if (!this.product?.firstQuantityStep) {
            this.inputModel = (Number(this.inputModel) - 1).toString();
        }

        if (this.isVanSalesMan) {
            this.onChange();
        } else {
            if (this.product?.firstQuantityStep) {
                const updatedValue = this.quantityService.decrementQuantityValue(
                    Number(this.inputModel),
                    this.product.firstQuantityStep,
                    this.product.secondQuantityStep
                );

                this.inputModel = updatedValue < this.minValue ? this.minValue.toString() : updatedValue.toString();
            }

            this.cartAction();
        }
    }


    public cartAction(): void {
        if (Number(this.inputModel) === 0) {
            this.removeFromCartHandler();
            return;
        }

        const executeMethod: () => void =
            this.quantity > 0 ? this.onChange.bind(this) : this.addToCartHandler.bind(this);

        executeMethod();
    }

    public addToCartHandler(): void {
        if (!this.checkInputModel()) {
            return;
        }

        if (Number(this.inputModel) === 0 && !this.product?.firstQuantityStep) {
            this.inputModel = (Number(this.inputModel) + 1).toString();
        }

        const updatedQuantity =
            !this.featureService.UsePackagesWithCartsAndOrders && this.product && this.product.packageSize !== 0
                ? Math.round(Number(this.inputModel) * this.product.packageSize)
                : Number(this.inputModel);

        if (!this.id) {
            this.addToCart.emit({ quantity: Number(this.inputModel), addMethod: this.add });
        } else {
            this.addToCart.emit({ productId: this.id, quantity: updatedQuantity, addMethod: this.add });
        }
    }

    private checkInputModel(): boolean {
        const qty = Number(this.inputModel);
        return !(Number.isNaN(qty) || qty < this.minValue || qty > this.maxInputValue);
    }

    public removeFromCartHandler(): void {
        this.inputModel = '0';

        if (!this.productId) {
            this.removeFromCart.emit({ quantity: 0, addMethod: this.add });
        } else {
            this.removeFromCart.emit({ id: this.id, productId: this.productId, addMethod: this.add });
        }
    }

    public onChange(): void {
        this.duplicate();
        if (this.inputModel !== this.quantity.toString()) {
            if (Number(this.inputModel) === 0) {
                this.removeFromCartHandler();
            } else {
                const updatedQuantity =
                    !this.featureService.UsePackagesWithCartsAndOrders && this.product && this.product.packageSize !== 0
                        ? Math.round(Number(this.inputModel) * this.product.packageSize)
                        : Number(this.inputModel);

                this.sendChangeQuantity.emit({
                    id: this.id,
                    productId: this.productId,
                    quantity: updatedQuantity,
                    sku: this.sku,
                    addMethod: this.add
                });
            }
        }
    }

    public onBlur(event: { preventDefault: () => void }): void {
        event.preventDefault();
        if (this.inputModel === '') {
            this.inputModel = this.quantity.toString();
        }
        this.onChange();
    }

    public onFocus(): void {
        if (!this.readonly) {
            this.inputModel = '';
        }
    }

    public onKeyup(event: any): void {
        const value: number = parseInt(event?.target?.value, 10);
        this.add = 'inserted amount';
        let newValue: number = 0;
        if (value) {
            if (value > this.maxInputValue) {
                newValue = this.maxInputValue;
            } else {
                newValue = value;
            }
        }
        this.inputModel = newValue.toString();
    }

    public validateIncrement(): boolean {
        const qty = Number(this.inputModel);
        return Number.isNaN(qty) || qty >= this.maxInputValue;
    }

    public validateDecrement(): boolean {
        const qty = Number(this.inputModel);
        return Number.isNaN(qty) || qty <= this.minInputValue;
    }

    public modelChangeHandler(event: any) {
        const value = event.replace(/\s+/g, '');
        const tempValue = value !== '' && !isNaN(toNumber(value)) ? Math.abs(parseInt(value, 10)) : 0;
        const newValue = tempValue > this.maxInputValue ? this.maxInputValue : tempValue;

        this.inputModel = newValue.toString();
    }

    public getRoundedPackageSize() {
        return this.product
            ? this.quantityService.calculatePackagePart({
                quantity: Number(this.inputModel) || this.product.quantity,
                packageSize: this.product.packageSize,
            })
            : null;
    }

    public isPackageHidden() {
        return this.quantity <= this.minValue || !this.product;
    }

    public getTrackData(action) {
        return `{ "data_interaction": "button_click", "button_text": "${action}", "name":"${this.pageName}" }`;
    }

    public ngOnDestroy(): void {
        this.unSubscribe$.next(true);
        this.unSubscribe$.complete();
    }
}
