import {
    Renderer2,
    Component,
    Input,
    ViewChild,
    OnChanges,
    Output,
    EventEmitter,
    ElementRef,
} from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';

@Component({
    selector: 'fullscreen-zoom-modal',
    templateUrl: './fullscreen-zoom-modal.component.html',
})
export class FullscreenZoomModalComponent implements OnChanges {
    @ViewChild('imageCarousel', { static: true }) public homeCarousel: NguCarousel<any>;
    @ViewChild('imgTarget') imgTarget: ElementRef;

    @Input() imgURL;
    @Input() product;
    @Input() srcSet;

    @Output() errorHandle = new EventEmitter<boolean>();

    public selectedImageShow: boolean;
    public selectedImage: string;
    loading = true;
    public scale = 1;

    public xPercent = 0;
    public yPercent = 0;
    public trnasformClass = {};

    public carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        speed: 400,
        point: {
            visible: true,
            hideOnSingleSlide: true,
        },
        velocity: 0,
        easing: 'ease',
    };

    constructor(public renderer: Renderer2) {}


    public ngOnChanges(): void {
        if (this.product.images.length === 0) {
            this.product.images = [null];
        }
        this.product.images = this.product.images.slice(0, 3);
        this.selectedImageShow = true;
        this.selectedImage = this.product.images[0];
    }

    public showImage(image: any): void {
        this.selectedImageShow = false;

        setTimeout(() => {
            this.selectedImage = image;
            this.selectedImageShow = true;
        }, 100);
    }

    public onImageLoadingError(_loadingError: boolean, index: number): void {
        this.product.images.splice(index, 1);
        this.selectedImage = this.product.images[0];
    }

    errorHandler(loadingError: boolean) {
        this.errorHandle.emit(loadingError);
        this.imgURL = `${PATHS.IMAGES}/product-placeholder.png`;
        this.srcSet = `${PATHS.IMAGES}/product-placeholder@2x.png`;
        this.loading = false;
    }

    public getScale() {
        return `scale(${this.scale})`;
    }

    onLoad() {
        this.loading = false;
    }
}
