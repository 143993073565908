import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '../../store';

import { ICatalogState } from './catalog.reducer';

export interface IStateWithCatalog extends fromRoot.IState {
    catalog: ICatalogState;
}

export const getCatalogFeatureState = createFeatureSelector<ICatalogState>('catalog');

export const getCatalog = createSelector(getCatalogFeatureState, (catalogState: ICatalogState) => catalogState.data);

export const getCatalogIsLoaded = createSelector(
    getCatalogFeatureState,
    (catalogState: ICatalogState) => catalogState.catalogLoaded
);

export const getProductsTotalCount = createSelector(
    getCatalogFeatureState,
    (catalogState: ICatalogState) => catalogState.data.products.length
);
