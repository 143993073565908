import { Component,Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';

import { ModalIds } from '@hot-libs/shared-types';

import { AuthenticationService } from 'apps/hot-b2b/src/app/account/services';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { AppState } from '@hot-b2b/store/reducers';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { selectModalState } from '@hot-b2b/store/modals/selector';

@Component({
    selector: 'hot-outlet-notfound-modal',
    templateUrl: './outlet-notfound-modal.component.html',
})
export class OutletNotFoundModalComponent  {
    @Input() public semId: string;

    public modalOutletNotFoundState$: Observable<ModalsState>;

    constructor(
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService
    ) {
        this.modalOutletNotFoundState$ = this.store.pipe(select(selectModalState(ModalIds.outletNotFound)));
    }

    PATHS = PATHS;


    public onClose(): void {
        this.modalService.toggleModal(ModalIds.outletNotFound, false);
        this.authenticationService.logout();
    }
}
