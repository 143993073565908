import { Pipe, PipeTransform } from '@angular/core';



@Pipe({
    name: 'search',
})
export class SearchPipe implements PipeTransform {
    transform(items: any[], field: string, value: string): any {
        if (!items) {
            return [];
        }
        if (value === null || value === undefined) {
            return items;
        }

        return items.filter((item) => {
            return item[field].toLowerCase().includes(value.toLowerCase());
        });
    }
}
