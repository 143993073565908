import { Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { Subject } from 'rxjs';

import { HotLegalAgreement } from '@hot-theme-nx/generated-api';
import { StorageKeys } from '@hot-libs/shared-types';

@Injectable({
    providedIn: 'root',
})
export class LegalAgreementSignalRService {
    private readonly legalAgreementsHubUrl = '/hubs/legal-agreements';

    private readonly termsAndConditionsChangedMethodName = 'TermsAndConditionsChanged';
    private readonly privacyPolicyChangedMethodName = 'PrivacyPolicyChanged';

    private hubConnection: HubConnection;
    private readonly termsAndConditionsChanged$ = new Subject<HotLegalAgreement>();
    private readonly privacyPolicyChanged$ = new Subject<HotLegalAgreement>();
    private isManualStop = false;

    constructor() {
        this.createConnection(this.legalAgreementsHubUrl);
    }

    public startConnection(groupName: string) {
        try {
            this.connectionState(groupName);
        } catch (error) {
            console.log(error);
            setTimeout(() => this.startConnection(groupName), 500);
        }

        this.hubConnection.onclose(() => this.onConnectionClose(groupName));
    }

    public stopConnection(): void {
        if (this.hubConnection.state === HubConnectionState.Connected) {
            this.isManualStop = true;
            this.hubConnection.off(this.termsAndConditionsChangedMethodName);
            this.hubConnection.off(this.privacyPolicyChangedMethodName);
            this.hubConnection.stop();
        }
    }

    public registerTermsAndConditionsChangeEvent(): Subject<HotLegalAgreement> {
        return this.termsAndConditionsChanged$;
    }

    public registerPrivacyPolicyChangeEvent(): Subject<HotLegalAgreement> {
        return this.privacyPolicyChanged$;
    }

    public changeGroup(oldGroupName: string, newGroupName: string): void {
        try {
            this.leaveGroup(oldGroupName);
            this.joinGroup(newGroupName);

            this.hubConnection.onclose(() => this.onConnectionClose(newGroupName));
        } catch (error) {
            console.log(error);
        }
    }

    private connectionState(groupName: string): void {
        if (this.hubConnection.state === HubConnectionState.Disconnected) {
            this.hubConnection.start().then(() => {
                this.joinGroup(groupName);

                this.subscribeToTermsChanged();
                this.subscribeToPolicyChanged();
            });
        }
    }

    private subscribeToTermsChanged(): void {
        this.hubConnection.on(this.termsAndConditionsChangedMethodName, (legalAgreement: HotLegalAgreement) =>
            this.termsAndConditionsChanged$.next(legalAgreement)
        );
    }

    private subscribeToPolicyChanged(): void {
        this.hubConnection.on(this.privacyPolicyChangedMethodName, (legalAgreement: HotLegalAgreement) => {
            this.privacyPolicyChanged$.next(legalAgreement)
        }
        );
    }

    private onConnectionClose(groupName: string): void {
        if (!this.isManualStop) {
            this.startConnection(groupName);
        }
    }

    private createConnection(url: string): void {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(url, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                accessTokenFactory: () =>
                    JSON.parse(localStorage.getItem(StorageKeys.bearerToken)).access_token,
            })
            .build();
    }

    private joinGroup(groupName: string): void {
        this.hubConnection.invoke('JoinGroup', groupName);
    }

    private leaveGroup(groupName: string): void {
        this.hubConnection.invoke('LeaveGroup', groupName);
    }
}
