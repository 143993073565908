import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationTokenStorageService } from '../services/authentication-token-storage.service';

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
    constructor(private readonly authenticationTokenStorageService: AuthenticationTokenStorageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let bearerToken = null;  
        if(!request.headers.has('skip')){
                bearerToken = this.authenticationTokenStorageService.getToken();
            }
            
        if (!!bearerToken) {                        
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${bearerToken.access_token}`,
                    },
            });
        }
    
        return next.handle(request);
    }
}
