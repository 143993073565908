import { ProductsState } from '@hot-b2b/store/products/model';

export const productsInitialState: ProductsState = {
    pending: false,
    fetched: false,
    data: {
        aggregations: [],
        products: [],
        totalCount: null,
    },
    error: null,
};
