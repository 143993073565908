import { EWidgetsActions } from './types';
import { WidgetsActions } from './actions';
import { widgetsInitialState } from './state';
import { IWidgetsState } from './model';
import { syncQuantityWithCart } from './helpers';

export function widgetsReducer(state: IWidgetsState = widgetsInitialState, action: WidgetsActions): IWidgetsState {
    switch (action.type) {
        case EWidgetsActions.WIDGETS_PENDING: {
            return {
                ...state,
                fetched: false,
                pending: true,
                error: null,
            };
        }
        case EWidgetsActions.WIDGETS_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        }
        case EWidgetsActions.POPULAR_PRODUCTS_SYNCHRONIZED_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: syncQuantityWithCart(state.data, action.payload),
            };
        }
        default:
            return state;
    }
}
