import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { HotApiNotificationMessagesService, NotificationMessage } from '@hot-theme-nx/generated-api';

import { authLoggedIn } from '@hot-b2b/store/auth/selector';
import {
    NotificationsEmptyAction,
    NotificationsGet,
    NotificationsGetSuccess,
    NotificationsMarkAsRead,
} from '@hot-b2b/store/notifications/actions';
import { newNotificationId } from '@hot-b2b/store/notifications/selector';
import { ENotificationSActions } from '@hot-b2b/store/notifications/types';
import { AppState } from '@hot-b2b/store/reducers';

@Injectable()
export class NotificationEffects {
    constructor(
        private readonly _store: Store<AppState>,
        private readonly _actions$: Actions,
        private readonly hotApiNotificationMessagesService: HotApiNotificationMessagesService
    ) {}

    public NotificationsGet$: Observable<NotificationsGetSuccess> = createEffect(() => this._actions$.pipe(
        ofType<NotificationsGet>(ENotificationSActions.NOTIFICATIONS_PENDING),
        withLatestFrom(this._store.pipe(select(authLoggedIn))),
        filter(([, loggedIn]: [NotificationsGet, boolean]) => loggedIn),
        switchMap(() =>
            this.hotApiNotificationMessagesService
                .getLatestMessages()
                .pipe(map((response: NotificationMessage[]) => new NotificationsGetSuccess(response)))
        )
    ));

    public NotificationsMarkAsRead$: Observable<NotificationsGet> = createEffect(() => this._actions$.pipe(
        ofType<NotificationsMarkAsRead>(ENotificationSActions.NOTIFICATIONS_MARK_AS_READ),
        withLatestFrom(this._store.pipe(select(newNotificationId))),
        filter(([, allUnreadIds]: [NotificationsMarkAsRead, string[]]) => !!allUnreadIds.length),
        switchMap(([action, allUnreadIds]: [NotificationsMarkAsRead, string[]]) => {
            const idsToSend: string[] = action.payload || allUnreadIds;
            return this.hotApiNotificationMessagesService.markAsRead(idsToSend).pipe(map(() => new NotificationsGet()));
        })
    ));

    public NotificationsGetSuccess$: Observable<NotificationsEmptyAction> = createEffect(() => this._actions$.pipe(
        ofType<NotificationsGetSuccess>(ENotificationSActions.NOTIFICATIONS_FETCHED),
        map(() => new NotificationsEmptyAction())
    ));
}
