import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'svgPath' })
export class PathSvgPipe implements PipeTransform {
    public transform(value: string): string {
        if (!value) {
            return '';
        }

        return `/assets/static/svg/${value}`;
    }
}
