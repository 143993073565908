import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { CurrencyFormat, HotProductAvailability, HotApiCatalogService } from '@hot-theme-nx/generated-api';

import { HotCartLineItemExtended } from '../../../models';

import { CartOfflineAddItem, CartOfflineChangeItem, CartOfflineRemoveItem } from '@hot-b2b/store/cart/actions';
import { cartItems } from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine, settingsCurrencyFormat, settingsStore } from '@hot-b2b/store/settings/selector';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { CustomerPermissions, PageName } from '@hot-libs/shared-types';
import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { FeaturesService, ProductService } from 'apps/hot-b2b/src/app/shared/services';
import { PermissionsService } from 'apps/hot-b2b/src/app/shared/services/permissions.service';
import find from 'lodash/find';
import { ConfigurationService } from '../../../services/configuration.service';
import { FIleDownloadUtil } from '../../../services/file-download-util.service';

@Component({
    selector: 'hot-product-details-modal',
    templateUrl: './product-details-modal.component.html',
})
export class ProductDetailsModalComponent implements OnInit {
    @Input() public modifier: string;
    @Input() public product;
    @Input() public isDistrCatalogProduct: boolean;
    @Input() public initDistrProductStatus: boolean;
    @Input() private isSetPromoProduct?: boolean;
    @Input() public isReturn: boolean;
    @Output() public closeModal: EventEmitter<any> = new EventEmitter();
    @Output() public addToCart: EventEmitter<any> = new EventEmitter();
    @Output() public removeFromCart: EventEmitter<any> = new EventEmitter();
    @Output() public sendChangeQuantity: EventEmitter<any> = new EventEmitter();
    @Output() public selectStatus: EventEmitter<{
        product: HotProductAvailability;
        status: boolean;
        initStatus: boolean;
    }> = new EventEmitter<{ product: HotProductAvailability; status: boolean; initStatus: boolean }>();
    @Output() public preorderChanged: EventEmitter<HotProductAvailability> = new EventEmitter<HotProductAvailability>();
    @Output() public activateChanged: EventEmitter<HotProductAvailability> = new EventEmitter<HotProductAvailability>();

    public settingsAppOnLine$: Observable<boolean>;
    public currencyFormat: CurrencyFormat;
    public settingsStore$: Observable<SettingsStoreModel>;

    public readonly featureStockControl: boolean = this.featuresService.StockControl;
    public readonly featureShowSku: boolean = this.featuresService.ShowDistributorLineItemSku;
    public readonly featureShowProductProperties: boolean = this.featuresService.ShowProductProperties;

    private cartItems$: Observable<HotCartLineItemExtended[]>;
    private readonly unsubscribe$ = new Subject();

    private readonly subscriptionDestroy$ = new Subject<boolean>();

    public haveReadPricingPermission: Observable<boolean> = this.permissionsService.checkPermission(
        CustomerPermissions.PricingRead
    );
    public readonly featureAllowPriceManagementOnDistributorPortal = this.featuresService
        .AllowPriceManagementOnDistributorPortal;
    public pageName: string = PageName.PRODUCTDETAILMODAL;
    public currencyFormat$: Observable<CurrencyFormat>;

    public get isPromo(): Observable<boolean> {
        return this.productService.showPromoLabel(this.product);
    }
    public isGRB2b = () => this.configurationService.getCurrentConfiguration() === 'GR';
    public isVN = () => this.configurationService.getCurrentConfiguration() === 'VN';
    public isHOPB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';

    constructor(
        private readonly store: Store<AppState>,
        private readonly featuresService: FeaturesService,
        private readonly permissionsService: PermissionsService,
        private readonly productService: ProductService,
        private readonly hotApiCatalogService: HotApiCatalogService,
        private readonly configurationService: ConfigurationService
    ) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.cartItems$ = this.store.pipe(select(cartItems));     
    }

    ngOnInit(): void {
        if (this.isSetPromoProduct) {
            this.cartItems$.pipe(take(1)).subscribe((items) => {
                const selectedItemFromCart = find(items, { productId: this.product.id });

                if (selectedItemFromCart) {
                    this.product.quantity = selectedItemFromCart.quantity;
                } else {
                    this.product.quantity = 0;
                }
            });
        }
    }

    public addToCartHandler(event: any, product: HotProductExtended): void {
        const tempProduct: HotProductExtended = { ...product };
        tempProduct.quantity = event.quantity;
        tempProduct.addMethod = event.addMethod;

        if (tempProduct.unitCurrent) {
            tempProduct.unitCurrent.quantity = event.quantity;
            tempProduct.id = event.productId;
            tempProduct.addMethod = event.addMethod;
        }

        this.settingsAppOnLine$
            .pipe(take(1))
            .subscribe((online: boolean) =>
                online ? this.addToCart.emit(tempProduct) : this.store.dispatch(new CartOfflineAddItem(tempProduct))
            );
    }

    public sendChangeQuantityHandler(event: any, product: HotProductExtended): void {
        const tempProduct: HotProductExtended = { ...product };

        tempProduct.quantity = event.quantity;
        if (tempProduct.unitCurrent) {
            tempProduct.id = event.productId;
            tempProduct.addMethod = event.addMethod;
        }

        this.settingsAppOnLine$
            .pipe(take(1))
            .subscribe((online: boolean) =>
                online
                    ? this.sendChangeQuantity.emit(tempProduct)
                    : this.store.dispatch(new CartOfflineChangeItem(tempProduct))
            );
    }

    public removeFromCartHandler(event: any, product: HotProductExtended): void {
        const tempProduct: HotProductExtended = { ...product };
        tempProduct.addMethod = event.addMethod;
        if (tempProduct.unitCurrent) {
            tempProduct.id = event.id;
            tempProduct.addMethod = event.addMethod;
        }

        this.settingsAppOnLine$
            .pipe(take(1))
            .subscribe((online: boolean) =>
                online
                    ? this.removeFromCart.emit(tempProduct)
                    : this.store.dispatch(new CartOfflineRemoveItem({ id: event.id }))
            );
    }

    public downloadProductInfoPdf(productId: string): void {
        this.hotApiCatalogService
            .exportSingleProduct(productId)
            .pipe(takeUntil(this.subscriptionDestroy$))
            .subscribe((response) => {
                FIleDownloadUtil.fileDownload(response, `${this.product.sku}.pdf`);
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(undefined);
        this.unsubscribe$.complete();
    }
}
