import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BaseControlValueAccessor, ValueAccessorProvider } from 'apps/hot-b2b/src/app/shared/internal';

@Component({
    selector: 'hot-select',
    templateUrl: './select.component.html',
    providers: [ValueAccessorProvider(HotSelectComponent)],
})
export class HotSelectComponent extends BaseControlValueAccessor implements OnInit {
    @Input() public optionalLabel: string;
    @Input() public hideDropDown:boolean;
    @Input() public items: string[] | any[];
    @Input() public selected: any;
    @Input() public modifier: string;
    @Input() public disabled: boolean;
    @Input() public placeholder: string;
    @Input() public caption: string;
    @Input() public maxValueLength: number;
    @Input() public isAdvancedFilter = false;
    @Input() public scrollNeeded: boolean;
    @Input() public isPlatformSelector : boolean = false;
    @Input() public isNavbarStyle: boolean;
    @Output() public optSelect = new EventEmitter();
    @Output() public platformSelect = new EventEmitter();
    public selectedOptionIndex: number = 0;
    public isDropdownOpened = false;
    public isTouchDevice: boolean;

    public ngOnInit(): void {
        this.isTouchDevice =
            window.innerWidth < 1024 &&
            ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0);
    }

    /**
     * Option selection
     */

    public selectHandle(event: any, item?: any): void {
        event.stopPropagation();
        this.isDropdownOpened = false;
        const itemIndex = this.placeholder ? event.target.selectedIndex - 1 : event.target.selectedIndex;
        this.optSelect.emit(item ? item : this.items[itemIndex]);
        this.selected = item ? item : this.items[itemIndex];
        if (this.selected) {
            this.onChange(this.selected);
        }
    }

    public writeValue(value: any): void {
        super.writeValue(value);
        if (value) {
            this.selected = value;
        }
    }

    public getSelectedValue() {
        let value: string;
        const str = this.selected[this.optionalLabel] || this.selected.name || this.selected;

        if (this.maxValueLength && str.length > this.maxValueLength) {
            value = str.slice(0, this.maxValueLength) + '...';
        } else {
            value = str;
        }
        return value;
    }

    public platformSelectHandler(item : any) : void {
        this.platformSelect.emit(item);
        this.isDropdownOpened = false;
    }
    public platformSelectHandlerMobileView(item: any): void {
        const index = this.items.indexOf(item);
        if (index !== -1) {
            this.selectedOptionIndex = index;
        }
        this.platformSelect.emit(item);
    }
    /**
     * Toggle the dropdown
     */
    public toggleDropdown(): void {
        if (!this.disabled) {
            this.isDropdownOpened = !this.isDropdownOpened;
        }
    }

    /**
     * Close Dropdown if click outside
     */
    public clickOutside(): void {
        this.isDropdownOpened = false;
    }
}
