import { LoyaltyActions } from '@hot-b2b/store/loyalty/actions';
import { LoyaltyState } from '@hot-b2b/store/loyalty/model';
import { loyaltyInitialState } from '@hot-b2b/store/loyalty/state';
import { ELoyaltyActions } from '@hot-b2b/store/loyalty/types';

export function loyaltyReducer(state: LoyaltyState = loyaltyInitialState, action: LoyaltyActions): LoyaltyState {
    switch (action.type) {
        case ELoyaltyActions.LOYALTY_PENDING: {
            return {
                ...state,
                pending: true,
                fetched: false,
                error: null,
            };
        }
        case ELoyaltyActions.LOYALTY_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    loyaltyStatement: action.payload,
                },
                error: null,
            };
        }
        case ELoyaltyActions.LOYALTY_ERROR: {
            return {
                ...state,
                pending: false,
                fetched: false,
                error: action.payload,
            };
        }
        case ELoyaltyActions.LOYALTY_STATUS_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    redemptionWindowStatus: action.payload,
                },
                error: null,
            };
        }
        case ELoyaltyActions.LOYALTY_BANNERS_PENDING: {
            return {
                ...state,
                pending: true,
                fetched: false,
            };
        }
        case ELoyaltyActions.LOYALTY_BANNERS_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    banners: action.payload,
                },
            };
        }
        default: {
            return state;
        }
    }
}
