import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { Capacitor } from '@capacitor/core';
import write_blob from 'capacitor-blob-writer';
import * as FileSaver from 'file-saver';

export class FIleDownloadUtil {
    public static async fileDownload(data: any, fileName: string) {
        try {
            if (Capacitor.getPlatform() === 'web') {
                FileSaver.saveAs(data, fileName);
            } else if (Capacitor.getPlatform() === 'ios') {
                this.writeBlobToFile(Directory.Documents, data, this.ensureFileExtension(fileName, data));
            } else if (Capacitor.getPlatform() === 'android') {
                this.writeBlobToFile(Directory.Cache, data, this.ensureFileExtension(fileName, data));
            }
        } catch (error) {
            console.log('FIleDownloadUtil:An error occurred', error);
        }
    }

    public static ensureFileExtension(fileName: string, data: any) {
        const hasExtension = /\.\w+$/.test(fileName); // Check for any extension
        if (hasExtension) {
            return fileName;
        } else {
            try {
                let blob = data as Blob;
                const extensionMap = {
                    'text/plain': '.txt',
                    'application/pdf': '.pdf',
                    'application/zip': '.zip',
                    'application/msword': '.doc',
                    'application/vnd.ms-excel': '.xls',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
                    'application/vnd.ms-powerpoint': '.ppt',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
                };
                const mimeType = blob.type;
                if (mimeType) {
                    const extension = extensionMap[mimeType];
                    if (extension) {
                        return fileName + extension;
                    } else {
                        return fileName + '.xlsx';
                    }
                } else {
                    return fileName + '.xlsx';
                }
            } catch (error) {
                console.log('FIleDownloadUtil:ensureFileExtension:error' + error);
            }
            return fileName + '.xlsx';
        }
    }

    private static writeBlobToFile = async (directory: Directory, blob, fileName) => {
        const finalFileUri = await Filesystem.getUri({
            directory: directory,
            path: `${fileName}`,
        });
        write_blob({
            path: `${fileName}`,
            directory: directory,
            blob: blob,
            fast_mode: true,
            recursive: true,
            on_fallback(error) {
                console.error(error);
            },
        }).then(async () => {
            await FileOpener.openFile({
                path: finalFileUri.uri,
            })
                .then(() => {
                    console.log('FIleDownloadUtil:File has been downloaded');
                })
                .catch((e) => {
                    console.log('FIleDownloadUtil:An error occurred during the download');
                });
        });
    };
}
