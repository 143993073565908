import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'hot-theme-nx-duplicatetab-checkout-warning-modal',
    templateUrl: './duplicatetab-checkout-warning-modal.component.html'
})
export class DuplicatetabCheckoutWarningModalComponent {
    @Output() public closeButton: EventEmitter<void> = new EventEmitter();

    public closeModal(): void {
        this.closeButton.emit();
    }
}
