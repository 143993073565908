import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, retry, shareReplay, switchMap } from 'rxjs/operators';

import { HotFulfillmentCenter } from '@hot-theme-nx/generated-api';

import { WarehouseService } from 'apps/hot-b2b/src/app/shared/services';
import {
    WarehouseReward,
    WarehousesError,
    WarehousesGet,
    WarehousesGetSuccess,
    WarehousesRewardsGetSuccess,
} from '@hot-b2b/store/warehouses/actions';
import { EWarehousesActions } from '@hot-b2b/store/warehouses/types';
import { AuthenticationService } from '../../account/services';

@Injectable()
export class WarehousesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly warehouseService: WarehouseService,
        private readonly authenticationService: AuthenticationService
    ) { }

    public WarehousesGet$: Observable<
        WarehousesGetSuccess | WarehousesError
    > = createEffect(() => this.actions$.pipe(
        ofType<WarehousesGet>(EWarehousesActions.WAREHOUSES_PENDING),
        switchMap(() => {
            return this.warehouseService.getWarehouses();
        }),
        switchMap((data: HotFulfillmentCenter[]) => [
            new WarehousesGetSuccess(data)
        ]),
        retry(3),
        catchError((err: any) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            return throwError(err);
        }),
        shareReplay()
    ));
    public WarehouseReward$:Observable<WarehousesRewardsGetSuccess | WarehousesError
    >= createEffect(() => this.actions$.pipe(ofType<WarehouseReward>(EWarehousesActions.WAREHOUSES_REWARDS_PENDING),
    switchMap(()=>{
        return this.warehouseService.getWarehouses(true);
    }),
    switchMap((data:HotFulfillmentCenter[])=>[
        new WarehousesRewardsGetSuccess(data)
    ]),
    retry(3),
    catchError((err: any) => {
        if (err.status === 401) {
            this.authenticationService.logout();
        }
        return throwError(err);
    }),
    shareReplay()
    ));


}
