import { Action } from '@ngrx/store';
import { MobielAppDetailssActions } from './type';
import { NativeAppModel } from '../../native/services';

export class UpdateNativeMobileAppDetails implements Action {
    public readonly type = MobielAppDetailssActions.UPDATE_APP_DETAILS;
    constructor(public payload: NativeAppModel) {}
}

export type NativeMobileAppDetailsAction = UpdateNativeMobileAppDetails;
