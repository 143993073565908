import { Component } from '@angular/core';
import { ModalIds } from '@hot-libs/shared-types';

import { ModalService } from 'apps/hot-b2b/src/app/shared/services';

@Component({
    selector: 'hot-distributor-order-confirmed-modal',
    templateUrl: './distributor-order-confirmed-modal.component.html',
})
export class DistributorOrderConfirmedModalComponent {
    constructor(private readonly modalService: ModalService) {}

    public close(): void {
        this.modalService.toggleModal(ModalIds.distributorOrderConfirmed, false);
    }
}
