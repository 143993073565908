import { HotBrandGroupExtended } from 'apps/hot-b2b/src/app/catalog/models';

const allBrands: HotBrandGroupExtended = {
    id: 'all',
    name: 'catalog.brands-page.all-brands',
    imageUrl: '',
    type: [],
};

export { allBrands };
