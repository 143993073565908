import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { HotAccountSettings, HotApiAccountService, HotLanguage } from '@hot-theme-nx/generated-api';
import { HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { FeatureNames, StorageKeys } from '@hot-libs/shared-types';

import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import { SettingsService } from 'apps/hot-b2b/src/app/shared/services/settings.service';
import { TranslateManagerService } from 'apps/hot-b2b/src/app/shared/services/translate-manager.service';
import { AuthEdit } from '@hot-b2b/store/auth/actions';
import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';

import {
    SettingsError,
    SettingsGet,
    SettingsGetSuccess,
    SettingsLanguagesGet,
    SettingsLanguagesGetSuccess,
    SettingsStoreGet,
    SettingsStoreGetSuccess,
    SettingsToggleOnline,
    SettingsUpdate,
} from './actions';
import { FeaturesItem, HotSettingsExtended, SettingsStoreSlots } from './model';
import { ESettingsActions } from './types';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CoreService } from '../../core/core.service';

@Injectable()
export class SettingsEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly _store: Store<AppState>,
        private readonly settingsService: SettingsService,
        private readonly translateManager: TranslateManagerService,
        private readonly featuresService: FeaturesService,
        private readonly hotAccountApi: HotApiAccountService,
        private readonly router: Router,
        private readonly coreService: CoreService,
        private readonly deviceDetectorService: DeviceDetectorService
    ) {}

    public SettingsGet$: Observable<SettingsGetSuccess | SettingsError> = createEffect(() => this._actions$.pipe(
        ofType<SettingsGet>(ESettingsActions.SETTINGS_PENDING),
        switchMap(() => {
            return this.settingsService.getSettings().pipe(
                map((response: HotSettingsExtended) => {
                    response.availableLanguages.forEach((language: HotLanguage) => {
                        // Heineken requested to show only language name, without culture. OTCS-2369
                        if (language.name.includes('(')) {
                            let index: number = language.name.indexOf('(');

                            language.name = language.name.substring(0, index).trim();

                            language.name = language.name.charAt(0).toLocaleUpperCase() + language.name.slice(1);
                        } else {
                            language.name = language.name.charAt(0).toLocaleUpperCase() + language.name.slice(1);
                        }
                    });
                    const dashboardFeature = response.features.find(
                        (featureItem: FeaturesItem) => featureItem.name === FeatureNames.DashboardHomePage
                    );

                    if (dashboardFeature?.isActive) {
                        this.coreService.setMainRoutingWithDashboard();
                    }
                    return new SettingsGetSuccess(response);
                }),
                catchError(() => of(new SettingsError('error')))
            );
        })
    ));

    public SettingsGetSuccess$: Observable<void> = createEffect(() => this._actions$.pipe(
        ofType<SettingsGetSuccess>(ESettingsActions.SETTINGS_FETCHED),
        map(() => {
            this.translateManager.languageWorker();
        })
    ),{ dispatch: false }
    );

    public SettingsLanguagesGet$: Observable<Action> = createEffect(() => this._actions$.pipe(
        ofType<SettingsLanguagesGet>(ESettingsActions.SETTINGS_LANGUAGE_PENDING),
        switchMap(() => {
            return this.translateManager
                .getAccountSettings()
                .pipe(map((response: HotAccountSettings) => new SettingsLanguagesGetSuccess(response)));
        })
    ));

    public SettingsLanguagesSuccess$: Observable<Action> = createEffect(() => this._actions$.pipe(
        ofType<SettingsLanguagesGetSuccess>(ESettingsActions.SETTINGS_LANGUAGE_FETCHED),
        tap(() => {
            this.translateManager.userLanguage();
        })
    ),{ dispatch: false }
    );

    public settingsStoreGet$ = createEffect(() => this._actions$.pipe(
        ofType<SettingsStoreGet>(ESettingsActions.SETTINGS_STORE_PENDING),
        mergeMap(() =>
            this.settingsService
                .getStoreSettings()
                .pipe(map((items: SettingsStoreModel) => [{ id: '', settings: items }]))
        ),
        tap((storeModel) => {
            from(storeModel)
                .pipe(first())
                .subscribe((store) => {
                    //for all browsers
                    if (store.settings && this.deviceDetectorService.browser) {
                        const settings = store.settings;
                        const browserCacheClearTime = localStorage.getItem(StorageKeys.browserCacheClearTime)
                            ? parseInt(localStorage.getItem(StorageKeys.browserCacheClearTime))
                            : 0;
                        if (
                            settings.invalidateBrowserCache &&
                            settings.cacheClearanceEffectiveTime !== '' &&
                            browserCacheClearTime < new Date(settings.cacheClearanceEffectiveTime).getTime()
                        ) {
                            this.settingsService.clearSettingCache();
                            localStorage.setItem(StorageKeys.browserCacheClearTime, new Date().getTime().toString());
                        }
                    }
                });
        }),
        map((response: SettingsStoreSlots[]) => new SettingsStoreGetSuccess(response))
    ));

    public accountSettingsUpdate$: Observable<void | SettingsError> = createEffect(() => this._actions$.pipe(
        ofType<SettingsUpdate>(ESettingsActions.SETTINGS_UPDATE),
        switchMap((action: SettingsUpdate) =>
            this.hotAccountApi.updateAccountSettings(action.payload).pipe(
                withLatestFrom(this._store.pipe(select(authUser))),
                map(([, userModel]: [null, HotUserExtended]) => {
                    const user = { ...userModel, ...action.payload };
                    this._store.dispatch(new AuthEdit(user));
                }),
                catchError(() => of(new SettingsError('Settings Update Error')))
            )
        )
    ),{ dispatch: false }
    );

    public SettingsToggleOnline$: Observable<void> = createEffect(() => this._actions$.pipe(
        ofType<SettingsToggleOnline>(ESettingsActions.SETTINGS_TOGGLE_ONLINE),
        map((action: SettingsToggleOnline) => {
            if (!action.payload) {
                this.settingsService
                    .isUrlAllowedForOfflineMode()
                    .pipe(take(1))
                    .subscribe((isUrlAllowed: boolean) => {
                        if (!isUrlAllowed) {
                            this.router.navigate(['/']);
                        }
                    });
            }
        })
    ),{ dispatch: false }
    );
}
