import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from 'apps/hot-b2b/src/app/app.component';
import { initializeSettings } from 'apps/hot-b2b/src/app/app.initializer';
import { CoreModule } from 'apps/hot-b2b/src/app/core/core.module';
import { createTranslateLoader } from 'apps/hot-b2b/src/app/shared/services/translate-manager.service';
import { rootEffects } from '@hot-b2b/store/effects';
import { clearAppStateReducer, rootReducer } from '@hot-b2b/store/reducers';
import { NativeService } from './native/services';
import {
    ErrorInterceptor,
    LoaderInterceptor,
    NoApiCacheInterceptor,
    TimerInterceptor,
    FilesDownloadInterceptor,
} from './shared/interceptors';

import { environment } from 'apps/hot-b2b/src/environments/environment';
import { IonicModule } from '@ionic/angular';
import {
    MSAL_BROADCAST_CONFIG,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MsalBroadcastService,
    MsalGuard,
    MsalModule,
    MsalService,
} from '@azure/msal-angular';

import { HotChatbotComponent } from './chatbot/chatbot.component';
import { HotDotTutorialBtnComponent } from './dot-tutorial-btn/dot-tutorial-btn.component';
import { HotFaqIconComponent } from './faq-icon/faq-icon.component';
import { MsalAngularConfigFactory, MsalConfigFactory } from './account/services/msalConfig';
import { TutorialComponent } from './shared/components/tutorial/tutorial.component';
import { ForceUpgradeComponent } from './force-upgrade/force-upgrade.component';
import { BemModule } from './shared/directives/bem';
import { LoaderComponent, LoaderComponentV2 } from './shared/components';
import { ImageModule } from './shared/image.module';
@NgModule({
    declarations: [
        AppComponent,
        HotChatbotComponent,
        HotDotTutorialBtnComponent,
        HotFaqIconComponent,
        ForceUpgradeComponent,
        LoaderComponent,
        LoaderComponentV2,
        TutorialComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        CoreModule,
        ImageModule,
        ServiceWorkerModule.register('/sw-main.js', { enabled: environment.production }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        StoreModule.forRoot(rootReducer, {
            metaReducers: [clearAppStateReducer],
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        EffectsModule.forRoot(rootEffects),
        StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer, stateKey: 'router' }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        IonicModule.forRoot(),
        MsalModule,
        BemModule
    ],
    providers: [
        Title,
        {
            /**
             * App starts in this order:
             * - AppModule initializing (constructor)
             * - Store initializing (https://github.com/ngrx/platform/issues/931)
             * - all store-effects initializing (constructors)
             * - all DI-s services in each effect-constructor initializing (constructor in each DI-service)
             * - all injected services initializing (all constructors and services fields)
             * - and only AFTER that APP_INITIALIZER and AppComponent rendering
             */
            provide: APP_INITIALIZER,
            useFactory: initializeSettings,
            multi: true,
            deps: [Store, Router, NativeService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TimerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoApiCacheInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FilesDownloadInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MsalConfigFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MsalAngularConfigFactory,
        },
        {
            provide: MSAL_BROADCAST_CONFIG,
            useValue: {
                eventsToReplay: 2,
            },
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
