import { Component, Input } from '@angular/core';

import { ModalIds } from '@hot-libs/shared-types';

import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';

@Component({
    selector: 'hot-network-unavailable-modal',
    templateUrl: './network-unavailable-modal.component.html',
})
export class NetworkUnavailableModalComponent {
    @Input() public title: string;
    public PATHS = PATHS;

    constructor(private readonly modalService: ModalService) {}

    public close(): void {
        this.modalService.toggleModal(ModalIds.networkUnavailable, false);
    }
}
