import { ProductService } from '@hot-theme-nx/common-api';

import { cartInitialState } from './state';

import { clearPriceToNumber } from '@hot-libs/helpers';
import { StorageKeys } from '@hot-libs/shared-types';
import {
    HotProductExtended,
    HotUnitOfMeasureExtended,
    ProductFunctionalityModel,
} from 'apps/hot-b2b/src/app/catalog/models';
import { HotFulfillmentCenter } from '@hot-theme-nx/generated-api';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { config } from 'apps/hot-b2b/src/environments/config';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import some from 'lodash/some';

/**
 * TO DO:
 * need refactoring all in file to OOP
 */

const updateProductFromCatalog = (cart: HotCartExtended, payload: HotProductExtended): HotCartExtended => {
    const tempIndex: number = cart.items.findIndex((item: HotCartLineItemExtended) => item.productId === payload.id);

    if (tempIndex !== -1) {
        // what fields needs to update
        cart.items[tempIndex].isSubscribed = payload.isSubscribed;
    }

    return cart;
};

const mutateProductForCartModel = (product: HotProductExtended, quantity: number): HotCartLineItemExtended => {
    const productId = product.unitCurrent ? product.unitCurrent.id : product.id;
    const salePrice = product.unitCurrent ? product.unitCurrent.salePrice : product.salePrice;
    const listPrice = product.unitCurrent ? product.unitCurrent.listPrice : product.listPrice;

    const newModel = {
        id: productId,
        quantity,
        productId,
        imageUrl: product.images ? product.images[0] : '',
        isGift: false,
        totalVolume: 0,
        isValid: true,
        isPromo: false,
        placedPrice: 0,
        extendedPrice: clearPriceToNumber(salePrice || listPrice) * quantity,
        discountAmount: 0,
        discountTotal: 0,
        emptiesDeposit: product.emptiesDeposit,
        emptiesDepositTotal: 0,
        taxTotal: 0,
        taxType: '',
        taxPercentRate: 0,
        taxIncludedRate: 0,
        vat: 0,
        comment: '',
        validationErrors: [],
        salePrice,
        listPrice,
        product,
    };

    return { ...product, ...newModel };
};

const calculateCart = (cart: HotCartExtended): HotCartExtended => {
    const rootValues = {
        itemsQuantity: 0,
        itemsCount: cart.items.length,
        itemsVolume: 0,
        salePrice: 0,
        subTotal: 0,
        taxTotal: 0,
        total: 0,
        emptiesDepositTotal: 0,
        extendedPriceTotal: 0,
        discountTotal: 0,
    };

    cart.items.forEach((item: HotCartLineItemExtended) => {
        rootValues.itemsQuantity += item.quantity;
        rootValues.itemsVolume += item.unitVolume;
        rootValues.salePrice += item.salePrice;
        rootValues.subTotal += item.listPrice * item.quantity;
        item.emptiesDepositTotal = item.emptiesDeposit ? item.emptiesDeposit * item.quantity : 0;
        rootValues.emptiesDepositTotal += item.emptiesDepositTotal;
        rootValues.extendedPriceTotal += item.salePrice * item.quantity;
        rootValues.discountTotal =
            item.salePrice > 0 && item.listPrice > item.salePrice
                ? (item.listPrice - item.salePrice) * item.quantity
                : 0;
        rootValues.total += item.salePrice * item.quantity + item.emptiesDepositTotal;
    });

    cart = { ...cart, ...rootValues };

    setCartInStorage(cart);

    return cart;
};

const getCartFromStorage = (): HotCartExtended => {
    let cart: HotCartExtended = cartInitialState.data.cart;
    cart.items = [];

    const cartStorageValue = localStorage.getItem(StorageKeys.offlineCart);
    if (cartStorageValue) {
        cart = JSON.parse(cartStorageValue);
    }

    return cart;
};

const setCartInStorage = (cart: HotCartExtended): void => {
    localStorage.setItem(StorageKeys.offlineCart, JSON.stringify(cart));
};

const checkUnit = (product) => {
    if (product.unitCurrent) {
        const unitDataFromId: HotUnitOfMeasureExtended = product.unitsOfMeasure.find(
            (item: HotUnitOfMeasureExtended) => item.id === product.id
        );
        if (unitDataFromId) {
            product.unitCurrent.quantity = product.quantity;
            product.listPrice = unitDataFromId.listPrice;
            product.salePrice = unitDataFromId.salePrice;
            product.extendedPrice = unitDataFromId.extendedPrice;
            product.packageType = unitDataFromId.packageType;

            delete product.unitCurrent;
            delete product.unitsOfMeasure;
        }
    }
    return product;
};

/**
 * TO DO:
 * setProductInCart function is dont need, need leave only changeProductInCart function
 */

const setProductInCart = (cart: HotCartExtended, product: HotProductExtended): HotCartExtended => {
    const tempCart: HotCartExtended = { ...cart };
    const tempProduct: HotProductExtended = { ...product };
    if (!tempCart.items.length && tempProduct) {
        tempCart.isValid = true;
    }

    checkUnit(tempProduct);

    const mutableObject: HotCartLineItemExtended = mutateProductForCartModel(tempProduct, tempProduct.quantity);

    tempCart.items = calculateDuplicatedItems(tempCart.items, mutableObject);

    return calculateCart(tempCart);
};

const calculateDuplicatedItems = (
    sourceItems: HotCartLineItemExtended[],
    sourceItem: HotCartLineItemExtended
): HotCartLineItemExtended[] => {
    const calculatedItems = [...sourceItems];

    const existedItem = sourceItems.find(
        (tempItem: HotCartLineItemExtended) => tempItem.productId === sourceItem.productId
    );
    if (existedItem) {
        calculatedItems[calculatedItems.indexOf(existedItem)].quantity = sourceItem.quantity;
    } else {
        calculatedItems.push(sourceItem);
    }

    return calculatedItems;
};

const setArrayOfProductInCart = (cart: HotCartExtended, products: HotProductExtended[]): HotCartExtended => {
    const tempCart: HotCartExtended = { ...cart };

    tempCart.items = products.map((product: HotProductExtended) => {
        const mutableObject: HotCartLineItemExtended = mutateProductForCartModel(product, 1);

        return [...tempCart.items, mutableObject][0];
    });

    return calculateCart(tempCart);
};

const setArrayOfOrdersInCart = (cart: HotCartExtended, products: any[]): HotCartExtended => {
    const tempCart: HotCartExtended = { ...cart };

    const changeSource = (data: HotCartLineItemExtended[], product: any): void => {
        data.forEach((item: HotCartLineItemExtended) => {
            if (item.id === product.id) {
                item.quantity += product.quantity;
            }
        });
    };

    products.forEach((product) => {
        product.salePrice = product.listPrice;
        changeSource(tempCart.items, product);
    });

    tempCart.items = uniqBy([...tempCart.items, ...products], 'id').filter((p) => p.isInStock);

    return calculateCart(tempCart);
};

const changeProductInCart = (cart: HotCartExtended, product: HotProductExtended): HotCartExtended => {
    const tempCart: HotCartExtended = cloneDeep(cart);
    const tempProduct: HotProductExtended = { ...product };

    checkUnit(tempProduct);

    const condition = (item: HotCartLineItemExtended): boolean =>
        item.productId === tempProduct.id || item.id === tempProduct.id;

    const productExist: HotCartLineItemExtended = tempCart.items.find((item: HotCartLineItemExtended) =>
        condition(item)
    );

    if (productExist) {
        tempCart.items.forEach((item: HotCartLineItemExtended) => {
            if (condition(item)) {
                item.quantity = tempProduct.quantity;
                item.extendedPrice = calculateExtendedPrice(tempProduct);
                item.totalVolume = item.packageVolume * item.quantity;
                return item;
            }
        });
    } else {
        const itemToPush: HotCartLineItemExtended = mutateProductForCartModel(tempProduct, tempProduct.quantity);
        tempCart.items = [...tempCart.items, itemToPush];
    }

    return calculateCart(tempCart);
};

const removeProductFromCart = (cart: HotCartExtended, id: string): HotCartExtended => {
    const tempCart: HotCartExtended = { ...cart };
    tempCart.items = tempCart.items.filter((item: HotCartLineItemExtended) => item.productId !== id && item.id !== id);

    return calculateCart(tempCart);
};

const mergePendingCartChanges = (
    existingChanges: ProductFunctionalityModel[],
    newChanges: ProductFunctionalityModel[]
): ProductFunctionalityModel[] => {
    const mergedChanges: ProductFunctionalityModel[] = [...existingChanges];

    newChanges.forEach((newItem: ProductFunctionalityModel) => {
        const existingItem: ProductFunctionalityModel = mergedChanges.find(
            (item: ProductFunctionalityModel) => item.productId === newItem.productId
        );

        if (existingItem) {
            existingItem.quantity = newItem.quantity;
        } else {
            mergedChanges.push(newItem);
        }
    });

    return mergedChanges;
};

const excludeCartChangesFromList = (
    existingItems: ProductFunctionalityModel[],
    itemsToExclude: ProductFunctionalityModel[]
): ProductFunctionalityModel[] =>
    existingItems.filter(
        (existingItem: ProductFunctionalityModel) =>
            !some(
                itemsToExclude,
                (item: ProductFunctionalityModel) =>
                    item.id === existingItem.id || item.productId === existingItem.productId
            )
    );

const calculateExtendedPrice = (item: HotProductExtended): number => (item.salePrice || item.listPrice) * item.quantity;

const removeGiftItems = (cart: HotCartExtended): HotCartExtended => {
    const filteredCart: HotCartExtended = { ...cart };

    filteredCart.items = filteredCart.items.filter((item: HotCartLineItemExtended) => !item.isGift);

    return calculateCart(filteredCart);
};

const getEmptyCart = (cart: HotCartExtended): HotCartExtended => {
    const tempCart: HotCartExtended = { ...cart };

    tempCart.items = [];

    return calculateCart(tempCart);
};

const normalizeItemsQuantity = (cartItems: HotCartLineItemExtended[], usePackagesWithCartsAndOrders: boolean) => {
    if (!cartItems) {
        return [];
    }

    cartItems.forEach((item) => {
        if (item.product?.firstQuantityStep) {
            item.firstQuantityStep = item.product.firstQuantityStep;
            item.secondQuantityStep = item.product.secondQuantityStep;
        }
    });

    return calculatePackagePart(cartItems, usePackagesWithCartsAndOrders);
};

const calculatePackagePart = (cartItems: HotCartLineItemExtended[], usePackagesWithCartsAndOrders: boolean) => {
    return cloneDeep(cartItems).map((item: HotCartLineItemExtended) => {
        if (item.packageSize !== 0 && !usePackagesWithCartsAndOrders) {
            item.quantity = ProductService.calculateFractionalPart(item.quantity, item.packageSize);
        }
        return item;
    });
};

const getCartDemoData = () => {
    return config.mockCart || {};
};
const syncQuantityWithCart = (products: HotProductExtended[], cartItems: HotCartLineItemExtended[]) => {
    let finalProducts = editQuantity(products, cartItems);
    return finalProducts;
};

const editQuantity = (
    catalogProducts: HotProductExtended[],
    cartProducts: HotCartLineItemExtended[],
    featureSplitOrdersBySuppliersWhenCheckout = false
): HotProductExtended[] => {
    const fulfillmentCenter = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter)) as HotFulfillmentCenter;

    return catalogProducts?.map((node: HotProductExtended) => {
        let temp: HotProductExtended = { ...node };
        temp.quantity = 0;
        temp.extendedPrice = 0;

        if (temp.unitsOfMeasure) {
            temp.unitsOfMeasure.forEach((unitOfMeasureItem: HotUnitOfMeasureExtended) => {
                unitOfMeasureItem.quantity = 0;
                unitOfMeasureItem.extendedPrice = unitOfMeasureItem.salePrice;
            });
        }

        cartProducts.forEach((item: HotCartLineItemExtended) => {
            if (temp.unitsOfMeasure) {
                temp = assignQuantities(item, temp);
            }
            else if (node.id === item.productId && !item.isGift) {
                temp = orderSplit(item, temp, featureSplitOrdersBySuppliersWhenCheckout, fulfillmentCenter);
            }
        });

        return temp;
    });
};
const assignQuantities = (item: HotCartLineItemExtended, temp: HotProductExtended) => {
    temp.unitsOfMeasure.forEach((unitOfMeasure: HotUnitOfMeasureExtended) => {
        if (unitOfMeasure.id === item.productId && !item.isGift) {
            temp.packageTypeHop = item.packageTypeHop ? item.packageTypeHop : ' ';
            unitOfMeasure.quantity = item.quantity;
            temp.quantity = item?.quantity;
        }
    });
    return temp;
};
const orderSplit = (
    item: HotCartLineItemExtended,
    temp: HotProductExtended,
    featureSplitOrdersBySuppliersWhenCheckout,
    fulfillmentCenter
) => {
    if (featureSplitOrdersBySuppliersWhenCheckout) {
        if (item.storeId === fulfillmentCenter?.storeId) {
            temp.quantity = item.quantity;
            temp.extendedPrice = item.extendedPrice;
        }
    } else {
        temp.quantity = item.quantity;
        temp.extendedPrice = item.extendedPrice;
    }
    return temp;
};
export {
    getCartFromStorage,
    updateProductFromCatalog,
    setProductInCart,
    setArrayOfProductInCart,
    changeProductInCart,
    removeProductFromCart,
    setArrayOfOrdersInCart,
    mergePendingCartChanges,
    excludeCartChangesFromList,
    removeGiftItems,
    getEmptyCart,
    getCartDemoData,
    normalizeItemsQuantity,
    calculatePackagePart,
    syncQuantityWithCart,
};
