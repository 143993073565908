import { Action } from '@ngrx/store';

import { NotificationMessage } from '@hot-theme-nx/generated-api';

import { ENotificationSActions } from '@hot-b2b/store/notifications/types';

export class NotificationsGet implements Action {
    public readonly type = ENotificationSActions.NOTIFICATIONS_PENDING;
}

export class NotificationsGetSuccess implements Action {
    public readonly type = ENotificationSActions.NOTIFICATIONS_FETCHED;
    constructor(public payload: NotificationMessage[]) {}
}

export class NotificationsMarkAsRead implements Action {
    public readonly type = ENotificationSActions.NOTIFICATIONS_MARK_AS_READ;
    constructor(public payload?: string[]) {}
}

export class NotificationError implements Action {
    public readonly type = ENotificationSActions.NOTIFICATIONS_ERROR;
    constructor(public payload: string | boolean) {}
}

export class NotificationsEmptyAction implements Action {
    public readonly type = ENotificationSActions.NOTIFICATIONS_EMPTY_ACTION;
}

export class NotificationsSetPopupShown implements Action {
    public readonly type = ENotificationSActions.NOTIFICATIONS_SET_POPUP_SHOWN;
    constructor(public payload: boolean) {}
}

export class NotificationsSetLastRead implements Action {
    public readonly type = ENotificationSActions.NOTIFICATIONS_SET_LAST_READ;
    constructor(public payload: boolean) {}
}

export type NotificationAction =
    | NotificationsGet
    | NotificationsGetSuccess
    | NotificationsMarkAsRead
    | NotificationError
    | NotificationsEmptyAction
    | NotificationsSetPopupShown
    | NotificationsSetLastRead;
