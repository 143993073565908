import { OrderTemplateEffects } from './order-template/effects';
import { WidgetsEffects } from './widgets/effects';

import { CartEffects } from '@hot-b2b/store/cart/effects';
import { CatalogEffects } from '@hot-b2b/store/catalog/effects';
import { FeedbackTopicsEffects } from '@hot-b2b/store/feedback/effects';
import { InsightsEffects } from '@hot-b2b/store/insights/effects';
import { CarouselsEffects } from '@hot-b2b/store/carousels/effects';
import { LoyaltyEffects } from '@hot-b2b/store/loyalty/effects';
import { NotificationEffects } from '@hot-b2b/store/notifications/effects';
import { OrdersEffects } from '@hot-b2b/store/orders/effects';
import { ProductsEffects } from '@hot-b2b/store/products/effects';
import { PromotionsEffects } from '@hot-b2b/store/promotions/effects';
import { SettingsEffects } from '@hot-b2b/store/settings/effects';
import { TutorialsEffects } from '@hot-b2b/store/tutorials/effects';
import { WarehousesEffects } from '@hot-b2b/store/warehouses/effects';
import { CmsImagesEffects } from './cms-images/effects';
import { AuthEffects } from './auth/effects';

export const rootEffects: any[] = [
    CartEffects,
    CatalogEffects,
    ProductsEffects,
    SettingsEffects,
    NotificationEffects,
    PromotionsEffects,
    OrdersEffects,
    OrderTemplateEffects,
    WarehousesEffects,
    InsightsEffects,
    CarouselsEffects,
    LoyaltyEffects,
    TutorialsEffects,
    FeedbackTopicsEffects,
    WidgetsEffects,
    CmsImagesEffects,
    AuthEffects,
];
