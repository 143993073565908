import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { CartItemsRemoveGifts } from '@hot-b2b/store/cart/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsToggleOnline } from '@hot-b2b/store/settings/actions';
import { settingsAppOnLine } from '@hot-b2b/store/settings/selector';
import { ConnectionService, LocalStorageService } from '@hot-libs/browser-specific';
import { ModalIds, StorageKeys } from '@hot-libs/shared-types';
import { CartService } from 'apps/hot-b2b/src/app/cart/services';
import { ValueAccessorProvider } from 'apps/hot-b2b/src/app/shared/internal';
import { FeaturesService, ModalService, StoreService } from 'apps/hot-b2b/src/app/shared/services';
import { InitDataManagerService } from 'apps/hot-b2b/src/app/shared/services/initdata-manager.service';
import { HotUserExtended } from '@hot-libs/shared-models';
import { authUser } from '@hot-b2b/store/auth/selector';
import { HotApiCartsService, HotApiOrdersService } from '@hot-theme-nx/generated-api';
import { offlineCatalogFetched } from '@hot-b2b/store/catalog/selector';
import { OfflineCatalogGet } from '@hot-b2b/store/catalog/actions';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'apps/hot-b2b/src/app/shared/services/configuration.service';

@Component({
    selector: 'hot-mode-switcher',
    templateUrl: './mode-switcher.component.html',
    providers: [ValueAccessorProvider(ModeSwitcherComponent)],
})
export class ModeSwitcherComponent {
    public settingsAppOnLine$: Observable<boolean>;

    public featurePwa: boolean = this.featuresService.Pwa;
    public authUser$: Observable<HotUserExtended>;
    public offlineCatalogPending$: Observable<boolean>;
    public offlineLoader: boolean = false;
    public isVnB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';
    public offlineMSG = {
        title: this.translateService.instant('shared.mode-switcher.offline-catalog-download-warning.downloading-title'),
        content: this.translateService.instant(
            'shared.mode-switcher.offline-catalog-download-warning.downloading-text'
        ),
        enableOK: false,
    };

    constructor(
        private readonly store: Store<AppState>,
        private readonly localStorageService: LocalStorageService,
        private readonly connectionService: ConnectionService,
        private readonly modalService: ModalService,
        private readonly storeService: StoreService,
        private readonly initDataManagerService: InitDataManagerService,
        private readonly cartService: CartService,
        public readonly featuresService: FeaturesService,
        private readonly apiOrdersService: HotApiOrdersService,
        private readonly apiCartsService: HotApiCartsService,
        private readonly translateService: TranslateService,
        private readonly configurationService: ConfigurationService
    ) {
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.authUser$ = this.store.pipe(select(authUser));
        this.offlineCatalogPending$ = this.store.pipe(select(offlineCatalogFetched));
    }

    public onChange(event): void {
        const status: boolean = !this.storeService.getSelector(settingsAppOnLine);

        const setMode = (lastActivity: number, modalAvailable: boolean): void => {
            this.localStorageService.setItem(StorageKeys.appMode, { online: status, lastActivity, modalAvailable });
        };

        if (status) {
            this.connectionService
                .monitor()
                .pipe(take(1))
                .subscribe((online: boolean) => {
                    this.onlineStatus(online, status, setMode);
                });
        } else {
            this.store.dispatch(new SettingsToggleOnline(status));
            setMode(Date.now(), true);
            this.store.dispatch(new CartItemsRemoveGifts());
            this.cartService.keepOfflineCartLineItems();
            this.cartService.calculateTaxesOffline();
        }
        if (event?.target && !event.target.checked) {
            this.store.dispatch(new OfflineCatalogGet());
            this.offlineCatalogPending$.pipe(take(2)).subscribe((offlineCatalogPending: boolean) => {
                if (offlineCatalogPending) {
                    this.offlineLoader = true;
                    this.offlineMSG = {
                        title: this.translateService.instant(
                            'shared.mode-switcher.offline-catalog-download-warning.downloaded-title'
                        ),
                        content: this.translateService.instant(
                            'shared.mode-switcher.offline-catalog-download-warning.downloaded-text'
                        ),
                        enableOK: true,
                    };
                } else {
                    this.offlineLoader = true;
                    this.offlineMSG = {
                        title: this.translateService.instant(
                            'shared.mode-switcher.offline-catalog-download-warning.downloading-title'
                        ),
                        content: this.translateService.instant(
                            'shared.mode-switcher.offline-catalog-download-warning.downloading-text'
                        ),
                        enableOK: false,
                    };
                }
            });
        } else {
            location.reload();
        }
    }
    public onlineStatus(online, status, setMode) {
        if (online) {
            this.store.dispatch(new SettingsToggleOnline(status));
            this.initDataManagerService.toOnline();
            const offlineOrders = JSON.parse(localStorage.getItem(StorageKeys.offlineOrderQueue));
            if (offlineOrders && offlineOrders.length > 0) {
                this.submitOffline(this.submitOfflineOrder());
            }
            setMode(null, false);
        } else {
            this.modalService.toggleModal(ModalIds.networkUnavailable, true);
        }
    }

    public submitOfflineOrder() {
        let outletId = null;
        this.authUser$.subscribe((userData: HotUserExtended) => {
            outletId = userData.outlet.id;
        });
        let offlineOrders = JSON.parse(localStorage.getItem(StorageKeys.offlineOrderQueue));
        let offlineArray = [];
        //selecting the current outlet orders
        if (offlineOrders !== null) {
            this.offlineOrder(offlineOrders, outletId, offlineArray);
            //selecting rest outlet orders
            this.selectRestOutletOrder(offlineArray, offlineOrders, outletId);
        }

        return offlineArray;
    }

    private offlineOrder(offlineOrders: any, outletId: any, offlineArray: any[]) {
        offlineOrders.forEach((element) => {
            if (element.outletId === outletId) {
                offlineArray.push(element);
            }
        });
    }

    private selectRestOutletOrder(offlineArray: any[], offlineOrders: any, outletId: any) {
        if (offlineArray.length < 5) {
            offlineOrders.forEach((element) => {
                if (offlineArray.length < 5 && element.outletId !== outletId) {
                    offlineArray.push(element);
                }
            });
        }
    }

    public submitOffline(data) {
        let offlineOrderItems = JSON.parse(localStorage.getItem(StorageKeys.offlineOrderQueue));
        let index;
        let failedOrders = [];
        ///getting the offline failed orders
        let offlineOrderErrorItems = JSON.parse(localStorage.getItem(StorageKeys.offlineFailedOrderQueue));
        if (offlineOrderErrorItems !== null) {
            offlineOrderErrorItems.forEach((element) => {
                failedOrders.push(element);
            });
        }
        if (offlineOrderItems !== null) {
            this.apiCartsService.processOrderOffline(data).subscribe((response: any) => {
                //pushing the failed order in an array
                this.pushFailedOrder(response, failedOrders);

                localStorage.setItem(StorageKeys.offlineFailedOrderQueue, JSON.stringify(failedOrders));

                // removing the order which has been sent to backend
                data.forEach((element1) => {
                    index = offlineOrderItems.findIndex((element) => {
                        if (element.outletId === element1.outletId) {
                            return true;
                        }
                    });
                    offlineOrderItems.splice(index, 1);
                });
                //storing the remaining orders in cache
                localStorage.setItem(StorageKeys.offlineOrderQueue, JSON.stringify(offlineOrderItems));
                const length = JSON.parse(localStorage.getItem(StorageKeys.offlineOrderQueue)).length;
                if (length > 0) {
                    this.submitOffline(this.submitOfflineOrder());
                }
            });
        }
    }

    private pushFailedOrder(response: any, failedOrders: any[]) {
        response.forEach((element) => {
            if (element.succeeded === false) {
                failedOrders.push(element);
            }
        });
    }

    public getTrackData(state) {
        let buttonText = state ? 'Online' : 'Offline';
        return `{ "data_interaction": "button_click", "button_text": "${buttonText}", "name":"Settings" }`;
    }
    closemodal() {
        this.modalService.toggleModal('offline-message', false);
        this.offlineLoader = false;
    }
}
