/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotOutletRegistrationResult } from '../models/hot-outlet-registration-result';
import { HotAnonymousUserOutletRegistrationRequest } from '../models/hot-anonymous-user-outlet-registration-request';
import { HotGroupAccountOutletRegistrationRequest } from '../models/hot-group-account-outlet-registration-request';
import { HotOutletSimplifiedRegistrationResult } from '../models/hot-outlet-simplified-registration-result';
import { HotOutletSimplifiedRegistrationRequest } from '../models/hot-outlet-simplified-registration-request';
import { HotOutletSimplifiedRegistrationConfirmationRequest } from '../models/hot-outlet-simplified-registration-confirmation-request';
@Injectable({
  providedIn: 'root',
})
class HotApiOutletRegistrationService extends __BaseService {
  static readonly createAnonymousUserRequestPath = '/storefrontapi/hot/outlet-registration/anonymous-user';
  static readonly createGroupAccountRequestPath = '/storefrontapi/hot/outlet-registration/group-account';
  static readonly createSalesRepresentativeRequestPath = '/storefrontapi/hot/outlet-registration/sales-representative';
  static readonly createSimplifiedRegistrationRequestPath = '/storefrontapi/hot/outlet-registration/simplified';
  static readonly confirmSimplifiedRegistrationRequestPath = '/storefrontapi/hot/outlet-registration/simplified/confirm';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  createAnonymousUserRequestResponse(body?: HotAnonymousUserOutletRegistrationRequest): __Observable<__StrictHttpResponse<HotOutletRegistrationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/outlet-registration/anonymous-user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOutletRegistrationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  createAnonymousUserRequest(body?: HotAnonymousUserOutletRegistrationRequest): __Observable<HotOutletRegistrationResult> {
    return this.createAnonymousUserRequestResponse(body).pipe(
      __map(_r => _r.body as HotOutletRegistrationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  createGroupAccountRequestResponse(body?: HotGroupAccountOutletRegistrationRequest): __Observable<__StrictHttpResponse<HotOutletRegistrationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/outlet-registration/group-account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOutletRegistrationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  createGroupAccountRequest(body?: HotGroupAccountOutletRegistrationRequest): __Observable<HotOutletRegistrationResult> {
    return this.createGroupAccountRequestResponse(body).pipe(
      __map(_r => _r.body as HotOutletRegistrationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  createSalesRepresentativeRequestResponse(body?: HotGroupAccountOutletRegistrationRequest): __Observable<__StrictHttpResponse<HotOutletRegistrationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/outlet-registration/sales-representative`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOutletRegistrationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  createSalesRepresentativeRequest(body?: HotGroupAccountOutletRegistrationRequest): __Observable<HotOutletRegistrationResult> {
    return this.createSalesRepresentativeRequestResponse(body).pipe(
      __map(_r => _r.body as HotOutletRegistrationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  createSimplifiedRegistrationRequestResponse(body?: HotOutletSimplifiedRegistrationRequest): __Observable<__StrictHttpResponse<HotOutletSimplifiedRegistrationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/outlet-registration/simplified`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOutletSimplifiedRegistrationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  createSimplifiedRegistrationRequest(body?: HotOutletSimplifiedRegistrationRequest): __Observable<HotOutletSimplifiedRegistrationResult> {
    return this.createSimplifiedRegistrationRequestResponse(body).pipe(
      __map(_r => _r.body as HotOutletSimplifiedRegistrationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  confirmSimplifiedRegistrationRequestResponse(body?: HotOutletSimplifiedRegistrationConfirmationRequest): __Observable<__StrictHttpResponse<HotOutletSimplifiedRegistrationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/outlet-registration/simplified/confirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOutletSimplifiedRegistrationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  confirmSimplifiedRegistrationRequest(body?: HotOutletSimplifiedRegistrationConfirmationRequest): __Observable<HotOutletSimplifiedRegistrationResult> {
    return this.confirmSimplifiedRegistrationRequestResponse(body).pipe(
      __map(_r => _r.body as HotOutletSimplifiedRegistrationResult)
    );
  }
}

module HotApiOutletRegistrationService {
}

export { HotApiOutletRegistrationService }
