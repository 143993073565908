import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { HotApiFeedbackService } from '@hot-theme-nx/generated-api';
import { AppState } from '@hot-b2b/store/reducers';
import { feedbackTopics } from '@hot-b2b/store/feedback/selectors';
import { HotSelectComponent } from 'apps/hot-b2b/src/app/shared/components/select/select.component';
import { HotCheckboxComponent } from 'apps/hot-b2b/src/app/shared/components/checkbox/checkbox.component';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { FeedbackTopicsGet } from '@hot-b2b/store/feedback/actions';


@Component({
    selector: 'hot-contact',
    templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {
    @ViewChild(HotSelectComponent) viewChildSelect: HotSelectComponent;
    @ViewChild(HotCheckboxComponent) viewChildCheckbox: HotCheckboxComponent;

    @Input() pageName = '';

    public topics$: Observable<string[]>;
    public feedbackForm: UntypedFormGroup;
    public showThankYouMessage: boolean;
    public deleteAccount: boolean = false;

    constructor(
        private readonly store: Store<AppState>,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly translateService: TranslateService,
        private readonly analyticService: AnalyticService,
        private readonly hotApiFeedbackService: HotApiFeedbackService
    ) {
        this.store.dispatch(new FeedbackTopicsGet());
        this.topics$ = this.store.pipe(select(feedbackTopics));
    }

    public ngOnInit(): void {
        this.feedbackForm = this.formBuilder.group({
            subject: [null, Validators.required],
            message: [null],
        });
        this.topics$.subscribe((items) => {
            let name = this.translateService.instant('shared.feedback.account-deletion');
            let exists = items.includes(name);
            if (!exists) {
                items.push(name);
            }
        });
    }

    public onSubmit(element: HTMLElement): void {
        if (this.feedbackForm.invalid) {
            this.analyticService.sendFormSubmitInfo(this.feedbackForm, 'contact');
            return;
        }
        this.analyticService.sendFormSubmitInfo(this.feedbackForm, 'contact');

        this.feedbackForm.controls['subject'].setErrors({ incorrect: true });

        this.hotApiFeedbackService
            .addFeedback({
                topic: this.feedbackForm.controls.subject.value,
                message: this.feedbackForm.controls.message.value,
                deleteAccount: this.deleteAccount,
            })
            .pipe(withLatestFrom(this.translateService.get('shared.feedback.subject-placeholder')))
            .subscribe(([, subjectPlaceholder]: [null, string]) => {
                this.showThankYouMessage = true;
                this.resetForm(subjectPlaceholder);
                this.scrollTo(element);
            });
    }

    public resetForm(subjectPlaceholder: string) {
        this.feedbackForm.reset();
        this.viewChildSelect.writeValue(subjectPlaceholder);
        this.viewChildCheckbox.writeValue(false);
        this.feedbackForm.markAsPristine();
        this.feedbackForm.markAsUntouched();
    }

    public scrollTo(element: HTMLElement): void {
        setTimeout(() => {
            const yOffset = -50;
            const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }, 100);
    }

    public getSubjectTrackData() {
        let text = this.feedbackForm.controls.subject.value ? this.feedbackForm.controls.subject.value : 'Subject';
        return `{"data_interaction":"Dropdown_click","button_text":"${text}","name":"${this.pageName}"}`;
    }

    public getMessageTrackData() {
        let text = this.feedbackForm.controls.message.value ? this.feedbackForm.controls.message.value : 'Message';
        return `{"data_interaction":"Input_click","button_text":"${text}","name":"${this.pageName}"}`;
    }
    public getSubject(item: string) {
        if (item === this.translateService.instant('shared.feedback.account-deletion')) {
            this.deleteAccount = true;
            this.feedbackForm.get('message').clearValidators();
            this.feedbackForm.get('message').updateValueAndValidity();
        } else {
            this.deleteAccount = false;
            this.feedbackForm.get('message').setValidators([Validators.required]);
            this.feedbackForm.get('message').updateValueAndValidity();
        }
    }
}
