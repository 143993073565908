import { Injectable } from '@angular/core';
import { take, takeWhile, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, timer, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../../account/services/user.service';
import { HotSecurityResponse } from '@hot-theme-nx/generated-api';
import Rabbit from 'crypto-js/rabbit';
import Utf8 from 'crypto-js/enc-utf8';
@Injectable({
    providedIn: 'root',
})
export class PasswordTimerService {
    public remainingMinutes: number = 0;
    public remainingMinutesObservable = new BehaviorSubject<number>(0);
    public countDown$: Observable<number>;
    public isVerificationAttempts: boolean = true;
    public subscription: Subscription;
    private stop$ = new Subject<void>();
    public otpExpired = new Subject<boolean>();
    private timerSubscription: Subscription;
    private isExpired = false;
    private isPageVisible: boolean = true;
    public callFromPage: string;
    private readonly pageNameData = new BehaviorSubject<any>(null);
    private encryptSecretKey: string = '273c4ecf9ec2b388837a1d382e271d64';

    constructor(public readonly router: Router, private readonly userService: UserService) {
        this.setupVisibilityChange();
    }

    setupVisibilityChange() {
        document.addEventListener('visibilitychange', () => {
            this.isPageVisible = !document.hidden;
            const currentTime = new Date().getTime();
            const storedTime = localStorage.getItem('nextPossibleGenerationAttempt');
            const attempsLeft = localStorage.getItem('OTPGenerationAttemptLeft');
            const stopTime = new Date(storedTime).getTime();
            if (this.isPageVisible && storedTime && attempsLeft == '0') {
                if (currentTime < stopTime) {
                    this.blockingCustomer(storedTime);
                } else {
                    this.stoptheTimer();
                    this.isExpired = false;
                    this.redirectionMethod(this.callFromPage);
                }
            }
        });
    }

    public blockingCustomer(nextDate: string) {
        this.getApiDateTime(nextDate).subscribe((apiDateTime) => {
            const now = new Date();
            const differenceInMinutes = Math.ceil(Math.abs(apiDateTime.getTime() - now.getTime()) / (1000 * 60));
            this.remainingMinutes = differenceInMinutes;
            this.startCountdown(this.remainingMinutes);
        });
    }

    public getApiDateTime(dateNext: string): any {
        return timer(1000).pipe(
            map(() => new Date(dateNext)),
            take(1)
        );
    }

    public startCountdown(minutes: number): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.countDown$ = timer(0, 1000).pipe(
            map((seconds) => minutes - seconds / 60),
            takeWhile((remaining) => remaining >= 0),
            takeUntil(this.stop$)
        );

        this.subscription = this.countDown$.subscribe((remainingMinutes) => {
            this.remainingMinutes = Math.ceil(remainingMinutes);
            this.remainingMinutesObservable.next(this.remainingMinutes);
            if (this.remainingMinutes == 0) {
                localStorage.setItem('resendButton', true.toString());
                localStorage.removeItem('nextPossibleGenerationAttempt');
                this.remainingMinutesObservable.next(0);
                this.isExpired = false;
                this.redirectionMethod(this.callFromPage);
            }
        });
    }

    private redirectionMethod(callFromPage: string) {
        if (callFromPage == 'forgot-password') {
            this.router.navigate(['/account/forgot-password']);
        } else {
            this.router.navigate(['/account/sign-in']);
        }
    }

    getTimerValue(): Observable<number> {
        return this.remainingMinutesObservable.asObservable();
    }

    stoptheTimer(): void {
        this.stop$.next();
        this.remainingMinutes = 0;
        this.remainingMinutesObservable.next(this.remainingMinutes);
    }

    calculateOtpExpiry(otpValidity) {
        this.isExpired = false;
        this.otpExpired.next(false);
        otpValidity = new Date(otpValidity);
        const currentTime = new Date().getTime();
        const timeDifference = otpValidity.getTime() - currentTime;

        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }

        this.timerSubscription = timer(timeDifference).subscribe(() => {
            this.otpExpired.next(true);
            this.isExpired = true;
            this.timerSubscription.unsubscribe();
        });
    }

    getOtpExpirationNotification(): boolean {
        return this.isExpired;
    }

    enableSMSOption(response: HotSecurityResponse, userName: string, pageCall: string) {
        if (response.succeeded && response.statusMessage == 'OTP_Generated_Successfully') {
            localStorage.removeItem('resendButton');
            localStorage.removeItem('nextPossibleGenerationAttempt');
            localStorage.removeItem('OTPGenerationAttemptLeft');
            this.calculateOtpExpiry(response.otpValidity);
        }

        if (response.errors.length == 0 && (response.phoneNumber == undefined || response.phoneNumber.length == 0)) {
            this.router.navigateByUrl('/account/forgot-password/missing-contact');
        } else {
            if (response.errors[0] == 'Invalid_Generation_OTP_Attempt') {
                localStorage.setItem('resendButton', 'false');
                localStorage.setItem('nextPossibleGenerationAttempt', response.nextPossibleGenerationAttempt);
            }
            let OTPGenerationAttemptsLeft = response.totalGenerationAttemptsLeft;
            this.router.navigate(['/account/forgot-password/otp-verification'], {
                queryParams: {
                    userName: userName,
                    OTPGenerationAttemptsLeft: OTPGenerationAttemptsLeft,
                    pageName: pageCall,
                    data: this.encryptKeyRabbit(response.emailId) + '_' + response.phoneNumber,
                },
            });
        }
    }

    enableEmailOption(response, username: string, pageCall: string) {
        if (response.succeeded && response.statusMessage == 'OTP_Generated_Successfully') {
            localStorage.removeItem('resendButton');
            localStorage.removeItem('nextPossibleGenerationAttempt');
            localStorage.removeItem('OTPGenerationAttemptLeft');
            this.calculateOtpExpiry(response.otpValidity);
        }

        if (response.errors.length == 0 && (response.emailId == undefined || response.emailId.length == 0)) {
            this.router.navigateByUrl('/account/forgot-password/missing-contact');
        } else {
            if (response.errors[0] == 'Invalid_Generation_OTP_Attempt') {
                localStorage.setItem('resendButton', 'false');
                localStorage.setItem('nextPossibleGenerationAttempt', response.nextPossibleGenerationAttempt);
            }
            let OTPGenerationAttemptsLeft = response.totalGenerationAttemptsLeft;
            this.router.navigate(['/account/forgot-password/otp-verification'], {
                queryParams: {
                    userName: username,
                    OTPGenerationAttemptsLeft: OTPGenerationAttemptsLeft,
                    pageName: pageCall,
                    data: this.encryptKeyRabbit(response.emailId) + '_' + response.phoneNumber,
                },
            });
        }
    }

    enableSMSandEmailOption(response: HotSecurityResponse, username: string, pageCall: string) {
        if (response.succeeded) {
            localStorage.removeItem('resendButton');
            localStorage.removeItem('nextPossibleGenerationAttempt');
            localStorage.removeItem('OTPGenerationAttemptLeft');
        }
        this.subFunctionForRedirection(response, username, pageCall);
    }

    private subFunctionForRedirection(response: HotSecurityResponse, username: string, pageCall: string) {
        if (response.errors.length == 0 &&
            (response.phoneNumber == undefined || response.phoneNumber.length == 0) &&
            (response.emailId == undefined || response.emailId.length == 0)) {
            this.router.navigateByUrl('/account/forgot-password/missing-contact');
        } else {
            this.innerFunctionForBothredirection(response, username, pageCall);
        }
    }

    private innerFunctionForBothredirection(response: HotSecurityResponse, username: string, pageCall: string) {
        if (response.errors[0] == 'Invalid_Generation_OTP_Attempt') {
            localStorage.setItem('resendButton', 'false');
            localStorage.setItem('nextPossibleGenerationAttempt', response.nextPossibleGenerationAttempt);
        }
        let OTPGenerationAttemptsLeft = response.totalGenerationAttemptsLeft;
        let encryptEmail = this.encryptKeyRabbit(response.emailId);

        let redirectionEmail = encryptEmail;

        if (response.emailId == undefined || response.emailId == '') {
            redirectionEmail = '';
        }

        let redirectionPhoneNumber = response.phoneNumber;

        if (response.phoneNumber == undefined || response.phoneNumber == '') {
            redirectionPhoneNumber = '';
        }

        this.router.navigate(['/account/forgot-password/verify-identity'], {
            queryParams: {
                userName: username,
                OTPGenerationAttemptsLeft: OTPGenerationAttemptsLeft,
                pageName: pageCall,
                data: redirectionEmail + '_' + redirectionPhoneNumber,
            },
        });
    }

    partiallyHideEmail(email) {
        if (email !== undefined && email.length > 0) {
            const parts = email.split('@');
            let userName = parts[0];
            const domain = parts[1];
            const userNameLength = userName.length;
            const hiddenUserName = userName.substring(0, 2) + '*'.repeat(userNameLength - 2);
            const hiddenDomainName = domain.substring(0, 2) + '*'.repeat(domain.length - 2);
            return `${hiddenUserName}@${hiddenDomainName}`;
        }
    }

    partiallyHidePhone(phone) {
        if (phone !== undefined && phone.length > 0) {
            const visibleDigit = 2;
            const hiddenDigit = phone.length - visibleDigit;
            const maskedSection = '*'.repeat(hiddenDigit);
            const visibleSection = phone.slice(hiddenDigit);
            return maskedSection + visibleSection;
        }
    }

    private decryptKeyRabbit(encryptedData: string) {
        try {
            const decrypted = Rabbit.decrypt(encryptedData, Utf8.parse(this.encryptSecretKey));
            return decrypted.toString(Utf8);
        } catch (e) {
            return null;
        }
    }

    private encryptKeyRabbit(data) {
        try {
            return Rabbit.encrypt(data, Utf8.parse(this.encryptSecretKey));
        } catch (e) {
            console.log(e);
        }
    }
}
