import { HotHomePageWidgetsWithLanguage } from '@hot-theme-nx/generated-api';

import { HotCartLineItemExtended } from '../../shared/models/cart.model';
import { editQuantity } from '../products/helpers';

import find from 'lodash/find';

const getWidgetsForCurrentLanguage = (
    widgets: HotHomePageWidgetsWithLanguage[],
    currentLanguageCode: string
): HotHomePageWidgetsWithLanguage => find(widgets, { language: currentLanguageCode });

const getPopularProductsWidget = (widgets: HotHomePageWidgetsWithLanguage[]): any => {
    const widgetsWithLanguage = widgets[0];

    return find(widgetsWithLanguage.widgets, { type: 'HotPopularProductsWidget' });
};

const syncQuantityWithCart = (
    widgets: HotHomePageWidgetsWithLanguage[],
    cartItems: HotCartLineItemExtended[]
): HotHomePageWidgetsWithLanguage[] => {
    const popularProductsWidget = getPopularProductsWidget(widgets);

    if (popularProductsWidget?.products) {
        popularProductsWidget.products = editQuantity(popularProductsWidget.products, cartItems);

        widgets[0].widgets.forEach((widget) => {
            if (widget.type === 'HotPopularProductsWidget') {
                widget.products = popularProductsWidget.products;
            }
            return widget;
        });
    }

    return widgets;
};

export { getWidgetsForCurrentLanguage, getPopularProductsWidget, syncQuantityWithCart };
