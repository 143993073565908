import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '@hot-b2b/store/reducers';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HotUserExtended } from '@hot-libs/shared-models';
import { authUser } from '@hot-b2b/store/auth/selector';
import { tutorialsData } from '@hot-b2b/store/tutorials/selector';
import { HotTutorial } from '@hot-theme-nx/generated-api';
import { ConfigurationService, ModalService } from '../../../services';
import { ModalIds, PageUrls, StorageKeys } from '@hot-libs/shared-types';
import { TutorialShowTutorialWithIdAction } from '@hot-b2b/store/tutorials/actions';

interface ITutorialCompleted {
    id?: string;
    completedDate?: Date;
}

@Component({
    selector: 'hot-tutorials-modal',
    templateUrl: './tutorials-modal.component.html',
})
export class TutorialsModalComponent {
    public authUser$: Observable<HotUserExtended>;
    public allTutorials$: Observable<HotTutorial[]>;

    public isHop = () => this.configurationService.getCurrentConfiguration() === 'HOP';

    constructor(
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly configurationService: ConfigurationService
    ) {
        this.authUser$ = this.store.pipe(select(authUser));
        this.allTutorials$ = this.store.pipe(select(tutorialsData));
    }

    private getLocalStorageTutorials(): ITutorialCompleted[] {
        const tutorials = localStorage.getItem(StorageKeys.tutorials);
        if (tutorials) {
            return JSON.parse(tutorials) as ITutorialCompleted[];
        }

        return [];
    }

    public setIncompletedTutorial(tutorialId: string, tutorialPage: string) {
        let completedTutorials = this.getLocalStorageTutorials();

        completedTutorials = completedTutorials.filter(function (obj) {
            return obj.id !== tutorialId;
        });

        localStorage.setItem(StorageKeys.tutorials, JSON.stringify(completedTutorials));

        this.modalService.toggleModal(ModalIds.tutorials, false);

        let tutorialUrl = PageUrls[tutorialPage];
        let currentUrl = this.router.url;
        let redirectPage = currentUrl == PageUrls.home ? PageUrls['previous-orders'] : PageUrls.home;
        this.store.dispatch(new TutorialShowTutorialWithIdAction(tutorialId));
        if (this.isHop() && PageUrls[tutorialPage] == PageUrls.home) {
            this.router.navigateByUrl(redirectPage).then(() => {
                this.router.navigateByUrl(PageUrls.ordering);
            });
        } else {
            this.router.navigateByUrl(tutorialUrl).then(() => {
                if (currentUrl == tutorialUrl)
                    this.router.navigateByUrl(redirectPage).then(() => {
                        this.router.navigateByUrl(tutorialUrl);
                    });
            });
        }
    }
}
