export enum ENotificationSActions {
    NOTIFICATIONS_PENDING = 'NOTIFICATIONS_PENDING',
    NOTIFICATIONS_FETCHED = 'NOTIFICATIONS_FETCHED',
    NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE',
    NOTIFICATIONS_MARK_AS_READ = 'NOTIFICATIONS_MARK_AS_READ',
    NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR',
    NOTIFICATIONS_EMPTY_ACTION = 'NOTIFICATIONS_EMPTY_ACTION',
    NOTIFICATIONS_SET_POPUP_SHOWN = 'NOTIFICATIONS_SET_POPUP_SHOWN',
    NOTIFICATIONS_SET_LAST_READ = 'NOTIFICATIONS_SET_LAST_READ',
}
