import { AuthActions } from '@hot-b2b/store/auth/actions';
import { localizeOutletAddressCountryName } from '@hot-b2b/store/auth/helpers';
import { AuthState } from '@hot-b2b/store/auth/model';
import { authInitialState } from '@hot-b2b/store/auth/state';
import { EAuthActions } from '@hot-b2b/store/auth/types';

export function authReducer(state = authInitialState, action: AuthActions): AuthState {
    switch (action.type) {
        case EAuthActions.AUTH_PENDING: {
            return {
                ...state,
                fetched: false,
                pending: true,
            };
        }
        case EAuthActions.AUTH_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload,
            };
        }
        case EAuthActions.AUTH_ERROR: {
            return {
                ...state,
                fetched: false,
                pending: false,
                error: action.payload,
            };
        }
        case EAuthActions.AUTH_USER_LOGIN_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    user: localizeOutletAddressCountryName(action.payload.user),
                    loggedIn: true,
                    loadedPage: '',
                    homePageUrl: action.payload.homePageUrl
                },
            };
        }
        case EAuthActions.AUTH_USER_EDIT: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    user: localizeOutletAddressCountryName(action.payload),
                    loggedIn: state.data.loggedIn,
                    loadedPage: state.data.loadedPage,
                    homePageUrl: state.data.homePageUrl
                },
            };
        }
        case EAuthActions.AUTH_USER_LOGOUT: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    user: null,
                    loggedIn: false,
                    loadedPage: '',
                    homePageUrl: ''
                },
            };
        }
        case EAuthActions.AUTH_USER_LOADED_PAGE: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    user: state.data.user,
                    loggedIn: state.data.loggedIn,
                    loadedPage: action.payload,
                    homePageUrl: state.data.homePageUrl
                },
            };
        }
        case EAuthActions.AUTH_USER_GET_CHILD_CONTACTS_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        childContactsSearchResult: action.payload,
                    },
                },
            };
        }
        case EAuthActions.AUTH_USER_UPDATE_TUTORIALHIDE_FLAG: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        contact: {
                            ...state.data.user.contact,
                            isTutorialHide: action.payload,
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
}
