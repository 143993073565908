import { Component, Input, OnInit } from '@angular/core';
import { StorageKeys } from '@hot-libs/shared-types';
import { LoaderService } from 'apps/hot-b2b/src/app/shared/services/loader.service';

@Component({
    selector: 'hot-theme-nx-payment-process-modal',
    templateUrl: './payment-process-modal.component.html',
})
export class PaymentProcessModalComponent implements OnInit {
    @Input() public stopLoading: boolean;
    public selectedPaymentMode: string = '';

    constructor (private readonly loaderService: LoaderService) {

    }
    ngOnInit(): void {
        this.loaderService.hide();
        this.selectedPaymentMode = JSON.parse(localStorage.getItem(StorageKeys.lastUsedPaymentMethod)).name;
    }
}
