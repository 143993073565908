import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'absoluteValue',
})
export class AbsoluteValue implements PipeTransform {
    transform(value: number, negative: boolean = false): number {
        if (negative) {
            return -Math.abs(value);
        }
        return Math.abs(value);
    }
}