import { Component, Input,OnInit} from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import size from 'lodash/size';

@Component({
    selector: 'hot-input-errors',
    templateUrl: './input-errors.component.html',
})
export class HotInputErrorsComponent implements OnInit {
    public customErrorMessage: string;
    public errors: ValidationErrors;
    public multiErrors = () => size(this.formController.errors) > 1;

    @Input() public formController: AbstractControl;
    @Input() public patternErrorText: string;
    @Input() public patternErrors: Array<string> = [];

    ngOnInit(): void {
        this.errors = this.formController?.errors;
        const formControlName = this.getFormControlName(this.formController);
        this.generateErrorMessage(formControlName);
    }

    private getFormControlName(formControl: AbstractControl): string | null {
        const formGroup = formControl.parent.controls;
        return Object.keys(formGroup).find((name) => formControl === formGroup[name]) || null;
    }

    private generateErrorMessage(controlName: string): void {
        switch (controlName) {
            case 'userName':
                this.customErrorMessage = 'shared.input.user-name-required';

                break;

            case 'password':
                this.customErrorMessage = 'shared.input.password-required';

                break;

            default:
                this.customErrorMessage = 'shared.input.required';

                break;
        }
    }
}
