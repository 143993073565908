import { createSelector, MemoizedSelector } from '@ngrx/store';

import { HotLoyaltyBanner, HotLoyaltyStatement, HotRedemptionWindowStatus } from '@hot-theme-nx/generated-api';

import { LoyaltyState } from '@hot-b2b/store/loyalty/model';
import { AppState } from '@hot-b2b/store/reducers';

const stateLoyalty = (state: AppState): LoyaltyState => state.loyalty;



export const loyalty: MemoizedSelector<AppState, HotLoyaltyStatement> = createSelector(
    stateLoyalty,
    (state: LoyaltyState) => state.data.loyaltyStatement

);

export const loyaltyError: MemoizedSelector<AppState, HotLoyaltyStatement> = createSelector(
    stateLoyalty,
    (state: LoyaltyState) => state.error
);

export const redemptionWindowStatus: MemoizedSelector<AppState, HotRedemptionWindowStatus> = createSelector(
    stateLoyalty,
    (state: LoyaltyState) => state.data.redemptionWindowStatus
);

export const loyaltyBanners: MemoizedSelector<AppState, { [key: string]: HotLoyaltyBanner }> = createSelector(
    stateLoyalty,
    (state: LoyaltyState) => state.data.banners
);

export const loyaltyPending: MemoizedSelector<AppState, boolean> = createSelector(
    stateLoyalty,
    (state: LoyaltyState) => state.pending
);

export const contractStatus: MemoizedSelector<AppState, string> = createSelector(stateLoyalty, (state: LoyaltyState) =>
    state.data.loyaltyStatement ? state.data.loyaltyStatement.contractStatus : null
);
