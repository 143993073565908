import { Component, Input } from '@angular/core';

@Component({
    selector: 'hot-volume-progress',
    templateUrl: './volume-progress.component.html',
})
export class HotVolumeProgress {
    @Input() mainTitle: string;
    @Input() subTitle: string;
    @Input() value = 0;
    @Input() min = 200;
    @Input() max = 250;
    @Input() highlight = false;

    get currentActualPos(): number {
        return (this.value * 100) / this.max;
    }

    get currentActualPosByMin(): number {
        return (this.value * 80) / this.min;
    }

    get minPos(): number {
        return 80;

    }

    get isEqualMin(): boolean {
        return this.value === this.min ? true : false;
    }

    get isLowerOrEqualMin(): boolean {
        return this.value <= this.min ? true : false;
    }

    get isMinReach(): boolean {
        return this.value >= this.min ? true : false;
    }

    get isMaxReach(): boolean {
        return this.currentActualPos >= 100 ? true : false;
    }

    get isOverMax(): boolean {
        return this.currentActualPos > 100 ? true : false;
    }

    get minMaxStep() {
        return 20/ (this.max - this.min);
    }

    get overMinLowerMaxPos() {
        return (this.value - this.min) * this.minMaxStep;
    }

    get currentPos(): number {
        if (this.isLowerOrEqualMin) {
            return this.currentActualPosByMin;
        }

        if (!this.isMaxReach) {
            return 80 + this.overMinLowerMaxPos;
        }

        return 100;
    }
}
