import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { NotificationMessage, HotPromotion, HotInsight, HotFulfillmentCenter } from '@hot-theme-nx/generated-api';

import { FeaturesService } from '../../services';

import { authUserType } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsStore, settingsData } from '@hot-b2b/store/settings/selector';
import { NotificationListState, SettingsStoreModel } from '@hot-libs/shared-models';
import { AccountType, NotificationType, OrderStatus, StorageKeys } from '@hot-libs/shared-types';
import { TriggerService } from 'apps/hot-b2b/src/app/shared/services/trigger.service';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { visiblePromotions } from '@hot-b2b/store/promotions/selector';
import { insights } from '@hot-b2b/store/insights/selector';
import { PromotionsGet, PromotionsGetFromCenter } from '@hot-b2b/store/promotions/actions';
import { InsightsGet, InsightsGetFromCenter } from '@hot-b2b/store/insights/actions';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
@Component({
    selector: 'hot-notification-list',
    templateUrl: './notification-list.component.html',
})
export class NotificationListComponent implements OnInit, OnChanges {
    @Input() public notifications: NotificationMessage[];
    @Input() public isCompactMode: boolean;
    @Input() public dateFormat: string;
    @Input() public locale: string;

    @Output() public toggle: EventEmitter<NotificationListState> = new EventEmitter<NotificationListState>();
    public distanceAway = null;
    private readonly messageTypesForNavigation: string[] = [
        NotificationType.ORDERSTATUSCHANGED,
        NotificationType.ORDERCANCELEDBYCUSTOMER,
        NotificationType.ORDERCANCELEDBYDISTRIBUTOR,
        NotificationType.ORDERCREATEDDISTRIBUTOR,
        NotificationType.ORDERCANCELEDBYCUSTOMERDISTRIBUTOR,
        NotificationType.ORDERPAIDEVENT,
        NotificationType.PROMOTION,
        NotificationType.INSIGHT,
        NotificationType.GPSADDEVENT,
        NotificationType.GPSCHANGEDEVENT,
    ];

    private readonly distributorNewOrderStatuses: string[] = [
        OrderStatus.Received,
        OrderStatus.SentToDistributor,
        OrderStatus.Confirmed,
        OrderStatus.Cancelled,
    ];

    private readonly distributorConfirmedOrderStatuses: string[] = [
        OrderStatus.OnTheWay,
        OrderStatus.Delivered,
        OrderStatus.ConfirmedDelivery,
    ];

    public readonly authUserType$: Observable<string>;

    private isNewOrderPageAvailable: boolean;
    private isConfirmedOrderPageAvailable: boolean;
    public featureEnableGPS: boolean = this.featuresService.EnableGPSTracking;
    private promotions$: Observable<HotPromotion[]>;
    private insights$: Observable<HotInsight[]>;
    private readonly settings$: Observable<HotSettingsExtended>;
    private navigateToPromotionAndInsightDetail: boolean = false;
    public storeIsRequiredForOutlet: boolean;
    public promoInsightNotifications: NotificationMessage[];
    constructor(
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly triggerService: TriggerService,
        private readonly featuresService: FeaturesService,
        private readonly analyticService: AnalyticService
    ) {
        this.authUserType$ = this.store.pipe(select(authUserType));
        this.promotions$ = this.store.pipe(select(visiblePromotions));
        this.insights$ = this.store.pipe(select(insights));
        this.settings$ = this.store.pipe(select(settingsData));
    }

    public ngOnInit(): void {
        this.store
            .pipe(select(settingsStore), take(1))
            .subscribe(
                (settings: SettingsStoreModel) =>
                    (this.isNewOrderPageAvailable = settings.showNewOrdersOnDistributorPortal)
            );
        this.storeIsRequiredForOutlet = this.featuresService.StoreIsRequiredForOutlet;
        if (!this.storeIsRequiredForOutlet) {
            let ffc: string | null = localStorage.getItem(StorageKeys.fulfillmentCenter);
            if (ffc) {
                const selectedFulfillmentCenter: HotFulfillmentCenter = JSON.parse(ffc);
                if (selectedFulfillmentCenter) {
                    this.store.dispatch(new PromotionsGetFromCenter(selectedFulfillmentCenter.id));
                    this.store.dispatch(new InsightsGetFromCenter(selectedFulfillmentCenter.id));
                }
            }
        } else {
            this.store.dispatch(new PromotionsGet());
            this.store.dispatch(new InsightsGet());
        }
        this.isConfirmedOrderPageAvailable = this.featuresService.ConfirmedOrdersPageForDistributor;
        this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
            this.navigateToPromotionAndInsightDetail = settings.navigateToPromotionAndInsightDetail;
        });
    }

    public ngOnChanges(): void {
        this.isPromoInsight();
    }

    public hasNewNotifications(): boolean {
        return this.notifications.some((notification: NotificationMessage) => !notification.isRead);
    }

    public navigateBy(notification: NotificationMessage): void {
        this.authUserType$.pipe(take(1)).subscribe((userType: string) => {
            if (this.messageTypesForNavigation.includes(notification.messageType)) {
                this.toggleNotificationList({ isOpened: false, isCompact: this.isCompactMode });

                if (this.currentUserIsCustomer(userType)) {
                    this.navigateToOrderAsCustomer(notification);
                } else if (this.currentUserIsDistributor(userType)) {
                    this.navigateToOrderAsDistributor(notification);
                }
            }
        });
    }

    public isPromoInsight() {
        this.promoInsightNotifications = [];
        this.notifications.forEach((notification: NotificationMessage) => {
            if (notification.messageType === NotificationType.PROMOTION) {
                this.promotions$.pipe(take(1)).subscribe((promotions: HotPromotion[]) => {
                    const index = promotions.findIndex(
                        (promotion: HotPromotion) => promotion.id === notification.notificationData
                    );
                    if (index !== -1) {
                        notification['code'] = promotions[index]?.code;
                        notification['imgUrl'] =
                            promotions[index]?.mobileBannerUrl || promotions[index]?.desktopBannerUrl;
                        this.promoInsightNotifications.push(notification);
                    }
                });
            } else if (notification.messageType === NotificationType.INSIGHT) {
                this.insights$.pipe(take(1)).subscribe((messageInsights: HotInsight[]) => {
                    const index = messageInsights.findIndex(
                        (insight: HotInsight) => insight.id === notification.notificationData
                    );
                    if (index !== -1) {
                        notification['code'] = messageInsights[index].code;
                        notification['imgUrl'] =
                            messageInsights[index]?.mobileBannerUrl || messageInsights[index]?.desktopBannerUrl;
                        this.promoInsightNotifications.push(notification);
                    }
                });
            }
        });
    }

    public toggleNotificationList(state: NotificationListState): void {
        this.toggle.emit(state);
    }

    private currentUserIsCustomer(currentUserType: string): boolean {
        let result = false;

        if (currentUserType === AccountType.Customer) {
            result = true;
        } else if (currentUserType === AccountType.SubDistributor) {
            const subDistributorMode = this.getSubDistributorUserMode();
            result = subDistributorMode === 'Customer';
        }

        return result;
    }

    public currentUserIsDistributor(currentUserType: string): boolean {
        let result = false;

        if (currentUserType === AccountType.Distributor) {
            result = true;
        } else if (currentUserType === AccountType.SubDistributor) {
            const subDistributorMode = this.getSubDistributorUserMode();
            result = subDistributorMode === 'Distributor';
        }

        return result;
    }

    private getSubDistributorUserMode(): string {
        return localStorage.getItem(StorageKeys.subDistributorMode);
    }

    private navigateToOrderAsCustomer(notification: NotificationMessage): void {
        const orderNumber: string = notification?.orderNumber;
        if (notification.messageType === NotificationType.PROMOTION) {
            this.navigatePromotionInsight(notification);
        } else if (notification.messageType === NotificationType.INSIGHT) {
            if (this.navigateToPromotionAndInsightDetail) {
                this.insights$.pipe(take(1)).subscribe((promotionInsights: HotInsight[]) => {
                    const insight = promotionInsights.find((item) => item.id === notification.notificationData);
                    if (insight?.code) {
                        this.router.navigateByUrl(`/trends/${insight.code}`);
                    } else {
                        this.router.navigateByUrl('/trends');
                    }
                });
            } else {
                this.router.navigateByUrl('/trends');
            }
        } else {
            this.showOrderOnPage('/orders', orderNumber);
        }
    }
    public navigatePromotionInsight(notification) {
        if (this.navigateToPromotionAndInsightDetail) {
            this.promotions$.pipe(take(1)).subscribe((promotions: HotPromotion[]) => {
                const promotion = promotions.find((item) => item.id === notification.notificationData);
                if (promotion?.code) {
                    this.router.navigateByUrl(`/promotions/${promotion.code}`);
                } else {
                    this.router.navigateByUrl('/promotions');
                }
            });
        } else {
            this.router.navigateByUrl('/promotions');
        }
    }

    private navigateToOrderAsDistributor(notification: NotificationMessage): void {
        const orderNumber = notification.orderNumber;
        const orderStatus = notification.orderStatus;

        // Order statuses here are taken from distributor order pages routing data.

        if (this.isNewOrderPageAvailable && this.distributorNewOrderStatuses.includes(orderStatus)) {
            this.showOrderOnPage('/distributor/new-orders', orderNumber);
        } else if (this.isConfirmedOrderPageAvailable && this.distributorConfirmedOrderStatuses.includes(orderStatus)) {
            this.showOrderOnPage('/distributor/confirmed-orders', orderNumber);
        }
    }

    private showOrderOnPage(urlPrefix: string, orderNumber: string): void {
        if (this.router.url.indexOf(urlPrefix) !== -1) {
            this.triggerService.triggerNavigateByNotification(orderNumber);
        } else {
            this.router.navigateByUrl(`${urlPrefix}/${orderNumber}`);
        }
    }
    public getArrivalTimeInMinutes(ETATime: any, createdTime: any) {
        let arrivalTime = new Date(ETATime);
        let notificationCreatedTime = new Date(createdTime);
        const actualArrivalTime = arrivalTime;
        let bufferTime = new Date(actualArrivalTime.getTime() + 45 * 60000);
        let timeDifference = (bufferTime.getTime() - notificationCreatedTime.getTime()) / 1000;
        timeDifference /= 60;
        this.distanceAway = Math.round(timeDifference);
        if (this.distanceAway >= 60) {
            const hrs = Math.floor(this.distanceAway / 60);
            const mints = Math.floor(this.distanceAway % 60);
            if (mints > 30) {
                this.distanceAway = hrs + 1 + ' hr';
            } else if (mints > 0 && mints <= 30) {
                this.distanceAway = hrs + ' hr' + ' 30' + ' min';
            } else {
                this.distanceAway = hrs + ' hr';
            }
        } else {
            const mints = Math.floor(this.distanceAway % 60) + ' min';
            this.distanceAway = mints;
        }
        return this.distanceAway;
    }
    public sendAnalyticEventInfo() {
        this.analyticService.gpsTracking('notifications modal');
    }
}
