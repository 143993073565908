import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

import { HotApiMarketingService, HotFulfillmentCenter, HotInsight, HotPromotion } from '@hot-theme-nx/generated-api';
import { StorageKeys } from '@hot-libs/shared-types';

import { AuthenticationService } from 'apps/hot-b2b/src/app/account/services';
import { AppState } from '@hot-b2b/store/reducers';
import { select, Store } from '@ngrx/store';
import { warehousesState } from '@hot-b2b/store/warehouses/selector';
import { WarehousesState } from '@hot-b2b/store/warehouses/model';
import { CacheClearService } from '../../shared/services/cache-clear.service';

@Injectable({
    providedIn: 'root',
})
export class MarketingService {
    constructor(
        private readonly hotApiMarketingService: HotApiMarketingService,
        private readonly authenticationService: AuthenticationService,
        private readonly store: Store<AppState>,
        private readonly clearCache: CacheClearService
    ) { }

    public getPromotionsFromCenters(
        includeInvisiblePromotions: boolean
    ): Observable<
        Array<{
            id: string;
            promotions: HotPromotion[];
        }>
    > {
        return this.store.pipe(select(warehousesState),
            filter(state => !!state.fetched),
            first(),
            switchMap((warehouses: WarehousesState) => {
                const centers: HotFulfillmentCenter[] = warehouses?.data?.warehouses; // rewardsCentersOnly: false
                const ffc = centers.find((w) => w.id === this.getCurrentFulfillmentCenterId()) || centers[0];

                return forkJoin(
                    Array(ffc).map((item: HotFulfillmentCenter) => {
                        const params: HotApiMarketingService.GetPromotionsParams = {
                            fulfillmentCenterId: item.id,
                            includeInvisiblePromotions,
                        };
                        return this.hotApiMarketingService
                            .getPromotions(params)
                            .pipe(map((response: HotPromotion[]) => ({ id: item.id, promotions: response })));
                    })
                )
            })
        )
    }

    public getInsightsFromCenters(): Observable<
        Array<{
            id: string;
            insights: HotInsight[];
        }>
    > {
        return this.store.pipe(select(warehousesState),
            filter(state => !!state.fetched),
            first(),
            switchMap((warehouses: WarehousesState) => {
                const centers: HotFulfillmentCenter[] = warehouses?.data?.warehouses; // rewardsCentersOnly: false
                const ffc = centers.find((w) => w.id === this.getCurrentFulfillmentCenterId()) || centers[0];

                return forkJoin(
                    Array(ffc).map((item: HotFulfillmentCenter) => {
                        const params: HotApiMarketingService.GetInsightsParams = {
                            fulfillmentCenterId: item.id,
                        };

                        return this.hotApiMarketingService
                            .getInsights(params)
                            .pipe(map((response: HotInsight[]) => ({ id: item.id, insights: response })));
                    })
                )
            }),
        )
    }
    public catchErrorService(err) {
        if (err.status === 401) {
            this.authenticationService.logout();
        }

    }
    public deleteInsightCache(): Promise<boolean> {
        const deleteURLs = [
            { 'dataGroupName': 'performance', 'url': '/storefrontapi/hot/marketing/insights' }
        ];

        return this.clearCache.deleteCacheByAPIUrl(deleteURLs)
        .then(() => {return true;})
        .catch(() => {return false;});
    }

    public deleteCarouselBannerCache(): Promise<boolean> {
        const deleteURLs = [
            { 'dataGroupName': 'performance8h', 'url': '/storefrontapi/hot/marketing/carousel-banners' }
        ];

        return this.clearCache.deleteCacheByAPIUrl(deleteURLs)
        .then(() => {return true;})
        .catch(() => {return false;});

    }

    public getPromotionsHistory(includeInvisiblePromotions?: boolean): Observable<HotPromotion[]> {
        return this.hotApiMarketingService.getPromotionsHistory(includeInvisiblePromotions).pipe(
            map((promotionHistory: HotPromotion[]) => {
                return promotionHistory;
            })
        );
    }

    private getCurrentFulfillmentCenterId(): string {
        const fulfillmentCenter = JSON.parse(
            localStorage.getItem(StorageKeys.fulfillmentCenter)
        ) as HotFulfillmentCenter;
        return fulfillmentCenter?.id ?? null;
    }
}
