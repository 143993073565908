/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotValidateActivationTokenResponse } from '../models/hot-validate-activation-token-response';
import { HotPhoneActivationTokenRequest } from '../models/hot-phone-activation-token-request';
import { HotSecurityResponse } from '../models/hot-security-response';
import { HotForgotPasswordRequest } from '../models/hot-forgot-password-request';
import { HotValidatePhoneTokenResponse } from '../models/hot-validate-phone-token-response';
import { HotValidatePhoneTokenRequest } from '../models/hot-validate-phone-token-request';
import { HotResetPasswordRequest } from '../models/hot-reset-password-request';
import { HotResetPasswordTokenResponse } from '../models/hot-reset-password-token-response';
import { HotOperationResult } from '../models/hot-operation-result';
import { ValidatePasswordResetTokenRequest } from '../models/validate-password-reset-token-request';
import { HotValidateActivationTokenRequest } from '../models/hot-validate-activation-token-request';
import { HotActivateResponse } from '../models/hot-activate-response';
import { HotActivateRequest } from '../models/hot-activate-request';
import { HotSimplifiedActivationRequest } from '../models/hot-simplified-activation-request';
import { HotInvitationRequest } from '../models/hot-invitation-request';
import { HotCurrentUserResponse } from '../models/hot-current-user-response';
import { User } from '../models/user';
@Injectable({
  providedIn: 'root',
})
class HotApiSecurityService extends __BaseService {
  static readonly signOutPath = '/storefrontapi/hot/security/sign-out';
  static readonly requestPhoneActivationTokenPath = '/storefrontapi/hot/security/phone-activation-token';
  static readonly forgotPasswordPath = '/storefrontapi/hot/security/forgot-password';
  static readonly validatePhoneTokenPath = '/storefrontapi/hot/security/validate-phone-token';
  static readonly resetPasswordPath = '/storefrontapi/hot/security/reset-password';
  static readonly hotResetPasswordPath = '/storefrontapi/hot/security/change-password';
  static readonly requestPasswordResetTokenPath = '/storefrontapi/hot/security/reset-password-token';
  static readonly validatePasswordResetTokenPath = '/storefrontapi/hot/security/validate-password-reset-token';
  static readonly validateActivationTokenPath = '/storefrontapi/hot/security/validate-activation-token';
  static readonly activatePath = '/storefrontapi/hot/security/activate';
  static readonly simplifiedActivatePath = '/storefrontapi/hot/security/activate/simplified';
  static readonly sendInvitationPath = '/storefrontapi/hot/security/invitation';
  static readonly getCurrentUserPath = '/storefrontapi/hot/security/user';
  static readonly getCurrentUserNamePath = '/storefrontapi/hot/security/current-username';
  static readonly updateflagPath = '/storefrontapi/hot/security/PopUpFlag';
  static readonly updateTutorialPopupPath = '/storefrontapi/hot/security/HideTutorial';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  signOutResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/sign-out`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  signOut(): __Observable<null> {
    return this.signOutResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  requestPhoneActivationTokenResponse(body?: HotPhoneActivationTokenRequest): __Observable<__StrictHttpResponse<HotValidateActivationTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/phone-activation-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotValidateActivationTokenResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  requestPhoneActivationToken(body?: HotPhoneActivationTokenRequest): __Observable<HotValidateActivationTokenResponse> {
    return this.requestPhoneActivationTokenResponse(body).pipe(
      __map(_r => _r.body as HotValidateActivationTokenResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  forgotPasswordResponse(body?: HotForgotPasswordRequest): __Observable<__StrictHttpResponse<HotSecurityResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/forgot-password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotSecurityResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  forgotPassword(body?: HotForgotPasswordRequest): __Observable<HotSecurityResponse> {
    return this.forgotPasswordResponse(body).pipe(
      __map(_r => _r.body as HotSecurityResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  validatePhoneTokenResponse(body?: HotValidatePhoneTokenRequest): __Observable<__StrictHttpResponse<HotValidatePhoneTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/validate-phone-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotValidatePhoneTokenResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  validatePhoneToken(body?: HotValidatePhoneTokenRequest): __Observable<HotValidatePhoneTokenResponse> {
    return this.validatePhoneTokenResponse(body).pipe(
      __map(_r => _r.body as HotValidatePhoneTokenResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  resetPasswordResponse(body?: HotResetPasswordRequest): __Observable<__StrictHttpResponse<HotSecurityResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/reset-password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotSecurityResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  resetPassword(body?: HotResetPasswordRequest): __Observable<HotSecurityResponse> {
    return this.resetPasswordResponse(body).pipe(
      __map(_r => _r.body as HotSecurityResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  hotResetPasswordResponse(body?: HotResetPasswordRequest): __Observable<__StrictHttpResponse<HotSecurityResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/change-password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotSecurityResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  hotResetPassword(body?: HotResetPasswordRequest): __Observable<HotSecurityResponse> {
    return this.hotResetPasswordResponse(body).pipe(
      __map(_r => _r.body as HotSecurityResponse)
    );
  }

  /**
   * @return Success
   */
  requestPasswordResetTokenResponse(): __Observable<__StrictHttpResponse<HotResetPasswordTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/security/reset-password-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotResetPasswordTokenResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  requestPasswordResetToken(): __Observable<HotResetPasswordTokenResponse> {
    return this.requestPasswordResetTokenResponse().pipe(
      __map(_r => _r.body as HotResetPasswordTokenResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  validatePasswordResetTokenResponse(body?: ValidatePasswordResetTokenRequest): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/validate-password-reset-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  validatePasswordResetToken(body?: ValidatePasswordResetTokenRequest): __Observable<HotOperationResult> {
    return this.validatePasswordResetTokenResponse(body).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  validateActivationTokenResponse(body?: HotValidateActivationTokenRequest): __Observable<__StrictHttpResponse<HotValidateActivationTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/validate-activation-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotValidateActivationTokenResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  validateActivationToken(body?: HotValidateActivationTokenRequest): __Observable<HotValidateActivationTokenResponse> {
    return this.validateActivationTokenResponse(body).pipe(
      __map(_r => _r.body as HotValidateActivationTokenResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  activateResponse(body?: HotActivateRequest): __Observable<__StrictHttpResponse<HotActivateResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/activate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotActivateResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  activate(body?: HotActivateRequest): __Observable<HotActivateResponse> {
    return this.activateResponse(body).pipe(
      __map(_r => _r.body as HotActivateResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  simplifiedActivateResponse(body?: HotSimplifiedActivationRequest): __Observable<__StrictHttpResponse<HotActivateResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/activate/simplified`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotActivateResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  simplifiedActivate(body?: HotSimplifiedActivationRequest): __Observable<HotActivateResponse> {
    return this.simplifiedActivateResponse(body).pipe(
      __map(_r => _r.body as HotActivateResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  sendInvitationResponse(body?: HotInvitationRequest): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/invitation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  sendInvitation(body?: HotInvitationRequest): __Observable<HotOperationResult> {
    return this.sendInvitationResponse(body).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }

  /**
   * @return Success
   */
  getCurrentUserResponse(): __Observable<__StrictHttpResponse<HotCurrentUserResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/security/user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCurrentUserResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  getCurrentUser(): __Observable<HotCurrentUserResponse> {
    return this.getCurrentUserResponse().pipe(
      __map(_r => _r.body as HotCurrentUserResponse)
    );
  }

  /**
   * @return Success
   */
  getCurrentUserNameResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/security/current-username`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * @return Success
   */
  getCurrentUserName(): __Observable<any> {
    return this.getCurrentUserNameResponse().pipe(
      __map(_r => _r.body as any)
    );
  }

  /**
   * @param params The `HotApiSecurityService.UpdateflagParams` containing the following parameters:
   *
   * - `flag`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateflagResponse(params: HotApiSecurityService.UpdateflagParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.flag != null) __params = __params.set('flag', params.flag.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/PopUpFlag`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `HotApiSecurityService.UpdateflagParams` containing the following parameters:
   *
   * - `flag`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateflag(params: HotApiSecurityService.UpdateflagParams): __Observable<boolean> {
    return this.updateflagResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  updateTutorialPopupResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/security/HideTutorial`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @return Success
   */
  updateTutorialPopup(): __Observable<boolean> {
    return this.updateTutorialPopupResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module HotApiSecurityService {

  /**
   * Parameters for Updateflag
   */
  export interface UpdateflagParams {
    flag?: boolean;
    body?: User;
  }
}

export { HotApiSecurityService }
