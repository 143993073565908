/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotCatalog } from '../models/hot-catalog';
import { HotCatalogV2 } from '../models/hot-catalog-v2';
import { HotOfflineCatalog } from '../models/hot-offline-catalog';
import { HotBrandGroupSearchResult } from '../models/hot-brand-group-search-result';
import { HotProductSearchResult } from '../models/hot-product-search-result';
import { HotProductSearchCriteria } from '../models/hot-product-search-criteria';
import { HotProductSearchCriteriaV2 } from '../models/hot-product-search-criteria-v2';
import { HotProductSearchLightResult } from '../models/hot-product-search-light-result';
import { HotProduct } from '../models/hot-product';
import { HotAggregationItem } from '../models/hot-aggregation-item';
import { HotCategory } from '../models/hot-category';
import { HotSuggestionsResult } from '../models/hot-suggestions-result';
import { BackInStockRequest } from '../models/back-in-stock-request';
import { HotProductSearchCriteriaBySku } from '../models/hot-product-search-criteria-by-sku';
import { HotSkuProductSearchCriteria } from '../models/hot-sku-product-search-criteria';
import { HotFavoriteProductReponse } from '../models/hot-favorite-product-reponse';
@Injectable({
  providedIn: 'root',
})
class HotApiCatalogService extends __BaseService {
  static readonly getCatalogPath = '/storefrontapi/hot/catalog';
  static readonly getOfflineCatalogPath = '/storefrontapi/hot/catalog/offline';
  static readonly getGroupAccountOfflineCatalogsPath = '/storefrontapi/hot/catalog/offline/group-account';
  static readonly searchBrandGroupsPath = '/storefrontapi/hot/catalog/brand-groups/search';
  static readonly searchProductsPath = '/storefrontapi/hot/catalog/products/search';
  static readonly searchProductsV2Path = '/storefrontapi/hot/catalog/products/search/v2';
  static readonly searchProductsLightPath = '/storefrontapi/hot/catalog/products/search-light';
  static readonly getProductsByIdsPath = '/storefrontapi/hot/catalog/products';
  static readonly getProductTypesByCategoryIdPath = '/storefrontapi/hot/catalog/products/types';
  static readonly getCategoryTreePath = '/storefrontapi/hot/catalog/categories/tree';
  static readonly getSuggestionsPath = '/storefrontapi/hot/catalog/suggestions';
  static readonly getBackInStockSubscriptionsPath = '/storefrontapi/hot/catalog/back-in-stock-subscriptions';
  static readonly subscribeForBackInStockPath = '/storefrontapi/hot/catalog/back-in-stock-subscriptions';
  static readonly exportSingleProductPath = '/storefrontapi/hot/catalog/products/{productId}/export';
  static readonly searchProductbySkuPath = '/storefrontapi/hot/catalog/products/search-by-sku';
  static readonly addOrDeletePath = '/storefrontapi/hot/catalog/product/favorite/add-or-delete';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `HotApiCatalogService.GetCatalogParams` containing the following parameters:
   *
   * - `returnableEmpties`:
   *
   * - `fulfillmentCenterId`:
   *
   * @return Success
   */
  getCatalogResponse(params: HotApiCatalogService.GetCatalogParams): __Observable<__StrictHttpResponse<HotCatalog>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.returnableEmpties != null) __params = __params.set('returnableEmpties', params.returnableEmpties.toString());
    if (params.fulfillmentCenterId != null) __params = __params.set('fulfillmentCenterId', params.fulfillmentCenterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/catalog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCatalog>;
      })
    );
  }
  /**
   * @param params The `HotApiCatalogService.GetCatalogParams` containing the following parameters:
   *
   * - `returnableEmpties`:
   *
   * - `fulfillmentCenterId`:
   *
   * @return Success
   */
  getCatalog(params: HotApiCatalogService.GetCatalogParams): __Observable<HotCatalog> {
    return this.getCatalogResponse(params).pipe(
      __map(_r => _r.body as HotCatalog)
    );
  }

  /**
   * @param fulfillmentCenterId undefined
   * @return Success
   */
  getOfflineCatalogResponse(fulfillmentCenterId?: string): __Observable<__StrictHttpResponse<HotCatalogV2>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (fulfillmentCenterId != null) __params = __params.set('fulfillmentCenterId', fulfillmentCenterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/catalog/offline`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCatalogV2>;
      })
    );
  }
  /**
   * @param fulfillmentCenterId undefined
   * @return Success
   */
  getOfflineCatalog(fulfillmentCenterId?: string): __Observable<HotCatalogV2> {
    return this.getOfflineCatalogResponse(fulfillmentCenterId).pipe(
      __map(_r => _r.body as HotCatalogV2)
    );
  }

  /**
   * @return Success
   */
  getGroupAccountOfflineCatalogsResponse(): __Observable<__StrictHttpResponse<Array<HotOfflineCatalog>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/catalog/offline/group-account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotOfflineCatalog>>;
      })
    );
  }
  /**
   * @return Success
   */
  getGroupAccountOfflineCatalogs(): __Observable<Array<HotOfflineCatalog>> {
    return this.getGroupAccountOfflineCatalogsResponse().pipe(
      __map(_r => _r.body as Array<HotOfflineCatalog>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchBrandGroupsResponse(body?: Array<string>): __Observable<__StrictHttpResponse<HotBrandGroupSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/catalog/brand-groups/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotBrandGroupSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchBrandGroups(body?: Array<string>): __Observable<HotBrandGroupSearchResult> {
    return this.searchBrandGroupsResponse(body).pipe(
      __map(_r => _r.body as HotBrandGroupSearchResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchProductsResponse(body?: HotProductSearchCriteria): __Observable<__StrictHttpResponse<HotProductSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/catalog/products/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotProductSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchProducts(body?: HotProductSearchCriteria): __Observable<HotProductSearchResult> {
    return this.searchProductsResponse(body).pipe(
      __map(_r => _r.body as HotProductSearchResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchProductsV2Response(body?: HotProductSearchCriteriaV2): __Observable<__StrictHttpResponse<HotProductSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/catalog/products/search/v2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotProductSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchProductsV2(body?: HotProductSearchCriteriaV2): __Observable<HotProductSearchResult> {
    return this.searchProductsV2Response(body).pipe(
      __map(_r => _r.body as HotProductSearchResult)
    );
  }

  /**
   * @param keyword undefined
   * @return Success
   */
  searchProductsLightResponse(keyword?: string): __Observable<__StrictHttpResponse<HotProductSearchLightResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (keyword != null) __params = __params.set('keyword', keyword.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/catalog/products/search-light`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotProductSearchLightResult>;
      })
    );
  }
  /**
   * @param keyword undefined
   * @return Success
   */
  searchProductsLight(keyword?: string): __Observable<HotProductSearchLightResult> {
    return this.searchProductsLightResponse(keyword).pipe(
      __map(_r => _r.body as HotProductSearchLightResult)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  getProductsByIdsResponse(ids?: Array<string>): __Observable<__StrictHttpResponse<Array<HotProduct>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/catalog/products`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotProduct>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  getProductsByIds(ids?: Array<string>): __Observable<Array<HotProduct>> {
    return this.getProductsByIdsResponse(ids).pipe(
      __map(_r => _r.body as Array<HotProduct>)
    );
  }

  /**
   * @param categoryId undefined
   * @return Success
   */
  getProductTypesByCategoryIdResponse(categoryId?: string): __Observable<__StrictHttpResponse<Array<HotAggregationItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (categoryId != null) __params = __params.set('categoryId', categoryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/catalog/products/types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotAggregationItem>>;
      })
    );
  }
  /**
   * @param categoryId undefined
   * @return Success
   */
  getProductTypesByCategoryId(categoryId?: string): __Observable<Array<HotAggregationItem>> {
    return this.getProductTypesByCategoryIdResponse(categoryId).pipe(
      __map(_r => _r.body as Array<HotAggregationItem>)
    );
  }

  /**
   * @return Success
   */
  getCategoryTreeResponse(): __Observable<__StrictHttpResponse<Array<HotCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/catalog/categories/tree`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotCategory>>;
      })
    );
  }
  /**
   * @return Success
   */
  getCategoryTree(): __Observable<Array<HotCategory>> {
    return this.getCategoryTreeResponse().pipe(
      __map(_r => _r.body as Array<HotCategory>)
    );
  }

  /**
   * @param params The `HotApiCatalogService.GetSuggestionsParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `keyword`:
   *
   * @return Success
   */
  getSuggestionsResponse(params: HotApiCatalogService.GetSuggestionsParams): __Observable<__StrictHttpResponse<HotSuggestionsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.keyword != null) __params = __params.set('keyword', params.keyword.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/catalog/suggestions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotSuggestionsResult>;
      })
    );
  }
  /**
   * @param params The `HotApiCatalogService.GetSuggestionsParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `keyword`:
   *
   * @return Success
   */
  getSuggestions(params: HotApiCatalogService.GetSuggestionsParams): __Observable<HotSuggestionsResult> {
    return this.getSuggestionsResponse(params).pipe(
      __map(_r => _r.body as HotSuggestionsResult)
    );
  }

  /**
   * @return Success
   */
  getBackInStockSubscriptionsResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/catalog/back-in-stock-subscriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @return Success
   */
  getBackInStockSubscriptions(): __Observable<Array<string>> {
    return this.getBackInStockSubscriptionsResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param body undefined
   */
  subscribeForBackInStockResponse(body?: BackInStockRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storefrontapi/hot/catalog/back-in-stock-subscriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  subscribeForBackInStock(body?: BackInStockRequest): __Observable<null> {
    return this.subscribeForBackInStockResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param productId undefined
   */
  exportSingleProductResponse(productId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/catalog/products/${encodeURIComponent(String(productId))}/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param productId undefined
   */
  exportSingleProduct(productId: string): __Observable<null> {
    return this.exportSingleProductResponse(productId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchProductbySkuResponse(body?: HotSkuProductSearchCriteria): __Observable<__StrictHttpResponse<Array<HotProductSearchCriteriaBySku>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/catalog/products/search-by-sku`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotProductSearchCriteriaBySku>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchProductbySku(body?: HotSkuProductSearchCriteria): __Observable<Array<HotProductSearchCriteriaBySku>> {
    return this.searchProductbySkuResponse(body).pipe(
      __map(_r => _r.body as Array<HotProductSearchCriteriaBySku>)
    );
  }

  /**
   * @param params The `HotApiCatalogService.AddOrDeleteParams` containing the following parameters:
   *
   * - `StoreId`:
   *
   * - `ProductSku`:
   *
   * - `ProductId`:
   *
   * - `OutletId`:
   *
   * - `IsFavorite`:
   *
   * @return Success
   */
  addOrDeleteResponse(params: HotApiCatalogService.AddOrDeleteParams): __Observable<__StrictHttpResponse<HotFavoriteProductReponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.StoreId != null) __params = __params.set('StoreId', params.StoreId.toString());
    if (params.ProductSku != null) __params = __params.set('ProductSku', params.ProductSku.toString());
    if (params.ProductId != null) __params = __params.set('ProductId', params.ProductId.toString());
    if (params.OutletId != null) __params = __params.set('OutletId', params.OutletId.toString());
    if (params.IsFavorite != null) __params = __params.set('IsFavorite', params.IsFavorite.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/catalog/product/favorite/add-or-delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotFavoriteProductReponse>;
      })
    );
  }
  /**
   * @param params The `HotApiCatalogService.AddOrDeleteParams` containing the following parameters:
   *
   * - `StoreId`:
   *
   * - `ProductSku`:
   *
   * - `ProductId`:
   *
   * - `OutletId`:
   *
   * - `IsFavorite`:
   *
   * @return Success
   */
  addOrDelete(params: HotApiCatalogService.AddOrDeleteParams): __Observable<HotFavoriteProductReponse> {
    return this.addOrDeleteResponse(params).pipe(
      __map(_r => _r.body as HotFavoriteProductReponse)
    );
  }
}

module HotApiCatalogService {

  /**
   * Parameters for GetCatalog
   */
  export interface GetCatalogParams {
    returnableEmpties?: boolean;
    fulfillmentCenterId?: string;
  }

  /**
   * Parameters for GetSuggestions
   */
  export interface GetSuggestionsParams {
    take?: number;
    keyword?: string;
  }

  /**
   * Parameters for AddOrDelete
   */
  export interface AddOrDeleteParams {
    StoreId?: string;
    ProductSku?: string;
    ProductId?: string;
    OutletId?: string;
    IsFavorite?: boolean;
  }
}

export { HotApiCatalogService }
