import { Component, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';

import { HotApiSecurityService } from '@hot-theme-nx/generated-api';

import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { ModalIds, StorageKeys } from '@hot-libs/shared-types';
import { AuthenticationService } from 'apps/hot-b2b/src/app/account/services';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { HotResetPasswordTokenResponse } from 'libs/generated-api/src/lib/models/hot-reset-password-token-response';

@Component({
    selector: 'hot-password-expired-modal',
    templateUrl: './password-expired-modal.component.html',
})
export class PasswordExpiredModalComponent implements OnDestroy {
    public modalPassExpiredState$: Observable<ModalsState>;

    private readonly subscriptionDestroyer$ = new Subject<boolean>();

    constructor(
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly hotApiSecurityService: HotApiSecurityService,
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService
    ) {
        this.modalPassExpiredState$ = this.store.pipe(select(selectModalState(ModalIds.passwordExpired)));
    }

    PATHS = PATHS;


    public onChangePasswordClick(): void {
        this.hotApiSecurityService
            .requestPasswordResetToken()
            .pipe(takeUntil(this.subscriptionDestroyer$))
            .subscribe((response: HotResetPasswordTokenResponse) => {
                localStorage.removeItem(StorageKeys.bearerToken);
                this.modalService.toggleModal(ModalIds.passwordExpired, false);

                const params = {
                    userId: response.userId,
                    token: response.token,
                };
                this.router.navigate(['/account/reset-password/sms-verification'], { queryParams: params });
            });
    }

    public onClose(): void {
        this.modalService.toggleModal(ModalIds.passwordExpired, false);
        this.authenticationService.logout();
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroyer$.next(true);
    }
}
