import { Component, Input } from '@angular/core';

@Component({
    selector: 'hot-faq-icon',
    templateUrl: './faq-icon.component.html',
})
export class HotFaqIconComponent {
    @Input() public isTextTopDown: boolean;

    
}
