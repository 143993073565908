import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { ofType, Actions } from '@ngrx/effects';

import {
    CurrencyFormat,
    HotApiCartsService,
    HotCartValidationRequest,
    HotCreateOrderRequest,
    HotFulfillmentCenter,
    HotOperationResult,
    ValidationResponseMessage,
} from '@hot-theme-nx/generated-api';

import { ApplicationInsightsService } from './application-insights.service';
import { FeaturesService } from './features.service';
import { LoaderService } from './loader.service';
import { PermissionsService } from './permissions.service';

import { authUser } from '@hot-b2b/store/auth/selector';
import { CartCreateOrder, CartCreateOrders, CartError, CartGet, CartErrorCodes } from '@hot-b2b/store/cart/actions';
import { cartData, cartItems, cartRemarks } from '@hot-b2b/store/cart/selector';
import { ModalToggle } from '@hot-b2b/store/modals/actions';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine, settingsCurrencyFormat, settingsData, settingsStore } from '@hot-b2b/store/settings/selector';
import { warehouses } from '@hot-b2b/store/warehouses/selector';
import { HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { CustomerPermissions, FeatureNames, TelemetryEventType } from '@hot-libs/shared-types';
import { RemarksFormService } from 'apps/hot-b2b/src/app/cart/services';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { CurrencyFormatPipe } from 'apps/hot-b2b/src/app/shared/pipes';
import { AnalyticService } from './analytic.service';
import moment from 'moment';
import { ECartActions } from '@hot-b2b/store/cart/types';
import { OrderSimulationTaxCalculation } from '@hot-b2b/store/orders/actions';
import { orderSimulationDetails } from '@hot-b2b/store/orders/selector';
import { OrderSimulation } from '@hot-b2b/store/orders/model';
import { ConfigurationService } from './configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';

@Injectable({
    providedIn: 'root',
})
export class OrderCreationService {
    public readonly paymentMethodValid$ = new BehaviorSubject<boolean>(true);
    private readonly cartValidateSuccessMobile = new BehaviorSubject<OrderSimulation>(null);
    public readonly currentResponse = this.cartValidateSuccessMobile.asObservable();
    public availableDeliveryDates$ = new BehaviorSubject<moment.Moment[]>(null);
    public isSeparateOrder: boolean;
    public invoiceType: string = null;
    private readonly paymentFailedError = new BehaviorSubject<boolean>(null);
    public readonly paymentFailedErrorFlag = this.paymentFailedError.asObservable();

    private readonly settingsAppOnLine$: Observable<boolean>;
    private readonly settingsStore$: Observable<SettingsStoreModel>;
    private readonly cartData$: Observable<HotCartExtended>;
    private readonly cartItems$: Observable<HotCartLineItemExtended[]>;
    private readonly user$: Observable<HotUserExtended>;
    public isVnB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';
    public settingSplitOrderByPOSM : boolean = false;

    private readonly haveCreateOrderPermission$: Observable<boolean> = this.permissionsService.checkPermission(
        CustomerPermissions.OrderCreate
    );
    public orderSimulationDetails$: Observable<OrderSimulation>;
    public errorTranslationList: ValidationResponseMessage[] = [];
    public preferredDeliveryDate: string;
    public purchaseOrderNumber: string;

    constructor(
        private readonly store: Store<AppState>,
        private readonly hotApiCartsService: HotApiCartsService,
        private readonly appInsightsService: ApplicationInsightsService,
        private readonly permissionsService: PermissionsService,
        private readonly featuresService: FeaturesService,
        private readonly loaderService: LoaderService,
        private readonly remarksFormService: RemarksFormService,
        private readonly currencyFormat: CurrencyFormatPipe,
        private readonly analyticService: AnalyticService,
        private readonly action: Actions,
        private readonly translateService: TranslateService,
        private readonly configurationService: ConfigurationService
    ) {
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.settingsStore$ = this.store.pipe(select(settingsStore));
        this.cartData$ = this.store.pipe(select(cartData));
        this.cartItems$ = this.store.pipe(select(cartItems));
        this.user$ = this.store.pipe(select(authUser));
        this.orderSimulationDetails$ = this.store.pipe(select(orderSimulationDetails));
        this.store.pipe(select(settingsData)).subscribe((settings: HotSettingsExtended) => {
            this.settingSplitOrderByPOSM = settings.splitOrderByPOSM;
        });
    }
    public createOrder(ignoreExternalCartValidationError: boolean = false): void {
        this.appInsightsService.startTrackingEvent(TelemetryEventType.OrderCreateResponseTime);
        this.appInsightsService.startTrackingEvent(TelemetryEventType.OrderCreateFullResponseTime);
        this.cartData$
            .pipe(
                withLatestFrom(
                    this.user$,
                    this.cartItems$,
                    this.store.pipe(select(cartRemarks)),
                    this.store.pipe(select(warehouses)),
                    this.settingsStore$,
                    this.store.pipe(select(settingsCurrencyFormat)),
                    this.featuresService.checkFeatures(FeatureNames.ExternalCartValidation),
                    this.featuresService.checkFeatures(FeatureNames.OrganizationLevelCreditLimitCheck),
                    this.featuresService.checkFeatures(FeatureNames.SplitOrdersBySuppliersWhenCheckout),
                    this.featuresService.checkFeatures(FeatureNames.RefreshCartAfterExternalValidation)
                ),
                take(1)
            )
            .subscribe(
                ([
                    cart,
                    user,
                    cartItemsData,
                    cartRemarksData,
                    _warehousesData,
                    storeSettings,
                    currencyFormatModel,
                    externalCartValidation,
                    OrganizationLevelCreditLimitCheck,
                    splitOrdersBySuppliersWhenCheckout,
                    refreshCartAfterExternalValidation,
                ]: [
                    HotCartExtended,
                    HotUserExtended,
                    HotCartLineItemExtended[],
                    HotCreateOrderRequest,
                    HotFulfillmentCenter[],
                    SettingsStoreModel,
                    CurrencyFormat,
                    boolean,
                    boolean,
                    boolean,
                    boolean
                ]) => {
                    let isBlocked = false;
                    isBlocked = user?.contact?.outlet?.isBlocked;
                    if (
                        !cart.isValid ||
                        (user.contact.outlet &&
                            isBlocked &&
                            storeSettings.isOwnedByHeineken &&
                            !user.contact.outlet.isCashOutlet) ||
                        !cartItemsData.length ||
                        (OrganizationLevelCreditLimitCheck && isBlocked)
                    ) {
                        return;
                    }
                    if (
                        storeSettings.hideInactiveProductsInCart &&
                        cartItemsData.some((item) =>
                            item.validationErrors.some((error) => error.errorCode === 'UnavailableError')
                        )
                    ) {
                        return;
                    }

                    const isSeparateOrder = this.isSeparateOrder || splitOrdersBySuppliersWhenCheckout || this.settingSplitOrderByPOSM;
                    const cartValidationModalState: ModalsState = {
                        id: 'cart-validation-modal',
                        isOpened: true,
                    };
                    this.cartValidations(
                        ignoreExternalCartValidationError,
                        externalCartValidation,

                        cartRemarksData,
                        isSeparateOrder,
                        cartValidationModalState,
                        refreshCartAfterExternalValidation,
                        cart
                    );
                    this.appInsightsService.trackEvent(TelemetryEventType.CartTotal, {
                        total: this.currencyFormat.transformToFloat(cart.total, currencyFormatModel),
                    });
                    this.setValidateResponseValue(null);
                }
            );
    }
    public cartValidations(
        ignoreExternalCartValidationError,
        externalCartValidation,

        cartRemarksData,
        isSeparateOrder,
        cartValidationModalState,
        refreshCartAfterExternalValidation,
        cart
    ) {
        if (!ignoreExternalCartValidationError && externalCartValidation) {
            if (this.isVnB2b()) {
                this.validateApiVn(
                    cart,
                    cartRemarksData,
                    isSeparateOrder,
                    cartValidationModalState,
                    refreshCartAfterExternalValidation
                );
            } else {
                this.cartValidation(
                    cartRemarksData,
                    isSeparateOrder,
                    cartValidationModalState,
                    refreshCartAfterExternalValidation
                );
            }
        } else {
            this.normalPlaceOrderApiCall(isSeparateOrder);
        }
    }

    private validateApiVn(
        cart: any,
        cartRemarksData: any,
        isSeparateOrder: any,
        cartValidationModalState: any,
        refreshCartAfterExternalValidation: any
    ) {
        let externalPromotionValidation = false;
        this.settingsStore$.subscribe((data) => {
            externalPromotionValidation = data.externalPromotionValidation;
        });
        this.validateCoupon(externalPromotionValidation, cart, cartRemarksData, isSeparateOrder, cartValidationModalState, refreshCartAfterExternalValidation);
        
    }

    private validateCoupon(externalPromotionValidation: any, cart: any, cartRemarksData: any, isSeparateOrder: any, cartValidationModalState: any, refreshCartAfterExternalValidation: any) {
    
        if (externalPromotionValidation) {
           
            this.cartCouponCheck(
                cart,
                cartRemarksData,
                isSeparateOrder,
                cartValidationModalState,
                refreshCartAfterExternalValidation
            );
        } 
        else {
           
            this.normalPlaceOrderApiCall(isSeparateOrder);
        }
    }

    private cartCouponCheck(
        cart: any,
        cartRemarksData: any,
        isSeparateOrder: any,
        cartValidationModalState: any,
        refreshCartAfterExternalValidation: any
    ) {
        if (cart.coupons.length > 0) {
            this.apiCallBasedOnCouponValidate(
                cart,
                cartRemarksData,
                isSeparateOrder,
                cartValidationModalState,
                refreshCartAfterExternalValidation
            );
        } else {
            this.normalPlaceOrderApiCall(isSeparateOrder);
        }
    }

    private apiCallBasedOnCouponValidate(
        cart: any,
        cartRemarksData: any,
        isSeparateOrder: any,
        cartValidationModalState: any,
        refreshCartAfterExternalValidation: any
    ) {
        if (cart.coupons[0].appliedSuccessfully) {
            this.cartValidation(
                cartRemarksData,
                isSeparateOrder,
                cartValidationModalState,
                refreshCartAfterExternalValidation
            );
        } else {
            this.normalPlaceOrderApiCall(isSeparateOrder);
        }
    }

    private normalPlaceOrderApiCall(isSeparateOrder: boolean) {
        isSeparateOrder
            ? this.store.dispatch(new CartCreateOrders(isSeparateOrder))
            : this.store.dispatch(new CartCreateOrder());
    }

    public orderSimulationRequest() {
        this.store.pipe(select(cartRemarks)).subscribe((data) => {
            this.preferredDeliveryDate = data.preferredDeliveryDate;
            this.purchaseOrderNumber = data.purchaseOrderNumber;
        });
        const cartValidationRequest: HotCartValidationRequest = {
            preferredDeliveryDate: this.preferredDeliveryDate,
            purchaseOrderNumber: this.purchaseOrderNumber,
        };
        this.store.dispatch(new OrderSimulationTaxCalculation(cartValidationRequest));
    }

    public setValidateResponseValue(response: OrderSimulation): void {
        this.cartValidateSuccessMobile.next(response);
    }

    public setDotPaymentFailedError(value:boolean):void{
        this.paymentFailedError.next(value);
    }

    public cartValidation(cartRemarksData, isSeparateOrder, cartValidationModalState, refreshCartAfterExternalValidation) {
        this.loaderService.setLoadingText('shared.loader.create-order');
        
        const cartValidationRequest: HotCartValidationRequest = {
            preferredDeliveryDate: cartRemarksData?.preferredDeliveryDate,
            purchaseOrderNumber: cartRemarksData?.purchaseOrderNumber,
        };

        this.hotApiCartsService.validateCart(cartValidationRequest).subscribe({
            next: (result: HotOperationResult) => {
                this.loaderService.clearLoadingText();
                if (result.succeeded) {
                    isSeparateOrder
                        ? this.store.dispatch(new CartCreateOrders(isSeparateOrder))
                        : this.store.dispatch(new CartCreateOrder());
                }
                else if (refreshCartAfterExternalValidation) {
                    this.errorTranslationList = [];
                    this.store.dispatch(new CartGet(true));
                    this.action.pipe(ofType(ECartActions.CART_DATA_UPDATED)).subscribe(() => {
                        for (const item of result.newErrors) {
                            this.cartOrder(item);
                        }

                        this.store.dispatch(new CartErrorCodes(result.errorCodes));
                        this.analyticService.sendWarningMessage(result.errors);
                        this.store.dispatch(new ModalToggle(cartValidationModalState));
                    });
                }
                else {
                    this.errorTranslationList = [];
                    for (const item of result.newErrors) {
                        this.cartOrder(item);
                    }
                    this.store.dispatch(new CartErrorCodes(result.errorCodes));
                    this.store.dispatch(new ModalToggle(cartValidationModalState));
                }
            },
            error: (_error: any) => {
                this.loaderService.clearLoadingText();
                this.store.dispatch(new CartErrorCodes(['HttpErrorResponse']));
                this.store.dispatch(new ModalToggle(cartValidationModalState));
            }
        });
    }
    public cartOrder(item) {
        if (this.isVnB2b()) {
            this.cartVouchers(item);
        } else {
            this.errorTranslationList.push({
                hasError: item.hasError,
                description: item.description,
                reason: item.description,
                reasonParameters: item.description,
            });
            this.store.dispatch(new CartError(this.errorTranslationList));
        }
    }

    private cartVouchers(item: any) {
        switch(item.description){
            case 'F00':
                this.pushErrorData(item.hasError, this.translateService.instant('shared.modals.cart-validation.error-F00'), item.reason, item.reasonParameters);
                break;
            case 'F01':
                this.pushErrorData(item.hasError, this.translateService.instant('shared.modals.cart-validation.error-F01'), item.reason, item.reasonParameters);
                break;
            case 'F02':
                this.pushErrorData(item.hasError, this.translateService.instant('shared.modals.cart-validation.error-F02'), item.reason, item.reasonParameters);
                break;
            case 'F03':
                this.pushErrorData(item.hasError, this.translateService.instant('shared.modals.cart-validation.error-F03'), item.reason, item.reasonParameters);
                break;
            case 'F04':
                this.pushErrorData(item.hasError, this.translateService.instant('shared.modals.cart-validation.error-F04'), item.reason, item.reasonParameters);
                break;
            case 'F05':
                this.pushErrorData(item.hasError, this.translateService.instant('shared.modals.cart-validation.error-F05'), item.reason, item.reasonParameters);
                break;
            case 'F06':
                this.pushErrorData(item.hasError, this.translateService.instant('shared.modals.cart-validation.error-F06'), item.reason, item.reasonParameters);
                break;
            case 'F07':
                this.pushErrorData(item.hasError, this.translateService.instant('shared.modals.cart-validation.error-F07'), item.reason, item.reasonParameters);
                break;
            case 'F08':
                let desc8: "";
                desc8 = this.translateService.instant('shared.modals.cart-validation.error-F08') + item.reasonParameters;
                this.pushErrorData(item.hasError, desc8, item.reason, item.reasonParameters);
                break;
            case 'F09':
                let desc9: "";
                desc9 = this.translateService.instant('shared.modals.cart-validation.error-F09') + item.reasonParameters;
                this.pushErrorData(item.hasError, desc9, item.reason, item.reasonParameters);
                break;
            case 'F10':
                let desc10: "";
                desc10 = this.translateService.instant('shared.modals.cart-validation.error-F10') + item.reasonParameters;
                this.pushErrorData(item.hasError, desc10, item.reason, item.reasonParameters);
                break;
            default:
                this.pushErrorData(item.hasError, item.reason, item.reason, item.reasonParameters);
                break;
        }
    }

    public pushErrorData(hasError: any, errorMessage: any, reason: any, reasonParam: any) {
        if(reason == undefined || reason.length < 0){
            reason = "";
        }

        if(reasonParam == undefined || reasonParam.length < 0){
            reasonParam = "";
        }

        this.errorTranslationList.push({
            hasError: hasError,
            description: errorMessage,
            reason: reason,
            reasonParameters: reasonParam,
        });

        this.store.dispatch(new CartError(this.errorTranslationList));
    }

    public isPlaceOrderInvalid(): Observable<boolean> {
        return combineLatest([
            this.settingsAppOnLine$,
            this.cartData$,
            this.cartItems$,
            this.store.pipe(select(cartRemarks)),
            this.user$,
            this.settingsStore$,
            this.haveCreateOrderPermission$,
            this.remarksFormService.remarksInvalid$,
            this.featuresService.checkFeatures(FeatureNames.OrganizationLevelCreditLimitCheck),
        ]).pipe(
            map(
                ([
                    isOnline,
                    cartDataModel,
                    cartItemsModel,
                    remarks,
                    userModel,
                    settingsStoreModel,
                    haveCreateOrderPermission,
                    remarksFormInvalid,
                    organizationLevelCreditLimitCheck,
                ]: [
                    boolean,
                    HotCartExtended,
                    HotCartLineItemExtended[],
                    HotCreateOrderRequest,
                    HotUserExtended,
                    SettingsStoreModel,
                    boolean,
                    boolean,
                    boolean
                ]) => {
                    let isBlocked = false;
                    isBlocked = userModel?.contact?.outlet?.isBlocked;

                    return (
                        (isOnline && !cartDataModel.isValid) ||
                        (isBlocked && settingsStoreModel.isOwnedByHeineken && !userModel.contact.outlet.isCashOutlet) ||
                        (isBlocked && organizationLevelCreditLimitCheck) ||
                        remarksFormInvalid ||
                        !cartItemsModel.length ||
                        !haveCreateOrderPermission ||
                        (settingsStoreModel.preferredDeliveryDateIsRequired && !remarks.preferredDeliveryDate) ||
                        (settingsStoreModel.hideInactiveProductsInCart &&
                            cartItemsModel.some((item) =>
                                item.validationErrors.some((error) => error.errorCode === 'UnavailableError')
                            ))
                    );
                }
            )
        );
    }

    public callAnalyticEventPOPPaymentError(showPaymentFailedError: boolean)
    {
        if(showPaymentFailedError)
        {
        let paymentError =   this.translateService.instant('shared.modals.payment-failed-pop.error-banner-text');
            this.analyticService.sendPOPPaymentErrorEventInfo(paymentError);
        }
    }

}
