/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotFulfillmentCenter } from '../models/hot-fulfillment-center';
import { HotDistributorSearchResult } from '../models/hot-distributor-search-result';
import { HotDistributorSearchCriteria } from '../models/hot-distributor-search-criteria';
import { HotOperationResult } from '../models/hot-operation-result';
@Injectable({
  providedIn: 'root',
})
class HotApiFulfillmentCentersService extends __BaseService {
  static readonly getAllAvailableFulfillmentCentersPath = '/storefrontapi/hot/fulfillment-centers';
  static readonly getAvailableFulfillmentCentersForAllOutletsPath = '/storefrontapi/hot/fulfillment-centers/all-outlets';
  static readonly searchUnavailableDistributorsPath = '/storefrontapi/hot/fulfillment-centers/search-unavailable';
  static readonly getUnavailableDistributorsPath = '/storefrontapi/hot/fulfillment-centers/search-unavailable';
  static readonly changeFulfillmentCenterPath = '/storefrontapi/hot/fulfillment-centers/current/{fulfillmentCenterId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param rewardCentersOnly undefined
   * @return Success
   */
  getAllAvailableFulfillmentCentersResponse(rewardCentersOnly?: boolean): __Observable<__StrictHttpResponse<Array<HotFulfillmentCenter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (rewardCentersOnly != null) __params = __params.set('rewardCentersOnly', rewardCentersOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/fulfillment-centers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotFulfillmentCenter>>;
      })
    );
  }
  /**
   * @param rewardCentersOnly undefined
   * @return Success
   */
  getAllAvailableFulfillmentCenters(rewardCentersOnly?: boolean): __Observable<Array<HotFulfillmentCenter>> {
    return this.getAllAvailableFulfillmentCentersResponse(rewardCentersOnly).pipe(
      __map(_r => _r.body as Array<HotFulfillmentCenter>)
    );
  }

  /**
   * @param rewardCentersOnly undefined
   * @return Success
   */
  getAvailableFulfillmentCentersForAllOutletsResponse(rewardCentersOnly?: boolean): __Observable<__StrictHttpResponse<Array<HotFulfillmentCenter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (rewardCentersOnly != null) __params = __params.set('rewardCentersOnly', rewardCentersOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/fulfillment-centers/all-outlets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotFulfillmentCenter>>;
      })
    );
  }
  /**
   * @param rewardCentersOnly undefined
   * @return Success
   */
  getAvailableFulfillmentCentersForAllOutlets(rewardCentersOnly?: boolean): __Observable<Array<HotFulfillmentCenter>> {
    return this.getAvailableFulfillmentCentersForAllOutletsResponse(rewardCentersOnly).pipe(
      __map(_r => _r.body as Array<HotFulfillmentCenter>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  searchUnavailableDistributorsResponse(body?: HotDistributorSearchCriteria): __Observable<__StrictHttpResponse<HotDistributorSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/fulfillment-centers/search-unavailable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotDistributorSearchResult>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  searchUnavailableDistributors(body?: HotDistributorSearchCriteria): __Observable<HotDistributorSearchResult> {
    return this.searchUnavailableDistributorsResponse(body).pipe(
      __map(_r => _r.body as HotDistributorSearchResult)
    );
  }

  /**
   * @param params The `HotApiFulfillmentCentersService.GetUnavailableDistributorsParams` containing the following parameters:
   *
   * - `PageSize`:
   *
   * - `PageNumber`:
   *
   * @return Success
   */
  getUnavailableDistributorsResponse(params: HotApiFulfillmentCentersService.GetUnavailableDistributorsParams): __Observable<__StrictHttpResponse<HotDistributorSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNumber != null) __params = __params.set('PageNumber', params.PageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/fulfillment-centers/search-unavailable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotDistributorSearchResult>;
      })
    );
  }
  /**
   * @param params The `HotApiFulfillmentCentersService.GetUnavailableDistributorsParams` containing the following parameters:
   *
   * - `PageSize`:
   *
   * - `PageNumber`:
   *
   * @return Success
   */
  getUnavailableDistributors(params: HotApiFulfillmentCentersService.GetUnavailableDistributorsParams): __Observable<HotDistributorSearchResult> {
    return this.getUnavailableDistributorsResponse(params).pipe(
      __map(_r => _r.body as HotDistributorSearchResult)
    );
  }

  /**
   * @param fulfillmentCenterId undefined
   * @return Success
   */
  changeFulfillmentCenterResponse(fulfillmentCenterId: string): __Observable<__StrictHttpResponse<HotOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/fulfillment-centers/current/${encodeURIComponent(String(fulfillmentCenterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotOperationResult>;
      })
    );
  }
  /**
   * @param fulfillmentCenterId undefined
   * @return Success
   */
  changeFulfillmentCenter(fulfillmentCenterId: string): __Observable<HotOperationResult> {
    return this.changeFulfillmentCenterResponse(fulfillmentCenterId).pipe(
      __map(_r => _r.body as HotOperationResult)
    );
  }
}

module HotApiFulfillmentCentersService {

  /**
   * Parameters for GetUnavailableDistributors
   */
  export interface GetUnavailableDistributorsParams {
    PageSize?: number;
    PageNumber?: number;
  }
}

export { HotApiFulfillmentCentersService }
