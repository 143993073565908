import { Component} from '@angular/core';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from '../../../services';

@Component({
    selector: 'hot-theme-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent {
    constructor(private readonly modalService: ModalService) {}

    public agreeConfirmation(closeWithSave: boolean) {
        this.modalService.toggleModal(ModalIds.confirmationModal, false, {
            closeWithSave,
        });
    }
}
