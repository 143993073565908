import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { ModalIds } from '@hot-libs/shared-types';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '@hot-b2b/store/reducers';

import { AdvanceFilterService } from 'apps/hot-b2b/src/app/shared/services/advance-filter.service';

@Component({
    selector: 'filter-selection-modal',
    templateUrl: './filter-selection-modal.component.html',
})
export class FilterSelectionModalComponent implements OnInit {
    @Input() filters;
    @Input() filterType = '';
    @Input() activeFilter;
    @Output() public closeFilterModal: EventEmitter<any> = new EventEmitter();
    @Output() public filterSelected: EventEmitter<any> = new EventEmitter();

    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    public resetFilter = false;
    public previouslySelectedFilter;

    private readonly unsubscribe$ = new Subject();
    modalFilterSelectionState$: Observable<ModalsState>;

    constructor(private readonly store: Store<AppState>, private readonly advanceFilterService: AdvanceFilterService) {
        this.modalFilterSelectionState$ = this.store.pipe(select(selectModalState(ModalIds.filterSelectionModal)));
    }

    ngOnInit(): void {
        this.modalFilterSelectionState$
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe((modalState: ModalsState) => {
                if (modalState.data?.filters) {
                    this.filters = modalState.data.filters;
                    if (this.filters.isMultiselect) {
                        this.filters.forEach((filter) => {
                            if (!filter['isChecked']) filter['isChecked'] = false;
                        });
                    }
                }
                if (modalState.data?.filterType) {
                    this.filterType = modalState.data.filterType;
                }
                if (modalState.data?.activeFilter) {
                    this.activeFilter = modalState.data.activeFilter;
                    this.previouslySelectedFilter = modalState.data.activeFilter;
                }
            });
    }

    addCurrency(filter) {
        return this.advanceFilterService.addCurrencySymbol(filter);
    }

    selectFilter(event, filter) {
        if (this.activeFilter && this.activeFilter == filter) {
            this.activeFilter = null;
        } else {
            this.activeFilter = filter;
        }
        event.preventDefault();
    }

    public filterIsSelected(item): boolean {
        return this.activeFilter?.includes(item);
    }

    public selectMultiFilter(filter, event): void {
        filter.isChecked = !filter.isChecked;
        if (filter.isChecked) {
            this.activeFilter.push(filter);
        } else {
            this.activeFilter = this.activeFilter.filter((item) => item !== filter);
        }
        event.preventDefault();
    }

    applyFilters() {
        let applyFilter = {
            filterName: this.filterType,
            selectedFilter: this.activeFilter,
        };
        this.filterSelected.emit(applyFilter);
        this.closeFilterModal.emit();
    }

    unselectAll() {
        this.resetFilter = true;
        this.activeFilter = null;
        this.applyFilters();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(undefined);
        this.unsubscribe$.complete();
    }
}
