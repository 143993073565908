import { ModalIds } from '@hot-libs/shared-types';
import { ModalsState } from '@hot-b2b/store/modals/model';

export const modalsInitialState: ModalsState[] = [
    { id: ModalIds.cacheWarning, isOpened: false },
    { id: ModalIds.cancelOrderConfirmation, isOpened: false },
    { id: ModalIds.cart, isOpened: false },
    { id: ModalIds.clearCartConfirmation, isOpened: false },
    { id: ModalIds.CannotUpdatePricesModal, isOpened: false },
    { id: ModalIds.clearCartConfirmationSingle, isOpened: false },
    { id: ModalIds.termsAndConditionsDeclined, isOpened: false },
    { id: ModalIds.privacyPolicyDeclined, isOpened: false },
    { id: ModalIds.disclaimer, isOpened: false },
    { id: ModalIds.distributorOrder, isOpened: false },
    { id: ModalIds.distributorOrderReject, isOpened: false },
    { id: ModalIds.emailEdit, isOpened: false },
    { id: ModalIds.feedbackSuccess, isOpened: false },
    { id: ModalIds.networkUnavailable, isOpened: false },
    { id: ModalIds.cartHasUnavailableProductsModal, isOpened: false },
    { id: ModalIds.cartHasChangedModal, isOpened: false },
    { id: ModalIds.offlineNotification, isOpened: false },
    { id: ModalIds.order, isOpened: false },
    { id: ModalIds.orderHasUnfinishedPayments, isOpened: false },
    { id: ModalIds.orderTemplates, isOpened: false },
    { id: ModalIds.outletNotFound, isOpened: false },
    { id: ModalIds.passwordExpired, isOpened: false },
    { id: ModalIds.passwordResetModal, isOpened: false },
    { id: ModalIds.remindMeLaterModal, isOpened: false },
    { id: ModalIds.phoneEdit, isOpened: false },
    { id: ModalIds.productDetails, isOpened: false },
    { id: ModalIds.reorderConfirmation, isOpened: false },
    { id: ModalIds.rewardConfirm, isOpened: false },
    { id: ModalIds.rewardProduct, isOpened: false },
    { id: ModalIds.rewardMechanics, isOpened: false },
    { id: ModalIds.rewardOrderResult, isOpened: false },
    { id: ModalIds.templateWarningOffline, isOpened: false },
    { id: ModalIds.termsAndConditions, isOpened: false },
    { id: ModalIds.cartValidation, isOpened: false },
    { id: ModalIds.unavailableInOffline, isOpened: false },
    { id: ModalIds.profileUpdate, isOpened: false },
    { id: ModalIds.notifyMe, isOpened: false },
    { id: ModalIds.faq, isOpened: false },
    { id: ModalIds.fastOrder, isOpened: false },
    { id: ModalIds.recommendedOrder, isOpened: false },
    { id: ModalIds.orderDetails, isOpened: false },
    { id: ModalIds.privacyPolicy, isOpened: false },
    { id: ModalIds.updateTermsAndConditions, isOpened: false },
    { id: ModalIds.updatePrivacyPolicy, isOpened: false },
    { id: ModalIds.distributorCatalogChangesSaved, isOpened: false },
    { id: ModalIds.distributorOrderConfirmed, isOpened: false },
    { id: ModalIds.distributorOrderConfirmEdit, isOpened: false },
    { id: ModalIds.addAmount, isOpened: false },
    { id: ModalIds.empties, isOpened: false },
    { id: ModalIds.rewardsTermsAndConditions, isOpened: false },
    { id: ModalIds.groupOrder, isOpened: false },
    { id: ModalIds.suggestedPromotions, isOpened: false },
    { id: ModalIds.orderExport, isOpened: false },
    { id: ModalIds.orderRating, isOpened: false },
    { id: ModalIds.ratingConfirmation, isOpened: false },
    { id: ModalIds.deliveryDetails, isOpened: false },
    { id: ModalIds.rewardMaintainance, isOpened: false },
    { id: ModalIds.imageZoom, isOpened: false },
    { id: ModalIds.payMethodModal, isOpened: false },
    { id: ModalIds.clearCartConfirmationConsignment, isOpened: false },
    { id: ModalIds.otpTechnicalIssue, isOpened: false },
    { id: ModalIds.kegsReturnPage, isOpened: false },
    { id: ModalIds.kegsHistoryPage, isOpened: false },
    { id: ModalIds.failedOutletRegistration, isOpened: false },
    { id: ModalIds.promotionRefreshAlert, isOpened: false },
    { id: ModalIds.tutorials, isOpened: false },
    { id: ModalIds.transitionConfirmation, isOpened: false },
    { id: ModalIds.orderCancelModal, isOpened: false },
    { id: ModalIds.insightModal, isOpened: false },
    { id: ModalIds.transitionConfirmation, isOpened: false },
    { id: ModalIds.voucherModal, isOpened: false },
    { id: ModalIds.ageConfirmationModal, isOpened: false },
    { id: ModalIds.distributorOrderConfirmReceivedCondition, isOpened: false },
    { id: ModalIds.confirmPartialDeliveryModal, isOpened: false },
    { id: ModalIds.distributorOrderRejectList, isOpened: false },
    { id: ModalIds.manageChildAccount, isOpened: false },
    { id: ModalIds.distributorOrdersDispatchedModal, isOpened: false },
    { id: ModalIds.distributorOrdersConfirmedModal, isOpened: false },
    { id: ModalIds.filterSelectionModal, isOpened: false },
    { id: ModalIds.pillSelectionModal, isOpened: false },
    { id: ModalIds.productPrice, isOpened: false },
    { id: ModalIds.confirmationModal, isOpened: false },
    { id: ModalIds.clearCartFromFileModal, isOpened: false },
    { id: ModalIds.updatePriceModal, isOpened: false },
    { id: ModalIds.distributorPricesTableModal, isOpened: false },
    { id: ModalIds.sdipGroupDetail, isOpened: false },
    { id: ModalIds.rejectListModal, isOpened: false },
    { id: ModalIds.removeSelectedItemCartConfirmationModal, isOpened: false },
    { id: ModalIds.customerOrderConfirmModal, isOpened: false },
    { id: ModalIds.informationModal, isOpened: false },
    { id: ModalIds.rewardVoucherTC, isOpened: false },
    { id: ModalIds.trackAndTraceModal, isOpened: false },
    { id: ModalIds.reportIssueConfirmation, isOpened: false },
    { id: ModalIds.reportIssueThanks, isOpened: false },
    { id: ModalIds.paymentConfirmation, isOpened: false },
    { id: ModalIds.paymentFailed, isOpened: false },
    { id: ModalIds.duplicatetabCheckoutWarning, isOpened: false },
    { id: ModalIds.digitalPaymentMethod, isOpened: false },
    { id: ModalIds.downloadInvoiceModal, isOpened: false },
    { id: ModalIds.distributorChangeModal, isOpened: false },
    { id: ModalIds.digitalAlternatePayment, isOpened: false },
    { id: ModalIds.basketOverlayPopup, isOpened: false },
    { id: ModalIds.confirmationOverlay, isOpened: false }

];
