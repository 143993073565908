export class CartErrorsCodeConstant {
    public static MINIMUM_ORDER_QUANTITY = 'hot-minimum-order-quantity-error';
    public static MAXIMUM_ORDER_QUANTITY = 'hot-maximum-order-quantity-error';
    public static MINIMUM_ORDER_TOTAL = 'hot-minimum-order-total-error';
    public static MAXIMUM_EMPTIES_RETURN_TOTAL = 'hot-empties-return-total-error';
    public static ONLY_RETURNABLE_EMPTIES = 'hot-only-returnable-empties-error';
    public static REQUIRE_LINE_ITEM_PRICE_GREATER_THAN_ZERO = 'hot-require-line-item-price-greater-than-zero-error';
    public static MIN_ORDER_QUANTITY = 'hot-min-quantity-error';
    public static MAX_ORDER_QUANTITY = 'hot-max-quantity-error';
    public static IN_STOCK_QUANTITY = 'hot-in-stock-quantity-error';
    public static MAX_PURCHASE_PER_TIME_PERIOD = 'hot-product-max-purchase-per-time-period-error';
    public static MINIMUM_ORDER_QUANTITY_PRODUCT_ERROR = 'hot-minimum-order-quantity-product-error';
    public static MULTIPLE_MINIMUM_NON_BACK_ORDER_QUANTITY_SETTING =
        'hot-multiple-minimum-non-back-order-quantity-setting-error';
    public static MINIMUM_ORDER_QUANTITY_CART_ERROR = 'hot-minimum-order-quantity-cart-error';
    public static PRODUCT_LIMIT_ORDER_QUANTITY_ERROR = 'hot-product-limit-order-quantity-error';
    public static PRODUCT_LIMIT_ORDER_QUANTITY_WARNING = 'hot-product-limit-order-quantity-warning';
    public static SPLIT_ORDER_ATLEAST_ONE_LINE_ITEM_PRICE_GREATER_THAN_ZERO =
        'hot-split-order-atleast-one-line-item-price-greater-than-zero';
}
