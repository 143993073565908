import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HotLegalAgreement, ApiConfiguration, HotApiLegalAgreementsService } from '@hot-theme-nx/generated-api';

@Injectable({
    providedIn: 'root',
})
export class LegalAgreementClient {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiConfiguration: ApiConfiguration,
        private readonly legalAgreementsApi: HotApiLegalAgreementsService
    ) {}

    public getTermsAndConditionsByLanguage(language: string): Observable<HotLegalAgreement> {
        return this.httpClient.get<HotLegalAgreement>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/legal-agreements/terms-and-conditions/${language}`
        );
    }

    public getPrivacyPolicyByLanguage(language: string): Observable<HotLegalAgreement> {
        return this.httpClient.get<HotLegalAgreement>(
            `${this.apiConfiguration.rootUrl}/storefrontapi/hot/legal-agreements/privacy-policy/${language}`
        );
    }

    public getCookiePolicyByLanguage(language: string): Observable<HotLegalAgreement> {
        return this.httpClient.get<HotLegalAgreement>(`/storefrontapi/hot/legal-agreements/cookie-policy/${language}`);
    }

    public getCartDisclaimerByLanguage(language: string): Observable<HotLegalAgreement> {
        return this.legalAgreementsApi.getCartDisclaimer(language);
    }

    public getDisclaimerByLanguage(language: string): Observable<HotLegalAgreement> {
        return this.legalAgreementsApi.getDisclaimer(language);
    }
}
