import { Injectable } from '@angular/core';
import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { HotApiDynamicAssociationsService, HotDynamicAssociationsEvaluateCriteria } from '@hot-theme-nx/generated-api';
import { HotCartLineItemExtended } from '../models';
import { HotProductRecommendationService } from 'libs/generated-api/src/lib/services/hot-product-recommendation.service';
import { settingsStore } from '@hot-b2b/store/settings/selector';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { AppState } from '@hot-b2b/store/reducers';
import { Store, select } from '@ngrx/store';
import { CartService } from '../../cart/services/cart.service';
import { StorageKeys } from '@hot-libs/shared-types';
@Injectable({
    providedIn: 'root',
})
export class RecommendedProductsService {
    public settingsStore$: Observable<SettingsStoreModel>;
    public productRecommendationLoader$ = new BehaviorSubject<boolean>(false);
    public storeId;
    constructor(
        private readonly apiDynamicAssociationsService: HotApiDynamicAssociationsService,
        private readonly apiProductRecommendation: HotProductRecommendationService,
        private readonly store: Store<AppState>,
        private readonly cartService: CartService
    ) {
        this.settingsStore$ = this.store.pipe(select(settingsStore));
        const fulfillmentCenter = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter));
        let storeId = fulfillmentCenter ? fulfillmentCenter.storeId : localStorage.getItem('storeId');
        this.storeId = storeId;
    }

    public SearchRecommendedProducts(
        cartItems: HotCartLineItemExtended[],
        EnableProductRecommendation: boolean
    ): Observable<HotProductExtended[]> {
        let recommendedProductsCount = 5;
        this.settingsStore$.pipe(take(1)).subscribe((value: SettingsStoreModel) => {
            recommendedProductsCount = value.recommendedProductsCount;
        });
        const fulfillmentCenter = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter));
        let storeId = fulfillmentCenter ? fulfillmentCenter.storeId : localStorage.getItem('storeId');
        
        if (EnableProductRecommendation) {
            const parms = {
                outletOuterId: localStorage.getItem(StorageKeys.outletExternalId),
                storeId: storeId || this.storeId || 0,
            };
            let searchedProductsObservable = this.apiProductRecommendation.getRecomendedProductsByIds(parms);
            return searchedProductsObservable.pipe(
                map((hotProducts) =>
                    hotProducts.products
                        .map((hotProduct) => <HotProductExtended>{ ...hotProduct })
                        .slice(0, recommendedProductsCount)
                )
            );
        } else {
            let productIds = cartItems.map((item) => item.productId);
            let crossSellingCriteria = <HotDynamicAssociationsEvaluateCriteria>{
                group: 'Related Items',
                productsToMatch: productIds,
            };
            let searchedProductsObservable = this.apiDynamicAssociationsService.getRecomendedProductsByIds(
                crossSellingCriteria
            );
            return searchedProductsObservable.pipe(
                map((hotProducts) => hotProducts.map((hotProduct) => <HotProductExtended>{ ...hotProduct }))
            );
        }
    }
}
