import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash/filter';

@Pipe({
    name: 'filterByValue',
})
export class FilterByValuePipe implements PipeTransform {
    public transform(data: any, value: string, propertyName: string): any {
        const filteredArray = filter(data, (item) => item[propertyName] === value);
        return value === undefined || value?.length === 0 || filteredArray?.length === 0 ? data : filteredArray;
    }
}
