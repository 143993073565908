//example from angular style guide
//export const mockHeroes   = ['Sam', 'Jill']; // prefer
//export const heroesUrl = 'api/heroes';    // prefer
//export const VILLAINS_URL = 'api/villains';  // tolerate
export class PATHS {
    public static ASSETS = '/assets';
    public static IMAGES = `${PATHS.ASSETS}/static/images`;
    public static SVG = `${PATHS.ASSETS}/static/svg`;
}

export class HotCookies {
    public static CURRENT_FULFILLMENT_CENTER = 'HOT-Current-Fulfillment-Center';
    public static CURRENT_STORE = 'HOT-Current-Store';
    public static CURRENT_USER_TYPE = 'HOT-Current-User-Type';
    public static CURRENT_OPTIMIZELY_ENDUSERID = 'HOT-Optimizely-EndUserID';
    public static ITEM_LIST_LOCATION = "item_list_name";
}
