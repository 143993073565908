import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HotOrderExtended } from '@hot-libs/shared-models';
import { StorageKeys } from '@hot-libs/shared-types';
import { HotCancelOrderRequest, HotOrderStatusReason, HotOrderStatusReasonsWithLanguage } from '@hot-theme-nx/generated-api';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { take } from 'rxjs/operators';
import {settingsData} from '@hot-b2b/store/settings/selector'

@Component({
    selector: 'hot-theme-distributor-reject-list-modal',
    templateUrl: './distributor-reject-list-modal.component.html',
})
export class DistributorRejectListModalComponent implements OnInit {
    @Input() public order: HotOrderExtended;
    @Input() public rejectReasons: HotOrderStatusReasonsWithLanguage[];
    @Output() public rejectedEvent: EventEmitter<HotCancelOrderRequest> = new EventEmitter();
    @Output() public backButtonClickEvent: EventEmitter<any> = new EventEmitter();

    public rejectReasonsForCurrentLanguage: HotOrderStatusReasonsWithLanguage;
    public customReasonText = '';
    public includeCustomReasonToRequest = false;
    public selectedReasons: string[] = [];
    public currentLanguageCode: string = localStorage.getItem(StorageKeys.currentLanguage);
    public settings$: Observable<HotSettingsExtended>;
    public featureEnableCHQIntegration: boolean;
    public otherReasonCancellationCode: string = '';

    constructor (private readonly translateService: TranslateService,private readonly store: Store<AppState>) {
        this.settings$ = this.store.pipe(select(settingsData));
    }
    ngOnInit(): void {
        this.otherReasonCancellationCode = this.translateService.instant('shared.modals.distributor-order-reject.reasonCodes.other');
        if (this.rejectReasons?.length) {
            this.rejectReasonsForCurrentLanguage =
                this.rejectReasons.find(
                    (reasonsWithLanguage: HotOrderStatusReasonsWithLanguage) =>
                        reasonsWithLanguage.language === this.currentLanguageCode
                ) ?? this.rejectReasons[0];
        } else {
            this.rejectReasonsForCurrentLanguage = {
                orderStatusReasons: [],
            };
        }
        this.settings$.pipe(take(1)).subscribe((settings: HotSettingsExtended) => {
            this.featureEnableCHQIntegration = settings.features.find((feature) => feature.name === "EnableCHQIntegration").isActive;
        });
    }

    public rejectOrder() {
        const rejectionReasons = [...this.selectedReasons];
        
        if (this.includeCustomReasonToRequest && this.customReasonText) {
            rejectionReasons.push(this.customReasonText);
        }
        if(this.featureEnableCHQIntegration) {
            const rejectionReasonsWithCodes : HotOrderStatusReason[] = []
            rejectionReasons.forEach((reason) => {
                let hotOrderStatusReason : HotOrderStatusReason = {};
                if(reason.includes(',')) {
                    reason = reason.split(',').join(' ');
                }
                hotOrderStatusReason.text = reason;
                this.rejectReasonsForCurrentLanguage.orderStatusReasons.forEach((reason) => {
                    if(reason.text === hotOrderStatusReason.text) {
                        hotOrderStatusReason.code = reason.code; 
                    }
                })
                if(hotOrderStatusReason.code === undefined) {
                    hotOrderStatusReason.code = this.otherReasonCancellationCode;
                }
                rejectionReasonsWithCodes.push(hotOrderStatusReason);
            })
            let cancelReasonStringForCHQ : string = "";
            rejectionReasonsWithCodes.forEach((reasonWithCode) => {
                cancelReasonStringForCHQ += reasonWithCode.text+','+reasonWithCode.code+'|';
            })
            cancelReasonStringForCHQ = cancelReasonStringForCHQ.slice(0,-1);
            const rejectRequest: HotCancelOrderRequest = {
                orderId: this.order.id,
                cancelReason: cancelReasonStringForCHQ,
            };
            this.rejectedEvent.emit(rejectRequest);
        }
        else {
            const rejectRequest: HotCancelOrderRequest = {
                orderId: this.order.id,
                cancelReason: rejectionReasons.join('|'),
            };
    
            this.rejectedEvent.emit(rejectRequest);
        }
    }

    public onReasonCheckboxChange(checked: boolean, reason: string) {
        if (checked) {
            this.selectedReasons.push(reason)   
        }
        else {
            this.selectedReasons = this.selectedReasons.filter((selectedReason) => selectedReason != reason);
        }
           
    }

    public onCustomReasonCheckboxChange(checked: boolean) {
        this.includeCustomReasonToRequest = checked;
    }
}
