import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { HotUserExtended } from '@hot-libs/shared-models';

import { UserService } from 'apps/hot-b2b/src/app/account/services';
import { AuthEdit } from '@hot-b2b/store/auth/actions';
import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { ModalService } from '../../../services';
import { ModalIds } from '@hot-libs/shared-types';

@Component({
    selector: 'hot-rewards-terms-modal',
    templateUrl: './rewards-terms-modal.component.html',
})
export class RewardsTermsModalComponent {
    @Input() public showModal: boolean;

    constructor(
        private readonly store: Store<AppState>,
        private readonly userService: UserService,
        private readonly router: Router,
        private readonly modalService: ModalService
    ) {
        this.user$ = this.store.pipe(select(authUser));
    }

    private readonly user$: Observable<HotUserExtended>;

    public agreeTermsAndConditions(isAgreed: boolean): void {
        this.modalService.toggleModal(ModalIds.rewardsTermsAndConditions, false);
        if (!isAgreed) {
            this.router.navigateByUrl('/');
        } else {
            this.userService
                .acceptRewardTerms()
                .pipe(withLatestFrom(this.user$))
                .subscribe(([_accepted, user]: [any, HotUserExtended]) => {
                    user.contact.isRewardTermsAccepted = true;
                    this.store.dispatch(new AuthEdit(user));
                    this.showModal = false;
                });
        }
    }
}
