import { Component,OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine, settingsSupport, settingsStore, settingsData } from '@hot-b2b/store/settings/selector';
import { ContactSupport, HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { AccountType, StorageKeys } from '@hot-libs/shared-types';
import { HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { authUser } from '@hot-b2b/store/auth/selector';
import { take } from 'rxjs/operators';
import { AccountService } from '@hot-theme-nx/common-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'hot-opco-info',
    templateUrl: './opco-info.component.html',
})
export class OpcoInfoComponent implements OnInit {
    public readonly accountType = AccountType;
    public subDistributorMode: string;
    public isCustomer: boolean;
    public email: string;
    public phoneNumber: string;
    public isEmailAvailable: boolean = true;
    public isPhoneNumberAvailable: boolean = true;

    public settingsAppOnLine$: Observable<boolean>;
    public settingsSupport$: Observable<ContactSupport>;
    public user$: Observable<HotUserExtended>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public hotSettings$: Observable<HotSettingsExtended>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly accountService: AccountService,
        private readonly translate: TranslateService
    ) {
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.settingsSupport$ = this.store.pipe(select(settingsSupport));
        this.user$ = this.store.pipe(select(authUser));
        this.hotSettings$ = this.store.pipe(select(settingsData));
        this.settingsStore$ = this.store.pipe(select(settingsStore));
    }

    public ngOnInit(): void {
        this.subDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);
        this.user$
            .pipe(take(1))
            .subscribe((user: HotUserExtended) => (this.isCustomer = this.accountService.isCustomer(user)));
        this.hotSettings$.subscribe((settings: HotSettingsExtended) => {
            this.email = settings.support.contactSupportEmail;
            this.phoneNumber = settings.support.contactSupportPhone;
            if (this.email == null || this.email == undefined || this.email.trim() == '') {
                this.isEmailAvailable = false;
            }
            if (this.phoneNumber == null || this.phoneNumber == undefined || this.phoneNumber.trim() == '') {
                this.isPhoneNumberAvailable = false;
            }
        });
    }
}
