import { DOCUMENT } from '@angular/common';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
    ChangeDetectionStrategy,
    OnChanges,
    SimpleChanges,
    OnInit,
    OnDestroy
} from '@angular/core';

import { HotCarouselBanner,HotSettings,} from '@hot-theme-nx/generated-api';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { NguCarouselConfig } from '@ngu/carousel';
import { Capacitor } from '@capacitor/core';
import { AppLauncher } from '@capacitor/app-launcher';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { settingsData } from '@hot-b2b/store/settings/selector';
import { AppState } from '@hot-b2b/store/reducers';
import {  map, takeUntil } from 'rxjs/operators';
import { GetSettingsSuccess } from 'libs/common-api/src/lib/store';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { Router } from '@angular/router';

@Component({
    selector: 'hot-overlay-carousel-eazle',
    templateUrl: './carousel-overlay-eazle.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselOverlayEazleComponent implements OnChanges, OnInit, OnDestroy {

    @Input() public carouselTileItems: HotCarouselBanner[] = [];
    public carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        speed: 400,
        interval: {
            timing: 3000,
            initialDelay: 1000,
        },
        point: {
            visible: true,
            hideOnSingleSlide: true,
        },
        loop: true,
        easing: 'ease',
        touch: true,
        RTL: this.document.documentElement.dir === 'rtl',
    };
    @Input() public active: string;
    @Input() public selectedCategoryId: string;
    @Output() public elOnClick: EventEmitter<any> = new EventEmitter();

    public isUpLg = () => this.screenDimensionService.upLg();

    private isValuesInitialized: boolean;
    public isUpSm = () => this.screenDimensionService.upSm();
    public isExternalUrl: boolean = false;
    public heroBannerTitleResponse: boolean;
    public hotSettings$: Observable<HotSettings>;
    private readonly subscriptionDestroy$ = new Subject<boolean>();
    public androidDownloadLink: string;
    public iosDownloadLink: string;
    public static readonly downloadLabel = "Download Now";
    public static readonly LearnMoreLabel = "Learn More";

    constructor(
        @Inject(DOCUMENT) private readonly document: any,
        public readonly screenDimensionService: ScreenDimensionService,
        private readonly store: Store<AppState>,
        private readonly analyticService: AnalyticService,
        private readonly router: Router,
    ) {
        this.hotSettings$ = this.store.pipe(select(settingsData));
     }

    ngOnInit(): void {
        this.hotSettings$
        .pipe(
            takeUntil(this.subscriptionDestroy$),
            map((settings) => GetSettingsSuccess({ settings }))
        )
        .subscribe((settings) => {
            this.iosDownloadLink = settings.settings.eazleIosAppDownloadLink;
            this.androidDownloadLink = settings.settings.eazleAndroidAppDownloadLink;
          
        });


    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.active?.currentValue &&
            changes.carouselTileItems?.currentValue.length &&
            !this.isValuesInitialized
        ) {
            this.isValuesInitialized = true;
        }
    }

    public openSite(item) {
        this.analyticService.heroBannerButtonClick(CarouselOverlayEazleComponent.downloadLabel);
        this.isExternalUrl = item.buttonTwoLink.startsWith('http://', 0) || item.buttonTwoLink.startsWith('https://', 0);

        if (this.isExternalUrl) {
            if ((Capacitor.getPlatform() === 'web')) {
                window.open(item.buttonTwoLink, '_blank');
                console.log("item button link", item.buttonLink);
            } else if (Capacitor.getPlatform() === 'android') {
                AppLauncher.openUrl({ url: this.androidDownloadLink });
            } else if (Capacitor.getPlatform() === 'ios') {
                AppLauncher.openUrl({ url: this.iosDownloadLink });
            }

        }
    }

    public learnMoreClick(item) { 
        this.analyticService.heroBannerButtonClick(CarouselOverlayEazleComponent.LearnMoreLabel);
        this.isExternalUrl = item.buttonLink.startsWith('http://', 0) || item.buttonLink.startsWith('https://', 0);
        if(this.isExternalUrl) {
            window.open(item.buttonLink, '_blank');
        }
        else {
            this.router.navigateByUrl(item.buttonLink);
        }
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }

}
