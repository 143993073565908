import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from '../../../services';
import { Observable } from 'rxjs';
import { take} from 'rxjs/operators';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { Store, select } from '@ngrx/store';
import { settingsStore } from '@hot-b2b/store/settings/selector';
import { AppState } from '@hot-b2b/store/reducers';

@Component({
    selector: 'hot-empties-modal',
    templateUrl: './empties-modal.component.html',
})
export class EmptiesModalComponent {
    public autoAddEmpties: boolean;
    private readonly settingsStore$: Observable<SettingsStoreModel>;
    constructor(private readonly modalService: ModalService, private readonly router: Router,private readonly store: Store<AppState>) {
        this.settingsStore$ = this.store.pipe(select(settingsStore));
        this.settingsStore$.pipe(take(1)).subscribe((value: SettingsStoreModel) => {
            this.autoAddEmpties = value.autoAddEmpties;
        });
    }

    public openEmptiesPage(): void {
        localStorage.setItem("auto-add-empties",JSON.stringify(this.autoAddEmpties));
        this.router.navigate(['empties']);
        this.modalService.toggleModal(ModalIds.empties, false);
    }

    public closeModal(): void {
        this.modalService.toggleModal(ModalIds.empties, false);
        this.router.navigate(['checkout']);
    }
}
