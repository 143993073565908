import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { authUser } from '@hot-b2b/store/auth/selector';
import { CartSetPaymentMethod } from '@hot-b2b/store/cart/actions';
import { cartPaymentMethod } from '@hot-b2b/store/cart/selector';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsBetaTesting, settingsPaymentMethods } from '@hot-b2b/store/settings/selector';
import { HotUserExtended } from '@hot-libs/shared-models';
import { ModalIds, StorageKeys } from '@hot-libs/shared-types';
import { ScreenDimensionService } from '@hot-theme-nx/common-api';
import { CurrencyFormat, HotPaymentMethod } from '@hot-theme-nx/generated-api';
import { select, Store } from '@ngrx/store';
import { OrderService } from 'apps/hot-b2b/src/app/order/services';
import { isUserBetaTester } from 'libs/common-api/src/lib/store/helpers';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { FeaturesService, ModalService } from '../../../services';
import { ConfigurationService } from '../../../services/configuration.service';
import { OrderCreationService } from '../../../services/order-creation.service';

@Component({
    selector: 'hot-paymethod-modal',
    templateUrl: './paymethod-modal.component.html',
})
export class PaymentMethodModalComponent implements OnInit {
    @Input() value;
    @Input() value2;
    @Output() paymentcode = new EventEmitter<any>();
    public isUserBetaTester: boolean;
    public condition: any;

    public paymentMethods$: Observable<HotPaymentMethod[]>;
    public user$: Observable<HotUserExtended>;
    private readonly subscriptionsDestroy$ = new Subject();
    public orderSuccess$: Observable<any>;
    public selectedPaymentMethod: HotPaymentMethod;
    public isFormValid: boolean = true;
    public currencyFormat: CurrencyFormat;
    public featureOrderPaymentsProcessing: boolean = this.featuresService.OrderPaymentsProcessing;
    public isUpLg = () => this.screenDimensionService.upLg();
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    public payMethodModalState$: Observable<ModalsState>;
    order: any;
    totAmount: any;
    selectedAmount: any;
    constructor(
        private readonly store: Store<AppState>,
        private readonly orderCreationService: OrderCreationService,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly router: Router,
        private readonly configurationService: ConfigurationService,
        private readonly featuresService: FeaturesService,
        private readonly orderService: OrderService,
        private readonly modalService: ModalService
    ) {
        this.paymentMethods$ = this.store.pipe(select(settingsPaymentMethods));
        this.user$ = this.store.pipe(select(authUser));
        this.orderSuccess$ = this.store.pipe(select(cartPaymentMethod));
        this.payMethodModalState$ = this.store.pipe(select(selectModalState(ModalIds.payMethodModal)));
    }

    public ngOnInit(): void {
        this.selectedAmount = this.orderService.orderNumber.displayedTotalAmount;
        this.payMethodModalState$.pipe(take(1)).subscribe((modalState: ModalsState) => {
            if (modalState.data) {
                this.order = modalState.data.order;
            }
        });

        this.orderCreationService.paymentMethodValid$.subscribe((isValid: boolean) => {
            this.isFormValid = isValid;
            if (!isValid) {
                this.scrollToPayment();
            }
        });
        this.user$
            .pipe(withLatestFrom(this.store.select(settingsBetaTesting)), takeUntil(this.subscriptionsDestroy$))
            .subscribe(([user, betaTesting]) => {
                const lastUsedPaymentMethod: string = localStorage.getItem(StorageKeys.lastUsedPaymentMethod);
                this.isUserBetaTester = isUserBetaTester(user, betaTesting?.userGroup);
                if (lastUsedPaymentMethod) {
                    const paymentMethod: HotPaymentMethod = JSON.parse(lastUsedPaymentMethod);
                    this.selectPaymentMethod(paymentMethod);
                }
            });
        if (this.isUpLg()) {
            this.scrollToPayment();
        }
    }

    private scrollToPayment(): void {
        setTimeout(() => {
            const el = document.getElementById('payment-method');
            if (el) {
                window.scrollTo({ top: el.offsetTop - 190 });
            }
        });
    }

    public selectPaymentMethod(paymentMethod: HotPaymentMethod): void {
        if (this.isPaymentMethodDisabled(paymentMethod)) {
            return;
        }

        this.selectedPaymentMethod = paymentMethod;
        this.store.dispatch(new CartSetPaymentMethod(paymentMethod));
        localStorage.setItem(StorageKeys.lastUsedPaymentMethod, JSON.stringify(paymentMethod));
        this.isFormValid = true;
    }

    public pay() {
        this.paymentcode.emit(this.selectedPaymentMethod.code);
        if (this.selectedPaymentMethod.code == 'Dlocal.ExpressCheckout') {
            this.payDlocalExpressCheckout();
        } else if (this.selectedPaymentMethod.code != 'Dlocal.ExpressCheckout') {
            if (
                this.featureOrderPaymentsProcessing &&
                this.selectedPaymentMethod.paymentHandlingOrderPhase === 'Checkout'
            ) {
                if (this.order.number) {
                    this.router.navigate(['/payment-process', this.order.number]);
                } else {
                    this.router.navigate(['/payment-process', this.order.orderNumber]);
                }
            } else {
                this.router.navigate(['/thank-you', this.order.orderNumber]);
            }
        }
        return this.order.orderNumber;
    }
    public payDlocalExpressCheckout() {
        if (
            this.featureOrderPaymentsProcessing &&
            this.selectedPaymentMethod.paymentHandlingOrderPhase === 'Checkout'
        ) {
            this.orderService.assignOrderNumber(this.order.number);
            this.router.navigate(['/smart-field']);
        }
        else if (this.order.number) {
                this.router.navigate(['/payment-process', this.order.number]);
        }
        else {
                this.router.navigate(['/payment-process', this.order.orderNumber]);
        }       
    }
    public isPaymentMethodDisabled(paymentMethod: HotPaymentMethod): boolean {
        return this.isUserBetaTester && paymentMethod.paymentHandlingOrderPhase === 'Checkout';
    }

    public closeModal(): void {
        this.modalService.toggleModal(ModalIds.payMethodModal, false);
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(undefined);
        this.subscriptionsDestroy$.complete();
    }
}
