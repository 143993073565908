import { Component } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { ModalIds } from '@hot-libs/shared-types';

@Component({
    selector: 'hot-theme-nx-distributor-orders-confirmed-modal',
    templateUrl: './distributor-orders-confirmed-modal.component.html'
})
export class DistributorOrdersConfirmedModalComponent {

    constructor(private readonly modalService: ModalService) { }

    public close(): void {
        this.modalService.toggleModal(ModalIds.distributorOrdersConfirmedModal, false);
    }

}
