import { ApplicationMode } from '@hot-libs/shared-models';
import { StorageKeys } from '@hot-libs/shared-types';

export const isApplicationOnline = () => {
    let isOnline = true;
    const storedApplicationMode = localStorage.getItem(StorageKeys.appMode);
    if (storedApplicationMode) {
        const applicationMode: ApplicationMode = JSON.parse(storedApplicationMode);
        isOnline = applicationMode.online;
    }
    return isOnline;
};
