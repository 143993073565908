export class AnalyticLocationConstant {
    public static readonly CATALOG_PAGE = 'catalog page';
    public static readonly BRAND_PAGE = 'brand page';
    public static readonly PRODUCT_DETAIL_PAGE = 'product detail';
    public static readonly RELATED_PRODUCTS = 'related products';
    public static readonly PROMOTIONS_LATEST = 'latest promotions';
    public static readonly PROMOTIONS_LIST = 'promotions list';
    public static readonly CHECKOUT_PAGE = 'checkout page';
    public static readonly CART_SIDEBAR = 'sidebar cart';
    public static readonly ORDER_TEMPLATES_ADD_TO_CART = 'order templates add to cart';
    public static readonly POPULAR_PRODUCTS_PAGE = 'popular products page';
    public static readonly DATA_SOURCE_WEB = 'web';
    public static readonly DATA_SOURCE_APP = 'app';
    public static readonly CART = 'cart'
}
