import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FeaturesService } from '../../../shared/services/features.service';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
})
export class NotFoundComponent {
    constructor(private readonly featureService: FeaturesService, private readonly router: Router) {}
    public ngOnInit(): void {        
        if (this.featureService.DashboardHomePage && this.router.url === '/ordering') {
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                this.router.navigate(['ordering']);
            });
        }
    }
}
