import { createSelector, MemoizedSelector } from '@ngrx/store';

import { HotCarouselBanner } from '@hot-theme-nx/generated-api';

import { AppState } from '@hot-b2b/store/reducers';
import { CarouselsState } from './model';

const stateCarousels = (state: AppState): CarouselsState  => state.carousels;

export const carouselFetched: MemoizedSelector<AppState, boolean> = createSelector(
    stateCarousels,
    (state: CarouselsState) => state.fetched
);

export const carouselDetails: MemoizedSelector<AppState, HotCarouselBanner[]> = createSelector(
    stateCarousels,
    (state: CarouselsState ) => state.data
);
