import { Injectable } from '@angular/core';

import { HotOrderExtended, HotOrderLineItemExtended } from '@hot-libs/shared-models';
import { ProductStockStatus } from '@hot-libs/shared-types';

import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { HotProductRecommendation } from 'libs/generated-api/src/lib/models/hot-product-recommendation';
import { HotCarouselBanner, OrderSuggestionProduct } from 'libs/generated-api/src/lib/models';
import {
    AnalyticEvent,
    AnalyticFastOrderModalEvent,
    AnalyticProductModel,
    AnalyticFastOrderProductModel,
    AnalyticCarousel,
    AnalyticEventGoogleAnalytic,
    AnalyticPageModel,
    AnalyticPRProductModel,
} from 'apps/hot-b2b/src/app/shared/models/analytic.model';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services/features.service';
import { StoreService } from 'apps/hot-b2b/src/app/shared/services/store.service';
import { settingsCurrencyFormat } from '@hot-b2b/store/settings/selector';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { NativeService } from '../../native/services';
import { AnalyticLocationConstant } from '@hot-theme-nx/common-api';
import { ItemLocationService } from './Item-location.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticService {
    private readonly category = 'page interactions';
    private readonly categoryErrorMessage = 'error message';
    private readonly categoryName = 'ecommerce';
    private readonly checkoutEventName = 'eecCheckout';
    private readonly purchaseEventName = 'eecPurchase';
    private readonly promoClickEventName = 'eecPromoClick';
    private readonly massOrderSelectFileClickEventName = 'mass_upload_select_file';
    private readonly massOrderReplaceFileClickEventName = 'mass_upload_replace_file';
    private readonly massOrderDownloadTemplateSheetClickEventName = 'mass_upload_download_template_sheet';
    private readonly massOrderDownloadErrorLogClickEventName = 'mass_upload_download_error_log';
    private readonly massOrderAddMoreProductClickEventName = 'mass_upload_add_more_products';
    private readonly massOrderErrorMessageClickEventName = 'error_message';
    private readonly massOrderClickEventName = 'mass_upload_order';
    private readonly promoViewEventName = 'eecPromoView';
    private readonly filterViewEventName = 'eecFilterView';
    private readonly productDetailViewEventName = 'eecProductDetailView';
    private readonly addToCartEventName = 'eecAddToCart';
    private readonly removeFromCartEventName = 'eecRemoveFromCart';
    private readonly deleteAllFromCartEventName = 'eecDeleteAllFromCart';
    private readonly submitAllFormEvent = 'eeFormSubmit';
    private readonly ErrorMessageEvent = 'error_message';
    private readonly heroBannerEvent = 'migration_banner_cta_click';
    private readonly productClickEvent = 'eecProductClick';
    private readonly clickErrorSelectOtherPaymentMethod = 'click_error_select_other_payment_method';
    private readonly clickOrderNotificationDetails = 'click_order_notification_details';
    private readonly clickOrderNotificationClose = 'click_order_notification_close';

    public formSubmitError: any[] = [];
    public recommenderKey = "recommendationKey";
    add: any;
    public applicationSource = {};

    constructor(
        private readonly featuresService: FeaturesService,
        private readonly storeService: StoreService,
        private readonly nativeService: NativeService,
        private readonly itemLocationService: ItemLocationService
    ) {
        this.applicationSource = this.getDataSource();
    }

    private getRecommenderKey(skuId: string): string {
        const recommendationKeyValue = JSON.parse(localStorage.getItem(this.recommenderKey)) ?? {};
        return recommendationKeyValue[skuId];
    }
    private setRecommenderKey(skuId: string, recId: string) {
        const recommendationKeyValue = JSON.parse(localStorage.getItem(this.recommenderKey)) ?? {};
        recommendationKeyValue[skuId] = recId;        
        localStorage.setItem(this.recommenderKey, JSON.stringify(recommendationKeyValue));
    }
 
    private clearAllRecommenderKey() {   
        localStorage.removeItem(this.recommenderKey);
    }
 
    private clearRecommenderKey(skuId: string) {
        const recommendationKeyValue = JSON.parse(localStorage.getItem(this.recommenderKey)) ?? {};
        if (recommendationKeyValue[skuId] != null) {
            delete recommendationKeyValue[skuId];
            localStorage.setItem(this.recommenderKey, JSON.stringify(recommendationKeyValue));
        }
    }
    public sendCheckoutEventInfo(
        cartItems: HotCartLineItemExtended[],
        stepNumber: number,
        cartSubTotal: number,
        cartQuantity: number
    ): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        const cartProducts: AnalyticProductModel[] = cartItems.map((item: HotCartLineItemExtended) => {
            const analyticProduct: AnalyticProductModel = this.mapToAnalyticProductModel(item);
            const currencyFormat = this.storeService.getSelector(settingsCurrencyFormat);
            const currencyDivider = Math.pow(10, currencyFormat.decimalDigits);
            analyticProduct.item_list_name = this.itemLocationService.getItemLocationFromStorage(item.sku)?.location;
            analyticProduct.item_location = this.itemLocationService.getItemLocationFromStorage(item.sku)?.location;
            analyticProduct.price= (item?.salePrice / currencyDivider)
            .toFixed(currencyFormat.decimalDigits)
            .toString();
            analyticProduct.quantity = item?.quantity;
            const recommendationKeyValue =JSON.parse(localStorage.getItem(this.recommenderKey)) ?? {}
            if(recommendationKeyValue[item.sku] !=="" && recommendationKeyValue[item.sku] !== undefined ){
            analyticProduct.pr_recommender_key = this.getRecommenderKey(item.sku)
        }
            return analyticProduct;
        });

        window.digitalData.ecommerce = {
            checkout: {
                actionField: {
                    step: stepNumber,
                    subtotal: cartSubTotal,
                    cartQuantity: cartQuantity,
                },
                products: cartProducts,
            },
            events: this.getAnalyticEventModel('checkout step ' + stepNumber),
            event: this.checkoutEventName,
        };
        window.callBootstrapperTrigger(this.checkoutEventName);
    }

    public sendFormValidationsErrorInfo(errorResponse: string, formName: string): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        if (window.digitalData.forminformation) {
            delete window.digitalData.forminformation;
        }
        window.digitalData.errormessage = {
            events: this.getAnalyticEventsModel('error message', formName, errorResponse),
            event: this.ErrorMessageEvent,
        };

        window.callBootstrapperTrigger(this.ErrorMessageEvent);
    }

    public sendFormSelfActivation(formName: string): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        if (window.digitalData.forminformation) {
            delete window.digitalData.forminformation;
        }
        window.digitalData = {
            events: this.getAnalyticEventsModel('form submit', 'successful', formName),
            event: this.submitAllFormEvent,
        };

        window.callBootstrapperTrigger(this.submitAllFormEvent);
    }

    public sendPurchaseEventInfo(order: HotOrderExtended, paymentmethod: string): void {
        if (!this.featuresService.GoogleAnalytics || window.digitalData.event == this.ErrorMessageEvent) {
            return;
        }
        
        const currencyFormat = this.storeService.getSelector(settingsCurrencyFormat);
        const currencyDivider = Math.pow(10, currencyFormat.decimalDigits);
        let SkuMixArray = [];
        const orderProducts: AnalyticProductModel[] = order.items.map((item: HotOrderLineItemExtended) => {
            const analyticProduct: AnalyticProductModel = this.mapToAnalyticProductModel(item);
            analyticProduct.price = (item.placedPrice / currencyDivider)
                .toFixed(currencyFormat.decimalDigits)
                .toString();
            analyticProduct.quantity = item.quantity;
            analyticProduct.item_list_name = this.itemLocationService.getItemLocationFromStorage(item.sku)?.location;
            analyticProduct.item_location = this.itemLocationService.getItemLocationFromStorage(item.sku)?.location;
            SkuMixArray.push(item.sku);
            const recommendationKeyValue =JSON.parse(localStorage.getItem(this.recommenderKey)) ?? {}            
            if(recommendationKeyValue[item.sku] !=="" && recommendationKeyValue[item.sku] !== undefined ){
            analyticProduct.pr_recommender_key = this.getRecommenderKey(item.sku)
            }   
            return analyticProduct;
        });
        const discountTypes = order?.rewardTypes?.join(', ');
        let skuUniqueMix = new Set(SkuMixArray);

        window.digitalData.ecommerce = {
            purchase: {
                actionField: {
                    id: order.number,
                    affiliation: order.outletName,
                    revenue: (order.extendedPriceTotal / currencyDivider)
                        .toFixed(currencyFormat.decimalDigits)
                        .toString(),
                    coupon: order.couponApplied?.length ? order.couponApplied : null,                     
                    discountType: discountTypes ? discountTypes : null,
                    tax: (order.taxTotal / currencyDivider).toFixed(currencyFormat.decimalDigits).toString(),
                    paymentMethod: paymentmethod,
                    distributorRemarks: order?.comment ? order?.comment : 'no',
                    skuMix: skuUniqueMix.size,
                },
                products: orderProducts,
            },
            events: this.getAnalyticEventModel('purchase'),
            event: this.purchaseEventName,
        };
        window.callBootstrapperTrigger(this.purchaseEventName);
        this.clearAllRecommenderKey()
    }

    public sendPromoClickEventInfo(item: HotCarouselBanner): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        window.digitalData.ecommerce = {
            promoClick: {
                promotions: [
                    {
                        name: item.name,
                        id: item.id,
                        creative: item.objectId ? 'promo banner' : 'content banner',
                        position: 'Home Page',
                    },
                ],
            },
            events: this.getAnalyticEventsModel('ecommerce', 'promo click', item.name),
            event: this.promoClickEventName,
        };

        window.callBootstrapperTrigger(this.promoClickEventName);
    }

    public sendMassOrderSelectFileClickEventInfo(): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        window.digitalData.events = {
            events: this.getAnalyticPageInteractionEventModel('mass upload select file'),
            event: this.massOrderSelectFileClickEventName,
        };

        window.callBootstrapperTrigger(this.massOrderSelectFileClickEventName);
    }

    public sendMassOrderReplaceFileClickEventInfo(): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        window.digitalData.events = {
            events: this.getAnalyticPageInteractionEventModel('mass upload replace file'),
            event: this.massOrderReplaceFileClickEventName,
        };

        window.callBootstrapperTrigger(this.massOrderReplaceFileClickEventName);
    }

    public sendMassOrderDownloadTemplateSheetClickEventInfo(): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        window.digitalData.events = {
            events: this.getAnalyticPageInteractionEventModel('mass upload download template sheet'),
            event: this.massOrderDownloadTemplateSheetClickEventName,
        };

        window.callBootstrapperTrigger(this.massOrderDownloadTemplateSheetClickEventName);
    }

    public sendClickEventInfo(eventInfo: string): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        if (window.digitalData.ecommerce || window.digitalData.page) {
            delete window.digitalData.ecommerce;
            delete window.digitalData.page;
        }

        window.digitalData.events = {
            event: eventInfo,
        };

        window.callBootstrapperTrigger(eventInfo);
    }

    public sendMassOrderDownloadErrorLogClickEventInfo(): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        window.digitalData.events = {
            events: this.getAnalyticPageInteractionEventModel('mass upload download error log'),
            event: this.massOrderDownloadErrorLogClickEventName,
        };

        window.callBootstrapperTrigger(this.massOrderDownloadErrorLogClickEventName);
    }

    public sendMassOrderAddMoreProductsClickEventInfo(): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        window.digitalData.events = {
            events: this.getAnalyticPageInteractionEventModel('mass upload add more products'),
            event: this.massOrderAddMoreProductClickEventName,
        };

        window.callBootstrapperTrigger(this.massOrderAddMoreProductClickEventName);
    }

    public sendMassOrderErrorMessageClickEventInfo(message: string): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        window.digitalData.events = {
            events: this.getAnalyticEventsModel(this.categoryErrorMessage, 'mass order upload page', message),
            event: this.massOrderErrorMessageClickEventName,
        };

        window.callBootstrapperTrigger(this.massOrderErrorMessageClickEventName);
    }

    public sendMassOrderClickEventInfo(message: string): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        window.digitalData.events = {
            events: this.getAnalyticEventsModel(this.category, 'mass upload order', message),
            event: this.massOrderClickEventName,
        };

        window.callBootstrapperTrigger(this.massOrderClickEventName);
    }

    public sendPromoViewEventInfo(data: HotCarouselBanner[]): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        const promoDetails: AnalyticCarousel[] = data.map((item: HotCarouselBanner) => this.mapToBanner(item));
        let carouselLabel;
        for (let index = 0; index < data.length; index++) {
            if (carouselLabel == null || undefined) {
                carouselLabel = data[index].name + ',';
            } else {
                carouselLabel += index == data.length - 1 ? data[index].name : data[index].name + ',';
            }
        }
        window.digitalData.ecommerce = {
            promoView: {
                promotions: promoDetails,
            },
            events: this.getAnalyticEventsModel('ecommerce', 'promo impressions', carouselLabel),
            event: this.promoViewEventName,
        };

        window.callBootstrapperTrigger(this.promoViewEventName);
    }

    public sendPOPPaymentErrorEventInfo(errorResponse: string) {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        if (window.digitalData.ecommerce) {
            delete window.digitalData.ecommerce;
        }
        window.digitalData = {
            events: this.getAnalyticEventsModel('error message', 'payment failed', errorResponse),
            event: this.ErrorMessageEvent,
        };
        window.callBootstrapperTrigger(this.ErrorMessageEvent);
    }

    public ifSelectedAnotherPaymentEventInfo() {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        window.digitalData = {
            events: this.getAnalyticEventsModel('page interactions', 'click error select other payment method', ''),
            event: this.clickErrorSelectOtherPaymentMethod,
        };
        window.callBootstrapperTrigger(this.clickErrorSelectOtherPaymentMethod);
    }
    public selectingActiveBannerSeeDetails() {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        window.digitalData = {
            event: this.clickOrderNotificationDetails,
        };
        window.callBootstrapperTrigger(this.clickOrderNotificationDetails);
    }

    public closingActiveBannerSeeDetails() {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        window.digitalData = {
            event: this.clickOrderNotificationClose,
        };
        window.callBootstrapperTrigger(this.clickOrderNotificationClose);
    }


    public sendFilterOrders(filterAttributes: any[]) {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        let valueOrder = [],
            valueData = [],
            valueType = [],
            valueStatus = [];

        for (let i of filterAttributes[0].filterAttributes) {
            if (i.name == 'ordernumber') {
                valueOrder.push(i.filter);
            } else if (i.name == 'orderstatus') {
                valueStatus.push(i.value[0]);
            } else if (i.name == 'ordertype') {
                valueType.push(i.filter[0]);
            } else if (i.name == 'date') {
                valueData.push(i.value);
            }
        }

        if (valueType.length > 0 && valueOrder[0] != '') {
            window.digitalData.filterInteractionOrders = {
                events: {
                    category: 'filter interaction orders',
                    action: 'filter applied orders',
                },
                filterAttributes: [
                    {
                        name: 'ordernumber',
                        filter: valueOrder[0],
                    },
                    {
                        name: 'orderstatus',
                        value: valueStatus[0],
                    },
                    {
                        name: 'ordertype',
                        value: valueType[0],
                    },
                    {
                        name: 'date',
                        value: valueData[0],
                    },
                ],
            };
        } else if (valueType.length > 0 && valueOrder[0] == '') {
            window.digitalData.filterInteractionOrders = {
                events: {
                    category: 'filter interaction orders',
                    action: 'filter applied orders',
                },
                filterAttributes: [
                    {
                        name: 'orderstatus',
                        value: valueStatus[0],
                    },
                    {
                        name: 'ordertype',
                        value: valueType[0],
                    },
                    {
                        name: 'date',
                        value: valueData[0],
                    },
                ],
            };
        } else if (valueType.length == 0 && valueOrder[0] != '') {
            window.digitalData.filterInteractionOrders = {
                events: {
                    category: 'filter interaction orders',
                    action: 'filter applied orders',
                },
                filterAttributes: [
                    {
                        name: 'ordernumber',
                        filter: valueOrder[0],
                    },
                    {
                        name: 'orderstatus',
                        value: valueStatus[0],
                    },
                    {
                        name: 'date',
                        value: valueData[0],
                    },
                ],
            };
        } else if (valueType.length == 0 && valueOrder[0] == '') {
            window.digitalData.filterInteractionOrders = {
                events: {
                    category: 'filter interaction orders',
                    action: 'filter applied orders',
                },
                filterAttributes: [
                    {
                        name: 'orderstatus',
                        value: valueStatus[0],
                    },
                    {
                        name: 'date',
                        value: valueData[0],
                    },
                ],
            };
        }

        window.callBootstrapperTrigger(this.filterViewEventName);
    }

    private mapToBanner(details: HotCarouselBanner): AnalyticCarousel {
        return {
            name: details.name,
            id: details.id,
            creative: details.objectId ? 'promo banner' : 'content banner',
            position: 'Home Page',
        };
    }

    public sendProductDetailInfo(product: HotProductExtended): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        window.digitalData.ecommerce = {
            detail: {
                products: [this.mapToAnalyticProductModel(product)],
            },
            events: this.getAnalyticEventModel('product detail view'),
            event: this.productDetailViewEventName,
        };

        window.callBootstrapperTrigger(this.productDetailViewEventName);
    }

    public sendAddCartEventInfo(products?: any[], location?: string, isCartUpdate = false, addMethod?: string): void {
        this.add = addMethod;
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        const cartProducts: AnalyticProductModel[] = products?.map(
            (item: HotProductExtended | HotCartLineItemExtended) => {
                const analyticProduct: AnalyticProductModel = this.mapToAnalyticProductModel(item);
                const currencyFormat = this.storeService.getSelector(settingsCurrencyFormat);
                const currencyDivider = Math.pow(10, currencyFormat.decimalDigits);
                analyticProduct.item_location = location;
                analyticProduct.addMethod = addMethod;
                analyticProduct.price= (item?.salePrice / currencyDivider)
                .toFixed(currencyFormat.decimalDigits)
                .toString();
                analyticProduct.quantity = item.quantity;
                analyticProduct.item_list_name = this.itemLocationService.getItemLocationFromStorage(
                    item.sku
                )?.location;

                if (isCartUpdate) {
                    analyticProduct.cartUpdate = 1;
                }

                if (
                    (item as HotCartLineItemExtended).validationErrors &&
                    (item as HotCartLineItemExtended).validationErrors.length
                ) {
                    analyticProduct.availability = ProductStockStatus.NoStock;
                }

                return analyticProduct;
            }
        );

        window.digitalData.ecommerce = {
            add: {
                products: cartProducts,
            },
            events:
                location == 'order modal'
                    ? this.getAnalyticEventsModel('ecommerce', 'add-to-cart', 'reorder')
                    : this.getAnalyticEventModel('add-to-cart'),
            event: this.addToCartEventName,
        };
        window.callBootstrapperTrigger(this.addToCartEventName);
    }

    public sendAddCartCaroucel(_event?: any, _product?: any, recommendation_position?: string ,featureLocation?:string): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        if(_product.hotRecommendedProductId){
        this.setRecommenderKey(_product.sku, _product.hotRecommendedProductId);
        }
        const currencyFormat = this.storeService.getSelector(settingsCurrencyFormat);
        const currencyDivider = Math.pow(10, currencyFormat.decimalDigits);
        window.digitalData.ecommerce = {
            add: {
                products: [
                    {
                        name: _product.name,
                        id: _product.id,
                        brand: _product.brand,
                        category: _product.type,
                        variant: '',
                        availability:
                            (_product as HotProductExtended).stockStatus != undefined
                                ? (_product as HotProductExtended).stockStatus
                                : '',
                        item_location: 'recommendations modal',
                        cartUpdate: 1,
                        addMethod: _event.addMethod,
                        unitLoad: '',
                        placement: 'carousel-basketpage',
                        price:((_product as HotProductExtended)?.salePrice/ currencyDivider)
                        .toFixed(currencyFormat.decimalDigits)
                        .toString(),
                        quantity:_product?.quantity,
                        alternative_product: '',
                        recommendation_position: recommendation_position,
                        recommendedProductId: _product?.hotRecommendedProductId,
                        item_list_name: featureLocation,
                        pr_recommended_material_key: _product.sku,
                        pr_recommender_key: _product.hotRecommendedProductId
                    },
                ],
            },
            events: {
                category: 'ecommerce',
                action: 'add-to-cart',
                label: '',
            },
            event: 'eecAddToCart',
        };
        window.callBootstrapperTrigger(this.addToCartEventName);
    }

    public sendRemoveCartEventInfo(
        product?: HotProductExtended | HotCartLineItemExtended,
        location?: string,
        addMethod?: string
    ): void {
        this.add = addMethod;
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        const analyticProduct: AnalyticProductModel = this.mapToAnalyticProductModel(product);
        const currencyFormat = this.storeService.getSelector(settingsCurrencyFormat);
        const currencyDivider = Math.pow(10, currencyFormat.decimalDigits);
        analyticProduct.item_location = location;
        analyticProduct.addMethod = addMethod;
        analyticProduct.price= (product?.salePrice / currencyDivider)
        .toFixed(currencyFormat.decimalDigits)
        .toString();
        analyticProduct.quantity = (product as HotProductExtended).quantity;
        if(this.getRecommenderKey((product as HotProductExtended).sku)){
        analyticProduct.pr_recommender_key= this.getRecommenderKey((product as HotProductExtended).sku);
        }   
        analyticProduct.item_list_name = this.itemLocationService.getItemLocationFromStorage(
            product.sku
        )?.removedFromPage;

        window.digitalData.ecommerce = {
            remove: {
                products: [analyticProduct],
            },
            events: this.getAnalyticEventModel('remove-from-cart'),
            event: this.removeFromCartEventName,
        };
        this.clearRecommenderKey(product.sku)
        window.callBootstrapperTrigger(this.removeFromCartEventName);
    }

    
    public sendRemoveCartCarousel(
        product?: HotProductExtended | HotCartLineItemExtended | HotProductRecommendation,
        location?: string,
        addMethod?: string,
        featureLocation?:string
    ): void {
        this.add = addMethod;
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        this.clearRecommenderKey(product.sku)
        const analyticProduct: AnalyticProductModel = this.mapToAnalyticProductModel(product);
        const currencyFormat = this.storeService.getSelector(settingsCurrencyFormat);
        const currencyDivider = Math.pow(10, currencyFormat.decimalDigits);
        analyticProduct.item_location = location;
        analyticProduct.addMethod = addMethod;
        analyticProduct.pr_recommended_material_key = product.sku;
        analyticProduct.price= (product?.salePrice / currencyDivider)
        .toFixed(currencyFormat.decimalDigits)
        .toString();
        analyticProduct.quantity = (product as HotProductExtended).quantity;
        analyticProduct.pr_recommender_key = (product as HotProductRecommendation).hotRecommendedProductId;
        analyticProduct.item_list_name = featureLocation;
 
       
        window.digitalData.ecommerce = {
            remove: {
                products: [analyticProduct],
            },
            events: this.getAnalyticEventModel('remove-from-cart'),
            event: this.removeFromCartEventName,
        };

        window.callBootstrapperTrigger(this.removeFromCartEventName);
    }

    private mapToAnalyticProductModel(
        product: HotProductExtended | HotOrderLineItemExtended | HotCartLineItemExtended
    ): AnalyticProductModel {
        return {
            id: product.sku,
            name: product.name,
            brand: product.brandGroup,
            category: product.type,
            availability: (product as HotProductExtended).stockStatus,
        };
    }

    private getAnalyticEventModel(name: string): AnalyticEvent {
        return {
            category: this.categoryName,
            action: name,
        };
    }

    private getAnalyticPageInteractionEventModel(name: string): AnalyticEvent {
        return {
            category: this.category,
            action: name,
        };
    }

    private getAnalyticEventsModel(category?: string, name?: string, label?: [] | string): AnalyticEvent {
        return {
            category: category || this.categoryName,
            action: name,
            label: label ? label : null,
        };
    }

    private getAnalyticEventsModelGoogle(
        category?: string,
        name?: string,
        label?: [] | string
    ): AnalyticEventGoogleAnalytic {
        return {
            category: category || this.categoryName,
            action: name,
            filterAttributes: label ? label : null,
        };
    }

    public sendDeleteAllFromCartEventInfo(cartItems: HotCartLineItemExtended[]): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        const cartProducts: AnalyticProductModel[] = cartItems.map((item: HotCartLineItemExtended) =>
            this.mapToAnalyticProductModel(item)
        );
        window.digitalData.ecommerce = {
            deleteAll: {
                products: cartProducts,
            },
            events: this.getAnalyticEventModel('delete-all-from-cart'),
            event: this.deleteAllFromCartEventName,
        };

        window.callBootstrapperTrigger(this.deleteAllFromCartEventName);
    }

    public sendFormSubmitInfo(formGroup: UntypedFormGroup, formName: string): void {
        this.getFormValidationErrors(formGroup);
        let arrayCount = this.formSubmitError.length;
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        if (window.digitalData.errormessage) {
            delete window.digitalData.errormessage;
        }
        if (arrayCount > 0) {
            window.digitalData.forminformation = {
                form_name: formName,
                formError: {
                    error_id: '',
                    error_message: this.formSubmitError,
                },
            };
        } else {
            window.digitalData.forminformation = {
                form_name: formName,
            };
        }
        window.callBootstrapperTrigger(this.submitAllFormEvent);
    }

    public getFormValidationErrors(formgroup: UntypedFormGroup) {
        this.formSubmitError = [];
        Object.keys(formgroup.controls).forEach((key) => {
            const controlErrors: ValidationErrors = formgroup.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    this.formSubmitError.push('Key control: ' + key + ', keyError: ' + keyError);
                });
            }
        });
    }

    public sendAddAllProductsEventInfo(products: OrderSuggestionProduct[], location: string, featureLocationFastOrder:string ): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        const cartProducts: AnalyticFastOrderProductModel[] = products.map((item: OrderSuggestionProduct) =>
            this.mapToAnalyticFastOrderProductModel(item,featureLocationFastOrder)
        );
        window.digitalData.ecommerce = {
            add: {
                products: cartProducts,
            },
            events: this.getFastOrderAddAllAnalyticEventModel('add-to-cart', location),
            event: this.addToCartEventName,
        };
        window.callBootstrapperTrigger(this.addToCartEventName);
    }

    public sendFilterInteractionEventInfo(isfilterPanel, filterAttribute) {
        if (this.featuresService.GoogleAnalytics) {
            window.digitalData.filterinteraction = {
                events: {
                    category: isfilterPanel ? 'more filter interaction' : 'filter interaction',
                    action: 'filter applied',
                    label: isfilterPanel ? 'filter modal' : 'quick filter',
                },
                filterAttributes: filterAttribute,
            };
            window.callBootstrapperTrigger('filter-interaction');
        }
    }

    private getFastOrderAddAllAnalyticEventModel(name: string, location: string): AnalyticFastOrderModalEvent {
        return {
            category: this.categoryName,
            action: name,
            label: location,
        };
    }

    private mapToAnalyticFastOrderProductModel(products?: OrderSuggestionProduct | HotProductRecommendation , featureLocationFastOrder?:string): AnalyticFastOrderProductModel {
        const currencyFormat = this.storeService.getSelector(settingsCurrencyFormat);
        const currencyDivider = Math.pow(10, currencyFormat.decimalDigits);
        if((products as HotProductRecommendation).hotRecommendedProductId){
            this.setRecommenderKey(products.sku, (products as HotProductRecommendation).hotRecommendedProductId)
            }
        return {
            name: products.name,
            id: products.id,
            brand: products.brand,
            category: (products as OrderSuggestionProduct).category,
            availability: (products as OrderSuggestionProduct).availability,
            item_list_name:featureLocationFastOrder,
            item_location: 'fast-order',
            pr_recommended_material_key: products.sku,
            pr_recommender_key: (products as HotProductRecommendation)?.hotRecommendedProductId,
            price: (products?.salePrice / currencyDivider)
            .toFixed(currencyFormat.decimalDigits)
            .toString(),
            quantity: (products as OrderSuggestionProduct).quantity,
        };
    }

    public sendFastModalAnalyticData(location: string): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }

        window.digitalData.ecommerce = {
            events: this.getFastOrderAnalyticEventModel(this.category, 'pop-up shown', location),
        };
        window.callBootstrapperTrigger('fast-order');
    }

    public sendWarningMessage(label) {
        window.digitalData.ecommerce = {
            events: this.getAnalyticEventsModel('warning message', 'modal pops up', label),
        };
        window.callBootstrapperTrigger('warning_message_modal');
    }

    public gpsTracking(label) {
        window.digitalData.ecommerce = {
            events: this.getAnalyticEventsModel('page interactions', 'track order', label),
        };
        window.callBootstrapperTrigger('track_order');
    }
    public pageDataEvent(pageType: string, primaryCategory: string) {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        const analyticsData: AnalyticPageModel = { pageType, primaryCategory };
        window.digitalData = {
            page: analyticsData,
            application: this.applicationSource,
        };
        try {
            window.callBootstrapperTrigger('pageData');
        } catch {
            setTimeout(() => window.callBootstrapperTrigger('pageData'), 4000);
        }
    }

    public heroBannerButtonClick(label) {
        window.digitalData.ecommerce = {
            events: this.getAnalyticEventsModel('hero-banner', 'migration banner cta click', label),
            event: this.heroBannerEvent,
        };
        window.callBootstrapperTrigger(this.heroBannerEvent);
    }

    private getFastOrderAnalyticEventModel(
        category: string,
        name: string,
        location: string
    ): AnalyticFastOrderModalEvent {
        return {
            category: category,
            action: name,
            label: location,
        };
    }
    private getDataSource() {
        let source = this.nativeService.isNativeSource()
            ? AnalyticLocationConstant.DATA_SOURCE_APP
            : AnalyticLocationConstant.DATA_SOURCE_WEB;
        return { source: source };
    }

    public sendProductClick(product: HotProductExtended, location: string): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        const analyticProduct: AnalyticProductModel = this.mapToAnalyticProductModel(product);
        analyticProduct.item_list_name = location;
        window.digitalData.ecommerce = {
            click: {
                actionField: {
                    list: 'Product catalog',
                },
                products: [analyticProduct],
            },
            events: this.getAnalyticEventModel('product click'),
            event: this.productClickEvent,
        };

        window.callBootstrapperTrigger(this.productClickEvent);
    }

    public sendPRLoadEvent(products: HotProductExtended[]): void {
        if (!this.featuresService.GoogleAnalytics) {
            return;
        }
        window.digitalData.ecommerce = {
            events: {
                event: 'pr_recommender_modal_load',
                category: 'ecommerce',
                action: 'pr_recommender_modal_load',
            },
            products: this.mapToPRLoadEventModel(products),
        };
        window.callBootstrapperTrigger('pr_recommender_modal_load');
    }

    private mapToPRLoadEventModel(products: HotProductExtended[]): AnalyticPRProductModel[] {
        const prProducts: AnalyticPRProductModel[] = products?.map((product: any) => {
            return {
                item_id: product.sku,
                pr_recommender_key: product.hotRecommendedProductId,
            };
        });
        return prProducts;
    }
}
