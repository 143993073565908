import { WarehousesState } from '@hot-b2b/store/warehouses/model';

export const warehousesInitialState: WarehousesState = {
    pending: false,
    fetched: false,
    rewardPending: false,
    rewardFetched: false,
    data: {
        warehouses: [],
        warehousesRewards: [],
    },
    error: null,
};
