import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HotDistributorPricelistRow } from '@hot-theme-nx/generated-api';
import orderBy from 'lodash/orderBy';

interface DistributorPricesColumnHeader {
    line1: string;
    line2: string;
    line3: string;
    fieldName?: string;
    priceIndex?: number;
}

enum sortState {
    none,
    idDesc,
    idAsc,
    nameDesc,
    nameAsc,
    outletUserGroupDesc,
    outletUserGroupAsc,
    outletTypeDesc,
    outletTypeAsc,
}

@Component({
    selector: 'hot-theme-distributor-prices-table-modal',
    templateUrl: './distributor-prices-table-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistributorPricesTableModalComponent implements OnInit {
    @Input() public pricelistRows: HotDistributorPricelistRow[];
    @Input() public showModal: boolean;
    @Output() public closeModal = new EventEmitter();

    public predefinedColumns: DistributorPricesColumnHeader[];
    public productColumns: DistributorPricesColumnHeader[];

    public sortStateEnum = sortState;
    public selectedSortType = sortState.none;

    public allColumns: DistributorPricesColumnHeader[];

    public initRows: HotDistributorPricelistRow[];

    public ngOnInit() {
        this.predefinedColumns = this.buildPredefinedColumns();
        this.productColumns = this.buildProductColumns();
        this.allColumns = [...this.predefinedColumns, ...this.productColumns];
        this.initRows = this.pricelistRows;
    }

    private buildPredefinedColumns(): DistributorPricesColumnHeader[] {
        return [
            {
                line1: '',
                line2: '',
                line3: 'shared.modals.d-price-table-modal.outlet-id',
                fieldName: 'outletOuterId',
            },
            {
                line1: '',
                line2: '',
                line3: 'shared.modals.d-price-table-modal.outlet-name',
                fieldName: 'outletName',
            },
            {
                line1: '',
                line2: '',
                line3: 'shared.modals.d-price-table-modal.outlet-user-group',
                fieldName: 'outletUserGroup',
            },
            {
                line1: 'shared.modals.d-price-table-modal.sku',
                line2: 'shared.modals.d-price-table-modal.product-name',
                line3: 'shared.modals.d-price-table-modal.outlet-type',
                fieldName: 'outletType',
            },
        ];
    }

    private buildProductColumns(): DistributorPricesColumnHeader[] {
        const firstRow = this.pricelistRows[0];

        return firstRow?.prices?.map((price, index) => ({
            line1: price.productSku,
            line2: price.productName,
            line3: '',
            priceIndex: index,
        }));
    }

    public isSortActive(sortType: sortState): boolean {
        return sortType === this.selectedSortType;
    }

    public sortOutletIdClick() {
        this.updateRowSorting('outletOuterId', this.sortStateEnum.idAsc, this.sortStateEnum.idDesc);
    }

    public sortOutletNameClick() {
        this.updateRowSorting('outletName', this.sortStateEnum.nameAsc, this.sortStateEnum.nameDesc);
    }

    public sortOutletUserGroupClick() {
        this.updateRowSorting('outletUserGroup', this.sortStateEnum.outletUserGroupAsc, this.sortStateEnum.outletUserGroupDesc);
    }

    public sortOutletTypeClick() {
        this.updateRowSorting('outletType', this.sortStateEnum.outletTypeAsc, this.sortStateEnum.outletTypeDesc);
    }

    private updateRowSorting(sortProperty: string, ascState: sortState, descState: sortState): void {
        if (this.selectedSortType === ascState) {
            this.selectedSortType = descState;
            this.pricelistRows = orderBy(this.pricelistRows, sortProperty, 'desc');
        } else if (this.selectedSortType === descState) {
            this.selectedSortType = this.sortStateEnum.none;
            this.pricelistRows = this.initRows;
        } else {
            this.selectedSortType = ascState;
            this.pricelistRows = orderBy(this.pricelistRows, sortProperty, 'asc');
        }
    }
}
