import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import {
    CurrencyFormat,
    HotApiCartsService,
    HotCreateOrderRequest,
    HotInsight,
    HotPaymentMethod,
    HotPromotion,
    HotOperationResult,
    HotSettings,
    HotApiOrdersService,
    HotOrder,
} from '@hot-theme-nx/generated-api';

import { DistributorCatalogService } from '../../../distributor/services';
import { OrderTemplateModel } from '../../../order-template/models';
import { OrderTemplateCreateModalType } from '../modal-content';

import { authUser } from '@hot-b2b/store/auth/selector';
import {
    CartCreateOrderSuccess,
    CartErrorClear,
    CartGet,
    CartSetPaymentMethod,
    CartValidateShipmentMethodSelected,
} from '@hot-b2b/store/cart/actions';
import {
    cartData,
    cartItems,
    cartItemsLength,
    cartError,
    cartRemarks,
    checkPickUpEnabledForStore,
} from '@hot-b2b/store/cart/selector';
import { insights } from '@hot-b2b/store/insights/selector';
import { ModalToggle } from '@hot-b2b/store/modals/actions';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { getSelectedTemplate } from '@hot-b2b/store/order-template/selector';
import {
    orders,
    ordersPrevious,
    orderSimulationtaxDetails,
    orderSimulationDetails,
    orderSimulationFailureErrorCode,
} from '@hot-b2b/store/orders/selector';
import { allPromotions, suggestedPromotions } from '@hot-b2b/store/promotions/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { routerCurrentUrl } from '@hot-b2b/store/router/selector';
import {
    settingsAppOnLine,
    settingsCurrencyFormat,
    settingsPaymentMethods,
    settingsStore,
    settingsData,
} from '@hot-b2b/store/settings/selector';
import { LocalStorageService, ScreenDimensionService } from '@hot-libs/browser-specific';
import { HotOrderExtended, HotUserExtended, SettingsStoreModel, SuggestedPromotion } from '@hot-libs/shared-models';
import {
    AccountType,
    CustomerPermissions,
    FeatureNames,
    ModalIds,
    OrderStatus,
    StorageKeys,
    TelemetryEventType,
} from '@hot-libs/shared-types';
import { CartService } from 'apps/hot-b2b/src/app/cart/services';

import { OrderTemplateService } from 'apps/hot-b2b/src/app/order-template/services/order-template.service';
import { HotCartExtended, HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import {
    ApplicationInsightsService,
    FeaturesService,
    ModalService,
    OrderDetailsService,
} from 'apps/hot-b2b/src/app/shared/services';
import { OrderCreationService } from 'apps/hot-b2b/src/app/shared/services/order-creation.service';
import { PermissionsService } from 'apps/hot-b2b/src/app/shared/services/permissions.service';
import find from 'lodash/find';
import moment from 'moment';
import { OrderDetailsEditModeService } from 'apps/hot-b2b/src/app/shared/services/order-details-edit-mode.service';
import { OrderDetailsEditEventsService } from 'apps/hot-b2b/src/app/shared/services/order-details-edit-events.service';

import { ConfigurationService } from '../../services/configuration.service';
import { OrderService } from '../../../order/services';
import { CurrencyFormatPipe } from 'apps/hot-b2b/src/app/shared/pipes';
import { AlertService } from '../../../shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { DistributorOrdersService } from '../../../distributor/services/distributor-orders.service';
import { OrderSimulation } from '@hot-b2b/store/orders/model';
import { OrderSimulationReset } from '@hot-b2b/store/orders/actions';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';

@Component({
    selector: 'hot-order-total',
    templateUrl: './order-total.component.html',
})
export class OrderTotalComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    @Input() public settingsStore: SettingsStoreModel;
    @Input() public currencyFormat: CurrencyFormat;
    @Input() public order: HotOrderExtended;
    @Input() public isExternalPlatformSelected: boolean;
    @Output() public showPaymentProcessing: EventEmitter<boolean> = new EventEmitter();

    public currencyFomatting: CurrencyFormatPipe = new CurrencyFormatPipe();

    public isNgB2b = () => this.configurationService.getCurrentConfiguration() === 'NG';
    public isEgB2b = () => this.configurationService.getCurrentConfiguration() === 'EG';
    public isEG = () => this.configurationService.getCurrentConfiguration() === 'EG';
    private cartItemsLength$: Observable<boolean>;
    public isPaymentMethodEnabled: boolean;
    public checkoutPaymentMethods: any;
    public otherPaymentMethods: any;
    public showCheckoutPaymentMethod: boolean;
    public showOtherPaymentMethod: boolean;
    public paymentHandlingOrderPhase: string;
    public allowPlaceOrder: boolean;
    public isCheckOut: boolean;
    public featureRestrictOrderPaymentsbyChannel: boolean = this.featuresService.RestrictOrderPaymentsbyChannel;
    public cartValidation: boolean = this.featuresService.ExternalCartValidation;
    public priceValidation: boolean = this.featuresService.ExternalPriceValidation;
    public refreshCartvalidate: boolean = this.featuresService.RefreshCartAfterExternalValidation;
    public outletChannel?: string;
    public hideInactiveProductsInCart: boolean = false;

    public restrictedChannelsforEGPay?: string[] = [];

    public remainingBalance: number;
    public finalremainingBalance: any;
    public cartTotal: number;
    public cartSubTotal: number;
    public cartExtendedTotal: number;
    public showNoPaymentmetodSelectedError = false;

    public cartData$: Observable<HotCartExtended>;
    public cartItems$: Observable<HotCartLineItemExtended[]>;
    public cartRemarks$: Observable<HotCreateOrderRequest>;

    public checkPickUpEnabledForStore$: Observable<boolean>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public user$: Observable<HotUserExtended>;
    public settingsAppOnLine$: Observable<boolean>;
    public settingsPaymentMethods$: Observable<HotPaymentMethod[]>;
    public ordersPrevious$: Observable<HotOrderExtended[]>;
    public ordersnew$: Observable<HotOrderExtended[]>;
    public readonly modalOrderState$: Observable<ModalsState>;
    public readonly modalCustomerOrderRejectState$: Observable<ModalsState>;
    public readonly modalCustomerOrderConfirmState$: Observable<ModalsState>;

    public featureOrderTemplates: boolean = this.featuresService.OrderTemplates;
    public featureOutletCreditLimit: boolean = this.featuresService.OutletCreditLimit;
    public featureShipOrderByDistributor: boolean = this.featuresService.ShipOrderByDistributor;
    public featureManageOrdersOnDistributorPortalByDistributor: boolean = this.featuresService
        .ManageOrdersOnDistributorPortalByDistributor;
    public featureReturnEmpties: boolean = this.featuresService.ReturnEmpties;
    public featureSelectDeliveryDate: boolean = this.featuresService.SelectDeliveryDate;
    public featureAllowDispatchingMultipleOrders: boolean = this.featuresService.AllowDispatchingMultipleOrders;
    public featureAllowConfirmingMultipleOrdersForNewOrders: boolean = this.featuresService
        .AllowConfirmingMultipleOrdersForNewOrders;
    public featureAllowDistributorsToCancelOrdersAfterConfirmation = this.featuresService
        .AllowDistributorsToCancelOrdersAfterConfirmation;
    public featureOrganizationLevelCreditLimitCheck = this.featuresService.OrganizationLevelCreditLimitCheck;
    public featureSplitOrdersBySuppliersWhenCheckout: boolean = this.featuresService.SplitOrdersBySuppliersWhenCheckout;
    public featureShowCreditValidationWhenCheckout: boolean = this.featuresService.ShowCreditValidationWhenCheckout;
    public featureEnableCustomerPickup: boolean = this.featuresService.EnableCustomerPickup;
    public featureValidateProductLimitOrderQuantity: boolean = this.featuresService.ValidateProductLimitOrderQuantity;

    public cartItemsCount: number;
    public replaceLineItemsParams: HotApiCartsService.ReplaceLineItemsParams;
    public currencyFormat$: Observable<CurrencyFormat>;
    public selectedOrderTemplate$: Observable<OrderTemplateModel>;
    public isChanged$: Observable<boolean>;
    public popCustomerId: string = '';
    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    private readonly suggestedPromotions$: Observable<SuggestedPromotion[]>;
    public orderSimulationDetails$: Observable<OrderSimulation>;
    public orderSimulationErrorCodeDetails$: Observable<Array<string>>;

    public haveCreateOrderPermission$: Observable<boolean> = this.permissionsService.checkPermission(
        CustomerPermissions.OrderCreate
    );

    public haveReadPricingPermission: Observable<boolean> = this.permissionsService.checkPermission(
        CustomerPermissions.PricingRead
    );
    private readonly subscriptionDestroy$ = new Subject<boolean>();
    public EnableCHQIntegration: boolean;

    public accountType = AccountType;
    public canManage = false;
    public isDisabled = false;

    public readonly goToBasketLocalizationKey = 'catalog.brand.button';
    public readonly sendOrderLocalizationKey = 'catalog.brand.send-order-button';
    public readonly checkoutButtonLocalizationKey = 'shared.modals.cart.checkout-button';
    private readonly cancelledStatus = 'Cancelled';
    private readonly cancelledExtendedStatus = 'cancelled.customer.default';
    public readonly modalDistributorOrderConfirmReceivedConditionState$: Observable<ModalsState>;

    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    public selectedOrder: HotOrderExtended;
    public readonly orderStatus = OrderStatus;
    private readonly currentUrl$: Observable<string>;
    private orderNumber: string;
    public orderTemplateSaved: boolean;
    public disabled: boolean = true;
    public distributorOrderModalOpened: boolean;
    public distributorOrderChanged: boolean;
    public distributorOrderCancelled: boolean;
    public distributorOrderConfirmed: boolean;
    public distributorOrderOnTheWay: any;
    public disableConfirmButton$: Observable<boolean>;
    public isOrderDetailsEditMode$: Observable<boolean>;
    public isEditOrderHasChanges$: Observable<boolean>;
    public disabled$: Observable<boolean>;
    public disableDistributorConfirmEditButton$: Observable<boolean>;
    public isDistributorOrderCanBeConfirmed: boolean;

    public childElementCount = (element: HTMLElement) => element.childElementCount;
    public currentTemplate: OrderTemplateModel;
    public ShowDistributorOrderEditOption: boolean = true;
    public restricStoreId: boolean = true;
    public hotSettings$: Observable<HotSettings>;

    private trends$: Observable<HotInsight[]>;
    private promotions$: Observable<HotPromotion[]>;
    public isOpcoDistributor: boolean;
    public vanSalesman: boolean;
    public driverName = null;
    public driverPhoneNumber = null;
    public arrivalMinutes = null;
    public cartError$: Observable<any>;
    public allowOutOfStockItemsInCart = false;
    public showCashOrderLoader: boolean = false;
    public showProcessing: boolean = false;
    public isLoading: boolean = true;
    public isBasketOverlay = this.featuresService.EnableBasketOverlay;

    public promotionalDiscountAmount: number;
    public isLAB2b = () => this.configurationService.getCurrentConfiguration() === 'RE';
    public isGR = () => this.configurationService.getCurrentConfiguration() === 'GR';
    public isVnB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';

    public featureOrderSimulationLA: boolean = this.featuresService.OrderSimulationPricesAndTaxes;
    public orderSimulationTaxDetails$: Observable<HotOperationResult>;
    public invoiceAmount?: number = 0;
    public showPaymentFailedError: boolean;
    public retryOrderNumber: string;
    public retryOrder: HotOrder;

    public get isPaymentEnabled(): Observable<boolean> {
        return this.settingsStore$.pipe(
            map(
                (storeSettings: SettingsStoreModel) =>
                    storeSettings.paymentMethodSelectionEnabled && !!storeSettings.paymentMethods?.length
            )
        );
    }

    public availableDeliveryDates: moment.Moment[];
    public isOrderSimulationFailGR: boolean;
    public isOrderSimulationSuccess: boolean;
    public deliveryDetailsMobile: any;
    public isExternalPlatformEnabled: boolean = false;
    public errorCode: any;
    public isPopPilotUser: boolean = false;
    public basketOverlayPopupState$: Observable<ModalsState>;

    private readonly unsubscribe$ = new Subject<boolean>();
    public isCheckoutDisabled = false;
    public shipmentMethodSelected: boolean = true;

    constructor(
        private readonly store: Store<AppState>,
        public readonly featuresService: FeaturesService,
        public readonly router: Router,
        public readonly orderCreationService: OrderCreationService,
        private readonly permissionsService: PermissionsService,
        private readonly appInsightsService: ApplicationInsightsService,
        private readonly storageService: LocalStorageService,
        public readonly orderDetailsService: OrderDetailsService,
        private readonly orderTemplateService: OrderTemplateService,
        private readonly modalService: ModalService,
        private readonly hotApiCartService: HotApiCartsService,
        public orderTemplatesService: OrderTemplateService,
        public readonly distributorCatalogService: DistributorCatalogService,
        private readonly cartService: CartService,
        private readonly orderDetailsEditModeService: OrderDetailsEditModeService,
        private readonly orderDetailsEditEventsService: OrderDetailsEditEventsService,
        public readonly configurationService: ConfigurationService,
        private orderService: OrderService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        public readonly distributorOrdersService: DistributorOrdersService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly apiOrdersService: HotApiOrdersService
    ) {
        this.hotSettings$ = this.store.pipe(select(settingsData));
        this.remainingBalance = 0;
        this.EnableCHQIntegration = this.featuresService.EnableCHQIntegration;
        this.cartData$ = this.store.pipe(select(cartData));
        this.ordersPrevious$ = this.store.pipe(select(ordersPrevious));
        this.ordersnew$ = this.store.pipe(select(orders));
        this.cartItems$ = this.store.pipe(select(cartItems));
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.user$ = this.store.pipe(select(authUser));
        this.cartRemarks$ = this.store.pipe(select(cartRemarks));
        this.orderSimulationErrorCodeDetails$ = this.store.pipe(select(orderSimulationFailureErrorCode));
        this.basketOverlayPopupState$ = this.store.pipe(select(selectModalState(ModalIds.basketOverlayPopup)));

        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.orderSimulationDetails$ = this.store.pipe(select(orderSimulationDetails));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.modalDistributorOrderConfirmReceivedConditionState$ = this.store.pipe(
            select(selectModalState(ModalIds.distributorOrderConfirmReceivedCondition))
        );
        this.settingsPaymentMethods$ = this.store.pipe(select(settingsPaymentMethods));
        this.currentUrl$ = this.store.pipe(select(routerCurrentUrl));
        this.modalOrderState$ = this.store.pipe(select(selectModalState(ModalIds.order)));
        this.modalCustomerOrderRejectState$ = this.store.pipe(select(selectModalState(ModalIds.rejectListModal)));
        this.modalCustomerOrderConfirmState$ = this.store.pipe(
            select(selectModalState(ModalIds.customerOrderConfirmModal))
        );
        this.cartItemsLength$ = this.store.pipe(select(cartItemsLength));
        this.trends$ = this.store.pipe(select(insights));
        this.promotions$ = this.store.pipe(select(allPromotions));
        this.selectedOrderTemplate$ = this.store.pipe(select(getSelectedTemplate));

        this.isChanged$ = this.distributorCatalogService.isChanged;
        this.disabled$ = this.orderService.isDisabled$;
        this.disableConfirmButton$ = this.modalService.disableConfirmButton$;
        this.disableDistributorConfirmEditButton$ = this.modalService.disableDistributorConfirmEditButton$;
        this.isOrderDetailsEditMode$ = this.orderDetailsEditModeService.isEditMode();
        this.isEditOrderHasChanges$ = this.orderDetailsEditEventsService.getHasChangesEvent();
        this.suggestedPromotions$ = this.store.pipe(select(suggestedPromotions));
        this.cartError$ = this.store.pipe(select(cartError));
        this.checkPickUpEnabledForStore$ = this.store.pipe(
            select(checkPickUpEnabledForStore),
            withLatestFrom(this.featuresService.checkFeatures(FeatureNames.EnableCustomerPickUp)),
            map(([storeSettingsForPickup, featureEnableCustomerPickUp]: [boolean, boolean]) => {
                return storeSettingsForPickup && featureEnableCustomerPickUp;
            })
        );
        this.orderSimulationTaxDetails$ = this.store.pipe(select(orderSimulationtaxDetails));
    }

    public isCustomerPickupEnabled(): Observable<boolean> {
        return this.checkPickUpEnabledForStore$;
    }

    public ngOnInit(): void {
        this.user$.subscribe((data) => {
            this.outletChannel = data.outlet.channel;
            this.isPopPilotUser = data.isPopPilotUser;
        });

        if (this.isVnB2b()) {
            this.hotSettings$.subscribe((settings: HotSettingsExtended) => {
                this.ShowDistributorOrderEditOption = settings.showDistributorOrderEditOption;
                this.user$.subscribe((userData: HotUserExtended) => {
                    const findRestricStoreId: boolean = settings.pilotDistributors.includes(userData.storeId);
                    this.restricStoreId = !findRestricStoreId;
                });
            });
        }
        this.settingsStore$.subscribe((settingsStoreModel: SettingsStoreModel) => {
            this.restrictedChannelsforEGPay = settingsStoreModel.restrictOrderPaymentsByChannel;
            if (settingsStoreModel?.allowEstimatedDeliveryDateOptional) {
                this.modalService.disableDistributorConfirmEditButton$.next(false);
            }
        });

        this.settingsPaymentMethods$.subscribe((data) => {
            this.checkoutPaymentMethods = data;
            this.orderService.checkoutPaymentMethods(this.checkoutPaymentMethods);
            console.log('checkoutPaymentMethods', this.checkoutPaymentMethods);

            this.otherPaymentMethods = data?.filter(
                (paymentMethod) => paymentMethod.paymentHandlingOrderPhase != 'Checkout'
            );
            this.orderService.otherPaymentMethods(this.otherPaymentMethods);
            console.log('otherPaymentMethods', this.otherPaymentMethods);
        });

        this.settingsStore$.subscribe((storeSettings: SettingsStoreModel) => {
            this.isPaymentMethodEnabled =
                storeSettings.paymentMethodSelectionEnabled && !!storeSettings.paymentMethods?.length;
        });

        this.subscription = this.disabled$.subscribe((data) => (this.disabled = data));
        this.getSelectedOrder();
        this.getCartData();

        this.settingsStore$
            .pipe(
                filter((storeSettings) => !!storeSettings),
                takeUntil(this.subscriptionsDestroy$)
            )
            .subscribe((storeSettings: SettingsStoreModel) => {
                this.allowOutOfStockItemsInCart = storeSettings.displayOutOfStockProductsInCustomersCatalog;
                this.hideInactiveProductsInCart = storeSettings.hideInactiveProductsInCart;

                if (storeSettings?.allowEstimatedDeliveryDateOptional) {
                    this.modalService.disableDistributorConfirmEditButton$.next(false);
                }
            });

        this.orderTemplatesService.saveSuccess.subscribe((saved: boolean) => {
            this.orderTemplateSaved = saved;
        });

        this.cartService.orderSaved.pipe(takeUntil(this.subscriptionsDestroy$)).subscribe((value: boolean) => {
            this.orderTemplateSaved = value;
        });

        this.modalService.modalState$.pipe(takeUntil(this.subscriptionsDestroy$)).subscribe((state: ModalsState) => {
            this.modalStateService(state);
        });

        this.modalService.modalDistributorOrderChanged$.subscribe(
            (isChanged: boolean) => (this.distributorOrderChanged = isChanged)
        );

        this.user$.pipe(takeUntil(this.subscriptionsDestroy$)).subscribe((user: HotUserExtended) => {
            switch (user.userType) {
                case this.accountType.Distributor:
                    this.canManage = this.featureManageOrdersOnDistributorPortalByDistributor;
                    break;
                case this.accountType.SubDistributor:
                    this.canManage = true;
                    break;
                case this.accountType.OpcoDistributor:
                    this.isOpcoDistributor = true;
                    break;
                case this.accountType.VanSalesMan:
                    this.vanSalesman = true;
                    break;
            }
        });
        this.orderService.trackingDetails
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe((orderDetails: HotOrderExtended) => {
                this.driverName = orderDetails.driverName;
                this.driverPhoneNumber = orderDetails.driverPhoneNumber;
                this.calculateArrivalInMinutes(orderDetails);
            });

        this.orderCreationService.availableDeliveryDates$
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe((availableDeliveryDates: moment.Moment[]) => {
                this.availableDeliveryDates = availableDeliveryDates;
            });

        if (this.isEG()) {
            this.modalEgStateService();
        }

        this.cartItems$.pipe(takeUntil(this.unsubscribe$)).subscribe((cartLineItems: HotCartLineItemExtended[]) => {
            let tempVal = cartLineItems.filter((data: any) =>
                data.validationErrors.some((error: any) => error.errorCode === 'HotPriceError')
            );
            if (this.isGR() && tempVal.length > 0) {
                this.isCheckoutDisabled = false;
            } else {
                this.validateCartErrors(cartLineItems);
            }
        });
    }
    public validateCartErrors(cartLineItems: HotCartLineItemExtended[]) {
        for (let a of cartLineItems) {
            if (a.validationErrors.length > 0) {
                this.isCheckoutDisabled = true;
                break;
            } else {
                this.isCheckoutDisabled = false;
            }
        }
    }
    public ngAfterViewInit(): void {
        this.orderSimulationErrorCodeDetails$.subscribe((orderSimulationErrorCode) => {
            this.errorCode = orderSimulationErrorCode;
        });
        this.orderCreationService.paymentFailedErrorFlag.pipe(takeUntil(this.subscriptionDestroy$)).subscribe((res) => {
            this.showPaymentFailedError = res;
            this.orderCreationService.callAnalyticEventPOPPaymentError(this.showPaymentFailedError);
            this.changeDetector.detectChanges();
        });
    }

    public modalStateService(state) {
        if (!!state) {
            if (state.id === ModalIds.distributorOrder) {
                if (state.isOpened) {
                    this.distributorOrderModalOpened = true;
                } else {
                    this.distributorOrderModalOpened = false;
                }
            }

            if (!!state.data) {
                this.distributorOrderCancelled = state.data.status?.toLowerCase().indexOf('cancelled') >= 0;
                this.distributorOrderConfirmed = state.data.status?.toLowerCase().indexOf('confirmed') >= 0;
                this.distributorOrderOnTheWay = state.data.status;
                this.isDistributorOrderCanBeConfirmed = state.data.deliveryCanBeConfirmedByDistributor;
            }

            if (localStorage.getItem('Platform') !== null) {
                if (localStorage.getItem('Platform') === 'External orders') this.isExternalPlatformEnabled = true;
                else this.isExternalPlatformEnabled = false;
            }
        }
    }
    public modalEgStateService() {
        if (this.featureRestrictOrderPaymentsbyChannel && this.isPaymentMethodEnabled) {
            const result = this.restrictedChannelsforEGPay?.filter((channel) => channel == this.outletChannel);
            console.log(result);
            if (result.length != 0) {
                this.showOtherPaymentMethod = true;
                this.orderService.otherPaymentMethod(this.showOtherPaymentMethod);
                if (this.otherPaymentMethods.length == 0) {
                    this.isPaymentMethodEnabled = false;
                    this.showCheckoutPaymentMethod = false;
                    this.orderService.checkoutPaymentMethod(this.showCheckoutPaymentMethod);
                    this.showOtherPaymentMethod = false;
                    this.orderService.otherPaymentMethod(this.showOtherPaymentMethod);
                    this.allowPlaceOrder = true;
                }
            } else {
                this.showCheckoutPaymentMethod = true;
                this.orderService.checkoutPaymentMethod(this.showCheckoutPaymentMethod);
            }
        } else if (this.isPaymentMethodEnabled) {
            this.allowPlaceOrder = false;
        } else this.allowPlaceOrder = true;
    }

    public getSelectedOrder() {
        combineLatest([this.currentUrl$, this.ordersnew$, this.settingsStore$])
            .pipe(
                filter((data) => !!data[1]),
                takeUntil(this.subscriptionsDestroy$)
            )
            .subscribe(([url, selectedOrders, settings]: [string, HotOrderExtended[], SettingsStoreModel]) => {
                this.orderNumber = '';
                this.orderNumber = url.split('/').pop();
                if (url.includes('/retry-payment')) {
                    const retryOrderNumber = url.replace('/retry-payment/', '');
                    this.getOrderDetails(retryOrderNumber);
                }
                this.selectedOrder = find(selectedOrders, { number: this.orderNumber });
                if (this.selectedOrder) {
                    this.checkOrderCancellationAvailability(settings);
                }
            });
    }

    private getOrderDetails(retryOrderNumber) {
        this.apiOrdersService.getOrderByNumber(retryOrderNumber).subscribe((order: any) => {
            this.retryOrder = order;
        });
    }

    public checkOrderCancellationAvailability(settings?: SettingsStoreModel) {
        if (settings?.cancelOrderByCustomerWhenOrderStatusIsSentToDistributor) {
            this.selectedOrder.cancellable =
                this.selectedOrder.status === this.orderStatus.Received ||
                this.selectedOrder.status === this.orderStatus.SentToDistributor;
        } else {
            this.selectedOrder.cancellable =
                this.selectedOrder.status !== this.orderStatus.Received ||
                moment.utc() < moment(this.selectedOrder.cancelEndDate);
            const dateDifference: number =
                moment(this.selectedOrder.cancelEndDate).valueOf() - moment().utc().valueOf();
            if (dateDifference > 0) {
                setTimeout(this.setOrderCancellation.bind(this), dateDifference);
            } else {
                setTimeout(() => {
                    this.setOrderCancellation();
                }, 0);
            }
        }
    }
    public setOrderCancellation() {
        this.selectedOrder.cancellable = false;
    }

    public getCartData(): void {
        this.cartData$
            .pipe(withLatestFrom(this.user$), takeUntil(this.subscriptionsDestroy$))
            .subscribe(([cart, user]: [HotCartExtended, HotUserExtended]) => {
                this.currencyFormat$.subscribe((currencyFormat: CurrencyFormat) => {
                    if (cart) {
                        this.cartTotal = cart.total;
                        this.cartSubTotal = cart.subTotal;
                        this.cartExtendedTotal = cart.extendedPriceTotal;

                        this.promotionalDiscount(cart);

                        if (this.featureOrganizationLevelCreditLimitCheck && user?.contact?.organization) {
                            this.remainingBalance = user.contact.organization.creditAmount - cart.total;
                        } else if (!this.featureOrganizationLevelCreditLimitCheck && user?.contact?.outlet) {
                            this.remainingBalance = user.contact.outlet.creditAmount - cart.total;
                        }

                        this.finalremainingBalance = this.currencyFomatting.transform(
                            this.remainingBalance,
                            currencyFormat
                        );
                        this.cartItemsCount = cart.items.length;
                    }
                });
            });
    }

    public promotionalDiscount(cart) {
        if (cart.discountTotal)
            this.promotionalDiscountAmount = cart.couponDiscountAmount
                ? cart.discountTotal - cart.couponDiscountAmount
                : cart.discountTotal;
        else this.promotionalDiscountAmount = 0;
    }

    public saveOrderTemplate(): void {
        this.settingsAppOnLine$.pipe(take(1)).subscribe((online: boolean) => {
            if (online) {
                this.orderTemplateService.openOrderTemplateModal();
            }
        });
    }
    public openOverlay(): void {
        this.modalService.toggleModal(ModalIds.basketOverlayPopup, true);
    }

    public navigate(url: string): void {
        //checking the cart validation errors in NG opco, for cart(or)checkout page redirection
        //to be deleted
        this.cartData$.pipe(take(1)).subscribe((cart: HotCartExtended) => {
            if (cart.validationErrors?.length && this.isNgB2b() && url.toLowerCase() == '/cart') {
                url = '/cart';
            } else if (!cart.validationErrors?.length && this.isNgB2b() && url.toLowerCase() == '/cart') {
                url = '/checkout';
            }
        });
        if (url.toLowerCase() !== '/checkout') {
            this.router.navigateByUrl(url);
        } else {
            this.appInsightsService.trackEvent(TelemetryEventType.CheckoutButtonClick);
            this.cartData$
                .pipe(withLatestFrom(this.settingsAppOnLine$), take(1))
                .subscribe(([cart, isOnline]: [HotCartExtended, boolean]) => {
                    this.cartOnlineData(isOnline, cart, url);
                });
        }
    }
    public cartOnlineData(isOnline, cart, url) {
        if (!isOnline || (isOnline && !cart.validationErrors?.length)) {
            this.validateOnlineData(cart, url, isOnline);
        } else {
            this.cartService.cartIsValid$.next(false);
        }
    }
    public validateOnlineData(cart, url, isOnline) {
        const hasEmpties = cart.items.some((item: HotCartLineItemExtended) => item.isReturnableEmpty);
        const hasItemsWithReturnableSku = cart.items.some(
            (item: HotCartLineItemExtended) => !!item.product.returnableEmptySku
        );

        if (isOnline && this.featureReturnEmpties && hasItemsWithReturnableSku && !hasEmpties) {
            this.modalService.toggleModal(ModalIds.empties, true, {
                openCheckoutOnModalClose: true,
            });
        } else if (!isOnline || cart.isValid || !cart.validationErrors?.length) {
            if (this.featuresService.PromotionSuggestions && suggestedPromotions.length) {
                this.modalService.toggleModal(ModalIds.suggestedPromotions, true);
            } else {
                this.router.navigateByUrl(url);
            }
        }
    }

    public reorder(): void {
        this.orderDetailsService.reorder(this.selectedOrder);
    }

    public createOrder(): void {
        this.settingsAppOnLine$
            .pipe(
                take(1),
                withLatestFrom(
                    this.settingsPaymentMethods$,
                    this.settingsStore$,
                    this.cartRemarks$,
                    this.checkPickUpEnabledForStore$
                )
            )
            .subscribe(([isOnline, paymentMethods, storeSettings, remarks, isPickupEnabled]) => {
                if (isPickupEnabled) {
                    if (remarks.shipmentMethodCode == '' || remarks.shipmentMethodCode == undefined) {
                        this.store.dispatch(new CartValidateShipmentMethodSelected(true));
                        this.shipmentMethodSelected=false;
                        return;
                    }
                }
                this.shipmentMethodSelected=true;

                if (!isOnline) {
                    const modalState: ModalsState = { id: 'modal-offline-notification', isOpened: true };
                    this.store.dispatch(new ModalToggle(modalState));
                } else if (this.isLAB2b()) {
                    this.validateTaxOrderSimulation();
                } else if (this.isGR()) {
                    this.validateCartSimulation();
                } else if (storeSettings.paymentMethodSelectionEnabled && paymentMethods.length) {
                    this.showCashOrderPopUp();
                    this.orderPaymentMethod();
                } else {
                    this.orderCreationService.createOrder();
                    this.checkError();
                }
            });
    }

    public showCashOrderPopUp() {
        const paymentMethodCode: string = JSON.parse(localStorage.getItem(StorageKeys.lastUsedPaymentMethod))?.code;

        if (this.router.url.includes('/checkout') && paymentMethodCode === 'CASH ORDER')
            this.showCashOrderLoader = true;
    }

    public validateTaxOrderSimulation() {
        if (this.featureOrderSimulationLA) {
            this.orderSimulationTaxDetails$.pipe(take(1)).subscribe((response) => {
                if (response.validationTaxDetails.length == 0) {
                    this.orderCreationService.orderSimulationRequest();
                } else {
                    this.orderSimulationTaxDetails$.pipe(take(1)).subscribe((simulationResponse) => {
                        if (simulationResponse.validationTaxDetails.length > 0 && simulationResponse.succeeded) {
                            this.orderCreationService.createOrder();
                        }
                    });
                }
            });
        } else if (!this.featureOrderSimulationLA) {
            this.orderCreationService.createOrder();
        }
    }

    public validateCartSimulation() {
        if (
            this.featuresService.ExternalPriceValidation &&
            this.featuresService.ExternalCartValidation &&
            this.featuresService.RefreshCartAfterExternalValidation &&
            !this.isOrderSimulationSuccess
        ) {
            this.store.dispatch(new OrderSimulationReset());
            this.orderCreationService.orderSimulationRequest();
            this.orderSimulationDetails$.subscribe((response) => {
                this.deliveryDetailsMobile = response;
                this.orderCreationService.setValidateResponseValue(this.deliveryDetailsMobile);
                if (response?.taxDetails?.succeeded) {
                    this.isOrderSimulationSuccess = true;
                    this.isOrderSimulationFailGR = false;
                    this.store.dispatch(new CartGet());
                } else {
                    this.isOrderSimulationSuccess = false;
                    this.isOrderSimulationFailGR = true;
                    this.store.dispatch(new CartGet());
                }
                if (
                    this.errorCode.includes('ExternalPriceChange') &&
                    !this.errorCode.includes('CartValidationError') &&
                    this.isGR()
                ) {
                    this.isOrderSimulationSuccess = true;
                    this.isOrderSimulationFailGR = false;
                }
                this.changeDetector.detectChanges();
            });
        } else {
            this.orderCreationService.createOrder();
        }
    }

    public orderPaymentMethod() {
        if (
            (this.router.url.indexOf('checkout') >= 0 && this.isPopPilotUser) ||
            this.router.url.indexOf('payment-method') >= 0
        ) {
            const paymentMethod: string = localStorage.getItem(StorageKeys.lastUsedPaymentMethod);
            this.orderCreationService.paymentMethodValid$.next(!!paymentMethod);
            this.paymentProcessing(paymentMethod);
        } else if (this.allowPlaceOrder && this.isEG()) {
            this.orderCreationService.createOrder();
        } else if (this.router.url.includes('/retry-payment')) {
            this.retryOrderPayment();
        } else {
            this.router.navigateByUrl('/checkout/payment-method');
        }
    }

    private retryOrderPayment() {
        const selectedPaymentCode = JSON.parse(localStorage.getItem(StorageKeys.lastUsedPaymentMethod))
            ? JSON.parse(localStorage.getItem(StorageKeys.lastUsedPaymentMethod))?.code
            : null;
        if (selectedPaymentCode) {
            this.orderService.selectedcode = selectedPaymentCode;
            this.store.dispatch(new CartCreateOrderSuccess(this.retryOrder));
        } else {
            this.showNoPaymentmetodSelectedError = true;
        }
    }

    public paymentProcessing(paymentMethod: any) {
        if (paymentMethod) {
            this.showNoPaymentmetodSelectedError = false;
            this.store.dispatch(new CartSetPaymentMethod(JSON.parse(paymentMethod)));
            if (
                JSON.parse(paymentMethod).code === 'Pop.AlternatePaymentMethod' &&
                this.isPopPilotUser &&
                !this.router.url.includes('/thank-you')
            ) {
                this.showProcessing = true;
                this.showPaymentProcessing.emit(true);
            }

            if (this.featureShowCreditValidationWhenCheckout && JSON.parse(paymentMethod).code === '01') {
                this.modalService.toggleModal(ModalIds.paymentConfirmation, true);
            } else {
                this.orderCreationService.createOrder();
                setTimeout(() => {
                    this.showPaymentProcessing.emit(false);
                }, 5000);
            }
        } else {
            this.showNoPaymentmetodSelectedError = true;
        }
    }
    public checkError(): void {
        this.cartError$.pipe(takeUntil(this.subscriptionsDestroy$)).subscribe((cartErrors) => {
            if (cartErrors && cartErrors.length > 0) {
                const cartErrorCode = cartErrors[0];

                if (cartErrorCode === 'invalid_receiver_phone_number') {
                    this.alertService.error(this.translateService.instant('cart.errors.' + cartErrorCode));
                }

                if (
                    this.featureValidateProductLimitOrderQuantity &&
                    cartErrors?.includes('HotProductLimitOrderQuantityError')
                ) {
                    this.store.dispatch(new CartErrorClear());
                    this.store.dispatch(new CartGet());
                }
            }
        });
    }

    public createOrderWithPayment(): void {
        const paymentMethod = this.storageService
            .getItem<HotPaymentMethod>(StorageKeys.lastUsedPaymentMethod)
            .getValue();

        if (!paymentMethod) {
            this.orderCreationService.paymentMethodValid$.next(false);
            return;
        }

        this.orderCreationService.paymentMethodValid$.next(true);
        this.orderCreationService.createOrder();
    }

    public addToCart(): void {
        this.isDisabled = true;
        this.orderDetailsService.addToCart(this.selectedOrder.id, this.selectedOrder.items);
    }

    public saveOrder(): void {
        this.settingsAppOnLine$.pipe(take(1)).subscribe((online: boolean) => {
            if (online) {
                this.modalService.toggleModal(ModalIds.orderTemplates, true, {
                    modalType: 'create',
                    orderId: this.selectedOrder.id,
                });
            }
        });
    }

    public cancelOrder(): void {
        this.orderDetailsService.cancelOrder(this.selectedOrder);
    }

    public continueShopping(): void {
        this.router.navigateByUrl('/brands/all');
    }

    public get isPlaceOrderFormInvalid(): Observable<boolean> {
        return this.orderCreationService.isPlaceOrderInvalid();
    }

    public openOrderTemplateModal(currentModalType: OrderTemplateCreateModalType) {
        this.cartItemsLength$.pipe(take(1)).subscribe((itemsExist) => {
            if (itemsExist) {
                this.modalService.toggleModal(ModalIds.orderTemplates, true, {
                    modalType: currentModalType,
                });
            } else {
                this.selectedOrderTemplate$.pipe(take(1)).subscribe((selectedOrderTemplate: OrderTemplateModel) => {
                    this.replaceLineItemsParams = { body: selectedOrderTemplate.items };
                    this.hotApiCartService.replaceLineItems(this.replaceLineItemsParams).subscribe(() => {
                        this.store.dispatch(new CartGet());
                        this.router.navigateByUrl('/checkout');
                    });
                });
            }
        });
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public isProductAlreadyInCart(): Observable<boolean> {
        if (this.router.url.includes('/brands') && this.cartItemsCount) {
            return of(true);
        }
        if (this.router.url.includes('/trends')) {
            return this.cartItems$.pipe(
                withLatestFrom(this.trends$),
                map(([productItems, trends]: [HotCartLineItemExtended[], HotInsight[]]) => {
                    const trendCodeFromUrl: string = this.router.url.split('/').pop();
                    const selectedTrend: HotInsight = trends?.find(
                        (trend: HotInsight) => trend.code === trendCodeFromUrl
                    );
                    const trendsProductIds = selectedTrend?.products.map((product) => product.id);
                    return this.isEntityProductAlreadyInCart(productItems, trendsProductIds || []);
                })
            );
        }
        if (this.router.url.includes('/promotions')) {
            return this.cartItems$.pipe(
                withLatestFrom(this.promotions$),
                map(([promotionItems, promotions]: [HotCartLineItemExtended[], HotPromotion[]]) => {
                    let promotionsProductsIds: string[] = [];
                    promotions.forEach((promotion) => {
                        if (promotion.products) {
                            const promotionProductIds: string[] = promotion.products.map(
                                (product) => product.productId
                            );
                            promotionsProductsIds = promotionsProductsIds.concat(promotionProductIds);
                        }
                    });

                    return this.isEntityProductAlreadyInCart(promotionItems, promotionsProductsIds);
                })
            );
        }

        return of(false);
    }

    private isEntityProductAlreadyInCart(entityProductItems: HotCartLineItemExtended[], productIds: string[]): boolean {
        if (productIds.length && entityProductItems.length) {
            const cartProductIds: string[] = entityProductItems.map((item: HotCartLineItemExtended) => item.productId);
            const isInCart: boolean = productIds.some(
                (entityProductId: string) => cartProductIds.indexOf(entityProductId) >= 0
            );
            return isInCart;
        }
        return false;
    }
    public isUpLg = () => this.screenDimensionService.upLg();

    public rejectOrder(): void {
        this.modalService.modalAction$.next('DistributorOrderReject');
    }

    public cancelOrderEdit(): void {
        this.modalService.modalAction$.next('DistributorOrderCancelEdit');
    }

    public confirmOrder(): void {
        this.modalService.modalAction$.next('DistributorOrderConfirm');
    }

    public confirmChangedOrder(): void {
        this.modalService.modalAction$.next('DistributorOrderConfirmEdited');
    }

    public shipOrder(): void {
        this.modalService.modalAction$.next('DispatchOrder');
    }

    public confirmDistributorReceivedCondition(): void {
        this.modalService.modalAction$.next('DistributorOrderConfirmReceivedCondition');
    }

    public confirmOrderVsm(): void {
        this.modalService.modalAction$.next('VsmOrderConfirm');
    }

    public checkout(): void {
        this.cartData$
            .pipe(withLatestFrom(this.settingsAppOnLine$), take(1))
            .subscribe(([data, isOnline]: [HotCartExtended, boolean]) => {
                if (!data.isValid) {
                    document.getElementById('cart-errors').scrollIntoView();
                }
                if (!isOnline || (isOnline && data.isValid)) {
                    const hasEmpties = data.items.some((item: HotCartLineItemExtended) => item.isReturnableEmpty);
                    const hasItemsWithReturnableSku = data.items.some(
                        (item: HotCartLineItemExtended) => !!item.product.returnableEmptySku
                    );
                    this.invoiceAmount = data?.total;
                    if (isOnline && this.featureReturnEmpties && hasItemsWithReturnableSku && !hasEmpties) {
                        this.modalService.toggleModal(ModalIds.empties, true);
                    } else {
                        this.navigate('/checkout');
                    }
                }
            });
    }
    public cartHasErrors(): Observable<boolean> {
        return this.cartData$.pipe(
            withLatestFrom(this.settingsAppOnLine$),
            take(1),
            map(
                ([cart, applicationIsOnline]: [HotCartExtended, boolean]) =>
                    !cart.items?.length ||
                    (applicationIsOnline && !cart.isValid && !!cart.validationErrors.length) ||
                    cart.items.every(
                        (item) =>
                            item.isUnavailableForCustomers ||
                            (!item.isInStock && !this.allowOutOfStockItemsInCart) ||
                            (this.featureSplitOrdersBySuppliersWhenCheckout &&
                                cart.items.every((cartItem) => cartItem.isSelected === false))
                    ) ||
                    (this.hideInactiveProductsInCart &&
                        cart.items.some((item) =>
                            item.validationErrors.some((error) => error.errorCode === 'UnavailableError')
                        ))
            )
        );
    }
    public cancelEditOrder(): void {
        this.orderDetailsEditEventsService.pushCancelEvent();
    }

    public saveEditOrder(): void {
        this.orderDetailsEditEventsService.pushSaveEvent();
    }
    public callDriver() {
        window.location.href = 'tel:' + +this.driverPhoneNumber;
    }
    public calculateArrivalInMinutes(orderDetails: any) {
        const arrivalTimeISOString = orderDetails.estimatedTimeArrival['value'];
        let arrivalTime = new Date(arrivalTimeISOString);
        const actualArrivalTime = arrivalTime;
        let bufferTime = new Date(actualArrivalTime.getTime() + 45 * 60000);
        let timeDifference = (bufferTime.getTime() - new Date().getTime()) / 1000;
        timeDifference /= 60;
        this.arrivalMinutes = Math.round(timeDifference);
        if (this.arrivalMinutes >= 60) {
            const hrs = Math.floor(this.arrivalMinutes / 60);
            const mints = Math.floor(this.arrivalMinutes % 60);
            if (mints > 30) {
                this.arrivalMinutes = hrs + 1 + ' hr';
            } else if (mints > 0 && mints <= 30) {
                this.arrivalMinutes = hrs + ' hr' + ' 30' + ' min';
            } else {
                this.arrivalMinutes = hrs + ' hr';
            }
        } else {
            const mints = Math.floor(this.arrivalMinutes % 60) + ' min';
            this.arrivalMinutes = mints;
        }
    }
    public validateReqButton(): void {
        this.orderService.validateRequestButton();
    }
    public backToBasket(): void {
        this.router.navigateByUrl('/cart');
    }

    public isShowShipFlow(): boolean {
        return (
            this.distributorOrderModalOpened &&
            this.featureShipOrderByDistributor &&
            this.canManage &&
            this.distributorOrderConfirmed
        );
    }

    public checkOnTheWayOrder(): boolean {
        return this.distributorOrderOnTheWay === 'On the way' && this.canManage && this.featureShipOrderByDistributor;
    }

    public isShowDistributorConfirmationFlow() {
        return this.distributorOrderModalOpened && this.isDistributorOrderCanBeConfirmed;
    }

    public rejectListOrder(): void {
        this.modalService.modalAction$.next('DistributorOrderRejectList');
    }

    public pageSupportsEditingAndConfirmingOrders() {
        return (
            this.router.url.includes('/distributor/new-orders') ||
            this.router.url.includes('/distributor/pending-orders') ||
            this.router.url.includes('/vsm')
        );
    }

    public pageSupportsDispatchingOrders() {
        return this.router.url.includes('/distributor/confirmed-orders');
    }

    public pageSupportsConfirmingOrders() {
        return this.router.url.includes('/distributor/new-orders');
    }

    public pageSupportsConfirmingOrderDeliveryByDistributor() {
        return (
            this.router.url.includes('/distributor/confirmed-orders') ||
            this.router.url.includes('/distributor/dispatched-orders')
        );
    }

    public dispatchAllSelectedOrders() {
        this.modalService.modalAction$.next('DispatchSelectedOrders');
    }

    public confirmAllSelectedOrders() {
        this.modalService.modalAction$.next('ConfirmSelectedOrders');
    }

    public rejectOrderByCustomer(): void {
        this.modalService.modalAction$.next('CustomerOrderReject');
    }

    public confirmOrderByCustomer(): void {
        this.modalService.modalAction$.next('CustomerOrderConfirm');
    }

    public isShowConfirmationFlow() {
        return (
            this.orderDetailsService.featureExtendedOrderDeliveryConfirmationFlow &&
            this.orderDetailsService.featureAddReasonsByCustomerWhenCancelOrder &&
            this.selectedOrder?.status === 'On the way'
        );
    }
}
