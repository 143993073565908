import { Component} from '@angular/core';
import { AuthEdit } from '@hot-b2b/store/auth/actions';
import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotUserExtended } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';
import { HotApiAccountService } from '@hot-theme-nx/generated-api';
import { select, Store } from '@ngrx/store';
import { AuthenticationService, UserService } from 'apps/hot-b2b/src/app/account/services';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { ModalService } from '../../../services';

@Component({
  selector: 'hot-age-confirmation-modal',
  templateUrl: './age-confirmation-modal.component.html'
})
export class AgeConfirmationModalComponent{
  private readonly user$: Observable<HotUserExtended>;

  constructor(
    private readonly store: Store<AppState>,
    private readonly modalService: ModalService,
    private readonly authenticationService: AuthenticationService,
    private readonly hotApiAccountService: HotApiAccountService,
    private readonly userService: UserService
  ) { 
    this.user$ = this.store.pipe(select(authUser));
  }

  public agreeAge() {
    this.userService.deleteUserCache();
    this.hotApiAccountService
        .acceptAgeConfirmation()
        .pipe(withLatestFrom(this.user$))
        .subscribe(([acceptedDate, user]: [any, HotUserExtended]) => {
            if (acceptedDate && user.contact) {
                user.contact.ageConfirmationAcceptedDate = acceptedDate;
                this.store.dispatch(new AuthEdit(user));
            }
            this.modalService.toggleModal(ModalIds.ageConfirmationModal, false);
        });
}

  public declineAge() {
      this.modalService.toggleModal(ModalIds.ageConfirmationModal, false);
      this.authenticationService.logout();
  }
  

}
