import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, observable, Observable, of, Subject } from 'rxjs';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';

import { HotApiFulfillmentCentersService, HotFulfillmentCenter } from '@hot-theme-nx/generated-api';
import { StorageKeys,TelemetryEventType,AccountType } from '@hot-libs/shared-types';

import { HotCartExtended } from 'apps/hot-b2b/src/app/shared/models';
import { CartGet } from '@hot-b2b/store/cart/actions';
import { InsightsGetFromCenter } from '@hot-b2b/store/insights/actions';
import { PromotionsGetFromCenter } from '@hot-b2b/store/promotions/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsChangeFFCID, SettingsStoreGet } from '@hot-b2b/store/settings/actions';
import { settingsAppOnLine, settingsStore,settingsData } from '@hot-b2b/store/settings/selector';
import { CookiesService } from './cookies.service';
import { HotCookies } from './constants.service';
import { EmptiesCatalogGet, OfflineCatalogGetFromCenter } from '@hot-b2b/store/catalog/actions';
import { WidgetsGet } from '@hot-b2b/store/widgets/actions';
import { FeaturesService } from './features.service';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { ApplicationInsightsService } from './application-insights.service';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import { CacheClearService } from './cache-clear.service';

@Injectable({
    providedIn: 'root',
})
export class WarehouseService {
    private readonly selectedSource = new BehaviorSubject<HotFulfillmentCenter>(null);
    private readonly reloadSource = new Subject();

    public readonly currentSelected = this.selectedSource.asObservable();
    public readonly reload$ = this.reloadSource.asObservable();

    public reloadedWarehouseFromCatalog: boolean;
    public reloadWarehouseFromCatalogEvent$ = new Subject<boolean>();
    private readonly settingsStore$: Observable<SettingsStoreModel>;
    private readonly settings$: Observable<HotSettingsExtended>;
    public enableLogs: boolean;

    constructor(
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly hotApiFulfillmentCentersService: HotApiFulfillmentCentersService,
        public readonly cookiesService: CookiesService,
        public readonly featureService: FeaturesService,
        private readonly appInsightsService: ApplicationInsightsService,
        private readonly clearCache: CacheClearService
    ) {
        this.settingsStore$ = this.store.pipe(select(settingsStore));
        this.settings$ = this.store.pipe(select(settingsData));
        this.settings$.subscribe((settings: HotSettingsExtended) => {
            this.enableLogs = settings.enableLog;
        });
    }

    public getWarehouses(rewardsCentersOnly: boolean = false): Observable<HotFulfillmentCenter[]> {
        return this.hotApiFulfillmentCentersService.getAllAvailableFulfillmentCenters(rewardsCentersOnly);
    }

    public setWarehouse(fulfillmentCenter: HotFulfillmentCenter, pageType: string): void {
        const userType = localStorage.getItem(StorageKeys.userType);
        if (this.enableLogs && userType === AccountType.SubDistributor) {
            this.getWarehouses().subscribe((fulfillmentCenters: HotFulfillmentCenter[]) => {
                const getAllWareHouses = fulfillmentCenters.filter(value => value.id);
                this.appInsightsService.trackEvent(TelemetryEventType.CartItem, {
                    pageType: pageType,
                    Ffc: getAllWareHouses,
                });
               
            });
            this.appInsightsService.trackEvent(TelemetryEventType.CartItem, {
                pageType: "setWarehouse",
                Ffc: fulfillmentCenter.id,
            });
        }
        this.selectedSource.next(fulfillmentCenter);
    }

    public reload() {
        this.reloadSource.next(undefined);
    }

    public selectFulfillmentCenter(): Observable<any> {
        return this.store.pipe(
            select(settingsAppOnLine),
            withLatestFrom(this.selectedSource),
            switchMap(([isOnline, selectedFfc]: [boolean, HotFulfillmentCenter]) => {
                if (isOnline) {
                    if (this.isRewardsFfc()) {
                        this.applyFfc(
                            selectedFfc,
                            StorageKeys.rewardsFulfillmentCenter,
                            StorageKeys.redirectUrlRewardsFfc,
                            'rewards'
                        );
                        this.cookiesService.setCookie(HotCookies.CURRENT_FULFILLMENT_CENTER, selectedFfc.id);
                        this.cookiesService.setCookie(HotCookies.CURRENT_STORE, selectedFfc.storeId);
                    } else {
                        const previousStoreId = this.cookiesService.getCookie(HotCookies.CURRENT_STORE);
                        this.cookiesService.setCookie(HotCookies.CURRENT_FULFILLMENT_CENTER, selectedFfc.id);
                        this.cookiesService.setCookie(HotCookies.CURRENT_STORE, selectedFfc.storeId);
                        this.store.dispatch(new CartGet());
                        this.store.dispatch(new SettingsChangeFFCID(selectedFfc.id));
                        this.getEmpties();
                        if (previousStoreId && previousStoreId !== selectedFfc.storeId) {
                            this.store.dispatch(new SettingsStoreGet());
                        }

                        this.applyFfc(selectedFfc, StorageKeys.fulfillmentCenter, StorageKeys.redirectUrlFfc,'notReward');
                    }
                } else {
                    this.store.dispatch(new SettingsChangeFFCID(selectedFfc.id));

                    this.applyFfc(selectedFfc, StorageKeys.fulfillmentCenter, StorageKeys.redirectUrlFfc,'offline');
                }

                return of(null);
            })
        );
    }
    private getEmpties() {
        this.settingsStore$.pipe(take(1)).subscribe((value: SettingsStoreModel) => {
            if (value.autoAddEmpties && this.featureService.ReturnEmpties) {
                this.store.dispatch(new EmptiesCatalogGet());
            }
        });
    }
    public setWarehouseFromCart(cart: HotCartExtended): void {
        const currentFulfillmentCenter = localStorage.getItem(StorageKeys.fulfillmentCenter);
        if (!currentFulfillmentCenter) {
            this.getWarehouses().subscribe((fulfillmentCenters: HotFulfillmentCenter[]) => {
                const fulfillmentCenter = fulfillmentCenters.find(
                    (ffc: HotFulfillmentCenter) => ffc.storeId === cart.storeId
                );
                if (fulfillmentCenter) {
                    localStorage.setItem(StorageKeys.fulfillmentCenter, JSON.stringify(fulfillmentCenter));
                    this.cookiesService.setCookie(HotCookies.CURRENT_FULFILLMENT_CENTER, fulfillmentCenter.id);
                    this.cookiesService.setCookie(HotCookies.CURRENT_STORE, fulfillmentCenter.storeId);

                    this.store.dispatch(new InsightsGetFromCenter(fulfillmentCenter.id));
                    this.store.dispatch(new PromotionsGetFromCenter(fulfillmentCenter.id));
                    const userType = localStorage.getItem(StorageKeys.userType);
                    if (this.enableLogs && userType === AccountType.SubDistributor) {
                        this.appInsightsService.trackEvent(TelemetryEventType.CartItem, {
                            pageType: 'setWarehouseFromCart',
                            cookieFfcId: fulfillmentCenter.id,
                            cookieStoreId: fulfillmentCenter.storeId
                        });
                    }
                }
            });
        }
    }

    private applyFfc(selectedFfc: HotFulfillmentCenter, ffcKey: string, redirectTo: string, type:String): void {
        const userType = localStorage.getItem(StorageKeys.userType);
        if (this.enableLogs && userType === AccountType.SubDistributor) {
            this.appInsightsService.trackEvent(TelemetryEventType.CartItem, {
                pageType: 'NextClick',
                Ffc: selectedFfc,
                type: type,
                cookieFfcId: this.cookiesService.getCookie(HotCookies.CURRENT_FULFILLMENT_CENTER),
                cookieStoreId: this.cookiesService.getCookie(HotCookies.CURRENT_STORE),
            });
        }
        localStorage.setItem(ffcKey, JSON.stringify(selectedFfc));
        const redirectUrl = localStorage.getItem(redirectTo);
        this.router.navigateByUrl(redirectUrl, { replaceUrl: true });

        if (!this.isRewardsFfc()) {
            this.store.dispatch(new InsightsGetFromCenter(selectedFfc.id));
            this.store.dispatch(new PromotionsGetFromCenter(selectedFfc.id));
            this.store.dispatch(new OfflineCatalogGetFromCenter(selectedFfc.id));

            // Reload home page widgets to display featured products and/or categories for current store
            this.store.dispatch(new WidgetsGet());
        }

        if (this.reloadedWarehouseFromCatalog) {
            this.reloadWarehouseFromCatalogEvent$.next(true);
            this.reloadedWarehouseFromCatalog = false;
        }
    }

    private isRewardsFfc(): boolean {
        return localStorage.getItem(StorageKeys.redirectUrlRewardsFfc) ? true : false;
    }
    public deleteFullfillmentCentreCache(): Promise<boolean> {
        const deleteURLs = [
            { 'dataGroupName': 'performance8h', 'url': '/storefrontapi/hot/fulfillment-centers' }
        ];

        return this.clearCache.deleteCacheByAPIUrl(deleteURLs)
        .then(() => {return true;})
        .catch(() => {return false;});
    }

    public shouldSkipWarehouseSelectionPage(): boolean {
        return localStorage.getItem(StorageKeys.skipWarehouseSelectionPage) === 'true';
    }

    public skipWarehouseSelectionPage(): void {
        localStorage.setItem(StorageKeys.skipWarehouseSelectionPage, 'true');
    }

    public resetWarehouseSelectionPageSkipping(): void {
        localStorage.removeItem(StorageKeys.skipWarehouseSelectionPage);
    }
}
