import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ApiConfiguration, HotAccountSettings, HotApiAccountService, HotLanguage } from '@hot-theme-nx/generated-api';

import { authLoggedIn } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { SettingsLanguagesChange, SettingsLanguagesGet } from '@hot-b2b/store/settings/actions';
import { settingsLanguageCurrent, settingsLanguages } from '@hot-b2b/store/settings/selector';
import { asyncLocalStorage } from '@hot-libs/helpers';
import { StorageKeys } from '@hot-libs/shared-types';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StyleService } from 'apps/hot-b2b/src/app/shared/services/style.service';
import { languages } from 'apps/hot-b2b/src/environments/languages';
import find from 'lodash/find';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/localizations/', '.json');
}

@Injectable({
    providedIn: 'root',
})
export class TranslateManagerService implements OnDestroy {
    public ngDestroyed$ = new Subject();
    private readonly settingsLanguages$: Observable<HotLanguage[]>;
    private readonly settingsLanguageCurrent$: Observable<HotLanguage>;
    private readonly authLoggedIn$: Observable<boolean>;
    private readonly months: { [code: string]: string[] } = {};

    constructor(
        private readonly translateService: TranslateService,
        private readonly httpClient: HttpClient,
        private readonly store: Store<AppState>,
        private readonly hotApiAccountService: HotApiAccountService,
        private styleService: StyleService,
        private readonly apiConfiguration: ApiConfiguration
    ) {
        this.authLoggedIn$ = this.store.pipe(select(authLoggedIn));
        this.settingsLanguages$ = this.store.pipe(select(settingsLanguages));
        this.settingsLanguageCurrent$ = this.store.pipe(select(settingsLanguageCurrent));
        translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.setCurrentLanguage(event.lang);
        });
        this.loadLanguagesPrebuilded();
    }

    public loadLanguagesPrebuilded(): void {
        for (const code in languages) {
            const locale = languages[code];
            registerLocaleData(locale, code);
            this.months[code] = locale[6] ? locale[6][2] : locale[5][2];
        }
    }

    public getMonths(code: string): string[] {
        return this.months[code];
    }

    public ngOnDestroy(): void {
        this.ngDestroyed$.next(undefined);
    }

    public languageWorker(): void {
        this.setDefaultLanguage();
    }

    private setAppLanguage(code: string): Observable<any> {
        return this.translateService.use(code);
    }

    private setDefaultLanguage(): void {
        this.authLoggedIn$.pipe(take(1)).subscribe((loggedIn: boolean) => {
            const currentLanguageCode: string = localStorage.getItem(StorageKeys.currentLanguage);
            if (loggedIn) {
                this.getUserLanguages();
            } else {
                this.anonymousLanguage(currentLanguageCode);
            }
        });
    }

    private anonymousLanguage(currentLanguageCode: string): void {
        this.settingsLanguages$.pipe(take(1)).subscribe((setlanguages: HotLanguage[]) => {
            const isCurrentLanguageAvailable = !!find(setlanguages, { code: currentLanguageCode });
            if (
                (!currentLanguageCode && setlanguages.length > 0) ||
                (!isCurrentLanguageAvailable && setlanguages.length > 0)
            ) {
                currentLanguageCode = setlanguages[0].code;

                asyncLocalStorage.setItem(StorageKeys.currentLanguage, currentLanguageCode);
            }

            this.translateService.setDefaultLang(currentLanguageCode);
            this.setAppLanguage(currentLanguageCode);
            this.setCurrentLanguage(currentLanguageCode);
        });
    }

    public userLanguage(): void {
        this.settingsLanguageCurrent$.pipe(take(1)).subscribe((language: HotLanguage) => {
            if (language !== null) {
                const currentLanguageCode: string = localStorage.getItem(StorageKeys.currentLanguage);
                const code: string = language.code || currentLanguageCode;
                this.translateService.setDefaultLang(code);
                this.setAppLanguage(code);
                this.setCurrentLanguage(code);
            }
        });
    }

    public getUserLanguages(): void {
        this.authLoggedIn$.pipe(take(1)).subscribe((data: boolean) => {
            if (data) {
                this.store.dispatch(new SettingsLanguagesGet());
            }
        });
    }

    // TO DO: need to refactoring
    public getAccountSettings(): Observable<HotAccountSettings> {
        return this.hotApiAccountService.getAccountSettings();
    }

    public putLanguages(code: string): Observable<any> {
        return this.httpClient.put(`${this.apiConfiguration.rootUrl}/storefrontapi/hot/account/settings`, {
            currentLanguageCode: code,
        });
    }

    private setCurrentLanguage(code: string): void {
        this.settingsLanguages$.pipe(take(1)).subscribe((languageSetting: HotLanguage[]) => {
            const lang: HotLanguage = find(languageSetting, {code});

            if (lang) {
                const isRTL = lang.isRightToLeft;

                document.documentElement.setAttribute('lang', lang.code.split('-')[0]);

                if (isRTL) {
                    document.documentElement.setAttribute('dir', 'rtl');
                } else {
                    document.documentElement.setAttribute('dir', 'ltr');
                }

                this.styleService.includeRTL(isRTL);
                this.styleService.includeLangStyles(lang.code);

                this.store.dispatch(new SettingsLanguagesChange(lang));
            }
        });
    }
}
