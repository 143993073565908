import { Injectable } from '@angular/core';
import { HotUserExtended } from '@hot-libs/shared-models';
import { AccountType, StorageKeys } from '@hot-libs/shared-types';

import {
    HotApiAccountService,
    HotChangeEmailRequest,
    HotChangePasswordRequest,
    HotChangePhoneNumberRequest,
    HotOperationResult,
} from '@hot-theme-nx/generated-api';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    constructor(private readonly hotApiAccountService: HotApiAccountService) {}

    public updateAccountInformation(email: string, phone: string) {
        return new Promise((resolve) => {
            const changeEmailRequest: HotChangeEmailRequest = {
                newEmail: email,
            };

            if (email) {
                this.hotApiAccountService.changeEmail(changeEmailRequest).subscribe({
                    next: (changeResponse: HotOperationResult) => {
                        resolve(changeResponse);
                    },
                    error: (error: string) => {
                        resolve(error);
                    },
                    complete: () => {
                        if (phone) {
                            this.resolveChangePhoneNumber(resolve, phone);
                        }
                    }
                });
            } else if (phone) {
                this.resolveChangePhoneNumber(resolve, phone);
            }
        });
    }

    public isCustomer(user: HotUserExtended): boolean {
        const subDistributorMode: string = localStorage.getItem(StorageKeys.subDistributorMode);
        return (
            user.userType === AccountType.Customer ||
            (user.userType === AccountType.SubDistributor && subDistributorMode !== 'Distributor')
        );
    }

    private resolveChangePhoneNumber(resolve, phone) {
        const changePhoneNumberRequest: HotChangePhoneNumberRequest = {
            newPhoneNumber: phone,
        };

        this.hotApiAccountService.changePhoneNumber(changePhoneNumberRequest).subscribe({
            next: (changeResponse: HotOperationResult) => {
                resolve(changeResponse);
            },
            error: (error: string) => {
                resolve(error);
            }
        });
    }

    public changePassword(oldPassword: string, newPassword: string, confirmPassword: string) {
        return new Promise((resolve) => {
            const changePasswordRequest: HotChangePasswordRequest = {
                oldPassword,
                newPassword,
                newPasswordConfirmation: confirmPassword,
            };

            this.hotApiAccountService.changePassword(changePasswordRequest).subscribe({
                next: (changePasswordResponse: HotOperationResult) => {
                    resolve(changePasswordResponse);
                },
                error: (error: string) => {
                    resolve(error);
                }
            });
        });
    }
}
