import { FeedbackTopicsState } from './model';

export const feedbackTopicsInitialState: FeedbackTopicsState = {
    pending: false,
    fetched: false,
    data: {
        topics: [],
    },
    error: null,
};
