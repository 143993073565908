import { Injectable } from '@angular/core';
import {  Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { CartRestoreSuccess } from '@hot-b2b/store/cart/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { HotApiOrdersService, HotCart, HotOrder } from '@hot-theme-nx/generated-api';
import { Router } from '@angular/router';
import { StorageKeys } from 'libs/shared-types/src/lib/enums/storage-keys.enum';
declare let ProcessOut: any;


@Injectable({
    providedIn: 'root',
})
export class PopService {

    client: any;

    constructor( private readonly apiOrdersService: HotApiOrdersService,private readonly router: Router,
        private readonly store: Store<AppState> ) {
    }

    public processoutCardTokenized(invoice_id) {
        this.client = new ProcessOut.ProcessOut(JSON.parse(localStorage.getItem(StorageKeys.popProjectId)));
        // make sure `invoiceID` generated from
        // your backend is available in this scope
        let invoiceID = invoice_id;
        let token = 'card_PkAd7HTcwbDVdxjFuHHfuqaZ0DZelzX0';

        this.client.makeCardPayment(
            invoiceID,
            token,
            {
                authorize_only: false, // set to true if you don�t want to capture directly
                // If you want to offer the customer a preferred scheme
                // to pay on (for example, if the customer's card supports
                // co-schemes such as carte bancaire)
                preferred_scheme: 'carte bancaire',
            },
            function (iv) {
                let field = document.createElement('input');
                field.type = 'hidden';
                field.name = 'invoice_id';
                field.value = iv;
            },
            function (err) {
            }

        )
    }

    public rollBackOrder(order: HotOrder): void {
        if (this.orderPaymentStartedOnCheckoutPage(order)) {
            this.cancelOrderAndRestoreCart(order.number);
        } else {
            this.cancelUnfinishedOrderPayments(order.number);
        }
    }

    private orderPaymentStartedOnCheckoutPage(order: HotOrder): boolean {
        return order.paymentHandlingOrderPhase === 'Checkout';
    }

    public cancelOrderAndRestoreCart(orderNumber: string): void {
        forkJoin([
            this.apiOrdersService.deleteOrder(orderNumber),
            this.apiOrdersService.restoreCartFromDeletedOrder(orderNumber),
        ]).subscribe(([, cart]: [any, HotCart]) => {
            this.store.dispatch(new CartRestoreSuccess(cart));
            this.router.navigateByUrl('/checkout');
        });
    }
    private cancelUnfinishedOrderPayments(orderNumber: string): void {
        this.apiOrdersService.cancelUnfinishedPayments(orderNumber).subscribe(() => {
            this.router.navigateByUrl('/orders');
        });
    }
}
