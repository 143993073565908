import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { switchMap, map, withLatestFrom, filter } from 'rxjs/operators';

import { HotTutorial, HotApiTutorialsService } from '@hot-theme-nx/generated-api';

import { AppState } from '@hot-b2b/store/reducers';
import { authLoggedIn } from '@hot-b2b/store/auth/selector';
import { ETutorialsActions } from '@hot-b2b/store/tutorials/types';
import { TutorialsGetSuccess, TutorialsGet } from '@hot-b2b/store/tutorials/actions';
import { FeaturesService } from '../../shared/services';
import { FeatureNames } from '@hot-libs/shared-types';

@Injectable()
export class TutorialsEffects {
    constructor(
        private readonly _store: Store<AppState>,
        private readonly _actions$: Actions,
        private readonly apiTutorialsService: HotApiTutorialsService,
        private readonly featuresService: FeaturesService
    ) {}

    public TutorialsGet$: Observable<TutorialsGetSuccess> = createEffect(() => this._actions$.pipe(
        ofType<TutorialsGet>(ETutorialsActions.TUTORIALS_PENDING),
        withLatestFrom(
            this._store.pipe(select(authLoggedIn)),
            this.featuresService.checkFeatures(FeatureNames.ShowDotTutorialButton)
        ),
        filter(([, loggedIn, showTutorialBtn]: [TutorialsGet, boolean, boolean]) => loggedIn && showTutorialBtn),
        switchMap(() =>
            this.apiTutorialsService
                .getTutorials()
                .pipe(map((response: HotTutorial[]) => new TutorialsGetSuccess(response)))
        )
    ));
}
