import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ModalIds, AccountType, StorageKeys } from '@hot-libs/shared-types'; //remindpopup
import { selectModalState } from '@hot-b2b/store/modals/selector'; //remindpopup
import { ModalService } from 'apps/hot-b2b/src/app/shared/services/modal.service'; //remindpopup
import { authUser } from '@hot-b2b/store/auth/selector';
import { CartErrorClear, CartGet } from '@hot-b2b/store/cart/actions';
import { cartData, cartError, cartFetched, cartItemsLength } from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsCurrencyFormat, settingsData, settingsStore } from '@hot-b2b/store/settings/selector';
import { MobileService, ScreenDimensionService } from '@hot-libs/browser-specific';
import { HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';
import { HotCartExtended } from 'apps/hot-b2b/src/app/shared/models';
import { ModalsState } from '@hot-b2b/store/modals/model';
import {
    CurrencyFormat,
    HotApiSecurityService,
    HotSettings,
    User,
    HotCarouselBanner,
} from '@hot-theme-nx/generated-api';
import { GetSettingsSuccess, IRootState } from 'libs/common-api/src/lib/store';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { FeaturesService } from '../../services/features.service';
import { DistributorOrdersService } from '../../../distributor/services/distributor-orders.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoaderServiceV2 } from '../../services/loader-v2.service';
import { carouselDetails, carouselFetched } from '@hot-b2b/store/carousels/selector';
import { DistributorCatalogService } from '../../../distributor/services';
import { OutletService } from '../../services/outlet.service';
import { InitDataManagerService } from '../../services/initdata-manager.service';
import { CarouselsGet } from '@hot-b2b/store/carousels/actions';
@Component({
    selector: 'hot-layout',
    templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit, AfterViewChecked, OnDestroy {
    @ViewChild('total') public elementView: ElementRef;
    public carouselItems: HotCarouselBanner[] = [];
    private appInsights: ApplicationInsights;
    public pwaSwitcherHeight = 0;
    public authUser$: Observable<HotUserExtended>;
    public cartItemsLength$: Observable<boolean>;
    public settingsStore$: Observable<SettingsStoreModel>;
    public userType: string;
    public isPwaSwitcherVisible: boolean;
    private userDetails = {
        userId: null,
        firstName: null,
        phone: null,
        emailId: null,
    };
    public featureEnableChatBot = this.featuresService.EnableChatBot;
    public updateFlagParams: HotApiSecurityService.UpdateflagParams;

    public userOutlets = {
        currentOutlet: null,
        outlets: null,
    };
    public subDistributorMode: string;
    public readonly accountType = AccountType;
    public userTypes: string;

    public cartData$: Observable<HotCartExtended>;
    public cartErrors$: Observable<any>;
    public cartFetched$: Observable<boolean>;
    public showNotification$: Observable<boolean>;

    private hideOrderTotal = true;
    private readonly subDistributorCustomer = 'Customer';
    private readonly subDistributorDistributor = 'Distributor';

    private readonly subscriptionDestroy$ = new Subject<boolean>();

    public isUpLg = () => this.screenDimensionService.upLg();
    public isMainPage = true;

    public hotSettings$: Observable<HotSettings>;
    public carouselFetched$: Observable<boolean>;
    public carouselDetails$: Observable<HotCarouselBanner[]>;

    public mobileOperatingSystem: string = this.mobileService.getMobileOperatingSystem();
    public isNativeApp: boolean = Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios';
    closeIcon = true;
    public isMobile = () => this.screenDimensionService.downSm();
    public upLg = () => this.screenDimensionService.upLg();
    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public isEgB2b = () => this.configurationService.getCurrentConfiguration() === 'EG';
    public duplicateTabCheckoutWarningModalState$: Observable<ModalsState>;
    public user$: Observable<User>;
    public isPopPilotUser : boolean = false; 
    public isPaymentProcessing : boolean = false;

    isPopUpDisabled: any;
    isPopUpMain: boolean = true;
    userResponse: any;
    public isSubmitted = false;
    public agree: boolean;
    updatedUserResponse: any;
    pop: boolean;
    user: any;
    settingsResponse: any;
    public androidDownloadLink: string;
    public iosDownloadLink: string;
    isActiveFlag: boolean;
    showpopUp: boolean = false;
    showPasswordChangePopUp: boolean = true;
    public isSmartFieldHeaderFooterShow: boolean = true;
    public modalPasswordRemindMeLaterUpdateState$: Observable<ModalsState>; //remindpopup
    public readonly currencyFormat$: Observable<CurrencyFormat>;
    public featureAllowConfirmingMultipleOrdersForNewOrders: boolean = this.featuresService
        .AllowConfirmingMultipleOrdersForNewOrders;
    public allowOrderSelectionNewOrdersPage = true;
    public allowOrderSelection = true;
    public featureAllowDispatchingMultipleOrders: boolean = this.featuresService.AllowDispatchingMultipleOrders;

    constructor(
        private readonly screenDimensionService: ScreenDimensionService,
        public readonly router: Router,
        private readonly store: Store<AppState>,
        private readonly cdr: ChangeDetectorRef,
        private readonly featuresService: FeaturesService,
        private readonly deviceDetectorService: DeviceDetectorService,
        private readonly mobileService: MobileService,
        public readonly storeRoot: Store<IRootState>,
        private readonly hotApiSecurityService: HotApiSecurityService,
        private readonly configurationService: ConfigurationService,
        private readonly modalService: ModalService, //remindpopup
        private readonly translateService: TranslateService,
        public readonly distributorOrdersService: DistributorOrdersService,
        private readonly loaderServiceV2: LoaderServiceV2,
        public readonly distributorCatalogService: DistributorCatalogService,
        private readonly route: ActivatedRoute,
        private readonly initDataManagerService: InitDataManagerService,
        private readonly outletService: OutletService,
    ) {
        this.authUser$ = this.store.pipe(select(authUser));
        this.cartItemsLength$ = this.store.pipe(select(cartItemsLength));
        this.cartFetched$ = this.store.pipe(select(cartFetched));
        this.subDistributorMode = localStorage.getItem(StorageKeys.subDistributorMode);
        this.modalPasswordRemindMeLaterUpdateState$ = this.store.pipe(
            select(selectModalState(ModalIds.remindMeLaterModal))
        ); //remindpopup
        this.hotSettings$ = this.store.pipe(select(settingsData));

        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.cartData$ = this.store.pipe(select(cartData));
        this.cartErrors$ = this.store.pipe(select(cartError));
        this.showNotification$ = this.distributorCatalogService.showNotification;

        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.duplicateTabCheckoutWarningModalState$ = this.store.pipe(
            select(selectModalState(ModalIds.duplicatetabCheckoutWarning))
        );
        if(this.router.url === '/') {
            this.isMainPage = true;
        } else {
            this.isMainPage =false;
        }
        this.carouselDetails$ = this.store.pipe(select(carouselDetails));
        this.carouselFetched$ = this.store.pipe(select(carouselFetched));
    }

    public ngOnInit(): void {
        this.store.dispatch(new CarouselsGet());
        if (this.featuresService.EazleEnabledOpco) {
            combineLatest([this.carouselDetails$, this.carouselFetched$])
            .pipe(
                filter((state) =>  !!state[1])
            ).subscribe((res) => {
                this.carouselItems = res[ 0 ].filter((banner: HotCarouselBanner) => banner.isActive && banner.heroBannerTitle);
            });
        }

        this.authUser$.pipe(takeUntil(this.subscriptionDestroy$)).subscribe((user: HotUserExtended) => {
            this.userTypes = user.userType;
            this.isPopPilotUser = user.isPopPilotUser
        });
        const currentTabTimestamp = sessionStorage.getItem(StorageKeys.tabTimestamp);
        const actualTabTimestamp = localStorage.getItem(StorageKeys.tabTimestamp);
        if (actualTabTimestamp !== currentTabTimestamp && this.userTypes !== AccountType.SubDistributor && this.userTypes !== AccountType.Distributor) {
            this.store.dispatch(new CartGet());
        }
        this.allowOrderSelection = this.featureAllowDispatchingMultipleOrders;
        this.allowOrderSelectionNewOrdersPage = this.featureAllowConfirmingMultipleOrdersForNewOrders;
        this.showpopUp = JSON.parse(sessionStorage.getItem(StorageKeys.popUpclose));
        this.checkOutlets();
        //for all browsers
        if (this.deviceDetectorService.browser) {
            this.checkAppTabs();
        }

        this.cartData$
            .pipe(
                distinctUntilChanged((cart: HotCartExtended) => !cart.items.length),
                withLatestFrom(this.cartErrors$),
                filter(([cart, cartErrors]: [HotCartExtended, any]) => !cart.items.length && !!cartErrors)
            )
            .subscribe(() => {
                this.store.dispatch(new CartErrorClear());
            });

        this.hotSettings$
            .pipe(
                takeUntil(this.subscriptionDestroy$),
                map((settings) => GetSettingsSuccess({ settings }))
            )
            .subscribe((settings) => {
                this.settingsResponse = settings.settings.features;
                this.iosDownloadLink = settings.settings.iosDownloadLink;
                this.androidDownloadLink = settings.settings.androidDownloadLink;
                this.settingsResponse.forEach((settingsRes) => {
                    if (settingsRes.name != undefined && settingsRes.name == 'AppDownloadPopup') {
                        this.isActiveFlag = settingsRes.isActive;
                    }
                });

                if (this.isActiveFlag) {
                    this.authUser$.pipe(first()).subscribe((data: HotUserExtended) => {
                        this.user = data;
                        this.isPopUpMain = data.appDownloadPopup;
                    });
                } else {
                    this.isPopUpMain = true;
                }
            });
    }
    public showPaymentProcessing(event:any){
      this.isPaymentProcessing = event;
    }
    public closeRemindmeLaterModal(event: any): void {
        this.modalService.toggleModal(ModalIds.remindMeLaterModal, false, event);
    }

    public toggleRadio(agree: boolean): void {
        this.updateFlagParams = {
            flag: agree,
            body: this.user,
        };
        this.isPopUpMain = agree;
        sessionStorage.setItem(StorageKeys.popUpclose, agree.toString());

        this.hotApiSecurityService.updateflag(this.updateFlagParams);
    }

    public checkOutlets(): void {
        this.authUser$.pipe(takeUntil(this.subscriptionDestroy$)).subscribe((user: HotUserExtended) => {
            if (user) {
                if(this.router.url.includes('order-suggestions') && this.router.url.includes('outletId') && user.userType === this.accountType.Customer){
                    let outletId = this.route.snapshot.queryParamMap.get('outletId');
                    let currentOutlet = user.contact.outlets.find(item=>item.id===outletId);
                    let isValidOutlet=user.contact.outlets?.some(el => el.id === outletId);
                    if(currentOutlet?.id!=user.contact.outlet.id && isValidOutlet){
                        this.outletService.setOutlet(currentOutlet.id).subscribe(() => {
                            this.userOutlets.currentOutlet = currentOutlet;
                            this.authUser$.pipe(first()).subscribe((userData: HotUserExtended) => {
                                this.initDataManagerService.cacheDelete(userData, currentOutlet);
                            });
                        });
                    }
                    else{
                        this.userOutlets.currentOutlet = user.contact.outlet;
                        let url = this.router.url;
                        url = url.slice(0, url.lastIndexOf('?'));
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        this.router.navigate([url])});
                    }  
                }
                else{
                 
                    this.userOutlets.currentOutlet =user.contact.outlet;
                }  
                this.userOutlets.outlets = user.contact.outlets;
                this.userType = user.userType;
                this.userDetails.userId = user.contactId;
                this.userDetails.firstName = user.contact?.firstName;
                this.userDetails.phone = user.phones[0] ?? '';
                this.userDetails.emailId = user.emails[0] ?? '';
                if (
                    user.userType === this.accountType.Customer ||
                    user.userType === this.accountType.SubDistributor ||
                    user.userType === this.accountType.Distributor ||
                    user.userType === this.accountType.VanSalesMan ||
                    user.userType === this.accountType.OpcoDistributor
                ) {
                    this.hideOrderTotal = false;
                }
                this.checkPWA();
            }
        });
    }

    public checkPWA(): void {
        if (this.featuresService.Pwa && this.userType === this.accountType.Customer) {
            this.isPwaSwitcherVisible = true;
        } else {
            this.pwaSwitcherHeight = 0;
        }
    }

    public ngAfterViewChecked(): void {
        this.pwaSwitcherHeight = !this.isUpLg() ? this.elementView.nativeElement.offsetHeight : 0;

        this.cdr.detectChanges();
    }

    public isWarehouseSelectButtonVisible(): boolean {
        return this.router.url === '/warehouses' && !this.isUpLg();
    }

    public isOrderTotalVisible(): boolean {
        return this.router.url !== '/warehouses' && !this.hideOrderTotal && !this.isUpLg();
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }

    toggleClose() {
        this.isPopUpMain = true;
        sessionStorage.setItem(StorageKeys.popUpclose, 'true');
    }

    private checkAppTabs(): void {
        // set data for current session
        const actualTabTimestamp = Date.now().toString();
        localStorage.setItem(StorageKeys.tabTimestamp, actualTabTimestamp);
        sessionStorage.setItem(StorageKeys.tabTimestamp, actualTabTimestamp);

        const actualOutletSemId = localStorage.getItem(StorageKeys.currentOutletSemId);
        if (actualOutletSemId) {
            sessionStorage.setItem(StorageKeys.currentOutletSemId, actualOutletSemId);
        }

        window.addEventListener('storage', (event: any) => this.sessionStorageHandler(event, this));
    }

    private sessionStorageHandler(_event: any, _context: any): void {
        const currentTabTimestamp = sessionStorage.getItem(StorageKeys.tabTimestamp);
        const actualTabTimestamp = localStorage.getItem(StorageKeys.tabTimestamp);
        localStorage.getItem('latestCart');
        if (actualTabTimestamp !== currentTabTimestamp) {
            //old session
            if (
                actualTabTimestamp !== currentTabTimestamp &&
                this.router.url.includes('/checkout') &&
                localStorage.getItem('latestCart')
            ) {
                localStorage.removeItem('latestCart');
                this.openModalCheckoutWarning();
            }
        }
    }

    public openModalCheckoutWarning(): void {
        this.modalService.toggleModal(ModalIds.duplicatetabCheckoutWarning, true);
    }

    public toggleDuplicateTabCheckoutWarningModal(state: boolean) {
        this.modalService.toggleModal(ModalIds.duplicatetabCheckoutWarning, state);
        this.store.dispatch(new CartGet());
        this.cartFetched$.subscribe((res) => (res ? this.loaderServiceV2.hide() : this.loaderServiceV2.show()));
    }

    public closeDuplicateTabCheckoutWarningModal(state: boolean) {
        this.modalService.toggleModal(ModalIds.duplicatetabCheckoutWarning, state);
    }

    navigateToStore() {
        if (this.mobileOperatingSystem == 'Android') {
            this.navigateTogoogleplay();
        }
        if (this.mobileOperatingSystem == 'iOS') {
            this.navigateToApplestore();
        }
    }

    public navigateTogoogleplay(): void {
        if (
            this.androidDownloadLink == null ||
            this.androidDownloadLink == undefined ||
            this.androidDownloadLink.trim() == ''
        ) {
            window.open(this.translateService.instant('shared.pop-up.app-download-link.android'), '_blank');
        } else {
            window.open(this.androidDownloadLink, '_blank');
        }
    }

    public navigateToApplestore(): void {
        if (this.iosDownloadLink == null || this.iosDownloadLink == undefined || this.iosDownloadLink.trim() == '') {
            window.open(this.translateService.instant('shared.pop-up.app-download-link.ios'), '_blank');
        } else {
            window.open(this.iosDownloadLink, '_blank');
        }
    }

    public setAppUpdateWarning() {
        if (this.isEgB2b() && !this.upLg()) {
            if (
                window.location.hostname.includes('beta.abcbeverages.net') ||
                window.location.hostname.includes('hei-b2b-eg1-b2botcom-t-azne-wa-frontend-2.azurewebsites.net')
            ) {
                return true;
            }
        }
    }  
}
