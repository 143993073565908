/**
 * Remove objects in array by array
 * @namespace removeByArray
 */
export function removeByArray(...forDeletion: any[]) {
    return this.filter((item) => !forDeletion.includes(item));
}

/**
 * find difference in arrays
 */
export const diffArray = (arr1, arr2) => {
    return arr1.concat(arr2).filter((val: any) => {
        if (!(arr1.includes(val) && arr2.includes(val))) {
            return val;
        }
    });
};

/**
 * Returns first matching deep value
 *
 * @param array - The array of objects to iterate over
 * @param value - object/value to find
 * @param childrenPath - children collection's field name
 * @param property - object field to check
 *
 * @returns matching deep value or null
 */
export function findDeep<T, U>(array: Array<T>, value: U, childrenPath: string, property: string = null): T | null {
    let result: T = null;

    if (array) {
        array.some((item) => {
            if (property && value === item[property] || (!property && Object.keys(value).every((key) => value[key] === item[key]))) {
                result = item;
                return true;
            } else if (item[childrenPath]) {
                result = findDeep<T, U>(item[childrenPath], value, childrenPath, property);
                return result;
            }
        });
    }

    return result;
}
