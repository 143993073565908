import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { TranslateService } from '@ngx-translate/core';

import {
    CurrencyFormat,
    HotFulfillmentCenter,
    HotLineItem,
    HotOrderEditRequest,
    HotProductSearchLightItem,
    HotStoreSettings,
    HotApiDeliveriesService,
    HotOrder,
    HotOperationResult,
    HotConfirmOrderDeliveryRequest,
    HotOrderStatusReasonsWithLanguage,
    HotApiSettingsService,
    HotApiOrdersService,
} from '@hot-theme-nx/generated-api';

import { OrderDetailsEditEventsService } from '../../../services/order-details-edit-events.service';
import { OrderDetailsEditModeService } from '../../../services/order-details-edit-mode.service';
import { OrderDetailsNotificationsService } from '../../../services/order-details-notifications.service';

import { ORDER_DETAILS_NOTIFICATIONS } from './order-modal.constants';

import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotSettingsExtended } from '@hot-b2b/store/settings/model';
import {
    settingsAppOnLine,
    settingsCurrencyFormat,
    settingsData,
    settingsLoyaltyCurrencyFormat,
    settingsStore,
} from '@hot-b2b/store/settings/selector';
import { warehouses } from '@hot-b2b/store/warehouses/selector';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import {
    DeliveryInfoModel,
    HotOrderExtended,
    HotOrderLineItemExtended,
    HotUserExtended,
    SettingsStoreModel,
} from '@hot-libs/shared-models';
import { AccountType, CustomerPermissions, ModalIds, OrderStatus, PageName, StorageKeys } from '@hot-libs/shared-types';
import { CartService } from 'apps/hot-b2b/src/app/cart/services';
import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { OrderTemplateService } from 'apps/hot-b2b/src/app/order-template/services';
import { OrderService } from 'apps/hot-b2b/src/app/order/services';
import { Router } from '@angular/router';
import {
    FeaturesService,
    ModalService,
    OrderDetailsService,
    ProductService,
} from 'apps/hot-b2b/src/app/shared/services';
import { DateFormatParserService } from 'apps/hot-b2b/src/app/shared/services/date-format-parser.service';
import { PermissionsService } from 'apps/hot-b2b/src/app/shared/services/permissions.service';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import moment from 'moment';
import { HotDelivery } from 'libs/generated-api/src/lib/models/hot-delivery';
import { ConfigurationService } from '../../../../shared/services/configuration.service';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { HotLowRatingReason } from 'libs/generated-api/src/lib/models/hot-low-rating-reason';
import { ConfirmOrderByCustomer, OrderSimulationReset } from '@hot-b2b/store/orders/actions';
import { DeliveryMethod, PickUpAddress } from '@hot-b2b/store/cart/model';
import { HotOrderShipment } from 'libs/generated-api/src/lib/models/hot-order-shipment';
import { latestOrder } from '@hot-b2b/store/orders/selector';
import { FIleDownloadUtil } from '../../../services/file-download-util.service';

@Component({
    selector: 'hot-order-modal',
    templateUrl: './order-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
    @Input() public order: HotOrderExtended;
    @Input() public modifier: string;
    @Input() public readonly;
    @Input() public isChanged = false;
    @Input() public documentDownloadTimeout = false;
    @Input() public confirmPartialDeliveryReasons: HotOrderStatusReasonsWithLanguage[];
    @Input() public lowRatingReasons: HotLowRatingReason[];
    @Input() public showDefaultColumns: boolean;
    @Input() public isReportIssue: boolean;
    @Input() public isShowExportButton = true;
    @Input() public isExternalPlatformSelected: boolean;
    @Input() public isExternalPlatformSelectedMobile: boolean;
    @Output() public buttonSaveOrder: EventEmitter<any> = new EventEmitter();
    @Output() public buttonCancel: EventEmitter<any> = new EventEmitter();
    @Output() public buttonConfirm: EventEmitter<any> = new EventEmitter();
    @Output() public buttonAcceptOrderDelivery: EventEmitter<any> = new EventEmitter();
    @Output() public buttonEditOrder: EventEmitter<any> = new EventEmitter();
    @Output() public closeModal: EventEmitter<any> = new EventEmitter();
    @Output() public exportFile: EventEmitter<any> = new EventEmitter();
    @Output() ratingSubmitted: EventEmitter<HotOrder> = new EventEmitter<HotOrder>();
    @Output() public updateOrderCancel: EventEmitter<any> = new EventEmitter();

    public orderItems: HotOrderLineItemExtended[];
    public emptiesOrderItems: HotOrderLineItemExtended[];

    public pageName = PageName.ORDERMODAL;

    public isSubmitted = false;
    public canConfirmOrderDelivery = false;
    public showReorderConfirmationModal: boolean;
    public readonly orderStatus = OrderStatus;

    public selectedFulfillmentCenter: string;

    public deliveryConfirmationForm: UntypedFormGroup = new UntypedFormGroup({
        deliveryComments: new UntypedFormControl(''),
        isDeliveredOnTime: new UntypedFormControl(),
        isDeliveredInFull: new UntypedFormControl(),
    });
    public featureShowAndCalculateExpectedDeliveryTime = this.featuresService.ShowAndCalculateExpectedDeliveryTime;
    public modalOrderRatingState$: Observable<ModalsState>;
    public modalRatingConfirmationState$: Observable<ModalsState>;
    public emitValue: HotOrder;
    public modalOrderCancel$: Observable<ModalsState>;
    public orderRatingDetails = new Subject<Object>();
    public modalCustomerOrderRejectState$: Observable<ModalsState>;
    public modalCustomerOrderConfirmState$: Observable<ModalsState>;
    public modalConfirmPartialDeliveryModal$: Observable<ModalsState>;
    public modalTrackAndTraceState$: Observable<ModalsState>;
    public modalReportIssueConfirmation$: Observable<ModalsState>;
    public modalReportIssueThanks$: Observable<ModalsState>;

    public featureBoostOrderCheckout: boolean = this.featuresService.BoostOrderCheckout;
    public featureCancelOrder: boolean = this.featuresService.CancelOrder;
    public featureConfirmDelivery: boolean = this.featuresService.ConfirmDelivery;
    public featureReorder: boolean = this.featuresService.Reorder;
    public featureOrderTemplates: boolean = this.featuresService.OrderTemplates;
    public featureStoreIsRequiredForOutlet: boolean = this.featuresService.StoreIsRequiredForOutlet;
    public featureSummarizeInvoices: boolean = this.featuresService.SummarizeInvoices;
    public featureOrderPaymentsProcessing: boolean = this.featuresService.OrderPaymentsProcessing;
    public featureReturnEmpties: boolean = this.featuresService.ReturnEmpties;
    public featureReportOrderIssue: boolean = this.featuresService.ReportOrderIssue;
    public featureEditOrder: boolean = this.featuresService.EditOrder;
    public featureEnableGPS: boolean = this.featuresService.EnableGPSTracking;
    public featureEnableRating: boolean = this.featuresService.EnableRating;
    public featureDisplayDriverDetails: boolean = this.featuresService.DisplayDriverDetails;
    public featureShowReceiverPhoneNumberAtCheckOutPage: boolean = this.featuresService
        .ShowReceiverPhoneNumberAtCheckOutPage;
    public featureExtendedOrderDeliveryConfirmationFlow: boolean = this.featuresService
        .ExtendedOrderDeliveryConfirmationFlow;
    public featureAddReasonsByCustomerWhenCancelOrder: boolean = this.featuresService
        .AddReasonsByCustomerWhenCancelOrder;
    public featureEnableCustomerPickup: boolean = this.featuresService.EnableCustomerPickup;

    public featureAddReasonsForPartialDelivery: boolean = this.featuresService.AddReasonsForPartialDelivery;
    public featureShowSalesRepPhoneNumber = this.featuresService.ShowSalesRepPhoneNumber;

    public haveReadPricingPermission: boolean;
    public featureShowCreditValidationWhenCheckout: boolean = this.featuresService.ShowCreditValidationWhenCheckout;

    public haveCreateOrderPermission$: Observable<boolean> = this.permissionsService.checkPermission(
        CustomerPermissions.OrderCreate
    );

    public orderFulfillmentCenter: HotFulfillmentCenter;
    public settingsAppOnLine$: Observable<boolean>;
    public warehouses$: Observable<HotFulfillmentCenter[]>;
    public settings$: Observable<HotSettingsExtended>;
    public storeSettings$: Observable<SettingsStoreModel>;
    public orderStatusesForDeliveryApprove: string[];
    public currencyFormat$: Observable<CurrencyFormat>;
    public loyaltyCurrencyFormat$: Observable<CurrencyFormat>;
    public authUser$: Observable<HotUserExtended>;
    public dateFormat: string;
    public locale: string;
    public itemsCount$: Observable<number>;
    public columns: string[] = ['purchase-order-number', 'status', 'total', 'payment'];
    public reportIssuesForm: UntypedFormGroup;
    public isEditOrderHasChanges$: Observable<boolean>;
    public startDate: string;
    public endDate: string;
    public isClosed: boolean;

    public productToAdd: HotProductSearchLightItem;
    public newProductQuantity = 0;

    public splitCancelledInvoiceNumber;

    private originalOrder: HotOrderExtended;
    private cancelTimeoutId;
    private editTimeoutId;
    //distributor contact
    public distributorPhoneNumber: string;
    public hotStoreSettings$: Observable<HotStoreSettings>;
    //distributor contact
    public customer: HotUserExtended;
    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    public orderTemplateSaved: boolean;
    public isEditMode: boolean;
    public isIssueReportFormValidated = false;

    public atleastOneDocumentChecked = true;
    public selectAllDocuments = true;

    public settingsStore$: Observable<SettingsStoreModel>;
    public orderRatingStatus: string;
    public allowToDownloadInvoicesDocument: boolean = false;

    // public LowRatingReasons: LowRatingReasons;
    public isDisabled = false;
    public confirmOrderDeliveryRequest: HotConfirmOrderDeliveryRequest;
    public enableTrackAndTraceOrderView: boolean = false;
    public settingShowDistributorNameOnMultiSupplierSections: boolean = false;
    public fulfillmentCenters: HotFulfillmentCenter[];

    private orderTypeFilterByCreditDebitNote: boolean = false;
    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public isEGB2b = () => this.configurationService.getCurrentConfiguration() === 'EG';
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    public isRw = () => this.configurationService.getCurrentConfiguration() === 'RW';
    public isRe = () => this.configurationService.getCurrentConfiguration() === 'RE';
    public isVnB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';
    public isMyB2b = () => this.configurationService.getCurrentConfiguration() === 'MY';
    public isSgB2b = () => this.configurationService.getCurrentConfiguration() === 'SG';
    public isLAB2b = () => this.configurationService.getCurrentConfiguration() === 'RE';
    public isGRB2b = () => this.configurationService.getCurrentConfiguration() === 'GR';
    public isSaB2b = () => this.configurationService.getCurrentConfiguration() === 'SA';
    public customerPartialDelivered = 'delivered.distributor.partialdelivered';
    public orderHiddenLabelPaid: boolean = false;
    public get isCancellable(): boolean {
        return (
            !this.order.isPaymentPaid &&
            this.order.cancellable &&
            !this.order.isOfflineOrder &&
            this.order.status !== this.orderStatus.Cancelled &&
            this.order.status !== this.orderStatus.ConfirmedDelivery &&
            this.featureCancelOrder
        );
    }

    public get isEditable(): boolean {
        return (
            this.order.editable &&
            this.order.status === 'Received' &&
            this.featureEditOrder &&
            this.order.isPaymentCanPay &&
            this.order.orderType !== 'BCS'
        );
    }
    public get deliveryPlannedShipmentEnd(): string {
        const deliveryCount = this.order.deliveries?.length;

        if (!deliveryCount) {
            return null;
        }

        if (this.order.status === 'Received') {
            return this.order.deliveries[0]?.plannedShipmentEnd;
        }

        return this.order.deliveries[deliveryCount - 1]?.plannedShipmentEnd;
    }
    public get isRemovable(): boolean {
        return this.order.items.filter((item: HotOrderLineItemExtended) => !item.isGift).length !== 1;
    }

    public get isAvailableToSave(): boolean {
        return (
            !this.order.isOfflineOrder &&
            (!this.order.cancellable || this.order.status === this.orderStatus.Cancelled || !this.featureCancelOrder)
        );
    }

    public isUpLg = () => this.screenDimensionService.upLg();
    private readonly subscriptionDestroy$ = new Subject<boolean>();
    private cancelOrderByCustomerWhenOrderStatusIsSentToDistributorSetting: boolean;
    public salesRepPhoneNumber: string;
    public latestOrder$: Observable<HotOrderExtended[]>;
    public orderNumber;
    public pageNum;
    showpopUp: boolean = false;
    public isPopPilotUser: boolean = false;

    constructor(
        public readonly orderDetailsNotificationsService: OrderDetailsNotificationsService,
        private readonly orderService: OrderService,
        private readonly store: Store<AppState>,
        private readonly featuresService: FeaturesService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly dateFormatParserService: DateFormatParserService,
        private readonly permissionsService: PermissionsService,
        public readonly orderDetailsService: OrderDetailsService,
        public readonly modalService: ModalService,
        public readonly orderTemplateService: OrderTemplateService,
        private readonly cartService: CartService,
        private readonly screenDimensionService: ScreenDimensionService,
        private readonly productService: ProductService,
        private readonly orderDetailsEditModeService: OrderDetailsEditModeService,
        private readonly orderDetailsEditEventsService: OrderDetailsEditEventsService,
        public router: Router,
        private readonly hotApiSettingsService: HotApiSettingsService,
        private readonly hotApiDeliveriesService: HotApiDeliveriesService,
        private readonly configurationService: ConfigurationService,
        private readonly apiOrdersService: HotApiOrdersService,
        private readonly analyticService: AnalyticService,
        private readonly translateService: TranslateService,
        private readonly latestNumber: HotApiOrdersService
    ) {
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.warehouses$ = this.store.pipe(select(warehouses));
        this.settings$ = this.store.pipe(select(settingsData));
        this.storeSettings$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.loyaltyCurrencyFormat$ = this.store.pipe(select(settingsLoyaltyCurrencyFormat));
        this.authUser$ = this.store.pipe(select(authUser));
        this.modalOrderRatingState$ = this.store.pipe(select(selectModalState(ModalIds.orderRating)));
        this.modalOrderCancel$ = this.store.pipe(select(selectModalState(ModalIds.orderCancelModal)));
        this.modalRatingConfirmationState$ = this.store.pipe(select(selectModalState(ModalIds.ratingConfirmation)));
        this.modalCustomerOrderRejectState$ = this.store.pipe(select(selectModalState(ModalIds.rejectListModal)));
        this.modalCustomerOrderConfirmState$ = this.store.pipe(
            select(selectModalState(ModalIds.customerOrderConfirmModal))
        );

        this.isEditOrderHasChanges$ = this.orderDetailsEditEventsService.getHasChangesEvent();
        this.settingsStore$ = this.store.pipe(select(settingsStore));

        this.settingsStore$.pipe(take(1)).subscribe((value: SettingsStoreModel) => {
            this.orderRatingStatus = value.orderRatingStatus;
        });
        this.modalConfirmPartialDeliveryModal$ = this.store.pipe(
            select(selectModalState(ModalIds.confirmPartialDeliveryModal))
        );
        this.modalTrackAndTraceState$ = this.store.pipe(select(selectModalState(ModalIds.trackAndTraceModal)));
        this.modalReportIssueConfirmation$ = this.store.pipe(
            select(selectModalState(ModalIds.reportIssueConfirmation))
        );
        this.modalReportIssueThanks$ = this.store.pipe(select(selectModalState(ModalIds.reportIssueThanks)));
        this.latestOrder$ = this.store.pipe(select(latestOrder));
    }

    public ngOnInit(): void {
        this.getLatestOrders();
        this.initSettings();
        this.cartService.orderSaved.pipe(takeUntil(this.subscriptionsDestroy$)).subscribe(() => {
            this.orderTemplateSaved = true;
            this.changeDetectorRef.markForCheck();
            this.changeDetectorRef.detectChanges();
        });
        this.showpopUp = JSON.parse(sessionStorage.getItem(StorageKeys.captureBannerClose));

        this.initEditOrderSubscriptions();
        this.setOrderItems();

        if (
            this.order.status === this.orderRatingStatus &&
            this.featureEnableRating &&
            this.order.rating < 1 &&
            !this.order.hasOwnProperty('isOrderRated')
        ) {
            this.modalService.toggleModal(ModalIds.orderRating, true);
        }

        this.permissionsService
            .checkPermission(CustomerPermissions.PricingRead)
            .subscribe((available: boolean) => (this.haveReadPricingPermission = available));

        this.splitCancelledInvoiceNumber = this.order?.cancelledInvoiceNumber?.split(',');
        this.reportIssuesForm = new UntypedFormGroup({
            orderId: new UntypedFormControl(this.order.id),
            productIssues: new UntypedFormArray([]),
        });

        this.reportIssuesForm.valueChanges.subscribe((formValue) => {
            this.isIssueReportFormValidated = !some(formValue.productIssues, (issue) => issue['Reason'] !== '');
        });

        const productIssuesForm = this.reportIssuesForm.get('productIssues') as UntypedFormArray;
        this.orderItems.forEach((order) => {
            productIssuesForm.push(
                new UntypedFormGroup({
                    ProductId: new UntypedFormControl(order.productId),
                    Reason: new UntypedFormControl(''),
                    Issue: new UntypedFormControl(''),
                })
            );
        });

        this.modalService.modalAction$.subscribe((action: string) => {
            if (action === 'CustomerOrderReject') {
                this.rejectOrder();
                this.modalService.modalAction$.next(null);
            } else if (action === 'CustomerOrderConfirm') {
                this.confirmOrder();
                this.modalService.modalAction$.next(null);
            }
        });

        this.warehouses$
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe((fulfillmentCenters: HotFulfillmentCenter[]) => {
                this.fulfillmentCenters = fulfillmentCenters;
            });
        this.isClosed = true;

        if (this.order?.cancelReason && this.order.cancelReason.includes(',')) {
            this.order.cancelReason = this.order.cancelReason.split(',')[0];
        }
        if (this.order?.hotOrderRejectionReason?.length && this.order.hotOrderRejectionReason[0].includes(',')) {
            this.order.hotOrderRejectionReason = this.order.hotOrderRejectionReason.map(
                (rejectionReason) => rejectionReason.split(',')[0]
            );
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.order && !changes.order.firstChange) {
            this.refreshTimeout();
        }
    }

    ngAfterViewChecked(): void {
        if (!this.showDefaultColumns) {
            const isUpSm = this.screenDimensionService.upSm();
            this.columns = this.configurationService.getPreviousOrdersColumn()[isUpSm ? 'desktop' : 'mobile'];
            if (!this.featureBoostOrderCheckout) {
                const invoiceStatusColumnIndex = this.columns.indexOf('invoiceStatus');
                if (invoiceStatusColumnIndex > -1) {
                    this.columns.splice(invoiceStatusColumnIndex, 1);
                }
            }
        }
    }

    public initSettings() {
        this.selectedFulfillmentCenter = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter));

        this.storeSettings$.pipe(take(1)).subscribe((data: SettingsStoreModel) => {
            this.cancelOrderByCustomerWhenOrderStatusIsSentToDistributorSetting =
                data.cancelOrderByCustomerWhenOrderStatusIsSentToDistributor;
            this.setTimeout();
        });

        this.settings$.subscribe((settings: HotSettingsExtended) => {
            this.dateFormat = this.dateFormatParserService.parseDateFormatForDatePipe(settings.dateFormat);
            if (settings.currentLanguage != null) {
                this.locale = settings.currentLanguage.code;
            }
            this.orderHiddenLabelPaid = settings.orderHiddenLabelPaid;
            this.allowToDownloadInvoicesDocument = settings.allowToDownloadInvoicesDocument;
            this.orderTypeFilterByCreditDebitNote = settings.orderTypeFilterByCreditDebitNote;
            this.enableTrackAndTraceOrderView = settings.enableTrackAndTraceOrderView;
            this.settingShowDistributorNameOnMultiSupplierSections =
                settings.showDistributorNameOnMultiSupplierSections;
        });

        this.authUser$.pipe(takeUntil(this.subscriptionsDestroy$)).subscribe((user: HotUserExtended) => {
            if (!user) {
                return;
            }
            this.isPopPilotUser = user.isPopPilotUser;
            this.customer = user;
            this.canConfirmOrderDelivery =
                user.userType === AccountType.SubDistributor || user.userType === AccountType.Customer;
            this.salesRepPhoneNumber = this.order?.salesRepresentativePhoneNumber;
        });

        this.itemsCount$ = this.orderDetailsService.calculateItemsCount(this.order);
        if (this.order !== null) {
            this.storeSettings$.subscribe((x) => {
                this.distributorPhoneNumber = (x['distributorPhoneNumber'] ?? '').toString().trim();
            });

            this.hotStoreSettings$ = this.hotApiSettingsService.getStoreSettings(this.order.fulfillmentCenterId);
        }
    }

    public setDateFormat(date): Observable<string> {
        let startDate = moment.utc(date).format('DD/MM/YYYY');
        let endDate = moment.utc(date).add(6, 'days').format('DD/MM/YYYY');
        this.startDate = startDate;
        this.endDate = endDate;
        return this.orderDetailsService.setDaysFormat(date);
    }
    public getFirstDayToLastDayOfWeek(date): Observable<string> {
        const weekTranslate = this.translateService.instant('shared.week-calendar.week');
        let weekNo: number = Number(moment.utc(date).format(`[${weekTranslate}] W,`).split(' ')[1].split(',')[0]);
        let firstMonday = new Date(new Date(date).getFullYear(), 0, 4, 0, 0, 0, 0);
        while (firstMonday.getDay() != 1) {
            firstMonday.setDate(firstMonday.getDate() - 1);
        }
        if (1 <= weekNo && weekNo <= 52) {
            let dateNumber: number = firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1));            
            let newDate = moment.utc(dateNumber).add(1, 'days').format('DD/MM/YYYY');                        
            let startDate = moment.utc(newDate, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let endDate = moment.utc(newDate, 'DD/MM/YYYY').add(6, 'days').format('DD/MM/YYYY');
            this.startDate = startDate;
            this.endDate = endDate;
        }
        return this.orderDetailsService.setDaysFormat(date);
    }
    public buildCreationDate(date): Observable<string> {
        return this.orderDetailsService.setOrderCreationDateFormat(date);
    }

    public setDateFormatforOrder(date): Observable<string> {
        return this.orderDetailsService.setDateFormatforOrder(date);
    }

    public addToCart(orderId: string, products: any): void {
        if (this.isLAB2b()) {
            this.store.dispatch(new OrderSimulationReset());
        }
        this.isDisabled = true;
        this.orderDetailsService.addToCart(orderId, products);
        this.cartService.keepOfflineCartLineItems();
    }

    public saveOrder(id): void {
        this.settingsAppOnLine$.pipe(take(1)).subscribe((online: boolean) => {
            if (online) {
                this.buttonSaveOrder.emit(id);
            }
        });
    }

    public cancelOrder(): void {
        this.orderDetailsService.cancelOrder(this.order);
    }

    public cancelChanges(): void {
        this.buttonCancel.emit();
    }

    public confirmChanges(id: string): void {
        this.buttonConfirm.emit(id);
    }

    public confirmDelivery(): void {
        if (this.deliveryConfirmationForm.valid) {
            this.isSubmitted = true;

            const formControls = this.deliveryConfirmationForm.controls;
            const deliveryInfo: DeliveryInfoModel = {
                deliveryComments: formControls.deliveryComments.value,
                isDeliveredOnTime: formControls.isDeliveredOnTime.value,
                isDeliveredInFull: formControls.isDeliveredInFull.value,
            };
            this.orderService
                .confirmDelivery(deliveryInfo, this.order.id)
                .pipe(takeUntil(this.subscriptionsDestroy$))
                .subscribe((response: HotOperationResult) => {
                    if (response.succeeded) {
                        this.changeDetectorRef.markForCheck();
                        this.orderDetailsService.setNewOrderStatus(
                            this.order,
                            'Confirmed delivery',
                            'confirmed-delivery.distributor.default'
                        );
                        this.deliveryConfirmationForm.reset();

                        this.buttonAcceptOrderDelivery.emit(this.order.id);
                    }
                });
        }
    }

    public getRatingIcon() {
        if (this.order.rating > 4) {
            return 'icon-rewards-green.svg';
        } else if (this.order.rating <= 2) {
            return 'icon-rewards-red.svg';
        } else {
            return 'icon-rewards-amber.svg';
        }
    }

    public getRatingTextColor() {
        if (this.order.rating > 4) {
            return '#017C28';
        } else if (this.order.rating <= 2) {
            return '#EC1C24';
        } else {
            return '#292929';
        }
    }

    public reorder(): void {
        this.orderDetailsService.reorder(this.order);
        this.cartService.keepOfflineCartLineItems();
    }

    public isAcceptDeliveryWarningVisible(): Observable<boolean> {
        return this.orderDetailsService.isAcceptDeliveryWarningVisible(
            this.order,
            this.canConfirmOrderDelivery,
            this.deliveryConfirmationForm.valid
        );
    }

    public payNow(
        order: any,
        _event: Event,
        orderNumber: string,
        orderTotal: string,
        orderHasUnfinishedPayments: boolean
    ): void {
        if (this.featureShowCreditValidationWhenCheckout) {
            this.modalService.toggleModal(ModalIds.paymentConfirmation, true, {
                orderNumber,
            });
        } else {
            this.orderService.orderNumber = order;

            this.orderDetailsService.toggleAddAmountOrderModal(true, order, {
                orderNumber,

                orderTotal,

                orderHasUnfinishedPayments,
            });
        }
    }

    //#region Order editing
    public toggleEditMode(isActive: boolean): void {
        if (isActive) {
            this.orderDetailsNotificationsService.showNotification(ORDER_DETAILS_NOTIFICATIONS.saveEdit);
            this.originalOrder = cloneDeep(this.order);
        } else {
            this.order = cloneDeep(this.originalOrder);
            this.setOrderItems();

            this.productToAdd = null;
            this.newProductQuantity = 0;

            this.changeDetectorRef.markForCheck();
        }

        this.orderDetailsEditModeService.setEditMode(isActive);
    }

    public selectProduct(product: HotProductSearchLightItem): void {
        this.productToAdd = product;
    }

    public changeNewProductQuantity(product: HotOrderLineItemExtended): void {
        this.newProductQuantity = product.quantity;
    }

    public removeNewProduct(): void {
        this.newProductQuantity = 0;
    }

    public changeProductQuantity(product: HotOrderLineItemExtended): void {
        this.changeQuantity(product.productId, product.quantity);
    }

    public removeProduct(product: HotOrderLineItemExtended): void {
        this.changeQuantity(product?.productId, 0);
    }

    public addProduct(): void {
        const request = this.getEditOrderRequest(false);

        const existingProductWithStepQuantity = request.items.find(
            (item: HotLineItem) =>
                item.productId === this.productToAdd.productId &&
                item.quantity < this.calculateProductQuantity(1, this.productToAdd.packageSize)
        );
        if (existingProductWithStepQuantity) {
            // If the order already contains an added product with a quantity less than a full package, replace it with a new value
            existingProductWithStepQuantity.quantity = this.calculateProductQuantity(
                this.newProductQuantity,
                this.productToAdd.packageSize
            );
        } else {
            request.items.push({
                productId: this.productToAdd.productId,
                quantity: this.calculateProductQuantity(this.newProductQuantity, this.productToAdd.packageSize),
            });
        }

        this.editOrder(request);

        this.productToAdd = null;
        this.newProductQuantity = 0;
    }

    public cancelEditOrder(): void {
        this.toggleEditMode(false);

        this.orderDetailsNotificationsService.showNotification(ORDER_DETAILS_NOTIFICATIONS.cancelledEdit);
    }

    public saveEditOrder(): void {
        const request = this.getEditOrderRequest(true);
        this.editOrder(request);
    }
    //#endregion

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
        this.orderService.orderRatingDetails.next(this.emitValue);
    }
    public getSubmittedRating(valueEmitted: HotOrderExtended) {
        this.emitValue = valueEmitted;
    }
    public get isShowReportIssueButton(): boolean {
        return (
            (this.order.status === this.orderStatus.Delivered || this.order.status === this.orderStatus.OnTheWay) &&
            this.featureReportOrderIssue
        );
    }

    public openReportIssue(): void {
        this.isReportIssue = true;
    }

    public openReportIssueMobile() {
        if (!this.isReportIssue) {
            this.isReportIssue = true;
            window.scrollTo(0, 0);
        } else {
            this.modalService.toggleModal(ModalIds.reportIssueConfirmation, true);
        }
    }

    public reportIssue(): void {
        this.modalService.toggleModal(ModalIds.reportIssueConfirmation, true);
    }

    public onCloseReportIssue(e) {
        if (e === true || e?.isClose) {
            if (e.isReported) {
                this.modalService.toggleModal(ModalIds.reportIssueConfirmation, false);
                this.modalService.toggleModal(ModalIds.reportIssueThanks, true);
            } else {
                this.modalService.toggleModal(ModalIds.reportIssueConfirmation, false);
                this.isReportIssue = false;
            }
        }
    }
    public onCloseReportIssueThanks(): void {
        this.modalService.toggleModal(ModalIds.reportIssueThanks, false);
        this.isReportIssue = false;
    }

    public getReasons(orderReason: string): Array<string> {
        if (!orderReason) return [];
        return orderReason.split('|');
    }
    private setOrderItems(): void {
        this.orderItems = this.order.items.filter((item: HotOrderLineItemExtended) => !item.isReturnableEmpty);

        if (this.orderTypeFilterByCreditDebitNote) {
            this.orderItems.forEach((item) => {
                const prices = this.productService.setProductPrice(item, this.pageName);
                item.invoicePrice = this.order.hasInvoice
                    ? this.productService.getInvoiceExtendedPriceWithDiscountForLineItem(item)
                    : prices.actualPrice;
            });
            const orderItemsPositivePrice = this.orderItems.filter(
                (item: HotOrderLineItemExtended) => item.invoicePrice > 0
            );
            const orderItemsNegativePrice = this.orderItems.filter(
                (item: HotOrderLineItemExtended) => item.invoicePrice <= 0
            );
            orderItemsNegativePrice.sort((item1, item2) => item2.invoicePrice - item1.invoicePrice);
            this.orderItems = orderItemsPositivePrice.concat(orderItemsNegativePrice);
        }

        this.emptiesOrderItems = this.order.items.filter((item: HotOrderLineItemExtended) => item.isReturnableEmpty);
    }

    private setTimeout(): void {
        const dateDifference = moment(this.order?.cancelEndDate).valueOf() - moment.utc().valueOf();
        if (!this.cancelOrderByCustomerWhenOrderStatusIsSentToDistributorSetting) {
            if (dateDifference > 0) {
                this.cancelTimeoutId = setTimeout(this.checkOrderCancellationTime.bind(this), dateDifference);
            } else {
                this.order.cancellable = false;
            }
        }

        const editDateDifference = moment(this.order?.editEndDate).valueOf() - moment.utc().valueOf();
        if (editDateDifference > 0) {
            this.editTimeoutId = setTimeout(this.checkOrderEditableTime.bind(this), editDateDifference);
        } else {
            this.order.editable = false;
        }
    }

    private checkOrderCancellationTime(): void {
        this.order.cancellable = false;
        if (this.isEditMode) {
            this.originalOrder.cancellable = false;
        }

        this.changeDetectorRef.detectChanges();
    }

    private checkOrderEditableTime(): void {
        if (this.isEditMode) {
            this.toggleEditMode(false);
        }
        this.order.editable = false;

        this.changeDetectorRef.markForCheck();
    }

    private refreshTimeout(): void {
        clearTimeout(this.cancelTimeoutId);
        clearTimeout(this.editTimeoutId);

        this.setTimeout();
    }

    private initEditOrderSubscriptions(): void {
        this.productService.removedProduct
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe((removedProduct: HotProductExtended) => this.removeProduct(removedProduct));

        this.orderDetailsEditModeService
            .isEditMode()
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe((isActive: boolean) => (this.isEditMode = isActive));

        this.orderDetailsEditEventsService
            .getCancelEvent()
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe(() => this.cancelEditOrder());

        this.orderDetailsEditEventsService
            .getSaveEvent()
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe(() => this.saveEditOrder());
    }

    private changeQuantity(productId: string, quantity: number): void {
        const request = this.getEditOrderRequest(false);

        const changedItemIndex = request.items.findIndex(
            (item: HotLineItem) => item.productId === productId && item.quantity !== quantity
        );

        if (changedItemIndex !== -1) {
            if (quantity === 0) {
                request.items.splice(changedItemIndex, 1);
            } else {
                request.items[changedItemIndex].quantity = quantity;
            }

            this.editOrder(request);
        }
    }

    private getEditOrderRequest(saveOrder: boolean): HotOrderEditRequest {
        return {
            saveOrder,
            items: this.order.items
                .filter((item: HotOrderLineItemExtended) => !item.isGift)
                .map((item: HotOrderLineItemExtended) => {
                    return {
                        productId: item.productId,
                        quantity: this.calculateProductQuantity(item.quantity, item.packageSize),
                    } as HotLineItem;
                }),
        };
    }

    private editOrder(request: HotOrderEditRequest): void {
        this.orderService
            .editOrder(this.order.id, request)
            .pipe(take(1))
            .subscribe((order: HotOrderExtended) => {
                // Temporary solution (fix problem with auto scroll to bottom on change)
                setTimeout(() => {
                    this.order = order;
                    this.setOrderItems();

                    if (request.saveOrder) {
                        this.buttonEditOrder.emit(this.order);

                        this.productToAdd = null;
                        this.newProductQuantity = 0;

                        this.orderDetailsEditModeService.setEditMode(false);
                        this.orderDetailsNotificationsService.showNotification(ORDER_DETAILS_NOTIFICATIONS.savedEdit);
                    }

                    this.checkEditOrderChanges();
                    this.changeDetectorRef.markForCheck();
                }, 1);
            });
    }

    public GoToTrack(): void {
        const orderNumber = this.order.number;

        this.modalService.toggleModal(ModalIds.order, false);

        this.router.navigate([`/orders/${orderNumber}/track-order`]);
        this.analyticService.gpsTracking('order detail page');
    }

    private checkEditOrderChanges(): void {
        const orderItems = this.order?.items
            .filter((item: HotOrderLineItemExtended) => !item.isGift)
            .map((item: HotOrderLineItemExtended) => {
                return { productId: item.productId, quantity: item.quantity };
            });

        const originalOrderItems = this.originalOrder?.items
            .filter((item: HotOrderLineItemExtended) => !item.isGift)
            .map((item: HotOrderLineItemExtended) => {
                return { productId: item.productId, quantity: item.quantity };
            });

        this.orderDetailsEditEventsService.pushHasChangesEvent(!isEqual(orderItems, originalOrderItems));
    }

    private calculateProductQuantity(quantity: number, packageSize: number): number {
        return !this.featuresService.UsePackagesWithCartsAndOrders && packageSize !== 0
            ? Math.round(quantity * packageSize)
            : quantity;
    }
    private getLatestOrders(): void {
        this.latestNumber
            .getLatestOrdersByStatus(this.pageNum)
            .pipe(filter((data) => data.length > 0 && data !== null))
            .subscribe((data: HotOrder[]) => {
                this.orderNumber = data[0].number;
                this.changeDetectorRef.markForCheck();
            });
    }

    //For openeing the poup with Excel and Pdf download icons
    public openExportModal(orderId) {
        this.exportFile.emit(orderId);
    }

    public selectAll() {
        this.selectAllDocuments = false;
        for (let doc of this.order.orderRelatedDocuments) {
            doc.isChecked = true;
            if (doc.type === 'delivery') {
                if (doc.response === undefined || doc.response === '') {
                    this.exportDelivery(doc, doc.hotDelivery);
                }
            }
        }
        this.atleastOneDocumentChecked = false;
    }

    //for opening the list of document
    public downloadIconClick(order: HotOrderExtended, deliveries): void {
        if (order.orderRelatedDocuments.length > 0) {
            order.downloadDocument = true;
            for (let orderDoc of order.orderRelatedDocuments) {
                orderDoc['isChecked'] = false;
                orderDoc['fileType'] = orderDoc.name.split('.').pop();
            }
            order.downloadTimeout = false;
        }

        if (deliveries?.length === 1) {
            let deliveryDetails = {
                fileType: 'xlsx',
                name: deliveries[0].number + '.xlsx',
                isChecked: false,
                type: 'delivery',
                url: '',
                delivery: deliveries[0],
            };
            if (!order.orderRelatedDocuments.some((doc) => doc.name === deliveryDetails.name)) {
                order.downloadDocument = true;
                order.orderRelatedDocuments.push(deliveryDetails);
            }
        }
        setTimeout(() => {
            order.downloadTimeout = true;
            this.documentDownloadTimeout = true;
        }, 300000);
    }

    public deselectAll() {
        this.selectAllDocuments = true;
        for (let doc of this.order.orderRelatedDocuments) {
            doc.isChecked = false;
        }
        this.atleastOneDocumentChecked = true;
    }

    public onChange(orderDocs, document) {
        this.atleastOneDocumentChecked = true;
        for (let doc of orderDocs) {
            if (doc.isChecked) {
                this.atleastOneDocumentChecked = false;
                if (document.type === 'delivery') {
                    if (document.response === undefined || document.response === '')
                        this.exportDelivery(document, document.delivery);
                }
            }
        }
        this.selectAllDocuments = true;
        if (this.checkIfAllDocumentsSelected(orderDocs)) {
            this.selectAllDocuments = false;
        }
    }

    public exportDelivery(document, delivery: HotDelivery): void {
        this.hotApiDeliveriesService
            .exportDelivery(delivery.id)
            .pipe(takeUntil(this.subscriptionDestroy$))
            .subscribe((response) => {
                document.response = response;
            });
    }

    public checkIfAllDocumentsSelected(orderDocs) {
        let docSelected = true;
        for (let doc of orderDocs) {
            if (doc.isChecked === false) {
                docSelected = false;
            }
        }
        return docSelected;
    }

    public download(order) {
        if (this.documentDownloadTimeout) {
            this.documentDownloadTimeout = false;
            this.closeModal.emit();
        } else {
            for (let doc of order.orderRelatedDocuments) {
                if (doc.isChecked) {
                    this.orderDeliveryDownload(doc);
                }
            }

            this.closeModal.emit();
        }
    }
    public orderDeliveryDownload(doc) {
        if (doc.type === 'delivery') {
            FIleDownloadUtil.fileDownload(doc.response, doc.name);
        } else {
            let Urls = [];

            Urls.push({
                FilePathUrl: doc.url,
                FileType: doc.fileType,
            });

            this.apiOrdersService
                .exportFile(Urls)
                .pipe(takeUntil(this.subscriptionDestroy$))
                .subscribe((response: string) => {
                    doc.response = response;
                    FIleDownloadUtil.fileDownload(response, doc.name);
                });
        }
        return doc;
    }

    public closeOrderRatingModal(): void {
        const cancelModalData = {
            orderId: this.order.id,
            fulfillmentCenterId: this.order.fulfillmentCenterId,
            rating: 0,
            lowRatingReason: null,
            isOrderRated: false,
        };
        this.apiOrdersService.addRatingReason(cancelModalData).subscribe(() => {
            this.order.isOrderRated = false;
            this.order.rating = 0;
            this.orderService.orderRatingDetails.next(this.order);
        });

        this.modalService.toggleModal(ModalIds.orderRating, false);
    }
    public closeRatingConfirmationModal(): void {
        this.modalService.toggleModal(ModalIds.ratingConfirmation, false);
    }
    public closeOrderCancelModal(performAction = false): void {
        if (performAction) {
            this.order.status = this.orderStatus.Cancelled;
            this.order.extendedStatus = 'cancelled.customer.default';
            if (typeof performAction === 'string') {
                this.order.cancelReason = performAction;
            }
            this.order.items.forEach(
                (item: HotOrderLineItemExtended) => (item.orderStatus = this.orderStatus.Cancelled)
            );
            this.orderDetailsService.setNewOrderStatus(this.order, this.order.status, this.order.extendedStatus);
            this.updateOrderCancel.emit(this.order);
        }
        this.modalService.toggleModal(ModalIds.orderCancelModal, false);
    }

    public isShowConfirmationFlow(): boolean {
        return (
            this.featureAddReasonsByCustomerWhenCancelOrder &&
            this.featureExtendedOrderDeliveryConfirmationFlow &&
            this.order.status === 'On the way'
        );
    }

    public rejectOrder() {
        this.modalService.toggleModal(ModalIds.rejectListModal, true);
    }

    public confirmOrder() {
        this.modalService.toggleModal(ModalIds.customerOrderConfirmModal, true);
    }

    public rejectOrderSuccess() {
        this.modalService.toggleModal(ModalIds.rejectListModal, false);
        this.closeModal.emit();
    }

    public confirmOrderSuccess(event: any) {
        if (this.featureAddReasonsForPartialDelivery && !event.request.isDeliveredInFull) {
            this.confirmOrderDeliveryRequest = event.request;
            this.modalService.toggleModal(ModalIds.customerOrderConfirmModal, false);
            this.modalService.toggleModal(ModalIds.confirmPartialDeliveryModal, true);
        } else {
            this.modalService.toggleModal(ModalIds.customerOrderConfirmModal, false);
            this.closeModal.emit();
        }
    }

    public backToReject() {
        this.modalService.toggleModal(ModalIds.customerOrderConfirmModal, false);
        this.modalService.toggleModal(ModalIds.rejectListModal, true);
    }

    public confirmPartialDeliveryOrder(request: HotConfirmOrderDeliveryRequest) {
        this.store.dispatch(new ConfirmOrderByCustomer(request));
        this.modalService.toggleModal(ModalIds.confirmPartialDeliveryModal, false);
        this.confirmOrderDeliveryRequest = {};
        this.closeModal.emit();
    }
    public trackOrderIconClick(event: Event, order: HotOrderExtended): void {
        event?.stopPropagation();
        this.modalService.toggleModal(ModalIds.trackAndTraceModal, true, order);
    }

    public closeTrackAndTraceModal() {
        this.modalService.toggleModal(ModalIds.trackAndTraceModal, false);
    }

    public getStoreNameByFfcId(storeId: string) {
        for (const fulfillmentCenter of this.fulfillmentCenters) {
            if (fulfillmentCenter.storeId === storeId) {
                return fulfillmentCenter.storeName;
            }
        }
    }

    public isCustomerPickupEnabled(): boolean {
        return this.featureEnableCustomerPickup;
    }

    public isPickupOrder(order: HotOrderExtended) {
        if(order.shipments.length > 0){
            return order.shipments[0].shipmentMethodCode == DeliveryMethod.PickUp;
        }
    }

    public getPickUpAddress(order: HotOrderExtended): PickUpAddress {
        const pAddress: PickUpAddress = {
            warehouseAddress: '',
            warehouseName: '',
        };

        if (order != undefined && this.isPickupOrder) {
            const address = order?.shipments?.find((item: HotOrderShipment) => {
                return item.shipmentMethodCode == DeliveryMethod.PickUp;
            })?.deliveryAddress;

            pAddress.warehouseAddress = [address?.countryName, address?.city, address?.line1].filter(Boolean).join(',');

            pAddress.warehouseName = address?.firstName;
        }

        return pAddress;
    }

    public getPickUpDate(order: HotOrderExtended) {
        if (order != undefined && this.isPickupOrder) {
            return order?.deliveredDate.formattedValue;
        }
        return '';
    }
    public retryPaymentNotification() {
        return this.router.url.includes('/orders');
    }
    public close() {
        this.isClosed = false;
    }
}
