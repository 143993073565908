import { HotCart, HotProduct } from '@hot-theme-nx/generated-api';

import { ICartLineItem } from '../../models/cart-line-item.model';
import { ProductService } from '../../services/product.service';

import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';

const changeItems = (cart: HotCart, items: ICartLineItem[]) => {
    cart.items = items;
    return cart;
};

const removeItem = (cart: HotCart, productId: string) => {
    const filteredItems = [...cart.items.filter((item) => item.productId !== productId)];

    cart.items = filteredItems;
    if (cart.itemsCount > 0) {
        cart.itemsCount -= 1;
    }

    return cart;
};

const checkProductsTypesCount = (cartItems: ICartLineItem[]) => {
    const alcoholProductsExist = !!find(cartItems, { isAlcoholic: true });
    const nonAlcoholProductsExist = !!find(cartItems, { isAlcoholic: false });
    if (alcoholProductsExist && nonAlcoholProductsExist) {
        return true;
    } else {
        return false;
    }
};

const normalizeItemsQuantity = (cartItems: ICartLineItem[], products: HotProduct[]) => {
    if (!cartItems) {
        return [];
    }
    if (products) {
        cartItems.forEach((item) => {
            products.forEach((product) => {
                if (product.id === item.productId && product.firstQuantityStep) {
                    item.firstQuantityStep = product.firstQuantityStep;
                    item.secondQuantityStep = product.secondQuantityStep;
                }
                return item;
            });
        });
    }
    return cloneDeep(cartItems).map((item: ICartLineItem) => {
        if (item.packageSize !== 0) {
            item.quantity = ProductService.calculateFractionalPart(item.quantity, item.packageSize);
        }
        return item;
    });
};

export { changeItems, checkProductsTypesCount, normalizeItemsQuantity, removeItem };
