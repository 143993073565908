import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getCatalog } from '../../catalog/store/catalog.selector';
import * as fromRoot from '../../store';

import { ICartState } from './cart.reducer';
import { checkProductsTypesCount, normalizeItemsQuantity } from './helpers';

export interface IStateWithCart extends fromRoot.IState {
    cart: ICartState;
}

export const getCartFeatureState = createFeatureSelector<ICartState>('cart');

export const getCart = createSelector(getCartFeatureState, (state) => state.cart);

export const getCartItems = createSelector(getCatalog, getCartFeatureState, (catalog, state) =>
    state.cart ? normalizeItemsQuantity(state.cart.items, catalog.products) : null
);

export const cartIsLoaded = createSelector(getCartFeatureState, (state) => (state.cart ? state.isLoading : null));

export const getCartInfo = createSelector(getCartFeatureState, (state) =>
    state.cart
        ? {
              totalCount: state.cart.itemsCount,
              totalPrice: state.cart.total,
              subTotalPrice: state.cart.subTotal,
              validationErrors: state.cart.validationErrors,
              showAlcoNonalcoSwitcher: checkProductsTypesCount(state.cart.items),
              isLoading: state.isLoading,
              isCartOpened: state.isCartOpened,
          }
        : null
);

export const getCartBuffer = createSelector(getCartFeatureState, (state) => state.changesBuffer);
