import { Component, Input} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '@hot-b2b/store/reducers';
import { SdipStatisticsByIncentive, CurrencyFormat } from '@hot-theme-nx/generated-api';
import { settingsCurrencyFormat } from '@hot-b2b/store/settings/selector';

@Component({
    selector: 'hot-perf-quarter',
    templateUrl: './perf-quarter.component.html',
})
export class PerfQuarterComponent  {
    @Input() public quarterData: SdipStatisticsByIncentive;
    @Input() public hidden: boolean = false;

    public currencyFormat$: Observable<CurrencyFormat>;

    constructor(private readonly store: Store<AppState>) {
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
    }


}
