import { Component, EventEmitter, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ModalIds } from '@hot-libs/shared-types';

import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { cartError, cartErrorCodes } from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import _find from "lodash/find";

@Component({
    selector: 'hot-cart-external-validation-modal',
    templateUrl: './cart-external-validation-modal.component.html',
})
export class CartExternalValidationModalComponent {
    @Output() public createOrderWithError = new EventEmitter();

    public cartErrors$: Observable<any>;
    public cartErrorCodes$: Observable<any>;
    public baseErrorCodes = [
        {
            name: 'Invalid_sender_receiver_application_id',
            code: 'NZ001',
        },
        {
            name: 'shipToCustomer_id_IV_PARTN_NUMBER_has_no_value_',
            code: 'NZ002'
        },
        {
            name: 'productId_has_no_value_',
            code: 'NZ003'
        },
        {
            name: 'quantityOrdered_has_no_value',
            code: 'NZ004'
        },
        {
            name: 'salesUnit_has_no_value_',
            code: 'NZ005'
        },
        {
            name: 'Response_from_the_Sales_Order_API_has_unexpected_format_',
            code: 'NZ006'
        },
        {
            name: 'Unexpected_error_during_the_communication_with_Sales_Order_API_Operation_returned_an_invalid_status_code_InternalServerError_',
            code: 'NZ007'
        },
        {
            name: 'Sales_Order_API_returned_unexpected_status_code_400_Bad_Request_',
            code: 'NZ008'
        },
        {
            name: 'Sales_Order_API_returned_unexpected_status_code_503_Service_Unavailable_',
            code: 'NZ009'
        },
        {
            name: 'HttpErrorResponse',
            code: 'NZ009'
        },
    ]

    constructor(private readonly store: Store<AppState>, private readonly modalService: ModalService) {
        this.cartErrors$ = this.store.pipe(select(cartError));
        this.cartErrorCodes$ = this.store.pipe(select(cartErrorCodes));
    }

    public close(): void {
        this.modalService.toggleModal(ModalIds.cartValidation, false);
    }

    public getErrorCode(errorCode) {
        const error = _find(this.baseErrorCodes, { name: errorCode })
        return error ? error.code : 'unknown';
    }
}
