import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, UrlSerializer } from '@angular/router';

import { CurrencyFormatOrderSimulationPipe, CurrencyFormatPipe } from 'apps/hot-b2b/src/app/shared/pipes';
import { SharedModule } from 'apps/hot-b2b/src/app/shared/shared.module';

import { NotFoundComponent, IframeRedirectorComponent, EazleMigrationErrorScreenComponent } from './components';
import { CoreRoutingModule } from './core-routing.module';
import { BearerTokenInterceptor, TokenExpirationInterceptor } from '@hot-theme-nx/common-api';
import { CustomUrlSerializer } from '@hot-libs/helpers';
import { CanDeactivateGuard } from '../shared/guards';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AzureLoginFailedComponent } from './components/azure-login-failed/azure-login-failed.component';

@NgModule({
    imports: [CommonModule, BrowserModule, BrowserAnimationsModule, CoreRoutingModule, SharedModule],
    exports: [RouterModule],
    declarations: [NotFoundComponent, IframeRedirectorComponent, EazleMigrationErrorScreenComponent, AzureLoginFailedComponent],
    providers: [
        CurrencyFormatPipe,
        CurrencyFormatOrderSimulationPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenExpirationInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BearerTokenInterceptor,
            multi: true,
        },
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer,
        },
        CanDeactivateGuard
    ],
})
export class CoreModule {}
