import { Injectable } from '@angular/core';

import { IBearerToken } from "@hot-libs/shared-models";
import { StorageKeys } from '@hot-libs/shared-types';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationTokenStorageService {
    public getToken(): IBearerToken {
        const tokenValue: string = localStorage.getItem(StorageKeys.bearerToken);
        const result: IBearerToken = JSON.parse(tokenValue);

        return result;
    }

    public getTokenExpirationDate(): Date {
        const dateValue = localStorage.getItem(StorageKeys.bearerTokenExpirationDate);

        return dateValue ? new Date(dateValue) : null;
    }

    public updateToken(token: IBearerToken): void {
        // There might be two cases when stored token value might be updated:
        // - Password authentication - result of token request with grant_type = password (has a refresh token inside);
        // - Access token refresh - result of token request with grant_type = refresh_token (does not have a refresh token inside).
        // We don't want to lose refresh token after refreshing the access token, so we need to 'merge' new token with already stored value.

        const existingToken = this.getToken();

        if (!token.refresh_token && existingToken) {
            token.refresh_token = existingToken.refresh_token;
        }

        const tokenValue: string = JSON.stringify(token);
        localStorage.setItem(StorageKeys.bearerToken, tokenValue);

        this.setExpirationDate(token.expires_in);
    }

    public clear(): void {
        localStorage.removeItem(StorageKeys.bearerToken);
        localStorage.removeItem(StorageKeys.bearerTokenExpirationDate);
        localStorage.removeItem(StorageKeys.userType);
    }

    private setExpirationDate(expiresInSeconds: number): void {
        const currentDate: Date = new Date();
        const expirationDate: Date = currentDate;
        expirationDate.setSeconds(currentDate.getSeconds() + expiresInSeconds);

        localStorage.setItem(StorageKeys.bearerTokenExpirationDate, expirationDate.toISOString());
    }
}
