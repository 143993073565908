import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../shared/services';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { HotApiMarketingService, 
  HotCarouselBanner
} from '@hot-theme-nx/generated-api';
import { settingsData, settingsSupport } from '@hot-b2b/store/settings/selector';
import { HotSettingsExtended, ContactSupport } from '@hot-b2b/store/settings/model';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '@hot-b2b/store/reducers';
import {  map, takeUntil, take } from 'rxjs/operators';
import { GetSettingsSuccess } from 'libs/common-api/src/lib/store';
import { Capacitor } from '@capacitor/core';
import { AppLauncher } from '@capacitor/app-launcher';

@Component({
  selector: 'hot-theme-nx-eazle-migration-error-screen',
  templateUrl: './eazle-migration-error-screen.component.html'
})
export class EazleMigrationErrorScreenComponent implements OnInit {

  public isUpLg = () => this.screenDimensionService.upLg();
  public eazleWebLink : string;
  public iosDownloadLink : string;
  public androidDownloadLink : string;
  public settingdisplayContactSupportPhone : boolean;
  public contactSupportPhone : string;
  private settings$: Observable<HotSettingsExtended>;
  public settingsSupport$: Observable<ContactSupport>;
  private readonly subscriptionDestroy$ = new Subject<boolean>();

  constructor(
    private readonly loaderService: LoaderService,
    private readonly screenDimensionService: ScreenDimensionService,
    private readonly hotApiMarketingService: HotApiMarketingService,
    private readonly store: Store<AppState>,
    ) {
      this.settings$ = this.store.pipe(select(settingsData));
      this.settingsSupport$ = this.store.pipe(select(settingsSupport));
      this.hotApiMarketingService.getCarouselBanners().subscribe((data: HotCarouselBanner[]) => {
        this.eazleWebLink = data.find(item =>
          item.buttonTwoLink?.startsWith('http://', 0) || item.buttonTwoLink?.startsWith('https://', 0)
        ).buttonTwoLink;
      });
    }

  ngOnInit(): void {
    this.loaderService.hide();
    this.settings$
        .pipe(
            takeUntil(this.subscriptionDestroy$),
            map((settings) => GetSettingsSuccess({ settings }))
        )
        .subscribe((settings) => {
            this.iosDownloadLink = settings.settings.eazleIosAppDownloadLink;
            this.androidDownloadLink = settings.settings.eazleAndroidAppDownloadLink;
        });
    this.settingsSupport$.pipe(take(1)).subscribe((settingsSupport) => {
      this.settingdisplayContactSupportPhone = settingsSupport.displayContactSupportPhone;
      this.contactSupportPhone = settingsSupport.contactSupportPhone.replace(/-/g," ");
    });
  }
  public openEazle(): void {
    if ((Capacitor.getPlatform() === 'web')) {
      window.open(this.eazleWebLink,'_blank','noopener,noreferrer');
    } else if (Capacitor.getPlatform() === 'android') {
      AppLauncher.openUrl({ url: this.androidDownloadLink });
    } else if (Capacitor.getPlatform() === 'ios') {
      AppLauncher.openUrl({ url: this.iosDownloadLink });
    }
  }
}
