import { Component } from '@angular/core';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';

@Component({
    selector: 'hot-rating-confirmation-modal',
    templateUrl: './rating-confirmation-modal.component.html',
})
export class RatingConfirmationModal {
    constructor(private readonly modalService: ModalService) {}
    public closeModal() {
        this.modalService.toggleModal(ModalIds.ratingConfirmation, false);
    }
}
