import { Store } from '@ngrx/store';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ModalService } from '../../../services';

import { ModalIds } from '@hot-libs/shared-types';
import { CartErrorClear, CartOrderPlacementError } from '@hot-b2b/store/cart/actions';
import { AppState } from '@hot-b2b/store/reducers';

@Component({
    selector: 'hot-cart-has-changed-modal',
    templateUrl: './cart-has-changed-modal.component.html',
})
export class CartHasChangedModalComponent {
    constructor(
        private readonly modalService: ModalService,
        private readonly router: Router,
        private readonly store: Store<AppState>
    ) {}

    public goToCart(): void {
        this.store.dispatch(new CartErrorClear());
        this.modalService.toggleModal(ModalIds.cartHasChangedModal, false);
        this.store.dispatch(new CartOrderPlacementError());
        this.router.navigateByUrl('/cart');
    }
}
