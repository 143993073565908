import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { HotApiMarketingService, HotInsight } from '@hot-theme-nx/generated-api';
import { FeatureNames, TelemetryEventType } from '@hot-libs/shared-types';

import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { CatalogService } from 'apps/hot-b2b/src/app/catalog/services';
import { MarketingService } from 'apps/hot-b2b/src/app/marketing/services';
import { ApplicationInsightsService, FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import { CartState } from '@hot-b2b/store/cart/model';
import { EInsightsActions } from '@hot-b2b/store/insights/types';
import { AppState } from '@hot-b2b/store/reducers';

import {
    InsightsGet,
    InsightsGetFromCenter,
    InsightsGetProducts,
    InsightsGetProductsSuccess,
    InsightsGetSuccess,
    InsightsGetSuccessFromCenter,
    InsightsSyncronized,
    InsightsSyncronizedSuccess,
} from './actions';

@Injectable()
export class InsightsEffects {
    constructor(
        private readonly store: Store<AppState>,
        private readonly actions$: Actions,
        private readonly marketingService: MarketingService,
        private readonly hotApiMarketingService: HotApiMarketingService,
        private readonly catalogService: CatalogService,
        private readonly featuresService: FeaturesService,
        private readonly appInsightsService: ApplicationInsightsService
    ) {}

    public InsightsGet$ = createEffect(() => this.actions$.pipe(
        ofType<InsightsGet>(EInsightsActions.INSIGHTS_PENDING),
        withLatestFrom(this.featuresService.checkFeatures(FeatureNames.StoreIsRequiredForOutlet)),
        tap(() => this.appInsightsService.startTrackingEvent(TelemetryEventType.InsightsLoadingTime)),
        switchMap(([_insight, storeIsRequiredForOutlet]: [InsightsGet, boolean]) => {
            const insightsParams: HotApiMarketingService.GetInsightsParams = {
                pageSize: null,
                fulfillmentCenterId: null,
            };
            return forkJoin([
                storeIsRequiredForOutlet
                    ? this.hotApiMarketingService
                          .getInsights(insightsParams)
                          .pipe(map((items: HotInsight[]) => [{ id: '', insights: items }]))
                    : this.marketingService.getInsightsFromCenters()
            ]);
        }),
        map((response: any) => response[0]),
        map((response: any) => new InsightsGetSuccess(response)),
        tap(() => this.appInsightsService.endTrackingEvent(TelemetryEventType.InsightsLoadingTime))
    ));

    public InsightsGetFromCenter$ = createEffect(() => this.actions$.pipe(
        ofType<InsightsGetFromCenter>(EInsightsActions.INSIGHTS_PENDING_FROM_CENTER),
        tap(() => this.appInsightsService.startTrackingEvent(TelemetryEventType.InsightsLoadingTime)),
        switchMap((data: InsightsGetFromCenter) => {
            const insightsParams: HotApiMarketingService.GetInsightsParams = {
                pageSize: null,
                fulfillmentCenterId: data.payload,
            };
            return data.payload
                ? this.hotApiMarketingService
                      .getInsights(insightsParams)
                      .pipe(map((items: HotInsight[]) => [{ id: data.payload, insights: items }]))
                : of([]);
        }),
        map((response: any) => [response[0]]),
        map((response: any) => new InsightsGetSuccessFromCenter(response)),
        tap(() => this.appInsightsService.endTrackingEvent(TelemetryEventType.InsightsLoadingTime))
    ));

    public InsightsGetProducts$ = createEffect(() => this.actions$.pipe(
        ofType<InsightsGetProducts>(EInsightsActions.INSIGHTS_GET_PRODUCTS),
        switchMap((action: InsightsGetProducts) =>
            this.catalogService
                .getProducts(action.payload)
                .pipe(
                    switchMap((response: HotProductExtended[]) => [
                        new InsightsGetProductsSuccess(response),
                        new InsightsSyncronized(),
                    ])
                )
        )
    ));

    public InsightsSyncronized: Observable<InsightsSyncronizedSuccess> = createEffect(() => this.actions$.pipe(
        ofType<InsightsSyncronized>(EInsightsActions.INSIGHTS_SYNCRONIED),
        withLatestFrom(this.store.pipe(select((state: AppState) => state.cart))),
        map(
            (action: [InsightsSyncronized, CartState]) =>
                new InsightsSyncronizedSuccess(action[0].payload ? action[0].payload.items : action[1].data.cart.items)
        )
    ));
}
