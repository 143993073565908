import { Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine } from '@hot-b2b/store/settings/selector';
import { AuthenticationService, UserService } from 'apps/hot-b2b/src/app/account/services';
import { AccountService } from '@hot-theme-nx/common-api';
import { HotUserExtended } from '@hot-libs/shared-models';
import { authUser } from '@hot-b2b/store/auth/selector';
import { ConfigurationService } from '../../services/configuration.service';
import { Capacitor } from '@capacitor/core';
import { HotApiCustomerService, HotDeviceTokenRequestBody } from '@hot-theme-nx/generated-api';
import { FeaturesService } from '../../services/features.service';
import { AccountType } from '@hot-libs/shared-types';
import { PermissionsService } from '../../services/permissions.service';

@Component({
    selector: 'hot-desktop-menu',
    templateUrl: './desktop-menu.component.html',
})
export class DesktopMenuComponent {
    @Input() public isModeSwitcherVisible = true;
    @Output() public toggle = new EventEmitter();
    deleteDeviceTokenbody: HotDeviceTokenRequestBody;

    public settingsAppOnLine$: Observable<boolean>;
    public user$: Observable<HotUserExtended>;
  
    public isHungary: boolean;
    private isNative = false;
    public readonly accountType = AccountType;
    public canUserAccessOpenItem: boolean = false;

    constructor(
        private readonly store: Store<AppState>,
        private readonly authenticationService: AuthenticationService,
        public readonly accountService: AccountService,
        private readonly configurationService: ConfigurationService,
        private readonly hotApiCustomerService: HotApiCustomerService,
        private readonly userService: UserService,
        private readonly featuresService: FeaturesService,
        private readonly permissionsService: PermissionsService,
    ) {
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
        this.user$ = this.store.pipe(select(authUser));
       
        this.isHungary = configurationService.getCurrentConfiguration() == 'HU' ? true : false;
    }
    public ngOnInit(): void {

        this.permissionsService
        .canUserAccessOpenItem()
        .subscribe((canUserAccessOpenItem: boolean) => (this.canUserAccessOpenItem = canUserAccessOpenItem));

        if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
            this.isNative = true;
        }
    }

    public signOut(): void {
        if (this.isNative) {
            this.deleteDeviceTokenbody = {
                token: localStorage.getItem('devicetoken'),
            };
            this.hotApiCustomerService.deleteDeviceToken(this.deleteDeviceTokenbody).subscribe();
            localStorage.removeItem('devicetoken');
        }
        // Resetting Publish Mobile Event to default
        this.userService.publishAppOpenedMobileEvent = true;

        this.settingsAppOnLine$.pipe(take(1)).subscribe((isOnline: boolean) => {
            if (isOnline) {
                this.authenticationService.logout();
            }
        });
    }

    public getTrackData(text) {
        return `{ "data_interaction": "Link_click", "button_text": "${text}", "name":"Desktop Menu" }`;
    }
}
