import { PromotionsState } from '@hot-b2b/store/promotions/model';
import { EPromotionsActions } from '@hot-b2b/store/promotions/types';
import { promotionsInitialState } from '@hot-b2b/store/promotions/state';
import { PromotionActions } from '@hot-b2b/store/promotions/actions';
import { editQuantity } from '@hot-b2b/store/products/helpers';

export function promotionsReducer(
    state: PromotionsState = promotionsInitialState,
    action: PromotionActions
): PromotionsState {
    switch (action.type) {
        case EPromotionsActions.PROMOTIONS_PENDING:
        case EPromotionsActions.PROMOTIONS_PENDING_FROM_CENTER: {
            return {
                ...state,
                fetched: false,
                pending: true,
                error: null,
            };
        }
        case EPromotionsActions.PROMOTIONS_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    slots: action.payload,
                },
            };
        }
        case EPromotionsActions.PROMOTIONS_FETCHED_FROM_CENTER: {
            let newSlots = state.data.slots;
            newSlots.forEach((element, index) => {
                if (element.id === action.payload[0]['id']) newSlots.splice(index, 1);
            });
            newSlots.push(...action.payload);
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    slots: newSlots,
                },
            };
        }
        case EPromotionsActions.PROMOTIONS_ERROR: {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        }
        case EPromotionsActions.PROMOTIONS_GET_PRODUCTS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    products: action.payload,
                },
            };
        }
        case EPromotionsActions.PROMOTIONS_CLEAR_PRODUCTS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    products: [],
                },
            };
        }
        case EPromotionsActions.PROMOTIONS_SYNCHRONIZED_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    products: editQuantity(state.data.products, action.payload),
                },
            };
        }
        case EPromotionsActions.PROMOTIONS_GET_ORDER_SUGGESTIONS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    orderSuggestion: action.payload,
                },
            };
        }
        default:
            return state;
    }
}
