import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookiesService } from '../services/cookies.service';
import { HotCookies } from '../services/constants.service';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ItemLocationService {
    private itemLocationSubject = new BehaviorSubject<ItemLocation[]>([] as ItemLocation[]);
    itemLocation$ = this.itemLocationSubject.asObservable();

    constructor(public readonly cookiesService: CookiesService) {
        const storedLocation = this.cookiesService.getCookie(HotCookies.ITEM_LIST_LOCATION);
        if (storedLocation) {
            const locations: ItemLocation[] = JSON.parse(storedLocation);
            this.itemLocationSubject.next(locations);
        } else {
            this.itemLocationSubject.next([]);
        }
    }

    addItemLocation(itemId: string, location: string) {
        const item = this.getItemLocation(itemId);
        const outletId = this.cookiesService.getCookie("HOT-Current-Outlet");
        const currentItems = this.itemLocationSubject.getValue();
        const existingIndex = currentItems.findIndex(item=> 
        item.itemId === itemId && item.outletId === outletId)
        if (!item) {
            if (!this.itemLocationSubject.value.find(loc => loc.itemId === itemId)) {
                let addItemLocation = location === "noUpdate" ? "cart" : location;
                const newLocation: ItemLocation = { itemId, location: addItemLocation, outletId };
                this.itemLocationSubject.next([...this.itemLocationSubject.value, newLocation]);
                const item_list_name = JSON.stringify([...this.itemLocationSubject.value]);
                this.cookiesService.setExpireItemListCookie(HotCookies.ITEM_LIST_LOCATION, item_list_name);
            }
        }

        else if(item!= undefined && existingIndex == -1) {
            const newItems: ItemLocation = { itemId, location, outletId };
            currentItems.push(newItems)
            this.itemLocationSubject.next(currentItems);
            this.cookiesService.setExpireItemListCookie(HotCookies.ITEM_LIST_LOCATION, JSON.stringify(currentItems));
        }
        else if ((item != undefined && location != item.location) && (location != "noUpdate") && existingIndex!== -1 ) {
            currentItems[existingIndex] = {itemId, location, outletId}
            this.itemLocationSubject.next([...currentItems]);
            this.cookiesService.setExpireItemListCookie(HotCookies.ITEM_LIST_LOCATION, JSON.stringify(currentItems));
        }
        else {
           this.itemLocationSubFunction(item,itemId,location);
        }
    }

    itemLocationSubFunction(item, itemId,location) {
        const itemHasRemovedFrom = !!item.removedFromPage;
            if (itemHasRemovedFrom) {
                const removedFrom = this.itemLocationSubject.value;
                const index = removedFrom.findIndex(locations => locations.itemId === itemId);
                if (index !== -1) {
                    removedFrom[index].removedFromPage = undefined;
                    removedFrom[index].location = location;
                    this.itemLocationSubject.next(removedFrom);
                    this.cookiesService.setExpireItemListCookie(HotCookies.ITEM_LIST_LOCATION, JSON.stringify(removedFrom));
                }
            }
    }

    removeItemLocation(itemId: string, removedFrom) {
        const locations = this.itemLocationSubject.value;
        const index = locations.findIndex(locations => locations.itemId === itemId);

        if (index !== -1) {
            locations[index].removedFromPage = removedFrom;
            this.itemLocationSubject.next(locations);
            this.cookiesService.setExpireItemListCookie(HotCookies.ITEM_LIST_LOCATION, JSON.stringify(locations));
        } else {
            console.warn(`item with id ${itemId} not found for removal`);
        }
    }
    addItemListLocation(items: any[], location?: string) {
        let itemIds = items.map(item => item.product.sku)
        itemIds.forEach(itemId => {
            this.addItemLocation(itemId, location)
        })
    }


    getItemLocation(itemId: string) {
        return this.itemLocationSubject.value.find(location => location.itemId === itemId);
    }

    getItemLocationFromStorage(itemId: string) {
        const storedLocation = this.cookiesService.getCookie(HotCookies.ITEM_LIST_LOCATION);
        if (!storedLocation) {
            return undefined;
        }

        try {
            const itemLocations: ItemLocation[] = JSON.parse(storedLocation);
            const matchedLocation = itemLocations.find(location => location.itemId === itemId);
            return matchedLocation
        } catch (error) {
            console.log("Error parsing item locations from local storage:", error);
            return undefined;
        }
    }

    deleteProductUsingOutletId() {
            const storeId = this.cookiesService.getCookie("HOT-Current-Outlet");

            this.itemLocationSubject.pipe(take(1)).subscribe((items: ItemLocation[]) => {
                const filteredItems = items.filter((item: ItemLocation) => {
                    return item.outletId !== storeId;
                });
                this.itemLocationSubject.next(filteredItems);
                this.cookiesService.setExpireItemListCookie(HotCookies.ITEM_LIST_LOCATION,JSON.stringify(filteredItems));
            });
    }
}
  
interface ItemLocation {
    itemId: string;
    location: string;
    removedFromPage?: string;
    outletId: string;
}
