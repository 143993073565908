import { Component,Input } from '@angular/core';

@Component({
    selector: 'hot-order-steps',
    templateUrl: './order-steps.component.html',
})
export class OrderStepsComponent  {
    @Input() public stepNumber = 'step-1';

  
}
