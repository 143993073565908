import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CurrencyFormat } from '@hot-theme-nx/generated-api';

import { LineItemsCountService } from '../../services/line-items-count.service';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';

import {
    cartItems,
    cartTotals,
    cartSubTotal,
    extendedPriceTotal,
    checkOutCartItems,
    checkOutCartTotals,
    checkoutCartSubTotal,
    checkoutCartExtendedPriceTotal,
} from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsCurrencyFormat, settingsStore } from '@hot-b2b/store/settings/selector';
import { HotCartLineItemExtended } from 'apps/hot-b2b/src/app/shared/models';
import { filter, map } from 'rxjs/operators';
import { ConfigurationService } from '../../services/configuration.service';
import { SettingsStoreModel } from '@hot-libs/shared-models';

@Component({
    selector: 'hot-cart-informer',
    templateUrl: './cart-informer.component.html',
})
export class CartInformerComponent {
    @Input()
    public modifier: string;
    @Input() public isReturn: boolean;
    @Input() isItemsNumberHidden = false;

    public cartItems$: Observable<HotCartLineItemExtended[]>;
    public itemsCount$: Observable<number>;
    public cartTotal$: Observable<number>;
    public cartSubTotal$: Observable<number>;
    public extendedPriceTotal$: Observable<number>;
    public currencyFormat$: Observable<CurrencyFormat>;
    public isNgB2b = () => this.configurationService.getCurrentConfiguration() === 'NG';
    public settingsStore$: Observable<SettingsStoreModel>;

    public featureSplitOrdersBySuppliersWhenCheckout: boolean = this.featuresService.SplitOrdersBySuppliersWhenCheckout;

    constructor(
        private readonly store: Store<AppState>,
        private readonly lineItemsCountService: LineItemsCountService,
        private readonly featuresService: FeaturesService,
        public readonly configurationService: ConfigurationService
    ) {
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );

        if (this.featureSplitOrdersBySuppliersWhenCheckout) {
            this.cartItems$ = this.store.pipe(
                select(checkOutCartItems),
                map((items: HotCartLineItemExtended[]) =>
                    items.filter((item: HotCartLineItemExtended) => !item.isReturnableEmpty)
                )
            );

            this.itemsCount$ = this.lineItemsCountService.getItemsCount(this.cartItems$);
            this.cartTotal$ = this.store.pipe(
                select(checkOutCartTotals),
                filter((data) => !!data)
            );

            this.cartSubTotal$ = this.store.pipe(
                select(checkoutCartSubTotal),
                filter((data) => !!data)
            );

            this.extendedPriceTotal$ = this.store.pipe(
                select(checkoutCartExtendedPriceTotal),
                filter((data) => !!data)
            );
        } else {
            this.cartItems$ = this.store.pipe(
                select(cartItems),
                map((items: HotCartLineItemExtended[]) =>
                    items.filter((item: HotCartLineItemExtended) => !item.isReturnableEmpty)
                )
            );

            this.itemsCount$ = this.lineItemsCountService.getItemsCount(this.cartItems$);
            this.cartTotal$ = this.store.pipe(
                select(cartTotals),
                filter((data) => !!data)
            );

            this.cartSubTotal$ = this.store.pipe(
                select(cartSubTotal),
                filter((data) => !!data)
            );

            this.extendedPriceTotal$ = this.store.pipe(
                select(extendedPriceTotal),
                filter((data) => !!data)
            );
        }
    }
}
