import { Component, OnInit, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { PATHS } from 'apps/hot-b2b/src/app/shared/services/constants.service';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { ModalService } from '../../../shared/services';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
@Component({
    selector: 'hot-product-image',
    templateUrl: './product-image.component.html',
})
export class ProductImageComponent implements OnInit {
    @Input() imgURL: string;
    @Input() srcSet: string;
    @Input() modifier: string;
    @Input() isImport: boolean;
    @Output() errorHandle = new EventEmitter<boolean>();

    loading = true;
    public isUpLg = () => this.screenDimensionService.upLg();
    public imageZoomState$: Observable<ModalsState>;

    @ViewChild('element') element: ElementRef;
    @ViewChild('imgTarget') imgTarget: ElementRef;

    public img;
    public parentSize;
    public scale = 1;
    public xPosition;
    public yPosition;
    public isPdp = false;
    public zoomed = false;
    public fullScreenMode = false;

    onLoad() {
        this.loading = false;
    }

    errorHandler(loadingError: boolean) {
        this.errorHandle.emit(loadingError);
        this.imgURL = `${PATHS.IMAGES}/product-placeholder.png`;
        this.srcSet = `${PATHS.IMAGES}/product-placeholder@2x.png`;
        this.loading = false;
    }

    constructor(
        public readonly screenDimensionService: ScreenDimensionService,
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService
    ) {
        {
            this.imageZoomState$ = this.store.pipe(select(selectModalState(ModalIds.imageZoom)));
        }
    }

    ngOnInit() {
        if (this.modifier) {
            this.isPdp = Object.keys(this.modifier).includes('detail');
            this.fullScreenMode = Object.keys(this.modifier).includes('fullScreenMode');
        }
    }

    ngAfterViewInit() {
        if (this.isPdp) {
            this.parentSize = {
                element: this.element.nativeElement.parentNode,
                imgTarget: this.imgTarget.nativeElement,

                get width() {
                    return this.element.offsetWidth;
                },
                get height() {
                    return this.element.offsetHeight;
                },
                get imageWidth() {
                    return this.imgTarget.offsetWidth;
                },
                get imageHeight() {
                    return this.imgTarget.offsetHeight;
                },
            };

            this.element.nativeElement.style.width = `inherit`;
            this.imgTarget.nativeElement.style.imageWidth = `inherit`;
            this.element.nativeElement.style.height = this.isUpLg ? 201 : 119;
            if (!this.zoomed) {
                this.xPosition = `${this.parentSize.imageWidth}px`;
                this.yPosition = this.isUpLg ? 201 : 119;
            }
        }
    }

    //on zoom
    public imgZoom(event, zoom = 1): void {
        if (this.isUpLg()) {
            this.zoomed = true;
            const img1 = event.target?.children[0];
            const img = this.imgTarget.nativeElement;
            this.xPosition = 225;
            img.style.width = `${this.xPosition * zoom}px`;
            img.style.height = `${this.yPosition * zoom}px`;

            if (!img1) {
                let left = Math.round(event.layerX / 2);
                if (left >= 0) {
                    img.style.left = `-${Math.round(event.layerX / 4)}px`;
                } else {
                    img.style.left = `${Math.round(event.layerX / 4)}px`;
                }
                img.style.top = `-${Math.round(event.layerY / 2)}px`;
            } else {
                img.style.left = `-${event.layerX * (zoom - 1)}px`;
                img.style.top = `-${event.layerY * (zoom - 1)}px`;
            }
        }
    }

    public resetImgZoom(_event, _zoom = 1): void {
        if (this.isUpLg()) {
            const img = this.imgTarget.nativeElement;
            this.zoomed = false;
            this.yPosition = this.isUpLg ? 201 : 119;

            if (img) {
                img.style.width = `${this.xPosition}px`;
                img.style.height = `${this.yPosition}px`;
                img.style.top = `${0}px`;
                img.style.left = `auto`;
            }
        }
    }

    public onResize(): void {
        this.isUpLg = () => this.screenDimensionService.upLg();
    }

    openFullscreenView() {
        if (!this.isUpLg()) this.modalService.toggleModal(ModalIds.imageZoom, true)
    }
}
