import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';

export const clearPriceToNumber = (value: any): number => {
    if (!isNumber(value)) {
        const clearString = value.replace(/,|\$|\^[a-zA-Z].*/g, '');
        if (!isNaN(Number(clearString))) {
            return Number(clearString);
        }
    }
    return value; 
};