import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { ModalIds } from '@hot-libs/shared-types';
import { select, Store } from '@ngrx/store';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'hot-theme-nx-outlet-failed-registration-modal',
  templateUrl: './outlet-failed-registration-modal.component.html'
})
export class OutletFailedRegistrationModalComponent {
  public modalFailedOutletRegistrationState$: Observable<ModalsState>;

  constructor(private readonly modalService: ModalService,private readonly store: Store<AppState>,private readonly router: Router) {
    this.modalFailedOutletRegistrationState$=this.store.pipe(select(selectModalState(ModalIds.failedOutletRegistration)));

   }
 


  public toggleModal(){
    this.modalService.toggleModal(ModalIds.failedOutletRegistration,false);
  }

  public closeModal(): void {
    this.modalService.toggleModal(ModalIds.failedOutletRegistration, false);
    this.router.navigate(['/account/sign-in']);
  }   

}
