import { Component, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../../services';

@Component({
    selector: 'hot-report-issue-thanks-modal',
    templateUrl: './report-issue-thanks-modal.component.html',
})
export class ReportIssueThanksModalComponent {
    @Output() public buttonClose = new EventEmitter();

    constructor(private readonly modalService: ModalService) {}

    public close(): void {
        this.buttonClose.emit();
    }

}
