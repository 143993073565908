import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CurrencyFormat } from '@hot-theme-nx/generated-api';
import { HotUserExtended, SettingsStoreModel } from '@hot-libs/shared-models';

import { HotCartExtended } from 'apps/hot-b2b/src/app/shared/models';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import { authUser } from '@hot-b2b/store/auth/selector';
import { cartData } from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsCurrencyFormat, settingsStore } from '@hot-b2b/store/settings/selector';

@Component({
    selector: 'hot-user-info',
    templateUrl: './user-info.component.html',
})
export class UserInfoComponent implements OnInit, OnDestroy {
    public remainingBalance: number;
    public currencyFormat: CurrencyFormat;
    public userData: any[];

    public authUser$: Observable<HotUserExtended>;
    public cartData$: Observable<HotCartExtended>;
    public settingsStore$: Observable<SettingsStoreModel>;

    public featureOutletCreditLimit: boolean = this.featuresService.OutletCreditLimit;
    public featureOrganizationLevelCreditLimitCheck = this.featuresService.OrganizationLevelCreditLimitCheck;


    public currencyFormat$: Observable<CurrencyFormat>;

    private readonly unsubscribe$ = new Subject();

    constructor(private readonly store: Store<AppState>, private readonly featuresService: FeaturesService) {
        this.remainingBalance = 0;
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.authUser$ = this.store.pipe(select(authUser));
        this.cartData$ = this.store.pipe(select(cartData));
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
    }

    public ngOnInit(): void {
        this.cartData$
            .pipe(withLatestFrom(this.authUser$), takeUntil(this.unsubscribe$))
            .subscribe(([cart, user]: [HotCartExtended, HotUserExtended]) => {
                if (cart && user && user.contact) {
                    if (this.featureOrganizationLevelCreditLimitCheck && user?.contact?.organization) {
                        this.remainingBalance = user.contact.organization.creditAmount - cart.total;
                    } else if (!this.featureOrganizationLevelCreditLimitCheck && user?.contact?.outlet) {
                        this.remainingBalance = user.contact.outlet.creditAmount - cart.total;
                    }
                }
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(undefined);
        this.unsubscribe$.complete();
    }
}
