import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CurrencyFormat } from '@hot-theme-nx/generated-api';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';

import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsStore } from '@hot-b2b/store/settings/selector';

@Component({
    selector: 'hot-totals',
    templateUrl: './totals.component.html',
})
export class TotalsComponent implements OnInit, OnDestroy {
    @Input() public modifier: string;
    @Input() public subTotal: number;
    @Input() public deposit: number;
    @Input() public tax: number;
    @Input() public total: number;
    @Input() public netTotal: number;
    @Input() public discountTotal: number;
    @Input() public extendedTotal: number;
    @Input() public orderStatus: string;
    @Input() public hasInvoice: boolean;
    @Input() public invoiceTotalPrice: string;
    @Input() public invoiceTotalDiscount: string;
    @Input() public invoiceTotalTax: string;
    @Input() public invoiceTotalAmount: string;
    @Input() public isPointsCurrency: boolean;

    public readonly settingsStore$: Observable<SettingsStoreModel>;
    public readonly modalTermsState$: Observable<ModalsState>;

    public currencyFormat: CurrencyFormat;

    private readonly unsubscribe$ = new Subject();

    constructor(private readonly store: Store<AppState>, private readonly modalService: ModalService) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
        this.modalTermsState$ = this.store.pipe(select(selectModalState(ModalIds.termsAndConditions)));
    }

    public ngOnInit(): void {
        this.settingsStore$.pipe(takeUntil(this.unsubscribe$)).subscribe((settings: SettingsStoreModel) => {
            this.currencyFormat = cloneDeep(settings.currencyFormat);
            if (this.isPointsCurrency) {
                this.currencyFormat = cloneDeep(settings.loyaltyCurrencyFormat);
            }
        });
    }

    public showTermsAndUseModal(): void {
        this.modalService.toggleModal(ModalIds.termsAndConditions, true);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(undefined);
        this.unsubscribe$.complete();
    }
}
