import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
    canDeactivate: (
        currentUrl?: string,
        nextUrl?: string
    ) => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
}

@Injectable()
export class CanDeactivateGuard  {
    canDeactivate(
        _component: CanComponentDeactivate,
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot,
        _nextState?: RouterStateSnapshot
    ) {
        return _component.canDeactivate ? _component.canDeactivate(_state.url, _nextState.url) : true;
    }
}
