import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import {
    HotConfirmOrderDeliveryRequest,
    HotConfirmOrderDeliveryRequestItem,
    CurrencyFormat,
} from '@hot-theme-nx/generated-api';

import { ChangeProductQuantityModel } from '../../../models';
import { OrderDetailsService, ProductService } from '../../../services';

import { HotOrderExtended, HotOrderLineItemExtended, SettingsStoreModel} from '@hot-libs/shared-models';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsStore } from '@hot-b2b/store/settings/selector';

@Component({
    selector: 'hot-theme-distributor-order-confirm-received-condition-modal',
    templateUrl: './distributor-order-confirm-received-condition-modal.component.html',
})
export class DistributorOrderConfirmReceivedConditionModalComponent implements OnInit, OnDestroy {
    @Input() public order: HotOrderExtended;
    @Input() public dateFormat: string;

    @Output() public confirmEvent: EventEmitter<HotConfirmOrderDeliveryRequest> = new EventEmitter();

    public pageName = 'ConfirmCustomerOrderModal';
    public locale: string;
    public currencyFormat: CurrencyFormat;

    private initOrderItems: HotConfirmOrderDeliveryRequestItem[] = [];
    public confirmOrderRequest: HotConfirmOrderDeliveryRequest = {
        isDeliveredInFull: true,
        items: [],
        orderId: '',
    };

    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    public readonly settingsStore$: Observable<SettingsStoreModel>;

    constructor(
        private readonly productService: ProductService,
        public readonly orderDetailsService: OrderDetailsService,
        private readonly store: Store<AppState>,
    ) {
        this.settingsStore$ = this.store.pipe(
            select(settingsStore),
            filter((value: SettingsStoreModel) => !!value)
        );
    }

    ngOnInit(): void {
        this.initOrderConfirmRequest();
        this.productService.changeProductQuantityEvent
            .pipe(takeUntil(this.subscriptionsDestroy$))
            .subscribe((data: ChangeProductQuantityModel) => {
                if (data?.productId) {
                    this.updateProductQuantity(data);
                }
            });
    }

    public confirmOrder(): void {
        this.confirmEvent.emit(this.confirmOrderRequest);
    }

    public setFullyDeliveryCondition(condition: boolean) {
        this.confirmOrderRequest.isDeliveredInFull = condition;
    }

    public setPartiallyDeliveryCondition(condition: boolean) {
        this.confirmOrderRequest.isDeliveredInFull = !condition;
    }

    public setDateFormat(date): Observable<string> {
        return this.orderDetailsService.setDateFormat(date);
    }

    public isConfirmButtonDisabled(): boolean {
        return !this.confirmOrderRequest.isDeliveredInFull && !this.isOrderChanged();
    }

    public isOrderChanged(): boolean {
        return !this.initOrderItems.every(
            (initItem: HotConfirmOrderDeliveryRequestItem) =>
                initItem.quantity ===
                this.confirmOrderRequest.items.find(
                    (reqItem: HotConfirmOrderDeliveryRequestItem) => reqItem.productId === initItem.productId && reqItem.isGift === initItem.isGift
                ).quantity
        );
    }
    public initOrder(item)
    {
        return    {
            isGift: item.isGift,
                productId: item.productId,
                    quantity: this.order.hasInvoice ? item.deliveredQuantity : item.quantity,
        }
    }


    private initOrderConfirmRequest() {
        this.confirmOrderRequest.orderId = this.order.id;
        this.confirmOrderRequest.items = this.order.items.map((item: HotOrderLineItemExtended) => {
            return this.initOrder(item);
        });
        this.initOrderItems = this.order.items.map((item: HotOrderLineItemExtended) => {
            return this.initOrder(item);
        });
    }
   
    private updateProductQuantity(changeModel: ChangeProductQuantityModel) {
        const index = this.confirmOrderRequest.items.findIndex(
            (item) => item.productId === changeModel.productId && item.isGift === changeModel.isGift
        );
        this.confirmOrderRequest.items[index].quantity = changeModel.quantity;
    }

    ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }
}
