import { tutorialsInitialState } from '@hot-b2b/store/tutorials/state';
import { TutorialsState } from '@hot-b2b/store/tutorials/model';
import { TutorialsAction } from '@hot-b2b/store/tutorials/actions';
import { ETutorialsActions } from '@hot-b2b/store/tutorials/types';

export function tutorialsReducer(
    state: TutorialsState = tutorialsInitialState,
    action: TutorialsAction
): TutorialsState {
    switch (action.type) {
        case ETutorialsActions.TUTORIALS_PENDING: {
            return {
                ...state,
                fetched: false,
                pending: true,
            };
        }
        case ETutorialsActions.TUTORIALS_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    tutorials: action.payload,
                },
            };
        }
        case ETutorialsActions.TUTORIALS_STARTED: {
            return {
                fetched: false,
                pending: true,
                data: {
                    ...state.data,
                    isStarted: action.payload,
                    currentTutorial: action.payload ? state.data.currentTutorial : null,
                    currentStep: action.payload ? state.data.currentStep : null,
                },
            };
        }
        case ETutorialsActions.TUTORIALS_SET_CURRENT: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    currentTutorial: action.payload,
                },
            };
        }
        case ETutorialsActions.TUTORIALS_SET_CURRENT_STEP: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    currentStep: action.payload,
                },
            };
        }
        case ETutorialsActions.SHOW_TUTORIALWITHID: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    showTutorialWithId: action.payload,
                },
            };
        }
        case ETutorialsActions.CLEAR_TUTORIALWITHID: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    showTutorialWithId:"",
                },
            };
        }
        default:
            return state;
    }
}
