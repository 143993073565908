import { OrdersActions } from '@hot-b2b/store/orders/actions';
import {
    getDefaultSearchCriteria,
    getOrdersDemoData,
    updatedOrders,
    updatedOrdersResponse,
} from '@hot-b2b/store/orders/helpers';
import { OrdersState } from '@hot-b2b/store/orders/model';
import { ordersInitialState } from '@hot-b2b/store/orders/state';
import { EOrdersActions } from '@hot-b2b/store/orders/types';

export function ordersReducer(state: OrdersState = ordersInitialState, action: OrdersActions): OrdersState {
    switch (action.type) {
        case EOrdersActions.ORDERS_RESPONSE_PENDING:
        case EOrdersActions.ORDERS_PREVIOUS_PENDING:
        case EOrdersActions.ORDERS_LAST_PENDING: {
            return {
                ...state,
                fetched: false,
                pending: true,
            };
        }
        case EOrdersActions.ORDERS_RESPONSE_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    ordersResponse: action.payload,
                },
            };
        }
        case EOrdersActions.ORDERS_PREVIOUS_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: false,
                data: {
                    ...state.data,
                    previous: action.payload.orders,
                    statistics: action.payload.statistics,
                },
            };
        }
        case EOrdersActions.ORDERS_LAST_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: false,
                latestOrdersFetchStatus: true,
                data: {
                    ...state.data,
                    latestOrder: action.payload.latestOrder,
                },
            };
        }

        case EOrdersActions.ORDERS_CHANGE_STATUS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    previous: updatedOrders(state.data.previous, action.payload),
                    ordersResponse: updatedOrdersResponse(state.data.ordersResponse, action.payload),
                },
            };
        }

        case EOrdersActions.ORDERS_DEMO_DATA_SET: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    ordersResponse: {
                        ...state.data.ordersResponse,
                        totalCount: 1,
                        orders: getOrdersDemoData(),
                    },
                },
            };
        }

        case EOrdersActions.ORDERS_DEMO_DATA_CLEAR: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    ordersResponse: {
                        ...state.data.ordersResponse,
                        totalCount: 0,
                        orders: [],
                    },
                },
            };
        }

        case EOrdersActions.ORDERS_UPDATE_SEARCH_CRITERIA: {
            return {
                ...state,
                pending: true,
                fetched: false,
                data: {
                    ...state.data,
                    searchOrdersCriteria: null,
                },
            };
        }

        case EOrdersActions.ORDERS_UPDATE_SEARCH_CRITERIA_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    searchOrdersCriteria: action.payload,
                },
            };
        }

        case EOrdersActions.ORDERS_SET_DEFAULT_SEARCH_CRITERIA: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    searchOrdersCriteria: getDefaultSearchCriteria(),
                },
            };
        }

        case EOrdersActions.ORDERS_CUSTOMER_REJECT_REASONS_SUCCESS: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    customerOrderRejectReasons: action.payload,
                },
            };
        }
        case EOrdersActions.ORDER_SIMULATION_SUCCCES: {
            return {
                ...state,
                orderSimulation: {
                    ...state.orderSimulation,
                    taxDetails: {
                        ...state.orderSimulation.taxDetails,
                        succeeded: action.payload
                    }
                },
            };
        }

        case EOrdersActions.ORDER_SIMULATION_SUCCESSFUL_RESPONSE: {
            return {
                ...state,
                orderSimulation: {
                    ...state.orderSimulation,
                    taxDetails: action.payload
                },
            }
        }

        case EOrdersActions.ORDER_SIMULATION_FETCHED: {
            return {
                ...state,
                orderSimulation: {
                    ...state.orderSimulation,
                    fetched: true
                },
            }
        }

        case EOrdersActions.ORDER_SIMULATION_RESET: {
            state.orderSimulation.taxDetails = ordersInitialState.orderSimulation.taxDetails
            return {
                ...state,
                orderSimulation: {
                    ...state.
                        orderSimulation
                }
            }
        }

        case EOrdersActions.ORDER_SIMULATION_FAILURE: {
            return {
                ...state,
                orderSimulation: {
                    ...state.orderSimulation,
                    taxDetails: {
                        ...state.orderSimulation.taxDetails,
                        errorCodes: action.payload.errorCodes,
                        errors: action.payload.errors,
                        plant: action?.payload?.plant,
                        deliveryMethod: action?.payload?.deliveryMethod,
                        paymentTerms: action?.payload?.paymentTerms,
                        warnings: action?.payload?.warnings
                    }
                },
            };
        }
        default:
            return state;
    }
}
