import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthenticationService } from 'libs/common-api/src/lib/services/authentication.service';

import { IBearerToken } from '@hot-libs/shared-models';

@Injectable()
export class TokenExpirationInterceptor implements HttpInterceptor {
    private refreshTokenSubject = new Subject<IBearerToken>();

    constructor(private readonly authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    return this.refreshAuthenticationToken().pipe(
                        switchMap(() => {
                            return next.handle(request);
                        })
                    );
                }

                return throwError(err);
            })
        );
    }

    private refreshAuthenticationToken(): Observable<IBearerToken> {
        this.refreshTokenSubject.subscribe({
            complete: () => {
                this.refreshTokenSubject = new Subject<IBearerToken>();
            },
        });

        // The theme can send multiple simultaneous requests, so in case of token failure this method can be triggered multiple times.
        // This will cause multiple access token requests, which is redundant. To prevent that, let's request the access token
        // for first failed request only and use received token for all following requests.

        if (this.refreshTokenSubject.observers.length === 1) {
            this.authenticationService.refreshAuthenticationToken().subscribe(this.refreshTokenSubject);
        }

        return this.refreshTokenSubject;
    }
}
