import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { OrdersTemplateGet, OrdersTemplateGetSuccess } from './actions';
import { EOrderTemplateActions } from './types';

import { OrderTemplateItemModel, OrderTemplateModel } from 'apps/hot-b2b/src/app/order-template/models';
import { OrderTemplateService } from 'apps/hot-b2b/src/app/order-template/services';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';

import { settingsStore } from '../settings/selector';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { FeatureNames } from '@hot-libs/shared-types';

@Injectable()
export class OrderTemplateEffects {
    constructor(
        private readonly _store: Store<AppState>,
        private readonly _actions$: Actions,
        private readonly orderTemplateService: OrderTemplateService,
        public featuresService: FeaturesService
    ) {}

    public getOrdersTemplate$: Observable<OrdersTemplateGetSuccess> = createEffect(() => this._actions$.pipe(
        ofType<OrdersTemplateGet>(EOrderTemplateActions.ORDERS_TEMPLATES_PENDING),
        withLatestFrom(
            this.featuresService.checkFeatures(FeatureNames.OrderTemplates)
        ),
        filter(([, orderTemplate]: [OrdersTemplateGet , boolean]) => orderTemplate),
        switchMap(() =>
            this.orderTemplateService.getOrderTemplates().pipe(
                withLatestFrom(this._store.select(settingsStore)),
                map(([response, storeSettings]: [OrderTemplateModel[], SettingsStoreModel]) => {
                    const allowOutOfStockItems =
                        this.featuresService.AllowDistributorsToControlStoreSettings &&
                        storeSettings.displayOutOfStockProductsInCustomersCatalog;

                    response.forEach(
                        (template: OrderTemplateModel) =>
                            (template.isValid = template.items.some(
                                (item: OrderTemplateItemModel) => item.isInStock || allowOutOfStockItems
                            ))
                    );

                    return new OrdersTemplateGetSuccess(response);
                })
            )
        )
    ));
}
