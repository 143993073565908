import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MobileService {
    public getMobileOperatingSystem(): string {
        const userAgent = navigator.userAgent;
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
            return 'iOS';
        } else if (userAgent.match(/Android/i)) {
            return 'Android';
        } else {
            return 'non-mobile or unknown';
        }
    }
}
