import { OpcoConfigurationModel } from '@hot-libs/shared-models';
import { OutletRegistrationFieldType } from '@hot-libs/shared-types';

export const config: OpcoConfigurationModel = {
    orderStatuses: [
        {
            key: 'saved',
            bgColor: 'rgb(254, 204, 2)',
            icon: 'saved-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'cancelled',
            bgColor: '#880005',
            icon: 'cancelled-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'blocked',
            bgColor: 'rgb(236, 28, 36)',
            icon: 'cancelled-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'sent-to-distributor',
            bgColor: 'rgb(0, 0, 0)',
            icon: 'placed-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'new-order',
            bgColor: 'rgb(0, 0, 0)',
            icon: 'placed-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'delivered',
            bgColor: 'rgb(12, 71, 157)',
            icon: 'delivered-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'pending',
            bgColor: 'rgb(141, 141, 141)',
            icon: 'pending-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'received',
            bgColor: 'rgb(141, 141, 141)',
            icon: 'pending-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'on-the-way',
            bgColor: 'rgb(248, 185, 102)',
            icon: 'on-the-way-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'delayed',
            bgColor: 'rgb(0, 166, 81)',
            icon: 'confirmed-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'confirmed',
            bgColor: 'rgb(0, 165, 81)',
            icon: 'confirmed-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'confirmed-delivery',
            bgColor: 'rgb(0, 165, 81)',
            icon: 'confirmed-icon',
            color: 'rgb(255, 255, 255)',
        },
        {
            key: 'sent-to-customer',
            bgColor: 'rgb(240, 90, 34)',
            icon: 'pending-icon',
            color: 'rgb(255, 255, 255)',
        },
    ],
    previousOrdersColumn: {
        desktop: ['status', 'date', 'items', 'total', 'payment','estimatedDeliveryDate'],
        mobile: ['status', 'date', 'total', 'payment','estimatedDeliveryDate'],
    },
    availableOutletRegistrationFields: {
        anonymousUserFields: [
            {
                name: 'OutletName',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'OutletEmail',
                isRequired: true,
                type: OutletRegistrationFieldType.Email,
            },
            {
                name: 'OutletPhone',
                isRequired: true,
                type: OutletRegistrationFieldType.Phone,
            },
            {
                name: 'AddressLine1',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'Tin',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'OutletExternalId',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
        ],
        groupAccountFields: [
            {
                name: 'OutletExternalId',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'OutletName',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'CustomerType',
                isRequired: true,
                type: OutletRegistrationFieldType.Select,
            },
            {
                name: 'SalesArea',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'Sector',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'Section',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'OutletPhone',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'FirstName',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'LastName',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'AddressLine1',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'SalesRepresentativeEmail',
                isRequired: true,
                type: OutletRegistrationFieldType.Email,
            },
        ],
        salesRepresentativeFields: [
            {
                name: 'OutletExternalId',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'OutletName',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'CustomerType',
                isRequired: true,
                type: OutletRegistrationFieldType.Select,
            },
            {
                name: 'SalesArea',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'Sector',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'Section',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'OutletPhone',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'FirstName',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'LastName',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'AddressLine1',
                isRequired: true,
                type: OutletRegistrationFieldType.Text,
            },
            {
                name: 'SalesRepresentativeEmail',
                isRequired: true,
                type: OutletRegistrationFieldType.Email,
            },
        ],
        customerSelfRegistration: [
            {
                name: 'ExternalOutletId',
                isRequired: true,
                readonly: false,
                type: OutletRegistrationFieldType.Text
            },
            {
                name: 'OutletName',
                isRequired: true,
                readonly: true,
                type: OutletRegistrationFieldType.Text
            },
            {
                name: 'ContactFirstName',
                isRequired: true,
                readonly: false,
                type: OutletRegistrationFieldType.Text
            },
            {
                name: 'ContactLastName',
                isRequired: true,
                readonly: false,
                type: OutletRegistrationFieldType.Text
            },
            {
                name: 'PhoneNumber',
                isRequired: true,
                readonly: false,
                type: OutletRegistrationFieldType.Phone
            },
            {
                name: 'Email',
                isRequired: false,
                readonly: false,
                type: OutletRegistrationFieldType.Text
            },
            {
                name: 'Username',
                isRequired: true,
                readonly: false,
                type: OutletRegistrationFieldType.Text
            },
            {
                name: 'Password',
                isRequired: true,
                readonly: false,
                type: OutletRegistrationFieldType.Password
            },
            {
                name: 'ConfirmPassword',
                isRequired: true,
                readonly: false,
                type: OutletRegistrationFieldType.Password
            },
            {
                name: 'UserLanguage',
                isRequired: true,
                readonly: true,
                type: OutletRegistrationFieldType.Hidden
            },
        ],
    },
    mockOrder: {
        comment: '',
        createdDate: '2021-02-09T14:31:25.0220861Z',
        currencyCode: 'USD',
        customerId: '2190bf26-8b97-462f-835d-0e40ceacd228',
        customerName: 'hdot-tutorial',
        deliveryDate: {
            formattedValue: 'Undefined',
        },
        discountTotal: 4000,
        emptiesDepositTotal: 1100,
        estimatedDeliveryDate: {
            value: '2021-02-10T10:00:00Z',
            formattedValue: 'Wed, 10/02/2021',
        },
        extendedPriceTotal: 32960,
        extendedStatus: 'received.distributor.default',
        fulfillmentCenterId: 'b0b696919f9141c68f85530fb8fe2f1c',
        hasInvoice: false,
        hasPaymentProvider: true,
        id: 'f9acb28f3cfb4540974474ae5cc4c670',
        isB2C: false,
        isBonusPointsOrder: false,
        isDeliveredInFull: false,
        isDeliveredOnTime: false,
        isOfflineOrder: false,
        isOnlinePaymentAvailable: false,
        isPaid: false,
        isSmsOrder: false,
        items: [
            {
                alcoholContent: '4.70%',
                brand: 'Bintang Pilsener',
                brandGroup: 'Bintang Pilsener',
                brandGroupImageUrl: 'https://hot-images-dev.azureedge.net/brands/bintang-pilsener-id.png',
                deliveredQuantity: 0,
                discountAmount: 500,
                discountTotal: 3000,
                emptiesDeposit: 100,
                extendedPrice: 23460,
                gtin: '',
                id: 'e5ccc91856e9497581b74dc3adcb90cf',
                imageUrl: 'https://hot-images-dev.azureedge.net/catalog/9aa23/10164/0_0_orig.png',
                isAlcoholic: true,
                isGift: false,
                isInStock: false,
                isOrdered: false,
                isPromo: true,
                isSubscribed: false,
                listPrice: 4410,
                name: 'BINTANG CAN 500ML CTN 24 CP4',
                packageName: '4 x 6 can pack',
                packageSize: 24,
                packageType: 'pack',
                packageVolume: 0.12,
                placedPrice: 3910,
                productId: '2581aa3457e04b28a728529a8b65b77e',
                quantity: 6,
                sku: '50075',
                taxPercentRate: 0.1,
                taxTotal: 2346,
                taxType: '',
                totalVolume: 0.72,
                type: 'Beer',
                unitName: '500ml',
                unitType: 'Cans',
                unitVolume: 0.0062,
            },
            {
                alcoholContent: 'No Alcohol',
                brand: 'Bintang Zero Original',
                brandGroup: 'Bintang Zero',
                brandGroupImageUrl: 'https://hot-images-dev.azureedge.net/brands/bintang-zero-id.png',
                deliveredQuantity: 0,
                discountAmount: 200,
                discountTotal: 1000,
                emptiesDeposit: 100,
                extendedPrice: 9500,
                gtin: '',
                id: 'c619408accd14f21862b7e6e09f0b257',
                imageUrl: 'https://hot-images-dev.azureedge.net/catalog/9aa23/10164/0_0_orig.png',
                isAlcoholic: false,
                isGift: false,
                isInStock: false,
                isOrdered: false,
                isPromo: true,
                isSubscribed: false,
                listPrice: 2100,
                name: 'BINTANG ZERO 330ML 0.0% CTN 24',
                packageName: '24 cans (carton)',
                packageSize: 24,
                packageType: 'carton',
                packageVolume: 0.0792,
                placedPrice: 1900,
                productId: 'b7d811e3b13543eeacd8bc3e69c49f5a',
                quantity: 5,
                sku: '10436',
                taxPercentRate: 0.1,
                taxTotal: 950,
                taxType: '',
                totalVolume: 0.396,
                type: 'Non alcoholic',
                unitName: '330ml',
                unitType: 'Cans',
                unitVolume: 0.0062,
            },
        ],
        itemsCount: 2,
        itemsQuantity: 11,
        itemsVolume: 1.116,
        number: 'CO210209-00001',
        outletId: '024907a6ea2143e4a19bc38983d64ef0',
        outletName: 'dvsstore outlet',
        outletOuterId: '1243254',
        paidTotal: 0,
        payments: [],
        preferredDeliveryDate: {
            value: '2021-02-10T00:00:00Z',
            formattedValue: 'Wed, 10/02/2021',
        },
        shippedDate: {
            formattedValue: 'Undefined',
        },
        status: 'Received',
        subTotal: 36960,
        subTotalDiscount: 4000,
        subTotalTaxTotal: 3296,
        taxTotal: 3296,
        total: 37356,
    },
    mockCart: {
        total: 5856,
        itemsCount: 1,
        itemsQuantity: 4,
        items: [
            {
                quantity: 4,
                name: 'Test Product',
                imageUrl: '',
                type: 'Beer',
                brand: 'Test Brand',
                brandGroup: 'Test Brand Group',
                brandGroupImageUrl: '',
                isAlcoholic: true,
                alcoholContent: '2.00%',
                packageName: '24 cans (carton)',
                packageType: 'carton',
                packageSize: 24,
                packageVolume: 0.0768,
                unitName: '320ml',
                unitType: 'Cans',
                unitVolume: 0.0062,
                totalVolume: 0.3072,
                isValid: true,
                isGift: false,
                isPromo: false,
                isInStock: true,
                isSubscribed: false,
                listPrice: 1240,
                salePrice: 1240,
                placedPrice: 1240,
                extendedPrice: 4960,
                emptiesDeposit: 100,
                emptiesDepositTotal: 400,
                taxType: '',
                taxTotal: 496,
                taxPercentRate: 0.1,
                taxIncludedRate: 0.0,
                vat: 0,
                isBaseUnitOfMeasure: false,
                baseQuantity: 0.0,
                validationErrors: [],
            },
        ],
    },
    mockReportReasons: [
        "damaged",
        "aged-or-expired-product",
        "incorrect-quantity",
        "other",
        "missing-product",
        "incorrect-product",
        "faulty-product",
    ],
    options: {
        externalValidationTemplate: false,
        reloadCartAfterExternalValidation: false
    },
    unitOfMeasureSorting: {
        cs: 1,
        case: 1,
        p12: 1,
        pac: 1,
        crt: 1,
        keg: 1,
        brl: 1,
        bot: 1,
        lay: 2,
        pal: 3,
    },
    orderSimulation: {
        orderSimulationPricesAndTaxes: false,
    },
};
