export enum EPromotionsActions {
    PROMOTIONS_PENDING = 'PROMOTIONS_PENDING',
    PROMOTIONS_FETCHED = 'PROMOTIONS_FETCHED',
    PROMOTIONS_ERROR = 'PROMOTIONS_ERROR',
    PROMOTIONS_GET_PRODUCTS = 'PROMOTIONS_GET_PRODUCTS',
    PROMOTIONS_GET_PRODUCTS_SUCCESS = 'PROMOTIONS_GET_PRODUCTS_SUCCESS',
    PROMOTIONS_CLEAR_PRODUCTS = 'PROMOTIONS_CLEAR_PRODUCTS',
    PROMOTIONS_SYNCHRONIZED = 'PROMOTIONS_SYNCHRONIZED',
    PROMOTIONS_SYNCHRONIZED_SUCCESS = 'PROMOTIONS_SYNCHRONIZED_SUCCESS',
    PROMOTIONS_CHANGE_FFF_ID = 'PROMOTIONS_CHANGE_FFF_ID',
    PROMOTIONS_GET_ORDER_SUGGESTIONS = 'PROMOTIONS_GET_ORDER_SUGGESTIONS',
    PROMOTIONS_GET_ORDER_SUGGESTIONS_SUCCESS = 'PROMOTIONS_GET_ORDER_SUGGESTIONS_SUCCESS',
    PROMOTIONS_PENDING_FROM_CENTER = 'PROMOTIONS_PENDING_FROM_CENTER',
    PROMOTIONS_FETCHED_FROM_CENTER = 'PROMOTIONS_FETCHED_FROM_CENTER'
}
