import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services/features.service';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { allBrands } from 'apps/hot-b2b/src/app/catalog/constants/brands';
import { HotBrandGroupExtended } from 'apps/hot-b2b/src/app/catalog/models';

@Component({
  selector: 'hot-advanced-brandgroup-filter',
  templateUrl: './advanced-brandgroup-filter.component.html',
})
export class AdvancedBrandgroupFilterComponent {
  @ViewChild('filtersCarousel', { static: false }) carousel: ElementRef;

  @Input() public active: string;
  @Input() public isClickable: boolean;

  @Input() public brands: HotBrandGroupExtended[];

  @Output() public onBrandClick: EventEmitter<any> = new EventEmitter();

  public stickyItem = allBrands;

  public selectedBrand: string;
  private scrollPoint: number;
  private isRTL = this.document.documentElement.dir === 'rtl';
  public lhs = false;
  public rhs = true;



  public isUpLg = () => this.screenDimensionService.upLg();
  public readonly featureEnableAdvancedFilters: boolean = this.featuresService.EnableAdvancedFilters;

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    public readonly screenDimensionService: ScreenDimensionService,
    private readonly featuresService: FeaturesService
  ) { }



  public selectBrandInFilter(brandName: string) {
    if (this.selectedBrand == brandName) {
      this.selectedBrand = null;
      this.onBrandClick.emit(null);
    } else {
      this.selectedBrand = brandName;
      this.onBrandClick.emit(brandName);
    }

  }

  public elementClick(item: string): void {
    if (this.isClickable) {
      this.onBrandClick.emit(item);
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    this.selectedBrand = this.active;
    if (changes.brands) {
      this.brands = changes.brands.currentValue;
    }

  }

  public slideTo(direction: string) {
    const scrollDistance = this.isUpLg() ? 105 * 3 : 67 * 2;
    let scrollMax = this.carousel.nativeElement.scrollWidth - this.carousel.nativeElement.offsetWidth;

    if (!this.isRTL) {
      this.carouselNativeElement(scrollMax, direction);

      switch (direction) {
        case 'left':
          const yLeft = this.carousel.nativeElement.scrollLeft - scrollDistance;
          this.carousel.nativeElement.scrollTo({ left: yLeft, behavior: 'smooth' });
          this.scrollPoint = this.carousel.nativeElement.scrollLeft;
          this.setCarouselNavPosition(scrollMax, direction)
          break;
        case 'right':
          const yRight = this.carousel.nativeElement.scrollLeft + scrollDistance;
          this.carousel.nativeElement.scrollTo({ left: yRight, behavior: 'smooth' });
          this.scrollPoint = this.carousel.nativeElement.scrollLeft;
          this.setCarouselNavPosition(scrollMax, direction)
          break;
      }
    } else {
      scrollMax = scrollMax * -1 + 1;
      if (this.carousel.nativeElement.scrollLeft <= scrollMax && direction === 'right') {
        this.carousel.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
        this.scrollPoint = null;
        return;
      } else if (this.carousel.nativeElement.scrollLeft >= 0 && direction === 'left') {
        this.carousel.nativeElement.scrollTo({ left: scrollMax, behavior: 'smooth' });
        this.scrollPoint = this.carousel.nativeElement.scrollLeft;
        return;
      }

      switch (direction) {
        case 'left':
          const yLeft = this.carousel.nativeElement.scrollLeft + scrollDistance;
          this.carousel.nativeElement.scrollTo({ left: yLeft, behavior: 'smooth' });
          this.scrollPoint = this.carousel.nativeElement.scrollLeft;
          break;
        case 'right':
          const yRight = this.carousel.nativeElement.scrollLeft - scrollDistance;
          this.carousel.nativeElement.scrollTo({ left: yRight, behavior: 'smooth' });
          this.scrollPoint = this.carousel.nativeElement.scrollLeft;
          break;
      }
    }

  }
  public carouselNativeElement(scrollMax,direction) {
    if (this.carousel.nativeElement.scrollLeft >= scrollMax && direction === 'right') {
      this.carousel.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
      this.scrollPoint = null;
    } else if (this.carousel.nativeElement.scrollLeft === 0 && direction === 'left') {
      this.carousel.nativeElement.scrollTo({ left: scrollMax, behavior: 'smooth' });
      this.scrollPoint = this.carousel.nativeElement.scrollLeft;
      this.setCarouselNavPosition(scrollMax, direction)
    }
  }
  ngAfterViewInit() {
    if (this.carousel.nativeElement.scrollLeft == 0) {
      this.lhs = false;
    }
  }


  setCarouselNavPosition(scrollMax, direction) {
    if ((this.carousel.nativeElement.scrollLeft >= scrollMax || this.carousel.nativeElement.scrollLeft + 250 >= scrollMax) && direction == "right") {
      this.rhs = false
    } else if ((this.carousel.nativeElement.scrollLeft - 315 <= 0) && direction == "left") {
      this.lhs = false;
      this.rhs = true;
    }
    else if (this.carousel.nativeElement.scrollLeft < scrollMax) {
      this.rhs = true;
      this.lhs = true;
    }
  }


  getTrackData(name) {
    return `{"data_interaction":"logo_click","button_text":"${name}","name":"PLP"}`;
  }

}
