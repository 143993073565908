import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { HotOrderExtended } from '@hot-libs/shared-models';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'hot-distributor-order-confirm-edit-modal',
    templateUrl: './distributor-order-confirm-edit-modal.component.html',
})

export class DistributorOrderConfirmEditModalComponent {
    @Input() public order: HotOrderExtended;
    @Output() public toggleModal: EventEmitter<any> = new EventEmitter();
    @Output() public submitConfirmEdit: EventEmitter<any> = new EventEmitter();

    public reasonForm: UntypedFormGroup;
    public reasonData: Array<any> = [
        { name: 'out-of-stock' },
        { name: 'promotion-ended' },
        { name: 'unable-requested-date' },
        { name: 'others' }
    ];

    public isSelectedOthers: boolean;
    public itemOthersValue = '';

    constructor(
        private fb: UntypedFormBuilder,
        private readonly translateService: TranslateService
    ) {
        this.reasonForm = this.fb.group({
            reasons: this.fb.array([], [Validators.required]),
            otherReason: ['']
        });
    }

    public ngOnInit(): void {
        this.itemOthersValue = this.translateService.instant('shared.modals.distributor-order-confirm-edit.reasons.others');
    }

    onStatusChanged(event: boolean, reasonItem: any) {
        const reasons: UntypedFormArray = this.reasonForm.get('reasons') as UntypedFormArray;
        const reasonValue = this.translateService.instant('shared.modals.distributor-order-confirm-edit.reasons.' + reasonItem.name);
        const isOtherReason = reasonValue === this.itemOthersValue;
        if (event) {
            reasons.push(new UntypedFormControl(reasonValue));
            if (isOtherReason) {
                this.isSelectedOthers = true;
                this.reasonForm.get('otherReason').setValidators([Validators.required]);
            }
        } else {
            reasons.controls.forEach((item: UntypedFormControl, index: number) => {
                if (item.value === reasonValue) {
                    reasons.removeAt(index);
                }
            });
            if (isOtherReason) {
                this.isSelectedOthers = false;
                this.reasonForm.get('otherReason').setValidators(null);
                this.reasonForm.get('otherReason').setValue('');
            }
        }
        this.reasonForm.get('otherReason').updateValueAndValidity();
    }

    closeModal(performAction: boolean): void {
        this.toggleModal.emit(performAction);
    }

    onSubmit(): void {
        if (this.reasonForm.valid) {
            const arrReasons = this.reasonForm.value.reasons.filter((value: string) => {
                if (this.isSelectedOthers) return value !== this.itemOthersValue;
                else return true;
            });
            if (this.isSelectedOthers) arrReasons.push(`${this.itemOthersValue}: ${this.reasonForm.value.otherReason}`);
            const reason = arrReasons.join('|');
            this.submitConfirmEdit.emit({ reason });
        }
    }
}
