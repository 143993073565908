import { createSelector, MemoizedSelector } from '@ngrx/store';
import { HotFulfillmentCenter } from '@hot-theme-nx/generated-api';
import { AppState } from '@hot-b2b/store/reducers';
import { WarehousesState } from '@hot-b2b/store/warehouses/model';

const stateWarehouses = (state: AppState): WarehousesState => state.warehouses;

export const warehouses: MemoizedSelector<AppState, HotFulfillmentCenter[]> = createSelector(
    stateWarehouses,
    (state: WarehousesState) => state.data.warehouses
);

export const warehousesRewards: MemoizedSelector<AppState, HotFulfillmentCenter[]> = createSelector(
    stateWarehouses,
    (state: WarehousesState) => state.data.warehousesRewards
);

export const warehousesState: MemoizedSelector<AppState, WarehousesState> = createSelector(
    stateWarehouses,
    (state: WarehousesState) => state
);

export const warehousesRewardsFetched: MemoizedSelector<AppState,boolean> = createSelector(
    stateWarehouses,
    (state: WarehousesState) => state.rewardFetched
);

