import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';

import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { ModalService } from '../../../shared/services';
import { ModalIds } from '@hot-libs/shared-types';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { select, Store } from '@ngrx/store';
import { Observable} from 'rxjs';

@Component({
    selector: 'hot-image-carousel',
    templateUrl: './image-carousel.component.html',
})
export class ImageCarouselComponent implements OnChanges {
    constructor(
        public readonly screenDimensionService: ScreenDimensionService,
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService
    ) {
        this.imageZoomState$ = this.store.pipe(select(selectModalState(ModalIds.imageZoom)));
    }

    @ViewChild('imageCarouselFullScreen', { static: true }) public homeCarousel: NguCarousel<any>;

    @Input() public product;
    @Input() public isPromo: boolean;

    public selectedImageShow: boolean;
    public selectedImage: string;

    public isUpLg = () => this.screenDimensionService.upLg();
    public imageZoomState$: Observable<ModalsState>;

    public carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        speed: 400,
        point: {
            visible: true,
            hideOnSingleSlide: true,
        },
        velocity: 0,
        easing: 'ease',
        touch: true,
    };

    public ngOnChanges(): void {
        if (this.product.images.length === 0) {
            this.product.images = [null];
        }
        this.product.images = this.product.images.slice(0, 3);
        this.selectedImageShow = true;
        this.selectedImage = this.product.images[0];
    }

    public showImage(image: any): void {
        this.selectedImageShow = false;

        setTimeout(() => {
            this.selectedImage = image;
            this.selectedImageShow = true;
        }, 100);
    }

    public onImageLoadingError(_loadingError: boolean, index: number): void {
        this.product.images.splice(index, 1);
        this.selectedImage = this.product.images[0];
    }

    public openFullscreenView() {
        this.modalService.toggleModal(ModalIds.imageZoom, true);
    }

    toggleRewardMaintainanceResult(state) {
        this.modalService.toggleModal(ModalIds.imageZoom, state);
    }
}
