import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { BaseControlValueAccessor, ValueAccessorProvider } from 'apps/hot-b2b/src/app/shared/internal';

@Component({
    selector: 'hot-checkbox',
    templateUrl: './checkbox.component.html',
    providers: [ValueAccessorProvider(HotCheckboxComponent)],
})
export class HotCheckboxComponent extends BaseControlValueAccessor {
    @Input() public form: UntypedFormGroup;
    @Input() public formControl: UntypedFormControl;
    @Input() public caption: string;
    @Input() public required: boolean;
    @Input() public isNotForm: boolean;
    @Input() public isChecked: boolean;
    @Input() public disabled: boolean;
    @Input() public modifier: string;

    @Output() public statusChanged: EventEmitter<any> = new EventEmitter();

    public changeStatus(isChecked: boolean): void {
        this.statusChanged.emit(isChecked);
    }

    public writeValue(value: boolean) {
        this.isChecked = value;
    }
}
