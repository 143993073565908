/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HotLoyaltyStatement } from '../models/hot-loyalty-statement';
import { HotCatalog } from '../models/hot-catalog';
import { HotRedemptionMechanic } from '../models/hot-redemption-mechanic';
import { HotRedemptionWindowStatus } from '../models/hot-redemption-window-status';
import { LoyaltySubscription } from '../models/loyalty-subscription';
@Injectable({
  providedIn: 'root',
})
class HotApiLoyaltyService extends __BaseService {
  static readonly getStatementPath = '/storefrontapi/hot/loyalty/statement';
  static readonly getCatalogPath = '/storefrontapi/hot/loyalty/catalog';
  static readonly getRedemptionMechanicsPath = '/storefrontapi/hot/loyalty/redemption-mechanics';
  static readonly getStatusPath = '/storefrontapi/hot/loyalty/status';
  static readonly getLoyaltySubscriptionByOuteltIdPath = '/storefrontapi/hot/loyalty/subscription';
  static readonly savePath = '/storefrontapi/hot/loyalty/subscription';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `HotApiLoyaltyService.GetStatementParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `startDate`:
   *
   * - `skip`:
   *
   * - `operationType`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getStatementResponse(params: HotApiLoyaltyService.GetStatementParams): __Observable<__StrictHttpResponse<HotLoyaltyStatement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.operationType != null) __params = __params.set('operationType', params.operationType.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/loyalty/statement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotLoyaltyStatement>;
      })
    );
  }
  /**
   * @param params The `HotApiLoyaltyService.GetStatementParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `startDate`:
   *
   * - `skip`:
   *
   * - `operationType`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  getStatement(params: HotApiLoyaltyService.GetStatementParams): __Observable<HotLoyaltyStatement> {
    return this.getStatementResponse(params).pipe(
      __map(_r => _r.body as HotLoyaltyStatement)
    );
  }

  /**
   * @param params The `HotApiLoyaltyService.GetCatalogParams` containing the following parameters:
   *
   * - `fulfillmentCenterId`:
   *
   * - `distributorProductsOnly`:
   *
   * @return Success
   */
  getCatalogResponse(params: HotApiLoyaltyService.GetCatalogParams): __Observable<__StrictHttpResponse<HotCatalog>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.fulfillmentCenterId != null) __params = __params.set('fulfillmentCenterId', params.fulfillmentCenterId.toString());
    if (params.distributorProductsOnly != null) __params = __params.set('distributorProductsOnly', params.distributorProductsOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/loyalty/catalog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotCatalog>;
      })
    );
  }
  /**
   * @param params The `HotApiLoyaltyService.GetCatalogParams` containing the following parameters:
   *
   * - `fulfillmentCenterId`:
   *
   * - `distributorProductsOnly`:
   *
   * @return Success
   */
  getCatalog(params: HotApiLoyaltyService.GetCatalogParams): __Observable<HotCatalog> {
    return this.getCatalogResponse(params).pipe(
      __map(_r => _r.body as HotCatalog)
    );
  }

  /**
   * @return Success
   */
  getRedemptionMechanicsResponse(): __Observable<__StrictHttpResponse<Array<HotRedemptionMechanic>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/loyalty/redemption-mechanics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HotRedemptionMechanic>>;
      })
    );
  }
  /**
   * @return Success
   */
  getRedemptionMechanics(): __Observable<Array<HotRedemptionMechanic>> {
    return this.getRedemptionMechanicsResponse().pipe(
      __map(_r => _r.body as Array<HotRedemptionMechanic>)
    );
  }

  /**
   * @param fulfillmentCenterId undefined
   * @return Success
   */
  getStatusResponse(fulfillmentCenterId?: string): __Observable<__StrictHttpResponse<HotRedemptionWindowStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (fulfillmentCenterId != null) __params = __params.set('fulfillmentCenterId', fulfillmentCenterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/loyalty/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HotRedemptionWindowStatus>;
      })
    );
  }
  /**
   * @param fulfillmentCenterId undefined
   * @return Success
   */
  getStatus(fulfillmentCenterId?: string): __Observable<HotRedemptionWindowStatus> {
    return this.getStatusResponse(fulfillmentCenterId).pipe(
      __map(_r => _r.body as HotRedemptionWindowStatus)
    );
  }

  /**
   * @param outletid undefined
   * @return Success
   */
  getLoyaltySubscriptionByOuteltIdResponse(outletid?: string): __Observable<__StrictHttpResponse<LoyaltySubscription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (outletid != null) __params = __params.set('outletid', outletid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storefrontapi/hot/loyalty/subscription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoyaltySubscription>;
      })
    );
  }
  /**
   * @param outletid undefined
   * @return Success
   */
  getLoyaltySubscriptionByOuteltId(outletid?: string): __Observable<LoyaltySubscription> {
    return this.getLoyaltySubscriptionByOuteltIdResponse(outletid).pipe(
      __map(_r => _r.body as LoyaltySubscription)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  saveResponse(body?: LoyaltySubscription): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storefrontapi/hot/loyalty/subscription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  save(body?: LoyaltySubscription): __Observable<number> {
    return this.saveResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }
}

module HotApiLoyaltyService {

  /**
   * Parameters for GetStatement
   */
  export interface GetStatementParams {
    take?: number;
    startDate?: string;
    skip?: number;
    operationType?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetCatalog
   */
  export interface GetCatalogParams {
    fulfillmentCenterId?: string;
    distributorProductsOnly?: boolean;
  }
}

export { HotApiLoyaltyService }
