import { Component, Input } from '@angular/core';

@Component({
    selector: 'hot-tooltip',
    templateUrl: './tooltip.component.html',
})
export class TooltipComponent {
    @Input() public content: string;
    @Input() public infoModifier: string;
    @Input() public pageName: string;
    @Input() public clickable: boolean;

    public isContentOpen: boolean = false;

    public getTrackData() {
        return `{ "data_interaction": "icon_click", "button_text": "${this.content}", "name":"${this.pageName}" }`;
    }

    public toggleContent() {
        this.isContentOpen = !this.isContentOpen;
    }

    public clickOutside() {
        this.isContentOpen = false;
    }
}
