import { Injectable } from '@angular/core';
import {
    HotApiChildContactsService,
    HotChildContactSearchResult,
} from '@hot-theme-nx/generated-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
    AuthGetChildContacts,
    AuthGetChildContactsSuccess,
} from './actions';
import { EAuthActions } from './types';

@Injectable()
export class AuthEffects {
    constructor(private readonly _actions$: Actions, private readonly apiContactsService: HotApiChildContactsService) {}

    public getUserChildContacts$: Observable<AuthGetChildContactsSuccess> = createEffect(() => this._actions$.pipe(
        ofType<AuthGetChildContacts>(EAuthActions.AUTH_USER_GET_CHILD_CONTACTS),
        mergeMap((action: AuthGetChildContacts) =>
            this.apiContactsService
                .getChildContacts(action.payload)
                .pipe(map((response: HotChildContactSearchResult) => new AuthGetChildContactsSuccess(response)))
        )
    ));
}
