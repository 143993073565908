import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash/filter';
import indexOf from 'lodash/indexOf';

import { FilterModel } from '@hot-libs/helpers';

import { HotBrandGroupExtended } from 'apps/hot-b2b/src/app/catalog/models';

@Pipe({
    name: 'filterBrands',
})
export class FilterBrandsPipe implements PipeTransform {
    public transform(data: HotBrandGroupExtended[], filters: FilterModel[]): HotBrandGroupExtended[] {
        return filters === undefined || filters.length === 0
            ? data
            : filter(data, (item: HotBrandGroupExtended) =>
                  item.type
                      .map(
                          (type: string) =>
                              indexOf(
                                  filters.map((filter: FilterModel) => filter.value),
                                  type
                              ) !== -1
                      )
                      .find((value: boolean) => value)
              );
    }
}
