import { createSelector, MemoizedSelector } from '@ngrx/store';

import {
    Contact,
    HotChildContactOutlet,
    HotChildContactOutletResponse,
    HotChildContactSearchResult,
    HotOutlet,
    Organization,
    Role,
} from '@hot-theme-nx/generated-api';
import { HotUserExtended } from '@hot-libs/shared-models';

import { AuthState, AuthUser } from '@hot-b2b/store/auth/model';
import { AppState } from '@hot-b2b/store/reducers';

const stateAuth = (state: AppState): AuthState => state.auth;
const settingsUserGroup = (state: AppState): string[] => state.settings.data?.dotCargoUserGroups;
const outletUserGroup = (state: AppState): string[] => state.auth.data?.user?.contact?.outlet?.userGroups;

export const authPending: MemoizedSelector<AppState, boolean> = createSelector(
    stateAuth,
    (state: AuthState) => state.pending
);

export const authFetched: MemoizedSelector<AppState, boolean> = createSelector(
    stateAuth,
    (state: AuthState) => state.fetched
);

export const authData: MemoizedSelector<AppState, AuthUser> = createSelector(
    stateAuth,
    (state: AuthState) => state.data
);

export const authUser: MemoizedSelector<AppState, HotUserExtended> = createSelector(
    stateAuth,
    (state: AuthState) => state.data.user
);

export const authLoggedIn: MemoizedSelector<AppState, boolean> = createSelector(
    stateAuth,
    (state: AuthState) => state.data.loggedIn
);

export const authHomePageUrl: MemoizedSelector<AppState, string> = createSelector(
    stateAuth,
    (state: AuthState) => state.data.homePageUrl
);

export const dataLoadedForPage: MemoizedSelector<AppState, string> = createSelector(
    stateAuth,
    (state: AuthState) => state.data.loadedPage
);

export const authUserType: MemoizedSelector<AppState, string> = createSelector(stateAuth, (state: AuthState) =>
    state.data.user ? state.data.user.userType : null
);

export const authContact: MemoizedSelector<AppState, Contact> = createSelector(stateAuth, (state: AuthState) =>
    state.data.user ? state.data.user.contact : null
);

export const authContactOrganisation: MemoizedSelector<AppState, Organization> = createSelector(
    stateAuth,
    (state: AuthState) => (state.data.user ? state.data.user.contact.organization : null)
);

export const authContactOutlet: MemoizedSelector<AppState, HotOutlet> = createSelector(stateAuth, (state: AuthState) =>
    state.data.user ? state.data.user.contact.outlet : null
);
export const authOutletIsCashCreditTerms: MemoizedSelector<AppState, boolean> = createSelector(
    stateAuth,
    (state: AuthState) => (state.data.user ? state.data.user.contact.outlet.isCashCreditTerms : null)
);

export const authOrganizationIsCashCreditTerms: MemoizedSelector<AppState, boolean> = createSelector(
    stateAuth,
    (state: AuthState) => (state.data.user ? state.data.user?.contact?.organization?.isCashCreditTerms : null)
);

export const authUserRoles: MemoizedSelector<AppState, Role[]> = createSelector(stateAuth, (state: AuthState) =>
    state.data.user ? state.data.user.roles : null
);

export const authOutletIsLoyaltyDisabled: MemoizedSelector<AppState, boolean> = createSelector(
    stateAuth,
    (state: AuthState) => (state.data.user ? state.data.user.contact.outlet.isLoyaltyDisabled : null)
);
export const authChildContactsSearchResult: MemoizedSelector<
    AppState,
    HotChildContactSearchResult
> = createSelector(stateAuth, (state: AuthState) =>
    state.data.user ? state.data.user.childContactsSearchResult : null
);

export const authChildContact: MemoizedSelector<AppState, HotChildContactOutletResponse> = createSelector(
    stateAuth,
    (state: AuthState) => state.data.user?.childContact
);

export const authChildContactOutletsToRemove: MemoizedSelector<AppState, HotChildContactOutlet[]> = createSelector(
    stateAuth,
    (state: AuthState) => state.data.user?.childContactOutletsToRemove || []
);

export const showPalletCounterForUserGroups: MemoizedSelector<AppState, boolean> = createSelector(
    [settingsUserGroup, outletUserGroup],
    (requiredGroups: string[], userGroups: string[]) => {
        for (const group of userGroups) {
            if (requiredGroups.includes(group)) {
                return true;
            }
        }
        return false;
    }
);
