import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { BaseControlValueAccessor, ValueAccessorProvider } from 'apps/hot-b2b/src/app/shared/internal';

@Component({
    selector: 'hot-input-online-payment',
    templateUrl: './input-online-payment.component.html',
    providers: [ValueAccessorProvider(HotInputOnlinePaymentComponent)],
})
export class HotInputOnlinePaymentComponent extends BaseControlValueAccessor {
    @Input() public modifier: string = '';
    @Input() public lockModifier: string;
    @Input() public wrapperModifier: string;
    @Input() public hideErrors: boolean;
    @Input() public formControl: UntypedFormControl;
    @Input() public name: string;
    @Input() public disabled: boolean;
    @Input() public type = 'text';
    @Input() public caption: string;
    @Input() public placeholder = '';
    @Input() public required: boolean;
    @Input() public readonly: boolean;
    @Input() public isSubmitted: boolean;
    @Input() public maxlength: number;
    @Input() public pattern: string;
    @Input() public allowEnterOnlyByPattern: boolean;
    @Input() public autocomplete: boolean | string;
    @Input() public hideFloatingPlaceholder: boolean;
    @Input() public patternErrorText: string;
    @Input() public checked: boolean;
    @Input() public showErrorIcon: boolean;
    @Input() public patternErrors: Array<string> = [];
    @Input() public pageName: string;
    @Input() public inValidProvider: any;
}
