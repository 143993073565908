import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { PageName,ModalIds } from '@hot-libs/shared-types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductService } from '../../../services';
import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { AnalyticService } from 'apps/hot-b2b/src/app/shared/services/analytic.service';
import { AnalyticRouteService } from '../../../services/analytic-route.service';
import {ModalService} from 'apps/hot-b2b/src/app/shared/services';
import {HotProductRecommendationLocalization} from  '@hot-theme-nx/generated-api';
import { StorageKeys } from '@hot-libs/shared-types';
import {HotProductRecommendationService} from 'libs/generated-api/src/lib/services/hot-product-recommendation.service';

@Component({
    selector: 'hot-recommended-order-modal',
    templateUrl: './recommended-order-modal.component.html',
})
export class RecommendedOrderModel implements OnInit, OnDestroy {
    @Input() public recommendedOrder: HotProductExtended[]= [];
    @Input() public featureLocationFastOrder : string;

    @Output() public addProducts = new EventEmitter();
    @Output() public close = new EventEmitter<void>();

    public pageName = PageName.FASTORDERMODAL;

    private readonly subscriptionDestroy$ = new Subject<boolean>();
    private readonly analyticProductLocation = 'fast-order';
    private readonly analyticsFastOrderModalView = 'fast_order_modal_view';
    public distributorName: string;
    public aiddaProductDescription: HotProductRecommendationLocalization;

    constructor(private readonly productService: ProductService, 
        private readonly analyticService: AnalyticService, 
        private readonly analyticRouteService: AnalyticRouteService,        
        private readonly modalService: ModalService,
        private readonly apiProductRecommendation: HotProductRecommendationService,
        ) {}

    public ngOnInit(): void {
        this.productService.removedProduct
            .pipe(takeUntil(this.subscriptionDestroy$))
            .subscribe((product) => this.removeProduct(product));
            this.analyticRouteService.fastOrderModalEvents(this.analyticsFastOrderModalView);
            this.analyticService.sendFastModalAnalyticData(this.analyticProductLocation);
            this.distributorName = localStorage.getItem('storeName');
            this.getAiddaDescription();
    
    }
    getAiddaDescription() {
        const fulfillmentCenter = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter));
        let storeId = fulfillmentCenter ? fulfillmentCenter.storeId : localStorage.getItem('storeId');
        const parms = {
            outletOuterId: localStorage.getItem(StorageKeys.outletExternalId),
            storeId: storeId || 0,
        };
        this.apiProductRecommendation.getRecomendedProductsByIds(parms).subscribe((data :HotProductRecommendationLocalization ) => {
            this.aiddaProductDescription = data;
        });

    }
   
    public addToCart(product: { productId: string; quantity: number }): void {
        this.setProductQuantity(product.productId, product.quantity);
    }

    public changeQuantity(product: HotProductExtended): void {
        this.setProductQuantity(product.id, product.quantity);
    }

    public removeProduct(product: HotProductExtended): void {
        this.setProductQuantity(product.id, 0);
        this.recommendedOrder = this.recommendedOrder.filter(suggestedProduct => suggestedProduct.id !== product.id);
        if(!this.recommendedOrder.length){
            this.modalService.toggleModal(ModalIds.recommendedOrder, false);
        }
    }

    public addAllProductsToCart(): void {
        const productsToAdd = this.recommendedOrder.filter(
            (product: HotProductExtended) => product.quantity > 0
        );
        this.analyticService.sendAddAllProductsEventInfo(productsToAdd, this.analyticProductLocation,this.featureLocationFastOrder);
        this.addProducts.emit(productsToAdd);
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }

    private setProductQuantity(productId: string, quantity: number): void {
        const recommendedProduct = this.recommendedOrder.find((p: HotProductExtended) => p.id === productId);
        
        if (recommendedProduct) {
            recommendedProduct.quantity = quantity;
        }
    }
}
