import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CurrencyFormat } from '@hot-theme-nx/generated-api';
import { HotOrderExtended } from '@hot-libs/shared-models';

import { AppState } from '@hot-b2b/store/reducers';
import { settingsAppOnLine } from '@hot-b2b/store/settings/selector';

@Component({
    selector: 'hot-order-list-table',
    templateUrl: './order-list-table.component.html',
})
export class OrderListTableComponent implements AfterViewChecked {
    @Input() public columns: string[];
    @Input() public currencyFormat: CurrencyFormat;
    @Input() public dateFormat: string;
    @Input() public haveReadPricingPermission: boolean;
    @Input() public locale: string;
    @Input() public orders: HotOrderExtended[] = [];

    @Output() public openModal: EventEmitter<HotOrderExtended> = new EventEmitter();
    @Output() public scrolled: EventEmitter<any> = new EventEmitter();

    @ViewChild('table') private readonly table: ElementRef;

    public settingsAppOnLine$: Observable<boolean>;
    public columnMaxWidth: number;

    constructor(private readonly router: Router, private readonly store: Store<AppState>) {
        this.settingsAppOnLine$ = this.store.pipe(select(settingsAppOnLine));
    }

    public onScroll(): void {
        this.scrolled.emit();
    }

    public ngAfterViewChecked(): void {
        if (this.table) {
            this.columnMaxWidth = this.table.nativeElement.offsetWidth / this.columns.length;
        }
    }

    public payNow(event: Event, orderNumber: string): void {
        event.stopPropagation();

        this.router.navigate(['/payment-process', orderNumber]);
    }
}
