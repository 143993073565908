import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CatalogService } from '../../../catalog/services/catalog.service';

import { multiFilter } from '@hot-b2b/store/catalog/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotAggregationItem, HotCategory } from '@hot-theme-nx/generated-api';
import { MultiFilter, MultiFilterType } from '../../models/multi-filters.model';
import { CatalogApplyMultiFilters } from '@hot-b2b/store/catalog/actions';
import { findDeep } from '@hot-libs/helpers';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { HotCategoryExtended } from '../../../catalog/models';

@Component({
    selector: 'hot-multi-filters',
    templateUrl: './multi-filters.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiFiltersComponent implements OnInit, OnDestroy {
    @Output() public toggle = new EventEmitter();
    @Output() public applyFilter = new EventEmitter<MultiFilter>();

    // All categories
    public categoryTree: HotCategory[];

    // Binded categories
    public categories: HotCategory[];

    public allMultiFilterType: MultiFilterType = {
        label: 'All',
        count: null,
        isSelected: false,
    };

    public aggregatedTypes: MultiFilterType[] = [];

    public appliedMultiFilter: MultiFilter;

    public categoryToApplyId: string;
    public selectedCategory: HotCategory;
    public selectedCategoryParentName: string;

    public filtersChanged: boolean;
    public isSoldByHeineken: boolean;
    public isLoaded = false;
    public canClearAll = false;

    private readonly subscriptionDestroy$ = new Subject<boolean>();

    constructor(
        private readonly store: Store<AppState>,
        private readonly catalogService: CatalogService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly configurationService: ConfigurationService
    ) {}

    public ngOnInit(): void {
        this.isLoaded = false;
        this.loadCategoryTree();
    }

    public initFilters() {
        this.store
            .pipe(select(multiFilter), takeUntil(this.subscriptionDestroy$))
            .subscribe((appliedFilter: MultiFilter) => {
                this.appliedMultiFilter = { ...appliedFilter };
                this.canClearAll =
                    !!this.appliedMultiFilter.categoryId ||
                    this.appliedMultiFilter.isSoldByHeineken ||
                    !!this.appliedMultiFilter.selectedTypes?.length;

                if (this.appliedMultiFilter?.categoryId?.length) {
                    const category = this.GetByIdInTree(this.appliedMultiFilter.categoryId);
                    this.toggleCategorySelected(category);
                } else {
                    this.isLoaded = true;
                }

                this.changeDetector.markForCheck();
            });
    }

    public loadCategoryTree(): void {
        this.catalogService
            .getCategoryTree()
            .pipe(takeUntil(this.subscriptionDestroy$))
            .subscribe((categories: HotCategoryExtended[]) => {
                let currentPosition = -1;

                categories.forEach((category) => {
                    currentPosition = -1;
                    if (category.aggregationItems.length > 0) {
                        category.aggregationItems.forEach((item, j) => {
                            if (j != currentPosition) {
                                if (item.count > 0) {
                                    category.isGoingToShow = true;
                                    currentPosition = j;
                                } else {
                                    category.isGoingToShow = false;
                                }
                            }
                        });
                    } else {
                        category.isGoingToShow = false;
                    }
                });

                
                    this.categoryTree = [...categories];
                    this.categories = [...categories];
                

                this.initFilters();
            });
    }

    public toggleCategorySelected(category: HotCategory): void {
        if (category.childCategories?.length) {
            this.setSelectedCategory(category);
            this.categories = [...category.childCategories];
            this.isLoaded = true;
        } else {
            this.loadProductTypes(category);
        }
    }

    public onSelectAll(event: Event): void {
        this.filtersChanged = true;
        this.canClearAll = true;

        this.allMultiFilterType.isSelected = !this.allMultiFilterType.isSelected;
        this.aggregatedTypes.forEach((type: MultiFilterType) => (type.isSelected = this.allMultiFilterType.isSelected));

        event.preventDefault();
    }

    public onSelect(event: Event, item: MultiFilterType): void {
        this.filtersChanged = true;
        this.canClearAll = true;
        item.isSelected = !item.isSelected;

        this.allMultiFilterType.isSelected = this.aggregatedTypes.every((type: MultiFilterType) => type.isSelected);

        event.preventDefault();
    }

    public onSwitch(event: Event): void {
        this.isSoldByHeineken = !this.isSoldByHeineken;
        this.filtersChanged = true;
        this.canClearAll = true;

        this.changeDetector.markForCheck();
        event.preventDefault();
    }

    public applyFilters(): void {
        const selectedTypeNames = this.aggregatedTypes
            .filter((type: MultiFilterType) => type.isSelected)
            .map((type: MultiFilterType) => type.label);

        const applyMultiFilter = {
            categoryId: this.categoryToApplyId,
            selectedTypes: selectedTypeNames,
            isSoldByHeineken: this.isSoldByHeineken,
        };

        this.applyFilter.emit(applyMultiFilter);
        this.store.dispatch(new CatalogApplyMultiFilters(applyMultiFilter));
        this.filtersChanged = false;

        this.close();
    }

    public back(): void {
        if (this.selectedCategory) {
            const category = this.GetByIdInTree(this.selectedCategory.parentId);

            if (category) {
                this.setSelectedCategory(category);
                this.categories = [...category.childCategories];
            } else {
                this.selectedCategory = null;
                this.categories = [...this.categoryTree];
            }

            this.aggregatedTypes = [];
        }
    }

    public unselectAll(): void {
        this.isSoldByHeineken = false;
        this.categoryToApplyId = null;
        this.allMultiFilterType.isSelected = false;
        this.aggregatedTypes.forEach((type: MultiFilterType) => (type.isSelected = false));

        this.filtersChanged = true;
        this.canClearAll = false;
        this.changeDetector.markForCheck();
    }

    public close(): void {
        this.toggle.emit();
    }

    public ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
    }

    private loadProductTypes(category: HotCategory): void {
        this.catalogService
            .getProductTypesByCategoryId(category.id)
            .pipe(takeUntil(this.subscriptionDestroy$))
            .subscribe((types: HotAggregationItem[]) => {
                this.setSelectedCategory(category);
                this.categories = null;

           
                    this.aggregatedTypes = [...types] as MultiFilterType[];
                

                this.allMultiFilterType.count = this.aggregatedTypes.reduce((acc, item) => {
                    acc += item.count;

                    return acc;
                }, 0);
                this.allMultiFilterType.isSelected = false;

                if (this.selectedCategory.id === this.appliedMultiFilter.categoryId) {
                    if (this.appliedMultiFilter.selectedTypes?.length) {
                        this.aggregatedTypes.forEach(
                            (type: MultiFilterType) =>
                                (type.isSelected = this.appliedMultiFilter.selectedTypes.includes(type.label))
                        );
                        this.allMultiFilterType.isSelected = this.aggregatedTypes.every(
                            (type: MultiFilterType) => type.isSelected
                        );
                    }

                    this.isSoldByHeineken = this.appliedMultiFilter.isSoldByHeineken;
                } else {
                    this.isSoldByHeineken = false;
                }

                this.isLoaded = true;
                this.changeDetector.markForCheck();
            });
    }

    private GetByIdInTree(id: string): HotCategory {
        return findDeep<HotCategory, string>(this.categoryTree, id, 'childCategories', 'id');
    }

    private setSelectedCategory(category: HotCategory): void {
        this.selectedCategory = { ...category };
        this.categoryToApplyId = category.id;

        const parentCategory = this.GetByIdInTree(category.parentId);
        this.selectedCategoryParentName = parentCategory?.name;

        if (this.appliedMultiFilter.categoryId !== category.id) {
            this.filtersChanged = true;
        }
    }
}
