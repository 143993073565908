import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CartProductUpdate } from '@hot-b2b/store/cart/actions';
import { CatalogItemChangeSubscription, CatalogItemUpdate } from '@hot-b2b/store/catalog/actions';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalData, selectModalState } from '@hot-b2b/store/modals/selector';
import { ProductsItemUpdate } from '@hot-b2b/store/products/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { settingsFFCId } from '@hot-b2b/store/settings/selector';

import { ModalIds } from '@hot-libs/shared-types';
import { select, Store } from '@ngrx/store';
import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import {
    FeaturesService,
    ModalService,
    OrderDetailsService,
    ProductService,
} from 'apps/hot-b2b/src/app/shared/services';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'hot-notify-me-modal',
    templateUrl: './notify-me-modal.component.html',
})
export class NotifyMeModalComponent implements OnDestroy, OnInit {
    @Input() public title: string;
    @Input() public content: string;
    @Input() public isNotification: boolean;

    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    public modalNotifyMeState$: Observable<ModalsState>;
    public modalData$: Observable<ModalsState>;
    public product: HotProductExtended;
    public featureBackInStockSubscriptionWithQuantity: boolean = this.featuresService
        .BackInStockSubscriptionWithQuantity;
    public backInStockQuantity: number;
    public selectedProductId: any;
    public selectedProduct: any;

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly orderDetailsService: OrderDetailsService,
        private readonly productService: ProductService,
        private readonly featuresService: FeaturesService
    ) {
        this.modalNotifyMeState$ = this.store.pipe(select(selectModalState(ModalIds.notifyMe)));
    }

    ngOnInit(): void {
        this.store
            .pipe(
                select(selectModalData(ModalIds.notifyMe)),
                filter((product: any) => !!product),
                takeUntil(this.subscriptionsDestroy$)
            )
            .subscribe((product: any) => {
                this.backInStockQuantity = 0;

                this.selectedProductId = product.productId || product.unitCurrent?.id || product.id;
                this.selectedProduct = product;
            });
        this.modalNotifyMeState$.pipe(takeUntil(this.subscriptionsDestroy$)).subscribe((modalState: ModalsState) => {
            if (modalState.data?.title) {
                this.title = modalState.data.title;
            }

            if (modalState.data?.content) {
                this.content = modalState.data.content;
            }
            this.isNotification = modalState.data.isNotification ? true : false;
        });
    }

    public onSubmit(): void {
        this.productService
            .putProductSubscriptionWithQuantity(this.selectedProductId, this.backInStockQuantity)
            .pipe(withLatestFrom(this.store.select(settingsFFCId)), takeUntil(this.subscriptionsDestroy$))
            .subscribe(([, ffcId]: [null, string]) => {
                this.selectedProduct.isSubscribed = true;

                this.store.dispatch(new CatalogItemChangeSubscription(this.selectedProductId));
                this.store.dispatch(new ProductsItemUpdate(this.selectedProduct));
                this.store.dispatch(new CartProductUpdate(this.selectedProduct));
                this.modalService.toggleModal(ModalIds.notifyMe, false);
            });
    }

    public close(): void {
        this.orderDetailsService.closeOrderDetails();
        this.modalService.toggleModal(ModalIds.notifyMe, false);
        this.modalService.toggleModal(ModalIds.order, false);
    }
    public productQuantityChanged(event: any): void {
        this.backInStockQuantity = event.quantity || 0;
    }
    ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }
}
