import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CurrencyFormat, HotProductAvailability } from '@hot-theme-nx/generated-api';

import { FeaturesService, ModalService, ProductService } from '../../../shared/services';
import { DistributorCatalogService } from '../../services';

import { AppState } from '@hot-b2b/store/reducers';
import { settingsCurrencyFormat, settingsStore } from '@hot-b2b/store/settings/selector';
import { SettingsStoreModel } from '@hot-libs/shared-models';
import { TranslateService } from '@ngx-translate/core';
import { ScreenDimensionService } from '@hot-libs/browser-specific';

@Component({
    selector: 'distributor-catalog-product',
    templateUrl: './distributor-catalog-product.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistributorCatalogProductComponent implements OnInit, OnDestroy {
    @Input() public product;
    @Input() public initDistrProductStatus: boolean;
    @Input() public pageName: string;
    @Input() public isGridViewActive: boolean;
    @Input() public showPrices: boolean;

    @Output() public selectStatus: EventEmitter<{
        product: HotProductAvailability;
        status: boolean;
        initStatus: boolean;
    }> = new EventEmitter<{ product: HotProductAvailability; status: boolean; initStatus: boolean }>();
    @Output() public preorderChanged: EventEmitter<HotProductAvailability> = new EventEmitter<HotProductAvailability>();
    @Output() public openModal: EventEmitter<HotProductAvailability> = new EventEmitter<HotProductAvailability>();
    @Output() public activateChanged: EventEmitter<HotProductAvailability> = new EventEmitter<HotProductAvailability>();
    @Output() public openPriceModal: EventEmitter<HotProductAvailability> = new EventEmitter<HotProductAvailability>();

    public isStockStatusDisabled: boolean;
    public cachedAvailability: boolean;
    public cachedInitAvailability: boolean;
    public inStockLabel: string;
    public outOfStockLabel: string;
    public dimensions: string;

    private subscriptionDestroy$ = new Subject();
    private unsubscribe$ = new Subject<boolean>();

    public currencyFormat$: Observable<CurrencyFormat>;
    public settingsStore$: Observable<SettingsStoreModel>;

    public readonly featureAllowPreorder: boolean = this.featuresService.AllowPreorder;
    public readonly featureStockControl: boolean = this.featuresService.StockControl;
    public readonly featureShowSku: boolean = this.featuresService.ShowDistributorLineItemSku;
    public readonly featureProductActivationFlow: boolean = this.featuresService.ProductActivationFlow;
    public featureUseCategoriesAsPrimaryCatalogFilter: boolean = this.featuresService
        .UseCategoriesAsPrimaryCatalogFilter;
    public readonly featureAllowPriceManagementOnDistributorPortal: boolean = this.featuresService
        .AllowPriceManagementOnDistributorPortal;
    public distributorPortalStockNumber = false;

    public isUpLg = () => this.screenDimensionService.upLg();

    constructor(
        public readonly distributorCatalogService: DistributorCatalogService,
        private readonly translateService: TranslateService,
        private readonly store: Store<AppState>,
        private readonly featuresService: FeaturesService,
        private readonly productService: ProductService,
        private readonly ref: ChangeDetectorRef,
        public readonly modalService: ModalService,
        private readonly screenDimensionService: ScreenDimensionService
    ) {
        this.currencyFormat$ = this.store.pipe(select(settingsCurrencyFormat));
        this.settingsStore$ = this.store.pipe(select(settingsStore));
    }

    public ngOnInit(): void {
        this.cachedInitAvailability = this.product.isAvailable;
        this.cachedAvailability = this.product.isAvailable;

        this.distributorCatalogService.updateCachedItems.pipe(takeUntil(this.subscriptionDestroy$)).subscribe((_) => {
            this.cachedInitAvailability = this.cachedAvailability;
        });

        this.dimensions = this.productService.getProductMeasures(this.product.product);

        this.inStockLabel = this.translateService.instant('distributor.catalog.in-stock');
        this.outOfStockLabel = this.translateService.instant('distributor.catalog.out-of-stock');

        this.modalService.modalState$.subscribe(() => {
                this.ref.markForCheck();         
        });

        this.settingsStore$.pipe(takeUntil(this.unsubscribe$)).subscribe((storeSettings: SettingsStoreModel) => {
            this.distributorPortalStockNumber = storeSettings.distributorPortalStockNumber;
        });
    }

    public changeProductAvailability(selectedValue: string) {
        if (selectedValue === this.inStockLabel) {
            this.cachedAvailability = true;
        } else {
            this.cachedAvailability = false;
        }

        this.selectStatus.emit({
            product: this.product,
            status: this.cachedAvailability,
            initStatus: this.initDistrProductStatus,
        });
    }

    ngOnDestroy(): void {
        this.subscriptionDestroy$.next(true);
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }
}
