import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ScreenDimensionService } from '@hot-libs/browser-specific';
import { ModalService } from '../../services';
import { ModalIds } from '@hot-libs/shared-types';

@Component({
  selector: 'hot-confirmation-overlay',
  templateUrl: './confirmation-overlay.component.html'
})
export class ConfirmationOverlayComponent{

  public isUpLg = () => this.screenDimensionService.upLg();

  constructor(
    private readonly screenDimensionService: ScreenDimensionService,
    private readonly router: Router,
    private readonly modalService: ModalService,

  ) { }

  

  public navigate(url: string): void {
    this.modalService.toggleModal(ModalIds.basketOverlayPopup, false);
    this.modalService.toggleModal(ModalIds.confirmationOverlay, false);
    this.router.navigateByUrl(url);
  }
}
