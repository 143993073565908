import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    HotApiVsmService,
    HotVsmOrderSearchCriteria,
    HotOrderSearchResult,
    HotUpdateOrderItem,
    HotOrder,
    HotDeliveredOrdersStatistics,
} from '@hot-theme-nx/generated-api';

@Injectable({
    providedIn: 'root',
})
export class VanSalesManOrdersClient {
    constructor(
        private readonly vsmServiceApi: HotApiVsmService
    ) { }

    public searchOrders(criteria: HotVsmOrderSearchCriteria): Observable<HotOrderSearchResult> {
        return this.vsmServiceApi.searchOrders(criteria);
    }

    public recalculateOrder(orderId: string, items: HotUpdateOrderItem[]): Observable<HotOrder> {
        return this.vsmServiceApi.recalculateOrder({ orderId, body: { items } });
    }

    public putOrder(orderId: string, items: HotUpdateOrderItem[]): Observable<HotOrder> {
        return this.vsmServiceApi.updateOrder({ orderId, body: { items } });
    }

    public confirmOrder(orderId: string): Observable<HotOrder> {
        return this.vsmServiceApi.confirmOrder(orderId);
    }

    public getOrdersStatistics(startDate?: string, endDate?: string): Observable<HotDeliveredOrdersStatistics> {
        return this.vsmServiceApi.getDeliveredOrdersStatistics({ startDate, endDate });
    }
}
