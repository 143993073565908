export class PageName {
    public static readonly SUMMARY = 'Summary';
    public static readonly BRAND = 'Brand';
    public static readonly INSIGHT = 'Insight';
    public static readonly PROMOTION = 'Promotion';
    public static readonly CARTSIDEBAR = 'CartSidebar';
    public static readonly PRODUCTDETAILMODAL = 'ProductDetailModal';
    public static readonly CARTMODAL = 'CartModal';
    public static readonly DISTRIBUTORORDERMODAL = 'DistributorOrderModal';
    public static readonly ORDERMODAL = 'OrderModal';
    public static readonly ORDERTEMPLATEMODAl = 'OrderTemplateModal';
    public static readonly ORDERDETAILS = 'OrderDetails';
    public static readonly ORDERTEMPLATES = 'OrderTemplates';
    public static readonly DISTRIBUTORORDERS = 'DistributorOrders';
    public static readonly EMPTIES = 'Empties';
    public static readonly GROUPACCOUNTORDERMODAL = 'GroupAccountOrderModal';
    public static readonly FASTORDERMODAL = 'FastOrderModal';
    public static readonly DISTRIBUTORCATALOG = 'DistributorCatalog';
    public static readonly ORDERRATING = 'OrderRatingModal';
    public static readonly RATINGCONFIRMATION = 'RatingConfirmationModal';
    public static readonly DELIVERYDETAILSMODAL = 'DeliveryDetailsModal';
    public static readonly KEGSCATALOG = 'KegsCatalog';
    public static readonly KEGSHISTORY = 'KegsHistory';
    public static readonly KEGSHISTORYMODAL = 'KegsHistoryModal';
    public static readonly ONLINEPAYMENT = 'OnlinePayment';
    public static readonly OPENINVOICE = 'OpenInvoice';
    public static readonly MASSORDER = 'MassOrder';
    public static readonly BASKETOVERLAY = 'BasketOverlay';
    public static readonly PROMOTIONTRACKING = 'PromotionTracking';
    public static readonly PROMOTIONALPRODUCT = 'PromotionalProduct';
    public static readonly PROGRAMDETAILS = 'ProgramDetails';
    public static readonly RECOMMENDEDORDER = 'RecommendedOrders';
    public static readonly ORDERSUGGESTIONS = 'OrderSuggestions'
}
