import { Pipe, PipeTransform } from '@angular/core';

import { OutletModel } from '../../shared/models';

import filter from 'lodash/filter';

@Pipe({
    name: 'searchOutlet',
})
export class SearchOutletPipe implements PipeTransform {
    public transform(outlets: OutletModel[], searchValue: string): OutletModel[] {
        if (!searchValue || searchValue.length < 3) {
            return outlets;
        }

        return filter(outlets, (item: OutletModel) => {
            const nameOuterIdString = `${item.name}::${item.outerId || null}`.toLowerCase();
            return nameOuterIdString.includes(searchValue.toLowerCase());
        });
    }
}
