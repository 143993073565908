import { WarehousesState } from '@hot-b2b/store/warehouses/model';
import { warehousesInitialState } from '@hot-b2b/store/warehouses/state';
import { WarehousesActions } from '@hot-b2b/store/warehouses/actions';
import { EWarehousesActions } from '@hot-b2b/store/warehouses/types';

export function warehousesReducer(
    state: WarehousesState = warehousesInitialState,
    action: WarehousesActions
): WarehousesState {
    switch (action.type) {
        case EWarehousesActions.WAREHOUSES_PENDING: {
            return {
                ...state,
                pending: true,
                fetched: false,
                error: null,
            };
        }
        case EWarehousesActions.WAREHOUSES_REWARDS_PENDING: {
            return {
                ...state,
                rewardPending: true,
                rewardFetched: false,
                error: null,
            };
        }
        case EWarehousesActions.WAREHOUSES_FETCHED: {
            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    ...state.data,
                    warehouses: action.payload,
                },
                error: null,
            };
        }
        case EWarehousesActions.WAREHOUSES_REWARDS_FETCHED: {
            return {
                ...state,
                rewardPending: false,
                rewardFetched: true,
                data: {
                    ...state.data,
                    warehousesRewards: action.payload,
                },
                error: null,
            };
        }
        case EWarehousesActions.WAREHOUSES_ERROR: {
            return {
                ...state,
                rewardFetched:false,
                rewardPending:false,
                pending: false,
                fetched: false,
                error: action.payload,
            };
        }
        default:
            return state;
    }
}
