import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash/filter';
import indexOf from 'lodash/indexOf';

@Pipe({
    name: 'filterByArray',
})
export class FilterByArrayPipe implements PipeTransform {
    public transform(data: any[], filters: string[], propertyName: string): any[] {
        return filters === undefined || filters.length === 0
            ? data
            : filter(data, (item) => indexOf(filters, item[propertyName]) !== -1);
    }
}
