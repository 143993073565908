import { Component, EventEmitter, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ModalIds } from '@hot-libs/shared-types';

import { ConfigurationService, ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { cartError } from '@hot-b2b/store/cart/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HotApiCartsService } from '@hot-theme-nx/generated-api';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'hot-cart-validation-modal',
    templateUrl: './cart-validation-modal.component.html',
})
export class CartValidationModalComponent {
    @Output() public createOrderWithError = new EventEmitter();

    public cartErrors$: Observable<any>;

    public showButton = true;
    public errorTranslationList: any[] = [];
    public isVnB2b = () => this.configurationService.getCurrentConfiguration() === 'VN';

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly translateService: TranslateService,
        private readonly router: Router,
        private readonly hotApiCartsService: HotApiCartsService,
        private readonly httpClient: HttpClient,
        private readonly configurationService: ConfigurationService
    ) {
        this.cartErrors$ = this.store.pipe(select(cartError));
    }

    ngOnInit() {
        this.cartErrors$.subscribe((cartShowError) => {
            for (let i = 0; i <= cartShowError.length; i++) {
                if (cartShowError[i].hasError) {
                    this.showButton = false;
                    break;
                } else if (!cartShowError[i].hasError) {
                    this.showButton = true;
                }
            }
        });
    }

    public close(): void {
        this.modalService.toggleModal(ModalIds.cartValidation, false);
        this.router.navigate(['/cart']);
    }
}
