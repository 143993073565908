import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { HotLegalAgreement } from '@hot-theme-nx/generated-api';

import { LegalAgreementService } from 'apps/hot-b2b/src/app/shared/services/legal-agreement.service';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import moment from 'moment';
import { ModalIds } from '@hot-libs/shared-types';

import { AuthenticationService, UserService } from 'apps/hot-b2b/src/app/account/services';
import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { AuthEdit } from '@hot-b2b/store/auth/actions';
import { authUser } from '@hot-b2b/store/auth/selector';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalData, selectModalState } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotUserExtended } from '@hot-libs/shared-models';
import { TermsAndConditionAndPrivacyPolicyResponse } from '@hot-theme-nx/generated-api'


@Component({
    selector: 'hot-privacy-policy-modal',
    templateUrl: './privacy-policy-modal.component.html',
})
export class PrivacyPolicyModalComponent implements OnInit, OnDestroy {

    @Input() public isDeclined: boolean;
    @Input() public isPolicyAccepted: boolean;
    @Output() public onDeclineEvent: EventEmitter<void> = new EventEmitter();
    @Output() public setAcceptPolicyEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public privacyPolicyAccepted = new EventEmitter();


    private readonly subscriptionsDestroy$ = new Subject<boolean>();
    private readonly user$: Observable<HotUserExtended>;
    private isDeclineNotificationShown: boolean;

    public privacyPolicy: HotLegalAgreement;

    public isPrivacyPolicyAccepted: boolean;
    public isFirstTimeAccept: boolean;
    public displayPrivacyPolicy: boolean;
    public privacyPolicyModifiedDate: string;
    public updateView: boolean;

    public modalPrivacyPolicyState$: Observable<ModalsState>;
    public modalDeclineState$: Observable<ModalsState>;


    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly userService: UserService,
        private readonly legalAgreementService: LegalAgreementService,
        private readonly authenticationService: AuthenticationService
    ) {

        this.modalPrivacyPolicyState$ = this.store.pipe(select(selectModalData(ModalIds.privacyPolicy)));
        this.modalDeclineState$ = this.store.pipe(select(selectModalState(ModalIds.privacyPolicyDeclined)));
        this.user$ = this.store.pipe(select(authUser));

        this.legalAgreementService.currentPrivacyPolicy$
            .pipe(withLatestFrom(this.user$), takeUntil(this.subscriptionsDestroy$))
            .subscribe(([privacyPolicy, user]: [HotLegalAgreement, HotUserExtended]) => {
                this.privacyPolicyModifiedDate = moment(privacyPolicy.modifiedDate).format('d MMMM YYYY');
                if (privacyPolicy) {
                    const privacyPolicyAcceptedDate =
                        ( user?.contact?.privacyPolicyAcceptedDate) || false;
                    this.isPrivacyPolicyAccepted =
                        privacyPolicyAcceptedDate > privacyPolicy.modifiedDate;
                }
            });


        this.modalPrivacyPolicyState$.pipe(takeUntil(this.subscriptionsDestroy$)).subscribe((data: any) => {

            if (data?.isAccountActivation) {
                this.displayPrivacyPolicy = true;
                this.isPrivacyPolicyAccepted = true;
            } else if (data?.isUpdatePrivacyPolicy && (!this.isPrivacyPolicyAccepted)) {
                this.updateView = true;
                this.isDeclineNotificationShown = data.isDeclined;
            }
        });
    }

    public ngOnInit(): void {
        this.legalAgreementService.currentPrivacyPolicy$
            .pipe(filter(Boolean), take(1))
            .subscribe((privacyPolicy: HotLegalAgreement) => {
                this.privacyPolicy = privacyPolicy;
            });

    }

    public togglePrivacyPolicyModal(state: boolean): void {
        this.modalService.toggleModal(ModalIds.privacyPolicy, state);
    }

    public toggleDeclineNotificationModal(state: boolean): void {
        this.modalService.toggleModal(ModalIds.privacyPolicyDeclined, state);
    }

    public declinePrivacyPolicy(): void {
        if (!this.isDeclined) {
            this.onDeclineEvent.emit();
            this.modalService.toggleModal(ModalIds.privacyPolicyDeclined, true);
        } else {
            this.modalService.toggleModal(ModalIds.privacyPolicy, false);
            this.authenticationService.logout();
        }
    }

    public setAcceptPolicy(value: boolean) {
        if (value) {
            this.setAcceptPolicyEvent.emit(value);
        } else {
            this.modalService.toggleModal(ModalIds.privacyPolicyDeclined, true, { acceptView: true });
        }
    }

    public agreePrivacyPolicy(): void {
        this.userService
            .acceptPrivacyPolicy()
            .pipe(withLatestFrom(this.user$))
            .subscribe(([termsAndConditionAndPrivacyPolicyResponse, user]: [TermsAndConditionAndPrivacyPolicyResponse, HotUserExtended]) => {
                if (termsAndConditionAndPrivacyPolicyResponse.acceptedDate && user.contact) {
                    user.contact.privacyPolicyAcceptedDate = termsAndConditionAndPrivacyPolicyResponse.acceptedDate;
                    this.store.dispatch(new AuthEdit(user));
                }
                this.isPrivacyPolicyAccepted = true;
                this.displayPrivacyPolicy = true;
                this.privacyPolicyAccepted.emit();
                this.modalService.toggleModal(ModalIds.privacyPolicy, false);
            });
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }
}
