import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

import { HotLegalAgreement } from '@hot-theme-nx/generated-api';
import { ModalIds, StorageKeys } from '@hot-libs/shared-types';

import { ModalService } from 'apps/hot-b2b/src/app/shared/services';
import { LegalAgreementService } from 'apps/hot-b2b/src/app/shared/services/legal-agreement.service';
import { ModalsState } from '@hot-b2b/store/modals/model';
import { selectModalData } from '@hot-b2b/store/modals/selector';
import { AppState } from '@hot-b2b/store/reducers';

@Component({
    selector: 'hot-disclaimer-modal',
    templateUrl: './disclaimer-modal.component.html'
})
export class DisclaimerModalComponent implements OnInit, OnDestroy {
    @Output() public disclaimerAccepted = new EventEmitter();

    public disclaimer: HotLegalAgreement;

    public modalTermsState$: Observable<ModalsState>

    private readonly subscriptionsDestroy$ = new Subject<boolean>();

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly legalAgreementService: LegalAgreementService,
        private readonly router: Router
    ) {
        this.modalTermsState$ = this.store.pipe(select(selectModalData(ModalIds.disclaimer)));
    }

    public ngOnInit(): void {
        this.legalAgreementService.currentDisclaimer$
            .pipe(
                takeUntil(this.subscriptionsDestroy$)
            )
            .subscribe((disclaimer: HotLegalAgreement) => {
                if (disclaimer) {
                    this.disclaimer = disclaimer;
                }
            });

    }

    public acceptDisclaimer(): void {
        this.toggleDisclaimerModal(false);
        localStorage.setItem(StorageKeys.acceptDisclaimer, 'true');
        window.scrollTo(0, 0);
    }

    public cancelDisclaimer(): void {
        this.router.navigate(['/']);
    }

    public toggleDisclaimerModal(state: boolean): void {
        this.modalService.toggleModal(ModalIds.disclaimer, state);
    }

    public ngOnDestroy(): void {
        this.subscriptionsDestroy$.next(true);
    }
}
