import { NotificationsState } from '@hot-b2b/store/notifications/model';

export const notificationsInitialState: NotificationsState = {
    pending: false,
    fetched: false,
    lastNotificationPopupShown: false,
    lastNotificationRead: false,
    data: {
        notifications: [],
    },
    error: null,
};
