import { Action } from '@ngrx/store';

import { HotTutorial, HotTutorialStep } from '@hot-theme-nx/generated-api';

import { ETutorialsActions } from '@hot-b2b/store/tutorials/types';

export class TutorialsGet implements Action {
    public readonly type = ETutorialsActions.TUTORIALS_PENDING;
}

export class TutorialsGetSuccess implements Action {
    public readonly type = ETutorialsActions.TUTORIALS_FETCHED;
    constructor(public payload: HotTutorial[]) {}
}

export class TutorialStartedAction implements Action {
    public readonly type = ETutorialsActions.TUTORIALS_STARTED;
    constructor(public payload: boolean) {}
}

export class TutorialCompleteAction implements Action {
    public readonly type = ETutorialsActions.TUTORIALS_ITEM_COMPLETE;
}

export class TutorialSetCurrentAction implements Action {
    public readonly type = ETutorialsActions.TUTORIALS_SET_CURRENT;
    constructor(public payload: HotTutorial) {}
}

export class TutorialSetCurrentStepAction implements Action {
    public readonly type = ETutorialsActions.TUTORIALS_SET_CURRENT_STEP;
    constructor(public payload: HotTutorialStep) {}
}
export class TutorialShowTutorialWithIdAction implements Action {
    public readonly type = ETutorialsActions.SHOW_TUTORIALWITHID;
    constructor(public payload: string) {}
}

export class TutorialClearTutorialWithIdAction implements Action {
    public readonly type = ETutorialsActions.CLEAR_TUTORIALWITHID;
    
}

export type TutorialsAction =
    | TutorialsGet
    | TutorialsGetSuccess
    | TutorialStartedAction
    | TutorialSetCurrentAction
    | TutorialCompleteAction
    | TutorialSetCurrentStepAction
    | TutorialShowTutorialWithIdAction
    | TutorialClearTutorialWithIdAction;
