import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthEdit } from '@hot-b2b/store/auth/actions';
import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { HotUserExtended } from '@hot-libs/shared-models';
import { ModalIds } from '@hot-libs/shared-types';
import { select, Store } from '@ngrx/store';
import { AuthenticationService, UserService } from 'apps/hot-b2b/src/app/account/services';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { ModalService } from '../../../services';
import { TermsAndConditionAndPrivacyPolicyResponse } from '@hot-theme-nx/generated-api'

@Component({
    selector: 'hot-theme-nx-update-privacy-policy-modal',
    templateUrl: './update-privacy-policy-modal.component.html',
})
export class UpdatePrivacyPolicyModalComponent {
    @Input() public title: string;
    @Input() public isDeclined: boolean;
    @Output() public onDeclineEvent: EventEmitter<void> = new EventEmitter();
    public notificationView: boolean;

    private readonly user$: Observable<HotUserExtended>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly modalService: ModalService,
        private readonly userService: UserService,
        private readonly authenticationService: AuthenticationService
    ) {
        this.user$ = this.store.pipe(select(authUser));
    }

    public close(): void {
        this.modalService.toggleModal(ModalIds.updatePrivacyPolicy, false);
    }

    public agreePrivacyPolicy(): void {
        this.userService.deleteUserCache();
        this.userService
            .acceptPrivacyPolicy()
            .pipe(withLatestFrom(this.user$))
            .subscribe(([termsAndConditionAndPrivacyPolicyResponse, user]: [TermsAndConditionAndPrivacyPolicyResponse, HotUserExtended]) => {
                if (termsAndConditionAndPrivacyPolicyResponse.acceptedDate && user.contact) {
                    user.contact.privacyPolicyAcceptedDate = termsAndConditionAndPrivacyPolicyResponse.acceptedDate;
                    this.store.dispatch(new AuthEdit(user));
                }

                this.close();
            });
    }

    public decline(): void {
        this.onDeclineEvent.emit();
        if (!this.isDeclined) {
            this.notificationView = true;
        } else {
            this.authenticationService.logout();
        }
    }

    public confirmWarning(): void {
        this.notificationView = false;
    }

    public onLink() {
        this.modalService.toggleModal(ModalIds.privacyPolicy, true, {
            isUpdatePrivacyPolicy: true,
            isDeclined: this.isDeclined,
        });
        this.close();
    }
}
