import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {
    HotApiCatalogService,
    HotApiLoyaltyService,
    ValidationError,
    HotLineItem,
    HotApiPricingService,
    HotVolumetricPrice,
} from '@hot-theme-nx/generated-api';

import { CartService } from '../../cart/services/cart.service';

import { AnalyticService } from './analytic.service';

import { CartAddItem, CartOfflineRemoveItem, CartUpdateItems } from '@hot-b2b/store/cart/actions';
import { AppState } from '@hot-b2b/store/reducers';
import { ProductFunctionalityModel, SettingsStoreModel, HotOrderLineItemExtended } from '@hot-libs/shared-models';
import { HotProductExtended } from 'apps/hot-b2b/src/app/catalog/models';
import { isApplicationOnline } from '@hot-libs/helpers';
import { HotCartLineItemExtended } from '../models/cart.model';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { cartItems } from '@hot-b2b/store/cart/selector';
import { Router } from '@angular/router';
import { settingsStore } from '@hot-b2b/store/settings/selector';
import { ConfigurationService } from './configuration.service';

import { FeaturesService } from './features.service';
import { StorageKeys } from '@hot-libs/shared-types';
import { AnalyticLocationConstant } from '@hot-theme-nx/common-api';

import { EmptiesCatalogGet } from '@hot-b2b/store/catalog/actions';
import { emptiesCatalogProducts } from '@hot-b2b/store/catalog/selector';
import { ChangeProductQuantityModel } from '../models';
import { HttpClient } from '@angular/common/http';
import { ItemLocationService } from 'apps/hot-b2b/src/app/shared/services/Item-location.service';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    private readonly cartItems$: Observable<HotCartLineItemExtended[]>;
    private readonly settingsStore$: Observable<SettingsStoreModel>;

    public productListPrice: number;
    public productActualPrice: number;
    public productBaseUnitPrice: number;
    public productQuantity: number;
    public removedProduct: Subject<HotProductExtended> = new Subject<HotProductExtended>();
    public markForCheck: Subject<string> = new Subject<string>();
    public inStockSubscription$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public isHopB2b = () => this.configurationService.getCurrentConfiguration() === 'HOP';
    public isMDOT = () => this.configurationService.getCurrentConfiguration() === 'MDOT';
    public enableGridViewMultiply: boolean;
    public autoAddedEmptiesRemovedFromCartTracker = [];
    public emptiesProducts$: Observable<HotProductExtended[]>;
    public emptiesProduct: HotProductExtended;
    public loadedProducts: HotProductExtended[];
    public autoAddEmpties: boolean;
    public hideAddEmptiesLink: boolean;
    public changeProductQuantityEvent = new Subject<ChangeProductQuantityModel>();

    private requestValidate = new Subject<any>();
    requestValidate$ = this.requestValidate.asObservable();

    private requestClearValidate = new Subject<any>();
    requestClearValidate$ = this.requestClearValidate.asObservable();

    constructor(
        private readonly router: Router,
        private readonly store: Store<AppState>,
        private readonly analyticService: AnalyticService,
        private readonly apiCatalogService: HotApiCatalogService,
        private readonly apiLoyaltyService: HotApiLoyaltyService,
        private readonly apiPricingService: HotApiPricingService,
        private readonly cartService: CartService,
        private readonly configurationService: ConfigurationService,
        public readonly featureService: FeaturesService,
        private readonly http: HttpClient,
        private readonly itemlocationService: ItemLocationService
    ) {
        this.cartItems$ = this.store.pipe(select(cartItems));
        this.settingsStore$ = this.store.pipe(select(settingsStore));

        this.settingsStore$.pipe(take(1)).subscribe((value: SettingsStoreModel) => {
            this.enableGridViewMultiply = value.showUnitPriceOnlyInGridView;
            this.autoAddEmpties = value.autoAddEmpties;
            this.hideAddEmptiesLink = value.hideAddEmptiesLink;
        });
        if (this.autoAddEmpties && this.featureService.ReturnEmpties) {
            this.store.dispatch(new EmptiesCatalogGet());
            this.emptiesProducts$ = this.store.pipe(select(emptiesCatalogProducts));
        }
    }

    public downloadExcelFile(templateUrl: string): void {
        let link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.href = templateUrl;
        link.download = templateUrl;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    public validateRequest(): void {
        this.requestValidate.next(undefined);
    }

    public validateClearRequest(): void {
        this.requestClearValidate.next(undefined);
    }

    public putProductSubscription(productId: string): Observable<null> {
        return this.apiCatalogService.subscribeForBackInStock({ productId, quantity: 0 });
    }
    public onSubscribe(productId: string, outletId: string, statusId: number): Observable<number> {
        return this.apiLoyaltyService.save({ productId, outletId, statusId });
    }
    public putProductSubscriptionWithQuantity(productId: string, quantity: number): Observable<null> {
        return this.apiCatalogService.subscribeForBackInStock({ productId, quantity: quantity });
    }
    public sendProductHandler(product: HotProductExtended, number: number): void {
        const selectedSupplier = JSON.parse(localStorage.getItem(StorageKeys.fulfillmentCenter));
        this.quantityPerPackageType(product, number);

        this.store.dispatch(
            new CartAddItem({
                productId: product.productId || product.id,
                storeId: product?.storeId || selectedSupplier?.storeId,
                quantity: this.productQuantity,
                packageTypeHop: product.unitCurrent?.packageType,
                isReturnableEmpty: product.isReturnableEmpty,
            })
        );

        let currentReturnableEmptySku =
            product?.returnableEmptySku != undefined
                ? product?.returnableEmptySku
                : product['product']?.returnableEmptySku;

        if (this.autoAddEmpties && currentReturnableEmptySku != undefined && this.featureService.ReturnEmpties) {
            let otherProductsInCartWithSameReturnableEmpties = null;
            let currentCartEmptiesQuantity = 0;

            this.emptiesProducts$.subscribe((emptiesProducts: HotProductExtended[]) => {
                this.emptiesProduct = emptiesProducts.find((f) => f.sku === currentReturnableEmptySku);
            });

            this.cartItems$.subscribe((data: HotCartLineItemExtended[]) => {
                otherProductsInCartWithSameReturnableEmpties = data.filter(
                    (f) =>
                        f?.product?.returnableEmptySku == currentReturnableEmptySku &&
                        (f?.sku != product?.sku || f.isGift)
                );
            });

            if (
                otherProductsInCartWithSameReturnableEmpties != undefined &&
                otherProductsInCartWithSameReturnableEmpties?.length > 0
            ) {
                currentCartEmptiesQuantity = otherProductsInCartWithSameReturnableEmpties.reduce(
                    (count: number, item: HotCartLineItemExtended) => {
                        return count + item?.quantity;
                    },
                    0
                );
            }

            if (this.emptiesProduct != null) {
                this.productEmpty(number, currentCartEmptiesQuantity);
            }
        }
    }

    public quantityPerPackageType(product: HotProductExtended, number: number): void {
        let packagesPerPallet = product.packagesPerPallet
            ? product.packagesPerPallet
            : product['product']?.packagesPerPallet;
        let packagesPerFCL = product.packagesPerFCL ? product.packagesPerFCL : product['product']?.packagesPerFCL;
        let packagesPerFTL = product.packagesPerFTL ? product.packagesPerFTL : product['product']?.packagesPerFTL;

        if (packagesPerPallet && product.unitCurrent?.packageType == 'Pallet') {
            this.productQuantity = number * packagesPerPallet;
        } else if (packagesPerFCL && product.unitCurrent?.packageType == 'FCL') {
            this.productQuantity = number * packagesPerFCL;
        } else if (packagesPerFTL && product.unitCurrent?.packageType == 'FTL') {
            this.productQuantity = number * packagesPerFTL;
        } else {
            this.productQuantity = number;
        }
    }

    public productEmpty(number, currentCartEmptiesQuantity) {
        let tempProductPerPackagesEmpties = this.emptiesProduct.packagesPerPallet
            ? this.emptiesProduct.packagesPerPallet
            : this.emptiesProduct?.packagesPerPallet;
        let newEmptiesQuantity =
            tempProductPerPackagesEmpties && this.emptiesProduct.unitCurrent?.packageType == 'Pallet'
                ? number * tempProductPerPackagesEmpties
                : number;

        this.store.dispatch(
            new CartAddItem({
                productId: this.emptiesProduct.productId || this.emptiesProduct.id,
                quantity: newEmptiesQuantity + currentCartEmptiesQuantity,
                packageTypeHop: this.emptiesProduct.unitCurrent?.packageType,
            })
        );
    }

    public setProductPrice(product: any, pageName?: string, isEmpties: boolean = false) {
        if (
            product?.unitsOfMeasure &&
            product.unitCurrent?.packageType == 'Unit' &&
            product?.unitCurrent &&
            pageName === 'Brand' &&
            this.isHopB2b()
        ) {
            this.productListPrice = product.unitCurrent?.listPrice * (product.unitCurrent?.quantity || 1);
            this.productActualPrice = product.unitCurrent?.salePrice * (product.unitCurrent?.quantity || 1);
            return { listPrice: this.productListPrice, actualPrice: this.productActualPrice };
        }

        if (
            product.unitsOfMeasure &&
            product.unitCurrent?.packageType == 'Pallet' &&
            product.unitCurrent &&
            pageName === 'Brand' &&
            this.isHopB2b()
        ) {
            this.productListPrice =
                product.unitCurrent?.listPrice *
                (product.unitCurrent?.quantity == 0
                    ? (product.unitCurrent?.quantity || 1) * product?.packagesPerPallet
                    : product.unitCurrent?.quantity || 1);
            this.productActualPrice =
                product.unitCurrent?.salePrice *
                (product.unitCurrent?.quantity == 0
                    ? (product.unitCurrent?.quantity || 1) * product?.packagesPerPallet
                    : product.unitCurrent?.quantity || 1);
            return { listPrice: this.productListPrice, actualPrice: this.productActualPrice };
        }

        this.productBaseUnitPrice = this.getProductBaseUnitPrice(product, product.salePrice);
        this.productUnitMeasure(product, pageName, isEmpties);
        this.catalogMeasure(product, pageName, isEmpties);
        this.catalogPrices(product, pageName, isEmpties);
        this.productPrice(product, pageName, isEmpties);

        return {
            listPrice: this.productListPrice,
            actualPrice: this.productActualPrice,
            baseUnitPrice: this.productBaseUnitPrice,
        };
    }
    public catalogMeasure(product: any, pageName?: string, isEmpties: boolean = false) {
        if (
            product.unitsOfMeasure &&
            (product.unitCurrent?.packageType == 'Unit' || product.unitCurrent?.packageType == 'Pallet') &&
            product.unitCurrent &&
            (pageName === 'CartSidebar' || pageName === 'Summary' || pageName === 'OrderModal') &&
            this.isHopB2b() &&
            isEmpties
        ) {
            this.productListPrice = product.emptiesReturn * (product.unitCurrent?.quantity || 1);
            this.productActualPrice = product.emptiesReturn * (product.unitCurrent?.quantity || 1);
            return { listPrice: this.productListPrice, actualPrice: this.productActualPrice };
        }
    }
    public productUnitMeasure(product: any, pageName?: string, isEmpties: boolean = false) {
        if (
            product.unitsOfMeasure &&
            (product.unitCurrent?.packageType == 'Unit' || product.unitCurrent?.packageType == 'Pallet') &&
            product.unitCurrent &&
            (pageName === 'CartSidebar' || pageName === 'OrderModal') &&
            this.isHopB2b() &&
            !isEmpties
        ) {
            this.productListPrice = product.unitCurrent?.listPrice * (product.unitCurrent?.quantity || 1);
            if (product.extendedPrice) {
                this.productActualPrice = product.extendedPrice;
            } else if (product.placedPrice) {
                this.productActualPrice = product.placedPrice * (product.unitCurrent?.quantity || 1);
            } else if (product.discountAmount) {
                this.productActualPrice =
                    (product.unitCurrent?.listPrice - product.discountAmount) * (product.unitCurrent?.quantity || 1);
            } else {
                this.productActualPrice = product.unitCurrent?.listPrice * (product.unitCurrent?.quantity || 1);
            }
            return { listPrice: this.productListPrice, actualPrice: this.productActualPrice };
        }
    }
    public catalogPrices(product: any, pageName?: string, isEmpties: boolean = false) {
        if (pageName === 'Summary' && !isEmpties && product.unitsOfMeasure && product.unitCurrent && this.isHopB2b()) {
            this.productListPrice = product.unitCurrent?.listPrice * (product.unitCurrent?.quantity || 1);
            if (product.extendedPrice) {
                this.productActualPrice = product.extendedPrice;
            } else if (product.placedPrice) {
                this.productActualPrice = product.placedPrice * (product.unitCurrent?.quantity || 1);
            } else if (product.discountAmount) {
                this.productActualPrice =
                    (product.unitCurrent?.listPrice - product.discountAmount) * (product.unitCurrent?.quantity || 1);
            } else {
                this.productActualPrice = product.unitCurrent?.listPrice * (product.unitCurrent?.quantity || 1);
            }
            return { listPrice: this.productListPrice, actualPrice: this.productActualPrice };
        }
    }
    public productPrice(product: any, pageName?: string, isEmpties: boolean = false) {
        if ((pageName === 'CartSidebar' || pageName === 'OrderModal' || pageName === 'OnlinePayment') && !isEmpties) {
            this.productListPrice = product.listPrice * product.quantity;
            this.productPriceInnerMethod(product, pageName);
            this.productBaseUnitPrice = this.getProductBaseUnitPrice(
                product,
                this.productActualPrice / product.quantity
            );
            return {
                listPrice: this.productListPrice,
                actualPrice: this.productActualPrice,
                baseUnitPrice: this.productBaseUnitPrice,
            };
        }

        if ((pageName === 'CartSidebar' || pageName === 'Summary' || pageName === 'OrderModal' || pageName === 'BasketOverlay') && isEmpties) {
            return this.emptiesPrice(product);
        }
        this.catalogPrice(product);
        this.currentviewProductPrice(product, pageName);
        this.productPrices(product, pageName, isEmpties);

        return { listPrice: this.productListPrice, actualPrice: this.productActualPrice };
    }
    private productPriceInnerMethod(product: any, pageName: string) {
        if (product.extendedPrice && pageName != 'OrderModal') {
            this.productActualPrice = product.extendedPrice;
        } else if (product.extendedPrice && pageName === 'OrderModal') {
            this.productActualPrice = product.quantity == 0 ? 0 : product.extendedPrice;
        } else if (product.placedPrice) {
            this.productActualPrice = product.placedPrice * product.quantity;
        } else if (product.discountAmount) {
            this.productActualPrice = (product.listPrice - product.discountAmount) * product.quantity;
        } else {
            this.productActualPrice = product.listPrice * product.quantity;
        }
    }

    private emptiesPrice(product: any) {
        this.productListPrice = product.emptiesReturn * (product.quantity || product.deliveredQuantity);
        this.productActualPrice = product.emptiesReturn * (product.quantity || product.deliveredQuantity);
        this.productBaseUnitPrice = this.getProductBaseUnitPrice(product, product.emptiesReturn);

        return {
            listPrice: this.productListPrice,
            actualPrice: this.productActualPrice,
            baseUnitPrice: this.productBaseUnitPrice,
        };
    }
    public productPrices(product: any, pageName?: string, isEmpties: boolean = false) {
        if (pageName === 'Summary' && !isEmpties && !(product.unitsOfMeasure && product.unitCurrent)) {
            this.productListPrice = product.listPrice * product.quantity;

            if (product.extendedPrice) {
                this.productActualPrice = product.extendedPrice;
            } else if (product.placedPrice) {
                this.productActualPrice = product.placedPrice * product.quantity;
            } else if (product.discountAmount) {
                this.productActualPrice = (product.listPrice - product.discountAmount) * product.quantity;
            } else {
                this.productActualPrice = product.listPrice * product.quantity;
            }

            return { listPrice: this.productListPrice, actualPrice: this.productActualPrice };
        }

        if (product.unitsOfMeasure && product.unitCurrent && !this.isHopB2b()) {
            this.productListPrice = product.unitCurrent.listPrice * (product.unitCurrent.quantity || 1);
            this.productActualPrice = product.unitCurrent.salePrice * (product.unitCurrent.quantity || 1);
            this.productBaseUnitPrice = product.unitCurrent.salePrice;

            if (product.unitCurrent.basePackagesRatio) {
                this.productBaseUnitPrice = this.productBaseUnitPrice / product.unitCurrent.basePackagesRatio;
            }
        }

        if (!this.productActualPrice) {
            this.productActualPrice = this.productListPrice;
        }

        return {
            listPrice: this.productListPrice,
            actualPrice: this.productActualPrice,
            baseUnitPrice: this.productBaseUnitPrice,
        };
    }
    public currentviewProductPrice(product: any, pageName?: string) {
        const currentView: string = localStorage.getItem(StorageKeys.catalogView);
        if (pageName === 'ProductDetailModal') {
            this.productListPrice = product.listPrice * (product.quantity || 1);
            this.productActualPrice = product.salePrice * (product.quantity || 1);
        } else if ((currentView === null || currentView === 'grid') && this.enableGridViewMultiply) {
            this.productListPrice = product.listPrice;
            this.productActualPrice = product.salePrice;
        } else {
            if (pageName === 'OrderModal' || pageName === 'DistributorConfirmedOrderModal') {
                this.productListPrice = product.listPrice * product.quantity;
                this.productActualPrice = product.salePrice * product.quantity;
            } else {
                this.productListPrice = product.listPrice * (product.quantity || 1);
                this.productActualPrice = product.salePrice * (product.quantity || 1);
            }
            this.productBaseUnitPrice = this.getProductBaseUnitPrice(product, product.salePrice);
        }
        return {
            listPrice: this.productListPrice,
            actualPrice: this.productActualPrice,
            baseUnitPrice: this.productBaseUnitPrice,
        };
    }
    public catalogPrice(product: any) {
        if (!this.featureService.UsePackagesWithCartsAndOrders && product.packageSize !== 0) {
            const currentView: string = localStorage.getItem(StorageKeys.catalogView);

            if ((currentView === null || currentView === 'grid') && this.enableGridViewMultiply) {
                this.productListPrice = product.listPrice;
                this.productActualPrice = product.salePrice;
            } else {
                this.productListPrice = product.listPrice * Math.round(product.quantity * product.packageSize || 1);
                this.productActualPrice = product.salePrice * Math.round(product.quantity * product.packageSize || 1);
                this.productBaseUnitPrice = this.getProductBaseUnitPrice(product, product.salePrice);
            }
            return {
                listPrice: this.productListPrice,
                actualPrice: this.productActualPrice,
                baseUnitPrice: this.productBaseUnitPrice,
            };
        }
    }

    public filterUnitsOfMeasure(product: HotProductExtended) {
        if (product.unitsOfMeasure && product.unitCurrent) {
            return product.unitsOfMeasure.filter((unitOfMeasure: any) => unitOfMeasure.id !== product.unitCurrent.id);
        }
    }

    public removeFromCartHandler(product: HotProductExtended, analyticProductLocation: string): void {
        if (analyticProductLocation === 'CartSidebar') {
            this.itemlocationService.removeItemLocation(product.sku, AnalyticLocationConstant.CART_SIDEBAR);
            this.analyticService.sendRemoveCartEventInfo(product, AnalyticLocationConstant.CART_SIDEBAR);
            this.sendProductHandler(product, 0);
        } else if (analyticProductLocation !== 'FastOrderModal') {
            this.itemlocationService.removeItemLocation(product.sku, analyticProductLocation);
            this.analyticService.sendRemoveCartEventInfo(product, analyticProductLocation);
            this.sendProductHandler(product, 0);
        }
    }

    public addProductToAutoAddEmptiesRemovedFromCartTracker(sku: string) {
        if (sku) {
            this.autoAddedEmptiesRemovedFromCartTracker.push(sku);
        }
    }

    public clearAutoAddEmptiesRemovedFromCartTracker() {
        this.autoAddedEmptiesRemovedFromCartTracker = [];
    }

    public replaceItemsInCart(products: ProductFunctionalityModel[], isConsignment?: boolean) {
        this.cartService.processProducts({ pending: products, processing: [], changedItems: [] }, isConsignment);
    }

    public updateItemPrices(): void {
        this.cartItems$
            .pipe(
                filter((items) => !!items?.length),
                take(1)
            )
            .subscribe((items: HotCartLineItemExtended[]) => {
                const isOnline: boolean = isApplicationOnline();

                if (isOnline) {
                    const invalidItemIds = items
                        .filter((item: HotCartLineItemExtended) =>
                            item.validationErrors.some((error: ValidationError) => error.errorCode === 'HotPriceError')
                        )
                        .map((item: HotCartLineItemExtended) => item.id);

                    if (invalidItemIds && invalidItemIds.length > 0) {
                        this.store.dispatch(new CartUpdateItems(invalidItemIds));
                    }
                }
            });
    }

    public removeOutOfStockFromCart() {
        this.cartItems$
            .pipe(
                filter((items) => !!items?.length),
                withLatestFrom(this.settingsStore$),
                take(1)
            )
            .subscribe(([items, storeSettings]: [HotCartLineItemExtended[], SettingsStoreModel]) => {
                const allowOutOfStockItems =
                    this.featureService.AllowDistributorsToControlStoreSettings &&
                    storeSettings.displayOutOfStockProductsInCustomersCatalog;
                if (allowOutOfStockItems) {
                    return;
                }
                const produtItems = items.filter(
                    (item) => (!item.isGift && !item.isInStock) || item.isUnavailableForCustomers
                );
                if (!produtItems.length) {
                    return;
                }

                let productsToRemove: ProductFunctionalityModel[] = [];
                const isOnline: boolean = isApplicationOnline();

                produtItems.forEach((product) => {
                    productsToRemove.push({
                        quantity: 0,
                        id: product.id,
                        productId: product.productId,
                        storeId: product.storeId,
                    });
                });

                if (isOnline) {
                    this.replaceItemsInCart(productsToRemove);
                } else {
                    // Temporary solution for offline
                    productsToRemove.forEach((product) =>
                        this.store.dispatch(new CartOfflineRemoveItem({ id: product.id }))
                    );
                }
            });
    }

    public getProductMeasures(product: any): string {
        let measures: string = '';
        const measuresDetails: string[] = [];

        if (!this.isHopB2b() && product.unitType) {
            measures += `<b>${product.unitType}</b>`;
        }
        if (!this.isHopB2b() && product.unitType && (product.unitName || product.packageName)) {
            measures += ' &nbsp;|&nbsp; ';
        }
        if (product.unitName) {
            measuresDetails.push(product.unitName);
        }
        if (product.packageName) {
            measuresDetails.push(product.packageName);
        }
        measures += measuresDetails.join(' &#8208; ');
        if (!measures.length) {
            measures = '-';
        }
        return measures;
    }

    public removeSelectedItemsCart(items: HotLineItem[]) {
        this.cartService.removeSelectedItemsCart(items);
    }

    public showPromoLabel(product: any): Observable<boolean> {
        return this.settingsStore$.pipe(
            filter((storeFilter: SettingsStoreModel) => !!storeFilter),
            map(() => {
                if (this.isMDOT() && product.hasInvoice) {
                    return product.hasDiscount;
                }
                return false;
            })
        );
    }

    public getInvoiceExtendedPriceWithDiscountForLineItem(lineItem: HotOrderLineItemExtended) {
        const invoiceExtendedPrice = lineItem.invoiceExtendedPrice || 0;
        const invoiceDiscountTotal = lineItem.invoiceDiscountTotal || 0;

        return invoiceExtendedPrice - invoiceDiscountTotal;
    }

    public getProductVolumetricPrice(ids: string[]): Observable<HotVolumetricPrice> {
        return this.apiPricingService.getVolumetricPricingByProductIds(ids);
    }
    public getProductBaseUnitPrice(product: any, price) {
        if (product.product) {
            product = product.product;
        }

        let productUnitPrice = price;

        if (product.basePackagesRatio) {
            productUnitPrice = productUnitPrice / product.basePackagesRatio;
        }

        if (productUnitPrice === Infinity) {
            productUnitPrice = undefined;
        }

        return productUnitPrice;
    }

    public setProductBaseUnitOfMeasure(product: any) {
        if (product.product) {
            product = product.product;
        }

        let baseUnitOfMeasure = product.packageType || 'Case';

        if (product.baseProduct) {
            if (product.baseProduct.unitOfMeasure) {
                baseUnitOfMeasure = product.baseProduct.unitOfMeasure;
            } else {
                baseUnitOfMeasure = product.baseProduct.packageType;
            }
        }

        return baseUnitOfMeasure;
    }

    public newPriceLA(product: any, salePrice: number, discountAmount: number) {
        let transformedSalePrice = salePrice.toFixed(2);

        let discountedListPrice = (salePrice - discountAmount).toFixed(2);
        this.productListPrice = product.listPrice * product.quantity;
        this.productActualPrice = salePrice - discountAmount;
        this.productActualPrice = parseFloat(this.productActualPrice.toFixed(2));
        if ((discountAmount == 0 && product.listPrice.toString() == transformedSalePrice.toString().replace('.', ''))
            || ( discountAmount != 0 &&
                product.listPrice.toString() == discountedListPrice.toString().replace('.', '') )) {
            return {
                listPrice: product.listPrice * product.quantity,
                actualPrice: product.listPrice * product.quantity,
            };
        } else {
            return { listPrice: this.productListPrice, actualPrice: this.productActualPrice * product.quantity };
        }
    }
}
