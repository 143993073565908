import { Injectable } from '@angular/core';
import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';
import { select, Store } from '@ngrx/store';
import { Observable} from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { HotUserExtended } from '@hot-libs/shared-models';
import { TelemetryEventType } from '@hot-libs/shared-types';
import packageContents from '@package-json';
import { authUser } from '@hot-b2b/store/auth/selector';
import { AppState } from '@hot-b2b/store/reducers';
import { applicationInsightsInstrumentationKey } from '@hot-b2b/store/settings/selector';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    private readonly instrumentationKey$: Observable<string>;
    private readonly authUser$: Observable<HotUserExtended>;

    private appInsights: ApplicationInsights;
    private userType: string;

    constructor(private readonly store: Store<AppState>) {
        this.instrumentationKey$ = this.store.pipe(select(applicationInsightsInstrumentationKey));
        this.authUser$ = this.store.pipe(select(authUser));
    }

    public initialize(): void {
        this.instrumentationKey$
            .pipe(withLatestFrom(this.authUser$))
            .subscribe(([instrumentationKeyValue, currentUser]: [string, HotUserExtended]) => {
                if (instrumentationKeyValue) {
                    this.appInsights = new ApplicationInsights({
                        config: {
                            instrumentationKey: instrumentationKeyValue,
                            enableAutoRouteTracking: true,
                        },
                    });

                    this.appInsights.loadAppInsights();
                    (this.appInsights.context.application as any) = { ver: packageContents.version, build: '' };  
                    this.updateAuthenticatedUserContext(currentUser);
                }
            });
    }

    private updateAuthenticatedUserContext(currentUser?: HotUserExtended): void {
        if (currentUser) {
            this.appInsights.setAuthenticatedUserContext(currentUser.id, currentUser.contactId, true);
            this.userType = currentUser.userType;
        } else {
            this.appInsights.clearAuthenticatedUserContext();
            this.userType = null;
        }
    }

    public startTrackingPage(eventType: TelemetryEventType): void {
        if (!this.appInsights?.context) {
            return;
        }

        const eventName = `${eventType}`;
        this.appInsights.startTrackPage(eventName);
    }

    public endTrackingPage(url: string, eventType: TelemetryEventType): void {
        if (!this.appInsights?.context) {
            return;
        }

        this.appInsights.stopTrackPage(`${eventType}`, url, { userType: this.userType });
    }

    public startTrackingEvent(eventType: TelemetryEventType): void {
        if (!this.appInsights?.context) {
            return;
        }

        const eventName = `${eventType}`;
        this.appInsights.context.telemetryTrace.name = eventName;
        this.appInsights.startTrackEvent(eventName);
    }

    public endTrackingEvent(eventType: TelemetryEventType): void {
        if (!this.appInsights?.context) {
            return;
        }

        this.appInsights.stopTrackEvent(`${eventType}`, { userType: this.userType });
    }

    public trackEvent(eventType: TelemetryEventType, params?: any): void {
        if (!this.appInsights?.context) {
            return;
        }

        const eventName: IEventTelemetry = { name: eventType.toString() };

        this.appInsights.trackEvent(eventName, params);
    }
}
