import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'account-datatable',
    templateUrl: './account-datatable.component.html',
})
export class AccountDatatableComponent {
    @Input() tableData;
    @Output() public downloadInvoice: EventEmitter<any> = new EventEmitter();
    @Output() public sortColumn: EventEmitter<any> = new EventEmitter();

    loading: boolean = false;
    cols = [
        { field: 'purchaseOrderNumber', label: 'Reference Number' },
        { field: 'documentType', label: 'Document Type' },
        { field: 'outletName', label: 'Outlet Name' },
    ];

    activeSorting = false;
    public currentSortHeader: {
        field: string;
        direction: string;
        count: number;
    } = {
        field: '',
        direction: '',
        count: 0,
    };

    ngOnChanges(): void {
        if (this.tableData == 0) {
            this.currentSortHeader.field = '';
            this.currentSortHeader.direction = '';
        }
    }

    public downloadIconClick(invoiceData) {
        this.downloadInvoice.emit(invoiceData);
    }

    public sort(header) {
        if (this.currentSortHeader.field == header) {
            if (this.currentSortHeader.direction == 'asc') {
                this.currentSortHeader.direction = 'desc';
            } else {
                this.currentSortHeader.direction = 'asc';
            }
        } else {
            this.currentSortHeader.field = header;
            this.currentSortHeader.direction = 'asc';
        }
        this.sortColumn.emit(this.currentSortHeader);
    }
}
