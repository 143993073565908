import { Action } from '@ngrx/store';

import { HotHomePageWidgetsWithLanguage } from '@hot-theme-nx/generated-api';

import { HotCartExtended, HotCartLineItemExtended } from '../../shared/models/cart.model';

import { EWidgetsActions } from './types';

export class WidgetsGet implements Action {
    public readonly type = EWidgetsActions.WIDGETS_PENDING;
}

export class WidgetsGetSuccess implements Action {
    public readonly type = EWidgetsActions.WIDGETS_FETCHED;
    constructor(public payload: HotHomePageWidgetsWithLanguage[]) {}
}

export class PopularProductsSynchronized implements Action {
    public readonly type = EWidgetsActions.POPULAR_PRODUCTS_SYNCHRONIZED;
    constructor(public payload?: HotCartExtended) {}
}

export class PopularProductsSynchronizedSuccess implements Action {
    public readonly type = EWidgetsActions.POPULAR_PRODUCTS_SYNCHRONIZED_SUCCESS;
    constructor(public payload: HotCartLineItemExtended[]) {}
}

export type WidgetsActions =
    | WidgetsGet
    | WidgetsGetSuccess
    | PopularProductsSynchronized
    | PopularProductsSynchronizedSuccess;
