import { Injectable } from '@angular/core';

import { OrderStatusModel, ExportFileType } from '@hot-libs/shared-models';

import { config } from 'apps/hot-b2b/src/environments/config';
import { environment } from 'apps/hot-b2b/src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    public isSfmcIntegrationEnabled(): boolean {
        return config.isSfmcSdkEnabledInDotApp;
    }

    public getOrderStatuses(): OrderStatusModel[] {
        return config.orderStatuses;
    }

    public getKegOrderStatuses(): OrderStatusModel[] {
        return config.kegOrderStatus;
    }

    public getAppTitle(): string {
        return environment.appTitle;
    }

    public getPreviousOrdersColumn(): any {
        return config.previousOrdersColumn;
    }

    public getMockOrder(): any {
        return config.mockOrder;
    }

    public getCurrentConfiguration(): any {
        return config.currentConfigurationName;
    }

    public getOrderExportFileTypes(): ExportFileType[] {
        return environment.exportOrdersTypes;
    }

    public getHeienekenValueRedirectionLink(): string[] {
        return environment.heinekenValuesRedirectionLinks;
    }
    public getPhonePrefix(): string | undefined {
        return config.phonePrefix;
    }
}
