import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import {
    HotApiCmsService,
    HotApiLoyaltyService,
    HotLoyaltyBanner,
    HotLoyaltyStatement,
    HotRedemptionWindowStatus,
} from '@hot-theme-nx/generated-api';

import {
    LoyaltyBannersGetSuccess,
    LoyaltyError,
    LoyaltyGet,
    LoyaltyGetSuccess,
    LoyaltyStatusGet,
    LoyaltyStatusGetSuccess,
} from '@hot-b2b/store/loyalty/actions';
import { ELoyaltyActions } from '@hot-b2b/store/loyalty/types';
import { FeatureNames } from '@hot-libs/shared-types';
import { FeaturesService } from 'apps/hot-b2b/src/app/shared/services';
import { LoyaltyService } from 'apps/hot-b2b/src/app/shared/services/loyalty.service';

@Injectable()
export class LoyaltyEffects {
    constructor(
        private readonly loyaltyService: LoyaltyService,
        private readonly actions$: Actions,
        private readonly apiLoyaltyService: HotApiLoyaltyService,
        private readonly apiCmsService: HotApiCmsService,
        private readonly featuresService: FeaturesService
    ) {}

    public LoyaltyGet$: Observable<LoyaltyGetSuccess | LoyaltyError> = createEffect(() => this.actions$.pipe(
        ofType<LoyaltyGet>(ELoyaltyActions.LOYALTY_PENDING),
        switchMap(() =>
            this.apiLoyaltyService
                .getStatement({ skip: 0, take: 0, operationType: '' })
                .pipe(
                    map((response: HotLoyaltyStatement) => new LoyaltyGetSuccess(response)),
                    catchError(async () =>new LoyaltyError('error'))
            )
        )
    ));

    public RedemptionAvailabilityGet$: Observable<LoyaltyStatusGetSuccess | LoyaltyError> = createEffect(() => this.actions$.pipe(
        ofType<LoyaltyStatusGet>(ELoyaltyActions.LOYALTY_PENDING),
        switchMap((action: LoyaltyStatusGet) =>
            this.loyaltyService
                .getState(action.payload)
                .pipe(map((response: HotRedemptionWindowStatus) => new LoyaltyStatusGetSuccess(response)))
        )
    ));

    public LoyaltyBannersGet$: Observable<LoyaltyBannersGetSuccess | LoyaltyError> = createEffect(() => this.actions$.pipe(
        ofType<LoyaltyGet>(ELoyaltyActions.LOYALTY_BANNERS_PENDING),
        withLatestFrom(this.featuresService.checkFeatures(FeatureNames.LoyaltyProgram)),
        filter(Boolean),
        switchMap(() =>
            this.apiCmsService
                .getLoyaltyBanners()
                .pipe(map((response: { [key: string]: HotLoyaltyBanner }) => new LoyaltyBannersGetSuccess(response)))
        )
    ));
}
